import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
//actions
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import moment from "moment"
import Switch from "react-switch"
import Select from "react-select"

import {
  createAssetItemFail,
  createAssetItemSuccess,
  getAllFamilyMembersDropdown,
  getLedgerDropdown,
  getLedgerItemCategoryDropdown,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import { Image } from "react-bootstrap"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"
import axios from "axios"
import { API_URL } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"

const MahallInstituteAssetItemTransactionCreate = ({ history }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const assetItemId = location?.state?.id
  const instituteId = location?.state?.institute
  const today = moment().format("YYYY-MM-DD")

  const {
    loading,
    language,
    searchLedgerItemCategories,
    searchLedgers,
    allFamilyMembers,
  } = useSelector(state => ({
    loading: state.Mahalls.loading,
    error: state.Mahalls.error,
    language: state.Layout.language,
    searchLedgerItemCategories:
      state.LedgerItemCategories.searchLedgerItemCategories,
    searchLedgers: state.Ledgers.searchLedgers,
    allFamilyMembers: state.FamilyMembers.allFamilyMembers,
  }))

  const [isLedgerItem, setIsLedgerItem] = useState(false)
  const [category, setCategory] = useState("select category")
  const [categoryId, setCategoryId] = useState("")
  const [searchTextCategory, setSearchTextCategory] = useState("")
  const [ledger, setLedger] = useState("select ledger")
  const [ledgerId, setLedgerId] = useState("")
  const [searchTextLedger, setSearchTextLedger] = useState("")
  const [familyMember, setFamilyMember] = useState("Select member...")
  const [familyMemberId, setFamilyMemberId] = useState("")
  const [searchTextMember, setSearchTextMember] = useState("")
  const [transactionType, setTransactionType] = useState("debit")
  const [otherName, setOtherName] = useState("")

  useEffect(() => {
    if (isLedgerItem) {
      dispatch(getLedgerItemCategoryDropdown(instituteId, searchTextCategory))
    }
  }, [isLedgerItem, instituteId, searchTextCategory, dispatch])

  useEffect(() => {
    if (isLedgerItem) {
      dispatch(getLedgerDropdown(instituteId, searchTextLedger))
    }
  }, [isLedgerItem, instituteId, searchTextLedger, dispatch])

  useEffect(() => {
    if (isLedgerItem) {
      dispatch(getAllFamilyMembersDropdown(searchTextMember))
    }
  }, [dispatch, isLedgerItem, searchTextMember])

  function handlerFinalValueCategory(event) {
    setCategoryId(event.value)
    setCategory(event.label)
  }

  function handlerFinalValueLedger(event) {
    setLedgerId(event.value)
    setLedger(event.label)
  }

  function handlerFinalValueMembers(event) {
    setFamilyMemberId(event.value)
    setFamilyMember(event.label)
  }

  const optionGroupCategory = [
    {
      options: searchLedgerItemCategories?.map((results, index) => ({
        key: index,
        label: results.institute_ledger_item_category_name,
        value: results.id,
      })),
    },
  ]

  const optionGroupLedger = [
    {
      options: searchLedgers?.map((result, index) => ({
        key: index,
        label: result?.institute_ledger_name,
        // `${result?.institute_ledger_name}
        // (${
        //   language === "mal"
        //     ? result?.institute_malayalam_name
        //       ? result?.institute_malayalam_name
        //       : result?.institute_english_name
        //     : result?.institute_english_name
        // }) (${result?.institute_type})`,
        value: result.id,
      })),
    },
  ]

  const optionGroupMembers = [
    {
      label: "All Members",
      value: "",
    },
    {
      options: allFamilyMembers?.map((result, index) => ({
        key: index,
        label: `${result?.full_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        })`,
        value: result.id,
      })),
    },
  ]

  const handleEntersCategory = textEntered => {
    setSearchTextCategory(textEntered)
  }

  const handleEntersLedger = textEntered => {
    setSearchTextLedger(textEntered)
  }

  const handleEntersMembers = textEntered => {
    setSearchTextMember(textEntered)
  }

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })
  console.log(customLoad)
  const [imagePreview, setImagePreview] = useState()

  const changeHandler = e => {
    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleValidSubmit = (onSubmitProps, values) => {
    setCustomLoad(true)
    createAssetItem(
      selectedFile,
      values,
      familyMemberId,
      assetItemId,
      categoryId,
      ledgerId,
      isLedgerItem,
      transactionType,
      dispatch,
      history,
      setCustomLoad
    )
    setImagePreview()
  }

  const transactionTypes = [
    {
      name: `${language === "mal" ? "ഇനം വാങ്ങുക" : "Buy Item"}`,
      value: "debit",
    },
    {
      name: `${language === "mal" ? "ഇനം വിൽക്കുക" : "Sell Item"}`,
      value: "credit",
    },
  ]

  return (
    <>
      <MetaTags>
        <title>Asset Item | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "അസറ്റ് ഇനം" : "Item Transaction"}
          breadcrumbItem={language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4 mb-4">Fill this form</CardTitle> */}
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <Row>
                        <div className="d-flex my-1">
                          {transactionTypes?.map((item, key) => (
                            <Col md="3" key={key}>
                              <div className="me-3">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio6"
                                  id={item.name}
                                  autoComplete="off"
                                  value={item.value}
                                  checked={
                                    transactionType === item.value
                                      ? true
                                      : false
                                  }
                                  onChange={e =>
                                    setTransactionType(e.target.value)
                                  }
                                />
                                {item.value === "debit" ? (
                                  <label
                                    className="btn btn-outline-success w-100"
                                    htmlFor={item.name}
                                  >
                                    {item.name}
                                  </label>
                                ) : (
                                  <label
                                    className="btn btn-outline-danger w-100"
                                    htmlFor={item.name}
                                  >
                                    {item.name}
                                  </label>
                                )}
                              </div>
                            </Col>
                          ))}
                        </div>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Title"}
                              {/* <span className="text-danger">&#42;</span> */}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="title"
                              type="text"
                              // validate={{
                              //   required: { value: true },
                              // }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <Row>
                            <Col md="4" className="mb-4">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-12 col-form-label"
                              >
                                {language === "mal" ? "തീയതി" : "Date"}
                              </Label>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="date"
                                type="date"
                                value={today}
                              />
                            </Col>
                            <Col md="4">
                              <div className="col mb-4">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-12 col-form-label"
                                >
                                  {language === "mal" ? "മൂല്യം" : "Value"}
                                  {/* <span className="text-danger">&#42;</span> */}
                                </Label>
                                <AvField
                                  id="horizontal-firstname-Input"
                                  name="value"
                                  type="number"
                                  value="0"
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                  // label={language === "mal" ? "മൊബൈൽ നമ്പർ" : "Meeting Date"}
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="col mb-4">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-12 col-form-label"
                                >
                                  {language === "mal" ? "അളവ്" : "Quantity"}
                                </Label>
                                <AvField
                                  id="horizontal-firstname-Input"
                                  name="quantity"
                                  type="number"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-12">
                          <div className="col mb-4">
                            <AvField
                              id="horizontal-firstname-Input"
                              name="remark"
                              type="textarea"
                              label={language === "mal" ? "വിവരണം" : "Remark"}
                            />
                          </div>
                        </div>
                      </Row>

                      <div className="col-4 mb-3">
                        <Label check for="checkbox">
                          {language === "mal"
                            ? "ലെഡ്ജർ ഇനമാണോ?"
                            : "Is Ledger Item"}{" "}
                          {" : "}
                        </Label>
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2 mx-3"
                          onColor="#626ed4"
                          onChange={v => {
                            setIsLedgerItem(v)
                          }}
                          checked={isLedgerItem}
                        />
                      </div>

                      {isLedgerItem && (
                        <Row>
                          {otherName?.length === 0 && (
                            <Col md="4" className="mb-4">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-12 col-form-label"
                              >
                                {language === "mal" ? "കുടുംബാംഗം" : "Member"}
                              </Label>
                              <Select
                                onInputChange={handleEntersMembers}
                                placeholder={familyMember}
                                options={optionGroupMembers}
                                classNamePrefix="select2-selection"
                                onChange={handlerFinalValueMembers}
                                type="text"
                                title="Family Member"
                                required="required"
                              />
                            </Col>
                          )}
                          {!familyMemberId && (
                            <Col md="4" className="mb-4">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-12 col-form-label"
                              >
                                {language === "mal"
                                  ? "വേറെ പേര്"
                                  : "Other Name"}
                              </Label>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="other_name"
                                type="text"
                                onChange={e => setOtherName(e.target.value)}
                              />
                            </Col>
                          )}
                          <Col md="4" className="mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "വിശദാംശങ്ങൾ"
                                : "Particulars"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="title"
                              type="text"
                            />
                          </Col>
                          <Col md="4" className="mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "ലെഡ്ജർ" : "Ledger"}
                            </Label>
                            <Select
                              onInputChange={handleEntersLedger}
                              placeholder={ledger}
                              options={optionGroupLedger}
                              classNamePrefix="select2-selection"
                              onChange={handlerFinalValueLedger}
                              type="text"
                              title="Institute Ledger"
                            />
                          </Col>
                          <Col md="4" className="mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "വിഭാഗം" : "Category"}
                            </Label>
                            <Select
                              onInputChange={handleEntersCategory}
                              placeholder={category}
                              options={optionGroupCategory}
                              classNamePrefix="select2-selection"
                              onChange={handlerFinalValueCategory}
                              type="text"
                              title="Institute Category"
                            />
                          </Col>
                          <Col md="2" className="mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "തുക" : "Amount"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="amount"
                              type="number"
                              value="0"
                              validate={{
                                required: { value: true },
                                min: {
                                  value: 0,
                                  errorMessage: `${
                                    language === "mal"
                                      ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                                      : "Amount must be greater than 0"
                                  }`,
                                },
                              }}
                            />
                          </Col>
                          {/* <Col md="3" className="mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "തീയതി" : "Date"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="date"
                              type="date"
                              value={today}
                            />
                          </Col> */}
                          {/* <Col md="12" className="mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "വിവരണം" : "Details"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="description"
                              type="textarea"
                            />
                          </Col> */}
                          <div className="row mb-4">
                            <Col sm="12" md="12">
                              <Label>
                                {language === "mal"
                                  ? "ബില്ലിന്റെ ഫോട്ടോ"
                                  : "Bill Image"}
                              </Label>
                              <AvField
                                name="bill_image"
                                type="file"
                                onChange={changeHandler}
                              />
                              {imagePreview && (
                                <Image
                                  style={{ width: "150px", height: "150px" }}
                                  className="my-2"
                                  src={imagePreview.image}
                                />
                              )}
                            </Col>
                          </div>
                        </Row>
                      )}

                      <div className="d-flex justify-content-end mt-5">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default MahallInstituteAssetItemTransactionCreate

MahallInstituteAssetItemTransactionCreate.propTypes = {
  history: PropTypes.object,
}

function createAssetItem(
  selectedFile,
  values,
  familyMemberId,
  assetItemId,
  categoryId,
  ledgerId,
  isLedgerItem,
  transactionType,
  dispatch,
  history,
  setCustomLoad
) {
  const formData = new FormData()
  const ledgerItem = {
    institute_ledger: ledgerId ? ledgerId : "",
    member: familyMemberId ? familyMemberId : "",
    other_name: values.other_name ? values.other_name : "",
    institute_ledger_item_category: categoryId ? categoryId : "",
    amount: values.amount ? parseInt(values.amount) : 0,
  }
  // if (selectedFile && selectedFile.image) {
  //   ;(ledgerItem.bill_image = selectedFile?.image), selectedFile.image.name
  // }
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append(
        "bill_image",
        selectedFile?.image,
        selectedFile?.image?.name
      )
  }
  formData.append("ledger_item", JSON.stringify(ledgerItem))
  formData.append("institute_asset_item", assetItemId)
  formData.append("title", values.title)
  formData.append("value", parseInt(values.value))
  formData.append("quantity", parseInt(values.quantity))
  formData.append("remark", values.remark)
  formData.append("is_ledger_item", isLedgerItem ? isLedgerItem : false)
  formData.append("date", values.date ? values.date : "")
  formData.append("transaction_type", transactionType)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .post(
      `${API_URL}/api/v1/institute-asset/asset-item-transaction/`,
      formData,
      {
        headers: {
          Authorization: "token " + token,
          Role: role,
        },
      }
    )
    .then(res => {
      if (res && !res?.error) {
        doneNotification("Added Successfully")
        setCustomLoad(false)
        dispatch(createAssetItemSuccess(res?.data))
        history.push(
          `/mahall/institute/assetItem/${res?.data?.institute_asset_item}`
        )
      } else {
        errorNotification(res?.error)
      }
    })
    .catch(err => {
      createAssetItemFail(err)
      errorNotification()
      setCustomLoad(false)
    })
}

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}
