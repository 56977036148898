import React, { useEffect } from "react"
import {
  // Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Table,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
// import ToolkitProvider from 'react-bootstrap-table2-toolkit'
// import BootstrapTable from 'react-bootstrap-table-next'
//actions
//componetns
// import { debounce, map, range } from 'lodash'

// import DeleteModal from 'components/Common/DeleteModal'
import Breadcrumbs from "components/Common/Breadcrumb"
// import MyPagination from 'components/Common/MyPagination'
import {
  getStudentAbsentDetail,
  // deleteCommitteeMeeting,
  getStudentDetail,
} from "store/actions"

const InstituteStudentDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()

  // const [searchText, setSearchText] = useState('')
  // const [page, setPage] = useState(1)

  const { studentDetail, detailLoading, language } = useSelector(state => ({
    detailLoading: state.Mahalls.detailLoading,
    studentDetail: state.Students.studentDetail,
    language: state.Layout.language,
  }))

  // const totalPages = Math.ceil(committeeMeetingAttendances?.count / 10)
  // const pages = range(1, totalPages + 1)

  // const pageSend = () => {
  //   if (page >= pages.length) {
  //     return pages.length
  //   }
  //   if (page < 1) {
  //     return 1
  //   } else {
  //     return page
  //   }
  // }

  // const debounceMeetingAttendanceSearch = debounce(
  //   value => setSearchText(value),
  //   600,
  // )

  // const columns = [
  //   {
  //     dataField: 'no',
  //     text: `${language === 'mal' ? 'നം.' : 'No.'}`,
  //     sort: true,
  //   },
  //   {
  //     dataField: 'committee_member_name',
  //     text: `${language === 'mal' ? 'അംഗം' : 'Member'}`,
  //     sort: true,
  //   },
  //   {
  //     dataField: 'committee_member_designation',
  //     text: `${language === 'mal' ? 'പദവി' : 'Designation'}`,
  //   },
  //   {
  //     dataField: 'attendance_time',
  //     text: `${language === 'mal' ? 'സമയം' : 'Time'}`,
  //   },
  //   {
  //     dataField: 'action',
  //     text: `${language === 'mal' ? 'പ്രവർത്തനങ്ങൾ' : 'Actions'}`,
  //   },
  // ]

  // const committeeMeetingAttendancesData = map(
  //   committeeMeetingAttendances?.results,
  //   (item, index) => ({
  //     ...item,
  //     key: index,
  //     no: index + 1,
  //     action: (
  //       <div className="m-0">
  //         <Button type="button" color="primary" className=" btn w-xs btn-sm">
  //           Edit
  //         </Button>
  //       </div>
  //     ),
  //   }),
  // )

  // const defaultSorted = [
  //   {
  //     dataField: 'id',
  //     order: 'asc',
  //   },
  // ]

  // const selectRow = {
  //   mode: 'checkbox',
  // }

  // const [isOpen, setIsOpen] = useState(false)

  // const handleDelete = () => {
  //   setIsOpen(true)
  // }
  // const handleDeleteEvent = () => {
  //   dispatch(
  //     deleteCommitteeMeeting(
  //       studentDetail.id,
  //       studentDetail?.committee,
  //       history,
  //     ),
  //   )
  //   setIsOpen(false)
  // }

  useEffect(() => {
    dispatch(getStudentDetail(params.id))
  }, [])

  useEffect(() => {
    dispatch(getStudentAbsentDetail(params.id))
  }, [])

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  return (
    <>
      {/* <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      /> */}

      <MetaTags>
        <title>Student Details | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal" ? "വിദ്യാർത്ഥി വിശദാംശങ്ങൾ" : "Student Details"
          }
          breadcrumbItem={language === "mal" ? "വിദ്യാർത്ഥികൾ" : "Students"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md="12" lg="12">
                <Card>
                  <CardBody>
                    {detailLoading ? (
                      <Spinner />
                    ) : (
                      <Row>
                        <Col md="12">
                          <h5 className="font-size-22 font-weight-bold mt-1">
                            {studentDetail?.title}
                          </h5>
                        </Col>
                        <Col md={6}>
                          <Col md="6" sm="12">
                            <h4>{studentDetail?.name}</h4>
                            <div className="table-responsive mt-4">
                              <Table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <td>
                                      {language === "mal" ? "ക്ലാസ്" : "Class"}
                                    </td>
                                    <td>
                                      {" "}
                                      {": "}
                                      {studentDetail?.class_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {language === "mal"
                                        ? "ഡിവിഷൻ"
                                        : "Division"}
                                    </td>
                                    <td>
                                      {": "}
                                      {studentDetail?.division_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    {/* <td>
                                      {language === 'mal'
                                        ? 'ക്രമ നം.'
                                        : 'Roll No.'}
                                    </td>
                                    <td>
                                      {': '}
                                      {studentDetail?.roll_no}
                                    </td> */}
                                    <td>
                                      {language === "mal" ? "വയസ്സ്" : "Age"}
                                    </td>
                                    <td>
                                      {": "}
                                      {getAge(studentDetail?.dob)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {language === "mal"
                                        ? "രക്ഷിതാവ്"
                                        : "Parent"}
                                    </td>
                                    <td>
                                      {": "}
                                      {studentDetail?.parent_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {language === "mal"
                                        ? "ഫോൺ നമ്പർ"
                                        : "Phone Number"}
                                    </td>
                                    <td>
                                      {": "}
                                      {studentDetail?.parent_phone}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {language === "mal"
                                        ? "വരിസംഖ്യ തുക"
                                        : "Varisangya Amount"}
                                    </td>
                                    <td>
                                      {": "}
                                      {studentDetail?.varisankya_amount}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {language === "mal"
                                        ? "വാലറ്റ് തുക"
                                        : "Wallet Amount"}
                                    </td>
                                    <td>
                                      {": "}
                                      {studentDetail?.varisankya_wallet}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default InstituteStudentDetails

InstituteStudentDetails.propTypes = {
  history: PropTypes.object,
}
