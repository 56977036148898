import {
  GET_STUDENT_VARISANGYA_WALLETS,
  CREATE_STUDENT_VARISANGYA_WALLET,
  GET_STUDENT_VARISANGYA_WALLETS_SUCCESS,
  GET_STUDENT_VARISANGYA_WALLETS_FAIL,
  CREATE_STUDENT_VARISANGYA_WALLET_SUCCESS,
  CREATE_STUDENT_VARISANGYA_WALLET_FAIL,
  GET_STUDENT_VARISANGYA_WALLET_DETAIL,
  GET_STUDENT_VARISANGYA_WALLET_DETAIL_SUCCESS,
  GET_STUDENT_VARISANGYA_WALLET_DETAIL_FAIL,
  CLEAR_STUDENT_VARISANGYA_WALLET_DETAIL,
  GET_STUDENT_VARISANGYA_WALLET_VIEW,
  GET_STUDENT_VARISANGYA_WALLET_VIEW_SUCCESS,
  GET_STUDENT_VARISANGYA_WALLET_VIEW_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  studentVarisangyaWallets: [],
  studentVarisangyaWalletDetail: [],
  studentVarisangyaWalletView: {},
  error: {},
  loading: false,
  tableLoading: false,
  pageLoading: false,
  createdStudentVarisangyaWallet: {},
}

const StudentVarisangyaWallets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_STUDENT_VARISANGYA_WALLET_DETAIL:
      return {
        ...state,
        studentVarisangyaWalletDetail: [],
      }
    case GET_STUDENT_VARISANGYA_WALLETS:
    case GET_STUDENT_VARISANGYA_WALLET_VIEW:
    case CREATE_STUDENT_VARISANGYA_WALLET:
      return {
        ...state,
        loading: true,
      }
    case GET_STUDENT_VARISANGYA_WALLET_DETAIL:
      return {
        ...state,
        tableLoading: true,
      }

    case GET_STUDENT_VARISANGYA_WALLETS_SUCCESS:
      return {
        ...state,
        studentVarisangyaWallets: action.payload,
        loading: false,
      }
    case GET_STUDENT_VARISANGYA_WALLETS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_STUDENT_VARISANGYA_WALLET_SUCCESS:
      return {
        ...state,
        studentVarisangyaWallets: action.payload,
        studentVarisangyaWalletDetail: {
          ...state.studentVarisangyaWalletDetail,
          results: [
            ...state.studentVarisangyaWalletDetail.results,
            action.payload,
          ],
        },
        createdStudentVarisangyaWallet: action.payload,
        loading: false,
      }

    case CREATE_STUDENT_VARISANGYA_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STUDENT_VARISANGYA_WALLET_DETAIL_SUCCESS:
      return {
        ...state,
        studentVarisangyaWalletDetail: action.payload,
        tableLoading: false,
      }
    case GET_STUDENT_VARISANGYA_WALLET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        tableLoading: false,
      }

    case GET_STUDENT_VARISANGYA_WALLET_VIEW_SUCCESS:
      return {
        ...state,
        studentVarisangyaWalletView: action.payload,
        tableLoading: false,
      }
    case GET_STUDENT_VARISANGYA_WALLET_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        tableLoading: false,
      }

    default:
      return state
  }
}

export default StudentVarisangyaWallets
