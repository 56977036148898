import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CLASSES,
  GET_CLASS_DETAIL,
  CREATE_CLASS,
  UPDATE_CLASS,
  DELETE_CLASS,
  GET_CLASS_DROPDOWN,
} from "./actionTypes"
import {
  getClassesSuccess,
  getClassesFail,
  getClassDetailSuccess,
  getClassDetailFail,
  createClassSuccess,
  createClassFail,
  updateClassSuccess,
  updateClassFail,
  deleteClassSuccess,
  deleteClassFail,
  getClassDropdownSuccess,
  getClassDropdownFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getClassesAPi = ({ searchText, page, institute_type }) => {
  if (institute_type) {
    return get(
      `/api/v1/madrasa/madrasa-class/?institute=${
        institute_type ? institute_type : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/madrasa/madrasa-class/?page=${page ? page : 1}&search=${
        searchText ? searchText : ""
      }`
    )
  }
}
const getClassDropdownAPi = ({ instituteId, searchText }) => {
  if (instituteId) {
    return get(
      `/api/v1/madrasa/madrasa-class-dropdown/?institute=${
        instituteId ? instituteId : ""
      }&search=${searchText ? searchText : ""}`
    )
  } else {
    return get(
      `/api/v1/madrasa/madrasa-class-dropdown/?search=${
        searchText ? searchText : ""
      }`
    )
  }
}
const getClassDetailsAPi = classId => {
  return get(`/api/v1/madrasa/madrasa-class/${classId}/`)
}
const createClassApi = ({ newClass }) => {
  return post("/api/v1/madrasa/madrasa-class/", newClass)
}
const updateClassApi = ({ classId, updatedClass }) => {
  return ApiPut(`/api/v1/madrasa/madrasa-class/${classId}/`, updatedClass)
}
const deleteClassApi = ({ classId }) => {
  return del(`/api/v1/madrasa/madrasa-class/${classId}/`)
}

function* fetchClasses({ payload }) {
  try {
    const response = yield call(getClassesAPi, payload)
    if (response && !response?.error) {
      yield put(getClassesSuccess(response))
    } else {
      yield put(getClassesFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getClassesFail(error))
  }
}

function* fetchClassDropdown({ payload }) {
  try {
    const response = yield call(getClassDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getClassDropdownSuccess(response))
    } else {
      yield put(getClassDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getClassDropdownFail(error))
  }
}

function* fetchClassDetail({ classId }) {
  try {
    const response = yield call(getClassDetailsAPi, classId)
    if (response && !response?.error) {
      yield put(getClassDetailSuccess(response))
    } else {
      yield put(getClassDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getClassDetailFail(error))
  }
}
function* onCreateClass({ payload }) {
  try {
    const response = yield call(createClassApi, payload)
    if (response && !response?.error) {
      yield put(createClassSuccess(response))
      if (payload.history) {
        payload.history.push("/varisangyas")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createClassFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createClassFail(error))
    errorNotification()
  }
}

function* onUpdateClass({ payload }) {
  try {
    const response = yield call(updateClassApi, payload)
    if (response && !response?.error) {
      yield put(updateClassSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateClassFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateClassFail(error))
    errorNotification()
  }
}

function* onDeleteClass({ payload }) {
  try {
    const response = yield call(deleteClassApi, payload)
    if (response && !response?.error) {
      yield put(deleteClassSuccess(payload?.classId))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteClassFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteClassFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* ClassesSaga() {
  yield takeEvery(GET_CLASSES, fetchClasses)
  yield takeEvery(GET_CLASS_DROPDOWN, fetchClassDropdown)
  yield takeEvery(GET_CLASS_DETAIL, fetchClassDetail)
  yield takeEvery(CREATE_CLASS, onCreateClass)
  yield takeEvery(UPDATE_CLASS, onUpdateClass)
  yield takeEvery(DELETE_CLASS, onDeleteClass)
}

export default ClassesSaga
