import { AvField, AvForm } from "availity-reactstrap-validation"
import React from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"

//actions
import { updateZakat } from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const UpdateMahallZakat = ({ history }) => {
  const dispatch = useDispatch()

  const { loading, language, zakatDetail } = useSelector(state => ({
    loading: state.Zakats.loading,
    error: state.Zakats.error,
    language: state.Layout.language,
    zakatDetail: state.Zakats.zakatDetail,
  }))

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const updateZakatData = {
      title: values.title,
      description: values.description,
      year: values.year,
    }
    // console.log('registerZakatData: ', registerZakatData);
    dispatch(updateZakat(updateZakatData, zakatDetail?.id, history))
  }

  return (
    <>
      <MetaTags>
        <title>Zakat | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "സകാത്ത്" : "Zakat"}
          breadcrumbItem={language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക" : "Update"}
        />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <Row>
                <Col sm="12" md="8" lg="8">
                  <Card>
                    <CardBody>
                      <Row>
                        <div className="row mb-4">
                          <Col md="8">
                            <Label>
                              {language === "mal" ? "തലക്കെട്ട്" : "Title"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              name="title"
                              type="text"
                              value={zakatDetail?.title}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: `${
                                    language === "mal"
                                      ? "നിർബന്ധമാണ്!"
                                      : "mandatory!"
                                  }`,
                                },
                              }}
                            />
                          </Col>
                          <Col>
                            <Label>
                              {language === "mal" ? "വർഷം" : "Year"}
                            </Label>
                            <AvField
                              name="year"
                              type="number"
                              value={zakatDetail?.year}
                              validate={{
                                maxLength: {
                                  value: 4,
                                  errorMessage: `${
                                    language === "mal"
                                      ? "വർഷം 4 അക്കങ്ങൾ ആയിരിക്കണം"
                                      : "Year must be 4 digits"
                                  }`,
                                },
                                minLength: {
                                  value: 4,
                                  errorMessage: `${
                                    language === "mal"
                                      ? "വർഷം 4 അക്കങ്ങൾ ആയിരിക്കണം"
                                      : "Year must be 4 digits"
                                  }`,
                                },
                              }}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col>
                            <Label>
                              {language === "mal" ? "വിവരണം" : "Description "}
                            </Label>
                            <AvField
                              name="description"
                              type="textarea"
                              rows="3"
                              value={zakatDetail?.description}
                            />
                          </Col>
                        </div>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="col-12 btn btn-success save-customer"
                          >
                            {loading && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            )}
                            {language === "mal"
                              ? "അപ്ഡേറ്റ് ചെയ്യുക"
                              : "Update"}
                          </button>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateMahallZakat

UpdateMahallZakat.propTypes = {
  history: PropTypes.object,
}
