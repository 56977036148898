import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_MASTER_ACCOUNT_ITEMS,
  GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD,
} from "./actionTypes"
import {
  getMasterAccountItemsSuccess,
  getMasterAccountItemsFail,
  getMasterAccountItemsForDownloadSuccess,
  getMasterAccountItemsForDownloadFail,
} from "./actions"

import { get } from "helpers/api_methods"
import { errorNotification } from "components/Common/Notification"

const getMasterAccountItemsAPi = ({
  searchText,
  page,
  date,
  sort,
  instituteType,
}) => {
  if (date.start_date && date.end_date) {
    return get(
      `/api/v1/institute/institute_ledger_item/?sort_by_institute=${
        sort && sort
      }&start_date=${date.start_date && date.start_date}&end_date=${
        date.end_date && date.end_date
      }&instituteType=${instituteType ? instituteType : ""}&search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else if (instituteType) {
    return get(
      `/api/v1/institute/institute_financia_year/?instituteType=${
        instituteType && instituteType
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/institute/institute_financia_year/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  }
}

const getMasterAccountItemsForDownloadAPi = ({ date, sort, instituteType }) => {
  if (date.start_date && date.end_date) {
    return get(
      `/api/v1/institute/institute_ledger_item/?sort_by_institute=${
        sort && sort
      }&instituteType=${instituteType ? instituteType : ""}&start_date=${
        date.start_date && date.start_date
      }&end_date=${date.end_date && date.end_date}`
    )
  } else if (instituteType) {
    return get(
      `/api/v1/institute/institute_financia_year/?instituteType=${
        instituteType && instituteType
      }`
    )
  } else {
    return get(`/api/v1/institute/institute_financia_year/`)
  }
}

function* fetchMasterAccountItems({ payload }) {
  try {
    const response = yield call(getMasterAccountItemsAPi, payload)
    if (response && !response?.error) {
      yield put(getMasterAccountItemsSuccess(response))
    } else {
      yield put(getMasterAccountItemsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMasterAccountItemsFail(error))
  }
}

function* fetchMasterAccountItemsForDownload({ payload }) {
  try {
    const response = yield call(getMasterAccountItemsForDownloadAPi, payload)
    if (response && !response?.error) {
      yield put(getMasterAccountItemsForDownloadSuccess(response))
    } else {
      yield put(getMasterAccountItemsForDownloadFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMasterAccountItemsForDownloadFail(error))
  }
}

function* MasterAccountItemsSaga() {
  yield takeEvery(GET_MASTER_ACCOUNT_ITEMS, fetchMasterAccountItems)
  yield takeEvery(
    GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD,
    fetchMasterAccountItemsForDownload
  )
}

export default MasterAccountItemsSaga
