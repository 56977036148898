import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import EducationsSaga from "./education/saga"
import ConstituenciesSaga from "./constituency/saga"
import MahallsSaga from "./mahall/saga"
import AdminsSaga from "./admin/saga"
//---------------------- Mahall ------------------------------------------------- //
import CommitteesSaga from "./committee/saga"
import CommitteeMembersSaga from "./committeeMember/saga"
import CommitteeMeetingsSaga from "./committeeMeeting/saga"
import CommitteeMeetingImagesSaga from "./committeeMeetingImage/saga"
import CommitteeMeetingAttendancesSaga from "./CommitteeMeetingAttendance/saga"
import StatesSaga from "./state/saga"
import DistrictsSaga from "./district/saga"
import VillagesSaga from "./village/saga"
import FamiliesSaga from "./family/saga"
import FamilyMembersSaga from "./familyMember/saga"
import JobsSaga from "./job/saga"
import InstitutesSaga from "./institute/saga"
import InstituteAccountsSaga from "./instituteAccount/saga"
import InstituteUsersSaga from "./instituteuser/saga"
import MahallUsersSaga from "./mahalluser/saga"
import TypeListSaga from "./typeList/saga"
import UsersSaga from "./user/saga"
import LsgsSaga from "./lsg/saga"
import EnquiriesSaga from "./enquiry/saga"
import DashBoardDataSaga from "./Dashboard/saga"
import GroupsSaga from "./group/saga"
import GroupAdminsSaga from "./groupadmin/saga"
import SurveyUsersSaga from "./surveyuser/saga"
import GalleriesSaga from "./instituteGallery/saga"
import LedgersSaga from "./ledger/saga"
import LedgerItemsSaga from "./ledgerItem/saga"
import MasterAccountItemsSaga from "./masterAccount/saga"
import LedgerItemCategoriesSaga from "./ledgerItemCategory/saga"
import NikahRegistrationsSaga from "./nikah/saga"
import DeathRegistrationsSaga from "./death/saga"
import ZakatsSaga from "./zakat/saga"
import ZakatItemsSaga from "./zakatItem/saga"
import VarisangyasSaga from "./varisangya/saga"
import UnapprovedFamiliesSaga from "./unapprovedFamily/saga"
import UnapprovedFamilyMembersSaga from "./unapprovedFamilyMember/saga"
import BannersSaga from "./banner/saga"
import NotificationsSaga from "./notification/saga"
import CollectionsSaga from "./collection/saga"
import FeedsSaga from "./feed/saga"
import CountriesSaga from "./country/saga"
import NocCertificateSaga from "./nocCertificate/saga"
import VarisangyaSplitLedgersSaga from "./varisangyaSplitLedger/saga"
import FamilyVarisangyasSaga from "./familyVarisangya/saga"
import FamilyVarisangyaItemsSaga from "./familyVarisangyaItem/saga"
import FamilyVarisangyaWalletsSaga from "./familyVarisangyaWallet/saga"
import FamilyMemberVarisangyasSaga from "./familyMemberVarisangya/saga"
import FamilyMemberVarisangyaItemsSaga from "./familyMemberVarisangyaItem/saga"
import FamilyMemberVarisangyaWalletsSaga from "./familyMemberVarisangyaWallet/saga"
import activityLogsSaga from "./activityLog/saga"
import AssetsSaga from "./assets/saga"
import AssetItemsSaga from "./assetItems/saga"
import AssetItemTransactionsSaga from "./assetItemTransactions/saga"
import MadrasaListsSaga from "./madrasa/saga"
import ClassesSaga from "./class/saga"
import DivisionsSaga from "./division/saga"
import StudentsSaga from "./student/saga"
import StaffsSaga from "./staff/saga"
import SubjectsSaga from "./subject/saga"
import StudentAttendancesSaga from "./studentAttendance/saga"
import TimetableSaga from "./timetable/saga"
import StudentVarisangyasSaga from "./studentVarisangya/saga"
import StudentVarisangyaWalletsSaga from "./studentVarisangyaWallet/saga"
import InstituteWalletsSaga from "./instituteWallet/saga"
import InstituteWalletContraSaga from "./instituteWalletContra/saga"
import TransationsSaga from "./Transactions/saga"
import SupportTicketSaga from "./SupportTIcket/saga"
import SuperFeedsSaga from "./Superfeeds/saga"
import SuperBannersSaga from "./SuperBanner/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(EducationsSaga),
    fork(ConstituenciesSaga),
    fork(MahallsSaga),
    fork(AdminsSaga),
    fork(CommitteesSaga),
    fork(CommitteeMembersSaga),
    fork(CommitteeMeetingsSaga),
    fork(CommitteeMeetingImagesSaga),
    fork(CommitteeMeetingAttendancesSaga),
    fork(StatesSaga),
    fork(DistrictsSaga),
    fork(VillagesSaga),
    fork(InstituteUsersSaga),
    fork(InstituteAccountsSaga),
    fork(MahallUsersSaga),
    fork(FamiliesSaga),
    fork(FamilyMembersSaga),
    fork(InstitutesSaga),
    fork(JobsSaga),
    fork(TypeListSaga),
    fork(UsersSaga),
    fork(LsgsSaga),
    fork(EnquiriesSaga),
    fork(DashBoardDataSaga),
    fork(GroupsSaga),
    fork(GroupAdminsSaga),
    fork(SurveyUsersSaga),
    fork(GalleriesSaga),
    fork(LedgersSaga),
    fork(LedgerItemsSaga),
    fork(MasterAccountItemsSaga),
    fork(LedgerItemCategoriesSaga),
    fork(NikahRegistrationsSaga),
    fork(DeathRegistrationsSaga),
    fork(ZakatsSaga),
    fork(ZakatItemsSaga),
    fork(VarisangyasSaga),
    fork(UnapprovedFamiliesSaga),
    fork(UnapprovedFamilyMembersSaga),
    fork(BannersSaga),
    fork(NotificationsSaga),
    fork(CollectionsSaga),
    fork(FeedsSaga),
    fork(CountriesSaga),
    fork(NocCertificateSaga),
    fork(VarisangyaSplitLedgersSaga),
    fork(FamilyVarisangyasSaga),
    fork(FamilyVarisangyaItemsSaga),
    fork(FamilyVarisangyaWalletsSaga),
    fork(FamilyMemberVarisangyasSaga),
    fork(FamilyMemberVarisangyaItemsSaga),
    fork(FamilyMemberVarisangyaWalletsSaga),
    fork(activityLogsSaga),
    fork(AssetsSaga),
    fork(AssetItemsSaga),
    fork(AssetItemTransactionsSaga),
    fork(MadrasaListsSaga),
    fork(ClassesSaga),
    fork(DivisionsSaga),
    fork(StudentsSaga),
    fork(StaffsSaga),
    fork(SubjectsSaga),
    fork(StudentAttendancesSaga),
    fork(TimetableSaga),
    fork(StudentVarisangyasSaga),
    fork(StudentVarisangyaWalletsSaga),
    fork(InstituteWalletsSaga),
    fork(InstituteWalletContraSaga),
    fork(TransationsSaga),
    fork(SupportTicketSaga),
    fork(SuperFeedsSaga),
    fork(SuperBannersSaga),
  ])
}
