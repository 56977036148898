import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"
import PropTypes from "prop-types"

import {
  getNikahRegistrations,
  // deleteNikahRegistration,
  getNikahRegistrationsCards,
  getNikahRegistrationsForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"

const AllMahallNikahRegistrations = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [modalNikahDownload, setModalNikahDownload] = useState(false)

  const {
    nikahRegistrations,
    loading,
    nikahRegistrationCards,
    language,
    nikahRegistrationsForDownload,
  } = useSelector(state => ({
    nikahRegistrations: state.NikahRegistrations?.nikahRegistrations,
    loading: state.NikahRegistrations?.loading,
    nikahRegistrationCards:
      state.NikahRegistrations?.nikahRegistrationsForDownload?.cards,
    language: state.Layout.language,
    nikahRegistrationsForDownload:
      state.NikahRegistrations?.nikahRegistrationsForDownload?.nikahs,
  }))

  //pages
  const totalPages = Math.ceil(nikahRegistrations?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getNikahRegistrationsForDownload())
  }, [dispatch])

  // useEffect(() => {
  //   if (modalNikahDownload) {
  //     dispatch(getNikahRegistrationsForDownload())
  //   }
  // }, [dispatch, modalNikahDownload])

  useEffect(() => {
    dispatch(getNikahRegistrations(searchText, pageSend()))
  }, [dispatch, page, searchText])

  useEffect(() => {
    dispatch(getNikahRegistrationsCards())
  }, [dispatch])

  // const handleDelete = id => {
  //   dispatch(deleteNikahRegistration(id, history))
  // }

  const cardData = [
    {
      title: language === "mal" ? "ആകെ നിക്കാഹുകൾ" : "Total Nikahs",
      cardValue: nikahRegistrationCards?.total_nikahs ?? 0,
      iconClass: "phone",
      routeLink: "#!",
      loading: loading,
      alt: "No Nikah Registrations",
    },
    {
      title: language === "mal" ? "ഈ മാസം പുതിയത്" : "New This Month",
      cardValue: nikahRegistrationCards?.total_nikahs_this_month ?? 0,
      iconClass: "phone-incoming",
      routeLink: "#!",
      loading: loading,
      alt: "No New Nikahs",
    },
    {
      title: language === "mal" ? "പെയ്ഡ് ചെയ്യാത്തത്" : "Unpaid",
      cardValue: nikahRegistrationCards?.total_fee_not_paid_nikahs ?? 0,
      iconClass: "check-circle",
      routeLink: "#!",
      loading: loading,
      alt: "No unpaid Nikahs",
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "mahall_custom_id",
      text: language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID",
    },
    {
      dataField: "date",
      text: language === "mal" ? "തീയതി" : "Date",
    },
    {
      dataField: "place",
      text: language === "mal" ? "സ്ഥലം" : "Place",
    },
    {
      dataField: "bride_name",
      text: language === "mal" ? "വധു" : "Bride",
    },
    {
      dataField: "bride_mahall",
      text: language === "mal" ? "മഹല്ല്" : "Mahall",
    },
    {
      dataField: "groom_name",
      text: language === "mal" ? "വരൻ" : "Groom",
    },
    {
      dataField: "groom_mahall",
      text: language === "mal" ? "മഹല്ല്" : "Mahall",
    },
    {
      dataField: "fee",
      text: language === "mal" ? "ഫീസ്" : "Fee",
    },
    // {
    //   dataField: "mahar",
    //   text: "Mahar",
    // },
    {
      dataField: "action",
      text: language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    },
  ]

  const nikahRegistrationsData = map(
    nikahRegistrations?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,

      date: item?.date && moment(item?.date).format("DD/MM/YYYY"),
      fee: (
        <>
          <Badge
            className={
              "font-size-12 badge-soft-" +
              `${item?.is_paid == true ? "success" : "danger"}`
            }
            color={"primary"}
            pill
          >
            {item?.fee ? item?.fee : "0"}
          </Badge>
        </>
      ),
      action: (
        <div className="m-0">
          {/* <Link to={`/nikahs/${item?.id}`} className="btn-sm">
          <i
            className="bx bx-show me-2"
            style={{ color: "green", fontSize: "18px" }}
          />
        </Link> */}
          <Link to={`/nikah/update/${item?.id}`} className="m-0">
            <Button type="button" color="primary" className=" btn w-xs btn-sm">
              {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
              {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
            </Button>
          </Link>

          {/* <Button
          type="button"
          color="white"
          className=" m-0 btn "
          onClick={() => { handleDelete(item.id) }}
        >
          <i className="bx bx-trash me-2" style={{ color: "red", fontSize: "18px" }} />
        </Button> */}
        </div>
      ),

      statusData: (
        <Badge
          className={
            "font-size-12 badge-soft-" +
            `${
              item?.status == "new"
                ? "success"
                : item?.status == "pending"
                ? "warning"
                : item?.status == "checked"
                ? "primary"
                : item?.status == "confirmed"
                ? "secondary"
                : item?.status == "closed"
                ? "danger"
                : ""
            }`
          }
          color={"primary"}
          pill
        >
          {item?.status}
        </Badge>
      ),
    })
  )

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    {
      label: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
      key: "mahall_custom_id",
    },
    { label: `${language === "mal" ? "തീയതി" : "Date"}`, key: "date" },
    { label: `${language === "mal" ? "സ്ഥലം" : "Place"}`, key: "place" },
    {
      label: `${language === "mal" ? "വധുവിന്റെ പേര്" : "Bride Name"}`,
      key: "bride_name",
    },
    {
      label: `${language === "mal" ? "വധുവിന്റെ മഹല്ല്" : "Bride Mahall"}`,
      key: "bride_mahall",
    },
    {
      label: `${language === "mal" ? "വധുവിന്റെ ജനന തീയതി" : "Bride DOB"}`,
      key: "bride_dob",
    },
    {
      label: `${language === "mal" ? "വരന്റെ പേര്" : "Groom Name"}`,
      key: "groom_name",
    },
    {
      label: `${language === "mal" ? "വരന്റെ മഹല്ല്" : "Groom Mahall"}`,
      key: "groom_mahall",
    },
    {
      label: `${language === "mal" ? "വരന്റെ ജനന തീയതി" : "Groom DOB"}`,
      key: "groom_dob",
    },
    { label: `${language === "mal" ? "ഫീസ് " : "Fee"}`, key: "fee" },
    {
      label: `${language === "mal" ? "ഫീസ് അടച്ചിട്ടുണ്ടോ?" : "Is Fee Paid?"}`,
      key: "is_paid",
    },
    {
      label: `${language === "mal" ? "വൈവാഹിക നില " : "Noc Id"}`,
      key: "noc_id",
    },
    // { label: "Listed Date", key: "date_added" },
  ]

  const nikahRegistrationsExcelData = map(
    nikahRegistrationsForDownload,
    (item, index) => ({
      ...item,
      no: (pageSend() - 1) * limit + index + 1,
      mahall_custom_id: item.mahall_custom_id,
      date: item.date && moment(item?.date).format("DD/MM/YYYY"),
      place: item.place,
      bride_name: item.bride_name,
      bride_mahall: item.bride_mahall,
      bride_dob: item.bride_dob && moment(item?.bride_dob).format("DD/MM/YYYY"),
      groom_name: item.groom_name,
      groom_mahall: item.groom_mahall,
      groom_dob: item.groom_dob && moment(item?.groom_dob).format("DD/MM/YYYY"),
      fee: item?.fee,
      is_paid:
        item?.is_paid === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      noc_id: item?.noc_id,
      // date_added: item?.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceNikahRegistrationSearch = debounce(
    value => setSearchText(value),
    600
  )

  const fileName = "khaf-smart-mahall-nikah-registrations-list.csv"

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalNikahDownload}
        onCloseclick={() => setModalNikahDownload(false)}
        excelData={nikahRegistrationsExcelData}
        headers={headers}
        list={nikahRegistrationsForDownload}
        fileName={fileName}
        loading={loading}
      />
      <Row>
        {cardData?.map((item, key) => (
          <Col lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink && item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue >= 0 && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      {/* <p className="m-0 text-info">
                        {item.cardValue >= 0 ? <></> : item.alt}
                      </p> */}
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Col sm="12" md="12" lg="12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={nikahRegistrationsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceNikahRegistrationSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <div className="col-md-8 d-flex justify-content-end">
                        <div>
                          <Button
                            style={{
                              width: language === "mal" ? "150px" : "130px",
                              border: "bold",
                            }}
                            className="btn-md me-2 mt-0  bg-white text-khaf"
                            onClick={() => setModalNikahDownload(true)}
                          >
                            <i
                              className="bx bx-download mx-1"
                              style={{ fontSize: "18px", color: "#009846" }}
                            />
                            {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                          </Button>
                          <Link
                            to="/nikah/register"
                            style={{
                              width: language === "mal" ? "220px" : "160px",
                            }}
                            className="w-md btn btn-success me-0"
                          >
                            {language === "mal"
                              ? "+ നിക്കാഹ് രജിസ്ട്രേഷൻ "
                              : "+ Nikah Registration"}
                          </Link>
                        </div>
                      </div>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {nikahRegistrations && nikahRegistrations?.length <= 0 && (
                      <p
                        style={{ fontSize: "15px" }}
                        className="text-center text-info"
                      >
                        {language === "mal"
                          ? "റെജിസ്ട്രേഷനുകൾ ഒന്നും ഇല്ല"
                          : "No Registrations Yet"}
                      </p>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllMahallNikahRegistrations

AllMahallNikahRegistrations.propTypes = {
  history: PropTypes.object,
}
