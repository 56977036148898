import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_UNAPPROVED_FAMILY_MEMBERS,
  GET_UNAPPROVED_FAMILY_MEMBER_DETAIL,
  UPDATE_UNAPPROVED_FAMILY_MEMBER,
  DELETE_UNAPPROVED_FAMILY_MEMBER,
  GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN,
} from "./actionTypes"
import {
  getUnapprovedFamilyMemberDetailSuccess,
  getUnapprovedFamilyMemberDetailFail,
  getUnapprovedFamilyMembersSuccess,
  getUnapprovedFamilyMembersFail,
  updateUnapprovedFamilyMemberSuccess,
  updateUnapprovedFamilyMemberFail,
  deleteUnapprovedFamilyMemberSuccess,
  deleteUnapprovedFamilyMemberFail,
  getUnapprovedFamilyMemberDropdownSuccess,
  getUnapprovedFamilyMemberDropdownFail,
} from "./actions"

import { get, del, patch } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getUnapprovedFamilyMembersAPi = ({ searchText, page, familyId }) => {
  if (familyId) {
    return get(
      `/api/v1/surveyapp/guest_familymember/?family=${
        familyId && familyId
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  } else if (searchText) {
    return get(
      `/api/v1/surveyapp/guest_familymember/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(`/api/v1/surveyapp/guest_familymember/?page=${page ? page : 1}`)
  }
}

const getUnapprovedFamilyMemberDetailsAPi = unapprovedFamilyMemberId => {
  return get(
    `/api/v1/surveyapp/guest_familymember/${unapprovedFamilyMemberId}/`
  )
}
const getUnapprovedFamilyMemberDropdownAPi = ({
  searchText,
  unapprovedFamilyId,
}) => {
  return get(
    `/api/v1/surveyapp/guest_familymember/?guest_family_dropdown=${unapprovedFamilyId}&search=${
      searchText && searchText
    }`
  )
}
const updateUnapprovedFamilyMemberApi = ({
  unapprovedFamilyMemberId,
  unapprovedFamilyMember,
}) => {
  return patch(
    `/api/v1/surveyapp/guest_familymember/${unapprovedFamilyMemberId}/`,
    unapprovedFamilyMember
  )
}
const deleteUnapprovedFamilyMemberApi = ({ unapprovedFamilyMemberId }) => {
  return del(
    `/api/v1/surveyapp/guest_familymember/${unapprovedFamilyMemberId}/`
  )
}

function* fetchUnapprovedFamilyMembers({ payload }) {
  try {
    const response = yield call(getUnapprovedFamilyMembersAPi, payload)
    if (response && !response?.error) {
      yield put(getUnapprovedFamilyMembersSuccess(response))
    } else {
      yield put(getUnapprovedFamilyMembersFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getUnapprovedFamilyMembersFail(error))
  }
}

function* fetchUnapprovedFamilyMemberDetail({ unapprovedFamilyMemberId }) {
  try {
    const response = yield call(
      getUnapprovedFamilyMemberDetailsAPi,
      unapprovedFamilyMemberId
    )
    if (response && !response?.error) {
      yield put(getUnapprovedFamilyMemberDetailSuccess(response))
    } else {
      yield put(getUnapprovedFamilyMemberDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getUnapprovedFamilyMemberDetailFail(error))
  }
}

function* fetchUnapprovedFamilyMemberDropdown({ payload }) {
  try {
    const response = yield call(getUnapprovedFamilyMemberDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getUnapprovedFamilyMemberDropdownSuccess(response))
    } else {
      yield put(getUnapprovedFamilyMemberDropdownFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getUnapprovedFamilyMemberDropdownFail(error))
  }
}

function* onUpdateUnapprovedFamilyMember({ payload }) {
  try {
    const response = yield call(updateUnapprovedFamilyMemberApi, payload)
    if (response && !response?.error) {
      yield put(updateUnapprovedFamilyMemberSuccess(response))
      payload.history.push(`/unapprovedfamilies/${response?.id}`)
      doneNotification("Updated Successfully")
    } else {
      yield put(updateUnapprovedFamilyMemberFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateUnapprovedFamilyMemberFail(error))
    // if (error?.response?.data?.error[0]) {
    //   yield put(updateFamilyMemberFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function* onDeleteUnapprovedFamilyMember({ payload }) {
  try {
    const response = yield call(deleteUnapprovedFamilyMemberApi, payload)
    if (response && !response?.error) {
      payload.history.push("/familymembers")
      yield put(deleteUnapprovedFamilyMemberSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteUnapprovedFamilyMemberFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(deleteUnapprovedFamilyMemberFail(error))
    // if (error?.response?.data?.error[0]) {
    //   yield put(deleteFamilyMemberFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* UnapprovedFamilyMembersSaga() {
  yield takeEvery(GET_UNAPPROVED_FAMILY_MEMBERS, fetchUnapprovedFamilyMembers)
  yield takeEvery(
    GET_UNAPPROVED_FAMILY_MEMBER_DETAIL,
    fetchUnapprovedFamilyMemberDetail
  )
  yield takeEvery(
    GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN,
    fetchUnapprovedFamilyMemberDropdown
  )
  yield takeEvery(
    UPDATE_UNAPPROVED_FAMILY_MEMBER,
    onUpdateUnapprovedFamilyMember
  )
  yield takeEvery(
    DELETE_UNAPPROVED_FAMILY_MEMBER,
    onDeleteUnapprovedFamilyMember
  )
}

export default UnapprovedFamilyMembersSaga
