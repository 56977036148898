import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_LEDGER_ITEMS,
  GET_LEDGER_ITEM_DETAIL,
  CREATE_LEDGER_ITEM,
  UPDATE_LEDGER_ITEM,
  DELETE_LEDGER_ITEM,
  GET_LEDGER_ITEMS_FOR_DOWNLOAD,
} from "./actionTypes"
import {
  getLedgerItemsSuccess,
  getLedgerItemsFail,
  getLedgerItemDetailSuccess,
  getLedgerItemDetailFail,
  createLedgerItemSuccess,
  createLedgerItemFail,
  updateLedgerItemSuccess,
  updateLedgerItemFail,
  deleteLedgerItemSuccess,
  deleteLedgerItemFail,
  getLedgerItemsForDownloadSuccess,
  getLedgerItemsForDownloadFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"
import { getLedgerDetailSuccess, getLedgerDetailFail } from "store/actions"

const getLedgerItemsAPi = ({
  ledgerId,
  searchText,
  page,
  date,
  transactionType,
  instituteId,
  categoryId,
  memberId,
  walletId,
}) => {
  return get(
    `/api/v1/institute/institute_ledger_item/?institute_ledger=${
      ledgerId ? ledgerId : ""
    }&start_date=${date.start_date ? date.start_date : ""}&end_date=${
      date.end_date ? date.end_date : ""
    }&transaction_type=${transactionType ? transactionType : ""}&institute=${
      instituteId ? instituteId : ""
    }&institute_ledger_item_category=${categoryId ? categoryId : ""}&member=${
      memberId ? memberId : ""
    }&institute_wallet=${walletId ? walletId : ""}&search=${
      searchText ? searchText : ""
    }&page=${page ? page : 1}`
  )
}
const getLedgerItemsForDownloadAPi = ({
  date,
  searchText,
  transactionType,
  ledgerId,
  instituteId,
  categoryId,
  memberId,
  walletId,
}) => {
  return get(
    `/api/v1/institute/institute_ledger_item/?institute_ledger=${
      ledgerId ? ledgerId : ""
    }&start_date=${date.start_date ? date.start_date : ""}&end_date=${
      date.end_date ? date.end_date : ""
    }&transaction_type=${transactionType ? transactionType : ""}&institute=${
      instituteId ? instituteId : ""
    }&institute_ledger_item_category=${categoryId ? categoryId : ""}&member=${
      memberId ? memberId : ""
    }&institute_wallet=${walletId ? walletId : ""}&search=${
      searchText ? searchText : ""
    }`
  )
}
const getLedgerItemDetailsAPi = ledgerItemId => {
  return get(`/api/v1/institute/institute_ledger_item/${ledgerItemId}/`)
}
const getLedgerDetailsAPi = ledgerId => {
  return get(`/api/v1/institute/institute_ledger/${ledgerId}/`)
}
const createLedgerItemApi = ({ ledgerItem }) => {
  return post("/api/v1/institute/institute_ledger_item/", ledgerItem)
}
const updateLedgerItemApi = ({ ledgerItemId, ledgerItem }) => {
  return ApiPut(
    `/api/v1/institute/institute_ledger_item/${ledgerItemId}/`,
    ledgerItem
  )
}
const deleteLedgerItemApi = ({ ledgerItemId }) => {
  return del(`/api/v1/institute/institute_ledger_item/${ledgerItemId}/`)
}

function* fetchLedgerItems({ payload }) {
  try {
    const response = yield call(getLedgerItemsAPi, payload)
    if (response && !response?.error) {
      yield put(getLedgerItemsSuccess(response))
    } else {
      yield put(getLedgerItemsFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getLedgerItemsFail(error))
  }
}

function* fetchLedgerItemsForDownload({ payload }) {
  try {
    const response = yield call(getLedgerItemsForDownloadAPi, payload)
    if (response && !response?.error) {
      yield put(getLedgerItemsForDownloadSuccess(response))
    } else {
      yield put(getLedgerItemsForDownloadFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getLedgerItemsForDownloadFail(error))
  }
}

function* fetchLedgerItemDetail({ ledgerItemId }) {
  try {
    const response = yield call(getLedgerItemDetailsAPi, ledgerItemId)
    if (response && !response?.error) {
      yield put(getLedgerItemDetailSuccess(response))
    } else {
      yield put(getLedgerItemDetailFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getLedgerItemDetailFail(error))
  }
}
function* onCreateLedgerItem({ payload }) {
  try {
    const response = yield call(createLedgerItemApi, payload)
    if (response && !response?.error) {
      yield put(createLedgerItemSuccess(response))
      // payload.history.push(`/ledgers/${response?.institute_ledger}`)
      // history.goBack()
      try {
        const response2 = yield call(getLedgerDetailsAPi, response2)
        console.log(response2)
        yield put(getLedgerDetailSuccess(response2))
      } catch (error) {
        yield put(getLedgerDetailFail(error))
      }
      doneNotification("Ledger Item Created Successfully")
    } else {
      yield put(createLedgerItemFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(createLedgerItemFail(error))
    errorNotification()
  }
}

function* onUpdateLedgerItem({ payload }) {
  try {
    const response = yield call(updateLedgerItemApi, payload)
    if (response && !response?.error) {
      yield put(updateLedgerItemSuccess(response))
      history.goBack()
      doneNotification("Ledger Item Updated Successfully")
    } else {
      yield put(updateLedgerItemFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(updateLedgerItemFail(error))
    errorNotification()
  }
}

function* onDeleteLedgerItem({ payload }) {
  try {
    const response = yield call(deleteLedgerItemApi, payload)
    if (response && !response?.error) {
      yield put(deleteLedgerItemSuccess(payload.ledgerItemId))
      doneNotification("Deleted Successfully")
      // try {
      //   const response2 = yield call(getLedgerItemsAPi, { page: 1 })
      //   yield put(getLedgerItemsSuccess(response2))
      // } catch (error) {
      //   yield put(getLedgerItemsFail(error))
      // }
    } else {
      yield put(deleteLedgerItemFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    console.log(error)
    errorNotification()
    yield put(deleteLedgerItemFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* LedgerItemsSaga() {
  yield takeEvery(GET_LEDGER_ITEMS, fetchLedgerItems)
  yield takeEvery(GET_LEDGER_ITEMS_FOR_DOWNLOAD, fetchLedgerItemsForDownload)
  yield takeEvery(GET_LEDGER_ITEM_DETAIL, fetchLedgerItemDetail)
  yield takeEvery(CREATE_LEDGER_ITEM, onCreateLedgerItem)
  yield takeEvery(UPDATE_LEDGER_ITEM, onUpdateLedgerItem)
  yield takeEvery(DELETE_LEDGER_ITEM, onDeleteLedgerItem)
}

export default LedgerItemsSaga
