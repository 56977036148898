import React from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import propTypes from "prop-types"
import { Modal, Button } from "reactstrap"
// import moment from 'moment'

import { autoGenerateStudentVarisangyas } from "store/actions"

const UiModalAutoGenerate = ({ show, onCloseclick, instituteId }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  console.log(instituteId)

  const { loading, language } = useSelector(state => ({
    loading: state.StudentVarisangyas.loading,
    language: state.Layout.language,
  }))

  // const today = moment(new Date()).format('yyyy-MM-DD')

  const handleValidSubmit = () => {
    onCloseclick()
    dispatch(autoGenerateStudentVarisangyas(instituteId))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0 fw-bold">
              {language === "mal"
                ? "വരിസംഗ്യ കാലയളവുകൾ ഓട്ടോ-ജനറേറ്റ് ചെയ്യുക "
                : "Auto Generate Varisangya List"}{" "}
              <br />
              <hr />
              <p style={{ fontSize: "14px" }} className="fw-normal">
                {language === "mal"
                  ? "താഴെയുള്ള ജനറേറ്റ് ബട്ടണിൽ ക്ലിക്ക് ചെയ്യുന്നതിലൂടെ, ഈ സാമ്പത്തിക വർഷത്തേക്കുള്ള എല്ലാ വരിസംഗ്യ കാലയളവുകളും നിങ്ങൾ സൃഷ്ടിക്കും"
                  : "By clicking the below Generate Button, you will create all varisangya periods for the present financial year."}
              </p>
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row w-100 justify-content-end">
              <Button
                color="success"
                className="w-25"
                onClick={() => handleValidSubmit()}
              >
                {loading && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </>
                )}{" "}
                {language === "mal" ? "ജനറേറ്റ്" : "Generate"}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalAutoGenerate
UiModalAutoGenerate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  instituteId: propTypes.string,
}
