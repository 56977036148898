import React, { Fragment, useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import Select from "react-select"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { AvForm } from "availity-reactstrap-validation"

import MyPagination from "components/Common/MyPagination"
//actions
import {
  getAllFamilyMembersDropdown,
  createFamilyMemberCollectionMember,
  getFamilyMemberCollectionMembers,
  getFamilyMemberCollectionDetail,
  deleteFamilyMemberCollectionMember,
  getFamilyMemberCollectionNotifications,
} from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

const CollectionDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    loading,
    memberLoading,
    notificationLoading,
    allFamilyMembers,
    language,
    familyMemberCollectionMembers,
    familyMemberCollectionDetail,
    familyMemberCollectionNotifications,
  } = useSelector(state => ({
    loading: state.Collections.loading,
    memberLoading: state.Collections.memberLoading,
    allFamilyMembers: state.FamilyMembers.allFamilyMembers,
    language: state.Layout.language,
    familyMemberCollectionMembers:
      state.Collections.familyMemberCollectionMembers,
    familyMemberCollectionDetail:
      state.Collections.familyMemberCollectionDetail,
    familyMemberCollectionNotifications:
      state.Notifications.familyMemberCollectionNotifications,
    notificationLoading: state.Notifications.loading,
  }))

  const collectionId = params?.id

  const [searchText, setSearchText] = useState("")
  const [familyMember, setFamilyMember] = useState("Select member...")
  const [familyMemberId, setFamilyMemberId] = useState()
  const [searchTextMember, setSearchTextMember] = useState("")
  const [page, setPage] = useState(1)
  const limit = 10
  const totalPages = Math.ceil(familyMemberCollectionMembers?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(
      getFamilyMemberCollectionMembers(
        searchTextMember,
        pageSend(),
        collectionId
      )
    )
  }, [dispatch, page, searchTextMember, collectionId])

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "family_member_name",
      text: language === "mal" ? "പേര്" : "Member Name",
    },
    {
      dataField: "action",
      text: language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    },
  ]

  const familyMembersData = map(
    familyMemberCollectionMembers?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,
      action: (
        <div className="m-0">
          <Button
            type="button"
            color="white"
            className=" m-0 btn "
            onClick={() => {
              handleDeleteMember(item.id)
            }}
          >
            <i
              className="bx bx-trash me-2"
              style={{ color: "red", fontSize: "18px" }}
            />
          </Button>
        </div>
      ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceFamilyMemberSearch = debounce(
    value => setSearchTextMember(value),
    600
  )

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchText))
  }, [dispatch, searchText])

  // useEffect(() => {
  //   setFamilyMember("Select member...")
  // }, [])

  const optionGroup = [
    {
      options: allFamilyMembers?.map((result, index) => ({
        key: index,
        label: `${result?.full_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        })`,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setFamilyMemberId(event.value)
    setFamilyMember(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  useEffect(() => {
    dispatch(getFamilyMemberCollectionDetail(collectionId))
  }, [dispatch, collectionId])

  // const handleDeleteEvent = () => {
  //   dispatch(deleteFamilyMemberCollection(collectionId, history))
  //   setIsOpen(false)
  // }

  const handleValidSubmit = () => {
    const data = {
      family_member: familyMemberId,
      collection: collectionId,
    }
    // console.log('Data: ', data);
    dispatch(createFamilyMemberCollectionMember(data, history))
    setFamilyMember("Select member...")
    setFamilyMemberId("")
  }

  const [isOpen, setIsOpen] = useState(false)

  // const handleDelete = () => {
  //   setIsOpen(true)
  // }

  const handleDeleteMember = id => {
    dispatch(deleteFamilyMemberCollectionMember(id))
  }

  const [searchTextNotification, setSearchTextNotification] = useState("")
  const [notificationPage, setNotificationPage] = useState(1)

  const totalNotificationPages = Math.ceil(
    familyMemberCollectionNotifications?.count / 10
  )
  const notificationPages = range(1, totalNotificationPages + 1)

  const notificationPageSend = () => {
    if (notificationPage >= notificationPages.length) {
      return notificationPages.length
    }
    if (notificationPage < 1) {
      return 1
    } else {
      return notificationPage
    }
  }

  useEffect(() => {
    dispatch(
      getFamilyMemberCollectionNotifications(
        searchTextNotification,
        notificationPageSend(),
        collectionId
      )
    )
  }, [dispatch, page, searchTextMember, collectionId])

  const debounceNotificationSearch = debounce(
    value => setSearchTextNotification(value),
    600
  )

  const notificationColumns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
      sort: true,
    },
    {
      dataField: "title",
      text: `${language === "mal" ? "ടൈറ്റിൽ" : "Title"}`,
      sort: true,
    },
    // {
    //   dataField: "sub_title",
    //   text: `${language === "mal" ? "മീറ്റിംഗ് തീയതി" : "Subtitle"}`,
    // },
    {
      dataField: "sender",
      text: `${language === "mal" ? "നിന്ന്" : "From"}`,
    },
    // {
    //   dataField: "collection_name",
    //   text: `${language === "mal" ? "ലേക്ക്" : "To"}`,
    // },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const notificationsData = map(
    familyMemberCollectionNotifications?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (notificationPageSend() - 1) * limit + index + 1,
      // meeting_date: (moment(item?.meeting_date).format("DD/MM/YYYY")),
      action: (
        <div className="m-0">
          <Link to={`/collection-notification/update/${item.id}`}>
            <Button type="button" color="success" className=" btn w-xs btn-sm">
              {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
              {language === "mal" ? "കാണുക" : "View"}
            </Button>
          </Link>
          {/* <Link to={`/institutes/${item?.id}`} className="btn-light btn-sm">
          View
        </Link> */}
        </div>
      ),
    })
  )

  const defaultSortedNotification = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRowNotification = {
    mode: "checkbox",
  }

  return (
    <>
      {/* <UiModalCollectionUpdate
        show={modalCollection}
        onCloseclick={() => setModalCollection(false)}
        history={history}
      /> */}
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteMember}
      />

      <MetaTags>
        <title>Member Collections | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "എല്ലാം" : "All"}
          breadcrumbItem={
            language === "mal" ? "അംഗങ്ങളുടെ കളക്ഷൻസ്" : "Member Collections"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md="9" lg="9">
                <Card className="">
                  <CardBody>
                    <Row>
                      <AvForm
                        className="form-horizontal "
                        onValidSubmit={(onSubmitProps, v) => {
                          handleValidSubmit(onSubmitProps, v)
                        }}
                      >
                        <div className="row mb-4">
                          <Col md="12">
                            <h3
                              className="text-khaf pt-3"
                              // style={{ fontSize: "25px" }}
                            >
                              {familyMemberCollectionDetail?.collection_name}
                            </h3>
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col md="6">
                            {/* <Label>
                              {language === "mal"
                                ? "കുടുംബാംഗം"
                                : "Member"}<span className="text-danger">&#42;</span>
                            </Label> */}
                            <Select
                              onInputChange={handleEnters}
                              placeholder={familyMember}
                              options={optionGroup}
                              classNamePrefix="select2-selection"
                              onChange={handlerFinalValue}
                              type="text"
                              title="Family Member"
                              required="required"
                            />
                          </Col>
                          <Col md="2 btn-collection">
                            <Button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              {language === "mal" ? "+ ചേർക്കുക" : "+ Add"}
                            </Button>
                          </Col>
                        </div>
                      </AvForm>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="9" lg="9">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={familyMembersData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e =>
                                      debounceFamilyMemberSearch(e.target.value)
                                    }
                                  >
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        defaultValue={searchText}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {memberLoading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : (
                            <>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table align-middle table-wrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            </>
                          )}
                          {familyMemberCollectionMembers &&
                            familyMemberCollectionMembers?.results &&
                            familyMemberCollectionMembers?.results?.length <=
                              0 && (
                              <p
                                style={{ fontSize: "15px" }}
                                className="text-center text-info"
                              >
                                {language === "mal"
                                  ? "അംഗങ്ങൾ ഒന്നും ഇല്ല"
                                  : "No Members Yet"}
                              </p>
                            )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <h5>{language === "mal" ? "അറിയിപ്പുകൾ" : "Notifications"}</h5>
            <Row>
              <Col sm="12" md="9" lg="9">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      columns={notificationColumns}
                      data={notificationsData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e =>
                                      debounceNotificationSearch(e.target.value)
                                    }
                                  >
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        defaultValue={searchTextNotification}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Col>
                            <div className="col-md-8 d-flex justify-content-end">
                              <Link
                                to={`/collection-notification/create?collectionid=${familyMemberCollectionDetail?.id}`}
                                className="w-md btn btn-success m-auto me-0 d-block"
                              >
                                {language === "mal"
                                  ? "+ പുതിയത് ചേർക്കുക"
                                  : "+ Create Notification"}
                              </Link>
                            </div>
                          </Row>
                          {notificationLoading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : (
                            <>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSortedNotification}
                                      selectRow={selectRowNotification}
                                      classes={"table align-middle table-wrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <MyPagination
                                totalPages={notificationPages}
                                page={notificationPage}
                                setPage={setNotificationPage}
                              />
                            </>
                          )}
                          {familyMemberCollectionNotifications &&
                            familyMemberCollectionNotifications?.results &&
                            familyMemberCollectionNotifications?.results
                              ?.length <= 0 && (
                              <p
                                style={{ fontSize: "15px" }}
                                className="text-center text-info"
                              >
                                {language === "mal"
                                  ? "അറിയിപ്പുകൾ ഒന്നും ഇല്ല"
                                  : "No Notifications Yet"}
                              </p>
                            )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CollectionDetails

CollectionDetails.propTypes = {
  history: PropTypes.object,
}
