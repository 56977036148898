import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import Select from "react-select"
import { Col, Modal, Button, Label } from "reactstrap"

import { updateTimetable, subjectsDropdown } from "store/actions"

const UiModalTimetableUpdate = ({ show, onCloseclick, data }) => {
  const dispatch = useDispatch()

  const { loading, language, subjectList } = useSelector(state => ({
    loading: state.Timetable.loading,
    language: state.Layout.language,
    subjectList: state.Subjects.subjectList,
  }))

  const timetableDetail = data

  const [searchTextSubject, setSearchTextSubject] = useState("")
  const [subject, setSubject] = useState("Select subject...")
  const [subjectId, setSubjectId] = useState("")

  useEffect(() => {
    setSubject(timetableDetail?.subject_name)
    setSubjectId(timetableDetail?.subject)
  }, [timetableDetail])

  const optionGroup = [
    {
      options: subjectList?.map((result, index) => ({
        key: index,
        label: result?.subject_name,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setSubjectId(event.value)
    setSubject(event.label)
  }

  const handleEnters = textEntered => {
    setSearchTextSubject(textEntered)
  }

  const handleValidSubmit = () => {
    onCloseclick()
    const updateTimetableData = {
      division: timetableDetail?.division,
      period_no: timetableDetail?.period_no,
      day: timetableDetail?.day,
      subject: subjectId,
      // description: values.description,
    }
    dispatch(updateTimetable(updateTimetableData, timetableDetail?.id))
  }

  useEffect(() => {
    dispatch(subjectsDropdown(searchTextSubject, timetableDetail?.division))
  }, [dispatch, searchTextSubject, timetableDetail?.division])

  // const handleDelete = () => {
  //   dispatch(deleteSubject(timetableDetail?.id))
  //   onCloseclick()
  // }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Update Timetable</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "വിഷയം" : "Subject Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={subject}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Subject"
                    required="required"
                  />
                </Col>
              </div>
              {/* <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>
                    {language === 'mal' ? 'അധ്യാപകൻ' : 'Teacher'}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEntersStaff}
                    placeholder={staff}
                    options={optionGroupStaff}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValueStaff}
                    type="text"
                    title="Staff"
                    required="required"
                  />
                </Col>
              </div> */}
              {/* <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    label={language === 'mal' ? 'വിവരണം' : 'Description'}
                  />
                </Col>
              </div> */}
              <div className="d-flex justify-content-end">
                {/* <Button
                  type="button"
                  color="danger"
                  className="w-25"
                  onClick={handleDelete}
                >
                  {language === 'mal' ? 'ഇല്ലാതാക്കുക' : 'Delete'}
                </Button> */}
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  // disabled={!subjectId}
                >
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalTimetableUpdate
UiModalTimetableUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.object,
  history: propTypes.object,
}
