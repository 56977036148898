import {
  GET_ASSET_ITEMS,
  GET_ASSET_ITEMS_SUCCESS,
  GET_ASSET_ITEMS_FAIL,
  GET_ASSET_ITEM_DETAIL,
  GET_ASSET_ITEM_DETAIL_SUCCESS,
  GET_ASSET_ITEM_DETAIL_FAIL,
  CREATE_ASSET_ITEM,
  CREATE_ASSET_ITEM_SUCCESS,
  CREATE_ASSET_ITEM_FAIL,
  UPDATE_ASSET_ITEM,
  UPDATE_ASSET_ITEM_SUCCESS,
  UPDATE_ASSET_ITEM_FAIL,
  DELETE_ASSET_ITEM,
  DELETE_ASSET_ITEM_SUCCESS,
  DELETE_ASSET_ITEM_FAIL,
} from "./actionTypes"

export const getAssetItems = (assetId, searchText, page) => ({
  type: GET_ASSET_ITEMS,
  payload: { assetId, searchText, page },
})
export const getAssetItemsSuccess = assetItems => ({
  type: GET_ASSET_ITEMS_SUCCESS,
  payload: assetItems,
})
export const getAssetItemsFail = error => ({
  type: GET_ASSET_ITEMS_FAIL,
  payload: error,
})

// export const getVarisangyaCards = () => ({
//   type: GET_VARISANGYA_CARDS,
// })
// export const getVarisangyaCardsSuccess = varisangyaCards => ({
//   type: GET_VARISANGYA_CARDS_SUCCESS,
//   payload: varisangyaCards,
// })
// export const getVarisangyaCardsFail = error => ({
//   type: GET_VARISANGYA_CARDS_FAIL,
//   payload: error,
// })

export const getAssetItemDetail = assetItemId => ({
  type: GET_ASSET_ITEM_DETAIL,
  assetItemId,
})

export const getAssetItemDetailSuccess = assetItemDetail => ({
  type: GET_ASSET_ITEM_DETAIL_SUCCESS,
  payload: assetItemDetail,
})

export const getAssetItemDetailFail = error => ({
  type: GET_ASSET_ITEM_DETAIL_FAIL,
  payload: error,
})

export const createAssetItem = (assetItem, history) => ({
  type: CREATE_ASSET_ITEM,
  payload: { assetItem, history },
})

export const createAssetItemSuccess = assetItem => ({
  type: CREATE_ASSET_ITEM_SUCCESS,
  payload: assetItem,
})

export const createAssetItemFail = error => ({
  type: CREATE_ASSET_ITEM_FAIL,
  payload: error,
})

export const updateAssetItem = (assetItem, assetItemId, history) => ({
  type: UPDATE_ASSET_ITEM,
  payload: { assetItem, assetItemId, history },
})

export const updateAssetItemSuccess = assetItem => ({
  type: UPDATE_ASSET_ITEM_SUCCESS,
  payload: assetItem,
})

export const updateAssetItemFail = error => ({
  type: UPDATE_ASSET_ITEM_FAIL,
  payload: error,
})

export const deleteAssetItem = (assetItemId, history) => ({
  type: DELETE_ASSET_ITEM,
  payload: { assetItemId, history },
})

export const deleteAssetItemSuccess = assetItem => ({
  type: DELETE_ASSET_ITEM_SUCCESS,
  payload: assetItem,
})

export const deleteAssetItemFail = error => ({
  type: DELETE_ASSET_ITEM_FAIL,
  payload: error,
})
