import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  Button,
  Badge,
} from "reactstrap"
// import images from "assets/images"

const CardWallet = props => {
  const { walletData, updateWalletModal } = props

  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            {/* {!walletData?.image ? (
              <div className="avatar-sm mx-auto mb-4">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft bg-" +
                    walletData?.color +
                    " text-" +
                    walletData?.color +
                    " font-size-16"
                  }
                >
                  {(walletData?.member_name === "No walletData"
                    ? walletData?.full_name
                    : walletData?.member_name
                  )?.charAt(0)}
                </span>
              </div>
            ) : (
              <div className="mb-4">
                <img
                  className="rounded-circle avatar-sm"
                  src={walletData?.image}
                  alt=""
                />
              </div>
            )} */}

            <h3 className="font-size-15 mb-1">
              {walletData?.institute_wallet_name}
            </h3>
            <Badge
              className={`${
                walletData?.is_bank ? "bg-success" : "bg-info"
              } rounded-pill`}
            >
              {walletData?.is_bank
                ? language === "mal"
                  ? "ബാങ്ക്"
                  : "Bank"
                : language === "mal"
                ? "കയ്യിൽ കാശ്"
                : "Cash in hand"}
            </Badge>
            <p className="text-muted m-0 pt-2 fs-4">
              {walletData?.balance ? walletData?.balance : 0}
            </p>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <Button
                  id={"message" + walletData?.id}
                  type="button"
                  color="white"
                  className=" btn m-0 p-0"
                  onClick={() => updateWalletModal(walletData)}
                >
                  <i
                    className="bx bx-pencil me-2"
                    style={{ color: "blue", fontSize: "18px" }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={"message" + walletData?.id}
                  >
                    {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
                  </UncontrolledTooltip>
                </Button>
                {/* <Link to="#" id={"message" + walletData?.id}>
                  <i className="bx bx-message-square-dots" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"message" + walletData?.id}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link> */}
              </div>
              <div className="flex-fill">
                <Link to={`/institute/wallet/${walletData?.id}`}>
                  <Button
                    id={"project" + walletData?.id}
                    type="button"
                    color="white"
                    className=" btn m-0 p-0"
                  >
                    <i
                      className="mdi mdi-eye me-2"
                      style={{ color: "green", fontSize: "18px" }}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={"project" + walletData?.id}
                    >
                      {language === "mal" ? "കാണുക" : "View"}
                    </UncontrolledTooltip>
                  </Button>
                </Link>
                {/* <Link to="#" id={"project" + walletData?.id}>
                  <i className="bx bx-pie-chart-alt" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"project" + walletData?.id}
                  >
                    Projects
                  </UncontrolledTooltip>
                </Link> */}
              </div>
              {/* <div className="flex-fill">
                <Link to="#" id={"profile" + walletData?.id}>
                  <i className="bx bx-walletData-circle" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"profile" + walletData?.id}
                  >
                    Profile
                  </UncontrolledTooltip>
                </Link>
              </div> */}
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardWallet.propTypes = {
  walletData: PropTypes.object,
  updateWalletModal: PropTypes.func,
}

export default CardWallet
