import {
  GET_STATES_SUCCESS,
  GET_STATES_FAIL,
  GET_STATE_DETAIL_SUCCESS,
  GET_STATE_DETAIL_FAIL,
  CREATE_STATE_SUCCESS,
  CREATE_STATE_FAIL,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAIL,
  GET_STATES,
  GET_STATE_DETAIL,
  UPDATE_STATE,
  CREATE_STATE,
  DELETE_STATE,
} from "./actionTypes"

const INIT_STATE = {
  states: [],
  stateDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdState: {},
}

const States = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATES:
    case UPDATE_STATE:
    case CREATE_STATE:
    case DELETE_STATE:
      return {
        ...state,
        loading: true,
      }
    case GET_STATE_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        loading: false,
      }

    case GET_STATES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_STATE_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdState: action.payload,
        loading: false,
      }

    case CREATE_STATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STATE_DETAIL_SUCCESS:
      return {
        ...state,
        stateDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_STATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_STATE_SUCCESS:
      return {
        ...state,
        stateDetail: action.payload,
        loading: false,
      }

    case UPDATE_STATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_STATE_SUCCESS:
      return {
        ...state,
        // states: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_STATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default States
