import React, { Fragment } from "react"
import { map } from "lodash"
import { Container, Row, Col } from "reactstrap"

//Import Components
import { useLanguage } from "../Language/SwitchLanguage"

import FeatureBox from "./feature-box"

//Import images
import featureImg1 from "assets/images/khaf/khaf-smart-sec1.jpg"
import featureImg2 from "assets/images/khaf/khaf-smart-sec2.jpg"
import featureImg3 from "assets/images/khaf/khaf-smart-sec3.jpg"
import featureImg4 from "assets/images/khaf/khaf-smart-sec4.jpg"

const Features = () => {
  const text = useLanguage()

  const fullFeaturs = featursData(text)

  return (
    <React.Fragment>
      <section className="section bg-grey" id="features">
        <Container className="myp-5">
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">{text?.features}</div>
                <h4 className="">{text?.keyfeatures}</h4>
              </div>
            </Col>
          </Row>
          {map(fullFeaturs, (feature, key) => (
            <Fragment key={key}>
              {feature?.isRtl ? (
                <Row className="align-items-center md:mb-4 pt-md-5">
                  <Col md="5">
                    <FeatureBox
                      num={feature?.num}
                      title={feature?.title}
                      features={feature?.list}
                      desc={feature?.desc}
                    />
                  </Col>
                  <Col md="6" sm="8" className="ms-md-auto">
                    <div className="mt-4 me-md-0">
                      <img
                        src={feature?.image}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="align-items-center md:flex-col md:mb-4 pt-md-5">
                  <Col md="6" sm="8">
                    <div>
                      <img
                        src={feature?.image}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                  <Col md="5" className="ms-auto">
                    <FeatureBox
                      num={feature?.num}
                      title={feature?.title}
                      features={feature?.list}
                      desc={feature?.desc}
                    />
                  </Col>
                </Row>
              )}
            </Fragment>
          ))}
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Features
function featursData(text) {
  return [
    {
      id: 1,
      image: featureImg1,
      isRtl: false,
      title: text?.surveyForm,
      desc: text?.feature1,
      num: "01",
      list: [
        { id: 1, desc: text?.feature1_list1 },
        { id: 2, desc: text?.feature1_list2 },
        { id: 3, desc: text?.feature1_list3 },
      ],
    },
    {
      id: 2,
      image: featureImg2,
      isRtl: true,
      title: text?.instituteMng,
      desc: text?.feature2,
      num: "02",
      list: [
        { id: 1, desc: text?.feature2_list1 },
        { id: 2, desc: text?.feature2_list2 },
        { id: 3, desc: text?.feature2_list3 },
      ],
    },
    {
      id: 3,
      image: featureImg3,
      isRtl: false,
      title: text?.committeeMeeting,
      desc: text?.feature3,
      num: "03",
      list: [
        { id: 1, desc: text?.feature3_list1 },
        { id: 2, desc: text?.feature3_list2 },
        { id: 3, desc: text?.feature3_list3 },
      ],
    },
    {
      id: 4,
      image: featureImg4,
      isRtl: true,
      title: text?.accountsAuditing,
      desc: text?.feature4,
      num: "04",
      list: [
        { id: 1, desc: text?.feature4_list1 },
        { id: 2, desc: text?.feature4_list2 },
      ],
    },
    // {
    //   id: 5,
    //   image: featureImg1,
    //   isRtl: false,
    //   title: text?.reportsCertificates,
    //   desc: "It will be as simple as Occidental in fact, it will be Occidental. To an English person, it will seem like simplified English, as a skeptical Cambridge friend.",
    //   num: "05",
    //   list: [
    //     { id: 1, desc: "Donec pede justo vel aliquet" },
    //     { id: 2, desc: "Aenean et nisl sagittis" },
    //   ],
    // },
  ]
}
