/* INSTITUTE_WALLETS */
export const GET_INSTITUTE_WALLETS = "GET_INSTITUTE_WALLETS"
export const GET_INSTITUTE_WALLETS_SUCCESS = "GET_INSTITUTE_WALLETS_SUCCESS"
export const GET_INSTITUTE_WALLETS_FAIL = "GET_INSTITUTE_WALLETS_FAIL"

/* INSTITUTE_WALLETS_DOWNLOAD */
export const GET_INSTITUTE_WALLETS_DOWNLOAD = "GET_INSTITUTE_WALLETS_DOWNLOAD"
export const GET_INSTITUTE_WALLETS_DOWNLOAD_SUCCESS =
  "GET_INSTITUTE_WALLETS_DOWNLOAD_SUCCESS"
export const GET_INSTITUTE_WALLETS_DOWNLOAD_FAIL =
  "GET_INSTITUTE_WALLETS_DOWNLOAD_FAIL"

/* INSTITUTE_WALLET_DROPDOWN DETAIL*/
export const GET_INSTITUTE_WALLET_DROPDOWN = "GET_INSTITUTE_WALLET_DROPDOWN"
export const GET_INSTITUTE_WALLET_DROPDOWN_SUCCESS =
  "GET_INSTITUTE_WALLET_DROPDOWN_SUCCESS"
export const GET_INSTITUTE_WALLET_DROPDOWN_FAIL =
  "GET_INSTITUTE_WALLET_DROPDOWN_FAIL"

/* INSTITUTE_WALLET DETAIL*/
export const GET_INSTITUTE_WALLET_DETAIL = "GET_INSTITUTE_WALLET_DETAIL"
export const GET_INSTITUTE_WALLET_DETAIL_SUCCESS =
  "GET_INSTITUTE_WALLET_DETAIL_SUCCESS"
export const GET_INSTITUTE_WALLET_DETAIL_FAIL =
  "GET_INSTITUTE_WALLET_DETAIL_FAIL"

/**
 * add INSTITUTE_WALLET
 */
export const CREATE_INSTITUTE_WALLET = "CREATE_INSTITUTE_WALLET"
export const CREATE_INSTITUTE_WALLET_SUCCESS = "CREATE_INSTITUTE_WALLET_SUCCESS"
export const CREATE_INSTITUTE_WALLET_FAIL = "CREATE_INSTITUTE_WALLET_FAIL"

/**
 * Edit INSTITUTE_WALLET
 */
export const UPDATE_INSTITUTE_WALLET = "UPDATE_INSTITUTE_WALLET"
export const UPDATE_INSTITUTE_WALLET_SUCCESS = "UPDATE_INSTITUTE_WALLET_SUCCESS"
export const UPDATE_INSTITUTE_WALLET_FAIL = "UPDATE_INSTITUTE_WALLET_FAIL"

/**
 * Delete INSTITUTE_WALLET
 */
export const DELETE_INSTITUTE_WALLET = "DELETE_INSTITUTE_WALLET"
export const DELETE_INSTITUTE_WALLET_SUCCESS = "DELETE_INSTITUTE_WALLET_SUCCESS"
export const DELETE_INSTITUTE_WALLET_FAIL = "DELETE_INSTITUTE_WALLET_FAIL"
