import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
//componets
//css
import "assets/scss/datatables.scss"
import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import moment from "moment"

import MyPagination from "components/Common/MyPagination"
import {
  getMasterAccountItems,
  getMasterAccountItemsForDownload,
} from "store/actions"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"

const AllAccounts = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [modalMasterAccountDownload, setModalMasterAccountDownload] =
    useState(false)
  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
  })

  const [type, setType] = useState("")

  console.log(type)

  const handleDate = () => {
    if (date?.start_date?.length >= 1 && date?.end_date?.length >= 1) {
      return date
    } else {
      return {
        start_date: "",
        end_date: "",
      }
    }
  }

  const {
    masterAccountItems,
    loading,
    language,
    masterAccountItemsForDownload,
  } = useSelector(state => ({
    masterAccountItems: state.MasterAccountItems.masterAccountItems,
    loading: state.MasterAccountItems.loading,
    language: state.Layout.language,
    dateLoading: state.MasterAccountItems.dateLoading,
    masterAccountItemsForDownload:
      state.MasterAccountItems.masterAccountItemsForDownload?.financial_years,
  }))

  //pages
  const totalPages = Math.ceil(
    masterAccountItems?.results?.financial_years?.length / 10
  )
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(
      getMasterAccountItems(searchText, pageSend(), handleDate(), true, type)
    )
  }, [dispatch, page, searchText, date, true, type])

  useEffect(() => {
    if (modalMasterAccountDownload) {
      dispatch(getMasterAccountItemsForDownload(handleDate(), true, type))
    }
  }, [dispatch, date, true, type, modalMasterAccountDownload])

  const cardData = [
    {
      title: `${language === "mal" ? "വരുമാനം" : "Income"}`,
      cardValue: `${masterAccountItems?.results?.total_income ?? 0}`,
      className: "text-success",
      iconClass: "downvote",
    },
    {
      title: `${language === "mal" ? "ചെലവ്" : "Expense"}`,
      cardValue: `${masterAccountItems?.results?.total_expense ?? 0}`,
      className: "text-danger",
      iconClass: "upvote",
    },
    {
      title: `${language === "mal" ? "ബാലൻസ്" : "Balance"}`,
      cardValue: `${masterAccountItems?.results?.total_balance ?? 0}`,
      className: "text-info",
      iconClass: "list-check",
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
      sort: true,
    },
    {
      dataField: "institute_ledger__institute__institute_english_name",
      text: `${language === "mal" ? "ഇൻസ്റ്റിറ്റ്യൂട്ട് തരം" : "Institute"}`,
      sort: true,
    },
    {
      dataField: "total_income",
      text: `${language === "mal" ? "വരവ്" : "Income"}`,
    },
    {
      dataField: "total_expense",
      text: `${language === "mal" ? "ചിലവ്" : "Expense"}`,
    },
    {
      dataField: "balance",
      text: `${language === "mal" ? "ബാക്കി" : "Balance"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const ledgerItemsData = map(
    masterAccountItems?.results?.financial_years,
    (item, index) => ({
      ...item,
      key: index,
      no: index + 1,
      institute_ledger__institute__institute_english_name: (
        <div>
          {language === "mal"
            ? item?.institute_ledger__institute__institute_malayalam_name
              ? item?.institute_ledger__institute__institute_malayalam_name
              : item?.institute_ledger__institute__institute_english_name
            : item?.institute_ledger__institute__institute_english_name}
          <Badge
            className={
              "mx-3 font-size-12 badge-soft-" +
              `${
                item.institute_ledger__institute__institute_type == "institute"
                  ? "primary"
                  : item.institute_ledger__institute__institute_type ==
                    "program"
                  ? "warning"
                  : item.institute_ledger__institute__institute_type == "mahall"
                  ? "success"
                  : ""
              }`
            }
            pill
          >
            {item.institute_ledger__institute__institute_type}
          </Badge>
        </div>
      ),
      total_income: <p className="text-success">{item.total_income}</p>,
      total_expense: <p className="text-danger">{item.total_expense}</p>,
      balance: <p className="text-dark">{item.balance}</p>,
      action: (
        <div>
          {item?.institute_ledger__institute__institute_type === "institute" ? (
            <Link
              to={`/institutes/${item?.institute_ledger__institute__id}`}
              className="btn-success btn-sm"
            >
              {language === "mal" ? "കാണുക" : "View"}
            </Link>
          ) : item?.institute_ledger__institute__institute_type ===
            "program" ? (
            <Link
              to={`/programes/${item?.institute_ledger__institute__id}`}
              className="btn-success btn-sm"
            >
              {language === "mal" ? "കാണുക" : "View"}
            </Link>
          ) : (
            <Link to={`/mahallmasters`} className="btn-success btn-sm">
              {language === "mal" ? "കാണുക" : "View"}
            </Link>
          )}
        </div>
      ),
    })
  )

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    {
      label: `${
        language === "mal" ? "ലെഡ്ജർ ഇനത്തിന്റെ  പേര്" : "Ledger Item Name"
      }`,
      key: "ledger_item_name",
    },
    {
      label: `${language === "mal" ? "തരം" : "Institute Type"}`,
      key: "institute_type",
    },
    {
      label: `${language === "mal" ? "ഓപ്പണിങ് ബാലൻസ്" : "Opening Balance"}`,
      key: "opening_balance",
    },
    { label: `${language === "mal" ? "വരവ്" : "Income"}`, key: "total_income" },
    {
      label: `${language === "mal" ? "ചിലവ്" : "Expense"}`,
      key: "total_expense",
    },
    { label: `${language === "mal" ? "ബാക്കി" : "Balance"}`, key: "balance" },
  ]

  const masterAccountsExcelData = map(
    masterAccountItemsForDownload,
    (item, index) => ({
      ...item,
      no: index + 1,
      ledger_item_name:
        language === "mal"
          ? item.institute_ledger__institute__institute_malayalam_name
            ? item.institute_ledger__institute__institute_malayalam_name
            : item.institute_ledger__institute__institute_english_name
          : item.institute_ledger__institute__institute_english_name,
      date: item.date && moment(item?.date).format("DD/MM/YYYY"),
      institute_type: item.institute_ledger__institute__institute_type,
      opening_balance: item.opening_balance,
      total_income: item.total_income,
      total_expense: item.total_expense,
      balance: item.balance,
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const fileName = "khaf-smart-institute-accounts-list.csv"

  const instituteTypes = [
    {
      label: `${language === "mal" ? "എല്ലാം" : "All"}`,
      id: "btnradio1",
      instituteType: "",
    },
    {
      label: `${language === "mal" ? "മഹല്ല്" : "Mahall"}`,
      id: "btnradio2",
      instituteType: "mahall",
    },
    {
      label: `${language === "mal" ? "ഇൻസ്റ്റിറ്റ്യൂട്ട്" : "Institute"}`,
      id: "btnradio3",
      instituteType: "institute",
    },
    {
      label: `${language === "mal" ? "പ്രോഗ്രാം" : "Program"}`,
      id: "btnradio4",
      instituteType: "program",
    },
    {
      label: `${language === "mal" ? "മദ്രസ" : "Madrasa"}`,
      id: "btnradio5",
      instituteType: "madrasa",
    },
  ]

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalMasterAccountDownload}
        onCloseclick={() => setModalMasterAccountDownload(false)}
        excelData={masterAccountsExcelData}
        headers={headers}
        list={masterAccountItemsForDownload}
        fileName={fileName}
        loading={loading}
      />
      <>
        <Row>
          {cardData?.map((item, key) => (
            <Col sm="12" md="6" lg="4" key={key} className="sm:p-0">
              <Card className="blog-stats-wid">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <h5 className="text-muted">{item.title}</h5>
                      <h4 className={`${item.className}`}>{item.cardValue}</h4>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i
                          className={
                            "bx bx-" + `${item.iconClass} ${item.className}`
                          }
                        ></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    {/* <Col md="4">
                      <div
                        className="btn-group btn-group-certificate mt-2 mt-xl-0"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        {instituteTypes?.map((item, key) => (
                          <Fragment key={key}>
                            <input
                              type="radio"
                              className="btn-check"
                              name="btnradio"
                              id={item?.id}
                              autoComplete="off"
                              defaultChecked={item?.id === 'btnradio1'}
                              onChange={() => setType(item?.instituteType)}
                            />
                            <label
                              className="btn btn-success"
                              htmlFor={item?.id}
                            >
                              {item?.label}
                            </label>
                          </Fragment>
                        ))}
                      </div>
                    </Col> */}
                    <Col md="4" className="">
                      <h5>From :</h5>
                      <AvField
                        type="date"
                        name="start_date"
                        value={date?.start_date}
                        onChange={e =>
                          setDate({
                            ...date,
                            start_date: moment(e.target.value).format(
                              "yyyy-MM-DD"
                            ),
                          })
                        }
                      />
                      <button
                        onClick={() =>
                          setDate({
                            ...date,
                            start_date: "",
                          })
                        }
                        type="button"
                        className="btn btn-white editable-cancel date_close btn-sm"
                      >
                        <i className="mdi mdi-close text-danger "></i>
                      </button>
                    </Col>
                    <Col md="4" className="">
                      <h5>To :</h5>
                      <AvField
                        type="date"
                        name="end_date"
                        value={date?.end_date}
                        onChange={e =>
                          setDate({
                            ...date,
                            end_date: moment(e.target.value).format(
                              "yyyy-MM-DD"
                            ),
                          })
                        }
                      />
                      <button
                        onClick={() =>
                          setDate({
                            ...date,
                            end_date: "",
                          })
                        }
                        type="button"
                        className="btn btn-white editable-cancel date_close btn-sm"
                      >
                        <i className="mdi mdi-close text-danger "></i>
                      </button>
                    </Col>
                    {/* <Col>
                    <Button className="btn btn-md w-md bg-khaf-blue btn-date mt-4">View</Button>
                  </Col> */}
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={ledgerItemsData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <form
                                className="app-search d-lg-block"
                                onChange={e => handleSearch(e)}
                              >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={
                                      language === "mal"
                                        ? "തിരയുക..."
                                        : "Search..."
                                    }
                                    defaultValue={searchText}
                                  />
                                  <span className="bx bx-search-alt" />
                                </div>
                              </form>
                            </div>
                          </div>
                        </Col>
                        <Col md="4">
                          <div
                            className="btn-group d-flex justify-content-center mt-2 mt-xl-0"
                            role="group"
                            aria-label="Basic radio toggle button group"
                          >
                            {instituteTypes?.map((item, key) => (
                              <Fragment key={key}>
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id={item?.id}
                                  autoComplete="off"
                                  defaultChecked={item?.id === "btnradio1"}
                                  onChange={() => setType(item?.instituteType)}
                                />
                                <label
                                  className="btn btn-success"
                                  htmlFor={item?.id}
                                >
                                  {item?.label}
                                </label>
                              </Fragment>
                            ))}
                          </div>
                        </Col>
                        <Col md="4">
                          <Button
                            style={{
                              width: language === "mal" ? "150px" : "130px",
                              border: "bold",
                            }}
                            className="btn-md me-4 mt-1 d-block m-auto bg-white text-khaf"
                            onClick={() => setModalMasterAccountDownload(true)}
                          >
                            <i
                              className="bx bx-download mx-1"
                              style={{ fontSize: "18px", color: "#009846" }}
                            />
                            {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                          </Button>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={"table align-middle table-wrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      )}
                      {masterAccountItems &&
                        masterAccountItems?.results &&
                        masterAccountItems?.results?.financial_years?.length <=
                          0 && (
                          <p
                            style={{ fontSize: "15px" }}
                            className="text-center text-info"
                          >
                            {language === "mal"
                              ? "അക്കൗണ്ടുകൾ ഒന്നും ഇല്ല"
                              : "No Accounts Yet"}
                          </p>
                        )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    </React.Fragment>
  )
}

export default AllAccounts
