import {
  GET_ACTIVITY_LOGS,
  GET_ACTIVITY_LOGS_SUCCESS,
  GET_ACTIVITY_LOGS_FAIL,
  GET_ACTIVITY_LOG_DETAIL,
  GET_ACTIVITY_LOG_DETAIL_SUCCESS,
  GET_ACTIVITY_LOG_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  activityLogs: [],
  activityLogDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
}

const ActivityLogs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACTIVITY_LOGS:
      return {
        ...state,
        loading: true,
      }
    case GET_ACTIVITY_LOG_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_ACTIVITY_LOGS_SUCCESS:
      return {
        ...state,
        activityLogs: action.payload,
        loading: false,
      }

    case GET_ACTIVITY_LOGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ACTIVITY_LOG_DETAIL_SUCCESS:
      return {
        ...state,
        activityLogDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_ACTIVITY_LOG_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    default:
      return state
  }
}

export default ActivityLogs
