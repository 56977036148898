import { useLocation } from "react-router-dom"

import EngData from "./engWords.json"
import MlmData from "./mlmWords.json"

function SwitchLanguage() {
  const path = useLocation()

  if (path?.pathname === "/home/en") {
    return "en"
  }
  if (path?.pathname === "/home/ml") {
    return "ml"
  }
  return "en"
}

export default SwitchLanguage

export function useLanguage() {
  if (SwitchLanguage() === "en") return EngData
  if (SwitchLanguage() === "ml") return MlmData
  return EngData
}
