import React, { useEffect } from "react"
import { Badge, Card, CardBody, Container, Modal } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { adminDonationDetails } from "store/actions"
import propTypes from "prop-types"
import moment from "moment"

function DonationDetails({ show, onCloseClick, Data }) {
  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  const handleStatus = status => {
    switch (status) {
      case "Pending":
      case "Processing":
      case "created":
        return "info"
      case "cancelled":
      case "late_authorized":
        return "warning"
      case "failed":
        return "danger"
      case "success":
      case "captured":
        return "success"
      default:
        return "info"
    }
  }

  return (
    <div>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <div className="modal-header d-flex align-items-center justify-content-between ">
          <div>
            <h5 className="modal-title mt-0">
              {" "}
              {language === "mal" ? "സംഭാവന വിശദാംശങ്ങൾ" : "Donation Details"}
            </h5>
          </div>
          <div className="">
            <button
              type="button"
              onClick={onCloseClick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="d-flex align-items-center justify-content-start py-2">
            <Badge
              className={
                "px-4 py-1 badge-soft-" +
                `${handleStatus(Data?.payment_status)}`
              }
            >
              {" "}
              {Data?.payment_status}{" "}
            </Badge>
          </div>
          <div className="d-flex justify-content-between">
            <div className="">
              <p className="">
                {language === "mal" ? "പേര്" : "Name"} :{" "}
                <p className="text-dark font-size-15">{Data?.name}</p>
              </p>
              <p className="">
                {language === "mal" ? "മെയിൽ" : "Email"} :{" "}
                <p className="text-dark font-size-15">{Data?.email}</p>
              </p>{" "}
              <p className="">
                {language === "mal" ? "മൊബൈൽ നമ്പർ" : "                Phone "}{" "}
                :{" "}
                <p className="text-dark font-size-15">{Data?.contact_number}</p>
              </p>
            </div>
            <div className="px-3">
              <p className="">
                {language === "mal" ? "തുക" : "Amount"} :{" "}
                <p className="text-dark font-size-15">{Data?.amount}</p>
              </p>{" "}
              <p className="">
                {language === "mal" ? "തീയതി" : "Date"} :{" "}
                <p className="text-dark font-size-15">
                  {" "}
                  {moment(Data?.date_added).format("DD/MM/YYYY - h:mm A")}
                </p>
              </p>
              <p className="">
                {language === "mal" ? "പരാമർശം" : "Remark"} :{" "}
                <p className="text-dark font-size-15">{Data?.remark}</p>
              </p>{" "}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DonationDetails

DonationDetails.propTypes = {
  onCloseClick: propTypes.func,
  show: propTypes.bool,
  Data: propTypes.object,
}

// const deleteModalStyle = {
//   Width: 400,
// }

// const deleteModalBodyStyle = {
//   minWidth:400
// }
