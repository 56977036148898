import {
  GET_ALL_MADRASA_LISTS,
  GET_ALL_MADRASA_LISTS_SUCCESS,
  GET_ALL_MADRASA_LISTS_FAIL,
  GET_MADRASA_DROPDOWN,
  GET_MADRASA_DROPDOWN_SUCCESS,
  GET_MADRASA_DROPDOWN_FAIL,
  GET_MADRASA_DETAIL,
  GET_MADRASA_DETAIL_SUCCESS,
  GET_MADRASA_DETAIL_FAIL,
  CREATE_MADRASA,
  CREATE_MADRASA_SUCCESS,
  CREATE_MADRASA_FAIL,
  UPDATE_MADRASA,
  UPDATE_MADRASA_SUCCESS,
  UPDATE_MADRASA_FAIL,
  DELETE_MADRASA,
  DELETE_MADRASA_SUCCESS,
  DELETE_MADRASA_FAIL,
} from "./actionTypes"

export const getAllMadrasaLists = () => ({
  type: GET_ALL_MADRASA_LISTS,
})

export const getAllMadrasaListsSuccess = allMadrasaLists => ({
  type: GET_ALL_MADRASA_LISTS_SUCCESS,
  payload: allMadrasaLists,
})
export const getAllMadrasaListsFail = error => ({
  type: GET_ALL_MADRASA_LISTS_FAIL,
  payload: error,
})

export const getMadrasaDropdown = searchText => ({
  type: GET_MADRASA_DROPDOWN,
  payload: searchText,
})

export const getMadrasaDropdownSuccess = allMadrasaLists => ({
  type: GET_MADRASA_DROPDOWN_SUCCESS,
  payload: allMadrasaLists,
})
export const getMadrasaDropdownFail = error => ({
  type: GET_MADRASA_DROPDOWN_FAIL,
  payload: error,
})

export const getMadrasaDetail = madrasaId => ({
  type: GET_MADRASA_DETAIL,
  madrasaId,
})

export const getMadrasaDetailSuccess = madrasaDetail => ({
  type: GET_MADRASA_DETAIL_SUCCESS,
  payload: madrasaDetail,
})

export const getMadrasaDetailFail = error => ({
  type: GET_MADRASA_DETAIL_FAIL,
  payload: error,
})

export const createMadrasa = (madrasa, history) => ({
  type: CREATE_MADRASA,
  payload: { madrasa, history },
})

export const createMadrasaSuccess = madrasa => ({
  type: CREATE_MADRASA_SUCCESS,
  payload: madrasa,
})

export const createMadrasaFail = error => ({
  type: CREATE_MADRASA_FAIL,
  payload: error,
})

export const updateMadrasa = (madrasa, madrasaId, history) => ({
  type: UPDATE_MADRASA,
  payload: { madrasa, madrasaId, history },
})

export const updateMadrasaSuccess = madrasa => ({
  type: UPDATE_MADRASA_SUCCESS,
  payload: madrasa,
})

export const updateMadrasaFail = error => ({
  type: UPDATE_MADRASA_FAIL,
  payload: error,
})

export const deleteMadrasa = (madrasaId, history) => ({
  type: DELETE_MADRASA,
  payload: { madrasaId, history },
})

export const deleteMadrasaSuccess = madrasa => ({
  type: DELETE_MADRASA_SUCCESS,
  payload: madrasa,
})

export const deleteMadrasaFail = error => ({
  type: DELETE_MADRASA_FAIL,
  payload: error,
})
