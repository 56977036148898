import {
  GET_STAFFS,
  GET_STAFFS_SUCCESS,
  GET_STAFFS_FAIL,
  GET_STAFF_DETAIL,
  GET_STAFF_DETAIL_SUCCESS,
  GET_STAFF_DETAIL_FAIL,
  CREATE_STAFF,
  CREATE_STAFF_SUCCESS,
  CREATE_STAFF_FAIL,
  UPDATE_STAFF,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_FAIL,
  DELETE_STAFF,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL,
  GET_STAFF_DROPDOWN,
  GET_STAFF_DROPDOWN_SUCCESS,
  GET_STAFF_DROPDOWN_FAIL,
} from "./actionTypes"

export const getStaffs = (searchText, page, instituteType) => ({
  type: GET_STAFFS,
  payload: { searchText, page, instituteType },
})
export const getStaffsSuccess = staffs => ({
  type: GET_STAFFS_SUCCESS,
  payload: staffs,
})
export const getStaffsFail = error => ({
  type: GET_STAFFS_FAIL,
  payload: error,
})

export const getStaffDropdown = (searchText, instituteId) => ({
  type: GET_STAFF_DROPDOWN,
  payload: { searchText, instituteId },
})
export const getStaffDropdownSuccess = staffList => ({
  type: GET_STAFF_DROPDOWN_SUCCESS,
  payload: staffList,
})
export const getStaffDropdownFail = error => ({
  type: GET_STAFF_DROPDOWN_FAIL,
  payload: error,
})

export const getStaffDetail = staffId => ({
  type: GET_STAFF_DETAIL,
  staffId,
})

export const getStaffDetailSuccess = staffDetail => ({
  type: GET_STAFF_DETAIL_SUCCESS,
  payload: staffDetail,
})

export const getStaffDetailFail = error => ({
  type: GET_STAFF_DETAIL_FAIL,
  payload: error,
})

export const createStaff = (newStaff, history) => ({
  type: CREATE_STAFF,
  payload: { newStaff, history },
})

export const createStaffSuccess = newStaff => ({
  type: CREATE_STAFF_SUCCESS,
  payload: newStaff,
})

export const createStaffFail = error => ({
  type: CREATE_STAFF_FAIL,
  payload: error,
})

export const updateStaff = (updatedStaff, staffId, history) => ({
  type: UPDATE_STAFF,
  payload: { updatedStaff, staffId, history },
})

export const updateStaffSuccess = updatedStaff => ({
  type: UPDATE_STAFF_SUCCESS,
  payload: updatedStaff,
})

export const updateStaffFail = error => ({
  type: UPDATE_STAFF_FAIL,
  payload: error,
})

export const deleteStaff = (staffId, history) => ({
  type: DELETE_STAFF,
  payload: { staffId, history },
})

export const deleteStaffSuccess = newStaff => ({
  type: DELETE_STAFF_SUCCESS,
  payload: newStaff,
})

export const deleteStaffFail = error => ({
  type: DELETE_STAFF_FAIL,
  payload: error,
})
