import {
  GET_ALL_MADRASA_LISTS_SUCCESS,
  GET_ALL_MADRASA_LISTS_FAIL,
  GET_MADRASA_DETAIL_SUCCESS,
  GET_MADRASA_DETAIL_FAIL,
  GET_MADRASA_DROPDOWN_SUCCESS,
  GET_MADRASA_DROPDOWN_FAIL,
  CREATE_MADRASA_SUCCESS,
  CREATE_MADRASA_FAIL,
  UPDATE_MADRASA_SUCCESS,
  UPDATE_MADRASA_FAIL,
  DELETE_MADRASA_SUCCESS,
  DELETE_MADRASA_FAIL,
  GET_ALL_MADRASA_LISTS,
  GET_MADRASA_DETAIL,
  GET_MADRASA_DROPDOWN,
  UPDATE_MADRASA,
  CREATE_MADRASA,
  DELETE_MADRASA,
} from "./actionTypes"

const INIT_STATE = {
  madrasaLists: [],
  madrasaDetail: {},
  searchAllMadrasaLists: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdMadrasa: {},
}

const MadrasaLists = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_MADRASA_LISTS:
    case UPDATE_MADRASA:
    case CREATE_MADRASA:
    case DELETE_MADRASA:
    case GET_MADRASA_DROPDOWN:
      return {
        ...state,
        loading: true,
      }
    case GET_MADRASA_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_ALL_MADRASA_LISTS_SUCCESS:
      return {
        ...state,
        madrasaLists: action.payload,
        loading: false,
      }

    case GET_ALL_MADRASA_LISTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_MADRASA_SUCCESS:
      return {
        ...state,
        // madrasaLists: action.payload,
        createdLsg: action.payload,
        loading: false,
      }

    case CREATE_MADRASA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MADRASA_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchAllMadrasaLists: action.payload,
        loading: false,
      }

    case GET_MADRASA_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MADRASA_DETAIL_SUCCESS:
      return {
        ...state,
        madrasaDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_MADRASA_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_MADRASA_SUCCESS:
      return {
        ...state,
        madrasaDetail: action.payload,
        loading: false,
      }

    case UPDATE_MADRASA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_MADRASA_SUCCESS:
      return {
        ...state,
        // madrasaLists: state.madrasaLists.filter(
        //   madrasa => madrasa.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_MADRASA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default MadrasaLists
