import {
  GET_ASSET_ITEMS,
  UPDATE_ASSET_ITEM,
  CREATE_ASSET_ITEM,
  DELETE_ASSET_ITEM,
  GET_ASSET_ITEM_DETAIL,
  GET_ASSET_ITEMS_SUCCESS,
  GET_ASSET_ITEMS_FAIL,
  CREATE_ASSET_ITEM_SUCCESS,
  CREATE_ASSET_ITEM_FAIL,
  GET_ASSET_ITEM_DETAIL_SUCCESS,
  GET_ASSET_ITEM_DETAIL_FAIL,
  UPDATE_ASSET_ITEM_SUCCESS,
  UPDATE_ASSET_ITEM_FAIL,
  DELETE_ASSET_ITEM_SUCCESS,
  DELETE_ASSET_ITEM_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  assetItems: [],
  assetItemDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdAssetItem: {},
}

const AssetItems = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case GET_VARISANGYA_CARDS:
    case GET_ASSET_ITEMS:
    case UPDATE_ASSET_ITEM:
    case CREATE_ASSET_ITEM:
    case DELETE_ASSET_ITEM:
      return {
        ...state,
        loading: true,
      }
    case GET_ASSET_ITEM_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_ASSET_ITEMS_SUCCESS:
      return {
        ...state,
        assetItems: action.payload,
        loading: false,
      }
    case GET_ASSET_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // case GET_VARISANGYA_CARDS_SUCCESS:
    //   return {
    //     ...state,
    //     varisangyaCards: action.payload,
    //     loading: false,
    //   }
    // case GET_VARISANGYA_CARDS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     loading: false,
    //   }

    case CREATE_ASSET_ITEM_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdAssetItem: action.payload,
        loading: false,
      }

    case CREATE_ASSET_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ASSET_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        assetItemDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_ASSET_ITEM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_ASSET_ITEM_SUCCESS:
      return {
        ...state,
        assetItemDetail: action.payload,
        loading: false,
      }

    case UPDATE_ASSET_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_ASSET_ITEM_SUCCESS:
      return {
        ...state,
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        loading: false,
      }

    case DELETE_ASSET_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default AssetItems
