import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import { Link } from "react-router-dom"
//actions
import { map } from "lodash"
import axios from "axios"
import { Image } from "react-bootstrap"

import {
  getFamilyDetail,
  getMahallTypeLists,
  getUserDetail,
  updateFamily,
  getStates,
  getDistrictDropdown,
  getVillageDropdown,
  getLsgDropdown,
  updateFamilySuccess,
  updateFamilyFail,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import { API_URL } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import HeaderLine from "components/Common/HeaderLine"
import { useFileSizes } from "hooks/useFileSize"
import { handleSelectOrDeselect } from "hooks/useSelectOrDeselect"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UpdateMahallFamily = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    familyDetail,
    mahallTypeLists,
    userDetail,
    States,
    searchDistricts,
    searchVillages,
    searchLsgs,
    language,
    isUpdated,
    mahallId,
    loading,
  } = useSelector(state => ({
    isUpdated: state.Families.isUpdated,
    familyDetail: state.Families.familyDetail,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    userDetail: state.Users.userDetail,
    mahallDetail: state.Mahalls.mahallDetail,
    States: state.States.states,
    searchDistricts: state.Districts.searchDistricts,
    searchVillages: state.Villages.searchVillages,
    searchLsgs: state.Lsgs.searchLsgs,
    language: state.Layout.language,
    mahallId: state.Users.userDetail?.mahall?.id,
    loading: state.Families.loading,
  }))

  const [activeTab, setactiveTab] = useState(1)
  // const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  // const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  // function toggleTabVertical(tab) {
  //   if (activeTabVartical !== tab) {
  //     var modifiedSteps = [...passedStepsVertical, tab]

  //     if (tab >= 1 && tab <= 4) {
  //       setoggleTabVertical(tab)
  //       setPassedStepsVertical(modifiedSteps)
  //     }
  //   }
  // }

  const [isZakatEligibleSwitch, setIsZakatEligibleSwitch] = useState(
    familyDetail?.is_zakat_eligible
  )

  const [helpFromMahallSwitch, setHelpFromMahallSwitch] = useState(
    familyDetail?.help_receiving_from_mahall
  )
  const [helpFromOthersSwitch, setHelpFromOthersSwitch] = useState(
    familyDetail?.help_receiving_from_others
  )

  const [state, setState] = useState(
    language === "mal"
      ? familyDetail?.state_malayalam_name
      : familyDetail?.state_english_name
  )
  const [stateId, setStateId] = useState("")
  const [district, setDistrict] = useState(
    language === "mal"
      ? familyDetail?.district_malayalam_name
      : familyDetail?.district_english_name
  )
  const [districtId, setDistrictId] = useState("")
  const [village, setVillage] = useState(
    language === "mal"
      ? familyDetail?.village_malayalam_name
      : familyDetail?.village_english_name
  )
  const [villageId, setVillageId] = useState("")
  const [lsg, setLsg] = useState(
    language === "mal"
      ? familyDetail?.lsg_malayalam_name
      : familyDetail?.lsg_english_name
  )
  const [lsgId, setLsgId] = useState("")
  const [searchTextState, setSearchTextState] = useState("")
  const [searchTextDistrict, setSearchTextDistrict] = useState("")
  const [searchTextVillage, setSearchTextVillage] = useState("")
  const [searchTextLsg, setSearchTextLsg] = useState("")

  const [postOffice, setPostOffice] = useState(familyDetail?.post_office)
  const [pinCode, setPinCode] = useState(familyDetail?.pin_code)

  // const [lsgType, setLsgType] = useState('')
  const [rationCardCategory, setRationCardCategory] = useState(
    familyDetail?.ration_card_category_choice_id
  )
  const [houseType, setHouseType] = useState(familyDetail?.house_type_choice_id)
  const [houseBuildType, setHouseBuildType] = useState(
    familyDetail?.house_build_type_choice_id
  )
  const [waterFacility, setWaterFacility] = useState(
    familyDetail?.water_facility_choice_id
  )
  const [electricFacility, setElectricFacility] = useState(
    familyDetail?.electric_facility_choice_id
  )
  const [financialStatus, setFinancialStatus] = useState(
    familyDetail?.financial_status_choice_id
      ? familyDetail?.financial_status_choice_id
      : ""
  )
  const [bankLoan, setBankLoan] = useState([
    familyDetail?.bank_loan_choice_id_array,
  ])
  const [homeBusiness, setHomeBusiness] = useState([
    familyDetail?.home_business_choice_id_array,
  ])

  const [yearsTillDate, setYearsTillDate] = useState(0)
  const [yearOfResidence, setYearOfResidence] = useState()

  // const [home, setHome] = useState('')
  // const [farm, setFarm] = useState('')
  // const [rent, setRent] = useState('')

  // const [twoWheeler, setTwoWheeler] = useState('')
  // const [threeWheeler, setThreeWheeler] = useState('')
  // const [fourWheeler, setFourWheeler] = useState('')

  const [houseName, setHouseName] = useState()

  const [imagePreview, setImagePreview] = useState()

  useEffect(() => {
    if (mahallId) {
      dispatch(getMahallTypeLists(mahallId))
    }
  }, [dispatch, mahallId])

  useEffect(() => {
    if (!userDetail) dispatch(getUserDetail())
  }, [dispatch, userDetail])

  console.log(familyDetail)
  useEffect(() => {
    setState(
      language === "mal"
        ? familyDetail?.state_malayalam_name
        : familyDetail?.state_english_name
    )
    setStateId(familyDetail?.state)
    setDistrict(
      language === "mal"
        ? familyDetail?.district_malayalam_name
        : familyDetail?.district_english_name
    )
    setDistrictId(familyDetail?.district)
    setLsg(
      language === "mal"
        ? familyDetail?.lsg_malayalam_name
        : familyDetail?.lsg_english_name
    )
    setLsgId(familyDetail?.lsg)
    setVillage(
      language === "mal"
        ? familyDetail?.village_malayalam_name
        : familyDetail?.village_english_name
    )
    setVillageId(familyDetail?.village)
    setHouseName(familyDetail?.house_name)
  }, [familyDetail])

  useEffect(() => {
    setPinCode(familyDetail?.pin_code)
    setPostOffice(familyDetail?.post_office)
  }, [familyDetail])

  useEffect(() => {
    dispatch(getStates(searchTextState))
  }, [searchTextState])

  useEffect(() => {
    dispatch(getDistrictDropdown(searchTextDistrict))
  }, [searchTextDistrict])

  useEffect(() => {
    dispatch(getVillageDropdown(searchTextVillage))
  }, [searchTextVillage])

  useEffect(() => {
    dispatch(getLsgDropdown(searchTextLsg))
  }, [searchTextLsg])

  const changeYearsTillDate = year => {
    const thisYear = new Date().getFullYear()
    const difference = thisYear - year
    setYearsTillDate(difference)
  }

  useEffect(() => {
    dispatch(getFamilyDetail(params.id))
  }, [])

  function handlerFinalValueState(event) {
    // dispatch(getStates(event.value))
    setStateId(event.value)
    setState(event.label)
  }

  function handlerFinalValueDistrict(event) {
    dispatch(getDistrictDropdown(event.value))
    setDistrictId(event.value)
    setDistrict(event.label)
  }

  function handlerFinalValueVillage(event) {
    dispatch(getVillageDropdown(event.value))
    setVillageId(event.value)
    setVillage(event.label)
  }

  function handlerFinalValueLsg(event) {
    dispatch(getLsgDropdown(event.value))
    setLsgId(event.value)
    setLsg(event.label)
  }

  const optionGroupState = [
    {
      options: States?.results?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results.state_malayalam_name
              ? results.state_malayalam_name
              : results.state_english_name
            : results.state_english_name,
        value: results.id,
      })),
    },
  ]

  const optionGroupDistrict = [
    {
      options: searchDistricts?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results.district_malayalam_name
              ? results.district_malayalam_name
              : results.district_english_name
            : results.district_english_name,
        value: results.id,
      })),
    },
  ]

  const optionGroupVillage = [
    {
      options: searchVillages?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results?.village_malayalam_name
              ? results?.village_malayalam_name
              : results?.village_english_name
            : results?.village_english_name,
        value: results.id,
      })),
    },
  ]

  const optionGroupLsg = [
    {
      options: searchLsgs?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results.lsg_malayalam_name
              ? results.lsg_malayalam_name
              : results.lsg_english_name
            : results.lsg_english_name,
        value: results.id,
      })),
    },
  ]

  useEffect(() => {
    setRationCardCategory(familyDetail?.ration_card_category_choice_id)
    setHouseType(familyDetail?.house_type_choice_id)
    setHouseBuildType(familyDetail?.house_build_type_choice_id)
    setWaterFacility(familyDetail?.water_facility_choice_id)
    setElectricFacility(familyDetail?.electric_facility_choice_id)
    setFinancialStatus(familyDetail?.financial_status_choice_id)
    setBankLoan(familyDetail?.bank_loan_choice_id_array)
    setHomeBusiness(familyDetail?.home_business_choice_id_array)
    setHelpFromMahallSwitch(familyDetail?.help_receiving_from_mahall)
    setHelpFromOthersSwitch(familyDetail?.help_receiving_from_others)
    changeYearsTillDate(familyDetail?.year_of_residence)
    setYearOfResidence(familyDetail?.year_of_residence)
  }, [familyDetail])

  const handleValidSubmit = values => {
    const totalArea =
      Number(values.land_area_home) +
      Number(values.land_area_farm) +
      Number(values.land_area_rent) +
      Number(values.land_area_others)
    const totalVehicles =
      Number(values.two_wheeler_vehicles) +
      Number(values.three_wheeler_vehicles) +
      Number(values.four_wheeler_vehicles)
    const updateFamilyData = {
      mahall: familyDetail?.mahall,
      mahall_custom_id: values.mahall_custom_id
        ? values.mahall_custom_id
        : null,
      varisangya_grade: values.varisangya_grade,
      house_name: values.house_name ? values.house_name : null,
      contact_number: values.contact_number ? values.contact_number : null,
      ward_number: values.ward_number ? values.ward_number : null,
      house_number: values.house_number ? values.house_number : null,
      area_choice_id:
        values.area_choice_id === "Select an area..." ||
        values.area_choice_id === "ഏരിയ തിരഞ്ഞെടുക്കുക ..."
          ? null
          : values.area_choice_id,
      family_varisankya_grade_choice_id:
        values.family_varisankya_grade_choice_id === "Select an grade..." ||
        values.family_varisankya_grade_choice_id === "ഗ്രേഡ് തിരഞ്ഞെടുക്കുക ..."
          ? null
          : values.family_varisankya_grade_choice_id,
      place: values.place ? values.place : null,
      via: values.via ? values.via : null,

      district: districtId ? districtId : null,
      state: stateId ? stateId : null,
      lsg: lsgId ? lsgId : null,
      village: villageId ? villageId : null,
      pin_code: pinCode ? pinCode : null,
      post_office: postOffice ? postOffice : null,

      year_of_residence: yearOfResidence ? yearOfResidence : null,
      ration_card_category_choice_id: rationCardCategory
        ? rationCardCategory
        : null,
      ration_card_number: values.ration_card_number
        ? values.ration_card_number
        : null,
      house_type_choice_id: houseType ? houseType : null,
      house_build_type_choice_id: houseBuildType ? houseBuildType : null,
      water_facility_choice_id: waterFacility ? waterFacility : null,
      electric_facility_choice_id: electricFacility ? electricFacility : null,

      land_area_home: values.land_area_home ? values.land_area_home : null,
      land_area_farm: values.land_area_farm ? values.land_area_farm : null,
      land_area_rent: values.land_area_rent ? values.land_area_rent : null,
      land_area_others: values.land_area_others
        ? values.land_area_others
        : null,
      total_land_area: totalArea ? totalArea : null,

      two_wheeler_vehicles: parseInt(values.two_wheeler_vehicles),
      three_wheeler_vehicles: parseInt(values.three_wheeler_vehicles),
      four_wheeler_vehicles: parseInt(values.four_wheeler_vehicles),
      taxi_vehicles: parseInt(values.taxi_vehicles),
      total_vehicles: totalVehicles,

      // location: ,
      // image: ,

      financial_status_choice_id: financialStatus ? financialStatus : null,
      annual_income_choice_id:
        values.annual_income_choice_id === "Select an income range..." ||
        values.annual_income_choice_id === "ഒരു വരുമാന പരിധി തിരഞ്ഞെടുക്കുക..."
          ? null
          : values.annual_income_choice_id,
      bank_loan_choice_id_array: bankLoan ? bankLoan : null,
      bank_loan_details: values.bank_loan_details
        ? values.bank_loan_details
        : null,

      home_business_choice_id_array: homeBusiness ? homeBusiness : null,
      home_business_details: values.home_business_details
        ? values.home_business_details
        : null,

      help_receiving_from_mahall: helpFromMahallSwitch
        ? helpFromMahallSwitch
        : null,
      help_receiving_from_mahall_details:
        values.help_receiving_from_mahall_details
          ? values.help_receiving_from_mahall_details
          : null,
      help_receiving_from_others: helpFromOthersSwitch
        ? helpFromOthersSwitch
        : null,
      help_receiving_from_others_details:
        values.help_receiving_from_others_details
          ? values.help_receiving_from_others_details
          : null,
      special_notes: values.special_notes ? values.special_notes : null,

      is_zakat_eligible: isZakatEligibleSwitch ? isZakatEligibleSwitch : null,
      zakat_eligibility_reason: values.zakat_eligibility_reason
        ? values.zakat_eligibility_reason
        : null,
      varisankya_amount: values.varisankya_amount
        ? parseInt(values.varisankya_amount)
        : 0,
    }
    setImagePreview()
    // console.log("updateFamilyData: ", updateFamilyData)
    dispatch(updateFamily(updateFamilyData, familyDetail?.id, history))

    if (isUpdated === true) {
      toggleTab(activeTab + 1)
    }
  }
  useEffect(() => {
    if (isUpdated === true) {
      toggleTab(activeTab + 1)
    }
  }, [isUpdated])

  const addOrRemoveItem = (arr, setArray, value) => {
    const isPresent = arr.includes(value)
    var newArr = arr

    if (isPresent) {
      newArr = arr.filter(item => {
        return item.toString() !== value.toString()
      })
    } else {
      newArr = [...arr, value]
    }
    setArray(newArr)
  }

  const handleEntersState = textEntered => {
    setSearchTextState(textEntered)
  }

  const handleEntersDistrict = textEntered => {
    setSearchTextDistrict(textEntered)
  }

  const handleEntersVillage = textEntered => {
    setSearchTextVillage(textEntered)
  }

  const handleEntersLsg = textEntered => {
    setSearchTextLsg(textEntered)
  }

  const [filesSize, setFilesSize] = useState()
  const [img, setImg] = useState()
  console.log(img)
  const [selectedFile, setSelectedFile] = useState({
    house_image: null,
  })

  const handleFileSizes = useFileSizes

  const changeHandler = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      house_image: e.target.files[0],
    })
    const [file] = e.target.files
    setImg(URL.createObjectURL(file))

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const [customLoad, setCustomLoad] = useState(false)

  const handleValidSubmitImage = () => {
    if (handleFileSizes(filesSize)?.status === true) {
      updateImage(setCustomLoad, selectedFile, params, dispatch, history)
    }
  }

  const headerData = [
    { id: 1, titleMal: "സ്ഥല വിവരം", titleEn: "Place Details" },
    { id: 2, titleMal: "വീട്ടു വിവരം", titleEn: "House Details" },
    {
      id: 3,
      titleMal: "സാമ്പത്തിക വിവരം",
      titleEn: "Financial Details",
      line: false,
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeTab])

  return (
    <>
      <MetaTags>
        <title>Families | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "കുടുംബങ്ങൾ" : "Families"}
          breadcrumbItem={language === "mal" ? "പുതുക്കുക" : "Update"}
        />
        <Container fluid className="sm:p-0">
          <div className="container-fluid mt-1">
            <Row>
              {HeaderLine(
                activeTab,
                passedSteps,
                setactiveTab,
                language,
                headerData
              )}

              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(v)
                }}
                className="sm:p-0"
              >
                <Col md={12} lg={12}>
                  <Card>
                    <CardBody className="p-0">
                      <Row>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            type="submit"
                            color="success"
                            className="w-sm m-2 btn-block"
                          >
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            )}
                            {language === "mal" ? "പുതുക്കുക" : "Update"}
                          </Button>
                        </div>
                      </Row>
                      <div className="wizard clearfix">
                        <div
                          className={`content clearfix ${
                            activeTab === 4 ? "d-none" : ""
                          }`}
                        >
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                              <Row>
                                <Col md="3">
                                  <div className="mb-3">
                                    <AvField
                                      name="mahall_custom_id"
                                      type="text"
                                      value={
                                        familyDetail?.mahall_custom_id || ""
                                      }
                                      label={
                                        language === "mal"
                                          ? "മഹല്ല് ഐഡി"
                                          : "Mahall ID"
                                      }
                                    />
                                  </div>
                                </Col>
                                {/* <Col md="3">
                                  <div className="mb-1">
                                    <AvField
                                      name="varisangya_grade"
                                      type="text"
                                      value={
                                        familyDetail?.varisangya_grade || ''
                                      }
                                      label={
                                        language === 'mal'
                                          ? 'വരിസംഖ്യ ഗ്രേഡ്'
                                          : 'Varisangya Grade'
                                      }
                                    />
                                  </div>
                                </Col> */}
                                {mahallTypeLists?.varisankya_grade &&
                                  mahallTypeLists?.varisankya_grade.length >
                                    0 && (
                                    <Col md="3" className="mb-1">
                                      <Label>
                                        {language === "mal"
                                          ? "വരിസംഖ്യ ഗ്രേഡ്"
                                          : "Varisangya Grade"}
                                        {/* <span className="text-danger">&#42;</span> */}
                                      </Label>
                                      <AvField
                                        type="select"
                                        name="family_varisankya_grade_choice_id"
                                        value={
                                          familyDetail?.family_varisankya_grade_choice_id
                                            ? familyDetail?.family_varisankya_grade_choice_id
                                            : ""
                                        }
                                      >
                                        <option value={null}>
                                          {language === "mal"
                                            ? "ഗ്രേഡ് തിരഞ്ഞെടുക്കുക ..."
                                            : "Select an grade..."}{" "}
                                        </option>
                                        {mahallTypeLists?.varisankya_grade?.map(
                                          (item, key) => (
                                            <option key={key} value={item.id}>
                                              {language === "mal"
                                                ? item?.malayalam
                                                : item?.english}
                                            </option>
                                          )
                                        )}
                                      </AvField>
                                    </Col>
                                  )}
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "വീട്ടുപേര്"
                                        : "House Name"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <AvField
                                      name="house_name"
                                      type="text"
                                      value={familyDetail?.house_name || ""}
                                      // label={
                                      //   language === "mal"
                                      //     ? "വീട്ടുപേര്"
                                      //     : "House Name"
                                      // }
                                      onChange={e =>
                                        setHouseName(e.target.value)
                                      }
                                      validate={{
                                        required: { value: true },
                                        maxLength: {
                                          value: 50,
                                          errorMessage: `${
                                            language === "mal"
                                              ? "വീട്ടുപേര് 50 അക്ഷരങ്ങളിൽ കുറവായിരിക്കണം"
                                              : "House Name should be less than 50 characters"
                                          }`,
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="contact_number"
                                      type="number"
                                      value={familyDetail?.contact_number || ""}
                                      label={
                                        language === "mal"
                                          ? "ബന്ധപ്പെടേണ്ട നമ്പർ"
                                          : "Contact No."
                                      }
                                      validate={{
                                        maxLength: {
                                          value: 15,
                                          errorMessage: `${
                                            language === "mal"
                                              ? "ഫോൺ നമ്പർ 16 അക്കങ്ങളിൽ കുറവായിരിക്കണം"
                                              : "phone number should be less than 16 digits"
                                          }`,
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="ward_number"
                                      type="text"
                                      value={familyDetail?.ward_number || ""}
                                      label={
                                        language === "mal"
                                          ? "വാർഡ് നമ്പർ"
                                          : "Ward Number"
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="house_number"
                                      type="text"
                                      value={familyDetail.house_number || ""}
                                      label={
                                        language === "mal"
                                          ? "വീട് നമ്പർ"
                                          : "House No."
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                {mahallTypeLists?.area &&
                                  mahallTypeLists?.area.length > 0 && (
                                    <Col md="6">
                                      <Label>
                                        {language === "mal" ? "ഏരിയ" : "Area"}
                                      </Label>
                                      <AvField
                                        type="select"
                                        name="area_choice_id"
                                        value={
                                          familyDetail?.area_choice_id
                                            ? familyDetail?.area_choice_id
                                            : ""
                                        }
                                        // label={
                                        //   language === "mal" ? "ഏരിയ" : "Area"
                                        // }
                                      >
                                        <option>
                                          {language === "mal"
                                            ? "ഏരിയ തിരഞ്ഞെടുക്കുക ..."
                                            : "Select an area..."}{" "}
                                        </option>
                                        {mahallTypeLists?.area?.map(
                                          (item, key) => (
                                            <option key={key} value={item.id}>
                                              {language === "mal"
                                                ? item?.malayalam
                                                : item?.english}
                                            </option>
                                          )
                                        )}
                                      </AvField>
                                    </Col>
                                  )}
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="place"
                                      type="text"
                                      value={familyDetail?.place || ""}
                                      label={
                                        language === "mal" ? "സ്ഥലം" : "Place"
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="via"
                                      type="text"
                                      value={familyDetail?.via || ""}
                                      label={language === "mal" ? "വഴി" : "Via"}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mt-5">
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "സംസ്ഥാനം"
                                        : "State"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <Select
                                      onInputChange={handleEntersState}
                                      placeholder={state}
                                      value={state}
                                      options={optionGroupState}
                                      classNamePrefix="select2-selection"
                                      onChange={handlerFinalValueState}
                                      type="text"
                                      title="State"
                                      required
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "ജില്ല"
                                        : "District"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <Select
                                      onInputChange={handleEntersDistrict}
                                      placeholder={district}
                                      value={district ? district : ""}
                                      options={optionGroupDistrict}
                                      classNamePrefix="select2-selection"
                                      onChange={handlerFinalValueDistrict}
                                      type="text"
                                      title="District"
                                      required
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="pin_code"
                                      type="text"
                                      value={pinCode}
                                      label={
                                        language === "mal"
                                          ? "പിൻകോഡ്"
                                          : "Pin Code"
                                      }
                                      onChange={e => {
                                        setPinCode(e.target.value)
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="post_office"
                                      type="text"
                                      value={postOffice}
                                      label={
                                        language === "mal"
                                          ? "പോസ്റ്റ് ഓഫീസ്"
                                          : "Post Office"
                                      }
                                      onChange={e => {
                                        setPostOffice(e.target.value)
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "തദ്ദേശ സ്വയംഭരണ സ്ഥാപനം"
                                        : "LSG Name"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <Select
                                      onInputChange={handleEntersLsg}
                                      placeholder={lsg}
                                      value={lsg ? lsg : ""}
                                      options={optionGroupLsg}
                                      classNamePrefix="select2-selection"
                                      onChange={handlerFinalValueLsg}
                                      type="text"
                                      required
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "വില്ലേജ്"
                                        : "Village"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <Select
                                      onInputChange={handleEntersVillage}
                                      placeholder={village}
                                      value={village ? village : ""}
                                      options={optionGroupVillage}
                                      classNamePrefix="select2-selection"
                                      onChange={handlerFinalValueVillage}
                                      type="text"
                                      title="Village"
                                      required
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                              <div>
                                <Row>
                                  <Col md="6">
                                    <div className="mb-3">
                                      <Label>
                                        {language === "mal"
                                          ? "താമസം തുടങ്ങിയ വർഷം"
                                          : "Year Of Residance"}
                                      </Label>
                                      <Input
                                        type="number"
                                        className="form-control"
                                        value={
                                          yearOfResidence ? yearOfResidence : ""
                                        }
                                        onChange={e => {
                                          setYearOfResidence(e.target.value)
                                          changeYearsTillDate(e.target.value)
                                        }}
                                      />
                                    </div>
                                  </Col>

                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label for="basicpill-vatno-input6">
                                        {language === "mal"
                                          ? "നാളിതുവരെ വർഷങ്ങൾ"
                                          : "Years till Date"}
                                      </Label>
                                      <Input
                                        type="text"
                                        readOnly
                                        className="form-control"
                                        id="basicpill-vatno-input6"
                                        value={
                                          yearsTillDate ? yearsTillDate : ""
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  {mahallTypeLists?.ration_card &&
                                    mahallTypeLists?.ration_card?.length && (
                                      <Col sm="12" md="6" className="mb-3">
                                        <Label for="basicpill-cstno-input7">
                                          {language === "mal"
                                            ? "റേഷൻ കാർഡ് വിഭാഗം"
                                            : "Ration Card Category"}
                                        </Label>
                                        <div className="d-flex  flex-wrap">
                                          {map(
                                            mahallTypeLists?.ration_card,
                                            (item, key) => (
                                              <div key={key} className="m-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  // name="btnradio2"
                                                  checked={
                                                    item.id ===
                                                    rationCardCategory
                                                  }
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      rationCardCategory,
                                                      setRationCardCategory,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setRationCardCategory(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item.malayalam
                                                    : item.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Col>
                                    )}
                                  <Col md="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="ration_card_number"
                                        type="text"
                                        value={
                                          familyDetail?.ration_card_number || ""
                                        }
                                        label={
                                          language === "mal"
                                            ? "റേഷൻ കാർഡ് നമ്പർ"
                                            : "Ration Card No."
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {mahallTypeLists?.house_type &&
                                  mahallTypeLists?.house_type?.length && (
                                    <Col sm="12" md="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-companyuin-input9">
                                          {language === "mal"
                                            ? "വീട്"
                                            : "House Type"}
                                        </Label>
                                        <div className="d-flex  flex-wrap">
                                          {map(
                                            mahallTypeLists?.house_type,
                                            (item, key) => (
                                              <div key={key} className="mx-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  // name="btnradio3"
                                                  checked={
                                                    item.id === houseType
                                                  }
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      houseType,
                                                      setHouseType,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setHouseType(e.target.value)
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item.malayalam
                                                    : item.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                {mahallTypeLists?.house_build_type &&
                                  mahallTypeLists?.house_build_type?.length && (
                                    <Col md="12" lg="8">
                                      <div className="mb-3">
                                        <Label for="basicpill-declaration-input10">
                                          {language === "mal"
                                            ? "വീടിൻ്റെ സ്ഥിതി"
                                            : "Build Type"}
                                        </Label>
                                        <div className="d-flex flex-wrap">
                                          {map(
                                            mahallTypeLists?.house_build_type,
                                            (item, key) => (
                                              <div key={key} className="mx-1 ">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  // name="btnradio4"
                                                  checked={
                                                    item.id === houseBuildType
                                                  }
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      houseBuildType,
                                                      setHouseBuildType,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setHouseBuildType(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                {mahallTypeLists?.water_facility &&
                                  mahallTypeLists?.water_facility?.length && (
                                    <Col md="12" lg="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-companyuin-input9">
                                          {language === "mal"
                                            ? "ജല സൗകര്യം"
                                            : "Water Facility"}
                                        </Label>
                                        <div className=" d-flex flex-wrap">
                                          {map(
                                            mahallTypeLists?.water_facility,
                                            (item, key) => (
                                              <div key={key} className=" mx-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  // name="btnradio5"
                                                  checked={
                                                    item.id === waterFacility
                                                  }
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      waterFacility,
                                                      setWaterFacility,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setWaterFacility(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                {mahallTypeLists?.electric_facility &&
                                  mahallTypeLists?.electric_facility
                                    ?.length && (
                                    <Col md="12" lg="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-declaration-input10">
                                          {language === "mal"
                                            ? "വൈത്യുതി"
                                            : "Electicity"}
                                        </Label>
                                        <div className="d-flex">
                                          {map(
                                            mahallTypeLists?.electric_facility,
                                            (item, key) => (
                                              <div key={key} className="me-3">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  // name="btnradio6"
                                                  checked={
                                                    item.id === electricFacility
                                                  }
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      electricFacility,
                                                      setElectricFacility,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setElectricFacility(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                <Row>
                                  {/* Land Area */}
                                  <Col md="12" className="mb-3">
                                    {language === "mal"
                                      ? "ആസ്തി വിവരം (സെന്റിൽ)"
                                      : "Land Area (in cents)"}

                                    <Col md="12" className="d-flex flex-wrap">
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="2"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "വീട്"
                                              : "Home"}
                                          </Label>
                                          <AvField
                                            name="land_area_home"
                                            value={
                                              familyDetail?.land_area_home ||
                                              "0"
                                            }
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="2"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "കൃഷി"
                                              : "Farm"}
                                          </Label>
                                          <AvField
                                            name="land_area_farm"
                                            value={
                                              familyDetail?.land_area_farm ||
                                              "0"
                                            }
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="2"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "വാടക"
                                              : "Rent"}
                                          </Label>
                                          <AvField
                                            name="land_area_rent"
                                            value={
                                              familyDetail?.land_area_rent ||
                                              "0"
                                            }
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="2"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "മറ്റുള്ളവ"
                                              : "Others"}
                                          </Label>
                                          <AvField
                                            name="land_area_others"
                                            value={
                                              familyDetail?.land_area_others ||
                                              "0"
                                            }
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                    </Col>
                                  </Col>

                                  {/* Vehicles */}
                                  <Col md="12" className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "വാഹനങ്ങൾ (എണ്ണം)"
                                        : "Vehicles (count)"}
                                    </Label>

                                    <Col md="12" className="d-flex flex-wrap">
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="2"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "2 വീലറുകൾ"
                                              : "2 Wheelers"}
                                          </Label>
                                          <AvField
                                            name="two_wheeler_vehicles"
                                            value={
                                              familyDetail?.two_wheeler_vehicles ||
                                              "0"
                                            }
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="2"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "3 വീലറുകൾ"
                                              : "3 Wheelers"}
                                          </Label>
                                          <AvField
                                            name="three_wheeler_vehicles"
                                            value={
                                              familyDetail?.three_wheeler_vehicles ||
                                              "0"
                                            }
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="2"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "4 വീലറുകൾ"
                                              : "4 Wheelers"}
                                          </Label>
                                          <AvField
                                            name="four_wheeler_vehicles"
                                            value={
                                              familyDetail?.four_wheeler_vehicles ||
                                              "0"
                                            }
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="2"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "ടാക്സികൾ"
                                              : "Taxi Vehicles"}
                                          </Label>
                                          <AvField
                                            name="taxi_vehicles"
                                            value={
                                              familyDetail?.taxi_vehicles || "0"
                                            }
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                    </Col>
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId={3}>
                              <div>
                                {mahallTypeLists?.financial_status &&
                                  mahallTypeLists?.financial_status?.length && (
                                    <Col md="6">
                                      <div className="mb-3">
                                        <Label>
                                          {language === "mal"
                                            ? "സാമ്പത്തിക സ്ഥിതി"
                                            : "Financial Status"}
                                        </Label>
                                        <div className="d-flex flex-wrap">
                                          {map(
                                            mahallTypeLists?.financial_status,
                                            (item, key) => (
                                              <div key={key} className="mx-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  checked={
                                                    item.id === financialStatus
                                                  }
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item?.id}
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      financialStatus,
                                                      setFinancialStatus,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setFinancialStatus(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                {mahallTypeLists?.annual_income &&
                                  mahallTypeLists?.annual_income.length > 0 && (
                                    <Col md="6" className="mb-4">
                                      <AvField
                                        type="select"
                                        name="annual_income_choice_id"
                                        value={
                                          familyDetail?.annual_income_choice_id
                                            ? familyDetail?.annual_income_choice_id
                                            : ""
                                        }
                                        label={
                                          language === "mal"
                                            ? "വാർഷിക വരുമാനം"
                                            : "Annual Income"
                                        }
                                      >
                                        <option>
                                          {language === "mal"
                                            ? "ഒരു വരുമാന പരിധി തിരഞ്ഞെടുക്കുക..."
                                            : "Select an income range..."}
                                        </option>
                                        {mahallTypeLists?.annual_income?.map(
                                          (item, key) => (
                                            <option key={key} value={item.id}>
                                              {item.english}
                                            </option>
                                          )
                                        )}
                                      </AvField>
                                    </Col>
                                  )}
                                {mahallTypeLists?.bank_loan &&
                                  mahallTypeLists?.bank_loan?.length && (
                                    <Col md="12">
                                      <div className="mb-3">
                                        <Label for="basicpill-cardno-input12">
                                          {language === "mal"
                                            ? "ബാങ്ക് വായ്പ"
                                            : "Bank Loan"}
                                        </Label>
                                        <div className="d-flex  flex-wrap">
                                          {map(
                                            mahallTypeLists?.bank_loan,
                                            (item, key) => (
                                              <div key={key} className="m-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  name={item.id}
                                                  checked={bankLoan?.includes(
                                                    item.id
                                                  )}
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e =>
                                                    addOrRemoveItem(
                                                      bankLoan,
                                                      setBankLoan,
                                                      e.target.value
                                                    )
                                                  }
                                                  readOnly
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                <Col md="12">
                                  <div className="mb-3">
                                    <AvField
                                      name="bank_loan_details"
                                      type="text"
                                      value={
                                        familyDetail?.bank_loan_details || ""
                                      }
                                      label={
                                        language === "mal"
                                          ? "ബാങ്ക് വായ്പ വിശദാംശങ്ങൾ"
                                          : "Bank Loan Details"
                                      }
                                    />
                                  </div>
                                </Col>
                                {mahallTypeLists?.home_business &&
                                  mahallTypeLists?.home_business?.length && (
                                    <>
                                      <Col md="12">
                                        <div className="mb-3">
                                          <Label>
                                            {language === "mal"
                                              ? "ഹോം ബിസിനസ്സ്"
                                              : "Home Business"}
                                          </Label>
                                          <div className="d-flex  flex-wrap">
                                            {map(
                                              mahallTypeLists?.home_business,
                                              (item, key) => (
                                                <div key={key} className="m-1">
                                                  <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name={item.id}
                                                    checked={homeBusiness?.includes(
                                                      item.id
                                                    )}
                                                    id={item.id}
                                                    autoComplete="off"
                                                    value={item.id}
                                                    onClick={e =>
                                                      addOrRemoveItem(
                                                        homeBusiness,
                                                        setHomeBusiness,
                                                        e.target.value
                                                      )
                                                    }
                                                    readOnly
                                                  />
                                                  <label
                                                    className="btn btn-outline-info"
                                                    htmlFor={item.id}
                                                  >
                                                    {language === "mal"
                                                      ? item?.malayalam
                                                      : item?.english}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md="12">
                                        <div className="mb-3">
                                          <AvField
                                            name="home_business_details"
                                            type="text"
                                            value={
                                              familyDetail?.home_business_details ||
                                              ""
                                            }
                                            label={
                                              language === "mal"
                                                ? "ഹോം ബിസിനസ്സ് വിശദാംശങ്ങൾ"
                                                : "Home Business Details"
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </>
                                  )}
                                <Row>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label className="form-check-label">
                                        {language === "mal"
                                          ? "മഹല്ലിൽ നിന്നുള്ള സഹായം"
                                          : "Help From Mahall"}
                                      </Label>
                                      <div className="form-check form-switch form-switch-lg">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          defaultChecked={helpFromMahallSwitch}
                                          onClick={() =>
                                            setHelpFromMahallSwitch(
                                              !helpFromMahallSwitch
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  {helpFromMahallSwitch && (
                                    <Col md="8">
                                      <div className="mb-3">
                                        <AvField
                                          name="help_receiving_from_mahall_details"
                                          type="text"
                                          value={
                                            familyDetail?.help_receiving_from_mahall_details
                                          }
                                          label={
                                            language === "mal"
                                              ? "വിശദാംശങ്ങൾ"
                                              : "Details"
                                          }
                                        />
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label className="form-check-label">
                                        {language === "mal"
                                          ? "മറ്റുള്ളവരിൽ നിന്നുള്ള സഹായം"
                                          : "Help From Others"}
                                      </Label>
                                      <div className="form-check form-switch form-switch-lg">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          defaultChecked={helpFromOthersSwitch}
                                          onClick={() =>
                                            setHelpFromOthersSwitch(
                                              !helpFromOthersSwitch
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  {helpFromOthersSwitch && (
                                    <Col md="8">
                                      <div className="mb-3">
                                        <AvField
                                          name="help_receiving_from_others_details"
                                          type="text"
                                          value={
                                            familyDetail?.help_receiving_from_others_details
                                          }
                                          label={
                                            language === "mal"
                                              ? "വിശദാംശങ്ങൾ"
                                              : "Details"
                                          }
                                        />
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Col md="12">
                                  <div className="mb-3">
                                    <AvField
                                      name="special_notes"
                                      type="textarea"
                                      value={familyDetail?.special_notes || ""}
                                      label={
                                        language === "mal"
                                          ? "പ്രത്യേക കുറിപ്പുകൾ"
                                          : "Special Notes"
                                      }
                                    />
                                  </div>
                                </Col>
                                <Row>
                                  <Col md="4">
                                    <div className="mb-3 mt-1">
                                      <Label className="form-check-label">
                                        {language === "mal"
                                          ? "സകാത്തിന് അർഹമാണോ"
                                          : "Is Zakat Eligible"}
                                      </Label>
                                      <div className="form-check form-switch form-switch-lg">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          defaultChecked={isZakatEligibleSwitch}
                                          onClick={() =>
                                            setIsZakatEligibleSwitch(
                                              !isZakatEligibleSwitch
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  {isZakatEligibleSwitch && (
                                    <Col md="8">
                                      <div className="mb-3 mt-1">
                                        <AvField
                                          name="zakat_eligibility_reason"
                                          type="text"
                                          value={
                                            familyDetail?.zakat_eligibility_reason
                                          }
                                          label={
                                            language === "mal"
                                              ? "അർഹതയുടെ കാരണം"
                                              : "Eligibility Reason"
                                          }
                                        />
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Col md="3" className="mb-3">
                                  <AvField
                                    type="number"
                                    name="varisankya_amount"
                                    value={
                                      familyDetail?.varisankya_amount
                                        ? familyDetail?.varisankya_amount
                                        : 0
                                    }
                                    label={
                                      language === "mal"
                                        ? "വരിസംഖ്യ തുക"
                                        : "Varisangya Amount"
                                    }
                                    validate={{
                                      min: {
                                        value: 0,
                                        errorMessage: `${
                                          language === "mal"
                                            ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                                            : "Amount must be greater than 0"
                                        }`,
                                      },
                                    }}
                                  ></AvField>
                                </Col>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                        {activeTab === 4 ? (
                          <></>
                        ) : (
                          <div className="actions clearfix">
                            <ul>
                              <li
                                className={
                                  activeTab === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab - 1)
                                  }}
                                >
                                  {language === "mal"
                                    ? "പിന്നിലേക്ക്"
                                    : "Previous"}
                                </Link>
                              </li>

                              {activeTab === 3 && (
                                <li>
                                  <Button
                                    type="submit"
                                    color="success"
                                    className="w-sm btn-block"
                                  >
                                    {loading && (
                                      <>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                      </>
                                    )}
                                    {language === "mal"
                                      ? "പുതുക്കുക"
                                      : "Update"}
                                  </Button>
                                </li>
                              )}
                              <li
                                className={
                                  activeTab === 3 ? "next d-none" : "next"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    !houseName ? null : toggleTab(activeTab + 1)
                                  }}
                                >
                                  {language === "mal" ? "അടുത്തത്" : "Next"}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </AvForm>
            </Row>
            <Row>
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmitImage(e, v)
                }}
                className="sm:p-0"
              >
                <Card className=" sm:p-0">
                  <CardBody>
                    <Row>
                      <Col md={6} lg={4}>
                        <div className="mb-3">
                          <Label>
                            {language === "mal"
                              ? "വീടിൻ്റെ ഫോട്ടോ"
                              : "House Photo"}
                          </Label>
                          <div>
                            <Input
                              onChange={changeHandler}
                              type="file"
                              name="house_image"
                              className="form"
                            />
                            {imagePreview ? (
                              <Image
                                style={{ width: "150px", height: "150px" }}
                                className="my-2"
                                src={imagePreview.image}
                              />
                            ) : (
                              <Image
                                style={{ width: "150px", height: "150px" }}
                                className="my-2"
                                src={familyDetail?.house_image}
                              />
                            )}
                          </div>
                          {handleFileSizes(filesSize)?.text && (
                            <p className="text-danger mt-1">
                              {handleFileSizes(filesSize)?.text}
                            </p>
                          )}
                        </div>
                      </Col>

                      {/* <Col md={6} lg={4}>
                        <div className="mb-3">
                          <Label>
                            {language === "mal" ? "ലൊക്കേഷൻ" : "Location"}
                          </Label>
                          <div className="d-flex align-items-center">
                            <Button className="mb-2" color="light">
                              Add Location
                            </Button>
                            <br />
                            <span className="mx-2">No location added</span>
                          </div>
                        </div>
                      </Col> */}
                      <Col
                        sm="4"
                        className="mt-2 align-items-center
                            d-flex
                            justify-content-end"
                        style={{ marginLeft: "auto" }}
                      >
                        <Button
                          type="submit"
                          color="success"
                          className="w-sm btn-block"
                        >
                          {customLoad && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal"
                            ? "സമർപ്പിക്കുക"
                            : "Submit Image/Location"}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </AvForm>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateMahallFamily

UpdateMahallFamily.propTypes = {
  history: PropTypes.object,
}

function updateImage(setCustomLoad, selectedFile, params, dispatch, history) {
  setCustomLoad(true)

  const { role } = useGetRole()
  const { token } = useGetToken()

  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.house_image &&
      formData.append(
        "house_image",
        selectedFile?.house_image,
        selectedFile?.house_image?.name
      )
  }

  axios
    .patch(`${API_URL}/api/v1/familydetails/family/${params.id}/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        dispatch(updateFamilySuccess(res.data))
        history.push(`/families/${res.data?.id}`)
        doneNotification("Image Updated Successfully")
        setCustomLoad(false)
      } else {
        // updateFamilyFail(err)
        errorNotification(res?.error)
      }
    })
    .catch(err => {
      updateFamilyFail(err)
      errorNotification()
      // if (err?.response?.data?.error) {
      //   updateFamilyFail(err)
      //   errorNotification()
      //   setCustomLoad(false)
      // }
    })
}
