/* SuperFeedss - This line cannot be edited or removed */
export const GET_SUPERFEEDS = "GET_SUPERFEEDS"
export const GET_SUPERFEEDS_SUCCESS = "GET_SUPERFEEDS_SUCCESS"
export const GET_SUPERFEEDS_FAIL = "GET_SUPERFEEDS_FAIL"

/* SuperFeeds_DETAILS */
export const GET_SUPERFEEDS_DETAILS = "GET_SUPERFEEDS_DETAILS"
export const GET_SUPERFEEDS_DETAILS_SUCCESS = "GET_SUPERFEEDS_DETAILS_SUCCESS"
export const GET_SUPERFEEDS_DETAILS_FAIL = "GET_SUPERFEEDS_DETAILS_FAIL"

/* CREATE SuperFeeds */
export const CREATE_SUPERFEEDS = "CREATE_SUPERFEEDS"
export const CREATE_SUPERFEEDS_SUCCESS = "CREATE_SUPERFEEDS_SUCCESS"
export const CREATE_SUPERFEEDS_FAIL = "CREATE_SUPERFEEDS_FAIL"

/* Edit SuperFeeds */
export const UPDATE_SUPERFEEDS = "UPDATE_SUPERFEEDS"
export const UPDATE_SUPERFEEDS_SUCCESS = "UPDATE_SUPERFEEDS_SUCCESS"
export const UPDATE_SUPERFEEDS_FAIL = "UPDATE_SUPERFEEDS_FAIL"

/* Delete SuperFeeds */
export const DELETE_SUPERFEEDS = "DELETE_SUPERFEEDS"
export const DELETE_SUPERFEEDS_SUCCESS = "DELETE_SUPERFEEDS_SUCCESS"
export const DELETE_SUPERFEEDS_FAIL = "DELETE_SUPERFEEDS_FAIL"

/*repost feed */
export const REPOST_FEEDS = "REPOST_FEEDS"
export const REPOST_FEED_SUCCESS = "REPOST_FEED_SUCCESS"
export const REPOST_FEED_FAIL = "REPOST_FEED_FAIL"
