import React from "react"
import { Container } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useSelector } from "react-redux"

import DatatableTables from "./DatatableTables"

import Breadcrumbs from "components/Common/Breadcrumb"

function AllInstituteStudentWalletLists() {
  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  return (
    <>
      <MetaTags>
        <title>Wallet | Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "മദ്രസ ഫീസ്" : "Madrasa Fee"}
          breadcrumbItem={
            language === "mal" ? "വാലറ്റ് വിശദാംശങ്ങൾ" : "Wallet Details"
          }
        />
        {/* <Row>
          <Col xs="12">
            <div
              className="page-title-box d-sm-flex align-items-center justify-content-end"
              style={{ marginBottom: "10px" }}
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0 d-flex justify-content-end mt-4">
                </ol>
              </div>
            </div>
          </Col>
        </Row> */}
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default AllInstituteStudentWalletLists
