import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation } from "react-router-dom"
import { Col, Container, Row, FormGroup, Label } from "reactstrap"
import PropTypes from "prop-types"
//redux
import { useSelector, useDispatch } from "react-redux"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
// actions
import OtpInput from "react-otp-input"

import { loginUser, apiError, loginOtpUser, getUserDetail } from "store/actions"
// import images
import logo_mahallu from "assets/images/logo/logo.png"
import login_img_mahall from "assets/images/bg_login.jpg"
import { Notification } from "components/Common/Notification"
import { useGetRole, useGetToken, useSetRole } from "hooks/useHandleSessions"
//css
import "toastr/build/toastr.min.css"
// import { handleGetFirebaseToken } from "components/PushNotification/firebase"

const Login = ({ history }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [toggleShow] = useState(false)
  // const [tryFcmToken, setTryFcmToken] = useState(false)

  const { user, loading, userDetail } = useSelector(state => ({
    error: state.Login.error,
    user: state.Login.user,
    loading: state.Login.loading,
    userDetail: state.Users.userDetail,
  }))
  const query = location?.search.slice(1)

  const [mobileNo, setMobileNo] = useState()

  // handleValidSubmit
  const handleValidSubmit = (e, values) => {
    setMobileNo(values.username)
    dispatch(loginUser(values, history))
  }

  const { token } = useGetToken()
  const { role } = useGetRole()

  useEffect(() => {
    if (token && !userDetail) {
      dispatch(getUserDetail())
    }
    if (token) {
      // if (!tryFcmToken) {
      //   setTryFcmToken(handleGetFirebaseToken(token, user))
      // }
      if (role) {
        history.push("/dashboard")
      } else {
        history.push("/role")
      }
    }
  }, [dispatch, token])

  useEffect(() => {
    dispatch(apiError(""))
    if (user) {
      Notification({
        type: "info",
        message: user?.response,
        title: "",
      })
    }

    if (user?.token) {
      console.log("roles: ", user?.roles)
      if (user?.roles?.length === 1) {
        useSetRole(user?.roles[0])
        history.push("/dashboard")
      } else {
        history.push("/role")
      }
    }
  }, [user])

  useEffect(() => {
    if (user?.session_key) {
      history.push({
        pathname: "/login",
        search: "otp",
      })
    }
  }, [user])

  const [otpText, setOtpText] = useState()

  const [attempt, setAttempt] = useState(0)

  const handleOtp = e => {
    dispatch(loginOtpUser({ otp: otpText }, e))
  }

  useEffect(() => {
    if (user?.response === "Incorrect OTP") {
      setAttempt(attempt + 1)
    }
  }, [user])

  useEffect(() => {
    if (attempt >= 5) {
      history.push("/login")
      setAttempt(0)
      setOtpText()
    }
  }, [attempt >= 5])

  const handleOtpChnages = v => {
    setOtpText(v)
  }

  useEffect(() => {
    if (otpText?.length === 6) {
      dispatch(loginOtpUser({ otp: otpText }))
    }
  }, [otpText])

  const changeNumber = () => {
    setOtpText()
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Login | Khaf </title>
        </MetaTags>
        <Container fluid className="p-0 bg-white" style={{ height: "100vh" }}>
          <Row className="g-0 h-100">
            <Col md={12} lg={12} xl={12} className="bg-light sm:d-none">
              <div className="">
                <img
                  src={login_img_mahall}
                  alt="logo"
                  className="login-bg-image"
                />
              </div>
            </Col>

            <div className="d-flex position-absolute main-prant-login">
              <div className="w-50 display-md-none"></div>
              <div className="custom-login-card w-50">
                <div className="bg-light-soft p-5 custom-login-child ">
                  <div className="w-100 loginCard ">
                    <div className="d-flex flex-column h-100 login-form-wrap">
                      <div className="m-auto">
                        <Link to="/dashboard" className="d-block auth-logo">
                          <h4>
                            <img src={logo_mahallu} alt="logo" height="50" />
                          </h4>
                        </Link>
                      </div>
                      <div className="w-100 sm:w-100 m-auto">
                        {query ? (
                          <div>
                            <h5 className="text-primary">OTP !</h5>
                            <p className="text-muted">
                              OTP Successfuly Sent To{" "}
                              <span className="mx-1"> {mobileNo} ,</span>
                              <Link
                                className=""
                                onClick={() => changeNumber("/login")}
                                to="#!"
                              >
                                Change Number ?
                              </Link>
                            </p>
                          </div>
                        ) : (
                          <div className="mb-5">
                            <h5 className="text-khaf-blue">Welcome Back !</h5>
                            <p className="text-muted">Login to continue</p>
                          </div>
                        )}
                        <div className="">
                          {query ? (
                            <>
                              <AvForm
                                className="form-horizontal"
                                style={{
                                  maxWidth: "400px",
                                }}
                                onValidSubmit={(e, v) => {
                                  handleOtp(e, v)
                                }}
                              >
                                <FormGroup
                                  className="verification-2 mb-3 "
                                  name="otp"
                                >
                                  <Label
                                    htmlFor="digit1-input"
                                    className="visually-hidden"
                                  >
                                    OTP
                                  </Label>
                                  <div className="wrapper_otp">
                                    <Label>
                                      Enter Your OTP{" "}
                                      {loading && (
                                        <>
                                          <i className="bx bx-loader bx-spin font-size-16 align-middle text-info me-2"></i>
                                        </>
                                      )}
                                    </Label>
                                    <OtpInput
                                      value={otpText}
                                      onChange={v => handleOtpChnages(v)}
                                      numInputs={6}
                                      isInputNum={true}
                                      inputStyle={"otp_input"}
                                      containerStyle={"otp_container"}
                                      shouldAutoFocus={true}
                                      focusStyle={"otp_focus"}
                                      // separator={<span>&nbsp;</span>}
                                    />
                                  </div>
                                  <div className="mt-3 d-grid">
                                    {attempt > 2 ? (
                                      <p className="text-danger my-2">
                                        You have {5 - attempt} more attampt left
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </FormGroup>
                              </AvForm>
                            </>
                          ) : (
                            <>
                              <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                  handleValidSubmit(e, v)
                                }}
                              >
                                <div className="mb-3 numberField">
                                  <AvField
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: "Required",
                                      },
                                      minLength: {
                                        value: 10,
                                        errorMessage:
                                          "Your number must be 10 digits",
                                      },
                                      maxLength: {
                                        value: 10,
                                        errorMessage:
                                          "Your number must be 10 digits",
                                      },
                                    }}
                                    name="username"
                                    label="Mobile Number"
                                    type={`${
                                      toggleShow == true ? "number" : "number"
                                    }`}
                                    // required
                                    placeholder="Enter Mobile Number"
                                    className="position-relative w-100 "
                                  />
                                </div>

                                <div className="mt-3 w-fit align">
                                  <button
                                    className="btn btn-success bg-khaf btn-block w-100 mr-auto"
                                    type="submit"
                                  >
                                    {loading && (
                                      <>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                      </>
                                    )}
                                    Login
                                  </button>
                                </div>
                                {/* 
                          <div className="mt-4 text-center">
                            <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock me-1" />
                              Forgot your password?
                            </Link>
                          </div> */}
                              </AvForm>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="w-100 text-center">
                        <p style={{ fontSize: "12px" }}>
                          from&nbsp;&nbsp;
                          <a
                            style={{
                              color: "#1781BB",
                              fontWeight: "600",
                              fontSize: "16px",
                            }}
                            className="text-size-16"
                            href="https://osperb.com/"
                          >
                            osperb
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login

Login.propTypes = {
  history: PropTypes.object,
}
