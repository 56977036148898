/* ALL_MADRASA */
export const GET_ALL_MADRASA_LISTS = "GET_ALL_MADRASA_LISTS"
export const GET_ALL_MADRASA_LISTS_SUCCESS = "GET_ALL_MADRASA_LISTS_SUCCESS"
export const GET_ALL_MADRASA_LISTS_FAIL = "GET_ALL_MADRASA_LISTS_FAIL"

/* MADRASA_DROPDOWN */
export const GET_MADRASA_DROPDOWN = "GET_MADRASA_DROPDOWN"
export const GET_MADRASA_DROPDOWN_SUCCESS = "GET_MADRASA_DROPDOWN_SUCCESS"
export const GET_MADRASA_DROPDOWN_FAIL = "GET_MADRASA_DROPDOWN_FAIL"

/* MADRASAS DETAIL*/
export const GET_MADRASA_DETAIL = "GET_MADRASA_DETAIL"
export const GET_MADRASA_DETAIL_SUCCESS = "GET_MADRASA_DETAIL_SUCCESS"
export const GET_MADRASA_DETAIL_FAIL = "GET_MADRASA_DETAIL_FAIL"

/**
 * add MADRASA
 */
export const CREATE_MADRASA = "CREATE_MADRASA"
export const CREATE_MADRASA_SUCCESS = "CREATE_MADRASA_SUCCESS"
export const CREATE_MADRASA_FAIL = "CREATE_MADRASA_FAIL"

/**
 * Edit MADRASA
 */
export const UPDATE_MADRASA = "UPDATE_MADRASA"
export const UPDATE_MADRASA_SUCCESS = "UPDATE_MADRASA_SUCCESS"
export const UPDATE_MADRASA_FAIL = "UPDATE_MADRASA_FAIL"

/**
 * Delete MADRASA
 */
export const DELETE_MADRASA = "DELETE_MADRASA"
export const DELETE_MADRASA_SUCCESS = "DELETE_MADRASA_SUCCESS"
export const DELETE_MADRASA_FAIL = "DELETE_MADRASA_FAIL"
