import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_TIMETABLE_LIST,
  UPDATE_TIMETABLE,
  PERIODS_DROPDOWN,
} from "./actionTypes"
import {
  getTimetableListSuccess,
  getTimetableListFail,
  updateTimetableSuccess,
  updateTimetableFail,
  periodsDropdownSuccess,
  periodsDropdownFail,
} from "./actions"

import { get, ApiPut } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getTimetableListAPi = ({ divisionId }) => {
  if (divisionId) {
    return get(
      `/api/v1/madrasa/madrasa-timetable-period/?division=${
        divisionId ? divisionId : ""
      }`
    )
  } else {
    return get(`/api/v1/madrasa/madrasa-timetable-period/`)
  }
}
const updateSubjectApi = ({ timetableId, updatedTimetable }) => {
  return ApiPut(
    `/api/v1/madrasa/madrasa-timetable-period/${timetableId}/`,
    updatedTimetable
  )
}
const periodsDropdownApi = ({ day, division }) => {
  return get(
    `/api/v1/madrasa/madrasa-timetable-period-dropdown/?day=${
      day ? day : ""
    }&division=${division ? division : ""}`
  )
}

function* fetchTimetableList({ payload }) {
  try {
    const response = yield call(getTimetableListAPi, payload)
    if (response && !response?.error) {
      yield put(getTimetableListSuccess(response))
    } else {
      yield put(getTimetableListFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getTimetableListFail(error))
  }
}

function* onUpdateTimetable({ payload }) {
  try {
    const response = yield call(updateSubjectApi, payload)
    if (response && !response?.error) {
      yield put(updateTimetableSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateTimetableFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateTimetableFail(error))
    errorNotification()
  }
}

function* fetchPeriodsDropdown({ payload }) {
  try {
    const response = yield call(periodsDropdownApi, payload)
    if (response && !response?.error) {
      yield put(periodsDropdownSuccess(response))
    } else {
      yield put(periodsDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(periodsDropdownFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* TimetableSaga() {
  yield takeEvery(GET_TIMETABLE_LIST, fetchTimetableList)
  yield takeEvery(UPDATE_TIMETABLE, onUpdateTimetable)
  yield takeEvery(PERIODS_DROPDOWN, fetchPeriodsDropdown)
}

export default TimetableSaga
