import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_MAHALLS,
  GET_MAHALLS_CARDS,
  GET_MAHALL_DETAIL,
  GET_MAHALL_DROPDOWN,
  CREATE_MAHALL,
  UPDATE_MAHALL,
  DELETE_MAHALL,
  PATCH_MAHALL_PREMIUM,
  GET_MAHALLS_FOR_DOWNLOAD,
} from "./actiontypes"
import {
  getMahallsSuccess,
  getMahallsFail,
  getMahallsCardsSuccess,
  getMahallsCardsFail,
  getMahallDetailSuccess,
  getMahallDetailFail,
  getMahallDropdownSuccess,
  getMahallDropdownFail,
  createMahallSuccess,
  createMahallFail,
  updateMahallSuccess,
  updateMahallFail,
  deleteMahallSuccess,
  deleteMahallFail,
  patchMahallPremiumSuccess,
  patchMahallPremiumFail,
  getMahallsForDownloadSuccess,
  getMahallsForDownloadFail,
} from "./actions"

import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getMahallsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/mahall/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/mahall/mahall/?page=${page ? page : 1}`)
  }
}
const getMahallsForDownloadAPi = () => {
  return get(`/api/v1/mahall/mahall/`)
}
const getMahallsCardsAPi = () => {
  return get(`/api/v1/mahall/mahall/?cards=all`)
}
const getMahallDropdownApi = () => {
  return get(`/api/v1/mahall/mahall_dropdown`)
}
const getMahallDetailsAPi = mahallId => {
  return get(`/api/v1/mahall/mahall/${mahallId}/`)
}
const patchMahallPremiumAPi = ({ mahallId, data }) => {
  return patch(`/api/v1/mahall/mahall/${mahallId}/`, data)
}
const createMahallApi = ({ mahall }) => {
  return post("/api/v1/mahall/mahall/", mahall, { "Content-type": "" })
}
const updateMahallApi = ({ mahallId, mahall }) => {
  return ApiPut(`/api/v1/mahall/mahall/${mahallId}/`, mahall)
}
const deleteMahallApi = ({ mahallId }) => {
  return del(`/api/v1/mahall/mahall/${mahallId}/`)
}

function* fetchMahalls({ payload }) {
  try {
    const response = yield call(getMahallsAPi, payload)
    if (response && !response?.error) {
      yield put(getMahallsSuccess(response))
    } else {
      yield put(getMahallsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallsFail(error))
  }
}

function* fetchMahallsForDownload({ payload }) {
  try {
    const response = yield call(getMahallsForDownloadAPi, payload)
    if (response && !response?.error) {
      yield put(getMahallsForDownloadSuccess(response))
    } else {
      yield put(getMahallsForDownloadFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallsForDownloadFail(error))
  }
}

function* fetchMahallsCards({ payload }) {
  try {
    const response = yield call(getMahallsCardsAPi, payload)
    if (response && !response?.error) {
      yield put(getMahallsCardsSuccess(response))
    } else {
      yield put(getMahallsCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallsCardsFail(error))
  }
}

function* fetchMahallDropdown({ payload }) {
  try {
    const response = yield call(getMahallDropdownApi, payload)
    if (response && !response?.error) {
      yield put(getMahallDropdownSuccess(response))
    } else {
      yield put(getMahallDropdownFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallDropdownFail(error))
  }
}

function* fetchMahallDetail({ mahallId }) {
  try {
    const response = yield call(getMahallDetailsAPi, mahallId)
    if (response && !response?.error) {
      yield put(getMahallDetailSuccess(response))
    } else {
      yield put(getMahallDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallDetailFail(error))
  }
}

function* onPatchMahallPremium({ payload }) {
  console.log(payload)
  try {
    const response = yield call(patchMahallPremiumAPi, payload)
    if (response && !response?.error) {
      yield put(patchMahallPremiumSuccess(response))
      doneNotification("Terms & Conditions Accepted")
    } else {
      yield put(patchMahallPremiumFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(patchMahallPremiumFail(error))
    errorNotification()
  }
}

function* onCreateMahall({ payload }) {
  // console.log("data :", payload)
  try {
    const response = yield call(createMahallApi, payload)
    if (response && !response?.error) {
      yield put(createMahallSuccess(response))
      payload.history.push("/mahalls")
      doneNotification()
    } else {
      yield put(createMahallFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createMahallFail(error))
    errorNotification()
  }
}

function* onUpdateMahall({ payload }) {
  try {
    const response = yield call(updateMahallApi, payload)
    if (response && !response?.error) {
      yield put(updateMahallSuccess(response))
      doneNotification()
    } else {
      yield put(updateMahallFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateMahallFail(error))
    errorNotification()
  }
}

function* onDeleteMahall({ payload }) {
  try {
    const response = yield call(deleteMahallApi, payload)
    if (response && !response?.error) {
      payload.history.push("/mahalls")
      yield put(deleteMahallSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteMahallFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteMahallFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* MahallsSaga() {
  yield takeEvery(GET_MAHALLS, fetchMahalls)
  yield takeEvery(GET_MAHALLS_FOR_DOWNLOAD, fetchMahallsForDownload)
  yield takeEvery(GET_MAHALLS_CARDS, fetchMahallsCards)
  yield takeEvery(GET_MAHALL_DETAIL, fetchMahallDetail)
  yield takeEvery(PATCH_MAHALL_PREMIUM, onPatchMahallPremium)
  yield takeEvery(GET_MAHALL_DROPDOWN, fetchMahallDropdown)
  yield takeEvery(CREATE_MAHALL, onCreateMahall)
  yield takeEvery(UPDATE_MAHALL, onUpdateMahall)
  yield takeEvery(DELETE_MAHALL, onDeleteMahall)
}

export default MahallsSaga
