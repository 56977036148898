import React, { Fragment, useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Table,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Keyboard, Mousewheel } from "swiper"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.scss"
//actions
//componetns
import { debounce, map, range } from "lodash"
import { Markup } from "interweave"

import UiModalMeetingAttendance from "./UiModalMeetingAttendance"
import UiModalMeetingAttendanceUpdate from "./UiModalMeetingAttendanceUpdate"
import UiModalMeetingImage from "./UiModalMeetingImage"
import UiModalMeetingImageUpdate from "./UiModalMeetingImageUpdate"

import DeleteModal from "components/Common/DeleteModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import MyPagination from "components/Common/MyPagination"
import {
  deleteCommitteeMeeting,
  getCommitteeMeetingDetail,
  getCommitteeMeetingAttendances,
  getCommitteeMeetingImages,
} from "store/actions"
import { useWindowSize } from "hooks/useWindowSize"

SwiperCore.use([Navigation, Keyboard, Mousewheel])

const InstituteCommitteeMeetingDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { width } = useWindowSize()

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const {
    committeeMeetingDetail,
    loading,
    detailLoading,
    language,
    committeeMeetingAttendances,
    committeeMeetingImages,
  } = useSelector(state => ({
    loading: state.CommitteeMeetings.loading,
    detailLoading: state.Mahalls.detailLoading,
    committeeMeetingDetail: state.CommitteeMeetings.committeeMeetingDetail,
    committeeMeetingAttendances:
      state.CommitteeMeetingAttendances.committeeMeetingAttendances,
    committeeMeetingImages: state.CommitteeMeetingImages.committeeMeetingImages,
    language: state.Layout.language,
  }))

  const [modalMeetingAttendance, setModalMeetingAttendance] = useState(false)
  const [modalMeetingAttendanceUpdate, setModalMeetingAttendanceUpdate] =
    useState(false)
  const [onUpdateMeetingAttendance, setOnUpdateMeetingAttendance] = useState({})
  const [modalMeetingImage, setModalMeetingImage] = useState(false)
  const [modalMeetingImageUpdate, setModalMeetingImageUpdate] = useState(false)
  const [onUpdateMeetingImage, setOnUpdateMeetingImage] = useState({})

  const updateMeetingAttendanceModal = item => {
    setOnUpdateMeetingAttendance(item)
    setModalMeetingAttendanceUpdate(true)
  }

  const updateMeetingImageModal = item => {
    setOnUpdateMeetingImage(item)
    setModalMeetingImageUpdate(true)
  }

  const totalPages = Math.ceil(committeeMeetingAttendances?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const debounceMeetingAttendanceSearch = debounce(
    value => setSearchText(value),
    600
  )

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
      sort: true,
    },
    {
      dataField: "committee_member_name",
      text: `${language === "mal" ? "അംഗം" : "Member"}`,
      sort: true,
    },
    {
      dataField: "committee_member_designation",
      text: `${language === "mal" ? "പദവി" : "Designation"}`,
    },
    {
      dataField: "attendance_time",
      text: `${language === "mal" ? "സമയം" : "Time"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const committeeMeetingAttendancesData = map(
    committeeMeetingAttendances?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: index + 1,
      action: (
        <div className="m-0">
          {/* <Button
          type="button"
          color="white"
          className=" btn m-0 p-0"
          onClick={() => updateMeetingAttendanceModal(item)}
        >
          <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} />
        </Button> */}
          {/* <Link to={`/institutes/${item?.id}`} className="btn-light btn-sm">
          View
        </Link> */}
          <Button
            type="button"
            color="primary"
            className=" btn w-xs btn-sm"
            onClick={() => updateMeetingAttendanceModal(item)}
          >
            {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
            Edit
          </Button>
        </div>
      ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  useEffect(() => {
    if (committeeMeetingDetail?.id) {
      dispatch(
        getCommitteeMeetingAttendances(
          committeeMeetingDetail?.id,
          searchText,
          pageSend()
        )
      )
    }
  }, [committeeMeetingDetail, searchText, page])

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(
      deleteCommitteeMeeting(
        committeeMeetingDetail.id,
        committeeMeetingDetail?.committee,
        history
      )
    )
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getCommitteeMeetingDetail(params.id))
  }, [])

  useEffect(() => {
    if (committeeMeetingDetail?.id) {
      dispatch(getCommitteeMeetingImages(committeeMeetingDetail?.id))
    }
  }, [committeeMeetingDetail])

  const handleWindowSizeVsSliderPreview = () => {
    if (width <= 480) {
      return 1
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 3.5
    } else return 3.5
  }

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <UiModalMeetingAttendance
        show={modalMeetingAttendance}
        onCloseclick={() => setModalMeetingAttendance(false)}
        history={history}
      />
      <UiModalMeetingAttendanceUpdate
        show={modalMeetingAttendanceUpdate}
        onCloseclick={() => setModalMeetingAttendanceUpdate(false)}
        data={onUpdateMeetingAttendance}
        history={history}
      />
      <UiModalMeetingImage
        show={modalMeetingImage}
        onCloseclick={() => setModalMeetingImage(false)}
        history={history}
      />
      <UiModalMeetingImageUpdate
        show={modalMeetingImageUpdate}
        onCloseclick={() => setModalMeetingImageUpdate(false)}
        data={onUpdateMeetingImage}
        history={history}
      />

      <MetaTags>
        <title>Committee Meeting | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal" ? "യോഗത്തിന്റെ വിശദാംശങ്ങൾ" : "Meeting Details"
          }
          breadcrumbItem={language === "mal" ? "യോഗം" : "Meeting"}
        />
        <Container fluid>
          {loading ? (
            <Spinner color="secondary" className="d-block m-auto" />
          ) : (
            <>
              <div className="container-fluid">
                <Row>
                  <Col md="12" lg="12">
                    <Card>
                      <CardBody>
                        {/* <Badge className="rounded-pill bg-dark font-size-14">
                      {mahallDetail?.custom_id}
                    </Badge> */}
                        {detailLoading ? (
                          <Spinner />
                        ) : (
                          <Row>
                            <Col md="12">
                              <h5 className="font-size-22 font-weight-bold mt-1">
                                {committeeMeetingDetail?.title}
                              </h5>
                            </Col>
                            <Col md={6}>
                              <Col md="6" sm="12">
                                <div className="table-responsive mt-4">
                                  <Table className="table mb-0">
                                    <tbody>
                                      <tr>
                                        <td>
                                          {language === "mal"
                                            ? "തീയതി"
                                            : "Date"}
                                        </td>
                                        <td>
                                          {": "}
                                          {moment(
                                            committeeMeetingDetail?.meeting_date
                                          ).format("DD-MM-YYYY")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {language === "mal"
                                            ? "സ്ഥലം"
                                            : "place"}
                                        </td>
                                        <td>
                                          {": "}
                                          {committeeMeetingDetail?.place}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {language === "mal"
                                            ? "ഹാജർ %"
                                            : "Attendance %"}
                                        </td>
                                        <td>
                                          {": "}
                                          {
                                            committeeMeetingDetail?.attandance_percentage
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {language === "mal"
                                            ? "വിവരണം"
                                            : "Description"}
                                        </td>
                                        <td>
                                          {": "}
                                          {committeeMeetingDetail?.description}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            </Col>
                            <Col md={6}>
                              <h5>{language === "mal" ? "അജണ്ട" : "Agenda"}</h5>
                              <p className="p-3">
                                <Markup
                                  content={committeeMeetingDetail?.agenda}
                                />
                              </p>
                            </Col>
                            {/* <Col md="12">
                          <div className="mt-3 mx-2">
                            <h5>Report</h5>
                            <p className="p-3"><Markup content={committeeMeetingDetail?.report} /></p>
                          </div>
                        </Col> */}
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="12">
                    <Card>
                      <CardBody>
                        <Col md="12">
                          <div className="mt-3 mx-2">
                            <h4
                              style={{ textDecorationLine: "underline" }}
                              className="text-center"
                            >
                              {language === "mal" ? "റിപ്പോർട്ട്" : "Report"}
                            </h4>
                            <p className="p-3">
                              <Markup
                                content={committeeMeetingDetail?.report}
                              />
                            </p>
                          </div>
                        </Col>
                        <Row className="task-dates">
                          <Col sm="12" xs="12">
                            <div className="d-flex justify-content-end mt-5">
                              <div className="">
                                <Link
                                  to={`/committee/meeting/update/${committeeMeetingDetail?.id}`}
                                  className={`btn btn-primary w-md ${
                                    loading && "disabled"
                                  } btn-m`}
                                >
                                  {language === "mal"
                                    ? "അപ്ഡേറ്റ് ചെയ്യുക"
                                    : "Update"}
                                </Link>
                              </div>
                              <div
                                style={{ position: "relative", left: "10px" }}
                              >
                                <Link
                                  style={{ opacity: 0.8 }}
                                  to="#"
                                  className={`btn btn-danger w-md ${
                                    loading && "disabled"
                                  }  btn-m`}
                                  onClick={handleDelete}
                                >
                                  {language === "mal"
                                    ? "ഡിലീറ്റ് ചെയ്യുക"
                                    : "Delete"}
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <Row>
                <div className="d-flex">
                  <h5>{language === "mal" ? "ഫോട്ടോകൾ" : "Images"}</h5>
                  <div>
                    <Button
                      onClick={() => setModalMeetingImage(true)}
                      className="btn btn-success btn-sm mx-3"
                    >
                      {language === "mal" ? "+ചേർക്കുക" : "+Add"}
                    </Button>
                  </div>
                </div>

                {/* <Col md="12" className="gallery">
              {committeeMeetingImages?.results?.map((item, key) => (
                <div key={key} className="mt-2 ">
                  <img onClick={() => updateMeetingImageModal(item)}
                    className="gallery-img" src={item?.image ? item?.image : image} alt={image} />
                  <h5 className="text-center text-muted">{item?.title}</h5>
                </div>
              ))}
            </Col> */}

                <div className="carausel-4-columns-cover card-product-small arrow-center position-relative">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={handleWindowSizeVsSliderPreview()}
                    grid={{
                      rows: 2,
                    }}
                    keyboard={{ enabled: true }}
                    direction="horizontal"
                    mousewheel={true}
                    navigation={{
                      prevEl: ".custom_prev_f",
                      nextEl: ".custom_next_f",
                    }}
                    autoplay={true}
                    speed={600}
                    className=""
                    breakpoints={{
                      480: {
                        slidesPerView: 1,
                      },
                      640: {
                        slidesPerView: 2.5,
                      },
                      768: {
                        slidesPerView: 3,
                      },
                      1024: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {committeeMeetingImages?.results?.map((item, i) => (
                      <SwiperSlide key={i}>
                        <div>
                          <img
                            src={item?.image}
                            alt=""
                            width={"100%"}
                            height={"200px"}
                            style={{
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => updateMeetingImageModal(item)}
                          />
                          <p className="m-0 py-2">{item?.title}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                    <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow">
                      <span className="slider-btn slider-prev slick-arrow custom_prev_f ">
                        <i className="bx bx-left-arrow-alt"></i>
                      </span>
                      <span className="slider-btn slider-next slick-arrow custom_next_f">
                        <i className="bx bx-right-arrow-alt"></i>
                      </span>
                    </div>
                  </Swiper>
                </div>

                {/* <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  slidesPerView={handleImageSize()}
                  grid={{
                    rows: 1,
                  }}
                  className="mySwiper"
                >
                  {committeeMeetingImages?.results?.map((item, key) => (
                    <SwiperSlide key={key} className="">
                      <div className="mt-5" style={{ minHeight: "280px" }}>
                        <img
                          onClick={() => updateMeetingImageModal(item)}
                          className="gallery-img"
                          src={item?.image ? item?.image : image}
                          alt={image}
                        />
                        <h5 className="text-center text-muted">
                          {item?.title}
                        </h5>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper> */}
              </Row>
              <Row>
                <h5 className="pt-3">
                  {language === "mal" ? "അറ്റന്റൻസ് :" : "Attendance :"}
                </h5>
                <Col lg="12" className="">
                  <React.Fragment>
                    <Row style={{ display: "flex" }}>
                      <Col className="col-lg-12 col-md-12">
                        <Card>
                          <CardBody>
                            <ToolkitProvider
                              keyField="id"
                              columns={columns}
                              data={committeeMeetingAttendancesData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <form
                                            className="app-search d-lg-block"
                                            onChange={e =>
                                              debounceMeetingAttendanceSearch(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <div className="position-relative">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={
                                                  language === "mal"
                                                    ? "തിരയുക..."
                                                    : "Search..."
                                                }
                                                defaultValue={searchText}
                                              />
                                              <span className="bx bx-search-alt" />
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="8">
                                      <div className="d-flex justify-content-end">
                                        <Button
                                          onClick={() =>
                                            setModalMeetingAttendance(true)
                                          }
                                          type="button"
                                          color="primary"
                                          className="btn-sm"
                                        >
                                          {language === "mal"
                                            ? "+ ഹാജർ ചേർക്കുക"
                                            : "+ Add Attendance"}
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  {loading ? (
                                    <Spinner
                                      color="secondary"
                                      className="d-block m-auto"
                                    />
                                  ) : (
                                    <>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField={"id"}
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={defaultSorted}
                                              selectRow={selectRow}
                                              classes={
                                                "table align-middle table-nowrap table-hover"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <MyPagination
                                        totalPages={pages}
                                        page={page}
                                        setPage={setPage}
                                      />
                                    </>
                                  )}
                                  {committeeMeetingAttendances &&
                                    committeeMeetingAttendances?.results &&
                                    committeeMeetingAttendances?.results
                                      ?.length <= 0 && (
                                      <p
                                        style={{ fontSize: "15px" }}
                                        className="text-center text-info"
                                      >
                                        No Attendances Yet
                                      </p>
                                    )}
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </React.Fragment>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </>
  )
}

export default InstituteCommitteeMeetingDetails

InstituteCommitteeMeetingDetails.propTypes = {
  history: PropTypes.object,
}
