import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import Select from "react-select"
import { Col, Modal, Button, Label } from "reactstrap"

import { createDivision, getStaffDropdown } from "store/actions"

const UiModalDivision = ({ show, onCloseclick, history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, language, staffList, instituteId } = useSelector(state => ({
    loading: state.Divisions.loading,
    language: state.Layout.language,
    staffList: state.Staffs.staffList,
    instituteId: state.Classes.classDetail?.institute,
  }))

  const [searchText, setSearchText] = useState("")
  const [staff, setStaff] = useState("Select staff...")
  const [staffId, setStaffId] = useState()

  const classId = params?.id

  const optionGroup = [
    {
      options: staffList?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setStaffId(event.value)
    setStaff(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const createDivisionData = {
      madrasa_class: classId,
      division_name: values.division_name,
      class_teacher: staffId,
      // description: values.description,
    }
    // console.log(createDivisionData);
    dispatch(createDivision(createDivisionData, history))
    setStaff("Select staff...")
    setStaffId("")
  }

  useEffect(() => {
    dispatch(getStaffDropdown(searchText, instituteId))
  }, [dispatch, searchText, instituteId])

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "ഡിവിഷൻ ചേർക്കുക" : "Add Division"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "ഡിവിഷന്റെ പേര്" : "Division Name"}
                    {/* <span className="text-danger">&#42;</span> */}
                  </Label>
                  <AvField
                    name="division_name"
                    type="text"
                    // validate={{
                    //   required: { value: true },
                    // }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>
                    {language === "mal" ? "അധ്യാപകൻ" : "Class Teacher"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={staff}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Family Member"
                    required="required"
                  />
                </Col>
              </div>
              {/* <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    label={language === 'mal' ? 'വിവരണം' : 'Description'}
                  />
                </Col>
              </div> */}
              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalDivision
UiModalDivision.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
