import React, { useState, useEffect } from "react"
import {
  Badge,
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
//actions institute
//components
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import UiModalZakatUpdate from "./UiModalZakatUpdate"
import UiModalZakatItemUpdate from "./UiModalZakatItemUpdate"
import UiModalZakatItemCreate from "./UiModalZakatItemCreate"

import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getZakatDetail } from "store/actions"

const MahallZakatDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const [searchText, setSearchText] = useState("")

  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const { zakatDetail, loading, language, zakatItems } = useSelector(state => ({
    zakatDetail: state.Zakats.zakatDetail,
    language: state.Layout.language,
    zakats: state.Zakats.zakats,
    loading: state.Zakats.loading,
    zakatItems: state.Zakats.zakatDetail?.zakat_item,
  }))

  // const [isOpen, setIsOpen] = useState(false)

  const [modalZakatItem, setModalZakatItem] = useState(false)
  const [modalZakatItemUpdate, setModalZakatItemUpdate] = useState(false)
  const [onUpdateZakatItem, setOnUpdateZakatItem] = useState({})
  const [modalZakatUpdate, setModalZakatUpdate] = useState(false)

  const updateZakatItemModal = item => {
    setOnUpdateZakatItem(item)
    setModalZakatItemUpdate(true)
  }

  const totalPages = Math.ceil(zakatItems?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  // useEffect(() => {
  //   if (zakatDetail?.id) {
  //     dispatch(getZakatItems(searchText, page, zakatDetail?.id))
  //     // &start_date=2022-09-01&end_date=2022-09-01
  //   }
  // }, [page, searchText, zakatDetail])

  const cardData = [
    {
      title: `${language === "mal" ? "ആകെ സകാത്ത്" : "Total ZakatItems"}`,
      cardValue: zakatDetail?.zakat_item_count,
      iconClass: "error-circle",
      routeLink: "#!",
      loading: loading,
      alt: `${language === "mal" ? "സകാത് ഇനങ്ങൾ ഇല്ല" : "No Zakat Items"}`,
    },
    {
      title: `${language === "mal" ? "നൽകിയത്" : "Zakats Given"}`,
      cardValue: zakatDetail?.is_given_count,
      iconClass: "error-circle",
      routeLink: "#!",
      loading: loading,
      alt: `${language === "mal" ? "നൽകിയ സകാതുകൾ ഇല്ല" : "No Zakats Given"}`,
    },
    // {
    //   title: "Unpaid",
    //   cardValue: ZakatItemCards?.total_fee_not_paid_Zakats,
    //   iconClass: "check-circle",
    //   routeLink: "#!",
    //   loading: loading,
    //   alt: "No unpaid Zakats",
    // },
  ]

  // const handleDelete = () => {
  //   setIsOpen(true)
  // }
  // const handleDeleteEvent = () => {
  //   dispatch(deleteLedger(ledgerDetail?.id, history))
  //   setIsOpen(false)
  // }

  useEffect(() => {
    dispatch(getZakatDetail(params.id))
  }, [])

  const debounceMahallZakatItemSearch = debounce(
    value => setSearchText(value),
    600
  )

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "house_name",
      text: language === "mal" ? "കുടുംബം" : "Family",
    },
    {
      dataField: "quantity",
      text: language === "mal" ? "സകാത്ത് അളവ്" : "Quantity",
    },
    {
      dataField: "statusData",
      text: language === "mal" ? "സ്റ്റാറ്റസ്" : "Status",
    },
    {
      dataField: "action",
      text: language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    },
  ]

  const zakatItemsData = map(zakatItems, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    action: (
      <div className="m-0">
        {/* <Link to={`/zakats/${item?.id}`} className="btn-sm">
          <i
            className="bx bx-show me-2"
            style={{ color: "green", fontSize: "18px" }}
          />
        </Link> */}
        {/* <Link to={`/zakatItem/update/${item?.id}`} className="m-0">
          <Button
            type="button"
            color="white"
            className=" btn m-0 p-0"
          >
            <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> 
            View
          </Button>
        </Link> */}
        <div>
          <Button
            type="button"
            color="primary"
            className="btn-sm"
            onClick={() => updateZakatItemModal(item)}
          >
            {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
          </Button>
        </div>

        {/* <Button
          type="button"
          color="white"
          className=" m-0 btn "
          onClick={() => { handleDelete(item.id) }}
        >
          <i className="bx bx-trash me-2" style={{ color: "red", fontSize: "18px" }} />
        </Button> */}
      </div>
    ),

    statusData: (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          `${item?.is_given == true ? "success" : "warning"}`
        }
        color={"primary"}
        pill
      >
        {`${item?.is_given ? "given" : "pending"}`}
      </Badge>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  return (
    <>
      {/* <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      /> */}
      <UiModalZakatItemCreate
        show={modalZakatItem}
        onCloseclick={() => setModalZakatItem(false)}
        history={history}
      />
      <UiModalZakatItemUpdate
        show={modalZakatItemUpdate}
        onCloseclick={() => setModalZakatItemUpdate(false)}
        data={onUpdateZakatItem}
        history={history}
      />
      <UiModalZakatUpdate
        show={modalZakatUpdate}
        onCloseclick={() => setModalZakatUpdate(false)}
        history={history}
      />
      {/* <UiModalZakatUpdate
        show={modalZakatUpdate}
        onCloseclick={() => setModalZakatUpdate(false)}
        history={history}
      /> */}

      <MetaTags>
        <title>Zakat | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "എല്ലാ സകാത്തുകളും " : "All Zakats"}
          breadcrumbItem={language === "mal" ? "സകാത്ത്" : "Zakat"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col className="col-lg-12 col-md-12">
                <Row>
                  <Col md="4">
                    <Card className="overflow-hidden bg-primary bg-soft">
                      <div className="">
                        <Row>
                          <div className="text-primary p-4">
                            <div className="px-2">
                              <Row>
                                <Col md="12">
                                  <h5 className="text-khaf">Zakat</h5>
                                  <h4 className="mt-4">{zakatDetail?.title}</h4>
                                  {/* <div className=" d-flex justify-content-end m-3">
                                    <Button type="button" className="btn-sm bg-khaf-blue text-white" onClick={() => setModalZakatUpdate(true)}>
                                      <i className="bx bx-edit text-center"></i>{language === "mal" ? " അപ്ഡേറ്റ്" : "Update"}</Button>
                                  </div> */}
                                  {/* <p className="text-muted">From {(moment(ledgerDetail?.ledgers?.date_added).format("DD/MM/YYYY"))}</p> */}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Row>
                      </div>
                      <div className=" d-flex justify-content-end m-3">
                        <Button
                          type="button"
                          className="btn-sm bg-khaf-blue text-white"
                          onClick={() => setModalZakatUpdate(true)}
                        >
                          <i className="bx bx-edit text-center"></i>
                          {language === "mal" ? " അപ്ഡേറ്റ്" : "Update"}
                        </Button>
                      </div>
                    </Card>
                  </Col>
                  <Col md="8">
                    <Row>
                      {cardData?.map((item, key) => (
                        <Col sm="12" md="6" lg="6" key={key} className="sm:p-0">
                          <Card className="blog-stats-wid">
                            <CardBody>
                              <div className="d-flex flex-wrap">
                                <div className="me-3">
                                  <h5 className="text-muted">{item.title}</h5>
                                  <h4 className={`${item.className}`}>
                                    {item.cardValue}
                                  </h4>
                                </div>
                                <div className="avatar-sm ms-auto">
                                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                    <i
                                      className={
                                        "bx bx-" +
                                        `${item.iconClass} ${item.className}`
                                      }
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="">
                <React.Fragment>
                  <Row style={{ minHeight: "600px", display: "flex" }}>
                    <Col className="col-lg-12 col-md-12">
                      <Card>
                        <CardBody>
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={zakatItemsData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <form
                                          className="app-search d-lg-block"
                                          onChange={e =>
                                            debounceMahallZakatItemSearch(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search..."
                                              defaultValue={searchText}
                                            />
                                            <span className="bx bx-search-alt" />
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="8">
                                    <div className="d-flex justify-content-end">
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="btn-sm"
                                        onClick={() => setModalZakatItem(true)}
                                      >
                                        {language === "mal"
                                          ? "+ എൻട്രി ചേർക്കുക"
                                          : "+ Add Zakat Item"}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                {loading ? (
                                  <Spinner
                                    color="secondary"
                                    className="d-block m-auto"
                                  />
                                ) : (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap table-hover"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <MyPagination
                                      totalPages={pages}
                                      page={page}
                                      setPage={setPage}
                                    />
                                  </>
                                )}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col className="col-lg-4 col-md-12" style={{minHeight:'600px'}}>
                      <Card className="" style={{minHeight:'500px'}}>
                        <CardBody>

                        </CardBody>
                      </Card>
                    </Col> */}
                  </Row>
                </React.Fragment>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default MahallZakatDetails

MahallZakatDetails.propTypes = {
  history: PropTypes.object,
}
