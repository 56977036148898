import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter, Link } from "react-router-dom"
import { GiVerticalBanner } from "react-icons/gi"

//i18n
import { withTranslation } from "react-i18next"

const AdminSidebarContent = props => {
  const ref = useRef()

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100 mb-5" ref={ref}>
        <div id="sidebar-menu" className="mt-4 mb-5">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="">
                <i className="bx bxs-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/donations" className="">
                <i className="bx bx-rupee"></i>
                <span>{props.t("Donations")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-cog"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/settings">{props.t("Preferences")}</Link>
                </li>
                <li>
                  <Link to="/countries">{props.t("Countries")}</Link>
                </li>
                <li>
                  <Link to="/states">{props.t("States")}</Link>
                </li>
                <li>
                  <Link to="/districts">{props.t("Districts")}</Link>
                </li>
                <li>
                  <Link to="/villages">{props.t("Villages")}</Link>
                </li>
                <li>
                  <Link to="/lsgs">{props.t("LSGs")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/admins" className="">
                <i className="bx bx-group"></i>
                <span>{props.t("Admins")}</span>
              </Link>
            </li>

            <li className="menu-title mt-3">{props.t("Data")} </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-receipt"></i>
                <span>{props.t("Enquiries")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/enquiries">{props.t("All Enquiries")}</Link>
                </li>
                <li>
                  <Link to="/enquiry/create">{props.t("Create Enquiry")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-map-alt"></i>
                <span>{props.t("Mahalls")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/mahalls">{props.t("All Mahalls")}</Link>
                </li>
                <li>
                  <Link to="/mahall/create">{props.t("Create Mahall")}</Link>
                </li>
                <li>
                  <Link to="/mahallusers">{props.t("Mahall Users")}</Link>
                </li>
                <li>
                  <Link to="/mahalluser/create">
                    {" "}
                    {props.t("Create Mahall User")}
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li className="menu-title mt-3">{props.t("Data")} </li> */}

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-globe"></i>
                <span>{props.t("Groups")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/groups">{props.t("All Groups")}</Link>
                </li>
                <li>
                  <Link to="/group/create">{props.t("Create Group")}</Link>
                </li>
                <li>
                  <Link to="/groupadmins">{props.t("Group Admins")}</Link>
                </li>
                <li>
                  <Link to="/groupadmin/create">
                    {props.t("Create Group Admin")}
                  </Link>
                </li>
              </ul>
            </li>
            {/*  Super Feed */}
            <li>
              <Link to="/super-feeds">
                <i className="bx bx-slideshow"></i>
                <span>{props.t("Super Feeds")}</span>
              </Link>
            </li>
            {/* super banners */}
            <li>
              <Link to="/super-banners">
                <i className="fas fa-list-alt"></i>
                <span>{props.t("Super Banners")}</span>
              </Link>
            </li>

            {/* Activity Logs */}
            <li>
              <Link to="/admin/activitylogs">
                <i className="bx bx-history"></i>
                <span>{props.t("Activity Logs")}</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-conversation"></i>
                <span>{props.t("Notifications")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="!#">{props.t("All Notifications")}</Link>
                </li>
                <li>
                  <Link to="!#">{props.t("Create Notification")}</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home"></i>
                <span>{props.t("Families")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/families">{props.t("All Families")}</Link>
                </li>
                <li>
                  <Link to="/family/create">{props.t("Create Family")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/familymembers">
                <i className="bx bx-group"></i>
                <span>{props.t("Family Members")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-chevron-up-circle"></i>
                <span>{props.t("Institutes")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/institutes">{props.t("All Institutes")}</Link>
                </li>
                <li>
                  <Link to="/institute/create">
                    {props.t("Create Institute")}
                  </Link>
                </li>
                <li>
                  <Link to="/instituteusers">{props.t("Institute Users")}</Link>
                </li>
                <li>
                  <Link to="/instituteuser/create">
                    {props.t("Create Institute User")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-task"></i>
                <span>{props.t("Accounts")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/accounts">{props.t("All Accounts")}</Link>
                </li>
                <li>
                  <Link to="/account/create">{props.t("Create Account")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-chat"></i>
                <span>{props.t("Committees")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/committees">{props.t("All Committees")}</Link>
                </li>
                <li>
                  <Link to="/committee/create">
                    {props.t("Create Committee")}
                  </Link>
                </li>
                <li>
                  <Link to="/meetings">{props.t("All Meetings")}</Link>
                </li>
                <li>
                  <Link to="/meeting/create">{props.t("Create Meeting")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-folder-open"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/reports">{props.t("All Reports")}</Link>
                </li>
                <li>
                  <Link to="/report/create">{props.t("Create Report")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-certification"></i>
                <span>{props.t("Certificates")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/certificates">{props.t("All Certificates")}</Link>
                </li>
                <li>
                  <Link to="/certificate/create">
                    {props.t("Create Certificate")}
                  </Link>
                </li>
              </ul>
            </li> */}

            {/* {map(mySideBar, (item, index) => (
              <Fragment key={index}>
                {item?.headingSaparetor && (
                  <li className="menu-title mt-3">
                    {props.t(`${item?.headingSaparetor}`)}{" "}
                  </li>
                )}
                <li>
                  {item?.heading && (
                    <Link
                      to="/#"
                      className={`${item?.badgeValue ? "" : "has-arrow"} `}
                    >
                      <i className={`${item.iconClass}`}></i>
                      <span>{props.t(`${item?.heading}`)}</span>
                      <span
                        className={`badge rounded-pill float-end bg-${item?.badgeClass}`}
                      >
                        {item?.badgeValue}
                      </span>
                    </Link>
                  )}
                  {/* {item?.heading && (
                    <>
                      <Link
                        to="#!"
                        className={`myFLex ${
                          item?.badgeValue ? "" : "has-arrow"
                        } `}
                      >
                        <div>
                          <i className={`${item.iconClass}`}></i>
                          <span>{props.t(`${item?.heading}`)}</span>
                          <span
                            className={`badge rounded-pill float-end bg-${item?.badgeClass}`}
                          >
                            {item?.badgeValue}
                          </span>
                        </div>
                      </Link>
                    </>
                  )} */}
            {/* <ul className="sub-menu" aria-expanded="false">
              {map(item?.subTitles, (title, index1) => (
                <li key={index1}>
                  <Link to={title?.routeLink}>
                    {props.t(`${title?.title}`)}
                  </Link>
                </li>
              ))}
            </ul> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

AdminSidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AdminSidebarContent))
