/* STAFFS */
export const GET_STAFFS = "GET_STAFFS"
export const GET_STAFFS_SUCCESS = "GET_STAFFS_SUCCESS"
export const GET_STAFFS_FAIL = "GET_STAFFS_FAIL"

/* STAFF_DROPDOWN*/
export const GET_STAFF_DROPDOWN = "GET_STAFF_DROPDOWN"
export const GET_STAFF_DROPDOWN_SUCCESS = "GET_STAFF_DROPDOWN_SUCCESS"
export const GET_STAFF_DROPDOWN_FAIL = "GET_STAFF_DROPDOWN_FAIL"

/* STAFF  DETAIL*/
export const GET_STAFF_DETAIL = "GET_STAFF_DETAIL"
export const GET_STAFF_DETAIL_SUCCESS = "GET_STAFF_DETAIL_SUCCESS"
export const GET_STAFF_DETAIL_FAIL = "GET_STAFF_DETAIL_FAIL"

/**
 * add STAFF
 */
export const CREATE_STAFF = "CREATE_STAFF"
export const CREATE_STAFF_SUCCESS = "CREATE_STAFF_SUCCESS"
export const CREATE_STAFF_FAIL = "CREATE_STAFF_FAIL"

/**
 * Edit STAFF
 */
export const UPDATE_STAFF = "UPDATE_STAFF"
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS"
export const UPDATE_STAFF_FAIL = "UPDATE_STAFF_FAIL"

/**
 * Delete STAFF
 */
export const DELETE_STAFF = "DELETE_STAFF"
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS"
export const DELETE_STAFF_FAIL = "DELETE_STAFF_FAIL"
