import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_EDUCATION_FAMILY_MEMBER,
  GET_EDUCATION_FAMILY_MEMBER_DETAIL,
  CREATE_EDUCATION_FAMILY_MEMBER,
  UPDATE_EDUCATION_FAMILY_MEMBER,
  DELETE_EDUCATION_FAMILY_MEMBER,
} from "./actionTypes"
import {
  getEducationFamilyMemberSuccess,
  getEducationFamilyMemberFail,
  getEducationFamilyMemberDetailSuccess,
  getEducationFamilyMemberDetailFail,
  createEducationFamilyMemberSuccess,
  createEducationFamilyMemberFail,
  updateEducationFamilyMemberSuccess,
  updateEducationFamilyMemberFail,
  deleteEducationFamilyMemberSuccess,
  deleteEducationFamilyMemberFail,
} from "./actions"

import {
  getFamilyMemberDetailSuccess,
  getFamilyMemberDetailFail,
} from "store/actions"
import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getEducationFamilyMemberAPi = ({ searchText, familyMemberId }) => {
  if (searchText) {
    return get(
      `/api/v1/familydetails/education/?familymember=${familyMemberId}?search=${
        searchText && searchText
      }`
    )
  } else {
    return get(`/api/v1/familydetails/education/`)
  }
}
const getFamilyMemberDetailsAPi = familyMemberId => {
  return get(`/api/v1/familydetails/familymember/${familyMemberId}/`)
}
const getEducationFamilyMemberDetailsAPi = familyMemberId => {
  return get(`/api/v1/familydetails/education/${familyMemberId}/`)
}
const createEducationFamilyMemberApi = ({ education }) => {
  return post("/api/v1/familydetails/education/", education)
}
const updateEducationFamilyMemberApi = ({ education, educationId }) => {
  return ApiPut(`/api/v1/familydetails/education/${educationId}/`, education)
}
const deleteEducationFamilyMemberApi = ({ educationId }) => {
  return del(`/api/v1/familydetails/education/${educationId}/`)
}

function* fetchEducationFamilyMember({ payload }) {
  try {
    const response = yield call(getEducationFamilyMemberAPi, payload)
    if (response && !response?.error) {
      yield put(getEducationFamilyMemberSuccess(response))
    } else {
      yield put(getEducationFamilyMemberFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getEducationFamilyMemberFail(error))
  }
}

function* fetchEducationFamilyMemberDetail({ familyMemberId }) {
  try {
    const response = yield call(
      getEducationFamilyMemberDetailsAPi,
      familyMemberId
    )
    if (response && !response?.error) {
      yield put(getEducationFamilyMemberDetailSuccess(response))
    } else {
      yield put(getEducationFamilyMemberDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getEducationFamilyMemberDetailFail(error))
  }
}
function* onCreateEducationFamilyMember({ payload }) {
  try {
    const response = yield call(createEducationFamilyMemberApi, payload)
    if (response && !response?.error) {
      yield put(createEducationFamilyMemberSuccess(response))
      payload.history.push(`/familymembers/${response?.family_member}`)
      doneNotification("Added Successfully")
      try {
        const response2 = yield call(
          getFamilyMemberDetailsAPi,
          response?.family_member
        )
        yield put(getFamilyMemberDetailSuccess(response2))
      } catch (error) {
        yield put(getFamilyMemberDetailFail(error))
      }
    } else {
      yield put(createEducationFamilyMemberFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createEducationFamilyMemberFail(error))
    errorNotification()
  }
}

function* onUpdateEducationFamilyMember({ payload }) {
  try {
    const response = yield call(updateEducationFamilyMemberApi, payload)
    if (response && !response?.error) {
      yield put(updateEducationFamilyMemberSuccess(response))
      payload.history.push(`/familymembers/${response?.family_member}`)
      try {
        const response2 = yield call(
          getFamilyMemberDetailsAPi,
          response?.family_member
        )
        yield put(getFamilyMemberDetailSuccess(response2))
      } catch (error) {
        yield put(getFamilyMemberDetailFail(error))
      }
      doneNotification("Updated Successfully")
    } else {
      yield put(updateEducationFamilyMemberFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateEducationFamilyMemberFail(error))
    errorNotification()
  }
}

function* onDeleteEducationFamilyMember({ payload }) {
  try {
    const response = yield call(deleteEducationFamilyMemberApi, payload)
    if (response && !response?.error) {
      payload.history.push("/educations")
      yield put(deleteEducationFamilyMemberSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteEducationFamilyMemberFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteEducationFamilyMemberFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* EducationFamilyMemberSaga() {
  yield takeEvery(GET_EDUCATION_FAMILY_MEMBER, fetchEducationFamilyMember)
  yield takeEvery(
    GET_EDUCATION_FAMILY_MEMBER_DETAIL,
    fetchEducationFamilyMemberDetail
  )
  yield takeEvery(CREATE_EDUCATION_FAMILY_MEMBER, onCreateEducationFamilyMember)
  yield takeEvery(UPDATE_EDUCATION_FAMILY_MEMBER, onUpdateEducationFamilyMember)
  yield takeEvery(DELETE_EDUCATION_FAMILY_MEMBER, onDeleteEducationFamilyMember)
}

export default EducationFamilyMemberSaga
