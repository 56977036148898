import React, { useState, Fragment, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Badge,
  Label,
  Table,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

//actions familymember

//componetns
import UiModalEducation from "./UiModalEducation"
import UiModalEducationUpdate from "./UiModalEducationUpdate"
import UiModalJob from "./UiModalJob"
import UiModalJobUpdate from "./UiModalJobUpdate"

import DeleteModal from "components/Common/DeleteModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  deleteUnapprovedFamilyMember,
  getUnapprovedFamilyMemberDetail,
} from "store/actions"

const UnapprovedFamilyMemberDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { unapprovedFamilyMemberDetail, loading, detailLoading, language } =
    useSelector(state => ({
      loading: state.FamilyMembers.loading,
      detailLoading: state.FamilyMembers.detailLoading,
      unapprovedFamilyMemberDetail:
        state.UnapprovedFamilyMembers.unapprovedFamilyMemberDetail,
      language: state.Layout.language,
    }))

  const [isOpen, setIsOpen] = useState(false)
  const [modalEducation, setModalEducation] = useState(false)
  const [modalEducationUpdate, setModalEducationUpdate] = useState(false)
  const [modalJob, setModalJob] = useState(false)
  const [modalJobUpdate, setModalJobUpdate] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(
      deleteUnapprovedFamilyMember(unapprovedFamilyMemberDetail?.id, history)
    )
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getUnapprovedFamilyMemberDetail(params?.id))
  }, [])

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    const startDate = moment(today)
    const timeEnd = moment(birthDate)
    const diff = startDate.diff(timeEnd)
    var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
    // console.log("diff:", diff / (365 * 24 * 60 * 60 * 1000))
    // console.log("age: ", age)
    if (age < 0) age = 0
    return age
  }

  const [onUpdateEducation, setOnUpdateEducation] = useState({})
  const [onUpdateJob, setOnUpdateJob] = useState({})

  const updateEducationModal = item => {
    setOnUpdateEducation(item)
    setModalEducationUpdate(true)
  }

  const updateJobModal = item => {
    setOnUpdateJob(item)
    setModalJobUpdate(true)
  }

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <UiModalEducation
        show={modalEducation}
        onCloseclick={() => setModalEducation(false)}
        history={history}
      />
      <UiModalEducationUpdate
        show={modalEducationUpdate}
        onCloseclick={() => setModalEducationUpdate(false)}
        data={onUpdateEducation}
        history={history}
      />
      <UiModalJob
        show={modalJob}
        onCloseclick={() => setModalJob(false)}
        history={history}
      />
      <UiModalJobUpdate
        show={modalJobUpdate}
        onCloseclick={() => setModalJobUpdate(false)}
        data={onUpdateJob}
        history={history}
      />

      <MetaTags>
        <title>Family Member | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal" ? "എല്ലാ കുടുംബാംഗങ്ങളും" : "All Family Members"
          }
          breadcrumbItem={language === "mal" ? "കുടുംബാംഗം" : "Family Member"}
        />
        <Container fluid className="sm:p-0">
          <div className="container-fluid">
            {detailLoading ? (
              <Spinner />
            ) : (
              <Row>
                <Card className="sm:p-0">
                  <CardBody>
                    <Badge
                      className="rounded-pill bg-warning"
                      style={{ fontSize: "12px" }}
                    >
                      {unapprovedFamilyMemberDetail?.mahall_custom_id}
                    </Badge>
                    <Row>
                      <Col md={6}>
                        <h4 className="font-size-12  mt-4">
                          {unapprovedFamilyMemberDetail?.family_name ? (
                            <Link
                              to={`/families/${unapprovedFamilyMemberDetail?.family}`}
                            >
                              {unapprovedFamilyMemberDetail?.family_name}
                            </Link>
                          ) : (
                            unapprovedFamilyMemberDetail?.family_name
                          )}
                        </h4>
                        <h4 className="font-size-18 text-black font-weight-bold">
                          {unapprovedFamilyMemberDetail?.full_name}
                        </h4>

                        <div>
                          <Badge className="me-1 badge-soft-primary font-size-12">
                            {getAge(unapprovedFamilyMemberDetail?.dob)} |{" "}
                            {unapprovedFamilyMemberDetail?.gender}
                          </Badge>
                          <Badge className="me-1 rounded-pill bg-danger font-size-12">
                            {unapprovedFamilyMemberDetail?.blood_group?.english}
                          </Badge>
                          {unapprovedFamilyMemberDetail?.is_working ? (
                            <Badge className="me-1 rounded-pill bg-info font-size-12">
                              {language === "mal" ? "തൊഴിലാളി" : "Worker"}
                            </Badge>
                          ) : (
                            <></>
                          )}

                          {unapprovedFamilyMemberDetail?.is_abroad ? (
                            <Badge className="me-1 rounded-pill bg-warning font-size-12">
                              {language === "mal" ? "പ്രവാസി" : "Abroad"}
                            </Badge>
                          ) : (
                            <></>
                          )}
                        </div>

                        <h5 className="font-size-14 mt-2">
                          {unapprovedFamilyMemberDetail?.father_name &&
                            (unapprovedFamilyMemberDetail?.gender === "male"
                              ? `S/o `
                              : `D/o `)}
                          {unapprovedFamilyMemberDetail?.father_id ? (
                            <Link
                              to={`/familymembers/${unapprovedFamilyMemberDetail?.father_id}`}
                            >
                              {unapprovedFamilyMemberDetail?.father_name}
                            </Link>
                          ) : (
                            unapprovedFamilyMemberDetail?.father_name
                          )}

                          {unapprovedFamilyMemberDetail?.mother_name && (
                            <>
                              {unapprovedFamilyMemberDetail?.mother_id ? (
                                <Link
                                  to={`/familymembers/${unapprovedFamilyMemberDetail?.mother_id}`}
                                >
                                  {unapprovedFamilyMemberDetail?.mother_name}
                                </Link>
                              ) : (
                                ` & ${unapprovedFamilyMemberDetail?.mother_name}`
                              )}
                            </>
                          )}
                        </h5>
                        <h5 className="font-size-14 mt-2">
                          {unapprovedFamilyMemberDetail?.spouse_name &&
                            `Married to ${unapprovedFamilyMemberDetail?.spouse_name}`}
                        </h5>
                        {unapprovedFamilyMemberDetail?.phone && (
                          <p className="mt-4">
                            <i className="bx bx-phone text-primary me-1" />
                            {unapprovedFamilyMemberDetail?.phone}
                          </p>
                        )}
                        {unapprovedFamilyMemberDetail?.email && (
                          <p>
                            <i className="bx bx-mail-send text-primary me-1" />
                            {unapprovedFamilyMemberDetail?.email}
                          </p>
                        )}
                      </Col>
                      <Col md={6}>
                        <div className="d-flex justify-content-end">
                          <Badge
                            className="rounded-pill bg-dark"
                            style={{ fontSize: "12px" }}
                          >
                            {unapprovedFamilyMemberDetail?.custom_id}
                          </Badge>
                        </div>
                        <img
                          style={{ width: "100px", height: "100px" }}
                          className="img-thumbnail rounded-circle"
                          src={unapprovedFamilyMemberDetail?.member_image}
                        />
                        {unapprovedFamilyMemberDetail?.id_card && (
                          <h5 className="font-size-13 text-muted font-weight-bold mt-4">
                            {language === "mal"
                              ? unapprovedFamilyMemberDetail?.id_card?.malayalam
                              : unapprovedFamilyMemberDetail?.id_card
                                  ?.english}{" "}
                            : {unapprovedFamilyMemberDetail?.id_card_number}
                          </h5>
                        )}
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={6}>
                        <h5 className="font-size-15 font-weight-bold">
                          {language === "mal"
                            ? "ആരോഗ്യ വിവരങ്ങൾ"
                            : "Health Details:"}
                        </h5>
                        <table className="mx-2">
                          <thead></thead>
                          <tbody>
                            <tr>
                              <td>
                                <i className="bx bx-health font-size-12 text-muted me-1" />{" "}
                                {language === "mal"
                                  ? "ആരോഗ്യ സ്ഥിതി"
                                  : "Health Status"}
                              </td>
                              <td>
                                {" :"}{" "}
                                {unapprovedFamilyMemberDetail?.health_condition?.map(
                                  (item, key) => {
                                    return (
                                      <Fragment key={key}>{`${
                                        key != 0 ? "," : ""
                                      } ${
                                        language === "mal"
                                          ? item.malayalam
                                          : item.english
                                      } `}</Fragment>
                                    )
                                  }
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <i className="bx bx-health font-size-12 text-muted me-1" />{" "}
                                {language === "mal"
                                  ? "രോഗത്തിന്റെ തരം"
                                  : "Disease Type"}{" "}
                              </td>
                              <td>
                                {" :"}{" "}
                                {unapprovedFamilyMemberDetail?.disease_type?.map(
                                  (item, key) => {
                                    return (
                                      <Fragment key={key}>{`${
                                        key != 0 ? "," : ""
                                      } ${
                                        language === "mal"
                                          ? item.malayalam
                                          : item.english
                                      } `}</Fragment>
                                    )
                                  }
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <i className="bx bx-health font-size-12 text-muted me-1" />{" "}
                                {language === "mal"
                                  ? "രോഗ നില"
                                  : "Disease Level"}
                              </td>
                              <td>
                                {" :"}{" "}
                                {language === "mal"
                                  ? unapprovedFamilyMemberDetail?.disease_level
                                      ?.malayalam
                                  : unapprovedFamilyMemberDetail?.disease_level
                                      ?.english}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <i className="bx bx-health font-size-12 text-muted me-1" />{" "}
                                {language === "mal"
                                  ? "വൈകല്യ തരം"
                                  : "Disability Type"}{" "}
                              </td>
                              <td>
                                {" :"}{" "}
                                {unapprovedFamilyMemberDetail?.disability_type?.map(
                                  (item, key) => {
                                    return (
                                      <Fragment key={key}>{`${
                                        key != 0 ? "," : ""
                                      } ${
                                        language === "mal"
                                          ? item.malayalam
                                          : item.english
                                      } `}</Fragment>
                                    )
                                  }
                                )}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                        <h5 className="font-size-15 font-weight-bold mt-2">
                          {language === "mal" ? "യോഗ്യത" : "Qualification :"}
                        </h5>
                        <table className="mx-2 ">
                          <thead></thead>
                          <tbody>
                            <tr>
                              <td>
                                <i className="bx bxs-graduation font-size-12 text-muted me-1" />{" "}
                                {language === "mal"
                                  ? "ഭൗതിക വിദ്യാഭ്യാസം"
                                  : "Formal"}
                              </td>
                              <td>
                                {" :"}{" "}
                                {language === "mal"
                                  ? unapprovedFamilyMemberDetail
                                      ?.educational_qualification_formal
                                      ?.malayalam
                                  : unapprovedFamilyMemberDetail
                                      ?.educational_qualification_formal
                                      ?.english}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <i className="bx bxs-graduation font-size-12 text-muted me-1" />{" "}
                                {language === "mal"
                                  ? "മത വിദ്യാഭ്യാസം"
                                  : "Islamic"}{" "}
                              </td>
                              <td>
                                {" :"}{" "}
                                {language === "mal"
                                  ? unapprovedFamilyMemberDetail
                                      ?.educational_qualification_islamic
                                      ?.malayalam
                                  : unapprovedFamilyMemberDetail
                                      ?.educational_qualification_islamic
                                      ?.english}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                        <h5 className="font-size-15 font-weight-bold mt-2">
                          {language === "mal"
                            ? "മറ്റുള്ളവ : "
                            : "Co-curricular : "}
                        </h5>
                        <table className="mx-2 ">
                          <thead></thead>
                          <tbody>
                            <tr>
                              <td>
                                <i className="bx bxs-graduation font-size-12 text-muted me-1" />{" "}
                                {language === "mal"
                                  ? "സാമൂഹിക പ്രവർത്തനങ്ങൾ"
                                  : "Social Activities"}
                              </td>
                              <td>
                                {" :"}{" "}
                                {unapprovedFamilyMemberDetail?.social_activity?.map(
                                  (item, key) => {
                                    return (
                                      <Fragment key={key}>{`${
                                        key != 0 ? "," : ""
                                      } ${
                                        language === "mal"
                                          ? item.malayalam
                                          : item.english
                                      } `}</Fragment>
                                    )
                                  }
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <i className="bx bxs-graduation font-size-12 text-muted me-1" />{" "}
                                {language === "mal" ? "കഴിവുകൾ" : "Skills"}{" "}
                              </td>
                              <td>
                                {" :"} {unapprovedFamilyMemberDetail?.skills}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <i className="bx bxs-graduation font-size-12 text-muted me-1" />{" "}
                                {language === "mal"
                                  ? "മോശം ശീലങ്ങൾ"
                                  : "Bad Habits"}{" "}
                              </td>
                              <td>
                                {" :"}{" "}
                                {unapprovedFamilyMemberDetail?.bad_habits?.map(
                                  (item, key) => {
                                    return (
                                      <Fragment key={key}>{`${
                                        key != 0 ? "," : ""
                                      } ${
                                        language === "mal"
                                          ? item.malayalam
                                          : item.english
                                      } `}</Fragment>
                                    )
                                  }
                                )}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot></tfoot>
                        </table>
                      </Col>
                      <Col md={6}>
                        <div>
                          <Label
                            style={{
                              borderTopLeftRadius: "5px",
                              borderTopRightRadius: "5px",
                              // border: '2px solid darkBlue'
                            }}
                            className="text-dark mb-0 mt-4"
                          >
                            {language === "mal"
                              ? "സ്ഥിരമായ വിലാസം :"
                              : "Permenent Address :"}
                          </Label>
                          <p className="mx-2 mt-1 d-flex">
                            <i className="bx bx-map-pin text-primary me-1" />{" "}
                            <p className="w-100">
                              {unapprovedFamilyMemberDetail?.family_place &&
                                `${unapprovedFamilyMemberDetail?.family_place}, `}
                              {unapprovedFamilyMemberDetail?.family_post_office &&
                                `${unapprovedFamilyMemberDetail?.family_post_office} post-`}
                              <br />
                              <span>
                                {unapprovedFamilyMemberDetail?.family_pin_code &&
                                  `${unapprovedFamilyMemberDetail?.family_pin_code}, `}
                                {unapprovedFamilyMemberDetail?.family_district &&
                                  `${unapprovedFamilyMemberDetail?.family_district}, `}
                                {unapprovedFamilyMemberDetail?.family_state &&
                                  `${unapprovedFamilyMemberDetail?.family_state}`}
                              </span>
                            </p>
                          </p>

                          <Label
                            style={{
                              borderTopLeftRadius: "5px",
                              borderTopRightRadius: "5px",
                            }}
                            className="text-dark mb-0 mt-1"
                          >
                            {language === "mal"
                              ? "ഇപ്പോഴത്തെ വിലാസം :"
                              : "Present Address :"}
                          </Label>
                          <p className="mx-2 mt-1 col-sm-12 col-md-6 d-flex">
                            <i className="bx bx-map-pin text-primary me-1" />{" "}
                            <p className="w-100">
                              {unapprovedFamilyMemberDetail?.present_address
                                ? unapprovedFamilyMemberDetail?.present_address
                                : "Same as above"}
                            </p>
                          </p>
                        </div>
                      </Col>
                    </Row>
                    {/* <h6 className="mt-2">Listed By :{unapprovedFamilyMemberDetail?.creator_name}</h6> */}
                    <Col sm="12" xs="12" className="">
                      <div className="d-flex justify-content-end mt-5">
                        <div className="">
                          <Link
                            to={`/unapprovedfamilymember/update/${unapprovedFamilyMemberDetail?.id}`}
                            className={`btn btn-primary w-md ${
                              loading && "disabled"
                            } btn-m`}
                          >
                            {language === "mal" ? "പുതുക്കുക" : "Update"}{" "}
                          </Link>
                        </div>
                        <div style={{ position: "relative", left: "10px" }}>
                          <Link
                            style={{ opacity: 0.8 }}
                            to="#"
                            className={`btn btn-danger w-md ${
                              loading && "disabled"
                            }  btn-m`}
                            onClick={handleDelete}
                          >
                            {language === "mal" ? "ഇല്ലാതാക്കുക" : "Delete"}
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
                <Card className="sm:p-0">
                  <CardBody>
                    {/* Education History */}
                    <Col sm="12" md="12" lg="12" className="mb-4 mt-3 sm:mt-0">
                      <div className="d-flex justify-content-between align-items-center eduHistory">
                        <h5 className="font-size-14 font-weight-bold">
                          {language === "mal"
                            ? "വിദ്യാഭ്യാസം :"
                            : "Education History :"}
                        </h5>
                        <Button
                          className="btn btn-success m-2 p-1"
                          onClick={() => {
                            setModalEducation(true)
                          }}
                        >
                          {language === "mal"
                            ? "+ വിദ്യാഭ്യാസം ചേർക്കുക"
                            : "+ Add Education"}
                        </Button>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-bordered mb-0">
                          <thead>
                            <tr>
                              <th>
                                {language === "mal" ? "തീയതി" : "Join Date"}
                              </th>
                              <th>
                                {language === "mal" ? "കോഴ്സ്" : "Course"}
                              </th>
                              <th>
                                {language === "mal" ? "സ്ഥാപനം" : "Institution"}
                              </th>
                              <th>{language === "mal" ? "സ്ഥലം" : "Place"}</th>
                              <th>
                                {language === "mal" ? "തീയതി" : "Pass Date"}
                              </th>
                              <th>
                                {language === "mal"
                                  ? "പ്രവർത്തനങ്ങൾ"
                                  : "Actions"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {unapprovedFamilyMemberDetail?.education?.map(
                              (item, key) => (
                                <Fragment key={key}>
                                  <tr>
                                    <td scope="row">
                                      {item?.join_date
                                        ? moment(item?.join_date).format(
                                            "DD-MM-yyyy"
                                          )
                                        : "NA"}
                                    </td>
                                    <td>{item?.course}</td>
                                    <td>{item?.institution_name}</td>
                                    <td>{item?.institution_place}</td>
                                    <td scope="row">
                                      {item?.pass_date
                                        ? moment(item?.pass_date).format(
                                            "DD-MM-yyyy"
                                          )
                                        : "NA"}
                                    </td>
                                    <td
                                      className="m-0 p-0"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <Button
                                        type="button"
                                        color="white"
                                        className="ms-1 btn"
                                        onClick={() =>
                                          updateEducationModal(item)
                                        }
                                      >
                                        <i
                                          className="bx bx-pencil me-2"
                                          style={{
                                            color: "green",
                                            fontSize: "14px",
                                          }}
                                        />
                                      </Button>

                                      {/* <Button
                                      type="button"
                                      color="white"
                                      className="ms-1 btn "
                                      // onClick={() => {
                                      //   handleDefaultTypeListItemDelete(item.id)
                                      // }}
                                      disabled
                                    >
                                      <i
                                        className="bx bx-trash me-2"
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                        }}
                                      />
                                    </Button> */}
                                    </td>
                                  </tr>
                                </Fragment>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
                <Card className="sm:p-0">
                  <CardBody>
                    {/* Job History */}
                    <Col sm="12" md="12" lg="12" className="mb-4 mt-3">
                      <div className="d-flex justify-content-between align-items-center eduHistory">
                        <h5 className="font-size-14 font-weight-bold">
                          {language === "mal" ? "ജോലി :" : "Job History :"}
                        </h5>
                        <Button
                          className="btn btn-success m-2 p-1"
                          onClick={() => {
                            setModalJob(true)
                          }}
                        >
                          {language === "mal" ? "+ ജോലി ചേർക്കുക" : "+ Add Job"}
                        </Button>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-bordered mb-0">
                          <thead>
                            <tr>
                              <th>{language === "mal" ? "തീയതി" : "Date"}</th>
                              <th>
                                {language === "mal"
                                  ? "ഉദ്യോഗ സ്ഥാനം"
                                  : "Job Position"}
                              </th>
                              <th>{language === "mal" ? "ഓഫീസ്" : "Office"}</th>
                              <th>
                                {language === "mal"
                                  ? "മേഖല/തരം"
                                  : "Sector/Type"}
                              </th>
                              <th>
                                {language === "mal"
                                  ? "പ്രവർത്തനങ്ങൾ"
                                  : "Actions"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {unapprovedFamilyMemberDetail?.job?.map(
                              (item, key) => (
                                <Fragment key={key}>
                                  <tr>
                                    <td scope="row">
                                      {item?.join_date
                                        ? moment(item?.join_date).format(
                                            "DD-MM-yyyy"
                                          )
                                        : "NA"}
                                    </td>
                                    <td>{item?.job_position}</td>
                                    <td>{`${item?.job_office}, ${item?.job_place}`}</td>
                                    <td>
                                      {item?.job_sector &&
                                        `${item?.job_sector?.english}/ ${item?.job_type?.english}`}
                                    </td>
                                    <td
                                      className="m-0 p-0"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <Button
                                        type="button"
                                        color="white"
                                        className="ms-1 btn"
                                        onClick={() => updateJobModal(item)}
                                      >
                                        <i
                                          className="bx bx-pencil me-2"
                                          style={{
                                            color: "green",
                                            fontSize: "14px",
                                          }}
                                        />
                                      </Button>

                                      {/* <Button
                                      type="button"
                                      color="white"
                                      className="ms-1 btn "
                                      // onClick={() => {
                                      //   handleDefaultTypeListItemDelete(item.id)
                                      // }}
                                      disabled
                                    >
                                      <i
                                        className="bx bx-trash me-2"
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                        }}
                                      />
                                    </Button> */}
                                    </td>
                                  </tr>
                                </Fragment>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default UnapprovedFamilyMemberDetails

UnapprovedFamilyMemberDetails.propTypes = {
  history: PropTypes.object,
}
