import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"

import {
  getMahalls,
  getMahallsCards,
  getMahallsForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"

const AllMahalls = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [modalMahallDownload, setModalMahallDownload] = useState(false)

  const { mahalls, loading, mahallsCards, language, mahallsForDownload } =
    useSelector(state => ({
      mahalls: state.Mahalls.mahalls,
      loading: state.Mahalls.loading,
      mahallsCards: state.Mahalls.mahallsCards,
      language: state.Layout.language,
      mahallsForDownload: state.Mahalls.mahallsForDownload,
    }))

  //pages
  const totalPages = Math.ceil(mahalls?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getMahalls(searchText, pageSend()))
  }, [dispatch, page, searchText])

  useEffect(() => {
    if (modalMahallDownload) {
      dispatch(getMahallsForDownload())
    }
  }, [dispatch, modalMahallDownload])

  useEffect(() => {
    dispatch(getMahallsCards())
  }, [dispatch])

  const cardData = [
    {
      title: `${language === "mal" ? "ആകെ മഹല്ലുകൾ" : "Total Mahalls"}`,
      cardValue: mahallsCards?.total_mahalls,
      iconClass: "map-alt",
      routeLink: "/mahalls",
      loading: loading,
      // alt: `${language === 'mal' ? "മഹല്ലുകൾ ഇല്ല" : "No Mahalls"}`,
    },
    {
      title: `${language === "mal" ? "ആകെ കുടുംബങ്ങൾ" : "Total Families"}`,
      cardValue: mahallsCards?.total_families,
      iconClass: "home",
      routeLink: "/families",
      loading: loading,
      // alt: `${language === 'mal' ? "കുടുംബങ്ങൾ ഇല്ല" : "No Families"}`,
    },
    {
      title: `${language === "mal" ? "ആകെ കുടുംബാംഗങ്ങൾ" : "Total Members"}`,
      cardValue: mahallsCards?.total_family_members,
      iconClass: "user",
      routeLink: "/familymembers",
      loading: loading,
      // alt: `${language === 'mal' ? "കുടുംബാംഗങ്ങൾ ഇല്ല" : "No Family Members"}`,
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    {
      dataField: "custom_id",
      text: `${language === "mal" ? "ഐഡി" : "ID"}`,
    },
    {
      dataField: "mahall_name",
      text: `${language === "mal" ? "മഹല്ലിൻ്റെ പേര്" : "Mahall Name"}`,
    },
    {
      dataField: "version",
      text: `${language === "mal" ? "വേർഷൻ" : "Version"}`,
    },
    {
      dataField: "families_count",
      text: `${language === "mal" ? "കുടുംബങ്ങൾ" : "Families"}`,
    },
    {
      dataField: "familiy_members_count",
      text: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members"}`,
    },
    {
      dataField: "village_name",
      text: `${language === "mal" ? "വില്ലജ്" : "Village"}`,
    },

    {
      dataField: "lsg_name",
      text: `${language === "mal" ? "ലോക്കൽ ബോഡി" : "LSG"}`,
    },

    {
      dataField: "district_name",
      text: `${language === "mal" ? "ജില്ല" : "District"}`,
    },
    {
      dataField: "date",
      text: `${language === "mal" ? "ചേർന്ന തീയതി" : "Joined on"}`,
    },

    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]

  const MahallsData = map(mahalls?.results, (item, index) => ({
    ...item,
    key: index,
    no: index + 1,
    mahall_name:
      language === "mal"
        ? item?.mahall_malayalam_name
          ? item?.mahall_malayalam_name
          : item?.mahall_english_name
        : item?.mahall_english_name,
    version: (
      <Badge
        className={
          "mx-1 font-size-12 badge-soft-" +
          `${
            item?.version == "premium"
              ? "warning"
              : item?.version == "basic"
              ? "info"
              : ""
          }`
        }
      >
        {item?.version}
      </Badge>
    ),
    village_name:
      language === "mal"
        ? item?.village_malayalam_name
          ? item?.village_malayalam_name
          : item?.village_english_name
        : item?.village_english_name,
    lsg_name:
      language === "mal"
        ? item?.lsg_malayalam_name
          ? item?.lsg_malayalam_name
          : item?.lsg_english_name
        : item?.lsg_english_name,
    district_name:
      language === "mal"
        ? item?.district_malayalam_name
          ? item?.district_malayalam_name
          : item?.district_english_name
        : item?.district_english_name,
    date: item?.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
    action: (
      <div>
        <Link to={`/mahalls/${item?.id}`} className="btn-light btn-sm">
          View
        </Link>
      </div>
    ),
  }))

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    { label: `${language === "mal" ? "ഐഡി" : "Id"}`, key: "custom_id" },
    {
      label: `${language === "mal" ? "മഹല്ലിന്റെ പേര്" : "Mahall Name"}`,
      key: "mahall_name",
    },
    {
      label: `${language === "mal" ? "മഹല്ല് കോഡ്" : "Mahall Code"}`,
      key: "mahall_code",
    },
    {
      label: `${
        language === "mal" ? "മഹല്ല് രജിസ്റ്റർ നമ്പർ" : "Mahall Register Number"
      }`,
      key: "register_number",
    },
    {
      label: `${language === "mal" ? "സ്ഥാപിതമായ തീയതി" : "Established Date"}`,
      key: "established_date",
    },
    { label: `${language === "mal" ? "വേർഷൻ" : "Version"}`, key: "version" },
    { label: `${language === "mal" ? "ഇ-മെയിൽ" : "E-mail"}`, key: "email" },
    { label: `${language === "mal" ? "ഫോൺ" : "Phone"}`, key: "phone_number" },
    { label: `${language === "mal" ? "സ്ഥലം" : "Place"}`, key: "place" },
    {
      label: `${language === "mal" ? "സംസ്ഥാനത്തിന്റെ പേര്" : "State Name"}`,
      key: "state_name",
    },
    {
      label: `${language === "mal" ? "ജില്ലയുടെ പേര്" : "District Name"}`,
      key: "district_name",
    },
    {
      label: `${language === "mal" ? "ഗ്രാമത്തിന്റെ പേര്" : "Village Name"}`,
      key: "village_name",
    },
    {
      label: `${language === "mal" ? "ലോക്കൽ ബോഡി" : "Lsg Name"}`,
      key: "lsg_name",
    },
    {
      label: `${language === "mal" ? "ലോക്കൽ ബോഡി തരം" : "Lsg Type"}`,
      key: "lsg_type",
    },
    {
      label: `${language === "mal" ? "പോസ്റ്റ് ഓഫീസ്" : "Post Office"}`,
      key: "post_office",
    },
    {
      label: `${language === "mal" ? "പിൻ കോഡ്" : "Pin Code"}`,
      key: "pincode",
    },
    {
      label: `${language === "mal" ? "കുടുംബങ്ങൾ" : "Families"}`,
      key: "families",
    },
    { label: `${language === "mal" ? "അംഗങ്ങൾ" : "Members"}`, key: "members" },
    { label: `${language === "mal" ? "ഗ്രൂപ്പ്" : "Group"}`, key: "group" },
    {
      label: `${
        language === "mal"
          ? "പ്രീമിയം അഭ്യർത്ഥിച്ചിട്ടുണ്ടോ?"
          : "Is Premium Requested ?"
      }`,
      key: "is_premium_requested",
    },
    {
      label: `${
        language === "mal"
          ? "നിബന്ധനകൾ അംഗീകരിച്ചിട്ടുണ്ടോ?"
          : "Is Terms Agreed ?"
      }`,
      key: "is_terms_agreed",
    },
    {
      label: `${language === "mal" ? "വിവരണം" : "Description"}`,
      key: "description",
    },
    {
      label: `${language === "mal" ? "തീയതി" : "Join Date"}`,
      key: "date_added",
    },
  ]

  const mahallsExcelData = map(mahallsForDownload, (item, index) => ({
    ...item,
    no: index + 1,
    custom_id: item.custom_id,
    mahall_name:
      language === "mal"
        ? item.mahall_malayalam_name
          ? item.mahall_malayalam_name
          : item.mahall_english_name
        : item.mahall_english_name,
    date_added:
      item.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
    version: item.version,
    state_name:
      language === "mal"
        ? item.state_malayalam_name
          ? item.state_malayalam_name
          : item.state_english_name
        : item.state_english_name,
    district_name:
      language === "mal"
        ? item.district_malayalam_name
          ? item.district_malayalam_name
          : item.district_english_name
        : item.district_english_name,
    village_name:
      language === "mal"
        ? item.village_malayalam_name
          ? item.village_malayalam_name
          : item.village_english_name
        : item.village_english_name,
    lsg_name:
      language === "mal"
        ? item.lsg_malayalam_name
          ? item.lsg_malayalam_name
          : item.lsg_english_name
        : item.lsg_english_name,
    lsg_type:
      language === "mal"
        ? item.lsg_type_malayalam
          ? item.lsg_type_malayalam
          : item.lsg_type_english
        : item.lsg_type_english,
    families: item.families_count,
    members: item.familiy_members_count,
    established_date:
      item.established_date &&
      moment(item?.established_date).format("DD/MM/YYYY"),
    group:
      language === "mal"
        ? item.mahallgroup_malayalam_name
          ? item.mahallgroup_malayalam_name
          : item.mahallgroup_english_name
        : item.mahallgroup_english_name,
    phone_number: item.phone_number,
    pincode: item.pin_code,
    post_office: item.post_office,
    place: item?.place,
    mahall_code: item.referral_code,
    register_number: item.register_number,
    is_premium_requested:
      item?.is_premium_requested === true
        ? language === "mal"
          ? "ഉണ്ട്"
          : "Yes"
        : language === "mal"
        ? "ഇല്ല"
        : "No",
    is_terms_agreed:
      item?.is_terms_agreed === true
        ? language === "mal"
          ? "ഉണ്ട്"
          : "Yes"
        : language === "mal"
        ? "ഇല്ല"
        : "No",
    description: item.description,
    email: item.email,
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMahallSearch = debounce(value => setSearchText(value), 600)

  const fileName = "khaf-smart-mahall-mahalls-list.csv"

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalMahallDownload}
        onCloseclick={() => setModalMahallDownload(false)}
        excelData={mahallsExcelData}
        headers={headers}
        list={mahallsForDownload}
        fileName={fileName}
        loading={loading}
      />
      <Row>
        {cardData?.map((item, key) => (
          <Col lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p>
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
        {/* <Row>
          <div className="col-md-6">
            <Link
              to={`/mahall/create`}
              type="submit"
              style={{ width: language === "mal" ? "200px" : "150px" }}
              className="w-md btn btn-success d-block"
            >
              {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New Mahall"}
            </Link>
          </div>
        </Row> */}
        <Col className="col-12 mt-2">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={MahallsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceMahallSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Col md="8">
                        <div className="d-flex justify-content-end">
                          <Button
                            style={{
                              width: language === "mal" ? "150px" : "130px",
                              border: "bold",
                            }}
                            className="btn-md me-2 mt-0  bg-white text-khaf"
                            onClick={() => setModalMahallDownload(true)}
                          >
                            <i
                              className="bx bx-download mx-1"
                              style={{ fontSize: "18px", color: "#009846" }}
                            />
                            {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                          </Button>
                          <Link
                            to={`/mahall/create`}
                            type="submit"
                            style={{
                              width: language === "mal" ? "200px" : "150px",
                            }}
                            className="w-md btn btn-success"
                          >
                            {language === "mal"
                              ? "+ പുതിയത് ചേർക്കുക"
                              : "+ New Mahall"}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllMahalls
