import {
  GET_DEFAULT_TYPE_LISTS,
  GET_DEFAULT_TYPE_LISTS_SUCCESS,
  GET_DEFAULT_TYPE_LISTS_FAIL,
  GET_MAHALL_TYPE_LISTS,
  GET_MAHALL_TYPE_LISTS_SUCCESS,
  GET_MAHALL_TYPE_LISTS_FAIL,
  CREATE_DEFAULT_TYPE_LIST_ITEM,
  CREATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS,
  CREATE_DEFAULT_TYPE_LIST_ITEM_FAIL,
  CREATE_MAHALL_TYPE_LIST_ITEM,
  CREATE_MAHALL_TYPE_LIST_ITEM_SUCCESS,
  CREATE_MAHALL_TYPE_LIST_ITEM_FAIL,
  UPDATE_DEFAULT_TYPE_LIST_ITEM,
  UPDATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS,
  UPDATE_DEFAULT_TYPE_LIST_ITEM_FAIL,
  UPDATE_MAHALL_TYPE_LIST_ITEM,
  UPDATE_MAHALL_TYPE_LIST_ITEM_SUCCESS,
  UPDATE_MAHALL_TYPE_LIST_ITEM_FAIL,
  DELETE_DEFAULT_TYPE_LIST_ITEM,
  DELETE_DEFAULT_TYPE_LIST_ITEM_SUCCESS,
  DELETE_DEFAULT_TYPE_LIST_ITEM_FAIL,
  DELETE_MAHALL_TYPE_LIST_ITEM,
  DELETE_MAHALL_TYPE_LIST_ITEM_SUCCESS,
  DELETE_MAHALL_TYPE_LIST_ITEM_FAIL,
  GET_MAHALL_WARD_NUMBERS,
  GET_MAHALL_WARD_NUMBERS_SUCCESS,
  GET_MAHALL_WARD_NUMBERS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  defaultTypeLists: [],
  mahallTypeLists: [],
  mahallWardNumbers: [],
  mahallWardNumbersloading: false,
  // searchVillages:[],
  error: {},
  loading: false,
  detailLoading: false,
  defaultTypeListDetail: {},
  mahallTypeListDetail: {},
  createdDefaultTypeList: {},
  createdMahallTypeList: {},
}

const TypeLists = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEFAULT_TYPE_LISTS:
    case GET_MAHALL_TYPE_LISTS:
    case CREATE_DEFAULT_TYPE_LIST_ITEM:
    case CREATE_MAHALL_TYPE_LIST_ITEM:
    case UPDATE_DEFAULT_TYPE_LIST_ITEM:
    case UPDATE_MAHALL_TYPE_LIST_ITEM:
    case DELETE_DEFAULT_TYPE_LIST_ITEM:
    case DELETE_MAHALL_TYPE_LIST_ITEM:
      return {
        ...state,
        loading: true,
      }
    case GET_MAHALL_WARD_NUMBERS:
      return {
        ...state,
        mahallWardNumbersloading: true,
      }

    case GET_DEFAULT_TYPE_LISTS_SUCCESS:
      return {
        ...state,
        defaultTypeLists: action.payload,
        loading: false,
      }

    case GET_DEFAULT_TYPE_LISTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MAHALL_TYPE_LISTS_SUCCESS:
      return {
        ...state,
        mahallTypeLists: action.payload,
        loading: false,
      }

    case GET_MAHALL_TYPE_LISTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //create

    case CREATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS: {
      if (state.defaultTypeLists?.[`${action.payload.type}`]) {
        state.defaultTypeLists?.[`${action.payload.type}`].push(action.payload)
      }
      return {
        ...state,
        defaultTypeLists: state.defaultTypeLists,
        createdDefaultTypeList: action.payload,
        loading: false,
      }
    }

    case CREATE_DEFAULT_TYPE_LIST_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_MAHALL_TYPE_LIST_ITEM_SUCCESS:
      if (state.mahallTypeLists?.[`${action.payload.type}`]) {
        state.mahallTypeLists?.[`${action.payload.type}`].push(action.payload)
      }
      return {
        ...state,
        mahallTypeLists: state.mahallTypeLists,
        createdMahallTypeList: action.payload,
        loading: false,
      }

    case CREATE_MAHALL_TYPE_LIST_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //update

    case UPDATE_DEFAULT_TYPE_LIST_ITEM_SUCCESS:
      return {
        ...state,
        defaultTypeListDetail: action.payload,
        loading: false,
      }

    case UPDATE_DEFAULT_TYPE_LIST_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_MAHALL_TYPE_LIST_ITEM_SUCCESS:
      return {
        ...state,
        mahallTypeListDetail: action.payload,
        loading: false,
      }

    case UPDATE_MAHALL_TYPE_LIST_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //delete

    case DELETE_DEFAULT_TYPE_LIST_ITEM_SUCCESS:
      // state.defaultTypeLists?.[`${action.payload.type}`].filter(
      //   defaultTypeList => defaultTypeList.id.toString() !== action.payload.id.toString()
      // );
      return {
        ...state,
        // defaultTypeLists: state.defaultTypeLists.filter(
        //   defaultTypeList => defaultTypeList.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_DEFAULT_TYPE_LIST_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_MAHALL_TYPE_LIST_ITEM_SUCCESS:
      return {
        ...state,
        // mahallTypeLists: state.mahallTypeLists.filter(
        //   mahallTypeList => mahallTypeList.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_MAHALL_TYPE_LIST_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MAHALL_WARD_NUMBERS_SUCCESS:
      return {
        ...state,
        mahallWardNumbers: action.payload,
        loading: false,
      }

    case GET_MAHALL_WARD_NUMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default TypeLists
