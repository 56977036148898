import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
//actions
import moment from "moment"

import {
  createDeathRegistration,
  getAllFamilyMembersDropdown,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateMahallDeathRegistration = ({ history }) => {
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState("")
  const [familyMember, setFamilyMember] = useState()
  const [familyMemberId, setFamilyMemberId] = useState()

  const { loading, error, language, userDetail, allFamilyMembers } =
    useSelector(state => ({
      loading: state.DeathRegistrations.loading,
      error: state.DeathRegistrations.error,
      userDetail: state.Users.userDetail,
      language: state.Layout.language,
      allFamilyMembers: state.FamilyMembers.allFamilyMembers,
    }))

  const today = moment(new Date()).format("yyyy-MM-DD")

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchText))
  }, [dispatch, searchText])

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    console.log(values)
    const registerDeathData = {
      mahall_custom_id: values.mahall_custom_id,
      family_member: familyMemberId,
      death_date: values.death_date,
      death_place: values.death_place,
      reason: values.reason,
      qabar: values.qabar,
    }
    // console.log('registerDeathData: ', registerDeathData);
    dispatch(createDeathRegistration(registerDeathData, history))
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }
  const optionGroup = [
    {
      options: allFamilyMembers?.map((result, index) => ({
        key: index,
        label: `${result?.full_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        })`,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setFamilyMemberId(event.value)
    setFamilyMember(event.label)
  }

  return (
    <>
      <MetaTags>
        <title>Death | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "മരണം" : "Death"}
          breadcrumbItem={language === "mal" ? "രജിസ്റ്റർ" : "Register"}
        />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <Row>
                <Col sm="12" md="8" lg="6">
                  <Card>
                    <CardBody>
                      <Row>
                        <div className="row mb-4">
                          <Col>
                            <AvField
                              name="mahall_custom_id"
                              label={
                                language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"
                              }
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Label>
                              {language === "mal" ? "തീയതി " : "Date "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              name="death_date"
                              type="date"
                              value={today}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: `${
                                    language === "mal"
                                      ? "നിർബന്ധമാണ്!"
                                      : "mandatory!"
                                  }`,
                                },
                              }}
                            />
                          </Col>
                        </div>
                        <div className="mb-3 row sm:mt-0">
                          <Label>
                            {language === "mal" ? "കുടുംബാംഗം" : "Member"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <Select
                            onInputChange={handleEnters}
                            placeholder={familyMember}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="Family Member"
                            required="required"
                          />
                        </div>

                        <div className="row mb-4">
                          <Col>
                            <Label>
                              {language === "mal" ? "മരണ സ്ഥലം" : "Death Place"}
                            </Label>
                            <AvField name="death_place" type="text" />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col>
                            <Label>
                              {language === "mal" ? "മരണ കാരണം " : "Reason"}
                            </Label>
                            <AvField name="reason" type="text" />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col>
                            <Label>
                              {language === "mal"
                                ? "ഖബർ വിവരങ്ങൾ"
                                : "Qabr Details"}
                            </Label>
                            <AvField name="qabar" type="textarea" />
                          </Col>
                        </div>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="col-12 btn btn-success save-customer"
                          >
                            {loading && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            )}
                            {language === "mal" ? "രജിസ്റ്റർ" : "Register"}
                          </button>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateMahallDeathRegistration

CreateMahallDeathRegistration.propTypes = {
  history: PropTypes.object,
}
