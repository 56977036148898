import React, { useEffect, useRef } from "react"
import { Button, Container, Row, Spinner } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Markup } from "interweave"
//actions
import moment from "moment"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"

import { getNocCertificateDetail } from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
//Import Images
import LetterHead from "components/Common/Letterhead/letterHead"
import NocCertificatePdf from "pages/mahallUser/PdfDownloads/nocCertificatePdf"

const NocCertificate = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const pdfRef = useRef()

  const nocId = location?.search?.split("?nocId=")[1]

  const { nocCertificateDetail, loading, language, userDetail } = useSelector(
    state => ({
      loading: state.NocCertificates.loading,
      detailLoading: state.MahallUsers.detailLoading,
      nocCertificateDetail: state.NocCertificates.nocCertificateDetail,
      language: state.Layout.language,
      userDetail: state.Users.userDetail,
    })
  )

  // console.log(nikahRegistrationDetail);

  // const today = moment(new Date()).format('DD-MM-yyyy')

  const downloadPdf = () => {
    const input = pdfRef.current
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL(`${userDetail?.mahall?.logo}`)
      const pdf = new jsPDF("p", "mm", "a4", true)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2
      const imgY = 30
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      )
      pdf.save("noc.pdf")
    })
  }

  useEffect(() => {
    dispatch(getNocCertificateDetail(nocId))
  }, [])
  return (
    <>
      {/* <MetaTags>
        <title>Mahall User | Khaf </title>
      </MetaTags> */}

      <NocCertificatePdf pdfRef={pdfRef} />

      <div className="page-content">
        <Breadcrumbs title="NOC" breadcrumbItem="Certificate" />
        <Container fluid>
          <div className="container-fluid">
            {loading ? (
              <Spinner color="secondary" className="d-block m-auto" />
            ) : (
              <div className="certificate-layout">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn-sm bg-khaf-blue print-button"
                    onClick={downloadPdf}
                  >
                    <i
                      className="bx bx-download mx-2"
                      style={{ fontSize: "15px", color: "" }}
                    />
                    {language === "mal" ? "ഡൗൺലോഡ്" : "Download"}
                  </Button>
                </div>
                <Row className="mt-2">
                  <LetterHead />
                  <div className="px-4">
                    <h5 className="d-flex justify-content-end pt-5 text-black">
                      {language === "mal" ? "തീയതി : " : "Date : "}{" "}
                      {moment(nocCertificateDetail?.date).format("DD-MM-yyyy")}
                    </h5>
                    <h1 className="text-center text-black pt-5">
                      {nocCertificateDetail?.title}
                    </h1>

                    <p
                      className="pt-4 text-black"
                      style={{ fontSize: "20px", textAlign: "justify" }}
                    >
                      <Markup content={nocCertificateDetail?.content} />
                    </p>
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginTop: "150px" }}
                    >
                      <p style={{ fontSize: "16px" }}>
                        {language === "mal" ? "സീൽ" : "Seal"}
                      </p>
                      <p style={{ fontSize: "16px" }}>
                        {language === "mal"
                          ? "പ്രസിഡന്റ് / സെക്രട്ടറി"
                          : "President / Secretary"}
                      </p>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-end mt-5">
                    <Button className="btn-md bg-khaf-blue print-button" onClick={handlePrint}><i className="bx bx-download mx-2" style={{ fontSize: '18px', color: "" }} />{language === "mal" ? "ഡൗൺലോഡ്" : "Download"}</Button>
                  </div> */}
                </Row>
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default NocCertificate

NocCertificate.propTypes = {
  history: PropTypes.object,
}
