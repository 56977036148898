import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Modal, Button, Label } from "reactstrap"
import axios from "axios"
import { Image } from "react-bootstrap"

import { createGallerySuccess, createGalleryFail } from "store/actions"
import { API_URL } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { useFileSizes } from "hooks/useFileSize"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UiModalMahallGallery = ({ show, onCloseclick, history }) => {
  const dispatch = useDispatch()
  // const params = useParams()
  const handleFileSizes = useFileSizes

  const { loading, language, instituteMahallDetail } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    instituteMahallDetail: state.Institutes.instituteMahallDetail,
  }))

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })

  const [imagePreview, setImagePreview] = useState()

  const [filesSize, setFilesSize] = useState()

  // console.log(instituteDetail);

  const handleValidSubmit = (onSubmitProps, values) => {
    setCustomLoad(true)
    // if (handleFileSizes(filesSize)?.status === true) {
    createGallery(
      selectedFile,
      values,
      instituteMahallDetail,
      dispatch,
      history,
      setCustomLoad
    )
    setImagePreview()
    // }
  }

  const changeHandler = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "ഗാലറി ഡാറ്റ ചേർക്കുക" : "Add Gallery Data"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-3">
                <AvField
                  name="title"
                  type="text"
                  label={language === "mal" ? "പേര്" : "Title"}
                />
              </div>
              {/* <div className="row mb-3">
                <AvField
                  name="label"
                  type="text"
                  label={language === "mal" ? "ലേബൽ" : "Label"}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="link"
                  type="text"
                  label={language === "mal" ? "ലിങ്ക്" : "Link"}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="description"
                  type="textarea"
                  label={language === "mal" ? "വിവരണം" : "Description"}
                />
              </div> */}
              <div className="row mb-3">
                <Label>{language === "mal" ? "ഫോട്ടോ" : "Photo"}</Label>
                {imagePreview && (
                  <Image
                    style={{ width: "300px", height: "200px" }}
                    className="my-2"
                    src={imagePreview.image}
                  />
                )}
                {handleFileSizes(filesSize)?.text && (
                  <p className="text-danger mt-1">
                    {handleFileSizes(filesSize)?.text}
                  </p>
                )}
                <AvField name="image" type="file" onChange={changeHandler} />
              </div>
              <div className="row w-100 justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  onClick={onCloseclick}
                >
                  {loading ||
                    (customLoad && (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </>
                    ))}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalMahallGallery
UiModalMahallGallery.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}

function createGallery(
  selectedFile,
  values,
  instituteMahallDetail,
  dispatch,
  history,
  setCustomLoad
) {
  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append("image", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("title", values.title)
  formData.append("label", values.label)
  formData.append("link", values.link)
  formData.append("description", values.description)
  formData.append("institute", instituteMahallDetail?.id)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .post(`${API_URL}/api/v1/institute/institute_image/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        doneNotification("Added Successfully")
        setCustomLoad(false)
        // history.push("/mahallmasters")
        dispatch(createGallerySuccess(res?.data))
        // dispatch(getInstituteMahallDetail(res?.data?.institute))
      } else {
        // createGalleryFail(err)
        errorNotification()
      }
    })
    .catch(err => {
      createGalleryFail(err)
      errorNotification()
      setCustomLoad(false)
    })
}
