import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import moment from "moment"

//actions
import UiModalFamilyDownload from "./UiModalFamilyDownload"

import {
  getUnapprovedFamilies,
  getUnapprovedFamiliesCards,
  getUnapprovedFamiliesForDownload,
  updateUnapprovedFamily,
  // updateUnapprovedFamily,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"

const AllUnapprovedFamilies = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [modalFamilyDownload, setModalFamilyDownload] = useState(false)

  const {
    unapprovedFamilies,
    unapprovedFamiliesForDownload,
    loading,
    unapprovedFamiliesCards,
    language,
    // userDetail,
  } = useSelector(state => ({
    unapprovedFamilies: state.UnapprovedFamilies.unapprovedFamilies,
    unapprovedFamiliesForDownload:
      state.UnapprovedFamilies.unapprovedFamiliesForDownload,
    loading: state.Families.loading,
    unapprovedFamiliesCards: state.UnapprovedFamilies.unapprovedFamiliesCards,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
  }))

  //pages
  const totalPages = Math.ceil(unapprovedFamilies?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  // const handlePremiumLink = () => {
  //   if (userDetail?.mahall?.version === 'premium') {
  //     return '!#'
  //   } else {
  //     return '/premium'
  //   }
  // }

  const handleStatusChange = id => {
    // const data = {
    //   is_approved: true,
    // }
    // console.log(data, id)
    dispatch(updateUnapprovedFamily({ is_approved: true }, id))
  }

  useEffect(() => {
    dispatch(getUnapprovedFamilies(searchText, pageSend()))
  }, [dispatch, page, searchText])

  useEffect(() => {
    if (modalFamilyDownload) {
      dispatch(getUnapprovedFamiliesForDownload(page, searchText))
    }
  }, [dispatch, page, searchText, modalFamilyDownload])

  useEffect(() => {
    dispatch(getUnapprovedFamiliesCards())
  }, [dispatch])

  const cardData = [
    {
      title: `${language === "mal" ? "ആകെ കുടുംബങ്ങൾ" : "Total Families"}`,
      cardValue: unapprovedFamiliesCards?.total_families,
      iconClass: "home",
      routeLink: "/families",
      loading: loading,
      alt: `${language === "mal" ? "കുടുംബങ്ങൾ ഇല്ല" : "No Families"}`,
    },
    {
      title: `${language === "mal" ? "ആകെ കുടുംബാംഗങ്ങൾ" : "Total Members"}`,
      cardValue: unapprovedFamiliesCards?.total_family_members,
      iconClass: "user",
      routeLink: "/familymembers",
      loading: loading,
      alt: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ ഇല്ല" : "No Family Members"}`,
    },
    {
      title: `${language === "mal" ? "ആൺ - പെൺ" : "Male - Female"}`,
      cardValue: `${unapprovedFamiliesCards?.total_males} - ${unapprovedFamiliesCards?.total_females}`,
      iconClass: "show",
      routeLink: "/familymembers",
      loading: loading,
      alt: `${language === "mal" ? "ആൺ - പെൺ ഇല്ല" : "No Male - Female"}`,
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    // {
    //   dataField: 'custom_id',
    //   text: `${language === 'mal' ? 'സിസ്റ്റം ഐഡി' : 'System ID'}`,
    // },
    {
      dataField: "mahall_custom_id",
      text: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
    },
    {
      dataField: "house_name",
      text: `${language === "mal" ? "വീട്ടുപേര്" : "House Name"}`,
      sort: true,
    },
    {
      dataField: "family_head_name",
      text: `${language === "mal" ? "കുടുംബനാഥൻ" : "Family Head"}`,
    },
    {
      dataField: "no_of_members",
      text: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members"}`,
    },
    {
      dataField: "houseNumber",
      text: `${language === "mal" ? "വീട് നമ്പർ" : "House No."}`,
    },
    // {
    //   dataField: "area_name",
    //   text: `${language === "mal" ? "ഏരിയ" : "Area"}`,
    // },
    {
      dataField: "is_approved",
      text: `${language === "mal" ? "സ്റ്റാറ്റസ്" : "Status"}`,
    },
    {
      dataField: "contact_number",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]
  const unapprovedFamiliesData = map(
    unapprovedFamilies?.results,
    (item, key) => ({
      ...item,
      key: key,
      no: (pageSend() - 1) * limit + key + 1,
      houseNumber: `${item?.house_number === null ? "" : item?.house_number}/${
        item?.ward_number === null ? "" : item?.ward_number
      }`,
      area_name:
        language === "mal"
          ? item?.area_malayalam_name
            ? item?.area_malayalam_name
            : item?.area_english_name
          : item?.area_english_name,
      is_approved: (
        <>
          <Button
            onClick={() => {
              handleStatusChange(item?.id)
            }}
            type="submit"
            color="black"
            className="btn w-xs btn-sm bg-light btn-rounded btn-approved"
          >
            {language === "mal" ? "അംഗീകരിക്കുക" : "Approve"}
          </Button>
          {/* <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleStatusChange(item?.id)
            }}
          />
        </div> */}
        </>
      ),
      action: (
        <div>
          <Link
            to={`/unapprovedfamilies/${item?.id}`}
            className=" btn-success w-xs btn-sm"
          >
            {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
            {language === "mal" ? "കാണുക" : "View"}
          </Link>
        </div>
      ),
    })
  )

  const headers = [
    { label: language === "mal" ? "നo." : "No.", key: "no" },
    {
      label: language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID",
      key: "custom_id",
    },
    {
      label: language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID",
      key: "mahall_custom_id",
    },
    {
      label: language === "mal" ? "വീട്ടുപേര്" : "House Name",
      key: "house_name",
    },
    {
      label: language === "mal" ? "വീട് നമ്പർ" : "House No.",
      key: "house_number",
    },
    { label: language === "mal" ? "ഏരിയ" : "Area", key: "area_name" },

    { label: language === "mal" ? "വഴി" : "Via", key: "via" },
    { label: language === "mal" ? "സ്ഥലം" : "Place", key: "place" },
    { label: language === "mal" ? "ലോക്കൽ ബോഡി" : "Local Body", key: "lsg" },
    {
      label: language === "mal" ? "ലോക്കൽ ബോഡി തരം" : "Local Body Type",
      key: "lsg_type",
    },
    { label: language === "mal" ? "വില്ലേജ് " : "Village", key: "village" },
    { label: language === "mal" ? "ജില്ല" : "District", key: "district" },
    { label: language === "mal" ? "സംസ്ഥാനം" : "State", key: "state" },
    {
      label: language === "mal" ? "പോസ്റ്റ് ഓഫീസ്" : "Post Office",
      key: "post_office",
    },
    { label: language === "mal" ? "പിൻകോഡ്" : "Pincode", key: "pin_code" },
    {
      label: language === "mal" ? "താമസം തുടങ്ങിയ വർഷം" : "Year of Residence",
      key: "year_of_residence",
    },
    { label: language === "mal" ? "ഫോൺ" : "Phone", key: "contact_number" },
    {
      label: language === "mal" ? "കുടുംബനാഥൻ" : "Family Head",
      key: "family_head_name",
    },
    {
      label: language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members",
      key: "no_of_members",
    },

    {
      label: language === "mal" ? "റേഷൻ കാർഡ് വിഭാഗം" : "Ration Card Category",
      key: "ration_card",
    },
    {
      label: language === "mal" ? "റേഷൻ കാർഡ് നമ്പർ" : "Ration Card No.",
      key: "ration_card_number",
    },
    { label: language === "mal" ? "വീട്" : "House Type", key: "house_type" },
    {
      label: language === "mal" ? "വീടിൻ്റെ സ്ഥിതി" : "Build Type",
      key: "house_build_type",
    },
    {
      label: language === "mal" ? "ജല സൗകര്യം" : "Water Facility",
      key: "water_facility",
    },
    {
      label: language === "mal" ? "വൈത്യുതി" : "Electicity",
      key: "electric_facility",
    },

    {
      label: language === "mal" ? "ആസ്തി വിവരം - വീട്" : "Land Area - Home",
      key: "land_area_home",
    },
    {
      label: language === "mal" ? "ആസ്തി വിവരം - കൃഷി" : "Land Area - Farm",
      key: "land_area_farm",
    },
    {
      label: language === "mal" ? "ആസ്തി വിവരം - വാടക" : "Land Area - Rent",
      key: "land_area_rent",
    },
    {
      label:
        language === "mal" ? "ആസ്തി വിവരം - മറ്റുള്ളവ" : "Land Area - Others",
      key: "land_area_others",
    },
    {
      label: language === "mal" ? "ആകെ ആസ്തി" : "Total Land Area",
      key: "total_land_area",
    },

    {
      label: language === "mal" ? "2 വീലറുകൾ " : "2 Wheelers",
      key: "two_wheeler_vehicles",
    },
    {
      label: language === "mal" ? "3 വീലറുകൾ" : "3 Wheelers",
      key: "three_wheeler_vehicles",
    },
    {
      label: language === "mal" ? "4 വീലറുകൾ" : "4 Wheelers",
      key: "four_wheeler_vehicles",
    },
    {
      label: language === "mal" ? "ടാക്സികൾ" : "Taxi Vehicles",
      key: "taxi_vehicles",
    },
    {
      label: language === "mal" ? "ആകെ വാഹനങ്ങൾ" : "Total Vehicles",
      key: "total_vehicles",
    },

    {
      label: language === "mal" ? "സാമ്പത്തിക സ്ഥിതി" : "financial_status",
      key: "financial_status",
    },
    {
      label: language === "mal" ? "വാർഷിക വരുമാനം" : "Annual Income",
      key: "annual_income",
    },

    {
      label: language === "mal" ? "ബാങ്ക് വായ്പ" : "Bank Loan",
      key: "bank_loan",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "bank_loan_details",
    },
    {
      label: language === "mal" ? "ഹോം ബിസിനസ്സ്" : "Home Business",
      key: "home_business",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "home_business_details",
    },
    {
      label:
        language === "mal" ? "മഹല്ലിൽ നിന്നുള്ള സഹായം" : "Help From Mahall",
      key: "help_receiving_from_mahall",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "help_receiving_from_mahall_details",
    },
    {
      label:
        language === "mal"
          ? "മറ്റുള്ളവരിൽ നിന്നുള്ള സഹായം"
          : "Help From Others",
      key: "help_receiving_from_others",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "help_receiving_from_others_details",
    },
    {
      label: language === "mal" ? "പ്രത്യേക കുറിപ്പുകൾ" : "Special Notes",
      key: "special_notes",
    },

    {
      label: language === "mal" ? "വീടിൻ്റെ ഫോട്ടോ ലിങ്ക്" : "House Photo Link",
      key: "house_image",
    },
    { label: "Listed Date", key: "date_added" },
  ]
  // console.log(familiesforDownload);

  const unApprovedFamiliesExcelData = map(
    unapprovedFamiliesForDownload?.results,
    (item, key) => ({
      ...item,
      no: key + 1,
      custom_id: item.custom_id,
      mahall_custom_id: item.mahall_custom_id,
      house_name: item.house_name,
      house_number: `${item.ward_number ? `${item.ward_number}` : ""} ${
        item.house_number ? `/${item.house_number}` : ""
      }`,
      area_name:
        language === "mal"
          ? item?.area?.malayalam
            ? item?.area?.malayalam
            : item?.area?.english
          : item?.area?.english,
      via: item.via,
      place: item.place,
      lsg:
        language === "mal"
          ? item?.lsg_malayalam_name
            ? item?.lsg_malayalam_name
            : item?.lsg_english_name
          : item?.lsg_english_name,
      lsg_type:
        language === "mal"
          ? item?.lsg_type_malayalam
            ? item?.lsg_type_malayalam
            : item?.lsg_type_english
          : item?.lsg_type_english,
      village:
        language === "mal"
          ? item?.village_malayalam_name
            ? item?.village_malayalam_name
            : item?.village_english_name
          : item?.village_english_name,
      district:
        language === "mal"
          ? item?.district_malayalam_name
            ? item?.district_malayalam_name
            : item?.district_english_name
          : item?.district_english_name,
      state:
        language === "mal"
          ? item?.state_malayalam_name
            ? item?.state_malayalam_name
            : item?.state_english_name
          : item?.state_english_name,
      post_office: item.post_office,
      pin_code: item.pin_code,
      year_of_residence: item.year_of_residence,
      contact_number: item.contact_number,
      family_head_name: item.family_head_name ? item.family_head_name : "",
      no_of_members: item.no_of_members,

      ration_card:
        language === "mal"
          ? item?.ration_card?.malayalam
            ? item?.ration_card?.malayalam
            : item?.ration_card?.english
          : item?.ration_card?.english,
      ration_card_number: item.ration_card_number,
      house_type:
        language === "mal"
          ? item?.house_type?.malayalam
            ? item?.house_type?.malayalam
            : item?.house_type?.english
          : item?.house_type?.english,
      house_build_type:
        language === "mal"
          ? item?.house_build_type?.malayalam
            ? item?.house_build_type?.malayalam
            : item?.house_build_type?.english
          : item?.house_build_type?.english,
      water_facility:
        language === "mal"
          ? item?.water_facility?.malayalam
            ? item?.water_facility?.malayalam
            : item?.water_facility?.english
          : item?.water_facility?.english,
      electric_facility:
        language === "mal"
          ? item?.electric_facility?.malayalam
            ? item?.electric_facility?.malayalam
            : item?.electric_facility?.english
          : item?.electric_facility?.english,

      land_area_home: item.land_area_home,
      land_area_farm: item.land_area_farm,
      land_area_rent: item.land_area_rent,
      land_area_others: item.land_area_others,
      total_land_area: item.total_land_area,

      two_wheeler_vehicles: item.two_wheeler_vehicles,
      three_wheeler_vehicles: item.three_wheeler_vehicles,
      four_wheeler_vehicles: item.four_wheeler_vehicles,
      taxi_vehicles: item.taxi_vehicles,
      total_vehicles: item.total_vehicles,

      financial_status:
        language === "mal"
          ? item?.financial_status?.malayalam
            ? item?.financial_status?.malayalam
            : item?.financial_status?.english
          : item?.financial_status?.english,
      annual_income: item?.annual_income,
      bank_loan: `${item?.bank_loan?.map(item => {
        return `${
          language === "mal"
            ? item?.malayalam
              ? item?.malayalam
              : item?.english
            : item?.english
        } `
      })}`,

      bank_loan_details: item.bank_loan_details,
      home_business: `${item?.home_business?.map(item => {
        return `${
          language === "mal"
            ? item?.malayalam
              ? item?.malayalam
              : item?.english
            : item?.english
        } `
      })}`,
      home_business_details: item.home_business_details,
      help_receiving_from_mahall:
        item.help_receiving_from_mahall === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      help_receiving_from_mahall_details:
        item.help_receiving_from_mahall_details,
      help_receiving_from_others:
        item.help_receiving_from_others === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      help_receiving_from_others_details:
        item.help_receiving_from_others_details,
      special_notes: item.special_notes,

      house_image: item.house_image,
      date_added:
        item?.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceUnapprovedFamilySearch = debounce(
    value => setSearchText(value),
    600
  )

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <UiModalFamilyDownload
        show={modalFamilyDownload}
        onCloseclick={() => setModalFamilyDownload(false)}
        excelData={unApprovedFamiliesExcelData}
        headers={headers}
        list={unapprovedFamiliesForDownload}
        loading={loading}
      />
      <Row>
        {cardData?.map((item, key) => (
          <Col md={4} lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue > 0 && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      <p className="m-0 text-info">
                        {item.cardValue > 0 ? <></> : item.alt}
                      </p>
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Col className="col-12 mt-2">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField={"key"}
                key={"key"}
                columns={columns}
                data={unapprovedFamiliesData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceUnapprovedFamilySearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Col md="8">
                        {unapprovedFamilies &&
                          unapprovedFamilies?.results?.length > 0 && (
                            <div className="d-flex justify-content-end">
                              <Button
                                style={{
                                  width: language === "mal" ? "150px" : "130px",
                                  border: "bold",
                                }}
                                className="btn-md bg-white text-khaf mt-1"
                                onClick={() => setModalFamilyDownload(true)}
                              >
                                <i
                                  className="bx bx-download mx-1"
                                  style={{ fontSize: "18px", color: "#009846" }}
                                />
                                {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                              </Button>
                            </div>
                          )}
                      </Col>
                      {/* <Link
                        style={{ width: language === "mal" ? "135px" : "50px" }}
                        className="w-md btn btn-primary m-auto me-0 mt-0 d-block"
                        to={handlePremiumLink()}>
                        <i className="bx bx-list-ol font-size-16 align-middle"></i>{" "}
                        {language === "mal" ? "ഫിൽട്ടർ" : "Filter"}
                        <i className="bx bx-crown mx-1" style={{ fontSize: '12px', color: "gold" }} />
                      </Link> */}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="key"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {unapprovedFamilies &&
                      unapprovedFamilies?.results &&
                      unapprovedFamilies?.results?.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          {language === "mal"
                            ? "കുടുംബങ്ങൾ ഒന്നും ഇല്ല"
                            : "No Families Yet"}
                        </p>
                      )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllUnapprovedFamilies
