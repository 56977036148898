import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import Select from "react-select"
import { Col, Modal, Button, Label, Row } from "reactstrap"

import {
  updateCommitteeMember,
  deleteCommitteeMember,
  getAllFamilyMembersDropdown,
} from "store/actions"

const UiModalCommitteeMemberUpdate = ({
  show,
  onCloseclick,
  history,
  data,
}) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const { loading, language, allFamilyMembers } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    allFamilyMembers: state.FamilyMembers.allFamilyMembers,
  }))

  const committeeMember = data

  const [searchText, setSearchText] = useState("")
  const [familyMember, setFamilyMember] = useState("Select member...")
  const [familyMemberId, setFamilyMemberId] = useState()

  useEffect(() => {
    // setFamilyMember(committeeMember?.full_name)
    setFamilyMember(
      committeeMember?.member_name === "No user"
        ? committeeMember?.full_name
        : committeeMember?.member_name
    )
    setFamilyMemberId(committeeMember?.family_member)
  }, [committeeMember])

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchText))
  }, [dispatch, searchText])

  const optionGroup = [
    {
      options: allFamilyMembers?.map((result, index) => ({
        key: index,
        label: `${result?.full_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        })`,
        // <div>
        //   {results?.full_name}
        //   {results?.mahall_custom_id ? (
        //     <Badge className="bg-khaf-blue mx-2">
        //       {results?.mahall_custom_id}
        //     </Badge>
        //   ) : (
        //     <Badge className="bg-khaf-blue mx-2">
        //       {results?.custom_id}
        //     </Badge>
        //   )}
        // </div>,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setFamilyMemberId(event.value)
    setFamilyMember(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const updateCommitteeMemberData = {
      committee: committeeMember?.committee,
      family_member: familyMemberId,
      designation: values.designation,
      description: values.description,
    }
    // console.log(updateCommitteeMemberData);
    dispatch(
      updateCommitteeMember(
        updateCommitteeMemberData,
        committeeMember?.id,
        history
      )
    )
  }

  const handleDeleteCommitteeMember = id => {
    // console.log(`delete item ${id}`);
    dispatch(deleteCommitteeMember(id, history, committeeMember?.committee))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal"
                ? "കമ്മിറ്റി മെമ്പറെ അപ്ഡേറ്റ് ചെയ്യുക"
                : "Update Committee Member"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "പേര്" : "Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={familyMember}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Family Member"
                    required="required"
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>
                    {language === "mal" ? "പദവി" : "Designation"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="designation"
                    type="text"
                    value={committeeMember?.designation}
                    // label={language === "mal" ? "പദവി" : "Designation"}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    value={committeeMember?.description}
                    label={language === "mal" ? "വിവരണം" : "Description"}
                  />
                </Col>
              </div>
              <Row className="mt-5 d-flex">
                <div className="col-6">
                  <div className="row justify-content-start">
                    <div>
                      <Button
                        type="button"
                        color="danger"
                        className="w-md"
                        onClick={() => {
                          onCloseclick()
                          handleDeleteCommitteeMember(committeeMember?.id)
                        }}
                      >
                        {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md m-auto me-0 d-block"
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക " : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalCommitteeMemberUpdate
UiModalCommitteeMemberUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
  data: propTypes.object,
}
