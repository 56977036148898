import {
  GET_TIMETABLE_LIST,
  UPDATE_TIMETABLE,
  GET_TIMETABLE_LIST_SUCCESS,
  GET_TIMETABLE_LIST_FAIL,
  UPDATE_TIMETABLE_SUCCESS,
  UPDATE_TIMETABLE_FAIL,
  PERIODS_DROPDOWN,
  PERIODS_DROPDOWN_SUCCESS,
  PERIODS_DROPDOWN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  timetableList: [],
  periodsList: [],
  error: {},
  loading: false,
}

const Timetable = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TIMETABLE_LIST:
    case UPDATE_TIMETABLE:
    case PERIODS_DROPDOWN:
      return {
        ...state,
        loading: true,
      }

    case GET_TIMETABLE_LIST_SUCCESS:
      return {
        ...state,
        timetableList: action.payload,
        loading: false,
      }
    case GET_TIMETABLE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_TIMETABLE_SUCCESS:
      return {
        ...state,
        timetableList: state.timetableList?.map(item =>
          item.id.toString() === action.payload.id.toString()
            ? { item, ...action.payload }
            : item
        ),
        periodsList: state.periodsList?.map(item =>
          item.id.toString() === action.payload.id.toString()
            ? { item, ...action.payload }
            : item
        ),
        loading: false,
      }

    case UPDATE_TIMETABLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PERIODS_DROPDOWN_SUCCESS:
      return {
        ...state,
        periodsList: action.payload,
        loading: false,
      }

    case PERIODS_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Timetable
