import {
  GET_STUDENTS,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAIL,
  GET_STUDENT_DETAIL,
  GET_STUDENT_DETAIL_SUCCESS,
  GET_STUDENT_DETAIL_FAIL,
  CREATE_STUDENT,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT_FAIL,
  UPDATE_STUDENT,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAIL,
  DELETE_STUDENT,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAIL,
  STUDENTS_DROPDOWN,
  STUDENTS_DROPDOWN_SUCCESS,
  STUDENTS_DROPDOWN_FAIL,
  GET_STUDENT_ABSENT_DETAIL,
  GET_STUDENT_ABSENT_DETAIL_SUCCESS,
  GET_STUDENT_ABSENT_DETAIL_FAIL,
} from "./actionTypes"

export const getStudents = (divisionId, instituteId, searchText, page) => ({
  type: GET_STUDENTS,
  payload: { divisionId, instituteId, searchText, page },
})
export const getStudentsSuccess = students => ({
  type: GET_STUDENTS_SUCCESS,
  payload: students,
})
export const getStudentsFail = error => ({
  type: GET_STUDENTS_FAIL,
  payload: error,
})

export const studentsDropdown = (searchText, divisionId, instituteId) => ({
  type: STUDENTS_DROPDOWN,
  payload: { searchText, divisionId, instituteId },
})
export const studentsDropdownSuccess = studentsList => ({
  type: STUDENTS_DROPDOWN_SUCCESS,
  payload: studentsList,
})
export const studentsDropdownFail = error => ({
  type: STUDENTS_DROPDOWN_FAIL,
  payload: error,
})

export const getStudentDetail = studentId => ({
  type: GET_STUDENT_DETAIL,
  studentId,
})

export const getStudentDetailSuccess = studentDetail => ({
  type: GET_STUDENT_DETAIL_SUCCESS,
  payload: studentDetail,
})

export const getStudentDetailFail = error => ({
  type: GET_STUDENT_DETAIL_FAIL,
  payload: error,
})

export const getStudentAbsentDetail = studentId => ({
  type: GET_STUDENT_ABSENT_DETAIL,
  studentId,
})

export const getStudentAbsentDetailSuccess = studentAbsentDetail => ({
  type: GET_STUDENT_ABSENT_DETAIL_SUCCESS,
  payload: studentAbsentDetail,
})

export const getStudentAbsentDetailFail = error => ({
  type: GET_STUDENT_ABSENT_DETAIL_FAIL,
  payload: error,
})

export const createStudent = (newStudent, history, dispatch) => ({
  type: CREATE_STUDENT,
  payload: { newStudent, history, dispatch },
})

export const createStudentSuccess = newStudent => ({
  type: CREATE_STUDENT_SUCCESS,
  payload: newStudent,
})

export const createStudentFail = error => ({
  type: CREATE_STUDENT_FAIL,
  payload: error,
})

export const updateStudent = (updatedStudent, studentId, history) => ({
  type: UPDATE_STUDENT,
  payload: { updatedStudent, studentId, history },
})

export const updateStudentSuccess = updatedStudent => ({
  type: UPDATE_STUDENT_SUCCESS,
  payload: updatedStudent,
})

export const updateStudentFail = error => ({
  type: UPDATE_STUDENT_FAIL,
  payload: error,
})

export const deleteStudent = (studentId, history) => ({
  type: DELETE_STUDENT,
  payload: { studentId, history },
})

export const deleteStudentSuccess = newStudent => ({
  type: DELETE_STUDENT_SUCCESS,
  payload: newStudent,
})

export const deleteStudentFail = error => ({
  type: DELETE_STUDENT_FAIL,
  payload: error,
})
