import React, { useState, Fragment } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Badge,
  Table,
  Collapse,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"

//actions familymember
import UiModalEducation from "./UiModalEducation"
import UiModalEducationUpdate from "./UiModalEducationUpdate"
import UiModalJob from "./UiModalJob"
import UiModalJobUpdate from "./UiModalJobUpdate"

import phone from "assets/images/icons/phone.png"
import gmail from "assets/images/icons/gmail.png"
import health from "assets/images/icons/health.png"
import certificate from "assets/images/icons/certificate.png"
import concentric from "assets/images/icons/concentric.png"
import location from "assets/images/icons/location.png"
import DeleteModal from "components/Common/DeleteModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import { deleteFamilyMember } from "store/actions"
import UiModalQrcode from "pages/common/UiModalQrcode"

const MahallFamilyMemberDetails = ({ history }) => {
  const dispatch = useDispatch()

  const { familyMemberDetail, detailLoading, language } = useSelector(
    state => ({
      detailLoading: state.FamilyMembers.detailLoading,
      familyMemberDetail: state.FamilyMembers.familyMemberDetail,
      language: state.Layout.language,
    })
  )
  const [modalQrcode, setModalQrcode] = useState(false)
  const [familyIdModal, setFamilyIdModal] = useState({})

  const [isOpen, setIsOpen] = useState(false)
  const [modalEducation, setModalEducation] = useState(false)
  const [modalEducationUpdate, setModalEducationUpdate] = useState(false)
  const [modalJob, setModalJob] = useState(false)
  const [modalJobUpdate, setModalJobUpdate] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(deleteFamilyMember(familyMemberDetail?.id, history))
    setIsOpen(false)
  }

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  const [onUpdateEducation, setOnUpdateEducation] = useState({})
  const [onUpdateJob, setOnUpdateJob] = useState({})

  const updateEducationModal = item => {
    setOnUpdateEducation(item)
    setModalEducationUpdate(true)
  }

  const updateJobModal = item => {
    setOnUpdateJob(item)
    setModalJobUpdate(true)
  }
  const [col1, setCol1] = useState(true)
  const [col2, setCol2] = useState(true)
  const [col3, setCol3] = useState(true)
  const [col4, setCol4] = useState(true)
  const familyIdQr = id => {
    setFamilyIdModal(id)
    setModalQrcode(true)
  }

  return (
    <>
      <UiModalQrcode
        show={modalQrcode}
        onCloseclick={() => setModalQrcode(false)}
        data={familyIdModal}
      />
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <UiModalEducation
        show={modalEducation}
        onCloseclick={() => setModalEducation(false)}
        history={history}
      />
      <UiModalEducationUpdate
        show={modalEducationUpdate}
        onCloseclick={() => setModalEducationUpdate(false)}
        data={onUpdateEducation}
        history={history}
      />
      <UiModalJob
        show={modalJob}
        onCloseclick={() => setModalJob(false)}
        history={history}
      />
      <UiModalJobUpdate
        show={modalJobUpdate}
        onCloseclick={() => setModalJobUpdate(false)}
        data={onUpdateJob}
        history={history}
      />

      <MetaTags>
        <title>Family Member | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal" ? "എല്ലാ കുടുംബാംഗങ്ങളും" : "All Family Members"
          }
          breadcrumbItem={language === "mal" ? "കുടുംബാംഗം" : "Family Member"}
        />
        <Container fluid className="sm:p-0">
          <div className="container-fluid">
            {detailLoading ? (
              <Spinner />
            ) : (
              <Row>
                <Card className="sm:p-0 px-0">
                  <div
                    className="card-header bg-khaf d-flex align-items-center familycard "
                    style={{
                      height: "45px",
                      borderRadius: "10px 10px 0 0",
                    }}
                  >
                    <h5 className="align-self-start text-white mb-0  pt-1 housename">
                      {familyMemberDetail?.family_name ? (
                        <Link
                          className="text-white"
                          to={`/families/${familyMemberDetail?.family}`}
                        >
                          {familyMemberDetail?.family_name}
                        </Link>
                      ) : (
                        familyMemberDetail?.family_name
                      )}
                    </h5>
                    <h6 className=" d-flex justify-content-between text-white  ms-auto pt-2 familyyear">
                      <Badge
                        className="rounded-pill bg-white me-2"
                        style={{ fontSize: "12px", color: "black" }}
                      >
                        {familyMemberDetail?.mahall_custom_id}
                      </Badge>
                      <Badge
                        className="rounded-pill bg-dark"
                        style={{ fontSize: "12px" }}
                      >
                        {familyMemberDetail?.custom_id}
                      </Badge>
                    </h6>
                  </div>
                  <CardBody
                    style={{
                      backgroundColor: "#f4fff4",
                      borderRadius: "0 0 10px 10px",
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <h5 className="font-size-15 text-black fw-bold">
                          {familyMemberDetail?.full_name}
                        </h5>
                        <div className="d-flex flex-wrap mt-3 text-black">
                          <p className="fs-5" style={{ width: "100px" }}>
                            {" "}
                            {language === "mal" ? "പ്രായം" : "Age"}{" "}
                          </p>
                          :{" "}
                          <span className="fs-5 px-2">
                            {getAge(familyMemberDetail?.dob)}{" "}
                          </span>
                        </div>
                        <div className="d-flex flex-wrap mt-1 text-black">
                          <p className="fs-5" style={{ width: "100px" }}>
                            {" "}
                            {language === "mal" ? "ലിംഗഭേദം" : "Gender"}
                          </p>
                          :{" "}
                          <span className="fs-5 px-2">
                            {familyMemberDetail?.gender}
                          </span>
                        </div>
                        <div className="d-flex flex-wrap mt-1 justify-content-between text-black">
                          <div className="d-flex ">
                            <p className="fs-5 m-0 pb-2">
                              {" "}
                              {familyMemberDetail?.spouse_name &&
                                `Married to ${familyMemberDetail?.spouse_name}`}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap mt-1 justify-content-between text-black">
                          <div className="d-flex ">
                            <p className="fs-5">
                              {" "}
                              {familyMemberDetail?.father_name &&
                                (familyMemberDetail?.gender === "male"
                                  ? `S/o `
                                  : `D/o `)}
                              {familyMemberDetail?.father_id ? (
                                <Link
                                  to={`/familymembers/${familyMemberDetail?.father_id}`}
                                >
                                  {familyMemberDetail?.father_name}
                                </Link>
                              ) : (
                                familyMemberDetail?.father_name
                              )}
                              {familyMemberDetail?.mother_name && (
                                <>
                                  {familyMemberDetail?.mother_id ? (
                                    <Link
                                      to={`/familymembers/${familyMemberDetail?.mother_id}`}
                                    >
                                      {familyMemberDetail?.mother_name}
                                    </Link>
                                  ) : (
                                    ` & ${familyMemberDetail?.mother_name}`
                                  )}
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className=" justify-end">
                          <div>
                            <div className="d-flex justify-content-end startjustify position-relative">
                              <img
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  objectFit: "contain",
                                }}
                                className="rounded"
                                src={familyMemberDetail?.member_image}
                              />
                              <h1
                                className="d-flex justify-content-end  position-absolute text-primary"
                                style={{
                                  right: "5px",
                                  bottom: "0px",
                                  fontSize: "10px",
                                  top: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  familyIdQr(familyMemberDetail?.id)
                                }
                              >
                                {language === "mal"
                                  ? "QR കോഡ് കാണുക"
                                  : "View QR Code"}{" "}
                              </h1>
                            </div>
                            <div className="d-flex flex-wrap mt-1 justify-content-end text-black startjustify">
                              <div className="d-flex">
                                <p style={{ width: "80px" }}>
                                  {" "}
                                  {language === "mal"
                                    ? familyMemberDetail?.id_card?.malayalam
                                    : familyMemberDetail?.id_card?.english}{" "}
                                </p>
                                {familyMemberDetail &&
                                  familyMemberDetail?.id_card_number && (
                                    <span>
                                      {" "}
                                      : {familyMemberDetail?.id_card_number}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="varisangya-div">
                            <div>
                              {familyMemberDetail?.varisankya_amount && (
                                <p className="fs-5 m-0 pb-2">
                                  <span className="text-black">
                                    {language === "mal"
                                      ? "വരിസംഖ്യ തുക :"
                                      : "Varisangya Amount :"}
                                  </span>
                                  <span className="mx-2 fw-bold font-size-15 text-success ">
                                    {familyMemberDetail?.varisankya_amount}
                                  </span>
                                </p>
                              )}
                              {familyMemberDetail?.varisankya_wallet && (
                                <p className="fs-5">
                                  <span className="text-black">
                                    {language === "mal"
                                      ? "വാലറ്റ് ബാലൻസ് : "
                                      : "Wallet Balance : "}
                                  </span>
                                  <span
                                    className={`fw-bold + ${
                                      familyMemberDetail?.varisankya_wallet == 0
                                        ? "text-primary"
                                        : familyMemberDetail?.varisankya_wallet >
                                          0
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                  >
                                    {familyMemberDetail?.varisankya_wallet}
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Card style={{ justifyContent: "center" }}>
                      <Row>
                        <Col md={6}>
                          <div className="d-flex flex-column justify-content-between mt-1">
                            <div className="d-flex m-3">
                              <img
                                src={phone}
                                alt={phone}
                                className="me-3"
                                style={{
                                  height: "17px",
                                  width: "17px",
                                }}
                              />
                              {familyMemberDetail?.phone && (
                                <p className="text-black fw-bold m-0 fs-5">
                                  {familyMemberDetail?.phone}
                                </p>
                              )}
                            </div>
                            <div className="d-flex mx-3 mb-3">
                              <img
                                src={gmail}
                                alt={gmail}
                                className="me-3"
                                style={{
                                  height: "17px",
                                  width: "17px",
                                }}
                              />
                              {familyMemberDetail?.email && (
                                <p className="text-black fw-bold fs-5 m-0">
                                  {familyMemberDetail?.email}
                                </p>
                              )}
                            </div>
                          </div>
                        </Col>

                        <Col
                          md={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="familybuttons"
                        >
                          <span
                            className="spandemmy"
                            style={{
                              height: "55px",
                              width: "2px",
                              backgroundColor: "#f2f1f1",
                              display: "inline-block",
                              position: "absolute",
                              borderRadius: "10px",
                            }}
                          ></span>
                          <div className="type-button align-items-center justify-content-between mx-auto">
                            {familyMemberDetail &&
                              familyMemberDetail?.blood_group &&
                              familyMemberDetail?.blood_group?.english && (
                                <Button className="me-2 bg-danger border-0 px-3 mb-2">
                                  {familyMemberDetail?.blood_group?.english}
                                </Button>
                              )}
                            {familyMemberDetail?.is_working ? (
                              <Button className="me-2 bg-info border-0 px-3 mb-2">
                                {language === "mal" ? "തൊഴിലാളി" : "Worker"}
                              </Button>
                            ) : (
                              <></>
                            )}
                            {familyMemberDetail?.is_abroad ? (
                              <Button className="me-2 bg-warning border-0 px-3 mb-2">
                                {language === "mal" ? "പ്രവാസി" : "Abroad"}{" "}
                                {familyMemberDetail &&
                                  familyMemberDetail?.present_country_malayalam &&
                                  `| ${
                                    language === "mal"
                                      ? familyMemberDetail?.present_country_malayalam
                                        ? familyMemberDetail?.present_country_malayalam
                                        : familyMemberDetail?.present_country_english
                                      : familyMemberDetail?.present_country_english
                                  }`}
                              </Button>
                            ) : (
                              <></>
                            )}
                            {familyMemberDetail?.is_orphan ? (
                              <Button className="me-2 bg-secondary border-0 px-3 mb-2">
                                {language === "mal" ? "അ നാഥൻ" : "Orphan"}
                              </Button>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card>

                    <Row>
                      <Col lg={6}>
                        <Card className="sm:p-0">
                          <div className="accordion" id="accordion">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{
                                    borderBottom: `${
                                      col3 ? "1px solid green" : "none"
                                    }`,
                                  }}
                                >
                                  <div
                                    className="d-flex m-2"
                                    style={{ marginLeft: "12px" }}
                                  >
                                    <img
                                      src={health}
                                      alt={health}
                                      className="me-2 py-1"
                                      style={{
                                        height: "35px",
                                        width: "26px",
                                      }}
                                    />
                                    <h5
                                      className=" mb-0 text-black mt-1  d-flex align-items-center"
                                      style={{ marginLeft: "2px" }}
                                    >
                                      {language === "mal"
                                        ? "ആരോഗ്യ വിവരങ്ങൾ"
                                        : "Health Details"}
                                    </h5>
                                  </div>
                                  <button
                                    className="border-0 bg-white text-khaf "
                                    type="button"
                                    onClick={() => setCol3(!col3)}
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "10px",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <i
                                      className={`${
                                        col3 ? "bx bx-minus" : "bx bx-plus"
                                      }`}
                                    />
                                  </button>
                                </div>
                              </h2>

                              <Collapse
                                isOpen={col3}
                                className="accordion-collapse"
                              >
                                <div className="accordion-body">
                                  <div className="d-flex flex-column justify-content-between ">
                                    <div className="d-flex">
                                      <h5
                                        className="text-black mt-1 cardfirstsection"
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "ആരോഗ്യ സ്ഥിതി"
                                          : "Health Status"}
                                      </h5>
                                      <p className="fs-5">
                                        <span className="me-2">:</span>
                                        <span className="text-black ">
                                          {familyMemberDetail?.health_condition?.map(
                                            (item, key) => {
                                              return (
                                                <Fragment key={key}>{`${
                                                  key != 0 ? "," : ""
                                                } ${
                                                  language === "mal"
                                                    ? item.malayalam
                                                    : item.english
                                                } `}</Fragment>
                                              )
                                            }
                                          )}
                                        </span>{" "}
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <h5
                                        className="text-black cardfirstsection"
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "രോഗത്തിന്റെ തരം"
                                          : "Disease Type"}{" "}
                                      </h5>
                                      <p className="fs-5">
                                        <span className="me-2">:</span>
                                        <span className="text-black">
                                          {familyMemberDetail?.disease_type?.map(
                                            (item, key) => {
                                              return (
                                                <Fragment key={key}>{`${
                                                  key != 0 ? "," : ""
                                                } ${
                                                  language === "mal"
                                                    ? item.malayalam
                                                    : item.english
                                                } `}</Fragment>
                                              )
                                            }
                                          )}
                                        </span>{" "}
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <h5
                                        className="text-black cardfirstsection"
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "രോഗ നില"
                                          : "Disease Level"}
                                      </h5>
                                      <p className="fs-5">
                                        <span className="me-2">:</span>
                                        <span className="text-black">
                                          {language === "mal"
                                            ? familyMemberDetail?.disease_level
                                                ?.malayalam
                                            : familyMemberDetail?.disease_level
                                                ?.english}
                                        </span>{" "}
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <h5
                                        className="text-black cardfirstsection"
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "വൈകല്യ തരം"
                                          : "Disability Type"}{" "}
                                      </h5>
                                      <p className="fs-5">
                                        <span className="me-2">:</span>
                                        <span className="text-black">
                                          {familyMemberDetail?.disability_type?.map(
                                            (item, key) => {
                                              return (
                                                <Fragment key={key}>{`${
                                                  key != 0 ? "," : ""
                                                } ${
                                                  language === "mal"
                                                    ? item.malayalam
                                                    : item.english
                                                } `}</Fragment>
                                              )
                                            }
                                          )}
                                        </span>{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        </Card>
                      </Col>
                      <Col lg={6}>
                        <Card className="sm:p-0">
                          <div className="accordion" id="accordion">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{
                                    borderBottom: `${
                                      col1 ? "1px solid green" : "none"
                                    }`,
                                  }}
                                >
                                  <div
                                    className="d-flex m-2"
                                    style={{ marginLeft: "12px" }}
                                  >
                                    <img
                                      src={concentric}
                                      alt={concentric}
                                      className="me-2 py-1"
                                      style={{
                                        height: "35px",
                                        width: "26px",
                                      }}
                                    />
                                    <h5
                                      className=" mb-0 text-black mt-1  d-flex align-items-center"
                                      style={{ marginLeft: "2px" }}
                                    >
                                      {language === "mal"
                                        ? "മറ്റുള്ളവ : "
                                        : "Co-curricular "}
                                    </h5>
                                  </div>
                                  <button
                                    className="border-0 bg-white text-khaf "
                                    type="button"
                                    onClick={() => setCol1(!col1)}
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "10px",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <i
                                      className={`${
                                        col1 ? "bx bx-minus" : "bx bx-plus"
                                      }`}
                                    />
                                  </button>
                                </div>
                              </h2>

                              <Collapse
                                isOpen={col1}
                                className="accordion-collapse"
                              >
                                <div className="accordion-body">
                                  <div className="d-flex flex-column justify-content-between ">
                                    <div className="d-flex">
                                      <h5
                                        className="text-black mt-1 cardfirstsection"
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "സാമൂഹിക പ്രവർത്തനങ്ങൾ"
                                          : "Social Activities"}
                                      </h5>
                                      <p className="fs-5">
                                        <span className="me-2">:</span>
                                        <span className="text-black ">
                                          {familyMemberDetail?.social_activity?.map(
                                            (item, key) => {
                                              return (
                                                <Fragment key={key}>{`${
                                                  key != 0 ? "," : ""
                                                } ${
                                                  language === "mal"
                                                    ? item.malayalam
                                                    : item.english
                                                } `}</Fragment>
                                              )
                                            }
                                          )}
                                        </span>{" "}
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <h5
                                        className="text-black cardfirstsection"
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "കഴിവുകൾ"
                                          : "Skills"}{" "}
                                      </h5>
                                      <p className="fs-5 ">
                                        <span className="me-2">:</span>
                                        <span className="text-black">
                                          {familyMemberDetail?.skills}
                                        </span>{" "}
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <h5
                                        className="text-black cardfirstsection"
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "മോശം ശീലങ്ങൾ"
                                          : "Bad Habits"}{" "}
                                      </h5>
                                      <p className=" fs-5">
                                        <span className="me-2">:</span>
                                        <span className="text-black">
                                          {familyMemberDetail?.bad_habits?.map(
                                            (item, key) => {
                                              return (
                                                <Fragment key={key}>{`${
                                                  key != 0 ? "," : ""
                                                } ${
                                                  language === "mal"
                                                    ? item.malayalam
                                                    : item.english
                                                } `}</Fragment>
                                              )
                                            }
                                          )}
                                        </span>{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Card className="sm:p-0">
                          <div className="accordion" id="accordion">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{
                                    borderBottom: `${
                                      col4 ? "1px solid green" : "none"
                                    }`,
                                  }}
                                >
                                  <div
                                    className="d-flex m-2"
                                    style={{ marginLeft: "12px" }}
                                  >
                                    <img
                                      src={certificate}
                                      alt={certificate}
                                      className="me-2 py-1"
                                      style={{
                                        height: "35px",
                                        width: "26px",
                                      }}
                                    />
                                    <h5
                                      className=" mb-0 text-black mt-1  d-flex align-items-center"
                                      style={{ marginLeft: "2px" }}
                                    >
                                      {language === "mal"
                                        ? "യോഗ്യത"
                                        : "Qualification "}
                                    </h5>
                                  </div>
                                  <button
                                    className="border-0 bg-white text-khaf "
                                    type="button"
                                    onClick={() => setCol4(!col4)}
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "10px",
                                      boxShadow: "none",
                                    }}
                                  >
                                    {" "}
                                    <i
                                      className={`${
                                        col4 ? "bx bx-minus" : "bx bx-plus"
                                      }`}
                                    />
                                  </button>
                                </div>
                              </h2>

                              <Collapse
                                isOpen={col4}
                                className="accordion-collapse"
                              >
                                <div className="accordion-body">
                                  <div className="d-flex flex-column justify-content-between ">
                                    <div className="d-flex">
                                      <h5
                                        className="text-black mt-1 cardfirstsection"
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "ഭൗതിക വിദ്യാഭ്യാസം"
                                          : "Formal"}
                                      </h5>
                                      <p className="fs-5">
                                        <span className="me-2">:</span>
                                        <span className="text-black ">
                                          {language === "mal"
                                            ? familyMemberDetail
                                                ?.educational_qualification_formal
                                                ?.malayalam
                                            : familyMemberDetail
                                                ?.educational_qualification_formal
                                                ?.english}
                                        </span>{" "}
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <h5
                                        className="text-black cardfirstsection"
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "മത വിദ്യാഭ്യാസം"
                                          : "Islamic"}{" "}
                                      </h5>
                                      <p className="fs-5">
                                        <span className="me-2">:</span>
                                        <span className="text-black">
                                          {language === "mal"
                                            ? familyMemberDetail
                                                ?.educational_qualification_islamic
                                                ?.malayalam
                                            : familyMemberDetail
                                                ?.educational_qualification_islamic
                                                ?.english}
                                        </span>{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        </Card>
                      </Col>

                      <Col lg={6}>
                        <Card className="sm:p-0">
                          <div className="accordion" id="accordion">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <div
                                  className="d-flex align-items-center justify-content-between "
                                  style={{
                                    borderBottom: `${
                                      col2 ? "1px solid green" : "none"
                                    }`,
                                  }}
                                >
                                  <div
                                    className="d-flex m-2"
                                    style={{ marginLeft: "12px" }}
                                  >
                                    <img
                                      src={location}
                                      alt={location}
                                      className="me-2 py-1"
                                      style={{
                                        height: "35px",
                                        width: "26px",
                                      }}
                                    />
                                    <h5
                                      className=" mb-0 text-black mt-1 d-flex align-items-center"
                                      style={{ marginLeft: "2px" }}
                                    >
                                      {language === "mal"
                                        ? "വിലാസം :"
                                        : " Address "}
                                    </h5>
                                  </div>
                                  <button
                                    className="border-0 bg-white text-khaf "
                                    type="button"
                                    onClick={() => setCol2(!col2)}
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "10px",
                                      boxShadow: "none",
                                    }}
                                  >
                                    {" "}
                                    <i
                                      className={`${
                                        col2 ? "bx bx-minus" : "bx bx-plus"
                                      }`}
                                    />
                                  </button>
                                </div>
                              </h2>

                              <Collapse
                                isOpen={col2}
                                className="accordion-collapse"
                              >
                                <div className="accordion-body">
                                  <div className="d-flex flex-column justify-content-between ">
                                    <div className="d-flex">
                                      <h5
                                        className="text-black mt-1 cardfirstsection "
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "സ്ഥിരമായ വിലാസം :"
                                          : "Permenent Address "}
                                      </h5>
                                      <p className="fs-5">
                                        <span className="me-2">:</span>
                                        <span className="text-black ">
                                          {familyMemberDetail?.family_place &&
                                            `${familyMemberDetail?.family_place}, `}
                                          {familyMemberDetail?.family_post_office &&
                                            `${familyMemberDetail?.family_post_office} post-`}
                                          {familyMemberDetail?.family_pin_code &&
                                            `${familyMemberDetail?.family_pin_code}, `}
                                          <br />
                                          <span className="px-2">
                                            {familyMemberDetail?.family_district &&
                                              `${familyMemberDetail?.family_district}, `}
                                            {familyMemberDetail?.family_state &&
                                              `${familyMemberDetail?.family_state}`}
                                          </span>
                                        </span>{" "}
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <h5
                                        className="text-black cardfirstsection"
                                        style={{ minWidth: "250px" }}
                                      >
                                        {language === "mal"
                                          ? "ഇപ്പോഴത്തെ വിലാസം :"
                                          : "Present Address "}
                                      </h5>
                                      <p className="fs-5">
                                        <span className="me-2">:</span>
                                        <span className="text-black">
                                          {familyMemberDetail?.present_address
                                            ? familyMemberDetail?.present_address
                                            : "Same as above"}
                                        </span>{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md="6">
                        <div className="d-flex align-items-end mb-3">
                          <p className="text-black fs-6 m-0">
                            Listed By :{" "}
                            <span className="text-black px-1">
                              {familyMemberDetail?.creator_name}
                            </span>
                          </p>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="d-flex justify-content-end">
                          <Link
                            to={`/familymember/update/${familyMemberDetail?.id}`}
                          >
                            <Button className="me-2 bg-khaf border-0">
                              {language === "mal" ? "പുതുക്കുക" : "Edit"}
                            </Button>
                          </Link>
                          <Link
                            style={{ opacity: 0.8 }}
                            to="#"
                            onClick={handleDelete}
                          >
                            <Button className="bg-danger border-0">
                              {language === "mal" ? "ഇല്ലാതാക്കുക" : "Delete"}
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="sm:p-0">
                  <CardBody>
                    {/* Education History */}
                    <Col sm="12" md="12" lg="12" className="mb-4 mt-3 sm:mt-0">
                      <div className="d-flex justify-content-between align-items-center eduHistory">
                        <h5 className="font-size-14 font-weight-bold">
                          {language === "mal"
                            ? "വിദ്യാഭ്യാസം :"
                            : "Education History :"}
                        </h5>
                        <Button
                          className="btn btn-success m-2 p-1"
                          onClick={() => {
                            setModalEducation(true)
                          }}
                        >
                          {language === "mal"
                            ? "+ വിദ്യാഭ്യാസം ചേർക്കുക"
                            : "+ Add Education"}
                        </Button>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-bordered mb-0">
                          <thead>
                            <tr>
                              <th>
                                {language === "mal" ? "തീയതി" : "Join Date"}
                              </th>
                              <th>
                                {language === "mal" ? "കോഴ്സ്" : "Course"}
                              </th>
                              <th>
                                {language === "mal" ? "സ്ഥാപനം" : "Institution"}
                              </th>
                              <th>{language === "mal" ? "സ്ഥലം" : "Place"}</th>
                              <th>
                                {language === "mal" ? "തീയതി" : "Pass Date"}
                              </th>
                              <th>
                                {language === "mal"
                                  ? "പ്രവർത്തനങ്ങൾ"
                                  : "Actions"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {familyMemberDetail?.education?.map((item, key) => (
                              <Fragment key={key}>
                                <tr>
                                  <td scope="row">
                                    {item?.join_date
                                      ? moment(item?.join_date).format(
                                          "DD-MM-yyyy"
                                        )
                                      : "NA"}
                                  </td>
                                  <td>{item?.course}</td>
                                  <td>{item?.institution_name}</td>
                                  <td>{item?.institution_place}</td>
                                  <td scope="row">
                                    {item?.pass_date
                                      ? moment(item?.pass_date).format(
                                          "DD-MM-yyyy"
                                        )
                                      : "NA"}
                                  </td>
                                  <td
                                    className="m-0 p-0"
                                    style={{ maxWidth: "200px" }}
                                  >
                                    <Button
                                      type="button"
                                      color="white"
                                      className="ms-1 btn"
                                      onClick={() => updateEducationModal(item)}
                                    >
                                      <i
                                        className="bx bx-pencil me-2"
                                        style={{
                                          color: "green",
                                          fontSize: "14px",
                                        }}
                                      />
                                    </Button>
                                  </td>
                                </tr>
                              </Fragment>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      {familyMemberDetail &&
                        familyMemberDetail?.education &&
                        familyMemberDetail?.education?.length <= 0 && (
                          <p
                            style={{ fontSize: "15px" }}
                            className="text-center text-info pt-5"
                          >
                            {language === "mal"
                              ? "വിദ്യാഭ്യാസം ഒന്നും ഇല്ല"
                              : "No Educations Yet"}
                          </p>
                        )}
                    </Col>
                  </CardBody>
                </Card>
                <Card className="sm:p-0">
                  <CardBody>
                    {/* Job History */}
                    <Col sm="12" md="12" lg="12" className="mb-4 mt-3">
                      <div className="d-flex justify-content-between align-items-center eduHistory">
                        <h5 className="font-size-14 font-weight-bold">
                          {language === "mal" ? "ജോലി :" : "Job History :"}
                        </h5>
                        <Button
                          className="btn btn-success m-2 p-1"
                          onClick={() => {
                            setModalJob(true)
                          }}
                        >
                          {language === "mal" ? "+ ജോലി ചേർക്കുക" : "+ Add Job"}
                        </Button>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-bordered mb-0">
                          <thead>
                            <tr>
                              <th>{language === "mal" ? "തീയതി" : "Date"}</th>
                              <th>
                                {language === "mal"
                                  ? "ഉദ്യോഗ സ്ഥാനം"
                                  : "Job Position"}
                              </th>
                              <th>{language === "mal" ? "ഓഫീസ്" : "Office"}</th>
                              <th>
                                {language === "mal"
                                  ? "മേഖല/തരം"
                                  : "Sector/Type"}
                              </th>
                              <th>
                                {language === "mal"
                                  ? "പ്രവർത്തനങ്ങൾ"
                                  : "Actions"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {familyMemberDetail?.job?.map((item, key) => (
                              <Fragment key={key}>
                                <tr>
                                  <td scope="row">
                                    {item?.join_date
                                      ? moment(item?.join_date).format(
                                          "DD-MM-yyyy"
                                        )
                                      : "NA"}
                                  </td>
                                  <td>{item?.job_position}</td>
                                  <td>{`${item?.job_office}, ${item?.job_place}`}</td>
                                  <td>
                                    {item?.job_sector &&
                                      `${item?.job_sector?.english}/ ${item?.job_type?.english}`}
                                  </td>
                                  <td
                                    className="m-0 p-0"
                                    style={{ maxWidth: "200px" }}
                                  >
                                    <Button
                                      type="button"
                                      color="white"
                                      className="ms-1 btn"
                                      onClick={() => updateJobModal(item)}
                                    >
                                      <i
                                        className="bx bx-pencil me-2"
                                        style={{
                                          color: "green",
                                          fontSize: "14px",
                                        }}
                                      />
                                    </Button>
                                  </td>
                                </tr>
                              </Fragment>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      {familyMemberDetail &&
                        familyMemberDetail?.job &&
                        familyMemberDetail?.job?.length <= 0 && (
                          <p
                            style={{ fontSize: "15px" }}
                            className="text-center text-info pt-5"
                          >
                            {language === "mal"
                              ? "ജോലികൾ ഒന്നും ഇല്ല"
                              : "No Jobs Yet"}
                          </p>
                        )}
                    </Col>
                  </CardBody>
                </Card>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default MahallFamilyMemberDetails

MahallFamilyMemberDetails.propTypes = {
  history: PropTypes.object,
}
