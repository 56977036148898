import React, { useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Keyboard, Mousewheel } from "swiper"
// import { Navigation } from "swiper"

import UiModalGallery from "./UiModalGallery"
import UiModalGalleryUpdate from "./UiModalGalleryUpdate"
import UiModalCommittee from "./UiModalCommittee"
import UiModalInstituteCategory from "./UiModalInstituteCategory"
import UiModalInstituteCategoryUpdate from "./UiModalInstituteCategoryUpdate"
import UiModalLedger from "./UiModalLedger"
import WelcomeComp from "./WelcomeComp"

import DeleteModal from "components/Common/DeleteModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  deleteInstitute,
  getInstituteDetail,
  getLedgers,
  getCommittees,
  getGalleries,
  getLedgerItemCategories,
  getAssets,
  getInstituteWallets,
} from "store/actions"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.scss"
import { useWindowSize } from "hooks/useWindowSize"
import { handleFinancialYear } from "pages/admin/Mahalls/financialYear"
import MyPagination from "components/Common/MyPagination"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { debounce, map, range } from "lodash"
import UiModalAsset from "./UiModalAsset"
import UiModalInstituteWallet from "./UiModalAddWallet"
import CardWallet from "components/Common/card-wallet"
import UiModalUpdateInstituteWallet from "./UiModalUpdateWallet"

SwiperCore.use([Navigation, Keyboard, Mousewheel])

const InstituteMahallDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { width } = useWindowSize()

  const {
    instituteDetail,
    loading,
    userDetail,
    language,
    ledgers,
    total_income,
    total_expense,
    total_balance,
    committees,
    galleries,
    ledgerItemCategories,
    assets,
    instituteWallets,
    ledgersCount,
  } = useSelector(state => ({
    loading: state.Institutes.loading,
    userDetail: state.Users.userDetail,
    instituteDetail: state.Institutes.instituteDetail,
    language: state.Layout.language,
    ledgers: state.Ledgers?.ledgers?.results?.ledgers,
    ledgersCount: state.Ledgers?.ledgers?.count,
    total_income: state.Ledgers?.ledgers?.results?.total_income,
    total_expense: state.Ledgers?.ledgers?.results?.total_expense,
    total_balance: state.Ledgers?.ledgers?.results?.total_balance,
    galleryDetail: state.Galleries.galleryDetail,
    galleries: state.Galleries.galleries,
    committees: state.Committees.committees,
    ledgerItemCategories: state.LedgerItemCategories.ledgerItemCategories,
    assets: state.Assets.assets,
    instituteWallets: state.InstituteWallets.instituteWallets,
  }))

  const { finalToDate, fromDate } = handleFinancialYear(
    userDetail?.mahall?.financial_year_starting_month
  )

  const [isOpen, setIsOpen] = useState(false)
  const [modalGallery, setModalGallery] = useState(false)
  const [modalGalleryUpdate, setModalGalleryUpdate] = useState(false)
  const [onUpdateGallery, setOnUpdateGallery] = useState({})

  const [modalLedger, setModalLedger] = useState(false)
  const [modalCommittee, setModalCommittee] = useState(false)
  const [modalInstituteCategory, setModalInstituteCategory] = useState(false)
  const [modalInstituteCategoryUpdate, setModalInstituteCategoryUpdate] =
    useState(false)
  const [onUpdateInstituteCategory, setOnUpdateInstituteCategory] = useState({})
  const [modalAsset, setModalAsset] = useState(false)
  const [modalWallet, setModalWallet] = useState(false)
  const [updateModalWallet, setUpdateModalWallet] = useState(false)
  const [onUpdateWalletData, setOnUpdateWalletData] = useState({})

  const updateWalletData = item => {
    setUpdateModalWallet(true)
    setOnUpdateWalletData(item)
  }

  const [searchText, setSearchText] = useState("")

  //Ledgers
  const [pageLedger, setPageLedger] = useState(1)
  const totalLedgersPages = Math.ceil((ledgersCount || 1) / 10)
  const ledgerPages = range(1, totalLedgersPages + 1)

  //assets
  const [pageAsset, setPageAsset] = useState(1)
  const totalAssetPages = Math.ceil((assets?.count || 1) / 10)
  const assetPages = range(1, totalAssetPages + 1)

  //committee
  const [pageCommittee, setPageCommittee] = useState(1)
  const committeeTotalPages = Math.ceil(committees?.count / 10)
  const committeePages = range(1, committeeTotalPages + 1)

  //category
  const [pageCategory, setPageCategory] = useState(1)
  const categoryTotalPages = Math.ceil(ledgerItemCategories?.count / 10)
  const categoryPages = range(1, categoryTotalPages + 1)

  //wallet
  const [pageWallet, setPageWallet] = useState(1)
  const walletTotalPages = Math.ceil(instituteWallets?.count / 10)
  const walletPages = range(1, walletTotalPages + 1)

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ." : "No."}`,
      sort: true,
    },
    {
      dataField: "asset_name",
      text: `${language === "mal" ? "അസറ്റ് പേര്" : "Asset Name"}`,
      sort: true,
    },
    {
      dataField: "remark",
      text: `${language === "mal" ? "റിമാർക്" : "Remark"}`,
    },
    // {
    //   dataField: "constituency_name",
    //   text: "Constituency",
    // },
    // {
    //   dataField: "district_english_name",
    //   text: "District",
    // },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const assetsData = map(assets?.results, (item, index) => ({
    ...item,
    key: index,
    no: index + 1,
    action: (
      <div>
        <Link
          to={`/mahall/institute/asset/${item?.id}`}
          className="btn btn-success w-xs btn-sm"
        >
          {language === "mal" ? "കാണുക" : "View"}
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  const debounceAssetSearch = debounce(value => setSearchText(value), 600)

  const handleDeleteEvent = () => {
    dispatch(deleteInstitute(instituteDetail.id, history))
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getInstituteWallets(instituteDetail.id, "", pageWallet))
  }, [dispatch, instituteDetail, pageWallet])

  useEffect(() => {
    dispatch(getAssets(instituteDetail.id, searchText, pageAsset))
  }, [dispatch, instituteDetail, searchText, pageAsset])

  useEffect(() => {
    dispatch(getInstituteDetail(params.id))
  }, [])

  useEffect(() => {
    if (instituteDetail?.id) {
      dispatch(getLedgers(instituteDetail?.id, "", pageLedger))
    }
  }, [instituteDetail, pageLedger])

  useEffect(() => {
    if (instituteDetail?.id) {
      dispatch(getCommittees(instituteDetail?.id, pageCommittee))
    }
  }, [instituteDetail, pageCommittee])

  useEffect(() => {
    if (instituteDetail?.id) {
      dispatch(getGalleries(instituteDetail?.id))
    }
  }, [instituteDetail])

  useEffect(() => {
    if (instituteDetail?.id) {
      dispatch(getLedgerItemCategories(instituteDetail?.id, "", pageCategory))
    }
  }, [instituteDetail, pageCategory])

  const cardData = [
    {
      title: `${language === "mal" ? "യൂസറുകൾ" : "Users"}`,
      cardValue: `${instituteDetail?.total_institute_user ?? 0}`,
      iconClass: "user",
      routeLink: "/#!",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "ലെഡ്ജറുകൾ" : "Ledgers"}`,
      cardValue: `${instituteDetail?.total_institute_accounts ?? 0}`,
      iconClass: "globe",
      routeLink: "/#!",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Ledgers"}`,
    },
    {
      title: `${language === "mal" ? "കമ്മിറ്റികൾ" : "Committees"}`,
      cardValue: `${instituteDetail?.total_institute_committees ?? 0}`,
      iconClass: "chat",
      routeLink: "/#!",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Committees"}`,
    },
    {
      title: `${
        language === "mal" ? "കമ്മിറ്റി അംഗങ്ങൾ" : "Committee Members"
      }`,
      cardValue: `${instituteDetail?.total_institute_committee_members ?? 0}`,
      iconClass: "group",
      routeLink: "/#!",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Committee Members"}`,
    },
    {
      title: `${language === "mal" ? "യോഗങ്ങൾ" : "Meetings"}`,
      cardValue: `${instituteDetail?.total_institute_committee_meetings ?? 0}`,
      iconClass: "chat",
      routeLink: "/#!",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Meetings"}`,
    },
  ]

  const updateGalleryModal = item => {
    setOnUpdateGallery(item)
    setModalGalleryUpdate(true)
  }

  const updateInstituteCategoryModal = item => {
    setOnUpdateInstituteCategory(item)
    setModalInstituteCategoryUpdate(true)
  }

  const handleWindowSizeVsSliderPreview = () => {
    if (width <= 480) {
      return 1
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 3.5
    } else return 3.5
  }

  return (
    <>
      <UiModalInstituteCategoryUpdate
        show={modalInstituteCategoryUpdate}
        onCloseclick={() => setModalInstituteCategoryUpdate(false)}
        data={onUpdateInstituteCategory}
        history={history}
      />
      <UiModalInstituteCategory
        show={modalInstituteCategory}
        onCloseclick={() => setModalInstituteCategory(false)}
        history={history}
      />
      <UiModalCommittee
        show={modalCommittee}
        onCloseclick={() => setModalCommittee(false)}
        history={history}
      />
      <UiModalLedger
        show={modalLedger}
        onCloseclick={() => setModalLedger(false)}
        history={history}
      />
      <UiModalGalleryUpdate
        show={modalGalleryUpdate}
        onCloseclick={() => setModalGalleryUpdate(false)}
        data={onUpdateGallery}
        history={history}
      />
      <UiModalGallery
        show={modalGallery}
        onCloseclick={() => setModalGallery(false)}
        history={history}
      />
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <UiModalAsset
        show={modalAsset}
        onCloseclick={() => setModalAsset(false)}
      />
      <UiModalInstituteWallet
        show={modalWallet}
        onCloseclick={() => setModalWallet(false)}
      />
      <UiModalUpdateInstituteWallet
        show={updateModalWallet}
        onCloseclick={() => setUpdateModalWallet(false)}
        data={onUpdateWalletData}
      />

      <MetaTags>
        <title>
          {" "}
          {instituteDetail?.institute_type === "institute"
            ? "Institutes"
            : "Programs"}{" "}
          | Khaf{" "}
        </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            instituteDetail?.institute_type === "institute"
              ? language === "mal"
                ? "എല്ലാ സ്ഥാപനങ്ങളും"
                : "All Institutes"
              : language === "mal"
              ? "എല്ലാ പ്രോഗ്രാമുകളും"
              : "All Programs"
          }
          breadcrumbItem={
            instituteDetail?.institute_type === "institute"
              ? language === "mal"
                ? "സ്ഥാപനം"
                : "Institute"
              : language === "mal"
              ? "പ്രോഗ്രാം "
              : "Program"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            {loading ? (
              <Spinner color="secondary" className="d-block m-auto" />
            ) : (
              <Row>
                <Col lg="12" className="">
                  <Row>
                    <Col lg="12">
                      <WelcomeComp />
                    </Col>
                    <Col lg="12">
                      <Row>
                        {cardData?.map((item, key) => (
                          <Col
                            sm="12"
                            md="4"
                            lg="4"
                            key={key}
                            className="sm:p-0 mt-0"
                          >
                            <Card className="blog-stats-wid">
                              <CardBody className="mhluser-cardbody">
                                <Link to={item.routeLink && item.routeLink}>
                                  <div className="d-flex flex-wrap">
                                    <div className="avatar-sm mt-1">
                                      <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                                        <i
                                          className={
                                            "bx bx-" + `${item.iconClass}`
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                    <div className="mx-4 pt-2">
                                      <p className="text-muted mb-1">
                                        {item.title}
                                      </p>
                                      {item.cardValue && (
                                        <h5 className="mb-0">
                                          {item.cardValue}
                                        </h5>
                                      )}
                                      <p className="m-0 text-info">
                                        {item.cardValue !== "" ? (
                                          <></>
                                        ) : (
                                          item.alt
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <div className="d-flex mb-3">
                      <h5>{language === "mal" ? "ഗാലറി" : "Gallery"}</h5>
                      <div>
                        <Button
                          onClick={() => setModalGallery(true)}
                          className="btn btn-success btn-sm mx-3"
                        >
                          {language === "mal" ? "+ചേർക്കുക" : "+Add"}
                        </Button>
                      </div>
                    </div>

                    <div className="carausel-4-columns-cover card-product-small arrow-center position-relative">
                      <Swiper
                        spaceBetween={10}
                        slidesPerView={handleWindowSizeVsSliderPreview()}
                        grid={{
                          rows: 2,
                        }}
                        keyboard={{ enabled: true }}
                        direction="horizontal"
                        mousewheel={true}
                        navigation={{
                          prevEl: ".custom_prev_f",
                          nextEl: ".custom_next_f",
                        }}
                        autoplay={true}
                        speed={600}
                        className=""
                        breakpoints={{
                          480: {
                            slidesPerView: 1,
                          },
                          640: {
                            slidesPerView: 2.5,
                          },
                          768: {
                            slidesPerView: 3,
                          },
                          1024: {
                            slidesPerView: 4,
                          },
                        }}
                      >
                        {galleries?.results?.map((item, i) => (
                          <SwiperSlide key={i}>
                            <div>
                              <img
                                src={item?.image}
                                alt=""
                                width={"100%"}
                                height={"200px"}
                                style={{
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => updateGalleryModal(item)}
                              />
                              <p className="m-0 py-2">{item?.title}</p>
                            </div>
                          </SwiperSlide>
                        ))}
                        <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow">
                          <span className="slider-btn slider-prev slick-arrow custom_prev_f ">
                            <i className="bx bx-left-arrow-alt"></i>
                          </span>
                          <span className="slider-btn slider-next slick-arrow custom_next_f">
                            <i className="bx bx-right-arrow-alt"></i>
                          </span>
                        </div>
                      </Swiper>
                    </div>

                    {/* <Swiper
                      navigation={true}
                      modules={[Navigation]}
                      slidesPerView={handleImageSize()}
                      grid={{
                        rows: 1,
                      }}
                      className="mySwiper"
                    >
                      {galleries?.results?.map((item, key) => (
                        <SwiperSlide key={key} className="">
                          <div className="mt-5" style={{ minHeight: "280px" }}>
                            <img
                              onClick={() => updateGalleryModal(item)}
                              className="gallery-img"
                              src={item?.image ? item?.image : image}
                              alt={image}
                            />
                            <h5 className="text-center text-muted">
                              {item?.title}
                            </h5>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper> */}
                  </Row>
                  <Row className="mt-3">
                    <div className="d-flex mb-3">
                      <h5>{language === "mal" ? "വാലറ്റുകൾ" : "Wallets"}</h5>
                      <Button
                        className="btn-sm mx-2"
                        color="success"
                        onClick={() => setModalWallet(true)}
                      >
                        {language === "mal" ? "+ ചേർക്കുക" : "+ Add"}
                      </Button>
                    </div>
                    <div className="row">
                      {map(instituteWallets?.results, (wallet, key) => (
                        <CardWallet
                          walletData={wallet}
                          updateWalletModal={updateWalletData}
                          key={key}
                        />
                      ))}
                      <MyPagination
                        totalPages={walletPages}
                        page={pageWallet}
                        setPage={setPageWallet}
                        title="wlt"
                      />
                    </div>
                  </Row>
                  <Row className="mt-5">
                    <Col md="12" lg="6">
                      <h5>{language === "mal" ? "അക്കൗണ്ട്" : "Account"}</h5>
                      <Card>
                        <CardBody>
                          <h6 className="text-black ">
                            {language === "mal" ? "സമ്മറി :" : "Summary :"}
                          </h6>
                          <p
                            className="text-center pb-2"
                            style={{
                              fontSize: "15px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              {language === "mal"
                                ? "സാമ്പത്തിക വർഷം :"
                                : "Financial Year :"}
                            </span>{" "}
                            <span className="text-primary">{`${
                              fromDate ? fromDate : ""
                            }${" "} to ${" "}${
                              finalToDate ? finalToDate : ""
                            }`}</span>
                          </p>
                          <Container fluid>
                            <div>
                              <Row className="m-3">
                                <Col md="4">
                                  <Card style={{ backgroundColor: "#e8fdfe" }}>
                                    <p
                                      className="p-3 m-0 justify-content-center"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "700",
                                        color: "#15a252",
                                      }}
                                    >
                                      {language === "mal" ? "വരവ്" : "Income"} :{" "}
                                      {total_income}
                                    </p>
                                  </Card>
                                </Col>
                                <Col md="4">
                                  <Card style={{ backgroundColor: "#fef4f0" }}>
                                    <p
                                      className="p-3 m-0 justify-content-center"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "700",
                                        color: "#ff4d45",
                                      }}
                                    >
                                      {language === "mal" ? "ചിലവ്" : "Expense"}{" "}
                                      :{total_expense}
                                    </p>
                                  </Card>
                                </Col>
                                <Col md="4">
                                  <Card style={{ backgroundColor: "#eef9f4" }}>
                                    <p
                                      className="p-3 m-0 justify-content-center"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "700",
                                        color: "blue",
                                      }}
                                    >
                                      {language === "mal"
                                        ? "ബാക്കി"
                                        : "Balance"}{" "}
                                      : {total_balance}
                                    </p>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          </Container>
                          <h6 className="text-black mt-4">
                            {language === "mal"
                              ? "എല്ലാ ലെഡ്ജറുകളും :"
                              : "All Ledgers :"}
                          </h6>
                          <Container fluid>
                            <Row>
                              {ledgers &&
                                ledgers.length > 0 &&
                                ledgers?.map((item, key) => (
                                  <Col
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    key={key}
                                    className="sm:p-0 mt-1"
                                  >
                                    <Card
                                      className="blog-stats-wid"
                                      style={{
                                        backgroundColor: "#dce0e4",
                                        borderRadius: "10px",
                                        minHeight: "130px",
                                      }}
                                    >
                                      <Link to={`/ledgers/${item?.id}`}>
                                        <h5 className="px-3 pt-3 text-black">
                                          {item.institute_ledger_name}
                                        </h5>
                                        <Row>
                                          <Col md="4">
                                            <div className="mx-3">
                                              <p className="m-0 text-muted pb-1">
                                                {" "}
                                                {language === "mal"
                                                  ? "വരവ്"
                                                  : "Income"}{" "}
                                              </p>

                                              <div
                                                className=" p-2 mb-3"
                                                style={{
                                                  borderRadius: "6px",
                                                  backgroundColor: "#e8fdfe",
                                                }}
                                              >
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "#15a252",
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  {item.credit_amount
                                                    ? item.credit_amount
                                                    : 0}
                                                </p>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mx-3">
                                              <p className="m-0 text-muted pb-1">
                                                {" "}
                                                {language === "mal"
                                                  ? "ചിലവ്"
                                                  : "Expense"}{" "}
                                              </p>

                                              <div
                                                className=" p-2"
                                                style={{
                                                  borderRadius: "6px",
                                                  backgroundColor: "#fef4f0",
                                                }}
                                              >
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "#ff4d45",
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  {item.debit_amount
                                                    ? item.debit_amount
                                                    : 0}
                                                </p>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mx-3">
                                              <p className="m-0 text-muted pb-1">
                                                {" "}
                                                {language === "mal"
                                                  ? "ബാക്കി"
                                                  : "Balance"}{" "}
                                              </p>

                                              <div
                                                className=" p-2"
                                                style={{
                                                  borderRadius: "6px",
                                                  backgroundColor: "#eef9f4",
                                                }}
                                              >
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "blue",
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  {item.balance
                                                    ? item.balance
                                                    : 0}
                                                </p>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Link>
                                    </Card>
                                  </Col>
                                ))}
                            </Row>
                            <MyPagination
                              totalPages={ledgerPages}
                              page={pageLedger}
                              setPage={setPageLedger}
                              title="ldg"
                            />
                            <Button
                              onClick={() => setModalLedger(true)}
                              className="bg-khaf w-100"
                            >
                              {language === "mal"
                                ? "+ പുതിയത് ചേർക്കുക"
                                : "+ Add New Ledger"}
                            </Button>
                          </Container>
                          <h6 className="text-muted mt-4">
                            {language === "mal"
                              ? "സ്ഥാപന ക്യാറ്റഗറികൾ :"
                              : "Institute Categories:"}
                          </h6>
                          <div className=" d-flex justify-content-end">
                            <Button
                              type="button"
                              color="success"
                              className="btn-sm"
                              onClick={() => setModalInstituteCategory(true)}
                            >
                              {language === "mal"
                                ? "+ പുതിയത് ചേർക്കുക"
                                : "+ Add Category"}
                            </Button>
                          </div>
                          <Table className="mx-1 mt-1">
                            <thead>
                              <tr>
                                <th>
                                  {language === "mal"
                                    ? "ക്യാറ്റഗറിയുടെ പേര്"
                                    : "Category Name"}
                                </th>
                                <th>
                                  {language === "mal" ? "ബാലൻസ്" : "Balanace"}
                                </th>
                                <th>
                                  {language === "mal"
                                    ? "പ്രവർത്തനങ്ങൾ"
                                    : "Actions"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {ledgerItemCategories?.results &&
                                ledgerItemCategories?.results.length > 0 &&
                                ledgerItemCategories?.results.map(
                                  (item, key) => (
                                    <tr key={key}>
                                      <td>
                                        {
                                          item.institute_ledger_item_category_name
                                        }
                                      </td>
                                      <td>{item.balance}</td>
                                      <td>
                                        <Button
                                          onClick={() =>
                                            updateInstituteCategoryModal(item)
                                          }
                                          color="primary"
                                          className="btn-primary btn-sm"
                                        >
                                          {language === "mal"
                                            ? "തിരുത്തുക"
                                            : "Edit"}
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                          <MyPagination
                            totalPages={categoryPages}
                            page={pageCategory}
                            setPage={setPageCategory}
                            title="ctg"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="12" lg="6">
                      <h5>{language === "mal" ? "കമ്മിറ്റി" : "committee"}</h5>
                      <Card>
                        <CardBody>
                          <h6 className="text-black">
                            {language === "mal"
                              ? "എല്ലാ കമ്മിറ്റികളും :"
                              : "All Committees :"}
                          </h6>
                          <Container fluid>
                            <Row>
                              {committees?.results &&
                                committees?.results?.committees?.length > 0 &&
                                committees?.results?.committees.map(
                                  (itm, key) => (
                                    <Col
                                      sm="12"
                                      md="12"
                                      lg="12"
                                      key={key}
                                      className="sm:p-0 mt-1"
                                    >
                                      <Card className="blog-stats-wid">
                                        <CardBody
                                          style={{
                                            backgroundColor: "#dcfbf0",
                                            borderRadius: "11px",
                                            minHeight: "130px",
                                          }}
                                        >
                                          <Link to={`/committees/${itm?.id}`}>
                                            <h5 className="text-black">
                                              {itm.title}
                                            </h5>
                                            <Row>
                                              <Col md="4">
                                                <div className="mx-3">
                                                  <p className="m-0 text-muted pb-1 committe-p-main">
                                                    {" "}
                                                    {language === "mal"
                                                      ? "അവസാന യോഗം : "
                                                      : "Last Meeting  "}{" "}
                                                  </p>

                                                  <div
                                                    className=" p-2 committe-p"
                                                    style={{
                                                      borderRadius: "6px",
                                                      backgroundColor: "white",
                                                      minHeight: "35.5px",
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0 text-black"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {itm.last_meeting_date &&
                                                        moment(
                                                          itm.last_meeting_date
                                                        ).format("DD-MM-yyyy")}
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col md="4">
                                                <div className="mx-3">
                                                  <p className="m-0 text-muted pb-1">
                                                    {" "}
                                                    {language === "mal"
                                                      ? "അംഗങ്ങൾ"
                                                      : "Members"}{" "}
                                                  </p>

                                                  <div
                                                    className=" p-2 "
                                                    style={{
                                                      borderRadius: "6px",
                                                      backgroundColor: "white",
                                                      minHeight: "35.5px",
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0 text-black"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {itm?.members_count
                                                        ? itm?.members_count
                                                        : 0}
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col md="4">
                                                <div className="mx-3">
                                                  <p className="m-0 text-muted pb-1">
                                                    {" "}
                                                    {language === "mal"
                                                      ? "യോഗങ്ങൾ"
                                                      : "Meetings"}{" "}
                                                  </p>

                                                  <div
                                                    className=" p-2 "
                                                    style={{
                                                      borderRadius: "6px",
                                                      backgroundColor: "white",
                                                      minHeight: "35.5px",
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0 text-black"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {itm?.meetings_count
                                                        ? itm?.meetings_count
                                                        : 0}
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Link>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  )
                                )}
                            </Row>
                            <MyPagination
                              totalPages={committeePages}
                              page={pageCommittee}
                              setPage={setPageCommittee}
                              title="cmt"
                            />
                            <Button
                              onClick={() => setModalCommittee(true)}
                              className={
                                committees?.results &&
                                committees?.results?.committees?.length <= 0
                                  ? "bg-khaf w-100 mt-4"
                                  : "bg-khaf w-100"
                              }
                            >
                              {language === "mal"
                                ? "+ പുതിയത് ചേർക്കുക"
                                : "+ Add New Committee"}
                            </Button>
                          </Container>
                        </CardBody>
                      </Card>
                      <h5>{language === "mal" ? "ആസ്തികൾ" : "Assets"}</h5>
                      <Card>
                        <CardBody>
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={assetsData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <form
                                          className="app-search d-lg-block"
                                          onChange={e =>
                                            debounceAssetSearch(e.target.value)
                                          }
                                        >
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={
                                                language === "mal"
                                                  ? "തിരയുക..."
                                                  : "Search..."
                                              }
                                              defaultValue={searchText}
                                            />
                                            <span className="bx bx-search-alt" />
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col
                                    md="8"
                                    className="d-flex justify-content-end align-items-center"
                                  >
                                    <Button
                                      color="primary"
                                      className="btn-sm"
                                      onClick={() => setModalAsset(true)}
                                    >
                                      {language === "mal"
                                        ? "+ അസറ്റ് ചേർക്കുക"
                                        : "+ Add Asset"}
                                    </Button>
                                  </Col>
                                </Row>
                                {loading ? (
                                  <Spinner
                                    color="secondary"
                                    className="d-block m-auto"
                                  />
                                ) : (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-wrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <MyPagination
                                      totalPages={assetPages}
                                      page={pageAsset}
                                      setPage={setPageAsset}
                                      title="ast"
                                    />
                                  </>
                                )}
                                {assets &&
                                  assets?.results &&
                                  assets?.results?.length <= 0 && (
                                    <p
                                      style={{ fontSize: "15px" }}
                                      className="text-center text-info"
                                    >
                                      {language === "mal"
                                        ? "ഇൻസ്റ്റിറ്റ്യൂട്ടുകൾ ഒന്നും ഇല്ല"
                                        : "No Assets Yet"}
                                    </p>
                                  )}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default InstituteMahallDetails

InstituteMahallDetails.propTypes = {
  history: PropTypes.object,
}
