import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
  Label,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import Select from "react-select"

import {
  getInstituteDropdown,
  getLedgers,
  getLedgersForDownload,
  getMahallTypeLists,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"
import { handleSelectOrDeselect } from "hooks/useSelectOrDeselect"

const AllLedgers = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [modalLedgerDownload, setModalLedgerDownload] = useState(false)

  const {
    ledgers,
    loading,
    language,
    ledgersForDownload,
    searchInstitutes,
    mahallId,
    mahallTypeLists,
  } = useSelector(state => ({
    ledgers: state.Ledgers.ledgers,
    loading: state.Ledgers.loading,
    language: state.Layout.language,
    ledgersForDownload: state.Ledgers.ledgersForDownload,
    searchInstitutes: state.Institutes.searchInstitutes,
    mahallId: state.Users.userDetail?.mahall?.id,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
  }))

  useEffect(() => {
    if (mahallId) {
      dispatch(getMahallTypeLists(mahallId))
    }
  }, [dispatch])

  const [ledgerCategory, setLedgerCategory] = useState()
  const [type, setType] = useState("")
  const [institute, setInstitute] = useState("choose institute")
  const [instituteId, setInstituteId] = useState("")
  const [searchTextInstitute, setSearchTextInstitute] = useState("")

  // const [date, setDate] = useState({
  //   start_date: "",
  //   end_date: "",
  // })

  // const handleDate = () => {
  //   if (date?.start_date?.length >= 1 && date?.end_date?.length >= 1) {
  //     return date
  //   } else {
  //     return {
  //       start_date: "",
  //       end_date: "",
  //     }
  //   }
  // }

  //pages
  const totalPages = Math.ceil(ledgers?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    if (modalLedgerDownload) {
      dispatch(
        getLedgersForDownload(instituteId, searchText, ledgerCategory, type)
      )
    }
  }, [
    dispatch,
    instituteId,
    searchText,
    ledgerCategory,
    type,
    modalLedgerDownload,
  ])

  useEffect(() => {
    dispatch(
      getLedgers(instituteId, searchText, pageSend(), ledgerCategory, type)
    )
  }, [dispatch, instituteId, page, searchText, ledgerCategory, type])

  const cardData = [
    {
      title: `${language === "mal" ? "വരുമാനം" : "Income"}`,
      cardValue: `${ledgers?.results?.total_income ?? 0}`,
      className: "text-success",
      iconClass: "downvote",
    },
    {
      title: `${language === "mal" ? "ചെലവ്" : "Expense"}`,
      cardValue: `${ledgers?.results?.total_expense ?? 0}`,
      className: "text-danger",
      iconClass: "upvote",
    },
    {
      title: `${language === "mal" ? "ബാലൻസ്" : "Balance"}`,
      cardValue: `${ledgers?.results?.total_balance ?? 0}`,
      className: "text-info",
      iconClass: "list-check",
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
      sort: true,
    },
    {
      dataField: "institute_ledger_name",
      text: `${language === "mal" ? "ലെഡ്ജറിന്റെ പേര്" : "Ledger Name"}`,
      sort: true,
    },
    {
      dataField: "income",
      text: `${language === "mal" ? "വരവ്" : "Income"}`,
    },
    {
      dataField: "expense",
      text: `${language === "mal" ? "ചിലവ്" : "Expense"}`,
    },
    {
      dataField: "balance",
      text: `${language === "mal" ? "ബാക്കി" : "Balance"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const ledgersData = map(ledgers?.results?.ledgers, (item, index) => ({
    ...item,
    key: index,
    no: index + 1,
    income: <p className="text-success">{item?.credit_amount}</p>,
    expense: <p className="text-danger">{item?.debit_amount}</p>,
    balance: <p className="text-dark">{item?.balance}</p>,
    institute_ledger_name: (
      <div>
        {item?.institute_ledger_name}
        <Badge className="mx-3 font-size-10 badge-soft-primary" pill>
          {language === "mal"
            ? item?.institute_malayalam_name
              ? item?.institute_malayalam_name
              : item?.institute_english_name
            : item?.institute_english_name}
        </Badge>
        <Badge
          className={
            "font-size-10 badge-soft-" +
            `${
              item.institute_type == "institute"
                ? "danger"
                : item.institute_type == "program"
                ? "warning"
                : item.institute_type == "mahall"
                ? "success"
                : ""
            }`
          }
          pill
        >
          {item?.institute_type}
        </Badge>
      </div>
    ),
    action: (
      <div>
        <Link to={`/ledgers/${item?.id}`} className="btn-success btn-sm">
          {language === "mal" ? "കാണുക" : "View"}
        </Link>
      </div>
    ),
  }))

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    {
      label: `${language === "mal" ? "ലെഡ്ജറിന്റെ പേര്" : "Ledger Name"}`,
      key: "ledger_name",
    },
    {
      label: `${language === "mal" ? "സ്ഥാപനത്തിന്റെ പേര്" : "Institute Name"}`,
      key: "institute_name",
    },
    {
      label: `${language === "mal" ? "തരം" : "Institute Type"}`,
      key: "institute_type",
    },
    { label: `${language === "mal" ? "വരവ്" : "Income"}`, key: "income" },
    { label: `${language === "mal" ? "ചിലവ്" : "Expense"}`, key: "expense" },
    { label: `${language === "mal" ? "ബാക്കി" : "Balance"}`, key: "balance" },
  ]

  const ledgersExcelData = map(ledgersForDownload, (item, index) => ({
    ...item,
    no: index + 1,
    ledger_name: item.institute_ledger_name,
    institute_name:
      language === "mal"
        ? item.institute_malayalam_name
          ? item.institute_malayalam_name
          : item.institute_english_name
        : item.institute_english_name,
    institute_type: item.institute_type,
    income: item.credit_amount,
    expense: item.debit_amount,
    balance: item.balance,
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const fileName = "khaf-smart-mahall-ledgers-list.csv"

  useEffect(() => {
    dispatch(getInstituteDropdown(searchTextInstitute, type))
  }, [searchTextInstitute, type, dispatch])

  function handlerFinalValue(event) {
    // dispatch(getInstituteDropdown(event.value))
    setInstituteId(event.value)
    setInstitute(event.label)
  }

  const optionGroup = [
    {
      label: "All Institutes",
      value: "",
    },
    {
      options: searchInstitutes?.map((result, index) => ({
        key: index,
        label: `${
          language === "mal"
            ? result.institute_malayalam_name
              ? result.institute_malayalam_name
              : result.institute_english_name
            : result.institute_english_name
        } (${result?.institute_type && result?.institute_type})`,
        value: result.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchTextInstitute(textEntered)
  }

  const instituteTypes = [
    {
      label: `${language === "mal" ? "എല്ലാം" : "All"}`,
      id: "btnradio1",
      instituteType: "",
    },
    {
      label: `${language === "mal" ? "മഹല്ല്" : "Mahall"}`,
      id: "btnradio2",
      instituteType: "mahall",
    },
    {
      label: `${language === "mal" ? "ഇൻസ്റ്റിറ്റ്യൂട്ട്" : "Institute"}`,
      id: "btnradio3",
      instituteType: "institute",
    },
    {
      label: `${language === "mal" ? "പ്രോഗ്രാം" : "Program"}`,
      id: "btnradio4",
      instituteType: "program",
    },
    {
      label: `${language === "mal" ? "മദ്രസ" : "Madrasa"}`,
      id: "btnradio5",
      instituteType: "madrasa",
    },
  ]

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalLedgerDownload}
        onCloseclick={() => setModalLedgerDownload(false)}
        excelData={ledgersExcelData}
        headers={headers}
        list={ledgersForDownload}
        fileName={fileName}
        loading={loading}
      />
      <Row>
        {cardData?.map((item, key) => (
          <Col sm="12" md="6" lg="4" key={key} className="sm:p-0">
            <Card className="blog-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <h5 className="text-muted">{item.title}</h5>
                    <h4 className={`${item.className}`}>{item.cardValue}</h4>
                  </div>
                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i
                        className={
                          "bx bx-" + `${item.iconClass} ${item.className}`
                        }
                      ></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        <Col md="12" sm="12">
          <Card>
            <CardBody>
              {/* <Row className="mb-3">
                  <Col md="3" className="mb-3">
                    <h5>From :</h5>
                    <AvField
                      type="date"
                      name="start_date"
                      value={date?.start_date}
                      onChange={e =>
                        setDate({
                          ...date,
                          start_date: moment(e.target.value).format(
                            "yyyy-MM-DD"
                          ),
                        })
                      }
                    />
                    <button
                      onClick={() =>
                        setDate({
                          ...date,
                          start_date: "",
                        })
                      }
                      type="button"
                      className="btn btn-white editable-cancel date_close btn-sm"
                    >
                      <i className="mdi mdi-close text-danger "></i>
                    </button>
                  </Col>
                  <Col md="3" className="">
                    <h5>To :</h5>
                    <AvField
                      type="date"
                      name="end_date"
                      value={date?.end_date}
                      onChange={e =>
                        setDate({
                          ...date,
                          end_date: moment(e.target.value).format("yyyy-MM-DD"),
                        })
                      }
                    />
                    <button
                      onClick={() =>
                        setDate({
                          ...date,
                          end_date: "",
                        })
                      }
                      type="button"
                      className="btn btn-white editable-cancel date_close btn-sm"
                    >
                      <i className="mdi mdi-close text-danger "></i>
                    </button>
                  </Col>
                </Row> */}
              <Row>
                <Col md="2" className="mb-3">
                  <Label>
                    {language === "mal"
                      ? "ഇൻസ്റ്റിറ്റ്യൂട്ട് തരം"
                      : "Institute Type"}
                  </Label>
                  <AvForm>
                    <AvField
                      type="select"
                      name="type"
                      onChange={e => setType(e.target.value)}
                    >
                      {instituteTypes?.map((item, key) => (
                        <option key={key} value={item?.instituteType}>
                          {item?.label}
                        </option>
                      ))}
                    </AvField>
                  </AvForm>
                </Col>
                <Col md="4" className="mb-3">
                  <Label>
                    {language === "mal" ? "ഇൻസ്റ്റിറ്റ്യൂട്ട്" : "Institute"}
                  </Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={institute}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Institute"
                  />
                </Col>
                <Col md="6">
                  <div className="row mb-4">
                    <Col sm="12" md="12">
                      <Label for="basicpill-servicetax-input8">
                        {language === "mal"
                          ? "ലെഡ്ജർ വിഭാഗം"
                          : "Ledger Category"}
                      </Label>
                      <div className="d-flex flex-wrap">
                        {map(mahallTypeLists?.ledger_category, (item, key) => (
                          <div key={key} className="mx-1 mt-1">
                            <input
                              type="radio"
                              className="btn-check"
                              name="btnradio5"
                              id={item.id}
                              autoComplete="off"
                              value={item.id}
                              checked={ledgerCategory === item.id}
                              onClick={e =>
                                handleSelectOrDeselect(
                                  ledgerCategory,
                                  setLedgerCategory,
                                  e.target.value
                                )
                              }
                              // onChange={e => setLedgerCategory(e.target.value)}
                            />
                            <label
                              className="btn btn-outline-info"
                              htmlFor={item.id}
                            >
                              {language === "mal"
                                ? item?.malayalam
                                : item?.english}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={ledgersData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      {/* <Col md="4">
                        <div
                          className="btn-group d-flex justify-content-center mt-2 mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {instituteTypes?.map((item, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id={item?.id}
                                autoComplete="off"
                                defaultChecked={item?.id === "btnradio1"}
                                onChange={() => setType(item?.instituteType)}
                              />
                              <label
                                className="btn btn-success"
                                htmlFor={item?.id}
                              >
                                {item?.label}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col> */}
                      <Col md="8">
                        <Button
                          style={{
                            width: language === "mal" ? "150px" : "130px",
                            border: "bold",
                          }}
                          className="btn-md me-4 mt-0 d-block m-auto bg-white text-khaf"
                          onClick={() => setModalLedgerDownload(true)}
                        >
                          <i
                            className="bx bx-download mx-1"
                            style={{ fontSize: "18px", color: "#009846" }}
                          />
                          {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                        </Button>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {ledgers && ledgers?.count <= 0 && (
                      <p
                        style={{ fontSize: "15px" }}
                        className="text-center text-info"
                      >
                        {language === "mal"
                          ? "ലെഡ്ജറുകൾ ഒന്നും ഇല്ല"
                          : "No Ledgers Yet"}
                      </p>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllLedgers
