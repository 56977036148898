import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import logo_mahallu from "../../assets/images/logo/logo.png"
import logo_mahallu_small from "../../assets/images/logo/logo-small.png"

import AdminSidebarContent from "./AdminSidebarContent"
import MahallUserSidebarContentPremium from "./MahallUserSidebarContentPremium"
import SurveyUserSidebarContent from "./SurveyUserSidebarContent"
import GroupAdminSidebarContent from "./GroupAdminSidebarContent "
import MahallUserSidebarContentBasic from "./MahallUserSidebarContentBasic"
import InstituteUserSidebarContent from "./InstituteUserSidebarContent "

import { getUserDetail } from "store/actions"
import { useGetRole } from "hooks/useHandleSessions"
import PushNotification from "components/PushNotification"

const Sidebar = () => {
  const dispatch = useDispatch()
  const { leftMenu, userDetail, language } = useSelector(state => ({
    leftMenu: state.Layout.leftMenu,
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
  }))

  useEffect(() => {
    if (!userDetail) dispatch(getUserDetail())
  }, [dispatch, userDetail])

  const { role } = useGetRole()

  const mobile = window.screen.width <= 768

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/dashboard" className="logo logo-dark ">
            <span className="logo-sm">
              {leftMenu && (
                <img src={logo_mahallu_small} alt="logo" height="30" />
              )}
            </span>
            <span className="logo-lg">
              {!leftMenu && (
                <>
                  <img src={logo_mahallu} alt="logo" height="35" />
                  <h5 className="mt-1" style={{ color: "#0B3354" }}></h5>
                  {role === "admin" ? (
                    <h6 className="mt-1" style={{ color: "#009846" }}>
                      <strong>
                        {language === "mal" ? "അഡ്‌മിൻ പാനൽ" : "ADMIN PANEL"}
                      </strong>
                    </h6>
                  ) : role === "mahall_user" ? (
                    <h6 className="mt-1">
                      <strong>
                        {language === "mal"
                          ? userDetail?.mahall?.mahall_malayalam_name
                            ? userDetail?.mahall?.mahall_malayalam_name
                            : userDetail?.mahall?.mahall_english_name
                          : userDetail?.mahall?.mahall_english_name}
                      </strong>
                    </h6>
                  ) : role === "survey_user" ? (
                    <>
                      <h6>
                        <strong>
                          {language === "mal"
                            ? userDetail?.mahall?.mahall_malayalam_name
                              ? userDetail?.mahall?.mahall_malayalam_name
                              : userDetail?.mahall?.mahall_english_name
                            : userDetail?.mahall?.mahall_english_name}
                        </strong>
                      </h6>
                      {/* <p>Survey Team</p> */}
                    </>
                  ) : role === "group_admin" ? (
                    <h6 className="mt-3" style={{ color: "#009846" }}>
                      <strong>
                        {language === "mal"
                          ? "അഡ്‌മിൻ പാനൽ"
                          : "GROUP ADMIN PANEL"}
                      </strong>
                    </h6>
                  ) : role === "institute_user" ? (
                    <h6>
                      <strong>
                        {language === "mal"
                          ? userDetail?.mahall?.mahall_malayalam_name
                            ? userDetail?.mahall?.mahall_malayalam_name
                            : userDetail?.mahall?.mahall_english_name
                          : userDetail?.mahall?.mahall_english_name}
                      </strong>
                    </h6>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </span>
          </Link>
        </div>

        <div data-simplebar className={`h-100  ${mobile ? "mt-2" : "mt-5"} `}>
          {/* <PushNotification /> */}

          {mobile && (
            <p className="mb-0 pb-0 mx-4">
              {role === "admin" ? (
                <h6 className="mt-3" style={{ color: "#009846" }}>
                  <strong>
                    {language === "mal" ? "അഡ്‌മിൻ പാനൽ" : "ADMIN PANEL"}
                  </strong>
                </h6>
              ) : role === "mahall_user" ? (
                <h6>
                  <strong>
                    {language === "mal"
                      ? userDetail?.mahall?.mahall_malayalam_name
                        ? userDetail?.mahall?.mahall_malayalam_name
                        : userDetail?.mahall?.mahall_english_name
                      : userDetail?.mahall?.mahall_english_name}
                  </strong>
                </h6>
              ) : role === "survey_user" ? (
                <>
                  <h6>
                    <strong>
                      {language === "mal"
                        ? userDetail?.mahall?.mahall_malayalam_name
                          ? userDetail?.mahall?.mahall_malayalam_name
                          : userDetail?.mahall?.mahall_english_name
                        : userDetail?.mahall?.mahall_english_name}
                    </strong>
                  </h6>
                  {/* <p>Survey Team</p> */}
                </>
              ) : role === "group_admin" ? (
                <h6 className="mt-3" style={{ color: "#009846" }}>
                  <strong>
                    {language === "mal"
                      ? "ഗ്രൂപ്പ് അഡ്‌മിൻ പാനൽ"
                      : "GROUP ADMIN PANEL"}
                  </strong>
                </h6>
              ) : (
                <></>
              )}
            </p>
          )}
          {role === "admin" ? (
            <AdminSidebarContent />
          ) : role === "mahall_user" ? (
            ["premium", "standard"].includes(userDetail?.mahall?.version) ? (
              <MahallUserSidebarContentPremium />
            ) : (
              <MahallUserSidebarContentBasic />
            )
          ) : role === "survey_user" ? (
            <SurveyUserSidebarContent />
          ) : role === "group_admin" ? (
            <GroupAdminSidebarContent />
          ) : role === "institute_user" ? (
            <InstituteUserSidebarContent />
          ) : (
            <></>
          )}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
