import {
  GET_MAHALL_USERS,
  GET_MAHALL_USERS_SUCCESS,
  GET_MAHALL_USERS_FAIL,
  GET_MAHALL_USERS_CARDS,
  GET_MAHALL_USERS_CARDS_SUCCESS,
  GET_MAHALL_USERS_CARDS_FAIL,
  GET_MAHALL_USER_DROPDOWN,
  GET_MAHALL_USER_DROPDOWN_SUCCESS,
  GET_MAHALL_USER_DROPDOWN_FAIL,
  GET_MAHALL_USER_DETAIL,
  GET_MAHALL_USER_DETAIL_SUCCESS,
  GET_MAHALL_USER_DETAIL_FAIL,
  UPDATE_MAHALL_USER_DETAIL,
  UPDATE_MAHALL_USER_DETAIL_SUCCESS,
  UPDATE_MAHALL_USER_DETAIL_FAIL,
  CREATE_MAHALL_USER,
  CREATE_MAHALL_USER_SUCCESS,
  CREATE_MAHALL_USER_FAIL,
  UPDATE_MAHALL_USER,
  UPDATE_MAHALL_USER_SUCCESS,
  UPDATE_MAHALL_USER_FAIL,
  DELETE_MAHALL_USER,
  DELETE_MAHALL_USER_SUCCESS,
  DELETE_MAHALL_USER_FAIL,
  OTP_VERIFICATION,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_FAIL,
  CHANGE_MAHALL_USER_STATUS_SUCCESS,
  CHANGE_MAHALL_USER_STATUS_FAIL,
  GET_MAHALL_USERS_FOR_DOWNLOAD,
  GET_MAHALL_USERS_FOR_DOWNLOAD_SUCCESS,
  GET_MAHALL_USERS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  mahallUsers: [],
  mahallUsersForDownload: [],
  mahallUsersCards: {},
  mahallUserDetail: {},
  searchMahallUsers: [],
  otp: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdMahallUser: {},
  createdUser: {},
  mahallUserStatus: {},
}

const MahallUsers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAHALL_USERS:
    case GET_MAHALL_USERS_FOR_DOWNLOAD:
    case GET_MAHALL_USERS_CARDS:
    case UPDATE_MAHALL_USER_DETAIL:
    case UPDATE_MAHALL_USER:
    case CREATE_MAHALL_USER:
    case DELETE_MAHALL_USER:
    case GET_MAHALL_USER_DROPDOWN:
      return {
        ...state,
        loading: true,
      }

    case GET_MAHALL_USER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_MAHALL_USERS_SUCCESS:
      return {
        ...state,
        mahallUsers: action.payload,
        users: action.payload,
        loading: false,
      }

    case GET_MAHALL_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MAHALL_USERS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        mahallUsersForDownload: action.payload,
        loading: false,
      }

    case GET_MAHALL_USERS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MAHALL_USERS_CARDS_SUCCESS:
      return {
        ...state,
        mahallUsersCards: action.payload,
        users: action.payload,
        loading: false,
      }

    case GET_MAHALL_USERS_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MAHALL_USER_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchMahallUsers: action.payload,
        loading: false,
      }

    case GET_MAHALL_USER_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CHANGE_MAHALL_USER_STATUS_SUCCESS:
      return {
        ...state,
        mahallUserStatus: action.payload,
        mahallUsers: {
          results: state.mahallUsers.results?.map(item =>
            item.id === action.payload.mahallUserId
              ? { ...item, is_active: action.payload.checked }
              : item
          ),
        },
        // loading: false,
      }

    case CHANGE_MAHALL_USER_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        // loading: false,
      }

    case CREATE_MAHALL_USER_SUCCESS:
      return {
        ...state,
        mahallUsers: action.payload,
        users: action.payload,
        createdMahallUser: action.payload,
        loading: false,
      }

    case CREATE_MAHALL_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MAHALL_USER_DETAIL_SUCCESS:
      return {
        ...state,
        mahallUserDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_MAHALL_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case OTP_VERIFICATION:
      state = {
        ...state,
        loading: true,
      }
      break
    case OTP_VERIFICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        otp: action.payload,
      }
      break

    case OTP_VERIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_MAHALL_USER_DETAIL_SUCCESS:
      return {
        ...state,
        mahallUserDetail: action.payload,
        loading: false,
      }

    case UPDATE_MAHALL_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_MAHALL_USER_SUCCESS:
      return {
        ...state,
        mahallUserDetail: action.payload,
        loading: false,
      }

    case UPDATE_MAHALL_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_MAHALL_USER_SUCCESS:
      return {
        ...state,
        mahallUsers: action.payload,
        loading: false,
      }

    case DELETE_MAHALL_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default MahallUsers
