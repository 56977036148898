import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Container,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
import moment from "moment"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"

import Breadcrumbs from "components/Common/Breadcrumb"
//actions
import {
  getFamiliesCards,
  getMahallTypeLists,
  getFilteredFamilies,
  getMahallWardNumbers,
  getFamiliesForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"
import { useGetSession } from "hooks/useHandleSessions"
// import { Button } from "bootstrap"

const AreaReports = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  // const [isFilterListOpen, setIsFilterListOpen] = useState(false)
  const [modalAreaListDownload, setModalAreaListDownload] = useState(false)

  const {
    families,
    loading,
    // familiesCards,
    language,
    userDetail,
    mahallTypeLists,
    mahallWardNumbers,
    // mahallId,
    familiesforDownload,
    // familiesforDownloadLoading,
    // familiesCardsLoading,
  } = useSelector(state => ({
    families: state.Families.families,
    loading: state.Families.loading,
    familiesforDownload: state.Families.familiesforDownload?.results,
    familiesforDownloadLoading: state.Families.familiesforDownloadLoading,
    familiesCards: state.Families.familiesCards,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    mahallWardNumbers: state.TypeLists.mahallWardNumbers.ward_numbers,
    mahallId: state.Users.userDetail?.mahall?.id,
    familiesCardsLoading: state.Families.familiesCardsLoading,
  }))

  const sort = useGetSession("sort_value_family")

  //pages
  const totalPages = Math.ceil(families?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  // const handlePremiumLink = () => {
  //   if (userDetail?.mahall?.version === 'premium') {
  //     return '!#'
  //   } else {
  //     return '/premium'
  //   }
  // }

  // useEffect(() => {
  //   dispatch(getFamilies(searchText, pageSend(), "", sort))
  // }, [dispatch, page, sort, "", searchText])

  useEffect(() => {
    dispatch(getFamiliesCards())
  }, [dispatch])

  useEffect(() => {
    if (userDetail?.mahall?.id) {
      if (!mahallTypeLists || mahallTypeLists.length <= 0)
        dispatch(getMahallTypeLists(userDetail?.mahall?.id))
      if (!mahallWardNumbers || mahallWardNumbers.length < 0)
        dispatch(getMahallWardNumbers(userDetail?.mahall?.id))
    }
  }, [dispatch, userDetail, mahallTypeLists, mahallWardNumbers])

  // const sortData = [
  //   {
  //     heading: `${language === 'mal' ? 'മഹല്ല് ഐഡി' : 'Mahall ID'}`,
  //     id: 'mahall_custom_id',
  //   },
  //   {
  //     heading: `${language === 'mal' ? 'അവസാനം ചേർത്തത്' : 'Last Added'}`,
  //     id: '-custom_id',
  //   },
  // ]

  // const cardData = [
  //   {
  //     title: `${language === 'mal' ? 'ആകെ കുടുംബങ്ങൾ' : 'Total Families'}`,
  //     cardValue: familiesCards?.total_families ?? 0,
  //     iconClass: 'home',
  //     routeLink: '/families',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'കുടുംബങ്ങൾ ഇല്ല' : 'No Families'}`,
  //   },
  //   {
  //     title: `${language === 'mal' ? 'ആകെ അംഗങ്ങൾ' : 'Total Members'}`,
  //     cardValue: familiesCards?.total_family_members ?? 0,
  //     iconClass: 'user',
  //     routeLink: '/familymembers',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'കുടുംബാംഗങ്ങൾ ഇല്ല' : 'No Family Members'}`,
  //   },
  //   {
  //     title: `${language === 'mal' ? 'ആൺ - പെൺ' : 'Male - Female'}`,
  //     cardValue: `${familiesCards?.total_males ?? 0} - ${
  //       familiesCards?.total_females ?? 0
  //     }`,
  //     iconClass: 'show',
  //     routeLink: '/familymembers',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'ആൺ - പെൺ ഇല്ല' : 'No Male - Female'}`,
  //   },
  // ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നo." : "No."}`,
    },
    // {
    //   dataField: "custom_id",
    //   text: `${language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID"}`,
    // },
    {
      dataField: "mahall_custom_id",
      text: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
    },
    {
      dataField: "house_name",
      text: `${language === "mal" ? "വീട്ടുപേര്" : "House Name"}`,
      sort: true,
    },
    {
      dataField: "family_head_name",
      text: `${language === "mal" ? "കുടുംബനാഥൻ" : "Family Head"}`,
    },
    {
      dataField: "no_of_members",
      text: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members"}`,
    },
    {
      dataField: "houseNumber",
      text: `${language === "mal" ? "വീട് നമ്പർ" : "House No."}`,
    },
    {
      dataField: "area_name",
      text: `${language === "mal" ? "ഏരിയ" : "Area"}`,
    },
    {
      dataField: "contact_number",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]
  const familiesData = map(families?.results, (item, key) => ({
    ...item,
    key: key,
    no: (pageSend() - 1) * limit + key + 1,
    houseNumber:
      `${item?.ward_number === null ? "" : item?.ward_number}` +
      (item?.house_number || item?.ward_number ? " / " : "") +
      `${item?.house_number === null ? "" : item?.house_number}`,
    area_name:
      language === "mal"
        ? item?.area?.malayalam
          ? item?.area?.malayalam
          : item?.area?.english
        : item?.area?.english,
    action: (
      <div>
        <Link to={`/families/${item?.id}`} className=" btn-success w-xs btn-sm">
          {/* <i className="bx bxs-show me-2 mt-2" /> */}
          {language === "mal" ? "കാണുക" : "View"}
        </Link>
      </div>
    ),
  }))

  const headers = [
    { label: language === "mal" ? "നo." : "No.", key: "no" },
    {
      label: language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID",
      key: "custom_id",
    },
    {
      label: language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID",
      key: "mahall_custom_id",
    },
    {
      label: language === "mal" ? "വീട്ടുപേര്" : "House Name",
      key: "house_name",
    },
    {
      label: language === "mal" ? "വീട് നമ്പർ" : "House No.",
      key: "house_number",
    },
    { label: language === "mal" ? "ഏരിയ" : "Area", key: "area_name" },

    { label: language === "mal" ? "വഴി" : "Via", key: "via" },
    { label: language === "mal" ? "സ്ഥലം" : "Place", key: "place" },
    { label: language === "mal" ? "ലോക്കൽ ബോഡി" : "Local Body", key: "lsg" },
    {
      label: language === "mal" ? "ലോക്കൽ ബോഡി തരം" : "Local Body Type",
      key: "lsg_type",
    },
    { label: language === "mal" ? "വില്ലേജ് " : "Village", key: "village" },
    { label: language === "mal" ? "ജില്ല" : "District", key: "district" },
    { label: language === "mal" ? "സംസ്ഥാനം" : "State", key: "state" },
    {
      label: language === "mal" ? "പോസ്റ്റ് ഓഫീസ്" : "Post Office",
      key: "post_office",
    },
    { label: language === "mal" ? "പിൻകോഡ്" : "Pincode", key: "pin_code" },
    {
      label: language === "mal" ? "താമസം തുടങ്ങിയ വർഷം" : "Year of Residence",
      key: "year_of_residence",
    },
    { label: language === "mal" ? "ഫോൺ" : "Phone", key: "contact_number" },
    {
      label: language === "mal" ? "കുടുംബനാഥൻ" : "Family Head",
      key: "family_head_name",
    },
    {
      label: language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members",
      key: "no_of_members",
    },

    {
      label: language === "mal" ? "റേഷൻ കാർഡ് വിഭാഗം" : "Ration Card Category",
      key: "ration_card",
    },
    {
      label: language === "mal" ? "റേഷൻ കാർഡ് നമ്പർ" : "Ration Card No.",
      key: "ration_card_number",
    },
    { label: language === "mal" ? "വീട്" : "House Type", key: "house_type" },
    {
      label: language === "mal" ? "വീടിൻ്റെ സ്ഥിതി" : "Build Type",
      key: "house_build_type",
    },
    {
      label: language === "mal" ? "ജല സൗകര്യം" : "Water Facility",
      key: "water_facility",
    },
    {
      label: language === "mal" ? "വൈത്യുതി" : "Electicity",
      key: "electric_facility",
    },

    {
      label: language === "mal" ? "ആസ്തി വിവരം - വീട്" : "Land Area - Home",
      key: "land_area_home",
    },
    {
      label: language === "mal" ? "ആസ്തി വിവരം - കൃഷി" : "Land Area - Farm",
      key: "land_area_farm",
    },
    {
      label: language === "mal" ? "ആസ്തി വിവരം - വാടക" : "Land Area - Rent",
      key: "land_area_rent",
    },
    {
      label:
        language === "mal" ? "ആസ്തി വിവരം - മറ്റുള്ളവ" : "Land Area - Others",
      key: "land_area_others",
    },
    {
      label: language === "mal" ? "ആകെ ആസ്തി" : "Total Land Area",
      key: "total_land_area",
    },

    {
      label: language === "mal" ? "2 വീലറുകൾ " : "2 Wheelers",
      key: "two_wheeler_vehicles",
    },
    {
      label: language === "mal" ? "3 വീലറുകൾ" : "3 Wheelers",
      key: "three_wheeler_vehicles",
    },
    {
      label: language === "mal" ? "4 വീലറുകൾ" : "4 Wheelers",
      key: "four_wheeler_vehicles",
    },
    {
      label: language === "mal" ? "ടാക്സികൾ" : "Taxi Vehicles",
      key: "taxi_vehicles",
    },
    {
      label: language === "mal" ? "ആകെ വാഹനങ്ങൾ" : "Total Vehicles",
      key: "total_vehicles",
    },

    {
      label: language === "mal" ? "സാമ്പത്തിക സ്ഥിതി" : "financial_status",
      key: "financial_status",
    },
    {
      label: language === "mal" ? "വാർഷിക വരുമാനം" : "Annual Income",
      key: "annual_income",
    },

    {
      label: language === "mal" ? "ബാങ്ക് വായ്പ" : "Bank Loan",
      key: "bank_loan",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "bank_loan_details",
    },
    {
      label: language === "mal" ? "ഹോം ബിസിനസ്സ്" : "Home Business",
      key: "home_business",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "home_business_details",
    },
    {
      label:
        language === "mal" ? "മഹല്ലിൽ നിന്നുള്ള സഹായം" : "Help From Mahall",
      key: "help_receiving_from_mahall",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "help_receiving_from_mahall_details",
    },
    {
      label:
        language === "mal"
          ? "മറ്റുള്ളവരിൽ നിന്നുള്ള സഹായം"
          : "Help From Others",
      key: "help_receiving_from_others",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "help_receiving_from_others_details",
    },
    {
      label: language === "mal" ? "പ്രത്യേക കുറിപ്പുകൾ" : "Special Notes",
      key: "special_notes",
    },

    {
      label: language === "mal" ? "വീടിൻ്റെ ഫോട്ടോ ലിങ്ക്" : "House Photo Link",
      key: "house_image",
    },
    { label: "Listed Date", key: "date_added" },
  ]
  // console.log(familiesforDownload);

  const areaReportsExcelData = map(familiesforDownload, (item, key) => ({
    ...item,
    no: (pageSend() - 1) * limit + key + 1,
    custom_id: item.custom_id,
    mahall_custom_id: item.mahall_custom_id,
    house_name: item.house_name,
    house_number: `${item.ward_number ? `${item.ward_number}` : ""} ${
      item.house_number ? `/${item.house_number}` : ""
    }`,
    area_name:
      language === "mal"
        ? item?.area?.malayalam
          ? item?.area?.malayalam
          : item?.area?.english
        : item?.area?.english,
    via: item.via,
    place: item.place,
    lsg:
      language === "mal"
        ? item?.lsg_malayalam_name
          ? item?.lsg_malayalam_name
          : item?.lsg_english_name
        : item?.lsg_english_name,
    lsg_type:
      language === "mal"
        ? item?.lsg_type_malayalam
          ? item?.lsg_type_malayalam
          : item?.lsg_type_english
        : item?.lsg_type_english,
    village:
      language === "mal"
        ? item?.village_malayalam_name
          ? item?.village_malayalam_name
          : item?.village_english_name
        : item?.village_english_name,
    district:
      language === "mal"
        ? item?.district_malayalam_name
          ? item?.district_malayalam_name
          : item?.district_english_name
        : item?.district_english_name,
    state:
      language === "mal"
        ? item?.state_malayalam_name
          ? item?.state_malayalam_name
          : item?.state_english_name
        : item?.state_english_name,
    post_office: item.post_office,
    pin_code: item.pin_code,
    year_of_residence: item.year_of_residence,
    contact_number: item.contact_number,
    family_head_name: item.family_head_name ? item.family_head_name : "",
    no_of_members: item.no_of_members,

    ration_card:
      language === "mal"
        ? item?.ration_card?.malayalam
          ? item?.ration_card?.malayalam
          : item?.ration_card?.english
        : item?.ration_card?.english,
    ration_card_number: item.ration_card_number,
    house_type:
      language === "mal"
        ? item?.house_type?.malayalam
          ? item?.house_type?.malayalam
          : item?.house_type?.english
        : item?.house_type?.english,
    house_build_type:
      language === "mal"
        ? item?.house_build_type?.malayalam
          ? item?.house_build_type?.malayalam
          : item?.house_build_type?.english
        : item?.house_build_type?.english,
    water_facility:
      language === "mal"
        ? item?.water_facility?.malayalam
          ? item?.water_facility?.malayalam
          : item?.water_facility?.english
        : item?.water_facility?.english,
    electric_facility:
      language === "mal"
        ? item?.electric_facility?.malayalam
          ? item?.electric_facility?.malayalam
          : item?.electric_facility?.english
        : item?.electric_facility?.english,

    land_area_home: item.land_area_home,
    land_area_farm: item.land_area_farm,
    land_area_rent: item.land_area_rent,
    land_area_others: item.land_area_others,
    total_land_area: item.total_land_area,

    two_wheeler_vehicles: item.two_wheeler_vehicles,
    three_wheeler_vehicles: item.three_wheeler_vehicles,
    four_wheeler_vehicles: item.four_wheeler_vehicles,
    taxi_vehicles: item.taxi_vehicles,
    total_vehicles: item.total_vehicles,

    financial_status:
      language === "mal"
        ? item?.financial_status?.malayalam
          ? item?.financial_status?.malayalam
          : item?.financial_status?.english
        : item?.financial_status?.english,
    annual_income: item?.annual_income,
    bank_loan: `${item?.bank_loan?.map(item => {
      return `${
        language === "mal"
          ? item?.malayalam
            ? item?.malayalam
            : item?.english
          : item?.english
      } `
    })}`,

    bank_loan_details: item.bank_loan_details,
    home_business: `${item?.home_business?.map(item => {
      return `${
        language === "mal"
          ? item?.malayalam
            ? item?.malayalam
            : item?.english
          : item?.english
      } `
    })}`,
    home_business_details: item.home_business_details,
    help_receiving_from_mahall:
      item.help_receiving_from_mahall === true
        ? language === "mal"
          ? "ഉണ്ട്"
          : "Yes"
        : language === "mal"
        ? "ഇല്ല"
        : "No",
    help_receiving_from_mahall_details: item.help_receiving_from_mahall_details,
    help_receiving_from_others:
      item.help_receiving_from_others === true
        ? language === "mal"
          ? "ഉണ്ട്"
          : "Yes"
        : language === "mal"
        ? "ഇല്ല"
        : "No",
    help_receiving_from_others_details: item.help_receiving_from_others_details,
    special_notes: item.special_notes,

    house_image: item.house_image,
    date_added:
      item?.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMahallFamilySearch = debounce(
    value => setSearchText(value),
    600
  )

  //multi-list
  const [areas, setAreas] = useState([])

  // const filterList = [
  //   {
  //     field: 'area_choice_id',
  //     value: areas,
  //   },
  // ]

  const [filterListArea, setFilterListArea] = useState()

  const addOrRemoveItem = (arr, setArray, value) => {
    const isPresent = arr.includes(value)
    var newArr = arr

    if (isPresent) {
      newArr = arr.filter(item => {
        return item.toString() !== value.toString()
      })
    } else {
      newArr = [...arr, value]
    }
    setArray(newArr)
    setFilterListArea([
      {
        field: "area_choice_id",
        value: newArr,
      },
    ])
  }

  // const handleFilterFind = values => {
  //   let filterListArea = []
  //   const filterItems = [
  //     {
  //       field: 'area_choice_id',
  //       value: areas,
  //     },
  //   ]

  //   for (const filterItem of filterItems) {
  //     if (filterItem.value.length > 0) {
  //       filterListArea.push(filterItem)
  //     }
  //   }

  //   const filterData = {
  //     filter: filterListArea,
  //   }
  //   dispatch(getFilteredFamilies(searchText, 20, filterData))
  // }

  const filterData = {
    filter: filterListArea,
    sort: [sort ? sort : "-custom_id"],
    search: searchText,
  }

  useEffect(() => {
    dispatch(getFilteredFamilies(page, 10, filterData))
  }, [dispatch, page, sort, 10, searchText, filterListArea])

  useEffect(() => {
    if (
      userDetail &&
      ["premium", "standard"].includes(userDetail?.mahall?.version) &&
      modalAreaListDownload
    ) {
      dispatch(getFamiliesForDownload(filterData))
    }
  }, [dispatch, sort, searchText, filterListArea, modalAreaListDownload])

  const fileName = "khaf-smart-mahall-area-reports-list.csv"

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalAreaListDownload}
        onCloseclick={() => setModalAreaListDownload(false)}
        excelData={areaReportsExcelData}
        headers={headers}
        list={familiesforDownload}
        fileName={fileName}
        loading={loading}
      />
      <MetaTags>
        <title>Report | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഏരിയ റിപ്പോർട്ടുകൾ " : "Area Reports"}
          breadcrumbItem={language === "mal" ? "റിപ്പോർട്ട്" : "Report"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col className="col-12">
                <ToolkitProvider
                  keyField={"key"}
                  key={"key"}
                  columns={columns}
                  data={familiesData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Card>
                        <CardBody>
                          <Row className="mb-0">
                            {/* <AvForm
                              onValidSubmit={(e, v) => {
                                handleFilterFind(v)
                              }}
                            > */}
                            <h4 className="text-center">
                              {language === "mal"
                                ? "ഏരിയ റിപ്പോർട്ട് "
                                : "Area Report"}
                            </h4>
                            <Col
                              md="12"
                              className="d-flex align-items-center justify-content-start"
                            >
                              <div>
                                {mahallTypeLists?.area &&
                                  mahallTypeLists?.area?.length && (
                                    <Col md="12" lg={12}>
                                      <div className="mb-3">
                                        {/* <Label className="text-center" for="basicpill-cardno-input12">
                                            {language === "mal"
                                              ? "ബാങ്ക് വായ്പ"
                                              : "Area"}
                                          </Label> */}
                                        <div className="d-flex  flex-wrap">
                                          {map(
                                            mahallTypeLists?.area,
                                            (item, key) => (
                                              <div key={key} className="m-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  name={item.id}
                                                  checked={areas?.includes(
                                                    item.id
                                                  )}
                                                  onChange={() => {}}
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e => {
                                                    addOrRemoveItem(
                                                      areas,
                                                      setAreas,
                                                      e.target.value
                                                    )
                                                  }}
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                          {/* <div>
                                              <Button className="btn-md mt-1" color="success" type="submit">  {language === "mal" ? "ഫിൽറ്റർ" : "Find"}</Button>
                                            </div> */}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                              </div>
                            </Col>
                            {/* </AvForm> */}
                          </Row>
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <>
                            <Row>
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <form
                                      className="app-search d-lg-block"
                                      onChange={e =>
                                        debounceMahallFamilySearch(
                                          e.target.value
                                        )
                                      }
                                    >
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder={
                                            language === "mal"
                                              ? "തിരയുക..."
                                              : "Search..."
                                          }
                                          defaultValue={searchText}
                                        />
                                        <span className="bx bx-search-alt" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </Col>
                              {/* {familiesforDownloadLoading && !familiesforDownload ? <Spinner color="secondary" className="d-block m-auto" /> :
                                userDetail?.mahall.version === 'basic' ? <></> :
                                  <Button data-tip data-for="downloadTip"
                                    style={{ width: "200px", border: 'none' }}
                                    className="w-md btn btn-info m-auto me-0 mt-0 mb-2 d-block bg-white"
                                  >
                                    {familiesforDownload && familiesforDownload.length > 0 && <CSVLink data={familiesExcelData} headers={headers} filename={"khaf-smart-mahall-families-list.csv"}>
                                      <i className="bx bx-download mx-1" style={{ fontSize: '18px', color: "blue" }} />
                                      {language === 'mal' ? `ഡൗൺലോഡ് (${families?.count})` : `Download (${families?.count})`}
                                    </CSVLink>}
                                  </Button>
                              } */}
                              <Col>
                                {userDetail?.mahall?.version === "basic" ? (
                                  <></>
                                ) : (
                                  <div className="d-flex justify-content-end">
                                    <Button
                                      style={{ width: "130px", border: "bold" }}
                                      className="btn-md me-4 mt-2 bg-white text-khaf"
                                      onClick={() =>
                                        setModalAreaListDownload(true)
                                      }
                                    >
                                      <i
                                        className="bx bx-download mx-1"
                                        style={{
                                          fontSize: "18px",
                                          color: "#009846",
                                        }}
                                      />
                                      {language === "mal"
                                        ? `ഡൗൺലോഡ് `
                                        : `Download`}
                                    </Button>
                                  </div>
                                )}
                              </Col>
                              {loading ? (
                                <Spinner
                                  color="secondary"
                                  className="d-block m-auto"
                                />
                              ) : (
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="key"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table align-middle table-wrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                          {families &&
                            families?.results &&
                            families?.results?.length <= 0 && (
                              <p
                                style={{ fontSize: "15px" }}
                                className="text-center text-info"
                              >
                                {language === "mal"
                                  ? "കുടുംബങ്ങൾ ഒന്നും ഇല്ല"
                                  : "No Families Yet"}
                              </p>
                            )}
                        </CardBody>
                      </Card>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AreaReports
