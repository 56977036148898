// import usFlag from "assets/images/flags/us.jpg"
// import spain from "assets/images/flags/spain.jpg"

const languages = {
  mal: {
    label: "മലയാളം",
    // flag: spain,
  },
  en: {
    label: "English",
    // flag: usFlag,
  },
}

export default languages
