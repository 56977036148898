import {
  GET_STUDENT_ATTENDANCES,
  GET_STUDENT_ATTENDANCES_SUCCESS,
  GET_STUDENT_ATTENDANCES_FAIL,
  GET_STUDENT_ATTENDANCE_DETAIL,
  GET_STUDENT_ATTENDANCE_DETAIL_SUCCESS,
  GET_STUDENT_ATTENDANCE_DETAIL_FAIL,
  CREATE_STUDENT_ATTENDANCE,
  CREATE_STUDENT_ATTENDANCE_SUCCESS,
  CREATE_STUDENT_ATTENDANCE_FAIL,
  UPDATE_STUDENT_ATTENDANCE,
  UPDATE_STUDENT_ATTENDANCE_SUCCESS,
  UPDATE_STUDENT_ATTENDANCE_FAIL,
  DELETE_STUDENT_ATTENDANCE,
  DELETE_STUDENT_ATTENDANCE_SUCCESS,
  DELETE_STUDENT_ATTENDANCE_FAIL,
  CREATE_WORKING_DAY,
  CREATE_WORKING_DAY_SUCCESS,
  CREATE_WORKING_DAY_FAIL,
} from "./actionTypes"

export const getStudentAttendances = (date, divisionId, page) => ({
  type: GET_STUDENT_ATTENDANCES,
  payload: { date, divisionId, page },
})

export const getStudentAttendancesSuccess = studentAttendances => ({
  type: GET_STUDENT_ATTENDANCES_SUCCESS,
  payload: studentAttendances,
})
export const getStudentAttendancesFail = error => ({
  type: GET_STUDENT_ATTENDANCES_FAIL,
  payload: error,
})

export const getStudentAttendanceDetail = (workingDayId, divisionId) => ({
  type: GET_STUDENT_ATTENDANCE_DETAIL,
  payload: { workingDayId, divisionId },
})

export const getStudentAttendanceDetailSuccess = studentAttendanceDetail => ({
  type: GET_STUDENT_ATTENDANCE_DETAIL_SUCCESS,
  payload: studentAttendanceDetail,
})

export const getStudentAttendanceDetailFail = error => ({
  type: GET_STUDENT_ATTENDANCE_DETAIL_FAIL,
  payload: error,
})

export const createStudentAttendance = studentAttendance => ({
  type: CREATE_STUDENT_ATTENDANCE,
  payload: { studentAttendance },
})

export const createStudentAttendanceSuccess = studentAttendance => ({
  type: CREATE_STUDENT_ATTENDANCE_SUCCESS,
  payload: studentAttendance,
})

export const createStudentAttendanceFail = error => ({
  type: CREATE_STUDENT_ATTENDANCE_FAIL,
  payload: error,
})

export const updateStudentAttendance = (
  studentAttendance,
  studentAttendanceId
) => ({
  type: UPDATE_STUDENT_ATTENDANCE,
  payload: { studentAttendance, studentAttendanceId },
})

export const updateStudentAttendanceSuccess = studentAttendance => ({
  type: UPDATE_STUDENT_ATTENDANCE_SUCCESS,
  payload: studentAttendance,
})

export const updateStudentAttendanceFail = error => ({
  type: UPDATE_STUDENT_ATTENDANCE_FAIL,
  payload: error,
})

export const deleteStudentAttendance = (
  studentAttendanceId,
  history,
  studentId
) => ({
  type: DELETE_STUDENT_ATTENDANCE,
  payload: { studentAttendanceId, history, studentId },
})

export const deleteStudentAttendanceSuccess = studentAttendance => ({
  type: DELETE_STUDENT_ATTENDANCE_SUCCESS,
  payload: studentAttendance,
})

export const deleteStudentAttendanceFail = error => ({
  type: DELETE_STUDENT_ATTENDANCE_FAIL,
  payload: error,
})

export const createWorkingDay = (instituteId, workingDate) => ({
  type: CREATE_WORKING_DAY,
  payload: { instituteId, workingDate },
})

export const createWorkingDaySuccess = workingDayDetail => ({
  type: CREATE_WORKING_DAY_SUCCESS,
  payload: workingDayDetail,
})

export const createWorkingDayFail = error => ({
  type: CREATE_WORKING_DAY_FAIL,
  payload: error,
})
