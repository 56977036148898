import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"

//actions
import {
  createInstituteUser,
  checkInstituteUserPhoneNumber,
  getInstituteDropdown,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateMahallInstituteUser = ({ history }) => {
  const dispatch = useDispatch()

  const [toggleShow1, setToggleShow1] = useState(false)
  const [toggleShow2, setToggleShow2] = useState(false)
  const [toggleShow3, setToggleShow3] = useState(false)
  const [toggleShow4, setToggleShow4] = useState(false)

  const {
    loading,
    // error,
    searchInstitutes,
    // mahallUserDetail,
    instituteUserPhoneNumberAvailability,
    instituteUserPhoneCheckLoading,
    language,
    // userDetail,
    // mahallId,
  } = useSelector(state => ({
    loading: state.MahallUsers.loading,
    error: state.MahallUsers.error,
    searchInstitutes: state.Institutes.searchInstitutes,
    mahallUserDetail: state.MahallUsers.mahallUserDetail,
    instituteUserPhoneNumberAvailability:
      state.Users.instituteUserPhoneNumberAvailability,
    instituteUserPhoneCheckLoading: state.Users.instituteUserPhoneCheckLoading,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
    mahallId: state.Users.userDetail?.mahall?.id,
  }))

  const [checkValue, setCheckValue] = useState()

  const [institute, setInstitute] = useState("search institute")
  const [instituteId, setInstituteId] = useState("")
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    dispatch(getInstituteDropdown(searchText))
  }, [searchText, dispatch])

  function handlerFinalValue(event) {
    // dispatch(getInstituteDropdown(event.value))
    setInstituteId(event.value)
    setInstitute(event.label)
  }

  const optionGroup = [
    {
      options: searchInstitutes?.map((result, index) => ({
        key: index,
        label: `${
          language === "mal"
            ? result.institute_malayalam_name
              ? result.institute_malayalam_name
              : result.institute_english_name
            : result.institute_english_name
        } (${result?.institute_type && result?.institute_type})`,
        // <div>
        //   {language === "mal"
        //     ? results.institute_malayalam_name
        //       ? results.institute_malayalam_name
        //       : results.institute_english_name
        //     : results.institute_english_name}
        //   {results?.institute_type ? (
        //     <Badge className={"mx-2 bg-" + `${results?.institute_type === "institute"
        //       ? "primary"
        //       : results?.institute_type === "program"
        //         ? "danger"
        //         : results?.institute_type === "mahall"
        //           ? "success"
        //           : ""
        //       }`}>
        //       {results?.institute_type}
        //     </Badge>
        //   ) : ""}
        // </div>,
        value: result.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const CreateInstituteUserData = {
      institute: instituteId,
      full_name: values.full_name,
      phone: values.phone,
      email: values.email,
      perm_view: toggleShow1,
      perm_create: toggleShow2,
      perm_update: toggleShow3,
      perm_delete: toggleShow4,
    }
    if (!instituteUserPhoneNumberAvailability) return
    dispatch(createInstituteUser(CreateInstituteUserData, history))
  }

  const handleCheckInstituteUserPhoneNumberAvailability = v => {
    if (v.length == 10) {
      const checkInstituteUserPhoneNumberData = {
        phone: v,
      }
      dispatch(checkInstituteUserPhoneNumber(checkInstituteUserPhoneNumberData))
      setCheckValue(v)
    }
  }

  // const mailError = error[0]

  return (
    <>
      <MetaTags>
        <title>Institute Users | Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal"
              ? "ഇൻസ്റ്റിറ്റ്യൂട്ട്  യൂസറുകൾ"
              : "Institute Users"
          }
          breadcrumbItem={language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create"}
        />
        <Container fluid className="sm:p-0">
          <div className="container-fluid">
            <Row>
              <Col lg={12} className="sm:p-0">
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4 mb-4 mt-1">
                      Basic Information
                    </CardTitle> */}
                    {/* <h6 style={{ marginTop: "-15px", marginBottom: "30px" }}>
                      Fill All Information Below
                    </h6> */}

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Full Name"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="full_name"
                                type="text"
                                title="Full name"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: `${
                                      language === "mal"
                                        ? "നിർബന്ധമാണ്"
                                        : "Required"
                                    }`,
                                  },
                                }}
                              />
                            </Col>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "ഫോൺ നമ്പർ"
                                : "Phone Number"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="phone"
                                type="number"
                                validate={{
                                  required: { value: true },
                                  minLength: {
                                    value: 10,
                                    errorMessage: `${
                                      language === "mal"
                                        ? "ഫോൺ നമ്പർ 10 അക്കങ്ങൾ ആയിരിക്കണം"
                                        : "phone number must be 10 digits"
                                    }`,
                                  },
                                  maxLength: {
                                    value: 10,
                                    errorMessage: `${
                                      language === "mal"
                                        ? "ഫോൺ നമ്പർ 10 അക്കങ്ങൾ ആയിരിക്കണം"
                                        : "phone number must be 10 digits"
                                    }`,
                                  },
                                }}
                                onChange={v => {
                                  handleCheckInstituteUserPhoneNumberAvailability(
                                    v.target.value
                                  )
                                  setCheckValue(v.target.value)
                                }}
                              />
                            </Col>
                            {checkValue &&
                              checkValue?.length == 10 &&
                              (!instituteUserPhoneCheckLoading ? (
                                instituteUserPhoneNumberAvailability ? (
                                  <p className="text-success">
                                    {language === "mal"
                                      ? "ലഭ്യമാണ്"
                                      : "Available"}
                                  </p>
                                ) : (
                                  <p className="text-danger">
                                    {language === "mal"
                                      ? "ലഭ്യമല്ല"
                                      : "Not Available"}
                                  </p>
                                )
                              ) : (
                                <></>
                              ))}
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div
                          className="col-md-6"
                          style={{ marginTop: "-15px" }}
                        >
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "ഇമെയിൽ" : "Email"}
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="email"
                                type="email"
                                title="Email"
                              />
                            </Col>
                          </div>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ marginTop: "-15px" }}
                        >
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "ഇൻസ്റ്റിറ്റ്യൂട്ട്"
                                : "Institute"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <Select
                                onInputChange={handleEnters}
                                placeholder={institute}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                                onChange={handlerFinalValue}
                                type="text"
                                title="Institute"
                                required="required"
                              />
                            </Col>
                          </div>
                        </div>
                      </Row>

                      <CardTitle className="h4 mb-4 mt-5">
                        {language === "mal" ? "അനുമതികൾ" : "Permissions"}
                      </CardTitle>
                      <h6 style={{ marginTop: "-15px", marginBottom: "30px" }}>
                        {language === "mal"
                          ? "ചുവടെയുള്ള ആവശ്യമായ അനുമതികൾ ടിക്ക് ചെയ്യുക"
                          : "Check The Required Permissions Below"}
                      </h6>

                      <Row>
                        <div className="col-md-6">
                          <div className="row mb-4">
                            {/* <Col sm={9}>
                              <AvField
                                id="horizontal-lastname-Input"
                                name="perm_view"
                                title="Perm view"
                                type="checkbox"
                                label={
                                  language === "mal"
                                    ? "എല്ലാ ഡാറ്റയും കാണുക"
                                    : "View All Data"
                                }
                                checked={toggleShow1 ? true : false}
                                className="mt-1"
                                onClick={() => setToggleShow1(!toggleShow1)}
                                readOnly
                              />
                            </Col> */}
                            <Col sm={9}>
                              <Label className="mx-5">
                                {language === "mal"
                                  ? "എല്ലാ ഡാറ്റയും കാണുക"
                                  : "View All Data"}
                              </Label>
                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input toggle-switch"
                                  id="customSwitchsizemd"
                                  checked={toggleShow1}
                                  onChange={() => {}}
                                  onClick={() => setToggleShow1(!toggleShow1)}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row mb-4">
                            {/* <Col sm={9}>
                              <AvField
                                id="horizontal-lastname-Input"
                                name="perm_create"
                                title="Perm create"
                                type="checkbox"
                                label={
                                  language === "mal"
                                    ? "എല്ലാ ഡാറ്റയും ചേർക്കുക"
                                    : "Add All Data"
                                }
                                checked={toggleShow2 ? true : false}
                                className="mt-1"
                                onClick={() => setToggleShow2(!toggleShow2)}
                                readOnly
                              />
                            </Col> */}
                            <Col sm={9}>
                              <Label className="mx-5">
                                {language === "mal"
                                  ? "എല്ലാ ഡാറ്റയും ചേർക്കുക"
                                  : "Add All Data"}
                              </Label>
                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input toggle-switch"
                                  id="customSwitchsizemd"
                                  checked={toggleShow2}
                                  onChange={() => {}}
                                  onClick={() => setToggleShow2(!toggleShow2)}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-6">
                          <div className="row mb-4">
                            {/* <Col sm={9}>
                              <AvField
                                id="horizontal-lastname-Input"
                                name="perm_update"
                                title="Perm update"
                                type="checkbox"
                                label={
                                  language === "mal"
                                    ? "എല്ലാ ഡാറ്റയും എഡിറ്റ് ചെയ്യുക"
                                    : "Edit All Data"
                                }
                                checked={toggleShow3 ? true : false}
                                className="mt-1"
                                onClick={() => setToggleShow3(!toggleShow3)}
                                readOnly
                              />
                            </Col> */}
                            <Col sm={9}>
                              <Label className="mx-5">
                                {language === "mal"
                                  ? "എല്ലാ ഡാറ്റയും എഡിറ്റ് ചെയ്യുക"
                                  : "Edit All Data"}
                              </Label>
                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input toggle-switch"
                                  id="customSwitchsizemd"
                                  checked={toggleShow3}
                                  onChange={() => {}}
                                  onClick={() => setToggleShow3(!toggleShow3)}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row mb-4">
                            {/* <Col sm={9}>
                              <AvField
                                id="horizontal-lastname-Input"
                                name="perm_delete"
                                title="Perm delete"
                                type="checkbox"
                                label={
                                  language === "mal"
                                    ? "എല്ലാ ഡാറ്റയും ഡിലീറ്റ് ചെയ്യുക "
                                    : "Delete All Data"
                                }
                                checked={toggleShow4 ? true : false}
                                className="mt-1"
                                onClick={() => setToggleShow4(!toggleShow4)}
                                readOnly
                              />
                            </Col> */}
                            <Col sm={9}>
                              <Label className="mx-5">
                                {language === "mal"
                                  ? "എല്ലാ ഡാറ്റയും ഡിലീറ്റ് ചെയ്യുക"
                                  : "Delete All Data"}
                              </Label>
                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input toggle-switch"
                                  id="customSwitchsizemd"
                                  checked={toggleShow4}
                                  onChange={() => {}}
                                  onClick={() => setToggleShow4(!toggleShow4)}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Row>

                      <div className="d-flex justify-content-end mt-5">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md m-auto me-0 d-block"
                            disabled={!instituteUserPhoneNumberAvailability}
                          >
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            )}
                            {language === "mal"
                              ? "പുതിയത് ചേർക്കുക"
                              : "Create User"}
                          </Button>
                        </div>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateMahallInstituteUser

CreateMahallInstituteUser.propTypes = {
  history: PropTypes.object,
}
