import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"

//actions
import {
  getCommitteeMeetingDetail,
  updateCommitteeMeeting,
  deleteCommitteeMeeting,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import FormEditors from "components/Common/FormEditor"

const UpdateInstituteCommitteeMeeting = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, committeeMeetingDetail, language } = useSelector(state => ({
    loading: state.CommitteeMeetings.loading,
    error: state.Mahalls.error,
    language: state.Layout.language,
    committeeMeetingDetail: state.CommitteeMeetings.committeeMeetingDetail,
  }))

  const [agenda, setAgenda] = useState("")
  const [report, setReport] = useState("")

  const agendaData = committeeMeetingDetail?.agenda
    ? committeeMeetingDetail?.agenda
    : ""
  const reportData = committeeMeetingDetail?.report
    ? committeeMeetingDetail?.report
    : ""

  useEffect(() => {
    setAgenda(agendaData)
    setReport(reportData)
  }, [committeeMeetingDetail])

  useEffect(() => {
    dispatch(getCommitteeMeetingDetail(params?.id))
  }, [dispatch, params.id])

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    // console.log(values)
    const updateCommitteeMeetingsData = {
      committee: committeeMeetingDetail?.committee,
      title: values.title,
      meeting_date: values.meeting_date,
      place: values.place,
      description: values.description,
      agenda: agenda,
      report: report,
    }
    console.log(updateCommitteeMeetingsData)
    dispatch(
      updateCommitteeMeeting(
        updateCommitteeMeetingsData,
        committeeMeetingDetail?.id,
        history
      )
    )
  }

  const [isOpen, setIsOpen] = useState(false)

  // const handleDelete = () => {
  //   setIsOpen(true)
  // }

  const handleDeleteEvent = () => {
    dispatch(deleteCommitteeMeeting(committeeMeetingDetail?.id, history))
    setIsOpen(false)
  }

  console.log(agenda)

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <MetaTags>
        <title>Meetings | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "യോഗം" : "Meeting"}
          breadcrumbItem={language === "mal" ? "അപ്ഡേറ്റ്" : "Update"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4 mb-4">Fill this form</CardTitle> */}
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Title"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="title"
                              type="text"
                              value={committeeMeetingDetail?.title}
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <Row>
                            <Col md="6">
                              <div className="col mb-4">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-12 col-form-label"
                                >
                                  {language === "mal"
                                    ? "മീറ്റിംഗ് തീയതി"
                                    : "Meeting Date"}
                                  <span className="text-danger">&#42;</span>
                                </Label>
                                <AvField
                                  id="horizontal-firstname-Input"
                                  name="meeting_date"
                                  type="date"
                                  value={committeeMeetingDetail?.meeting_date}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  // label={language === "mal" ? "മൊബൈൽ നമ്പർ" : "Meeting Date"}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="col mb-4">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-12 col-form-label"
                                >
                                  {language === "mal" ? "സ്ഥലം" : "Place"}
                                </Label>
                                <AvField
                                  id="horizontal-firstname-Input"
                                  name="place"
                                  type="text"
                                  value={committeeMeetingDetail?.place}
                                  // label={language === "mal" ? "മൊബൈൽ നമ്പർ" : "Place"}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-12">
                          <div className="col mb-4">
                            <AvField
                              id="horizontal-firstname-Input"
                              name="description"
                              type="textarea"
                              value={committeeMeetingDetail?.description}
                              label={
                                language === "mal" ? "വിവരണം" : "Description"
                              }
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="col-12 mb-3">
                            <Label htmlFor="lbltype" className="form-label">
                              {language === "mal" ? "അജണ്ട" : "Agenda"}
                            </Label>
                            <Col sm="12" className="mt-0">
                              {agenda?.length >= 1 && (
                                <FormEditors
                                  content={agenda}
                                  setContent={setAgenda}
                                />
                              )}

                              {agenda?.length <= 0 && (
                                <FormEditors
                                  content={agenda}
                                  setContent={setAgenda}
                                />
                              )}
                            </Col>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="col-12 mb-3">
                            <Label htmlFor="lbltype" className="form-label">
                              {language === "mal" ? "റിപ്പോർട്ട്" : "Report"}
                            </Label>
                            <Col sm="12" className="mt-0">
                              {agenda?.length >= 1 && (
                                <FormEditors
                                  content={report}
                                  setContent={setReport}
                                />
                              )}
                              {agenda?.length <= 0 && (
                                <FormEditors
                                  content={report}
                                  setContent={setReport}
                                />
                              )}
                            </Col>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-5 d-flex">
                        {/* <div className="col-6">
                          <div className="row justify-content-start">
                            <div>
                              <Button
                                type="button"
                                color="danger"
                                className="w-md"
                                onClick={handleDelete}
                              >
                                {language === "mal"
                                  ? "ഡിലീറ്റ് ചെയ്യുക"
                                  : "Delete"}
                              </Button>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-12">
                          <div className="row justify-content-end">
                            <div>
                              <Button
                                type="submit"
                                color="primary"
                                className="w-100 m-auto me-0 d-block"
                              >
                                {loading && (
                                  <>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                  </>
                                )}
                                {language === "mal"
                                  ? "അപ്ഡേറ്റ് ചെയ്യുക "
                                  : "Update"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateInstituteCommitteeMeeting

UpdateInstituteCommitteeMeeting.propTypes = {
  history: PropTypes.object,
}
