import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
  Button,
} from "reactstrap"
// import images from "assets/images"

const CardContact = props => {
  const { user, updateCommitteeMemberModal } = props

  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            {!user?.image ? (
              <div className="avatar-sm mx-auto mb-4">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft bg-" +
                    user?.color +
                    " text-" +
                    user?.color +
                    " font-size-16"
                  }
                >
                  {(user?.member_name === "No user"
                    ? user?.full_name
                    : user?.member_name
                  )?.charAt(0)}
                </span>
              </div>
            ) : (
              <div className="mb-4">
                <img
                  className="rounded-circle avatar-sm"
                  src={user?.image}
                  alt=""
                />
              </div>
            )}

            <h5 className="font-size-15 mb-1">
              <Link to="#" className="text-dark">
                {user?.member_name === "No user"
                  ? user?.full_name
                  : user?.member_name}
              </Link>
            </h5>
            <p className="text-muted">{user?.designation}</p>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <Button
                  id={"message" + user?.id}
                  type="button"
                  color="white"
                  className=" btn m-0 p-0"
                  onClick={() => updateCommitteeMemberModal(user)}
                >
                  <i
                    className="bx bx-pencil me-2"
                    style={{ color: "green", fontSize: "18px" }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={"message" + user?.id}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Button>
                {/* <Link to="#" id={"message" + user?.id}>
                  <i className="bx bx-message-square-dots" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"message" + user?.id}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link> */}
              </div>
              <div className="flex-fill">
                <Button
                  id={"project" + user?.id}
                  type="button"
                  color="white"
                  className=" btn m-0 p-0"
                  onClick={() => updateCommitteeMemberModal(user)}
                >
                  <i
                    className="bx bx-trash me-2"
                    style={{ color: "red", fontSize: "18px" }}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={"project" + user?.id}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Button>
                {/* <Link to="#" id={"project" + user?.id}>
                  <i className="bx bx-pie-chart-alt" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"project" + user?.id}
                  >
                    Projects
                  </UncontrolledTooltip>
                </Link> */}
              </div>
              {/* <div className="flex-fill">
                <Link to="#" id={"profile" + user?.id}>
                  <i className="bx bx-user-circle" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"profile" + user?.id}
                  >
                    Profile
                  </UncontrolledTooltip>
                </Link>
              </div> */}
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardContact.propTypes = {
  user: PropTypes.object,
  updateCommitteeMemberModal: PropTypes.func,
}

export default CardContact
