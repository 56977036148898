import {
  GET_INSTITUTE_WALLET_CONTRA_LIST_SUCCESS,
  GET_INSTITUTE_WALLET_CONTRA_LIST_FAIL,
  CREATE_INSTITUTE_WALLET_CONTRA_SUCCESS,
  CREATE_INSTITUTE_WALLET_CONTRA_FAIL,
  GET_INSTITUTE_WALLET_CONTRA_LIST,
  CREATE_INSTITUTE_WALLET_CONTRA,
} from "./actionTypes"

const INIT_STATE = {
  instituteWalletContraList: [],
  error: {},
  loading: false,
  detailLoading: false,
}

const InstituteWalletContra = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INSTITUTE_WALLET_CONTRA_LIST:
    case CREATE_INSTITUTE_WALLET_CONTRA:
      return {
        ...state,
        loading: true,
      }
    case GET_INSTITUTE_WALLET_CONTRA_LIST_SUCCESS:
      return {
        ...state,
        instituteWalletContraList: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_WALLET_CONTRA_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_INSTITUTE_WALLET_CONTRA_SUCCESS:
      return {
        ...state,
        instituteWalletContraList: {
          ...state.instituteWalletContraList,
          results: [...state.instituteWalletContraList.results, action.payload],
        },
        loading: false,
      }

    case CREATE_INSTITUTE_WALLET_CONTRA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default InstituteWalletContra
