import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import axios from "axios"
//actions
import { Image } from "react-bootstrap"

import { API_URL } from "helpers/api_methods"
import {
  createMahallNotificationSuccess,
  createMahallNotificationFail,
} from "store/actions"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const CreateMahallNotification = ({ history }) => {
  const dispatch = useDispatch()

  const { loading, groupId, language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    error: state.Mahalls.error,
    groupId: state.Users.userDetail?.mahall_group?.id,
    language: state.Layout.language,
  }))

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })

  const [imagePreview, setImagePreview] = useState()

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    setCustomLoad(true)
    createNewMahallNotification(
      selectedFile,
      groupId,
      values,
      dispatch,
      history,
      setCustomLoad
    )
    setImagePreview()
  }

  const changeHandler = e => {
    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <>
      <MetaTags>
        <title>Mahall Notifications | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal" ? "മഹല്ല് അറിയിപ്പുകൾ" : "Mahall Notifications"
          }
          breadcrumbItem={
            language === "mal"
              ? "പുതിയത്  ചേർക്കുക"
              : "Create Mahall Notification"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <Label>
                            {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="title"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-3">
                        <Col className="mb-3">
                          <AvField
                            name="sub_title"
                            type="text"
                            label={
                              language === "mal" ? "സബ്‌ടൈറ്റിൽ" : "Subtitle"
                            }
                          />
                        </Col>
                      </div>

                      <div className="row mb-4 ">
                        {/* <Col sm="12" md="6" className="mb-3">
                          <AvField
                            name="datetime"
                            type="date"
                            label={language === "mal" ? "തീയതി" : "Date"}
                          />
                        </Col> */}
                        <Col sm="12" md="6">
                          <AvField
                            name="sender"
                            type="text"
                            label={language === "mal" ? "നിന്ന്" : "From"}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="description"
                            type="textarea"
                            label={
                              language === "mal" ? "വിവരണം" : "Description"
                            }
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <Label>
                            {" "}
                            {language === "mal" ? "ഫോട്ടോ" : "Image"}{" "}
                          </Label>
                          <AvField
                            name="image"
                            type="file"
                            onChange={changeHandler}
                          />
                          {imagePreview && (
                            <Image
                              style={{ width: "150px", height: "150px" }}
                              className="my-2"
                              src={imagePreview.image}
                            />
                          )}
                        </Col>
                      </div>

                      <div className="d-flex justify-content-end">
                        <Button type="submit" color="success" className="w-md">
                          {loading ||
                            (customLoad && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            ))}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateMahallNotification

CreateMahallNotification.propTypes = {
  history: PropTypes.object,
}

function createNewMahallNotification(
  selectedFile,
  groupId,
  values,
  dispatch,
  history,
  setCustomLoad
) {
  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append("image", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("mahall_group", groupId)
  formData.append("title", values.title)
  formData.append("sub_title", values.sub_title)
  // formData.append("datetime", values.datetime)
  formData.append("sender", values.sender)
  formData.append("description", values.description)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .post(`${API_URL}/api/v1/notification/mahall_notification/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      dispatch(createMahallNotificationSuccess(res.data))
      history.push("/mahall/notifications")
      doneNotification("Created Successfully")
      setCustomLoad(false)
    })
    .catch(err => {
      createMahallNotificationFail(err)
      errorNotification()
      setCustomLoad(false)
    })
}
