import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_STUDENTS,
  GET_STUDENT_DETAIL,
  CREATE_STUDENT,
  UPDATE_STUDENT,
  DELETE_STUDENT,
  STUDENTS_DROPDOWN,
  GET_STUDENT_ABSENT_DETAIL,
} from "./actionTypes"
import {
  getStudentsSuccess,
  getStudentsFail,
  getStudentDetailSuccess,
  getStudentDetailFail,
  createStudentSuccess,
  createStudentFail,
  updateStudentSuccess,
  updateStudentFail,
  deleteStudentSuccess,
  deleteStudentFail,
  studentsDropdownSuccess,
  studentsDropdownFail,
  getStudentAbsentDetailSuccess,
  getStudentAbsentDetailFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"
import { clearMemberDetail } from "store/actions"

const getStudentsAPi = ({ divisionId, instituteId, searchText, page }) => {
  if (divisionId) {
    return get(
      `/api/v1/madrasa/madrasa-student/?division=${
        divisionId ? divisionId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else if (instituteId) {
    return get(
      `/api/v1/madrasa/madrasa-student/?institute=${
        instituteId ? instituteId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/madrasa/madrasa-student/?search=${
        searchText ? searchText : ""
      }&page=${page ? page : 1}`
    )
  }
}
const getStudentsDropdownAPi = ({ searchText, divisionId, instituteId }) => {
  if (instituteId) {
    return get(
      `/api/v1/madrasa/madrasa-student-dropdown/?institute=${
        instituteId ? instituteId : ""
      }&search=${searchText ? searchText : ""}`
    )
  } else {
    return get(
      `/api/v1/madrasa/madrasa-student-dropdown/?division=${
        divisionId ? divisionId : ""
      }&search=${searchText ? searchText : ""}`
    )
  }
}
const getStudentDetailsAPi = studentId => {
  return get(`/api/v1/madrasa/madrasa-student/${studentId}/`)
}
const getStudentAbsentDetailsAPi = studentId => {
  return post(`/api/v1/madrasa/student-absent-dates/${studentId}/`)
}
const createStudentApi = ({ newStudent }) => {
  return post("/api/v1/madrasa/madrasa-student/", newStudent)
}
const updateStudentApi = ({ studentId, updatedStudent }) => {
  return ApiPut(`/api/v1/madrasa/madrasa-student/${studentId}/`, updatedStudent)
}
const deleteStudentApi = ({ studentId }) => {
  return del(`/api/v1/madrasa/madrasa-student/${studentId}/`)
}

function* fetchStudents({ payload }) {
  try {
    const response = yield call(getStudentsAPi, payload)
    if (response && !response?.error) {
      yield put(getStudentsSuccess(response))
    } else {
      yield put(getStudentsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentsFail(error))
  }
}

function* fetchStudentsDropdown({ payload }) {
  try {
    const response = yield call(getStudentsDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(studentsDropdownSuccess(response))
    } else {
      yield put(studentsDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(studentsDropdownFail(error))
  }
}

function* fetchStudentDetail({ studentId }) {
  try {
    const response = yield call(getStudentDetailsAPi, studentId)
    if (response && !response?.error) {
      yield put(getStudentDetailSuccess(response))
    } else {
      yield put(getStudentDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentDetailFail(error))
  }
}
function* fetchStudentAbsentDetail({ studentId }) {
  try {
    const response = yield call(getStudentAbsentDetailsAPi, studentId)
    if (response && !response?.error) {
      yield put(getStudentAbsentDetailSuccess(response))
    } else {
      yield put(getStudentAbsentDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentAbsentDetailFail(error))
  }
}
function* onCreateStudent({ payload }) {
  try {
    const response = yield call(createStudentApi, payload)
    if (response && !response?.error) {
      yield put(createStudentSuccess(response))
      if (payload.history && payload.dispatch) {
        payload.history.push(`/division/${response?.division}`)
        payload.dispatch(clearMemberDetail())
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createStudentFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createStudentFail(error))
    errorNotification()
  }
}

function* onUpdateStudent({ payload }) {
  try {
    const response = yield call(updateStudentApi, payload)
    if (response && !response?.error) {
      payload.history.push(`/division/${response?.division}`)
      yield put(updateStudentSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateStudentFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateStudentFail(error))
    errorNotification()
  }
}

function* onDeleteStudent({ payload }) {
  try {
    const response = yield call(deleteStudentApi, payload)
    if (response && !response?.error) {
      yield put(deleteStudentSuccess(response))
      doneNotification("Deleted Successfully")
      payload.history.goBack()
    } else {
      yield put(deleteStudentFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteStudentFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* StudentsSaga() {
  yield takeEvery(GET_STUDENTS, fetchStudents)
  yield takeEvery(STUDENTS_DROPDOWN, fetchStudentsDropdown)
  yield takeEvery(GET_STUDENT_DETAIL, fetchStudentDetail)
  yield takeEvery(GET_STUDENT_ABSENT_DETAIL, fetchStudentAbsentDetail)
  yield takeEvery(CREATE_STUDENT, onCreateStudent)
  yield takeEvery(UPDATE_STUDENT, onUpdateStudent)
  yield takeEvery(DELETE_STUDENT, onDeleteStudent)
}

export default StudentsSaga
