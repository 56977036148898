import {
  GET_LSGS_SUCCESS,
  GET_LSGS_FAIL,
  GET_LSG_DETAIL_SUCCESS,
  GET_LSG_DETAIL_FAIL,
  GET_LSG_DROPDOWN_SUCCESS,
  GET_LSG_DROPDOWN_FAIL,
  CREATE_LSG_SUCCESS,
  CREATE_LSG_FAIL,
  UPDATE_LSG_SUCCESS,
  UPDATE_LSG_FAIL,
  DELETE_LSG_SUCCESS,
  DELETE_LSG_FAIL,
  GET_LSGS,
  GET_LSG_DETAIL,
  GET_LSG_DROPDOWN,
  UPDATE_LSG,
  CREATE_LSG,
  DELETE_LSG,
} from "./actionTypes"

const INIT_STATE = {
  lsgs: [],
  lsgDetail: {},
  searchLsgs: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdlsg: {},
}

const Lsgs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LSGS:
    case UPDATE_LSG:
    case CREATE_LSG:
    case DELETE_LSG:
    case GET_LSG_DROPDOWN:
      return {
        ...state,
        loading: true,
      }
    case GET_LSG_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_LSGS_SUCCESS:
      return {
        ...state,
        lsgs: action.payload,
        loading: false,
      }

    case GET_LSGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_LSG_SUCCESS:
      return {
        ...state,
        // lsgs: action.payload,
        createdLsg: action.payload,
        loading: false,
      }

    case CREATE_LSG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_LSG_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchLsgs: action.payload,
        loading: false,
      }

    case GET_LSG_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_LSG_DETAIL_SUCCESS:
      return {
        ...state,
        lsgDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_LSG_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_LSG_SUCCESS:
      return {
        ...state,
        lsgDetail: action.payload,
        loading: false,
      }

    case UPDATE_LSG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_LSG_SUCCESS:
      return {
        ...state,
        // lsgs: state.lsgs.filter(
        //   lsg => lsg.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_LSG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Lsgs
