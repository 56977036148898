import {
  GET_GROUP_ADMIN,
  GET_GROUP_ADMIN_SUCCESS,
  GET_GROUP_ADMIN_FAIL,
  GET_GROUP_ADMIN_DETAIL,
  GET_GROUP_ADMIN_DETAIL_SUCCESS,
  GET_GROUP_ADMIN_DETAIL_FAIL,
  CHANGE_GROUP_ADMIN_STATUS_SUCCESS,
  CHANGE_GROUP_ADMIN_STATUS_FAIL,
  CREATE_GROUP_ADMIN,
  CREATE_GROUP_ADMIN_SUCCESS,
  CREATE_GROUP_ADMIN_FAIL,
  UPDATE_GROUP_ADMIN,
  UPDATE_GROUP_ADMIN_SUCCESS,
  UPDATE_GROUP_ADMIN_FAIL,
  DELETE_GROUP_ADMIN,
  DELETE_GROUP_ADMIN_SUCCESS,
  DELETE_GROUP_ADMIN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  groupAdmins: [],
  groupAdminDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdgroupAdmin: {},
  groupAdminStatus: {},
}

const GroupAdmins = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case CHANGE_GROUP_ADMIN_STATUS:
    case GET_GROUP_ADMIN:
    case UPDATE_GROUP_ADMIN:
    case CREATE_GROUP_ADMIN:
    case DELETE_GROUP_ADMIN:
      return {
        ...state,
        loading: true,
      }
    case GET_GROUP_ADMIN_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_GROUP_ADMIN_SUCCESS:
      return {
        ...state,
        groupAdmins: action.payload,
        loading: false,
      }

    case GET_GROUP_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CHANGE_GROUP_ADMIN_STATUS_SUCCESS:
      return {
        ...state,
        groupAdminStatus: action.payload,
        groupAdmins: {
          results: state.groupAdmins.results?.map(item =>
            item.id === action.payload.groupAdminId
              ? { ...item, is_active: action.payload.checked }
              : item
          ),
        },
        // loading: false,
      }

    case CHANGE_GROUP_ADMIN_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        // loading: false,
      }
    case CREATE_GROUP_ADMIN_SUCCESS:
      return {
        ...state,
        groupAdmins: action.payload,
        createdGroupAdmin: action.payload,
        loading: false,
      }

    case CREATE_GROUP_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_GROUP_ADMIN_DETAIL_SUCCESS:
      return {
        ...state,
        groupAdminDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_GROUP_ADMIN_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_GROUP_ADMIN_SUCCESS:
      return {
        ...state,
        groupAdminDetail: action.payload,
        loading: false,
      }

    case UPDATE_GROUP_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_GROUP_ADMIN_SUCCESS:
      return {
        groupAdmins: action.payload,
        loading: false,
      }

    case DELETE_GROUP_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default GroupAdmins
