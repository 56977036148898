import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COMMITTEE_MEETING_ATTENDANCES,
  GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL,
  CREATE_COMMITTEE_MEETING_ATTENDANCE,
  UPDATE_COMMITTEE_MEETING_ATTENDANCE,
  DELETE_COMMITTEE_MEETING_ATTENDANCE,
} from "./actionTypes"
import {
  getCommitteeMeetingAttendancesSuccess,
  getCommitteeMeetingAttendancesFail,
  getCommitteeMeetingAttendanceDetailSuccess,
  getCommitteeMeetingAttendanceDetailFail,
  createCommitteeMeetingAttendanceSuccess,
  createCommitteeMeetingAttendanceFail,
  updateCommitteeMeetingAttendanceSuccess,
  updateCommitteeMeetingAttendanceFail,
  deleteCommitteeMeetingAttendanceSuccess,
  deleteCommitteeMeetingAttendanceFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"
import {
  getCommitteeMeetingDetailSuccess,
  getCommitteeMeetingDetailFail,
} from "store/actions"

const getCommitteeMeetingAttendancesAPi = ({
  committeeMeetingId,
  searchText,
  page,
}) => {
  if (committeeMeetingId) {
    return get(
      `/api/v1/committee/committee_meeting_attendance/?committee_meeting=${
        committeeMeetingId && committeeMeetingId
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/committee/committee_meeting_attendance/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  }
}
const getCommitteeMeetingAttendanceDetailsAPi =
  committeeMeetingAttendanceId => {
    return get(
      `/api/v1/committee/committee_meeting_attendance/${committeeMeetingAttendanceId}/`
    )
  }
const getCommitteeMeetingDetailsAPi = committeeMeetingId => {
  return get(`/api/v1/committee/committee_meeting/${committeeMeetingId}/`)
}
const createCommitteeMeetingAttendanceApi = ({
  committeeMeetingAttendance,
}) => {
  return post(
    "/api/v1/committee/committee_meeting_attendance/",
    committeeMeetingAttendance
  )
}
const updateCommitteeMeetingAttendanceApi = ({
  committeeMeetingAttendanceId,
  committeeMeetingAttendance,
}) => {
  return ApiPut(
    `/api/v1/committee/committee_meeting_attendance/${committeeMeetingAttendanceId}/`,
    committeeMeetingAttendance
  )
}
const deleteCommitteeMeetingAttendanceApi = ({
  committeeMeetingAttendanceId,
}) => {
  return del(
    `/api/v1/committee/committee_meeting_attendance/${committeeMeetingAttendanceId}/`
  )
}

function* fetchCommitteeMeetingAttendances({ payload }) {
  try {
    const response = yield call(getCommitteeMeetingAttendancesAPi, payload)
    if (response && !response?.error) {
      yield put(getCommitteeMeetingAttendancesSuccess(response))
    } else {
      yield put(getCommitteeMeetingAttendancesFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getCommitteeMeetingAttendancesFail(error))
  }
}

function* fetchCommitteeMeetingAttendanceDetail({
  committeeMeetingAttendanceId,
}) {
  try {
    const response = yield call(
      getCommitteeMeetingAttendanceDetailsAPi,
      committeeMeetingAttendanceId
    )
    if (response && !response?.error) {
      yield put(getCommitteeMeetingAttendanceDetailSuccess(response))
    } else {
      yield put(getCommitteeMeetingAttendanceDetailFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getCommitteeMeetingAttendanceDetailFail(error))
  }
}
function* onCreateCommitteeMeetingAttendance({ payload }) {
  try {
    const response = yield call(createCommitteeMeetingAttendanceApi, payload)
    if (response && !response?.error) {
      yield put(createCommitteeMeetingAttendanceSuccess(response))
      // payload.history.push("/committeemeetingattendances")
      doneNotification("Created Successfully")
      try {
        const response2 = yield call(
          getCommitteeMeetingDetailsAPi,
          response?.committee_meeting
        )
        yield put(getCommitteeMeetingDetailSuccess(response2))
      } catch (error) {
        yield put(getCommitteeMeetingDetailFail(error))
      }
    } else {
      yield put(createCommitteeMeetingAttendanceFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createCommitteeMeetingAttendanceFail(error))
    errorNotification()
  }
}

function* onUpdateCommitteeMeetingAttendance({ payload }) {
  try {
    const response = yield call(updateCommitteeMeetingAttendanceApi, payload)
    if (response && !response?.error) {
      yield put(updateCommitteeMeetingAttendanceSuccess(response))
      doneNotification("Updated Successfully")
      try {
        const response2 = yield call(
          getCommitteeMeetingDetailsAPi,
          response?.committee_meeting
        )
        yield put(getCommitteeMeetingDetailSuccess(response2))
      } catch (error) {
        yield put(getCommitteeMeetingDetailFail(error))
      }
    } else {
      yield put(updateCommitteeMeetingAttendanceFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateCommitteeMeetingAttendanceFail(error))
    errorNotification()
  }
}

function* onDeleteCommitteeMeetingAttendance({ payload }) {
  try {
    const response = yield call(deleteCommitteeMeetingAttendanceApi, payload)
    if (response && !response?.error) {
      yield put(
        deleteCommitteeMeetingAttendanceSuccess(
          payload.committeeMeetingAttendanceId
        )
      )
      doneNotification("Deleted Successfully")
      // try {
      //   const response2 = yield call(getCommitteeMeetingAttendancesAPi, payload)
      //   yield put(getCommitteeMeetingAttendancesSuccess(response2))
      // } catch (error) {
      //   yield put(getCommitteeMeetingAttendancesFail(error))
      // }
    } else {
      yield put(deleteCommitteeMeetingAttendanceFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteCommitteeMeetingAttendanceFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* CommitteeMeetingAttendancesSaga() {
  yield takeEvery(
    GET_COMMITTEE_MEETING_ATTENDANCES,
    fetchCommitteeMeetingAttendances
  )
  yield takeEvery(
    GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL,
    fetchCommitteeMeetingAttendanceDetail
  )
  yield takeEvery(
    CREATE_COMMITTEE_MEETING_ATTENDANCE,
    onCreateCommitteeMeetingAttendance
  )
  yield takeEvery(
    UPDATE_COMMITTEE_MEETING_ATTENDANCE,
    onUpdateCommitteeMeetingAttendance
  )
  yield takeEvery(
    DELETE_COMMITTEE_MEETING_ATTENDANCE,
    onDeleteCommitteeMeetingAttendance
  )
}

export default CommitteeMeetingAttendancesSaga
