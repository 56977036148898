import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import {
  getMahallUserDetail,
  updateMahallUser,
  deleteMahallUser,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

const UpdateMahallUser = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { mahallUserDetail, loading, language } = useSelector(state => ({
    mahallUserDetail: state.MahallUsers.mahallUserDetail,
    loading: state.MahallUsers.loading,
    mahallUsers: state.MahallUsers.mahallUsers,
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
  }))

  const [toggleShow1, setToggleShow1] = useState(
    mahallUserDetail?.perm_view || false
  )
  const [toggleShow2, setToggleShow2] = useState(
    mahallUserDetail?.perm_create || false
  )
  const [toggleShow3, setToggleShow3] = useState(
    mahallUserDetail?.perm_update || false
  )
  const [toggleShow4, setToggleShow4] = useState(
    mahallUserDetail?.perm_delete || false
  )

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(deleteMahallUser(mahallUserDetail.id, history))
    setIsOpen(false)
  }

  function handleValidSubmit(values) {
    const updateMahallUserAccountData = {
      full_name: values.full_name,
      phone: values.phone,
      email: values.email,
      // mahall : mahallUserDetail.mahall,
    }
    const permissions = {
      perm_view: toggleShow1,
      perm_create: toggleShow2,
      perm_update: toggleShow3,
      perm_delete: toggleShow4,
      mahall: mahallUserDetail.mahall,
    }
    dispatch(
      updateMahallUser(
        { updateMahallUserAccountData, ...permissions },
        mahallUserDetail.id,
        history
      )
    )
    window.scroll(0, 0)
  }

  useEffect(() => {
    dispatch(getMahallUserDetail(params?.id))
  }, [dispatch, params.id])

  useEffect(() => {
    setToggleShow1(mahallUserDetail?.perm_view)
    setToggleShow2(mahallUserDetail?.perm_create)
    setToggleShow3(mahallUserDetail?.perm_update)
    setToggleShow4(mahallUserDetail?.perm_delete)
  }, [dispatch, params.id, mahallUserDetail])

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <MetaTags>
        <title>Mahall User | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="MahallUsers" breadcrumbItem="Update" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {loading ? (
                      <div
                        className="d-flex justify-contents-center"
                        style={{ height: "100vH" }}
                      >
                        <Spinner color="secondary" className="d-block m-auto" />
                      </div>
                    ) : (
                      <>
                        <AvForm
                          className="form-horizontal "
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(v)
                          }}
                        >
                          <Row>
                            <div className="col-md-6">
                              <div className="col mb-4">
                                <Label
                                  htmlFor="horizontal-username-Input"
                                  className="col-sm-12 col-form-label"
                                >
                                  {language === "mal" ? "പേര്" : "Full Name"}
                                  <span className="text-danger">&#42;</span>
                                </Label>
                                <Col sm={9}>
                                  <AvField
                                    id="horizontal-username-Input"
                                    className="filePathClass"
                                    name="full_name"
                                    type="text"
                                    value={mahallUserDetail?.full_name}
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                    }}
                                  />
                                </Col>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="col mb-4">
                                <Label
                                  htmlFor="horizontal-username-Input"
                                  className="col-sm-12 col-form-label"
                                >
                                  {language === "mal"
                                    ? "ഫോൺ നമ്പർ"
                                    : "Phone Number"}
                                  <span className="text-danger">&#42;</span>
                                </Label>
                                <Col sm={9}>
                                  <AvField
                                    id="horizontal-username-Input"
                                    className="filePathClass mb-2"
                                    name="phone"
                                    readOnly
                                    type="number"
                                    value={mahallUserDetail?.phone}
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                    }}
                                  />
                                </Col>
                                <Link
                                  to="/mahall/mahalluser/updatephone"
                                  className="text mt-2"
                                >
                                  {language === "mal"
                                    ? "ഫോൺ നമ്പർ മാറ്റണോ?"
                                    : "Change Phone Number?"}
                                </Link>
                              </div>
                            </div>
                          </Row>

                          <Row>
                            <div
                              className="col-md-6"
                              style={{ marginTop: "-10px" }}
                            >
                              <div className="col mb-4">
                                <Label
                                  htmlFor="horizontal-username-Input"
                                  className="col-sm-12 col-form-label"
                                >
                                  {language === "mal" ? "ഇമെയിൽ" : "Email"}
                                </Label>
                                <Col sm={9}>
                                  <AvField
                                    id="horizontal-username-Input"
                                    className="filePathClass"
                                    name="email"
                                    type="email"
                                    value={mahallUserDetail?.email}
                                  />
                                </Col>
                              </div>
                            </div>
                            <div
                              className="col-md-6"
                              style={{ marginTop: "-10px" }}
                            >
                              <div className="col mb-4">
                                <Label
                                  htmlFor="horizontal-username-Input"
                                  className="col-sm-12 col-form-label"
                                >
                                  {language === "mal" ? "മഹല്ല്" : "Mahall"}
                                  <span className="text-danger">&#42;</span>
                                </Label>
                                <Col sm={9}>
                                  <AvField
                                    id="horizontal-username-Input"
                                    className="filePathClass"
                                    name="mahall"
                                    readOnly
                                    type="text"
                                    value={
                                      mahallUserDetail?.mahall_english_name
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                      },
                                    }}
                                  />
                                </Col>
                              </div>
                            </div>
                          </Row>

                          <CardTitle className="h4 mb-4 mt-5">
                            {language === "mal" ? "അനുമതികൾ" : "Permissions"}
                          </CardTitle>
                          <h6
                            style={{ marginTop: "-15px", marginBottom: "30px" }}
                          >
                            {language === "mal"
                              ? "ചുവടെയുള്ള ആവശ്യമായ അനുമതികൾ ടിക്ക് ചെയ്യുക"
                              : "Check The Required Permissions Below"}
                          </h6>

                          <Row>
                            <div className="col-md-6">
                              <div className="row mb-4">
                                {/* <Col sm={9}>
                                  <AvField
                                    id="horizontal-lastname-Input"
                                    name="perm_view"
                                    title="Perm view"
                                    type="checkbox"
                                    value={mahallUserDetail?.perm_view}
                                    checked={toggleShow1 ? true : false}
                                    className="mt-1"
                                    onClick={() => setToggleShow1(!toggleShow1)}
                                    readOnly
                                  />
                                </Col>
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-12 col-form-label"
                                  style={{
                                    marginLeft: "30px",
                                    marginTop: "-28px",
                                  }}
                                >
                                  View All Data
                                </Label> */}
                                <Col sm={9}>
                                  <Label className="mx-5">
                                    {language === "mal"
                                      ? "എല്ലാ ഡാറ്റയും കാണുക"
                                      : "View All Data"}
                                  </Label>
                                  <div className="form-check form-switch form-switch-md">
                                    <input
                                      type="checkbox"
                                      className="form-check-input toggle-switch"
                                      id="customSwitchsizemd"
                                      defaultChecked={toggleShow1}
                                      onChange={() => {}}
                                      onClick={() =>
                                        setToggleShow1(!toggleShow1)
                                      }
                                    />
                                  </div>
                                </Col>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row mb-4">
                                {/* <Col sm={9}>
                                  <AvField
                                    id="horizontal-lastname-Input"
                                    name="perm_create"
                                    title="Perm create"
                                    type="checkbox"
                                    value={mahallUserDetail?.perm_create}
                                    checked={toggleShow2 ? true : false}
                                    className="mt-1"
                                    onClick={() => setToggleShow2(!toggleShow2)}
                                    readOnly
                                  />
                                </Col>
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-12 col-form-label"
                                  style={{
                                    marginLeft: "30px",
                                    marginTop: "-28px",
                                  }}
                                >
                                  Add All Data
                                </Label> */}
                                <Col sm={9}>
                                  <Label className="mx-5">
                                    {language === "mal"
                                      ? "എല്ലാ ഡാറ്റയും ചേർക്കുക"
                                      : "Add All Data"}
                                  </Label>
                                  <div className="form-check form-switch form-switch-md">
                                    <input
                                      type="checkbox"
                                      className="form-check-input toggle-switch"
                                      id="customSwitchsizemd"
                                      defaultChecked={toggleShow2}
                                      onChange={() => {}}
                                      onClick={() =>
                                        setToggleShow2(!toggleShow2)
                                      }
                                    />
                                  </div>
                                </Col>
                              </div>
                            </div>
                          </Row>

                          <Row>
                            <div className="col-md-6">
                              <div className="row mb-4">
                                {/* <Col sm={9}>
                                  <AvField
                                    id="horizontal-lastname-Input"
                                    name="perm_update"
                                    title="Perm update"
                                    type="checkbox"
                                    value={mahallUserDetail?.perm_update}
                                    checked={toggleShow3 ? true : false}
                                    className="mt-1"
                                    onClick={() => setToggleShow3(!toggleShow3)}
                                    readOnly
                                  />
                                </Col>
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-12 col-form-label"
                                  style={{
                                    marginLeft: "30px",
                                    marginTop: "-28px",
                                  }}
                                >
                                  Edit All Data
                                </Label> */}
                                <Col sm={9}>
                                  <Label className="mx-5">
                                    {language === "mal"
                                      ? "എല്ലാ ഡാറ്റയും എഡിറ്റ് ചെയ്യുക"
                                      : "Edit All Data"}
                                  </Label>
                                  <div className="form-check form-switch form-switch-md">
                                    <input
                                      type="checkbox"
                                      className="form-check-input toggle-switch"
                                      id="customSwitchsizemd"
                                      defaultChecked={toggleShow3}
                                      onChange={() => {}}
                                      onClick={() =>
                                        setToggleShow3(!toggleShow3)
                                      }
                                    />
                                  </div>
                                </Col>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row mb-4">
                                {/* <Col sm={9}>
                                  <AvField
                                    id="horizontal-lastname-Input"
                                    name="perm_delete"
                                    title="Perm delete"
                                    type="checkbox"
                                    value={mahallUserDetail?.perm_delete}
                                    checked={toggleShow4 ? true : false}
                                    className="mt-1"
                                    onClick={() => setToggleShow4(!toggleShow4)}
                                    readOnly
                                  />
                                </Col>
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-12 col-form-label"
                                  style={{
                                    marginLeft: "30px",
                                    marginTop: "-28px",
                                  }}
                                >
                                  Delete All Data
                                </Label> */}
                                <Col sm={9}>
                                  <Label className="mx-5">
                                    {language === "mal"
                                      ? "എല്ലാ ഡാറ്റയും ഡിലീറ്റ് ചെയ്യുക"
                                      : "Delete All Data"}
                                  </Label>
                                  <div className="form-check form-switch form-switch-md">
                                    <input
                                      type="checkbox"
                                      className="form-check-input toggle-switch"
                                      id="customSwitchsizemd"
                                      defaultChecked={toggleShow4}
                                      onChange={() => {}}
                                      onClick={() =>
                                        setToggleShow4(!toggleShow4)
                                      }
                                    />
                                  </div>
                                </Col>
                              </div>
                            </div>
                          </Row>

                          <Row className="mt-5">
                            <div className="col-sm-6">
                              <div className="row justify-content-start">
                                <Col sm={9}>
                                  <div>
                                    <Button
                                      type="button"
                                      color="danger"
                                      className="w-md"
                                      onClick={handleDelete}
                                    >
                                      Delete User
                                    </Button>
                                  </div>
                                </Col>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="row justify-content-end">
                                <Col sm={9}>
                                  <div>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="w-md m-auto me-0 d-block"
                                    >
                                      Update
                                    </Button>
                                  </div>
                                </Col>
                              </div>
                            </div>
                          </Row>
                        </AvForm>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateMahallUser

UpdateMahallUser.propTypes = {
  history: PropTypes.object,
}
