import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"

import { getInstitutes, getInstitutesCards } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"

const AllMahallProgrammes = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { institutes, loading, language } = useSelector(state => ({
    institutes: state.Institutes.institutes,
    loading: state.Institutes.loading,
    language: state.Layout.language,
    institutesCards: state.Institutes.institutesCards,
  }))

  //pages
  const totalPages = Math.ceil(institutes?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const instituteType = "program"

  useEffect(() => {
    dispatch(getInstitutes(searchText, pageSend(), instituteType))
  }, [dispatch, page, searchText, instituteType])

  useEffect(() => {
    dispatch(getInstitutesCards())
  }, [dispatch])

  const cardData = [
    {
      title: `${language === "mal" ? "പ്രോഗ്രാമുകൾ" : "Programs"}`,
      cardValue: institutes?.results?.total_institutes,
      iconClass: "grid-alt",
      routeLink: "/programes",
      loading: loading,
      alt: `${language === "mal" ? "പ്രോഗ്രാമുകൾ ഇല്ല" : "No Programs"}`,
    },
    {
      title: `${language === "mal" ? "യൂസറുകൾ" : "Users"}`,
      cardValue: institutes?.results?.total_institute_users,
      iconClass: "group",
      routeLink: "/#!",
      loading: loading,
      alt: `${language === "mal" ? "യൂസറുകൾ ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "ലെഡ്ജറുകൾ" : "Ledgers"}`,
      cardValue: institutes?.results?.total_institute_ledgers,
      iconClass: "receipt",
      routeLink: "/#!",
      loading: loading,
      alt: `${language === "mal" ? "ലെഡ്ജറുകൾ ഇല്ല" : "No Ledgers"}`,
    },
    // {
    //   title: `${language === "mal" ? "അക്കൗണ്ടുകൾ" : "Accounts"}`,
    //   cardValue: `${institutesCards?.total_institute_accounts}`,
    //   iconClass: "show",
    //   routeLink: "/#!",
    //   loading: loading,
    //   alt: `${language === "mal" ? "അക്കൗണ്ടുകൾ ഇല്ല" : "No Accounts"}`,
    // },
  ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "സീരിയൽ നമ്പർ." : "Serial No."}`,
      sort: true,
    },
    {
      dataField: "institute_english_name",
      text: `${language === "mal" ? "പ്രോഗ്രാമിന്റെ പേര്" : "Programme Name"}`,
      sort: true,
    },
    {
      dataField: "institute_place",
      text: `${language === "mal" ? "സ്ഥലം" : "Place"}`,
    },
    {
      dataField: "established_date",
      text: `${language === "mal" ? "ചേർന്ന തീയതി" : "Join Date"}`,
    },
    // {
    //   dataField: "constituency_name",
    //   text: "Constituency",
    // },
    // {
    //   dataField: "district_english_name",
    //   text: "District",
    // },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const institutesData = map(
    institutes?.results?.institutes,
    (item, index) => ({
      ...item,
      key: index,
      no: index + 1,
      established_date:
        item.established_date &&
        moment(item.established_date).format("DD-MM-yyyy"),
      institute_english_name: `${
        language === "mal"
          ? item?.institute_malayalam_name
            ? item?.institute_malayalam_name
            : item?.institute_english_name
          : item?.institute_english_name
      }`,

      action: (
        <div>
          <Link
            to={`/institutes/${item?.id}`}
            className="btn-success w-xs btn-sm"
          >
            {language === "mal" ? "കാണുക" : "View"}
          </Link>
        </div>
      ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  const debounceInstituteSearch = debounce(value => setSearchText(value), 600)

  return (
    <React.Fragment>
      <Row>
        {cardData?.map((item, key) => (
          <Col sm="12" md="6" lg="4" key={key} className="sm:p-0">
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink && item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {/* {item.cardValue > 0 && (
                        )} */}
                      <h5 className="mb-0">
                        {item.cardValue && item.cardValue}
                      </h5>
                      {/* <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p> */}
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
        <div className="col-md-6 mb-3">
          <Link
            to={`/program/create?type=program`}
            type="submit"
            style={{ width: language === "mal" ? "200px" : "150px" }}
            className="w-md btn btn-success d-block"
          >
            {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New Programme"}
          </Link>
        </div>
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={institutesData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceInstituteSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {institutes &&
                      institutes?.results &&
                      institutes?.results?.institutes?.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          {language === "mal"
                            ? "പ്രോഗ്രാമുകൾ ഒന്നും ഇല്ല"
                            : "No Programs Yet"}
                        </p>
                      )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllMahallProgrammes
