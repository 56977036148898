import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import Select from "react-select"
import { Col, Modal, Button, Label } from "reactstrap"
import moment from "moment"

import {
  createCommitteeMeetingAttendance,
  getCommitteeMemberDropdown,
} from "store/actions"

const UiModalMeetingAttendance = ({ show, onCloseclick, history }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const { loading, language, searchCommitteeMembers, committeeMeetingDetail } =
    useSelector(state => ({
      loading: state.Mahalls.loading,
      language: state.Layout.language,
      searchCommitteeMembers: state.CommitteeMembers.searchCommitteeMembers,
      committeeMeetingDetail: state.CommitteeMeetings.committeeMeetingDetail,
    }))

  const now = moment().format("HH:mm")
  console.log("time: ", now)

  const [searchText, setSearchText] = useState("")
  const [member, setMember] = useState("search member")
  const [memberId, setMemberId] = useState("")

  useEffect(() => {
    if (committeeMeetingDetail?.committee) {
      dispatch(
        getCommitteeMemberDropdown(
          committeeMeetingDetail?.committee,
          searchText
        )
      )
    }
  }, [dispatch, committeeMeetingDetail?.committee, searchText])

  function handlerFinalValue(event) {
    // dispatch(getCommitteeMemberDropdown(event.value))
    setMemberId(event.value)
    setMember(event.label)
  }

  useEffect(() => {
    setMember("search member")
  })

  const optionGroup = [
    {
      options: searchCommitteeMembers?.map((results, index) => ({
        key: index,
        label: results.full_name,
        value: results.id,
      })),
    },
  ]

  // const debounceMemberSearch = debounce(value => setSearchText(value), 600)

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const createMeetingAttendanceData = {
      committee_meeting: committeeMeetingDetail?.id,
      committee_member: memberId,
      attendance_time: values.attendance_time,
      description: values.description,
    }
    // console.log(createMeetingAttendanceData);
    dispatch(
      createCommitteeMeetingAttendance(createMeetingAttendanceData, history)
    )
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "ഹാജർ ചേർക്കുക" : "Add Attendance"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col md="4">
                  <Label>{language === "mal" ? "സമയം" : "Time"}</Label>
                  <AvField
                    name="attendance_time"
                    type="time"
                    value={now}
                    helpMessage="24 Hr format"
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "അംഗം" : "Member"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={member}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="CommitteeMember"
                    required="required"
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    label={language === "mal" ? "വിവരണം" : "Note"}
                  />
                </Col>
              </div>
              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Add"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalMeetingAttendance
UiModalMeetingAttendance.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
