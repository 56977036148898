import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import { getIndividualNotifications } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"

const AllIndividualNotifications = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { individualNotifications, loading, language } = useSelector(state => ({
    individualNotifications: state.Notifications.individualNotifications,
    loading: state.Mahalls.loading,
    mahallsCards: state.Mahalls.mahallsCards,
    language: state.Layout.language,
  }))

  //pages
  const totalPages = Math.ceil(individualNotifications?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getIndividualNotifications(searchText, pageSend()))
  }, [dispatch, page, searchText])

  // useEffect(() => {
  //   dispatch(getMahallsCards())
  // }, [dispatch])

  // const cardData = [
  //   {
  //     title: `${language === 'mal' ? 'ആകെ മഹല്ലുകൾ' : 'Total Mahalls'}`,
  //     cardValue: mahallsCards?.total_mahalls,
  //     iconClass: 'map-alt',
  //     routeLink: '/mahalls',
  //     loading: loading,
  //     // alt: `${language === 'mal' ? "മഹല്ലുകൾ ഇല്ല" : "No Mahalls"}`,
  //   },
  //   // {
  //   //   title: `${language === "mal" ? "ആകെ കുടുംബങ്ങൾ" : "Total Families"}`,
  //   //   cardValue: mahallsCards?.total_families,
  //   //   iconClass: "home",
  //   //   routeLink: "/#!",
  //   //   loading: loading,
  //   //   // alt: `${language === 'mal' ? "കുടുംബങ്ങൾ ഇല്ല" : "No Families"}`,
  //   // },
  //   // {
  //   //   title: `${language === "mal" ? "ആകെ കുടുംബാംഗങ്ങൾ" : "Total Members"}`,
  //   //   cardValue: mahallsCards?.total_family_members,
  //   //   iconClass: "user",
  //   //   routeLink: "/familymembers",
  //   //   loading: loading,
  //   //   // alt: `${language === 'mal' ? "കുടുംബാംഗങ്ങൾ ഇല്ല" : "No Family Members"}`,
  //   // },
  // ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    // {
    //   dataField: "datetime",
    //   text: `${language === "mal" ? "തീയതി" : "Date"}`,
    // },
    {
      dataField: "title",
      text: `${language === "mal" ? "ടൈറ്റിൽ" : "Title"}`,
    },
    {
      dataField: "sender",
      text: `${language === "mal" ? "നിന്ന് " : "From"}`,
    },
    {
      dataField: "family_member_name",
      text: `${language === "mal" ? "ലേക്ക്" : "To"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]

  const IndividualNotificationsData = map(
    individualNotifications?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: index + 1,
      // datetime: (item?.datetime && moment(item?.datetime).format("DD/MM/YYYY - h:mm A")),
      action: (
        <div>
          <Link
            to={`/notification/update/${item?.id}`}
            className="btn-success w-xs btn-sm"
          >
            {language === "mal" ? "കാണുക" : "View"}
          </Link>
          {/* <Link to={`/mahall/notifications/${item?.id}`} className="btn-success w-xs btn-sm">
          {language === "mal" ? "കാണുക" : "View"}
        </Link> */}
        </div>
      ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceIndividualNotificationSearch = debounce(
    value => setSearchText(value),
    600
  )

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        {/* {cardData?.map((item, key) => (
          <Col lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p>
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))} */}
        <Row>
          <div className="col-md-12 d-flex justify-content-end">
            <Link
              to={`/notification/create`}
              type="button"
              style={{ width: language === "mal" ? "200px" : "200px" }}
              className="w-md btn btn-success d-block"
            >
              {language === "mal"
                ? "+ പുതിയത് ചേർക്കുക"
                : "+ Create Notification"}
            </Link>
          </div>
        </Row>
        <Col className="col-12 mt-2">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={IndividualNotificationsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceIndividualNotificationSearch(
                                  e.target.value
                                )
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {individualNotifications &&
                      individualNotifications?.results &&
                      individualNotifications?.results?.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          {language === "mal"
                            ? "അറിയിപ്പുകൾ ഒന്നും ഇല്ല"
                            : "No Notifications Yet"}
                        </p>
                      )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllIndividualNotifications
