import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ASSET_ITEMS,
  GET_ASSET_ITEM_DETAIL,
  CREATE_ASSET_ITEM,
  UPDATE_ASSET_ITEM,
  DELETE_ASSET_ITEM,
} from "./actionTypes"
import {
  getAssetItemsSuccess,
  getAssetItemsFail,
  getAssetItemDetailSuccess,
  getAssetItemDetailFail,
  createAssetItemSuccess,
  createAssetItemFail,
  updateAssetItemSuccess,
  updateAssetItemFail,
  deleteAssetItemSuccess,
  deleteAssetItemFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getAssetItemsAPi = ({ assetId, searchText, page }) => {
  if (assetId) {
    return get(
      `/api/v1/institute-asset/asset-item/?institute_asset=${
        assetId ? assetId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : ""}`
    )
  } else {
    return get(
      `/api/v1/institute-asset/asset-item/?search=${
        searchText ? searchText : ""
      }&page=${page ? page : 1}`
    )
  }
}
// const getVarisangyasCardsAPi = () => {
//   return get(`/api/v1/varisankya/varisankya/?cards=all`)
// }
const getAssetItemDetailsAPi = assetItemId => {
  return get(`/api/v1/institute-asset/asset-item/${assetItemId}/`)
}
const createAssetItemApi = ({ assetItem }) => {
  return post("/api/v1/institute-asset/asset-item/", assetItem)
}
const updateAssetItemApi = ({ assetItemId, assetItem }) => {
  return ApiPut(`/api/v1/institute-asset/asset-item/${assetItemId}/`, assetItem)
}
const deleteAssetItemApi = ({ assetItemId }) => {
  return del(`/api/v1/institute-asset/asset-item/${assetItemId}/`)
}

function* fetchAssetItems({ payload }) {
  try {
    const response = yield call(getAssetItemsAPi, payload)
    if (response && !response?.error) {
      yield put(getAssetItemsSuccess(response))
    } else {
      yield put(getAssetItemsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAssetItemsFail(error))
  }
}

// function* fetchVarisangyasCards() {
//   try {
//     const response = yield call(getVarisangyasCardsAPi)
//     if (response && !response?.error) {
//       yield put(getVarisangyaCardsSuccess(response))
//     } else {
//       yield put(getVarisangyaCardsFail(response))
//       errorNotification()
//     }
//   } catch (error) {
//     yield put(getVarisangyaCardsFail(error))
//   }
// }

function* fetchAssetItemDetail({ assetItemId }) {
  try {
    const response = yield call(getAssetItemDetailsAPi, assetItemId)
    if (response && !response?.error) {
      yield put(getAssetItemDetailSuccess(response))
    } else {
      yield put(getAssetItemDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAssetItemDetailFail(error))
  }
}
function* onCreateAssetItem({ payload }) {
  try {
    const response = yield call(createAssetItemApi, payload)
    if (response && !response?.error) {
      yield put(createAssetItemSuccess(response))
      if (payload.history) {
        payload.history.push("/varisangyas")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createAssetItemFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createAssetItemFail(error))
    errorNotification()
  }
}

function* onUpdateAssetItem({ payload }) {
  try {
    const response = yield call(updateAssetItemApi, payload)
    if (response && !response?.error) {
      payload.history.push("/varisangyas")
      yield put(updateAssetItemSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateAssetItemFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateAssetItemFail(error))
    errorNotification()
  }
}

function* onDeleteAssetItem({ payload }) {
  try {
    const response = yield call(deleteAssetItemApi, payload)
    if (response && !response?.error) {
      yield put(deleteAssetItemSuccess(response))
      doneNotification("Deleted Successfully")
      payload.history.push("/assetItems")
    } else {
      yield put(deleteAssetItemFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteAssetItemFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* AssetItemsSaga() {
  yield takeEvery(GET_ASSET_ITEMS, fetchAssetItems)
  // yield takeEvery(GET_VARISANGYA_CARDS, fetchVarisangyasCards)
  yield takeEvery(GET_ASSET_ITEM_DETAIL, fetchAssetItemDetail)
  yield takeEvery(CREATE_ASSET_ITEM, onCreateAssetItem)
  yield takeEvery(UPDATE_ASSET_ITEM, onUpdateAssetItem)
  yield takeEvery(DELETE_ASSET_ITEM, onDeleteAssetItem)
}

export default AssetItemsSaga
