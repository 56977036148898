import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { MetaTags } from "react-meta-tags"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"

//actions
import {
  createMahallUser,
  getMahallDropdown,
  checkUserPhoneNumber,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateMahallUser = ({ history }) => {
  const dispatch = useDispatch()

  const [toggleShow1, setToggleShow1] = useState(false)
  const [toggleShow2, setToggleShow2] = useState(false)
  const [toggleShow3, setToggleShow3] = useState(false)
  const [toggleShow4, setToggleShow4] = useState(false)

  const {
    loading,
    error,
    searchMahalls,
    // mahallUserDetail,
    userPhoneNumberAvailability,
    checkLoading,
    language,
    // userDetail,
  } = useSelector(state => ({
    loading: state.MahallUsers.loading,
    error: state.MahallUsers.error,
    searchMahalls: state.Mahalls.searchMahalls,
    mahallUserDetail: state.MahallUsers.mahallUserDetail,
    userPhoneNumberAvailability: state.Users.userPhoneNumberAvailability,
    checkLoading: state.Users.checkLoading,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
  }))

  const [mahall, setMahall] = useState("Search Mahall")
  const [mahallId, setMahallId] = useState("")
  const [searchText, setSearchText] = useState("")

  const [checkValue, setCheckValue] = useState()

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const CreateMahallUserData = {
      mahall: mahallId,
      full_name: values.full_name,
      phone: values.phone,
      email: values.email,
      perm_view: toggleShow1,
      perm_create: toggleShow2,
      perm_update: toggleShow3,
      perm_delete: toggleShow4,
    }
    if (!userPhoneNumberAvailability) return
    dispatch(createMahallUser(CreateMahallUserData, history))
  }

  useEffect(() => {
    dispatch(getMahallDropdown(searchText))
  }, [dispatch, searchText])

  function handlerFinalValue(event) {
    dispatch(getMahallDropdown(event.value))
    setMahallId(event.value)
    setMahall(event.label)
  }

  const Options = [
    {
      options: searchMahalls?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results.mahall_malayalam_name
              ? results.mahall_malayalam_name
              : results.mahall_english_name
            : results.mahall_english_name,
        value: results.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const handleCheckUserPhoneNumberAvailability = v => {
    if (v.length == 10) {
      const checkUserPhoneNumberData = {
        phone: v,
      }
      dispatch(checkUserPhoneNumber(checkUserPhoneNumberData))
      setCheckValue(v)
    }
    // console.log("phone: ", v)
  }

  const mailError = error[0]

  return (
    <>
      <MetaTags>
        <title>Mahall Users | Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "മഹല്ല്  യൂസറുകൾ" : "Mahall Users"}
          breadcrumbItem={language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4 mb-4 mt-1">
                      Basic Information
                    </CardTitle> */}
                    {/* <h6 style={{ marginTop: "-15px", marginBottom: "30px" }}>
                      Fill All Information Below
                    </h6> */}

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Full Name"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="full_name"
                                type="text"
                                title="Full name"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </Col>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "ഫോൺ നമ്പർ"
                                : "Phone Number"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="phone"
                                type="number"
                                validate={{
                                  required: { value: true },
                                  minLength: {
                                    value: 10,
                                    errorMessage:
                                      "phone number must be 10 digits",
                                  },
                                  maxLength: {
                                    value: 10,
                                    errorMessage:
                                      "phone number must be 10 digits",
                                  },
                                }}
                                onChange={v => {
                                  handleCheckUserPhoneNumberAvailability(
                                    v.target.value
                                  )
                                  setCheckValue(v.target.value)
                                }}
                              />
                            </Col>
                            {checkValue &&
                              checkValue?.length == 10 &&
                              (!checkLoading ? (
                                userPhoneNumberAvailability ? (
                                  <p className="text-success">Available</p>
                                ) : (
                                  <p className="text-danger">Not Available</p>
                                )
                              ) : (
                                <></>
                              ))}
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div
                          className="col-md-6"
                          style={{ marginTop: "-15px" }}
                        >
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "ഇമെയിൽ" : "Email"}
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="email"
                                type="email"
                                title="Email"
                                // validate={{
                                //   required: { value: true },
                                // }}
                              />
                            </Col>
                          </div>
                        </div>
                        <div
                          className="col-md-6"
                          style={{ marginTop: "-15px" }}
                        >
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "മഹല്ല്" : "Mahall"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <Select
                                onInputChange={handleEnters}
                                placeholder={mahall}
                                value={mahall}
                                options={Options}
                                classNamePrefix="select2-selection"
                                onChange={handlerFinalValue}
                                type="text"
                                // style={{ borderRadius: "8px", height: "40px" }}
                                title="Mahall"
                                required="required"
                              />
                            </Col>
                          </div>
                        </div>
                      </Row>

                      <CardTitle className="h4 mb-4 mt-5">
                        {language === "mal" ? "അനുമതികൾ" : "Permissions"}
                      </CardTitle>
                      <h6 style={{ marginTop: "-15px", marginBottom: "30px" }}>
                        {language === "mal"
                          ? "ചുവടെയുള്ള ആവശ്യമായ അനുമതികൾ ടിക്ക് ചെയ്യുക"
                          : "Check The Required Permissions Below"}
                      </h6>

                      <Row>
                        <div className="col-md-6">
                          <div className="row mb-4">
                            {/* <Col sm={9}>
                              <AvField
                                id="horizontal-lastname-Input"
                                name="perm_view"
                                title="Perm view"
                                type="checkbox"
                                checked={toggleShow1 ? true : false}
                                className="mt-1"
                                onClick={() => setToggleShow1(!toggleShow1)}
                                readOnly
                              />
                            </Col>
                            <Label
                              htmlFor="horizontal-lastname-Input"
                              className="col-sm-12 col-form-label"
                              style={{ marginLeft: "30px", marginTop: "-28px" }}
                            >
                              View All Data
                            </Label> */}
                            <Col sm={9}>
                              <Label className="mx-5">
                                {language === "mal"
                                  ? "എല്ലാ ഡാറ്റയും കാണുക"
                                  : "View All Data"}
                              </Label>
                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input toggle-switch"
                                  id="customSwitchsizemd"
                                  checked={toggleShow1}
                                  onChange={() => {}}
                                  onClick={() => setToggleShow1(!toggleShow1)}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row mb-4">
                            {/* <Col sm={9}>
                              <AvField
                                id="horizontal-lastname-Input"
                                name="perm_create"
                                title="Perm create"
                                type="checkbox"
                                checked={toggleShow2 ? true : false}
                                className="mt-1"
                                onClick={() => setToggleShow2(!toggleShow2)}
                                readOnly
                              />
                            </Col>
                            <Label
                              htmlFor="horizontal-lastname-Input"
                              className="col-sm-12 col-form-label"
                              style={{ marginLeft: "30px", marginTop: "-28px" }}
                            >
                              Add All Data
                            </Label> */}
                            <Col sm={9}>
                              <Label className="mx-5">
                                {language === "mal"
                                  ? "എല്ലാ ഡാറ്റയും ചേർക്കുക"
                                  : "Add All Data"}
                              </Label>
                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input toggle-switch"
                                  id="customSwitchsizemd"
                                  checked={toggleShow2}
                                  onChange={() => {}}
                                  onClick={() => setToggleShow2(!toggleShow2)}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-6">
                          <div className="row mb-4">
                            {/* <Col sm={9}>
                              <AvField
                                id="horizontal-lastname-Input"
                                name="perm_update"
                                title="Perm update"
                                type="checkbox"
                                checked={toggleShow3 ? true : false}
                                className="mt-1"
                                onClick={() => setToggleShow3(!toggleShow3)}
                                readOnly
                              />
                            </Col>
                            <Label
                              htmlFor="horizontal-lastname-Input"
                              className="col-sm-12 col-form-label"
                              style={{ marginLeft: "30px", marginTop: "-28px" }}
                            >
                              Edit All Data
                            </Label> */}
                            <Col sm={9}>
                              <Label className="mx-5">
                                {language === "mal"
                                  ? "എല്ലാ ഡാറ്റയും എഡിറ്റ് ചെയ്യുക"
                                  : "Edit All Data"}
                              </Label>
                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input toggle-switch"
                                  id="customSwitchsizemd"
                                  checked={toggleShow3}
                                  onChange={() => {}}
                                  onClick={() => setToggleShow3(!toggleShow3)}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row mb-4">
                            {/* <Col sm={9}>
                              <AvField
                                id="horizontal-lastname-Input"
                                name="perm_delete"
                                title="Perm delete"
                                type="checkbox"
                                checked={toggleShow4 ? true : false}
                                className="mt-1"
                                onClick={() => setToggleShow4(!toggleShow4)}
                                readOnly
                              />
                            </Col>
                            <Label
                              htmlFor="horizontal-lastname-Input"
                              className="col-sm-12 col-form-label"
                              style={{ marginLeft: "30px", marginTop: "-28px" }}
                            >
                              Delete All Data
                            </Label> */}
                            <Col sm={9}>
                              <Label className="mx-5">
                                {language === "mal"
                                  ? "എല്ലാ ഡാറ്റയും ഡിലീറ്റ് ചെയ്യുക"
                                  : "Delete All Data"}
                              </Label>
                              <div className="form-check form-switch form-switch-md">
                                <input
                                  type="checkbox"
                                  className="form-check-input toggle-switch"
                                  id="customSwitchsizemd"
                                  checked={toggleShow4}
                                  onChange={() => {}}
                                  onClick={() => setToggleShow4(!toggleShow4)}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                      </Row>

                      <div className="row justify-content-end mt-5">
                        <Col sm={9}>
                          <div>
                            {mailError === undefined ? (
                              ""
                            ) : (
                              <Alert color="danger">{error}</Alert>
                            )}

                            <Button
                              type="submit"
                              color="primary"
                              className="w-md m-auto me-0 d-block"
                              disabled={!userPhoneNumberAvailability}
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create User
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateMahallUser

CreateMahallUser.propTypes = {
  history: PropTypes.object,
}
