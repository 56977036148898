import {
  GET_STUDENT_VARISANGYAS,
  GET_STUDENT_VARISANGYA_CARDS,
  UPDATE_STUDENT_VARISANGYA,
  CREATE_STUDENT_VARISANGYA,
  DELETE_STUDENT_VARISANGYA,
  GET_STUDENT_VARISANGYA_DETAIL,
  GET_STUDENT_VARISANGYAS_SUCCESS,
  GET_STUDENT_VARISANGYAS_FAIL,
  GET_STUDENT_VARISANGYA_CARDS_SUCCESS,
  GET_STUDENT_VARISANGYA_CARDS_FAIL,
  CREATE_STUDENT_VARISANGYA_SUCCESS,
  CREATE_STUDENT_VARISANGYA_FAIL,
  GET_STUDENT_VARISANGYA_DETAIL_SUCCESS,
  GET_STUDENT_VARISANGYA_DETAIL_FAIL,
  UPDATE_STUDENT_VARISANGYA_SUCCESS,
  UPDATE_STUDENT_VARISANGYA_FAIL,
  DELETE_STUDENT_VARISANGYA_SUCCESS,
  DELETE_STUDENT_VARISANGYA_FAIL,
  GET_STUDENT_VARISANGYA_ITEMS,
  GET_STUDENT_VARISANGYA_ITEMS_SUCCESS,
  GET_STUDENT_VARISANGYA_ITEMS_FAIL,
  AUTO_GENERATE_STUDENT_VARISANGYAS,
  AUTO_GENERATE_STUDENT_VARISANGYAS_SUCCESS,
  AUTO_GENERATE_STUDENT_VARISANGYAS_FAIL,
  REFRESH_STUDENT_VARISANGYA,
  REFRESH_STUDENT_VARISANGYA_SUCCESS,
  REFRESH_STUDENT_VARISANGYA_FAIL,
  UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER,
  UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
  UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  studentVarisangyas: [],
  studentVarisangyaItems: [],
  studentVarisangyaCards: {},
  studentVarisangyaDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  // autoGenerateLoading: false,
  refreshLoading: false,
  createdStudentVarisangya: {},
}

const StudentVarisangyas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STUDENT_VARISANGYAS:
    case GET_STUDENT_VARISANGYA_CARDS:
    case GET_STUDENT_VARISANGYA_ITEMS:
    case UPDATE_STUDENT_VARISANGYA:
    case CREATE_STUDENT_VARISANGYA:
    case DELETE_STUDENT_VARISANGYA:
    case UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER:
      return {
        ...state,
        loading: true,
      }
    case GET_STUDENT_VARISANGYA_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case AUTO_GENERATE_STUDENT_VARISANGYAS:
      return {
        ...state,
        loading: true,
      }
    case REFRESH_STUDENT_VARISANGYA:
      return {
        ...state,
        refreshLoading: true,
      }

    case GET_STUDENT_VARISANGYAS_SUCCESS:
      return {
        ...state,
        studentVarisangyas: action.payload,
        loading: false,
      }
    case GET_STUDENT_VARISANGYAS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STUDENT_VARISANGYA_ITEMS_SUCCESS:
      return {
        ...state,
        studentVarisangyaItems: action.payload,
        loading: false,
      }
    case GET_STUDENT_VARISANGYA_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STUDENT_VARISANGYA_CARDS_SUCCESS:
      return {
        ...state,
        studentVarisangyaCards: action.payload,
        loading: false,
      }
    case GET_STUDENT_VARISANGYA_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_STUDENT_VARISANGYA_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        studentVarisangyas: {
          ...state.studentVarisangyas,
          results: [...state.studentVarisangyas.results, action.payload],
        },
        createdStudentVarisangya: action.payload,
        loading: false,
      }

    case CREATE_STUDENT_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STUDENT_VARISANGYA_DETAIL_SUCCESS:
      return {
        ...state,
        studentVarisangyaDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_STUDENT_VARISANGYA_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_STUDENT_VARISANGYA_SUCCESS:
      return {
        ...state,
        // studentVarisangyaDetail: action.payload,
        studentVarisangyas: {
          results: state.studentVarisangyas.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_STUDENT_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_STUDENT_VARISANGYA_SUCCESS:
      return {
        ...state,
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        studentVarisangyas: {
          results: state.studentVarisangyas.results.filter(
            studentVarisangya => studentVarisangya.id !== action.payload
          ),
        },
        loading: false,
      }

    case DELETE_STUDENT_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case AUTO_GENERATE_STUDENT_VARISANGYAS_SUCCESS:
      return {
        ...state,
        // studentVarisangyas: action.payload,
        loading: false,
      }
    case AUTO_GENERATE_STUDENT_VARISANGYAS_FAIL:
      return {
        ...state,
        // error: action.payload,
        loading: false,
      }

    case REFRESH_STUDENT_VARISANGYA_SUCCESS:
      return {
        ...state,
        studentVarisangyaItems: action.payload,
        refreshLoading: false,
      }
    case REFRESH_STUDENT_VARISANGYA_FAIL:
      return {
        ...state,
        // error: action.payload,
        refreshLoading: false,
      }

    case UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_FAIL:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export default StudentVarisangyas
