import {
  CREATE_SUPPORT_TICKET,
  CREATE_SUPPORT_TICKET_FAIL,
  CREATE_SUPPORT_TICKET_ITEM,
  CREATE_SUPPORT_TICKET_ITEM_FAIL,
  CREATE_SUPPORT_TICKET_ITEM_SUCCESS,
  CREATE_SUPPORT_TICKET_SUCCESS,
  GET_SUPPORT_TICKETS,
  GET_SUPPORT_TICKETS_FAIL,
  GET_SUPPORT_TICKETS_SUCCESS,
  GET_SUPPORT_TICKET_DETAILS,
  GET_SUPPORT_TICKET_DETAILS_FAIL,
  GET_SUPPORT_TICKET_DETAILS_SUCCESS,
  GET_SUPPORT_TICKET_ITEM,
  GET_SUPPORT_TICKET_ITEM_FAIL,
  GET_SUPPORT_TICKET_ITEM_SUCCESS,
  UPDATE_SUPPORT_TICKET,
  UPDATE_SUPPORT_TICKET_FAIL,
  UPDATE_SUPPORT_TICKET_SUCCESS,
} from "./actionTypes"

export const createSupportTicket = () => ({
  type: CREATE_SUPPORT_TICKET,
  payload: "",
})
export const createSupportTicketSuccess = () => ({
  type: CREATE_SUPPORT_TICKET_SUCCESS,
  payload: "",
})
export const createSupportTicketFail = error => ({
  type: CREATE_SUPPORT_TICKET_FAIL,
  payload: error,
})

// update

export const updateSupportTicket = (supportTicketId, updateValues) => ({
  type: UPDATE_SUPPORT_TICKET,
  payload: { supportTicketId, updateValues },
})
export const updateSupportTicketSuccess = () => ({
  type: UPDATE_SUPPORT_TICKET_SUCCESS,
  payload: "",
})
export const updateSupportTicketFail = error => ({
  type: UPDATE_SUPPORT_TICKET_FAIL,
  payload: error,
})
// LIST

export const getSupportTickets = (search, page) => ({
  type: GET_SUPPORT_TICKETS,
  payload: { search, page },
})
export const getSupportTicketsSuccess = allSupportTicket => ({
  type: GET_SUPPORT_TICKETS_SUCCESS,
  payload: allSupportTicket,
})
export const getSupportTicketsFail = error => ({
  type: GET_SUPPORT_TICKETS_FAIL,
  payload: error,
})

export const getSupportTicketDetails = supportTicketId => ({
  type: GET_SUPPORT_TICKET_DETAILS,
  payload: supportTicketId,
})
export const getSupportTicketDetailsSuccess = supportDetails => ({
  type: GET_SUPPORT_TICKET_DETAILS_SUCCESS,
  payload: supportDetails,
})
export const getSupportTicketDetailsFail = error => ({
  type: GET_SUPPORT_TICKET_DETAILS_FAIL,
  payload: error,
})

// support ticket item get

export const getSupportTicketItem = supportTicketId => ({
  type: GET_SUPPORT_TICKET_ITEM,
  payload: supportTicketId,
})

export const getSupportTicketItemSuccess = supportTicketItem => ({
  type: GET_SUPPORT_TICKET_ITEM_SUCCESS,
  payload: supportTicketItem,
})

export const getSupportTicketItemFail = error => ({
  type: GET_SUPPORT_TICKET_ITEM_FAIL,
  payload: error,
})

// create

export const createSupportTicketItem = message => (
  console.log(message),
  {
    type: CREATE_SUPPORT_TICKET_ITEM,
    payload: message,
  }
)
export const createSupportTicketItemSuccess = supportTicketItem => ({
  type: CREATE_SUPPORT_TICKET_ITEM_SUCCESS,
  payload: supportTicketItem,
})

export const createSupportTicketItemFail = error => ({
  type: CREATE_SUPPORT_TICKET_ITEM_FAIL,
  payload: error,
})
