import React from "react"
import { Row, Col, Card, CardBody, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import moment from "moment"

import user1 from "assets/images/logo/mahalluLogo.png"

const WelcomeComp = () => {
  const { language, instituteDetail } = useSelector(state => ({
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    instituteDetail: state.Institutes.instituteDetail,
  }))

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="8">
              <div className="text-primary p-4">
                <div className="d-flex justify-content-start align-items-center">
                  <h5 className="text-primary">
                    {language === "mal"
                      ? instituteDetail?.institute_malayalam_name
                        ? instituteDetail?.institute_malayalam_name
                        : instituteDetail?.institute_english_name
                      : instituteDetail?.institute_english_name}
                  </h5>
                  <Badge className="badge-soft-secondary mx-4">
                    {instituteDetail?.institute_type}
                  </Badge>
                </div>
                <h6 className="text-primary">
                  {instituteDetail?.institute_place}
                </h6>
                <p>
                  {language === "mal" ? "" : "Since "}{" "}
                  {moment(instituteDetail?.established_date).format(
                    "DD/MM/yyyy"
                  )}{" "}
                  {language === "mal" ? " മുതൽ" : ""}
                </p>
              </div>
            </Col>
            {/* <Col xs="4" className="align-self-center">
              <img src={instituteDetail?.logo ? instituteDetail?.logo : user1} alt="profileImg" className="img-fluid" />
            </Col> */}
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <div className="avatar-md profile-user-wid">
                  <img
                    src={instituteDetail?.logo ? instituteDetail?.logo : user1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
              </div>
            </Col>

            <Col sm="8">
              <p className="text-muted mt-1">
                {instituteDetail?.description
                  ? instituteDetail?.description
                  : ""}
              </p>
            </Col>
            <div className="d-flex justify-content-end">
              <Link
                to={`/institute/update/${instituteDetail?.id}`}
                className="m-0 btn btn-primary btn-sm "
              >
                Update
                {/* <i className="bx bx-edit me-2" style={{ color: "blue", fontSize: "20px" }} /> */}
              </Link>
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
