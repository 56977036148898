import React from "react"
import { Container } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useSelector } from "react-redux"

import DatatableTables from "./DatatableTables"

import Breadcrumbs from "components/Common/Breadcrumb"

function MahallCommittee() {
  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))
  return (
    <>
      <MetaTags>
        <title>All Committees | Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഡാഷ്ബോർഡ്" : "Dashboard"}
          breadcrumbItem={
            language === "mal" ? "എല്ലാ കമ്മിറ്റികളും" : "All Committees"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default MahallCommittee
