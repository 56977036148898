import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"

//actions
import { getLsgDetail, updateLsg, getDistrictDropdown } from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"

const UpdateLsg = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [district, setDistrict] = useState("Search District")
  const [districtId, setDistrictId] = useState("")
  const [searchText, setSearchText] = useState("")
  const [lsgType, setLsgType] = useState()

  const { lsgDetail, searchDistricts } = useSelector(state => ({
    lsgDetail: state.Lsgs.lsgDetail,
    searchDistricts: state.Districts.searchDistricts,
  }))

  function handleValidSubmit(values) {
    const updateLsgData = {
      lsg_english_name: values.lsg_english_name,
      lsg_malayalam_name: values.lsg_malayalam_name,
      lsg_type_english: lsgTypes[values.lsg_type]?.english,
      lsg_type_malayalam: lsgTypes[values.lsg_type]?.malayalam,
      district: districtId,
    }

    dispatch(updateLsg(updateLsgData, lsgDetail.id, history))
    window.scroll(0, 0)
  }

  useEffect(() => {
    dispatch(getLsgDetail(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    setDistrict(lsgDetail.district_english_name)
    setDistrictId(lsgDetail.district)
    setLsgType(
      lsgTypes?.findIndex(
        x => x.english.toString() === lsgDetail?.lsg_type_english?.toString()
      )
    )
  }, [dispatch, lsgDetail])

  useEffect(() => {
    dispatch(getDistrictDropdown(searchText))
  }, [searchText, dispatch])

  function handlerFinalValue(event) {
    dispatch(getDistrictDropdown(event.value))
    setDistrictId(event.value)
    setDistrict(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const optionGroup = [
    {
      options: searchDistricts?.map((results, index) => ({
        key: index,
        label: results.district_english_name,
        value: results.id,
      })),
    },
  ]

  const lsgTypes = [
    {
      english: "Grama Panchayat",
      malayalam: "ഗ്രാമ പഞ്ചായത്ത്",
      value: "panchayat",
    },
    {
      english: "Municipality",
      malayalam: "മുനിസിപ്പാലിറ്റി",
      value: "municipality",
    },
    { english: "Corporation", malayalam: "കോർപ്പറേഷൻ", value: "corporation" },
    { english: "Others", malayalam: "മറ്റുള്ളവ", value: "others" },
  ]

  return (
    <>
      <MetaTags>
        <title>LSG | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="LSGs" breadcrumbItem="Update" />
        <Container fluid>
          <div className="container-fluid mt-5">
            <Row>
              <Col sm="12" md="12" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Update LSG</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="lsg_english_name"
                            type="text"
                            label="Lsg Name (English)"
                            value={lsgDetail?.lsg_english_name}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="lsg_malayalam_name"
                            type="text"
                            label="Lsg Name (മലയാളം)"
                            value={lsgDetail?.lsg_malayalam_name || ""}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <div className="row">
                            <AvField
                              type="select"
                              name="lsg_type"
                              label="LSGType"
                              value={lsgType}
                            >
                              <option>Choose a LSG Type ...</option>
                              {lsgTypes.map((item, key) => (
                                <option key={key} value={key}>
                                  {item.english}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>

                        <Col>
                          <Label> District </Label>
                          <Select
                            onInputChange={handleEnters}
                            placeholder={district}
                            value={district}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="District"
                            required="required"
                          />
                        </Col>
                      </div>

                      <div className="d-flex justify-content-end">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateLsg

UpdateLsg.propTypes = {
  history: PropTypes.object,
}
