import { AvField, AvForm } from "availity-reactstrap-validation"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Label, Modal, Row } from "reactstrap"
import propTypes from "prop-types"

//actions
import { updateAsset, deleteAsset } from "store/actions"

//componets

const UiModalAssetUpdate = ({ show, onCloseclick, history }) => {
  const dispatch = useDispatch()

  const { loading, language, assetDetail } = useSelector(state => ({
    loading: state.Assets.loading,
    error: state.Assets.error,
    language: state.Layout.language,
    assetDetail: state.Assets.assetDetail,
  }))

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const updateAssetData = {
      institute: assetDetail?.institute,
      asset_name: values.asset_name,
      remark: values.remark,
    }
    // console.log('registerZakatData: ', registerZakatData);
    dispatch(updateAsset(updateAssetData, assetDetail?.id))
  }

  const handleDeleteZakat = id => {
    dispatch(deleteAsset(id, history))
  }

  return (
    <>
      <React.Fragment>
        <div>
          <Modal isOpen={show} toggle={onCloseclick} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക" : "Update"}
              </h5>
              <button
                type="button"
                onClick={onCloseclick}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm
                className="form-horizontal "
                onValidSubmit={(onSubmitProps, v) => {
                  handleValidSubmit(onSubmitProps, v)
                }}
              >
                <div className="row mb-4">
                  <Col sm="12" md="12">
                    <Label>
                      {language === "mal" ? "പേര്" : "Asset Name"}
                      <span className="text-danger">&#42;</span>
                    </Label>
                    <AvField
                      name="asset_name"
                      type="text"
                      value={assetDetail?.asset_name}
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                    />
                  </Col>
                </div>

                <div className="row mb-4">
                  <Col>
                    <AvField
                      name="remark"
                      type="textarea"
                      rows="3"
                      value={assetDetail?.remark}
                      label={language === "mal" ? "റിമാർക്" : "Remark"}
                    />
                  </Col>
                </div>

                <Row className="mt-5 d-flex">
                  <div className="col-6">
                    <div className="row justify-content-start">
                      <div>
                        <Button
                          type="button"
                          color="danger"
                          className="w-md"
                          onClick={() => {
                            onCloseclick()
                            handleDeleteZakat(assetDetail?.id)
                          }}
                        >
                          {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="row justify-content-end">
                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          className="w-md m-auto me-0 d-block"
                          onClick={onCloseclick}
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക " : "Update"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Row>
              </AvForm>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    </>
  )
}

export default UiModalAssetUpdate

UiModalAssetUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.any,
}
