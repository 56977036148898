import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"
import { useLocation } from "react-router-dom"

import { createFamilyVarisangyaItem } from "store/actions"

const UiModalVarisangyaItemCreate = ({ show, onCloseclick, history, data }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const varisangyaId = location?.search?.split("?varisangyaId=")[1]

  const { loading, language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
  }))

  const filteredData = data

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const createFamilyVarisangyaItemData = {
      amount: values.amount,
      filter: filteredData,
      family_varisankya: varisangyaId,
    }

    dispatch(
      createFamilyVarisangyaItem(createFamilyVarisangyaItemData, history)
    )
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal"
                ? "വരിസംഖ്യ ഇനം തുക ചേർക്കുക"
                : "Add Varisangya Item Amount"}
              {/* <hr /> */}
              {/* <p style={{ fontSize: "14px" }} className="fw-normal">
                {language === "mal"
                  ? "താഴെയുള്ള സമർപ്പിക്കുക ബട്ടണിൽ ക്ലിക്ക് ചെയ്യുന്നതിലൂടെ, ലിസ്‌റ്റ് ചെയ്‌ത എല്ലാ കുടുംബങ്ങൾക്കും വരിസംഗ്യ തുക ചേർക്കപ്പെടുന്നതാണ്."
                  : "By clicking the below Submit Button, you will add varisangya amount for all the listed families."}
              </p> */}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col md="5" className="d-flex justify-contents-center">
                  <Label className="mx-2">
                    {language === "mal" ? "തുക" : "Amount"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="amount"
                    type="text"
                    validate={{
                      required: { value: true },
                      min: {
                        value: 1,
                        errorMessage: `${
                          language === "mal"
                            ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                            : "Amount must be greater than 0"
                        }`,
                      },
                    }}
                  />
                </Col>
              </div>
              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalVarisangyaItemCreate

UiModalVarisangyaItemCreate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
  data: propTypes.any,
}
