import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_GROUP,
  GET_GROUP_DETAIL,
  CREATE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
} from "./actionTypes"
import {
  getGroupSuccess,
  getGroupFail,
  getGroupDetailSuccess,
  getGroupDetailFail,
  createGroupSuccess,
  createGroupFail,
  updateGroupSuccess,
  updateGroupFail,
  deleteGroupSuccess,
  deleteGroupFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getGroupAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/mahall_group/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/mahall/mahall_group/?page=${page ? page : 1}`)
  }
}
const getGroupDetailsAPi = groupId => {
  return get(`/api/v1/mahall/mahall_group/${groupId}/`)
}
const createGroupApi = ({ group }) => {
  return post("/api/v1/mahall/mahall_group/", group)
}
const updateGroupApi = ({ group, groupId }) => {
  return ApiPut(`/api/v1/mahall/mahall_group/${groupId}/`, group)
}
const deleteGroupApi = ({ groupId }) => {
  return del(`/api/v1/mahall/mahall_group/${groupId}/`)
}

function* fetchGroup({ payload }) {
  try {
    const response = yield call(getGroupAPi, payload)
    if (response && !response?.error) {
      yield put(getGroupSuccess(response))
    } else {
      yield put(getGroupFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getGroupFail(error))
  }
}

function* fetchGroupDetail({ groupId }) {
  try {
    const response = yield call(getGroupDetailsAPi, groupId)
    if (response && !response?.error) {
      yield put(getGroupDetailSuccess(response))
    } else {
      yield put(getGroupDetailFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getGroupDetailFail(error))
  }
}
function* onCreateGroup({ payload }) {
  try {
    const response = yield call(createGroupApi, payload)
    if (response && !response?.error) {
      yield put(createGroupSuccess(response))
      payload.history.push("/groups")
      doneNotification("Created Successfully")
    } else {
      yield put(createGroupFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(createGroupFail(error))
    errorNotification()
  }
}

function* onUpdateGroup({ payload }) {
  try {
    const response = yield call(updateGroupApi, payload)
    if (response && !response?.error) {
      yield put(updateGroupSuccess(response))
      payload.history.push("/groups")
      doneNotification("Updated Successfully")
    } else {
      yield put(updateGroupFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(updateGroupFail(error))
    errorNotification()
  }
}

function* onDeleteGroup({ payload }) {
  try {
    const response = yield call(deleteGroupApi, payload)
    if (response && !response?.error) {
      // payload.history.push("/groups")
      yield put(deleteGroupSuccess(response))
      doneNotification("Deleted Successfully")
      try {
        const response = yield call(getGroupAPi, { page: 1 })
        yield put(getGroupSuccess(response))
      } catch (error) {
        yield put(getGroupFail(error))
      }
    } else {
      yield put(deleteGroupFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteGroupFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* GroupsSaga() {
  yield takeEvery(GET_GROUP, fetchGroup)
  yield takeEvery(GET_GROUP_DETAIL, fetchGroupDetail)
  yield takeEvery(CREATE_GROUP, onCreateGroup)
  yield takeEvery(UPDATE_GROUP, onUpdateGroup)
  yield takeEvery(DELETE_GROUP, onDeleteGroup)
}

export default GroupsSaga
