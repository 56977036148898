import React from "react"
import { Col, Row } from "reactstrap"

function BasePage() {
  return (
    <Row>
      <Col lg="12" style={{ marginTop: "95px" }} className="mx-3 pe-5"></Col>
    </Row>
  )
}

export default BasePage
