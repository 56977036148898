import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import { getFamilies, getFamiliesCards } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"

const AllSurveyUserFamilies = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const { families, loading, familiesCards, language, userDetail } =
    useSelector(state => ({
      families: state.Families.families,
      loading: state.Families.loading,
      familiesCards: state.Families.familiesCards,
      language: state.Layout.language,
      userDetail: state.Users.userDetail,
    }))

  //pages
  const totalPages = Math.ceil(families?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const handlePremiumLink = () => {
    if (["premium", "standard"].includes(userDetail?.mahall?.version)) {
      return "#!"
    } else {
      return "/premium"
    }
  }

  useEffect(() => {
    dispatch(getFamilies(searchText, pageSend()))
  }, [dispatch, page, searchText])

  useEffect(() => {
    dispatch(getFamiliesCards())
  }, [dispatch])

  const cardData = [
    {
      title: `${language === "mal" ? "ആകെ കുടുംബങ്ങൾ" : "Total Families"}`,
      cardValue: familiesCards?.total_families,
      iconClass: "home",
      routeLink: "/families",
      loading: loading,
      alt: `${language === "mal" ? "കുടുംബങ്ങൾ ഇല്ല" : "No Families"}`,
    },
    {
      title: `${language === "mal" ? "ആകെ കുടുംബാംഗങ്ങൾ" : "Total Members"}`,
      cardValue: familiesCards?.total_family_members,
      iconClass: "user",
      routeLink: "/familymembers",
      loading: loading,
      alt: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ ഇല്ല" : "No Family Members"}`,
    },
    {
      title: `${language === "mal" ? "ആൺ - പെൺ" : "Male - Female"}`,
      cardValue: `${familiesCards?.total_males} - ${familiesCards?.total_females}`,
      iconClass: "show",
      routeLink: "/familymembers",
      loading: loading,
      alt: `${language === "mal" ? "ആൺ - പെൺ ഇല്ല" : "No Male - Female"}`,
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    // {
    //   dataField: 'custom_id',
    //   text: `${language === 'mal' ? 'സിസ്റ്റം ഐഡി' : 'System ID'}`,
    // },
    {
      dataField: "mahall_custom_id",
      text: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
    },
    {
      dataField: "house_name",
      text: `${language === "mal" ? "വീട്ടുപേര്" : "House Name"}`,
      sort: true,
    },
    {
      dataField: "family_head_name",
      text: `${language === "mal" ? "കുടുംബനാഥൻ" : "Family Head"}`,
    },
    {
      dataField: "no_of_members",
      text: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members"}`,
    },
    {
      dataField: "houseNumber",
      text: `${language === "mal" ? "വീട് നമ്പർ" : "House No."}`,
    },
    {
      dataField: "area_name",
      text: `${language === "mal" ? "ഏരിയ" : "Area"}`,
    },
    {
      dataField: "contact_number",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]
  const familiesData = map(families?.results, (item, key) => ({
    ...item,
    key: key,
    no: (pageSend() - 1) * limit + key + 1,
    houseNumber: `${item?.house_number === null ? "" : item?.house_number}/${
      item?.ward_number === null ? "" : item?.ward_number
    }`,
    area_name:
      language === "mal"
        ? item?.area?.malayalam
          ? item?.area?.malayalam
          : item?.area?.english
        : item?.area?.english,
    action: (
      <div>
        <Link to={`/families/${item?.id}`}>
          <Button type="button" color="success" className=" btn w-xs btn-sm">
            {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
            View
          </Button>
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMahallFamilySearch = debounce(
    value => setSearchText(value),
    600
  )

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        {cardData?.map((item, key) => (
          <Col md={4} lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      {/* <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p> */}
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Row>
          <div className="col-md-6">
            <Link
              to={`/family/create`}
              type="submit"
              style={{ width: language === "mal" ? "200px" : "150px" }}
              className="w-md btn btn-success d-block"
            >
              {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New Family"}
            </Link>
          </div>
        </Row>
        <Col className="col-12 mt-2">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField={"key"}
                key={"key"}
                columns={columns}
                data={familiesData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceMahallFamilySearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Link
                        style={{ width: language === "mal" ? "135px" : "50px" }}
                        className="w-md btn btn-primary m-auto me-0 mt-0 d-block"
                        to={handlePremiumLink()}
                      >
                        <i className="bx bx-list-ol font-size-16 align-middle"></i>{" "}
                        {language === "mal" ? "ഫിൽട്ടർ" : "Filter"}
                        <i
                          className="bx bx-crown mx-1"
                          style={{ fontSize: "12px", color: "gold" }}
                        />
                      </Link>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="key"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {families &&
                      families?.results &&
                      families?.results?.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          No Families Yet
                        </p>
                      )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllSurveyUserFamilies
