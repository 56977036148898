import {
  GET_LEDGER_ITEM_CATEGORIES,
  UPDATE_LEDGER_ITEM_CATEGORY,
  CREATE_LEDGER_ITEM_CATEGORY,
  DELETE_LEDGER_ITEM_CATEGORY,
  GET_LEDGER_ITEM_CATEGORY_DETAIL,
  GET_LEDGER_ITEM_CATEGORIES_SUCCESS,
  GET_LEDGER_ITEM_CATEGORIES_FAIL,
  CREATE_LEDGER_ITEM_CATEGORY_SUCCESS,
  CREATE_LEDGER_ITEM_CATEGORY_FAIL,
  GET_LEDGER_ITEM_CATEGORY_DETAIL_SUCCESS,
  GET_LEDGER_ITEM_CATEGORY_DETAIL_FAIL,
  UPDATE_LEDGER_ITEM_CATEGORY_SUCCESS,
  UPDATE_LEDGER_ITEM_CATEGORY_FAIL,
  DELETE_LEDGER_ITEM_CATEGORY_SUCCESS,
  DELETE_LEDGER_ITEM_CATEGORY_FAIL,
  GET_LEDGER_ITEM_CATEGORY_DROPDOWN,
  GET_LEDGER_ITEM_CATEGORY_DROPDOWN_SUCCESS,
  GET_LEDGER_ITEM_CATEGORY_DROPDOWN_FAIL,
  GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD,
  GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_SUCCESS,
  GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  ledgerItemCategories: {},
  ledgerItemCategoriesForDownload: {},
  ledgerItemCategoryDetail: {},
  searchLedgerItemCategories: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdLedgerItemCategory: {},
}

const LedgerItemCategories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEDGER_ITEM_CATEGORIES:
    case GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD:
    case UPDATE_LEDGER_ITEM_CATEGORY:
    case CREATE_LEDGER_ITEM_CATEGORY:
    case DELETE_LEDGER_ITEM_CATEGORY:
    case GET_LEDGER_ITEM_CATEGORY_DROPDOWN:
      return {
        ...state,
        loading: true,
      }
    case GET_LEDGER_ITEM_CATEGORY_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_LEDGER_ITEM_CATEGORIES_SUCCESS:
      return {
        ...state,
        ledgerItemCategories: action.payload,
        loading: false,
      }

    case GET_LEDGER_ITEM_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        ledgerItemCategoriesForDownload: action.payload,
        loading: false,
      }

    case GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_LEDGER_ITEM_CATEGORY_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchLedgerItemCategories: action.payload,
        loading: false,
      }

    case GET_LEDGER_ITEM_CATEGORY_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_LEDGER_ITEM_CATEGORY_SUCCESS:
      return {
        ...state,
        // ledgerItemCategories: action.payload,
        ledgerItemCategories: {
          ...state.ledgerItemCategories,
          results: [...state.ledgerItemCategories.results, action.payload],
        },
        createdLedgerItemCategory: action.payload,
        loading: false,
      }

    case CREATE_LEDGER_ITEM_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_LEDGER_ITEM_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        ledgerItemCategoryDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_LEDGER_ITEM_CATEGORY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_LEDGER_ITEM_CATEGORY_SUCCESS:
      return {
        ...state,
        ledgerItemCategories: {
          results: state.ledgerItemCategories.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_LEDGER_ITEM_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_LEDGER_ITEM_CATEGORY_SUCCESS:
      return {
        ...state,
        ledgerItemCategories: {
          results: state.ledgerItemCategories.results.filter(
            ledgerItemCategory => ledgerItemCategory.id !== action.payload
          ),
        },
        loading: false,
      }

    case DELETE_LEDGER_ITEM_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default LedgerItemCategories
