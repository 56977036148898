import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import { createSurveyUser, checkSurveyUserPhoneNumber } from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateSurveyUser = ({ history }) => {
  const dispatch = useDispatch()

  const {
    loading,
    // error,
    mahallId,
    language,
    surveyUserPhoneNumberAvailability,
    surveyUserPhoneCheckLoading,
  } = useSelector(state => ({
    loading: state.Mahalls.loading,
    error: state.Mahalls.error,
    mahallId: state.Users.userDetail?.mahall?.id,
    language: state.Layout.language,
    surveyUserPhoneNumberAvailability:
      state.Users.surveyUserPhoneNumberAvailability,
    surveyUserPhoneCheckLoading: state.Users.surveyUserPhoneCheckLoading,
  }))

  const [checkValue, setCheckValue] = useState()

  const handleCheckSurveyUserPhoneNumberAvailability = v => {
    if (v.length == 10) {
      const checkSurveyUserPhoneNumberData = {
        phone: v,
      }
      dispatch(checkSurveyUserPhoneNumber(checkSurveyUserPhoneNumberData))
      setCheckValue(v)
    }
    // console.log("phone: ", v)
  }

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    // console.log(values)
    const createSurveyUserData = {
      full_name: values.full_name,
      email: values.email,
      phone: values.phone,
      mahall: mahallId,
    }
    if (!surveyUserPhoneNumberAvailability) return
    dispatch(createSurveyUser(createSurveyUserData, history))
  }

  return (
    <>
      <MetaTags>
        <title>Survey Users | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "സർവേ യൂസറുകൾ" : "Survey Users"}
          breadcrumbItem={
            language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create Survey User"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={9}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Fill this form</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Full Name"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="full_name"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </Col>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "മൊബൈൽ നമ്പർ"
                                : "Mobile Number"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="phone"
                                type="number"
                                // label={language === "mal" ? "മൊബൈൽ നമ്പർ" : "Mobile Number"}
                                validate={{
                                  required: { value: true },
                                  maxLength: {
                                    value: 10,
                                    errorMessage: `${
                                      language === "mal"
                                        ? "ഫോൺ നമ്പർ 10 അക്കങ്ങൾ ആയിരിക്കണം"
                                        : "phone number must be 10 digits"
                                    }`,
                                  },
                                }}
                                onChange={v => {
                                  handleCheckSurveyUserPhoneNumberAvailability(
                                    v.target.value
                                  )
                                  setCheckValue(v.target.value)
                                }}
                              />
                            </Col>
                            {checkValue &&
                              checkValue?.length == 10 &&
                              (!surveyUserPhoneCheckLoading ? (
                                surveyUserPhoneNumberAvailability ? (
                                  <p className="text-success">
                                    {language === "mal"
                                      ? "ലഭ്യമാണ്"
                                      : "Available"}
                                  </p>
                                ) : (
                                  <p className="text-danger">
                                    {language === "mal"
                                      ? "ലഭ്യമല്ല"
                                      : "Not Available"}
                                  </p>
                                )
                              ) : (
                                <></>
                              ))}
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="email"
                                type="email"
                                label={language === "mal" ? "ഇ_മെയിൽ" : "Email"}
                              />
                            </Col>
                          </div>
                        </div>
                      </Row>
                      <div className="d-flex justify-content-end mt-5">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Submit
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateSurveyUser

CreateSurveyUser.propTypes = {
  history: PropTypes.object,
}
