import React, { useState } from "react"
import { Image } from "react-bootstrap"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useDispatch } from "react-redux"

import photo from "../../../../assets/images/crypto/contactImg.png"
import { useLanguage } from "../Language/SwitchLanguage"

import { createEnquiry } from "store/actions"

function ContactUs() {
  const dispatch = useDispatch()
  const text = useLanguage()

  const [eqForm, setEqForm] = useState({
    full_name: "",
    phone: "",
    email: "",
    mahall_name: "",
    mahall_place: "",
    mahall_register_number: "",
    note: "",
  })

  const handleInput = e => {
    setEqForm({ ...eqForm, [e.target.name]: e.target.value })
  }

  const handleValidSubmit = e => {
    e.preventDefault()

    dispatch(createEnquiry(eqForm))

    setEqForm({
      full_name: "",
      phone: "",
      email: "",
      mahall_name: "",
      mahall_place: "",
      mahall_register_number: "",
      note: "",
    })
  }

  return (
    <React.Fragment>
      <section className="section bg-grey" id="contact">
        <Container
          style={{ filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))" }}
        >
          <Row className="mb-5 w-100 m-0">
            <Card className="d-flex flex-row p-0">
              <div style={{ width: "70%" }} className="d-none customDisplay">
                <Image src={photo} className="h-100 w-100" />
              </div>

              <CardBody className="p-4">
                <div className="p-3">
                  <form
                    className="form-horizontal"
                    onSubmit={e => handleValidSubmit(e)}
                  >
                    <Row className="">
                      <div className="mb-3">
                        <h5 className="">
                          <strong>{text?.ConnectWithUs}</strong>
                        </h5>
                        <p className="text-muted font-size-12">
                          {text?.contactDescription}
                        </p>
                      </div>
                      <Col sm="12" className="mb-3">
                        <label>{text?.fullName}</label>
                        <input
                          name="full_name"
                          className="form-control"
                          type="text"
                          value={eqForm.full_name}
                          onChange={handleInput}
                          required
                        />
                      </Col>
                      <Col sm="6" className="mb-3">
                        <label>{text?.phone}</label>

                        <input
                          name="phone"
                          className="form-control"
                          value={eqForm.phone}
                          type="phone"
                          required
                          onChange={handleInput}
                        />
                      </Col>
                      <Col sm="6" className="mb-3">
                        <label>{text?.email}</label>

                        <input
                          name="email"
                          value={eqForm.email}
                          className="form-control"
                          type="email"
                          onChange={handleInput}
                        />
                      </Col>
                      <Col sm="6" className="mb-3">
                        <label>{text?.mahallName}</label>

                        <input
                          name="mahall_name"
                          value={eqForm.mahall_name}
                          className="form-control"
                          required
                          type="text"
                          onChange={handleInput}
                        />
                      </Col>
                      <Col sm="6" className="mb-3">
                        <label>{text?.mahallPlace}</label>

                        <input
                          name="mahall_place"
                          value={eqForm.mahall_place}
                          className="form-control"
                          required
                          type="text"
                          onChange={handleInput}
                        />
                      </Col>

                      <Col className="text-end d-flex justify-content-center align-item-center mt-3 button_submit_eq">
                        <button
                          type="submit"
                          className="btn btn-success text-center text-white w-50 sm:w-100  py-3"
                          style={{ cursor: "pointer" }}
                        >
                          <h4 className="text-white mb-0 eq-button-new">
                            <i className="bx bx-phone font-size-20 align-middle me-2"></i>{" "}
                            {text?.buttonSubmit}
                          </h4>
                        </button>
                        <p className="mb-0 pb-0 mt-1 text-center w-75">
                          {text?.buttonSubmitDes}
                        </p>
                      </Col>
                    </Row>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default ContactUs
