import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import { Link } from "react-router-dom"
//actions
import { map } from "lodash"
// import axios from 'axios'
// import { Image } from 'react-bootstrap'

import {
  createFamily,
  getMahallTypeLists,
  getUserDetail,
  getStates,
  getDistrictDropdown,
  getVillageDropdown,
  getLsgDropdown,
  // updateFamilySuccess,
  // updateFamilyFail,
  checkCustomId,
} from "store/actions"
//componets
// import { API_URL } from 'helpers/api_methods'
// import {
//   doneNotification,
//   errorNotification,
// } from 'components/Common/Notification'
import HeaderLine from "components/Common/HeaderLine"
// import { useFileSizes } from 'hooks/useFileSize'
import { handleSelectOrDeselect } from "hooks/useSelectOrDeselect"
// import { useGetRole, useGetToken } from 'hooks/useHandleSessions'

// export const useFileSizes = size => {
//   if (size <= 300000) {
//     return {
//       status: true,
//       text: "",
//     }
//   } else {
//     return {
//       status: false,
//       text: "The file size must be less than 300kb",
//     }
//   }
// }
const CreateSurveyUserFamily = ({ history }) => {
  const dispatch = useDispatch()

  const {
    States,
    searchDistricts,
    searchVillages,
    mahallTypeLists,
    searchLsgs,
    userDetail,
    language,
    createdFamily,
    loading,
    mahallId,
    customIdAvailability,
    customIdCheckLoading,
  } = useSelector(state => ({
    loading: state.Families.loading,
    error: state.Families.error,
    States: state.States.states,
    searchDistricts: state.Districts.searchDistricts,
    searchVillages: state.Villages.searchVillages,
    searchLsgs: state.Lsgs.searchLsgs,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    createdFamily: state.Families.createdFamily,
    mahallId: state.Users.userDetail?.mahall?.id,
    customIdAvailability: state.Users.customIdAvailability,
    customIdCheckLoading: state.Users.customIdCheckLoading,
  }))

  const [checkValue, setCheckValue] = useState()

  const [activeTab, setactiveTab] = useState(1)
  // const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  // const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  // function toggleTabVertical(tab) {
  //   if (activeTabVartical !== tab) {
  //     var modifiedSteps = [...passedStepsVertical, tab]

  //     if (tab >= 1 && tab <= 3) {
  //       setoggleTabVertical(tab)
  //       setPassedStepsVertical(modifiedSteps)
  //     }
  //   }
  // }

  const [helpFromMahallSwitch, setHelpFromMahallSwitch] = useState(false)
  const [helpFromOthersSwitch, setHelpFromOthersSwitch] = useState(false)

  // const [mahallId, setMahallId] = useState("")

  const [searchTextState, setSearchTextState] = useState("")
  const [searchTextDistrict, setSearchTextDistrict] = useState("")
  const [searchTextVillage, setSearchTextVillage] = useState("")
  const [searchTextLsg, setSearchTextLsg] = useState("")

  const [state, setState] = useState("search state")
  const [stateId, setStateId] = useState("")
  const [district, setDistrict] = useState("Search District")
  const [districtId, setDistrictId] = useState("")
  const [village, setVillage] = useState("Search Village")
  const [villageId, setVillageId] = useState("")
  const [lsg, setLsg] = useState("Search Lsg")
  const [lsgId, setLsgId] = useState("")

  const [postOffice, setPostOffice] = useState("")
  const [pinCode, setPinCode] = useState("")

  // const [lsgType, setLsgType] = useState('')
  const [rationCardCategory, setRationCardCategory] = useState("")
  const [houseType, setHouseType] = useState("")
  const [houseBuildType, setHouseBuildType] = useState("")
  const [waterFacility, setWaterFacility] = useState("")
  const [electricFacility, setElectricFacility] = useState("")
  const [financialStatus, setFinancialStatus] = useState("")
  const [bankLoan, setBankLoan] = useState([])
  const [homeBusiness, setHomeBusiness] = useState([])

  const [yearsTillDate, setYearsTillDate] = useState(0)
  const [yearOfResidence, setYearOfResidence] = useState()

  const [houseName, setHouseName] = useState()

  const [dataSubmitted, setDataSubmitted] = useState(false)

  // const [imagePreview, setImagePreview] = useState()

  useEffect(() => {
    dispatch(getUserDetail())
  }, [dispatch])

  useEffect(() => {
    setState(
      language === "mal"
        ? userDetail?.mahall?.state_malayalam_name
          ? userDetail?.mahall?.state_malayalam_name
          : userDetail?.mahall?.state_english_name
        : userDetail?.mahall?.state_english_name
    )
    setStateId(userDetail?.mahall?.state)
    setDistrict(
      language === "mal"
        ? userDetail?.mahall?.district_malayalam_name
          ? userDetail?.mahall?.district_malayalam_name
          : userDetail?.mahall?.district_english_name
        : userDetail?.mahall?.district_english_name
    )
    setDistrictId(userDetail?.mahall?.district)
    setLsg(
      language === "mal"
        ? userDetail?.mahall?.lsg_malayalam_name
          ? userDetail?.mahall?.lsg_malayalam_name
          : userDetail?.mahall?.lsg_english_name
        : userDetail?.mahall?.lsg_english_name
    )
    setLsgId(userDetail?.mahall?.lsg)
    setVillage(
      language === "mal"
        ? userDetail?.mahall?.village_malayalam_name
          ? userDetail?.mahall?.village_malayalam_name
          : userDetail?.mahall?.village_english_name
        : userDetail?.mahall?.village_english_name
    )
    setVillageId(userDetail?.mahall?.village)
    setPinCode(userDetail?.mahall?.pin_code)
    setPostOffice(userDetail?.mahall?.post_office)
  }, [dispatch, userDetail])

  useEffect(() => {
    dispatch(getMahallTypeLists(mahallId))
  }, [dispatch, mahallId, userDetail])

  useEffect(() => {
    dispatch(getStates(searchTextState))
  }, [searchTextState, dispatch])

  useEffect(() => {
    dispatch(getDistrictDropdown(searchTextDistrict))
  }, [searchTextDistrict, dispatch])

  useEffect(() => {
    dispatch(getVillageDropdown(searchTextVillage))
  }, [searchTextVillage, dispatch])

  useEffect(() => {
    dispatch(getLsgDropdown(searchTextLsg))
  }, [searchTextLsg, dispatch])

  function handlerFinalValueState(event) {
    // dispatch(getStates(event.value))
    setStateId(event.value)
    setState(event.label)
  }

  function handlerFinalValueDistrict(event) {
    dispatch(getDistrictDropdown(event.value))
    setDistrictId(event.value)
    setDistrict(event.label)
  }

  function handlerFinalValueVillage(event) {
    dispatch(getVillageDropdown(event.value))
    setVillageId(event.value)
    setVillage(event.label)
  }

  function handlerFinalValueLsg(event) {
    dispatch(getLsgDropdown(event.value))
    setLsgId(event.value)
    setLsg(event.label)
  }

  const optionGroupState = [
    {
      options: States?.results?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results.state_malayalam_name
              ? results.state_malayalam_name
              : results.state_english_name
            : results.state_english_name,
        value: results.id,
      })),
    },
  ]

  const optionGroupDistrict = [
    {
      options: searchDistricts.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results.district_malayalam_name
              ? results.district_malayalam_name
              : results.district_english_name
            : results.district_english_name,
        value: results.id,
      })),
    },
  ]

  const optionGroupVillage = [
    {
      options: searchVillages?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results?.village_malayalam_name
              ? results?.village_malayalam_name
              : results?.village_english_name
            : results?.village_english_name,
        value: results.id,
      })),
    },
  ]

  const optionGroupLsg = [
    {
      options: searchLsgs?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results.lsg_malayalam_name
              ? results.lsg_malayalam_name
              : results.lsg_english_name
            : results.lsg_english_name,
        value: results.id,
      })),
    },
  ]

  const handleValidSubmit = values => {
    const totalArea =
      Number(values.land_area_home) +
      Number(values.land_area_farm) +
      Number(values.land_area_rent)
    const totalVehicles =
      Number(values.two_wheeler_vehicles) +
      Number(values.three_wheeler_vehicles) +
      Number(values.four_wheeler_vehicles)
    const createFamilyData = {
      mahall_custom_id: values.mahall_custom_id
        ? values.mahall_custom_id
        : null,
      varisangya_grade: values.varisangya_grade,
      house_name: values.house_name ? values.house_name : null,
      contact_number: values.contact_number ? values.contact_number : null,
      ward_number: values.ward_number ? values.ward_number : null,
      house_number: values.house_number ? values.house_number : null,
      area_choice_id:
        values.area_choice_id === "Select an area..." ||
        values.area_choice_id === "ഏരിയ തിരഞ്ഞെടുക്കുക ..."
          ? null
          : values.area_choice_id,
      family_varisankya_grade_choice_id:
        values.family_varisankya_grade_choice_id === "Select an grade..." ||
        values.family_varisankya_grade_choice_id === "ഗ്രേഡ് തിരഞ്ഞെടുക്കുക ..."
          ? null
          : values.family_varisankya_grade_choice_id,
      place: values.place ? values.place : null,
      via: values.via ? values.via : null,

      district: districtId ? districtId : null,
      state: stateId ? stateId : null,
      lsg: lsgId ? lsgId : null,
      village: villageId ? villageId : null,
      pin_code: pinCode ? pinCode : null,
      post_office: postOffice ? postOffice : null,
      varisankya_amount: null,

      year_of_residence: yearOfResidence ? yearOfResidence : null,
      ration_card_category_choice_id: rationCardCategory
        ? rationCardCategory
        : null,
      ration_card_number: values.ration_card_number
        ? values.ration_card_number
        : null,
      house_type_choice_id: houseType ? houseType : null,
      house_build_type_choice_id: houseBuildType ? houseBuildType : null,
      water_facility_choice_id: waterFacility ? waterFacility : null,
      electric_facility_choice_id: electricFacility ? electricFacility : null,

      land_area_home: values.land_area_home ? values.land_area_home : null,
      land_area_farm: values.land_area_farm ? values.land_area_farm : null,
      land_area_rent: values.land_area_rent ? values.land_area_rent : null,
      land_area_others: values.land_area_others
        ? values.land_area_others
        : null,
      total_land_area: totalArea ? totalArea : null,

      two_wheeler_vehicles: parseInt(values.two_wheeler_vehicles),
      three_wheeler_vehicles: parseInt(values.three_wheeler_vehicles),
      four_wheeler_vehicles: parseInt(values.four_wheeler_vehicles),
      taxi_vehicles: parseInt(values.taxi_vehicles),
      total_vehicles: totalVehicles,

      // location: ,
      // image: ,

      financial_status_choice_id: financialStatus ? financialStatus : null,
      annual_income_choice_id:
        values.annual_income_choice_id === "Select an income range..." ||
        values.annual_income_choice_id === "ഒരു വരുമാന പരിധി തിരഞ്ഞെടുക്കുക..."
          ? null
          : values.annual_income_choice_id,
      bank_loan_choice_id_array: bankLoan ? bankLoan : null,
      bank_loan_details: values.bank_loan_details
        ? values.bank_loan_details
        : null,

      home_business_choice_id_array: homeBusiness ? homeBusiness : null,
      home_business_details: values.home_business_details
        ? values.home_business_details
        : null,

      help_receiving_from_mahall: helpFromMahallSwitch
        ? helpFromMahallSwitch
        : null,
      help_receiving_from_mahall_details:
        values.help_receiving_from_mahall_details
          ? values.help_receiving_from_mahall_details
          : null,
      help_receiving_from_others: helpFromOthersSwitch
        ? helpFromOthersSwitch
        : null,
      help_receiving_from_others_details:
        values.help_receiving_from_others_details
          ? values.help_receiving_from_others_details
          : null,
      special_notes: values.special_notes ? values.special_notes : null,
      // varisankya_amount: values.varisankya_amount
      //   ? values.varisankya_amount
      //   : 0,
    }
    // setImagePreview()
    // console.log("createFamilyData: ", createFamilyData)
    dispatch(createFamily(createFamilyData, history))
    setDataSubmitted(true)

    // if (createdFamily?.id) {
    //   toggleTab(activeTab + 1)
    // }
  }
  useEffect(() => {
    if (createdFamily?.id) {
      if (dataSubmitted) {
        history.push(`/families/${createdFamily?.id}`)
        setDataSubmitted(false)
      }
    }
  }, [createdFamily?.id])

  const changeYearsTillDate = year => {
    const thisYear = new Date().getFullYear()
    const difference = thisYear - year
    setYearsTillDate(difference)
  }

  const addOrRemoveItem = (arr, setArray, value) => {
    const isPresent = arr.includes(value)
    var newArr = arr
    // console.log("is present: ", isPresent)

    if (isPresent) {
      newArr = arr.filter(item => {
        return item.toString() !== value.toString()
      })
    } else {
      newArr = [...arr, value]
    }
    setArray(newArr)
    // console.log("newArr : ", newArr)
  }

  const handleEntersState = textEntered => {
    setSearchTextState(textEntered)
  }

  const handleEntersDistrict = textEntered => {
    setSearchTextDistrict(textEntered)
  }

  const handleEntersVillage = textEntered => {
    setSearchTextVillage(textEntered)
  }

  const handleEntersLsg = textEntered => {
    setSearchTextLsg(textEntered)
  }

  // const [selectedFile, setSelectedFile] = useState({
  //   house_image: null,
  // })

  // const [img, setImg] = useState()
  // const [filesSize, setFilesSize] = useState()

  // const changeHandler = e => {
  //   const filesize = e.target.files[0].size
  //   setFilesSize(filesize)

  //   setSelectedFile({
  //     ...selectedFile,
  //     house_image: e.target.files[0],
  //   })

  //   const [file] = e.target.files
  //   setImg(URL.createObjectURL(file))

  //   if (e.target.files && e.target.files[0]) {
  //     let reader = new FileReader()
  //     reader.onload = e => {
  //       setImagePreview({ image: e.target.result })
  //     }
  //     reader.readAsDataURL(e.target.files[0])
  //   }
  // }

  // const [customLoad, setCustomLoad] = useState(false)

  // const handleFileSizes = useFileSizes

  // const handleValidSubmitImage = () => {
  //   if (handleFileSizes(filesSize)?.status === true) {
  //     uploadImage(setCustomLoad, selectedFile, createdFamily, dispatch, history)
  //   }
  // }

  const headerData = [
    { id: 1, titleMal: "സ്ഥല വിവരം", titleEn: "Place Details" },
    { id: 2, titleMal: "വീട്ടു വിവരം", titleEn: "House Details" },
    {
      id: 3,
      titleMal: "സാമ്പത്തിക വിവരം",
      titleEn: "Financial Details",
      line: false,
    },
    // { id: 4, titleMal: 'മറ്റ് വിവരം', titleEn: 'Other details', line: false },
  ]

  // const handleSkip = () => {
  //   history.push(`/families/${createdFamily?.id}`)
  // }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeTab])

  const handleCheckFamilyCustomId = v => {
    const checkFamilyCustomIdData = {
      mahall: mahallId,
      mahall_custom_id: v,
      model: "family",
    }
    dispatch(checkCustomId(checkFamilyCustomIdData))
  }

  return (
    <>
      <MetaTags>
        <title>Families | Khaf </title>
      </MetaTags>

      <div className="page-content ">
        <Row>
          <Col xs="12">
            <div
              className="page-title-box d-sm-flex align-items-center justify-content-end"
              style={{ marginBottom: "10px" }}
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0 d-flex justify-content-end mt-4">
                  <BreadcrumbItem>
                    <Link to="#">
                      {language === "mal" ? "കുടുംബങ്ങൾ" : "Families"}
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">
                      {language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create"}
                    </Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Breadcrumbs
          title={language === "mal" ? "കുടുംബങ്ങൾ" : "Families"}
          breadcrumbItem={language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create"}
        /> */}
        <Container fluid>
          <div className="container-fluid p-0">
            <Row>
              {HeaderLine(
                activeTab,
                passedSteps,
                setactiveTab,
                language,
                headerData
              )}

              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(v)
                }}
                className="sm:p-0"
              >
                <Col md={12} lg={12}>
                  <Card style={{ borderRadius: "15px" }}>
                    <CardBody className="p-0">
                      <div className="wizard clearfix">
                        <div
                          className={`content clearfix mt-4 ${
                            activeTab === 4 ? "d-none" : ""
                          }`}
                        >
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                              <Row>
                                <Col md="3">
                                  <div className="mb-1">
                                    <AvField
                                      name="mahall_custom_id"
                                      type="text"
                                      label={
                                        language === "mal"
                                          ? "മഹല്ല് ഐഡി"
                                          : "Mahall ID"
                                      }
                                      onChange={v => {
                                        handleCheckFamilyCustomId(
                                          v.target.value
                                        )
                                        setCheckValue(v.target.value)
                                      }}
                                    />
                                  </div>
                                  {checkValue &&
                                    checkValue?.length > 0 &&
                                    (!customIdCheckLoading ? (
                                      customIdAvailability ? (
                                        <p className="text-success">
                                          {language === "mal"
                                            ? "ലഭ്യമാണ്"
                                            : "Available"}
                                        </p>
                                      ) : (
                                        <p className="text-danger">
                                          {language === "mal"
                                            ? "ലഭ്യമല്ല"
                                            : "Not Available"}
                                        </p>
                                      )
                                    ) : (
                                      <></>
                                    ))}
                                </Col>
                                {mahallTypeLists?.varisankya_grade &&
                                  mahallTypeLists?.varisankya_grade.length >
                                    0 && (
                                    <Col md="3" className="mb-1">
                                      <Label>
                                        {language === "mal"
                                          ? "വരിസംഖ്യ ഗ്രേഡ്"
                                          : "Varisangya Grade"}
                                        {/* <span className="text-danger">&#42;</span> */}
                                      </Label>
                                      <AvField
                                        type="select"
                                        name="family_varisankya_grade_choice_id"
                                        // label={
                                        //   language === "mal" ? "ഏരിയ" : "Area"
                                        // }
                                        // helpMessage="Select an area"
                                      >
                                        <option value={null}>
                                          {language === "mal"
                                            ? "ഗ്രേഡ് തിരഞ്ഞെടുക്കുക ..."
                                            : "Select an grade..."}{" "}
                                        </option>
                                        {mahallTypeLists?.varisankya_grade?.map(
                                          (item, key) => (
                                            <option key={key} value={item.id}>
                                              {language === "mal"
                                                ? item?.malayalam
                                                : item?.english}
                                            </option>
                                          )
                                        )}
                                      </AvField>
                                    </Col>
                                  )}
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "വീട്ടുപേര്"
                                        : "House Name"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <AvField
                                      name="house_name"
                                      type="text"
                                      // label={
                                      //   language === "mal"
                                      //     ? "വീട്ടുപേര്"
                                      //     : "House Name"
                                      // }
                                      validate={{
                                        required: { value: true },
                                        maxLength: {
                                          value: 50,
                                          errorMessage: `${
                                            language === "mal"
                                              ? "വീട്ടുപേര് 50 അക്കങ്ങൾ ആയിരിക്കണം"
                                              : "House Name must be 50 characters"
                                          }`,
                                        },
                                      }}
                                      onChange={e =>
                                        setHouseName(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="contact_number"
                                      type="number"
                                      label={
                                        language === "mal"
                                          ? "ബന്ധപ്പെടേണ്ട നമ്പർ"
                                          : "Contact No."
                                      }
                                      validate={{
                                        maxLength: {
                                          value: 15,
                                          errorMessage: `${
                                            language === "mal"
                                              ? "ഫോൺ നമ്പർ 15 അക്കങ്ങൾ ആയിരിക്കണം"
                                              : "phone number must be 10 digits"
                                          }`,
                                        },
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="ward_number"
                                      type="text"
                                      label={
                                        language === "mal"
                                          ? "വാർഡ് നമ്പർ"
                                          : "Ward Number"
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="house_number"
                                      type="text"
                                      label={
                                        language === "mal"
                                          ? "വീട് നമ്പർ"
                                          : "House No."
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                {mahallTypeLists?.area &&
                                  mahallTypeLists?.area.length > 0 && (
                                    <Col md="6" className="mb-3">
                                      <Label>
                                        {language === "mal" ? "ഏരിയ" : "Area"}
                                        <span className="text-danger">
                                          &#42;
                                        </span>
                                      </Label>
                                      <AvField
                                        type="select"
                                        name="area_choice_id"
                                        // label={
                                        //   language === "mal" ? "ഏരിയ" : "Area"
                                        // }
                                        // helpMessage="Select an area"
                                      >
                                        <option>
                                          {language === "mal"
                                            ? "ഏരിയ തിരഞ്ഞെടുക്കുക ..."
                                            : "Select an area..."}{" "}
                                        </option>
                                        {mahallTypeLists?.area?.map(
                                          (item, key) => (
                                            <option key={key} value={item.id}>
                                              {language === "mal"
                                                ? item?.malayalam
                                                : item?.english}
                                            </option>
                                          )
                                        )}
                                      </AvField>
                                    </Col>
                                  )}
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="place"
                                      type="text"
                                      label={
                                        language === "mal" ? "സ്ഥലം" : "Place"
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="via"
                                      type="text"
                                      label={language === "mal" ? "വഴി" : "Via"}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-5">
                                <Col md="6">
                                  <div className="mb-3 sm:mt-0">
                                    <Label>
                                      {language === "mal"
                                        ? "സംസ്ഥാനം"
                                        : "State"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <Select
                                      onInputChange={handleEntersState}
                                      placeholder={state}
                                      options={optionGroupState}
                                      classNamePrefix="select2-selection"
                                      onChange={handlerFinalValueState}
                                      type="text"
                                      title="State"
                                      required="required"
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3 sm:mt-0">
                                    <Label>
                                      {language === "mal"
                                        ? "ജില്ല"
                                        : "District"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <Select
                                      onInputChange={handleEntersDistrict}
                                      placeholder={district}
                                      value={district}
                                      options={optionGroupDistrict}
                                      classNamePrefix="select2-selection"
                                      onChange={handlerFinalValueDistrict}
                                      type="text"
                                      title="District"
                                      required="required"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="pin_code"
                                      type="text"
                                      value={pinCode}
                                      label={
                                        language === "mal"
                                          ? "പിൻകോഡ്"
                                          : "Pin Code"
                                      }
                                      onChange={e => {
                                        setPinCode(e.target.value)
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="post_office"
                                      type="text"
                                      value={postOffice}
                                      label={
                                        language === "mal"
                                          ? "പോസ്റ്റ് ഓഫീസ്"
                                          : "Post Office"
                                      }
                                      onChange={e => {
                                        setPostOffice(e.target.value)
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "തദ്ദേശ സ്വയംഭരണ സ്ഥാപനം"
                                        : "LSG Name"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <Select
                                      onInputChange={handleEntersLsg}
                                      placeholder={lsg}
                                      value={lsg}
                                      options={optionGroupLsg}
                                      classNamePrefix="select2-selection"
                                      onChange={handlerFinalValueLsg}
                                      type="text"
                                      required
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "വില്ലേജ്"
                                        : "Village"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <Select
                                      onInputChange={handleEntersVillage}
                                      placeholder={village}
                                      value={village}
                                      options={optionGroupVillage}
                                      classNamePrefix="select2-selection"
                                      onChange={handlerFinalValueVillage}
                                      type="text"
                                      title="Village"
                                      required="required"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                              <div>
                                <Row>
                                  <Col md="6">
                                    <div className="mb-3">
                                      <Label>
                                        {language === "mal"
                                          ? "താമസം തുടങ്ങിയ വർഷം"
                                          : "Year Of Residance"}
                                      </Label>
                                      <Input
                                        type="number"
                                        className="form-control"
                                        value={yearOfResidence}
                                        onChange={e => {
                                          setYearOfResidence(e.target.value)
                                          changeYearsTillDate(e.target.value)
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label for="basicpill-vatno-input6">
                                        {language === "mal"
                                          ? "നാളിതുവരെ വർഷങ്ങൾ"
                                          : "Years till Date"}
                                      </Label>
                                      <Input
                                        type="text"
                                        readOnly
                                        className="form-control"
                                        id="basicpill-vatno-input6"
                                        value={yearsTillDate}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  {mahallTypeLists?.ration_card &&
                                    mahallTypeLists?.ration_card?.length && (
                                      <Col md="12" lg="6" className="mb-3">
                                        <Label for="basicpill-cstno-input7">
                                          {language === "mal"
                                            ? "റേഷൻ കാർഡ് വിഭാഗം"
                                            : "Ration Card Category"}
                                        </Label>
                                        <div className="d-flex  flex-wrap">
                                          {map(
                                            mahallTypeLists?.ration_card,
                                            (item, key) => (
                                              <div key={key} className="m-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  name="btnradio2"
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  checked={
                                                    rationCardCategory ===
                                                    item.id
                                                  }
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      rationCardCategory,
                                                      setRationCardCategory,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setRationCardCategory(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item.malayalam
                                                    : item.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Col>
                                    )}
                                  <Col md="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="ration_card_number"
                                        type="text"
                                        label={
                                          language === "mal"
                                            ? "റേഷൻ കാർഡ് നമ്പർ"
                                            : "Ration Card No."
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {mahallTypeLists?.house_type &&
                                  mahallTypeLists?.house_type?.length && (
                                    <Col md="12" lg="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-companyuin-input9">
                                          {language === "mal"
                                            ? "വീട്"
                                            : "House Type"}
                                        </Label>
                                        <div className="d-flex  flex-wrap">
                                          {map(
                                            mahallTypeLists?.house_type,
                                            (item, key) => (
                                              <div key={key} className="mx-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  name="btnradio3"
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  checked={
                                                    houseType === item.id
                                                  }
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      houseType,
                                                      setHouseType,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setHouseType(e.target.value)
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item.malayalam
                                                    : item.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                {mahallTypeLists?.house_build_type &&
                                  mahallTypeLists?.house_build_type?.length && (
                                    <Col md="12" lg="8">
                                      <div className="mb-3">
                                        <Label for="basicpill-declaration-input10">
                                          {language === "mal"
                                            ? "വീടിൻ്റെ സ്ഥിതി"
                                            : "Build Type"}
                                        </Label>
                                        <div className="d-flex flex-wrap">
                                          {map(
                                            mahallTypeLists?.house_build_type,
                                            (item, key) => (
                                              <div key={key} className="mx-1 ">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  name="btnradio4"
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  checked={
                                                    houseBuildType === item.id
                                                  }
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      houseBuildType,
                                                      setHouseBuildType,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setHouseBuildType(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                {mahallTypeLists?.water_facility &&
                                  mahallTypeLists?.water_facility?.length && (
                                    <Col md="12" lg="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-companyuin-input9">
                                          {language === "mal"
                                            ? "ജല സൗകര്യം"
                                            : "Water Facility"}
                                        </Label>
                                        <div className=" d-flex flex-wrap">
                                          {map(
                                            mahallTypeLists?.water_facility,
                                            (item, key) => (
                                              <div key={key} className=" mx-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  name="btnradio5"
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  checked={
                                                    waterFacility === item.id
                                                  }
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      waterFacility,
                                                      setWaterFacility,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setWaterFacility(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                {mahallTypeLists?.electric_facility &&
                                  mahallTypeLists?.electric_facility
                                    ?.length && (
                                    <Col md="12" lg={6}>
                                      <div className="mb-3">
                                        <Label for="basicpill-declaration-input10">
                                          {language === "mal"
                                            ? "വൈത്യുതി"
                                            : "Electicity"}
                                        </Label>
                                        <div className="d-flex">
                                          {map(
                                            mahallTypeLists?.electric_facility,
                                            (item, key) => (
                                              <div key={key} className="me-3">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  name="btnradio6"
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  checked={
                                                    electricFacility === item.id
                                                  }
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      electricFacility,
                                                      setElectricFacility,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setElectricFacility(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                <Row>
                                  {/* Land Area */}
                                  <Col md="12" className="mb-3">
                                    <Label for="basicpill-companyuin-input9">
                                      {language === "mal"
                                        ? "ആസ്തി വിവരം (സെന്റിൽ)"
                                        : "Land Area (in cents)"}
                                    </Label>

                                    <Col md="6" className="d-flex flex-wrap">
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="3"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "വീട്"
                                              : "Home"}
                                          </Label>
                                          <AvField
                                            name="land_area_home"
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="3"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "കൃഷി"
                                              : "Farm"}
                                          </Label>
                                          <AvField
                                            name="land_area_farm"
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="3"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "വാടക"
                                              : "Rent"}
                                          </Label>
                                          <AvField
                                            name="land_area_rent"
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="3"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "മറ്റുള്ളവ"
                                              : "Others"}
                                          </Label>
                                          <AvField
                                            name="land_area_others"
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                    </Col>
                                  </Col>

                                  {/* Vehicles */}
                                  <Col md="12" className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "വാഹനങ്ങൾ (എണ്ണം)"
                                        : "Vehicles (count)"}
                                    </Label>

                                    <Col md="6" className="d-flex flex-wrap">
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="3"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "2 വീലറുകൾ"
                                              : "2 Wheelers"}
                                          </Label>
                                          <AvField
                                            name="two_wheeler_vehicles"
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="3"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "3 വീലറുകൾ"
                                              : "3 Wheelers"}
                                          </Label>
                                          <AvField
                                            name="three_wheeler_vehicles"
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="3"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "4 വീലറുകൾ"
                                              : "4 Wheelers"}
                                          </Label>
                                          <AvField
                                            name="four_wheeler_vehicles"
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        sm="3"
                                        xs="4"
                                        md="3"
                                        className="d-flex p-1"
                                      >
                                        <div
                                          className="p-2 text-center"
                                          style={{
                                            backgroundColor: "#556EE6",
                                            color: "white",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <Label>
                                            {language === "mal"
                                              ? "ടാക്സികൾ"
                                              : "Taxi Vehicles"}
                                          </Label>
                                          <AvField
                                            name="taxi_vehicles"
                                            type="number"
                                          />
                                        </div>
                                      </Col>
                                    </Col>
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId={3}>
                              <div>
                                <Row>
                                  {mahallTypeLists?.financial_status &&
                                    mahallTypeLists?.financial_status
                                      ?.length && (
                                      <Col md="6" lg="6">
                                        <div className="mb-3">
                                          <Label>
                                            {language === "mal"
                                              ? "സാമ്പത്തിക സ്ഥിതി"
                                              : "Financial Status"}
                                          </Label>
                                          <div className="d-flex flex-wrap">
                                            {map(
                                              mahallTypeLists?.financial_status,
                                              (item, key) => (
                                                <div key={key} className="mx-1">
                                                  <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="btnradio7"
                                                    id={item.id}
                                                    autoComplete="off"
                                                    value={item.id}
                                                    checked={
                                                      financialStatus ===
                                                      item.id
                                                    }
                                                    onClick={e =>
                                                      handleSelectOrDeselect(
                                                        financialStatus,
                                                        setFinancialStatus,
                                                        e.target.value
                                                      )
                                                    }
                                                    // onChange={e =>
                                                    //   setFinancialStatus(
                                                    //     e.target.value
                                                    //   )
                                                    // }
                                                  />
                                                  <label
                                                    className="btn btn-outline-info"
                                                    htmlFor={item.id}
                                                  >
                                                    {language === "mal"
                                                      ? item?.malayalam
                                                      : item?.english}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    )}

                                  {mahallTypeLists?.annual_income &&
                                    mahallTypeLists?.annual_income.length >
                                      0 && (
                                      <Col md="6" lg="6" className="mb-3">
                                        <AvField
                                          type="select"
                                          name="annual_income_choice_id"
                                          label={
                                            language === "mal"
                                              ? "വാർഷിക വരുമാനം"
                                              : "Annual Income"
                                          }
                                          // helpMessage="Select an area"
                                        >
                                          <option value="">
                                            {language === "mal"
                                              ? "ഒരു വരുമാന പരിധി തിരഞ്ഞെടുക്കുക..."
                                              : "Select an income range..."}
                                          </option>
                                          {mahallTypeLists?.annual_income?.map(
                                            (item, key) => (
                                              <option key={key} value={item.id}>
                                                {language === "mal"
                                                  ? item?.malayalam
                                                  : item?.english}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      </Col>
                                    )}

                                  {mahallTypeLists?.bank_loan &&
                                    mahallTypeLists?.bank_loan?.length && (
                                      <Col md="6" lg={6}>
                                        <div className="mb-3">
                                          <Label for="basicpill-cardno-input12">
                                            {language === "mal"
                                              ? "ബാങ്ക് വായ്പ"
                                              : "Bank Loan"}
                                          </Label>
                                          <div className="d-flex  flex-wrap">
                                            {map(
                                              mahallTypeLists?.bank_loan,
                                              (item, key) => (
                                                <div key={key} className="m-1">
                                                  <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name={item.id}
                                                    checked={bankLoan?.includes(
                                                      item.id
                                                    )}
                                                    id={item.id}
                                                    autoComplete="off"
                                                    value={item.id}
                                                    onClick={e =>
                                                      addOrRemoveItem(
                                                        bankLoan,
                                                        setBankLoan,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="btn btn-outline-info"
                                                    htmlFor={item.id}
                                                  >
                                                    {language === "mal"
                                                      ? item?.malayalam
                                                      : item?.english}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    )}

                                  <Col md="6" lg="6">
                                    <div className="mb-4">
                                      <AvField
                                        name="bank_loan_details"
                                        type="textarea"
                                        label={
                                          language === "mal"
                                            ? "ബാങ്ക് വായ്പ വിശദാംശങ്ങൾ"
                                            : "Bank Loan Details"
                                        }
                                      />
                                    </div>
                                  </Col>
                                  {mahallTypeLists?.home_business &&
                                    mahallTypeLists?.home_business?.length && (
                                      <Col md="6" lg={6}>
                                        <div className="mb-3">
                                          <Label>
                                            {language === "mal"
                                              ? "ഹോം ബിസിനസ്സ്"
                                              : "Home Business"}
                                          </Label>
                                          <div className="d-flex  flex-wrap">
                                            {map(
                                              mahallTypeLists?.home_business,
                                              (item, key) => (
                                                <div key={key} className="m-1">
                                                  <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name={item.id}
                                                    checked={homeBusiness.includes(
                                                      item.id
                                                    )}
                                                    id={item.id}
                                                    autoComplete="off"
                                                    value={item.id}
                                                    onClick={e =>
                                                      addOrRemoveItem(
                                                        homeBusiness,
                                                        setHomeBusiness,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="btn btn-outline-info"
                                                    htmlFor={item.id}
                                                  >
                                                    {language === "mal"
                                                      ? item?.malayalam
                                                      : item?.english}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </Col>
                                    )}
                                  <Col md="6" lg={6}>
                                    <div className="mb-4">
                                      <AvField
                                        name="home_business_details"
                                        type="textarea"
                                        label={
                                          language === "mal"
                                            ? "ഹോം ബിസിനസ്സ് വിശദാംശങ്ങൾ"
                                            : "Home Business Details"
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-check-label"
                                        htmlFor="customSwitchsizemd"
                                      >
                                        {language === "mal"
                                          ? "മഹല്ലിൽ നിന്നുള്ള സഹായം"
                                          : "Help From Mahall"}
                                      </Label>
                                      <div className="form-check form-switch form-switch-lg">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          checked={helpFromMahallSwitch}
                                          onClick={() =>
                                            setHelpFromMahallSwitch(
                                              !helpFromMahallSwitch
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  {helpFromMahallSwitch && (
                                    <Col md="8">
                                      <div className="mb-3">
                                        <AvField
                                          name="help_receiving_from_mahall_details"
                                          type="text"
                                          label={
                                            language === "mal"
                                              ? "വിശദാംശങ്ങൾ"
                                              : "Details"
                                          }
                                        />
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  <Col md="4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-check-label"
                                        htmlFor="customSwitchsizemd"
                                      >
                                        {language === "mal"
                                          ? "മറ്റുള്ളവരിൽ നിന്നുള്ള സഹായം"
                                          : "Help From Others"}
                                      </Label>
                                      <div className="form-check form-switch form-switch-lg">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          checked={helpFromOthersSwitch}
                                          onClick={() =>
                                            setHelpFromOthersSwitch(
                                              !helpFromOthersSwitch
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  {helpFromOthersSwitch && (
                                    <Col md="8">
                                      <div className="mb-3">
                                        <AvField
                                          name="help_receiving_from_others_details"
                                          type="text"
                                          label={
                                            language === "mal"
                                              ? "വിശദാംശങ്ങൾ"
                                              : "Details"
                                          }
                                        />
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Col md="12">
                                  <div className="mb-3">
                                    <AvField
                                      name="special_notes"
                                      type="textarea"
                                      label={
                                        language === "mal"
                                          ? "പ്രത്യേക കുറിപ്പുകൾ"
                                          : "Special Notes"
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col md="3" className="mb-3">
                                  <AvField
                                    type="number"
                                    name="varisankya_amount"
                                    value={0}
                                    label={
                                      language === "mal"
                                        ? "വരിസംഖ്യ തുക"
                                        : "Varisangya Amount"
                                    }
                                    validate={{
                                      min: {
                                        value: 0,
                                        errorMessage: `${
                                          language === "mal"
                                            ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                                            : "Amount must be greater than 0"
                                        }`,
                                      },
                                    }}
                                  ></AvField>
                                </Col>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                        {activeTab === 4 ? (
                          <></>
                        ) : (
                          <div className="actions clearfix">
                            <ul>
                              <li
                                className={
                                  activeTab === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab - 1)
                                  }}
                                >
                                  {language === "mal"
                                    ? "പിന്നിലേക്ക്"
                                    : "Previous"}
                                </Link>
                              </li>
                              {activeTab !== 3 && (
                                <li
                                  className={
                                    activeTab === 3 || !houseName
                                      ? "next disabled"
                                      : "next"
                                  }
                                  title={
                                    !houseName && "please add a house name!"
                                  }
                                  style={{
                                    cursor: !houseName ? "not-allowed" : "",
                                  }}
                                >
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleTab(activeTab + 1)
                                    }}
                                  >
                                    {language === "mal" ? "അടുത്തത്" : "Next"}
                                  </Link>
                                </li>
                              )}
                              {activeTab === 3 && (
                                <li>
                                  <Button
                                    type="submit"
                                    color="success"
                                    className="w-sm btn-block"
                                  >
                                    {loading && (
                                      <>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                      </>
                                    )}
                                    {language === "mal"
                                      ? "സമർപ്പിക്കുക"
                                      : "Submit"}
                                  </Button>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </AvForm>
            </Row>
            {/* <Row>
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmitImage(e, v)
                }}
                className="sm:p-0"
              >
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={4}>
                    <Card className="sm:p-0">
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <div className="mb-3">
                              <Label>
                                {language === 'mal'
                                  ? 'വീടിൻ്റെ ഫോട്ടോ'
                                  : 'House Photo'}
                              </Label>
                              <div>
                                <Input
                                  onChange={changeHandler}
                                  type="file"
                                  name="house_image"
                                  className="form"
                                />
                              </div>
                              {handleFileSizes(filesSize)?.text && (
                                <p className="text-danger mt-1">
                                  {handleFileSizes(filesSize)?.text}
                                </p>
                              )}
                              {imagePreview && (
                                <Image
                                  style={{ width: '150px', height: '150px' }}
                                  className="my-2"
                                  src={imagePreview.image}
                                />
                              )}
                            </div>
                          </Col>

                          <Col
                            sm="4"
                            className="mt-5 align-items-center
                            d-flex
                            justify-content-end"
                            style={{ marginLeft: 'auto' }}
                          >
                            <Button
                              type="submit"
                              color="primary"
                              className="w-sm btn-block mx-2"
                              onClick={handleSkip}
                            >
                              {language === 'mal' ? 'ഒഴിവാക്കുക' : 'Skip'}
                            </Button>
                            {img && (
                              <Button
                                type="submit"
                                color="success"
                                className="w-sm btn-block"
                              >
                                {customLoad && (
                                  <>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                  </>
                                )}
                                {language === 'mal' ? 'സമർപ്പിക്കുക' : 'Submit'}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>
                </TabContent>
              </AvForm>
            </Row> */}
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateSurveyUserFamily

CreateSurveyUserFamily.propTypes = {
  history: PropTypes.object,
}

// function uploadImage(
//   setCustomLoad,
//   selectedFile,
//   createdFamily,
//   dispatch,
//   history,
// ) {
//   setCustomLoad(true)

//   const { role } = useGetRole()

//   const formData = new FormData()
//   {
//     selectedFile &&
//       selectedFile?.house_image &&
//       formData.append(
//         'house_image',
//         selectedFile?.house_image,
//         selectedFile?.house_image?.name,
//       )
//   }

//   const { token } = useGetToken()

//   // 51a7a4e3-18d0-489d-b4af-e0553a0fed92
//   axios
//     .patch(
//       `${API_URL}/api/v1/familydetails/family/${createdFamily?.id}/`,
//       formData,
//       {
//         headers: {
//           Authorization: 'token ' + token,
//           Role: role,
//         },
//       },
//     )
//     .then(res => {
//       if (res && !res?.error) {
//         dispatch(updateFamilySuccess(res.data))
//         history.push(`/families/${res.data?.id}`)
//         doneNotification('Uploaded Successfully')
//         setCustomLoad(false)
//       } else {
//         // updateFamilyFail(err)
//         errorNotification()
//       }
//     })
//     .catch(err => {
//       // console.log(err)
//       updateFamilyFail(err)
//       errorNotification()
//       setCustomLoad(false)
//     })
// }
