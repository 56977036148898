import React, { useEffect } from "react"
import { Col, Row, Card, CardBody, Badge } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
//action
import moment from "moment"

import CardContact from "../card-contact"

import { getDashboardCard } from "store/actions"
import familiesImage from "assets/images/home/family.png"
import familymembersImage from "assets/images/home/familymembers.png"
import user1 from "assets/images/logo/mahalluLogo.png"

function SurveyUserDashboard() {
  const dispatch = useDispatch()

  const { userDetail, language, familiesCardsLoading, dashboardCard } =
    useSelector(state => ({
      userDetail: state.Users.userDetail,
      language: state.Layout.language,
      dashboardCard: state.Dashboard.dashboardCard,
      familiesCardsLoading: state.Families.loading,
    }))

  useEffect(() => {
    dispatch(getDashboardCard())
  }, [dispatch])

  const cardData = [
    {
      title: `${language === "mal" ? "ആകെ കുടുംബങ്ങൾ" : "Total Families"}`,
      cardValue: dashboardCard?.families ?? 0,
      iconClass: "home",
      routeLink: "/",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Active Users"}`,
      subTitle: `${language === "mal" ? "ഇന്ന്" : "Today"}`,
      count: dashboardCard?.today_families,
    },
    {
      title: `${language === "mal" ? "ആകെ കുടുംബാംഗങ്ങൾ" : "Total Members"}`,
      cardValue: dashboardCard?.members ?? 0,
      iconClass: "group",
      routeLink: "/",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Inactive Users"}`,
      subTitle: `${language === "mal" ? "ഇന്ന്" : "Today"}`,
      count: dashboardCard?.today_members,
    },
  ]

  return (
    <Row>
      <Col lg="12" style={{ marginTop: "95px" }} className="mx-3 pe-5">
        <Row style={{ minHeight: "52vh", height: "auto" }}>
          <Col lg="6">
            {/* <WelcomeComp /> */}
            <Card className="blog-stats-wid">
              <CardBody
                style={{ backgroundColor: "#86cea8", borderRadius: "11px" }}
              >
                <Link to={""}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <div className="d-flex justify-content-start">
                        <h5 className="mb-0 text-khaf-blue">
                          {language === "mal"
                            ? userDetail?.mahall?.mahall_malayalam_name
                              ? userDetail?.mahall?.mahall_malayalam_name
                              : userDetail?.mahall?.mahall_english_name
                            : userDetail?.mahall?.mahall_english_name}
                        </h5>
                        <Badge
                          style={{
                            backgroundColor: "#0b3354",
                            color: "#f8f8fb",
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="mx-4 px-2"
                        >
                          {userDetail?.mahall?.version}
                        </Badge>
                      </div>
                      <p className="text-muted ">
                        {language === "mal" ? "" : "Since "}{" "}
                        {moment(userDetail?.mahall?.established_date).format(
                          "DD/MM/yyyy"
                        )}{" "}
                        {language === "mal" ? " മുതൽ" : ""}
                      </p>
                      <h5
                        className="mt-5 text-khaf-blue width"
                        style={{ maxWidth: "350px" }}
                      >
                        {`${
                          userDetail?.mahall?.lsg_english_name
                            ? userDetail?.mahall?.lsg_english_name
                            : ""
                        } ${
                          userDetail?.mahall?.lsg_type_english
                            ? userDetail?.mahall?.lsg_type_english
                            : ""
                        }, `}
                        {userDetail?.mahall?.village_english_name
                          ? userDetail?.mahall?.village_english_name
                          : ""}
                        ,
                        {`${
                          userDetail?.mahall?.post_office
                            ? userDetail?.mahall?.post_office
                            : ""
                        }-${
                          userDetail?.mahall?.pin_code
                            ? userDetail?.mahall?.pin_code
                            : ""
                        }, `}
                        {userDetail?.mahall?.district_english_name
                          ? userDetail?.mahall?.district_english_name
                          : ""}
                        ,
                        {userDetail?.mahall?.state_english_name
                          ? userDetail?.mahall?.state_english_name
                          : ""}
                      </h5>
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <img
                          src={
                            userDetail?.mahall?.logo
                              ? userDetail?.mahall?.logo
                              : user1
                          }
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Row>
              {cardData?.map((item, key) => (
                <Col sm="12" md="6" lg="6" key={key} className="sm:p-0">
                  <Card className="blog-stats-wid">
                    <CardBody>
                      <Link to={item.routeLink && item.routeLink}>
                        <div className="d-flex flex-wrap">
                          <div className="me-3">
                            <p className="text-muted mb-3">{item.title}</p>
                            {item.cardValue && (
                              <h5 className="mb-0">{item.cardValue}</h5>
                            )}
                            <p className="m-0 text-info">
                              {item.cardValue !== "" ? <></> : item.alt}
                            </p>
                            <div className="">
                              <p className="mt-2 text-success">
                                {item.subTitle}
                              </p>
                              {item.count && (
                                <h5 className="mb-0">{item.count}</h5>
                              )}
                            </div>
                            <p className="m-0 text-info">
                              {item.count !== "" ? <></> : "0"}
                            </p>
                          </div>

                          <div className="avatar-sm ms-auto">
                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                              <i
                                className={"bx bxs-" + `${item.iconClass}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          {/* <Col lg={3}>
            <HealthChart />
          </Col>
          <Col lg={6}>
            <DiseaseChart />
          </Col>
          <Col lg={3}>
            <PieChart />
          </Col> */}
        </Row>
        <Row className="m-2 mt-0">
          {/* <CardContact image={profileImage} title='Profile' link='/' /> */}
          <CardContact
            image={familiesImage}
            title={language === "mal" ? "കുടുംബങ്ങൾ" : "Families"}
            link="/families"
          />
          <CardContact
            image={familymembersImage}
            title={language === "mal" ? "അംഗങ്ങൾ" : "Members"}
            link="/familymembers"
          />
        </Row>
      </Col>
    </Row>
  )
}

export default SurveyUserDashboard
