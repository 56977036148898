import {
  GET_VARISANGYAS,
  GET_VARISANGYA_CARDS,
  UPDATE_VARISANGYA,
  CREATE_VARISANGYA,
  DELETE_VARISANGYA,
  GET_VARISANGYA_DETAIL,
  GET_VARISANGYAS_SUCCESS,
  GET_VARISANGYAS_FAIL,
  GET_VARISANGYA_CARDS_SUCCESS,
  GET_VARISANGYA_CARDS_FAIL,
  CREATE_VARISANGYA_SUCCESS,
  CREATE_VARISANGYA_FAIL,
  GET_VARISANGYA_DETAIL_SUCCESS,
  GET_VARISANGYA_DETAIL_FAIL,
  UPDATE_VARISANGYA_SUCCESS,
  UPDATE_VARISANGYA_FAIL,
  DELETE_VARISANGYA_SUCCESS,
  DELETE_VARISANGYA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  varisangyas: [],
  varisangyaCards: {},
  varisangyaDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdVarisangya: {},
}

const Varisangyas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VARISANGYAS:
    case GET_VARISANGYA_CARDS:
    case UPDATE_VARISANGYA:
    case CREATE_VARISANGYA:
    case DELETE_VARISANGYA:
      return {
        ...state,
        loading: true,
      }
    case GET_VARISANGYA_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_VARISANGYAS_SUCCESS:
      return {
        ...state,
        varisangyas: action.payload,
        loading: false,
      }
    case GET_VARISANGYAS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_VARISANGYA_CARDS_SUCCESS:
      return {
        ...state,
        varisangyaCards: action.payload,
        loading: false,
      }
    case GET_VARISANGYA_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_VARISANGYA_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdVarisangya: action.payload,
        loading: false,
      }

    case CREATE_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_VARISANGYA_DETAIL_SUCCESS:
      return {
        ...state,
        varisangyaDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_VARISANGYA_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_VARISANGYA_SUCCESS:
      return {
        ...state,
        varisangyaDetail: action.payload,
        loading: false,
      }

    case UPDATE_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_VARISANGYA_SUCCESS:
      return {
        ...state,
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        loading: false,
      }

    case DELETE_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Varisangyas
