import React from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"

import { createLedgerItemCategory } from "store/actions"

const UiModalMahallCategory = ({ show, onCloseclick, history }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const { loading, language, instituteId } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    instituteId: state.Institutes.instituteMahallDetail?.id,
  }))

  const handleValidSubmit = (onSubmitProps, values) => {
    const createLedgerItemCategoryData = {
      institute_ledger_item_category_name:
        values.institute_ledger_item_category_name,
      institute: instituteId,
    }
    // console.log(createCommitteeData);
    dispatch(createLedgerItemCategory(createLedgerItemCategoryData, history))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "കാറ്റഗറി ചേർക്കുക" : "Create Category"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "പേര്" : "Category Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="institute_ledger_item_category_name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <div className="row w-100 justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  onClick={onCloseclick}
                >
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalMahallCategory
UiModalMahallCategory.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
