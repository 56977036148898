import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import Select from "react-select"
import { Col, Modal, Button, Label } from "reactstrap"

import { updateSubject, getStaffDropdown, deleteSubject } from "store/actions"

const UiModalSubjectUpdate = ({ show, onCloseclick, data }) => {
  const dispatch = useDispatch()

  const { loading, language, staffList } = useSelector(state => ({
    loading: state.Divisions.loading,
    language: state.Layout.language,
    staffList: state.Staffs.staffList,
  }))

  const subjectDetail = data

  const [searchText, setSearchText] = useState("")
  const [staff, setStaff] = useState("Select staff...")
  const [staffId, setStaffId] = useState("")

  useEffect(() => {
    setStaff(subjectDetail?.teacher_name)
    setStaffId(subjectDetail?.teacher)
  }, [subjectDetail])

  const optionGroup = [
    {
      options: staffList?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setStaffId(event.value)
    setStaff(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const updateSubjectData = {
      division: subjectDetail?.division,
      subject_name: values.subject_name,
      teacher: staffId,
      // description: values.description,
    }
    dispatch(updateSubject(updateSubjectData, subjectDetail?.id))
  }

  useEffect(() => {
    dispatch(getStaffDropdown(searchText, subjectDetail?.institute))
  }, [dispatch, searchText, subjectDetail])

  const handleDelete = () => {
    dispatch(deleteSubject(subjectDetail?.id))
    onCloseclick()
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Update Subject</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "വിഷയം" : "Subject Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="subject_name"
                    type="text"
                    value={subjectDetail?.subject_name}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>
                    {language === "mal" ? "അധ്യാപകൻ" : "Teacher"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={staff}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Family Member"
                    required="required"
                  />
                </Col>
              </div>
              {/* <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    label={language === 'mal' ? 'വിവരണം' : 'Description'}
                  />
                </Col>
              </div> */}
              <div className="d-flex justify-content-between">
                <Button
                  type="button"
                  color="danger"
                  className="w-25"
                  onClick={handleDelete}
                >
                  {language === "mal" ? "ഇല്ലാതാക്കുക" : "Delete"}
                </Button>
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  disabled={!staffId}
                >
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalSubjectUpdate
UiModalSubjectUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.object,
  history: propTypes.object,
}
