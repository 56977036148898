import { AvField, AvForm } from "availity-reactstrap-validation"
import { create } from "lodash"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import { createTransations } from "store/actions"
import logo_mahallu from "assets/images/logo/logo.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

function CreateTransactions() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { transaction, loading } = useSelector(state => ({
    transaction: state.Transactions?.transactions,
    loading: state?.Transactions?.loading,
  }))
  console.log(transaction)

  // open payament start

  useEffect(() => {
    if (transaction?.trn_token) {
      initiateOpenPayment(transaction?.trn_token)
    }
  }, [transaction?.trn_token])

  const initiateOpenPayment = trn_token => {
    console.log("token : ", trn_token)

    Layer.checkout(
      {
        token: trn_token,
        accesskey: process.env.REACT_APP_OPEN_PAYMENT_KEY,
      },
      function (response) {
        console.log("open payment res", response)
        switch (response?.status) {
          case "pending":
            history,
              push({
                pathname: "/home/en",
              })

          case "success":
          case "captured":
            if (response && response.payment_id) {
              history.push({
                pathname: "/success-page",
              })
            }
            break
          case "cancelled":
          case "error":
          case "failure":
            history.push({
              pathname: "/404",
            })
          default:
            break
        }
        // success
        // failure
        // pending
        // error
        // cancelled
      },
      function (err) {
        console.log("err", err)
        alert(err.message)
      }
    )
  }

  const handleValidSubmit = (onSubmitProps, createData) => {
    console.log(createData)

    dispatch(createTransations(createData, history))
  }

  // const accesskey = "76f7e3c0-c3c8-11ed-8eca-6196faedc8ed"

  return (
    <div>
      <Container className="p-5">
        <Card>
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={logo_mahallu}
              width={100}
              height={100}
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center m-0 h4">
            {/* ഖാഫ് മഹല്ല് ശാക്തീകരണ സമിതി */}
            Khaf Mahall Empowerment Committee
          </div>
          <CardBody>
            <div className="">
              {" "}
              <div className="d-flex align-items-center justify-content-center">
                {" "}
                <p className="font-size-16">Add Donation</p>
              </div>
            </div>
            <div className="p-3">
              <AvForm
                onValidSubmit={(onSubmitProps, v) => {
                  handleValidSubmit(onSubmitProps, v)
                }}
              >
                <Row className="p-2">
                  <Col md={6} lg={6} sm={12}>
                    <AvField
                      name="name"
                      type="text"
                      className="form-control"
                      label="Name"
                    />
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <AvField
                      name="email"
                      type="mail"
                      className="form-control"
                      label="E-Mail"
                    />
                  </Col>
                </Row>
                <Row className="p-2">
                  <Col md={6} lg={6} sm={12}>
                    <AvField
                      name="contact_number"
                      type="number"
                      className="form-control"
                      label="Contact Number"
                      validate={{
                        minLength: { value: 10 },
                        maxLength: { value: 16 },
                      }}
                    />
                  </Col>
                  {/* <Col md={4} lg={4} sm={12}>
                    <AvField
                      name="payment_type"
                      type="text"
                      className="form-control"
                      label="Payment Type"
                    />
                  </Col> */}
                  <Col md={6} lg={6} sm={12}>
                    <AvField
                      name="amount"
                      type="number"
                      className="form-control"
                      label=" Amount"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Col>
                </Row>
                <Col md={12} lg={12} sm={12} className="p-2">
                  <AvField
                    name="remark"
                    type="text"
                    className="form-control"
                    label=" Rremark"
                  />
                </Col>
                <div className="d-flex align-items-center justify-content-end p-2">
                  <Button className="px-5" color="success" type="submit">
                    {loading && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )}{" "}
                    Submit
                  </Button>
                </div>
              </AvForm>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default CreateTransactions
