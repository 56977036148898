import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_MAHALL_USERS,
  GET_MAHALL_USERS_CARDS,
  GET_MAHALL_USER_DROPDOWN,
  GET_MAHALL_USER_DETAIL,
  OTP_VERIFICATION,
  UPDATE_MAHALL_USER_DETAIL,
  CREATE_MAHALL_USER,
  UPDATE_MAHALL_USER,
  DELETE_MAHALL_USER,
  CHANGE_MAHALL_USER_STATUS,
  GET_MAHALL_USERS_FOR_DOWNLOAD,
} from "./actionTypes"
import {
  getMahallUsersSuccess,
  getMahallUsersFail,
  getMahallUsersCardsSuccess,
  getMahallUsersCardsFail,
  getMahallUserDropdownSuccess,
  getMahallUserDropdownFail,
  getMahallUserDetailSuccess,
  getMahallUserDetailFail,
  otpVerificationSuccess,
  otpVerificationFail,
  updateMahallUserDetailSuccess,
  updateMahallUserDetailFail,
  createMahallUserSuccess,
  createMahallUserFail,
  updateMahallUserSuccess,
  updateMahallUserFail,
  deleteMahallUserSuccess,
  deleteMahallUserFail,
  changeMahallUserStatusSuccess,
  changeMahallUserStatusFail,
  getMahallUsersForDownloadSuccess,
  getMahallUsersForDownloadFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getMahallUsersAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/mahalluser/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/mahall/mahalluser/?page=${page ? page : 1}`)
  }
}
const getMahallUsersForDownloadAPi = () => {
  return get(`/api/v1/mahall/mahalluser/`)
}
const getMahallUsersCardsAPi = () => {
  return get(`/api/v1/mahall/mahalluser/?cards=all`)
}
const getMahallUserDropdownAPi = mahallUserId => {
  return get(`/api/v1/account/user/${mahallUserId}/`)
}
function otpVerificationApi({ phone }) {
  return post("/api/v1/account/otp_verification", phone)
}
const getMahallUserDetailsAPi = mahallUserId => {
  return get(`/api/v1/mahall/mahalluser/${mahallUserId}/`)
}
const createMahallUserApi = ({ mahallUser }) => {
  return post("/api/v1/mahall/mahalluser/", mahallUser)
}
const changeMahallUserStatusApi = ({ mahallUserId }) => {
  return post(`/api/v1/mahall/mahalluser/status/${mahallUserId}/`)
}
const updateMahallUserApi = ({ mahallUserId, mahallUser }) => {
  return ApiPut(`/api/v1/mahall/mahalluser/${mahallUserId}/`, mahallUser)
}
const updateMahallUserDetailApi = ({ mahallUserId, mahallUser }) => {
  return ApiPut(`/api/v1/account/user/update${mahallUserId}/`, mahallUser)
}
const deleteMahallUserApi = ({ mahallUserId }) => {
  return del(`/api/v1/mahall/mahalluser/${mahallUserId}/`)
}

function* fetchMahallUsers({ payload }) {
  try {
    const response = yield call(getMahallUsersAPi, payload)
    if (response && !response?.error) {
      yield put(getMahallUsersSuccess(response))
    } else {
      yield put(getMahallUsersFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallUsersFail(error))
    errorNotification()
  }
}

function* fetchMahallUsersForDownload({ payload }) {
  try {
    const response = yield call(getMahallUsersForDownloadAPi, payload)
    if (response && !response?.error) {
      yield put(getMahallUsersForDownloadSuccess(response))
    } else {
      yield put(getMahallUsersForDownloadFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallUsersForDownloadFail(error))
    errorNotification()
  }
}

function* fetchMahallUsersCards({ payload }) {
  try {
    const response = yield call(getMahallUsersCardsAPi, payload)
    if (response && !response?.error) {
      yield put(getMahallUsersCardsSuccess(response))
    } else {
      yield put(getMahallUsersCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallUsersCardsFail(error))
    errorNotification(error)
  }
}

function* fetchMahallUserDropdown({ payload }) {
  try {
    const response = yield call(getMahallUserDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getMahallUserDropdownSuccess(response))
    } else {
      yield put(getMahallUserDropdownFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallUserDropdownFail(error))
    errorNotification(error)
  }
}

function* otpVerification({ payload }) {
  try {
    const response = yield call(otpVerificationApi, payload)

    if (response?.error_message) {
      yield put(otpVerificationFail(response?.error_message))
    } else {
      yield put(otpVerificationSuccess(response))
    }
  } catch (error) {
    yield put(otpVerificationFail(error))
    errorNotification(error)
  }
}

function* fetchMahallUserDetail({ mahallUserId }) {
  try {
    const response = yield call(getMahallUserDetailsAPi, mahallUserId)
    if (response && !response?.error) {
      yield put(getMahallUserDetailSuccess(response))
    } else {
      yield put(getMahallUserDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallUserDetailFail(error))
    errorNotification(error)
  }
}
function* onCreateMahallUser({ payload }) {
  // console.log("saga payload: ", payload)
  try {
    const response = yield call(createMahallUserApi, payload)
    if (response && !response?.error) {
      yield put(createMahallUserSuccess(response))
      payload.history.push("/mahallusers")
      doneNotification("Created Successfully")
    } else {
      yield put(createMahallUserFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createMahallUserFail(error))
    errorNotification(error)
    // if (error?.response?.data?.error[0]) {
    //   yield put(createMahallUserFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function* changeMahallUserStatus({ payload }) {
  try {
    const response = yield call(changeMahallUserStatusApi, payload)
    if (response && response[0] === "Successfully Updated") {
      yield put(changeMahallUserStatusSuccess(payload))
      doneNotification("Status Updated Successfully")
      // window.location.reload()
    }
  } catch (error) {
    yield put(changeMahallUserStatusFail(error))
  }
}

function* onUpdateMahallUser({ payload }) {
  try {
    const response = yield call(updateMahallUserApi, payload)
    if (response && !response?.error) {
      yield put(updateMahallUserSuccess(response))
      payload.history.push("/mahallusers")
      doneNotification("Updated Successfully")
    } else {
      yield put(updateMahallUserFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateMahallUserFail(error))
    errorNotification(error)
    // if (error?.response?.data?.error[0]) {
    //   yield put(updateMahallUserFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function* onUpdateMahallUserDetail({ payload }) {
  try {
    const response = yield call(updateMahallUserDetailApi, payload)
    yield put(updateMahallUserDetailSuccess(response))
    doneNotification()
  } catch (error) {
    yield put(updateMahallUserDetailFail(error))
    errorNotification(error)
  }
}

function* onDeleteMahallUser({ payload }) {
  // console.log(payload)
  try {
    const response = yield call(deleteMahallUserApi, payload)
    if (response && !response?.error) {
      payload.history.push("/mahallusers")
      yield put(deleteMahallUserSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteMahallUserFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(deleteMahallUserFail(error))
    errorNotification(error)
    // if (error?.response?.data?.error[0]) {
    //   yield put(deleteMahallUserFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* MahallUsersSaga() {
  yield takeEvery(GET_MAHALL_USERS, fetchMahallUsers)
  yield takeEvery(GET_MAHALL_USERS_FOR_DOWNLOAD, fetchMahallUsersForDownload)
  yield takeEvery(GET_MAHALL_USERS_CARDS, fetchMahallUsersCards)
  yield takeEvery(GET_MAHALL_USER_DROPDOWN, fetchMahallUserDropdown)
  yield takeEvery(GET_MAHALL_USER_DETAIL, fetchMahallUserDetail)
  yield takeEvery(OTP_VERIFICATION, otpVerification)
  yield takeEvery(UPDATE_MAHALL_USER_DETAIL, onUpdateMahallUserDetail)
  yield takeEvery(CREATE_MAHALL_USER, onCreateMahallUser)
  yield takeEvery(CHANGE_MAHALL_USER_STATUS, changeMahallUserStatus)
  yield takeEvery(UPDATE_MAHALL_USER, onUpdateMahallUser)
  yield takeEvery(DELETE_MAHALL_USER, onDeleteMahallUser)
}

export default MahallUsersSaga
