import React from "react"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { map } from "lodash"

function HeaderLine(
  activeTab,
  passedSteps,
  setactiveTab,
  language,
  headerData
) {
  return (
    <div className="steps clearfix mb-4 d-flex align-items-center justify-content-center">
      {map(headerData, (item, key) => (
        <NavItem
          key={key}
          className={`d-flex flex-column position-relative
                  align-items-center ${classnames({
                    current: activeTab === (item?.id ? item?.id : 1),
                  })}`}
        >
          <span
            className="number-label"
            style={{
              backgroundColor: passedSteps.includes(item?.id) ? "#4294fe" : "",
              color: passedSteps.includes(item?.id) ? "#ffffff" : "#4294fd",
            }}
          >
            {item?.id}
          </span>
          {item?.line === false ? (
            <></>
          ) : (
            <span
              className="line-between"
              style={{
                backgroundColor: passedSteps.includes(item?.id)
                  ? "#4294fe"
                  : "#a4a8ab",
              }}
            ></span>
          )}
          <NavLink
            className={`text-center ${classnames({
              current: activeTab === (item?.id ? item?.id : 1),
            })}`}
            style={{
              color: passedSteps.includes(item?.id) ? "#4294fe" : "",
            }}
            onClick={() => {
              setactiveTab(1)
              console.log("calling here")
            }}
            disabled={!(passedSteps || []).includes(item?.id)}
          >
            {language === "mal" ? item?.titleMal : item?.titleEn}
          </NavLink>
        </NavItem>
      ))}
    </div>
  )
}

export default HeaderLine
