import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_SUPERFEEDS,
  GET_SUPERFEEDS_DETAILS,
  CREATE_SUPERFEEDS,
  UPDATE_SUPERFEEDS,
  DELETE_SUPERFEEDS,
  REPOST_FEEDS,
  GET_SUPER_BANNERS,
  GET_SUPER_BANNERS_DETAILS,
  CREATE_SUPER_BANNERS,
  UPDATE_SUPER_BANNERS,
  DELETE_SUPER_BANNERS,
} from "./actionTypes"
import {
  getSuperFeedssFail,
  getSuperFeedssSuccess,
  getSuperFeedsDetailsSuccess,
  getSuperFeedsDetailsFail,
  createSuperFeedsFail,
  createSuperFeedsSuccess,
  updateSuperFeedsSuccess,
  updateSuperFeedsFail,
  deleteSuperFeedsSuccess,
  deleteSuperFeedsFail,
  getSuperFeedsSuccess,
  getSuperFeedsFail,
  repostFeedSuccess,
  repostFeedFail,
  getSuperBanners,
  getSuperBannerSuccess,
  getSuperBannerFail,
  getSuperBannerDetailsSuccess,
  getSuperBannerDetailsFail,
  createSuperBannerSuccess,
  createSuperBannerFail,
  updateSuperBannerSuccess,
  updateSuperBannerFail,
  deleteSuperBannerSuccess,
  deleteSuperBannerFail,
} from "./actions"

import {
  get,
  post,
  ApiPut,
  del,
  API_URL_DEV,
  API_URL,
} from "helpers/api_methods"
import {
  Notification,
  errorNotification,
} from "../../components/Common/Notification"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"
import axios from "axios"

const { token } = useGetToken()
const { role } = useGetRole()
// SuperFeeds - This line cannot be edited or removed
function getSuperBannersAPi({ page, searchText }) {
  return get(
    `/api/v1/banner/banner/?superbanner=${true}&page=${
      page ? page : 1
    }&search=${searchText && searchText}`
  )
}

const getSuperBannerDetailsAPi = superBannerId => {
  return get(`/api/v1/banner/banner/${superBannerId}?superbanner=${true}`)
}

// const createSuperFeedsApi = ({ superfeeds }) => {
//   return post("/banner/banner/?superbanner=${true}", superfeeds);
// };

const createSuperBannersApi = async ({ createData }) => {
  console.log(createData)

  const formData = new FormData()
  {
    formData?.append(
      "image_web",
      createData?.image_web?.image,
      createData?.image_web?.image?.name
    )
    formData?.append(
      "image_app",
      createData?.image_app?.image,
      createData?.image_app?.image?.name
    )
    formData?.append("title", createData?.title),
      formData?.append("description", createData?.description),
      formData?.append("url", createData?.url)
    formData?.append("priority", createData?.priority)
  }

  try {
    let response = await axios({
      method: "post",
      url: `${API_URL}/api/v1/banner/banner/?superbanner=${true}`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "token " + token,
        Role: role,
      },
      data: formData,
      // finalValue,
    })
    return response.data
  } catch (err) {
    console.log(err)
    return err?.response?.data
  }
}

// const updateSuperFeedsApi = ({ superfeeds, superfeedsId }) => {
//   return ApiPut(`/banner/banner/?superbanner=${true}${superfeedsId}`, superfeeds)
// }

const updateSuperBannersApi = async ({ superBannerId, updateData }) => {
  console.log(updateData)
  console.log(superBannerId)

  const formData = new FormData()
  // {
  //   updateData && updateData?.image
  //   formData?.append("image", updateData?.image?.image)
  // }

  {
    updateData?.image_web &&
      updateData?.image_web?.image &&
      formData.append(
        "image_web",
        updateData?.image_web?.image && updateData?.image_web?.image,
        updateData?.image_web?.name
      )
    updateData?.image_app &&
      updateData?.image_app?.image &&
      formData?.append(
        "image_app",
        updateData?.image_app && updateData?.image_app?.image,
        updateData?.image_app?.name
      )
    formData?.append("title", updateData?.title),
      formData?.append("url", updateData?.url)
    formData?.append("priority", updateData?.priority)
  }
  try {
    let response = await axios({
      method: "patch",
      url: `${API_URL}/api/v1/banner/banner/${superBannerId}?superbanner=${true}`,
      formData,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "token " + token,
        Role: role,
      },
      data: formData,
      // finalValue,
    })
    return response.data
  } catch (err) {
    console.log(err)
    return err?.response?.data
  }
}

// function deleteSuperFeedApi({ superfeedsId }) {
//   console.log(superfeedsId)
//   return del(`/api/v1/banner/banner/?superbanner=${true}${superfeedsId}`)
// }

const deleteSuperBannerApi = ({ superFeedId }) => {
  console.log("superFeedId => ", superFeedId)
  return del(`/api/v1/banner/banner/?superbanner=${true}/${superFeedId}`)
}
const repostFeedApi = superFeedId => {
  return post(`/api/v1/feed/super-feed-repost/${superFeedId}/`)
}

function* fetchSuperBanners({ payload }) {
  try {
    const response = yield call(getSuperBannersAPi, payload)
    yield put(getSuperBannerSuccess(response))
  } catch (error) {
    yield put(getSuperBannerFail(error))
  }
}

function* fetchSuperBannerDetails({ payload: superfeedsId }) {
  try {
    const response = yield call(getSuperBannerDetailsAPi, superfeedsId)
    yield put(getSuperBannerDetailsSuccess(response))
  } catch (error) {
    yield put(getSuperBannerDetailsFail(error))
  }
}

function* onCreateSuperBanners({ payload }) {
  try {
    const response = yield call(createSuperBannersApi, payload)
    if (response?.message) {
      Notification(response?.message)
    } else if (response) {
      yield put(createSuperBannerSuccess(response))
      payload?.history?.push("/super-banners")
      doneNotification("SuperBanner Created Successfully!")
    }
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createSuperBannerFail(error))
  }
}

function* onUpdateSuperBanners({ payload }) {
  try {
    const response = yield call(updateSuperBannersApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "SuperFeeds Updated Successfully!",
        title: "",
      })
      yield put(updateSuperBannerSuccess(response))
      if (payload.history) {
        payload.history.push("/super-banners")
      }
    }
  } catch (error) {
    console.log(error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateSuperBannerFail(error?.response?.data))
  }
}

function* onDeleteSuperBanners({ payload }) {
  try {
    const response = yield call(deleteSuperBannerApi, payload)
    yield put(deleteSuperBannerSuccess(response))
    doneNotification("Super Banner Deleted Successfully")
    payload?.history?.push("/super-banners")
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification(error?.response?.data)
    }
    yield put(deleteSuperBannerFail(error?.response?.data))
    errorNotification()
  }
}

function* SuperBannersSaga() {
  yield takeEvery(GET_SUPER_BANNERS, fetchSuperBanners)
  yield takeEvery(GET_SUPER_BANNERS_DETAILS, fetchSuperBannerDetails)
  yield takeEvery(CREATE_SUPER_BANNERS, onCreateSuperBanners)
  yield takeEvery(UPDATE_SUPER_BANNERS, onUpdateSuperBanners)
  yield takeEvery(DELETE_SUPER_BANNERS, onDeleteSuperBanners)
}

export default SuperBannersSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
