export const CREATE_SUPPORT_TICKET = "CREATE_SUPPORT_TICKET"
export const CREATE_SUPPORT_TICKET_SUCCESS = "CREATE_SUPPORT_TICKET_SUCCESS"
export const CREATE_SUPPORT_TICKET_FAIL = "CREATE_SUPPORT_TICKET_FAIL"

export const UPDATE_SUPPORT_TICKET = "UPDATE_SUPPORT_TICKET"
export const UPDATE_SUPPORT_TICKET_SUCCESS = "UPDATE_SUPPORT_TICKET_SUCCESS"
export const UPDATE_SUPPORT_TICKET_FAIL = "UPDATE_SUPPORT_TICKET_FAIL"

export const GET_SUPPORT_TICKETS = "GET_SUPPORT_TICKETS"
export const GET_SUPPORT_TICKETS_SUCCESS = "GET_SUPPORT_TICKETS_SUCCESS"
export const GET_SUPPORT_TICKETS_FAIL = "GET_SUPPORT_TICKETS_FAIL"

export const GET_SUPPORT_TICKET_DETAILS = "GET_SUPPORT_TICKET_DETAILS"
export const GET_SUPPORT_TICKET_DETAILS_SUCCESS =
  "GET_SUPPORT_TICKET_DETAILS_SUCCESS"
export const GET_SUPPORT_TICKET_DETAILS_FAIL = "GET_SUPPORT_TICKET_DETAILS_FAIL"

export const GET_SUPPORT_TICKET_ITEM = "GET_SUPPORT_TICKET_ITEM"
export const GET_SUPPORT_TICKET_ITEM_SUCCESS = "GET_SUPPORT_TICKET_ITEM_SUCCESS"
export const GET_SUPPORT_TICKET_ITEM_FAIL = "GET_SUPPORT_TICKET_ITEM_FAIL"

export const CREATE_SUPPORT_TICKET_ITEM = "CREATE_SUPPORT_TICKET_ITEM"
export const CREATE_SUPPORT_TICKET_ITEM_SUCCESS =
  "CREATE_SUPPORT_TICKET_ITEM_SUCCESS"
export const CREATE_SUPPORT_TICKET_ITEM_FAIL = "CREATE_SUPPORT_TICKET_ITEM_FAIL"
