import {
  GET_TIMETABLE_LIST,
  GET_TIMETABLE_LIST_SUCCESS,
  GET_TIMETABLE_LIST_FAIL,
  UPDATE_TIMETABLE,
  UPDATE_TIMETABLE_SUCCESS,
  UPDATE_TIMETABLE_FAIL,
  PERIODS_DROPDOWN,
  PERIODS_DROPDOWN_SUCCESS,
  PERIODS_DROPDOWN_FAIL,
} from "./actionTypes"

export const getTimetableList = divisionId => ({
  type: GET_TIMETABLE_LIST,
  payload: { divisionId },
})
export const getTimetableListSuccess = timetableList => ({
  type: GET_TIMETABLE_LIST_SUCCESS,
  payload: timetableList,
})
export const getTimetableListFail = error => ({
  type: GET_TIMETABLE_LIST_FAIL,
  payload: error,
})

export const updateTimetable = (updatedTimetable, timetableId) => ({
  type: UPDATE_TIMETABLE,
  payload: { updatedTimetable, timetableId },
})

export const updateTimetableSuccess = updatedTimetable => ({
  type: UPDATE_TIMETABLE_SUCCESS,
  payload: updatedTimetable,
})

export const updateTimetableFail = error => ({
  type: UPDATE_TIMETABLE_FAIL,
  payload: error,
})

export const periodsDropdown = (day, division) => ({
  type: PERIODS_DROPDOWN,
  payload: { day, division },
})

export const periodsDropdownSuccess = periodsList => ({
  type: PERIODS_DROPDOWN_SUCCESS,
  payload: periodsList,
})

export const periodsDropdownFail = error => ({
  type: PERIODS_DROPDOWN_FAIL,
  payload: error,
})
