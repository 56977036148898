import React from "react"
import { map } from "lodash"
import { Col, Input, Row } from "reactstrap"
import { useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

const MyPagination = ({ totalPages, page, setPage, title }) => {
  const history = useHistory()
  const location = useLocation()

  const urlTitle = title || "p"

  const [pageNumber, setPageNumber] = React.useState(
    location?.state?.[urlTitle] || 1
  )

  const apiPage = () => {
    if (page >= totalPages.length) {
      return totalPages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  const OnlyFive =
    totalPages.length > 5 && parseInt(page) > 5
      ? totalPages.slice(parseInt(page) - 5, parseInt(page) + 5)
      : totalPages.slice(0, parseInt(page) + 5)

  const [showOnlyFive, setShowOnlyFive] = React.useState(OnlyFive)

  React.useEffect(() => {
    if (OnlyFive?.length <= 10) {
      setShowOnlyFive(OnlyFive)
    }
  }, [page, location?.state?.[urlTitle]])

  const handleNextClick = () => {
    setPage(page + 1)
    history.push({
      pathname: location.pathname,
      search: `${urlTitle}=${page + 1}`,
      state: {
        [urlTitle]: page + 1,
      },
    })
  }
  const handlePrevClick = () => {
    setPage(page - 1)
    history.push({
      pathname: location.pathname,
      search: `${urlTitle}=${page - 1}`,
      state: {
        [urlTitle]: page - 1,
      },
    })
  }
  const handleNumClick = item => {
    setPage(item)
    history.push({
      pathname: location.pathname,
      search: `${urlTitle}=${item}`,
      state: {
        [urlTitle]: item,
      },
    })
  }

  React.useEffect(() => {
    if (location?.state && location?.state?.page) {
      setPage(location?.state?.[urlTitle])
    }
  }, [location?.state?.[urlTitle]])

  const handleSubmit = e => {
    e.preventDefault()
    setPage(pageNumber)
    // history.push({
    //   pathname: location.pathname,
    //   search: `p=${pageNumber}`,
    //   state: {
    //     page: pageNumber,
    //   },
    // })
  }

  return (
    <Row
      className="align-items-md-center  mt-30 "
      style={{ marginTop: "2rem" }}
    >
      <Col
        style={{
          padding: 12,
          borderRadius: 8,
        }}
        className="align-items-center inner-custom-pagination  d-flex pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination pagination-mob"
      >
        {totalPages?.length > 0 && (
          <form
            onSubmit={handleSubmit}
            className="d-flex align-items-center"
            style={{ flex: 1 }}
          >
            <p className="p-0 m-0 pe-1 font-size-12 ">Page </p>
            <Input
              name="page"
              type="number"
              className=""
              min={1}
              max={totalPages?.length}
              style={{ width: 45, height: 30, border: "1px solid green" }}
              value={pageNumber}
              onChange={e => setPageNumber(e.target.value)}
            />
            <p className="p-0 m-0 px-1 font-size-12 ">
              {" "}
              of {totalPages?.length}
            </p>
            <button
              type="submit"
              style={{ width: 60, height: 30, border: "1px solid green" }}
              className={`ms-2 btn tickbtn d-flex align-items-center justify-contetn-center my-custom-success-soft btn-success `}
            >
              Go
              <i className="fas fa-angle-right ms-1 "></i>
            </button>
          </form>
        )}

        <div className="text-md-right overflowScroll form-pagination">
          {page <= 1 ? (
            <></>
          ) : (
            <div
              className="btn-group me-0 "
              role="group"
              aria-label="First group"
            >
              <span
                style={{
                  borderRadius: "50%",
                  border: "none",
                }}
                className="btn btn-outline-light text-info "
                onClick={handlePrevClick}
              >
                <i className="fas fa-angle-left"></i>
              </span>
            </div>
          )}

          {totalPages?.length >= 2 && (
            <div
              className="btn-group me-2 "
              role="group"
              aria-label="Second group"
            >
              {map(showOnlyFive, (item, index) => (
                <span
                  key={index}
                  className="btn btn-outline-info"
                  onClick={() => handleNumClick(item)}
                  style={{
                    borderRadius: "50%",
                    marginLeft: "5px",
                    marginRight: "5px",
                    border: "none",
                    backgroundColor: pageNumber == item && "#66c2ff",
                    color: pageNumber == item && "#fff",
                  }}
                >
                  {item}
                </span>
              ))}
            </div>
          )}

          {page >= totalPages?.length ? (
            <></>
          ) : (
            <div className="btn-group" role="group" aria-label="Third group">
              <span
                className="btn btn-outline-light text-info"
                style={{
                  borderRadius: "50%",
                  border: "none",
                }}
                onClick={handleNextClick}
              >
                <i className="fas fa-angle-right"></i>
              </span>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

MyPagination.propTypes = {
  totalPages: PropTypes.array,
  page: PropTypes.any,
  title: PropTypes.string,
  setPage: PropTypes.func,
}

export default MyPagination
