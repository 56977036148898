import React from "react"
import { BreadcrumbItem, Col, Container, Row } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

import DatatableTables from "./DatatableTables"

function Collections() {
  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  return (
    <>
      <MetaTags>
        <title>Collections| Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Row>
          <Col xs="12">
            <div
              className="page-title-box d-sm-flex align-items-center justify-content-end"
              style={{ marginBottom: "10px" }}
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0 d-flex justify-content-end mt-4">
                  <BreadcrumbItem>
                    <Link to="#">
                      {language === "mal" ? "ഡാഷ്ബോർഡ്" : "Dashboard"}
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">
                      {language === "mal" ? "കളക്ഷനുകൾ" : "Collections"}
                    </Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <Container fluid className="sm:p-0">
          <div className="container-fluid sm:p-0">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Collections
