import {
  GET_INSTITUTE_ACCOUNTS_SUCCESS,
  GET_INSTITUTE_ACCOUNTS_FAIL,
  GET_INSTITUTE_ACCOUNT_DETAIL_SUCCESS,
  GET_INSTITUTE_ACCOUNT_DETAIL_FAIL,
  CREATE_INSTITUTE_ACCOUNT_SUCCESS,
  CREATE_INSTITUTE_ACCOUNT_FAIL,
  UPDATE_INSTITUTE_ACCOUNT_SUCCESS,
  UPDATE_INSTITUTE_ACCOUNT_FAIL,
  DELETE_INSTITUTE_ACCOUNT_SUCCESS,
  DELETE_INSTITUTE_ACCOUNT_FAIL,
  GET_ACCOUNT_ITEM_DETAIL_SUCCESS,
  GET_ACCOUNT_ITEM_DETAIL_FAIL,
  CREATE_ACCOUNT_ITEM_SUCCESS,
  CREATE_ACCOUNT_ITEM_FAIL,
  UPDATE_ACCOUNT_ITEM_SUCCESS,
  UPDATE_ACCOUNT_ITEM_FAIL,
  DELETE_ACCOUNT_ITEM_SUCCESS,
  DELETE_ACCOUNT_ITEM_FAIL,
  GET_INSTITUTE_ACCOUNTS,
  GET_INSTITUTE_ACCOUNT_DETAIL,
  UPDATE_INSTITUTE_ACCOUNT,
  CREATE_INSTITUTE_ACCOUNT,
  DELETE_INSTITUTE_ACCOUNT,
  GET_ACCOUNT_ITEM_DETAIL,
  UPDATE_ACCOUNT_ITEM,
  CREATE_ACCOUNT_ITEM,
  DELETE_ACCOUNT_ITEM,
} from "./actionTypes"

const INIT_STATE = {
  instituteAccounts: [],
  instituteAccountDetail: {},
  accountItemDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdInstituteAccount: {},
}

const InstituteAccounts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INSTITUTE_ACCOUNTS:
    case UPDATE_INSTITUTE_ACCOUNT:
    case CREATE_INSTITUTE_ACCOUNT:
    case DELETE_INSTITUTE_ACCOUNT:
    case UPDATE_ACCOUNT_ITEM:
    case CREATE_ACCOUNT_ITEM:
    case DELETE_ACCOUNT_ITEM:
      return {
        ...state,
        loading: true,
      }
    case GET_ACCOUNT_ITEM_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_INSTITUTE_ACCOUNT_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_INSTITUTE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        instituteAccounts: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_ACCOUNTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_INSTITUTE_ACCOUNT_SUCCESS:
      return {
        ...state,
        instituteAccounts: action.payload,
        createdInstituteAccount: action.payload,
        loading: false,
      }

    case CREATE_INSTITUTE_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_ACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        instituteAccountDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_INSTITUTE_ACCOUNT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_INSTITUTE_ACCOUNT_SUCCESS:
      return {
        ...state,
        instituteAccountDetail: action.payload,
        loading: false,
      }

    case UPDATE_INSTITUTE_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_INSTITUTE_ACCOUNT_SUCCESS:
      return {
        ...state,
        instituteAccounts: state.instituteAccounts.filter(
          instituteAccount =>
            instituteAccount.id.toString() !== action.payload.id.toString()
        ),
        loading: false,
      }

    case DELETE_INSTITUTE_ACCOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_ACCOUNT_ITEM_SUCCESS:
      return {
        ...state,
        accountItems: action.payload,
        createdAccountItem: action.payload,
        loading: false,
      }

    case CREATE_ACCOUNT_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ACCOUNT_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        accountItemDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_ACCOUNT_ITEM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_ACCOUNT_ITEM_SUCCESS:
      return {
        ...state,
        accountItemDetail: action.payload,
        loading: false,
      }

    case UPDATE_ACCOUNT_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_ACCOUNT_ITEM_SUCCESS:
      return {
        ...state,
        accountItems: state.accountItems.filter(
          accountItem =>
            accountItem.id.toString() !== action.payload.id.toString()
        ),
        loading: false,
      }

    case DELETE_ACCOUNT_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default InstituteAccounts
