import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import {
  getSurveyUserDetail,
  updateSurveyUser,
  deleteSurveyUser,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

const UpdateSurveyUser = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { surveyUserDetail, language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    surveyUserDetail: state.SurveyUsers.surveyUserDetail,
    language: state.Layout.language,
  }))

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }

  const handleDeleteEvent = () => {
    dispatch(deleteSurveyUser(surveyUserDetail?.id, history))
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getSurveyUserDetail(params.id))
  }, [dispatch, params.id])

  function handleValidSubmit(values) {
    const updateSurveyUserData = {
      account: surveyUserDetail?.account,
      mahall: surveyUserDetail?.mahall,
      is_active: surveyUserDetail?.is_active,
    }
    const updateSurveyUserAccountData = {
      full_name: values.full_name,
      email: values.email,
      phone: values.phone,
    }
    dispatch(
      updateSurveyUser(
        { updateSurveyUserAccountData, ...updateSurveyUserData },
        surveyUserDetail?.id,
        history
      )
    )
  }

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <MetaTags>
        <title>Survey Users | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "സർവേ യൂസറുകൾ" : "Survey Users"}
          breadcrumbItem={language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക" : "Update"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={9}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">
                      Update Survey Users
                    </CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Full Name"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="full_name"
                                type="text"
                                value={surveyUserDetail?.full_name}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </Col>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="phone"
                                type="number"
                                label={
                                  language === "mal"
                                    ? "മൊബൈൽ നമ്പർ"
                                    : "Mobile Number"
                                }
                                value={surveyUserDetail?.phone}
                                readOnly
                              />
                            </Col>
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="email"
                                type="email"
                                label={language === "mal" ? "ഇ_മെയിൽ" : "Email"}
                                value={surveyUserDetail?.email}
                              />
                            </Col>
                          </div>
                        </div>
                      </Row>

                      <Row className="mt-5 d-flex">
                        <div className="col-6">
                          <div className="row justify-content-start">
                            <div>
                              <Button
                                type="button"
                                color="danger"
                                className="w-md"
                                onClick={handleDelete}
                              >
                                {language === "mal"
                                  ? "ഡിലീറ്റ് ചെയ്യുക"
                                  : "Delete"}
                              </Button>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="row justify-content-end">
                            <div>
                              <Button
                                type="submit"
                                color="primary"
                                className="w-md m-auto me-0 d-block"
                              >
                                {language === "mal"
                                  ? "അപ്ഡേറ്റ് ചെയ്യുക "
                                  : "Update"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateSurveyUser

UpdateSurveyUser.propTypes = {
  history: PropTypes.object,
}
