import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"
import PropTypes from "prop-types"

import {
  getEnquiries,
  // deleteEnquiry,
  getEnquiriesCards,
  getEnquiriesForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"

const AllEnquiries = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [modalEnquiryDownload, setModalEnquiryDownload] = useState(false)

  const { enquiries, loading, enquiriesCards, language, enquiriesForDownload } =
    useSelector(state => ({
      enquiries: state.Enquiries.enquiries,
      loading: state.Enquiries.loading,
      enquiriesCards: state.Enquiries.enquiriesCards,
      language: state.Layout.language,
      enquiriesForDownload: state.Enquiries.enquiriesForDownload,
    }))

  //pages
  const totalPages = Math.ceil(enquiries?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getEnquiries(searchText, pageSend()))
  }, [dispatch, page, searchText])

  useEffect(() => {
    if (modalEnquiryDownload) {
      dispatch(getEnquiriesForDownload())
    }
  }, [dispatch, modalEnquiryDownload])

  useEffect(() => {
    dispatch(getEnquiriesCards())
  }, [dispatch])

  // const handleDelete = id => {
  //   dispatch(deleteEnquiry(id, history))
  // }

  const cardData = [
    {
      title: "Total Enquiries",
      cardValue: enquiriesCards?.total_enquiries,
      iconClass: "phone",
      routeLink: "#!",
      loading: loading,
      alt: "No Enquiries",
    },
    {
      title: "New Enquiries",
      cardValue: enquiriesCards?.new_enquiries,
      iconClass: "phone-incoming",
      routeLink: "#!",
      loading: loading,
      alt: "No New Enquiries",
    },
    {
      title: "Closed Enquiries",
      cardValue: enquiriesCards?.closed_enquiries,
      iconClass: "check-circle",
      routeLink: "#!",
      loading: loading,
      alt: "No Closed Enquiries",
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: "No.",
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "full_name",
      text: "Name",
    },
    {
      dataField: "phone",
      text: "Phone No.",
    },
    {
      dataField: "mahall_name",
      text: "Mahall Name",
    },
    {
      dataField: "mahall_place",
      text: "Place",
    },
    {
      dataField: "statusData",
      text: "status",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const enquiriesData = map(enquiries?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,

    date: moment(item?.date_added).format("DD/MM/YYYY hh:mm A"),
    action: (
      <div className="m-0">
        <Link to={`/enquiry/update/${item?.id}`} className="m-0">
          <Button type="button" color="white" className=" btn m-0 p-0">
            <i
              className="bx bx-pencil me-2"
              style={{ color: "green", fontSize: "18px" }}
            />
          </Button>
        </Link>

        {/* <Button
          type="button"
          color="white"
          className=" m-0 btn "
          onClick={() => { handleDelete(item.id) }}
        >
          <i className="bx bx-trash me-2" style={{ color: "red", fontSize: "18px" }} />
        </Button> */}
      </div>
    ),

    statusData: (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          `${
            item?.status == "new"
              ? "success"
              : item?.status == "pending"
              ? "warning"
              : item?.status == "checked"
              ? "primary"
              : item?.status == "confirmed"
              ? "secondary"
              : item?.status == "closed"
              ? "danger"
              : ""
          }`
        }
        color={"primary"}
        pill
      >
        {item?.status}
      </Badge>
    ),
  }))

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    { label: `${language === "mal" ? "പേര്" : "Name"}`, key: "name" },
    { label: `${language === "mal" ? "തീയതി" : "Date"}`, key: "date_added" },
    { label: `${language === "mal" ? "ഫോൺ" : "Phone"}`, key: "phone" },
    { label: `${language === "mal" ? "ഇ-മെയിൽ" : "E-mail"}`, key: "email" },
    {
      label: `${language === "mal" ? "മഹല്ലിന്റെ പേര്" : "Mahall Name"}`,
      key: "mahall_name",
    },
    {
      label: `${language === "mal" ? "മഹല്ല് സ്ഥലം" : "Mahall Place"}`,
      key: "mahall_place",
    },
    {
      label: `${
        language === "mal" ? "മഹല്ല് രജിസ്റ്റർ നമ്പർ" : "Mahall Register Number"
      }`,
      key: "mahall_register_number",
    },
    {
      label: `${language === "mal" ? "സ്റ്റാറ്റസ്" : "Status"}`,
      key: "status",
    },
    { label: `${language === "mal" ? "വിവരണം" : "Note"}`, key: "note" },
  ]

  const enquiriesExcelData = map(enquiriesForDownload, (item, index) => ({
    ...item,
    no: (pageSend() - 1) * limit + index + 1,
    name: item.full_name,
    date_added:
      item.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
    phone: item.phone,
    email: item.email,
    mahall_name: item.mahall_name,
    mahall_place: item.mahall_place,
    mahall_register_number: item.mahall_register_number,
    status: item.status,
    note: item?.note,
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceEnquirySearch = debounce(value => setSearchText(value), 600)

  const fileName = "khaf-smart-mahall-enquiries-list.csv"

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalEnquiryDownload}
        onCloseclick={() => setModalEnquiryDownload(false)}
        excelData={enquiriesExcelData}
        headers={headers}
        list={enquiriesForDownload}
        fileName={fileName}
        loading={loading}
      />
      <Row>
        {cardData?.map((item, key) => (
          <Col lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink && item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue > 0 && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p>
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Col sm="12" md="12" lg="12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={enquiriesData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceEnquirySearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <div className="col-md-8 d-flex justify-content-end">
                        <div>
                          <Button
                            style={{
                              width: language === "mal" ? "150px" : "130px",
                              border: "bold",
                            }}
                            className="btn-md me-2 mt-0  bg-white text-khaf"
                            onClick={() => setModalEnquiryDownload(true)}
                          >
                            <i
                              className="bx bx-download mx-1"
                              style={{ fontSize: "18px", color: "#009846" }}
                            />
                            {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                          </Button>
                          <Link
                            to="/enquiry/create"
                            style={{ width: "120px" }}
                            className="w-md btn btn-success me-0"
                          >
                            + New Enquiry
                          </Link>
                        </div>
                      </div>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllEnquiries

AllEnquiries.propTypes = {
  history: PropTypes.object,
}
