import React from "react"
import { Row } from "reactstrap"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import LetterHead from "components/Common/Letterhead/letterHead"
import LetterHeadWithValues from "components/Common/Letterhead/letterHeadWithValues"

const DeathCertificateDownloadPdf = ({
  pdfRef,
  activeTab,
  mahall,
  language,
}) => {
  const today = moment(new Date()).format("DD-MM-yyyy")

  console.log(mahall)

  //   const downloadPdf = () => {
  //     const input = pdfRef.current
  //     const img = new Image()
  //     const imgSrc = `${userDetail?.mahall?.logo}`
  //     img.src = imgSrc
  //     img.onload = () => {
  //       html2canvas(input).then(canvas => {
  //         const imgData = canvas.toDataURL('image/png')
  //         const pdf = new jsPDF('p', 'mm', 'a4', true)
  //         const pdfWidth = pdf.internal.pageSize.getWidth()
  //         const pdfHeight = pdf.internal.pageSize.getHeight()
  //         const imgWidth = canvas.width
  //         const imgHeight = canvas.height
  //         const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
  //         const imgX = (pdfWidth - imgWidth * ratio) / 2
  //         const imgY = 30
  //         pdf.addImage(
  //           imgData,
  //           'PNG',
  //           imgX,
  //           imgY,
  //           imgWidth * ratio,
  //           imgHeight * ratio,
  //         )
  //         pdf.save('nikah.pdf')
  //       })
  //     }
  //   }

  //   useEffect(() => {
  //     dispatch(getNikahRegistrationDetail(nikkahId))
  //   }, [])

  //   const callFunctionFromOtherPage = () => {
  //     console.log('Function called from the other page!')
  //   }

  //   useEffect(() => {
  //     // const params = new URLSearchParams(window.location.search)
  //     console.log(location)
  //     const shouldCallFunction = location?.search

  //     if (shouldCallFunction === 'true') {
  //       downloadPdf()
  //     }
  //   }, [])

  // function downloadPDF() {
  //   var doc = new jsPDF("p", "pt", "a4")
  //   // const windowWidth = document.documentElement.clientWidth;
  //   // const windowHeight = document.documentElement.clientHeight;
  //   const html = document.querySelector("#pdf-content")

  //   doc.html(html, {
  //     callback: function () {
  //       doc.save("window.pdf")
  //     },
  //     x: 0,
  //     y: 0,
  //   })
  //   // pdfdoc.fromHTML($('#pdf-content').html(), 10, 10, '',
  //   //   function (callback) { pdfdoc.save('First.pdf'); });

  //   // const doc = new jsPDF();
  //   // const windowWidth = document.documentElement.clientWidth;
  //   // const windowHeight = document.documentElement.clientHeight;
  //   // const html = document.querySelector('#pdf-content');

  //   // doc.html(html, {
  //   //   callback: function () {
  //   //     doc.save('window.pdf');
  //   //   },
  //   //   x: 0,
  //   //   y: 0,
  //   // });
  // }

  // function generatePDF() {
  //   const doc = new jsPDF();
  //   doc.text('Hello, World!', 10, 10);
  //   doc.save('my-document.pdf');
  // }

  // const onButtonClick = () => {
  //   // using Java Script method to get PDF file
  //   fetch(pdfFile).then(response => {
  //     response.blob().then(blob => {
  //       console.log(blob);
  //       // Creating new object of PDF file
  //       const fileURL = window.URL.createObjectURL(blob);
  //       console.log("url", fileURL);
  //       // Setting various property values
  //       let alink = document.createElement('a');
  //       alink.href = fileURL;
  //       alink.download = pdfFile;
  //       alink.click();
  //     })
  //   })
  // }

  // const downloadPDF = () => {
  //   const capture = document.querySelector('.actual-receipt');
  //   html2canvas(capture).then((canvas) => {
  //     const imgData = canvas.toDataURL('img/png');
  //     const doc = new jsPDF('p', 'mm', 'a4');
  //     const componentWidth = doc.internal.pageSize.getWidth();
  //     const componentHeight = doc.internal.pageSize.getHeight();
  //     doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
  //     doc.save('receipt.pdf');
  //   })
  // }

  // function generatePDF() {
  //   const html = document.documentElement.innerHTML;
  //   const documentDefinition = { content: html };
  //   const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  //   return pdfDocGenerator;
  // }

  // function downloadPDF() {
  //   const pdfDocGenerator = generatePDF();
  //   pdfDocGenerator.getBlob((blob) => {
  //     saveAs(blob, 'my-pdf-file.pdf');
  //   });
  // }

  // cut responsive

  const inputDateString = moment(mahall?.death_date).format("DD/MM/YYYY")

  var parts = inputDateString.split("/")
  var day = parseInt(parts[0])
  var month = parseInt(parts[1])
  var year = parseInt(parts[2])

  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  var monthName = monthNames[month - 1]

  var dayWithSuffix =
    day +
    (day % 10 == 1 && day != 11
      ? "st"
      : day % 10 == 2 && day != 12
      ? "nd"
      : day % 10 == 3 && day != 13
      ? "rd"
      : "th")

  return (
    <>
      <div
        style={{
          paddingLeft: "50px",
          paddingRight: "50px",
          position: "absolute",
          top: -1400,
          width: "780px",
        }}
        ref={pdfRef}
      >
        <Row>
          <div className="d-flex justify-content-center">
            {/* <div className="mt-1" style={{ border: 'none' }}>
              <img
                src={userDetail?.mahall?.logo && userDetail?.mahall?.logo}
                alt=""
                className="img-thumbnail logo-certificate"
                style={{ width: '100px', height: '100px' }}
              />
            </div> */}
            {/* <div>
              <h2 className="text-center text-black">
                <b>
                  {" "}
                  {language === "mal"
                    ? userDetail?.mahall?.mahall_malayalam_name
                      ? userDetail?.mahall?.mahall_malayalam_name
                      : userDetail?.mahall?.mahall_english_name
                    : userDetail?.mahall?.mahall_english_name}
                </b>
              </h2>
              <p className="text-black text-center">
                {`${
                  language === "mal"
                    ? userDetail?.mahall?.lsg_malayalam_name
                      ? userDetail?.mahall?.lsg_malayalam_name
                      : userDetail?.mahall?.lsg_english_name
                    : userDetail?.mahall?.lsg_english_name
                } 
                        ${
                          language === "mal"
                            ? userDetail?.mahall?.lsg_type_malayalam
                              ? userDetail?.mahall?.lsg_type_malayalam
                              : userDetail?.mahall?.lsg_type_english
                            : userDetail?.mahall?.lsg_type_english
                        },`}{" "}
                {`${
                  userDetail?.mahall?.post_office
                    ? userDetail?.mahall?.post_office
                    : ""
                }-${
                  userDetail?.mahall?.pin_code
                    ? userDetail?.mahall?.pin_code
                    : ""
                }, `}{" "}
                <br />
                {language === "mal"
                  ? userDetail?.mahall?.village_malayalam_name
                    ? userDetail?.mahall?.village_malayalam_name
                    : userDetail?.mahall?.village_english_name
                  : userDetail?.mahall?.village_english_name}
                ,{" "}
                {language === "mal"
                  ? userDetail?.mahall?.district_malayalam_name
                    ? userDetail?.mahall?.district_malayalam_name
                    : userDetail?.mahall?.district_english_name
                  : userDetail?.mahall?.district_english_name}
                ,{" "}
                {language === "mal"
                  ? userDetail?.mahall?.state_malayalam_name
                    ? userDetail?.mahall?.state_malayalam_name
                    : userDetail?.mahall?.state_english_name
                  : userDetail?.mahall?.state_english_name}
                ,{" "}
                {`${language === "mal" ? "ഫോൺ" : "Mob No:"} : ${
                  userDetail?.phone && userDetail?.phone
                }`}
              </p>
            </div> */}
            {/* <LetterHeadWithValues
              mahall={mahall?.mahall_details}
              language={language}
            />
          </div>
          <div className="d-flex justify-content-between pt-5">
            <h5 className="text-black">
              {language === "mal" ? "റെജി നം : " : "Reg No : "}{" "}
              {mahall?.mahall_custom_id}{" "}
            </h5>
            <h5 className="text-black">
              {language === "mal" ? "തീയതി : " : "Date : "} {today}
            </h5>
          </div>
          <h1 className="text-center text-black pt-3">
            {language === "mal" ? "മരണ സർട്ടിഫിക്കറ്റ്" : "DEATH CERTIFICATE"}
          </h1> */}
            {/* <p className="p-4" style={{ fontSize: 24 }}>
            <span className="d-flex aling-items-center justify-content-center">
              {language === "mal"
                ? "ഈ മരണം ഔദ്യോഗികമായി സ്ഥിരീകരിച്ചു"
                : "This is Acknowledge the Death of "}{" "}
              <br />
            </span>
            <span className=" text-dark flex-center">
              <b>
                {" "}
                {mahall?.family_member_name} <br />
              </b>
            </span>{" "}
            <span className="flex-center ">
              {language === "mal" ? "ദിവസം" : "on the"} &nbsp;
              <span className="text-dark">
                <b>{dayWithSuffix}</b> &nbsp;
              </span>{" "}
              {language === "mal" ? "മാസം" : " day of "} &nbsp;
              <span className="text-dark">
                <b>{monthName}</b> &nbsp;
              </span>{" "}
              {language === "mal" ? "വർഷത്തിൽ" : "in the year"} &nbsp;
              <span className="text-dark">
                <b>{year}</b>. &nbsp;
              </span>
              <br />{" "}
            </span>{" "}
            <span className="flex-center text-dark">
              {" "}
              {language === "mal" ? "സ്ഥലം" : "at"} : &nbsp;{" "}
              <b>{mahall?.death_place}</b>
            </span>
          </p>
          <div className="flex-end">
            <p className="font-size-16 text-dark">
              {language === "mal"
                ? "പ്രസിഡന്റിന്റെ ഒപ്പ്"
                : "Signature of President"}{" "}
            </p>
          </div> */}

            {window?.width >= 420 ? (
              <Row className="mt-2">
                <LetterHeadWithValues
                  mahall={mahall?.mahall_details}
                  language={language}
                />
                <div className="d-flex justify-content-between pt-5">
                  <h5 className="text-black">
                    {language === "mal" ? "റെജി നം : " : "Reg No : "}{" "}
                    {mahall?.mahall_custom_id}{" "}
                  </h5>
                  <h5 className="text-black">
                    {language === "mal" ? "തീയതി : " : "Date : "} {today}
                  </h5>
                </div>
                <h1 className="text-center text-black pt-5">
                  {language === "mal"
                    ? "മരണ സർട്ടിഫിക്കറ്റ്"
                    : "DEATH CERTIFICATE"}
                </h1>
                <p className="p-4" style={{ fontSize: 24 }}>
                  <span className="d-flex aling-items-center justify-content-center">
                    {language === "mal"
                      ? "ഈ മരണം ഔദ്യോഗികമായി സ്ഥിരീകരിച്ചു"
                      : "This is Acknowledge the Death of "}{" "}
                    <br />
                  </span>
                  <span className=" text-dark flex-center">
                    <b>
                      {" "}
                      {mahall?.family_member_name} <br />
                    </b>
                  </span>{" "}
                  <span className="flex-center ">
                    {language === "mal" ? "ദിവസം" : "on the"} &nbsp;
                    <span className="text-dark">
                      <b>{dayWithSuffix}</b> &nbsp;
                    </span>{" "}
                    {language === "mal" ? "മാസം" : " day of "} &nbsp;
                    <span className="text-dark">
                      <b>{monthName}</b> &nbsp;
                    </span>{" "}
                    {language === "mal" ? "വർഷത്തിൽ" : "in the year"} &nbsp;
                    <span className="text-dark">
                      <b>{year}</b>. &nbsp;
                    </span>
                    <br />{" "}
                  </span>{" "}
                  <span className="flex-center text-dark">
                    {" "}
                    {language === "mal" ? "സ്ഥലം" : "at"} : &nbsp;{" "}
                    <b>{mahall?.death_place}</b>
                  </span>
                </p>
                <div className="flex-end">
                  <p className="font-size-16 text-dark">
                    {language === "mal"
                      ? "പ്രസിഡന്റിന്റെ ഒപ്പ്"
                      : "Signature of President"}{" "}
                  </p>
                </div>
              </Row>
            ) : (
              <Row className="mt-2">
                <LetterHeadWithValues
                  mahall={mahall?.mahall_details}
                  language={language}
                />
                <div className="d-flex justify-content-between pt-5">
                  <h5 className="text-black">
                    {language === "mal" ? "റെജി നം : " : "Reg No : "}{" "}
                    {mahall?.mahall_custom_id}{" "}
                  </h5>
                  <h5 className="text-black">
                    {language === "mal" ? "തീയതി : " : "Date : "} {today}
                  </h5>
                </div>
                <h1 className="text-center text-black pt-5">
                  {language === "mal"
                    ? "മരണ സർട്ടിഫിക്കറ്റ്"
                    : "DEATH CERTIFICATE"}
                </h1>
                <p className="p-4" style={{ fontSize: 18 }}>
                  <span className="">
                    {language === "mal"
                      ? "ഈ മരണം ഔദ്യോഗികമായി സ്ഥിരീകരിച്ചു"
                      : "This is Acknowledge the Death of "}{" "}
                    <br />
                  </span>
                  <span className="">
                    <b> {mahall?.family_member_name}</b>
                  </span>{" "}
                  <span className=" ">
                    {language === "mal" ? "ദിവസം" : "on the"} &nbsp;
                    <span className="text-dark">
                      <b>{dayWithSuffix}</b> &nbsp;
                    </span>{" "}
                    {language === "mal" ? "മാസം" : " day of "} &nbsp;
                    <span className="text-dark">
                      <b>{monthName}</b> &nbsp;
                    </span>{" "}
                    {language === "mal" ? "വർഷത്തിൽ" : "in the year"} &nbsp;
                    <span className="text-dark">
                      <b>{year}</b>. &nbsp;
                    </span>
                  </span>{" "}
                  <br />
                  <span className=" text-dark">
                    {" "}
                    {language === "mal" ? "സ്ഥലം" : "at"} : &nbsp;{" "}
                    <b>{mahall?.death_place}</b>
                  </span>
                </p>
                <div className="flex-end">
                  <p className="font-size-16 text-dark">
                    {language === "mal"
                      ? "പ്രസിഡന്റിന്റെ ഒപ്പ്"
                      : "Signature of President"}{" "}
                  </p>
                </div>
              </Row>
            )}
          </div>
        </Row>
      </div>
    </>
  )
}

export default DeathCertificateDownloadPdf

DeathCertificateDownloadPdf.propTypes = {
  pdfRef: PropTypes.any,
  activeTab: PropTypes.number,
  mahall: PropTypes?.object,
  language: PropTypes.any,
}
