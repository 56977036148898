import {
  GET_VARISANGYA_SPLIT_LEDGERS,
  GET_VARISANGYA_SPLIT_LEDGERS_SUCCESS,
  GET_VARISANGYA_SPLIT_LEDGERS_FAIL,
  CREATE_VARISANGYA_SPLIT_LEDGER,
  CREATE_VARISANGYA_SPLIT_LEDGER_SUCCESS,
  CREATE_VARISANGYA_SPLIT_LEDGER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  varisangyaSplitLedgers: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdVarisangyaSplitLedger: {},
}

const VarisangyaSplitLedgers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VARISANGYA_SPLIT_LEDGERS:
    case CREATE_VARISANGYA_SPLIT_LEDGER:
      return {
        ...state,
        loading: true,
      }

    case GET_VARISANGYA_SPLIT_LEDGERS_SUCCESS:
      return {
        ...state,
        varisangyaSplitLedgers: action.payload,
        loading: false,
      }
    case GET_VARISANGYA_SPLIT_LEDGERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_VARISANGYA_SPLIT_LEDGER_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdVarisangyaSplitLedger: action.payload,
        loading: false,
      }

    case CREATE_VARISANGYA_SPLIT_LEDGER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default VarisangyaSplitLedgers
