/* CLASSES */
export const GET_CLASSES = "GET_CLASSES"
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS"
export const GET_CLASSES_FAIL = "GET_CLASSES_FAIL"

/* CLASS_DROPDOWN*/
export const GET_CLASS_DROPDOWN = "GET_CLASS_DROPDOWN"
export const GET_CLASS_DROPDOWN_SUCCESS = "GET_CLASS_DROPDOWN_SUCCESS"
export const GET_CLASS_DROPDOWN_FAIL = "GET_CLASS_DROPDOWN_FAIL"

/* CLASS  DETAIL*/
export const GET_CLASS_DETAIL = "GET_CLASS_DETAIL"
export const GET_CLASS_DETAIL_SUCCESS = "GET_CLASS_DETAIL_SUCCESS"
export const GET_CLASS_DETAIL_FAIL = "GET_CLASS_DETAIL_FAIL"

/**
 * add CLASS
 */
export const CREATE_CLASS = "CREATE_CLASS"
export const CREATE_CLASS_SUCCESS = "CREATE_CLASS_SUCCESS"
export const CREATE_CLASS_FAIL = "CREATE_CLASS_FAIL"

/**
 * Edit CLASS
 */
export const UPDATE_CLASS = "UPDATE_CLASS"
export const UPDATE_CLASS_SUCCESS = "UPDATE_CLASS_SUCCESS"
export const UPDATE_CLASS_FAIL = "UPDATE_CLASS_FAIL"

/**
 * Delete CLASS
 */
export const DELETE_CLASS = "DELETE_CLASS"
export const DELETE_CLASS_SUCCESS = "DELETE_CLASS_SUCCESS"
export const DELETE_CLASS_FAIL = "DELETE_CLASS_FAIL"
