import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
//actions
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import moment from "moment"

import FormEditors from "../../../../components/Common/FormEditor"

import { createCommitteeMeeting } from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateMahallCommitteeMeeting = ({ history }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const committeeId = location?.search?.split("?committeeid=")[1]

  const { loading, language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    error: state.Mahalls.error,
    language: state.Layout.language,
    committeeDetail: state.Committees.committeeDetail?.committee,
  }))

  const today = moment(new Date()).format("yyyy-MM-DD")

  // console.log(committeeId);

  const [agenda, setAgenda] = useState("")

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    // console.log(values)
    const createCommitteeMeetingsData = {
      committee: committeeId,
      title: values.title,
      meeting_date: values.meeting_date,
      agenda: agenda,
      place: values.place,
      description: values.description,
    }
    // console.log(createCommitteeMeetingsData);
    dispatch(createCommitteeMeeting(createCommitteeMeetingsData, history))
  }

  return (
    <>
      <MetaTags>
        <title>Meetings | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "യോഗം" : "Meeting"}
          breadcrumbItem={language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4 mb-4">Fill this form</CardTitle> */}
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Title"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="title"
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <Row>
                            <Col md="6">
                              <div className="col mb-4">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-12 col-form-label"
                                >
                                  {language === "mal"
                                    ? "മീറ്റിംഗ് തീയതി"
                                    : "Meeting Date"}
                                  <span className="text-danger">&#42;</span>
                                </Label>
                                <AvField
                                  id="horizontal-firstname-Input"
                                  name="meeting_date"
                                  type="date"
                                  value={today}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  // label={language === "mal" ? "മൊബൈൽ നമ്പർ" : "Meeting Date"}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="col mb-4">
                                <Label
                                  htmlFor="horizontal-firstname-Input"
                                  className="col-sm-12 col-form-label"
                                >
                                  {language === "mal" ? "സ്ഥലം" : "Place"}
                                </Label>
                                <AvField
                                  id="horizontal-firstname-Input"
                                  name="place"
                                  type="text"
                                  // label={language === "mal" ? "മൊബൈൽ നമ്പർ" : "Place"}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-12">
                          <div className="col mb-4">
                            <AvField
                              id="horizontal-firstname-Input"
                              name="description"
                              type="textarea"
                              label={
                                language === "mal" ? "വിവരണം" : "Description"
                              }
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div className="col-12 mb-3">
                            <Label htmlFor="lbltype" className="form-label">
                              {language === "mal" ? "അജണ്ട" : "Agenda"}
                            </Label>
                            <Col sm="12" className="mt-0">
                              <FormEditors
                                content={agenda}
                                setContent={setAgenda}
                              />
                            </Col>
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex justify-content-end mt-5">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateMahallCommitteeMeeting

CreateMahallCommitteeMeeting.propTypes = {
  history: PropTypes.object,
}
