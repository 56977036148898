import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import moment from "moment"
//actions
import axios from "axios"

import {
  getMahallDetail,
  updateMahallFail,
  updateMahallSuccess,
  getVillageDropdown,
  getLsgDropdown,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { API_URL } from "helpers/api_methods"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UpdateGroupAdminMahall = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { mahallDetail, loading, searchVillages, searchLsgs } = useSelector(
    state => ({
      loading: state.Mahalls.loading,
      mahallDetail: state.Mahalls.mahallDetail,
      searchVillages: state.Villages.searchVillages,
      searchLsgs: state.Lsgs.searchLsgs,
    })
  )

  const [village, setVillage] = useState("Search Village")
  const [villageId, setVillageId] = useState("")
  const [searchText, setSearchText] = useState("")

  const [lsg, setLsg] = useState("Search LSG")
  const [lsgId, setLsgId] = useState("")
  const [searchTextLsg, setSearchTextLsg] = useState("")

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })

  const versions = ["basic", "standard", "premium"]

  function handleValidSubmit(values) {
    updateMahallNew(
      selectedFile,
      values,
      villageId,
      lsgId,
      mahallDetail,
      dispatch,
      setCustomLoad
    )
    // window.scroll(0, 0)
  }

  useEffect(() => {
    dispatch(getMahallDetail(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    setVillage(mahallDetail?.village_english_name)
    setVillageId(mahallDetail?.village || "")
  }, [dispatch, mahallDetail])

  useEffect(() => {
    setLsg(mahallDetail?.lsg_english_name)
    setLsgId(mahallDetail?.lsg || "")
  }, [dispatch, mahallDetail])

  useEffect(() => {
    dispatch(getVillageDropdown(searchText))
  }, [searchText, dispatch])

  useEffect(() => {
    dispatch(getLsgDropdown(searchTextLsg))
  }, [searchTextLsg, dispatch])

  function handlerFinalValueVillage(event) {
    dispatch(getVillageDropdown(event.value))
    setVillageId(event.value)
    setVillage(event.label)
  }

  function handlerFinalValueLsg(event) {
    dispatch(getLsgDropdown(event.value))
    setLsgId(event.value)
    setLsg(event.label)
  }

  const optionGroupVillage = [
    {
      options: searchVillages?.map((results, index) => ({
        key: index,
        label: results.village_english_name,
        value: results.id,
      })),
    },
  ]

  const optionGroupLsg = [
    {
      options: searchLsgs?.map((results, index) => ({
        key: index,
        label: results.lsg_english_name,
        value: results.id,
      })),
    },
  ]

  const handleEntersVillage = textEntered => {
    setSearchText(textEntered)
  }

  const handleEntersLsg = textEntered => {
    setSearchTextLsg(textEntered)
  }

  const changeHandler = e => {
    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })
  }

  return (
    <>
      <MetaTags>
        <title>Mahalls | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Mahalls" breadcrumbItem="Update" />
        <Container fluid>
          <div className="container-fluid mt-5">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Update Mahall</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <Label>
                            Name (English)
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="mahall_english_name"
                            type="text"
                            // label="Name (English)"
                            value={mahallDetail?.mahall_english_name || ""}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col className="mb-4">
                          <AvField
                            name="mahall_malayalam_name"
                            type="text"
                            label="Name (മലയാളം)"
                            value={mahallDetail?.mahall_malayalam_name || ""}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <div className="row">
                            <AvField
                              type="select"
                              name="version"
                              label="Version"
                              value={mahallDetail?.version || ""}
                            >
                              <option>Choose a version ...</option>
                              {versions.map((item, key) => (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>
                        <Col sm="12" md="6">
                          <AvField
                            name="register_number"
                            type="text"
                            label="Register Number"
                            value={mahallDetail?.register_number || ""}
                          />
                        </Col>
                      </div>

                      <div className="row mb-1  ">
                        <Col sm="12" md="6" className="mb-3">
                          <AvField
                            name="email"
                            type="email"
                            label="Email"
                            value={mahallDetail?.email || ""}
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <AvField
                            name="phone_number"
                            type="number"
                            label="Phone Number"
                            value={mahallDetail?.phone_number || ""}
                            validate={{
                              minLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                              maxLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-1">
                        <Col sm="12" md="6" className="mb-3">
                          <AvField
                            name="established_date"
                            type="date"
                            label="Established Date"
                            value={
                              mahallDetail?.established_date
                                ? moment(mahallDetail?.established_date).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""
                            }
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <Label>
                            Place<span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="place"
                            type="text"
                            title="Place"
                            // label="Place"
                            value={mahallDetail?.place || ""}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-1">
                        <Col sm="12" md="6" className="mb-3">
                          <Label>
                            {" "}
                            Village<span className="text-danger">
                              &#42;
                            </span>{" "}
                          </Label>
                          <Select
                            onInputChange={handleEntersVillage}
                            placeholder={village}
                            value={village}
                            options={optionGroupVillage}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValueVillage}
                            type="text"
                            required="required"
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <Label>
                            {" "}
                            LSG<span className="text-danger">&#42;</span>{" "}
                          </Label>
                          <Select
                            onInputChange={handleEntersLsg}
                            placeholder={lsg}
                            value={lsg}
                            options={optionGroupLsg}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValueLsg}
                            type="text"
                            title="lsg"
                            required="required"
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col sm="12" md="6">
                          <AvField
                            name="post_office"
                            type="text"
                            label="Post Office"
                            value={mahallDetail?.post_office || ""}
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <AvField
                            name="pin_code"
                            type="text"
                            label="Pin Code"
                            value={mahallDetail?.pin_code || ""}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="description"
                            type="textarea"
                            label="Description"
                            value={mahallDetail?.description || ""}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <Label> Logo </Label>
                          <AvField
                            name="logo"
                            type="file"
                            onChange={changeHandler}
                          />
                        </Col>
                      </div>
                      {mahallDetail?.logo && (
                        <img
                          src={mahallDetail?.logo}
                          alt={mahallDetail?.mahall_english_name}
                          width="150px"
                          height="150px"
                        />
                      )}

                      <div className="d-flex justify-content-end">
                        <Button type="submit" color="primary" className="w-md">
                          {loading ||
                            (customLoad && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            ))}
                          Update
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateGroupAdminMahall

UpdateGroupAdminMahall.propTypes = {
  history: PropTypes.object,
}

function updateMahallNew(
  selectedFile,
  values,
  villageId,
  lsgId,
  mahallDetail,
  dispatch,
  setCustomLoad
) {
  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append("logo", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("mahall_english_name", values.mahall_english_name)
  formData.append("mahall_malayalam_name", values.mahall_malayalam_name)
  formData.append("register_number", values.register_number)
  formData.append("email", values.email)
  formData.append("phone_number", values.phone_number)
  formData.append("established_date", values.established_date)
  formData.append("place", values.place)
  formData.append("village", villageId)
  formData.append("lsg", lsgId)
  formData.append("post_office", values.post_office)
  formData.append("pin_code", values.pin_code)
  formData.append("description", values.description)
  formData.append("version", values.version)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .put(`${API_URL}/api/v1/mahall/mahall/${mahallDetail.id}/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      dispatch(updateMahallSuccess(res.data))
      history.back()
      doneNotification()
      setCustomLoad(false)
    })
    .catch(err => {
      updateMahallFail(err)
      errorNotification()
      setCustomLoad(false)
    })
}
