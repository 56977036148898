import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COMMITTEE_MEETINGS,
  GET_COMMITTEE_MEETING_DROPDOWN,
  GET_COMMITTEE_MEETING_DETAIL,
  CREATE_COMMITTEE_MEETING,
  UPDATE_COMMITTEE_MEETING,
  DELETE_COMMITTEE_MEETING,
} from "./actionTypes"
import {
  getCommitteeMeetingsSuccess,
  getCommitteeMeetingsFail,
  getCommitteeMeetingDropdownSuccess,
  getCommitteeMeetingDropdownFail,
  getCommitteeMeetingDetailSuccess,
  getCommitteeMeetingDetailFail,
  createCommitteeMeetingSuccess,
  createCommitteeMeetingFail,
  updateCommitteeMeetingSuccess,
  updateCommitteeMeetingFail,
  deleteCommitteeMeetingSuccess,
  deleteCommitteeMeetingFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getCommitteeMeetingsAPi = ({ committeeId, searchText, page }) => {
  if (committeeId) {
    return get(
      `/api/v1/committee/committee_meeting/?committee=${
        committeeId && committeeId
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  } else if (searchText) {
    return get(
      `/api/v1/committee/committee_meeting/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(`/api/v1/committee/committee_meeting/?page=${page ? page : 1}`)
  }
}
const getCommitteeMeetingDropdownAPi = searchText => {
  return get(
    `/api/v1/committee/committee_meeting_dropdown?search=${
      searchText && searchText
    }`
  )
}
const getCommitteeMeetingDetailsAPi = committeeMeetingId => {
  return get(`/api/v1/committee/committee_meeting/${committeeMeetingId}/`)
}
const createCommitteeMeetingApi = ({ committeeMeeting }) => {
  return post("/api/v1/committee/committee_meeting/", committeeMeeting)
}
const updateCommitteeMeetingApi = ({
  committeeMeetingId,
  committeeMeeting,
}) => {
  return ApiPut(
    `/api/v1/committee/committee_meeting/${committeeMeetingId}/`,
    committeeMeeting
  )
}
const deleteCommitteeMeetingApi = ({ committeeMeetingId }) => {
  return del(`/api/v1/committee/committee_meeting/${committeeMeetingId}/`)
}

function* fetchCommitteeMeetings({ payload }) {
  try {
    const response = yield call(getCommitteeMeetingsAPi, payload)
    if (response && !response?.error) {
      yield put(getCommitteeMeetingsSuccess(response))
    } else {
      yield put(getCommitteeMeetingsFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getCommitteeMeetingsFail(error))
  }
}

function* fetchCommitteeMeetingDropdown({ payload }) {
  try {
    const response = yield call(getCommitteeMeetingDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getCommitteeMeetingDropdownSuccess(response))
    } else {
      yield put(getCommitteeMeetingDropdownFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getCommitteeMeetingDropdownFail(error))
  }
}

function* fetchCommitteeMeetingDetail({ committeeMeetingId }) {
  try {
    const response = yield call(
      getCommitteeMeetingDetailsAPi,
      committeeMeetingId
    )
    if (response && !response?.error) {
      yield put(getCommitteeMeetingDetailSuccess(response))
    } else {
      yield put(getCommitteeMeetingDetailFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getCommitteeMeetingDetailFail(error))
  }
}
function* onCreateCommitteeMeeting({ payload }) {
  try {
    const response = yield call(createCommitteeMeetingApi, payload)
    if (response && !response?.error) {
      yield put(createCommitteeMeetingSuccess(response))
      payload.history.goBack()
      // TODO: check the route againe -> /api/v1/committee/meetings
      doneNotification("Created Successfully")
    } else {
      yield put(createCommitteeMeetingFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createCommitteeMeetingFail(error))
    errorNotification()
  }
}

function* onUpdateCommitteeMeeting({ payload }) {
  try {
    const response = yield call(updateCommitteeMeetingApi, payload)
    if (response && !response?.error) {
      yield put(updateCommitteeMeetingSuccess(response))
      payload.history.goBack()
      doneNotification("Updated Successfully")
    } else {
      yield put(updateCommitteeMeetingFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateCommitteeMeetingFail(error))
    errorNotification()
  }
}

function* onDeleteCommitteeMeeting({ payload }) {
  console.log("payload: ", payload)
  console.log("payload.history: ", payload.history)
  console.log("payload.committeeId: ", payload.committeeId)
  try {
    const response = yield call(deleteCommitteeMeetingApi, payload)
    if (response && !response?.error) {
      yield put(deleteCommitteeMeetingSuccess(response))
      payload.history.push(`/committees/${payload.committeeId}`)
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteCommitteeMeetingFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteCommitteeMeetingFail(error))
    console.log("error: ", error)
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* CommitteeMeetingsSaga() {
  yield takeEvery(GET_COMMITTEE_MEETINGS, fetchCommitteeMeetings)
  yield takeEvery(GET_COMMITTEE_MEETING_DETAIL, fetchCommitteeMeetingDetail)
  yield takeEvery(GET_COMMITTEE_MEETING_DROPDOWN, fetchCommitteeMeetingDropdown)
  yield takeEvery(CREATE_COMMITTEE_MEETING, onCreateCommitteeMeeting)
  yield takeEvery(UPDATE_COMMITTEE_MEETING, onUpdateCommitteeMeeting)
  yield takeEvery(DELETE_COMMITTEE_MEETING, onDeleteCommitteeMeeting)
}

export default CommitteeMeetingsSaga
