import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_INSTITUTE_WALLETS,
  GET_INSTITUTE_WALLET_DROPDOWN,
  GET_INSTITUTE_WALLET_DETAIL,
  CREATE_INSTITUTE_WALLET,
  UPDATE_INSTITUTE_WALLET,
  DELETE_INSTITUTE_WALLET,
  GET_INSTITUTE_WALLETS_DOWNLOAD,
} from "./actionTypes"
import {
  getInstituteWalletsSuccess,
  getInstituteWalletsFail,
  getInstituteWalletDropdownSuccess,
  getInstituteWalletDropdownFail,
  getInstituteWalletDetailSuccess,
  getInstituteWalletDetailFail,
  createInstituteWalletSuccess,
  createInstituteWalletFail,
  updateInstituteWalletSuccess,
  updateInstituteWalletFail,
  deleteInstituteWalletSuccess,
  deleteInstituteWalletFail,
  getInstituteWalletsDownloadSuccess,
  getInstituteWalletsDownloadFail,
} from "./actions"

import { get, post, del, ApiPut } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getInstituteWalletsAPi = ({ instituteId, searchText, page }) => {
  if (instituteId) {
    return get(
      `/api/v1/institute/institute_wallet/?institute=${
        instituteId ? instituteId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/institute/institute_wallet/?search=${
        searchText ? searchText : ""
      }&page=${page ? page : 1}`
    )
  }
}
const getInstituteWalletsDownloadAPi = ({ instituteId, searchText }) => {
  if (instituteId) {
    return get(
      `/api/v1/institute/institute_wallet/?institute=${
        instituteId ? instituteId : ""
      }&search=${searchText ? searchText : ""}`
    )
  } else {
    return get(
      `/api/v1/institute/institute_wallet/?search=${
        searchText ? searchText : ""
      }`
    )
  }
}
const getInstituteWalletDropdownAPi = ({ instituteId, searchText }) => {
  if (instituteId) {
    return get(
      `/api/v1/institute/institute_wallet_dropdown?institute=${
        instituteId ? instituteId : ""
      }&search=${searchText ? searchText : ""}`
    )
  } else
    return get(
      `/api/v1/institute/institute_wallet_dropdown?search=${
        searchText ? searchText : ""
      }`
    )
}
const getInstituteWalletDetailsAPi = instituteWalletId => {
  return get(`/api/v1/institute/institute_wallet/${instituteWalletId}/`)
}
const createInstituteWalletApi = ({ instituteWallet }) => {
  return post("/api/v1/institute/institute_wallet/", instituteWallet)
}
const updateInstituteWalletApi = ({ instituteWallet, instituteWalletId }) => {
  return ApiPut(
    `/api/v1/institute/institute_wallet/${instituteWalletId}/`,
    instituteWallet
  )
}
const deleteInstituteWalletApi = ({ instituteWalletId }) => {
  return del(`/api/v1/institute/institute_wallet/${instituteWalletId}/`)
}

function* fetchInstituteWallets({ payload }) {
  try {
    const response = yield call(getInstituteWalletsAPi, payload)
    if (response && !response?.error) {
      yield put(getInstituteWalletsSuccess(response))
    } else {
      yield put(getInstituteWalletsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstituteWalletsFail(error))
    errorNotification()
  }
}

function* fetchInstituteWalletsDownload({ payload }) {
  try {
    const response = yield call(getInstituteWalletsDownloadAPi, payload)
    if (response && !response?.error) {
      yield put(getInstituteWalletsDownloadSuccess(response))
    } else {
      yield put(getInstituteWalletsDownloadFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstituteWalletsDownloadFail(error))
    errorNotification()
  }
}

function* fetchInstituteWalletDropdown({ payload }) {
  try {
    const response = yield call(getInstituteWalletDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getInstituteWalletDropdownSuccess(response))
    } else {
      yield put(getInstituteWalletDropdownFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstituteWalletDropdownFail(error))
    errorNotification(error)
  }
}

function* fetchInstituteWalletDetail({ instituteWalletId }) {
  try {
    const response = yield call(getInstituteWalletDetailsAPi, instituteWalletId)
    if (response && !response?.error) {
      yield put(getInstituteWalletDetailSuccess(response))
    } else {
      yield put(getInstituteWalletDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstituteWalletDetailFail(error))
    errorNotification(error)
  }
}
function* onCreateInstituteWallet({ payload }) {
  try {
    const response = yield call(createInstituteWalletApi, payload)
    if (response && !response?.error) {
      yield put(createInstituteWalletSuccess(response))
      doneNotification("Created Successfully")
    } else {
      yield put(createInstituteWalletFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createInstituteWalletFail(error))
    errorNotification()
  }
}

function* onUpdateInstituteWallet({ payload }) {
  try {
    const response = yield call(updateInstituteWalletApi, payload)
    if (response && !response?.error) {
      yield put(updateInstituteWalletSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateInstituteWalletFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateInstituteWalletFail(error))
    errorNotification()
  }
}

function* onDeleteInstituteWallet({ payload }) {
  try {
    const response = yield call(deleteInstituteWalletApi, payload)
    if (response && !response?.error) {
      yield put(deleteInstituteWalletSuccess(payload.instituteWalletId))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteInstituteWalletFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteInstituteWalletFail(error))
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* InstituteWalletsSaga() {
  yield takeEvery(GET_INSTITUTE_WALLETS, fetchInstituteWallets)
  yield takeEvery(GET_INSTITUTE_WALLETS_DOWNLOAD, fetchInstituteWalletsDownload)
  yield takeEvery(GET_INSTITUTE_WALLET_DETAIL, fetchInstituteWalletDetail)
  yield takeEvery(GET_INSTITUTE_WALLET_DROPDOWN, fetchInstituteWalletDropdown)
  yield takeEvery(CREATE_INSTITUTE_WALLET, onCreateInstituteWallet)
  yield takeEvery(UPDATE_INSTITUTE_WALLET, onUpdateInstituteWallet)
  yield takeEvery(DELETE_INSTITUTE_WALLET, onDeleteInstituteWallet)
}

export default InstituteWalletsSaga
