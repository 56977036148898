import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Modal, Button } from "reactstrap"
// import { useParams } from 'react-router-dom'
import { Link } from "react-router-dom/cjs/react-router-dom.min"

import {
  //  patchMahallPremium,
  getUserDetail,
  patchMahallPremium,
} from "store/actions"

const TermsAndConditionModal = ({ show, onCloseclick }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const { userDetail, patchMahall, language } = useSelector(state => ({
    userDetail: state.Users.userDetail,
    patchMahall: state.Mahalls.patchMahall,
    language: state.Layout.language,
  }))

  useEffect(() => {
    dispatch(getUserDetail())
  }, [patchMahall])

  const handleValidSubmit = () => {
    const TermsAndConditionData = {
      is_terms_agreed: true,
      mahall: userDetail?.mahall?.id,
    }
    // console.log(TermsAndConditionData);
    dispatch(patchMahallPremium(userDetail?.mahall?.id, TermsAndConditionData))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={() => {
                handleValidSubmit()
              }}
            >
              <div className="row mb-3">
                <Link to="/terms" className="text-center h6">
                  Terms & Conditions
                </Link>
              </div>

              <div className="row w-100 justify-content-center">
                <Button
                  type="submit"
                  color="primary"
                  className="w-75"
                  onClick={onCloseclick}
                >
                  {" "}
                  {language === "mal"
                    ? "സമർപ്പിക്കുക"
                    : "Accept Terms & Conditions"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default TermsAndConditionModal
TermsAndConditionModal.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
