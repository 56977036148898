import {
  GET_FAMILIES,
  GET_FAMILIES_SUCCESS,
  GET_FAMILIES_FAIL,
  GET_FAMILIES_FOR_DOWNLOAD,
  GET_FAMILIES_FOR_DOWNLOAD_SUCCESS,
  GET_FAMILIES_FOR_DOWNLOAD_FAIL,
  GET_FILTERED_FAMILIES,
  GET_FILTERED_FAMILIES_SUCCESS,
  GET_FILTERED_FAMILIES_FAIL,
  GET_FAMILIES_CARDS,
  GET_FAMILIES_CARDS_SUCCESS,
  GET_FAMILIES_CARDS_FAIL,
  GET_FAMILY_DROPDOWN,
  GET_FAMILY_DROPDOWN_SUCCESS,
  GET_FAMILY_DROPDOWN_FAIL,
  GET_ALL_FAMILY_DROPDOWN,
  GET_ALL_FAMILY_DROPDOWN_SUCCESS,
  GET_ALL_FAMILY_DROPDOWN_FAIL,
  GET_FAMILY_DETAIL,
  GET_FAMILY_DETAIL_SUCCESS,
  GET_FAMILY_DETAIL_FAIL,
  CREATE_FAMILY,
  CREATE_FAMILY_SUCCESS,
  CREATE_FAMILY_FAIL,
  UPDATE_FAMILY,
  UPDATE_FAMILY_SUCCESS,
  UPDATE_FAMILY_FAIL,
  DELETE_FAMILY,
  DELETE_FAMILY_SUCCESS,
  DELETE_FAMILY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  families: [],
  allFamilies: [],
  familiesforDownload: [],
  filteredFamilies: [],
  familiesCards: {},
  familyDetail: {},
  searchFamilies: [],
  error: {},
  loading: false,
  familiesCardsLoading: false,
  familiesforDownloadLoading: false,
  createdFamily: {},
  isUpdated: false,
}

const Families = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FAMILIES:
    case GET_FILTERED_FAMILIES:
    case GET_FAMILIES_CARDS:
    case GET_FAMILY_DROPDOWN:
    case GET_ALL_FAMILY_DROPDOWN:
    case UPDATE_FAMILY:
    case CREATE_FAMILY:
    case DELETE_FAMILY:
      return {
        ...state,
        loading: true,
      }
    case GET_FAMILY_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_FAMILIES_FOR_DOWNLOAD:
      return {
        ...state,
        familiesforDownloadLoading: true,
      }

    case GET_FAMILIES_SUCCESS:
      return {
        ...state,
        families: action.payload,
        loading: false,
      }

    case GET_FAMILIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILIES_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        familiesforDownload: action.payload,
        familiesforDownloadLoading: false,
      }

    case GET_FAMILIES_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        familiesforDownloadLoading: false,
      }

    case GET_FILTERED_FAMILIES_SUCCESS:
      return {
        ...state,
        families: action.payload,
        loading: false,
      }

    case GET_FILTERED_FAMILIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILIES_CARDS_SUCCESS:
      return {
        ...state,
        familiesCards: action.payload,
        familiesCardsLoading: false,
      }

    case GET_FAMILIES_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_FAMILY_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchFamilies: action.payload,
        loading: false,
      }

    case GET_FAMILY_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ALL_FAMILY_DROPDOWN_SUCCESS:
      return {
        ...state,
        allFamilies: action.payload,
        loading: false,
      }

    case GET_ALL_FAMILY_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_SUCCESS:
      return {
        ...state,
        families: action.payload,
        createdFamily: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_DETAIL_SUCCESS:
      return {
        ...state,
        familyDetail: action.payload,
        loading: false,
        detailLoading: false,
        isUpdated: false,
      }

    case GET_FAMILY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_FAMILY_SUCCESS:
      return {
        ...state,
        familyDetail: action.payload,
        isUpdated: true,
        loading: false,
      }

    case UPDATE_FAMILY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isUpdated: true,
      }

    case DELETE_FAMILY_SUCCESS:
      return {
        ...state,
        // families: state.families.filter(
        //   family => family.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_FAMILY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Families
