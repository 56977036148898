import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_STUDENT_VARISANGYAS,
  GET_STUDENT_VARISANGYA_CARDS,
  GET_STUDENT_VARISANGYA_DETAIL,
  CREATE_STUDENT_VARISANGYA,
  UPDATE_STUDENT_VARISANGYA,
  DELETE_STUDENT_VARISANGYA,
  GET_STUDENT_VARISANGYA_ITEMS,
  AUTO_GENERATE_STUDENT_VARISANGYAS,
  REFRESH_STUDENT_VARISANGYA,
  UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER,
} from "./actionTypes"
import {
  getStudentVarisangyasSuccess,
  getStudentVarisangyasFail,
  getStudentVarisangyaCardsSuccess,
  getStudentVarisangyaCardsFail,
  getStudentVarisangyaDetailSuccess,
  getStudentVarisangyaDetailFail,
  createStudentVarisangyaSuccess,
  createStudentVarisangyaFail,
  updateStudentVarisangyaSuccess,
  updateStudentVarisangyaFail,
  deleteStudentVarisangyaSuccess,
  deleteStudentVarisangyaFail,
  getStudentVarisangyaItemsSuccess,
  getStudentVarisangyaItemsFail,
  autoGenerateStudentVarisangyasSuccess,
  autoGenerateStudentVarisangyasFail,
  refreshStudentVarisangyaSuccess,
  refreshStudentVarisangyaFail,
  updateStudentVarisangyaAmountByFilterSuccess,
  updateStudentVarisangyaAmountByFilterFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getStudentVarisangyasAPi = ({ searchText, page, limit, instituteId }) => {
  return get(
    `/api/v1/madrasa-varisankya/madrasa-student-varisankya/?institute=${
      instituteId ? instituteId : ""
    }&search=${searchText ? searchText : ""}&page=${
      page ? page : 1
    }&page_size=${limit ? limit : 10}`
  )
}

const autoGenerateStudentVarisangyasAPi = ({ instituteId }) => {
  return post(
    `/api/v1/madrasa-varisankya/madrasa_student_varisankya_auto_generate/?institute=${
      instituteId ? instituteId : ""
    }`
  )
}

const refreshStudentVarisangyaAPi = ({ studentVarisangyaId }) => {
  console.log("studentVarisangyaId: ", studentVarisangyaId)
  return post(
    `/api/v1/madrasa-varisankya/madrasa_student_varisankya_refresh/${studentVarisangyaId}/`
  )
}

const updateStudentVarisangyaAmountByFilterApi = ({
  studentVarisangyaAmuontByFilter,
}) => {
  return post(
    "/api/v1/madrasa-varisankya/madrasa_student_varisankya_amount_update_in_filter/",
    studentVarisangyaAmuontByFilter,
    { "Content-type": "" }
  )
}

const getStudentVarisangyasCardsAPi = () => {
  return get(`/api/v1/varisankya/family_varisankya/?cards=all`)
}
const getStudentVarisangyaDetailsAPi = studentVarisangyaId => {
  return get(
    `/api/v1/madrasa-varisankya/madrasa-student-varisankya/${studentVarisangyaId}/`
  )
}
const getStudentVarisangyaItemsAPi = ({ familyId }) => {
  return get(
    `/api/v1/madrasa-varisankya/madrasa-student-varisankya-item/?family=${
      familyId && familyId
    }`
  )
}
const createStudentVarisangyaApi = ({ studentVarisangya }) => {
  return post(
    "/api/v1/madrasa-varisankya/madrasa-student-varisankya/",
    studentVarisangya
  )
}
const updateStudentVarisangyaApi = ({
  studentVarisangyaId,
  studentVarisangya,
}) => {
  return ApiPut(
    `/api/v1/madrasa-varisankya/madrasa-student-varisankya/${studentVarisangyaId}/`,
    studentVarisangya
  )
}
const deleteStudentVarisangyaApi = ({ studentVarisangyaId }) => {
  return del(
    `/api/v1/madrasa-varisankya/madrasa-student-varisankya/${studentVarisangyaId}/`
  )
}

function* fetchStudentVarisangyas({ payload }) {
  try {
    const response = yield call(getStudentVarisangyasAPi, payload)
    if (response && !response?.error) {
      yield put(getStudentVarisangyasSuccess(response))
    } else {
      yield put(getStudentVarisangyasFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentVarisangyasFail(error))
  }
}

function* autoGenerateStudentVarisangyas({ payload }) {
  try {
    const response = yield call(autoGenerateStudentVarisangyasAPi, payload)
    if (response && !response?.error) {
      yield put(autoGenerateStudentVarisangyasSuccess(response))
      try {
        const response2 = yield call(getStudentVarisangyasAPi, { page: 1 })
        yield put(getStudentVarisangyasSuccess(response2))
      } catch (error) {
        yield put(getStudentVarisangyasFail(error))
      }
    } else {
      yield put(autoGenerateStudentVarisangyasFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentVarisangyasFail(error))
  }
}

function* refreshStudentVarisangya({ payload }) {
  console.log(payload)
  try {
    const response = yield call(refreshStudentVarisangyaAPi, payload)
    if (response && !response?.error) {
      yield put(refreshStudentVarisangyaSuccess(response))
      doneNotification(response?.response)
      try {
        const response2 = yield call(
          getStudentVarisangyaDetailsAPi,
          payload?.studentVarisangyaId
        )
        yield put(getStudentVarisangyaDetailSuccess(response2))
      } catch (error) {
        yield put(getStudentVarisangyaDetailFail(error))
      }
    } else {
      yield put(refreshStudentVarisangyaFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    yield put(refreshStudentVarisangyaFail(error))
  }
}

function* updateStudentVarisangyaAmountByFilter({ payload }) {
  console.log("data :", payload)
  try {
    const response = yield call(
      updateStudentVarisangyaAmountByFilterApi,
      payload
    )
    if (response && !response?.error) {
      console.log("response:", response?.error)
      yield put(updateStudentVarisangyaAmountByFilterSuccess(response))
      payload.history.push("/family/wallet/details")
      doneNotification("Created Successfully")
    } else {
      yield put(updateStudentVarisangyaAmountByFilterFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error :", error)
    yield put(updateStudentVarisangyaAmountByFilterFail(error))
    errorNotification()
  }
}

function* fetchStudentVarisangyaItems({ payload }) {
  try {
    const response = yield call(getStudentVarisangyaItemsAPi, payload)
    if (response && !response?.error) {
      yield put(getStudentVarisangyaItemsSuccess(response))
    } else {
      yield put(getStudentVarisangyaItemsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentVarisangyaItemsFail(error))
  }
}

function* fetchStudentVarisangyasCards() {
  try {
    const response = yield call(getStudentVarisangyasCardsAPi)
    if (response && !response?.error) {
      yield put(getStudentVarisangyaCardsSuccess(response))
    } else {
      yield put(getStudentVarisangyaCardsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentVarisangyaCardsFail(error))
  }
}

function* fetchStudentVarisangyaDetail({ studentVarisangyaId }) {
  try {
    const response = yield call(
      getStudentVarisangyaDetailsAPi,
      studentVarisangyaId
    )
    if (response && !response?.error) {
      yield put(getStudentVarisangyaDetailSuccess(response))
    } else {
      yield put(getStudentVarisangyaDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentVarisangyaDetailFail(error))
  }
}
function* onCreateStudentVarisangya({ payload }) {
  try {
    const response = yield call(createStudentVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(createStudentVarisangyaSuccess(response))
      if (payload.history) {
        payload.history.push("/varisangyas")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createStudentVarisangyaFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createStudentVarisangyaFail(error))
    errorNotification()
  }
}

function* onUpdateStudentVarisangya({ payload }) {
  try {
    const response = yield call(updateStudentVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(updateStudentVarisangyaSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateStudentVarisangyaFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateStudentVarisangyaFail(error))
    errorNotification()
  }
}

function* onDeleteStudentVarisangya({ payload }) {
  try {
    const response = yield call(deleteStudentVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(deleteStudentVarisangyaSuccess(payload.studentVarisangyaId))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteStudentVarisangyaFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteStudentVarisangyaFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* StudentVarisangyasSaga() {
  yield takeEvery(GET_STUDENT_VARISANGYAS, fetchStudentVarisangyas)
  yield takeEvery(GET_STUDENT_VARISANGYA_ITEMS, fetchStudentVarisangyaItems)
  yield takeEvery(GET_STUDENT_VARISANGYA_CARDS, fetchStudentVarisangyasCards)
  yield takeEvery(GET_STUDENT_VARISANGYA_DETAIL, fetchStudentVarisangyaDetail)
  yield takeEvery(CREATE_STUDENT_VARISANGYA, onCreateStudentVarisangya)
  yield takeEvery(UPDATE_STUDENT_VARISANGYA, onUpdateStudentVarisangya)
  yield takeEvery(DELETE_STUDENT_VARISANGYA, onDeleteStudentVarisangya)
  yield takeEvery(
    AUTO_GENERATE_STUDENT_VARISANGYAS,
    autoGenerateStudentVarisangyas
  )
  yield takeEvery(REFRESH_STUDENT_VARISANGYA, refreshStudentVarisangya)
  yield takeEvery(
    UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER,
    updateStudentVarisangyaAmountByFilter
  )
}

export default StudentVarisangyasSaga
