import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter, Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const MahallUserSidebarContentBasic = props => {
  const ref = useRef()
  // const history = useHistory()

  const { userDetail } = useSelector(state => ({
    leftMenu: state.Layout.leftMenu,
    userDetail: state.Users.userDetail,
  }))

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  // const handlePremium = heading => {
  //   if (heading === 'Institutes') {
  //     return true
  //   }
  //   if (heading === 'Accounts') {
  //     return true
  //   }
  //   if (heading === 'Committees') {
  //     return true
  //   }
  //   if (heading === 'Members') {
  //     return true
  //   }
  //   if (heading === 'Meetings') {
  //     return true
  //   }
  //   if (heading === 'Meeting Attendances') {
  //     return true
  //   }
  //   if (heading === 'Institute Users') {
  //     return true
  //   }
  // }

  const handlePremiumLink = path => {
    if (["premium", "standard"].includes(userDetail?.mahall?.version)) {
      return path
    } else {
      return "/premium"
    }
  }

  const [selectedTag, setSelectedTag] = useState("")

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className="mt-4">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="">
                <i className="bx bxs-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            {/* {userDetail?.mahall?.version === 'basic' ?

            <li className="premium">
              <Link to="/">
                <span className="badge rounded-pill  float-end" style={{fontSize:'0px'}}>
                  <i className="bx bx-crown" style={{fontSize:'15px', color: "gold"}}></i>
                </span>
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Profile")}</span>
              </Link>
            </li>
            :
            <li>
              <Link to="/profile">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Profile")}</span>
              </Link>
            </li>
            } */}

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-user"></i>
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/mahallusers">{props.t("Mahall Users")}</Link>
                </li>
                <li>
                  <Link to="/surveyusers">{props.t("Survey Users")}</Link>
                </li>
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("Institute Users")}
                >
                  <Link
                    className={
                      selectedTag === "Institute Users" ? "active" : ""
                    }
                    to={handlePremiumLink("/")}
                  >
                    {props.t("Institute Users")}
                  </Link>
                </li>
              </ul>
            </li>

            <li className="menu-title mt-3">{props.t("Data")} </li>
            {/* Families */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home"></i>
                <span>{props.t("Families")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/families">{props.t("All Families")}</Link>
                </li>
                <li>
                  <Link to="/family/create">{props.t("Create Family")}</Link>
                </li>
              </ul>
            </li>
            {/* Members */}
            <li>
              <Link to="/familymembers">
                <i className="bx bx-group"></i>
                <span>{props.t("Members")}</span>
              </Link>
            </li>
            {/* Institutes */}
            <li>
              <Link to="/#" className="has-arrow">
                <span
                  className="badge rounded-pill  float-end"
                  style={{ fontSize: "0px" }}
                >
                  <i
                    className="bx bx-crown"
                    style={{ fontSize: "15px", color: "gold" }}
                  ></i>
                </span>
                <i className="bx bx-chevron-up-circle"></i>
                <span>{props.t("Institutes")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className="d-flex justify-content-between">
                  <Link to={handlePremiumLink("/institutes")}>
                    {props.t("All Institutes")}
                  </Link>
                </li>
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("Create Institute")}
                >
                  <Link
                    className={
                      selectedTag === "Create Institute" ? "active" : ""
                    }
                    to={handlePremiumLink("/institute/create")}
                  >
                    {props.t("Create Institute")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* Accounts */}
            <li>
              <Link to="/#" className="has-arrow">
                <span
                  className="badge rounded-pill  float-end"
                  style={{ fontSize: "0px" }}
                >
                  <i
                    className="bx bx-crown"
                    style={{ fontSize: "15px", color: "gold" }}
                  ></i>
                </span>
                <i className="bx bx-spreadsheet"></i>
                <span>{props.t("Accounts")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("All Accounts")}
                >
                  <Link
                    className={selectedTag === "All Accounts" ? "active" : ""}
                    to={handlePremiumLink("/")}
                  >
                    {props.t("All Accounts")}
                  </Link>
                </li>
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("Create Account")}
                >
                  <Link
                    className={selectedTag === "Create Account" ? "active" : ""}
                    to={handlePremiumLink("/")}
                  >
                    {props.t("Create Account")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* Committees */}
            <li>
              <Link to="/#" className="has-arrow">
                <span
                  className="badge rounded-pill  float-end"
                  style={{ fontSize: "0px" }}
                >
                  <i
                    className="bx bx-crown"
                    style={{ fontSize: "15px", color: "gold" }}
                  ></i>
                </span>
                <i className="bx bx-chat"></i>
                <span>{props.t("Committees")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("All Committees")}
                >
                  <Link
                    className={selectedTag === "All Committees" ? "active" : ""}
                    to={handlePremiumLink("/")}
                  >
                    {props.t("All Committees")}
                  </Link>
                </li>
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("Create Committee")}
                >
                  <Link
                    className={
                      selectedTag === "Create Committee" ? "active" : ""
                    }
                    to={handlePremiumLink("/")}
                  >
                    {props.t("Create Committee")}
                  </Link>
                </li>
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("All Meetings")}
                >
                  <Link
                    className={selectedTag === "All Meetings" ? "active" : ""}
                    to={handlePremiumLink("/")}
                  >
                    {props.t("All Meetings")}
                  </Link>
                </li>
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("Create Meeting")}
                >
                  <Link
                    className={selectedTag === "Create Meeting" ? "active" : ""}
                    to={handlePremiumLink("/")}
                  >
                    {props.t("Create Meeting")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* Registrations */}
            <li>
              <Link to="/#" className="has-arrow">
                <span
                  className="badge rounded-pill  float-end"
                  style={{ fontSize: "0px" }}
                >
                  <i
                    className="bx bx-crown"
                    style={{ fontSize: "15px", color: "gold" }}
                  ></i>
                </span>
                <i className="bx bx-task"></i>
                <span>{props.t("Registrations")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("Nikah Registrations")}
                >
                  <Link
                    className={
                      selectedTag === "Nikah Registrations" ? "active" : ""
                    }
                    to={handlePremiumLink("/")}
                  >
                    {props.t("Nikah Registrations")}
                  </Link>
                </li>
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("Birth Registrations")}
                >
                  <Link
                    className={
                      selectedTag === "Birth Registrations" ? "active" : ""
                    }
                    to={handlePremiumLink("/")}
                  >
                    {props.t("Birth Registrations")}
                  </Link>
                </li>
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("Death Registrations")}
                >
                  <Link
                    className={
                      selectedTag === "Death Registrations" ? "active" : ""
                    }
                    to={handlePremiumLink("/")}
                  >
                    {props.t("Death Registrations")}
                  </Link>
                </li>
              </ul>
            </li>
            {/* Reports */}
            <li className="mt-3">
              <Link to="/#" className="has-arrow">
                <span
                  className="badge rounded-pill  float-end"
                  style={{ fontSize: "0px" }}
                >
                  <i
                    className="bx bx-crown"
                    style={{ fontSize: "15px", color: "gold" }}
                  ></i>
                </span>
                <i className="bx bx-folder-open"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li
                  className="d-flex justify-content-between"
                  onClick={() => setSelectedTag("All Reports")}
                >
                  <Link
                    className={selectedTag === "All Reports" ? "active" : ""}
                    to={handlePremiumLink("/")}
                  >
                    {props.t("All Reports")}
                  </Link>
                </li>
                <li
                  className="d-flex juseHistoryustify-content-between"
                  onClick={() => setSelectedTag("Create Report")}
                >
                  <Link
                    className={selectedTag === "Create Report" ? "active" : ""}
                    to={handlePremiumLink("/")}
                  >
                    {props.t("Create Report")}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

MahallUserSidebarContentBasic.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MahallUserSidebarContentBasic))
