import React from "react"
import { Row } from "reactstrap"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
//actions
import moment from "moment"

//componetns

//Import Images

const FamilyVarisangyaReceiptPdf = ({ pdfRef }) => {
  const { familyVarisangyaWalletView, language, userDetail } = useSelector(
    state => ({
      familyVarisangyaWalletView:
        state.FamilyVarisangyaWallets.familyVarisangyaWalletView,
      language: state.Layout.language,
      userDetail: state.Users.userDetail,
    })
  )

  // console.log(nikahRegistrationDetail);

  // const today = moment(new Date()).format('DD-MM-yyyy')

  return (
    <div
      style={{
        paddingLeft: "50px",
        paddingRight: "50px",
        position: "absolute",
        top: -1400,
        width: "780px",
      }}
      ref={pdfRef}
    >
      <Row>
        <div style={{ border: "2px solid black" }}>
          <div className="d-flex header-div justify-content-center">
            {/* <img
              src={userDetail?.mahall?.logo && userDetail?.mahall?.logo}
              alt=""
              className="img-thumbnail reciept-logo"
              style={{ width: '100px', height: '100px' }}
            /> */}
            <div className="text-black text-heading">
              <h2 className="text-black">
                {language === "mal"
                  ? userDetail?.mahall?.mahall_malayalam_name
                    ? userDetail?.mahall?.mahall_malayalam_name
                    : userDetail?.mahall?.mahall_english_name
                  : userDetail?.mahall?.mahall_english_name}
              </h2>
              <p style={{ marginBottom: "0" }}>
                {language === "mal"
                  ? userDetail?.mahall?.village_malayalam_name
                    ? userDetail?.mahall?.village_malayalam_name
                    : userDetail?.mahall?.village_english_name
                  : userDetail?.mahall?.village_english_name}
                , {userDetail?.mahall?.post_office},{" "}
                {userDetail?.mahall?.pin_code}
              </p>
            </div>
          </div>
          <h2 className="text-black pt-4 text-center">
            {familyVarisangyaWalletView?.trn_type === "credit"
              ? language === "mal"
                ? "രസീത്"
                : "RECEIPT"
              : language === "mal"
              ? "വൗച്ചർ"
              : "VOUCHER"}
          </h2>
          <div className="middle-div">
            <div className="sub-middle-div">
              <div className="d-flex font-p">
                <h5 className="font-h5">
                  {familyVarisangyaWalletView?.trn_type === "credit"
                    ? language === "mal"
                      ? "രസീത് നം"
                      : "Receipt No."
                    : language === "mal"
                    ? "വൗച്ചർ നം"
                    : "Voucher No."}
                </h5>
                <p> {familyVarisangyaWalletView?.var_no}</p>
              </div>
              <div className="d-flex font-p">
                <h5 className="font-h5">
                  {language === "mal" ? "തീയതി" : "Date"}
                </h5>
                <p>
                  :{" "}
                  {moment(familyVarisangyaWalletView?.date_added).format(
                    "DD-MM-yyyy"
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex font-p">
              <h5 className="font-h5">
                {language === "mal" ? "പേര്" : "Name"}
              </h5>
              <p> : {familyVarisangyaWalletView?.family_name}</p>
            </div>
            <div className="d-flex pb-4 font-p">
              <h5 className="font-h5">
                {language === "mal" ? "വിശദാംശങ്ങൾ" : "Particulars"}
              </h5>
              <p> : {familyVarisangyaWalletView?.remark}</p>
            </div>
            <div className="d-flex font-p">
              <h5 className="font-h5">
                {language === "mal" ? "തുക" : "Amount"}
              </h5>
              <p> : {familyVarisangyaWalletView?.amount}</p>
            </div>
          </div>
          {familyVarisangyaWalletView?.trn_type === "debit" ? (
            <div className="d-flex justify-content-around pt-5 pb-3 text-black font-p">
              <p>{language === "mal" ? "സ്വീകർത്താവ്" : "Reciever"}</p>
              <p>
                {language === "mal"
                  ? "സെക്രട്ടറി / പ്രസിഡന്റ്"
                  : "Secretary / President"}
              </p>
            </div>
          ) : (
            <p
              className="d-flex justify-content-end pt-5 pb-3 text-black font-p"
              style={{ paddingRight: "170px" }}
            >
              {language === "mal"
                ? "സെക്രട്ടറി / പ്രസിഡന്റ്"
                : "Secretary / President"}
            </p>
          )}
        </div>
      </Row>
    </div>
  )
}

export default FamilyVarisangyaReceiptPdf

FamilyVarisangyaReceiptPdf.propTypes = {
  pdfRef: PropTypes.any,
}
