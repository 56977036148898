import React from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import moment from "moment"
import { Modal, Button } from "reactstrap"

import { updateEducationFamilyMember } from "store/actions"

// const [modal_center, setmodal_center] = useState(false);

const UiModalEducationUpdate = ({ show, onCloseclick, data, history }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  const education = data

  const handleValidSubmit = (onSubmitProps, values) => {
    const updateEducationFamilyMemberData = {
      family_member: education?.family_member,
      institution_name: values.institution_name,
      institution_place: values.institution_place,
      contact_number: values.contact_number,
      join_date: new Date(values.join_date),
      pass_date: new Date(values.pass_date),
      course: values.course,
      cost_per_year: values.cost_per_year,
    }

    dispatch(
      updateEducationFamilyMember(
        updateEducationFamilyMemberData,
        education.id,
        history
      )
    )
  }

  // console.log(data);

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Fill The Form</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-3">
                <AvField
                  name="institution_name"
                  value={education?.institution_name}
                  type="text"
                  label={
                    language === "mal"
                      ? "സ്ഥാപനത്തിന്റെ പേര്"
                      : "Institution Name"
                  }
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="institution_place"
                  value={education?.institution_place}
                  type="text"
                  label={
                    language === "mal"
                      ? "സ്ഥാപനത്തിന്റെ സ്ഥലം"
                      : "Institution Place"
                  }
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="contact_number"
                  value={education?.contact_number}
                  type="number"
                  label={
                    language === "mal"
                      ? "ബന്ധപ്പെടേണ്ട നമ്പർ"
                      : "Contact Number"
                  }
                  validate={{
                    minLength: {
                      value: 10,
                      errorMessage: "Your number must be 10 digits",
                    },
                    maxLength: {
                      value: 10,
                      errorMessage: "Your number must be 10 digits",
                    },
                  }}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="join_date"
                  value={
                    education?.join_date
                      ? moment(education?.join_date).format("YYYY-MM-DD")
                      : null
                  }
                  type="date"
                  label={language === "mal" ? "ചേർന്ന തീയതി" : "Joined Date"}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="course"
                  value={education?.course}
                  type="text"
                  label={language === "mal" ? "കോഴ്സ്" : "course"}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="pass_date"
                  value={
                    education?.pass_date
                      ? moment(education?.pass_date).format("YYYY-MM-DD")
                      : null
                  }
                  type="date"
                  label={language === "mal" ? "പാസ് തീയതി" : "Pass Date"}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="cost_per_year"
                  value={education?.cost_per_year}
                  type="number"
                  label={language === "mal" ? "പ്രതിവർഷം ഫീസ്" : "Fee Per Year"}
                />
              </div>
              <div className="row w-100 justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  onClick={onCloseclick}
                >
                  {" "}
                  {language === "mal" ? "പുതുക്കുക" : "Update"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalEducationUpdate
UiModalEducationUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.any,
  history: propTypes.object,
}
