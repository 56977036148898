import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COMMITTEES,
  GET_COMMITTEE_DROPDOWN,
  GET_COMMITTEE_DETAIL,
  CREATE_COMMITTEE,
  UPDATE_COMMITTEE,
  DELETE_COMMITTEE,
} from "./actionTypes"
import {
  getCommitteesSuccess,
  getCommitteesFail,
  getCommitteeDropdownSuccess,
  getCommitteeDropdownFail,
  getCommitteeDetailSuccess,
  getCommitteeDetailFail,
  createCommitteeSuccess,
  createCommitteeFail,
  updateCommitteeSuccess,
  updateCommitteeFail,
  deleteCommitteeSuccess,
  deleteCommitteeFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getCommitteesAPi = ({ instituteId, page, searchText }) => {
  return get(
    `/api/v1/committee/committee/?institute=${
      instituteId ? instituteId : ""
    }&page=${page ? page : 1}&search=${searchText ? searchText : ""}`
  )
}
const getCommitteeDropdownAPi = searchText => {
  return get(
    `/api/v1/committee/committee_dropdown?search=${searchText && searchText}`
  )
}
const getCommitteeDetailsAPi = committeeId => {
  return get(`/api/v1/committee/committee/${committeeId}/`)
}
// const getInstituteDetailsAPi = instituteId => {
//   return get(`/api/v1/institute/institute/${instituteId}/`)
// }
const createCommitteeApi = ({ committee }) => {
  return post("/api/v1/committee/committee/", committee)
}
const updateCommitteeApi = ({ committeeId, committee }) => {
  return ApiPut(`/api/v1/committee/committee/${committeeId}/`, committee)
}
const deleteCommitteeApi = ({ committeeId }) => {
  return del(`/api/v1/committee/committee/${committeeId}/`)
}

function* fetchCommittees({ payload }) {
  try {
    const response = yield call(getCommitteesAPi, payload)
    if (response && !response?.error) {
      yield put(getCommitteesSuccess(response))
    } else {
      yield put(getCommitteesFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getCommitteesFail(error))
  }
}

function* fetchCommitteeDropdown({ payload }) {
  try {
    const response = yield call(getCommitteeDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getCommitteeDropdownSuccess(response))
    } else {
      yield put(getCommitteeDropdownFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getCommitteeDropdownFail(error))
  }
}

function* fetchCommitteeDetail({ committeeId }) {
  try {
    const response = yield call(getCommitteeDetailsAPi, committeeId)
    if (response && !response?.error) {
      yield put(getCommitteeDetailSuccess(response))
    } else {
      yield put(getCommitteeDetailFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getCommitteeDetailFail(error))
  }
}
function* onCreateCommittee({ payload }) {
  try {
    const response = yield call(createCommitteeApi, payload)
    if (response && !response?.error) {
      yield put(createCommitteeSuccess(response))
      doneNotification("Created Successfully")
      // try {
      //   const response2 = yield call(getInstituteDetailsAPi, response?.institute)
      //   yield put(getInstituteDetailSuccess(response2))
      // } catch (error) {
      //   yield put(getInstituteDetailFail(error))
      //   console.log('error: ', error);
      // }
    } else {
      yield put(createCommitteeFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createCommitteeFail(error))
    errorNotification()
  }
}

function* onUpdateCommittee({ payload }) {
  try {
    const response = yield call(updateCommitteeApi, payload)
    if (response && !response?.error) {
      yield put(updateCommitteeSuccess(response))
      doneNotification("Updated Successfully")
      try {
        const response2 = yield call(getCommitteeDetailsAPi, response?.id)
        yield put(getCommitteeDetailSuccess(response2))
      } catch (error) {
        yield put(getCommitteeDetailFail(error))
      }
    } else {
      yield put(updateCommitteeFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateCommitteeFail(error))
    errorNotification()
  }
}

function* onDeleteCommittee({ payload }) {
  try {
    const response = yield call(deleteCommitteeApi, payload)
    if (response && !response?.error) {
      payload.history.goBack()
      yield put(deleteCommitteeSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteCommitteeFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteCommitteeFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* CommitteesSaga() {
  yield takeEvery(GET_COMMITTEES, fetchCommittees)
  yield takeEvery(GET_COMMITTEE_DETAIL, fetchCommitteeDetail)
  yield takeEvery(GET_COMMITTEE_DROPDOWN, fetchCommitteeDropdown)
  yield takeEvery(CREATE_COMMITTEE, onCreateCommittee)
  yield takeEvery(UPDATE_COMMITTEE, onUpdateCommittee)
  yield takeEvery(DELETE_COMMITTEE, onDeleteCommittee)
}

export default CommitteesSaga
