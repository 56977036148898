import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import moment from "moment"
//actions
import axios from "axios"
import { debounce } from "lodash"
import { Image } from "react-bootstrap"

import { handleFinancialYear, months } from "../financialYear"

import {
  getMahallDetail,
  updateMahallFail,
  updateMahallSuccess,
  getVillageDropdown,
  getLsgDropdown,
  getGroup,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { API_URL } from "helpers/api_methods"
import { useFileSizes } from "hooks/useFileSize"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UpdateMahall = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const handleFileSizes = useFileSizes

  const {
    mahallDetail,
    loading,
    searchVillages,
    searchLsgs,
    groups,
    language,
  } = useSelector(state => ({
    loading: state.Mahalls.loading,
    mahallDetail: state.Mahalls.mahallDetail,
    searchVillages: state.Villages.searchVillages,
    searchLsgs: state.Lsgs.searchLsgs,
    groups: state.Groups.groups,
    language: state.Layout.language,
  }))

  const [financialYear, setFinancialYear] = useState(0)

  const [group, setGroup] = useState("Search Group")
  const [groupId, setGroupId] = useState("")
  const [searchTextGroup, setSearchTextGroup] = useState("")

  const [village, setVillage] = useState("Search Village")
  const [villageId, setVillageId] = useState("")
  const [searchText, setSearchText] = useState("")

  const [lsg, setLsg] = useState("Search LSG")
  const [lsgId, setLsgId] = useState("")
  const [searchTextLsg, setSearchTextLsg] = useState("")

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })

  const [imagePreview, setImagePreview] = useState()

  const [filesSize, setFilesSize] = useState()

  const versions = ["basic", "standard", "premium"]

  const [fromFinancialDate, setFromFinancialDate] = useState("")
  const [toFinancialDate, setToFinancialDate] = useState("")

  const handleFinancialYearSetting = month => {
    const { finalToDate, fromDate } = handleFinancialYear(month)
    setFromFinancialDate(fromDate)
    setToFinancialDate(finalToDate)
  }
  console.log(financialYear)
  console.log(customLoad)
  function handleValidSubmit(values) {
    // if (handleFileSizes(filesSize)?.status === true) {
    updateMahallNew(
      selectedFile,
      values,
      villageId,
      lsgId,
      groupId,
      mahallDetail,
      dispatch,
      setCustomLoad
    )
    setImagePreview()
    // }
  }

  // useEffect(() => {
  //   setFinancialYear(mahallDetail?.financial_year_starting_month)
  // }, [dispatch, params.id])

  useEffect(() => {
    dispatch(getMahallDetail(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    setGroup(mahallDetail?.mahallgroup_english_name)
    setGroupId(mahallDetail?.mahall_group || "")
    setVillage(mahallDetail?.village_english_name)
    setVillageId(mahallDetail?.village || "")
    setLsg(mahallDetail?.lsg_english_name)
    setLsgId(mahallDetail?.lsg || "")
    handleFinancialYearSetting(mahallDetail?.financial_year_starting_month)
  }, [dispatch, mahallDetail])

  useEffect(() => {
    dispatch(getGroup(searchTextGroup))
  }, [dispatch, searchTextGroup])

  useEffect(() => {
    dispatch(getVillageDropdown(searchText))
  }, [searchText, dispatch])

  useEffect(() => {
    dispatch(getLsgDropdown(searchTextLsg))
  }, [searchTextLsg, dispatch])

  function handlerFinalValueGroup(event) {
    // dispatch(getGroupAdmin(event.value))
    setGroupId(event.value)
    setGroup(event.label)
  }

  function handlerFinalValueVillage(event) {
    dispatch(getVillageDropdown(event.value))
    setVillageId(event.value)
    setVillage(event.label)
  }

  function handlerFinalValueLsg(event) {
    dispatch(getLsgDropdown(event.value))
    setLsgId(event.value)
    setLsg(event.label)
  }

  const OptionGroup = [
    {
      options: groups?.results?.map((results, index) => ({
        key: index,
        label: results.mahallgroup_english_name,
        value: results.id,
      })),
    },
  ]

  const optionGroupVillage = [
    {
      options: searchVillages?.map((results, index) => ({
        key: index,
        label: results.village_english_name,
        value: results.id,
      })),
    },
  ]

  const optionGroupLsg = [
    {
      options: searchLsgs?.map((results, index) => ({
        key: index,
        label: results.lsg_english_name,
        value: results.id,
      })),
    },
  ]

  const debounceGroupSearch = debounce(value => setSearchTextGroup(value), 600)

  const debounceVillageSearch = debounce(value => setSearchText(value), 600)

  const debounceLsgSearch = debounce(value => setSearchTextLsg(value), 600)

  const changeHandler = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <>
      <MetaTags>
        <title>Mahalls | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Mahalls" breadcrumbItem="Update" />
        <Container fluid>
          <div className="container-fluid mt-5">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Update Mahall</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <Label>
                            Name (English)
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="mahall_english_name"
                            type="text"
                            // label="Name (English)"
                            value={mahallDetail?.mahall_english_name || ""}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col className="mb-4">
                          <AvField
                            name="mahall_malayalam_name"
                            type="text"
                            label="Name (മലയാളം)"
                            value={mahallDetail?.mahall_malayalam_name || ""}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <div className="row">
                            <AvField
                              type="select"
                              name="version"
                              label="Version"
                              value={mahallDetail?.version || ""}
                            >
                              <option>Choose a version ...</option>
                              {versions.map((item, key) => (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>
                        <Col sm="12" md="6">
                          <AvField
                            name="register_number"
                            type="text"
                            label="Register Number"
                            value={mahallDetail?.register_number || ""}
                          />
                        </Col>
                      </div>

                      <div className="row mb-1  ">
                        <Col sm="12" md="6" className="mb-3">
                          <AvField
                            name="email"
                            type="email"
                            label="Email"
                            value={mahallDetail?.email || ""}
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <AvField
                            name="phone_number"
                            type="number"
                            label="Phone Number"
                            value={mahallDetail?.phone_number || ""}
                            validate={{
                              minLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                              maxLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-1">
                        <Col sm="12" md="6" className="mb-3">
                          <AvField
                            name="established_date"
                            type="date"
                            label="Established Date"
                            value={
                              mahallDetail?.established_date
                                ? moment(mahallDetail?.established_date).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""
                            }
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <Label>
                            Place<span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="place"
                            type="text"
                            title="Place"
                            // label="Place"
                            value={mahallDetail?.place || ""}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-1">
                        <Col sm="12" md="6" className="mb-3">
                          <Label>
                            {" "}
                            Village<span className="text-danger">
                              &#42;
                            </span>{" "}
                          </Label>
                          <Select
                            onInputChange={debounceVillageSearch}
                            placeholder={village}
                            value={village}
                            options={optionGroupVillage}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValueVillage}
                            type="text"
                            required="required"
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <Label>
                            {" "}
                            LSG<span className="text-danger">&#42;</span>{" "}
                          </Label>
                          <Select
                            onInputChange={debounceLsgSearch}
                            placeholder={lsg}
                            value={lsg}
                            options={optionGroupLsg}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValueLsg}
                            type="text"
                            title="lsg"
                            required="required"
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col sm="12" md="6">
                          <AvField
                            name="post_office"
                            type="text"
                            label="Post Office"
                            value={mahallDetail?.post_office || ""}
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <AvField
                            name="pin_code"
                            type="text"
                            label="Pin Code"
                            value={mahallDetail?.pin_code || ""}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col sm="12" md="9">
                          <Label>Group</Label>
                          <Select
                            onInputChange={debounceGroupSearch}
                            placeholder={group}
                            value={group}
                            options={OptionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValueGroup}
                            type="text"
                            title="Group"
                            // required="required"
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="description"
                            type="textarea"
                            label="Description"
                            value={mahallDetail?.description || ""}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col sm="12" md="4">
                          <Label>
                            Financial Year Starting Month
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            type="select"
                            name="financial_year_starting_month"
                            value={
                              mahallDetail?.financial_year_starting_month || ""
                            }
                            validate={{ required: { value: true } }}
                            onChange={e => {
                              setFinancialYear(e.target.value)
                              handleFinancialYearSetting(e.target.value)
                            }}
                          >
                            <option>Select month...</option>
                            {months?.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item?.label}
                              </option>
                            ))}
                          </AvField>
                        </Col>
                        <Col sm="12" md="5">
                          <Label>
                            Financial Year
                            {/* <span className="text-danger">&#42 Starting Month;</span> */}
                          </Label>
                          <AvField
                            type="text"
                            name="financial_year"
                            readOnly
                            value={`${
                              fromFinancialDate ? fromFinancialDate : ""
                            }${" "} to ${" "}${
                              toFinancialDate ? toFinancialDate : ""
                            }`}
                          />
                        </Col>
                        {/* <Col sm="12" md="4">
                          <Label>Default Varisangya Amount<span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="default_varisankya_amount"
                            type="number"
                            value={mahallDetail?.default_varisankya_amount || ""}
                            validate={{
                              required: { value: true },
                              min: {
                                value: 0,
                                errorMessage: `${language === "mal"
                                  ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                                  : "Amount must be greater than 0"
                                  }`,
                              }
                            }}
                          />
                        </Col> */}
                      </div>

                      <div className="row mb-4">
                        <Col sm="12" md="4">
                          <Label>
                            Default Family Varisangya Amount
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="default_varisankya_amount"
                            type="number"
                            value={
                              mahallDetail?.default_varisankya_amount || ""
                            }
                            validate={{
                              required: { value: true },
                              min: {
                                value: 0,
                                errorMessage: `${
                                  language === "mal"
                                    ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                                    : "Amount must be greater than 0"
                                }`,
                              },
                            }}
                          />
                        </Col>
                        <Col sm="12" md="4">
                          <Label>
                            Default Family Member Varisangya Amount
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="default_familymember_varisankya_amount"
                            type="number"
                            value={
                              mahallDetail?.default_familymember_varisankya_amount ||
                              ""
                            }
                            validate={{
                              required: { value: true },
                              min: {
                                value: 0,
                                errorMessage: `${
                                  language === "mal"
                                    ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                                    : "Amount must be greater than 0"
                                }`,
                              },
                            }}
                          />
                        </Col>
                        <Col sm="12" md="4">
                          <Label>
                            Varisangya Period
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="varisankya_period"
                            type="number"
                            value={mahallDetail?.varisankya_period || ""}
                            validate={{
                              required: { value: true },
                              max: {
                                value: 12,
                                errorMessage: `${
                                  language === "mal"
                                    ? "തുക 13-ൽ കുറവായിരിക്കണം"
                                    : "period must be less than 13"
                                }`,
                              },
                            }}
                          />
                        </Col>
                      </div>

                      {/* <div className="row mb-4">
                        <Label>Default Varisangya Amount<span className="text-danger">&#42;</span>
                        </Label>
                        <Col sm="12" md="6">
                          <AvField
                            name="default_varisankya_amount"
                            type="number"
                            value={mahallDetail?.default_varisankya_amount || ""}
                            validate={{
                              required: { value: true },
                              min: {
                                value: 0,
                                errorMessage: `${language === "mal"
                                  ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                                  : "Amount must be greater than 0"
                                  }`,
                              }
                            }}
                          />
                        </Col>
                      </div> */}

                      <div className="row mb-4">
                        <Col>
                          <Label> Logo </Label>
                          <AvField
                            name="logo"
                            type="file"
                            onChange={changeHandler}
                          />
                          {handleFileSizes(filesSize)?.text && (
                            <p className="text-danger mt-1">
                              {handleFileSizes(filesSize)?.text}
                            </p>
                          )}
                          {imagePreview ? (
                            <Image
                              style={{ width: "150px", height: "150px" }}
                              className="my-2"
                              src={imagePreview.image}
                            />
                          ) : (
                            <Image
                              style={{ width: "150px", height: "150px" }}
                              className="my-2"
                              src={mahallDetail?.logo}
                            />
                          )}
                        </Col>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Update
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateMahall

UpdateMahall.propTypes = {
  history: PropTypes.object,
}

function updateMahallNew(
  selectedFile,
  values,
  villageId,
  lsgId,
  groupId,
  mahallDetail,
  dispatch,
  setCustomLoad
) {
  const { role } = useGetRole()
  const { token } = useGetToken()

  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append("logo", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("mahall_english_name", values.mahall_english_name)
  formData.append("mahall_malayalam_name", values.mahall_malayalam_name)
  formData.append("register_number", values.register_number)
  formData.append("email", values.email)
  formData.append("phone_number", values.phone_number)
  formData.append("established_date", values.established_date)
  formData.append("place", values.place)
  formData.append("village", villageId)
  formData.append("lsg", lsgId)
  formData.append("post_office", values.post_office)
  formData.append("pin_code", values.pin_code)
  formData.append("description", values.description)
  formData.append("version", values.version)
  formData.append("mahall_group", groupId)
  formData.append(
    "default_varisankya_amount",
    values.default_varisankya_amount
      ? parseInt(values.default_varisankya_amount)
      : 0
  )
  formData.append(
    "default_familymember_varisankya_amount",
    values.default_familymember_varisankya_amount
      ? parseInt(values.default_familymember_varisankya_amount)
      : 0
  )
  formData.append(
    "varisankya_period",
    values.varisankya_period ? parseInt(values.varisankya_period) : 1
  )
  formData.append(
    "financial_year_starting_month",
    values.financial_year_starting_month === "Select month..."
      ? null
      : parseInt(values.financial_year_starting_month)
  )

  axios
    .put(`${API_URL}/api/v1/mahall/mahall/${mahallDetail.id}/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        dispatch(updateMahallSuccess(res.data))
        history.back()
        doneNotification("Updated Successfully")
        setCustomLoad(false)
      } else {
        updateMahallFail()
        errorNotification()
      }
    })
    .catch(err => {
      updateMahallFail(err)
      errorNotification()
      // if (err?.response?.data?.error[0]) {
      //   updateMahallFail(err)
      //   errorNotification()
      //   setCustomLoad(false)
      // }
    })
}
