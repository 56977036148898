import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_GALLERIES,
  GET_GALLERY_DETAIL,
  CREATE_GALLERY,
  UPDATE_GALLERY,
  DELETE_GALLERY,
} from "./actionTypes"
import {
  getGalleriesSuccess,
  getGalleriesFail,
  getGalleryDetailSuccess,
  getGalleryDetailFail,
  createGallerySuccess,
  createGalleryFail,
  updateGallerySuccess,
  updateGalleryFail,
  deleteGallerySuccess,
  deleteGalleryFail,
} from "./actions"

import { get, post, del, patch } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"
import {
  getInstituteDetailSuccess,
  getInstituteDetailFail,
} from "store/actions"

const getGalleriesAPi = ({ instituteId }) => {
  if (instituteId) {
    return get(
      `/api/v1/institute/institute_image/?institute=${
        instituteId && instituteId
      }`
    )
  } else {
    return get(`/api/v1/institute/institute_image/`)
  }
}
const getInstituteDetailsAPi = instituteId => {
  return get(`/api/v1/institute/institute/${instituteId}/`)
}
const getGalleryDetailsAPi = ({ galleryId }) => {
  return get(`/api/v1/institute/institute_image/${galleryId}/`)
}
const createGalleryApi = ({ gallery }) => {
  return post("/api/v1/institute/institute_image/", gallery)
}
const updateGalleryApi = ({ galleryId, gallery }) => {
  return patch(`/api/v1/institute/institute_image/${galleryId}/`, gallery)
}
const deleteGalleryApi = ({ galleryId }) => {
  return del(`/api/v1/institute/institute_image/${galleryId}/`)
}

function* fetchGalleries({ payload }) {
  try {
    const response = yield call(getGalleriesAPi, payload)
    if (response && !response?.error) {
      yield put(getGalleriesSuccess(response))
    } else {
      yield put(getGalleriesFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getGalleriesFail(error))
  }
}

function* fetchGalleryDetail({ instituteId }) {
  try {
    const response = yield call(getGalleryDetailsAPi, instituteId)
    console.log(response)
    yield put(getGalleryDetailSuccess(response))
  } catch (error) {
    yield put(getGalleryDetailFail(error))
  }
}
function* onCreateGallery({ payload }) {
  try {
    const response = yield call(createGalleryApi, payload)
    yield put(createGallerySuccess(response))
    payload.history.push(`/institutes${response?.id}`)
    if (response) {
      doneNotification("Added Successfully")
    }
    try {
      const response2 = yield call(getInstituteDetailsAPi, response?.id)
      yield put(getInstituteDetailSuccess(response2))
    } catch (error) {
      yield put(getInstituteDetailFail(error))
    }
  } catch (error) {
    yield put(createGalleryFail(error))
    errorNotification()
  }
}

function* onUpdateGallery({ payload }) {
  try {
    const response = yield call(updateGalleryApi, payload)
    yield put(updateGallerySuccess(response))
    history.goBack()
    if (response) {
      doneNotification()
    }
  } catch (error) {
    yield put(updateGalleryFail(error))
    errorNotification()
  }
}

function* onDeleteGallery({ payload }) {
  try {
    const response = yield call(deleteGalleryApi, payload)
    if (response && !response?.error) {
      yield put(deleteGallerySuccess(response))
      doneNotification("Deleted Successfully")
      try {
        const response = yield call(getGalleriesAPi, payload)
        yield put(getGalleriesSuccess(response))
      } catch (error) {
        yield put(getGalleriesFail(error))
      }
    } else {
      yield put(deleteGalleryFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteGalleryFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* GalleriesSaga() {
  yield takeEvery(GET_GALLERIES, fetchGalleries)
  yield takeEvery(GET_GALLERY_DETAIL, fetchGalleryDetail)
  yield takeEvery(CREATE_GALLERY, onCreateGallery)
  yield takeEvery(UPDATE_GALLERY, onUpdateGallery)
  yield takeEvery(DELETE_GALLERY, onDeleteGallery)
}

export default GalleriesSaga
