import {
  GET_VILLAGES_SUCCESS,
  GET_VILLAGES_FAIL,
  GET_VILLAGE_DETAIL_SUCCESS,
  GET_VILLAGE_DETAIL_FAIL,
  GET_VILLAGE_DROPDOWN_SUCCESS,
  GET_VILLAGE_DROPDOWN_FAIL,
  CREATE_VILLAGE_SUCCESS,
  CREATE_VILLAGE_FAIL,
  UPDATE_VILLAGE_SUCCESS,
  UPDATE_VILLAGE_FAIL,
  DELETE_VILLAGE_SUCCESS,
  DELETE_VILLAGE_FAIL,
  GET_VILLAGES,
  GET_VILLAGE_DETAIL,
  GET_VILLAGE_DROPDOWN,
  UPDATE_VILLAGE,
  CREATE_VILLAGE,
  DELETE_VILLAGE,
} from "./actionTypes"

const INIT_STATE = {
  villages: [],
  villageDetail: {},
  searchVillages: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdVillage: {},
}

const Villages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VILLAGES:
    case UPDATE_VILLAGE:
    case CREATE_VILLAGE:
    case DELETE_VILLAGE:
    case GET_VILLAGE_DROPDOWN:
      return {
        ...state,
        loading: true,
      }
    case GET_VILLAGE_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_VILLAGES_SUCCESS:
      return {
        ...state,
        villages: action.payload,
        loading: false,
      }

    case GET_VILLAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_VILLAGE_SUCCESS:
      return {
        ...state,
        // villages: action.payload,
        createdVillage: action.payload,
        loading: false,
      }

    case CREATE_VILLAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_VILLAGE_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchVillages: action.payload,
        loading: false,
      }

    case GET_VILLAGE_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_VILLAGE_DETAIL_SUCCESS:
      return {
        ...state,
        villageDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_VILLAGE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_VILLAGE_SUCCESS:
      return {
        ...state,
        villageDetail: action.payload,
        loading: false,
      }

    case UPDATE_VILLAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_VILLAGE_SUCCESS:
      return {
        ...state,
        // villages: state.villages.filter(
        //   village => village.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_VILLAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Villages
