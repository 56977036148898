import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_BANNERS,
  GET_BANNER_DETAIL,
  CREATE_BANNER,
  UPDATE_BANNER,
  DELETE_BANNER,
} from "./actionTypes"
import {
  getBannersSuccess,
  getBannersFail,
  getBannerDetailSuccess,
  getBannerDetailFail,
  createBannerSuccess,
  createBannerFail,
  updateBannerSuccess,
  updateBannerFail,
  deleteBannerSuccess,
  deleteBannerFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getBannersAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/banner/banner/?search=${searchText && searchText}&page=${
        page && page
      }`
    )
  } else {
    return get(`/api/v1/banner/banner/?page=${page ? page : 1}`)
  }
}
// const getVarisangyasCardsAPi = () => {
//   return get(`/api/v1/varisankya/varisankya/?cards=all`)
// }
const getBannerDetailsAPi = bannerId => {
  return get(`/api/v1/banner/banner/${bannerId}/`)
}
const createBannerApi = ({ banner }) => {
  return post("/api/v1/banner/banner/", banner)
}
const updateBannerApi = ({ bannerId, banner }) => {
  return ApiPut(`/api/v1/banner/banner/${bannerId}/`, banner)
}
const deleteBannerApi = ({ bannerId }) => {
  return del(`/api/v1/banner/banner/${bannerId}/`)
}

function* fetchBanners({ payload }) {
  try {
    const response = yield call(getBannersAPi, payload)
    if (response && !response?.error) {
      yield put(getBannersSuccess(response))
    } else {
      yield put(getBannersFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getBannersFail(error))
  }
}

// function* fetchVarisangyasCards() {
//   try {
//     const response = yield call(getVarisangyasCardsAPi)
//     if (response && !response?.error) {
//       yield put(getVarisangyaCardsSuccess(response))
//     } else {
//       yield put(getVarisangyaCardsFail(response))
//       errorNotification()
//     }
//   } catch (error) {
//     yield put(getVarisangyaCardsFail(error))
//   }
// }

function* fetchBannerDetail({ bannerId }) {
  try {
    const response = yield call(getBannerDetailsAPi, bannerId)
    if (response && !response?.error) {
      yield put(getBannerDetailSuccess(response))
    } else {
      yield put(getBannerDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getBannerDetailFail(error))
  }
}
function* onCreateBanner({ payload }) {
  try {
    const response = yield call(createBannerApi, payload)
    if (response && !response?.error) {
      yield put(createBannerSuccess(response))
      if (payload.history) {
        payload.history.push("/banners")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createBannerFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createBannerFail(error))
    errorNotification()
  }
}

function* onUpdateBanner({ payload }) {
  try {
    const response = yield call(updateBannerApi, payload)
    if (response && !response?.error) {
      payload.history.push("/banners")
      yield put(updateBannerSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateBannerFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateBannerFail(error))
    errorNotification()
  }
}

function* onDeleteBanner({ payload }) {
  try {
    const response = yield call(deleteBannerApi, payload)
    if (response && !response?.error) {
      yield put(deleteBannerSuccess(payload?.bannerId))
      doneNotification("Deleted Successfully")
      if (payload?.history) {
        payload.history.goBack()
      }
    } else {
      yield put(deleteBannerFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteBannerFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* BannersSaga() {
  yield takeEvery(GET_BANNERS, fetchBanners)
  // yield takeEvery(GET_VARISANGYA_CARDS, fetchVarisangyasCards)
  yield takeEvery(GET_BANNER_DETAIL, fetchBannerDetail)
  yield takeEvery(CREATE_BANNER, onCreateBanner)
  yield takeEvery(UPDATE_BANNER, onUpdateBanner)
  yield takeEvery(DELETE_BANNER, onDeleteBanner)
}

export default BannersSaga
