import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_VARISANGYA_SPLIT_LEDGERS,
  CREATE_VARISANGYA_SPLIT_LEDGER,
} from "./actionTypes"
import {
  getVarisangyaSplitLedgersSuccess,
  getVarisangyaSplitLedgersFail,
  createVarisangyaSplitLedgerSuccess,
  createVarisangyaSplitLedgerFail,
} from "./actions"

import { get, post } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getVarisangyaSplitLedgersAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/varisankya/varisankya_split_ledger/?search=${
        searchText && searchText
      }&page=${page && page}`
    )
  } else {
    return get(
      `/api/v1/varisankya/varisankya_split_ledger/?page=${page ? page : 1}`
    )
  }
}
const createVarisangyaSplitLedgerApi = ({ varisangyaSplitLedger }) => {
  return post(
    "/api/v1/varisankya/varisankya_split_ledger/",
    varisangyaSplitLedger
  )
}

function* fetchVarisangyaSplitLedgers({ payload }) {
  try {
    const response = yield call(getVarisangyaSplitLedgersAPi, payload)
    if (response && !response?.error) {
      yield put(getVarisangyaSplitLedgersSuccess(response))
    } else {
      yield put(getVarisangyaSplitLedgersFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getVarisangyaSplitLedgersFail(error))
  }
}

function* onCreateVarisangyaSplitLedger({ payload }) {
  try {
    const response = yield call(createVarisangyaSplitLedgerApi, payload)
    if (response && !response?.error) {
      yield put(createVarisangyaSplitLedgerSuccess(response))
      if (payload.history) {
        payload.history.push("/varisangya/splits")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createVarisangyaSplitLedgerFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createVarisangyaSplitLedgerFail(error))
    errorNotification()
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* VarisangyaSplitLedgersSaga() {
  yield takeEvery(GET_VARISANGYA_SPLIT_LEDGERS, fetchVarisangyaSplitLedgers)
  yield takeEvery(CREATE_VARISANGYA_SPLIT_LEDGER, onCreateVarisangyaSplitLedger)
}

export default VarisangyaSplitLedgersSaga
