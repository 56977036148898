import React, { useState, useEffect } from "react"
import {
  // Badge,
  // Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams, Link } from "react-router-dom"
import PropTypes from "prop-types"
//actions institute
//components
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getAssetItemDetail, getAssetItemTransactions } from "store/actions"

const MahallInstituteAssetItemDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const limit = 10

  const { assetItemDetail, loading, language, assetItemTransactions } =
    useSelector(state => ({
      assetItemDetail: state.AssetItems.assetItemDetail,
      language: state.Layout.language,
      loading: state.AssetItems.loading,
      assetItemTransactions: state.AssetItemTransactions.assetItemTransactions,
    }))

  const totalPages = Math.ceil(assetItemTransactions?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getAssetItemTransactions(assetItemDetail?.id, searchText, page))
  }, [assetItemDetail, searchText, page])

  // const cardData = [
  //   {
  //     title: `${language === "mal" ? "ആകെ സകാത്ത്" : "Total ZakatItems"}`,
  //     cardValue: assetItemDetail?.zakat_item_count,
  //     iconClass: "error-circle",
  //     routeLink: "#!",
  //     loading: loading,
  //     alt: `${language === "mal" ? "സകാത് ഇനങ്ങൾ ഇല്ല" : "No Zakat Items"}`,
  //   },
  //   {
  //     title: `${language === "mal" ? "നൽകിയത്" : "Assets Given"}`,
  //     cardValue: assetItemDetail?.is_given_count,
  //     iconClass: "error-circle",
  //     routeLink: "#!",
  //     loading: loading,
  //     alt: `${language === "mal" ? "നൽകിയ സകാതുകൾ ഇല്ല" : "No Assets Given"}`,
  //   },
  //   // {
  //   //   title: "Unpaid",
  //   //   cardValue: ZakatItemCards?.total_fee_not_paid_Assets,
  //   //   iconClass: "check-circle",
  //   //   routeLink: "#!",
  //   //   loading: loading,
  //   //   alt: "No unpaid Assets",
  //   // },
  // ]

  useEffect(() => {
    dispatch(getAssetItemDetail(params.id))
  }, [])

  const debounceAssetItemTransactionSearch = debounce(
    value => setSearchText(value),
    600
  )

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
      sort: true,
    },
    // {
    //   dataField: "voucher_number",
    //   text: `${
    //     ledgerDetail?.ledgers?.ledger_category?.english === "Income"
    //       ? language === "mal"
    //         ? "രസീത് നം."
    //         : "Receipt no."
    //       : ledgerDetail?.ledgers?.ledger_category?.english === "Expense"
    //       ? language === "mal"
    //         ? "വൗച്ചർ നം."
    //         : "Voucher no."
    //       : language === "mal"
    //       ? "ഇടപാട് നം."
    //       : "Tr no."
    //   }`,
    // },
    {
      dataField: "title",
      text: `${language === "mal" ? "വിശദാംശങ്ങൾ" : "Particulars"}`,
    },
    {
      dataField: "date",
      text: `${language === "mal" ? "തീയതി" : "Date"}`,
      sort: true,
    },
    {
      dataField: "quantity",
      text: `${language === "mal" ? "അളവ്" : "Quantity"}`,
    },
    {
      dataField: "value",
      text: `${language === "mal" ? "മൂല്യം" : "Value"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
    // {
    //   dataField: "print",
    //   text: "",
    // },
  ]

  const assetItemTransactionsData = map(
    assetItemTransactions?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,
      value: (
        <p
          className={`${
            item?.transaction_type === "debit" ? "text-danger" : "text-success"
          } m-0`}
        >
          {item?.value}
        </p>
      ),
      action: (
        <div className="m-0">
          <Link
            to={`/mahall/institute/assetItem/transaction/${item?.id}`}
            className="btn btn-success btn-sm"
          >
            {language === "mal" ? "കാണുക" : "View"}
          </Link>
        </div>
      ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  return (
    <>
      <MetaTags>
        <title>Asset Item | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal"
              ? "ആസ്തി ഇനത്തിന്റെ വിവരങ്ങൾ"
              : "Asset Item Details"
          }
          breadcrumbItem={language === "mal" ? "ആസ്തി ഇനം" : "Asset Item"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col className="col-lg-12 col-md-12">
                <Row>
                  <Col md="4">
                    <Card className="overflow-hidden bg-primary bg-soft">
                      <div className="">
                        <Row>
                          <div className="text-primary p-4">
                            <div className="px-2">
                              <Row>
                                <Col md="12">
                                  {/* <h5 className="text-khaf">Asset</h5> */}
                                  <h5 className="text-khaf">
                                    {assetItemDetail?.item_name}
                                  </h5>
                                  <p className="m-0 text-muted">
                                    {assetItemDetail?.remark}
                                  </p>
                                  {/* <div className=" d-flex justify-content-end m-3">
                                    <Button type="button" className="btn-sm bg-khaf-blue text-white" onClick={() => setModalAssetUpdate(true)}>
                                      <i className="bx bx-edit text-center"></i>{language === "mal" ? " അപ്ഡേറ്റ്" : "Update"}</Button>
                                  </div> */}
                                  {/* <p className="text-muted">From {(moment(ledgerDetail?.ledgers?.date_added).format("DD/MM/YYYY"))}</p> */}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                  {/* <Col md="8">
                    <Row>
                      {cardData?.map((item, key) => (
                        <Col sm="12" md="6" lg="6" key={key} className="sm:p-0">
                          <Card className="blog-stats-wid">
                            <CardBody>
                              <div className="d-flex flex-wrap">
                                <div className="me-3">
                                  <h5 className="text-muted">{item.title}</h5>
                                  <h4 className={`${item.className}`}>
                                    {item.cardValue}
                                  </h4>
                                </div>
                                <div className="avatar-sm ms-auto">
                                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                    <i
                                      className={
                                        "bx bx-" +
                                        `${item.iconClass} ${item.className}`
                                      }
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col> */}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="">
                <React.Fragment>
                  <Row style={{ minHeight: "600px", display: "flex" }}>
                    <Col className="col-lg-12 col-md-12">
                      <Card>
                        <CardBody>
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={assetItemTransactionsData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <form
                                          className="app-search d-lg-block"
                                          onChange={e =>
                                            debounceAssetItemTransactionSearch(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search..."
                                              defaultValue={searchText}
                                            />
                                            <span className="bx bx-search-alt" />
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="8">
                                    <div className="d-flex justify-content-end">
                                      <Link
                                        to={{
                                          pathname:
                                            "/mahall/institute/assetItem/add/entry",
                                          state: assetItemDetail,
                                        }}
                                        className="btn btn-primary btn-sm"
                                      >
                                        {language === "mal"
                                          ? "+ എൻട്രി ചേർക്കുക"
                                          : "+ Add Entry"}
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                                {loading ? (
                                  <Spinner
                                    color="secondary"
                                    className="d-block m-auto"
                                  />
                                ) : (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap table-hover"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <MyPagination
                                      totalPages={pages}
                                      page={page}
                                      setPage={setPage}
                                    />
                                  </>
                                )}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </React.Fragment>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default MahallInstituteAssetItemDetails

MahallInstituteAssetItemDetails.propTypes = {
  history: PropTypes.object,
}
