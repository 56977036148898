import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_VILLAGES,
  GET_VILLAGE_DETAIL,
  CREATE_VILLAGE,
  UPDATE_VILLAGE,
  DELETE_VILLAGE,
  GET_VILLAGE_DROPDOWN,
} from "./actionTypes"
import {
  getVillagesSuccess,
  getVillagesFail,
  getVillageDropdownSuccess,
  getVillageDropdownFail,
  getVillageDetailSuccess,
  getVillageDetailFail,
  createVillageSuccess,
  createVillageFail,
  updateVillageSuccess,
  updateVillageFail,
  deleteVillageSuccess,
  deleteVillageFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getVillagesAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/village/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/mahall/village/?page=${page ? page : 1}`)
  }
}

const getVillageDropdownAPi = searchText => {
  return get(
    `/api/v1/mahall/village_dropdown?search=${searchText && searchText}`
  )
}
const getVillageDetailsAPi = villageId => {
  return get(`/api/v1/mahall/village/${villageId}/`)
}
const createVillageApi = ({ village }) => {
  return post("/api/v1/mahall/village/", village)
}
const updateVillageApi = ({ villageId, village }) => {
  return ApiPut(`/api/v1/mahall/village/${villageId}/`, village)
}
const deleteVillageApi = ({ villageId }) => {
  return del(`/api/v1/mahall/village/${villageId}/`)
}

function* fetchVillages({ payload }) {
  try {
    const response = yield call(getVillagesAPi, payload)
    if (response && !response?.error) {
      yield put(getVillagesSuccess(response))
    } else {
      yield put(getVillagesFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getVillagesFail(error))
  }
}

function* fetchVillageDropdown({ payload }) {
  try {
    const response = yield call(getVillageDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getVillageDropdownSuccess(response))
    } else {
      yield put(getVillageDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getVillageDropdownFail(error))
  }
}

function* fetchVillageDetail({ villageId }) {
  try {
    const response = yield call(getVillageDetailsAPi, villageId)
    if (response && !response?.error) {
      yield put(getVillageDetailSuccess(response))
    } else {
      yield put(getVillageDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getVillageDetailFail(error))
  }
}
function* onCreateVillage({ payload }) {
  try {
    const response = yield call(createVillageApi, payload)
    if (response && !response?.error) {
      yield put(createVillageSuccess(response))
      payload.history.push("/villages")
      doneNotification("Created Successfully")
      // get villages
      try {
        const response = yield call(getVillagesAPi, payload)
        yield put(getVillagesSuccess(response))
      } catch (error) {
        yield put(getVillagesFail(error))
      }
    } else {
      yield put(createVillageFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createVillageFail(error))
    // console.log('error: ', error);
    errorNotification()
  }
}

function* onUpdateVillage({ payload }) {
  try {
    const response = yield call(updateVillageApi, payload)
    if (response && !response?.error) {
      yield put(updateVillageSuccess(response))
      payload.history.push("/villages")
      doneNotification("Updated Successfully")
    } else {
      yield put(updateVillageFail(response))
      errorNotification()
    }
    // get villages
    // try {
    //   const response2 = yield call(getVillagesAPi, payload)
    //   yield put(getVillagesSuccess(response2))
    // } catch (error) {
    //   yield put(getVillagesFail(error))
    // }
  } catch (error) {
    yield put(updateVillageFail(error))
    errorNotification()
  }
}

function* onDeleteVillage({ payload }) {
  try {
    const response = yield call(deleteVillageApi, payload)
    if (response && !response?.error) {
      yield put(deleteVillageSuccess(response))
      doneNotification("Deleted Successfully")
      // get villages
      try {
        const response = yield call(getVillagesAPi, payload)
        yield put(getVillagesSuccess(response))
      } catch (error) {
        yield put(getVillagesFail(error))
      }
    } else {
      yield put(deleteVillageFail(response))
      errorNotification()
    }
  } catch (error) {
    // console.log('error: ', error);
    errorNotification()
    yield put(deleteVillageFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* VillagesSaga() {
  yield takeEvery(GET_VILLAGES, fetchVillages)
  yield takeEvery(GET_VILLAGE_DETAIL, fetchVillageDetail)
  yield takeEvery(GET_VILLAGE_DROPDOWN, fetchVillageDropdown)
  yield takeEvery(CREATE_VILLAGE, onCreateVillage)
  yield takeEvery(UPDATE_VILLAGE, onUpdateVillage)
  yield takeEvery(DELETE_VILLAGE, onDeleteVillage)
}

export default VillagesSaga
