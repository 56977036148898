import React, { useState, useEffect } from "react"
import { Badge, Button, Col, Container, Row, Card, CardBody } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
//actions institute
import ReactApexChart from "react-apexcharts"

import WelcomeComp from "./WelcomeComp"

import { deleteInstitute, getInstituteDetail } from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import image from "assets/images/bg_login.jpg"

const InstituteUserMahallDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { instituteDetail, language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    detailLoading: state.Mahalls.detailLoading,
    instituteDetail: state.Institutes.instituteDetail,
    language: state.Layout.language,
  }))

  const series = [56, 38, 26]
  const options = {
    labels: ["Category 1", "Category 2", "Category 3"],
    colors: ["#556ee6", "#34c38f", "#f46a6a"],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
  }

  const [isOpen, setIsOpen] = useState(false)

  // const handleDelete = () => {
  //   setIsOpen(true)
  // }
  const handleDeleteEvent = () => {
    dispatch(deleteInstitute(instituteDetail.id, history))
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getInstituteDetail(params.id))
  }, [])

  const cardData = [
    {
      title: `${language === "mal" ? "യൂസറുകൾ" : "Users"}`,
      cardValue: "NA",
      iconClass: "phone",
      routeLink: "/enquiries",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "ഗ്രൂപ്പുകൾ" : "Accounts"}`,
      cardValue: "NA",
      iconClass: "globe",
      routeLink: "/groups",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Accounts"}`,
    },
    {
      title: `${language === "mal" ? "ഗ്രൂപ്പ് അഡ്മിൻസ്" : "Committees"}`,
      cardValue: "NA",
      iconClass: "globe",
      routeLink: "/groups",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Committees"}`,
    },
    {
      title: `${language === "mal" ? "മഹല്ലുകൾ" : "Committee Members"}`,
      cardValue: "NA",
      iconClass: "map",
      routeLink: "/mahalls",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Committee Members"}`,
    },
    {
      title: `${language === "mal" ? "മഹല്ല് യൂസറുകൾ" : "Meetings"}`,
      cardValue: "NA",
      iconClass: "user",
      routeLink: "/mahallusers",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Meetings"}`,
    },
    {
      title: `${language === "mal" ? "കുടുംബങ്ങൾ" : "Reports"}`,
      cardValue: "NA",
      iconClass: "home",
      routeLink: "/families",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Reports"}`,
    },
  ]

  const Summary = [
    {
      heading: "This Year",
      value: "20555",
      percentage: "22.5%",
      percentageText: "text-success ms-1",
      iconClass: "bx bx-up-arrow-alt text-success",
    },
    {
      heading: "This Month",
      value: "20555",
      percentage: "45.7%",
      percentageText: "text-success ms-1",
      iconClass: "bx bx-up-arrow-alt text-success",
    },
    {
      heading: "This Week",
      value: "20555",
      percentage: "10.05%",
      percentageText: "text-danger ms-1",
      iconClass: "bx bx-down-arrow-alt text-danger",
    },
    {
      heading: "Today",
      value: "20555",
      percentage: "4.7%",
      percentageText: "text-success ms-1",
      iconClass: "bx bx-up-arrow-alt text-success",
    },
  ]

  const Data = [
    {
      title: "new committee",
      value1: "4",
      value2: "3",
      value3: "9",
      value4: "5",
    },
    {
      title: "new committee",
      value1: "4",
      value2: "3",
      value3: "9",
      value4: "5",
    },
    {
      title: "new committee",
      value1: "4",
      value2: "3",
      value3: "9",
      value4: "5",
    },
  ]

  const Activity = [
    {
      title: "new committee",
      list1: "yugygyiu",
      list2: "huhuh",
      list3: "fftyfty",
      date: "29/10/99",
      logo: `${instituteDetail?.logo}`,
      view: "View More",
    },
    {
      title: "new committee",
      list1: "yugygyiu",
      list2: "huhuh",
      list3: "fftyfty",
      date: "29/10/99",
      logo: `${instituteDetail?.logo}`,
      view: "View More",
    },
  ]

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <MetaTags>
        <title>Institute | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="All Institutes" breadcrumbItem="Institute" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg="12" className="">
                <Row>
                  <Col lg="4">
                    <WelcomeComp />
                  </Col>
                  <Col lg="8">
                    <Row>
                      {cardData?.map((item, key) => (
                        <Col
                          sm="12"
                          md="6"
                          lg="4"
                          key={key}
                          className="sm:p-0 mt-2"
                        >
                          <Card className="blog-stats-wid">
                            <CardBody>
                              <Link to={item.routeLink && item.routeLink}>
                                <div className="d-flex flex-wrap">
                                  <div className="me-3">
                                    <p className="text-muted mb-3">
                                      {item.title}
                                    </p>
                                    {item.cardValue && (
                                      <h5 className="mb-0">{item.cardValue}</h5>
                                    )}
                                    <p className="m-0 text-info">
                                      {item.cardValue !== "" ? <></> : item.alt}
                                    </p>
                                  </div>

                                  <div className="avatar-sm ms-auto">
                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                      <i
                                        className={
                                          "bx bx-" + `${item.iconClass}`
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex">
                    <h4>Gallery</h4>
                    <div>
                      <Button className="btn btn-success btn-sm mx-3">
                        +Add
                      </Button>
                    </div>
                  </div>
                  <Col md="12">
                    <div className="mt-2 d-flex gallery">
                      <img className="gallery-img" src={image} alt="" />
                      <img className="gallery-img" src={image} alt="" />
                      <img className="gallery-img" src={image} alt="" />
                      <img className="gallery-img" src={image} alt="" />
                      <img className="gallery-img" src={image} alt="" />
                      <img className="gallery-img" src={image} alt="" />
                      <img className="gallery-img" src={image} alt="" />
                      <img className="gallery-img" src={image} alt="" />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md="12" lg="6">
                    <h4>Account</h4>
                    <Card>
                      <CardBody>
                        <h6 className="text-muted">summary:</h6>
                        <Container fluid>
                          <div className="d-flex justify-content-between mt-2">
                            <Button className="btn btn-info  btn-label">
                              <i className="bx bx-down-arrow-alt label-icon"></i>{" "}
                              24355.00
                            </Button>
                            <Button className="btn btn-danger  btn-label">
                              <i className="bx bx-up-arrow-alt label-icon"></i>{" "}
                              24355.00
                            </Button>
                            <Button className="btn btn-success  btn-label ">
                              <i className="mdi mdi-scale-balance label-icon"></i>
                              24355.00
                            </Button>
                          </div>
                          <div className="d-flex justify-content-between">
                            {Summary.map((item, key) => (
                              <div key={key} className="mt-2">
                                <h6 className="text-muted">{item.heading}</h6>
                                <p className="d-flex">
                                  {item.value}
                                  <p className={`${item.percentageText}`}>
                                    {item.percentage}
                                    <i className={`${item.iconClass}`}></i>
                                  </p>
                                </p>
                              </div>
                            ))}
                          </div>
                        </Container>
                        <h6 className="text-muted mt-4">All Ledgers:</h6>
                        <Container fluid>
                          <Row>
                            {Data.map((itm, key) => (
                              <Col
                                sm="12"
                                md="12"
                                lg="12"
                                key={key}
                                className="sm:p-0 mt-1"
                              >
                                <Card className="blog-stats-wid bg-info">
                                  <CardBody
                                    style={{
                                      backgroundColor: "#c7d9e9",
                                      borderRadius: "11px",
                                    }}
                                  >
                                    <Link to="#!">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h5>{itm.title}</h5>
                                        <Badge className="badge-soft-primary">
                                          In
                                        </Badge>
                                        <Badge className="badge-soft-danger">
                                          Out
                                        </Badge>
                                        <Badge className="badge-soft-success">
                                          Balance
                                        </Badge>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h6>Last Meeting :{itm.value1}</h6>
                                        <p>{itm.value2}</p>
                                        <p>{itm.value3}</p>
                                        <p>{itm.value4}</p>
                                      </div>
                                    </Link>
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                          <Button className="btn btn-info w-100">
                            + Add New Ledger
                          </Button>
                        </Container>
                        <h6 className="text-muted mt-4">Category Summary:</h6>
                        <div className="mt-2">
                          <div id="donut-chart">
                            <ReactApexChart
                              options={options}
                              series={series}
                              type="donut"
                              height={260}
                              className="apex-charts"
                            />
                          </div>
                        </div>

                        <div className="text-center text-muted">
                          <Row>
                            <Col xs="4">
                              <div className="mt-4">
                                <p className="mb-2 text-truncate">
                                  <i className="mdi mdi-circle text-primary me-1" />{" "}
                                  Category 1
                                </p>
                                <h5>
                                  <i className="bx bx-rupee"></i> 2,132
                                </h5>
                              </div>
                            </Col>
                            <Col xs="4">
                              <div className="mt-4">
                                <p className="mb-2 text-truncate">
                                  <i className="mdi mdi-circle text-success me-1" />{" "}
                                  Category 2
                                </p>
                                <h5>
                                  <i className="bx bx-rupee"></i> 1,763
                                </h5>
                              </div>
                            </Col>
                            <Col xs="4">
                              <div className="mt-4">
                                <p className="mb-2 text-truncate">
                                  <i className="mdi mdi-circle text-danger me-1" />{" "}
                                  Category 3
                                </p>
                                <h5>
                                  <i className="bx bx-rupee"></i> 973
                                </h5>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" lg="6">
                    <h4>committee</h4>
                    <Card>
                      <CardBody>
                        <h6 className="text-muted">All Committees:</h6>
                        <Container fluid>
                          <Row>
                            {Data.map((itm, key) => (
                              <Col
                                sm="12"
                                md="12"
                                lg="12"
                                key={key}
                                className="sm:p-0 mt-1"
                              >
                                <Card className="blog-stats-wid bg-info">
                                  <CardBody
                                    style={{
                                      backgroundColor: "#c9ebdf",
                                      borderRadius: "11px",
                                    }}
                                  >
                                    <Link to="#!">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h5>{itm.title}</h5>
                                        <Badge className="badge-soft-primary">
                                          Members
                                        </Badge>
                                        <Badge className="badge-soft-secondary">
                                          Meetings
                                        </Badge>
                                        <Badge className="badge-soft-danger">
                                          Attendance %
                                        </Badge>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h6>Last Meeting :{itm.value1}</h6>
                                        <p>{itm.value2}</p>
                                        <p>{itm.value3}</p>
                                        <p>{itm.value4}</p>
                                      </div>
                                    </Link>
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                          <Button className="btn btn-success w-100">
                            + Add New Committee
                          </Button>
                        </Container>
                        <h6 className="text-muted mt-4">Recent Activities :</h6>
                        <Container fluid>
                          {Activity.map((item, key) => (
                            <div key={key}>
                              <div className="d-flex mt-2">
                                <div>
                                  <img
                                    src={item.logo}
                                    alt=""
                                    className="img-thumbnail rounded-circle"
                                  />
                                </div>
                                <div className="mx-4">
                                  <h4>{item.title}</h4>
                                  <ul>
                                    <li>{item.list1}</li>
                                    <li>{item.list2}</li>
                                    <li>{item.list3}</li>
                                  </ul>
                                  <h5 className="text-muted">{item.date}</h5>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <Link to="#!">{item.view}</Link>
                              </div>
                            </div>
                          ))}
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <div style={{ position: "relative", left: "10px" }}>
                            <Link
                              style={{ opacity: 0.8 }}
                              to="#"
                              className={`btn btn-danger w-md ${loading && "disabled"
                                }  btn-m`}
                              onClick={handleDelete}
                            >
                              Delete
                            </Link>
                          </div> */}
          </div>
        </Container>
      </div>
    </>
  )
}

export default InstituteUserMahallDetails

InstituteUserMahallDetails.propTypes = {
  history: PropTypes.object,
}
