import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_MAHALL_NOTIFICATIONS,
  GET_MAHALL_NOTIFICATIONS_CARDS,
  GET_MAHALL_NOTIFICATION_DETAIL,
  CREATE_MAHALL_NOTIFICATION,
  UPDATE_MAHALL_NOTIFICATION,
  DELETE_MAHALL_NOTIFICATION,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL,
  CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  GET_INDIVIDUAL_NOTIFICATIONS,
  GET_INDIVIDUAL_NOTIFICATIONS_CARDS,
  GET_INDIVIDUAL_NOTIFICATION_DETAIL,
  CREATE_INDIVIDUAL_NOTIFICATION,
  UPDATE_INDIVIDUAL_NOTIFICATION,
  DELETE_INDIVIDUAL_NOTIFICATION,
} from "./actiontypes"
import {
  getMahallNotificationsSuccess,
  getMahallNotificationsFail,
  getMahallNotificationsCardsSuccess,
  getMahallNotificationsCardsFail,
  getMahallNotificationDetailSuccess,
  getMahallNotificationDetailFail,
  createMahallNotificationSuccess,
  createMahallNotificationFail,
  updateMahallNotificationSuccess,
  updateMahallNotificationFail,
  deleteMahallNotificationSuccess,
  deleteMahallNotificationFail,
  getFamilyMemberCollectionNotificationsSuccess,
  getFamilyMemberCollectionNotificationsFail,
  getFamilyMemberCollectionNotificationsCardsSuccess,
  getFamilyMemberCollectionNotificationsCardsFail,
  getFamilyMemberCollectionNotificationDetailSuccess,
  getFamilyMemberCollectionNotificationDetailFail,
  createFamilyMemberCollectionNotificationSuccess,
  createFamilyMemberCollectionNotificationFail,
  updateFamilyMemberCollectionNotificationSuccess,
  updateFamilyMemberCollectionNotificationFail,
  deleteFamilyMemberCollectionNotificationSuccess,
  deleteFamilyMemberCollectionNotificationFail,
  getIndividualNotificationsSuccess,
  getIndividualNotificationsFail,
  getIndividualNotificationsCardsSuccess,
  getIndividualNotificationsCardsFail,
  getIndividualNotificationDetailSuccess,
  getIndividualNotificationDetailFail,
  createIndividualNotificationSuccess,
  createIndividualNotificationFail,
  updateIndividualNotificationSuccess,
  updateIndividualNotificationFail,
  deleteIndividualNotificationSuccess,
  deleteIndividualNotificationFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

// ------------------------------------MAHALL NOTIFICATIONS----------------------------------------

const getMahallNotificationsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/notification/mahall_notification/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/notification/mahall_notification/?page=${page ? page : 1}`
    )
  }
}
const getMahallNotificationsCardsAPi = () => {
  return get(`/api/v1/notification/mahall_notification/?cards=all`)
}
const getMahallNotificationDetailsAPi = mahallNotificationId => {
  return get(
    `/api/v1/notification/mahall_notification/${mahallNotificationId}/`
  )
}
const createMahallNotificationApi = ({ mahallNotification }) => {
  return post("/api/v1/notification/mahall_notification/", mahallNotification, {
    "Content-type": "",
  })
}
const updateMahallNotificationApi = ({
  mahallNotificationId,
  mahallNotification,
}) => {
  return ApiPut(
    `/api/v1/notification/mahall_notification/${mahallNotificationId}/`,
    mahallNotification
  )
}
const deleteMahallNotificationApi = ({ mahallNotificationId }) => {
  return del(
    `/api/v1/notification/mahall_notification/${mahallNotificationId}/`
  )
}

function* fetchMahallNotifications({ payload }) {
  try {
    const response = yield call(getMahallNotificationsAPi, payload)
    if (response && !response?.error) {
      yield put(getMahallNotificationsSuccess(response))
    } else {
      yield put(getMahallNotificationsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallNotificationsFail(error))
  }
}

function* fetchMahallNotificationsCards({ payload }) {
  try {
    const response = yield call(getMahallNotificationsCardsAPi, payload)
    if (response && !response?.error) {
      yield put(getMahallNotificationsCardsSuccess(response))
    } else {
      yield put(getMahallNotificationsCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallNotificationsCardsFail(error))
  }
}

function* fetchMahallNotificationDetail({ mahallNotificationId }) {
  try {
    const response = yield call(
      getMahallNotificationDetailsAPi,
      mahallNotificationId
    )
    if (response && !response?.error) {
      yield put(getMahallNotificationDetailSuccess(response))
    } else {
      yield put(getMahallNotificationDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMahallNotificationDetailFail(error))
  }
}

function* onCreateMahallNotification({ payload }) {
  // console.log("data :", payload)
  try {
    const response = yield call(createMahallNotificationApi, payload)
    if (response && !response?.error) {
      yield put(createMahallNotificationSuccess(response))
      // payload.history.push("/mahalls")
      doneNotification("Created Successfully")
    } else {
      yield put(createMahallNotificationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createMahallNotificationFail(error))
    errorNotification()
  }
}

function* onUpdateMahallNotification({ payload }) {
  try {
    const response = yield call(updateMahallNotificationApi, payload)
    if (response && !response?.error) {
      yield put(updateMahallNotificationSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateMahallNotificationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateMahallNotificationFail(error))
    errorNotification()
  }
}

function* onDeleteMahallNotification({ payload }) {
  try {
    const response = yield call(deleteMahallNotificationApi, payload)
    if (response && !response?.error) {
      payload.history.push("/mahall/notifications")
      yield put(deleteMahallNotificationSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteMahallNotificationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteMahallNotificationFail(error))
  }
}

// ------------------------------------FAMILY MEMBER COLLECTION NOTIFICATIONS----------------------------------------

const getFamilyMemberCollectionNotificationsAPi = ({
  searchText,
  page,
  collectionId,
}) => {
  if (collectionId) {
    return get(
      `/api/v1/notification/familymember_collection_notification/?collection=${
        collectionId && collectionId
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/notification/familymember_collection_notification/?page=${
        page ? page : 1
      }`
    )
  }
}
const getFamilyMemberCollectionNotificationsCardsAPi = () => {
  return get(
    `/api/v1/notification/familymember_collection_notification/?cards=all`
  )
}
const getFamilyMemberCollectionNotificationDetailsAPi =
  familyMemberCollectionNotificationId => {
    return get(
      `/api/v1/notification/familymember_collection_notification/${familyMemberCollectionNotificationId}/`
    )
  }
const createFamilyMemberCollectionNotificationApi = ({
  familyMemberCollectionNotification,
}) => {
  return post(
    "/api/v1/notification/familymember_collection_notification/",
    familyMemberCollectionNotification,
    { "Content-type": "" }
  )
}
const updateFamilyMemberCollectionNotificationApi = ({
  familyMemberCollectionNotificationId,
  familyMemberCollectionNotification,
}) => {
  return ApiPut(
    `/api/v1/notification/familymember_collection_notification/${familyMemberCollectionNotificationId}/`,
    familyMemberCollectionNotification
  )
}
const deleteFamilyMemberCollectionNotificationApi = ({
  familyMemberCollectionNotificationId,
}) => {
  return del(
    `/api/v1/notification/familymember_collection_notification/${familyMemberCollectionNotificationId}/`
  )
}

function* fetchFamilyMemberCollectionNotifications({ payload }) {
  try {
    const response = yield call(
      getFamilyMemberCollectionNotificationsAPi,
      payload
    )
    if (response && !response?.error) {
      yield put(getFamilyMemberCollectionNotificationsSuccess(response))
    } else {
      yield put(getFamilyMemberCollectionNotificationsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberCollectionNotificationsFail(error))
  }
}

function* fetchFamilyMemberCollectionNotificationsCards({ payload }) {
  try {
    const response = yield call(
      getFamilyMemberCollectionNotificationsCardsAPi,
      payload
    )
    if (response && !response?.error) {
      yield put(getFamilyMemberCollectionNotificationsCardsSuccess(response))
    } else {
      yield put(getFamilyMemberCollectionNotificationsCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberCollectionNotificationsCardsFail(error))
  }
}

function* fetchFamilyMemberCollectionNotificationDetail({
  familyMemberCollectionNotificationId,
}) {
  try {
    const response = yield call(
      getFamilyMemberCollectionNotificationDetailsAPi,
      familyMemberCollectionNotificationId
    )
    if (response && !response?.error) {
      yield put(getFamilyMemberCollectionNotificationDetailSuccess(response))
    } else {
      yield put(getFamilyMemberCollectionNotificationDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberCollectionNotificationDetailFail(error))
  }
}

function* onCreateFamilyMemberCollectionNotification({ payload }) {
  // console.log("data :", payload)
  try {
    const response = yield call(
      createFamilyMemberCollectionNotificationApi,
      payload
    )
    if (response && !response?.error) {
      yield put(createFamilyMemberCollectionNotificationSuccess(response))
      // payload.history.push("/mahalls")
      doneNotification("Created Successfully")
    } else {
      yield put(createFamilyMemberCollectionNotificationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createFamilyMemberCollectionNotificationFail(error))
    errorNotification()
  }
}

function* onUpdateFamilyMemberCollectionNotification({ payload }) {
  try {
    const response = yield call(
      updateFamilyMemberCollectionNotificationApi,
      payload
    )
    if (response && !response?.error) {
      yield put(updateFamilyMemberCollectionNotificationSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateFamilyMemberCollectionNotificationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateFamilyMemberCollectionNotificationFail(error))
    errorNotification()
  }
}

function* onDeleteFamilyMemberCollectionNotification({ payload }) {
  try {
    const response = yield call(
      deleteFamilyMemberCollectionNotificationApi,
      payload
    )
    if (response && !response?.error) {
      payload.history.goBack()
      yield put(deleteFamilyMemberCollectionNotificationSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteFamilyMemberCollectionNotificationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteFamilyMemberCollectionNotificationFail(error))
  }
}

// ------------------------------------INDIVIDUAL NOTIFICATIONS----------------------------------------

const getIndividualNotificationsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/notification/individual_notification/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/notification/individual_notification/?page=${page ? page : 1}`
    )
  }
}
const getIndividualNotificationsCardsAPi = () => {
  return get(`/api/v1/notification/individual_notification/?cards=all`)
}
const getIndividualNotificationDetailsAPi = individualNotificationId => {
  return get(
    `/api/v1/notification/individual_notification/${individualNotificationId}/`
  )
}
const createIndividualNotificationApi = ({ individualNotification }) => {
  return post(
    "/api/v1/notification/individual_notification/",
    individualNotification,
    { "Content-type": "" }
  )
}
const updateIndividualNotificationApi = ({
  individualNotificationId,
  individualNotification,
}) => {
  return ApiPut(
    `/api/v1/notification/individual_notification/${individualNotificationId}/`,
    individualNotification
  )
}
const deleteIndividualNotificationApi = ({ individualNotificationId }) => {
  return del(
    `/api/v1/notification/individual_notification/${individualNotificationId}/`
  )
}

function* fetchIndividualNotifications({ payload }) {
  try {
    const response = yield call(getIndividualNotificationsAPi, payload)
    if (response && !response?.error) {
      yield put(getIndividualNotificationsSuccess(response))
    } else {
      yield put(getIndividualNotificationsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getIndividualNotificationsFail(error))
  }
}

function* fetchIndividualNotificationsCards({ payload }) {
  try {
    const response = yield call(getIndividualNotificationsCardsAPi, payload)
    if (response && !response?.error) {
      yield put(getIndividualNotificationsCardsSuccess(response))
    } else {
      yield put(getIndividualNotificationsCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getIndividualNotificationsCardsFail(error))
  }
}

function* fetchIndividualNotificationDetail({ individualNotificationId }) {
  try {
    const response = yield call(
      getIndividualNotificationDetailsAPi,
      individualNotificationId
    )
    if (response && !response?.error) {
      yield put(getIndividualNotificationDetailSuccess(response))
    } else {
      yield put(getIndividualNotificationDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getIndividualNotificationDetailFail(error))
  }
}

function* onCreateIndividualNotification({ payload }) {
  // console.log("data :", payload)
  try {
    const response = yield call(createIndividualNotificationApi, payload)
    if (response && !response?.error) {
      yield put(createIndividualNotificationSuccess(response))
      // payload.history.push("/mahalls")
      doneNotification("Created Successfully")
    } else {
      yield put(createIndividualNotificationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createIndividualNotificationFail(error))
    errorNotification()
  }
}

function* onUpdateIndividualNotification({ payload }) {
  try {
    const response = yield call(updateIndividualNotificationApi, payload)
    if (response && !response?.error) {
      yield put(updateIndividualNotificationSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateIndividualNotificationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateIndividualNotificationFail(error))
    errorNotification()
  }
}

function* onDeleteIndividualNotification({ payload }) {
  try {
    const response = yield call(deleteIndividualNotificationApi, payload)
    if (response && !response?.error) {
      payload.history.push("/notifications")
      yield put(deleteIndividualNotificationSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteIndividualNotificationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteIndividualNotificationFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* NotificationsSaga() {
  yield takeEvery(GET_MAHALL_NOTIFICATIONS, fetchMahallNotifications)
  yield takeEvery(GET_MAHALL_NOTIFICATIONS_CARDS, fetchMahallNotificationsCards)
  yield takeEvery(GET_MAHALL_NOTIFICATION_DETAIL, fetchMahallNotificationDetail)
  yield takeEvery(CREATE_MAHALL_NOTIFICATION, onCreateMahallNotification)
  yield takeEvery(UPDATE_MAHALL_NOTIFICATION, onUpdateMahallNotification)
  yield takeEvery(DELETE_MAHALL_NOTIFICATION, onDeleteMahallNotification)

  yield takeEvery(
    GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS,
    fetchFamilyMemberCollectionNotifications
  )
  yield takeEvery(
    GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS,
    fetchFamilyMemberCollectionNotificationsCards
  )
  yield takeEvery(
    GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL,
    fetchFamilyMemberCollectionNotificationDetail
  )
  yield takeEvery(
    CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
    onCreateFamilyMemberCollectionNotification
  )
  yield takeEvery(
    UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
    onUpdateFamilyMemberCollectionNotification
  )
  yield takeEvery(
    DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
    onDeleteFamilyMemberCollectionNotification
  )

  yield takeEvery(GET_INDIVIDUAL_NOTIFICATIONS, fetchIndividualNotifications)
  yield takeEvery(
    GET_INDIVIDUAL_NOTIFICATIONS_CARDS,
    fetchIndividualNotificationsCards
  )
  yield takeEvery(
    GET_INDIVIDUAL_NOTIFICATION_DETAIL,
    fetchIndividualNotificationDetail
  )
  yield takeEvery(
    CREATE_INDIVIDUAL_NOTIFICATION,
    onCreateIndividualNotification
  )
  yield takeEvery(
    UPDATE_INDIVIDUAL_NOTIFICATION,
    onUpdateIndividualNotification
  )
  yield takeEvery(
    DELETE_INDIVIDUAL_NOTIFICATION,
    onDeleteIndividualNotification
  )
}

export default NotificationsSaga
