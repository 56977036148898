import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_JOB_FAMILY_MEMBER,
  GET_JOB_FAMILY_MEMBER_DETAIL,
  CREATE_JOB_FAMILY_MEMBER,
  UPDATE_JOB_FAMILY_MEMBER,
  DELETE_JOB_FAMILY_MEMBER,
} from "./actionTypes"
import {
  getJobFamilyMemberSuccess,
  getJobFamilyMemberFail,
  getJobFamilyMemberDetailSuccess,
  getJobFamilyMemberDetailFail,
  createJobFamilyMemberSuccess,
  createJobFamilyMemberFail,
  updateJobFamilyMemberSuccess,
  updateJobFamilyMemberFail,
  deleteJobFamilyMemberSuccess,
  deleteJobFamilyMemberFail,
} from "./actions"

import {
  getFamilyMemberDetailSuccess,
  getFamilyMemberDetailFail,
} from "store/actions"
import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getFamilyMemberDetailsAPi = familyMemberId => {
  return get(`/api/v1/familydetails/familymember/${familyMemberId}/`)
}
const getJobFamilyMemberAPi = ({ searchText, familyMemberId }) => {
  if (searchText) {
    return get(
      `/api/v1/familydetails/job/?familymember=${familyMemberId}?search=${
        searchText && searchText
      }`
    )
  } else {
    return get(`/api/v1/familydetails/job/`)
  }
}
const getJobFamilyMemberDetailsAPi = familyMemberId => {
  return get(`/api/v1/familydetails/job/${familyMemberId}/`)
}
const createJobFamilyMemberApi = ({ job }) => {
  return post("/api/v1/familydetails/job/", job)
}
const updateJobFamilyMemberApi = ({ job, jobId }) => {
  return ApiPut(`/api/v1/familydetails/job/${jobId}/`, job)
}
const deleteJobFamilyMemberApi = ({ jobId }) => {
  return del(`/api/v1/familydetails/job/${jobId}/`)
}

function* fetchJobFamilyMember({ payload }) {
  try {
    const response = yield call(getJobFamilyMemberAPi, payload)
    if (response && !response?.error) {
      yield put(getJobFamilyMemberSuccess(response))
    } else {
      yield put(getJobFamilyMemberFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getJobFamilyMemberFail(error))
  }
}

function* fetchJobFamilyMemberDetail({ jobId }) {
  try {
    const response = yield call(getJobFamilyMemberDetailsAPi, jobId)
    if (response && !response?.error) {
      yield put(getJobFamilyMemberDetailSuccess(response))
    } else {
      yield put(getJobFamilyMemberDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getJobFamilyMemberDetailFail(error))
  }
}
function* onCreateJobFamilyMember({ payload }) {
  try {
    const response = yield call(createJobFamilyMemberApi, payload)
    if (response && !response?.error) {
      yield put(createJobFamilyMemberSuccess(response))
      payload.history.push(`/familymembers/${response?.family_member}`)
      doneNotification("Added Successfully")
      try {
        const response2 = yield call(
          getFamilyMemberDetailsAPi,
          response?.family_member
        )
        yield put(getFamilyMemberDetailSuccess(response2))
      } catch (error) {
        yield put(getFamilyMemberDetailFail(error))
      }
    } else {
      yield put(createJobFamilyMemberFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createJobFamilyMemberFail(error))
    errorNotification()
  }
}

function* onUpdateJobFamilyMember({ payload }) {
  try {
    const response = yield call(updateJobFamilyMemberApi, payload)
    if (response && !response?.error) {
      yield put(updateJobFamilyMemberSuccess(response))
      payload.history.push(`/familymembers/${response?.family_member}`)
      try {
        const response2 = yield call(
          getFamilyMemberDetailsAPi,
          response?.family_member
        )
        yield put(getFamilyMemberDetailSuccess(response2))
      } catch (error) {
        yield put(getFamilyMemberDetailFail(error))
      }
      doneNotification("Updated Successfully")
    } else {
      yield put(updateJobFamilyMemberFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateJobFamilyMemberFail(error))
    errorNotification()
  }
}

function* onDeleteJobFamilyMember({ payload }) {
  try {
    const response = yield call(deleteJobFamilyMemberApi, payload)
    if (response && !response?.error) {
      payload.history.push("/jobs")
      yield put(deleteJobFamilyMemberSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteJobFamilyMemberFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteJobFamilyMemberFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* JobFamilyMemberSaga() {
  yield takeEvery(GET_JOB_FAMILY_MEMBER, fetchJobFamilyMember)
  yield takeEvery(GET_JOB_FAMILY_MEMBER_DETAIL, fetchJobFamilyMemberDetail)
  yield takeEvery(CREATE_JOB_FAMILY_MEMBER, onCreateJobFamilyMember)
  yield takeEvery(UPDATE_JOB_FAMILY_MEMBER, onUpdateJobFamilyMember)
  yield takeEvery(DELETE_JOB_FAMILY_MEMBER, onDeleteJobFamilyMember)
}

export default JobFamilyMemberSaga
