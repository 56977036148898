import {
  GET_CLASSES,
  GET_CLASSES_SUCCESS,
  GET_CLASSES_FAIL,
  GET_CLASS_DETAIL,
  GET_CLASS_DETAIL_SUCCESS,
  GET_CLASS_DETAIL_FAIL,
  CREATE_CLASS,
  CREATE_CLASS_SUCCESS,
  CREATE_CLASS_FAIL,
  UPDATE_CLASS,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_FAIL,
  DELETE_CLASS,
  DELETE_CLASS_SUCCESS,
  DELETE_CLASS_FAIL,
  GET_CLASS_DROPDOWN,
  GET_CLASS_DROPDOWN_SUCCESS,
  GET_CLASS_DROPDOWN_FAIL,
} from "./actionTypes"

export const getClasses = (searchText, page, institute_type) => ({
  type: GET_CLASSES,
  payload: { searchText, page, institute_type },
})
export const getClassesSuccess = classes => ({
  type: GET_CLASSES_SUCCESS,
  payload: classes,
})
export const getClassesFail = error => ({
  type: GET_CLASSES_FAIL,
  payload: error,
})

export const getClassDropdown = (instituteId, searchText) => ({
  type: GET_CLASS_DROPDOWN,
  payload: { instituteId, searchText },
})
export const getClassDropdownSuccess = classList => ({
  type: GET_CLASS_DROPDOWN_SUCCESS,
  payload: classList,
})
export const getClassDropdownFail = error => ({
  type: GET_CLASS_DROPDOWN_FAIL,
  payload: error,
})

export const getClassDetail = classId => ({
  type: GET_CLASS_DETAIL,
  classId,
})

export const getClassDetailSuccess = classDetail => ({
  type: GET_CLASS_DETAIL_SUCCESS,
  payload: classDetail,
})

export const getClassDetailFail = error => ({
  type: GET_CLASS_DETAIL_FAIL,
  payload: error,
})

export const createClass = (newClass, history) => ({
  type: CREATE_CLASS,
  payload: { newClass, history },
})

export const createClassSuccess = newClass => ({
  type: CREATE_CLASS_SUCCESS,
  payload: newClass,
})

export const createClassFail = error => ({
  type: CREATE_CLASS_FAIL,
  payload: error,
})

export const updateClass = (updatedClass, classId, history) => ({
  type: UPDATE_CLASS,
  payload: { updatedClass, classId, history },
})

export const updateClassSuccess = updatedClass => ({
  type: UPDATE_CLASS_SUCCESS,
  payload: updatedClass,
})

export const updateClassFail = error => ({
  type: UPDATE_CLASS_FAIL,
  payload: error,
})

export const deleteClass = (classId, history) => ({
  type: DELETE_CLASS,
  payload: { classId, history },
})

export const deleteClassSuccess = newClass => ({
  type: DELETE_CLASS_SUCCESS,
  payload: newClass,
})

export const deleteClassFail = error => ({
  type: DELETE_CLASS_FAIL,
  payload: error,
})
