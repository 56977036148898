import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label, Row } from "reactstrap"
import { map } from "lodash"

import {
  updateLedger,
  deleteLedger,
  getLedgerDetail,
  getMahallTypeLists,
} from "store/actions"
import { handleSelectOrDeselect } from "hooks/useSelectOrDeselect"

const UiModalLedgerUpdate = ({ show, onCloseclick, history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    loading,
    language,
    // instituteId,
    ledgerDetail,
    mahallTypeLists,
    mahallId,
  } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    instituteId: state.Institutes.instituteDetail?.id,
    ledgerDetail: state.Ledgers.ledgerDetail?.ledgers,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    mahallId: state.Users.userDetail?.mahall?.id,
  }))

  const handleValidSubmit = (onSubmitProps, values) => {
    const UpdateLedgerData = {
      institute_ledger_name: values.institute_ledger_name,
      institute: ledgerDetail?.institute,
      ledger_category_choice_id: ledgerCategory,
      // opening_balance: values.opening_balance ? parseInt(values.opening_balance) : 0,
      // balance: values.opening_balance ? parseInt(values.opening_balance) : 0,
      description: values.description,
    }
    // console.log(UpdateLedgerData);
    dispatch(updateLedger(UpdateLedgerData, ledgerDetail?.id, history))
  }

  useEffect(() => {
    dispatch(getLedgerDetail(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    if (mahallId) {
      dispatch(getMahallTypeLists(mahallId))
    }
  }, [dispatch])

  const [ledgerCategory, setLedgerCategory] = useState(
    ledgerDetail?.ledger_category_choice_id
  )

  useEffect(() => {
    setLedgerCategory(ledgerDetail?.ledger_category_choice_id)
  }, [ledgerDetail])

  const handleDeleteLedger = () => {
    // console.log(`delete item ${ledgerDetail?.id}`);
    dispatch(deleteLedger(ledgerDetail?.id, history))
    // TODO: dispatch deleteGallery
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal"
                ? "ലെഡ്ജർ അപ്ഡേറ്റ് ചെയ്യുക"
                : "Update Ledger"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "ലെഡ്ജറിന്റെ പേര്" : "Ledger Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="institute_ledger_name"
                    type="text"
                    value={ledgerDetail?.institute_ledger_name}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label for="basicpill-servicetax-input8">
                    {language === "mal" ? "ലെഡ്ജർ വിഭാഗം" : "Ledger Category"}
                  </Label>
                  <div className="d-flex flex-wrap">
                    {map(mahallTypeLists?.ledger_category, (item, key) => (
                      <div key={key} className="mx-1 mt-1">
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio5"
                          id={item.id}
                          autoComplete="off"
                          checked={item.id === ledgerCategory}
                          value={item.id}
                          onClick={e =>
                            handleSelectOrDeselect(
                              ledgerCategory,
                              setLedgerCategory,
                              e.target.value
                            )
                          }
                          // onChange={e => setLedgerCategory(e.target.value)}
                        />
                        <label
                          className="btn btn-outline-info"
                          htmlFor={item.id}
                        >
                          {language === "mal" ? item?.malayalam : item?.english}
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    value={ledgerDetail?.description}
                    label={language === "mal" ? "വിവരണം" : "Description"}
                  />
                </Col>
              </div>
              <Row className="mt-5 d-flex">
                <div className="col-6">
                  <div className="row justify-content-start">
                    <div>
                      <Button
                        type="button"
                        color="danger"
                        className="w-md"
                        onClick={() => {
                          onCloseclick()
                          handleDeleteLedger(ledgerDetail?.id)
                        }}
                      >
                        {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md m-auto me-0 d-block"
                        onClick={onCloseclick}
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക " : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalLedgerUpdate
UiModalLedgerUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
