import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
//actions
//componets
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import {
  // debounce,
  map,
  range,
} from "lodash"
import moment from "moment"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

import UiModalAddStudentVarisangyaArrear from "./UiModalAddStudentVarisangyaArrear"

import MyPagination from "components/Common/MyPagination"
import {
  studentsDropdown,
  createStudentVarisangyaWallet,
  getStudentDetail,
  getStudentVarisangyaWalletDetail,
  clearStudentVarisangyaWalletDetail,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateInstituteStudentVarisangyaTransaction = ({ history }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const searchText = ""
  const sort = "manual"
  const [page, setPage] = useState(1)
  const limit = 10
  const [modalAddArrear, setModalAddArrear] = useState(false)

  const {
    loading,
    language,
    studentsList,
    studentDetail,
    studentVarisangyaWalletDetail,
    tableLoading,
  } = useSelector(state => ({
    loading: state.StudentVarisangyaWallets.loading,
    language: state.Layout.language,
    studentsList: state.Students.studentsList,
    studentDetail: state.Students.studentDetail,
    studentVarisangyaWalletDetail:
      state.StudentVarisangyaWallets.studentVarisangyaWalletDetail,
    tableLoading: state.StudentVarisangyaWallets.tableLoading,
  }))

  const instituteId = location?.search?.split("?instituteId=")[1]

  const totalPages = Math.ceil(studentVarisangyaWalletDetail?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const [searchTextStudent, setSearchTextStudent] = useState("")
  const [selectedStudent, setSelectedStudent] = useState("Choose Student")
  const [studentId, setStudentId] = useState("")

  useEffect(() => {
    dispatch(studentsDropdown(searchTextStudent, "", instituteId))
  }, [searchTextStudent, instituteId, dispatch])

  useEffect(() => {
    dispatch(clearStudentVarisangyaWalletDetail())
  }, [])

  function handlerFinalValueStudent(event) {
    // dispatch(studentsDropdown(event.value))
    setStudentId(event.value)
    setSelectedStudent(event.label && event.family_head_name)
  }

  const optionGroupStudent = [
    {
      options: studentsList?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?.id,
      })),
    },
  ]

  const handleEntersStudent = textEntered => {
    setSearchTextStudent(textEntered)
  }

  useEffect(() => {
    if (studentId) {
      dispatch(getStudentDetail(studentId))
      dispatch(
        getStudentVarisangyaWalletDetail(studentId, page, searchText, sort)
      )
    }
  }, [dispatch, studentId, page, searchText])

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const createStudentVarisangyaWalletData = {
      madrasa_student: studentId,
      amount: values.amount ? parseInt(values.amount) : 0,
      trn_type: "credit",
      remark: values.remark,
    }
    // console.log(createVarisangyaData);
    if (studentId && parseInt(values.amount) > 0)
      dispatch(
        createStudentVarisangyaWallet(
          createStudentVarisangyaWalletData,
          history
        )
      )
  }

  // const handleVarisangyaArrearAdd = (onSubmitProps, values) => {
  //   const createStudentVarisangyaWalletData = {
  //     family: studentId,
  //     amount: values.amount ? parseInt(values.amount) : 0,
  //     trn_type: 'debit',
  //     remark: values.remark ? values.remark : 'Arrears',
  //   }
  //   // console.log(createVarisangyaData);
  //   dispatch(
  //     createStudentVarisangyaWallet(createStudentVarisangyaWalletData, history),
  //   )
  // }

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "date",
      text: language === "mal" ? "തീയതി" : "Date",
    },
    {
      dataField: "amount",
      text: language === "mal" ? "തുക" : "Amount",
    },
    {
      dataField: "balance",
      text: language === "mal" ? "ബാലൻസ്" : "Balance",
    },
    {
      dataField: "remark",
      text: language === "mal" ? "റിമാർക്കുകൾ" : "Remarks",
    },
    // {
    //   dataField: "action",
    //   text: language === 'mal' ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    // }
  ]

  const studentVarisangyaTransactionsData = map(
    studentVarisangyaWalletDetail?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,
      amount: (
        <p
          className={
            item?.trn_type === "credit" ? "text-success" : "text-danger"
          }
        >
          {item?.amount}
        </p>
      ),
      balance: <p className="text-primary">{item?.balance}</p>,
      date: item?.date_added && moment(item?.date_added).format("DD/MM/yyyy"),
      // action: (
      //   <div className="m-0">
      //     <Link to={`/varisangya/update/${item?.id}`} className="m-0">
      //       <Button
      //         type="button"
      //         color="primary"
      //         className=" btn w-xs btn-sm"
      //       >
      //         {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
      //         {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
      //       </Button>
      //     </Link>
      //   </div>
      // ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("diff:", diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("age: ", age)
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  // const debouncefamilyVarisangyaWalletSearch = debounce(
  //   value => setSearchText(value),
  //   600,
  // )

  return (
    <>
      <UiModalAddStudentVarisangyaArrear
        show={modalAddArrear}
        onCloseclick={() => setModalAddArrear(false)}
        data={studentDetail}
      />
      <MetaTags>
        <title>Madrasa Fee | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "മദ്രസ ഫീസ്" : "Madrasa Fee"}
          breadcrumbItem={language === "mal" ? "ചേർക്കുക" : "Entry"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4 mb-4">Fill this form</CardTitle> */}

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="col-md-12">
                        <div className={`col ${studentId ? "mb-2" : "mb-4"}`}>
                          <Label>
                            {language === "mal" ? "വിദ്യാർത്ഥി" : "Student"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <Col sm={12}>
                            <Select
                              onInputChange={handleEntersStudent}
                              placeholder={selectedStudent}
                              value={selectedStudent}
                              options={optionGroupStudent}
                              classNamePrefix="select2-selection"
                              onChange={handlerFinalValueStudent}
                              type="text"
                              title="Family"
                              required="required"
                            />
                          </Col>
                        </div>
                      </div>
                      {studentId && (
                        <Row className="mt-2">
                          <Col md="12">
                            <Card style={{ backgroundColor: "#e6fcee" }}>
                              <CardBody>
                                {/* <h5 className="font-size-13 text-primary font-weight-bold">
                                  {language === 'mal'
                                    ? studentDetail?.area?.malayalam
                                    : studentDetail?.area?.english}
                                </h5> */}

                                <div className="d-flex">
                                  <h5 className="font-size-22 font-weight-bold mt-1">
                                    {studentDetail?.class_name}{" "}
                                    {studentDetail?.division_name}
                                  </h5>
                                </div>
                                <Badge
                                  className="bg-primary rounded-pill"
                                  style={{ fontSize: "12px" }}
                                >
                                  {language === "mal" ? "അംഗങ്ങൾ" : "Roll No."}{" "}
                                  : {studentDetail?.roll_no}
                                </Badge>
                                <Badge
                                  className="mx-2 bg-success rounded-pill"
                                  style={{ fontSize: "12px" }}
                                >
                                  {/* {language === 'mal' ? 'മഹല്ല് ഐഡി' : 'DOB'} :{' '} */}
                                  {getAge(studentDetail?.dob)}
                                </Badge>
                                {/* <div className="text-muted mt-1 d-flex">
                                  <h5 className="font-size-12 text-muted font-weight-bold mt-1">
                                    {studentDetail?.house_number}/
                                    {studentDetail?.ward_number}
                                  </h5>

                                  <h5 className="font-size-12 text-muted font-weight-bold mt-1">
                                    -{' '}
                                    {language === 'mal'
                                      ? studentDetail?.lsg_malayalam_name
                                      : studentDetail?.lsg_english_name}
                                  </h5>
                                </div> */}
                                <p className="my-2">
                                  {/* <i className="bx bx-map-pin text-primary me-1" />{' '} */}
                                  <span>S/O</span>{" "}
                                  {studentDetail?.parent_name || ""}
                                </p>

                                <p className="mb-0">
                                  <i className="bx bx-phone text-primary me-1" />{" "}
                                  {studentDetail?.parent_phone || ""}
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <div className="col-md-12 mt-2">
                          <div className="col">
                            <Row>
                              <Col sm={4}>
                                <AvField
                                  id="horizontal-firstname-Input"
                                  name="amount"
                                  type="number"
                                  label={language === "mal" ? "തുക" : "Amount"}
                                />
                              </Col>
                              <Col sm={8}>
                                <AvField
                                  id="horizontal-firstname-Input"
                                  name="remark"
                                  type="text"
                                  label={
                                    language === "mal" ? "വിവരണം" : "Remarks"
                                  }
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Row>

                      <div className="d-flex justify-content-end mt-5">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              {studentId && (
                <Col lg={6}>
                  {studentId && (
                    <>
                      {/* <Button
                    onClick={() => setModalAddArrear(true)}
                    style={{ width: language === 'mal' ? "170px" : "150px" }}
                    className="btn btn-danger m-auto mb-2 me-0 d-block"
                  >
                    <i className={"bx bx-calendar-minus mx-1"}></i>
                    {language === 'mal' ? "കുടിശ്ശിക ചേർക്കൽ" : "Add Arrears"}
                  </Button> */}
                      {/* <Button
                    onClick={() => setModalAddArrear(true)}
                    style={{ width: language === 'mal' ? "170px" : "150px" }}
                    className="btn btn-success m-auto mb-2 me-0 d-block"
                  >
                    <i className={"bx bx-calendar-minus mx-1"}></i>
                    {language === 'mal' ? "കുടിശ്ശിക ചേർക്കൽ" : "Add Transaction"}
                  </Button> */}
                    </>
                  )}
                  <Card>
                    {studentId && (
                      <Col className="pt-3 px-3">
                        <Row className="d-flex align-items-center">
                          <Col>
                            <p className="text-">
                              {language === "mal"
                                ? "വാലറ്റ് ബാലൻസ് : "
                                : "Wallet Balance :"}{" "}
                              <span className="fw-bold ">
                                {studentId && studentDetail?.varisankya_wallet}
                              </span>
                            </p>
                          </Col>
                          <Col>
                            <Button
                              onClick={() => setModalAddArrear(true)}
                              style={{
                                width: language === "mal" ? "170px" : "150px",
                              }}
                              className="btn btn-danger m-auto me-0 d-block"
                            >
                              <i className={"bx bx-calendar-minus mx-1"}></i>
                              {language === "mal"
                                ? "കുടിശ്ശിക ചേർക്കൽ"
                                : "Add Arrears"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {studentId && <hr />}
                    <CardTitle className="mx-3 mt-3">
                      {language === "mal" ? "ഇടപാടുകൾ :" : "Transactions :"}
                    </CardTitle>
                    <CardBody>
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={studentVarisangyaTransactionsData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            {tableLoading ? (
                              <Spinner
                                color="secondary"
                                className="d-block m-auto"
                              />
                            ) : (
                              <>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-wrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                {(!studentVarisangyaWalletDetail?.results ||
                                  studentVarisangyaWalletDetail?.results
                                    ?.length <= 0) && (
                                  <p
                                    style={{ fontSize: "15px" }}
                                    className="text-center text-info"
                                  >
                                    {language === "mal"
                                      ? "ഇടപാടുകൾ ഒന്നും ഇല്ല"
                                      : "No Transactions Yet"}
                                  </p>
                                )}
                                <MyPagination
                                  totalPages={pages}
                                  page={page}
                                  setPage={setPage}
                                />
                              </>
                            )}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateInstituteStudentVarisangyaTransaction

CreateInstituteStudentVarisangyaTransaction.propTypes = {
  history: PropTypes.object,
}
