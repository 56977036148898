import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_LEDGER_ITEM_CATEGORIES,
  GET_LEDGER_ITEM_CATEGORY_DETAIL,
  CREATE_LEDGER_ITEM_CATEGORY,
  UPDATE_LEDGER_ITEM_CATEGORY,
  DELETE_LEDGER_ITEM_CATEGORY,
  GET_LEDGER_ITEM_CATEGORY_DROPDOWN,
  GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD,
} from "./actionTypes"
import {
  getLedgerItemCategoriesSuccess,
  getLedgerItemCategoriesFail,
  getLedgerItemCategoryDetailSuccess,
  getLedgerItemCategoryDetailFail,
  createLedgerItemCategorySuccess,
  createLedgerItemCategoryFail,
  updateLedgerItemCategorySuccess,
  updateLedgerItemCategoryFail,
  deleteLedgerItemCategorySuccess,
  deleteLedgerItemCategoryFail,
  getLedgerItemCategoryDropdownSuccess,
  getLedgerItemCategoryDropdownFail,
  getLedgerItemCategoriesForDownloadSuccess,
  getLedgerItemCategoriesForDownloadFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getLedgerItemCategoriesAPi = ({ instituteId, searchText, page }) => {
  if (instituteId) {
    return get(
      `/api/v1/institute/institute_ledger_item_category/?institute=${
        instituteId && instituteId
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
    // eslint-disable-next-line no-dupe-else-if
  } else {
    return get(
      `/api/v1/institute/institute_ledger_item_category/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  }
}
const getLedgerItemCategoriesForDownloadAPi = ({ instituteId, searchText }) => {
  if (instituteId) {
    return get(
      `/api/v1/institute/institute_ledger_item_category/?institute=${
        instituteId && instituteId
      }&search=${searchText ? searchText : ""}`
    )
    // eslint-disable-next-line no-dupe-else-if
  } else {
    return get(
      `/api/v1/institute/institute_ledger_item_category/?search=${
        searchText && searchText
      }`
    )
  }
}
const getLedgerItemCategoryDetailsAPi = ledgerItemCategoryId => {
  return get(
    `/api/v1/institute/institute_ledger_item_category/${ledgerItemCategoryId}/`
  )
}
const getLedgerItemCategoryDropdownAPi = ({ instituteId, searchText }) => {
  if (instituteId) {
    return get(
      `/api/v1/institute/institute_ledger_category_dropdown?institute=${
        instituteId && instituteId
      }&search=${searchText ? searchText : ""}`
    )
  } else {
    return get(
      `/api/v1/institute/institute_ledger_category_dropdown?search=${
        searchText ? searchText : ""
      }`
    )
  }
}
const createLedgerItemCategoryApi = ({ ledgerItemCategory }) => {
  return post(
    "/api/v1/institute/institute_ledger_item_category/",
    ledgerItemCategory
  )
}
const updateLedgerItemCategoryApi = ({
  ledgerItemCategoryId,
  ledgerItemCategory,
}) => {
  return ApiPut(
    `/api/v1/institute/institute_ledger_item_category/${ledgerItemCategoryId}/`,
    ledgerItemCategory
  )
}
const deleteLedgerItemCategoryApi = ({ ledgerItemCategoryId }) => {
  return del(
    `/api/v1/institute/institute_ledger_item_category/${ledgerItemCategoryId}/`
  )
}

function* fetchLedgerItemCategories({ payload }) {
  try {
    const response = yield call(getLedgerItemCategoriesAPi, payload)
    yield put(getLedgerItemCategoriesSuccess(response))
  } catch (error) {
    yield put(getLedgerItemCategoriesFail(error))
  }
}

function* fetchLedgerItemCategoriesForDownload({ payload }) {
  try {
    const response = yield call(getLedgerItemCategoriesForDownloadAPi, payload)
    yield put(getLedgerItemCategoriesForDownloadSuccess(response))
  } catch (error) {
    yield put(getLedgerItemCategoriesForDownloadFail(error))
  }
}

function* fetchLedgerItemCategoryDetail({ ledgerCategoryId }) {
  try {
    const response = yield call(
      getLedgerItemCategoryDetailsAPi,
      ledgerCategoryId
    )
    yield put(getLedgerItemCategoryDetailSuccess(response))
  } catch (error) {
    yield put(getLedgerItemCategoryDetailFail(error))
  }
}
function* fetchLedgerItemCategoryDropdown({ payload }) {
  try {
    const response = yield call(getLedgerItemCategoryDropdownAPi, payload)
    console.log(response)
    yield put(getLedgerItemCategoryDropdownSuccess(response))
  } catch (error) {
    yield put(getLedgerItemCategoryDropdownFail(error))
  }
}
function* onCreateLedgerItemCategory({ payload }) {
  try {
    const response = yield call(createLedgerItemCategoryApi, payload)
    if (response && !response?.error) {
      yield put(createLedgerItemCategorySuccess(response))
      doneNotification("Created Successfully")
      // try {
      //   const response2 = yield call(getInstituteDetailsAPi, response?.institute)
      //   yield put(getInstituteDetailSuccess(response2))
      // } catch (error) {
      //   yield put(getInstituteDetailFail(error))
      // }
    } else {
      yield put(createLedgerItemCategoryFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createLedgerItemCategoryFail(error))
    errorNotification()
  }
}

function* onUpdateLedgerItemCategory({ payload }) {
  console.log(payload)
  try {
    const response = yield call(updateLedgerItemCategoryApi, payload)
    if (response && !response?.error) {
      yield put(updateLedgerItemCategorySuccess(response))
      doneNotification("Updated Successfully")
      // try {
      //   const response2 = yield call(getInstituteDetailsAPi, response?.institute)
      //   yield put(getInstituteDetailSuccess(response2))
      // } catch (error) {
      //   yield put(getInstituteDetailFail(error))
      // }
    } else {
      yield put(updateLedgerItemCategoryFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    yield put(updateLedgerItemCategoryFail(error))
    errorNotification()
  }
}

function* onDeleteLedgerItemCategory({ payload }) {
  try {
    const response = yield call(deleteLedgerItemCategoryApi, payload)
    if (response && !response?.error) {
      yield put(deleteLedgerItemCategorySuccess(payload.ledgerItemCategoryId))
      doneNotification("Deleted Successfully")
      // try {
      //   const response = yield call(getLedgerItemCategoriesAPi, payload)
      //   yield put(getLedgerItemCategoriesSuccess(response))
      // } catch (error) {
      //   yield put(getLedgerItemCategoriesFail(error))
      // }
    } else {
      yield put(deleteLedgerItemCategoryFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log(error)
    errorNotification()
    yield put(deleteLedgerItemCategoryFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* LedgerItemCategoriesSaga() {
  yield takeEvery(GET_LEDGER_ITEM_CATEGORIES, fetchLedgerItemCategories)
  yield takeEvery(
    GET_LEDGER_ITEM_CATEGORIES_FOR_DOWNLOAD,
    fetchLedgerItemCategoriesForDownload
  )
  yield takeEvery(
    GET_LEDGER_ITEM_CATEGORY_DETAIL,
    fetchLedgerItemCategoryDetail
  )
  yield takeEvery(
    GET_LEDGER_ITEM_CATEGORY_DROPDOWN,
    fetchLedgerItemCategoryDropdown
  )
  yield takeEvery(CREATE_LEDGER_ITEM_CATEGORY, onCreateLedgerItemCategory)
  yield takeEvery(UPDATE_LEDGER_ITEM_CATEGORY, onUpdateLedgerItemCategory)
  yield takeEvery(DELETE_LEDGER_ITEM_CATEGORY, onDeleteLedgerItemCategory)
}

export default LedgerItemCategoriesSaga
