import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
//actions
import { Link } from "react-router-dom/cjs/react-router-dom.min"

import UiModalCertificate from "../UiModalCertificate"

import {
  getNikahRegistrationDetail,
  updateNikahRegistration,
  getMahallTypeLists,
  getAllFamilyMembersDropdown,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"

const UpdateMahallNikahRegistration = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    nikahRegistrationDetail,
    language,
    userDetail,
    mahallTypeLists,
    loading,
    allFamilyMembers,
  } = useSelector(state => ({
    nikahRegistrationDetail: state.NikahRegistrations.nikahRegistrationDetail,
    loading: state.NikahRegistrations.loading,
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    allFamilyMembers: state.FamilyMembers.allFamilyMembers,
  }))

  const [modalCertificate, setModalCertificate] = useState(false)

  const [isPaidSwitch, setIsPaidSwitch] = useState(
    nikahRegistrationDetail?.is_paid || false
  )
  // const [applicantType, setApplicantType] = useState(
  //   nikahRegistrationDetail?.mahall_of || "bride"
  // )

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(""))
  }, [dispatch, ""])

  function handleValidSubmit(onSubmitProps, values) {
    const updateNikahRegistrationData = {
      mahall_custom_id: values.mahall_custom_id,
      date: values.nikah_date,
      place: values.nikah_place,
      headed_by: values.headed_by,
      mahall_of: nikahRegistrationDetail?.mahall_of,
      member: nikahRegistrationDetail?.member,

      groom_name: values.groom_name,
      groom_father_name: values.groom_father_name,
      groom_dob: values.groom_dob,
      groom_address: values.groom_address,
      groom_mahall: values.groom_mahall,
      groom_aadhaar: values.groom_aadhaar,

      bride_name: values.bride_name,
      bride_father_name: values.bride_father_name,
      bride_dob: values.bride_dob,
      bride_address: values.bride_address,
      bride_mahall: values.bride_mahall,
      bride_aadhaar: values.bride_aadhaar,

      valiyy: values.valiyy,
      relation_bw_bride_valiyy: values.relation_bw_bride_valiyy,
      witness_1_name: values.witness_1_name,
      witness_2_name: values.witness_2_name,

      fee: values.fee,
      noc_id: "",
      is_paid: isPaidSwitch,
      mahar_type_choice_id:
        values.mahar_type_choice_id === "Select one ..." ||
        values.mahar_type_choice_id === "തിരഞ്ഞെടുക്കുക ..."
          ? null
          : values.mahar_type_choice_id,
      mahar_unit: values.mahar_unit,
    }
    // console.log('updateNikahRegistrationData: ',updateNikahRegistrationData);
    dispatch(
      updateNikahRegistration(
        updateNikahRegistrationData,
        nikahRegistrationDetail.id,
        history
      )
    )

    window.scroll(0, 0)
  }

  useEffect(() => {
    dispatch(getNikahRegistrationDetail(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    if (userDetail?.mahall?.id) {
      if (!mahallTypeLists || mahallTypeLists.length <= 0)
        dispatch(getMahallTypeLists(userDetail?.mahall?.id))
    }
  }, [dispatch, userDetail, mahallTypeLists])

  // const applicantTypes = [
  //   { name: `${language === "mal" ? "വധു" : "Bride"}`, value: "bride" },
  //   { name: `${language === "mal" ? "വരൻ" : "Groom"}`, value: "groom" },
  // ]

  const maharTypes = mahallTypeLists?.[`mahar_type`]?.map(item => {
    return {
      id: item.id,
      label: `${language === "mal" ? item?.malayalam : item?.english}`,
    }
  })

  const memberName = allFamilyMembers?.find(
    item => item?.id === nikahRegistrationDetail?.member
  )

  return (
    <>
      <UiModalCertificate
        show={modalCertificate}
        onCloseclick={() => setModalCertificate(false)}
      />
      <MetaTags>
        <title>Nikah | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "നിക്കാഹ്" : "Nikah"}
          breadcrumbItem={language === "mal" ? "അപ്ഡേറ്റ്" : "Update"}
        />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <Row>
                <Col sm="12" md="12" lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <div className="d-flex justify-content-end">
                          <Link
                            to={`/nikah/certificate?nikkahid=${nikahRegistrationDetail?.id}`}
                            type="button"
                            className="btn btn-outline-success"
                          >
                            {language === "mal"
                              ? "സർട്ടിഫിക്കറ്റ് കാണുക"
                              : "View Certificate"}
                          </Link>
                        </div>
                        <Col sm="12" md="6">
                          <Label>
                            {language === "mal" ? "അപേക്ഷക/ൻ " : "Applicant "}
                          </Label>
                          {/* <div className="row mb-3 d-flex">
                            {
                              applicantTypes.map((item, key) => (
                                <Col md="6" key={key}>
                                  <div className="me-3">
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="btnradio6"
                                      id={item.name}
                                      autoComplete="off"
                                      value={item.value}
                                      checked={applicantType === item.value ? true : false}
                                      onChange={e =>
                                        setApplicantType(
                                          e.target.value
                                        )
                                      }
                                    />
                                    {item.value === "bride" ? (
                                      <label
                                        className="btn btn-outline-primary w-100"
                                        htmlFor={item.name}
                                      >
                                        {item.name}
                                      </label>) : (
                                      <label
                                        className="btn btn-outline-primary w-100"
                                        htmlFor={item.name}
                                      >
                                        {item.name}
                                      </label>
                                    )}
                                  </div>
                                </Col>
                              )
                              )}
                          </div> */}

                          <div
                            className="mb-3 text-center bg-primary d-flex align-items-center justify-content-center text-white"
                            style={{ height: "40px" }}
                          >
                            <div>
                              {nikahRegistrationDetail?.mahall_of
                                ?.charAt(0)
                                .toUpperCase() +
                                nikahRegistrationDetail?.mahall_of?.slice(1)}
                            </div>
                          </div>

                          <div className="row mb-4">
                            <Col>
                              <Label>
                                {language === "mal" ? "കുടുംബാംഗം" : "Member"}
                              </Label>
                              <AvField
                                name="member"
                                readOnly
                                type="text"
                                value={memberName?.full_name}
                              />
                            </Col>
                          </div>

                          <div className="row mb-4">
                            <Col>
                              <AvField
                                name="mahall_custom_id"
                                label={
                                  language === "mal"
                                    ? "രജിസ്റ്റർ നമ്പർ"
                                    : "Register Number"
                                }
                                type="text"
                                readOnly
                                value={
                                  nikahRegistrationDetail?.mahall_custom_id
                                }
                              />
                            </Col>
                            <Col>
                              <Label>
                                {language === "mal"
                                  ? "നിക്കാഹ് നടന്ന തീയതി "
                                  : "Nikah Date "}
                                <span className="text-danger">&#42;</span>
                              </Label>
                              <AvField
                                name="nikah_date"
                                type="date"
                                value={nikahRegistrationDetail?.date}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </Col>
                          </div>
                          <div className="row mb-4">
                            <Col>
                              <Label>
                                {language === "mal"
                                  ? "നിക്കാഹ് നടന്ന സ്ഥലം "
                                  : "Nikah Place "}
                                <span className="text-danger">&#42;</span>
                              </Label>
                              <AvField
                                name="nikah_place"
                                type="text"
                                value={nikahRegistrationDetail?.place}
                                required
                              />
                            </Col>
                          </div>

                          <div className="row mb-4 ">
                            <Col>
                              <Label>
                                {language === "mal"
                                  ? "കാർമികത്വം "
                                  : "Headed By "}
                              </Label>
                              <AvField
                                name="headed_by"
                                type="text"
                                value={nikahRegistrationDetail?.headed_by}
                              />
                            </Col>
                          </div>
                        </Col>
                        <Col sm="12" md="6">
                          <div className="row mb-4 ">
                            <Col>
                              <Label>
                                {language === "mal" ? "വലിയ്യ് " : "Valiyy "}
                              </Label>
                              <AvField
                                name="valiyy"
                                type="text"
                                value={nikahRegistrationDetail?.valiyy}
                              />
                            </Col>
                          </div>
                          <div className="row mb-4 ">
                            <Col>
                              <Label>
                                {language === "mal"
                                  ? "വധുവുമായുള്ള ബന്ധം "
                                  : "Relation with Bride "}
                              </Label>
                              <AvField
                                name="relation_bw_bride_valiyy"
                                type="text"
                                value={
                                  nikahRegistrationDetail?.relation_bw_bride_valiyy
                                }
                              />
                            </Col>
                          </div>

                          <div className="row mb-4">
                            <Col>
                              <Label>
                                {language === "mal"
                                  ? "മഹർ തരം "
                                  : "Mahar Type "}
                              </Label>
                              <AvField
                                name="mahar_type_choice_id"
                                type="select"
                                value={
                                  nikahRegistrationDetail?.mahar_type_choice_id
                                }
                              >
                                <option>
                                  {language === "mal"
                                    ? "തിരഞ്ഞെടുക്കുക ..."
                                    : "Select one ..."}
                                </option>
                                {maharTypes?.map((item, key) => (
                                  <option key={key} value={item.id}>
                                    {item.label}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                            <Col>
                              <Label>
                                {language === "mal" ? "അളവ് " : "Unit "}
                              </Label>
                              <AvField
                                name="mahar_unit"
                                type="text"
                                value={nikahRegistrationDetail?.mahar_unit}
                              />
                            </Col>
                          </div>

                          <div className="row mb-4">
                            <Col>
                              <Label>
                                {language === "mal" ? "ഫീസ്" : "Fee"}
                              </Label>
                              <AvField
                                name="fee"
                                type="number"
                                value={nikahRegistrationDetail?.fee}
                              />
                            </Col>
                            <Col>
                              <Label for="basicpill-email-input4">
                                {language === "mal"
                                  ? "ഫീസ് അടച്ചിട്ടുണ്ടോ?"
                                  : "Is Fee Paid?"}
                              </Label>
                              <div className="form-check form-switch form-switch-lg">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizemd"
                                  checked={isPaidSwitch ? true : false}
                                  onChange={() =>
                                    setIsPaidSwitch(!isPaidSwitch)
                                  }
                                />
                              </div>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col sm="12" md="12" lg="6">
                  <Card>
                    <CardBody>
                      <div
                        className="mb-3 text-center bg-khaf d-flex align-items-center justify-content-center text-white"
                        style={{ height: "30px" }}
                      >
                        <div>
                          <strong>
                            {language === "mal" ? "വധു" : "Bride"}
                          </strong>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "പേര് " : "Name "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="bride_name"
                            type="text"
                            value={nikahRegistrationDetail?.bride_name}
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "മഹല്ല് " : "Mahall "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="bride_mahall"
                            type="text"
                            value={nikahRegistrationDetail?.bride_mahall}
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-3">
                        <Col>
                          <div className="mb-3">
                            <Label>
                              {language === "mal"
                                ? "പിതാവിന്റെ പേര് "
                                : "Father Name "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              name="bride_father_name"
                              type="text"
                              value={nikahRegistrationDetail?.bride_father_name}
                              required
                            />
                          </div>
                        </Col>
                        <Col>
                          <Label>
                            {language === "mal" ? "പിതാവിന്റെ പേര് " : "DOB"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="bride_dob"
                            type="date"
                            value={nikahRegistrationDetail?.bride_dob}
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "വിലാസം " : "Address "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="bride_address"
                            type="textarea"
                            rows="3"
                            value={nikahRegistrationDetail?.bride_address}
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "ആധാർ നമ്പർ" : "Aadhaar No."}
                        </Label>
                        <Col>
                          <AvField
                            name="bride_aadhaar"
                            type="number"
                            value={nikahRegistrationDetail?.bride_aadhaar}
                            validate={{
                              // required: { value: true },
                              maxLength: {
                                value: 12,
                                errorMessage: `${
                                  language === "mal"
                                    ? "ആധാർ നമ്പർ 12 അക്കങ്ങൾ ആയിരിക്കണം"
                                    : "Aadhaar number must be 12 digits"
                                }`,
                              },
                            }}
                          />
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12" md="12" lg="6">
                  <Card>
                    <CardBody>
                      {/* <CardTitle className="h4 mb-4">{language === 'mal' ? "വരൻ" : "Groom"}</CardTitle> */}
                      <div
                        className="mb-3 text-center bg-khaf-blue d-flex align-items-center justify-content-center text-white"
                        style={{ height: "30px" }}
                      >
                        <div>
                          <strong>
                            {language === "mal" ? "വരൻ" : "Groom"}
                          </strong>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "പേര് " : "Name "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="groom_name"
                            type="text"
                            value={nikahRegistrationDetail?.groom_name}
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "മഹല്ല് " : "Mahall "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="groom_mahall"
                            type="text"
                            value={nikahRegistrationDetail?.groom_mahall}
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-3">
                        <Col>
                          <div className="mb-3">
                            <Label>
                              {language === "mal"
                                ? "പിതാവിന്റെ പേര് "
                                : "Father Name "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              name="groom_father_name"
                              type="text"
                              value={nikahRegistrationDetail?.groom_father_name}
                              required
                            />
                          </div>
                        </Col>
                        <Col>
                          <Label>
                            {language === "mal" ? "പിതാവിന്റെ പേര് " : "DOB"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="groom_dob"
                            type="date"
                            value={nikahRegistrationDetail?.groom_dob}
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "വിലാസം " : "Address "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="groom_address"
                            type="textarea"
                            rows="3"
                            value={nikahRegistrationDetail?.groom_address}
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "ആധാർ നമ്പർ" : "Aadhaar No."}
                        </Label>
                        <Col>
                          <AvField
                            name="groom_aadhaar"
                            type="number"
                            value={nikahRegistrationDetail?.groom_aadhaar}
                            validate={{
                              // required: { value: true },
                              maxLength: {
                                value: 12,
                                errorMessage: `${
                                  language === "mal"
                                    ? "ആധാർ നമ്പർ 12 അക്കങ്ങൾ ആയിരിക്കണം"
                                    : "Aadhaar number must be 12 digits"
                                }`,
                              },
                            }}
                          />
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col sm="12" md="12" lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm="12" md="6">
                          {/* <div className="row mt-4 mb-4">
                            <Col>
                            <label>{language === 'mal' ? "വധുവിന്റെ ഒപ്പ് (ഫോട്ടോ)" : "Bride's Signature (Photo)"}</label>
                              <AvField
                                name="bride_sign"
                                type="file"
                              />
                            </Col>
                          </div> */}

                          <div className="row mb-4 ">
                            <Col className="mt-4 ">
                              <Label>
                                {language === "mal"
                                  ? "സാക്ഷി (1) പേര് "
                                  : "Witness (1) Name "}
                                <span className="text-danger">&#42;</span>
                              </Label>
                              <AvField
                                name="witness_1_name"
                                type="text"
                                value={nikahRegistrationDetail?.witness_1_name}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </Col>
                          </div>
                          {/* <div className="row mt-4 mb-4">
                            <Col>
                            <label>{language === 'mal' ? "സാക്ഷിയുടെ (1) ഒപ്പ് (ഫോട്ടോ)" : "Witness's (1) Signature (Photo)"}</label>
                              <AvField
                                name="witness_1_sign"
                                type="file"
                              />
                            </Col>
                          </div> */}
                        </Col>

                        <Col sm="12" md="6">
                          {/* <div className="row mt-4 mb-4">
                            <Col>
                            <label>{language === 'mal' ? "വരന്റെ ഒപ്പ് (ഫോട്ടോ)" : "Groom's Signature (Photo)"}</label>
                              <AvField
                                name="groom_sign"
                                type="file"
                              />
                            </Col>
                          </div> */}

                          <div className="row mb-4 ">
                            <Col className="mt-4 ">
                              <Label>
                                {language === "mal"
                                  ? "സാക്ഷി (2) പേര് "
                                  : "Witness (2) Name "}
                                <span className="text-danger">&#42;</span>
                              </Label>
                              <AvField
                                name="witness_2_name"
                                type="text"
                                value={nikahRegistrationDetail?.witness_2_name}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </Col>
                          </div>
                          {/* <div className="row mt-4 mb-4">
                            <Col>
                            <label>{language === 'mal' ? "സാക്ഷിയുടെ (2) ഒപ്പ് (ഫോട്ടോ)" : "Witness's (2) Signature (Photo)"}</label>
                              <AvField
                                name="witness_2_sign"
                                type="file"
                              />
                            </Col>
                          </div> */}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="col-12 btn btn-success save-customer"
                    >
                      {loading && (
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      )}
                      {language === "mal" ? "അപ്ഡേറ്റ്" : "Update"}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateMahallNikahRegistration

UpdateMahallNikahRegistration.propTypes = {
  history: PropTypes.object,
}
