import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FAMILY_MEMBERS,
  GET_FAMILY_MEMBERS_FOR_DOWNLOAD,
  GET_FILTERED_FAMILY_MEMBERS,
  GET_FAMILY_MEMBERS_CARDS,
  GET_FAMILY_MEMBER_DROPDOWN,
  GET_ALL_FAMILY_MEMBERS_DROPDOWN,
  GET_FAMILY_MEMBER_DETAIL,
  UPDATE_PHONE_FAMILY_MEMBER,
  CREATE_FAMILY_MEMBER,
  UPDATE_FAMILY_MEMBER,
  DELETE_FAMILY_MEMBER,
} from "./actionTypes"
import {
  getFamilyMembersSuccess,
  getFamilyMembersFail,
  getFamilyMembersForDownloadSuccess,
  getFamilyMembersForDownloadFail,
  getFilteredFamilyMembersSuccess,
  getFilteredFamilyMembersFail,
  getFamilyMembersCardsSuccess,
  getFamilyMembersCardsFail,
  getFamilyMemberDropdownSuccess,
  getFamilyMemberDropdownFail,
  getAllFamilyMembersDropdownSuccess,
  getAllFamilyMembersDropdownFail,
  getFamilyMemberDetailSuccess,
  getFamilyMemberDetailFail,
  updatePhoneFamilyMemberSuccess,
  updatePhoneFamilyMemberFail,
  createFamilyMemberSuccess,
  createFamilyMemberFail,
  updateFamilyMemberSuccess,
  updateFamilyMemberFail,
  deleteFamilyMemberSuccess,
  deleteFamilyMemberFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getFamilyMembersAPi = ({ searchText, page, familyId, sort }) => {
  if (familyId) {
    return get(
      `/api/v1/familydetails/familymember/?family=${
        familyId && familyId
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  } else if (sort) {
    return get(
      `/api/v1/familydetails/familymember/?ordering=${sort && sort}&search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/familydetails/familymember/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  }
}

const getFamilyMembersForDownloadAPi = ({ filterData }) => {
  return post(`/api/v1/filter/family-member-filter/`, filterData)
}

const getFilteredFamilyMembersAPi = ({ page, pageSize, filterData }) => {
  // if (searchText) {
  //   return post(`/api/v1/filter/family-member-filter/?page=${page ? page : 1}&page_size=${pageSize ? pageSize : 10}&search=${searchText && searchText}`, filterData)
  // }
  // else {
  return post(
    `/api/v1/filter/family-member-filter/?page=${page ? page : 1}&page_size=${
      pageSize ? pageSize : 10
    }`,
    filterData
  )
  // }
}

const getFamilyMembersCardsAPi = () => {
  return get(`/api/v1/familydetails/familymember/?cards=all`)
}
const getFamilyMemberDropdownAPi = ({ searchText, familyId }) => {
  return get(
    `/api/v1/familydetails/familymember/?family_dropdown=${familyId}&search=${
      searchText && searchText
    }`
  )
}
const getAllFamilyMembersDropdownAPi = ({ searchText }) => {
  return get(
    `/api/v1/familydetails/family_member_dropdown/?search=${
      searchText && searchText
    }`
  )
}
const getFamilyMemberDetailsAPi = familyMemberId => {
  return get(`/api/v1/familydetails/familymember/${familyMemberId}/`)
}
const updatePhoneFamilyMemberAPi = ({ phone }) => {
  return get("/api/v1/account/otp_verification", phone)
}
const createFamilyMemberApi = ({ familyMember }) => {
  return post("/api/v1/familydetails/familymember/", familyMember)
}
const updateFamilyMemberApi = ({ familyMemberId, familyMember }) => {
  // console.log("title =>")
  // console.log("data :", familyMemberId)
  return ApiPut(
    `/api/v1/familydetails/familymember/${familyMemberId}/`,
    familyMember
  )
}
const deleteFamilyMemberApi = ({ familyMemberId }) => {
  return del(`/api/v1/familydetails/familymember/${familyMemberId}/`)
}

function* fetchFamilyMembers({ payload }) {
  try {
    const response = yield call(getFamilyMembersAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyMembersSuccess(response))
    } else {
      yield put(getFamilyMembersFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMembersFail(error))
  }
}

function* fetchFamilyMembersForDownload({ payload }) {
  try {
    const response = yield call(getFamilyMembersForDownloadAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyMembersForDownloadSuccess(response))
    } else {
      yield put(getFamilyMembersForDownloadFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyMembersForDownloadFail(error))
  }
}

function* fetchFilteredFamilyMembers({ payload }) {
  try {
    const response = yield call(getFilteredFamilyMembersAPi, payload)
    if (response && !response?.error) {
      yield put(getFilteredFamilyMembersSuccess(response))
    } else {
      yield put(getFilteredFamilyMembersFail(response))
      errorNotification()
    }
  } catch (error) {
    console.log("error: ", error)
    yield put(getFilteredFamilyMembersFail(error))
  }
}

function* fetchFamilyMembersCards() {
  try {
    const response = yield call(getFamilyMembersCardsAPi)
    if (response && !response?.error) {
      yield put(getFamilyMembersCardsSuccess(response))
    } else {
      yield put(getFamilyMembersCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMembersCardsFail(error))
  }
}

function* fetchFamilyMemberDropdown({ payload }) {
  try {
    const response = yield call(getFamilyMemberDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyMemberDropdownSuccess(response))
    } else {
      yield put(getFamilyMemberDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyMemberDropdownFail(error))
  }
}

function* fetchAllFamilyMembersDropdown({ payload }) {
  try {
    const response = yield call(getAllFamilyMembersDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getAllFamilyMembersDropdownSuccess(response))
    } else {
      yield put(getAllFamilyMembersDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAllFamilyMembersDropdownFail(error))
  }
}

function* fetchFamilyMemberDetail({ familyMemberId }) {
  try {
    const response = yield call(getFamilyMemberDetailsAPi, familyMemberId)
    if (response && !response?.error) {
      yield put(getFamilyMemberDetailSuccess(response))
    } else {
      yield put(getFamilyMemberDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyMemberDetailFail(error))
  }
}
function* updatePhoneFamilyMember({ payload }) {
  try {
    const response = yield call(updatePhoneFamilyMemberAPi, payload)
    if (response && !response?.error) {
      yield put(updatePhoneFamilyMemberSuccess(response))
    } else {
      yield put(updatePhoneFamilyMemberFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updatePhoneFamilyMemberFail(error))
  }
}
function* onCreateFamilyMember({ payload }) {
  try {
    const response = yield call(createFamilyMemberApi, payload)
    if (response && !response?.error) {
      yield put(createFamilyMemberSuccess(response))
      // payload.history.push(`/families/${response?.family}`)
      doneNotification("Created Successfully")
    } else {
      yield put(createFamilyMemberFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createFamilyMemberFail(error))
    errorNotification(error)
    // if (error?.response?.data?.error[0]) {
    //   yield put(createFamilyMemberFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function* onUpdateFamilyMember({ payload }) {
  // console.log("data :", payload)
  try {
    const response = yield call(updateFamilyMemberApi, payload)
    if (response && !response?.error) {
      yield put(updateFamilyMemberSuccess(response))
      payload.history.push(`/familymembers/${response?.id}`)
      doneNotification("Updated Successfully")
    } else {
      yield put(updateFamilyMemberFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateFamilyMemberFail(error))
    // if (error?.response?.data?.error[0]) {
    //   yield put(updateFamilyMemberFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function* onDeleteFamilyMember({ payload }) {
  try {
    const response = yield call(deleteFamilyMemberApi, payload)
    if (response && !response?.error) {
      payload.history.push("/familymembers")
      yield put(deleteFamilyMemberSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteFamilyMemberFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(deleteFamilyMemberFail(error))
    // if (error?.response?.data?.error[0]) {
    //   yield put(deleteFamilyMemberFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* FamilyMembersSaga() {
  yield takeEvery(GET_FAMILY_MEMBERS, fetchFamilyMembers)
  yield takeEvery(
    GET_FAMILY_MEMBERS_FOR_DOWNLOAD,
    fetchFamilyMembersForDownload
  )
  yield takeEvery(GET_FILTERED_FAMILY_MEMBERS, fetchFilteredFamilyMembers)
  yield takeEvery(GET_FAMILY_MEMBERS_CARDS, fetchFamilyMembersCards)
  yield takeEvery(GET_FAMILY_MEMBER_DETAIL, fetchFamilyMemberDetail)
  yield takeEvery(UPDATE_PHONE_FAMILY_MEMBER, updatePhoneFamilyMember)
  yield takeEvery(GET_FAMILY_MEMBER_DROPDOWN, fetchFamilyMemberDropdown)
  yield takeEvery(
    GET_ALL_FAMILY_MEMBERS_DROPDOWN,
    fetchAllFamilyMembersDropdown
  )
  yield takeEvery(CREATE_FAMILY_MEMBER, onCreateFamilyMember)
  yield takeEvery(UPDATE_FAMILY_MEMBER, onUpdateFamilyMember)
  yield takeEvery(DELETE_FAMILY_MEMBER, onDeleteFamilyMember)
}

export default FamilyMembersSaga
