import {
  GET_STUDENT_VARISANGYA_WALLETS,
  GET_STUDENT_VARISANGYA_WALLETS_SUCCESS,
  GET_STUDENT_VARISANGYA_WALLETS_FAIL,
  CREATE_STUDENT_VARISANGYA_WALLET,
  CREATE_STUDENT_VARISANGYA_WALLET_SUCCESS,
  CREATE_STUDENT_VARISANGYA_WALLET_FAIL,
  GET_STUDENT_VARISANGYA_WALLET_DETAIL,
  GET_STUDENT_VARISANGYA_WALLET_DETAIL_SUCCESS,
  GET_STUDENT_VARISANGYA_WALLET_DETAIL_FAIL,
  CLEAR_STUDENT_VARISANGYA_WALLET_DETAIL,
  GET_STUDENT_VARISANGYA_WALLET_VIEW,
  GET_STUDENT_VARISANGYA_WALLET_VIEW_SUCCESS,
  GET_STUDENT_VARISANGYA_WALLET_VIEW_FAIL,
} from "./actionTypes"

export const getStudentVarisangyaWallets = (searchText, page, sort) => ({
  type: GET_STUDENT_VARISANGYA_WALLETS,
  payload: { searchText, page, sort },
})
export const getStudentVarisangyaWalletsSuccess = studentVarisangyaWallets => ({
  type: GET_STUDENT_VARISANGYA_WALLETS_SUCCESS,
  payload: studentVarisangyaWallets,
})
export const getStudentVarisangyaWalletsFail = error => ({
  type: GET_STUDENT_VARISANGYA_WALLETS_FAIL,
  payload: error,
})

export const createStudentVarisangyaWallet = studentVarisangyaWallet => ({
  type: CREATE_STUDENT_VARISANGYA_WALLET,
  payload: { studentVarisangyaWallet },
})

export const createStudentVarisangyaWalletSuccess =
  studentVarisangyaWallet => ({
    type: CREATE_STUDENT_VARISANGYA_WALLET_SUCCESS,
    payload: studentVarisangyaWallet,
  })

export const createStudentVarisangyaWalletFail = error => ({
  type: CREATE_STUDENT_VARISANGYA_WALLET_FAIL,
  payload: error,
})

export const getStudentVarisangyaWalletDetail = (
  studentId,
  page,
  searchText,
  sort
) => ({
  type: GET_STUDENT_VARISANGYA_WALLET_DETAIL,
  payload: { studentId, page, searchText, sort },
})

export const getStudentVarisangyaWalletDetailSuccess =
  studentVarisangyaWalletDetail => ({
    type: GET_STUDENT_VARISANGYA_WALLET_DETAIL_SUCCESS,
    payload: studentVarisangyaWalletDetail,
  })

export const getStudentVarisangyaWalletDetailFail = error => ({
  type: GET_STUDENT_VARISANGYA_WALLET_DETAIL_FAIL,
  payload: error,
})

export const clearStudentVarisangyaWalletDetail = () => ({
  type: CLEAR_STUDENT_VARISANGYA_WALLET_DETAIL,
})

export const getStudentVarisangyaWalletView = studentVarisangyaWalletId => ({
  type: GET_STUDENT_VARISANGYA_WALLET_VIEW,
  studentVarisangyaWalletId,
})

export const getStudentVarisangyaWalletViewSuccess =
  studentVarisangyaWalletView => ({
    type: GET_STUDENT_VARISANGYA_WALLET_VIEW_SUCCESS,
    payload: studentVarisangyaWalletView,
  })

export const getStudentVarisangyaWalletViewFail = error => ({
  type: GET_STUDENT_VARISANGYA_WALLET_VIEW_FAIL,
  payload: error,
})
