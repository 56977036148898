import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
//actions
import moment from "moment"

import {
  createNikahRegistration,
  getMahallTypeLists,
  getAllFamilyMembersDropdown,
  getFamilyMemberDetail,
  checkCustomId,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateMahallNikahRegistration = ({ history }) => {
  const dispatch = useDispatch()

  const today = moment(new Date()).format("yyyy-MM-DD")

  const [isPaidSwitch, setIsPaidSwitch] = useState(false)
  const [applicantType, setApplicantType] = useState("bride")
  const [checkValue, setCheckValue] = useState()

  const {
    loading,
    // error,
    language,
    userDetail,
    mahallTypeLists,
    allFamilyMembers,
    familyMemberDetail,
    customIdAvailability,
    customIdCheckLoading,
    mahallId,
  } = useSelector(state => ({
    loading: state.NikahRegistrations.loading,
    error: state.NikahRegistrations.error,
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    allFamilyMembers: state.FamilyMembers.allFamilyMembers,
    familyMemberDetail: state.FamilyMembers.familyMemberDetail,
    customIdAvailability: state.Users.customIdAvailability,
    customIdCheckLoading: state.Users.customIdCheckLoading,
    mahallId: state.Users.userDetail?.mahall?.id,
  }))

  const [searchText, setSearchText] = useState("")
  const [familyMember, setFamilyMember] = useState("Select member...")
  const [familyMemberId, setFamilyMemberId] = useState()

  useEffect(() => {
    if (familyMemberId) {
      dispatch(getFamilyMemberDetail(familyMemberId))
    }
  }, [dispatch, familyMemberId])

  useEffect(() => {
    if (userDetail?.mahall?.id) {
      if (!mahallTypeLists || mahallTypeLists.length <= 0)
        dispatch(getMahallTypeLists(userDetail?.mahall?.id))
    }
  }, [dispatch, userDetail, mahallTypeLists])

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const registerNikahData = {
      mahall_custom_id: values.mahall_custom_id,
      date: values.nikah_date,
      place: values.nikah_place,
      headed_by: values.headed_by,
      mahall_of: applicantType,
      member: familyMemberId,

      groom_name: values.groom_name,
      groom_father_name: values.groom_father_name,
      groom_dob: values.groom_dob,
      groom_address: values.groom_address,
      groom_mahall: values.groom_mahall,
      groom_aadhaar: values.groom_aadhaar,

      bride_name: values.bride_name,
      bride_father_name: values.bride_father_name,
      bride_dob: values.bride_dob,
      bride_address: values.bride_address,
      bride_mahall: values.bride_mahall,
      bride_aadhaar: values.bride_aadhaar,

      valiyy: values.valiyy,
      relation_bw_bride_valiyy: values.relation_bw_bride_valiyy,
      witness_1_name: values.witness_1_name,
      witness_2_name: values.witness_2_name,

      fee: values.fee,
      noc_id: "",
      is_paid: isPaidSwitch,
      mahar_type_choice_id:
        values.mahar_type_choice_id === "Select one ..." ||
        values.mahar_type_choice_id === "തിരഞ്ഞെടുക്കുക ..."
          ? null
          : values.mahar_type_choice_id,
      mahar_unit: values.mahar_unit,

      // bride_sign: values.bride_sign,
      // groom_sign: values.groom_sign,
      // witness_1_sign: values.witness_1_sign,
      // witness_2_sign: values.witness_2_sign,
    }
    // console.log('registerNikahData: ', registerNikahData);
    dispatch(createNikahRegistration(registerNikahData, history))
  }

  // const mailError = error[0]

  const applicantTypes = [
    { name: `${language === "mal" ? "വധു" : "Bride"}`, value: "bride" },
    { name: `${language === "mal" ? "വരൻ" : "Groom"}`, value: "groom" },
  ]

  const maharTypes = mahallTypeLists?.[`mahar_type`]?.map(item => {
    return {
      id: item.id,
      label: `${language === "mal" ? item?.malayalam : item?.english}`,
    }
  })

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchText))
  }, [dispatch, searchText])

  const optionGroup = [
    {
      options: allFamilyMembers?.map((result, index) => ({
        key: index,
        label: `${result?.full_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        })`,
        // <div>
        //   {results?.full_name}
        //   {results?.mahall_custom_id ? (
        //     <Badge className="bg-khaf-blue mx-2">
        //       {results?.mahall_custom_id}
        //     </Badge>
        //   ) : (
        //     <Badge className="bg-khaf-blue mx-2">
        //       {results?.custom_id}
        //     </Badge>
        //   )}
        // </div>,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setFamilyMemberId(event.value)
    setFamilyMember(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const handleCheckNikahCustomId = v => {
    const checkNikahCustomIdData = {
      mahall: mahallId,
      mahall_custom_id: v,
      model: "nikah",
    }
    dispatch(checkCustomId(checkNikahCustomIdData))
    setCheckValue(v)
    // console.log("phone: ", v)
  }

  return (
    <>
      <MetaTags>
        <title>Nikah | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "നിക്കാഹ്" : "Nikah"}
          breadcrumbItem={language === "mal" ? "രജിസ്റ്റർ" : "Register"}
        />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <Row>
                <Col sm="12" md="12" lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm="12" md="6">
                          <Label>
                            {language === "mal" ? "അപേക്ഷക/ൻ " : "Applicant "}
                          </Label>
                          <div className="row mb-3 d-flex">
                            {applicantTypes.map((item, key) => (
                              <Col md="6" key={key}>
                                <div className="me-3">
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="btnradio6"
                                    id={item.name}
                                    autoComplete="off"
                                    value={item.value}
                                    checked={
                                      applicantType === item.value
                                        ? true
                                        : false
                                    }
                                    onChange={e =>
                                      setApplicantType(e.target.value)
                                    }
                                  />
                                  {item.value === "bride" ? (
                                    <label
                                      className="btn btn-outline-primary w-100"
                                      htmlFor={item.name}
                                    >
                                      {item.name}
                                    </label>
                                  ) : (
                                    <label
                                      className="btn btn-outline-primary w-100"
                                      htmlFor={item.name}
                                    >
                                      {item.name}
                                    </label>
                                  )}
                                </div>
                              </Col>
                            ))}
                          </div>

                          <div className="row mb-4">
                            <Col>
                              <Label>
                                {language === "mal" ? "കുടുംബാംഗം" : "Member"}
                              </Label>
                              <Select
                                onInputChange={handleEnters}
                                placeholder={familyMember}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                                onChange={handlerFinalValue}
                                type="text"
                                title="Family Member"
                              />
                            </Col>
                          </div>

                          <div className="row mb-4">
                            <Col>
                              <AvField
                                name="mahall_custom_id"
                                label={
                                  language === "mal"
                                    ? "രജിസ്റ്റർ നമ്പർ"
                                    : "Register Number"
                                }
                                type="text"
                                onChange={v => {
                                  handleCheckNikahCustomId(v.target.value)
                                  setCheckValue(v.target.value)
                                }}
                              />
                              {checkValue &&
                                checkValue?.length > 0 &&
                                (!customIdCheckLoading ? (
                                  customIdAvailability ? (
                                    <p className="text-success">
                                      {language === "mal"
                                        ? "ലഭ്യമാണ്"
                                        : "Available"}
                                    </p>
                                  ) : (
                                    <p className="text-danger">
                                      {language === "mal"
                                        ? "ലഭ്യമല്ല"
                                        : "Not Available"}
                                    </p>
                                  )
                                ) : (
                                  <></>
                                ))}
                            </Col>
                            <Col>
                              <Label>
                                {language === "mal"
                                  ? "നിക്കാഹ് നടന്ന തീയതി "
                                  : "Nikah Date "}
                                <span className="text-danger">&#42;</span>
                              </Label>
                              <AvField
                                name="nikah_date"
                                type="date"
                                value={today}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </Col>
                          </div>
                          <div className="row mb-4">
                            <Col>
                              <Label>
                                {language === "mal"
                                  ? "നിക്കാഹ് നടന്ന സ്ഥലം "
                                  : "Nikah Place "}
                                <span className="text-danger">&#42;</span>
                              </Label>
                              <AvField
                                name="nikah_place"
                                type="text"
                                required
                              />
                            </Col>
                          </div>

                          <div className="row mb-4 ">
                            <Col>
                              <Label>
                                {language === "mal"
                                  ? "കാർമികത്വം "
                                  : "Headed By "}
                              </Label>
                              <AvField name="headed_by" type="text" />
                            </Col>
                          </div>
                        </Col>
                        <Col sm="12" md="6">
                          <div className="row mb-4 ">
                            <Col>
                              <Label>
                                {language === "mal" ? "വലിയ്യ് " : "Valiyy "}
                              </Label>
                              <AvField name="valiyy" type="text" />
                            </Col>
                          </div>
                          <div className="row mb-4 ">
                            <Col>
                              <Label>
                                {language === "mal"
                                  ? "വധുവുമായുള്ള ബന്ധം "
                                  : "Relation with Bride "}
                              </Label>
                              <AvField
                                name="relation_bw_bride_valiyy"
                                type="text"
                              />
                            </Col>
                          </div>

                          <div className="row mb-4">
                            <Col>
                              <Label>
                                {language === "mal"
                                  ? "മഹർ തരം "
                                  : "Mahar Type "}
                              </Label>
                              <AvField
                                name="mahar_type_choice_id"
                                type="select"
                              >
                                <option>
                                  {language === "mal"
                                    ? "തിരഞ്ഞെടുക്കുക ..."
                                    : "Select one ..."}
                                </option>
                                {maharTypes?.map((item, key) => (
                                  <option key={key} value={item.id}>
                                    {item.label}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                            <Col>
                              <Label>
                                {language === "mal" ? "അളവ് " : "Unit "}
                              </Label>
                              <AvField name="mahar_unit" type="text" />
                            </Col>
                          </div>

                          <div className="row mb-4">
                            <Col>
                              <Label>
                                {language === "mal" ? "ഫീസ്" : "Fee"}
                              </Label>
                              <AvField name="fee" type="number" />
                            </Col>
                            <Col>
                              <Label for="basicpill-email-input4">
                                {language === "mal"
                                  ? "ഫീസ് അടച്ചിട്ടുണ്ടോ?"
                                  : "Is Fee Paid?"}
                              </Label>
                              <div className="form-check form-switch form-switch-lg">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizemd"
                                  checked={isPaidSwitch ? true : false}
                                  onChange={() =>
                                    setIsPaidSwitch(!isPaidSwitch)
                                  }
                                />
                              </div>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col sm="12" md="12" lg="6">
                  <Card>
                    <CardBody>
                      {/* <CardTitle className="h4 mb-4">{language === 'mal' ? "വധു" : "Bride"}</CardTitle> */}

                      {/* bride */}
                      <div
                        className="mb-3 text-center bg-khaf d-flex align-items-center justify-content-center text-white"
                        style={{ height: "30px" }}
                      >
                        <div>
                          <strong>
                            {language === "mal" ? "വധു" : "Bride"}
                          </strong>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "പേര് " : "Name "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="bride_name"
                            type="text"
                            value={
                              applicantType === "bride"
                                ? familyMemberDetail?.full_name
                                : ""
                            }
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "മഹല്ല് " : "Mahall "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="bride_mahall"
                            type="text"
                            value={
                              applicantType === "bride"
                                ? language === "mal"
                                  ? userDetail?.mahall?.mahall_malayalam_name
                                    ? userDetail?.mahall?.mahall_malayalam_name
                                    : userDetail?.mahall?.mahall_english_name
                                  : userDetail?.mahall?.mahall_english_name
                                : ""
                            }
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-3">
                        <Col sm="12" md="6">
                          <div className="mb-3">
                            <Label>
                              {language === "mal"
                                ? "പിതാവിന്റെ പേര് "
                                : "Father Name "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              name="bride_father_name"
                              type="text"
                              value={
                                applicantType === "bride"
                                  ? familyMemberDetail?.father_name
                                  : ""
                              }
                              required
                            />
                          </div>
                        </Col>
                        <Col>
                          <Label>
                            {language === "mal" ? "ജനന തീയതി" : "DOB"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="bride_dob"
                            type="date"
                            value={
                              applicantType === "bride"
                                ? familyMemberDetail?.dob
                                : ""
                            }
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "വിലാസം " : "Address "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="bride_address"
                            type="textarea"
                            value={
                              applicantType === "bride"
                                ? familyMemberDetail?.present_address
                                : ""
                            }
                            rows="3"
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "ആധാർ നമ്പർ" : "Aadhaar No."}
                        </Label>
                        <Col>
                          <AvField
                            name="bride_aadhaar"
                            type="number"
                            validate={{
                              // required: { value: true },
                              maxLength: {
                                value: 12,
                                errorMessage: `${
                                  language === "mal"
                                    ? "ആധാർ നമ്പർ 12 അക്കങ്ങൾ ആയിരിക്കണം"
                                    : "Aadhaar number must be 12 digits"
                                }`,
                              },
                            }}
                          />
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12" md="12" lg="6">
                  <Card>
                    <CardBody>
                      {/* <CardTitle className="h4 mb-4">{language === 'mal' ? "വരൻ" : "Groom"}</CardTitle> */}
                      <div
                        className="mb-3 text-center bg-khaf-blue d-flex align-items-center justify-content-center text-white"
                        style={{ height: "30px" }}
                      >
                        <div>
                          <strong>
                            {language === "mal" ? "വരൻ" : "Groom"}
                          </strong>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "പേര് " : "Name "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="groom_name"
                            type="text"
                            value={
                              applicantType === "groom"
                                ? familyMemberDetail?.full_name
                                : ""
                            }
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "മഹല്ല് " : "Mahall "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="groom_mahall"
                            type="text"
                            value={
                              applicantType === "groom"
                                ? language === "mal"
                                  ? userDetail?.mahall?.mahall_malayalam_name
                                    ? userDetail?.mahall?.mahall_malayalam_name
                                    : userDetail?.mahall?.mahall_english_name
                                  : userDetail?.mahall?.mahall_english_name
                                : ""
                            }
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-3">
                        <Col sm="12" md="6">
                          <div className="mb-3">
                            <Label>
                              {language === "mal"
                                ? "പിതാവിന്റെ പേര് "
                                : "Father Name "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              name="groom_father_name"
                              type="text"
                              value={
                                applicantType === "groom"
                                  ? familyMemberDetail?.father_name
                                  : ""
                              }
                              required
                            />
                          </div>
                        </Col>
                        <Col>
                          <Label>
                            {language === "mal" ? "ജനന തീയതി " : "DOB"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="groom_dob"
                            type="date"
                            value={
                              applicantType === "groom"
                                ? familyMemberDetail?.dob
                                : ""
                            }
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "വിലാസം " : "Address "}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <Col>
                          <AvField
                            name="groom_address"
                            type="textarea"
                            rows="3"
                            value={
                              applicantType === "groom"
                                ? familyMemberDetail?.present_address
                                : ""
                            }
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label>
                          {language === "mal" ? "ആധാർ നമ്പർ" : "Aadhaar No."}
                        </Label>
                        <Col>
                          <AvField
                            name="groom_aadhaar"
                            type="number"
                            validate={{
                              // required: { value: true },
                              maxLength: {
                                value: 12,
                                errorMessage: `${
                                  language === "mal"
                                    ? "ആധാർ നമ്പർ 12 അക്കങ്ങൾ ആയിരിക്കണം"
                                    : "Aadhaar number must be 12 digits"
                                }`,
                              },
                            }}
                          />
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col sm="12" md="12" lg="12">
                  <Card>
                    <CardBody>
                      {/* <CardTitle className="h4 mb-4">{language === 'mal' ? "മറ്റുള്ളവ" : "Others"}</CardTitle> */}
                      <Row>
                        <Col sm="12" md="6">
                          {/* <div className="row mt-4 mb-4">
                            <Col>
                              <label>{language === 'mal' ? "വധുവിന്റെ ഒപ്പ് (ഫോട്ടോ)" : "Bride's Signature (Photo)"}</label>
                              <AvField
                                name="bride_sign"
                                type="file"
                              />
                            </Col>
                          </div> */}

                          <div className="row mb-4 ">
                            <Col className="mt-4 ">
                              <Label>
                                {language === "mal"
                                  ? "സാക്ഷി (1) പേര് "
                                  : "Witness (1) Name "}
                                <span className="text-danger">&#42;</span>
                              </Label>
                              <AvField
                                name="witness_1_name"
                                type="text"
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </Col>
                          </div>
                          {/* <div className="row mt-4 mb-4">
                            <Col>
                              <label>{language === 'mal' ? "സാക്ഷിയുടെ (1) ഒപ്പ് (ഫോട്ടോ)" : "Witness's (1) Signature (Photo)"}</label>
                              <AvField
                                name="witness_1_sign"
                                type="file"
                              />
                            </Col>
                          </div> */}
                        </Col>

                        <Col sm="12" md="6">
                          {/* <div className="row mt-4 mb-4">
                            <Col>
                              <label>{language === 'mal' ? "വരന്റെ ഒപ്പ് (ഫോട്ടോ)" : "Groom's Signature (Photo)"}</label>
                              <AvField
                                name="groom_sign"
                                type="file"
                              />
                            </Col>
                          </div> */}

                          <div className="row mb-4 ">
                            <Col className="mt-4 ">
                              <Label>
                                {language === "mal"
                                  ? "സാക്ഷി (2) പേര് "
                                  : "Witness (2) Name "}
                                <span className="text-danger">&#42;</span>
                              </Label>
                              <AvField
                                name="witness_2_name"
                                type="text"
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </Col>
                          </div>
                          {/* <div className="row mt-4 mb-4">
                            <Col>
                              <label>{language === 'mal' ? "സാക്ഷിയുടെ (2) ഒപ്പ് (ഫോട്ടോ)" : "Witness's (2) Signature (Photo)"}</label>
                              <AvField
                                name="witness_2_sign"
                                type="file"
                              />
                            </Col>
                          </div> */}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="col-12 btn btn-success save-customer"
                    >
                      {loading && (
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      )}
                      {language === "mal" ? "രജിസ്റ്റർ" : "Register"}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateMahallNikahRegistration

CreateMahallNikahRegistration.propTypes = {
  history: PropTypes.object,
}
