import {
  GET_FAMILY_VARISANGYA_ITEMS,
  GET_FAMILY_VARISANGYA_ITEMS_SUCCESS,
  GET_FAMILY_VARISANGYA_ITEMS_FAIL,
  DELETE_FAMILY_VARISANGYA_ITEM,
  CREATE_FAMILY_VARISANGYA_ITEM,
  CREATE_FAMILY_VARISANGYA_ITEM_SUCCESS,
  CREATE_FAMILY_VARISANGYA_ITEM_FAIL,
  DELETE_FAMILY_VARISANGYA_ITEM_SUCCESS,
  DELETE_FAMILY_VARISANGYA_ITEM_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  familyVarisangyaItems: [],
  error: {},
  loading: false,
}

const FamilyVarisangyaItems = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FAMILY_VARISANGYA_ITEMS:
    case CREATE_FAMILY_VARISANGYA_ITEM:
    case DELETE_FAMILY_VARISANGYA_ITEM:
      return {
        ...state,
        loading: true,
      }

    case GET_FAMILY_VARISANGYA_ITEMS_SUCCESS:
      return {
        ...state,
        familyVarisangyaItems: action.payload,
        loading: false,
      }
    case GET_FAMILY_VARISANGYA_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_VARISANGYA_ITEM_SUCCESS:
      return {
        ...state,
        // familyVarisangyaItems: {
        //   ...state.familyVarisangyaItems,
        //   results: [...state.familyVarisangyaItems.results, action.payload],
        // },
        loading: false,
      }

    case CREATE_FAMILY_VARISANGYA_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_FAMILY_VARISANGYA_ITEM_SUCCESS:
      return {
        ...state,
        familyVarisangyaItems: {
          ...state.familyVarisangyaItems,
          results: state.familyVarisangyaItems.results.filter(
            familyVarisangyaItem =>
              familyVarisangyaItem.id !== action.payload.familyVarisangyaItemId
          ),
        },
        loading: false,
      }

    case DELETE_FAMILY_VARISANGYA_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default FamilyVarisangyaItems
