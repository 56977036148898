import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ZAKATS,
  GET_ZAKAT_CARDS,
  GET_ZAKAT_DETAIL,
  CREATE_ZAKAT,
  UPDATE_ZAKAT,
  DELETE_ZAKAT,
} from "./actionTypes"
import {
  getZakatsSuccess,
  getZakatsFail,
  getZakatCardsSuccess,
  getZakatCardsFail,
  getZakatDetailSuccess,
  getZakatDetailFail,
  createZakatSuccess,
  createZakatFail,
  updateZakatSuccess,
  updateZakatFail,
  deleteZakatSuccess,
  deleteZakatFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getZakatsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/zakat/zakat/?search=${searchText && searchText}&page=${
        page && page
      }`
    )
  } else {
    return get(`/api/v1/zakat/zakat/?page=${page ? page : 1}`)
  }
}
const getZakatsCardsAPi = () => {
  return get(`/api/v1/zakat/zakat/?cards=all`)
}
const getZakatDetailsAPi = zakatId => {
  return get(`/api/v1/zakat/zakat/${zakatId}/`)
}
const createZakatsApi = ({ zakat }) => {
  return post("/api/v1/zakat/zakat/", zakat)
}
const updateZakatsApi = ({ zakatId, zakat }) => {
  return ApiPut(`/api/v1/zakat/zakat/${zakatId}/`, zakat)
}
const deleteZakatApi = ({ zakatId }) => {
  return del(`/api/v1/zakat/zakat/${zakatId}/`)
}

function* fetchZakats({ payload }) {
  try {
    const response = yield call(getZakatsAPi, payload)
    if (response && !response?.error) {
      yield put(getZakatsSuccess(response))
    } else {
      yield put(getZakatsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getZakatsFail(error))
  }
}

function* fetchZakatsCards() {
  try {
    const response = yield call(getZakatsCardsAPi)
    if (response && !response?.error) {
      yield put(getZakatCardsSuccess(response))
    } else {
      yield put(getZakatCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getZakatCardsFail(error))
  }
}

function* fetchZakatDetail({ zakatId }) {
  try {
    const response = yield call(getZakatDetailsAPi, zakatId)
    if (response && !response?.error) {
      yield put(getZakatDetailSuccess(response))
    } else {
      yield put(getZakatDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getZakatDetailFail(error))
  }
}
function* onCreateZakats({ payload }) {
  try {
    const response = yield call(createZakatsApi, payload)
    if (response && !response?.error) {
      yield put(createZakatSuccess(response))
      if (payload.history) {
        payload.history.push("/zakats")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createZakatFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createZakatFail(error))
    errorNotification()
  }
}

function* onUpdateZakats({ payload }) {
  try {
    const response = yield call(updateZakatsApi, payload)
    if (response && !response?.error) {
      yield put(updateZakatSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateZakatFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateZakatFail(error))
    errorNotification()
  }
}

function* onDeleteZakats({ payload }) {
  try {
    const response = yield call(deleteZakatApi, payload)
    if (response && !response?.error) {
      payload.history.push("/zakats")
      yield put(deleteZakatSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteZakatFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteZakatFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* ZakatsSaga() {
  yield takeEvery(GET_ZAKATS, fetchZakats)
  yield takeEvery(GET_ZAKAT_CARDS, fetchZakatsCards)
  yield takeEvery(GET_ZAKAT_DETAIL, fetchZakatDetail)
  yield takeEvery(CREATE_ZAKAT, onCreateZakats)
  yield takeEvery(UPDATE_ZAKAT, onUpdateZakats)
  yield takeEvery(DELETE_ZAKAT, onDeleteZakats)
}

export default ZakatsSaga
