import React, { useEffect, useRef } from "react"
import propTypes from "prop-types"
import { Modal } from "reactstrap"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import DonationReceiptPdf from "pages/mahallUser/PdfDownloads/receiptDonationPdf"
import { useDispatch, useSelector } from "react-redux"
import { getTransactionDetails } from "store/actions"
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min"

function RecieptModal({ onCloseClick, show }) {
  const dispatch = useDispatch()

  const { transaction, transactionDetails } = useSelector(state => ({
    transaction: state.Transactions?.transactions,
    transactionDetails: state.Transactions?.transactionDetails,
  }))
  const transactionId = localStorage?.getItem("transationId")

  useEffect(() => {
    dispatch(
      getTransactionDetails(transaction?.id ? transaction?.id : transactionId)
    )
  }, [dispatch, transaction?.id, location?.state?.id])

  const pdfRef = useRef()
  const downloadPdf = () => {
    const input = pdfRef.current
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL(`${""}`)
      const pdf = new jsPDF("p", "mm", "a4", true)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2
      const imgY = 30
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      )
      pdf.save("receipt.pdf")
    })
  }

  console.log(show)

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} className="">
      <DonationReceiptPdf
        pdfRef={pdfRef}
        transactionDetails={transactionDetails}
      />
      <div className="p-3">
        <h5
          className="modal-title h4 flex-center  font-size-22"
          style={{ fontStyle: 600 }}
        >
          Khaf Mahall Empowerment Committee
        </h5>
        <button
          type="button"
          onClick={onCloseClick}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body p-3">
          <div className="p-3 d-flex align-items-center justify-content-between">
            <div>
              No:{" "}
              <span className="text-danger">{transactionDetails?.auto_id}</span>
            </div>
            <div className="font-size-18">Receipt</div>
            <div>Date : 12/12/2012</div>
          </div>
          <div className="mb-0">
            <div className="text-muted font-size-15">
              Received with thanks from Jb <b>{transactionDetails?.name},</b> a
              sum of Rupees of <b>{transactionDetails?.amount} .</b> DONATION
              FOR #khaf.in(Khaf Mahall Empowerment)
            </div>
            <p className="d-flex align-items-center justify-content"></p>
          </div>
          <div className="d-flex align-items-center justify-content-between ">
            <div className="">
              <b> Rs : {transactionDetails?.amount}</b>
            </div>
            <div className="">
              <b>Secretary</b>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 d-flex align-items-center justify-content-end">
        <div className="btn btn-success btn-sm p-1" onClick={downloadPdf}>
          Download PDF
        </div>
      </div>
    </Modal>
  )
}

export default RecieptModal

RecieptModal.propTypes = {
  onCloseClick: propTypes.func,
  show: propTypes.func,
}
