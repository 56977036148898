import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"

//actions
import { createVillage, getDistrictDropdown } from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateVillage = ({ history }) => {
  const dispatch = useDispatch()

  const { loading, searchDistricts } = useSelector(state => ({
    loading: state.Villages.loading,
    error: state.Villages.error,
    villageDetail: state.Villages.villageDetail,
    Districts: state.Districts.Districts,
    searchDistricts: state.Districts.searchDistricts,
  }))

  const [district, setDistrict] = useState("Search District")
  const [districtId, setDistrictId] = useState("")
  const [searchText, setSearchText] = useState("")

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    dispatch(createVillage({ ...values, district: districtId }, history))
  }

  useEffect(() => {
    dispatch(getDistrictDropdown(searchText))
  }, [searchText, dispatch])

  function handlerFinalValue(event) {
    dispatch(getDistrictDropdown(event.value))
    setDistrictId(event.value)
    setDistrict(event.label)
  }

  const optionGroup = [
    {
      options: searchDistricts?.map((results, index) => ({
        key: index,
        label: results.district_english_name,
        value: results.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  // const mailError = error[0]

  return (
    <>
      <MetaTags>
        <title>Villages | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Villages" breadcrumbItem="Create" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm={12} lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Fill this form</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            name="village_english_name"
                            type="text"
                            label="Village Name (English)"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            name="village_malayalam_name"
                            type="text"
                            label="Village Name (മലയാളം)"
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <Label> District </Label>
                          <Select
                            onInputChange={handleEnters}
                            placeholder={district}
                            value={district}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="District"
                            required="required"
                          />
                        </Col>
                      </div>

                      <div className="d-flex justify-content-end">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            )}
                            Submit
                          </Button>
                        </div>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateVillage

CreateVillage.propTypes = {
  history: PropTypes.object,
}
