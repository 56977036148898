import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
  Container,
  Badge,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
import moment from "moment"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { MetaTags } from "react-meta-tags"

import Breadcrumbs from "components/Common/Breadcrumb"
//actions
import {
  getMahallTypeLists,
  getFilteredFamilyMembers,
  getFamilyMembersForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"
// import { Button } from "bootstrap"

const BloodGroups = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [modalBloodBankListDownload, setModalBloodBankListDownload] =
    useState(false)

  const {
    familyMembers,
    loading,
    language,
    userDetail,
    mahallTypeLists,
    familyMembersforDownload,
    // familyMembersforDownloadLoading,
  } = useSelector(state => ({
    familyMembers: state.FamilyMembers.familyMembers,
    loading: state.FamilyMembers.loading,
    familyMembersforDownload:
      state.FamilyMembers.familyMembersforDownload?.results,
    familyMembersforDownloadLoading:
      state.FamilyMembers.familyMembersforDownloadLoading,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
  }))

  const sort = "dob"

  //pages
  const totalPages = Math.ceil(familyMembers?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    if (userDetail?.mahall?.id) {
      dispatch(getMahallTypeLists(userDetail?.mahall?.id))
    }
  }, [dispatch, userDetail])

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("diff:", diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("age: ", age)
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  function getDateFromAge(age) {
    var today = new Date()
    var birthDate = moment(today)
    birthDate = birthDate.subtract(age, "years")
    birthDate = birthDate.format("YYYY-MM-DD")
    return birthDate
  }

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
    },
    // {
    //   dataField: "custom_id",
    //   text: `${language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID"}`,
    // },
    {
      dataField: "mahall_custom_id",
      text: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
    },
    {
      dataField: "full_name",
      text: `${language === "mal" ? "പേര്" : "Name"}`,
      sort: true,
    },
    {
      dataField: "family_name",
      text: `${language === "mal" ? "വീട്ടുപേര്" : "Family Name"}`,
    },
    {
      dataField: "ageGender",
      text: `${language === "mal" ? "പ്രായം / ലിംഗം" : "Age / Gender"}`,
    },
    {
      dataField: "blood_group.english",
      text: `${language === "mal" ? "രക്ത ഗ്രൂപ്പ്" : "Blood Group"}`,
    },
    {
      dataField: "healthStatus",
      text: `${language === "mal" ? "ആരോഗ്യ സ്ഥിതി" : "Health Status"}`,
    },
    {
      dataField: "phone",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]
  const familyMembersData = map(familyMembers?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    ageGender: `${getAge(item?.dob)} / ${item?.gender}`,
    healthStatus: (
      <>
        {item?.health_condition?.map((status, key) => {
          return (
            <Fragment key={key}>
              <Badge
                className={
                  "mx-1 font-size-12 badge-soft-" +
                  `${
                    status.english == "Patient"
                      ? "danger"
                      : status.english == "Satisfied"
                      ? "success"
                      : status.english == "Disabled"
                      ? "warning"
                      : ""
                  }`
                }
                color="black"
                pill
              >
                {language === "mal"
                  ? status.malayalam
                    ? status.malayalam
                    : status.english
                  : status.english}
              </Badge>
            </Fragment>
          )
        })}
      </>
    ),

    action: (
      <Link
        to={`/familymembers/${item?.id}`}
        className=" btn-success w-xs btn-sm"
      >
        {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
        {language === "mal" ? "കാണുക" : "View"}
      </Link>
    ),
  }))

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    {
      label: `${language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID"}`,
      key: "custom_id",
    },
    {
      label: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
      key: "mahall_custom_id",
    },
    { label: `${language === "mal" ? "പേര്" : "Name"}`, key: "full_name" },
    {
      label: `${language === "mal" ? "വീട്ടുപേര്" : "Family Name"}`,
      key: "family_name",
    },
    { label: `${language === "mal" ? "ലിംഗം" : "Gender"}`, key: "gender" },
    { label: `${language === "mal" ? "പ്രായം" : "Age"}`, key: "age" },
    { label: `${language === "mal" ? "ഫോൺ" : "Phone"}`, key: "phone" },
    { label: `${language === "mal" ? "ഇ-മെയിൽ " : "Email"}`, key: "email" },
    {
      label: `${language === "mal" ? "ഐഡി കാർഡ് " : "Id Card"}`,
      key: "id_card",
    },
    {
      label: `${language === "mal" ? "ഐഡി നമ്പർ " : "ID Number"}`,
      key: "id_card_number",
    },
    {
      label: `${language === "mal" ? "പിതാവ് " : "Father"}`,
      key: "father_name",
    },
    {
      label: `${language === "mal" ? "മാതാവ്" : "Mother"}`,
      key: "mother_name",
    },
    {
      label: `${language === "mal" ? "വൈവാഹിക നില " : "Marital Status"}`,
      key: "marital_status",
    },
    {
      label: `${language === "mal" ? "ഇണയുടെ പേര് " : "Spouse"}`,
      key: "spouse_name",
    },
    {
      label: `${language === "mal" ? "അവിവാഹിത/ൻ കാരണം " : "Unmarried Reason"}`,
      key: "unmarried_reason",
    },
    {
      label: `${language === "mal" ? "വിദേശത്താണോ?" : "Is Abroad?"}`,
      key: "is_abroad",
    },
    {
      label: `${language === "mal" ? "അഡ്രസ്" : "Address"}`,
      key: "present_address",
    },
    {
      label: `${language === "mal" ? "രക്ത ഗ്രൂപ്പ്" : "Blood Group"}`,
      key: "blood_group",
    },
    {
      label: `${
        language === "mal" ? "രക്തദാനം ചെയ്യാൻ തയ്യാറാണോ?" : "Ready To Donate?"
      }`,
      key: "ready_to_donate",
    },
    {
      label: `${language === "mal" ? "ആരോഗ്യ സ്ഥിതി" : "Health Status"}`,
      key: "healthStatus",
    },
    {
      label: `${language === "mal" ? "രോഗത്തിന്റെ തരം" : "Disease Type"}`,
      key: "disease_type",
    },
    {
      label: `${language === "mal" ? "മറ്റ് രോഗ തരം" : "Other Disease Type"}`,
      key: "other_disease_type",
    },
    {
      label: `${language === "mal" ? "രോഗ നില" : "Disease Level"}`,
      key: "disease_level",
    },
    {
      label: `${language === "mal" ? "വൈകല്യ തരം" : "Disability Type"}`,
      key: "disability_type",
    },
    {
      label: `${
        language === "mal" ? "മറ്റ് വൈകല്യ തരം" : "Other Disability Type"
      }`,
      key: "other_disability_type",
    },
    {
      label: `${
        language === "mal" ? "സാമൂഹിക പ്രവർത്തനങ്ങൾ" : "Social Activities"
      }`,
      key: "social_activity",
    },
    {
      label: `${
        language === "mal" ? "പ്രവർത്തന വിശദാംശങ്ങൾ" : "Activity Details"
      }`,
      key: "social_activity_details",
    },
    {
      label: `${language === "mal" ? "മോശം ശീലങ്ങൾ " : "Bad Habits"}`,
      key: "bad_habits",
    },
    {
      label: `${
        language === "mal" ? "ഭൗതിക വിദ്യാഭ്യാസം" : "Formal Education"
      }`,
      key: "educational_qualification_formal",
    },
    {
      label: `${language === "mal" ? "വിശദാംശങ്ങൾ" : "Details"}`,
      key: "educational_qualification_formal_details",
    },
    {
      label: `${language === "mal" ? "മത വിദ്യാഭ്യാസം" : "Islamic Education"}`,
      key: "educational_qualification_islamic",
    },
    {
      label: `${language === "mal" ? "വിശദാംശങ്ങൾ" : "Details"}`,
      key: "educational_qualification_islamic_details",
    },
    {
      label: `${language === "mal" ? "വിദ്യാർത്ഥിയാണോ?" : "Is Student?"}`,
      key: "is_student",
    },
    {
      label: `${
        language === "mal" ? "പഠിക്കാത്തതിന്റെ കാരണം" : "Not Studying Reason"
      }`,
      key: "not_studying_reason",
    },
    {
      label: `${language === "mal" ? "ജോലി ചെയ്യുന്നുണ്ടോ?" : "Is Working?"}`,
      key: "is_working",
    },
    {
      label: `${
        language === "mal" ? "ജോലി ചെയ്യാത്തതിന്റെ കാരണം" : "Not Working Reason"
      }`,
      key: "not_working_reason",
    },
    { label: "Listed Date", key: "date_added" },
  ]
  // console.log(familiesforDownload);

  const bloodBankReportsExcelData = map(
    familyMembersforDownload?.results,
    (item, index) => ({
      ...item,
      no: (pageSend() - 1) * limit + index + 1,
      custom_id: item.custom_id,
      mahall_custom_id: item.mahall_custom_id,
      full_name: item.full_name,
      family_name: item.family_name,
      blood_group: item.blood_group?.english,
      gender:
        language === "mal"
          ? item.gender === "male"
            ? "പുരുഷൻ"
            : item.gender === "female"
            ? "സ്ത്രീ"
            : "മറ്റുള്ളവ"
          : item.gender === "male"
          ? "Male"
          : item.gender === "female"
          ? "Female"
          : "Others",
      age: `${getAge(item?.dob)}`,
      healthStatus: `${item?.health_condition?.map(status => {
        return `${
          language === "mal"
            ? status.malayalam
              ? status.malayalam
              : status.english
            : status.english
        } `
      })}`,
      phone: item.phone,
      email: item.email,
      father_name: item.father_name,
      mother_name: item.mother_name,
      marital_status:
        language === "mal"
          ? item?.marital_status?.malayalam
            ? item?.marital_status?.malayalam
            : item?.marital_status?.english
          : item?.marital_status?.english,
      spouse_name: item.spouse_name,
      unmarried_reason:
        language === "mal"
          ? item?.unmarried_reason?.malayalam
            ? item?.unmarried_reason?.malayalam
            : item?.unmarried_reason?.english
          : item?.unmarried_reason?.english,
      ready_to_donate:
        item?.ready_to_donate === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      id_card:
        language === "mal"
          ? item?.id_card?.malayalam
            ? item?.id_card?.malayalam
            : item?.id_card?.english
          : item?.id_card?.english,
      id_card_number: item.id_card_number,
      is_abroad:
        item?.is_abroad === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      present_address: item.present_address,
      disease_type: `${item?.disease_type?.map(status => {
        return `${
          language === "mal"
            ? status.malayalam
              ? status.malayalam
              : status.english
            : status.english
        } `
      })}`,
      other_disease_type: item.other_disease_type,
      disease_level:
        language === "mal"
          ? item?.disease_level?.malayalam
            ? item?.disease_level?.malayalam
            : item?.disease_level?.english
          : item?.disease_level?.english,
      disability_type: `${item?.disability_type?.map(status => {
        return `${
          language === "mal"
            ? status.malayalam
              ? status.malayalam
              : status.english
            : status.english
        } `
      })}`,
      social_activity: `${item?.social_activity?.map(status => {
        return `${
          language === "mal"
            ? status.malayalam
              ? status.malayalam
              : status.english
            : status.english
        } `
      })}`,
      other_disability_type: item.other_disability_type,
      social_activity_details: item.social_activity_details,
      bad_habits: `${item?.bad_habits?.map(status => {
        return `${
          language === "mal"
            ? status.malayalam
              ? status.malayalam
              : status.english
            : status.english
        } `
      })}`,
      educational_qualification_formal:
        language === "mal"
          ? item?.educational_qualification_formal?.malayalam
            ? item?.educational_qualification_formal?.malayalam
            : item?.educational_qualification_formal?.english
          : item?.educational_qualification_formal?.english,
      educational_qualification_formal_details:
        item.educational_qualification_formal_details,
      educational_qualification_islamic:
        language === "mal"
          ? item?.educational_qualification_islamic?.malayalam
            ? item?.educational_qualification_islamic?.malayalam
            : item?.educational_qualification_islamic?.english
          : item?.educational_qualification_islamic?.english,
      educational_qualification_islamic_details:
        item.educational_qualification_islamic_details,
      is_student:
        item?.is_student === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      not_studying_reason:
        language === "mal"
          ? item?.not_studying_reason?.malayalam
            ? item?.not_studying_reason?.malayalam
            : item?.not_studying_reason?.english
          : item?.not_studying_reason?.english,
      is_working:
        item?.is_working === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      not_working_reason:
        language === "mal"
          ? item?.not_working_reason?.malayalam
            ? item?.not_working_reason?.malayalam
            : item?.not_working_reason?.english
          : item?.not_working_reason?.english,
      date_added:
        item?.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMahallFamilyMemberSearch = debounce(
    value => setSearchText(value),
    600
  )

  //multi-list
  const [bloodGroups, setBloodGroups] = useState([])
  const [gender] = useState("male")
  const [ageFrom] = useState(18)
  const [ageTo, setAgeTo] = useState("")
  const [healthCondition] = useState("satisfied")

  const setOfNumbers = count => {
    return Array.from({ length: count }, (v, i) => i + 19)
  }

  const [filterListArea, setFilterListArea] = useState()

  const addOrRemoveItem = (arr, setArray, value) => {
    const isPresent = arr.includes(value)
    var newArr = arr

    if (isPresent) {
      newArr = arr.filter(item => {
        return item.toString() !== value.toString()
      })
    } else {
      newArr = [...arr, value]
    }
    setArray(newArr)
    setFilterListArea([
      {
        field: "blood_group_choice_id",
        value: newArr,
      },
      {
        field: "gender",
        value: gender,
      },
      {
        field: "health_condition_choice_id_array",
        value: healthCondition,
      },
      {
        field: "is_abroad",
        value: false,
      },
      {
        field: "ready_to_donate",
        value: true,
      },
      {
        field: "dob",
        value: {
          from: getDateFromAge(
            parseInt(ageTo !== "none" && ageTo !== "" ? ageTo : 100) + 1
          ),
          to: getDateFromAge(parseInt(ageFrom)),
        },
      },
    ])
  }

  const handleSetAge = ageTo => {
    setFilterListArea([
      {
        field: "blood_group_choice_id",
        value: bloodGroups,
      },
      {
        field: "gender",
        value: gender,
      },
      {
        field: "health_condition_choice_id_array",
        value: healthCondition,
      },
      {
        field: "is_abroad",
        value: false,
      },
      {
        field: "ready_to_donate",
        value: true,
      },
      {
        field: "dob",
        value: {
          from: getDateFromAge(
            parseInt(ageTo !== "none" && ageTo !== "" ? ageTo : 100) + 1
          ),
          to: getDateFromAge(parseInt(ageFrom)),
        },
      },
    ])
  }

  const filterData = {
    filter: filterListArea,
    sort: [sort],
    search: searchText,
  }

  useEffect(() => {
    dispatch(getFilteredFamilyMembers(page, 10, filterData))
  }, [dispatch, page, sort, 10, searchText, filterListArea])

  useEffect(() => {
    if (
      userDetail &&
      ["premium", "standard"].includes(userDetail?.mahall?.version) &&
      modalBloodBankListDownload
    ) {
      dispatch(getFamilyMembersForDownload(filterData))
    }
  }, [dispatch, sort, searchText, filterListArea, modalBloodBankListDownload])

  const fileName = "khaf-smart-mahall-bloodbank-reports-list.csv"

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalBloodBankListDownload}
        onCloseclick={() => setModalBloodBankListDownload(false)}
        excelData={bloodBankReportsExcelData}
        headers={headers}
        list={familyMembersforDownload}
        fileName={fileName}
        loading={loading}
      />
      <MetaTags>
        <title>Report | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "രക്തബാങ്ക് " : "Blood Bank"}
          breadcrumbItem={language === "mal" ? "റിപ്പോർട്ട്" : "Report"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col className="col-12">
                <ToolkitProvider
                  keyField={"key"}
                  key={"key"}
                  columns={columns}
                  data={familyMembersData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Card>
                        <CardBody>
                          <Row className="mb-0">
                            <AvForm>
                              <h4 className="text-center">
                                {language === "mal"
                                  ? "രക്തബാങ്ക് "
                                  : "Blood Bank"}
                              </h4>
                              <Col
                                md="12"
                                className="d-flex align-items-center justify-content-start"
                              >
                                <div className="d-flex">
                                  <div>
                                    {mahallTypeLists?.blood_group &&
                                      mahallTypeLists?.blood_group?.length && (
                                        <Col md="12" lg={12}>
                                          <div className="mb-3">
                                            <Label for="basicpill-cardno-input12">
                                              {language === "mal"
                                                ? "രക്തഗ്രൂപ്പ്"
                                                : "Blood Group"}
                                            </Label>
                                            <div className="d-flex  flex-wrap">
                                              {map(
                                                mahallTypeLists?.blood_group,
                                                (item, key) => (
                                                  <div
                                                    key={key}
                                                    className="m-1"
                                                  >
                                                    <input
                                                      type="radio"
                                                      className="btn-check"
                                                      name={item.id}
                                                      checked={bloodGroups?.includes(
                                                        item.id
                                                      )}
                                                      onChange={() => {}}
                                                      id={item.id}
                                                      autoComplete="off"
                                                      value={item.id}
                                                      onClick={e => {
                                                        addOrRemoveItem(
                                                          bloodGroups,
                                                          setBloodGroups,
                                                          e.target.value
                                                        )
                                                      }}
                                                    />
                                                    <label
                                                      className="btn btn-outline-info"
                                                      htmlFor={item.id}
                                                    >
                                                      {language === "mal"
                                                        ? item?.malayalam
                                                        : item?.english}
                                                    </label>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                      )}
                                  </div>
                                  <div className="mx-2">
                                    <Label>
                                      {" "}
                                      {language === "mal" ? "വയസ്സ്" : "Age"}
                                    </Label>
                                    <div className="d-flex mt-1">
                                      <div className="col-md-2">
                                        <AvField
                                          type="text"
                                          name="age_from"
                                          readOnly
                                          value={ageFrom}
                                          helpMessage={
                                            language === "mal" ? "മുതൽ" : "From"
                                          }
                                        ></AvField>
                                      </div>
                                      <div className="mx-1 col-md-2">
                                        <AvField
                                          type="select"
                                          name="age_to"
                                          onChange={e => {
                                            setAgeTo(e.target.value)
                                            handleSetAge(e.target.value)
                                          }}
                                          helpMessage={
                                            language === "mal" ? "വരെ" : "To"
                                          }
                                        >
                                          <option>{"none"}</option>
                                          {setOfNumbers(100)?.map(
                                            (item, key) => (
                                              <option key={key} value={item}>
                                                {item}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </AvForm>
                          </Row>
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <>
                            <Row>
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <form
                                      className="app-search d-lg-block"
                                      onChange={e =>
                                        debounceMahallFamilyMemberSearch(
                                          e.target.value
                                        )
                                      }
                                    >
                                      <div className="position-relative">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder={
                                            language === "mal"
                                              ? "തിരയുക..."
                                              : "Search..."
                                          }
                                          defaultValue={searchText}
                                        />
                                        <span className="bx bx-search-alt" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                {userDetail?.mahall?.version === "basic" ? (
                                  <></>
                                ) : (
                                  <div className="d-flex justify-content-end">
                                    <Button
                                      style={{ width: "130px", border: "bold" }}
                                      className="btn-md me-4 mt-2 bg-white text-khaf"
                                      onClick={() =>
                                        setModalBloodBankListDownload(true)
                                      }
                                    >
                                      <i
                                        className="bx bx-download mx-1"
                                        style={{
                                          fontSize: "18px",
                                          color: "#009846",
                                        }}
                                      />
                                      {language === "mal"
                                        ? `ഡൗൺലോഡ് `
                                        : `Download`}
                                    </Button>
                                  </div>
                                )}
                              </Col>
                              {/* {familyMembersforDownload && !familyMembersforDownload ? <Spinner color="secondary" className="d-block m-auto" /> :
                                userDetail?.mahall.version === 'basic' ? <></> :
                                  <Button data-tip data-for="downloadTip"
                                    style={{ width: "200px", border: 'none' }}
                                    className="w-md btn btn-info m-auto me-0 mt-0 mb-2 d-block bg-white"
                                  >
                                    {familyMembersforDownload && familyMembersforDownload.length > 0 && <CSVLink data={familyMembersExcelData} headers={headers} filename={"khaf-smart-mahall-families-list.csv"}>
                                      <i className="bx bx-download mx-1" style={{ fontSize: '18px', color: "blue" }} />
                                      {language === 'mal' ? `ഡൗൺലോഡ് (${familyMembers?.count})` : `Download (${familyMembers?.count})`}
                                    </CSVLink>}
                                  </Button>
                              } */}
                              {loading ? (
                                <Spinner
                                  color="secondary"
                                  className="d-block m-auto"
                                />
                              ) : (
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="key"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table align-middle table-wrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                          {familyMembers &&
                            familyMembers?.results &&
                            familyMembers?.results?.length <= 0 && (
                              <p
                                style={{ fontSize: "15px" }}
                                className="text-center text-info"
                              >
                                {language === "mal"
                                  ? "കുടുംബങ്ങൾ ഒന്നും ഇല്ല"
                                  : "No Families Yet"}
                              </p>
                            )}
                        </CardBody>
                      </Card>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BloodGroups
