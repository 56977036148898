import React, { Fragment, useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Badge,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//componets
import { debounce, map, range } from "lodash"

import UiModalRefreshStudentVarisangya from "./UiModalRefreshStudentVarisangya"

// import MyPagination from 'components/Common/MyPagination'
//actions
import { getStudentVarisangyaDetail } from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"

const StudentVarisangyaDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const [searchText, setSearchText] = useState("")
  // const [page, setPage] = useState(1)
  const page = 1
  const limit = 10
  const [modalRefreshStudentVarisangya, setModalRefreshStudentVarisangya] =
    useState(false)

  const {
    studentVarisangyaDetail,
    loading,
    // detailLoading,
    language,
    refreshLoading,
  } = useSelector(state => ({
    loading: state.StudentVarisangyas.loading,
    detailLoading: state.Mahalls.detailLoading,
    studentVarisangyaDetail: state.StudentVarisangyas.studentVarisangyaDetail,
    language: state.Layout.language,
    refreshLoading: state.StudentVarisangyas.refreshLoading,
  }))

  //pages
  const totalPages = Math.ceil(
    studentVarisangyaDetail?.madrasa_student_varisankya_items?.length / 10
  )
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getStudentVarisangyaDetail(params.id))
  }, [])

  const cardData = [
    {
      title: `${language === "mal" ? `ആകെ തുക` : `Total Amount`}`,
      cardValue: `${studentVarisangyaDetail?.total_varisankya_amount ?? 0}`,
      iconClass: "chat",
      routeLink: "/#!",
      loading: loading,
      alt: `0`,
    },
    {
      title: `${language === "mal" ? `ആകെ എണ്ണം` : `Total Items`}`,
      cardValue: `${studentVarisangyaDetail?.total_count ?? 0}`,
      iconClass: "chat",
      routeLink: "/#!",
      loading: loading,
      alt: `0`,
    },
    {
      title: `${language === "mal" ? "ശേഷിക്കുന്ന തുക" : "Pending Amount"}`,
      cardValue: `${
        studentVarisangyaDetail?.total_varisankya_amount -
          studentVarisangyaDetail?.recieved_varisankya_amount ?? 0
      }`,
      iconClass: "error-circle",
      routeLink: "/#!",
      loading: loading,
      alt: `0`,
    },
    {
      title: `${language === "mal" ? "ശേഷിക്കുന്ന എണ്ണം" : "Pending Count"}`,
      cardValue: `${studentVarisangyaDetail?.pending_count ?? 0}`,
      iconClass: "error-circle",
      routeLink: "/#!",
      loading: loading,
      alt: `0`,
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "madrasa_student_name",
      text: language === "mal" ? "വിദ്യാർത്ഥി" : "Student",
    },
    {
      dataField: "amount",
      text: language === "mal" ? "തുക" : "Amount",
    },
    {
      dataField: "pending_amount",
      text: language === "mal" ? "ശേഷിക്കുന്ന തുക" : "Pending",
    },
    {
      dataField: "payment_date",
      text: language === "mal" ? "അടച്ച തീയതി" : "Paid Date",
    },
    {
      dataField: "paidStatus",
      text: language === "mal" ? "സ്റ്റാറ്റസ്" : "Status",
    },
    // {
    //   dataField: "action",
    //   text: language === 'mal' ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    // }
  ]

  const studentVarisangyaItemsData = map(
    studentVarisangyaDetail?.madrasa_student_varisankya_items,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,
      // amount: <p className={item?.trn_type === "credit" ? "text-success" : "text-danger"}>{item?.amount}</p>,
      balance: <p className="text-primary">{item?.balance}</p>,
      payment_date:
        item?.payment_date && moment(item?.payment_date).format("DD/MM/yyyy"),
      paidStatus: (
        <>
          <Badge
            className={
              "font-size-12 badge-soft-" +
              `${
                item?.is_paid
                  ? "success"
                  : item?.pending_amount == item?.amount
                  ? "danger"
                  : "warning"
              }`
            }
            color={"primary"}
            pill
          >
            {item?.is_paid
              ? "Paid"
              : item?.pending_amount == item?.amount
              ? "UnPaid"
              : "Partial"}
          </Badge>
        </>
      ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceStudentVarisangyaItemSearch = debounce(
    value => setSearchText(value),
    600
  )

  return (
    <>
      <UiModalRefreshStudentVarisangya
        show={modalRefreshStudentVarisangya}
        onCloseclick={() => setModalRefreshStudentVarisangya(false)}
        data={studentVarisangyaDetail?.id}
      />

      <MetaTags>
        <title>Madrasa Fee | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "മദ്രസ ഫീസ്" : "Madrasa Fee"}
          breadcrumbItem={language === "mal" ? "വിശദാംശങ്ങൾ" : "Details"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md="12" lg="12">
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <Row>
                    <Col className="col-lg-12 col-md-12">
                      <Row>
                        {/* <Col md="4">
                          <Card className="overflow-hidden">
                            <div className="bg-primary bg-soft">
                              <Row>
                                <div className="text-primary p-4">
                                  <div className="px-2">
                                    <Row>
                                      <Col md="12">
                                        <h5 className="text-khaf">Committee</h5>
                                        <h4 className="mt-4">{studentVarisangyaDetail?.title}</h4>
                                        <p className="text-muted">{(moment(studentVarisangyaDetail?.date).format("DD/MM/YYYY"))}</p>
                                      </Col>
                                      <div className=" d-flex justify-content-end">
                                          <Button type="button" className="btn-sm bg-khaf-blue" onClick={() => setModalCommitteeUpdate(true)}>
                                            <i className="bx bx-edit text-center"></i>{language === "mal" ? " അപ്ഡേറ്റ്" : "Update"}</Button>
                                        </div>
                                    </Row>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Card>
                        </Col> */}
                        <Col md="12">
                          <Row>
                            {cardData?.map((item, key) => (
                              <Col
                                sm="12"
                                md="6"
                                lg="3"
                                key={key}
                                className="sm:p-0"
                              >
                                <Card className="blog-stats-wid">
                                  <CardBody>
                                    <Link to={item.routeLink && item.routeLink}>
                                      <div className="d-flex flex-wrap">
                                        <div className="me-3">
                                          <p className="text-muted mb-2">
                                            {item.title}
                                          </p>
                                          {item.cardValue && (
                                            <h5 className="mb-0">
                                              {item.cardValue}
                                            </h5>
                                          )}
                                          <p className="m-0 text-info">
                                            {item.cardValue ? <></> : item.alt}
                                          </p>
                                        </div>

                                        <div className="avatar-sm ms-auto">
                                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                            <i
                                              className={
                                                "bx bxs-" + `${item.iconClass}`
                                              }
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {/* <Row className="task-dates">
                  <Col sm="12" xs="12">
                    <div className="d-flex justify-content-end mt-5">
                      <div className="">
                        <Link
                          to={`/mahall/update/${studentVarisangyaDetail?.id}`}
                          className={`btn btn-primary w-md ${loading && "disabled"
                            } btn-m`}
                        >
                          Update{" "}
                        </Link>
                      </div>
                      <div style={{ position: "relative", left: "10px" }}>
                        <Link
                          style={{ opacity: 0.8 }}
                          to="#"
                          className={`btn btn-danger w-md ${loading && "disabled"
                            }  btn-m`}
                          onClick={handleDelete}
                        >
                          Delete
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row> */}
              </Col>
            </Row>
            <Col sm="12" md="12" lg="12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={studentVarisangyaItemsData}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <form
                                  className="app-search d-lg-block"
                                  onChange={e =>
                                    debounceStudentVarisangyaItemSearch(
                                      e.target.value
                                    )
                                  }
                                >
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search..."
                                      defaultValue={searchText}
                                    />
                                    <span className="bx bx-search-alt" />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </Col>
                          {/* <div className="col-md-8">
                            <Link to="/family/varisangya/transaction/create"
                              style={{ width: "120px" }}
                              className="w-md btn btn-success m-auto me-0 d-block"
                            >
                              {language === 'mal' ? "+ വരിസംഖ്യ " : "+ Varisangya"}
                            </Link>
                          </div> */}
                          <Button
                            onClick={() =>
                              setModalRefreshStudentVarisangya(true)
                            }
                            style={{
                              width: language === "mal" ? "150px" : "120px",
                            }}
                            className="w-md btn btn-info m-auto me-0 d-block"
                          >
                            <i className={"bx bx-add-to-queue mx-1"}></i>
                            {language === "mal"
                              ? "ലിസ്റ്റ് പുതുക്കുക"
                              : "Refresh List"}
                          </Button>
                        </Row>
                        {loading ? (
                          <Spinner
                            color="secondary"
                            className="d-block m-auto"
                          />
                        ) : refreshLoading ? (
                          <Spinner
                            color="secondary"
                            className="d-block m-auto"
                          />
                        ) : (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-wrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            {/* <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            /> */}
                          </>
                        )}
                        {studentVarisangyaDetail &&
                          studentVarisangyaDetail
                            ?.madrasa_student_varisankya_items?.length <= 0 && (
                            <p
                              style={{ fontSize: "15px" }}
                              className="text-center text-info"
                            >
                              {language === "mal"
                                ? "ഇനങ്ങൾ ഒന്നും ഇല്ല"
                                : "No Items Yet"}
                            </p>
                          )}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </div>
        </Container>
      </div>
    </>
  )
}

export default StudentVarisangyaDetails

StudentVarisangyaDetails.propTypes = {
  history: PropTypes.object,
}
