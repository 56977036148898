import React from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import propTypes from "prop-types"
import { Modal, Button } from "reactstrap"
// import moment from 'moment'

import { refreshFamilyMemberVarisangya } from "store/actions"

const UiModalRefreshFamilyMemberVarisangya = ({ show, onCloseclick, data }) => {
  const dispatch = useDispatch()
  // const params = useParams()
  const varisangyaId = data

  const { loading, language } = useSelector(state => ({
    loading: state.FamilyMemberVarisangyas.refreshLoading,
    language: state.Layout.language,
  }))

  // const today = moment(new Date()).format('yyyy-MM-DD')

  const handleValidSubmit = () => {
    console.log("varisangyaId: ", varisangyaId)
    dispatch(refreshFamilyMemberVarisangya(varisangyaId))
    if (!loading) onCloseclick()
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0 fw-bold">
              {language === "mal"
                ? "വരിസംഗ്യ ഫാമിലി ലിസ്റ്റ് പുതുക്കുക "
                : "Refresh Varisangya Family List"}{" "}
              <br />
              <hr />
              <p style={{ fontSize: "14px" }} className="fw-normal">
                {language === "mal"
                  ? "താഴെയുള്ള പുതുക്കുക ബട്ടണിൽ ക്ലിക്ക് ചെയ്യുന്നതിലൂടെ, ഈ വരിസംഗ്യ കാലയളവിലേക്ക് നിങ്ങൾ പുതിയ കുടുംബങ്ങളെ അപ്‌ഡേറ്റ് ചെയ്യപ്പെടുന്നതാണ്."
                  : "By clicking the below Refresh Button, you will update new families in to this varisangya period."}
              </p>
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row w-100 justify-content-end">
              <Button
                color="success"
                className="w-25"
                onClick={() => handleValidSubmit()}
              >
                {loading && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </>
                )}{" "}
                {language === "mal" ? "പുതുക്കുക" : "Refresh"}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalRefreshFamilyMemberVarisangya
UiModalRefreshFamilyMemberVarisangya.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.any,
}
