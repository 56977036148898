import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
//componets
import moment from "moment"
import PropTypes from "prop-types"

//css
import "assets/scss/datatables.scss"
import UiModalCreateFamilyVarisangya from "./UiModalCreateFamilyVarisangya"
import UiModalUpdateFamilyVarisangya from "./UiModalUpdateFamilyVarisangya"
import UiModalAutoGenerate from "./UiModalAutoGenerate"

import MyPagination from "components/Common/MyPagination"
import {
  getFamilyVarisangyas,
  //  deleteFamilyVarisangya
} from "store/actions"

const AllFamilyVarisangyas = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(12)

  const { familyVarisangyas, loading, language, autoGenerateLoading } =
    useSelector(state => ({
      familyVarisangyas: state.FamilyVarisangyas.familyVarisangyas,
      loading: state.FamilyVarisangyas?.loading,
      language: state.Layout.language,
      autoGenerateLoading: state.FamilyVarisangyas?.autoGenerateLoading,
    }))

  //pages
  const totalPages = Math.ceil(familyVarisangyas?.count / limit)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getFamilyVarisangyas(searchText, pageSend(), limit))
  }, [dispatch, page, searchText])

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "title",
      text: language === "mal" ? "ടൈറ്റിൽ" : "Title",
    },
    {
      dataField: "date",
      text: language === "mal" ? "തീയതി" : "Date",
    },
    {
      dataField: "total",
      text: language === "mal" ? "ആകെ തുക" : "Total",
    },
    // {
    //   dataField: "total_varisankya_amount",
    //   text: language === 'mal' ? "തുക" : "Total Amount",
    // },
    {
      dataField: "pending",
      text: language === "mal" ? "ശേഷിക്കുന്ന തുക" : "Pending",
    },
    // {
    //   dataField: "pending_amount",
    //   text: language === 'mal' ? "ശേഷിക്കുന്നവ  തുക" : "Pending Amount",
    // },
    {
      dataField: "status",
      text: language === "mal" ? "സ്റ്റാറ്റസ്" : "Status",
    },
    // {
    //   dataField: "to_date",
    //   text: language === 'mal' ? "വർഷം" : "To",
    // },
    // {
    //   dataField: "amount",
    //   text: language === 'mal' ? "തുക" : "Amount",
    // },
    {
      dataField: "action",
      text: "",
    },
  ]

  // const handleDeleteVarisangya = id => {
  //   dispatch(deleteFamilyVarisangya(id, history))
  // }

  const familyVarisangyasData = map(
    familyVarisangyas?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,
      date: item?.date && moment(item?.date).format("DD/MM/yyyy"),
      total: `${item?.total_varisankya_amount} (${item?.total_count})`,
      pending_amount: Number(
        item?.total_varisankya_amount - item?.recieved_varisankya_amount
      ).toFixed(2),
      pending: `${Number(
        item?.total_varisankya_amount - item?.recieved_varisankya_amount
      ).toFixed(2)} (${item?.pending_count})`,
      is_active: (
        <>
          {
            item?.is_active ? "Active" : "Inactive"
            // <div className="form-check form-switch form-switch-md mb-3">
            //   <input
            //     type="checkbox"
            //     className="form-check-input"
            //     id="customSwitchsizemd"
            //     // onChange={(e) => {
            //     //   handleSwitchChange(e, item?.id)
            //     // }}
            //     checked={item?.is_active}
            //   />
            // </div>
          }
        </>
      ),
      status: (
        <Badge
          className={
            "font-size-12 badge-soft-" +
            `${item?.is_active ? "success" : "danger"}`
          }
          color={"primary"}
          pill
        >
          {item?.is_active ? "Active" : "Inactive"}
        </Badge>
      ),
      action: (
        <>
          {item?.is_active ? (
            <div>
              <Link to={`/family/varisangyas/${item?.id}`}>
                <Button
                  type="button"
                  color="success"
                  className=" btn-sm mx-2"
                  style={{ width: "80px" }}
                >
                  {language === "mal" ? "കാണുക" : "View"}
                </Button>
              </Link>
            </div>
          ) : (
            // <div>
            //   <Button
            //     onClick={() => handleDeleteVarisangya(item?.id)}
            //     type="button"
            //     color="danger"
            //     className=" btn-sm mx-2"
            //     style={{ width: "80px" }}
            //   >
            //     {language === "mal" ? "ഡിലീറ്റ്" : "Delete"}
            //   </Button>
            // </div>
            <div>
              <Button
                onClick={() => updateVarisangyaData(item)}
                type="button"
                color="primary"
                className=" btn-sm mx-2"
                style={{ width: "80px" }}
              >
                {language === "mal" ? "എഡിറ്റ് " : "Edit"}
              </Button>
            </div>
          )}
        </>
      ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceVarisangyaSearch = debounce(value => setSearchText(value), 600)

  const [modalVarisangya, setModalVarisangya] = useState(false)
  const [modalUpdateVarisangya, setModalUpdateVarisangya] = useState(false)
  const [modalAutoGenerate, setModalAutoGenerate] = useState(false)
  const [onUpdateModalVarisangya, setOnUpdateModalVarisangya] = useState({})

  const updateVarisangyaData = item => {
    setModalUpdateVarisangya(true)
    setOnUpdateModalVarisangya(item)
  }
  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  // const handleAutoGenerate = () => {
  //   console.log('generate action')
  // }

  return (
    <React.Fragment>
      <UiModalCreateFamilyVarisangya
        show={modalVarisangya}
        onCloseclick={() => setModalVarisangya(false)}
      />
      <UiModalUpdateFamilyVarisangya
        show={modalUpdateVarisangya}
        onCloseclick={() => setModalUpdateVarisangya(false)}
        data={onUpdateModalVarisangya}
      />
      <UiModalAutoGenerate
        show={modalAutoGenerate}
        onCloseclick={() => setModalAutoGenerate(false)}
      />

      <Row>
        {/* {cardData?.map((item, key) => (
          <Col lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink && item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue > 0 && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p>
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))} */}
        <Col sm="12" md="12" lg="12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={familyVarisangyasData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceVarisangyaSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <div className="col-md-8 d-flex justify-contents-space-between">
                        <Button
                          onClick={() => setModalAutoGenerate(true)}
                          style={{
                            width: language === "mal" ? "140px" : "150px",
                          }}
                          className="w-md btn btn-info m-auto me-0 d-block"
                        >
                          <i className={"bx bx-transfer-alt mx-1"}></i>
                          {language === "mal"
                            ? "ഓട്ടോ-ജനറേറ്റ്"
                            : "Auto Generate"}
                        </Button>
                        <Button
                          onClick={() => setModalVarisangya(true)}
                          style={{
                            width: language === "mal" ? "90px" : "120px",
                          }}
                          className="w-md btn btn-success m-auto me-0 d-block"
                        >
                          {language === "mal" ? "+ വരിസംഖ്യ " : "+ Varisangya"}
                        </Button>
                      </div>
                      {/* <div className="col-md-4">
                        <Button
                          onClick={() => setModalVarisangya(true)}
                          style={{ width: "120px" }}
                          className="w-md btn btn-success m-auto me-0 d-block"
                        >
                          {language === 'mal' ? "+ വരിസംഖ്യ " : "+ Varisangya"}
                        </Button>
                      </div> */}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : autoGenerateLoading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {familyVarisangyas &&
                      familyVarisangyas?.results?.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          {language === "mal"
                            ? "വരിസംഖ്യകൾ ഒന്നും ഇല്ല"
                            : "No Varisangyas Yet"}
                        </p>
                      )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllFamilyVarisangyas

AllFamilyVarisangyas.propTypes = {
  history: PropTypes.object,
}
