import React from "react"
import { Container } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useSelector } from "react-redux"

import SettingsIndex from "./SingleView/index"

import Breadcrumbs from "components/Common/Breadcrumb"

function Settings() {
  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  return (
    <>
      <MetaTags>
        <title>Preferences | Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ക്രമീകരണങ്ങൾ" : "Settings"}
          breadcrumbItem={language === "mal" ? "മുൻഗണനകൾ" : "Preferences"}
        />
        <Container fluid>
          <div className="container-fluid">
            <SettingsIndex />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Settings
