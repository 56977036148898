export const useFileSizes = bytes => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes == 0) return "0 Byte"
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

  const sizeInKb = Math.round(bytes / 1024)

  if (sizeInKb > 5120) {
    return {
      status: false,
      text: "The file size must be less than or equal to 5MB",
    }
  } else if (sizeInKb <= 5120) {
    return {
      size: Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i],
      sizeInKb: Math.round(bytes / 1024),
      text: "",
      status: true,
    }
  } else {
    return {
      status: false,
      text: "",
    }
  }
}
