import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"

import {
  updateZakatItem,
  // deleteZakatItem,
  getAllFamilyDropdown,
} from "store/actions"
// import { useFileSizes } from 'components/Common/useFileSize'

const UiModalZakatItemUpdate = ({ show, onCloseclick, history, data }) => {
  const dispatch = useDispatch()
  // const params = useParams()
  // const handleFileSizes = useFileSizes

  const { loading, language, zakatId } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    zakatId: state.Zakats.zakatDetail?.id,
    allFamilies: state.Families.allFamilies,
  }))

  const zakatItem = data

  // const [family, setFamily] = useState('')
  // const [familyId, setFamilyId] = useState('')
  const [searchText] = useState("")
  const [isGivenSwitch, setIsGivenSwitch] = useState(false)

  useEffect(() => {
    // setFamily(zakatItem?.house_name)
    // setFamilyId(zakatItem?.family)
    setIsGivenSwitch(zakatItem?.is_given)
  }, [zakatItem])

  useEffect(() => {
    dispatch(getAllFamilyDropdown(searchText, true))
  }, [searchText, true, dispatch])

  // function handlerFinalValue(event) {
  //   setFamilyId(event.value)
  //   setFamily(event.label)
  // }

  // const optionGroup = [
  //   {
  //     options: allFamilies?.map((results, index) => ({
  //       key: index,
  //       label: (
  //         <div>
  //           {results?.house_name}
  //           {results?.mahall_custom_id ? (
  //             <Badge className="bg-khaf-blue mx-2">
  //               {results?.mahall_custom_id}
  //             </Badge>
  //           ) : results?.family_head_name ? (
  //             <span className="text-khaf"> ({results?.family_head_name})</span>
  //           ) : (
  //             <Badge className="bg-khaf-blue mx-2">{results?.custom_id}</Badge>
  //           )}
  //         </div>
  //       ),
  //       value: results.id,
  //     })),
  //   },
  // ]

  // const handleEnters = textEntered => {
  //   setSearchText(textEntered)
  // }
  // const [customLoad, setCustomLoad] = useState(false)

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    // setCustomLoad(true)

    const updateZakatItemData = {
      family: zakatItem?.family,
      quantity: values.quantity,
      description: values.description,
      is_given: isGivenSwitch,
      zakat: zakatId,
    }
    // console.log('updateZakatItemData: ', updateZakatItemData);
    dispatch(updateZakatItem(updateZakatItemData, zakatItem?.id, history))
  }

  // const handleDeleteZakatItem = id => {
  //   dispatch(deleteZakatItem(id, zakatId))
  // }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "എൻട്രി അപ്ഡേറ്റ് ചെയ്യുക" : "Update Entry"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>
                    {language === "mal" ? "കുടുംബം" : "Family"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  {/* <Select
                    onInputChange={handleEnters}
                    placeholder={family}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Family"
                  /> */}
                  <AvField
                    name="family"
                    type="text"
                    value={zakatItem?.house_name}
                    readOnly
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col>
                  <Label>{language === "mal" ? "വിവരണം" : "Details"}</Label>
                  <AvField
                    name="description"
                    type="textarea"
                    value={zakatItem?.description}
                  />
                </Col>
              </div>

              <div className="row mb-4">
                <Col md="4">
                  <Label>
                    {language === "mal" ? "സകാത്ത് അളവ്" : "Zakat Quantity"}
                  </Label>
                  <AvField
                    name="quantity"
                    type="number"
                    value={zakatItem?.quantity}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col>
                  <Label for="basicpill-email-input4">
                    {language === "mal" ? "നൽകിയിട്ടുണ്ടോ?" : "Is Given?"}
                  </Label>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                      checked={isGivenSwitch ? true : false}
                      onChange={() => setIsGivenSwitch(!isGivenSwitch)}
                    />
                  </div>
                </Col>
              </div>

              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalZakatItemUpdate
UiModalZakatItemUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
  data: propTypes.object,
}
