import {
  GET_STUDENTS,
  UPDATE_STUDENT,
  CREATE_STUDENT,
  DELETE_STUDENT,
  GET_STUDENT_DETAIL,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAIL,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT_FAIL,
  GET_STUDENT_DETAIL_SUCCESS,
  GET_STUDENT_DETAIL_FAIL,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAIL,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAIL,
  STUDENTS_DROPDOWN,
  STUDENTS_DROPDOWN_SUCCESS,
  STUDENTS_DROPDOWN_FAIL,
  GET_STUDENT_ABSENT_DETAIL,
  GET_STUDENT_ABSENT_DETAIL_SUCCESS,
  GET_STUDENT_ABSENT_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  students: [],
  studentsList: [],
  studentDetail: {},
  studentAbsentDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdStudent: {},
}

const Students = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STUDENTS_DROPDOWN:
    case GET_STUDENTS:
    case UPDATE_STUDENT:
    case CREATE_STUDENT:
    case DELETE_STUDENT:
    case GET_STUDENT_ABSENT_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case GET_STUDENT_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload,
        loading: false,
      }
    case GET_STUDENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case STUDENTS_DROPDOWN_SUCCESS:
      return {
        ...state,
        studentsList: action.payload,
        loading: false,
      }
    case STUDENTS_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_STUDENT_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdStudent: action.payload,
        loading: false,
      }

    case CREATE_STUDENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STUDENT_DETAIL_SUCCESS:
      return {
        ...state,
        studentDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_STUDENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_STUDENT_ABSENT_DETAIL_SUCCESS:
      return {
        ...state,
        studentAbsentDetail: action.payload,
        loading: false,
      }

    case GET_STUDENT_ABSENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        studentDetail: action.payload,
        loading: false,
      }

    case UPDATE_STUDENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        loading: false,
      }

    case DELETE_STUDENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Students
