import {
  GET_GALLERIES,
  UPDATE_GALLERY,
  CREATE_GALLERY,
  DELETE_GALLERY,
  GET_GALLERY_DETAIL,
  GET_GALLERIES_SUCCESS,
  GET_GALLERIES_FAIL,
  CREATE_GALLERY_SUCCESS,
  CREATE_GALLERY_FAIL,
  GET_GALLERY_DETAIL_SUCCESS,
  GET_GALLERY_DETAIL_FAIL,
  UPDATE_GALLERY_SUCCESS,
  UPDATE_GALLERY_FAIL,
  DELETE_GALLERY_SUCCESS,
  DELETE_GALLERY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  galleries: [],
  galleryDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdGallery: {},
}

const Galleries = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GALLERIES:
    case UPDATE_GALLERY:
    case CREATE_GALLERY:
    case DELETE_GALLERY:
    case GET_GALLERY_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_GALLERIES_SUCCESS:
      return {
        ...state,
        galleries: action.payload,
        loading: false,
      }

    case GET_GALLERIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_GALLERY_SUCCESS:
      return {
        ...state,
        // galleries: action.payload,
        galleries: {
          ...state.galleries,
          results: [...state.galleries.results, action.payload],
        },
        createdGallery: action.payload,
        loading: false,
      }

    case CREATE_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_GALLERY_DETAIL_SUCCESS:
      return {
        ...state,
        galleryDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_GALLERY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_GALLERY_SUCCESS:
      return {
        ...state,
        galleryDetail: action.payload,
        galleries: {
          results: state.galleries.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case DELETE_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Galleries
