import React from "react"
import { Container, Row } from "reactstrap"

import { useLanguage } from "../Language/SwitchLanguage"

import CardBox from "./card-box"

function MiniCard() {
  const text = useLanguage()
  const tags = [
    {
      title: text?.multiLanguage,
      description: text?.descriptionForMultiLang,
      color: "warning",
      icon: "bx bx-comment-add",
    },
    {
      title: text?.optionalChoice,
      description: text?.descriptionForOptionalChoice,
      color: "primary",
      icon: "mdi mdi-ethereum",
    },
    {
      title: text?.multipleUser,
      description: text?.descriptonForMultiUser,
      color: "success",
      icon: "bx bx-shield",
    },
  ]
  return (
    <Container className="d-flex miniCardSection align-items-end justify-content-center">
      <Row className="sm:mt-4 mt-5 mb-4 d-flex align-items-stretch justify-content-between md:justify-evenly w-100">
        <CardBox coins={tags} />
      </Row>
    </Container>
  )
}

export default MiniCard
