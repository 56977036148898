import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Navbar,
  Container,
  Collapse,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap"

//custom hooks
import SwitchLanguage, { useLanguage } from "../Language/SwitchLanguage"

import ScrollspyNav from "./scrollSpy"

//Import Images
import logodark from "assets/images/logo/logo.png"
import logolight from "assets/images/logo/logo_white.png"
import { useWindowSize } from "hooks/useWindowSize"

const Navbar_Page = props => {
  const history = useHistory()
  const [isOpenMenu, setisOpenMenu] = useState(false)

  const text = useLanguage()

  const navItems = navContent(history, text)

  let TargetId = navItems.map(item => {
    return item.idnm
  })

  const size = useWindowSize()

  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        id="navbar"
        fixed="top"
        style={{
          paddingBottom: isOpenMenu && "1rem",
        }}
        className={" navigation sticky " + props.navClass}
      >
        <Container>
          <NavbarBrand className="navbar-logo" href="/">
            {size.width < 992 ? (
              <>
                <img
                  src={logodark}
                  alt=""
                  height="40"
                  className="logo logo-dark"
                />
              </>
            ) : (
              <>
                {props.imglight !== true ? (
                  <img
                    src={logodark}
                    alt=""
                    height="40"
                    className="logo logo-dark"
                  />
                ) : (
                  <img
                    src={logolight}
                    alt=""
                    height="40"
                    className="logo logo-light"
                  />
                )}
              </>
            )}
          </NavbarBrand>
          {size?.width <= 768 ? (
            <Nav className="ms-auto navbar-nav" id="topnav-menu">
              {navItems
                ?.filter(i => i.idnm === "navbar")
                .map((item, key) => (
                  <NavItem
                    key={key}
                    className={item.navheading === "Home" ? "active" : ""}
                  >
                    <NavLink href={"#" + item.idnm}> {item.navheading}</NavLink>
                  </NavItem>
                ))}
            </Nav>
          ) : (
            ""
          )}

          <NavbarToggler
            className="mt-3 mb-3"
            onClick={() => {
              setisOpenMenu(!isOpenMenu)
            }}
          >
            <i className="fa fa-fw fa-bars" />
          </NavbarToggler>

          <Collapse id="topnav-menu-content" isOpen={isOpenMenu} navbar>
            <ScrollspyNav
              scrollTargetIds={TargetId}
              scrollDuration="800"
              headerBackground="true"
              activeNavClass="active"
              className="navbar-collapse"
            >
              <Nav className="ms-auto navbar-nav" id="topnav-menu">
                {navItems.map((item, key) => (
                  <NavItem
                    key={key}
                    className={item.navheading === "Home" ? "active" : ""}
                  >
                    <NavLink href={"#" + item.idnm}> {item.navheading}</NavLink>
                  </NavItem>
                ))}
              </Nav>
            </ScrollspyNav>
            <div className="ms-lg-2 mx-3 mt-3 mb-2">
              <Link to="/login" className="btn btn-success w-xs bg-khaf">
                {text?.signIn}
              </Link>
            </div>
            {size?.width <= 1000 ? (
              <>
                <a
                  href="tel:+91623566630"
                  title="Give us a call"
                  className="queries"
                >
                  <p className="font-popins text-sm text-dark mt-3">
                    <i
                      style={{ color: "#009846" }}
                      className="bx bx-phone-call ms-1 bx-fade-left"
                    />
                    +91 6235666630
                  </p>
                </a>
              </>
            ) : (
              <>
                {props.imglight !== true ? (
                  <a
                    href="tel:+91623566630"
                    title="Give us a call"
                    className="queries"
                  >
                    <p className="font-popins text-sm text-dark mt-3 mx-1 h5">
                      <i
                        style={{ color: "#009846" }}
                        className="bx bx-phone-call ms-1 bx-fade-left"
                      />
                      +91 6235666630
                    </p>
                  </a>
                ) : (
                  <a
                    href="tel:+91623566630"
                    title="Give us a call"
                    className="queries"
                  >
                    <p className="font-popins text-sm text-white h5 mt-3 mx-1">
                      <i className="bx bx-phone-call ms-1 bx-fade-left text-white" />
                      +91 6235666630
                    </p>
                  </a>
                )}
              </>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  )
}

Navbar_Page.propTypes = {
  imglight: PropTypes.any,
  navClass: PropTypes.string,
  isPrivacy: PropTypes.any,
}

export default Navbar_Page

function navContent(history, text) {
  // const handleClick = () => {
  //   history.push('/about')
  // }
  return [
    {
      id: 1,
      idnm: "navbar",
      navheading:
        SwitchLanguage() === "en" ? (
          <span onClick={() => history?.push("/home/ml")}>മലയാളം</span>
        ) : (
          <span onClick={() => history?.push("/home/en")}>English</span>
        ),
    },
    { id: 1, idnm: "home", navheading: text?.home },
    { id: 3, idnm: "features", navheading: text?.features },
    { id: 2, idnm: "about", navheading: text?.about },
    // { id: 3, idnm: "roadmap", navheading: "Roadmap" },
    // { id: 4, idnm: "team", navheading: "Team" },
    // { id: 5, idnm: "news", navheading: "News" },
    // { id: 6, idnm: "faqs", navheading: "FAQs" },
    { id: 1, idnm: "contact", navheading: text?.register },
  ]
}
