import {
  ADMIN_ALL_DONATIONS,
  ADMIN_ALL_DONATIONS_FAIL,
  ADMIN_ALL_DONATIONS_SUCCESS,
  ADMIN_DONATION_DETAILS,
  ADMIN_DONATION_DETAILS_FAIL,
  ADMIN_DONATION_DETAILS_SUCCESS,
  CREATE_TRANSACTIONS,
  CREATE_TRANSACTIONS_FAIL,
  CREATE_TRANSACTIONS_SUCCESS,
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTION_DETAILS_FAIL,
  GET_TRANSACTION_DETAILS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  transactions: {},
  transactionDetails: {},
  adminDonationList: [],
  adminDonationDetails: {},
  loading: false,
  error: {},
}
const Transactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_TRANSACTIONS:
      return {
        ...state,
        loading: true,
      }

    case CREATE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
      }

    case CREATE_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_TRANSACTION_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case GET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionDetails: action.payload,
      }
    case GET_TRANSACTION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // admin

    case ADMIN_ALL_DONATIONS:
      return {
        ...state,
        loading: true,
      }
    case ADMIN_ALL_DONATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminDonationList: action.payload,
      }

    case ADMIN_ALL_DONATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case ADMIN_DONATION_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case ADMIN_DONATION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminDonationDetails: action.payload,
      }

    case ADMIN_DONATION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
  }
  return state
}

export default Transactions
