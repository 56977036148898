import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import axios from "axios"
//actions
import { Image } from "react-bootstrap"

import { API_URL } from "helpers/api_methods"
import { createFeedSuccess, createFeedFail } from "store/actions"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import { useFileSizes } from "hooks/useFileSize"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const CreateFeed = ({ history }) => {
  const dispatch = useDispatch()
  const handleFileSizes = useFileSizes

  const { loading, language, mahallId } = useSelector(state => ({
    loading: state.Feeds.loading,
    error: state.Mahalls.error,
    language: state.Layout.language,
    mahallId: state.Users.userDetail?.mahall?.id,
  }))

  const [customLoad, setCustomLoad] = useState(false)
  console.log(customLoad)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })

  const [imagePreview, setImagePreview] = useState()

  const [filesSize, setFilesSize] = useState()

  const changeHandler = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    createNewFeed(
      selectedFile,
      values,
      mahallId,
      dispatch,
      history,
      setCustomLoad
    )
    setImagePreview()
  }

  return (
    <>
      <MetaTags>
        <title>Feeds | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഫീഡുകൾ" : "Feeds"}
          breadcrumbItem={
            language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create Feed"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col md="12" className="mb-3">
                          <Label>
                            {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField name="title" type="text" />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col md="12" className="mb-3">
                          <AvField
                            name="url"
                            type="text"
                            label={language === "mal" ? "യു ആർ എൽ" : "URL"}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col md="12">
                          <Label>
                            {language === "mal" ? "വിവരണം" : "Description"}
                          </Label>
                          <AvField name="description" type="textarea" />
                        </Col>
                      </div>

                      {/* <div className="row mb-4"> */}
                      <Col md="12">
                        <Label>{language === "mal" ? "ഫോട്ടോ" : "Image"}</Label>
                        <AvField
                          name="image"
                          type="file"
                          onChange={changeHandler}
                          // validate={{
                          //   required: { value: true },
                          // }}
                        />
                        {handleFileSizes(filesSize)?.text && (
                          <p className="text-danger mt-1">
                            {handleFileSizes(filesSize)?.text}
                          </p>
                        )}
                        {imagePreview && (
                          <Image
                            style={{ width: "40%", height: "50%" }}
                            className="my-2"
                            src={imagePreview.image}
                          />
                        )}
                      </Col>

                      {/* </div> */}

                      <div className="d-flex justify-content-end">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateFeed

CreateFeed.propTypes = {
  history: PropTypes.object,
}

function createNewFeed(selectedFile, values, mahallId, dispatch, history) {
  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append("image", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("mahall", mahallId)
  formData.append("title", values.title)
  formData.append("url", values.url)
  formData.append("description", values.description)

  const { role } = useGetRole()
  const { token } = useGetToken()

  axios
    .post(`${API_URL}/api/v1/feed/feed/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        dispatch(createFeedSuccess(res.data))
        history.push("/feeds")
        doneNotification("Created Successfully")
      } else {
        // createFeedFail(err)
        errorNotification()
      }
    })
    .catch(err => {
      createFeedFail(err)
      errorNotification()
    })
}
