import React, { useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  // Table,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
// import ToolkitProvider from 'react-bootstrap-table2-toolkit'
// import BootstrapTable from 'react-bootstrap-table-next'
// import { debounce, map, range } from 'lodash'
//actions institute
//componetns
// import moment from 'moment'
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Keyboard, Mousewheel } from "swiper"

import UiModalGallery from "./UiModalGallery"
import UiModalGalleryUpdate from "./UiModalGalleryUpdate"
// import UiModalCommittee from './UiModalCommittee'
// import UiModalInstituteCategory from './UiModalInstituteCategory'
// import UiModalInstituteCategoryUpdate from './UiModalInstituteCategoryUpdate'
// import UiModalLedger from './UiModalLedger'
import WelcomeComp from "./WelcomeComp"
// import UiModalClass from './UiModalClass'
// import UiModalStaff from './UiModalStaff'
// import UiModalStaffUpdate from './UiModalStaffUpdate'

import account from "assets/images/home/account.png"
import committee from "assets/images/home/committee.png"
import classImage from "assets/images/home/class.png"
import students from "assets/images/home/students.png"
import staffs from "assets/images/home/familymembers.png"
import assets from "assets/images/home/asset.png"
import DeleteModal from "components/Common/DeleteModal"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  deleteInstitute,
  getInstituteDetail,
  // getLedgers,
  // getCommittees,
  getGalleries,
  // getLedgerItemCategories,
  // getClasses,
  // getStaffs,
  getInstituteWallets,
} from "store/actions"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.scss"
import { useWindowSize } from "hooks/useWindowSize"
// import { handleFinancialYear } from 'pages/admin/Mahalls/financialYear'
// import MyPagination from 'components/Common/MyPagination'
import CardContact from "pages/admin/Dashboard/card-contact"
import UiModalInstituteWallet from "./UiModalAddWallet"
import CardWallet from "components/Common/card-wallet"
import UiModalUpdateInstituteWallet from "./UiModalUpdateWallet"
import { map, range } from "lodash"
import MyPagination from "components/Common/MyPagination"

SwiperCore.use([Navigation, Keyboard, Mousewheel])

const MadrasaDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { width } = useWindowSize()

  const {
    instituteDetail,
    loading,
    // userDetail,
    language,
    // ledgers,
    // total_income,
    // total_expense,
    // total_balance,
    // committees,
    galleries,
    // ledgerItemCategories,
    // classes,
    // staffs,
    instituteWallets,
  } = useSelector(state => ({
    loading: state.Institutes.loading,
    userDetail: state.Users.userDetail,
    instituteDetail: state.Institutes.instituteDetail,
    language: state.Layout.language,
    ledgers: state.Ledgers?.ledgers?.results?.ledgers,
    total_income: state.Ledgers?.ledgers?.results?.total_income,
    total_expense: state.Ledgers?.ledgers?.results?.total_expense,
    total_balance: state.Ledgers?.ledgers?.results?.total_balance,
    galleryDetail: state.Galleries.galleryDetail,
    galleries: state.Galleries.galleries,
    committees: state.Committees.committees,
    ledgerItemCategories: state.LedgerItemCategories.ledgerItemCategories,
    classes: state.Classes.classes,
    staffs: state.Staffs.staffs,
    instituteWallets: state.InstituteWallets.instituteWallets,
  }))

  // const { finalToDate, fromDate } = handleFinancialYear(
  //   userDetail?.mahall?.financial_year_starting_month,
  // )

  const [isOpen, setIsOpen] = useState(false)
  const [modalGallery, setModalGallery] = useState(false)
  const [modalGalleryUpdate, setModalGalleryUpdate] = useState(false)
  const [onUpdateGallery, setOnUpdateGallery] = useState({})
  const [modalWallet, setModalWallet] = useState(false)
  const [updateModalWallet, setUpdateModalWallet] = useState(false)
  const [onUpdateWalletData, setOnUpdateWalletData] = useState({})

  //wallet
  const [pageWallet, setPageWallet] = useState(1)
  const walletTotalPages = Math.ceil(instituteWallets?.count / 10)
  const walletPages = range(1, walletTotalPages + 1)

  const updateWalletData = item => {
    setUpdateModalWallet(true)
    setOnUpdateWalletData(item)
  }

  // const [modalLedger, setModalLedger] = useState(false)
  // const [modalCommittee, setModalCommittee] = useState(false)
  // const [modalInstituteCategory, setModalInstituteCategory] = useState(false)
  // const [modalInstituteCategoryUpdate, setModalInstituteCategoryUpdate] =
  //   useState(false)
  // const [onUpdateInstituteCategory, setOnUpdateInstituteCategory] = useState({})

  // const [modalMadrasaClass, setModalMadrasaClass] = useState(false)
  // const [modalStaff, setModalStaff] = useState(false)

  const handleDeleteEvent = () => {
    dispatch(deleteInstitute(instituteDetail.id, history))
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getInstituteWallets(instituteDetail.id, "", pageWallet))
  }, [dispatch, instituteDetail, pageWallet])

  // useEffect(() => {
  //   dispatch(getClasses('', 1, instituteDetail?.id))
  // }, [dispatch, instituteDetail?.id])

  useEffect(() => {
    dispatch(getInstituteDetail(params.id))
  }, [])

  // useEffect(() => {
  //   if (instituteDetail?.id) {
  //     dispatch(getLedgers(instituteDetail?.id))
  //   }
  // }, [instituteDetail])

  // useEffect(() => {
  //   if (instituteDetail?.id) {
  //     dispatch(getCommittees(instituteDetail?.id))
  //   }
  // }, [instituteDetail])

  useEffect(() => {
    if (instituteDetail?.id) {
      dispatch(getGalleries(instituteDetail?.id))
    }
  }, [instituteDetail])

  // useEffect(() => {
  //   if (instituteDetail?.id) {
  //     dispatch(getLedgerItemCategories(instituteDetail?.id))
  //   }
  // }, [instituteDetail])

  const varisangyaData = [
    {
      title: `${language === "mal" ? "ലിസ്റ്റുകൾ" : "Lists"}`,
      routeLink: `/student/varisangya/lists?instituteId=${instituteDetail?.id}`,
    },
    {
      title: `${language === "mal" ? "ഇടപാടുകൾ" : "Transactions"}`,
      routeLink: `/student/fee/transactions?instituteId=${instituteDetail?.id}`,
    },
    {
      title: `${language === "mal" ? "വാലറ്റ് ഹിസ്റ്ററി" : "Wallet History"}`,
      routeLink: `/student/wallet/list?instituteId=${instituteDetail?.id}`,
    },
  ]

  const cardData = [
    {
      title: `${language === "mal" ? "യൂസറുകൾ" : "Users"}`,
      cardValue: `${instituteDetail?.total_institute_user ?? 0}`,
      iconClass: "user",
      routeLink: "/#!",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "ലെഡ്ജറുകൾ" : "Ledgers"}`,
      cardValue: `${instituteDetail?.total_institute_accounts ?? 0}`,
      iconClass: "globe",
      routeLink: "/#!",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Ledgers"}`,
    },
    {
      title: `${language === "mal" ? "കമ്മിറ്റികൾ" : "Committees"}`,
      cardValue: `${instituteDetail?.total_institute_committees ?? 0}`,
      iconClass: "chat",
      routeLink: "/#!",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Committees"}`,
    },
    {
      title: `${
        language === "mal" ? "കമ്മിറ്റി അംഗങ്ങൾ" : "Committee Members"
      }`,
      cardValue: `${instituteDetail?.total_institute_committee_members ?? 0}`,
      iconClass: "group",
      routeLink: "/#!",
      loading: "",
      alt: `${language === "mal" ? "ഇല്ല" : "No Committee Members"}`,
    },
    // {
    //   title: `${language === 'mal' ? 'യോഗങ്ങൾ' : 'Meetings'}`,
    //   cardValue: `${instituteDetail?.total_institute_committee_meetings ?? 0}`,
    //   iconClass: 'chat',
    //   routeLink: '/#!',
    //   loading: '',
    //   alt: `${language === 'mal' ? 'ഇല്ല' : 'No Meetings'}`,
    // },
  ]

  const updateGalleryModal = item => {
    setOnUpdateGallery(item)
    setModalGalleryUpdate(true)
  }

  // const updateInstituteCategoryModal = item => {
  //   setOnUpdateInstituteCategory(item)
  //   setModalInstituteCategoryUpdate(true)
  // }

  const handleWindowSizeVsSliderPreview = () => {
    if (width <= 480) {
      return 1
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 3.5
    } else return 3.5
  }

  // const [searchTextStaff, setSearchTextStaff] = useState('')
  // const [staffPage, setStaffPage] = useState(1)

  // const totalStaffPages = Math.ceil(staffs?.count / 10)
  // const staffPages = range(1, totalStaffPages + 1)

  // const staffPageSend = () => {
  //   if (staffPage >= staffPages.length) {
  //     return staffPages.length
  //   }
  //   if (staffPage < 1) {
  //     return 1
  //   } else {
  //     return staffPage
  //   }
  // }

  // useEffect(() => {
  //   dispatch(getStaffs(searchTextStaff, staffPageSend(), instituteDetail?.id))
  // }, [dispatch, instituteDetail?.id, searchTextStaff, staffPage])

  // const [modalStaffUpdate, setModalStaffUpdate] = useState(false)
  // const [onUpdateStaff, setOnUpdateStaff] = useState()

  // const updateStaffModal = item => {
  //   setOnUpdateStaff(item)
  //   setModalStaffUpdate(true)
  // }

  // const debounceStaffSearch = debounce(value => setSearchTextStaff(value), 600)

  // const staffColumns = [
  //   {
  //     dataField: 'no',
  //     text: `${language === 'mal' ? 'നം.' : 'No.'}`,
  //     sort: true,
  //   },
  //   {
  //     dataField: 'name',
  //     text: `${language === 'mal' ? 'പേര്' : 'Name'}`,
  //     sort: true,
  //   },
  //   {
  //     dataField: 'phone',
  //     text: `${language === 'mal' ? 'ഫോൺ' : 'Phone'}`,
  //   },
  //   {
  //     dataField: 'dob',
  //     text: `${language === 'mal' ? 'ഡി ഒ ബി' : 'DOB'}`,
  //   },
  //   {
  //     dataField: 'salary',
  //     text: `${language === 'mal' ? 'ശമ്പളം' : 'Salary'}`,
  //   },
  //   {
  //     dataField: 'action',
  //     text: `${language === 'mal' ? 'പ്രവർത്തനങ്ങൾ' : 'Actions'}`,
  //   },
  // ]

  // const staffsData = map(staffs?.results, (item, index) => ({
  //   ...item,
  //   key: index,
  //   no: index + 1,
  //   dob: moment(item?.dob).format('DD/MM/YYYY'),
  //   action: (
  //     <div className="m-0">
  //       <Button
  //         type="button"
  //         color="primary"
  //         className=" btn w-xs btn-sm"
  //         onClick={() => updateStaffModal(item)}
  //       >
  //         Edit
  //       </Button>
  //     </div>
  //   ),
  // }))

  // const defaultSortedStaff = [
  //   {
  //     dataField: 'id',
  //     order: 'asc',
  //   },
  // ]

  // Select All Button operation
  // const selectRowStaff = {
  //   mode: 'checkbox',
  // }

  return (
    <>
      {/* <UiModalInstituteCategoryUpdate
        show={modalInstituteCategoryUpdate}
        onCloseclick={() => setModalInstituteCategoryUpdate(false)}
        data={onUpdateInstituteCategory}
        history={history}
      /> */}
      {/* <UiModalInstituteCategory
        show={modalInstituteCategory}
        onCloseclick={() => setModalInstituteCategory(false)}
        history={history}
      /> */}
      {/* <UiModalCommittee
        show={modalCommittee}
        onCloseclick={() => setModalCommittee(false)}
        history={history}
      /> */}
      {/* <UiModalLedger
        show={modalLedger}
        onCloseclick={() => setModalLedger(false)}
        history={history}
      /> */}
      <UiModalGalleryUpdate
        show={modalGalleryUpdate}
        onCloseclick={() => setModalGalleryUpdate(false)}
        data={onUpdateGallery}
        history={history}
      />
      <UiModalGallery
        show={modalGallery}
        onCloseclick={() => setModalGallery(false)}
        history={history}
      />
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <UiModalInstituteWallet
        show={modalWallet}
        onCloseclick={() => setModalWallet(false)}
      />
      <UiModalUpdateInstituteWallet
        show={updateModalWallet}
        onCloseclick={() => setUpdateModalWallet(false)}
        data={onUpdateWalletData}
      />
      {/* <UiModalClass
        show={modalMadrasaClass}
        onCloseclick={() => setModalMadrasaClass(false)}
        history={history}
      /> */}
      {/* <UiModalStaff
        show={modalStaff}
        onCloseclick={() => setModalStaff(false)}
        history={history}
      /> */}
      {/* <UiModalStaffUpdate
        show={modalStaffUpdate}
        onCloseclick={() => setModalStaffUpdate(false)}
        data={onUpdateStaff}
        history={history}
      /> */}

      <MetaTags>
        <title>Madrasa | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "മദ്രസ വിവരങ്ങൾ" : "Madrasa Details"}
          breadcrumbItem={language === "mal" ? "മദ്രസ" : "Madrasa"}
        />
        <Container fluid>
          <div className="container-fluid">
            {loading ? (
              <Spinner color="secondary" className="d-block m-auto" />
            ) : (
              <Row>
                <Col lg="12" className="">
                  <Row>
                    <Col lg="12">
                      <WelcomeComp />
                    </Col>
                    <Col lg="12">
                      <Row>
                        {cardData?.map((item, key) => (
                          <Col
                            sm="12"
                            md="3"
                            lg="3"
                            key={key}
                            className="sm:p-0 mt-0"
                          >
                            <Card className="blog-stats-wid">
                              <CardBody className="mhluser-cardbody">
                                <Link to={item.routeLink && item.routeLink}>
                                  <div className="d-flex flex-wrap">
                                    <div className="avatar-sm mt-1">
                                      <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                                        <i
                                          className={
                                            "bx bx-" + `${item.iconClass}`
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                    <div className="mx-4 pt-2">
                                      <p className="text-muted mb-1">
                                        {item.title}
                                      </p>
                                      {item.cardValue && (
                                        <h5 className="mb-0">
                                          {item.cardValue}
                                        </h5>
                                      )}
                                      <p className="m-0 text-info">
                                        {item.cardValue !== "" ? (
                                          <></>
                                        ) : (
                                          item.alt
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <h5>{language === "mal" ? "മദ്രസ ഫീസ്" : "Madrasa Fee"}</h5>
                    <Row className="mt-1">
                      {varisangyaData?.map((item, key) => (
                        <Col
                          sm="12"
                          md="3"
                          lg="3"
                          key={key}
                          className="sm:p-0 mt-0"
                        >
                          <Card className="blog-stats-wid bg-info">
                            <CardBody className="mhluser-cardbody">
                              <Link to={item.routeLink && item.routeLink}>
                                {/* <div className="d-flex flex-wrap"> */}
                                {/* <div className="avatar-sm mt-1">
                                    <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                                      <i
                                        className={
                                          'bx bx-' + `${item.iconClass}`
                                        }
                                      ></i>
                                    </div>
                                  </div> */}
                                <div className="mx-4 pt-2">
                                  <h4 className="text-white mb-1 text-center">
                                    {item.title}
                                  </h4>
                                  {/* {item.cardValue && (
                                      <h5 className="mb-0">{item.cardValue}</h5>
                                    )}
                                    <p className="m-0 text-info">
                                      {item.cardValue !== '' ? <></> : item.alt}
                                    </p> */}
                                  {/* </div> */}
                                </div>
                              </Link>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Row>
                  <Row>
                    <div className="d-flex mb-1 mt-3">
                      <h5>{language === "mal" ? "ഗാലറി" : "Gallery"}</h5>
                      <div>
                        <Button
                          onClick={() => setModalGallery(true)}
                          className="btn btn-success btn-sm mx-3"
                        >
                          {language === "mal" ? "+ചേർക്കുക" : "+Add"}
                        </Button>
                      </div>
                    </div>
                    {galleries && galleries?.results <= 0 ? (
                      <p className="text-primary fs-5">
                        {language === "mal"
                          ? "ഫോട്ടോസ് ഒന്നും ഇല്ല "
                          : "No Images Yet"}
                      </p>
                    ) : (
                      <div className="carausel-4-columns-cover card-product-small arrow-center position-relative mt-2">
                        <Swiper
                          spaceBetween={10}
                          slidesPerView={handleWindowSizeVsSliderPreview()}
                          grid={{
                            rows: 2,
                          }}
                          keyboard={{ enabled: true }}
                          direction="horizontal"
                          mousewheel={true}
                          navigation={{
                            prevEl: ".custom_prev_f",
                            nextEl: ".custom_next_f",
                          }}
                          autoplay={true}
                          speed={600}
                          className=""
                          breakpoints={{
                            480: {
                              slidesPerView: 1,
                            },
                            640: {
                              slidesPerView: 2.5,
                            },
                            768: {
                              slidesPerView: 3,
                            },
                            1024: {
                              slidesPerView: 4,
                            },
                          }}
                        >
                          {galleries?.results?.map((item, i) => (
                            <SwiperSlide key={i}>
                              <div>
                                <img
                                  src={item?.image}
                                  alt=""
                                  width={"100%"}
                                  height={"200px"}
                                  style={{
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => updateGalleryModal(item)}
                                />
                                <p className="m-0 py-2">{item?.title}</p>
                              </div>
                            </SwiperSlide>
                          ))}
                          <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow">
                            <span className="slider-btn slider-prev slick-arrow custom_prev_f ">
                              <i className="bx bx-left-arrow-alt"></i>
                            </span>
                            <span className="slider-btn slider-next slick-arrow custom_next_f">
                              <i className="bx bx-right-arrow-alt"></i>
                            </span>
                          </div>
                        </Swiper>
                      </div>
                      // <Swiper
                      //   navigation={true}
                      //   modules={[Navigation]}
                      //   slidesPerView={handleImageSize()}
                      //   grid={{
                      //     rows: 1,
                      //   }}
                      //   className="mySwiper"
                      // >
                      //   {galleries?.results?.map((item, key) => (
                      //     <SwiperSlide key={key} className="">
                      //       <div
                      //         className="mt-5"
                      //         style={{ minHeight: "280px" }}
                      //       >
                      //         <img
                      //           onClick={() => updateGalleryModal(item)}
                      //           className="gallery-img"
                      //           src={item?.image ? item?.image : image}
                      //           alt={image}
                      //         />
                      //         <h5 className="text-center text-muted">
                      //           {item?.title}
                      //         </h5>
                      //       </div>
                      //     </SwiperSlide>
                      //   ))}
                      // </Swiper>
                    )}
                  </Row>
                  <Row className="mt-3">
                    <div className="d-flex mb-3">
                      <h5>{language === "mal" ? "വാലറ്റുകൾ" : "Wallets"}</h5>
                      <Button
                        className="btn-sm mx-2"
                        color="success"
                        onClick={() => setModalWallet(true)}
                      >
                        {language === "mal" ? "+ ചേർക്കുക" : "+ Add"}
                      </Button>
                    </div>
                    <div className="row">
                      {map(instituteWallets?.results, (wallet, key) => (
                        <CardWallet
                          walletData={wallet}
                          updateWalletModal={updateWalletData}
                          key={key}
                        />
                      ))}
                      <MyPagination
                        totalPages={walletPages}
                        page={pageWallet}
                        setPage={setPageWallet}
                        title="wlt"
                      />
                    </div>
                  </Row>
                  <Row className="mt-5">
                    <CardContact
                      image={account}
                      title={`${
                        language === "mal" ? "അക്കൗണ്ടുകൾ" : "Accounts"
                      }`}
                      link={`/account/madrasa?madrasaid=${instituteDetail?.id}`}
                    />
                    <CardContact
                      image={committee}
                      title={`${
                        language === "mal" ? "കമ്മിറ്റികൾ" : "Committees"
                      }`}
                      link={`/committee/madrasa?madrasaid=${instituteDetail?.id}`}
                    />
                    <CardContact
                      image={classImage}
                      title={`${language === "mal" ? "ക്ലാസുകൾ" : "Classes"}`}
                      link={`/classes?madrasaid=${instituteDetail?.id}`}
                    />
                    <CardContact
                      image={students}
                      title={`${
                        language === "mal" ? "വിദ്യാർത്ഥികൾ" : "Students"
                      }`}
                      link={`/students?madrasaid=${instituteDetail?.id}`}
                    />
                    <CardContact
                      image={staffs}
                      title={`${language === "mal" ? "സ്റ്റാഫുകൾ" : "Staffs"}`}
                      link={`/staffs?madrasaid=${instituteDetail?.id}`}
                    />
                    <CardContact
                      image={assets}
                      title={`${language === "mal" ? "ആസ്തികൾ" : "Assets"}`}
                      link={`/mahall/madrasa/asset/list?madrasaid=${instituteDetail?.id}`}
                    />
                  </Row>
                  {/* <Row className="mt-5">
                    <Col md="12" lg="6">
                      <h5>{language === 'mal' ? 'അക്കൗണ്ട്' : 'Account'}</h5>
                      <Card>
                        <CardBody>
                          <h6 className="text-black ">
                            {language === 'mal' ? 'സമ്മറി :' : 'Summary :'}
                          </h6>
                          <p
                            className="text-center pb-2"
                            style={{
                              fontSize: '15px',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <span>
                              {language === 'mal'
                                ? 'സാമ്പത്തിക വർഷം :'
                                : 'Financial Year :'}
                            </span>{' '}
                            <span className="text-primary">{`${
                              fromDate ? fromDate : ''
                            }${' '} to ${' '}${
                              finalToDate ? finalToDate : ''
                            }`}</span>
                          </p>
                          <Container fluid>
                            <div>
                              <Row className="m-3">
                                <Col md="4">
                                  <Card style={{ backgroundColor: '#e8fdfe' }}>
                                    <p
                                      className="p-3 m-0 justify-content-center"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: '700',
                                        color: '#15a252',
                                      }}
                                    >
                                      {language === 'mal' ? 'വരവ്' : 'Income'} :{' '}
                                      {total_income}
                                    </p>
                                  </Card>
                                </Col>
                                <Col md="4">
                                  <Card style={{ backgroundColor: '#fef4f0' }}>
                                    <p
                                      className="p-3 m-0 justify-content-center"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: '700',
                                        color: '#ff4d45',
                                      }}
                                    >
                                      {language === 'mal' ? 'ചിലവ്' : 'Expense'}{' '}
                                      :{total_expense}
                                    </p>
                                  </Card>
                                </Col>
                                <Col md="4">
                                  <Card style={{ backgroundColor: '#eef9f4' }}>
                                    <p
                                      className="p-3 m-0 justify-content-center"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: '700',
                                        color: 'blue',
                                      }}
                                    >
                                      {language === 'mal'
                                        ? 'ബാക്കി'
                                        : 'Balance'}{' '}
                                      : {total_balance}
                                    </p>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          </Container>
                          <h6 className="text-black mt-4">
                            {language === 'mal'
                              ? 'എല്ലാ ലെഡ്ജറുകളും :'
                              : 'All Ledgers :'}
                          </h6>
                          <Container fluid>
                            <Row>
                              {ledgers &&
                                ledgers.length > 0 &&
                                ledgers?.map((item, key) => (
                                  <Col
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    key={key}
                                    className="sm:p-0 mt-1"
                                  >
                                    <Card
                                      className="blog-stats-wid"
                                      style={{
                                        backgroundColor: '#dce0e4',
                                        borderRadius: '10px',
                                        minHeight: '130px',
                                      }}
                                    >
                                      <Link to={`/madrasaledger/${item?.id}`}>
                                        <h5 className="px-3 pt-3 text-black">
                                          {item.institute_ledger_name}
                                        </h5>
                                        <Row>
                                          <Col md="4">
                                            <div className="mx-3">
                                              <p className="m-0 text-muted pb-1">
                                                {' '}
                                                {language === 'mal'
                                                  ? 'വരവ്'
                                                  : 'Income'}{' '}
                                              </p>

                                              <div
                                                className=" p-2 mb-3"
                                                style={{
                                                  borderRadius: '6px',
                                                  backgroundColor: '#e8fdfe',
                                                }}
                                              >
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: '#15a252',
                                                    fontSize: '15px',
                                                  }}
                                                >
                                                  {item.credit_amount
                                                    ? item.credit_amount
                                                    : 0}
                                                </p>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mx-3">
                                              <p className="m-0 text-muted pb-1">
                                                {' '}
                                                {language === 'mal'
                                                  ? 'ചിലവ്'
                                                  : 'Expense'}{' '}
                                              </p>

                                              <div
                                                className=" p-2"
                                                style={{
                                                  borderRadius: '6px',
                                                  backgroundColor: '#fef4f0',
                                                }}
                                              >
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: '#ff4d45',
                                                    fontSize: '15px',
                                                  }}
                                                >
                                                  {item.debit_amount
                                                    ? item.debit_amount
                                                    : 0}
                                                </p>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mx-3">
                                              <p className="m-0 text-muted pb-1">
                                                {' '}
                                                {language === 'mal'
                                                  ? 'ബാക്കി'
                                                  : 'Balance'}{' '}
                                              </p>

                                              <div
                                                className=" p-2"
                                                style={{
                                                  borderRadius: '6px',
                                                  backgroundColor: '#eef9f4',
                                                }}
                                              >
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: 'blue',
                                                    fontSize: '15px',
                                                  }}
                                                >
                                                  {item.balance
                                                    ? item.balance
                                                    : 0}
                                                </p>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Link>
                                    </Card>
                                  </Col>
                                ))}
                            </Row>
                            <Button
                              onClick={() => setModalLedger(true)}
                              className="bg-khaf w-100"
                            >
                              {language === 'mal'
                                ? '+ പുതിയത് ചേർക്കുക'
                                : '+ Add New Ledger'}
                            </Button>
                          </Container>
                          <h6 className="text-muted mt-4">
                            {language === 'mal'
                              ? 'മദ്രസ ക്യാറ്റഗറികൾ :'
                              : 'Madrasa Categories:'}
                          </h6>
                          <div className=" d-flex justify-content-end">
                            <Button
                              type="button"
                              color="success"
                              className="btn-sm"
                              onClick={() => setModalInstituteCategory(true)}
                            >
                              {language === 'mal'
                                ? '+ പുതിയത് ചേർക്കുക'
                                : '+ Add Category'}
                            </Button>
                          </div>
                          <Table className="mx-1 mt-1">
                            <thead>
                              <tr>
                                <th>
                                  {language === 'mal'
                                    ? 'ക്യാറ്റഗറിയുടെ പേര്'
                                    : 'Category Name'}
                                </th>
                                <th>
                                  {language === 'mal' ? 'ബാലൻസ്' : 'Balanace'}
                                </th>
                                <th>
                                  {language === 'mal'
                                    ? 'പ്രവർത്തനങ്ങൾ'
                                    : 'Actions'}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {ledgerItemCategories?.results &&
                                ledgerItemCategories?.results.length > 0 &&
                                ledgerItemCategories?.results.map(
                                  (item, key) => (
                                    <tr key={key}>
                                      <td>
                                        {
                                          item.institute_ledger_item_category_name
                                        }
                                      </td>
                                      <td>{item.balance}</td>
                                      <td>
                                        <Button
                                          onClick={() =>
                                            updateInstituteCategoryModal(item)
                                          }
                                          color="primary"
                                          className="btn-primary btn-sm"
                                        >
                                          {language === 'mal'
                                            ? 'തിരുത്തുക'
                                            : 'Edit'}
                                        </Button>
                                      </td>
                                    </tr>
                                  ),
                                )}
                            </tbody>
                          </Table>
                          {ledgerItemCategories?.results &&
                            ledgerItemCategories?.results.length <= 0 && (
                              <p className="text-center text-primary">
                                {language === 'mal'
                                  ? 'ക്യാറ്റഗറികൾ ഒന്നും ഇല്ല'
                                  : 'No Categories Yet'}
                              </p>
                            )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="12" lg="6">
                      <h5>{language === 'mal' ? 'കമ്മിറ്റി' : 'committee'}</h5>
                      <Card>
                        <CardBody>
                          <h6 className="text-black">
                            {language === 'mal'
                              ? 'എല്ലാ കമ്മിറ്റികളും :'
                              : 'All Committees :'}
                          </h6>
                          <Container fluid>
                            <Row>
                              {committees?.results &&
                                committees?.results?.committees?.length > 0 &&
                                committees?.results?.committees.map(
                                  (itm, key) => (
                                    <Col
                                      sm="12"
                                      md="12"
                                      lg="12"
                                      key={key}
                                      className="sm:p-0 mt-1"
                                    >
                                      <Card className="blog-stats-wid">
                                        <CardBody
                                          style={{
                                            backgroundColor: '#dcfbf0',
                                            borderRadius: '11px',
                                            minHeight: '130px',
                                          }}
                                        >
                                          <Link
                                            to={`/madrasacommittee/${itm?.id}`}
                                          >
                                            <h5 className="text-black">
                                              {itm.title}
                                            </h5>
                                            <Row>
                                              <Col md="4">
                                                <div className="mx-3">
                                                  <p className="m-0 text-muted pb-1 committe-p-main">
                                                    {' '}
                                                    {language === 'mal'
                                                      ? 'അവസാന യോഗം : '
                                                      : 'Last Meeting  '}{' '}
                                                  </p>

                                                  <div
                                                    className=" p-2 committe-p"
                                                    style={{
                                                      borderRadius: '6px',
                                                      backgroundColor: 'white',
                                                      minHeight: '35.5px',
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0 text-black"
                                                      style={{
                                                        fontSize: '15px',
                                                      }}
                                                    >
                                                      {itm.last_meeting_date &&
                                                        moment(
                                                          itm.last_meeting_date,
                                                        ).format('DD-MM-yyyy')}
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col md="4">
                                                <div className="mx-3">
                                                  <p className="m-0 text-muted pb-1">
                                                    {' '}
                                                    {language === 'mal'
                                                      ? 'അംഗങ്ങൾ'
                                                      : 'Members'}{' '}
                                                  </p>

                                                  <div
                                                    className=" p-2 "
                                                    style={{
                                                      borderRadius: '6px',
                                                      backgroundColor: 'white',
                                                      minHeight: '35.5px',
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0 text-black"
                                                      style={{
                                                        fontSize: '15px',
                                                      }}
                                                    >
                                                      {itm?.members_count
                                                        ? itm?.members_count
                                                        : 0}
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col md="4">
                                                <div className="mx-3">
                                                  <p className="m-0 text-muted pb-1">
                                                    {' '}
                                                    {language === 'mal'
                                                      ? 'യോഗങ്ങൾ'
                                                      : 'Meetings'}{' '}
                                                  </p>

                                                  <div
                                                    className=" p-2 "
                                                    style={{
                                                      borderRadius: '6px',
                                                      backgroundColor: 'white',
                                                      minHeight: '35.5px',
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0 text-black"
                                                      style={{
                                                        fontSize: '15px',
                                                      }}
                                                    >
                                                      {itm?.meetings_count
                                                        ? itm?.meetings_count
                                                        : 0}
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Link>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  ),
                                )}
                            </Row>
                            <Button
                              onClick={() => setModalCommittee(true)}
                              className={
                                committees?.results &&
                                committees?.results?.committees?.length <= 0
                                  ? 'bg-khaf w-100 mt-4'
                                  : 'bg-khaf w-100'
                              }
                            >
                              {language === 'mal'
                                ? '+ പുതിയത് ചേർക്കുക'
                                : '+ Add New Committee'}
                            </Button>
                          </Container>
                          <h6 className="text-muted mt-4">
                            {language === 'mal'
                              ? 'മദ്രസ ക്ലാസുകൾ :'
                              : 'Madrasa Classes:'}
                          </h6>
                          <div className=" d-flex justify-content-end">
                            <Button
                              type="button"
                              color="success"
                              className="btn-sm"
                              onClick={() => setModalMadrasaClass(true)}
                            >
                              {language === 'mal'
                                ? '+ പുതിയത് ചേർക്കുക'
                                : '+ Add Class'}
                            </Button>
                          </div>
                          <Table className="mx-1 mt-1">
                            <thead>
                              <tr>
                                <th>
                                  {language === 'mal' ? 'ക്ലാസ്' : 'Class Name'}
                                </th>
                                <th>
                                  {language === 'mal'
                                    ? 'പ്രവർത്തനങ്ങൾ'
                                    : 'Actions'}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {classes?.results &&
                                classes?.results.length > 0 &&
                                classes?.results.map((item, key) => (
                                  <tr key={key}>
                                    <td>{item?.class_name}</td>
                                    <td>
                                      <Link
                                        to={`/class/${item?.id}`}
                                        color="success"
                                        className="btn btn-success btn-sm"
                                      >
                                        {language === 'mal' ? 'കാണുക' : 'View'}
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          {classes?.results && classes?.results.length <= 0 && (
                            <p className="text-center text-primary">
                              {language === 'mal'
                                ? 'ക്ലാസുകൾ ഒന്നും ഇല്ല'
                                : 'No Classes Yet'}
                            </p>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <h5 className="pb-1">
                      {language === 'mal' ? 'ഡിവിഷനുകൾ :' : 'Staffs :'}
                    </h5>
                    <Col lg="12" className="">
                      <React.Fragment>
                        <Row style={{ display: 'flex' }}>
                          <Col className="col-lg-12 col-md-12">
                            <Card>
                              <CardBody>
                                <ToolkitProvider
                                  keyField="id"
                                  columns={staffColumns}
                                  data={staffsData}
                                  search
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row className="mb-2">
                                        <Col md="4">
                                          <div className="search-box me-2 mb-2 d-inline-block">
                                            <div className="position-relative">
                                              <form
                                                className="app-search d-lg-block"
                                                onChange={e =>
                                                  debounceStaffSearch(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <div className="position-relative">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={
                                                      language === 'mal'
                                                        ? 'തിരയുക...'
                                                        : 'Search...'
                                                    }
                                                    defaultValue={
                                                      searchTextStaff
                                                    }
                                                  />
                                                  <span className="bx bx-search-alt" />
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col md="8">
                                          <div className="d-flex justify-content-end">
                                            <Button
                                              type="button"
                                              color="primary"
                                              className="btn-sm mb-4"
                                              onClick={() =>
                                                setModalStaff(true)
                                              }
                                            >
                                              {language === 'mal'
                                                ? '+ സ്റ്റാഫിനെ ചേർക്കുക'
                                                : '+ Add Staff'}
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                      {loading ? (
                                        <Spinner
                                          color="secondary"
                                          className="d-block m-auto"
                                        />
                                      ) : (
                                        <>
                                          <Row>
                                            <Col xl="12">
                                              <div className="table-responsive">
                                                <BootstrapTable
                                                  keyField={'id'}
                                                  responsive
                                                  bordered={false}
                                                  striped={false}
                                                  defaultSorted={
                                                    defaultSortedStaff
                                                  }
                                                  selectRow={selectRowStaff}
                                                  classes={
                                                    'table align-middle table-nowrap table-hover'
                                                  }
                                                  headerWrapperClasses={
                                                    'thead-light'
                                                  }
                                                  {...toolkitProps.baseProps}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          <MyPagination
                                            totalPages={staffPages}
                                            page={staffPage}
                                            setPage={setStaffPage}
                                          />
                                        </>
                                      )}
                                      {staffs &&
                                        staffs?.results &&
                                        staffs?.results?.length <= 0 && (
                                          <p
                                            style={{ fontSize: '15px' }}
                                            className="text-center text-info"
                                          >
                                            {language === 'mal'
                                              ? 'സ്റ്റാഫുകൾ ഒന്നും ഇല്ല'
                                              : 'No Staffs Yet'}
                                          </p>
                                        )}
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </React.Fragment>
                    </Col>
                  </Row> */}
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default MadrasaDetails

MadrasaDetails.propTypes = {
  history: PropTypes.object,
}
