import React, { useEffect } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import MetaTags from "react-meta-tags"
//Import Images
import { useDispatch, useSelector } from "react-redux"
// import CardPricing from "./cardPricing"
import { Image } from "react-bootstrap"
// import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import img2 from "assets/images/khaf/khaf-pricing.png"
import { useWindowSize } from "hooks/useWindowSize"
import { patchMahallPremium, getUserDetail } from "store/actions"
const PagesPremium = () => {
  const dispatch = useDispatch()
  // const params = useParams()
  const size = useWindowSize()

  const { userDetail, patchMahall, language } = useSelector(state => ({
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
    patchMahall: state.Mahalls.patchMahall,
  }))

  useEffect(() => {
    dispatch(getUserDetail())
  }, [patchMahall])

  const onHandlePremiumRequest = () => {
    const premiumData = {
      is_premium_requested: true,
    }
    dispatch(patchMahallPremium(userDetail.mahall.id, premiumData))
  }

  // const pricings = [
  //   {
  //     id: 1,
  //     title: "Current Plan",
  //     description: "Khaf Mahall Surveyor",
  //     icon: "",
  //     price: "1999",
  //     offerPrice: "Free",
  //     duration: "Year",
  //     borderColor: "#f2f2f5",
  //     link: "",
  //     backgroundColor: "#FFFFFF",
  //     // #009846
  //     features: [
  //       { title: "Family Details" },
  //       { title: "Family Members" },
  //       { title: "Basic Features" },
  //       { title: "Free Setup" },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     title: "Premium",
  //     description: "Khaf Smart Mahall",
  //     icon: "bx-crown",
  //     price: "13999",
  //     offerPrice: "5999",
  //     duration: "Year",
  //     borderColor: "#009846",
  //     link: "",
  //     backgroundColor: "#009846",
  //     features: [
  //       { title: "All Basic Features" },
  //       { title: "Advanced Features" },
  //       { title: "Institutes" },
  //       { title: "Accounts" }, ,
  //       { title: "Committee Details" },
  //       { title: "Meetings" },
  //       { title: "Reports" },
  //       { title: "Certificates" },
  //     ],
  //   },
  // ]
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <MetaTags>
          <title>Premium | osperb </title>
        </MetaTags>
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center">
                <h4>
                  {language === "mal"
                    ? "നമ്മുടെ മഹല്ല് അപ്‌ഗ്രേഡ് ചെയ്യാം"
                    : "Lets Upgrade our mahall!"}
                </h4>
                <p className="text-muted mt-3">
                  {language === "mal"
                    ? `എല്ലാ പ്രൊഫഷണൽ ടൂളുകളും ഉപയോഗിച്ച് നമ്മുടെ മഹല്ലിനെ അടുത്ത ലെവലിലേക്ക് അപ്‌ഗ്രേഡ് ചെയ്യാം. 
                ചുവടെയുള്ള പ്ലാനുകളിൽ നിന്ന് അനുയോജ്യമായത് തിരഞ്ഞെടുക്കുക.`
                    : `along with all the professional tools in to the next level. 
                Choose the suitable one from the plans below.`}
                </p>
              </div>
            </Col>
          </Row>
          {size.width < 768 ? (
            <div className="mt-5">
              <Image
                src={img2}
                className=" my-auto mx-auto d-block"
                style={{
                  maxHeight: "70vh",
                  maxWidth: "90vw",
                  objectFit: "cover",
                  filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))",
                }}
              />
              <div className="d-flex justify-content-center">
                <Button
                  className={
                    userDetail?.mahall?.is_premium_requested
                      ? "outline btn-info mt-2 btn-lg"
                      : "btn btn-success mt-2 btn-lg"
                  }
                  disabled={userDetail?.mahall?.is_premium_requested}
                  onClick={onHandlePremiumRequest}
                >
                  {userDetail?.mahall?.is_premium_requested
                    ? "Already Requested! "
                    : "Pre Book Now!"}
                  <i
                    className={
                      userDetail?.mahall?.is_premium_requested
                        ? "mdi mdi-check-bold ms-1 bx-fade-right"
                        : ""
                    }
                  />
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ height: "80vh" }}>
              <Image
                src={img2}
                className=" my-auto mx-auto d-block"
                style={{
                  maxHeight: "70vh",
                  maxWidth: "90vw",
                  objectFit: "cover",
                  filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))",
                }}
              />
              <div className="d-flex justify-content-center">
                <Button
                  className={
                    userDetail?.mahall?.is_premium_requested
                      ? "outline btn-info mt-2 btn-lg"
                      : "btn btn-khaf mt-2 btn-lg"
                  }
                  disabled={userDetail?.mahall?.is_premium_requested}
                  onClick={onHandlePremiumRequest}
                >
                  {userDetail?.mahall?.is_premium_requested
                    ? "Already Requested! "
                    : "Pre Book Now!"}
                  <i
                    className={
                      userDetail?.mahall?.is_premium_requested
                        ? "mdi mdi-check-bold ms-1 bx-fade-right"
                        : ""
                    }
                  />
                </Button>
              </div>
            </div>
          )}
          {/* <Card className="mx-3">
          <Row className="justify-content-center mt-5">
            <Col lg={6} >
              <div className="text-center mb-3">
                <h4>{language === "mal" ? "നമ്മുടെ മഹല്ല് അപ്‌ഗ്രേഡ് ചെയ്യാം" : "Lets Upgrade our mahall!"}</h4>
                <p className="text-muted mt-3">
                  {language === "mal" ?
                    `എല്ലാ പ്രൊഫഷണൽ ടൂളുകളും ഉപയോഗിച്ച് നമ്മുടെ മഹല്ലിനെ അടുത്ത ലെവലിലേക്ക് അപ്‌ഗ്രേഡ് ചെയ്യാം. 
                ചുവടെയുള്ള പ്ലാനുകളിൽ നിന്ന് അനുയോജ്യമായത് തിരഞ്ഞെടുക്കുക.` :
                    `along with all the professional tools in to the next level. 
                Choose the suitable one from the plans below.`}
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-4 d-flex justify-content-center">
            {pricings.map((pricing, key) => (
              <CardPricing pricing={pricing} key={"_pricing_" + key} />
            ))}
          </Row>
        </Card> */}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PagesPremium
