import React, { useState, useEffect, useRef } from "react"
import { Button, Container, Row, Spinner } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
//actions
import moment, { lang } from "moment"
import { MetaTags } from "react-meta-tags"

import {
  getDeathRegistrationDetail,
  getNikahRegistrationDetail,
} from "store/actions"
import LetterHead from "components/Common/Letterhead/letterHead"
import Breadcrumbs from "components/Common/Breadcrumb"
import NikahCertificateDownloadPdf from "pages/mahallUser/PdfDownloads/nikahCertificatePdf"
import DeathCertificateDownloadPdf from "pages/mahallUser/PdfDownloads/deathCertificatePdf"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useWindowSize } from "react-use"

const DeathCertificate = () => {
  const dispatch = useDispatch()
  const pdfRef = useRef()
  const params = useParams()

  const { deathRegistrationDetails, loading, language, userDetail } =
    useSelector(state => ({
      loading: state.NikahRegistrations.loading,
      detailLoading: state.MahallUsers.detailLoading,
      deathRegistrationDetails:
        state.DeathRegistrations.deathRegistrationDetail,
      language: state.Layout.language,
      userDetail: state.Users.userDetail,
    }))

  const [activeTab, setActiveTab] = useState(1)

  const downloadPdf = () => {
    const input = pdfRef.current
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL(`${userDetail?.mahall?.logo}`)
      const pdf = new jsPDF("p", "mm", "a4", true)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2
      const imgY = 30
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      )
      pdf.save("nikah.pdf")
    })
  }

  const today = moment(new Date()).format("DD-MM-yyyy")

  useEffect(() => {
    dispatch(getDeathRegistrationDetail(params?.id))
  }, [dispatch, params?.id])

  // function downloadPDF() {
  //   var doc = new jsPDF("p", "pt", "a4")
  //   // const windowWidth = document.documentElement.clientWidth;
  //   // const windowHeight = document.documentElement.clientHeight;
  //   const html = document.querySelector("#pdf-content")

  //   doc.html(html, {
  //     callback: function () {
  //       doc.save("window.pdf")
  //     },
  //     x: 0,
  //     y: 0,
  //   })
  //   // pdfdoc.fromHTML($('#pdf-content').html(), 10, 10, '',
  //   //   function (callback) { pdfdoc.save('First.pdf'); });

  //   // const doc = new jsPDF();
  //   // const windowWidth = document.documentElement.clientWidth;
  //   // const windowHeight = document.documentElement.clientHeight;
  //   // const html = document.querySelector('#pdf-content');

  //   // doc.html(html, {
  //   //   callback: function () {
  //   //     doc.save('window.pdf');
  //   //   },
  //   //   x: 0,
  //   //   y: 0,
  //   // });
  // }

  // function generatePDF() {
  //   const doc = new jsPDF();
  //   doc.text('Hello, World!', 10, 10);
  //   doc.save('my-document.pdf');
  // }

  // const onButtonClick = () => {
  //   // using Java Script method to get PDF file
  //   fetch(pdfFile).then(response => {
  //     response.blob().then(blob => {
  //       console.log(blob);
  //       // Creating new object of PDF file
  //       const fileURL = window.URL.createObjectURL(blob);
  //       console.log("url", fileURL);
  //       // Setting various property values
  //       let alink = document.createElement('a');
  //       alink.href = fileURL;
  //       alink.download = pdfFile;
  //       alink.click();
  //     })
  //   })
  // }

  // const downloadPDF = () => {
  //   const capture = document.querySelector('.actual-receipt');
  //   html2canvas(capture).then((canvas) => {
  //     const imgData = canvas.toDataURL('img/png');
  //     const doc = new jsPDF('p', 'mm', 'a4');
  //     const componentWidth = doc.internal.pageSize.getWidth();
  //     const componentHeight = doc.internal.pageSize.getHeight();
  //     doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
  //     doc.save('receipt.pdf');
  //   })
  // }

  // function generatePDF() {
  //   const html = document.documentElement.innerHTML;
  //   const documentDefinition = { content: html };
  //   const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  //   return pdfDocGenerator;
  // }

  // function downloadPDF() {
  //   const pdfDocGenerator = generatePDF();
  //   pdfDocGenerator.getBlob((blob) => {
  //     saveAs(blob, 'my-pdf-file.pdf');
  //   });
  // }

  const inputDateString = moment(deathRegistrationDetails?.death_date).format(
    "DD/MM/YYYY"
  )

  var parts = inputDateString.split("/")
  var day = parseInt(parts[0])
  var month = parseInt(parts[1])
  var year = parseInt(parts[2])

  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  var monthName = monthNames[month - 1]

  var dayWithSuffix =
    day +
    (day % 10 == 1 && day != 11
      ? "st"
      : day % 10 == 2 && day != 12
      ? "nd"
      : day % 10 == 3 && day != 13
      ? "rd"
      : "th")

  const window = useWindowSize()

  return (
    <>
      <MetaTags>
        <title>Death Certificate | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <DeathCertificateDownloadPdf
          pdfRef={pdfRef}
          activeTab={activeTab}
          mahall={deathRegistrationDetails}
          language={language}
        />
        <Breadcrumbs title="Nikkah" breadcrumbItem="Certificate" />
        <Container fluid>
          <div className="container-fluid">
            {/* <div
              className="btn-group btn-group-certificate mt-2 mt-xl-0"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                autoComplete="off"
                defaultChecked
                onChange={() => setActiveTab(1)}
              />
              <label className="btn btn-primary" htmlFor="btnradio1">
                {language === "mal" ? "മോഡൽ 1" : "Model 1"}
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio2"
                autoComplete="off"
                onChange={() => setActiveTab(2)}
              />
              <label className="btn btn-primary" htmlFor="btnradio2">
                {language === "mal" ? "മോഡൽ 2" : "Model 2"}
              </label>
            </div> */}
            {loading ? (
              <Spinner color="secondary" className="d-block m-auto" />
            ) : (
              <div className="certificate-layout" id="pdf-content">
                <div className="btn-download-certificate">
                  <Button
                    className="btn-sm bg-khaf-blue print-button"
                    onClick={downloadPdf}
                  >
                    <i
                      className="bx bx-download mx-2"
                      style={{ fontSize: "15px", color: "" }}
                    />
                    {language === "mal" ? "ഡൗൺലോഡ്" : "Download"}
                  </Button>
                </div>

                {window?.width >= 420 ? (
                  <Row className="mt-2">
                    <LetterHead />
                    <div className="d-flex justify-content-between pt-5">
                      <h5 className="text-black">
                        {language === "mal" ? "റെജി നം : " : "Reg No : "}{" "}
                        {deathRegistrationDetails?.mahall_custom_id}{" "}
                      </h5>
                      <h5 className="text-black">
                        {language === "mal" ? "തീയതി : " : "Date : "} {today}
                      </h5>
                    </div>
                    <h1 className="text-center text-black pt-5">
                      {language === "mal"
                        ? "മരണ സർട്ടിഫിക്കറ്റ്"
                        : "DEATH CERTIFICATE"}
                    </h1>
                    <p className="p-4" style={{ fontSize: 24 }}>
                      <span className="d-flex aling-items-center justify-content-center">
                        {language === "mal"
                          ? "ഈ മരണം ഔദ്യോഗികമായി സ്ഥിരീകരിച്ചു"
                          : "This is Acknowledge the Death of "}{" "}
                        <br />
                      </span>
                      <span className=" text-dark flex-center">
                        <b>
                          {" "}
                          {deathRegistrationDetails?.family_member_name} <br />
                        </b>
                      </span>{" "}
                      <span className="flex-center ">
                        {language === "mal" ? "ദിവസം" : "on the"} &nbsp;
                        <span className="text-dark">
                          <b>{dayWithSuffix}</b> &nbsp;
                        </span>{" "}
                        {language === "mal" ? "മാസം" : " day of "} &nbsp;
                        <span className="text-dark">
                          <b>{monthName}</b> &nbsp;
                        </span>{" "}
                        {language === "mal" ? "വർഷത്തിൽ" : "in the year"} &nbsp;
                        <span className="text-dark">
                          <b>{year}</b>. &nbsp;
                        </span>
                        <br />{" "}
                      </span>{" "}
                      <span className="flex-center text-dark">
                        {" "}
                        {language === "mal" ? "സ്ഥലം" : "at"} : &nbsp;{" "}
                        <b>{deathRegistrationDetails?.death_place}</b>
                      </span>
                    </p>
                    <div className="flex-end">
                      <p className="font-size-16 text-dark">
                        {language === "mal"
                          ? "പ്രസിഡന്റിന്റെ ഒപ്പ്"
                          : "Signature of President"}{" "}
                      </p>
                    </div>
                  </Row>
                ) : (
                  <Row className="mt-2">
                    <LetterHead />
                    <div className="d-flex justify-content-between pt-5">
                      <h5 className="text-black">
                        {language === "mal" ? "റെജി നം : " : "Reg No : "}{" "}
                        {deathRegistrationDetails?.mahall_custom_id}{" "}
                      </h5>
                      <h5 className="text-black">
                        {language === "mal" ? "തീയതി : " : "Date : "} {today}
                      </h5>
                    </div>
                    <h1 className="text-center text-black pt-5">
                      {language === "mal"
                        ? "മരണ സർട്ടിഫിക്കറ്റ്"
                        : "DEATH CERTIFICATE"}
                    </h1>
                    <p className="p-4" style={{ fontSize: 18 }}>
                      <span className="">
                        {language === "mal"
                          ? "ഈ മരണം ഔദ്യോഗികമായി സ്ഥിരീകരിച്ചു"
                          : "This is Acknowledge the Death of "}{" "}
                        <br />
                      </span>
                      <span className="">
                        <b> {deathRegistrationDetails?.family_member_name}</b>
                      </span>{" "}
                      <span className=" ">
                        {language === "mal" ? "ദിവസം" : "on the"} &nbsp;
                        <span className="text-dark">
                          <b>{dayWithSuffix}</b> &nbsp;
                        </span>{" "}
                        {language === "mal" ? "മാസം" : " day of "} &nbsp;
                        <span className="text-dark">
                          <b>{monthName}</b> &nbsp;
                        </span>{" "}
                        {language === "mal" ? "വർഷത്തിൽ" : "in the year"} &nbsp;
                        <span className="text-dark">
                          <b>{year}</b>. &nbsp;
                        </span>
                      </span>{" "}
                      <br />
                      <span className=" text-dark">
                        {" "}
                        {language === "mal" ? "സ്ഥലം" : "at"} : &nbsp;{" "}
                        <b>{deathRegistrationDetails?.death_place}</b>
                      </span>
                    </p>
                    <div className="flex-end">
                      <p className="font-size-16 text-dark">
                        {language === "mal"
                          ? "പ്രസിഡന്റിന്റെ ഒപ്പ്"
                          : "Signature of President"}{" "}
                      </p>
                    </div>
                  </Row>
                )}
              </div>
            )}
          </div>
        </Container>
      </div>

      {/* <NikahCertificateDownloadPdf pdfRef={pdfRef} activeTab={activeTab} /> */}
    </>
  )
}

export default DeathCertificate

DeathCertificate.propTypes = {
  history: PropTypes.object,
}
