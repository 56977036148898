import {
  GET_DIVISIONS,
  GET_DIVISIONS_SUCCESS,
  GET_DIVISIONS_FAIL,
  GET_DIVISION_DETAIL,
  GET_DIVISION_DETAIL_SUCCESS,
  GET_DIVISION_DETAIL_FAIL,
  CREATE_DIVISION,
  CREATE_DIVISION_SUCCESS,
  CREATE_DIVISION_FAIL,
  UPDATE_DIVISION,
  UPDATE_DIVISION_SUCCESS,
  UPDATE_DIVISION_FAIL,
  DELETE_DIVISION,
  DELETE_DIVISION_SUCCESS,
  DELETE_DIVISION_FAIL,
  DIVISION_DROPDOWN,
  DIVISION_DROPDOWN_SUCCESS,
  DIVISION_DROPDOWN_FAIL,
} from "./actionTypes"

export const getDivisions = (classId, searchText, page) => ({
  type: GET_DIVISIONS,
  payload: { classId, searchText, page },
})
export const getDivisionsSuccess = divisions => ({
  type: GET_DIVISIONS_SUCCESS,
  payload: divisions,
})
export const getDivisionsFail = error => ({
  type: GET_DIVISIONS_FAIL,
  payload: error,
})

export const divisionDropdown = (searchText, classId) => ({
  type: DIVISION_DROPDOWN,
  payload: { searchText, classId },
})
export const divisionDropdownSuccess = allDivisions => ({
  type: DIVISION_DROPDOWN_SUCCESS,
  payload: allDivisions,
})
export const divisionDropdownFail = error => ({
  type: DIVISION_DROPDOWN_FAIL,
  payload: error,
})

export const getDivisionDetail = divisionId => ({
  type: GET_DIVISION_DETAIL,
  divisionId,
})

export const getDivisionDetailSuccess = divisionDetail => ({
  type: GET_DIVISION_DETAIL_SUCCESS,
  payload: divisionDetail,
})

export const getDivisionDetailFail = error => ({
  type: GET_DIVISION_DETAIL_FAIL,
  payload: error,
})

export const createDivision = (newDivision, history) => ({
  type: CREATE_DIVISION,
  payload: { newDivision, history },
})

export const createDivisionSuccess = newDivision => ({
  type: CREATE_DIVISION_SUCCESS,
  payload: newDivision,
})

export const createDivisionFail = error => ({
  type: CREATE_DIVISION_FAIL,
  payload: error,
})

export const updateDivision = (updatedDivision, divisionId, history) => ({
  type: UPDATE_DIVISION,
  payload: { updatedDivision, divisionId, history },
})

export const updateDivisionSuccess = updatedDivision => ({
  type: UPDATE_DIVISION_SUCCESS,
  payload: updatedDivision,
})

export const updateDivisionFail = error => ({
  type: UPDATE_DIVISION_FAIL,
  payload: error,
})

export const deleteDivision = (divisionId, history) => ({
  type: DELETE_DIVISION,
  payload: { divisionId, history },
})

export const deleteDivisionSuccess = newDivision => ({
  type: DELETE_DIVISION_SUCCESS,
  payload: newDivision,
})

export const deleteDivisionFail = error => ({
  type: DELETE_DIVISION_FAIL,
  payload: error,
})
