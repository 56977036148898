import {
  GET_SUPER_BANNERS,
  GET_SUPER_BANNER_DETAILS_FAIL,
  CREATE_SUPER_BANNERS,
  UPDATE_SUPER_BANNERS,
  DELETE_SUPER_BANNERS,
  DELETE_SUPER_BANNER_FAIL,
  GET_SUPER_BANNER_FAIL,
  UPDATE_SUPER_BANNER_FAIL,
  CREATE_SUPER_BANNER_FAIL,
  GET_SUPER_BANNER_SUCCESS,
  GET_SUPER_BANNER_DETAILS_SUCCESS,
  CREATE_SUPER_BANNER_SUCCESS,
  UPDATE_SUPER_BANNER_SUCCESS,
  DELETE_SUPER_BANNER_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  superBanners: {
    superBanners: [],
    total: "",
    page: "",
  },
  superBannerDetails: {},
  error: {},
  loading: false,
}

const SuperBanners = (state = INIT_STATE, action) => {
  switch (action.type) {
    // SuperFeeds - This line cannot be edited or removed
    case GET_SUPER_BANNERS:
    case GET_SUPER_BANNER_DETAILS_FAIL:
    case CREATE_SUPER_BANNERS:
    case UPDATE_SUPER_BANNERS:
    case DELETE_SUPER_BANNERS:
      return {
        ...state,
        loading: true,
      }

    case DELETE_SUPER_BANNER_FAIL:
    case GET_SUPER_BANNER_FAIL:
    case GET_SUPER_BANNER_DETAILS_FAIL:
    case UPDATE_SUPER_BANNER_FAIL:
    case CREATE_SUPER_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SUPER_BANNER_SUCCESS:
      return {
        ...state,
        superBanners: action.payload,
        error: {},
        loading: false,
      }

    case GET_SUPER_BANNER_DETAILS_SUCCESS:
      return {
        ...state,
        superBannerDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_SUPER_BANNER_SUCCESS:
      return {
        ...state,
        // superfeeds: {
        //   ...state.superfeeds,
        //   superfeedss: [...state?.superfeeds?.superfeeds, action.payload],
        //   total: state.superfeeds.total + 1,
        // },
        // superfeedsDetails: action.payload,
        superBanners: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_SUPER_BANNER_SUCCESS:
      return {
        ...state,
        superBanners: {
          ...state.superBanners,
          superBanners: state.superBanners.superbanners?.map(superBanners =>
            superBanners._id === action.payload._id
              ? { ...superBanners, ...action.payload }
              : superBanners
          ),
        },
        superBannersDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_SUPER_BANNER_SUCCESS:
      return {
        ...state,
        superBanners: action?.payload,
        // {
        //   ...state.superfeeds,
        //   superfeeds: state.superfeeds.superfeeds?.filter(
        //     superfeeds => superfeeds._id !== action.payload._id
        //   ),
        // },
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default SuperBanners
