import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import { getVillages, deleteVillage } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"

const AllVillages = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const { villages, loading, language } = useSelector(state => ({
    villages: state.Villages.villages,
    loading: state.Villages.loading,
    language: state.Layout.language,
  }))

  //pages
  const totalPages = Math.ceil(villages?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getVillages(searchText, pageSend()))
  }, [dispatch, page, searchText])

  const handleDelete = id => {
    dispatch(deleteVillage(id, history))
  }

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    {
      dataField: "village_name",
      text: `${language === "mal" ? "പേര്" : "Name"}`,
    },
    {
      dataField: "district_name",
      text: `${language === "mal" ? "ജില്ല" : "District"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]

  const villagesData = map(villages?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    village_name:
      language === "mal"
        ? item?.village_malayalam_name
          ? item?.village_malayalam_name
          : item?.village_english_name
        : item?.village_english_name,
    district_name:
      language === "mal"
        ? item?.district_malayalam_name
          ? item?.district_malayalam_name
          : item?.district_english_name
        : item?.district_english_name,
    action: (
      <div className="m-0">
        <Link to={`/village/update/${item?.id}`} className="m-0">
          <Button type="button" color="white" className=" btn m-0 p-0">
            <i
              className="bx bx-pencil me-2"
              style={{ color: "green", fontSize: "18px" }}
            />
          </Button>
        </Link>

        <Button
          type="button"
          color="white"
          className=" m-0 btn "
          onClick={() => {
            handleDelete(item.id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "18px" }}
          />
        </Button>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceVillageSearch = debounce(value => setSearchText(value), 600)

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        <Col sm="12" md="12" lg="6">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={villagesData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceVillageSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <div className="col-md-8">
                        <Link
                          to="/village/create"
                          style={{
                            width: language === "mal" ? "200px" : "120px",
                          }}
                          className="w-md btn btn-success m-auto me-0 d-block"
                        >
                          {language === "mal"
                            ? "+ പുതിയത് ചേർക്കുക"
                            : "+ New Village"}
                        </Link>
                      </div>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllVillages
