export const CREATE_TRANSACTIONS = "CREATE_TRANSACTIONS"
export const CREATE_TRANSACTIONS_SUCCESS = "CREATE_TRANSACTIONS_SUCCESS"
export const CREATE_TRANSACTIONS_FAIL = "CREATE_TRANSACTIONS_FAIL"

export const GET_TRANSACTION_DETAILS = "GET_TRANSACTION_DETAILS"
export const GET_TRANSACTION_DETAILS_SUCCESS = "GET_TRANSACTION_DETAILS_SUCCESS"
export const GET_TRANSACTION_DETAILS_FAIL = "GET_TRANSACTION_DETAILS_FAIL"

export const ADMIN_ALL_DONATIONS = "ADMIN_ALL_DONATIONS"
export const ADMIN_ALL_DONATIONS_SUCCESS = "ADMIN_ALL_DONATIONS_SUCCESS"
export const ADMIN_ALL_DONATIONS_FAIL = "ADMIN_ALL_DONATIONS_FAIL"

export const ADMIN_DONATION_DETAILS = "ADMIN_DONATION_DETAILS"
export const ADMIN_DONATION_DETAILS_SUCCESS = "ADMIN_DONATION_DETAILS_SUCCESS"
export const ADMIN_DONATION_DETAILS_FAIL = "ADMIN_DONATION_DETAILS_FAIL"
