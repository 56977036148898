import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_INSTITUTES,
  GET_INSTITUTE_DROPDOWN,
  GET_INSTITUTE_DETAIL,
  CREATE_INSTITUTE,
  UPDATE_INSTITUTE,
  DELETE_INSTITUTE,
  GET_INSTITUTES_CARDS,
  GET_INSTITUTE_MAHALL_DETAIL,
} from "./actionTypes"
import {
  getInstitutesSuccess,
  getInstitutesFail,
  getInstituteDropdownSuccess,
  getInstituteDropdownFail,
  getInstituteDetailSuccess,
  getInstituteDetailFail,
  createInstituteSuccess,
  createInstituteFail,
  updateInstituteSuccess,
  updateInstituteFail,
  deleteInstituteSuccess,
  deleteInstituteFail,
  getInstitutesCardsSuccess,
  getInstitutesCardsFail,
  getInstituteMahallDetailSuccess,
  getInstituteMahallDetailFail,
} from "./actions"

import { get, post, del, patch } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getInstitutesAPi = ({ searchText, page, instituteType }) => {
  if ((searchText, instituteType)) {
    return get(
      `/api/v1/institute/institute/?institute_type=${
        instituteType && instituteType
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  } else {
    return get(`/api/v1/institute/institute/?page=${page ? page : 1}`)
  }
}
const getInstituteDropdownAPi = ({ searchText, institute_type }) => {
  if (institute_type) {
    return get(
      `/api/v1/institute/institute_dropdown?search=${
        searchText ? searchText : ""
      }&institute_type=${institute_type ? institute_type : ""}`
    )
  } else
    return get(
      `/api/v1/institute/institute_dropdown?search=${
        searchText ? searchText : ""
      }`
    )
}
const getInstituteDetailsAPi = instituteId => {
  return get(`/api/v1/institute/institute/${instituteId}/`)
}
const getInstituteMahallDetailsAPi = () => {
  return get(`/api/v1/institute/mahall_institute/`)
}
const getInstitutesCardsAPi = () => {
  return get(`/api/v1/institute/institute/?cards=all`)
}
const createInstituteApi = ({ institute }) => {
  return post("/api/v1/institute/institute/", institute)
}
const updateInstituteApi = ({ instituteId, institute }) => {
  return patch(`/api/v1/institute/institute/${instituteId}/`, institute)
}
const deleteInstituteApi = ({ instituteId }) => {
  return del(`/api/v1/institute/institute/${instituteId}/`)
}

function* fetchInstitutes({ payload }) {
  try {
    const response = yield call(getInstitutesAPi, payload)
    if (response && !response?.error) {
      yield put(getInstitutesSuccess(response))
    } else {
      yield put(getInstitutesFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstitutesFail(error))
    errorNotification()
  }
}

function* fetchInstituteDropdown({ payload }) {
  try {
    const response = yield call(getInstituteDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getInstituteDropdownSuccess(response))
    } else {
      yield put(getInstituteDropdownFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstituteDropdownFail(error))
    errorNotification(error)
  }
}

function* fetchInstituteDetail({ instituteId }) {
  try {
    const response = yield call(getInstituteDetailsAPi, instituteId)
    if (response && !response?.error) {
      yield put(getInstituteDetailSuccess(response))
    } else {
      yield put(getInstituteDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstituteDetailFail(error))
    errorNotification(error)
  }
}
function* fetchInstituteMahallDetail({ payload }) {
  try {
    const response = yield call(getInstituteMahallDetailsAPi, payload)
    if (response && !response?.error) {
      yield put(getInstituteMahallDetailSuccess(response))
    } else {
      yield put(getInstituteMahallDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstituteMahallDetailFail(error))
  }
}
function* fetchInstitutesCards() {
  try {
    const response = yield call(getInstitutesCardsAPi)
    if (response && !response?.error) {
      yield put(getInstitutesCardsSuccess(response))
    } else {
      yield put(getInstitutesCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstitutesCardsFail(error))
  }
}
function* onCreateInstitute({ payload }) {
  try {
    const response = yield call(createInstituteApi, payload)
    if (response && !response?.error) {
      yield put(createInstituteSuccess(response))
      payload.history.push("/institutes")
      doneNotification("Created Successfully")
    } else {
      yield put(createInstituteFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createInstituteFail(error))
    errorNotification()
  }
}

function* onUpdateInstitute({ payload }) {
  try {
    const response = yield call(updateInstituteApi, payload)
    if (response && !response?.error) {
      yield put(updateInstituteSuccess(response))
      history.goBack()
      doneNotification()
    } else {
      yield put(updateInstituteFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateInstituteFail(error))
    errorNotification()
  }
}

function* onDeleteInstitute({ payload }) {
  try {
    const response = yield call(deleteInstituteApi, payload)
    if (response && !response?.error) {
      yield put(deleteInstituteSuccess(response))
      doneNotification("Deleted Successfully")
      if (payload?.type === "institute") {
        payload.history.push("/institutes")
      } else if (payload?.type === "program") {
        payload.history.push("/programes")
      } else if (payload?.type === "madrasa") {
        payload.history.push("/madrasas")
      }
    } else {
      yield put(deleteInstituteFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteInstituteFail(error))
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* InstitutesSaga() {
  yield takeEvery(GET_INSTITUTES, fetchInstitutes)
  yield takeEvery(GET_INSTITUTE_DETAIL, fetchInstituteDetail)
  yield takeEvery(GET_INSTITUTE_MAHALL_DETAIL, fetchInstituteMahallDetail)
  yield takeEvery(GET_INSTITUTE_DROPDOWN, fetchInstituteDropdown)
  yield takeEvery(GET_INSTITUTES_CARDS, fetchInstitutesCards)
  yield takeEvery(CREATE_INSTITUTE, onCreateInstitute)
  yield takeEvery(UPDATE_INSTITUTE, onUpdateInstitute)
  yield takeEvery(DELETE_INSTITUTE, onDeleteInstitute)
}

export default InstitutesSaga
