/* STUDENT_VARISANGYAS */
export const GET_STUDENT_VARISANGYAS = "GET_STUDENT_VARISANGYAS"
export const GET_STUDENT_VARISANGYAS_SUCCESS = "GET_STUDENT_VARISANGYAS_SUCCESS"
export const GET_STUDENT_VARISANGYAS_FAIL = "GET_STUDENT_VARISANGYAS_FAIL"

/* STUDENT_VARISANGYAS CARDS*/
export const GET_STUDENT_VARISANGYA_CARDS = "GET_STUDENT_VARISANGYA_CARDS"
export const GET_STUDENT_VARISANGYA_CARDS_SUCCESS =
  "GET_STUDENT_VARISANGYA_CARDS_SUCCESS"
export const GET_STUDENT_VARISANGYA_CARDS_FAIL =
  "GET_STUDENT_VARISANGYA_CARDS_FAIL"

/* STUDENT_VARISANGYA  DETAIL*/
export const GET_STUDENT_VARISANGYA_DETAIL = "GET_STUDENT_VARISANGYA_DETAIL"
export const GET_STUDENT_VARISANGYA_DETAIL_SUCCESS =
  "GET_STUDENT_VARISANGYA_DETAIL_SUCCESS"
export const GET_STUDENT_VARISANGYA_DETAIL_FAIL =
  "GET_STUDENT_VARISANGYA_DETAIL_FAIL"

/* STUDENT_VARISANGYA_ITEMS */
export const GET_STUDENT_VARISANGYA_ITEMS = "GET_STUDENT_VARISANGYA_ITEMS"
export const GET_STUDENT_VARISANGYA_ITEMS_SUCCESS =
  "GET_STUDENT_VARISANGYA_ITEMS_SUCCESS"
export const GET_STUDENT_VARISANGYA_ITEMS_FAIL =
  "GET_STUDENT_VARISANGYA_ITEMS_FAIL"

/**
 * add STUDENT_VARISANGYA
 */
export const CREATE_STUDENT_VARISANGYA = "CREATE_STUDENT_VARISANGYA"
export const CREATE_STUDENT_VARISANGYA_SUCCESS =
  "CREATE_STUDENT_VARISANGYA_SUCCESS"
export const CREATE_STUDENT_VARISANGYA_FAIL = "CREATE_STUDENT_VARISANGYA_FAIL"

/**
 * Edit STUDENT_VARISANGYA
 */
export const UPDATE_STUDENT_VARISANGYA = "UPDATE_STUDENT_VARISANGYA"
export const UPDATE_STUDENT_VARISANGYA_SUCCESS =
  "UPDATE_STUDENT_VARISANGYA_SUCCESS"
export const UPDATE_STUDENT_VARISANGYA_FAIL = "UPDATE_STUDENT_VARISANGYA_FAIL"

/**
 * Delete STUDENT_VARISANGYA
 */
export const DELETE_STUDENT_VARISANGYA = "DELETE_STUDENT_VARISANGYA"
export const DELETE_STUDENT_VARISANGYA_SUCCESS =
  "DELETE_STUDENT_VARISANGYA_SUCCESS"
export const DELETE_STUDENT_VARISANGYA_FAIL = "DELETE_STUDENT_VARISANGYA_FAIL"

// AUTO_GENERATE_STUDENT_VARISANGYAS
export const AUTO_GENERATE_STUDENT_VARISANGYAS =
  "AUTO_GENERATE_STUDENT_VARISANGYAS"
export const AUTO_GENERATE_STUDENT_VARISANGYAS_SUCCESS =
  "AUTO_GENERATE_STUDENT_VARISANGYAS_SUCCESS"
export const AUTO_GENERATE_STUDENT_VARISANGYAS_FAIL =
  "AUTO_GENERATE_STUDENT_VARISANGYAS_FAIL"

// REFRESH_STUDENT_VARISANGYA
export const REFRESH_STUDENT_VARISANGYA = "REFRESH_STUDENT_VARISANGYA"
export const REFRESH_STUDENT_VARISANGYA_SUCCESS =
  "REFRESH_STUDENT_VARISANGYA_SUCCESS"
export const REFRESH_STUDENT_VARISANGYA_FAIL = "REFRESH_STUDENT_VARISANGYA_FAIL"

// UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER
export const UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER =
  "UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER"
export const UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS =
  "UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS"
export const UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_FAIL =
  "UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_FAIL"
