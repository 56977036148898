import { useDecryptData, useEncryptData } from "./useDecryptEncrypt"

import { TOKEN_PREFIX } from "helpers/api_methods"

const STORAGE = localStorage

export const useSetToken = token => {
  const encryptedToken = useEncryptData(token)
  STORAGE.setItem(TOKEN_PREFIX, encryptedToken)
}

export const useGetToken = () => {
  const encryptedToken = STORAGE.getItem(TOKEN_PREFIX)
  const decryptData = useDecryptData(encryptedToken)
  return decryptData
}

export const useSetRole = role => {
  const currentRole = role

  const encryptedRole = useEncryptData(currentRole)

  STORAGE.setItem("current_role", encryptedRole)
}

export const useGetRole = () => {
  const encryptedRole = STORAGE.getItem("current_role")

  const { isSuccess, token: role } = useDecryptData(encryptedRole)

  return {
    isSuccess,
    role,
  }
}

export const useSetSession = (key, value) => {
  STORAGE.setItem(key, value)
}
export const useGetSession = key => {
  return STORAGE.getItem(key)
}
export const useRemoveSession = key => {
  STORAGE.removeItem(key)
}
