import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
//actions
import moment from "moment"

import FormEditors from "../../../../components/Common/FormEditor"

import {
  createNocCertificate,
  getAllFamilyMembersDropdown,
  checkCustomId,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateNocCertificate = ({ history }) => {
  const dispatch = useDispatch()

  const {
    loading,
    language,
    mahallId,
    allFamilyMembers,
    customIdAvailability,
    customIdCheckLoading,
  } = useSelector(state => ({
    loading: state.Mahalls.loading,
    error: state.Mahalls.error,
    language: state.Layout.language,
    mahallId: state.Users.userDetail?.mahall?.id,
    allFamilyMembers: state.FamilyMembers.allFamilyMembers,
    customIdAvailability: state.Users.customIdAvailability,
    customIdCheckLoading: state.Users.customIdCheckLoading,
  }))

  const [searchText, setSearchText] = useState("")
  const [familyMember, setFamilyMember] = useState("Select member...")
  const [familyMemberId, setFamilyMemberId] = useState()
  const [nocType, setNocType] = useState("Others")

  const today = moment(new Date()).format("yyyy-MM-DD")

  // console.log(committeeId);

  // const nikah_template = `<p>To Whom It May Concern, </p><p>Subject: <strong>No Objection Certificate for Nikah</strong> </p><p>This is to certify that <strong>Mr. [Groom&#x27;s Full Name],</strong> son of Mr. <strong>[Groom&#x27;s Father&#x27;s Name],</strong> and <strong>Ms. [Bride&#x27;s Full Name],</strong> daughter of <strong>Mr. [Bride&#x27;s Father&#x27;s Name],</strong> have approached our Mahall for the purpose of solemnizing their marriage through the Islamic Nikah ceremony. </p><p>We, the undersigned members of the Mahall committee, hereby declare that we have no objections to the aforementioned union, and we consider it in compliance with Islamic customs and teachings. We have conducted the necessary due diligence, reviewed the documentation, and ensured that both parties meet the requirements for marriage in accordance with Islamic law. </p><p>Furthermore, we have performed all required religious and legal checks and verifications, and it is our firm belief that the union between <strong>Mr. [Groom&#x27;s Full Name]</strong> and <strong>Ms. [Bride&#x27;s Full Name]</strong> is permissible under Islamic law. </p><p><u>The current marital status of the parties is as follows:</u></p><p>- Mr. [Groom&#x27;s Full Name] - [Current Marital Status] -[Number of Marriages]</p><p>- Ms. [Bride&#x27;s Full Name] - [Current Marital Status] - [Number of Marriages]</p><p><em><br/>This No Objection Certificate is issued to facilitate the Nikah ceremony and to confirm that the Mahall does not raise any objections to this marriage.</em> We wish the couple a blessed and harmonious marital life. </p><p>For any further inquiries or information, please feel free to contact our Mahall office at <strong>[Contact Information].</strong>  </p><p>Yours faithfully,</p><p>[Signature of the Mahall Committee Member]</p><p>[Printed Name of the Mahall Committee Member]</p><p><br/></p>`
  const [content, setContent] = useState("")
  const [checkValue, setCheckValue] = useState()

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    // console.log(values)
    const createNocCertificateData = {
      title: values.title,
      subject: values.subject,
      mahall_custom_id: values.mahall_custom_id,
      mahall: mahallId,
      member: familyMemberId,
      content: content,
      date: values.date,
      noc_type: nocType,
    }
    // console.log(createCommitteeMeetingsData);
    dispatch(createNocCertificate(createNocCertificateData, history))
  }

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchText))
  }, [dispatch, searchText])

  const optionGroup = [
    {
      options: allFamilyMembers?.map((result, index) => ({
        key: index,
        label: `${result?.full_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        })`,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setFamilyMemberId(event.value)
    setFamilyMember(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const handleCheckNocCertificateCustomId = v => {
    const checkNocCertificateCustomIdData = {
      mahall: mahallId,
      mahall_custom_id: v,
      model: "noc",
    }
    dispatch(checkCustomId(checkNocCertificateCustomIdData))
    setCheckValue(v)
    // console.log("phone: ", v)
  }

  return (
    <>
      <MetaTags>
        <title>NOC Certificates | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal" ? "എൻ ഒ സി സർട്ടിഫിക്കറ്റ് " : "NOC Certificate"
          }
          breadcrumbItem={
            language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create NOC Certificate"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4 mb-4">Fill this form</CardTitle> */}
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Title"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="title"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "കുടുംബാംഗം" : "Member"}
                            </Label>
                            <Select
                              onInputChange={handleEnters}
                              placeholder={familyMember}
                              options={optionGroup}
                              classNamePrefix="select2-selection"
                              onChange={handlerFinalValue}
                              type="text"
                              title="Family Member"
                            />
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "വിഷയം" : "Subject"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="subject"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="col mb-2">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "തീയതി" : "Custom Id"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="mahall_custom_id"
                              type="text"
                              onChange={v => {
                                handleCheckNocCertificateCustomId(
                                  v.target.value
                                )
                                setCheckValue(v.target.value)
                              }}
                            />
                          </div>
                          {checkValue &&
                            checkValue?.length > 0 &&
                            (!customIdCheckLoading ? (
                              customIdAvailability ? (
                                <p className="text-success">
                                  {language === "mal"
                                    ? "ലഭ്യമാണ്"
                                    : "Available"}
                                </p>
                              ) : (
                                <p className="text-danger">
                                  {language === "mal"
                                    ? "ലഭ്യമല്ല"
                                    : "Not Available"}
                                </p>
                              )
                            ) : (
                              <></>
                            ))}
                        </div>
                        <div className="col-md-3">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "തീയതി" : "Date"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="date"
                              type="date"
                              value={today}
                            />
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <Col md="12">
                          <div className="col-12 mb-3">
                            <Label htmlFor="lbltype" className="form-label">
                              {language === "mal" ? "ഉള്ളടക്കം" : "Content"}
                            </Label>
                            <Col sm="12" className="mt-0">
                              <FormEditors
                                content={content}
                                setContent={setContent}
                              />
                            </Col>
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex justify-content-end mt-5">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateNocCertificate

CreateNocCertificate.propTypes = {
  history: PropTypes.object,
}
