import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CONSTITUENCIES,
  GET_CONSTITUENCY_DROPDOWN,
  GET_CONSTITUENCY_DETAIL,
  CREATE_CONSTITUENCY,
  UPDATE_CONSTITUENCY,
  DELETE_CONSTITUENCY,
} from "./actiontypes"
import {
  getConstituenciesSuccess,
  getConstituenciesFail,
  getConstituencyDropdownSuccess,
  getConstituencyDropdownFail,
  getConstituencyDetailSuccess,
  getConstituencyDetailFail,
  createConstituencySuccess,
  createConstituencyFail,
  updateConstituencySuccess,
  updateConstituencyFail,
  deleteConstituencySuccess,
  deleteConstituencyFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getConstituenciesAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/constituency/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/mahall/constituency/?page=${page ? page : 1}`)
  }
}
const getConstituencyDropdownAPi = searchText => {
  return get(
    `/api/v1/mahall/constituency_dropdown?search=${searchText && searchText}`
  )
}
const getConstituencyDetailsAPi = constituencyId => {
  return get(`/api/v1/mahall/constituency/${constituencyId}/`)
}
const createConstituencyApi = ({ constituency }) => {
  return post("/api/v1/mahall/constituency/", constituency)
}
const updateConstituencyApi = ({ constituencyId, constituency }) => {
  return ApiPut(`/api/v1/mahall/constituency/${constituencyId}/`, constituency)
}
const deleteConstituencyApi = ({ constituencyId }) => {
  return del(`/api/v1/mahall/constituency/${constituencyId}/`)
}

function* fetchConstituencies({ payload }) {
  try {
    const response = yield call(getConstituenciesAPi, payload)
    if (response && !response?.error) {
      yield put(getConstituenciesSuccess(response))
    } else {
      yield put(getConstituenciesFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getConstituenciesFail(error))
  }
}

function* fetchConstituencyDropdown({ payload }) {
  try {
    const response = yield call(getConstituencyDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getConstituencyDropdownSuccess(response))
    } else {
      yield put(getConstituencyDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getConstituencyDropdownFail(error))
  }
}

function* fetchConstituencyDetail({ constituencyId }) {
  try {
    const response = yield call(getConstituencyDetailsAPi, constituencyId)
    if (response && !response?.error) {
      yield put(getConstituencyDetailSuccess(response))
    } else {
      yield put(getConstituencyDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getConstituencyDetailFail(error))
  }
}
function* onCreateConstituency({ payload }) {
  try {
    const response = yield call(createConstituencyApi, payload)
    if (response && !response?.error) {
      yield put(createConstituencySuccess(response))
      payload.history.push("/constituencys")
      doneNotification("Created Successfully")
    } else {
      yield put(createConstituencyFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createConstituencyFail(error))
    errorNotification()
  }
}

function* onUpdateConstituency({ payload }) {
  try {
    const response = yield call(updateConstituencyApi, payload)
    if (response && !response?.error) {
      yield put(updateConstituencySuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateConstituencyFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateConstituencyFail(error))
    errorNotification()
  }
}

function* onDeleteConstituency({ payload }) {
  try {
    const response = yield call(deleteConstituencyApi, payload)
    if (response && !response?.error) {
      payload.history.push("/constituencys")
      yield put(deleteConstituencySuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteConstituencyFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteConstituencyFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* ConstituenciesSaga() {
  yield takeEvery(GET_CONSTITUENCIES, fetchConstituencies)
  yield takeEvery(GET_CONSTITUENCY_DETAIL, fetchConstituencyDetail)
  yield takeEvery(GET_CONSTITUENCY_DROPDOWN, fetchConstituencyDropdown)
  yield takeEvery(CREATE_CONSTITUENCY, onCreateConstituency)
  yield takeEvery(UPDATE_CONSTITUENCY, onUpdateConstituency)
  yield takeEvery(DELETE_CONSTITUENCY, onDeleteConstituency)
}

export default ConstituenciesSaga
