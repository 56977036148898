import React, { useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
// import moment from "moment"
//actions institute
//componetns
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getAssets } from "store/actions"
import UiModalAsset from "./UiModalAsset"

const InstituteAssetLists = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { loading, language, assets } = useSelector(state => ({
    language: state.Layout.language,
    loading: state.Assets.loading,
    assets: state.Assets.assets,
    // classCards: state.Assetes.classDetail?.cards,
  }))

  const madrasaId = location?.search?.split("?madrasaid=")[1]

  // const cardData = [
  //   {
  //     title: `${language === 'mal' ? 'അംഗങ്ങൾ' : 'Members'}`,
  //     cardValue: `${classCards?.committee_members ?? 0}`,
  //     iconAsset: 'group',
  //     routeLink: '/#!',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'അംഗങ്ങൾ ഇല്ല' : 'No Members'}`,
  //   },
  //   {
  //     title: `${language === 'mal' ? 'യോഗങ്ങൾ' : 'Students'}`,
  //     cardValue: `${classCards?.committee_meetings ?? 0}`,
  //     iconAsset: 'chat',
  //     routeLink: '/#!',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'യോഗങ്ങൾ ഇല്ല' : 'No Students'}`,
  //   },
  //   {
  //     title: `${
  //       language === 'mal'
  //         ? `യോഗങ്ങൾ (${moment().format('MMM')})`
  //         : `Students (${moment().format('MMM')})`
  //     }`,
  //     cardValue: `${classCards?.meetings_this_month ?? 0}`,
  //     iconAsset: 'chat',
  //     routeLink: '/#!',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'യോഗങ്ങൾ ഇല്ല' : 'No Students'}`,
  //   },
  // ]

  const [searchTextAsset, setSearchTextAsset] = useState("")
  const [assetPage, setAssetPage] = useState(1)
  const [modalMadrasaAsset, setModalMadrasaAsset] = useState(false)

  const totalAssetPages = Math.ceil(assets?.count / 10)
  const assetPages = range(1, totalAssetPages + 1)

  const assetPageSend = () => {
    if (assetPage >= assetPages.length) {
      return assetPages.length
    }
    if (assetPage < 1) {
      return 1
    } else {
      return assetPage
    }
  }

  const debounceAssetSearch = debounce(value => setSearchTextAsset(value), 600)

  const assetColumns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ." : "No."}`,
      sort: true,
    },
    {
      dataField: "asset_name",
      text: `${language === "mal" ? "അസറ്റ് പേര്" : "Asset Name"}`,
      sort: true,
    },
    {
      dataField: "remark",
      text: `${language === "mal" ? "റിമാർക്" : "Remark"}`,
    },
    // {
    //   dataField: "established_date",
    //   text: `${language === "mal" ? "ചേർന്ന തീയതി" : "Join Date"}`,
    // },
    // {
    //   dataField: "constituency_name",
    //   text: "Constituency",
    // },
    // {
    //   dataField: "district_english_name",
    //   text: "District",
    // },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const assetsData = map(assets?.results, (item, index) => ({
    ...item,
    key: index,
    no: index + 1,
    action: (
      <div>
        <Link
          to={`/mahall/institute/asset/${item?.id}`}
          className="btn btn-success w-xs btn-sm"
        >
          {language === "mal" ? "കാണുക" : "View"}
        </Link>
      </div>
    ),
  }))

  const defaultSortedAsset = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRowAsset = {
    mode: "checkbox",
  }

  useEffect(() => {
    dispatch(getAssets(madrasaId, searchTextAsset, assetPageSend()))
  }, [dispatch, madrasaId, searchTextAsset, assetPage])

  return (
    <>
      <UiModalAsset
        show={modalMadrasaAsset}
        onCloseclick={() => setModalMadrasaAsset(false)}
        madrasaId={madrasaId}
      />
      <MetaTags>
        <title>Assets | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ക്ലാസ് ലിസ്റ്റുകൾ" : "Asset Lists"}
          breadcrumbItem={language === "mal" ? "ക്ലാസ്" : "Asset"}
        />
        <Container fluid>
          <div className="container-fluid">
            <>
              <Row>
                <Col className="col-lg-12 col-md-12">
                  <Row>
                    {/* <Col md="8">
                      <Row>
                        {cardData?.map((item, key) => (
                          <Col
                            sm="12"
                            md="6"
                            lg="4"
                            key={key}
                            className="sm:p-0"
                          >
                            <Card className="blog-stats-wid">
                              <CardBody>
                                <Link to={item.routeLink && item.routeLink}>
                                  <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                      <p className="text-muted mb-2">
                                        {item.title}
                                      </p>
                                      {item.cardValue && (
                                        <h5 className="mb-0">
                                          {item.cardValue}
                                        </h5>
                                      )}
                                      <p className="m-0 text-info">
                                        {item.cardValue ? <></> : item.alt}
                                      </p>
                                    </div>

                                    <div className="avatar-sm ms-auto">
                                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                        <i
                                          className={
                                            'bx bxs-' + `${item.iconAsset}`
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg="12" className="">
                  <React.Fragment>
                    <Row style={{ display: "flex" }}>
                      <Col className="col-lg-6 col-md-6">
                        <Card>
                          <CardBody>
                            <ToolkitProvider
                              keyField="id"
                              columns={assetColumns}
                              data={assetsData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <form
                                            className="app-search d-lg-block"
                                            onChange={e =>
                                              debounceAssetSearch(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <div className="position-relative">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={
                                                  language === "mal"
                                                    ? "തിരയുക..."
                                                    : "Search..."
                                                }
                                                defaultValue={searchTextAsset}
                                              />
                                              <span className="bx bx-search-alt" />
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="8">
                                      <div className="d-flex justify-content-end">
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="btn-sm mb-4"
                                          onClick={() =>
                                            setModalMadrasaAsset(true)
                                          }
                                        >
                                          {language === "mal"
                                            ? "+ ക്ലാസ് ചേർക്കുക"
                                            : "+ Add Asset"}
                                        </Button>
                                        {/* <Link
                                          to={`/committee/meeting/create?committeeid=${classDetail?.id}`}
                                          className="btn-sm btn btn-primary"
                                        >
                                          {language === 'mal'
                                            ? '+ വിദ്യാർത്ഥിയെ ചേർക്കുക'
                                            : '+ Add Student'}
                                        </Link> */}
                                      </div>
                                    </Col>
                                  </Row>
                                  {loading ? (
                                    <Spinner
                                      color="secondary"
                                      className="d-block m-auto"
                                    />
                                  ) : (
                                    <>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField={"id"}
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={defaultSortedAsset}
                                              selectRow={selectRowAsset}
                                              assets={
                                                "table align-middle table-nowrap table-hover"
                                              }
                                              headerWrapperAssetes={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <MyPagination
                                        totalPages={assetPages}
                                        page={assetPage}
                                        setPage={setAssetPage}
                                      />
                                    </>
                                  )}
                                  {assets &&
                                    assets?.results &&
                                    assets?.results?.length <= 0 && (
                                      <p
                                        style={{ fontSize: "15px" }}
                                        className="text-center text-info"
                                      >
                                        {language === "mal"
                                          ? "യോഗങ്ങൾ ഒന്നും ഇല്ല"
                                          : "No Assets Yet"}
                                      </p>
                                    )}
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </React.Fragment>
                </Col>
              </Row>
            </>
          </div>
        </Container>
      </div>
    </>
  )
}

export default InstituteAssetLists

InstituteAssetLists.propTypes = {
  history: PropTypes.object,
}
