import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
//actions
// import moment from 'moment'

import { createVarisangyaSplitLedger, getLedgerDropdown } from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateVarisangyaSplit = ({ history }) => {
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState("")
  const [ledger, setLedger] = useState("Select ledger...")
  const [ledgerId, setLedgerId] = useState()
  console.log(ledgerId)
  const { language, searchLedgers } = useSelector(state => ({
    loading: state.DeathRegistrations.loading,
    error: state.DeathRegistrations.error,
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    searchLedgers: state.Ledgers.searchLedgers,
  }))

  // const today = moment(new Date()).format('yyyy-MM-DD')

  const [splitLedgers, setSplitLedgers] = useState([
    {
      institute_ledger: "",
      default_title: "",
      percentage: parseInt(""),
    },
  ])

  useEffect(() => {
    dispatch(getLedgerDropdown(searchText))
  }, [dispatch, searchText])

  // useEffect(() => {
  //   console.log('splitLedgers: ', splitLedgers);
  // }, [splitLedgers])

  const [percentage, setPercentage] = useState()

  const total = splitLedgers.reduce((a, b) => {
    return Number(a) + Number(b.percentage)
  }, 0)

  // handleValidSubmit
  const handleValidSubmit = () => {
    if (total === 100) {
      const splitData = {
        split_ledgers: splitLedgers,
      }
      dispatch(createVarisangyaSplitLedger(splitData, history))
    }
    setPercentage(total)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }
  const optionGroup = [
    {
      options: searchLedgers?.map((result, index) => ({
        key: index,
        label: `${result?.institute_ledger_name} (${
          language === "mal"
            ? result?.institute_malayalam_name
              ? result?.institute_malayalam_name
              : result?.institute_english_name
            : result?.institute_english_name
        }) (${result?.institute_type})`,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event, item) {
    setLedgerId(event.value)
    setLedger(event.label)
    setSplitLedgers(splitLedger =>
      splitLedger.map(x =>
        x === item ? { ...x, institute_ledger: event.value } : x
      )
    )
  }

  return (
    <>
      <MetaTags>
        <title>Split Ledger | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ലെഡ്ജറുകൾ വിഭജിക്കുക" : "Split Ledger"}
          breadcrumbItem={language === "mal" ? "വരിസംഖ്യ" : "Varisangya"}
        />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <Row>
                <Col sm="12" md="12" lg="12">
                  <Card>
                    <CardBody>
                      <Col className="col-12 d-flex flex-wrap">
                        <div className="col-12 mb-4">
                          <div className="d-flex align-items-center justify-content-start mt-2">
                            <Label htmlFor="lbltype" className="form-label">
                              {language === "mal"
                                ? "ലെഡ്ജറുകൾ വിഭജിക്കുക"
                                : "Split Ledgers"}
                            </Label>
                            <button
                              className="mx-2 btn bg-light d-flex align-items-center justify-content-center"
                              onClick={e => {
                                e.preventDefault()
                                setSplitLedgers(splitLedger => [
                                  ...splitLedger,
                                  {
                                    institute_ledger: "",
                                    default_title: "",
                                    percentage: "",
                                  },
                                ]),
                                  setLedger("Select ledger...")
                                setLedgerId("")
                              }}
                            >
                              <i
                                className="bx bx-plus"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </button>
                          </div>

                          <Col md="12" className=" mt-4">
                            {splitLedgers?.map((item, index) => {
                              return (
                                <div key={index}>
                                  <Row className="mt-3">
                                    <Col md="4" className="db__column">
                                      <Label>
                                        {language === "mal"
                                          ? "ലെഡ്ജർ"
                                          : "Ledger"}
                                        <span className="text-danger">
                                          &#42;
                                        </span>
                                      </Label>
                                      <Select
                                        onInputChange={handleEnters}
                                        placeholder={ledger}
                                        options={optionGroup}
                                        classNamePrefix="select2-selection"
                                        onChange={e =>
                                          handlerFinalValue(e, item)
                                        }
                                        type="text"
                                        title="Ledger"
                                        required="required"
                                      />
                                      {/* <AvField
                                        name="institute_ledger"
                                        type="text"
                                        label="Ledger"
                                        onChange={e => {
                                          const value = e.target.value
                                          setSplitLedgers(
                                            splitLedger =>
                                              splitLedger.map(x =>
                                                x === item
                                                  ? { ...x, value }
                                                  : x
                                              )
                                          )
                                        }}
                                      /> */}
                                    </Col>

                                    <Col md="4">
                                      <AvField
                                        name="default_title"
                                        type="text"
                                        label={
                                          language === "mal"
                                            ? "വിവരണങ്ങൾ"
                                            : "Particulars"
                                        }
                                        onChange={e => {
                                          const default_title = e.target.value
                                          setSplitLedgers(splitLedger =>
                                            splitLedger.map(x =>
                                              x === item
                                                ? { ...x, default_title }
                                                : x
                                            )
                                          )
                                        }}
                                        // onChange={(e) => setSplitLedgers({ ...splitLedgers, start_date: (e.target.value) })}
                                      />
                                    </Col>

                                    <Col md="2">
                                      <AvField
                                        name="percentage"
                                        type="number"
                                        label={
                                          language === "mal"
                                            ? "ശതമാനം"
                                            : "Percentage"
                                        }
                                        onChange={e => {
                                          const percentage = e.target.value
                                          setSplitLedgers(splitLedger =>
                                            splitLedger.map(x =>
                                              x === item
                                                ? { ...x, percentage }
                                                : x
                                            )
                                          )
                                        }}
                                        // onChange={(e) => setSplitLedgers({ ...splitLedgers, start_date: (e.target.value) })}
                                      />
                                    </Col>

                                    <Col
                                      md="2"
                                      style={{
                                        display: "flex",
                                        alignItems: "end",
                                      }}
                                    >
                                      <button
                                        className="mx-1 btn"
                                        onClick={e => {
                                          e.preventDefault()
                                          setSplitLedgers(splitLedger =>
                                            splitLedger.filter(x => x !== item)
                                          )
                                        }}
                                      >
                                        <i
                                          className="bx bx-trash text-danger"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                      </button>
                                    </Col>
                                  </Row>
                                </div>
                              )
                            })}
                          </Col>
                        </div>
                      </Col>
                      <p className="text-danger">
                        {percentage > 100 || percentage < 100
                          ? language === "mal"
                            ? "മൊത്തം ശതമാനം 100 ആയിരിക്കണം"
                            : "Total Percentage must be 100"
                          : ""}
                      </p>
                      <div className="d-flex justify-content-end">
                        <Button color="success" type="submit">
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                      {/* <Row>
                        <div className="row mb-4">
                          <Col>
                            <AvField
                              name="mahall_custom_id"
                              label={language === 'mal' ? "മഹല്ല് ഐഡി" : "Mahall ID"}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Label>{language === 'mal' ? "തീയതി " : "Date "}<span className="text-danger">&#42;</span></Label>
                            <AvField
                              name="death_date"
                              type="date"
                              value={today}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: `${language === 'mal' ? 'നിർബന്ധമാണ്!' : 'mandatory!'}`
                                },

                              }}
                            />
                          </Col>
                        </div>
                        <div className="mb-3 row sm:mt-0">
                          <Label>
                            {language === "mal"
                              ? "കുടുംബാംഗം"
                              : "Member"}<span className="text-danger">&#42;</span>
                          </Label>
                          <Select
                            onInputChange={handleEnters}
                            placeholder={familyMember}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="Family Member"
                            required="required"
                          />
                        </div>
                        <div className="row mb-4">
                          <Col>
                            <Label>{language === 'mal' ? "മരണ കാരണം " : "Reason"}</Label>
                            <AvField
                              name="reason"
                              type="text"
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col>
                            <Label>{language === 'mal' ? "ഖബർ വിവരങ്ങൾ" : "Qabr Details"}</Label>
                            <AvField
                              name="qabar"
                              type="textarea"
                            />
                          </Col>
                        </div>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="col-12 btn btn-success save-customer"
                          >
                            {loading && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            )}
                            {language === 'mal' ? "രജിസ്റ്റർ" : "Register"}
                          </button>
                        </div>
                      </Row> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateVarisangyaSplit

CreateVarisangyaSplit.propTypes = {
  history: PropTypes.object,
}
