import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FAMILY_VARISANGYA_WALLETS,
  CREATE_FAMILY_VARISANGYA_WALLET,
  GET_FAMILY_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_VARISANGYA_WALLET_VIEW,
  DELETE_FAMILY_VARISANGYA_WALLET,
} from "./actionTypes"
import {
  getFamilyVarisangyaWalletsSuccess,
  getFamilyVarisangyaWalletsFail,
  createFamilyVarisangyaWalletSuccess,
  createFamilyVarisangyaWalletFail,
  getFamilyVarisangyaWalletDetailSuccess,
  getFamilyVarisangyaWalletDetailFail,
  getFamilyVarisangyaWalletViewSuccess,
  getFamilyVarisangyaWalletViewFail,
  deleteFamilyVarisangyaWalletSuccess,
  deleteFamilyVarisangyaWalletFail,
} from "./actions"

import { get, post, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getFamilyVarisangyaWalletsAPi = ({ searchText, page, sort }) => {
  if (sort === "manual") {
    return get(
      `/api/v1/varisankya/family_varisankya_wallet_transaction/?search=${
        searchText && searchText
      }&page=${page ? page : 1}&is_manual=True`
    )
  } else {
    return get(
      `/api/v1/varisankya/family_varisankya_wallet_transaction/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  }
}
const createFamilyVarisangyaWalletApi = ({ familyVarisangyaWallet }) => {
  return post(
    "/api/v1/varisankya/family_varisankya_wallet_transaction/",
    familyVarisangyaWallet
  )
}
const getFamilyVarisangyaWalletDetailAPi = ({
  familyId,
  page,
  searchText,
  sort,
}) => {
  if (sort === "manual") {
    return get(
      `/api/v1/varisankya/family_varisankya_wallet_transaction/?family=${
        familyId && familyId
      }&search=${searchText && searchText}&page=${
        page ? page : 1
      }&is_manual=True`
    )
  } else {
    return get(
      `/api/v1/varisankya/family_varisankya_wallet_transaction/?family=${
        familyId && familyId
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  }
}

const getFamilyVarisangyaWalletViewAPi = familyVarisangyaWalletId => {
  return get(
    `/api/v1/varisankya/family_varisankya_wallet_transaction/${familyVarisangyaWalletId}/`
  )
}

const deleteFamilyVarisangyaWalletApi = ({ transactionId }) => {
  return del(
    `/api/v1/varisankya/family_varisankya_wallet_transaction/${transactionId}/
    `
  )
}

function* fetchFamilyVarisangyaWallets({ payload }) {
  try {
    const response = yield call(getFamilyVarisangyaWalletsAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyVarisangyaWalletsSuccess(response))
    } else {
      yield put(getFamilyVarisangyaWalletsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyaWalletsFail(error))
  }
}

function* onCreateFamilyVarisangyaWallet({ payload }) {
  try {
    const response = yield call(createFamilyVarisangyaWalletApi, payload)
    if (response && !response?.error) {
      yield put(createFamilyVarisangyaWalletSuccess(response))
      doneNotification("Created Successfully")
    } else {
      yield put(createFamilyVarisangyaWalletFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createFamilyVarisangyaWalletFail(error))
    errorNotification()
  }
}

function* fetchFamilyVarisangyaWalletDetail({ payload }) {
  try {
    const response = yield call(getFamilyVarisangyaWalletDetailAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyVarisangyaWalletDetailSuccess(response))
    } else {
      yield put(getFamilyVarisangyaWalletDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyaWalletDetailFail(error))
  }
}

function* fetchFamilyVarisangyaWalletView({ familyVarisangyaWalletId }) {
  try {
    const response = yield call(
      getFamilyVarisangyaWalletViewAPi,
      familyVarisangyaWalletId
    )
    if (response && !response?.error) {
      yield put(getFamilyVarisangyaWalletViewSuccess(response))
    } else {
      yield put(getFamilyVarisangyaWalletViewFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyaWalletViewFail(error))
  }
}
function* deleteFamilyVarisangyaWallet({ payload }) {
  try {
    const response = yield call(deleteFamilyVarisangyaWalletApi, payload)
    if (response && !response?.error) {
      doneNotification("Transaction Removed Successfully")
      yield put(deleteFamilyVarisangyaWalletSuccess(response))
      const familyDetailsResponse = yield call(
        getFamilyVarisangyaWalletDetailAPi,
        {
          familyId: response?.family,
          page: 1,
          searchText: "",
          sort: "",
        }
      )
      if (familyDetailsResponse) {
        yield put(getFamilyVarisangyaWalletDetailSuccess(familyDetailsResponse))
      }
    } else {
      yield put(deleteFamilyVarisangyaWalletFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(deleteFamilyVarisangyaWalletFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* FamilyVarisangyaWalletsSaga() {
  yield takeEvery(GET_FAMILY_VARISANGYA_WALLETS, fetchFamilyVarisangyaWallets)
  yield takeEvery(
    CREATE_FAMILY_VARISANGYA_WALLET,
    onCreateFamilyVarisangyaWallet
  )
  yield takeEvery(
    GET_FAMILY_VARISANGYA_WALLET_DETAIL,
    fetchFamilyVarisangyaWalletDetail
  )
  yield takeEvery(
    GET_FAMILY_VARISANGYA_WALLET_VIEW,
    fetchFamilyVarisangyaWalletView
  )
  yield takeEvery(DELETE_FAMILY_VARISANGYA_WALLET, deleteFamilyVarisangyaWallet)
}

export default FamilyVarisangyaWalletsSaga
