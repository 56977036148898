import {
  GET_COMMITTEE_MEMBERS_SUCCESS,
  GET_COMMITTEE_MEMBERS_FAIL,
  GET_COMMITTEE_MEMBER_DETAIL_SUCCESS,
  GET_COMMITTEE_MEMBER_DETAIL_FAIL,
  CREATE_COMMITTEE_MEMBER_SUCCESS,
  CREATE_COMMITTEE_MEMBER_FAIL,
  UPDATE_COMMITTEE_MEMBER_SUCCESS,
  UPDATE_COMMITTEE_MEMBER_FAIL,
  DELETE_COMMITTEE_MEMBER_SUCCESS,
  DELETE_COMMITTEE_MEMBER_FAIL,
  GET_COMMITTEE_MEMBERS,
  GET_COMMITTEE_MEMBER_DETAIL,
  UPDATE_COMMITTEE_MEMBER,
  CREATE_COMMITTEE_MEMBER,
  DELETE_COMMITTEE_MEMBER,
  GET_COMMITTEE_MEMBER_DROPDOWN,
  GET_COMMITTEE_MEMBER_DROPDOWN_SUCCESS,
  GET_COMMITTEE_MEMBER_DROPDOWN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  committeeMembers: [],
  committeeMemberDetail: {},
  searchCommitteeMembers: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdCommitteeMember: {},
}

const CommitteeMembers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMITTEE_MEMBERS:
    case GET_COMMITTEE_MEMBER_DROPDOWN:
    case UPDATE_COMMITTEE_MEMBER:
    case CREATE_COMMITTEE_MEMBER:
    case DELETE_COMMITTEE_MEMBER:
      return {
        ...state,
        loading: true,
      }
    case GET_COMMITTEE_MEMBER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_COMMITTEE_MEMBERS_SUCCESS:
      return {
        ...state,
        committeeMembers: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_COMMITTEE_MEMBER_SUCCESS:
      return {
        ...state,
        // committeeMembers: action.payload,
        committeeMembers: {
          ...state.committeeMembers,
          results: [...state.committeeMembers.results, action.payload],
        },
        createdCommitteeMember: action.payload,
        loading: false,
      }

    case CREATE_COMMITTEE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        committeeMemberDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_COMMITTEE_MEMBER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_COMMITTEE_MEMBER_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchCommitteeMembers: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_COMMITTEE_MEMBER_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_COMMITTEE_MEMBER_SUCCESS:
      return {
        ...state,
        committeeMembers: {
          results: state.committeeMembers.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_COMMITTEE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_COMMITTEE_MEMBER_SUCCESS:
      return {
        ...state,
        committeeMembers: {
          results: state.committeeMembers.results.filter(
            committeeMember => committeeMember.id !== action.payload
          ),
        },
        loading: false,
      }

    case DELETE_COMMITTEE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default CommitteeMembers
