import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FEEDS,
  GET_FEED_DETAIL,
  CREATE_FEED,
  UPDATE_FEED,
  DELETE_FEED,
} from "./actionTypes"
import {
  getFeedsSuccess,
  getFeedsFail,
  getFeedDetailSuccess,
  getFeedDetailFail,
  createFeedSuccess,
  createFeedFail,
  updateFeedSuccess,
  updateFeedFail,
  deleteFeedSuccess,
  deleteFeedFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getFeedsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/feed/feed/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/feed/feed/?page=${page ? page : 1}`)
  }
}
const getFeedDetailsAPi = feedId => {
  return get(`/api/v1/feed/feed/${feedId}/`)
}
const createFeedApi = ({ feed }) => {
  return post("/api/v1/feed/feed/", feed)
}
const updateFeedApi = ({ feedId, feed }) => {
  return ApiPut(`/api/v1/feed/feed/${feedId}/`, feed)
}
const deleteFeedApi = ({ feedId }) => {
  return del(`/api/v1/feed/feed/${feedId}/`)
}

function* fetchFeeds({ payload }) {
  try {
    const response = yield call(getFeedsAPi, payload)
    if (response && !response?.error) {
      yield put(getFeedsSuccess(response))
    } else {
      yield put(getFeedsFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getFeedsFail(error))
  }
}

function* fetchFeedDetail({ feedId }) {
  try {
    const response = yield call(getFeedDetailsAPi, feedId)
    if (response && !response?.error) {
      yield put(getFeedDetailSuccess(response))
    } else {
      yield put(getFeedDetailFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getFeedDetailFail(error))
  }
}
function* onCreateFeed({ payload }) {
  try {
    const response = yield call(createFeedApi, payload)
    if (response && !response?.error) {
      yield put(createFeedSuccess(response))
      if (payload.history) {
        payload.history.push("/enquiries")
      }
      doneNotification()
    } else {
      yield put(createFeedFail(response?.error))
      errorNotification()
    }
    // swal({
    //   title: "Enquiry Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createFeedFail(error))
    errorNotification()
  }
}

function* onUpdateFeed({ payload }) {
  try {
    const response = yield call(updateFeedApi, payload)
    if (response && !response?.error) {
      payload.history.push("/enquiries")
      yield put(updateFeedSuccess(response))
      doneNotification()
    } else {
      yield put(updateFeedFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    // console.log("error: ", error)
    yield put(updateFeedFail(error))
    errorNotification()
  }
}

function* onDeleteFeed({ payload }) {
  try {
    const response = yield call(deleteFeedApi, payload)
    if (response && !response?.error) {
      payload.history.push("/feeds")
      yield put(deleteFeedSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteFeedFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteFeedFail(error))
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* FeedsSaga() {
  yield takeEvery(GET_FEEDS, fetchFeeds)
  yield takeEvery(GET_FEED_DETAIL, fetchFeedDetail)
  yield takeEvery(CREATE_FEED, onCreateFeed)
  yield takeEvery(UPDATE_FEED, onUpdateFeed)
  yield takeEvery(DELETE_FEED, onDeleteFeed)
}

export default FeedsSaga
