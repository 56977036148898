import React from "react"
import logo_mahallu from "assets/images/logo/logo.png"

import { Card, CardBody, Container } from "reactstrap"

function Deatails() {
  return (
    <div>
      <Container className="p-5">
        <Card>
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={logo_mahallu}
              width={100}
              height={100}
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center m-0 h4">
            {/* ഖാഫ് മഹല്ല് ശാക്തീകരണ സമിതി */}
            Khaf Mahall Empowerment Committee
          </div>
          <CardBody>
            <div className="">
              {" "}
              <div className="d-flex align-items-center justify-content-center">
                {" "}
                <p className="font-size-16"> Donation Details</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Deatails
