import React, { useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
//actions institute
//componetns
import moment from "moment"

import UiModalCommittee from "./UiModalCommittee"

import Breadcrumbs from "components/Common/Breadcrumb"
import { getCommittees } from "store/actions"
import "swiper/swiper.scss"

const InstituteMadrasaCommitteeView = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { loading, language, committees } = useSelector(state => ({
    loading: state.Committees.loading,
    language: state.Layout.language,
    committees: state.Committees.committees,
  }))

  const madrasaId = location?.search?.split("?madrasaid=")[1]

  const [modalCommittee, setModalCommittee] = useState(false)

  useEffect(() => {
    if (madrasaId) {
      dispatch(getCommittees(madrasaId))
    }
  }, [madrasaId])

  return (
    <>
      <UiModalCommittee
        show={modalCommittee}
        onCloseclick={() => setModalCommittee(false)}
        madrasaId={madrasaId}
      />

      <MetaTags>
        <title>Madrasa Committee | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal"
              ? "മദ്രസ കമ്മിറ്റി വിവരങ്ങൾ"
              : "Madrasa Committee Details"
          }
          breadcrumbItem={
            language === "mal" ? "മദ്രസ കമ്മിറ്റി" : "Madrasa Committee"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            {loading ? (
              <Spinner color="secondary" className="d-block m-auto" />
            ) : (
              <Row>
                <Col lg="12" className="">
                  <Row className="mt-2">
                    <Col md="12" lg="6">
                      {/* <h5>{language === 'mal' ? 'കമ്മിറ്റി' : 'committee'}</h5> */}
                      <Card>
                        <CardBody>
                          <h6 className="text-black">
                            {language === "mal"
                              ? "എല്ലാ കമ്മിറ്റികളും :"
                              : "All Committees :"}
                          </h6>
                          <Container fluid>
                            <Row>
                              {committees?.results &&
                                committees?.results?.committees?.length > 0 &&
                                committees?.results?.committees.map(
                                  (itm, key) => (
                                    <Col
                                      sm="12"
                                      md="12"
                                      lg="12"
                                      key={key}
                                      className="sm:p-0 mt-1"
                                    >
                                      <Card className="blog-stats-wid">
                                        <CardBody
                                          style={{
                                            backgroundColor: "#dcfbf0",
                                            borderRadius: "11px",
                                            minHeight: "130px",
                                          }}
                                        >
                                          <Link
                                            to={`/madrasacommittee/${itm?.id}`}
                                          >
                                            <h5 className="text-black">
                                              {itm.title}
                                            </h5>
                                            <Row>
                                              <Col md="4">
                                                <div className="mx-3">
                                                  <p className="m-0 text-muted pb-1 committe-p-main">
                                                    {" "}
                                                    {language === "mal"
                                                      ? "അവസാന യോഗം : "
                                                      : "Last Meeting  "}{" "}
                                                  </p>

                                                  <div
                                                    className=" p-2 committe-p"
                                                    style={{
                                                      borderRadius: "6px",
                                                      backgroundColor: "white",
                                                      minHeight: "35.5px",
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0 text-black"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {itm.last_meeting_date &&
                                                        moment(
                                                          itm.last_meeting_date
                                                        ).format("DD-MM-yyyy")}
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col md="4">
                                                <div className="mx-3">
                                                  <p className="m-0 text-muted pb-1">
                                                    {" "}
                                                    {language === "mal"
                                                      ? "അംഗങ്ങൾ"
                                                      : "Members"}{" "}
                                                  </p>

                                                  <div
                                                    className=" p-2 "
                                                    style={{
                                                      borderRadius: "6px",
                                                      backgroundColor: "white",
                                                      minHeight: "35.5px",
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0 text-black"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {itm?.members_count
                                                        ? itm?.members_count
                                                        : 0}
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col md="4">
                                                <div className="mx-3">
                                                  <p className="m-0 text-muted pb-1">
                                                    {" "}
                                                    {language === "mal"
                                                      ? "യോഗങ്ങൾ"
                                                      : "Meetings"}{" "}
                                                  </p>

                                                  <div
                                                    className=" p-2 "
                                                    style={{
                                                      borderRadius: "6px",
                                                      backgroundColor: "white",
                                                      minHeight: "35.5px",
                                                    }}
                                                  >
                                                    <p
                                                      className="m-0 text-black"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {itm?.meetings_count
                                                        ? itm?.meetings_count
                                                        : 0}
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Link>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  )
                                )}
                            </Row>
                            <Button
                              onClick={() => setModalCommittee(true)}
                              className={
                                committees?.results &&
                                committees?.results?.committees?.length <= 0
                                  ? "bg-khaf w-100 mt-4"
                                  : "bg-khaf w-100"
                              }
                            >
                              {language === "mal"
                                ? "+ പുതിയത് ചേർക്കുക"
                                : "+ Add New Committee"}
                            </Button>
                          </Container>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default InstituteMadrasaCommitteeView

InstituteMadrasaCommitteeView.propTypes = {
  history: PropTypes.object,
}
