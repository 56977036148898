import React from "react"
import { Container } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useSelector } from "react-redux"

import DatatableTables from "./DatatableTables"

import Breadcrumbs from "components/Common/Breadcrumb"

function FilteredMemberNotifications() {
  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  return (
    <>
      <MetaTags>
        <title>Notifications | Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഡാഷ്ബോർഡ്" : "Dashboard"}
          breadcrumbItem={language === "mal" ? "അറിയിപ്പുകൾ" : "Notifications"}
        />
        <Container fluid className="sm:p-0">
          <div className="container-fluid sm:p-0">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default FilteredMemberNotifications
