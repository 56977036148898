import React, { useEffect } from "react"
import { Col, Row, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

//action

import WelcomeComp from "./WelcomeComp"

import { getMahallsCards } from "store/actions"

function GroupAdminDashboard() {
  const dispatch = useDispatch()

  const { language, mahallsCards, loading } = useSelector(state => ({
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    mahallsCards: state.Mahalls.mahallsCards,
    loading: state.Mahalls.loading,
  }))

  // useEffect(() => {
  //   dispatch(getMahallUsersCards())
  //   dispatch(getFamiliesCards())
  // }, [dispatch])

  useEffect(() => {
    dispatch(getMahallsCards())
  }, [dispatch])

  const cardData = [
    {
      title: `${language === "mal" ? "മഹല്ലുകൾ" : "Mahalls"}`,
      cardValue: mahallsCards?.total_mahalls ?? 0,
      iconClass: "home",
      routeLink: "/",
      loading: loading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Active Users"}`,
    },
    // {
    //   title: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members"}`,
    //   cardValue: dashboardCard?.members,
    //   iconClass: "group",
    //   routeLink: "/",
    //   loading: loading,
    //   alt: `${language === "mal" ? "ഇല്ല" : "No Inactive Users"}`,
    // },
  ]

  return (
    <Row>
      <Col lg="12" style={{ marginTop: "95px" }} className="mx-3 pe-5">
        <Row style={{ minHeight: "52vh", height: "auto" }}>
          <Col lg="4">
            <WelcomeComp />
          </Col>
          <Col lg="8">
            <Row>
              {cardData?.map((item, key) => (
                <Col sm="12" md="6" lg="4" key={key} className="sm:p-0">
                  <Card className="blog-stats-wid">
                    <CardBody>
                      <Link to={item.routeLink && item.routeLink}>
                        <div className="d-flex flex-wrap">
                          <div className="me-3">
                            <p className="text-muted mb-3">{item.title}</p>
                            {item.cardValue && (
                              <h5 className="mb-0">{item.cardValue}</h5>
                            )}
                            <p className="m-0 text-info">
                              {item.cardValue !== "" ? <></> : item.alt}
                            </p>
                          </div>

                          <div className="avatar-sm ms-auto">
                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                              <i
                                className={"bx bxs-" + `${item.iconClass}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="m-2 mt-0">
          {/* <CardContact image={profileImage} title='Profile' link='/' /> */}
          {/* <CardContact
            image={mapImage}
            title="Mahalls"
            link="/mahalls"
          /> */}
          {/* <CardContact
            image={familiesImage}
            title="Families"
            link="/families"
          />
          <CardContact
            image={familymembersImage}
            title="Members"
            link="/familymembers"
          /> */}
        </Row>
      </Col>
    </Row>
  )
}

export default GroupAdminDashboard
