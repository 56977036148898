import {
  GET_NOC_CERTIFICATES,
  GET_NOC_CERTIFICATES_SUCCESS,
  GET_NOC_CERTIFICATES_FAIL,
  UPDATE_NOC_CERTIFICATE,
  CREATE_NOC_CERTIFICATE,
  DELETE_NOC_CERTIFICATE,
  GET_NOC_CERTIFICATE_DETAIL,
  CREATE_NOC_CERTIFICATE_SUCCESS,
  CREATE_NOC_CERTIFICATE_FAIL,
  GET_NOC_CERTIFICATE_DETAIL_SUCCESS,
  GET_NOC_CERTIFICATE_DETAIL_FAIL,
  UPDATE_NOC_CERTIFICATE_SUCCESS,
  UPDATE_NOC_CERTIFICATE_FAIL,
  DELETE_NOC_CERTIFICATE_SUCCESS,
  DELETE_NOC_CERTIFICATE_FAIL,
  GET_NOC_CERTIFICATES_FOR_DOWNLOAD,
  GET_NOC_CERTIFICATES_FOR_DOWNLOAD_SUCCESS,
  GET_NOC_CERTIFICATES_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  nocCertificates: [],
  nocCertificateForDownload: [],
  nocCertificateDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdNocCertificate: {},
}

const NocCertificates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOC_CERTIFICATES:
    case GET_NOC_CERTIFICATES_FOR_DOWNLOAD:
    case UPDATE_NOC_CERTIFICATE:
    case CREATE_NOC_CERTIFICATE:
    case DELETE_NOC_CERTIFICATE:
      return {
        ...state,
        loading: true,
      }
    case GET_NOC_CERTIFICATE_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_NOC_CERTIFICATES_SUCCESS:
      return {
        ...state,
        nocCertificates: action.payload,
        loading: false,
      }
    case GET_NOC_CERTIFICATES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_NOC_CERTIFICATES_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        nocCertificateForDownload: action.payload,
        loading: false,
      }

    case GET_NOC_CERTIFICATES_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_NOC_CERTIFICATE_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdNocCertificate: action.payload,
        loading: false,
      }

    case CREATE_NOC_CERTIFICATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_NOC_CERTIFICATE_DETAIL_SUCCESS:
      return {
        ...state,
        nocCertificateDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_NOC_CERTIFICATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_NOC_CERTIFICATE_SUCCESS:
      return {
        ...state,
        nocCertificateDetail: action.payload,
        loading: false,
      }

    case UPDATE_NOC_CERTIFICATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_NOC_CERTIFICATE_SUCCESS:
      return {
        ...state,
        // states: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // enquiries: state.enquiries.filter(
        //   noc => noc.id.tostring() !== action.payload.id.tostring()
        // ),
        // enquiries: action.payload,
        loading: false,
      }

    case DELETE_NOC_CERTIFICATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default NocCertificates
