import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FAMILY_VARISANGYA_ITEMS,
  CREATE_FAMILY_VARISANGYA_ITEM,
  DELETE_FAMILY_VARISANGYA_ITEM,
} from "./actionTypes"
import {
  getFamilyVarisangyaItemsSuccess,
  getFamilyVarisangyaItemsFail,
  createFamilyVarisangyaItemSuccess,
  createFamilyVarisangyaItemFail,
  deleteFamilyVarisangyaItemSuccess,
  deleteFamilyVarisangyaItemFail,
} from "./actions"

import { get, post, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getFamilyVarisangyaItemsAPi = ({ varisangyaId, searchText, page }) => {
  if (varisangyaId) {
    return get(
      `/api/v1/varisankya/family_varisankya_item/?family_varisankya=${
        varisangyaId ? varisangyaId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/varisankya/family_varisankya_item/?search=${
        searchText ? searchText : ""
      }&page=${page ? page : 1}`
    )
  }
}
const createFamilyVarisangyaItemApi = ({ familyVarisangyaItem }) => {
  return post(
    "/api/v1/varisankya/family_varisankya_single_entry_amount_update_in_filter/",
    familyVarisangyaItem
  )
}
const deleteFamilyVarisangyaItemApi = ({ familyVarisangyaItemId }) => {
  return del(
    `/api/v1/varisankya/family_varisankya_item/${familyVarisangyaItemId}/`
  )
}

function* fetchFamilyVarisangyaItems({ payload }) {
  try {
    const response = yield call(getFamilyVarisangyaItemsAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyVarisangyaItemsSuccess(response))
    } else {
      yield put(getFamilyVarisangyaItemsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyaItemsFail(error))
  }
}
function* onCreateFamilyVarisangyaItem({ payload }) {
  try {
    const response = yield call(createFamilyVarisangyaItemApi, payload)
    if (response && !response?.error) {
      yield put(createFamilyVarisangyaItemSuccess(response))
      if (payload.history) {
        payload.history.push(
          `/family/varisangyas/${response?.family_varisankya}`
        )
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createFamilyVarisangyaItemFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createFamilyVarisangyaItemFail(error))
    errorNotification()
  }
}

function* onDeleteFamilyVarisangyaItem({ payload }) {
  try {
    const response = yield call(deleteFamilyVarisangyaItemApi, payload)
    if (response && !response?.error) {
      yield put(
        deleteFamilyVarisangyaItemSuccess(payload.familyVarisangyaItemId)
      )
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteFamilyVarisangyaItemFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteFamilyVarisangyaItemFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* FamilyVarisangyaItemsSaga() {
  yield takeEvery(GET_FAMILY_VARISANGYA_ITEMS, fetchFamilyVarisangyaItems)
  yield takeEvery(CREATE_FAMILY_VARISANGYA_ITEM, onCreateFamilyVarisangyaItem)
  yield takeEvery(DELETE_FAMILY_VARISANGYA_ITEM, onDeleteFamilyVarisangyaItem)
}

export default FamilyVarisangyaItemsSaga
