import {
  GET_INSTITUTE_WALLETS,
  GET_INSTITUTE_WALLETS_SUCCESS,
  GET_INSTITUTE_WALLETS_FAIL,
  GET_INSTITUTE_WALLET_DROPDOWN,
  GET_INSTITUTE_WALLET_DROPDOWN_SUCCESS,
  GET_INSTITUTE_WALLET_DROPDOWN_FAIL,
  GET_INSTITUTE_WALLET_DETAIL,
  GET_INSTITUTE_WALLET_DETAIL_SUCCESS,
  GET_INSTITUTE_WALLET_DETAIL_FAIL,
  CREATE_INSTITUTE_WALLET,
  CREATE_INSTITUTE_WALLET_SUCCESS,
  CREATE_INSTITUTE_WALLET_FAIL,
  UPDATE_INSTITUTE_WALLET,
  UPDATE_INSTITUTE_WALLET_SUCCESS,
  UPDATE_INSTITUTE_WALLET_FAIL,
  DELETE_INSTITUTE_WALLET,
  DELETE_INSTITUTE_WALLET_SUCCESS,
  DELETE_INSTITUTE_WALLET_FAIL,
  GET_INSTITUTE_WALLETS_DOWNLOAD,
  GET_INSTITUTE_WALLETS_DOWNLOAD_SUCCESS,
  GET_INSTITUTE_WALLETS_DOWNLOAD_FAIL,
} from "./actionTypes"

export const getInstituteWallets = (instituteId, searchText, page) => ({
  type: GET_INSTITUTE_WALLETS,
  payload: { instituteId, searchText, page },
})

export const getInstituteWalletsSuccess = instituteWallets => ({
  type: GET_INSTITUTE_WALLETS_SUCCESS,
  payload: instituteWallets,
})
export const getInstituteWalletsFail = error => ({
  type: GET_INSTITUTE_WALLETS_FAIL,
  payload: error,
})

export const getInstituteWalletsDownload = (instituteId, searchText) => ({
  type: GET_INSTITUTE_WALLETS_DOWNLOAD,
  payload: { instituteId, searchText },
})

export const getInstituteWalletsDownloadSuccess = instituteWalletsDownload => ({
  type: GET_INSTITUTE_WALLETS_DOWNLOAD_SUCCESS,
  payload: instituteWalletsDownload,
})
export const getInstituteWalletsDownloadFail = error => ({
  type: GET_INSTITUTE_WALLETS_DOWNLOAD_FAIL,
  payload: error,
})

export const getInstituteWalletDropdown = (instituteId, searchText) => ({
  type: GET_INSTITUTE_WALLET_DROPDOWN,
  payload: { instituteId, searchText },
})

export const getInstituteWalletDropdownSuccess = instituteWallets => ({
  type: GET_INSTITUTE_WALLET_DROPDOWN_SUCCESS,
  payload: instituteWallets,
})
export const getInstituteWalletDropdownFail = error => ({
  type: GET_INSTITUTE_WALLET_DROPDOWN_FAIL,
  payload: error,
})

export const getInstituteWalletDetail = instituteWalletId => ({
  type: GET_INSTITUTE_WALLET_DETAIL,
  instituteWalletId,
})

export const getInstituteWalletDetailSuccess = instituteWalletDetail => ({
  type: GET_INSTITUTE_WALLET_DETAIL_SUCCESS,
  payload: instituteWalletDetail,
})

export const getInstituteWalletDetailFail = error => ({
  type: GET_INSTITUTE_WALLET_DETAIL_FAIL,
  payload: error,
})

export const createInstituteWallet = instituteWallet => ({
  type: CREATE_INSTITUTE_WALLET,
  payload: { instituteWallet },
})

export const createInstituteWalletSuccess = instituteWallet => ({
  type: CREATE_INSTITUTE_WALLET_SUCCESS,
  payload: instituteWallet,
})

export const createInstituteWalletFail = error => ({
  type: CREATE_INSTITUTE_WALLET_FAIL,
  payload: error,
})

export const updateInstituteWallet = (instituteWallet, instituteWalletId) => ({
  type: UPDATE_INSTITUTE_WALLET,
  payload: { instituteWallet, instituteWalletId },
})

export const updateInstituteWalletSuccess = instituteWallet => ({
  type: UPDATE_INSTITUTE_WALLET_SUCCESS,
  payload: instituteWallet,
})

export const updateInstituteWalletFail = error => ({
  type: UPDATE_INSTITUTE_WALLET_FAIL,
  payload: error,
})

export const deleteInstituteWallet = instituteWalletId => ({
  type: DELETE_INSTITUTE_WALLET,
  payload: { instituteWalletId },
})

export const deleteInstituteWalletSuccess = instituteWallet => ({
  type: DELETE_INSTITUTE_WALLET_SUCCESS,
  payload: instituteWallet,
})

export const deleteInstituteWalletFail = error => ({
  type: DELETE_INSTITUTE_WALLET_FAIL,
  payload: error,
})
