import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import { getStateDetail, updateState } from "store/state/actions"
import Breadcrumbs from "components/Common/Breadcrumb"

const UpdateState = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { stateDetail } = useSelector(state => ({
    stateDetail: state.States.stateDetail,
  }))

  function handleValidSubmit(values) {
    dispatch(updateState(values, stateDetail.id, history))
    window.scroll(0, 0)
  }

  useEffect(() => {
    dispatch(getStateDetail(params.id))
  }, [])

  return (
    <>
      <MetaTags>
        <title>State | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="States" breadcrumbItem="Update" />
        <Container fluid>
          <div className="container-fluid mt-5">
            <Row>
              <Col sm="12" md="12" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Update State</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="state_english_name"
                            type="text"
                            label="State Name (English)"
                            value={stateDetail?.state_english_name || ""}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter state name",
                              },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="state_malayalam_name"
                            type="text"
                            label="State Name (മലയാളം)"
                            value={stateDetail?.state_malayalam_name || ""}
                          />
                        </Col>
                      </div>

                      <div className="row justify-content-end">
                        <Col>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Update
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateState

UpdateState.propTypes = {
  history: PropTypes.object,
}
