import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter, Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const MahallUserSidebarContentPremium = props => {
  const ref = useRef()
  // const history = useHistory()
  // const dispatch = useDispatch()

  const { language } = useSelector(state => ({
    leftMenu: state.Layout.leftMenu,
    userDetail: state.Users.userDetail,
    institutes: state.Institutes.institutes,
    language: state.Layout.language,
  }))

  // const instituteType = 'mahall'

  // useEffect(() => {
  //   dispatch(getInstitutes('', 1, instituteType))
  // }, [dispatch, instituteType])

  // console.log(institutes);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  // const handlePremium = heading => {
  //   if (heading === 'Institutes') {
  //     return true
  //   }
  //   if (heading === 'Accounts') {
  //     return true
  //   }
  //   if (heading === 'Committees') {
  //     return true
  //   }
  //   if (heading === 'Members') {
  //     return true
  //   }
  //   if (heading === 'Meetings') {
  //     return true
  //   }
  //   if (heading === 'Meeting Attendances') {
  //     return true
  //   }
  //   if (heading === 'Institute Users') {
  //     return true
  //   }
  // }

  // const handlePremiumLink = path => {
  //   if (userDetail?.mahall?.version === 'premium') {
  //     return path
  //   } else {
  //     return '/premium'
  //   }
  // }

  // const [selectedTag, setSelectedTag] = useState('')

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className="mt-4">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="">
                <i className="bx bxs-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            {/* {userDetail?.mahall?.version === 'basic' ?

            <li className="premium">
              <Link to="/">
                <span className="badge rounded-pill  float-end" style={{fontSize:'0px'}}>
                  <i className="bx bx-crown" style={{fontSize:'15px', color: "gold"}}></i>
                </span>
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Profile")}</span>
              </Link>
            </li>
            :
            <li>
              <Link to="/profile">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Profile")}</span>
              </Link>
            </li>
            } */}

            {/* Users */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-user"></i>
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/mahallusers">{props.t("Mahall Users")}</Link>
                </li>
                <li>
                  <Link to="/surveyusers">{props.t("Survey Users")}</Link>
                </li>
                <li>
                  <Link to="/instituteusers">{props.t("Institute Users")}</Link>
                </li>
                {/* <li>
                  <Link to="/madrasausers">{props.t('Madrasa Users')}</Link>
                </li> */}
              </ul>
            </li>

            {/* Mahall Main */}
            <li>
              <Link to="/mahallmasters">
                <i className="bx bx-globe"></i>
                <span>{props.t("Mahall Main")}</span>
              </Link>
            </li>

            {/* Master Account */}

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-spreadsheet"></i>
                <span>{props.t("Master Accounts")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/accounts">{props.t("Institute Accounts")}</Link>
                </li>
                <li>
                  <Link to="/institute/categories">
                    {props.t("Institute Categories")}
                  </Link>
                </li>
                <li>
                  <Link to="/institute/wallets">
                    {props.t("Institute Wallets")}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/category/ledger/items">
                    {props.t('Category Reports')}
                  </Link>
                </li> */}
                <li>
                  <Link to="/ledgers">{props.t("Ledgers")}</Link>
                </li>
                <li>
                  <Link to="/ledger/items">{props.t("Ledger Items")}</Link>
                </li>
              </ul>
            </li>
            {/* Settings */}
            {/* <li>
              <Link to={``} className="has-arrow">
                <i className="bx bxs-cog"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/varisangya/splits">
                    {props.t("Split Ledgers")}
                  </Link>
                </li>
              </ul>
            </li> */}

            {/* Notification */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-conversation"></i>
                <span>{props.t("Notifications")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/notifications">{props.t("Individual")}</Link>
                </li>
                <li>
                  <Link to="/collection-notifications">
                    {props.t("Collection")}
                  </Link>
                </li>
              </ul>
            </li>

            {/* Reports */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-report"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/report/areas">{props.t("Area")}</Link>
                </li>
                <li>
                  <Link to="/report/bloodgroups">{props.t("Blood Bank")}</Link>
                </li>
                <li>
                  <Link to="/report/orphans">{props.t("Orphans")}</Link>
                </li>
              </ul>
            </li>

            {/* Social */}
            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-slideshow"></i>
                <span>{props.t("Social")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li >
                  <Link to="/banners">{props.t("Banners")}</Link>
                </li>
                <li >
                  <Link to="/feeds">
                    {props.t("Feeds")}
                  </Link>
                </li>
              </ul>
            </li> */}

            <li className="menu-title mt-3">
              {props.t(`${language === "mal" ? "ഡാറ്റ" : "DATA"}`)}{" "}
            </li>
            {/* Families */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home"></i>
                <span>{props.t("Families")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/families">{props.t("All Families")}</Link>
                </li>
                <li>
                  <Link to="/family/create">{props.t("Create Family")}</Link>
                </li>
                <li>
                  <Link to="/unapprovedfamilies">{props.t("Unapproved")}</Link>
                </li>
              </ul>
            </li>

            {/* Members */}
            <li>
              <Link to="/familymembers">
                <i className="bx bx-group"></i>
                <span>{props.t("Members")}</span>
              </Link>
            </li>

            {/* Collection */}
            <li>
              <Link to="/collections">
                <i className="bx bx-hive  "></i>
                <span>{props.t("Collections")}</span>
              </Link>
            </li>

            {/* Institutes */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-chevron-up-circle"></i>
                <span>{props.t("Institutes")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/institutes">{props.t("All Institutes")}</Link>
                </li>
                <li>
                  <Link to="/institute/create?type=institute">
                    {props.t("Create Institute")}
                  </Link>
                </li>
              </ul>
            </li>

            {/* Programs */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-calendar-event"></i>
                <span>{props.t("Programs")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/programes">{props.t("All Programs")}</Link>
                </li>
                <li>
                  <Link to="/program/create?type=program">
                    {props.t("Create Programme")}
                  </Link>
                </li>
              </ul>
            </li>

            {/* Madrasas */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-chevron-up-circle"></i>
                <span>{props.t("Madrasa")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/madrasas">{props.t("All Madrasa")}</Link>
                </li>
                <li>
                  <Link to="/madrasa/create/new?type=madrasa">
                    {props.t("Create Madrasa")}
                  </Link>
                </li>
              </ul>
            </li>

            {/* Committees */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-chat"></i>
                <span>{props.t("Committees")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/committees">{props.t("All Committees")}</Link>
                </li>
                {/* <li >
                  <Link to="/committee/create">
                    {props.t("Create Committee")}
                  </Link>
                </li> */}
                <li>
                  <Link to="/committee/meetings">
                    {props.t("All Meetings")}
                  </Link>
                </li>
                {/* <li >
                  <Link to="/meeting/create">{props.t("Create Meeting")}</Link>
                </li> */}
              </ul>
            </li>

            <li className="menu-title mt-3">
              {props.t(
                `${language === "mal" ? "രജിസ്ട്രേഷനുകൾ" : "REGISTRATIONS"}`
              )}{" "}
            </li>

            {/* Registrations */}
            <li>
              <Link to="/nikahs">
                <i className="bx bx-food-menu"></i>
                <span>{props.t("Nikah Registrations")}</span>
              </Link>
            </li>
            {/* <li >
              <Link to="/births">{props.t("Birth Registrations")}</Link>
            </li> */}
            <li>
              {/* <Link to="/deaths">{props.t("Death Registrations")}</Link> */}
              <Link to="/deaths">
                <i className="bx bx-error-circle"></i>
                <span>{props.t("Death Registrations")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/noc">
                <i className="bx bx-task"></i>
                <span>{props.t('N.O.C')}</span>
              </Link>
            </li> */}

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-task"></i>
                <span>{props.t("N.O.C")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/noc?noc_type=Others">{props.t("Commen NOC")}</Link>
                </li>
                <li>
                  <Link to="/noc?noc_type=Nikah_registration">
                    {props.t("Nikah NOC")}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/nikah/noc">{props.t('Nikah N.O.C')}</Link>
                </li> */}
              </ul>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-task"></i>
                <span>{props.t("Registrations")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li >
                  <Link to="/nikahs">{props.t("Nikah Registrations")}</Link>
                </li>
                <li >
                  <Link to="/deaths">{props.t("Death Registrations")}</Link>
                </li>
                <li >
                  <Link to="/noc">{props.t("N.O.C")}</Link>
                </li>
              </ul>
            </li> */}

            <li className="menu-title mt-3">
              {props.t(`${language === "mal" ? "സമാഹാരം" : "COLLECTIBLES"}`)}{" "}
            </li>

            {/* Varisangya */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-money"></i>
                <span>{props.t("Family Varisangya")}</span>
              </Link>

              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/family/varisangyas">{props.t("List")}</Link>
                </li>
                <li>
                  <Link to="/family/varisangya/transactions">
                    {props.t("Transactions")}
                  </Link>
                </li>
                <li>
                  <Link to="/family/wallet/details">
                    {props.t("Wallet History")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-money"></i>
                <span>{props.t("Member Varisangya")}</span>
              </Link>

              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/family/member/varisangyas">{props.t("List")}</Link>
                </li>
                <li>
                  <Link to="/family/member/varisangya/transactions">
                    {props.t("Transactions")}
                  </Link>
                </li>
                <li>
                  <Link to="/family/member/wallet/details">
                    {props.t("Wallet History")}
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-money"></i>
                <span>{props.t('Student Varisangya')}</span>
              </Link>

              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/student/varisangyas">{props.t('List')}</Link>
                </li>
                <li>
                  <Link to="/student/varisangya/transactions">
                    {props.t('Transactions')}
                  </Link>
                </li>
                <li>
                  <Link to="/student/wallet/details">
                    {props.t('Wallet History')}
                  </Link>
                </li>
              </ul>
            </li> */}

            {/* Zakat */}
            <li>
              <Link to="/zakats">
                <i className="bx bxs-bank"></i>
                <span>{props.t("Zakat")}</span>
              </Link>
            </li>

            {/* Asset */}
            {/* <li>
              <Link to="/assets">
                <i className="bx bxs-bank"></i>
                <span>{props.t("Assets")}</span>
              </Link>
            </li> */}

            <li className="menu-title mt-3">
              {props.t(`${language === "mal" ? "സാമൂഹികം" : "SOCIAL"}`)}{" "}
            </li>

            {/* Banner */}
            <li>
              <Link to="/banners">
                <i className="bx bx-photo-album"></i>
                <span>{props.t("Banners")}</span>
              </Link>
            </li>

            {/* Feed */}
            <li>
              <Link to="/feeds">
                <i className="bx bx-slideshow"></i>
                <span>{props.t("Feeds")}</span>
              </Link>
            </li>
            {/*  Super Feed */}
            <li>
              <Link to="/super-feeds">
                <i className="bx bx-slideshow"></i>
                <span>{props.t("Super Feeds")}</span>
              </Link>
            </li>
            {/* Activity Logs */}
            <li>
              <Link to="/activitylogs">
                <i className="bx bx-history"></i>
                <span>{props.t("Activity Logs")}</span>
              </Link>
            </li>
            {/* Support */}
            <li>
              <Link to="/support-ticket">
                <i className="bx bx-support"></i>
                <span>{props.t("Support")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-folder-open"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li >
                  <Link to="/reports">{props.t("All Reports")}</Link>
                </li>
                <li >
                  <Link to="/report/create">{props.t("Create Report")}</Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
        <div
          className="text-center text-khaf-blue"
          style={{ marginBottom: "80px" }}
        >
          <hr />
          <p>version 3.1.0</p>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

MahallUserSidebarContentPremium.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MahallUserSidebarContentPremium))
