import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COMMITTEE_MEETING_IMAGES,
  GET_COMMITTEE_MEETING_IMAGE_DETAIL,
  CREATE_COMMITTEE_MEETING_IMAGE,
  UPDATE_COMMITTEE_MEETING_IMAGE,
  DELETE_COMMITTEE_MEETING_IMAGE,
} from "./actionTypes"
import {
  getCommitteeMeetingImagesSuccess,
  getCommitteeMeetingImagesFail,
  getCommitteeMeetingImageDetailSuccess,
  getCommitteeMeetingImageDetailFail,
  createCommitteeMeetingImageSuccess,
  createCommitteeMeetingImageFail,
  updateCommitteeMeetingImageSuccess,
  updateCommitteeMeetingImageFail,
  deleteCommitteeMeetingImageSuccess,
  deleteCommitteeMeetingImageFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getCommitteeMeetingImagesAPi = ({ committeeMeetingId }) => {
  if (committeeMeetingId) {
    return get(
      `/api/v1/committee/committee_meeting_images/?committee_meeting=${
        committeeMeetingId && committeeMeetingId
      }`
    )
  } else {
    return get(`/api/v1/committee/committee_meeting_images/`)
  }
}
const getCommitteeMeetingImageDetailsAPi = committeeMeetingImageId => {
  return get(
    `/api/v1/committee/committee_meeting_images/${committeeMeetingImageId}/`
  )
}
const createCommitteeMeetingImageApi = ({ committeeMeetingImage }) => {
  return post(
    "/api/v1/committee/committee_meeting_images/",
    committeeMeetingImage
  )
}
const updateCommitteeMeetingImageApi = ({
  committeeMeetingImageId,
  committeeMeetingImage,
}) => {
  return ApiPut(
    `/api/v1/committee/committee_meeting_images/${committeeMeetingImageId}/`,
    committeeMeetingImage
  )
}
const deleteCommitteeMeetingImageApi = ({ committeeMeetingImageId }) => {
  return del(
    `/api/v1/committee/committee_meeting_images/${committeeMeetingImageId}/`
  )
}

function* fetchCommitteeMeetingImages({ payload }) {
  try {
    const response = yield call(getCommitteeMeetingImagesAPi, payload)
    if (response && !response?.error) {
      yield put(getCommitteeMeetingImagesSuccess(response))
    } else {
      yield put(getCommitteeMeetingImagesFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getCommitteeMeetingImagesFail(error))
  }
}

function* fetchCommitteeMeetingImageDetail({ committeeMeetingImageId }) {
  try {
    const response = yield call(
      getCommitteeMeetingImageDetailsAPi,
      committeeMeetingImageId
    )
    yield put(getCommitteeMeetingImageDetailSuccess(response))
  } catch (error) {
    yield put(getCommitteeMeetingImageDetailFail(error))
  }
}
function* onCreateCommitteeMeetingImage({ payload }) {
  try {
    const response = yield call(createCommitteeMeetingImageApi, payload)
    yield put(createCommitteeMeetingImageSuccess(response))
    payload.history.goBack()
    // TODO: check the route againe -> /api/v1/committee/meetings
    doneNotification("Created Successfully")
  } catch (error) {
    yield put(createCommitteeMeetingImageFail(error))
    errorNotification()
  }
}

function* onUpdateCommitteeMeetingImage({ payload }) {
  try {
    const response = yield call(updateCommitteeMeetingImageApi, payload)
    yield put(updateCommitteeMeetingImageSuccess(response))
    payload.history.goBack()
    doneNotification("Updated Successfully")
  } catch (error) {
    yield put(updateCommitteeMeetingImageFail(error))
    errorNotification()
  }
}

function* onDeleteCommitteeMeetingImage({ payload }) {
  try {
    const response = yield call(deleteCommitteeMeetingImageApi, payload)
    console.log("imageerror:", response?.error)
    if (response && !response?.error) {
      yield put(deleteCommitteeMeetingImageSuccess(response))
      doneNotification("Deleted Successfully")
      try {
        const response = yield call(getCommitteeMeetingImagesAPi, payload)
        yield put(getCommitteeMeetingImagesSuccess(response))
      } catch (error) {
        yield put(getCommitteeMeetingImagesFail(error))
      }
    } else {
      yield put(deleteCommitteeMeetingImageFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    console.log("error:", error)
    errorNotification()
    yield put(deleteCommitteeMeetingImageFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* CommitteeMeetingImagesSaga() {
  yield takeEvery(GET_COMMITTEE_MEETING_IMAGES, fetchCommitteeMeetingImages)
  yield takeEvery(
    GET_COMMITTEE_MEETING_IMAGE_DETAIL,
    fetchCommitteeMeetingImageDetail
  )
  yield takeEvery(CREATE_COMMITTEE_MEETING_IMAGE, onCreateCommitteeMeetingImage)
  yield takeEvery(UPDATE_COMMITTEE_MEETING_IMAGE, onUpdateCommitteeMeetingImage)
  yield takeEvery(DELETE_COMMITTEE_MEETING_IMAGE, onDeleteCommitteeMeetingImage)
}

export default CommitteeMeetingImagesSaga
