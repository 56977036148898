import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"

import { createInstituteWallet } from "store/actions"

const UiModalInstituteWallet = ({ show, onCloseclick }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const { loading, language, instituteId } = useSelector(state => ({
    loading: state.Assets.loading,
    language: state.Layout.language,
    instituteId: state.Institutes.instituteDetail?.id,
  }))

  const [isBank, setIsBank] = useState(false)

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const createInstituteWalletData = {
      institute: instituteId,
      institute_wallet_name: values.institute_wallet_name,
      is_bank: isBank,
      description: values.description,
    }
    dispatch(createInstituteWallet(createInstituteWalletData))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "വാലറ്റ് ചേർക്കുക" : "Create Wallet"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "വാലറ്റ് പേര്" : "Wallet Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="institute_wallet_name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    label={language === "mal" ? "റിമാർക്" : "Remark"}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col>
                  <Label for="basicpill-email-input4">
                    {language === "mal" ? "ബാങ്ക് ആണോ?" : "Is Bank?"}
                  </Label>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                      checked={isBank ? true : false}
                      onChange={() => setIsBank(!isBank)}
                    />
                  </div>
                </Col>
              </div>
              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalInstituteWallet
UiModalInstituteWallet.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
