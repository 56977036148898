import {
  GET_EDUCATION_FAMILY_MEMBER_SUCCESS,
  GET_EDUCATION_FAMILY_MEMBER_FAIL,
  GET_EDUCATION_FAMILY_MEMBER_DETAIL_SUCCESS,
  GET_EDUCATION_FAMILY_MEMBER_DETAIL_FAIL,
  CREATE_EDUCATION_FAMILY_MEMBER_SUCCESS,
  CREATE_EDUCATION_FAMILY_MEMBER_FAIL,
  UPDATE_EDUCATION_FAMILY_MEMBER_SUCCESS,
  UPDATE_EDUCATION_FAMILY_MEMBER_FAIL,
  DELETE_EDUCATION_FAMILY_MEMBER_SUCCESS,
  DELETE_EDUCATION_FAMILY_MEMBER_FAIL,
  GET_EDUCATION_FAMILY_MEMBER,
  GET_EDUCATION_FAMILY_MEMBER_DETAIL,
  UPDATE_EDUCATION_FAMILY_MEMBER,
  CREATE_EDUCATION_FAMILY_MEMBER,
  DELETE_EDUCATION_FAMILY_MEMBER,
} from "./actionTypes"

const INIT_STATE = {
  educations: [],
  educationDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdEducation: {},
}

const EducationFamilyMembers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EDUCATION_FAMILY_MEMBER:
    case UPDATE_EDUCATION_FAMILY_MEMBER:
    case CREATE_EDUCATION_FAMILY_MEMBER:
    case DELETE_EDUCATION_FAMILY_MEMBER:
      return {
        ...state,
        loading: true,
      }
    case GET_EDUCATION_FAMILY_MEMBER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_EDUCATION_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        educations: action.payload,
        loading: false,
      }

    case GET_EDUCATION_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_EDUCATION_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        educations: action.payload,
        createdEducation: action.payload,
        loading: false,
      }

    case CREATE_EDUCATION_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_EDUCATION_FAMILY_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        educationDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_EDUCATION_FAMILY_MEMBER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_EDUCATION_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        educationDetail: action.payload,
        loading: false,
      }

    case UPDATE_EDUCATION_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_EDUCATION_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        educations: state.educations.filter(
          education => education.id.toString() !== action.payload.id.toString()
        ),
        loading: false,
      }

    case DELETE_EDUCATION_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default EducationFamilyMembers
