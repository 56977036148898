import {
  ADMIN_ALL_DONATIONS,
  ADMIN_ALL_DONATIONS_FAIL,
  ADMIN_ALL_DONATIONS_SUCCESS,
  ADMIN_DONATION_DETAILS,
  ADMIN_DONATION_DETAILS_FAIL,
  ADMIN_DONATION_DETAILS_SUCCESS,
  CREATE_TRANSACTIONS,
  CREATE_TRANSACTIONS_FAIL,
  CREATE_TRANSACTIONS_SUCCESS,
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTION_DETAILS_FAIL,
  GET_TRANSACTION_DETAILS_SUCCESS,
} from "./actionTypes"

export const createTransations = (createData, history) => ({
  type: CREATE_TRANSACTIONS,
  payload: { createData, history },
})

export const createTransationsSuccess = transactions => ({
  type: CREATE_TRANSACTIONS_SUCCESS,
  payload: transactions,
})

export const createTransationsFail = error => ({
  type: CREATE_TRANSACTIONS_FAIL,
  payload: error,
})

export const getTransactionDetails = transactionId => ({
  type: GET_TRANSACTION_DETAILS,
  payload: transactionId,
})
export const getTransactionDetailsSuccess = transactionDetails => ({
  type: GET_TRANSACTION_DETAILS_SUCCESS,
  payload: transactionDetails,
})
export const getTransactionDetailsFail = error => ({
  type: GET_TRANSACTION_DETAILS_FAIL,
  payload: error,
})

// admin

export const adminAllDonation = page => ({
  type: ADMIN_ALL_DONATIONS,
  payload: page,
})
export const adminAllDonationSuccess = adminDonationList => ({
  type: ADMIN_ALL_DONATIONS_SUCCESS,
  payload: adminDonationList,
})
export const adminAllDonationFail = error => ({
  type: ADMIN_ALL_DONATIONS_FAIL,
  payload: error,
})

export const adminDonationDetails = donationId => ({
  type: ADMIN_DONATION_DETAILS,
  payload: donationId,
})
export const adminDonationDetailsSuccess = adminDonationDetails => ({
  type: ADMIN_DONATION_DETAILS_SUCCESS,
  payload: adminDonationDetails,
})
export const adminDonationDetailsFail = error => ({
  type: ADMIN_DONATION_DETAILS_FAIL,
  payload: error,
})
