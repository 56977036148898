import React from "react"
import { Row } from "reactstrap"
// import { useLocation } from 'react-router-dom'
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Markup } from "interweave"
//actions
import moment from "moment"

// import { getNocCertificateDetail } from 'store/actions'
//componetns
//Import Images
// import LetterHead from 'components/Common/Letterhead/letterHead'

const NocCertificatePdf = ({ pdfRef }) => {
  // const dispatch = useDispatch()
  // const location = useLocation()

  console.log("pdfRef")
  console.log(pdfRef)
  // const nocId = location?.search?.split('?nocId=')[1]

  const { nocCertificateDetail, language, userDetail } = useSelector(state => ({
    nocCertificateDetail: state.NocCertificates.nocCertificateDetail,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
  }))

  // console.log(nikahRegistrationDetail);

  // const today = moment(new Date()).format('DD-MM-yyyy')

  // const handlePrint = () => {
  //   window.print()
  // }

  // useEffect(() => {
  //   dispatch(getNocCertificateDetail(nocId))
  // }, [])
  return (
    <div
      style={{
        paddingLeft: "50px",
        paddingRight: "50px",
        position: "absolute",
        top: -1400,
        width: "780px",
      }}
      ref={pdfRef}
    >
      <Row className="mt-2">
        <div className="d-flex justify-content-center">
          {/* <div className="mt-1" style={{ border: 'none' }}>
              <img
                src={userDetail?.mahall?.logo && userDetail?.mahall?.logo}
                alt=""
                className="img-thumbnail logo-certificate"
                style={{ width: '100px', height: '100px' }}
              />
            </div> */}
          <div>
            <h2 className="text-center text-black">
              <b>
                {" "}
                {language === "mal"
                  ? userDetail?.mahall?.mahall_malayalam_name
                    ? userDetail?.mahall?.mahall_malayalam_name
                    : userDetail?.mahall?.mahall_english_name
                  : userDetail?.mahall?.mahall_english_name}
              </b>
            </h2>
            <p className="text-black text-center">
              {`${
                language === "mal"
                  ? userDetail?.mahall?.lsg_malayalam_name
                    ? userDetail?.mahall?.lsg_malayalam_name
                    : userDetail?.mahall?.lsg_english_name
                  : userDetail?.mahall?.lsg_english_name
              } 
                        ${
                          language === "mal"
                            ? userDetail?.mahall?.lsg_type_malayalam
                              ? userDetail?.mahall?.lsg_type_malayalam
                              : userDetail?.mahall?.lsg_type_english
                            : userDetail?.mahall?.lsg_type_english
                        },`}{" "}
              {`${
                userDetail?.mahall?.post_office
                  ? userDetail?.mahall?.post_office
                  : ""
              }-${
                userDetail?.mahall?.pin_code ? userDetail?.mahall?.pin_code : ""
              }, `}{" "}
              <br />
              {language === "mal"
                ? userDetail?.mahall?.village_malayalam_name
                  ? userDetail?.mahall?.village_malayalam_name
                  : userDetail?.mahall?.village_english_name
                : userDetail?.mahall?.village_english_name}
              ,{" "}
              {language === "mal"
                ? userDetail?.mahall?.district_malayalam_name
                  ? userDetail?.mahall?.district_malayalam_name
                  : userDetail?.mahall?.district_english_name
                : userDetail?.mahall?.district_english_name}
              ,{" "}
              {language === "mal"
                ? userDetail?.mahall?.state_malayalam_name
                  ? userDetail?.mahall?.state_malayalam_name
                  : userDetail?.mahall?.state_english_name
                : userDetail?.mahall?.state_english_name}
              ,{" "}
              {`${language === "mal" ? "ഫോൺ" : "Mob No:"} : ${
                userDetail?.phone && userDetail?.phone
              }`}
            </p>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid black" }}></div>
        {/* <LetterHead /> */}
        <div className="px-4">
          <h5 className="d-flex justify-content-end pt-5 text-black">
            {language === "mal" ? "തീയതി : " : "Date : "}{" "}
            {moment(nocCertificateDetail?.date).format("DD-MM-yyyy")}
          </h5>
          <h1 className="text-center text-black pt-5">
            {nocCertificateDetail?.title}
          </h1>

          <p
            className="pt-4 text-black"
            style={{ fontSize: "20px", textAlign: "justify" }}
          >
            <Markup content={nocCertificateDetail?.content} />
          </p>
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: "150px" }}
          >
            <p style={{ fontSize: "16px" }}>
              {language === "mal" ? "സീൽ" : "Seal"}
            </p>
            <p style={{ fontSize: "16px" }}>
              {language === "mal"
                ? "പ്രസിഡന്റ് / സെക്രട്ടറി"
                : "President / Secretary"}
            </p>
          </div>
        </div>
        {/* <div className="d-flex justify-content-end mt-5">
                    <Button className="btn-md bg-khaf-blue print-button" onClick={handlePrint}><i className="bx bx-download mx-2" style={{ fontSize: '18px', color: "" }} />{language === "mal" ? "ഡൗൺലോഡ്" : "Download"}</Button>
                  </div> */}
      </Row>
    </div>
  )
}

export default NocCertificatePdf

NocCertificatePdf.propTypes = {
  pdfRef: PropTypes.any,
}
