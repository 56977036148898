import React from "react"
import { Container } from "reactstrap"
import { MetaTags } from "react-meta-tags"

import DatatableTables from "./DatatableTables"

import Breadcrumbs from "components/Common/Breadcrumb"

function Donations() {
  return (
    <>
      <MetaTags>
        <title>All Donations | Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs title="Donation" breadcrumbItem="All Donations" />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Donations
