import { useEffect, useState } from "react"

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key)
      // Check if the item is a valid JSON string
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error(`Error parsing localStorage item with key "${key}":`, error)
      return initialValue
    }
  })

  // Update the local storage
  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      localStorage.setItem(key, JSON.stringify(valueToStore))
      setStoredValue(valueToStore)
    } catch (error) {
      console.error(
        `Error updating localStorage item with key "${key}":`,
        error
      )
    }
  }

  // useEffect(() => {
  //   try {
  //     const item = localStorage.getItem(key)
  //     setStoredValue(item ? JSON.parse(item) : initialValue)
  //   } catch (error) {
  //     console.error(
  //       `Error retrieving localStorage item with key "${key}":`,
  //       error
  //     )
  //   }
  // }, [key, initialValue])

  return [storedValue, setValue]
}

export default useLocalStorage
