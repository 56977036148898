import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import axios from "axios"
//actions
import { Image } from "react-bootstrap"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

import { API_URL } from "helpers/api_methods"
import {
  updateFeedSuccess,
  updateFeedFail,
  getFeedDetail,
  deleteFeed,
  deleteSuperFeeds,
  updateSuperFeeds,
  getSuperFeedsDetails,
  repostFeeds,
} from "store/actions"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import { useFileSizes } from "hooks/useFileSize"
import DeleteModal from "components/Common/DeleteModal"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UpdateSuperFeed = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const handleFileSizes = useFileSizes

  const { loading, language, superFeedDetail, mahallId } = useSelector(
    state => ({
      loading: state.Banners.loading,
      error: state.Mahalls.error,
      language: state.Layout.language,
      superFeedDetail: state?.SuperFeeds?.superfeedsDetails,
      mahallId: state.Feeds.superFeedDetail?.mahall,
    })
  )
  console.log(superFeedDetail)
  // const [customLoad, setCustomLoad] = useState(false)
  const [imageUpload, setImageUpload] = useState()
  const [imagePreview, setImagePreview] = useState()

  const handleImageChange = e => {
    const fileName = Array.from(e.target.files)
    const name =
      fileName[0]?.name.length <= 100
        ? fileName[0]?.name
        : errorNotification("Ensure this filename has at most 100 characters ")
    setImageUpload({
      name: name,
      size: fileName[0]?.size,
      image: e.target.files[0],
    })
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  useEffect(() => {
    dispatch(getSuperFeedsDetails(params?.id))
  }, [dispatch, params?.id])

  useEffect(() => {
    if (superFeedDetail?.image) {
      setImagePreview(superFeedDetail)
      setImageUpload(superFeedDetail)
    }
  }, [superFeedDetail])

  function handleValidSubmit(onSubmitProps, values) {
    const updateData = {
      ...values,
      // // title: values?.title,
      // image: imageUpload ? imageUpload : "",
      // // description: values?.description,
      // // url: values?.url,
    }
    dispatch(updateSuperFeeds(superFeedDetail?.id, updateData, history))
  }

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }

  const handleDeleteFeed = () => {
    dispatch(deleteSuperFeeds(params?.id, history))
    setIsOpen(false)
  }

  console.log(superFeedDetail)

  const handleRepost = () => {
    dispatch(repostFeeds(params?.id))
  }

  const role = useGetRole()

  console.log(role)

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteFeed}
      />
      <MetaTags>
        <title> Super Feeds | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? " മുന്തിയ ഫീഡുകൾ" : "Super Feeds"}
          breadcrumbItem={
            language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക" : "Update Super Feed"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col md="12" className="mb-3">
                          <Label>
                            {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="title"
                            type="text"
                            value={superFeedDetail?.title}
                          />
                        </Col>
                      </div>

                      <div className="row mb-3">
                        <Col md="12" className="mb-3">
                          <AvField
                            name="url"
                            type="text"
                            value={superFeedDetail?.url}
                            label={language === "mal" ? "യു ആർ എൽ" : "URL"}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col md="12" className="mb-3">
                          <AvField
                            name="description"
                            type="textarea"
                            value={superFeedDetail?.description}
                            label={
                              language === "mal" ? "വിവരണം" : "Description"
                            }
                          />
                        </Col>
                      </div>

                      {/* <div className="row mb-4"> */}
                      <Col md="12">
                        <Label>{language === "mal" ? "ഫോട്ടോ" : "Image"}</Label>
                        <AvField
                          name="image"
                          type="file"
                          onChange={handleImageChange}
                        />
                        {/* {handleFileSizes(filesSize)?.text && (
                          <p className="text-danger mt-1">
                            {handleFileSizes(filesSize)?.text}
                          </p>
                        )} */}
                        {imagePreview ? (
                          <Image
                            style={{ width: "40%", height: "50%" }}
                            className="my-2"
                            src={imagePreview?.image}
                          />
                        ) : (
                          <Image
                            style={{ width: "40%", height: "50%" }}
                            className="my-2"
                            src={superFeedDetail?.image}
                          />
                        )}
                      </Col>

                      {role?.role === "admin" ? (
                        ""
                      ) : (
                        <div className="py-2">
                          <Button
                            size="sm"
                            color="success"
                            onClick={handleRepost}
                          >
                            Repost This Feed
                          </Button>
                        </div>
                      )}

                      {/* </div> */}
                      {role?.role === "mahall_user" ? (
                        ""
                      ) : (
                        <Row className="mt-5 d-flex">
                          <div className="col-6">
                            <div className="row justify-content-start">
                              <div>
                                <Button
                                  type="button"
                                  color="danger"
                                  className="w-md"
                                  onClick={handleDelete}
                                >
                                  {language === "mal"
                                    ? "ഡിലീറ്റ് ചെയ്യുക"
                                    : "Delete"}
                                </Button>
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="row justify-content-end">
                              <div>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="w-md m-auto me-0 d-block"
                                >
                                  {loading && (
                                    <>
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    </>
                                  )}
                                  {language === "mal"
                                    ? "അപ്ഡേറ്റ് ചെയ്യുക"
                                    : "Update"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Row>
                      )}

                      {/* <div className="d-flex justify-content-end">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Update
                        </Button>
                      </div> */}
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateSuperFeed

UpdateSuperFeed.propTypes = {
  history: PropTypes.object,
}
