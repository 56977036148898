import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
  Input,
  Badge,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
import Select from "react-select"
import moment from "moment"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { IoCloseOutline } from "react-icons/io5"
import UiModalFamilyDownload from "./UiModalFamilyDownload"

//actions
import {
  getFamiliesCards,
  getMahallTypeLists,
  getFilteredFamilies,
  getMahallWardNumbers,
  getFamiliesForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import { useGetSession, useSetSession } from "hooks/useHandleSessions"
import useLocalStorage from "hooks/useLocalStorage"

// import { Button } from "bootstrap"
const AllMahallFamilies = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [isFilterListOpen, setIsFilterListOpen] = useState(false)
  const [modalFamilyDownload, setModalFamilyDownload] = useState(false)

  const {
    families,
    loading,
    familiesCards,
    language,
    userDetail,
    mahallTypeLists,
    mahallWardNumbers,
    // mahallId,
    familiesforDownload,
    familiesforDownloadLoading,
    familiesCardsLoading,
  } = useSelector(state => ({
    families: state.Families.families,
    loading: state.Families.loading,
    familiesforDownload: state.Families.familiesforDownload,
    familiesforDownloadLoading: state.Families.familiesforDownloadLoading,
    familiesCards: state.Families.familiesCards,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    mahallWardNumbers: state.TypeLists.mahallWardNumbers.ward_numbers,
    mahallId: state.Users.userDetail?.mahall?.id,
    familiesCardsLoading: state.Families.familiesCardsLoading,
  }))

  console.log(familiesforDownload)

  // let filterListArea = [];

  const sort_value_family = useGetSession("sort_value_family")
  const pageSize_value = useGetSession("pageSize_value")

  const [sort, setSort] = useState(
    sort_value_family ? sort_value_family : "-custom_id"
  )
  const [pageSize, setPageSize] = useState(pageSize_value || 10)
  const [filterListArea, setFilterListArea] = useState([])

  // filterListArea state changes to local
  const [dataArray, setDataArray] = useLocalStorage("familyFilter", [])

  //pages
  const totalPages = Math.ceil(families?.count / pageSize)
  console.log(totalPages)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const handlePremiumLink = () => {
    if (["premium", "standard"].includes(userDetail?.mahall?.version)) {
      return "!#"
    } else {
      return "/premium"
    }
  }

  const filterData = {
    filter: filterListArea,
    sort: [sort],
    search: searchText,
  }

  // console.log(filterListArea) //never delete
  console.log(families)

  useEffect(() => {
    dispatch(getFamiliesCards())
  }, [dispatch])

  // useEffect(() => {
  //   if (userDetail && userDetail?.mahall?.version === 'premium') {
  //     dispatch(getFamiliesForDownload())
  //   }
  // }, [dispatch])

  useEffect(() => {
    if (userDetail?.mahall?.id) {
      if (!mahallTypeLists || mahallTypeLists.length <= 0)
        dispatch(getMahallTypeLists(userDetail?.mahall?.id))
      if (!mahallWardNumbers || mahallWardNumbers.length < 0)
        dispatch(getMahallWardNumbers(userDetail?.mahall?.id))
    }
  }, [dispatch, userDetail, mahallTypeLists, mahallWardNumbers])

  const sortData = [
    {
      heading: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
      id: "mahall_custom_id",
    },
    {
      heading: `${language === "mal" ? "തിയതി" : "Date"}`,
      id: "-custom_id",
    },
  ]

  const cardData = [
    {
      title: `${language === "mal" ? "ആകെ കുടുംബങ്ങൾ" : "Total Families"}`,
      cardValue: familiesCards?.total_families ?? 0,
      iconClass: "home",
      routeLink: "/families",
      loading: loading,
      alt: `${language === "mal" ? "കുടുംബങ്ങൾ ഇല്ല" : "No Families"}`,
    },
    {
      title: `${language === "mal" ? "ആകെ അംഗങ്ങൾ" : "Total Members"}`,
      cardValue: familiesCards?.total_family_members ?? 0,
      iconClass: "user",
      routeLink: "/familymembers",
      loading: loading,
      alt: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ ഇല്ല" : "No Family Members"}`,
    },
    {
      title: `${language === "mal" ? "ആൺ - പെൺ" : "Male - Female"}`,
      cardValue: `${familiesCards?.total_males ?? 0} - ${
        familiesCards?.total_females ?? 0
      }`,
      iconClass: "show",
      routeLink: "/familymembers",
      loading: loading,
      alt: `${language === "mal" ? "ആൺ - പെൺ ഇല്ല" : "No Male - Female"}`,
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നo." : "No."}`,
    },
    // {
    //   dataField: "custom_id",
    //   text: `${language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID"}`,
    // },
    {
      dataField: "mahall_custom_id",
      text: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
    },
    {
      dataField: "house_name",
      text: `${language === "mal" ? "വീട്ടുപേര്" : "House Name"}`,
      sort: true,
    },
    {
      dataField: "family_head_name",
      text: `${language === "mal" ? "കുടുംബനാഥൻ" : "Family Head"}`,
    },
    {
      dataField: "no_of_members",
      text: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members"}`,
    },
    {
      dataField: "houseNumber",
      text: `${language === "mal" ? "വീട് നമ്പർ" : "House No."}`,
    },
    {
      dataField: "area_name",
      text: `${language === "mal" ? "ഏരിയ" : "Area"}`,
    },
    {
      dataField: "contact_number",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]
  const familiesData = map(families?.results, (item, key) => ({
    ...item,
    key: key,
    no: (pageSend() - 1) * pageSize + key + 1,
    houseNumber:
      `${item?.ward_number === null ? "" : item?.ward_number}` +
      (item?.house_number || item?.ward_number ? " / " : "") +
      `${item?.house_number === null ? "" : item?.house_number}`,
    area_name:
      language === "mal"
        ? item?.area?.malayalam
          ? item?.area?.malayalam
          : item?.area?.english
        : item?.area?.english,
    action: (
      <div>
        <Link
          target="_blank"
          to={`/families/${item?.id}`}
          className=" btn-success w-xs btn-sm"
        >
          {/* <i className="bx bxs-show me-2 mt-2" /> */}
          {language === "mal" ? "കാണുക" : "View"}
        </Link>
      </div>
    ),
  }))

  const headers = [
    { label: language === "mal" ? "നo." : "No.", key: "no" },
    {
      label: language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID",
      key: "custom_id",
    },
    {
      label: language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID",
      key: "mahall_custom_id",
    },
    // {
    //   label: language === 'mal' ? 'വരിസംഖ്യ ഗ്രേഡ്' : 'Varisangya Grade',
    //   key: 'varisankya_grade',
    // },
    {
      label: language === "mal" ? "വീട്ടുപേര്" : "House Name",
      key: "house_name",
    },
    {
      label: language === "mal" ? "വീട് നമ്പർ" : "House No.",
      key: "house_number",
    },
    { label: language === "mal" ? "ഏരിയ" : "Area", key: "area_name" },

    { label: language === "mal" ? "വഴി" : "Via", key: "via" },
    { label: language === "mal" ? "സ്ഥലം" : "Place", key: "place" },
    { label: language === "mal" ? "ലോക്കൽ ബോഡി" : "Local Body", key: "lsg" },
    {
      label: language === "mal" ? "ലോക്കൽ ബോഡി തരം" : "Local Body Type",
      key: "lsg_type",
    },
    { label: language === "mal" ? "വില്ലേജ് " : "Village", key: "village" },
    { label: language === "mal" ? "ജില്ല" : "District", key: "district" },
    { label: language === "mal" ? "സംസ്ഥാനം" : "State", key: "state" },
    {
      label: language === "mal" ? "പോസ്റ്റ് ഓഫീസ്" : "Post Office",
      key: "post_office",
    },
    { label: language === "mal" ? "പിൻകോഡ്" : "Pincode", key: "pin_code" },
    {
      label: language === "mal" ? "താമസം തുടങ്ങിയ വർഷം" : "Year of Residence",
      key: "year_of_residence",
    },
    { label: language === "mal" ? "ഫോൺ" : "Phone", key: "contact_number" },
    {
      label: language === "mal" ? "കുടുംബനാഥൻ" : "Family Head",
      key: "family_head_name",
    },
    {
      label: language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members",
      key: "no_of_members",
    },
    {
      label: language === "mal" ? "വരിസംഗ്യ ഗ്രേഡ്" : "Varisangya Grade",
      key: "family_varisankya_grade",
    },
    {
      label: language === "mal" ? "റേഷൻ കാർഡ് വിഭാഗം" : "Ration Card Category",
      key: "ration_card",
    },
    {
      label: language === "mal" ? "റേഷൻ കാർഡ് നമ്പർ" : "Ration Card No.",
      key: "ration_card_number",
    },
    { label: language === "mal" ? "വീട്" : "House Type", key: "house_type" },
    {
      label: language === "mal" ? "വീടിൻ്റെ സ്ഥിതി" : "Build Type",
      key: "house_build_type",
    },
    {
      label: language === "mal" ? "ജല സൗകര്യം" : "Water Facility",
      key: "water_facility",
    },
    {
      label: language === "mal" ? "വൈത്യുതി" : "Electicity",
      key: "electric_facility",
    },

    {
      label: language === "mal" ? "ആസ്തി വിവരം - വീട്" : "Land Area - Home",
      key: "land_area_home",
    },
    {
      label: language === "mal" ? "ആസ്തി വിവരം - കൃഷി" : "Land Area - Farm",
      key: "land_area_farm",
    },
    {
      label: language === "mal" ? "ആസ്തി വിവരം - വാടക" : "Land Area - Rent",
      key: "land_area_rent",
    },
    {
      label:
        language === "mal" ? "ആസ്തി വിവരം - മറ്റുള്ളവ" : "Land Area - Others",
      key: "land_area_others",
    },
    {
      label: language === "mal" ? "ആകെ ആസ്തി" : "Total Land Area",
      key: "total_land_area",
    },

    {
      label: language === "mal" ? "2 വീലറുകൾ " : "2 Wheelers",
      key: "two_wheeler_vehicles",
    },
    {
      label: language === "mal" ? "3 വീലറുകൾ" : "3 Wheelers",
      key: "three_wheeler_vehicles",
    },
    {
      label: language === "mal" ? "4 വീലറുകൾ" : "4 Wheelers",
      key: "four_wheeler_vehicles",
    },
    {
      label: language === "mal" ? "ടാക്സികൾ" : "Taxi Vehicles",
      key: "taxi_vehicles",
    },
    {
      label: language === "mal" ? "ആകെ വാഹനങ്ങൾ" : "Total Vehicles",
      key: "total_vehicles",
    },

    {
      label: language === "mal" ? "സാമ്പത്തിക സ്ഥിതി" : "financial_status",
      key: "financial_status",
    },
    {
      label: language === "mal" ? "വാർഷിക വരുമാനം" : "Annual Income",
      key: "annual_income",
    },

    {
      label: language === "mal" ? "ബാങ്ക് വായ്പ" : "Bank Loan",
      key: "bank_loan",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "bank_loan_details",
    },
    {
      label: language === "mal" ? "ഹോം ബിസിനസ്സ്" : "Home Business",
      key: "home_business",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "home_business_details",
    },
    {
      label:
        language === "mal" ? "മഹല്ലിൽ നിന്നുള്ള സഹായം" : "Help From Mahall",
      key: "help_receiving_from_mahall",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "help_receiving_from_mahall_details",
    },
    {
      label:
        language === "mal"
          ? "മറ്റുള്ളവരിൽ നിന്നുള്ള സഹായം"
          : "Help From Others",
      key: "help_receiving_from_others",
    },
    {
      label: language === "mal" ? "വിശദാംശങ്ങൾ" : "Details",
      key: "help_receiving_from_others_details",
    },
    {
      label: language === "mal" ? "പ്രത്യേക കുറിപ്പുകൾ" : "Special Notes",
      key: "special_notes",
    },

    {
      label: language === "mal" ? "വീടിൻ്റെ ഫോട്ടോ ലിങ്ക്" : "House Photo Link",
      key: "house_image",
    },
    { label: "Listed Date", key: "date_added" },
  ]
  // console.log(familiesforDownload);

  const familiesExcelData = map(familiesforDownload?.results, (item, key) => ({
    ...item,
    no: (pageSend() - 1) * pageSize + key + 1,
    custom_id: item.custom_id,
    mahall_custom_id: item.mahall_custom_id,
    house_name: item.house_name,
    house_number: `${item.ward_number ? `${item.ward_number}` : ""} ${
      item.house_number ? `/${item.house_number}` : ""
    }`,
    area_name:
      language === "mal"
        ? item?.area?.malayalam
          ? item?.area?.malayalam
          : item?.area?.english
        : item?.area?.english,
    via: item.via,
    place: item.place,
    lsg:
      language === "mal"
        ? item?.lsg_malayalam_name
          ? item?.lsg_malayalam_name
          : item?.lsg_english_name
        : item?.lsg_english_name,
    lsg_type:
      language === "mal"
        ? item?.lsg_type_malayalam
          ? item?.lsg_type_malayalam
          : item?.lsg_type_english
        : item?.lsg_type_english,
    village:
      language === "mal"
        ? item?.village_malayalam_name
          ? item?.village_malayalam_name
          : item?.village_english_name
        : item?.village_english_name,
    district:
      language === "mal"
        ? item?.district_malayalam_name
          ? item?.district_malayalam_name
          : item?.district_english_name
        : item?.district_english_name,
    state:
      language === "mal"
        ? item?.state_malayalam_name
          ? item?.state_malayalam_name
          : item?.state_english_name
        : item?.state_english_name,
    post_office: item.post_office,
    pin_code: item.pin_code,
    year_of_residence: item.year_of_residence,
    contact_number: item.contact_number,
    family_head_name: item.family_head_name ? item.family_head_name : "",
    no_of_members: item.no_of_members,
    family_varisankya_grade: item.family_varisankya_grade,

    ration_card:
      language === "mal"
        ? item?.ration_card?.malayalam
          ? item?.ration_card?.malayalam
          : item?.ration_card?.english
        : item?.ration_card?.english,
    ration_card_number: item.ration_card_number,
    house_type:
      language === "mal"
        ? item?.house_type?.malayalam
          ? item?.house_type?.malayalam
          : item?.house_type?.english
        : item?.house_type?.english,
    house_build_type:
      language === "mal"
        ? item?.house_build_type?.malayalam
          ? item?.house_build_type?.malayalam
          : item?.house_build_type?.english
        : item?.house_build_type?.english,
    water_facility:
      language === "mal"
        ? item?.water_facility?.malayalam
          ? item?.water_facility?.malayalam
          : item?.water_facility?.english
        : item?.water_facility?.english,
    electric_facility:
      language === "mal"
        ? item?.electric_facility?.malayalam
          ? item?.electric_facility?.malayalam
          : item?.electric_facility?.english
        : item?.electric_facility?.english,

    land_area_home: item.land_area_home,
    land_area_farm: item.land_area_farm,
    land_area_rent: item.land_area_rent,
    land_area_others: item.land_area_others,
    total_land_area: item.total_land_area,

    two_wheeler_vehicles: item.two_wheeler_vehicles,
    three_wheeler_vehicles: item.three_wheeler_vehicles,
    four_wheeler_vehicles: item.four_wheeler_vehicles,
    taxi_vehicles: item.taxi_vehicles,
    total_vehicles: item.total_vehicles,

    financial_status:
      language === "mal"
        ? item?.financial_status?.malayalam
          ? item?.financial_status?.malayalam
          : item?.financial_status?.english
        : item?.financial_status?.english,
    annual_income: item?.annual_income,
    bank_loan: `${item?.bank_loan?.map(item => {
      return `${
        language === "mal"
          ? item?.malayalam
            ? item?.malayalam
            : item?.english
          : item?.english
      } `
    })}`,

    bank_loan_details: item.bank_loan_details,
    home_business: `${item?.home_business?.map(item => {
      return `${
        language === "mal"
          ? item?.malayalam
            ? item?.malayalam
            : item?.english
          : item?.english
      } `
    })}`,
    home_business_details: item.home_business_details,
    help_receiving_from_mahall:
      item.help_receiving_from_mahall === true
        ? language === "mal"
          ? "ഉണ്ട്"
          : "Yes"
        : language === "mal"
        ? "ഇല്ല"
        : "No",
    help_receiving_from_mahall_details: item.help_receiving_from_mahall_details,
    help_receiving_from_others:
      item.help_receiving_from_others === true
        ? language === "mal"
          ? "ഉണ്ട്"
          : "Yes"
        : language === "mal"
        ? "ഇല്ല"
        : "No",
    help_receiving_from_others_details: item.help_receiving_from_others_details,
    special_notes: item.special_notes,

    house_image: item.house_image,
    date_added:
      item?.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMahallFamilySearch = debounce(
    value => setSearchText(value),
    600
  )
  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  const years = back => {
    const year = new Date().getFullYear()
    return Array.from({ length: back }, (v, i) => year - back + i + 1)
  }

  //multi-list
  const [areas, setAreas] = useState([])
  const [selectedMultiArea, setSelectedMultiArea] = useState(null)
  const [rationCards, setRationCards] = useState([])
  const [selectedMultiRationCard, setSelectedMultiRationCard] = useState(null)
  const [houseTypes, setHouseTypes] = useState([])

  console.log(houseTypes)

  const [selectedMultiHouseType, setSelectedMultiHouseType] = useState(null)

  console.log(selectedMultiHouseType)

  const [houseBuildTypes, setHouseBuildTypes] = useState([])
  const [selectedMultiHouseBuildType, setSelectedMultiHouseBuildType] =
    useState(null)
  const [waterFacilities, setWaterFacilities] = useState([])
  const [selectedMultiWaterFacility, setSelectedMultiWaterFacility] =
    useState(null)
  const [electricFacilities, setElectricFacilities] = useState([])
  const [selectedMultiElectricFacility, setSelectedMultiElectricFacility] =
    useState(null)
  const [financialStatuses, setFinancialStatuses] = useState([])
  const [selectedMultiFinancialStatus, setSelectedMultiFinancialStatus] =
    useState(null)
  const [annualIncomes, setAnnualIncomes] = useState([])
  const [selectedMultiAnnualIncome, setSelectedMultiAnnualIncome] =
    useState(null)
  const [bankLoans, setBankLoans] = useState([])
  const [selectedMultiBankLoan, setSelectedMultiBankLoan] = useState(null)
  const [homeBusinesses, setHomeBusinesses] = useState([])
  const [selectedMultiHomeBusiness, setSelectedMultiHomeBusiness] =
    useState(null)

  const [wardNumbers, setWardNumbers] = useState([])
  const [selectedMultiWardNumber, setSelectedMultiWardNumber] = useState(null)

  const [varisangyaGrades, setVarisangyaGrades] = useState([])
  const [selectedMultiVarisangyaGrade, setSelectedMultiVarisangyaGrade] =
    useState(null)

  useEffect(() => {
    if (dataArray?.length >= 1) {
      setFilterListArea(dataArray)
      setIsFilterListOpen(true)
    }
  }, [dataArray])

  const optionGroupType = type => {
    const optionGroup = mahallTypeLists?.[`${type}`]?.map(item => {
      return {
        value: item.id,
        label: `${language === "mal" ? item?.malayalam : item?.english}`,
      }
    })
    return optionGroup
  }

  function handleMultiType(typeData, setSelectedMultiType, setType) {
    setSelectedMultiType(typeData)
    const arrangedTypes = typeData.map(a => a.value)
    setType(arrangedTypes)
  }

  // eg: mahallTypeListFilterItem(area,"ഏരിയ","Area",selectedMultiArea,setSelectedMultiArea,setAreas)
  const mahallTypeListFilterItem = (
    type,
    mal,
    en,
    selectedMultiType,
    setSelectedMultiType,
    setTypes
  ) => {
    return (
      mahallTypeLists?.[`${type}`] &&
      mahallTypeLists?.[`${type}`]?.length > 0 && (
        <Col md="3" className="mb-3">
          <Label>{language === "mal" ? mal : en}</Label>
          <div className="mb-3">
            <Select
              value={selectedMultiType}
              isMulti={true}
              onChange={e => {
                handleMultiType(e, setSelectedMultiType, setTypes)
              }}
              options={optionGroupType(type)}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
      )
    )
  }

  //ward_number
  const optionGroupWardNumber = () => {
    const optionGroup = mahallWardNumbers?.map(item => {
      return {
        value: item,
        label: item,
      }
    })
    return optionGroup
  }

  function handleMultiWardNumber(e) {
    setSelectedMultiWardNumber(e)
    const arrangedWardNumbers = e.map(a => a.value)
    setWardNumbers(arrangedWardNumbers)
  }

  const handleFilterFind = values => {
    const filterList = []
    // areas, rationCards, houseTypes, houseBuildTypes, waterFacilities, electricFacilities, financialStatuses, annualIncomes, bankLoans, homeBusinesses,
    // total_vehicles,total_land_area,year_of_residence_from,year_of_residence_to,ward_number,help_receiving_from_others,help_receiving_from_mahall

    const filterItems = [
      {
        field: "area_choice_id",
        value: areas,
      },
      {
        field: "ration_card_category_choice_id",
        value: rationCards,
      },
      {
        field: "house_type_choice_id",
        value: houseTypes,
      },
      {
        field: "house_build_type_choice_id",
        value: houseBuildTypes,
      },
      {
        field: "water_facility_choice_id",
        value: waterFacilities,
      },
      {
        field: "electric_facility_choice_id",
        value: electricFacilities,
      },
      {
        field: "financial_status_choice_id",
        value: financialStatuses,
      },
      {
        field: "annual_income_choice_id",
        value: annualIncomes,
      },
      {
        field: "bank_loan_choice_id_array",
        value: bankLoans,
      },
      {
        field: "home_business_choice_id_array",
        value: homeBusinesses,
      },
      {
        field: "ward_number",
        value: wardNumbers,
      },
      {
        field: "family_varisankya_grade_choice_id",
        value: varisangyaGrades,
      },
    ]

    for (const filterItem of filterItems) {
      if (filterItem.value.length > 0) {
        filterList.push(filterItem)
      }
    }

    if (values.house_name)
      filterList.push({
        field: "house_name",
        value: [values.house_name],
      })
    if (values.house_number)
      filterList.push({
        field: "house_number",
        value: [values.house_number],
      })

    if (
      values.total_land_area &&
      values.total_land_area !== "none" &&
      values.total_land_area !== "0"
    )
      filterList.push({
        field: "total_land_area",
        value: [parseInt(values.total_land_area)],
      })
    if (
      values.total_vehicles &&
      values.total_vehicles !== "none" &&
      values.total_vehicles !== "0"
    )
      filterList.push({
        field: "total_vehicles",
        value: [parseInt(values.total_vehicles)],
      })
    // if (values.ward_number && values.ward_number !== 'none' && values.ward_number !== '0') filterList.push({"field":"ward_number", "value":[values.ward_number]});
    if (
      values.help_receiving_from_mahall &&
      values.help_receiving_from_mahall !== "none" &&
      values.help_receiving_from_mahall !== null
    )
      filterList.push({
        field: "help_receiving_from_mahall",
        value: [
          values.help_receiving_from_mahall === "true"
            ? true
            : values.help_receiving_from_mahall === "false"
            ? false
            : null,
        ],
      })
    if (
      values.help_receiving_from_others &&
      values.help_receiving_from_others !== "none" &&
      values.help_receiving_from_others !== null
    )
      filterList.push({
        field: "help_receiving_from_others",
        value: [
          values.help_receiving_from_others === "true"
            ? true
            : values.help_receiving_from_others === "false"
            ? false
            : null,
        ],
      })
    if (
      values.is_zakat_eligible &&
      values.is_zakat_eligible !== "none" &&
      values.is_zakat_eligible !== null
    )
      filterList.push({
        field: "is_zakat_eligible",
        value: [
          values.is_zakat_eligible === "true"
            ? true
            : values.is_zakat_eligible === "false"
            ? false
            : null,
        ],
      })
    if (
      values.year_of_residence_from &&
      values.year_of_residence_to &&
      values.year_of_residence_from !== "none" &&
      values.year_of_residence_from !== null &&
      values.year_of_residence_to !== "none" &&
      values.year_of_residence_to !== null
    )
      filterList.push({
        field: "year_of_residence",
        value: {
          from: values.year_of_residence_from,
          to: values.year_of_residence_to,
        },
      })
    // if (values.year_of_residence_from && values.year_of_residence_from !== 'none' && values.year_of_residence_from !== null && values.year_of_residence_to === 'none' || values.year_of_residence_to !== null || !values.year_of_residence_to) filterList.push({"field":"year_of_residence", "value":{"from":values.year_of_residence_from}});

    setFilterListArea(filterList)
    setDataArray(filterList)
  }

  // const handleFamiliesDownload = () => {
  //   if (userDetail && userDetail?.mahall?.version === 'premium') {
  //     dispatch(getFamiliesForDownload())
  //   }
  // }

  const handleFilterLabelText = labelText => {
    if (labelText?.includes("choice_id")) {
      const cutTheChoiceId = labelText?.split("choice_id")[0]
      const stringWithSpaces = cutTheChoiceId.replace(/_/g, " ")
      const capitalizedString = stringWithSpaces.replace(/\b\w/g, match =>
        match.toUpperCase()
      )
      return capitalizedString
    } else {
      return labelText
    }
  }

  useEffect(() => {
    const filterData = {
      filter: filterListArea,
      sort: [sort],
      search: searchText,
    }
    if (
      userDetail &&
      ["premium", "standard"].includes(userDetail?.mahall?.version) &&
      modalFamilyDownload
    ) {
      dispatch(getFamiliesForDownload(filterData))
    }
  }, [dispatch, sort, searchText, filterListArea, modalFamilyDownload])

  useEffect(() => {
    const filterData = {
      filter: filterListArea,
      sort: [sort],
      search: searchText,
    }
    dispatch(getFilteredFamilies(page, pageSize, filterData))
  }, [dispatch, page, sort, pageSize, searchText, filterListArea, dataArray])

  console.log("ameen", filterData)

  const removeFilterItems = item => {
    console.log("data :", item)
    const modifiedFilter = filterListArea?.filter(i => i?.field !== item?.field)

    setFilterListArea(modifiedFilter)
    setDataArray(modifiedFilter)
  }

  return (
    <React.Fragment>
      <UiModalFamilyDownload
        show={modalFamilyDownload}
        onCloseclick={() => setModalFamilyDownload(false)}
        excelData={familiesExcelData}
        headers={headers}
        list={familiesforDownload}
        loading={familiesforDownloadLoading}
      />
      <Row>
        {familiesCardsLoading ? (
          <Spinner color="secondary" className="d-block m-auto" />
        ) : (
          <>
            {!isFilterListOpen &&
              cardData?.map((item, key) => (
                <Col md={4} lg={4} key={key}>
                  <Card className="blog-stats-wid">
                    <CardBody>
                      <Link to={item.routeLink}>
                        <div className="d-flex flex-wrap">
                          <div className="me-3">
                            <p className="text-muted mb-2">{item.title}</p>
                            {/* {item.cardValue && (
                        )} */}
                            <h5 className="mb-0">
                              {item.cardValue && item.cardValue}
                            </h5>
                            {/* <p className="m-0 text-info">
                        {item.cardValue >= 0 ? <></> : item.alt}
                      </p> */}
                          </div>

                          <div className="avatar-sm ms-auto">
                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                              <i
                                className={"bx bxs-" + `${item.iconClass}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </>
        )}
        {!isFilterListOpen && (
          <Row>
            <div className="col-md-6 mb-2">
              <Link
                to={`/family/create`}
                type="submit"
                style={{ width: language === "mal" ? "200px" : "150px" }}
                className="w-md btn btn-success d-block"
              >
                {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New Family"}
              </Link>
            </div>
          </Row>
        )}
        <Col className="col-12">
          <ToolkitProvider
            keyField={"key"}
            key={"key"}
            columns={columns}
            data={familiesData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Card>
                  <CardBody>
                    <Row className="mb-0">
                      <Col md="2">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceMahallFamilySearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="d-flex mt-2 mb-2"
                          style={{ alignItems: "center" }}
                        >
                          <p>{language === "mal" ? "ക്രമം" : "Sort by"}</p>
                          <div className="mx-2 mb-3">
                            <select
                              value={sort}
                              onChange={e => {
                                setSort(e.target.value)
                                useSetSession(
                                  "sort_value_family",
                                  e.target.value
                                )
                              }}
                              style={{
                                height: "30px",
                                padding: "0 5px",
                                borderColor: "#d0d6db",
                                borderRadius: "3px",
                              }}
                            >
                              {/* <option>
                                {language === "mal"
                                  ? "തിരഞ്ഞെടുക്കുക ..."
                                  : "Choose..."}{" "}
                              </option> */}
                              {sortData.map((item, key) => (
                                <option key={key} value={item.id}>
                                  {item.heading}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="d-flex mt-2 mb-2"
                          style={{ alignItems: "center", width: "100px" }}
                        >
                          <p>{language === "mal" ? "ക്രമം" : "Show"}</p>
                          <div className="mx-2">
                            <div className="mb-3">
                              <Input
                                value={pageSize}
                                onChange={e => {
                                  if (
                                    e.target.value <= 0 ||
                                    e.target.value > 100
                                  ) {
                                    // setPageSize(10)
                                  } else {
                                    setPageSize(e.target.value)
                                    useSetSession(
                                      "pageSize_value",
                                      e.target.value
                                    )
                                  }
                                }}
                                style={{
                                  height: "30px",
                                  padding: "0 5px",
                                  borderColor: "#d0d6db",
                                  borderRadius: "3px",
                                }}
                              ></Input>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        md="4"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div>
                          {userDetail?.mahall?.version === "basic" ? (
                            <Link
                              style={{
                                width: language === "mal" ? "135px" : "50px",
                              }}
                              className="w-md btn btn-primary m-auto me-0 mt-0 d-block"
                              to={handlePremiumLink()}
                            >
                              <i className="bx bx-list-ol font-size-16 align-middle"></i>{" "}
                              {language === "mal" ? "ഫിൽറ്റർ" : "Filter"}
                              <i
                                className="bx bx-crown mx-1"
                                style={{ fontSize: "12px", color: "gold" }}
                              />
                            </Link>
                          ) : (
                            <Button
                              style={{
                                width: language === "mal" ? "135px" : "50px",
                              }}
                              className="w-md btn btn-info m-auto me-0 mt-0 d-block bg-khaf-blue"
                              onClick={() =>
                                setIsFilterListOpen(!isFilterListOpen)
                              }
                            >
                              {!isFilterListOpen ? (
                                <>
                                  <i className="bx bx-list-ol font-size-16 align-middle"></i>
                                  {language === "mal" ? "ഫിൽറ്റർ" : "Filter"}
                                </>
                              ) : (
                                `${language === "mal" ? "അടയ്ക്കുക" : "Close"}`
                              )}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {isFilterListOpen ? (
                      <>
                        <AvForm
                          onValidSubmit={(e, v) => {
                            handleFilterFind(v)
                          }}
                        >
                          <Row>
                            <hr />
                            <Col md="3">
                              <AvField
                                name="house_name"
                                type="string"
                                label="House Name"
                              />
                            </Col>
                            <Col md="2">
                              <AvField
                                name="house_number"
                                type="string"
                                label="House Number"
                              />
                            </Col>
                            {mahallTypeListFilterItem(
                              "area",
                              "ഏരിയ",
                              "Area",
                              selectedMultiArea,
                              setSelectedMultiArea,
                              setAreas
                            )}

                            {mahallTypeListFilterItem(
                              "ration_card",
                              "റേഷൻ കാർഡ്",
                              "Ration Card",
                              selectedMultiRationCard,
                              setSelectedMultiRationCard,
                              setRationCards
                            )}

                            {mahallTypeListFilterItem(
                              "house_type",
                              "വീട്",
                              "House Type",
                              selectedMultiHouseType,
                              setSelectedMultiHouseType,
                              setHouseTypes
                            )}

                            {mahallTypeListFilterItem(
                              "house_build_type",
                              "വീടിൻ്റെ സ്ഥിതി",
                              "Build Type",
                              selectedMultiHouseBuildType,
                              setSelectedMultiHouseBuildType,
                              setHouseBuildTypes
                            )}

                            {mahallTypeListFilterItem(
                              "water_facility",
                              "ജല സൗകര്യം",
                              "Water Facility",
                              selectedMultiWaterFacility,
                              setSelectedMultiWaterFacility,
                              setWaterFacilities
                            )}

                            {mahallTypeListFilterItem(
                              "electric_facility",
                              "വൈത്യുതി",
                              "Electricity",
                              selectedMultiElectricFacility,
                              setSelectedMultiElectricFacility,
                              setElectricFacilities
                            )}

                            {mahallTypeListFilterItem(
                              "financial_status",
                              "സാമ്പത്തിക സ്ഥിതി",
                              "Financial Status",
                              selectedMultiFinancialStatus,
                              setSelectedMultiFinancialStatus,
                              setFinancialStatuses
                            )}

                            {mahallTypeListFilterItem(
                              "annual_income",
                              "വാർഷിക വരുമാനം",
                              "Annual Income",
                              selectedMultiAnnualIncome,
                              setSelectedMultiAnnualIncome,
                              setAnnualIncomes
                            )}

                            {mahallTypeListFilterItem(
                              "bank_loan",
                              "ബാങ്ക് വായ്പ",
                              "Bank Loan",
                              selectedMultiBankLoan,
                              setSelectedMultiBankLoan,
                              setBankLoans
                            )}
                            {mahallTypeListFilterItem(
                              "home_business",
                              "ഹോം ബിസിനസ്സ്",
                              "Home Business",
                              selectedMultiHomeBusiness,
                              setSelectedMultiHomeBusiness,
                              setHomeBusinesses
                            )}
                            {mahallTypeListFilterItem(
                              "varisankya_grade",
                              "വരിസംഖ്യ ഗ്രേഡ്",
                              "Varisangya Grade",
                              selectedMultiVarisangyaGrade,
                              setSelectedMultiVarisangyaGrade,
                              setVarisangyaGrades
                            )}

                            {mahallWardNumbers &&
                              mahallWardNumbers.length > 0 && (
                                <Col md="2" className="mb-3">
                                  <Label>
                                    {language === "mal"
                                      ? "വാർഡ് നമ്പർ"
                                      : "Ward No."}
                                  </Label>
                                  <div className="mb-3">
                                    <Select
                                      value={selectedMultiWardNumber}
                                      isMulti={true}
                                      onChange={e => {
                                        handleMultiWardNumber(e)
                                      }}
                                      options={optionGroupWardNumber()}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                              )}
                            {/* Help From Mahall */}

                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "മഹല്ലിൽ നിന്നുള്ള സഹായം"
                                    : "Help From Mahall"}
                                </Label>
                                <AvField
                                  type="select"
                                  name="help_receiving_from_mahall"
                                >
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "ഉണ്ട്",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "ഇല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                            {/* Help From Others */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "മറ്റുള്ളവരിൽ നിന്നുള്ള സഹായം"
                                    : "Help From Others"}
                                </Label>
                                <AvField
                                  type="select"
                                  name="help_receiving_from_others"
                                >
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "ഉണ്ട്",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "ഇല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                            {/* Year Of Residance */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "താമസം തുടങ്ങിയ വർഷം"
                                    : "Year Of Residance"}
                                </Label>
                                <Col className=" d-flex justify-content-space-between">
                                  <div className="col-md-6">
                                    <AvField
                                      type="select"
                                      name="year_of_residence_from"
                                      helpMessage={
                                        language === "mal"
                                          ? "മുതൽ"
                                          : "From year"
                                      }
                                    >
                                      <option>{"none"}</option>
                                      {years(100)?.map((item, key) => (
                                        <option key={key} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </AvField>
                                  </div>

                                  <div className="col-md-6 mx-1">
                                    <AvField
                                      type="select"
                                      name="year_of_residence_to"
                                      helpMessage={
                                        language === "mal" ? "വരെ" : "To year"
                                      }
                                    >
                                      <option>{"none"}</option>
                                      {years(100)?.map((item, key) => (
                                        <option key={key} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </AvField>
                                  </div>
                                </Col>
                              </Col>
                            }
                            {/* Land Area */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "ആകെ ആസ്തി"
                                    : "Total Land Area"}
                                </Label>
                                <AvField
                                  type="number"
                                  name="total_land_area"
                                  helpMessage={
                                    language === "mal"
                                      ? "ഇതിലും കുറവോ തുല്യമോ"
                                      : "Less than or equal to"
                                  }
                                ></AvField>
                              </Col>
                            }
                            {/* Total Vehicles */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "ആകെ വാഹനങ്ങൾ"
                                    : "Total Vehicles"}
                                </Label>
                                <AvField
                                  type="number"
                                  name="total_vehicles"
                                  helpMessage={
                                    language === "mal"
                                      ? "ഇതിലും കുറവോ തുല്യമോ"
                                      : "Less than or equal to"
                                  }
                                ></AvField>
                              </Col>
                            }
                            {/* Is Zakat Eligible */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "സകാത്തിന് അർഹമാണോ"
                                    : "Is Zakat Eligible?"}
                                </Label>
                                <AvField type="select" name="is_zakat_eligible">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "ഉണ്ട്",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "ഇല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                          </Row>

                          {filterListArea?.length >= 1 && (
                            <div className="mb-3">
                              <p>Filters</p>

                              {filterListArea?.map((label, key) => (
                                <Badge
                                  key={key}
                                  className={`bg-light rounded-pill p-2 px-3 me-2 mb-2`}
                                >
                                  {handleFilterLabelText(label?.field)}
                                  <IoCloseOutline
                                    onClick={() => removeFilterItems(label)}
                                    className="font-size-14 text-danger cursor-pointer ms-2"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Badge>
                              ))}
                            </div>
                          )}

                          <Col
                            md="12"
                            className="mb-3 d-flex align-items-end justify-content-end"
                          >
                            <Button
                              type="submit"
                              className="w-xl btn btn-success"
                            >
                              {language === "mal" ? "ഫിൽറ്റർ" : "Find"}
                            </Button>
                          </Col>
                        </AvForm>
                      </>
                    ) : (
                      <></>
                    )}
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          {/* <Col md="2">
                            <AvForm>
                              <div className="d-flex" style={{ width: "120px", border: "1px solid #333" }}>
                                <AvField
                                  type="select"
                                  name="sort_family"
                                  className="sort"
                                  value={sort}
                                  onChange={(e) => {
                                    setSort(e.target.value)
                                  }}
                                >
                                  <option>
                                    {language === "mal"
                                      ? "ഏരിയ തിരഞ്ഞെടുക്കുക ..."
                                      : "Sort by"}{" "}
                                  </option>
                                  {sortData.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {item.heading}
                                    </option>
                                  ))}
                                </AvField>
                                <i className="bx bxs-sort-alt" style={{ fontSize: "15px", display: "flex", alignItems: "center" }} />
                              </div>
                            </AvForm>
                          </Col> */}
                          {/* {familiesforDownloadLoading && !familiesforDownload ? <Spinner color="secondary" className="d-block m-auto" /> :
                            userDetail?.mahall.version === 'basic' ? <></> :
                              <Button data-tip data-for="downloadTip"
                                style={{ width: "200px", border: 'none' }}
                                className="w-md btn btn-info m-auto me-0 mt-0 mb-2 d-block bg-white"
                              >
                                {familiesforDownload && familiesforDownload.length > 0 && <CSVLink data={familiesExcelData} headers={headers} filename={"khaf-smart-mahall-families-list.csv"}>
                                  <i className="bx bx-download mx-1" style={{ fontSize: '18px', color: "blue" }} />
                                  {language === 'mal' ? `ഡൗൺലോഡ് (${families?.count})` : `Download (${families?.count})`}
                                </CSVLink>}
                              </Button>
                          } */}
                          {userDetail?.mahall?.version === "basic" ? (
                            <></>
                          ) : (
                            <Button
                              style={{
                                width: language === "mal" ? "150px" : "130px",
                                border: "bold",
                              }}
                              className="btn-md m-auto me-4 mt-0 mb-2 d-block bg-white text-khaf"
                              onClick={() => setModalFamilyDownload(true)}
                            >
                              <i
                                className="bx bx-download mx-1"
                                style={{ fontSize: "18px", color: "#009846" }}
                              />
                              {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                            </Button>
                          )}
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="key"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {families &&
                      families?.results &&
                      families?.results?.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          {language === "mal"
                            ? "കുടുംബങ്ങൾ ഒന്നും ഇല്ല"
                            : "No Families Yet"}
                        </p>
                      )}
                  </CardBody>
                </Card>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllMahallFamilies
