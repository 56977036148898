import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ACTIVITY_LOGS, GET_ACTIVITY_LOG_DETAIL } from "./actionTypes"
import {
  getActivityLogsSuccess,
  getActivityLogsFail,
  getActivityLogDetailSuccess,
  getActivityLogDetailFail,
} from "./actions"

// import {
//   getFamilyMemberDetailSuccess,
//   getFamilyMemberDetailFail,
// } from 'store/actions'
import { get } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getActivityLogsAPi = ({ searchText, page }) => {
  return get(
    `/api/v1/activity-log/log-entry/?page=${page ? page : 1}&search=${
      searchText ? searchText : ""
    }`
  )
}
const getActivityLogDetailsAPi = logId => {
  return get(`/api/v1/activity-log/log-entry/${logId}/`)
}

function* fetchactivityLogs({ payload }) {
  try {
    const response = yield call(getActivityLogsAPi, payload)
    if (response && !response?.error) {
      yield put(getActivityLogsSuccess(response))
    } else {
      yield put(getActivityLogsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getActivityLogsFail(error))
  }
}

function* fetchactivityLogDetail({ logId }) {
  try {
    const response = yield call(getActivityLogDetailsAPi, logId)
    if (response && !response?.error) {
      yield put(getActivityLogDetailSuccess(response))
    } else {
      yield put(getActivityLogDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getActivityLogDetailFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}

function* activityLogsSaga() {
  yield takeEvery(GET_ACTIVITY_LOGS, fetchactivityLogs)
  yield takeEvery(GET_ACTIVITY_LOG_DETAIL, fetchactivityLogDetail)
}

export default activityLogsSaga
