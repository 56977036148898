import {
  GET_ZAKAT_ITEMS,
  GET_ZAKAT_ITEMS_SUCCESS,
  GET_ZAKAT_ITEMS_FAIL,
  GET_ZAKAT_ITEM_CARDS,
  GET_ZAKAT_ITEM_CARDS_SUCCESS,
  GET_ZAKAT_ITEM_CARDS_FAIL,
  GET_ZAKAT_ITEM_DETAIL,
  GET_ZAKAT_ITEM_DETAIL_SUCCESS,
  GET_ZAKAT_ITEM_DETAIL_FAIL,
  CREATE_ZAKAT_ITEM,
  CREATE_ZAKAT_ITEM_SUCCESS,
  CREATE_ZAKAT_ITEM_FAIL,
  UPDATE_ZAKAT_ITEM,
  UPDATE_ZAKAT_ITEM_SUCCESS,
  UPDATE_ZAKAT_ITEM_FAIL,
  DELETE_ZAKAT_ITEM,
  DELETE_ZAKAT_ITEM_SUCCESS,
  DELETE_ZAKAT_ITEM_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  zakatItems: [],
  zakatItemCards: {},
  zakatItemDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdZakatItem: {},
}

const ZakatItems = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ZAKAT_ITEMS:
    case GET_ZAKAT_ITEM_CARDS:
    case UPDATE_ZAKAT_ITEM:
    case CREATE_ZAKAT_ITEM:
    case DELETE_ZAKAT_ITEM:
      return {
        ...state,
        loading: true,
      }
    case GET_ZAKAT_ITEM_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_ZAKAT_ITEMS_SUCCESS:
      return {
        ...state,
        zakatItems: action.payload,
        loading: false,
      }
    case GET_ZAKAT_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ZAKAT_ITEM_CARDS_SUCCESS:
      return {
        ...state,
        zakatItemCards: action.payload,
        loading: false,
      }
    case GET_ZAKAT_ITEM_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_ZAKAT_ITEM_SUCCESS:
      return {
        ...state,
        states: action.payload,
        // zakatDetail: {
        //   ...state.zakatDetail,
        //   zakat_item: [...state.zakatDetail.zakat_item, action.payload]
        // },
        createdZakatItem: action.payload,
        loading: false,
      }

    case CREATE_ZAKAT_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ZAKAT_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        zakatItemDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_ZAKAT_ITEM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_ZAKAT_ITEM_SUCCESS:
      return {
        ...state,
        zakatItemDetail: action.payload,
        loading: false,
      }

    case UPDATE_ZAKAT_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_ZAKAT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case DELETE_ZAKAT_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default ZakatItems
