import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Container,
  TabContent,
  TabPane,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import moment from "moment"
import Select from "react-select"
//actions
import { map } from "lodash"
import { Image } from "react-bootstrap"

import { uploadImage } from "./uploadImage"

import {
  getFamilyMemberDetail,
  updateFamilyMember,
  getMahallTypeLists,
  getFamilyMemberDropdown,
  getCountries,
  checkFamilyMemberPhoneNumberUpdate,
  checkCustomId,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import HeaderLine from "components/Common/HeaderLine"
import { useFileSizes } from "hooks/useFileSize"
import { handleSelectOrDeselect } from "hooks/useSelectOrDeselect"
import { countryCodes } from "pages/common/countryCodes"

const UpdateMahallFamilyMember = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    familyMemberDetail,
    mahallTypeLists,
    userDetail,
    searchFamilyMembers,
    mahallId,
    language,
    loading,
    countries,
    familyMemberPhoneNumberUpdateAvailability,
    memberPhoneUpdateCheckLoading,
    customIdCheckLoading,
    customIdAvailability,
  } = useSelector(state => ({
    familyMemberDetail: state.FamilyMembers.familyMemberDetail,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    userDetail: state.Users.userDetail,
    searchFamilyMembers: state.FamilyMembers.searchFamilyMembers,
    mahallId: state.Users.userDetail?.mahall?.id,
    language: state.Layout.language,
    loading: state.FamilyMembers.loading,
    countries: state.Countries.countries?.results,
    familyMemberPhoneNumberUpdateAvailability:
      state.Users.familyMemberPhoneNumberUpdateAvailability,
    memberPhoneUpdateCheckLoading: state.Users.memberPhoneUpdateCheckLoading,
    customIdAvailability: state.Users.customIdAvailability,
    customIdCheckLoading: state.Users.customIdCheckLoading,
  }))

  const [activeTab, setactiveTab] = useState(1)
  // const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  // const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  // function toggleTabVertical(tab) {
  //   if (activeTabVartical !== tab) {
  //     var modifiedSteps = [...passedStepsVertical, tab]

  //     if (tab >= 1 && tab <= 3) {
  //       setoggleTabVertical(tab)
  //       setPassedStepsVertical(modifiedSteps)
  //     }
  //   }
  // }

  const [searchText] = useState("")

  console.log(searchText)
  // const [mahall, setMahall] = useState('')
  const [checkValue, setCheckValue] = useState(familyMemberDetail?.phone)
  const [checkValueCustomId, setCheckValueCustomId] = useState()
  const [searchResultFather, setSearchResultFather] = useState(false)
  const [searchResultMother, setSearchResultMother] = useState(false)
  const [searchResultSpouse, setSearchResultSpouse] = useState(false)

  const [searchTextCountries, setSearchTextCountries] = useState("")
  const [countryName, setCountryName] = useState(
    language === "mal"
      ? familyMemberDetail?.present_country_malayalam
      : familyMemberDetail?.present_country_english
  )
  const [countryId, setCountryId] = useState("")
  const [fatherName, setFatherName] = useState(familyMemberDetail?.father_name)
  const [fatherId, setFatherId] = useState(familyMemberDetail?.father_id)
  const [motherName, setMotherName] = useState(familyMemberDetail?.mother_name)
  const [motherId, setMotherId] = useState(familyMemberDetail?.mother_id)
  const [spouseName, setSpouseName] = useState(familyMemberDetail?.spouse_name)
  const [spouseId, setSpouseId] = useState(familyMemberDetail?.spouse_id)

  const [fullName, setFullName] = useState()

  const [isOrphanSwitch, setIsOrphanSwitch] = useState(
    familyMemberDetail?.is_orphan || false
  )
  const [isFamilyHeadSwitch, setIsFamilyHeadSwitch] = useState(
    familyMemberDetail?.is_family_head || false
  )
  const [isAbroadSwitch, setIsAbroadSwitch] = useState(
    familyMemberDetail?.is_abroad || false
  )
  const [readyToDonateSwitch, setReadyToDonateSwitch] = useState(
    familyMemberDetail?.ready_to_donate || false
  )
  const [isStudentSwitch, setIsStudentSwitch] = useState(
    familyMemberDetail?.is_student || false
  )
  const [isWorkingSwitch, setIsWorkingSwitch] = useState(
    familyMemberDetail?.is_working || false
  )

  const [gender, setGender] = useState(familyMemberDetail?.gender)
  const [idCard, setIdCard] = useState(familyMemberDetail?.id_card_choice_id)
  const [maritalStatus, setMaritalStatus] = useState(
    familyMemberDetail?.marital_status_choice_id
  )
  const [unmarriedReason, setUnmarriedReason] = useState(
    familyMemberDetail?.unmarried_reason_choice_id
  )
  const [bloodGroup, setBloodGroup] = useState(
    familyMemberDetail?.blood_group_choice_id
  )
  const [healthCondition, setHealthCondition] = useState([
    familyMemberDetail?.health_condition_choice_id_array,
  ])
  const [diseaseType, setDiseaseType] = useState(
    familyMemberDetail?.disease_type_choice_id_array
  )
  const [diseaseLevel, setDiseaseLevel] = useState(
    familyMemberDetail?.disease_level_choice_id
  )
  const [disabilityType, setDisabilityType] = useState([
    familyMemberDetail?.disability_type_choice_id_array,
  ])
  const [socialActivity, setSocialActivity] = useState([
    familyMemberDetail?.social_activity_type_id_array,
  ])
  const [badHabits, setBadHabits] = useState([
    familyMemberDetail?.bad_habits_choice_id_array,
  ])

  const [islamicEducation, setIslamicEducation] = useState(
    familyMemberDetail?.educational_qualification_islamic_choice_id
  )
  const [formalEducation, setFormalEducation] = useState(
    familyMemberDetail?.educational_qualification_formal_choice_id
  )
  const [notStudyReason, setNotStudyReason] = useState(
    familyMemberDetail?.not_studying_reason_choice_id
  )
  const [notWorkingReason, setNotWorkingReason] = useState(
    familyMemberDetail?.not_working_reason_choice_id
  )
  const [jobSector, setJobSector] = useState(
    familyMemberDetail?.job?.job_sector_choice_id
  )
  console.log(jobSector)
  const [jobType, setJobType] = useState(
    familyMemberDetail?.job?.job_type_choice_id
  )
  console.log(jobType)

  const [age, setAge] = useState(0)
  const [dateOfBirth, setDateOfBirth] = useState(familyMemberDetail?.dob)

  const [imagePreview, setImagePreview] = useState()

  const [whatsappNumber, setWhatsappNumber] = useState(false)

  const genderTypes = [
    { english: "Male", malayalam: "പുരുഷൻ", value: "male" },
    { english: "Female", malayalam: "സ്ത്രീ", value: "female" },
    { english: "Others", malayalam: "മറ്റുള്ളവ", value: "other" },
  ]

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("diff:", diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("age: ", age)
      if (age < 0) age = 0
      setAge(Number(age))
      return age
    } else {
      return 0
    }
  }

  const convertToDOB = getAge => {
    const currentYear = new Date().getFullYear()
    const dob = currentYear - getAge
    setDateOfBirth(moment(dob, "YYYY").format("yyyy-MM-DD"))
  }

  useEffect(() => {
    if (mahallId) {
      dispatch(getMahallTypeLists(mahallId))
    }
  }, [userDetail, mahallId])

  // useEffect(() => {
  //   dispatch(getFamilyMemberDropdown(searchText, familyMemberDetail?.family))
  // }, [searchText, dispatch])

  useEffect(() => {
    dispatch(getFamilyMemberDetail(params.id))
  }, [params.id, dispatch])

  useEffect(() => {
    setCountryId(familyMemberDetail?.present_country)
    setCountryName(
      language === "mal"
        ? familyMemberDetail?.present_country_malayalam
          ? familyMemberDetail?.present_country_malayalam
          : familyMemberDetail?.present_country_english
        : familyMemberDetail?.present_country_english
    )
    setFatherId(familyMemberDetail?.father_id)
    setMotherId(familyMemberDetail?.mother_id)
    setSpouseId(familyMemberDetail?.spouse_id)
    setFatherName(familyMemberDetail?.father_name)
    setMotherName(familyMemberDetail?.mother_name)
    setSpouseName(familyMemberDetail?.spouse_name)
    setIsOrphanSwitch(familyMemberDetail?.is_orphan)
    setIsFamilyHeadSwitch(familyMemberDetail?.is_family_head)
    setIsAbroadSwitch(familyMemberDetail?.is_abroad)
    setReadyToDonateSwitch(familyMemberDetail?.ready_to_donate)
    setIsStudentSwitch(familyMemberDetail?.is_student)
    setIsWorkingSwitch(familyMemberDetail?.is_working)
    setGender(familyMemberDetail?.gender)
    setIdCard(familyMemberDetail?.id_card_choice_id)
    setMaritalStatus(familyMemberDetail?.marital_status_choice_id)
    setUnmarriedReason(familyMemberDetail?.unmarried_reason_choice_id)
    setBloodGroup(familyMemberDetail?.blood_group_choice_id)
    setHealthCondition(familyMemberDetail?.health_condition_choice_id_array)
    setDiseaseType(familyMemberDetail?.disease_type_choice_id_array)
    setDiseaseLevel(familyMemberDetail?.disease_level_choice_id)
    setDisabilityType(familyMemberDetail?.disability_type_choice_id_array)
    setSocialActivity(familyMemberDetail?.social_activity_type_id_array)
    setBadHabits(familyMemberDetail?.bad_habits_choice_id_array)
    setIslamicEducation(
      familyMemberDetail?.educational_qualification_islamic_choice_id
    )
    setFormalEducation(
      familyMemberDetail?.educational_qualification_formal_choice_id
    )
    setNotStudyReason(familyMemberDetail?.not_studying_reason_choice_id)
    setNotWorkingReason(familyMemberDetail?.not_working_reason_choice_id)
    setJobSector(familyMemberDetail?.job?.job_sector_choice_id)
    setJobType(familyMemberDetail?.job?.job_type_choice_id)
    setDateOfBirth(familyMemberDetail?.dob)
    setFullName(familyMemberDetail?.full_name)
    getAge(familyMemberDetail?.dob)
  }, [familyMemberDetail])

  const handleValidSubmit = values => {
    const updateFamilyMemberData = {
      updateFamiyMemberAccountData: {
        full_name: values.full_name ? values.full_name : null,
        email: values.email ? values.email : null,
        phone: values.phone,
      },
      account: familyMemberDetail?.account,
      mahall_custom_id: values.mahall_custom_id
        ? values.mahall_custom_id
        : null,
      varisangya_grade: values.varisangya_grade,
      birth_register_no: values.birth_register_no,
      full_name: values.full_name ? values.full_name : null,
      alt_country_code: values?.alt_country_code
        ? values?.alt_country_code
        : null,
      alt_phone_number: values?.alt_phone_number
        ? values?.alt_phone_number
        : null,
      whatsapp_country_code: values?.whatsapp_country_code
        ? values?.whatsapp_country_code
        : null,
      whatsapp_number: values?.whatsapp_number ? values?.whatsapp_number : null,
      family: familyMemberDetail?.family,
      gender: gender ? gender : null,
      is_orphan: isOrphanSwitch ? isOrphanSwitch : false,
      dob: dateOfBirth ? dateOfBirth : null,
      is_family_head: isFamilyHeadSwitch ? isFamilyHeadSwitch : false,
      relation_choice_id:
        values.relation_choice_id === "Select relation..." ||
        values.relation_choice_id === "ബന്ധം തിരഞ്ഞെടുക്കുക..."
          ? null
          : values.relation_choice_id,
      family_member_varisankya_grade_choice_id:
        values.family_member_varisankya_grade_choice_id ===
          "Select an grade..." ||
        values.family_member_varisankya_grade_choice_id ===
          "ഗ്രേഡ് തിരഞ്ഞെടുക്കുക ..."
          ? null
          : values.family_member_varisankya_grade_choice_id,
      father_name: fatherName ? fatherName : null,
      father_id: fatherId ? fatherId : null,
      mother_name: motherName ? motherName : null,
      mother_id: motherId ? motherId : null,
      email: values.email ? values.email : null,
      id_card_choice_id: idCard ? idCard : null,
      id_card_number: values.id_card_number ? values.id_card_number : null,
      is_abroad: isAbroadSwitch ? isAbroadSwitch : false,
      present_country: countryId ? countryId : null,
      present_address: values.present_address ? values.present_address : null,
      marital_status_choice_id: maritalStatus ? maritalStatus : null,
      spouse_name: spouseName ? spouseName : null,
      spouse_id: spouseId ? spouseId : null,
      unmarried_reason_choice_id: unmarriedReason ? unmarriedReason : null,
      divorce_reason: values.divorce_reason ? values.divorce_reason : null,

      blood_group_choice_id: bloodGroup ? bloodGroup : null,
      ready_to_donate: readyToDonateSwitch ? readyToDonateSwitch : false,
      health_condition_choice_id_array: healthCondition
        ? healthCondition
        : null,
      disease_type_choice_id_array: diseaseType ? diseaseType : null,
      other_disease_type: values.other_disease_type
        ? values.other_disease_type
        : null,
      disease_level_choice_id: diseaseLevel ? diseaseLevel : null,
      disability_type_choice_id_array: disabilityType ? disabilityType : null,
      other_disability_type: values.other_disability_type
        ? values.other_disability_type
        : null,
      social_activity_type_id_array: socialActivity ? socialActivity : null,
      social_activity_details: values.social_activity_details
        ? values.social_activity_details
        : null,
      skills: values.skills ? values.skills : null,
      bad_habits_choice_id_array: badHabits ? badHabits : null,

      educational_qualification_islamic_choice_id: islamicEducation
        ? islamicEducation
        : null,
      educational_qualification_islamic_details:
        values.educational_qualification_islamic_details
          ? values.educational_qualification_islamic_details
          : null,
      educational_qualification_formal_choice_id: formalEducation
        ? formalEducation
        : null,
      educational_qualification_formal_details:
        values.educational_qualification_formal_details
          ? values.educational_qualification_formal_details
          : null,
      is_student: isStudentSwitch ? isStudentSwitch : false,
      not_studying_reason_choice_id: notStudyReason ? notStudyReason : null,
      is_working: isWorkingSwitch ? isWorkingSwitch : false,
      not_working_reason_choice_id: notWorkingReason ? notWorkingReason : null,
      varisankya_amount: values.varisankya_amount
        ? parseInt(values.varisankya_amount)
        : 0,
    }
    dispatch(
      updateFamilyMember(
        updateFamilyMemberData,
        familyMemberDetail?.id,
        history
      )
    )
  }

  const handleCheckFamilyMemberCustomId = v => {
    const checkFamilyMemberCustomIdData = {
      mahall: mahallId,
      mahall_custom_id: v,
      model: "familymember",
    }
    dispatch(checkCustomId(checkFamilyMemberCustomIdData))
    setCheckValueCustomId(v)
    // console.log("phone: ", v)
  }

  const addOrRemoveItem = (arr, setArray, value) => {
    const isPresent = arr?.includes(value)
    var newArr = arr
    // console.log("is present: ", isPresent)

    if (isPresent) {
      newArr = arr.filter(item => {
        return item.toString() !== value.toString()
      })
    } else {
      newArr = [...arr, value]
    }
    setArray(newArr)
    // console.log("newArr : ", newArr)
  }

  const handleFatherNameChange = value => {
    // console.log("value: ", value)
    setFatherName(value)
    dispatch(getFamilyMemberDropdown(value, familyMemberDetail?.family))
    // console.log("searchFamilyMembers[0]?.id: ", searchFamilyMembers[0]?.id)
    setSearchResultFather(true)
    setSearchResultMother(false)
    setSearchResultSpouse(false)
  }

  const handleMotherNameChange = value => {
    // console.log("value: ", value)
    setMotherName(value)
    dispatch(getFamilyMemberDropdown(value, familyMemberDetail?.family))
    // console.log("searchFamilyMembers[0]?.id: ", searchFamilyMembers[0]?.id)
    setSearchResultFather(false)
    setSearchResultMother(true)
    setSearchResultSpouse(false)
  }

  const handleSpouseNameChange = value => {
    // console.log("value: ", value)
    setSpouseName(value)
    dispatch(getFamilyMemberDropdown(value, familyMemberDetail?.family))
    // console.log("searchFamilyMembers[0]?.id: ", searchFamilyMembers[0]?.id)
    setSearchResultFather(false)
    setSearchResultMother(false)
    setSearchResultSpouse(true)
  }

  // const optionGroup = [
  //   {
  //     options: searchFamilyMembers?.map((results, index) => ({
  //       key: index,
  //       label: results.full_name,
  //       value: results.id,
  //     })),
  //   },
  // ]

  // const handleEnters = textEntered => {
  //   setSearchText(textEntered)
  //   setFatherName(textEntered)
  //   setMotherName(textEntered)
  //   setSpouseName(textEntered)
  // }

  const [img, setImg] = useState()
  console.log(img)
  const [selectedFile, setSelectedFile] = useState({
    member_image: null,
  })
  const [filesSize, setFilesSize] = useState()

  const changeHandler = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      member_image: e.target.files[0],
    })
    const [file] = e.target.files
    setImg(URL.createObjectURL(file))

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const [customLoad, setCustomLoad] = useState(false)

  const familyMemberId = { id: familyMemberDetail?.id }

  const isUpdate = true

  const handleFileSizes = useFileSizes

  const handleValidSubmitImage = () => {
    if (handleFileSizes(filesSize)?.status === true) {
      uploadImage(
        setCustomLoad,
        selectedFile,
        familyMemberId,
        dispatch,
        history,
        isUpdate
      )
      setImagePreview()
    }
  }

  const headerData = [
    { id: 1, titleMal: "അടിസ്ഥാന വിവരം", titleEn: "Basic Details" },
    { id: 2, titleMal: "ആരോഗ്യ വിവരം", titleEn: "Health Details" },
    {
      id: 3,
      titleMal: "വിദ്യാഭ്യാസം / ജോലി",
      titleEn: "Education / Job",
      line: false,
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeTab])

  useEffect(() => {
    dispatch(getCountries(searchTextCountries))
  }, [dispatch, searchTextCountries])

  const optionGroupCountry = [
    {
      options: countries?.map((result, index) => ({
        key: index,
        label: `${
          language === "mal"
            ? result?.country_malayalam_name
              ? result?.country_malayalam_name
              : result?.country_english_name
            : result?.country_english_name
        }`,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValueCountry(event) {
    setCountryId(event.value)
    setCountryName(event.label)
  }

  const handleEnterCountries = textEntered => {
    setSearchTextCountries(textEntered)
  }

  useEffect(() => {
    const currentNumber = {
      phone: familyMemberDetail?.phone,
    }
    dispatch(
      checkFamilyMemberPhoneNumberUpdate(currentNumber, familyMemberDetail?.id)
    )
  }, [familyMemberDetail])

  const handleCheckFamilyMemberPhoneNumberUpdate = v => {
    if (v.length == 10) {
      const checkFamilyMemberPhoneNumberUpdateData = {
        phone: v,
      }
      dispatch(
        checkFamilyMemberPhoneNumberUpdate(
          checkFamilyMemberPhoneNumberUpdateData,
          familyMemberDetail?.id
        )
      )
      setCheckValue(v)
    }
  }

  return (
    <>
      <MetaTags>
        <title>Family Members | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Family Members"}
          breadcrumbItem={language === "mal" ? "പുതുക്കുക" : "Update"}
        />
        <Container fluid className="sm:p-0">
          <div className="container-fluid">
            <Row>
              {HeaderLine(
                activeTab,
                passedSteps,
                setactiveTab,
                language,
                headerData
              )}
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(v)
                }}
                className="sm:p-0"
              >
                <Col md={12} lg={12}>
                  <Card style={{ borderRadius: "15px" }}>
                    <CardBody className="p-0">
                      <Row>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            type="submit"
                            color="success"
                            className="w-sm m-2 btn-block"
                          >
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            )}
                            {language === "mal" ? "പുതുക്കുക" : "Update"}
                          </Button>
                        </div>
                      </Row>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                              <Row>
                                <Col md="3">
                                  <div className="mb-3">
                                    <AvField
                                      name="mahall_custom_id"
                                      type="text"
                                      value={
                                        familyMemberDetail?.mahall_custom_id
                                      }
                                      label={
                                        language === "mal"
                                          ? "മഹല്ല് ഐഡി"
                                          : "Mahall ID"
                                      }
                                      onChange={v => {
                                        handleCheckFamilyMemberCustomId(
                                          v.target.value
                                        )
                                        setCheckValueCustomId(v.target.value)
                                      }}
                                    />
                                  </div>
                                  {checkValueCustomId &&
                                    checkValueCustomId?.length > 0 &&
                                    (!customIdCheckLoading ? (
                                      customIdAvailability ? (
                                        <p className="text-success">
                                          {language === "mal"
                                            ? "ലഭ്യമാണ്"
                                            : "Available"}
                                        </p>
                                      ) : (
                                        <p className="text-danger">
                                          {language === "mal"
                                            ? "ലഭ്യമല്ല"
                                            : "Not Available"}
                                        </p>
                                      )
                                    ) : (
                                      <></>
                                    ))}
                                </Col>
                                {/* <Col md="3">
                                  <div className="mb-2">
                                    <AvField
                                      name="varisangya_grade"
                                      type="text"
                                      value={
                                        familyMemberDetail?.varisangya_grade
                                      }
                                      label={
                                        language === 'mal'
                                          ? 'വരിസംഖ്യ ഗ്രേഡ്'
                                          : 'Varisangya Grade'
                                      }
                                    />
                                  </div>
                                </Col> */}
                                {mahallTypeLists?.varisankya_grade &&
                                  mahallTypeLists?.varisankya_grade.length >
                                    0 && (
                                    <Col md="3" className="mb-1">
                                      <Label>
                                        {language === "mal"
                                          ? "വരിസംഖ്യ ഗ്രേഡ്"
                                          : "Varisangya Grade"}
                                        {/* <span className="text-danger">&#42;</span> */}
                                      </Label>
                                      <AvField
                                        type="select"
                                        name="family_member_varisankya_grade_choice_id"
                                        value={
                                          familyMemberDetail?.family_member_varisankya_grade_choice_id
                                            ? familyMemberDetail?.family_member_varisankya_grade_choice_id
                                            : ""
                                        }
                                      >
                                        <option value={null}>
                                          {language === "mal"
                                            ? "ഗ്രേഡ് തിരഞ്ഞെടുക്കുക ..."
                                            : "Select an grade..."}{" "}
                                        </option>
                                        {mahallTypeLists?.varisankya_grade?.map(
                                          (item, key) => (
                                            <option key={key} value={item.id}>
                                              {language === "mal"
                                                ? item?.malayalam
                                                : item?.english}
                                            </option>
                                          )
                                        )}
                                      </AvField>
                                    </Col>
                                  )}
                                <Col md="3">
                                  <div className="mb-2">
                                    <AvField
                                      name="birth_register_no"
                                      type="text"
                                      value={
                                        familyMemberDetail?.birth_register_no
                                      }
                                      label={
                                        language === "mal"
                                          ? " ജനന രജിസ്റ്റർ നമ്പർ"
                                          : "Birth Register Number"
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label>
                                      {language === "mal"
                                        ? "പേര്"
                                        : "Full Name"}
                                      <span className="text-danger">&#42;</span>
                                    </Label>
                                    <AvField
                                      name="full_name"
                                      type="text"
                                      value={familyMemberDetail?.full_name}
                                      onChange={e =>
                                        setFullName(e.target.value)
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      {language === "mal" ? "ലിംഗം" : "Gender"}
                                    </Label>
                                    <div className="d-flex flex-wrap">
                                      {map(genderTypes, (item, key) => (
                                        <div key={key} className="mx-1 ">
                                          <input
                                            type="radio"
                                            className="btn-check"
                                            name="btnradio11323"
                                            checked={item.value === gender}
                                            id={item.value}
                                            autoComplete="off"
                                            value={item.value}
                                            onClick={e =>
                                              handleSelectOrDeselect(
                                                gender,
                                                setGender,
                                                e.target.value
                                              )
                                            }
                                            // onChange={e =>
                                            //   setGender(e.target.value)
                                            // }
                                          />
                                          <label
                                            className="btn btn-outline-info"
                                            htmlFor={item.value}
                                          >
                                            {language === "mal"
                                              ? item?.malayalam
                                              : item?.english}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label for="basicpill-email-input4">
                                      {language === "mal"
                                        ? "അനാഥനാണോ?"
                                        : "Is Orphan?"}
                                    </Label>
                                    <div className="form-check form-switch form-switch-lg">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizemd"
                                        checked={isOrphanSwitch}
                                        onClick={() =>
                                          setIsOrphanSwitch(!isOrphanSwitch)
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="6">
                                  <Row>
                                    <Col xs="9">
                                      <div className="mb-3">
                                        <Label for="basicpill-email-input4">
                                          {language === "mal"
                                            ? "ജനന തിയതി"
                                            : "Date Of Birth"}
                                        </Label>
                                        <Input
                                          name="dob"
                                          type="date"
                                          className="form-control"
                                          id="basicpill-email-input4"
                                          value={dateOfBirth}
                                          onChange={e => {
                                            setDateOfBirth(e.target.value)
                                            getAge(e.target.value)
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col xs="3">
                                      <Label>
                                        {language === "mal" ? "വയസ്സ്" : "Age"}
                                      </Label>
                                      <Input
                                        name="age"
                                        // readOnly
                                        type="number"
                                        className="form-control"
                                        onChange={e => {
                                          convertToDOB(e.target.value)
                                          setAge(e.target.value)
                                        }}
                                        value={age}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md="6">
                                  <Row>
                                    <Col md="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-email-input4">
                                          {language === "mal"
                                            ? "കുടുംബനാഥനാണോ?"
                                            : "Is Family Head?"}
                                        </Label>
                                        <div className="form-check form-switch form-switch-lg">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customSwitchsizemd"
                                            checked={isFamilyHeadSwitch}
                                            onClick={() =>
                                              setIsFamilyHeadSwitch(
                                                !isFamilyHeadSwitch
                                              )
                                            }
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    {!isFamilyHeadSwitch && (
                                      <Col md="6">
                                        <AvField
                                          type="select"
                                          name="relation_choice_id"
                                          value={
                                            familyMemberDetail?.relation_choice_id
                                          }
                                          label={
                                            language === "mal"
                                              ? "കുടുംബനാഥനുമായുള്ള ബന്ധം?"
                                              : "Relation"
                                          }
                                        >
                                          <option>
                                            {language === "mal"
                                              ? "ബന്ധം തിരഞ്ഞെടുക്കുക..."
                                              : "Select relation..."}
                                          </option>
                                          {mahallTypeLists?.relation?.map(
                                            (item, key) => (
                                              <option key={key} value={item.id}>
                                                {item?.english}
                                              </option>
                                            )
                                          )}
                                        </AvField>
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      type="text"
                                      name="fatherName"
                                      label={
                                        language === "mal"
                                          ? "പിതാവ് "
                                          : "Father Name"
                                      }
                                      value={fatherName}
                                      onChange={e => {
                                        handleFatherNameChange(e.target.value)
                                      }}
                                    ></AvField>
                                    {searchResultFather &&
                                      fatherName &&
                                      fatherName.length > 0 &&
                                      searchFamilyMembers &&
                                      searchFamilyMembers[0]?.id && (
                                        <>
                                          {`Suggestion : ${
                                            fatherName &&
                                            fatherName.length > 0 &&
                                            (searchFamilyMembers[0]?.id
                                              ? `${searchFamilyMembers[0]?.full_name}`
                                              : "")
                                          }`}
                                          <Button
                                            className="m-2"
                                            onClick={() => {
                                              setFatherName(
                                                searchFamilyMembers[0]
                                                  ?.full_name
                                              )
                                              setFatherId(
                                                searchFamilyMembers[0]?.id
                                              )
                                            }}
                                          >
                                            {language === "mal"
                                              ? "തിരഞ്ഞെടുക്കുക"
                                              : "Select"}
                                          </Button>
                                        </>
                                      )}
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      type="text"
                                      name="motheName"
                                      label={
                                        language === "mal"
                                          ? "മാതാവ്"
                                          : "Mother Name"
                                      }
                                      value={motherName}
                                      onChange={e => {
                                        handleMotherNameChange(e.target.value)
                                      }}
                                    ></AvField>
                                    {searchResultMother &&
                                      motherName &&
                                      motherName.length > 0 &&
                                      searchFamilyMembers &&
                                      searchFamilyMembers[0]?.id && (
                                        <>
                                          {`Suggestion: ${
                                            motherName &&
                                            motherName.length > 0 &&
                                            (searchFamilyMembers[0]?.id
                                              ? `${searchFamilyMembers[0]?.full_name}`
                                              : "")
                                          }`}
                                          <Button
                                            className="m-2"
                                            onClick={() => {
                                              setMotherName(
                                                searchFamilyMembers[0]
                                                  ?.full_name
                                              )
                                              setMotherId(
                                                searchFamilyMembers[0]?.id
                                              )
                                            }}
                                          >
                                            {language === "mal"
                                              ? "തിരഞ്ഞെടുക്കുക"
                                              : "Select"}
                                          </Button>
                                        </>
                                      )}
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col md="6">
                                  <div className="mb-2 row">
                                    <Col md="4" xs="4" sm="4">
                                      <div style={{ marginTop: "27px" }}>
                                        <AvField
                                          type="text"
                                          name="countryCode"
                                          value="india (+91)"
                                          readOnly
                                        />
                                      </div>
                                    </Col>
                                    <Col md="8" xs="8" sm="8">
                                      <AvField
                                        name="phone"
                                        type="number"
                                        value={familyMemberDetail?.phone}
                                        // readOnly={
                                        //   familyMemberDetail?.phone ? true : false
                                        // }
                                        label={
                                          language === "mal"
                                            ? "നമ്പർ"
                                            : "Mobile Number"
                                        }
                                        validate={{
                                          // required: { value: true },
                                          maxLength: {
                                            value: 10,
                                            errorMessage: `${
                                              language === "mal"
                                                ? "ഫോൺ നമ്പർ 10 അക്കങ്ങൾ ആയിരിക്കണം"
                                                : "phone number must be 10 digits"
                                            }`,
                                          },
                                        }}
                                        onChange={v => {
                                          handleCheckFamilyMemberPhoneNumberUpdate(
                                            v.target.value
                                          )
                                          setCheckValue(v.target.value)
                                        }}
                                      />
                                      {/* {userDetail?.mahall?.version === "basic" ? (
                                      <></>
                                    ) : (
                                      familyMemberDetail?.phone && (
                                        <Link
                                          to="/familymember/update/phone/familymember"
                                          className="text mt-2 "
                                        >
                                          {language === "mal" ? "ഫോൺ നമ്പർ മാറ്റണോ?" : "Change Phone Number?"}
                                        </Link>
                                      )
                                    )} */}
                                      {checkValue &&
                                        checkValue?.length == 10 &&
                                        (!memberPhoneUpdateCheckLoading ? (
                                          familyMemberPhoneNumberUpdateAvailability ? (
                                            <p className="text-success pt-2">
                                              {language === "mal"
                                                ? "ലഭ്യമാണ്"
                                                : "Available"}
                                            </p>
                                          ) : (
                                            <p className="text-danger pt-2">
                                              {language === "mal"
                                                ? "ലഭ്യമല്ല"
                                                : "Not Available"}
                                            </p>
                                          )
                                        ) : (
                                          <></>
                                        ))}
                                    </Col>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      name="email"
                                      type="email"
                                      value={familyMemberDetail?.email}
                                      label={
                                        language === "mal" ? "ഇമെയിൽ" : "Email"
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3 row">
                                    <Col md="4" xs="4">
                                      <div style={{ marginTop: "27px" }}>
                                        <AvField
                                          type="select"
                                          name="alt_country_code"
                                          value={
                                            familyMemberDetail?.alt_country_code
                                          }
                                        >
                                          {countryCodes?.map((item, key) => (
                                            <option key={key} value={item.code}>
                                              {`${item?.name} (${item?.code})`}
                                            </option>
                                          ))}
                                        </AvField>
                                      </div>
                                    </Col>
                                    <Col md="8" xs="8">
                                      <AvField
                                        id="horizontal-firstname-Input"
                                        name="alt_phone_number"
                                        type="number"
                                        value={
                                          familyMemberDetail?.alt_phone_number
                                        }
                                        label={
                                          language === "mal"
                                            ? "ബദൽ നമ്പർ"
                                            : "Alternative Number"
                                        }
                                        validate={{
                                          // required: { value: true },
                                          maxLength: {
                                            value: 15,
                                            errorMessage: `${
                                              language === "mal"
                                                ? "ബദൽ നമ്പർ 15 അക്കങ്ങൾ ആയിരിക്കണം"
                                                : "Alternative number must be 15 digits"
                                            }`,
                                          },
                                        }}
                                      />
                                    </Col>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3 row">
                                    <Col md="4" xs="4">
                                      <div style={{ marginTop: "27px" }}>
                                        <AvField
                                          type="select"
                                          name="whatsapp_country_code"
                                          value={
                                            familyMemberDetail?.whatsapp_country_code
                                          }
                                        >
                                          {countryCodes?.map((item, key) => (
                                            <option key={key} value={item.code}>
                                              {`${item?.name} (${item?.code})`}
                                            </option>
                                          ))}
                                        </AvField>
                                      </div>
                                    </Col>
                                    <Col md="8" xs="8">
                                      <AvField
                                        id="horizontal-firstname-Input"
                                        name="whatsapp_number"
                                        type="number"
                                        value={
                                          whatsappNumber
                                            ? checkValue
                                            : familyMemberDetail?.whatsapp_number
                                        }
                                        label={
                                          language === "mal"
                                            ? "വാട്ട്സ്ആപ്പ് നമ്പർ"
                                            : "Whatsapp Number"
                                        }
                                        validate={{
                                          // required: { value: true },
                                          maxLength: {
                                            value: 15,
                                            errorMessage: `${
                                              language === "mal"
                                                ? "വാട്ട്സ്ആപ്പ് നമ്പർ 15 അക്കങ്ങൾ ആയിരിക്കണം"
                                                : "Whatsapp number must be 15 digits"
                                            }`,
                                          },
                                        }}
                                      />
                                      <div
                                        className="d-flex justify-content-end text-primary mt-1"
                                        style={{ alignItems: "center" }}
                                      >
                                        <input
                                          type="checkbox"
                                          name="checkbox_name"
                                          value="checkox_value"
                                          className="me-2"
                                          onChange={() =>
                                            setWhatsappNumber(!whatsappNumber)
                                          }
                                        />
                                        <p className="m-0">
                                          Same as mobile number
                                        </p>
                                      </div>
                                    </Col>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-5">
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-email-input4">
                                      {language === "mal"
                                        ? "ഐഡി കാർഡ് തരം"
                                        : "Id Card Type"}
                                    </Label>
                                    <div className="d-flex flex-wrap">
                                      {map(
                                        mahallTypeLists?.id_card,
                                        (item, key) => (
                                          <div key={key} className="mx-1 ">
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              name="btnradio2"
                                              checked={item.id === idCard}
                                              id={item.id}
                                              autoComplete="off"
                                              value={item.id}
                                              onClick={e =>
                                                handleSelectOrDeselect(
                                                  idCard,
                                                  setIdCard,
                                                  e.target.value
                                                )
                                              }
                                              // onChange={e =>
                                              //   setIdCard(e.target.value)
                                              // }
                                            />
                                            <label
                                              className="btn btn-outline-info"
                                              htmlFor={item.id}
                                            >
                                              {language === "mal"
                                                ? item?.malayalam
                                                : item?.english}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="mb-3">
                                    <AvField
                                      type="text"
                                      name="id_card_number"
                                      value={familyMemberDetail?.id_card_number}
                                      label={
                                        language === "mal"
                                          ? "ഐഡി നമ്പർ"
                                          : "ID Number"
                                      }
                                    ></AvField>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={isAbroadSwitch ? "1" : "3"}>
                                  <div className="mb-3">
                                    <Label for="basicpill-email-input4">
                                      {language === "mal"
                                        ? "വിദേശത്താണോ?"
                                        : "Is Abroad?"}
                                    </Label>
                                    <div className="form-check form-switch form-switch-lg">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizemd"
                                        checked={isAbroadSwitch}
                                        onClick={() =>
                                          setIsAbroadSwitch(!isAbroadSwitch)
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </Col>
                                {isAbroadSwitch && (
                                  <Col md="3">
                                    <div>
                                      <Label>
                                        {language === "mal"
                                          ? "രാജ്യം"
                                          : "Country"}
                                      </Label>
                                      <Select
                                        onInputChange={handleEnterCountries}
                                        placeholder={countryName}
                                        options={optionGroupCountry}
                                        classNamePrefix="select2-selection"
                                        onChange={handlerFinalValueCountry}
                                        type="text"
                                        title="Country"
                                      />
                                    </div>
                                  </Col>
                                )}
                                <Col md={isAbroadSwitch ? "8" : "9"}>
                                  <div className="mb-3">
                                    <AvField
                                      name="present_address"
                                      type="textarea"
                                      value={
                                        familyMemberDetail?.present_address
                                      }
                                      label={
                                        language === "mal"
                                          ? "നിലവിലെ അഡ്രസ്"
                                          : "Present Address"
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-email-input4">
                                      {language === "mal"
                                        ? "വൈവാഹിക നില"
                                        : "Marital Status"}
                                    </Label>
                                    <div className="d-flex flex-wrap">
                                      {map(
                                        mahallTypeLists?.marital_status,
                                        (item, key) => (
                                          <div key={key} className="mx-1">
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              name="btnradio3"
                                              checked={
                                                item.id === maritalStatus
                                              }
                                              id={item.id}
                                              autoComplete="off"
                                              value={item.id}
                                              onClick={e =>
                                                handleSelectOrDeselect(
                                                  maritalStatus,
                                                  setMaritalStatus,
                                                  e.target.value
                                                )
                                              }
                                              // onChange={e =>
                                              //   setMaritalStatus(e.target.value)
                                              // }
                                            />
                                            <label
                                              className="btn btn-outline-info"
                                              htmlFor={item.id}
                                            >
                                              {language === "mal"
                                                ? item?.malayalam
                                                : item?.english}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                {mahallTypeLists?.marital_status?.find(
                                  x =>
                                    x.id.toString() ===
                                    maritalStatus?.toString()
                                )?.english === "Married" && (
                                  <Col md="6">
                                    <AvField
                                      type="text"
                                      name="spouseName"
                                      label={
                                        language === "mal"
                                          ? "ഇണയുടെ പേര്"
                                          : "Spouse Name"
                                      }
                                      value={spouseName}
                                      onChange={e => {
                                        handleSpouseNameChange(e.target.value)
                                      }}
                                    ></AvField>
                                    {searchResultSpouse &&
                                      spouseName &&
                                      spouseName.length > 0 &&
                                      searchFamilyMembers &&
                                      searchFamilyMembers[0]?.id && (
                                        <>
                                          {`Suggestion: ${
                                            spouseName &&
                                            spouseName.length > 0 &&
                                            (searchFamilyMembers[0]?.id
                                              ? `${searchFamilyMembers[0]?.full_name}`
                                              : "")
                                          }`}
                                          <Button
                                            className="m-2"
                                            onClick={() => {
                                              setSpouseName(
                                                searchFamilyMembers[0]
                                                  ?.full_name
                                              )
                                              setSpouseId(
                                                searchFamilyMembers[0]?.id
                                              )
                                            }}
                                          >
                                            {language === "mal"
                                              ? "തിരഞ്ഞെടുക്കുക"
                                              : "Select"}
                                          </Button>
                                        </>
                                      )}
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                {mahallTypeLists?.marital_status?.find(
                                  x =>
                                    x.id.toString() ===
                                    maritalStatus?.toString()
                                )?.english === "Unmarried" && (
                                  <Col md="12">
                                    <div className="mb-3">
                                      <Label for="basicpill-email-input4">
                                        {language === "mal"
                                          ? "അവിവാഹിത/ൻ കാരണം"
                                          : "Unmarried Reason"}
                                      </Label>
                                      <div className="d-flex flex-wrap">
                                        {map(
                                          mahallTypeLists?.unmarried_reason,
                                          (item, key) => (
                                            <div key={key} className="mx-1">
                                              <input
                                                type="radio"
                                                className="btn-check"
                                                name="btnradio4"
                                                checked={
                                                  item.id === unmarriedReason
                                                }
                                                id={item.id}
                                                autoComplete="off"
                                                value={item.id}
                                                onClick={e =>
                                                  handleSelectOrDeselect(
                                                    unmarriedReason,
                                                    setUnmarriedReason,
                                                    e.target.value
                                                  )
                                                }
                                                // onChange={e =>
                                                //   setUnmarriedReason(
                                                //     e.target.value
                                                //   )
                                                // }
                                              />
                                              <label
                                                className="btn btn-outline-info"
                                                htmlFor={item.id}
                                              >
                                                {language === "mal"
                                                  ? item?.malayalam
                                                  : item?.english}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                {mahallTypeLists?.marital_status?.find(
                                  x =>
                                    x.id.toString() ===
                                    maritalStatus?.toString()
                                )?.english === "Divorced" && (
                                  <Col md="12">
                                    <div className="mb-3">
                                      <AvField
                                        name="divorce_reason"
                                        type="text"
                                        value={
                                          familyMemberDetail?.divorce_reason
                                        }
                                        label={
                                          language === "mal"
                                            ? "വിവാഹമോചനത്തിനുള്ള കാരണം"
                                            : "Divorce Reason"
                                        }
                                      />
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                              <div>
                                <Row>
                                  <Col md="9">
                                    <div className="mb-3">
                                      <Label for="basicpill-servicetax-input8">
                                        {language === "mal"
                                          ? "രക്ത ഗ്രൂപ്പ്"
                                          : "Blood Group"}
                                      </Label>
                                      {/* <div className="d-flex flex-wrap">
                                        {map(
                                          mahallTypeLists?.blood_group,
                                          (item, key) => (
                                            <div key={key} className="mx-1">
                                              {bloodGroup === item.id ? 'true' : 'false'}
                                              <input
                                                type="radio"
                                                className="btn-check"
                                                name="btnradio5"
                                                checked={item.id === bloodGroup}
                                                id={item.id}
                                                autoComplete="off"
                                                value={item.id}
                                                onChange={e =>
                                                  setBloodGroup(e.target.value)
                                                }
                                              />
                                              <label
                                                className="btn btn-outline-info"
                                                htmlFor={item.id}
                                              >
                                                {item.english}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </div> */}
                                      <div className="d-flex flex-wrap">
                                        {map(
                                          mahallTypeLists?.blood_group,
                                          (item, key) => (
                                            <div key={key} className="mx-1">
                                              <input
                                                type="radio"
                                                className="btn-check"
                                                // name="btnradio5"
                                                checked={item.id === bloodGroup}
                                                id={item.id}
                                                autoComplete="off"
                                                value={item.id}
                                                onClick={e =>
                                                  handleSelectOrDeselect(
                                                    bloodGroup,
                                                    setBloodGroup,
                                                    e.target.value
                                                  )
                                                }
                                                // onChange={e =>
                                                //   setBloodGroup(e.target.value)
                                                // }
                                              />
                                              <label
                                                className="btn btn-outline-info"
                                                htmlFor={item.id}
                                              >
                                                {language === "mal"
                                                  ? item?.malayalam
                                                  : item?.english}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label for="basicpill-vatno-input6">
                                        {language === "mal"
                                          ? "രക്തദാനം ചെയ്യാൻ തയ്യാറാണോ?"
                                          : "Ready To Donate?"}
                                      </Label>
                                      <div className="form-check form-switch form-switch-lg">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          checked={readyToDonateSwitch}
                                          onClick={() =>
                                            setReadyToDonateSwitch(
                                              !readyToDonateSwitch
                                            )
                                          }
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="12" md="6" className="mb-3">
                                    <Label for="basicpill-cstno-input7">
                                      {language === "mal"
                                        ? "ആരോഗ്യസ്ഥിതി (ഒന്നിലധികം)"
                                        : "Health Condition (multiple)"}
                                    </Label>
                                    <div className="d-flex  flex-wrap">
                                      {map(
                                        mahallTypeLists?.health_condition,
                                        (item, key) => (
                                          <div key={key} className="m-1">
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              name={item.id}
                                              checked={healthCondition?.includes(
                                                item.id
                                              )}
                                              id={item.id}
                                              autoComplete="off"
                                              value={item.id}
                                              onClick={e =>
                                                addOrRemoveItem(
                                                  healthCondition,
                                                  setHealthCondition,
                                                  e.target.value
                                                )
                                              }
                                              readOnly
                                            />
                                            <label
                                              className="btn btn-outline-info"
                                              htmlFor={item.id}
                                            >
                                              {language === "mal"
                                                ? item?.malayalam
                                                : item?.english}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  {healthCondition?.includes(
                                    mahallTypeLists?.health_condition?.find(
                                      x => x?.english === "Patient"
                                    )?.id
                                  ) && (
                                    <Col sm="12" md="12" className="mb-3">
                                      <Label for="basicpill-cstno-input7">
                                        {language === "mal"
                                          ? "രോഗത്തിന്റെ തരം (ഒന്നിലധികം)"
                                          : "Disease Type (multiple)"}
                                      </Label>
                                      <div className="d-flex  flex-wrap">
                                        {map(
                                          mahallTypeLists?.disease_type,
                                          (item, key) => (
                                            <div key={key} className="m-1">
                                              <input
                                                type="radio"
                                                className="btn-check"
                                                name={item.id}
                                                checked={diseaseType?.includes(
                                                  item.id
                                                )}
                                                id={item.id}
                                                autoComplete="off"
                                                value={item.id}
                                                onClick={e =>
                                                  addOrRemoveItem(
                                                    diseaseType,
                                                    setDiseaseType,
                                                    e.target.value
                                                  )
                                                }
                                                readOnly
                                              />
                                              <label
                                                className="btn btn-outline-info"
                                                htmlFor={item.id}
                                              >
                                                {language === "mal"
                                                  ? item?.malayalam
                                                  : item?.english}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  {healthCondition?.includes(
                                    mahallTypeLists?.health_condition?.find(
                                      x => x?.english === "Patient"
                                    )?.id
                                  ) &&
                                    diseaseType?.includes(
                                      mahallTypeLists?.disease_type?.find(
                                        x => x?.english === "Others"
                                      )?.id
                                    ) && (
                                      <Col md="6">
                                        <div className="mb-3">
                                          <AvField
                                            name="other_disease_type"
                                            type="text"
                                            value={
                                              familyMemberDetail?.other_disease_type
                                            }
                                            label={
                                              language === "mal"
                                                ? "മറ്റ് രോഗ തരം"
                                                : "Other Disease Type"
                                            }
                                          />
                                        </div>
                                      </Col>
                                    )}
                                  {healthCondition?.includes(
                                    mahallTypeLists?.health_condition?.find(
                                      x => x?.english === "Patient"
                                    )?.id
                                  ) && (
                                    <Col md="6">
                                      <div className="mb-3">
                                        <Label for="basicpill-pancard-input5">
                                          {language === "mal"
                                            ? "രോഗ നില"
                                            : "Disease Level"}
                                        </Label>
                                        <div className="d-flex  flex-wrap">
                                          {map(
                                            mahallTypeLists?.disease_level,
                                            (item, key) => (
                                              <div key={key} className="m-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  // name="btnradio8"
                                                  checked={
                                                    item.id === diseaseLevel
                                                  }
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      diseaseLevel,
                                                      setDiseaseLevel,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setDiseaseLevel(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  {healthCondition?.includes(
                                    mahallTypeLists?.health_condition?.find(
                                      x => x?.english === "Disabled"
                                    )?.id
                                  ) && (
                                    <Col md="12">
                                      <div className="mb-3">
                                        <Label for="basicpill-declaration-input10">
                                          {language === "mal"
                                            ? "വൈകല്യ തരം (ഒന്നിലധികം)"
                                            : "Disability Type (multiple)"}
                                          )
                                        </Label>
                                        <div className="d-flex  flex-wrap">
                                          {map(
                                            mahallTypeLists?.disability_type,
                                            (item, key) => (
                                              <div key={key} className="m-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  name={item.id}
                                                  checked={disabilityType?.includes(
                                                    item.id
                                                  )}
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e =>
                                                    addOrRemoveItem(
                                                      disabilityType,
                                                      setDisabilityType,
                                                      e.target.value
                                                    )
                                                  }
                                                  readOnly
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  {healthCondition?.includes(
                                    mahallTypeLists?.health_condition?.find(
                                      x => x?.english === "Disabled"
                                    )?.id
                                  ) &&
                                    disabilityType?.includes(
                                      mahallTypeLists?.disability_type?.find(
                                        x => x?.english === "Others"
                                      )?.id
                                    ) && (
                                      <Col md="6">
                                        <div className="mb-3">
                                          <AvField
                                            name="other_disability_type"
                                            type="text"
                                            value={
                                              familyMemberDetail?.other_disability_type
                                            }
                                            label={
                                              language === "mal"
                                                ? "മറ്റ് വൈകല്യ തരം"
                                                : "Other Disability Type"
                                            }
                                          />
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md="12">
                                    <div className="mb-3">
                                      <Label for="basicpill-declaration-input10">
                                        {language === "mal"
                                          ? "സാമൂഹിക പ്രവർത്തനങ്ങൾ (ഒന്നിലധികം)"
                                          : "Social Activities (multiple)"}
                                      </Label>
                                      <div className="d-flex  flex-wrap">
                                        {map(
                                          mahallTypeLists?.social_activity_type,
                                          (item, key) => (
                                            <div key={key} className="m-1">
                                              <input
                                                type="radio"
                                                className="btn-check"
                                                name={item.id}
                                                checked={socialActivity?.includes(
                                                  item.id
                                                )}
                                                id={item.id}
                                                autoComplete="off"
                                                value={item.id}
                                                onClick={e =>
                                                  addOrRemoveItem(
                                                    socialActivity,
                                                    setSocialActivity,
                                                    e.target.value
                                                  )
                                                }
                                                readOnly
                                              />
                                              <label
                                                className="btn btn-outline-info"
                                                htmlFor={item.id}
                                              >
                                                {language === "mal"
                                                  ? item?.malayalam
                                                  : item?.english}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  {socialActivity &&
                                    socialActivity?.length > 0 && (
                                      <Col md="6">
                                        <div className="mb-3">
                                          <AvField
                                            name="social_activity_details"
                                            type="text"
                                            value={
                                              familyMemberDetail?.social_activity_details
                                            }
                                            label={
                                              language === "mal"
                                                ? "വിശദാംശങ്ങൾ"
                                                : "Details"
                                            }
                                          />
                                        </div>
                                      </Col>
                                    )}
                                </Row>
                                <Row>
                                  <Col md="12">
                                    <div className="mb-3">
                                      <AvField
                                        name="skills"
                                        type="textarea"
                                        value={familyMemberDetail?.skills}
                                        label={
                                          language === "mal"
                                            ? "കഴിവുകൾ"
                                            : "Skills"
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="12">
                                    <div className="mb-3">
                                      <Label for="basicpill-declaration-input10">
                                        {language === "mal"
                                          ? "മോശം ശീലങ്ങൾ (ഒന്നിലധികം)"
                                          : "Bad Habits (multiple)"}
                                      </Label>
                                      <div className="d-flex  flex-wrap">
                                        {map(
                                          mahallTypeLists?.bad_habits,
                                          (item, key) => (
                                            <div key={key} className="m-1">
                                              <input
                                                type="radio"
                                                className="btn-check"
                                                name={item.id}
                                                checked={badHabits?.includes(
                                                  item.id
                                                )}
                                                id={item.id}
                                                autoComplete="off"
                                                value={item.id}
                                                onClick={e =>
                                                  addOrRemoveItem(
                                                    badHabits,
                                                    setBadHabits,
                                                    e.target.value
                                                  )
                                                }
                                                readOnly
                                              />
                                              <label
                                                className="btn btn-outline-info"
                                                htmlFor={item.id}
                                              >
                                                {language === "mal"
                                                  ? item?.malayalam
                                                  : item?.english}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>

                            <TabPane tabId={3}>
                              <div>
                                <Row>
                                  <Col md="12">
                                    <div className="mb-3">
                                      <Label for="basicpill-servicetax-input8">
                                        {language === "mal"
                                          ? "മത വിദ്യാഭ്യാസം"
                                          : "Islamic Education"}
                                      </Label>
                                      <div className="d-flex flex-wrap">
                                        {map(
                                          mahallTypeLists?.educational_qualification_islamic,
                                          (item, key) => (
                                            <div key={key} className="mx-1">
                                              <input
                                                type="radio"
                                                className="btn-check"
                                                // name="btnradio5"
                                                checked={
                                                  item.id === islamicEducation
                                                }
                                                id={item.id}
                                                autoComplete="off"
                                                value={item.id}
                                                onClick={e =>
                                                  handleSelectOrDeselect(
                                                    islamicEducation,
                                                    setIslamicEducation,
                                                    e.target.value
                                                  )
                                                }
                                                // onChange={e =>
                                                //   setIslamicEducation(
                                                //     e.target.value
                                                //   )
                                                // }
                                              />
                                              <label
                                                className="btn btn-outline-info"
                                                htmlFor={item.id}
                                              >
                                                {language === "mal"
                                                  ? item?.malayalam
                                                  : item?.english}
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Col md="12">
                                  <div className="mb-3">
                                    <AvField
                                      name="educational_qualification_islamic_details"
                                      value={
                                        familyMemberDetail?.educational_qualification_formal_details
                                      }
                                      type="text"
                                      label={
                                        language === "mal"
                                          ? "വിശദാംശങ്ങൾ"
                                          : "Details"
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col md="12">
                                  <div className="mb-3">
                                    <Label for="basicpill-servicetax-input8">
                                      {language === "mal"
                                        ? "ഭൗതിക വിദ്യാഭ്യാസം"
                                        : "Formal Education"}
                                    </Label>
                                    <div className="d-flex flex-wrap">
                                      {map(
                                        mahallTypeLists?.educational_qualification_formal,
                                        (item, key) => (
                                          <div key={key} className="mx-1">
                                            <input
                                              type="radio"
                                              className="btn-check"
                                              // name="btnradio5"
                                              checked={
                                                item.id === formalEducation
                                              }
                                              id={item.id}
                                              autoComplete="off"
                                              value={item.id}
                                              onClick={e =>
                                                handleSelectOrDeselect(
                                                  formalEducation,
                                                  setFormalEducation,
                                                  e.target.value
                                                )
                                              }
                                              // onChange={e =>
                                              //   setFormalEducation(
                                              //     e.target.value
                                              //   )
                                              // }
                                            />
                                            <label
                                              className="btn btn-outline-info"
                                              htmlFor={item.id}
                                            >
                                              {language === "mal"
                                                ? item?.malayalam
                                                : item?.english}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <div className="mb-3">
                                    <AvField
                                      name="educational_qualification_formal_details"
                                      value={
                                        familyMemberDetail?.educational_qualification_islamic_details
                                      }
                                      type="text"
                                      label={
                                        language === "mal"
                                          ? "വിശദാംശങ്ങൾ"
                                          : "Details"
                                      }
                                    />
                                  </div>
                                </Col>
                                <Row>
                                  <Col md="3">
                                    <div className="mb-3">
                                      <Label for="basicpill-vatno-input6">
                                        {language === "mal"
                                          ? "വിദ്യാർത്ഥിയാണോ?"
                                          : "Is Student?"}
                                      </Label>
                                      <div className="form-check form-switch form-switch-lg">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          checked={isStudentSwitch}
                                          onClick={() =>
                                            setIsStudentSwitch(!isStudentSwitch)
                                          }
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  {!isStudentSwitch && (
                                    <Col md="9">
                                      <div className="mb-3">
                                        <Label for="basicpill-servicetax-input8">
                                          {language === "mal"
                                            ? "പഠിക്കാത്തതിന്റെ കാരണം"
                                            : "Not Studying Reason"}
                                        </Label>
                                        <div className="d-flex flex-wrap">
                                          {map(
                                            mahallTypeLists?.not_studying_reason,
                                            (item, key) => (
                                              <div key={key} className="mx-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  // name="btnradio5"
                                                  checked={
                                                    item.id === notStudyReason
                                                  }
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      notStudyReason,
                                                      setNotStudyReason,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setNotStudyReason(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  <Col md="3">
                                    <div className="mb-4">
                                      <Label for="basicpill-vatno-input6">
                                        {language === "mal"
                                          ? "ജോലി ചെയ്യുന്നുണ്ടോ?"
                                          : "Is Working?"}
                                      </Label>
                                      <div className="form-check form-switch form-switch-lg">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          checked={isWorkingSwitch}
                                          onClick={() =>
                                            setIsWorkingSwitch(!isWorkingSwitch)
                                          }
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  {!isWorkingSwitch && (
                                    <Col md="9">
                                      <div className="mb-3">
                                        <Label for="basicpill-servicetax-input8">
                                          {language === "mal"
                                            ? "ജോലി ചെയ്യാത്തതിന്റെ കാരണം"
                                            : "Not Working Reason"}
                                        </Label>
                                        <div className="d-flex flex-wrap">
                                          {map(
                                            mahallTypeLists?.not_working_reason,
                                            (item, key) => (
                                              <div key={key} className="mx-1">
                                                <input
                                                  type="radio"
                                                  className="btn-check"
                                                  // name="btnradio5"
                                                  checked={
                                                    item.id === notWorkingReason
                                                  }
                                                  id={item.id}
                                                  autoComplete="off"
                                                  value={item.id}
                                                  onClick={e =>
                                                    handleSelectOrDeselect(
                                                      notWorkingReason,
                                                      setNotWorkingReason,
                                                      e.target.value
                                                    )
                                                  }
                                                  // onChange={e =>
                                                  //   setNotWorkingReason(
                                                  //     e.target.value
                                                  //   )
                                                  // }
                                                />
                                                <label
                                                  className="btn btn-outline-info"
                                                  htmlFor={item.id}
                                                >
                                                  {language === "mal"
                                                    ? item?.malayalam
                                                    : item?.english}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Col md="3" className="mb-3">
                                  <AvField
                                    type="number"
                                    name="varisankya_amount"
                                    value={
                                      familyMemberDetail?.varisankya_amount
                                        ? familyMemberDetail?.varisankya_amount
                                        : 0
                                    }
                                    label={
                                      language === "mal"
                                        ? "വരിസംഖ്യ തുക"
                                        : "Varisangya Amount"
                                    }
                                    validate={{
                                      min: {
                                        value: 0,
                                        errorMessage: `${
                                          language === "mal"
                                            ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                                            : "Amount must be greater than 0"
                                        }`,
                                      },
                                    }}
                                  ></AvField>
                                </Col>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                activeTab === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                              >
                                {language === "mal"
                                  ? "പിന്നിലേക്ക്"
                                  : "Previous"}
                              </Link>
                            </li>
                            {activeTab !== 3 && (
                              <li
                                className={
                                  activeTab === 3 || !fullName
                                    ? "next disabled"
                                    : "next"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    !fullName ? null : toggleTab(activeTab + 1)
                                  }}
                                >
                                  {language === "mal" ? "അടുത്തത്" : "Next"}
                                </Link>
                              </li>
                            )}
                            {activeTab === 3 && (
                              <li>
                                <Button
                                  type="submit"
                                  color="success"
                                  className="w-sm btn-block"
                                >
                                  {loading && (
                                    <>
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    </>
                                  )}
                                  {language === "mal" ? "പുതുക്കുക" : "Update"}
                                </Button>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </AvForm>
            </Row>
            <Row>
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmitImage(e, v)
                }}
                className="sm:p-0"
              >
                <Card className="">
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label>
                            {language === "mal" ? "ഫോട്ടോ" : "Photo"}
                          </Label>
                          <div>
                            <Input
                              onChange={changeHandler}
                              type="file"
                              name="member_image"
                              className="form"
                            />
                          </div>
                          {handleFileSizes(filesSize)?.text && (
                            <p className="text-danger mt-1">
                              {handleFileSizes(filesSize)?.text}
                            </p>
                          )}
                          {imagePreview ? (
                            <Image
                              style={{ width: "150px", height: "150px" }}
                              className="my-2"
                              src={imagePreview.image}
                            />
                          ) : (
                            <Image
                              style={{ width: "150px", height: "150px" }}
                              className="my-2"
                              src={familyMemberDetail?.member_image}
                            />
                          )}
                        </div>
                      </Col>
                      <Col
                        sm="4"
                        className="mt-5 align-items-center
                            d-flex
                            justify-content-end"
                        style={{ marginLeft: "auto" }}
                      >
                        <Button
                          type="submit"
                          color="success"
                          className="w-sm btn-block"
                          disabled={selectedFile?.member_image === null}
                        >
                          {customLoad && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal"
                            ? "ഫോട്ടോ അപ്‌ലോഡ് ചെയ്യുക"
                            : "Upload Photo"}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </AvForm>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateMahallFamilyMember

UpdateMahallFamilyMember.propTypes = {
  history: PropTypes.object,
}
