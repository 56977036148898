import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

//Import Components

import Navbar from "./Navbar/Navbar"
import Section from "./HeroSection/Section"
import Features from "./Features/features"
import Footer from "./Footer/footer"
import MiniCard from "./MiniCards"
import ContactUs from "./contact"
import AboutUs from "./AboutUs/about"
import Video from "./Video/video"
import Packages from "./Packages/packages"

import logodark from "assets/images/logo/logo.png"
import AppDownloadSection from "./AppDownloadSection"
import Donation from "./Donation"

const LandingPage = () => {
  const [imglight, setimglight] = useState(true)
  const [navClass, setnavClass] = useState("")

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])
  // Use ComponentDidMount
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true)
  }, [])

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 80) {
      setimglight(false)
      setnavClass("nav-sticky")
    } else {
      setimglight(true)
      setnavClass("")
    }
  }

  // const height =
  //   document.querySelector('.modal_section')?.offsetHeight / 2 ?? 250

  return (
    <React.Fragment>
      <MetaTags>
        <title>Home | Khaf</title>
      </MetaTags>

      {loading ? (
        <>
          <div className="load-container">
            <div className="outer-ring"></div>
            <div style={{ marginLeft: "-17px" }} className="mt-2">
              <img
                src={logodark}
                alt=""
                height="40"
                className="logo logo-dark"
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          <Navbar navClass={navClass} imglight={imglight} />

          <Section />

          {/* <ImageCards /> */}

          <MiniCard />

          <AboutUs />

          <Video />

          <Features />

          {/* <Packages /> */}

          <Donation />
          <ContactUs />

          <Footer />
        </div>
      )}
    </React.Fragment>
  )
}

export default LandingPage
