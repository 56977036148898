import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
//actions
import axios from "axios"
import { Image } from "react-bootstrap"
import moment from "moment"

import { createInstituteSuccess, createInstituteFail } from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import { API_URL } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { useFileSizes } from "hooks/useFileSize"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const CreateMahallProgram = ({ history }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const handleFileSizes = useFileSizes

  const { loading, mahallId, language } = useSelector(state => ({
    loading: state.Institutes.loading,
    error: state.Institutes.error,
    mahallId: state.Users.userDetail?.mahall?.id,
    language: state.Layout.language,
  }))

  const today = moment().format("YYYY-MM-DD")

  const instituteType = location?.search?.split("?type=")[1]

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })

  const [imagePreview, setImagePreview] = useState()

  const [filesSize, setFilesSize] = useState()

  const handleChange = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    onSubmitProps.preventDefault()

    setCustomLoad(true)
    createNewInstitute(
      filesSize,
      selectedFile,
      values,
      mahallId,
      instituteType,
      dispatch,
      history,
      setCustomLoad
    )
    setImagePreview()
  }

  return (
    <>
      <MetaTags>
        <title>Programs | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "പ്രോഗ്രാമുകൾ" : "Programs"}
          breadcrumbItem={language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">
                      {language === "mal"
                        ? "ഈ ഫോം പൂരിപ്പിക്കുക"
                        : "Fill this form"}
                    </CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                      // contentType="multipart/form-data"
                    >
                      <div className="row mb-4">
                        <Col>
                          <Label>
                            {language === "mal"
                              ? "പേര് (ഇംഗ്ലീഷ്)"
                              : "Name (English)"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="institute_english_name"
                            type="text"
                            // label="Name (English)"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col className="mb-3">
                          <AvField
                            name="institute_malayalam_name"
                            type="text"
                            label={
                              language === "mal"
                                ? "പേര് (മലയാളം)"
                                : "Name (മലയാളം)"
                            }
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col sm="12" md="6">
                          <AvField
                            name="institute_place"
                            type="text"
                            label={language === "mal" ? "സ്ഥലം" : "Place"}
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <AvField
                            name="established_date"
                            value={today}
                            type="date"
                            label={
                              language === "mal"
                                ? "സ്ഥാപിച്ച തീയതി"
                                : "Established Date"
                            }
                          />
                        </Col>
                      </div>
                      <div className="row mb-4 mt-2">
                        <Col md="12">
                          <AvField
                            name="description"
                            type="textarea"
                            label={
                              language === "mal" ? "വിവരണം" : "Description"
                            }
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <Label>{language === "mal" ? "ലോഗോ" : "Logo"} </Label>
                          <AvField
                            name="logo"
                            type="file"
                            onChange={handleChange}
                          />
                          {handleFileSizes(filesSize)?.text && (
                            <p className="text-danger mt-1">
                              {handleFileSizes(filesSize)?.text}
                            </p>
                          )}
                          {imagePreview && (
                            <Image
                              style={{ width: "150px", height: "150px" }}
                              className="my-2"
                              src={imagePreview.image}
                            />
                          )}
                        </Col>
                      </div>
                      <div className="justify-content-end d-flex">
                        <Button type="submit" color="primary" className="w-md">
                          {loading ||
                            (customLoad && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            ))}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateMahallProgram

CreateMahallProgram.propTypes = {
  history: PropTypes.object,
}

function createNewInstitute(
  filesSize,
  selectedFile,
  values,
  mahallId,
  instituteType,
  dispatch,
  history
  // setCustomLoad,
) {
  const formData = new FormData()
  const handleFileSizes = useFileSizes
  {
    handleFileSizes(filesSize)?.status === true &&
      selectedFile &&
      selectedFile?.image &&
      formData.append("logo", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("mahall", mahallId)
  formData.append("institute_english_name", values.institute_english_name)
  formData.append("institute_malayalam_name", values.institute_malayalam_name)
  formData.append("established_date", values.established_date)
  formData.append("institute_type", instituteType)
  formData.append("institute_place", values.institute_place)
  formData.append("description", values.description)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .post(`${API_URL}/api/v1/institute/institute/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        dispatch(createInstituteSuccess(res.data))
        history.push("/programes")
        doneNotification("Created Successfully")
        // setCustomLoad(false)
      } else {
        // createInstituteFail(err)
        errorNotification()
      }
    })
    .catch(err => {
      createInstituteFail(err)
      errorNotification()
      // setCustomLoad(false)
    })
}
