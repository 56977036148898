import ReactApexChart from "react-apexcharts"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Spinner } from "reactstrap"

import "react-datepicker/dist/react-datepicker.css"

import { getDashboardData } from "store/Dashboard/actions"

const HealthChart = () => {
  const dispatch = useDispatch()

  const { loading, chartData, language } = useSelector(state => ({
    chartData: state.Dashboard.dashboardData,
    loading: state.Dashboard.dashboardLoading,
    language: state.Layout.language,
  }))

  console.log(chartData)

  useEffect(() => {
    dispatch(getDashboardData())
  }, [dispatch])

  const total =
    chartData?.no_of_satisfied +
    chartData?.no_of_patients +
    chartData?.no_of_disabled

  const series = Object.values(chartData)

  const options = {
    labels: [
      `${language === "mal" ? "സംതൃപ്തർ" : "Satisfied"}`,
      `${language === "mal" ? "രോഗികൾ" : "Patient"}`,
      `${language === "mal" ? "വികലാംഗർ" : "Disabled"}`,
    ],
    colors: ["#60e2c0", "#d7d6e1", "#03a96f"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  console.log(series)

  return (
    <>
      <Card style={{ height: "400px" }}>
        <CardBody>
          <CardTitle
            className="mb-1 d-flex "
            style={{ justifyContent: "space-between" }}
          >
            {language === "mal" ? "ആരോഗ്യ ചാർട്ട്" : "Health Chart"}{" "}
            <span style={{ float: "right" }}>Total :{total}</span>
          </CardTitle>

          {!loading && series?.length ? (
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              height="340px"
            />
          ) : (
            <>
              <Spinner type="grow" color="info" />
            </>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default HealthChart
