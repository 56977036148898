import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Label,
  Input,
  Container,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
import Select from "react-select"
import { MetaTags } from "react-meta-tags"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import {
  getFamiliesCards,
  getMahallTypeLists,
  getFilteredFamilies,
  getMahallWardNumbers,
  getFamiliesForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
//css
import "assets/scss/datatables.scss"
import { useGetSession, useSetSession } from "hooks/useHandleSessions"
import UiModalVarisangyaItemCreate from "./UiModalVarisangyaItem"
// import { Button } from "bootstrap"

const FamilyVarisangyaItemFilterList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [isFilterListOpen, setIsFilterListOpen] = useState(false)

  const [modalVarisangyaItemCreate, setModalVarisangyaItemCreate] =
    useState(false)
  const [modalVarisangyaItemCreateData, setModalVarisangyaItemCreateData] =
    useState([])

  const {
    families,
    loading,
    // familiesCards,
    language,
    userDetail,
    mahallTypeLists,
    mahallWardNumbers,
    // mahallId,
    // familiesforDownload,
    // familiesforDownloadLoading,
    // familiesCardsLoading,
  } = useSelector(state => ({
    families: state.Families.families,
    loading: state.Families.loading,
    familiesforDownload: state.Families.familiesforDownload,
    familiesforDownloadLoading: state.Families.familiesforDownloadLoading,
    familiesCards: state.Families.familiesCards,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    mahallWardNumbers: state.TypeLists.mahallWardNumbers.ward_numbers,
    mahallId: state.Users.userDetail?.mahall?.id,
    familiesCardsLoading: state.Families.familiesCardsLoading,
  }))

  // let filterListArea = [];

  const sort_value_family = useGetSession("sort_value_family")
  const pageSize_value = useGetSession("pageSize_value")

  const [sort, setSort] = useState(
    sort_value_family ? sort_value_family : "-custom_id"
  )
  const [pageSize, setPageSize] = useState(pageSize_value || 10)
  const [filterListArea, setFilterListArea] = useState([])

  //pages
  const totalPages = Math.ceil(families?.count / pageSize)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const handlePremiumLink = () => {
    if (["premium", "standard"].includes(userDetail?.mahall?.version)) {
      return "!#"
    } else {
      return "/premium"
    }
  }

  const filterData = {
    filter: filterListArea,
    sort: [sort],
    search: searchText,
  }

  useEffect(() => {
    if (
      userDetail &&
      ["premium", "standard"].includes(userDetail?.mahall?.version)
    ) {
      dispatch(getFamiliesForDownload(filterData))
    }
  }, [dispatch, sort, searchText, filterListArea])

  useEffect(() => {
    // dispatch(getFamilies(searchText, pageSend(), "", sort, pageSize))
    dispatch(getFilteredFamilies(page, pageSize, filterData))
  }, [dispatch, page, sort, pageSize, searchText, filterListArea])

  useEffect(() => {
    dispatch(getFamiliesCards())
  }, [dispatch])

  // useEffect(() => {
  //   if (userDetail && userDetail?.mahall?.version === 'premium') {
  //     dispatch(getFamiliesForDownload())
  //   }
  // }, [dispatch])

  useEffect(() => {
    if (userDetail?.mahall?.id) {
      if (!mahallTypeLists || mahallTypeLists.length <= 0)
        dispatch(getMahallTypeLists(userDetail?.mahall?.id))
      if (!mahallWardNumbers || mahallWardNumbers.length < 0)
        dispatch(getMahallWardNumbers(userDetail?.mahall?.id))
    }
  }, [dispatch, userDetail, mahallTypeLists, mahallWardNumbers])

  const sortData = [
    {
      heading: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
      id: "mahall_custom_id",
    },
    {
      heading: `${language === "mal" ? "തിയതി" : "Date"}`,
      id: "-custom_id",
    },
  ]

  // const cardData = [
  //   {
  //     title: `${language === 'mal' ? 'ആകെ കുടുംബങ്ങൾ' : 'Total Families'}`,
  //     cardValue: familiesCards?.total_families ?? 0,
  //     iconClass: 'home',
  //     routeLink: '/families',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'കുടുംബങ്ങൾ ഇല്ല' : 'No Families'}`,
  //   },
  //   {
  //     title: `${language === 'mal' ? 'ആകെ അംഗങ്ങൾ' : 'Total Members'}`,
  //     cardValue: familiesCards?.total_family_members ?? 0,
  //     iconClass: 'user',
  //     routeLink: '/familymembers',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'കുടുംബാംഗങ്ങൾ ഇല്ല' : 'No Family Members'}`,
  //   },
  //   {
  //     title: `${language === 'mal' ? 'ആൺ - പെൺ' : 'Male - Female'}`,
  //     cardValue: `${familiesCards?.total_males ?? 0} - ${
  //       familiesCards?.total_females ?? 0
  //     }`,
  //     iconClass: 'show',
  //     routeLink: '/familymembers',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'ആൺ - പെൺ ഇല്ല' : 'No Male - Female'}`,
  //   },
  // ]

  const columns = [
    // {
    //   dataField: "no",
    //   text: `${language === "mal" ? "നo." : "No."}`,
    // },
    // {
    //   dataField: "custom_id",
    //   text: `${language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID"}`,
    // },
    {
      dataField: "mahall_custom_id",
      text: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
    },
    {
      dataField: "house_name",
      text: `${language === "mal" ? "വീട്ടുപേര്" : "House Name"}`,
      sort: true,
    },
    {
      dataField: "family_head_name",
      text: `${language === "mal" ? "കുടുംബനാഥൻ" : "Family Head"}`,
    },
    {
      dataField: "houseNumber",
      text: `${language === "mal" ? "വീട് നമ്പർ" : "House No."}`,
    },
    {
      dataField: "no_of_members",
      text: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members"}`,
    },
    {
      dataField: "varisankya_amount",
      text: `${language === "mal" ? "വരിസംഗ്യ തുക" : "Varisankya Amount"}`,
    },
    {
      dataField: "varisankya_wallet",
      text: `${language === "mal" ? "ബാലൻസ്" : "Balance"}`,
    },
    // {
    //   dataField: "contact_number",
    //   text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    // },
    {
      dataField: "action",
      text: `${language === "mal" ? "" : ""}`,
    },
  ]

  const familiesData = map(families?.results, (item, key) => ({
    ...item,
    key: key,
    no: (pageSend() - 1) * pageSize + key + 1,
    houseNumber:
      `${item?.ward_number === null ? "" : item?.ward_number}` +
      (item?.house_number || item?.ward_number ? " / " : "") +
      `${item?.house_number === null ? "" : item?.house_number}`,
    area_name:
      language === "mal"
        ? item?.area?.malayalam
          ? item?.area?.malayalam
          : item?.area?.english
        : item?.area?.english,
    varisankya_wallet: (
      <p
        className={
          item?.varisankya_wallet == 0
            ? "text-primary"
            : item?.varisankya_wallet > 0
            ? "text-success"
            : "text-danger"
        }
      >
        {item?.varisankya_wallet}
      </p>
    ),
    // action: (
    //   <div>
    //     <Link
    //       to={`/family/wallet/details/${item?.id}`}
    //       className=" btn-success w-xs btn-sm"
    //     >
    //       {language === "mal" ? "കാണുക" : "View Wallet"}
    //     </Link>
    //   </div>
    // ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMahallFamilySearch = debounce(
    value => setSearchText(value),
    600
  )
  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  const years = back => {
    const year = new Date().getFullYear()
    return Array.from({ length: back }, (v, i) => year - back + i + 1)
  }

  //multi-list
  const [areas, setAreas] = useState([])
  const [selectedMultiArea, setSelectedMultiArea] = useState(null)
  const [rationCards, setRationCards] = useState([])
  const [selectedMultiRationCard, setSelectedMultiRationCard] = useState(null)
  const [houseTypes, setHouseTypes] = useState([])
  const [selectedMultiHouseType, setSelectedMultiHouseType] = useState(null)
  const [houseBuildTypes, setHouseBuildTypes] = useState([])
  const [selectedMultiHouseBuildType, setSelectedMultiHouseBuildType] =
    useState(null)
  const [waterFacilities, setWaterFacilities] = useState([])
  const [selectedMultiWaterFacility, setSelectedMultiWaterFacility] =
    useState(null)
  const [electricFacilities, setElectricFacilities] = useState([])
  const [selectedMultiElectricFacility, setSelectedMultiElectricFacility] =
    useState(null)
  const [financialStatuses, setFinancialStatuses] = useState([])
  const [selectedMultiFinancialStatus, setSelectedMultiFinancialStatus] =
    useState(null)
  const [annualIncomes, setAnnualIncomes] = useState([])
  const [selectedMultiAnnualIncome, setSelectedMultiAnnualIncome] =
    useState(null)
  const [bankLoans, setBankLoans] = useState([])
  const [selectedMultiBankLoan, setSelectedMultiBankLoan] = useState(null)
  const [homeBusinesses, setHomeBusinesses] = useState([])
  const [selectedMultiHomeBusiness, setSelectedMultiHomeBusiness] =
    useState(null)

  const [wardNumbers, setWardNumbers] = useState([])
  const [selectedMultiWardNumber, setSelectedMultiWardNumber] = useState(null)

  const [varisangyaGrades, setVarisangyaGrades] = useState([])
  const [selectedMultiVarisangyaGrade, setSelectedMultiVarisangyaGrade] =
    useState(null)

  const optionGroupType = type => {
    const optionGroup = mahallTypeLists?.[`${type}`]?.map(item => {
      return {
        value: item.id,
        label: `${language === "mal" ? item?.malayalam : item?.english}`,
      }
    })
    return optionGroup
  }

  function handleMultiType(typeData, setSelectedMultiType, setType) {
    setSelectedMultiType(typeData)
    const arrangedTypes = typeData.map(a => a.value)
    setType(arrangedTypes)
  }

  // eg: mahallTypeListFilterItem(area,"ഏരിയ","Area",selectedMultiArea,setSelectedMultiArea,setAreas)
  const mahallTypeListFilterItem = (
    type,
    mal,
    en,
    selectedMultiType,
    setSelectedMultiType,
    setTypes
  ) => {
    return (
      mahallTypeLists?.[`${type}`] &&
      mahallTypeLists?.[`${type}`]?.length > 0 && (
        <Col md="3" className="mb-3">
          <Label>{language === "mal" ? mal : en}</Label>
          <div className="mb-3">
            <Select
              value={selectedMultiType}
              isMulti={true}
              onChange={e => {
                handleMultiType(e, setSelectedMultiType, setTypes)
              }}
              options={optionGroupType(type)}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
      )
    )
  }

  //ward_number
  const optionGroupWardNumber = () => {
    const optionGroup = mahallWardNumbers?.map(item => {
      return {
        value: item,
        label: item,
      }
    })
    return optionGroup
  }

  function handleMultiWardNumber(e) {
    setSelectedMultiWardNumber(e)
    const arrangedWardNumbers = e.map(a => a.value)
    setWardNumbers(arrangedWardNumbers)
  }

  const handleFilterFind = values => {
    const filterList = []

    const filterItems = [
      {
        field: "area_choice_id",
        value: areas,
      },
      {
        field: "ration_card_category_choice_id",
        value: rationCards,
      },
      {
        field: "house_type_choice_id",
        value: houseTypes,
      },
      {
        field: "house_build_type_choice_id",
        value: houseBuildTypes,
      },
      {
        field: "water_facility_choice_id",
        value: waterFacilities,
      },
      {
        field: "electric_facility_choice_id",
        value: electricFacilities,
      },
      {
        field: "financial_status_choice_id",
        value: financialStatuses,
      },
      {
        field: "annual_income_choice_id",
        value: annualIncomes,
      },
      {
        field: "bank_loan_choice_id_array",
        value: bankLoans,
      },
      {
        field: "home_business_choice_id_array",
        value: homeBusinesses,
      },
      {
        field: "ward_number",
        value: wardNumbers,
      },
      {
        field: "family_varisankya_grade_choice_id",
        value: varisangyaGrades,
      },
    ]

    for (const filterItem of filterItems) {
      if (filterItem.value.length > 0) {
        filterList.push(filterItem)
      }
    }

    if (
      values.total_land_area &&
      values.total_land_area !== "none" &&
      values.total_land_area !== "0"
    )
      filterList.push({
        field: "total_land_area",
        value: [parseInt(values.total_land_area)],
      })
    if (
      values.total_vehicles &&
      values.total_vehicles !== "none" &&
      values.total_vehicles !== "0"
    )
      filterList.push({
        field: "total_vehicles",
        value: [parseInt(values.total_vehicles)],
      })
    if (
      values.help_receiving_from_mahall &&
      values.help_receiving_from_mahall !== "none" &&
      values.help_receiving_from_mahall !== null
    )
      filterList.push({
        field: "help_receiving_from_mahall",
        value: [
          values.help_receiving_from_mahall === "true"
            ? true
            : values.help_receiving_from_mahall === "false"
            ? false
            : null,
        ],
      })
    if (
      values.help_receiving_from_others &&
      values.help_receiving_from_others !== "none" &&
      values.help_receiving_from_others !== null
    )
      filterList.push({
        field: "help_receiving_from_others",
        value: [
          values.help_receiving_from_others === "true"
            ? true
            : values.help_receiving_from_others === "false"
            ? false
            : null,
        ],
      })
    if (
      values.year_of_residence_from &&
      values.year_of_residence_to &&
      values.year_of_residence_from !== "none" &&
      values.year_of_residence_from !== null &&
      values.year_of_residence_to !== "none" &&
      values.year_of_residence_to !== null
    )
      filterList.push({
        field: "year_of_residence",
        value: {
          from: values.year_of_residence_from,
          to: values.year_of_residence_to,
        },
      })

    setFilterListArea(filterList)
    setModalVarisangyaItemCreateData(filterList)
  }

  return (
    <React.Fragment>
      <UiModalVarisangyaItemCreate
        show={modalVarisangyaItemCreate}
        onCloseclick={() => setModalVarisangyaItemCreate(false)}
        history={history}
        data={modalVarisangyaItemCreateData}
      />
      <MetaTags>
        <title>Varisangya Item | Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal"
              ? "കുടുംബ വരിസംഖ്യ ഇനം"
              : "Family Varisangya Item"
          }
          breadcrumbItem={language === "mal" ? "ചേർക്കുക" : "Create"}
        />
        {/* <Row>
          <Col xs="12">
            <div
              className="page-title-box d-sm-flex align-items-center justify-content-end"
              style={{ marginBottom: "10px" }}
            >
              <div className="page-title-right">
                <ol className="breadcrumb m-0 d-flex justify-content-end mt-4">
                </ol>
              </div>
            </div>
          </Col>
        </Row> */}
        <Container fluid>
          <div className="container-fluid">
            <Row>
              {/* {familiesCardsLoading ? (
          <Spinner color="secondary" className="d-block m-auto" />
        ) : (
          <>
            {!isFilterListOpen && cardData?.map((item, key) => (
              <Col md={4} lg={4} key={key}>
                <Card className="blog-stats-wid">
                  <CardBody>
                    <Link to={item.routeLink}>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">{item.title}</p>
                          <h5 className="mb-0">{item.cardValue && item.cardValue}</h5>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                            <i className={"bx bxs-" + `${item.iconClass}`}></i>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </>
        )} */}
              {/* {!isFilterListOpen && <Row>
          <div className="col-md-6 mb-2">
            <Link
              to={`/family/create`}
              type="submit"
              style={{ width: language === "mal" ? "200px" : "150px" }}
              className="w-md btn btn-success d-block"
            >
              {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New Family"}
            </Link>
          </div>
        </Row>
        } */}
              <Col className="col-12">
                <ToolkitProvider
                  keyField={"key"}
                  key={"key"}
                  columns={columns}
                  data={familiesData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Card>
                        <CardBody>
                          <Row className="mb-0">
                            <Col md="2">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e =>
                                      debounceMahallFamilySearch(e.target.value)
                                    }
                                  >
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder={
                                          language === "mal"
                                            ? "തിരയുക..."
                                            : "Search..."
                                        }
                                        defaultValue={searchText}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                className="d-flex mt-2 mb-2"
                                style={{ alignItems: "center" }}
                              >
                                <p>
                                  {language === "mal" ? "ക്രമം" : "Sort by"}
                                </p>
                                <div className="mx-2 mb-3">
                                  <select
                                    value={sort}
                                    onChange={e => {
                                      setSort(e.target.value)
                                      useSetSession(
                                        "sort_value_family",
                                        e.target.value
                                      )
                                    }}
                                    style={{
                                      height: "30px",
                                      padding: "0 5px",
                                      borderColor: "#d0d6db",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    {/* <option>
                                {language === "mal"
                                  ? "തിരഞ്ഞെടുക്കുക ..."
                                  : "Choose..."}{" "}
                              </option> */}
                                    {sortData.map((item, key) => (
                                      <option key={key} value={item.id}>
                                        {item.heading}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                className="d-flex mt-2 mb-2"
                                style={{ alignItems: "center", width: "100px" }}
                              >
                                <p>{language === "mal" ? "ക്രമം" : "Show"}</p>
                                <div className="mx-2">
                                  <div className="mb-3">
                                    <Input
                                      value={pageSize}
                                      onChange={e => {
                                        if (
                                          e.target.value <= 0 ||
                                          e.target.value > 100
                                        ) {
                                          // setPageSize(10)
                                        } else {
                                          setPageSize(e.target.value)
                                          useSetSession(
                                            "pageSize_value",
                                            e.target.value
                                          )
                                        }
                                      }}
                                      style={{
                                        height: "30px",
                                        padding: "0 5px",
                                        borderColor: "#d0d6db",
                                        borderRadius: "3px",
                                      }}
                                    ></Input>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col
                              md="4"
                              className="d-flex align-items-center justify-content-end"
                            >
                              <div>
                                {userDetail?.mahall?.version === "basic" ? (
                                  <Link
                                    style={{
                                      width:
                                        language === "mal" ? "135px" : "50px",
                                    }}
                                    className="w-md btn btn-primary m-auto me-0 mt-0 d-block"
                                    to={handlePremiumLink()}
                                  >
                                    <i className="bx bx-list-ol font-size-16 align-middle"></i>{" "}
                                    {language === "mal" ? "ഫിൽറ്റർ" : "Filter"}
                                    <i
                                      className="bx bx-crown mx-1"
                                      style={{
                                        fontSize: "12px",
                                        color: "gold",
                                      }}
                                    />
                                  </Link>
                                ) : (
                                  <Button
                                    style={{
                                      width:
                                        language === "mal" ? "135px" : "50px",
                                    }}
                                    className="w-md btn btn-info m-auto me-0 mt-0 d-block bg-khaf-blue"
                                    onClick={() =>
                                      setIsFilterListOpen(!isFilterListOpen)
                                    }
                                  >
                                    {!isFilterListOpen ? (
                                      <>
                                        <i className="bx bx-list-ol font-size-16 align-middle"></i>
                                        {language === "mal"
                                          ? "ഫിൽറ്റർ"
                                          : "Filter"}
                                      </>
                                    ) : (
                                      `${
                                        language === "mal"
                                          ? "അടയ്ക്കുക"
                                          : "Close"
                                      }`
                                    )}
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                          {isFilterListOpen ? (
                            <>
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  handleFilterFind(v)
                                }}
                              >
                                <Row>
                                  <hr />
                                  {mahallTypeListFilterItem(
                                    "area",
                                    "ഏരിയ",
                                    "Area",
                                    selectedMultiArea,
                                    setSelectedMultiArea,
                                    setAreas
                                  )}
                                  {mahallTypeListFilterItem(
                                    "ration_card",
                                    "റേഷൻ കാർഡ്",
                                    "Ration Card",
                                    selectedMultiRationCard,
                                    setSelectedMultiRationCard,
                                    setRationCards
                                  )}
                                  {mahallTypeListFilterItem(
                                    "house_type",
                                    "വീട്",
                                    "House Type",
                                    selectedMultiHouseType,
                                    setSelectedMultiHouseType,
                                    setHouseTypes
                                  )}
                                  {mahallTypeListFilterItem(
                                    "house_build_type",
                                    "വീടിൻ്റെ സ്ഥിതി",
                                    "Build Type",
                                    selectedMultiHouseBuildType,
                                    setSelectedMultiHouseBuildType,
                                    setHouseBuildTypes
                                  )}
                                  {mahallTypeListFilterItem(
                                    "water_facility",
                                    "ജല സൗകര്യം",
                                    "Water Facility",
                                    selectedMultiWaterFacility,
                                    setSelectedMultiWaterFacility,
                                    setWaterFacilities
                                  )}
                                  {mahallTypeListFilterItem(
                                    "electric_facility",
                                    "വൈത്യുതി",
                                    "Electricity",
                                    selectedMultiElectricFacility,
                                    setSelectedMultiElectricFacility,
                                    setElectricFacilities
                                  )}
                                  {mahallTypeListFilterItem(
                                    "financial_status",
                                    "സാമ്പത്തിക സ്ഥിതി",
                                    "Financial Status",
                                    selectedMultiFinancialStatus,
                                    setSelectedMultiFinancialStatus,
                                    setFinancialStatuses
                                  )}
                                  {mahallTypeListFilterItem(
                                    "annual_income",
                                    "വാർഷിക വരുമാനം",
                                    "Annual Income",
                                    selectedMultiAnnualIncome,
                                    setSelectedMultiAnnualIncome,
                                    setAnnualIncomes
                                  )}
                                  {mahallTypeListFilterItem(
                                    "bank_loan",
                                    "ബാങ്ക് വായ്പ",
                                    "Bank Loan",
                                    selectedMultiBankLoan,
                                    setSelectedMultiBankLoan,
                                    setBankLoans
                                  )}
                                  {mahallTypeListFilterItem(
                                    "home_business",
                                    "ഹോം ബിസിനസ്സ്",
                                    "Home Business",
                                    selectedMultiHomeBusiness,
                                    setSelectedMultiHomeBusiness,
                                    setHomeBusinesses
                                  )}
                                  {mahallTypeListFilterItem(
                                    "varisankya_grade",
                                    "വരിസംഖ്യ ഗ്രേഡ്",
                                    "Varisangya Grade",
                                    selectedMultiVarisangyaGrade,
                                    setSelectedMultiVarisangyaGrade,
                                    setVarisangyaGrades
                                  )}

                                  {/* Ward No. */}
                                  {mahallWardNumbers &&
                                    mahallWardNumbers.length > 0 && (
                                      <Col md="2" className="mb-3">
                                        <Label>
                                          {language === "mal"
                                            ? "വാർഡ് നമ്പർ"
                                            : "Ward No."}
                                        </Label>
                                        <div className="mb-3">
                                          <Select
                                            value={selectedMultiWardNumber}
                                            isMulti={true}
                                            onChange={e => {
                                              handleMultiWardNumber(e)
                                            }}
                                            options={optionGroupWardNumber()}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                    )}
                                  {/* Help From Mahall */}
                                  {
                                    <Col md="2" className="mb-3">
                                      <Label>
                                        {language === "mal"
                                          ? "മഹല്ലിൽ നിന്നുള്ള സഹായം"
                                          : "Help From Mahall"}
                                      </Label>
                                      <AvField
                                        type="select"
                                        name="help_receiving_from_mahall"
                                      >
                                        <option>{"none"}</option>
                                        {[
                                          {
                                            id: true,
                                            malayalam: "ഉണ്ട്",
                                            english: "Yes",
                                          },
                                          {
                                            id: false,
                                            malayalam: "ഇല്ല",
                                            english: "No",
                                          },
                                        ]?.map((item, key) => (
                                          <option key={key} value={item.id}>
                                            {language === "mal"
                                              ? item?.malayalam
                                              : item?.english}
                                          </option>
                                        ))}
                                      </AvField>
                                    </Col>
                                  }
                                  {/* Help From Others */}
                                  {
                                    <Col md="2" className="mb-3">
                                      <Label>
                                        {language === "mal"
                                          ? "മറ്റുള്ളവരിൽ നിന്നുള്ള സഹായം"
                                          : "Help From Others"}
                                      </Label>
                                      <AvField
                                        type="select"
                                        name="help_receiving_from_others"
                                      >
                                        <option>{"none"}</option>
                                        {[
                                          {
                                            id: true,
                                            malayalam: "ഉണ്ട്",
                                            english: "Yes",
                                          },
                                          {
                                            id: false,
                                            malayalam: "ഇല്ല",
                                            english: "No",
                                          },
                                        ]?.map((item, key) => (
                                          <option key={key} value={item.id}>
                                            {language === "mal"
                                              ? item?.malayalam
                                              : item?.english}
                                          </option>
                                        ))}
                                      </AvField>
                                    </Col>
                                  }
                                  {/* Year Of Residance */}
                                  {
                                    <Col md="2" className="mb-3">
                                      <Label>
                                        {language === "mal"
                                          ? "താമസം തുടങ്ങിയ വർഷം"
                                          : "Year Of Residance"}
                                      </Label>
                                      <Col className=" d-flex justify-content-space-between">
                                        <div className="col-md-6">
                                          <AvField
                                            type="select"
                                            name="year_of_residence_from"
                                            helpMessage={
                                              language === "mal"
                                                ? "മുതൽ"
                                                : "From year"
                                            }
                                          >
                                            <option>{"none"}</option>
                                            {years(100)?.map((item, key) => (
                                              <option key={key} value={item}>
                                                {item}
                                              </option>
                                            ))}
                                          </AvField>
                                        </div>

                                        <div className="col-md-6 mx-1">
                                          <AvField
                                            type="select"
                                            name="year_of_residence_to"
                                            helpMessage={
                                              language === "mal"
                                                ? "വരെ"
                                                : "To year"
                                            }
                                          >
                                            <option>{"none"}</option>
                                            {years(100)?.map((item, key) => (
                                              <option key={key} value={item}>
                                                {item}
                                              </option>
                                            ))}
                                          </AvField>
                                        </div>
                                      </Col>
                                    </Col>
                                  }
                                  {/* Land Area */}
                                  {
                                    <Col md="2" className="mb-3">
                                      <Label>
                                        {language === "mal"
                                          ? "ആകെ ആസ്തി"
                                          : "Total Land Area"}
                                      </Label>
                                      <AvField
                                        type="number"
                                        name="total_land_area"
                                        helpMessage={
                                          language === "mal"
                                            ? "ഇതിലും കുറവോ തുല്യമോ"
                                            : "Less than or equal to"
                                        }
                                      ></AvField>
                                    </Col>
                                  }
                                  {/* Total Vehicles */}
                                  {
                                    <Col md="2" className="mb-3">
                                      <Label>
                                        {language === "mal"
                                          ? "ആകെ വാഹനങ്ങൾ"
                                          : "Total Vehicles"}
                                      </Label>
                                      <AvField
                                        type="number"
                                        name="total_vehicles"
                                        helpMessage={
                                          language === "mal"
                                            ? "ഇതിലും കുറവോ തുല്യമോ"
                                            : "Less than or equal to"
                                        }
                                      ></AvField>
                                    </Col>
                                  }
                                </Row>
                                <Col
                                  md="12"
                                  className="mb-3 d-flex align-items-end justify-content-end"
                                >
                                  <Button
                                    onClick={() =>
                                      setModalVarisangyaItemCreate(true)
                                    }
                                    type="submit"
                                    color="info"
                                    className="me-2"
                                  >
                                    {language === "mal"
                                      ? "കുടുംബ വരിസംഖ്യ ഇനം ചേർക്കുക"
                                      : "Create Family Varisangya Item"}
                                  </Button>
                                  <Button
                                    type="submit"
                                    className="w-xl btn btn-success"
                                  >
                                    {language === "mal" ? "ഫിൽറ്റർ" : "Find"}
                                  </Button>
                                </Col>
                              </AvForm>
                            </>
                          ) : (
                            <></>
                          )}
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          {loading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : (
                            <>
                              <Row>
                                {/* <Col
                            md="12"
                            className="mb-3 d-flex align-items-end justify-content-end"
                          >
                            <Button
                              onClick={handleFilterFindModal}
                              type="button"
                              color="info"
                              className="me-3"
                            >
                              {language === 'mal'
                                ? 'വരിസംഗ്യ തുക അപ്ഡേറ്റ് ചെയ്യുക'
                                : 'Bulk Update Varisangya Amount'}
                            </Button>
                          </Col> */}
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="key"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table align-middle table-wrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            </>
                          )}
                          {families &&
                            families?.results &&
                            families?.results?.length <= 0 && (
                              <p
                                style={{ fontSize: "15px" }}
                                className="text-center text-info"
                              >
                                {language === "mal"
                                  ? "കുടുംബങ്ങൾ ഒന്നും ഇല്ല"
                                  : "No Families Yet"}
                              </p>
                            )}
                        </CardBody>
                      </Card>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FamilyVarisangyaItemFilterList
