import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_DISTRICTS,
  GET_DISTRICT_DETAIL,
  CREATE_DISTRICT,
  UPDATE_DISTRICT,
  DELETE_DISTRICT,
  GET_DISTRICT_DROPDOWN,
} from "./actionTypes"
import {
  getDistrictsSuccess,
  getDistrictsFail,
  getDistrictDropdownSuccess,
  getDistrictDropdownFail,
  getDistrictDetailSuccess,
  getDistrictDetailFail,
  createDistrictSuccess,
  createDistrictFail,
  updateDistrictSuccess,
  updateDistrictFail,
  deleteDistrictSuccess,
  deleteDistrictFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getDistrictsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/district/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/mahall/district/?page=${page ? page : 1}`)
  }
}
const getDistrictDropdownAPi = searchText => {
  return get(
    `/api/v1/mahall/district_dropdown?search=${searchText && searchText}`
  )
}
const getDistrictDetailsAPi = districtId => {
  return get(`/api/v1/mahall/district/${districtId}/`)
}
const createDistrictApi = ({ district }) => {
  return post("/api/v1/mahall/district/", district)
}
const updateDistrictApi = ({ districtId, district }) => {
  return ApiPut(`/api/v1/mahall/district/${districtId}/`, district)
}
const deleteDistrictApi = ({ districtId }) => {
  return del(`/api/v1/mahall/district/${districtId}/`)
}

function* fetchDistricts({ payload }) {
  try {
    const response = yield call(getDistrictsAPi, payload)
    if (response && !response?.error) {
      yield put(getDistrictsSuccess(response))
    } else {
      yield put(getDistrictsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getDistrictsFail(error))
  }
}

function* fetchDistrictDropdown({ payload }) {
  try {
    const response = yield call(getDistrictDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getDistrictDropdownSuccess(response))
    } else {
      yield put(getDistrictDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getDistrictDropdownFail(error))
  }
}

function* fetchDistrictDetail({ districtId }) {
  try {
    const response = yield call(getDistrictDetailsAPi, districtId)
    if (response && !response?.error) {
      yield put(getDistrictDetailSuccess(response))
    } else {
      yield put(getDistrictDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getDistrictDetailFail(error))
  }
}
function* onCreateDistrict({ payload }) {
  try {
    const response = yield call(createDistrictApi, payload)
    if (response && !response?.error) {
      yield put(createDistrictSuccess(response))
      payload.history.push("/districts")
      doneNotification("Created Successfully")
    } else {
      yield put(createDistrictFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createDistrictFail(error))
    errorNotification()
  }
}

function* onUpdateDistrict({ payload }) {
  try {
    const response = yield call(updateDistrictApi, payload)
    if (response && !response?.error) {
      payload.history.push("/districts")
      yield put(updateDistrictSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateDistrictFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateDistrictFail(error))
    errorNotification()
  }
}

function* onDeleteDistrict({ payload }) {
  try {
    const response = yield call(deleteDistrictApi, payload)
    if (response && !response?.error) {
      // payload.history.push("/districts")
      yield put(deleteDistrictSuccess(response))
      doneNotification("Deleted Successfully")
      //get districts
      try {
        const response = yield call(getDistrictsAPi, payload)
        yield put(getDistrictsSuccess(response))
      } catch (error) {
        yield put(getDistrictsFail(error))
      }
    } else {
      yield put(deleteDistrictFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteDistrictFail(error))
    console.log("error :", error)
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* DistrictsSaga() {
  yield takeEvery(GET_DISTRICTS, fetchDistricts)
  yield takeEvery(GET_DISTRICT_DETAIL, fetchDistrictDetail)
  yield takeEvery(GET_DISTRICT_DROPDOWN, fetchDistrictDropdown)
  yield takeEvery(CREATE_DISTRICT, onCreateDistrict)
  yield takeEvery(UPDATE_DISTRICT, onUpdateDistrict)
  yield takeEvery(DELETE_DISTRICT, onDeleteDistrict)
}

export default DistrictsSaga
