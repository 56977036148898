import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_STUDENT_VARISANGYA_WALLETS,
  CREATE_STUDENT_VARISANGYA_WALLET,
  GET_STUDENT_VARISANGYA_WALLET_DETAIL,
  GET_STUDENT_VARISANGYA_WALLET_VIEW,
} from "./actionTypes"
import {
  getStudentVarisangyaWalletsSuccess,
  getStudentVarisangyaWalletsFail,
  createStudentVarisangyaWalletSuccess,
  createStudentVarisangyaWalletFail,
  getStudentVarisangyaWalletDetailSuccess,
  getStudentVarisangyaWalletDetailFail,
  getStudentVarisangyaWalletViewSuccess,
  getStudentVarisangyaWalletViewFail,
} from "./actions"

import { get, post } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getStudentVarisangyaWalletsAPi = ({ searchText, page, sort }) => {
  if (sort === "manual") {
    return get(
      `/api/v1/madrasa-varisankya/madrasa-student-varisankya-wallet-transaction/?search=${
        searchText && searchText
      }&page=${page ? page : 1}&is_manual=True`
    )
  } else {
    return get(
      `/api/v1/madrasa-varisankya/madrasa-student-varisankya-wallet-transaction/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  }
}
const createStudentVarisangyaWalletApi = ({ studentVarisangyaWallet }) => {
  return post(
    "/api/v1/madrasa-varisankya/madrasa-student-varisankya-wallet-transaction/",
    studentVarisangyaWallet
  )
}
const getStudentVarisangyaWalletDetailAPi = ({
  studentId,
  page,
  searchText,
  sort,
}) => {
  if (sort === "manual") {
    return get(
      `/api/v1/madrasa-varisankya/madrasa-student-varisankya-wallet-transaction/?madrasa_student=${
        studentId && studentId
      }&search=${searchText && searchText}&page=${
        page ? page : 1
      }&is_manual=True`
    )
  } else {
    return get(
      `/api/v1/madrasa-varisankya/madrasa-student-varisankya-wallet-transaction/?madrasa_student=${
        studentId && studentId
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  }
}

const getStudentVarisangyaWalletViewAPi = studentVarisangyaWalletId => {
  return get(
    `/api/v1/madrasa-varisankya/madrasa-student-varisankya-wallet-transaction/${studentVarisangyaWalletId}/`
  )
}

function* fetchStudentVarisangyaWallets({ payload }) {
  try {
    const response = yield call(getStudentVarisangyaWalletsAPi, payload)
    if (response && !response?.error) {
      yield put(getStudentVarisangyaWalletsSuccess(response))
    } else {
      yield put(getStudentVarisangyaWalletsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentVarisangyaWalletsFail(error))
  }
}

function* onCreateStudentVarisangyaWallet({ payload }) {
  try {
    const response = yield call(createStudentVarisangyaWalletApi, payload)
    if (response && !response?.error) {
      yield put(createStudentVarisangyaWalletSuccess(response))
      doneNotification("Created Successfully")
    } else {
      yield put(createStudentVarisangyaWalletFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createStudentVarisangyaWalletFail(error))
    errorNotification()
  }
}

function* fetchStudentVarisangyaWalletDetail({ payload }) {
  try {
    const response = yield call(getStudentVarisangyaWalletDetailAPi, payload)
    if (response && !response?.error) {
      yield put(getStudentVarisangyaWalletDetailSuccess(response))
    } else {
      yield put(getStudentVarisangyaWalletDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentVarisangyaWalletDetailFail(error))
  }
}

function* fetchStudentVarisangyaWalletView({ studentVarisangyaWalletId }) {
  try {
    const response = yield call(
      getStudentVarisangyaWalletViewAPi,
      studentVarisangyaWalletId
    )
    if (response && !response?.error) {
      yield put(getStudentVarisangyaWalletViewSuccess(response))
    } else {
      yield put(getStudentVarisangyaWalletViewFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStudentVarisangyaWalletViewFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* StudentVarisangyaWalletsSaga() {
  yield takeEvery(GET_STUDENT_VARISANGYA_WALLETS, fetchStudentVarisangyaWallets)
  yield takeEvery(
    CREATE_STUDENT_VARISANGYA_WALLET,
    onCreateStudentVarisangyaWallet
  )
  yield takeEvery(
    GET_STUDENT_VARISANGYA_WALLET_DETAIL,
    fetchStudentVarisangyaWalletDetail
  )
  yield takeEvery(
    GET_STUDENT_VARISANGYA_WALLET_VIEW,
    fetchStudentVarisangyaWalletView
  )
}

export default StudentVarisangyaWalletsSaga
