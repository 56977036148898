import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import axios from "axios"
//actions
import { Image } from "react-bootstrap"

import { API_URL } from "helpers/api_methods"
import { createBannerSuccess, createBannerFail } from "store/actions"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import { useFileSizes } from "hooks/useFileSize"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const CreateBanner = ({ history }) => {
  const dispatch = useDispatch()
  const handleFileSizes = useFileSizes

  const { loading, language, mahallId } = useSelector(state => ({
    loading: state.Banners.loading,
    error: state.Mahalls.error,
    language: state.Layout.language,
    mahallId: state.Users.userDetail?.mahall?.id,
  }))

  const [customLoadAppImage, setCustomLoadAppImage] = useState(false)
  const [selectedFileAppImage, setSelectedFileAppImage] = useState({
    image: null,
  })
  console.log(customLoadAppImage)
  const [imagePreviewAppImage, setImagePreviewAppImage] = useState()

  const [filesSizeAppImage, setFilesSizeAppImage] = useState()

  const changeHandlerAppImage = e => {
    const filesizeAppImage = e.target.files[0].size
    setFilesSizeAppImage(filesizeAppImage)

    setSelectedFileAppImage({
      ...selectedFileAppImage,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreviewAppImage({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  // const [customLoadWebImage, setCustomLoadWebImage] = useState(false)
  const [selectedFileWebImage, setSelectedFileWebImage] = useState({
    image: null,
  })
  console.log(setSelectedFileWebImage)
  const [imagePreviewWebImage, setImagePreviewWebImage] = useState()
  console.log(imagePreviewWebImage)
  // const [filesSizeWebImage, setFilesSizeWebImage] = useState()
  // const changeHandlerWebImage = e => {
  //   const filesizeWebImage = e.target.files[0].size
  //   setFilesSizeWebImage(filesizeWebImage)

  //   setSelectedFileWebImage({
  //     ...selectedFileWebImage,
  //     image: e.target.files[0],
  //   })

  //   if (e.target.files && e.target.files[0]) {
  //     let reader = new FileReader()
  //     reader.onload = e => {
  //       setImagePreviewWebImage({ image: e.target.result })
  //     }
  //     reader.readAsDataURL(e.target.files[0])
  //   }
  // }

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    createNewBanner(
      selectedFileAppImage,
      selectedFileWebImage,
      values,
      mahallId,
      dispatch,
      history,
      setCustomLoadAppImage
      // setCustomLoadWebImage,
    )
    setImagePreviewAppImage()
    setImagePreviewWebImage()
  }

  return (
    <>
      <MetaTags>
        <title>Banners | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ബാനറുകൾ" : "Banners"}
          breadcrumbItem={
            language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create Banner"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="row mb-3">
                        <Col md="6">
                          <Label>
                            {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                          </Label>
                          <AvField name="title" type="text" />
                        </Col>
                        <Col md="6" className="banner-field">
                          <Label>
                            {language === "mal" ? "മുൻഗണന" : "Priority"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="priority"
                            type="number"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col md="12" className="mb-3">
                          <AvField
                            name="url"
                            type="text"
                            label={language === "mal" ? "യു ആർ എൽ" : "URL"}
                          />
                        </Col>
                      </div>

                      {/* <div className="row mb-4"> */}
                      <Col md="12">
                        <Label>
                          {language === "mal"
                            ? "മൊബൈൽ ആപ്പിലേക്കുള്ള ഫോട്ടോ"
                            : "App Image"}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <AvField
                          name="image_app"
                          type="file"
                          onChange={changeHandlerAppImage}
                          validate={{
                            required: { value: true },
                          }}
                        />
                        {handleFileSizes(filesSizeAppImage)?.text && (
                          <p className="text-danger mt-1">
                            {handleFileSizes(filesSizeAppImage)?.text}
                          </p>
                        )}
                        {imagePreviewAppImage && (
                          <Image
                            style={{ width: "50%", height: "50%" }}
                            className="my-2"
                            src={imagePreviewAppImage.image}
                          />
                        )}
                      </Col>

                      {/* <Col md="12" className="banner-field mt-2">
                        <Label> {language === "mal" ? "വെബ്ബിലേക്കുള്ള ഫോട്ടോ" : "Web Image"} </Label>
                        <AvField
                          name="image_web"
                          type="file"
                          onChange={changeHandlerWebImage}
                        />
                        {handleFileSizes(filesSizeWebImage)?.text && (
                          <p className="text-danger mt-1">
                            {handleFileSizes(filesSizeWebImage)?.text}
                          </p>
                        )}
                        {
                          imagePreviewWebImage && <Image style={{ width: "50%", height: "50%" }} className="my-2" src={imagePreviewWebImage.image} />
                        }
                      </Col> */}

                      {/* </div> */}

                      <div className="d-flex justify-content-end">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateBanner

CreateBanner.propTypes = {
  history: PropTypes.object,
}

function createNewBanner(
  selectedFileAppImage,
  selectedFileWebImage,
  values,
  mahallId,
  dispatch,
  history
) {
  const formData = new FormData()
  {
    selectedFileAppImage &&
      selectedFileAppImage?.image &&
      formData.append(
        "image_app",
        selectedFileAppImage?.image,
        selectedFileAppImage?.image?.name
      )
  }
  // {
  //   selectedFileWebImage &&
  //     selectedFileWebImage?.image &&
  //     formData.append("image_web", selectedFileWebImage?.image, selectedFileWebImage?.image?.name)
  // }
  formData.append("mahall", mahallId)
  formData.append("title", values.title)
  formData.append("url", values.url)
  formData.append("priority", values.priority)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .post(`${API_URL}/api/v1/banner/banner/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        dispatch(createBannerSuccess(res.data))
        history.push("/banners")
        doneNotification("Created Successfully")
      } else {
        // createBannerFail(err)
        errorNotification()
      }
    })
    .catch(err => {
      createBannerFail(err)
      errorNotification()
      // if (err?.response?.data?.error[0]) {
      //   console.log(err?.response?.data?.error[0]);
      //   createMahallFail(err)
      //   errorNotification()
      //   setCustomLoadAppImage(false)
      // }
    })
}
