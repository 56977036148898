import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu, Badge } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { withRouter, Link } from "react-router-dom"

//images
import user1 from "assets/images/logo/user.png"
import adminImage from "assets/images/khaf/icon1.png"
import groupAdminImage from "assets/images/khaf/icon3.png"
import mahallUserImage from "assets/images/khaf/icon2.png"
import surveyUserImage from "assets/images/khaf/icon5.png"
import instituteUserImage from "assets/images/khaf/icon6.png"
import { getUserDetail, setUserRole } from "store/actions"
import {
  useGetRole,
  useRemoveSession,
  useSetRole,
} from "hooks/useHandleSessions"

const ProfileMenu = props => {
  const dispatch = useDispatch()

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  // const [, setCurrentRole] = useState('')
  const { role } = useGetRole()

  const { userDetail, language } = useSelector(state => ({
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
  }))

  useEffect(() => {
    if (!userDetail) dispatch(getUserDetail())
  }, [dispatch, userDetail])

  const handleLogout = async () => {
    useRemoveSession("token")
    window.location.reload(false)
  }

  const handleProfileSelect = userRole => {
    window.location.reload()
    const roleData = {
      role: userRole,
    }
    dispatch(setUserRole(roleData, history))
    useSetRole(userRole)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={
              role === "admin"
                ? adminImage
                : role === "group_admin"
                ? groupAdminImage
                : role === "mahall_user"
                ? mahallUserImage
                : role === "survey_user"
                ? surveyUserImage
                : role === "institute_user"
                ? instituteUserImage
                : user1
            }
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {userDetail?.full_name}
            <br />
            {/* <p className="font-size-8">{'admin'}</p> */}
            <Badge
              className={"mx-0 m-0 font-size-8 badge-soft-success"}
              color="black"
              pill
            >
              {role === "admin" ? (
                language === "mal" ? (
                  "അഡ്മിൻ"
                ) : (
                  "Admin"
                )
              ) : role === "group_admin" ? (
                language === "mal" ? (
                  "ഗ്രൂപ്പ് അഡ്മിൻ"
                ) : (
                  "Group Admin"
                )
              ) : role === "mahall_user" ? (
                language === "mal" ? (
                  "മഹല്ല് യൂസർ"
                ) : (
                  "Mahall User"
                )
              ) : role === "survey_user" ? (
                language === "mal" ? (
                  "സർവേ യൂസർ"
                ) : (
                  "Survey User"
                )
              ) : role === "institute_user" ? (
                userDetail?.institute?.institute_type !== "madrasa" ? (
                  language === "mal" ? (
                    "സ്ഥാപന യൂസർ"
                  ) : (
                    "Institute User"
                  )
                ) : language === "mal" ? (
                  "മദ്രസ യൂസർ"
                ) : (
                  "Madrasa User"
                )
              ) : (
                <></>
              )}
            </Badge>
          </span>

          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <Link to="/profile">
            <DropdownItem>
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Change Profile")}
            </DropdownItem>
          </Link> */}
          {/* {Roll == "supervisor" && (
            <DropdownItem tag="a" href="/profile?wallet">
              <i className="bx bx-wallet font-size-16 align-middle me-1" />
              {props.t("My Wallet")}
            </DropdownItem>
          )} */}

          <div
            className={
              userDetail?.roles && userDetail?.roles?.length > 1 ? "mb-3" : ""
            }
          >
            {userDetail?.roles && userDetail?.roles?.length > 1 ? (
              <>
                {/* { console.log('userDetail?.roles: ',userDetail?.roles)} */}
                {userDetail?.roles?.map(
                  (roleOption, key) =>
                    roleOption !== "user" && (
                      <div
                        className="d-flex align-items-center justify-content-between mx-3 p-2 mb-2 rounded"
                        key={key}
                      >
                        <div className="d-flex align-items-center">
                          {roleOption === "admin" ? (
                            <>
                              <img
                                width="20"
                                className="rounded"
                                src={adminImage}
                                alt=""
                              />
                              <p className="ms-3 mb-0">
                                {language === "mal" ? "അഡ്മിൻ" : "Admin"}
                              </p>
                            </>
                          ) : roleOption === "group_admin" ? (
                            <>
                              <img
                                width="20"
                                className="rounded"
                                src={groupAdminImage}
                                alt=""
                              />
                              <p className="ms-3 mb-0">
                                {language === "mal"
                                  ? "ഗ്രൂപ്പ് അഡ്മിൻ"
                                  : "Group Admin"}
                              </p>
                            </>
                          ) : roleOption === "mahall_user" ? (
                            <>
                              <img
                                width="20"
                                className="rounded"
                                src={mahallUserImage}
                                alt=""
                              />
                              <p className="ms-3 mb-0">
                                {language === "mal"
                                  ? "മഹല്ല് യൂസർ"
                                  : "Mahall User"}
                              </p>
                            </>
                          ) : roleOption === "survey_user" ? (
                            <>
                              <img
                                width="20"
                                className="rounded"
                                src={surveyUserImage}
                                alt=""
                              />
                              <p className="ms-3 mb-0">
                                {language === "mal"
                                  ? "സർവേ യൂസർ"
                                  : "Survey User"}
                              </p>
                            </>
                          ) : roleOption === "institute_user" ? (
                            <>
                              <img
                                width="20"
                                className="rounded"
                                src={instituteUserImage}
                                alt=""
                              />
                              <p className="ms-3 mb-0">
                                {language === "mal"
                                  ? "സ്ഥാപന യൂസർ"
                                  : "Institute User"}
                              </p>
                            </>
                          ) : (
                            // roleOption === "user" ?
                            //   <>
                            //     <img width="20" className="rounded" src={user1} alt="" />
                            //     <p className="ms-3 mb-0">User</p>
                            //   </>:
                            <></>
                          )}
                        </div>
                        <input
                          type="radio"
                          id="customRadio1"
                          name="customRadio"
                          checked={role === roleOption}
                          onChange={() => {}}
                          className="form-check-input"
                          style={{ width: "15px", height: "15px" }}
                          onClick={() => {
                            handleProfileSelect(roleOption)
                          }}
                        />
                      </div>
                    )
                )}
                <div className="dropdown-divider" />
              </>
            ) : (
              ""
            )}
          </div>

          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
          <span
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout on this device")}</span>
          </span>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  history: PropTypes.object,
}

// const mapStatetoProps = state => {
//   const { error, success } = state.
//   return
// }

export default withRouter(withTranslation()(ProfileMenu))
