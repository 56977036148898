import {
  GET_FAMILY_MEMBER_COLLECTIONS,
  GET_FAMILY_MEMBER_COLLECTIONS_CARDS,
  UPDATE_FAMILY_MEMBER_COLLECTION,
  CREATE_FAMILY_MEMBER_COLLECTION,
  DELETE_FAMILY_MEMBER_COLLECTION,
  GET_FAMILY_MEMBER_COLLECTION_DETAIL,
  GET_FAMILY_MEMBER_COLLECTIONS_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTIONS_FAIL,
  GET_FAMILY_MEMBER_COLLECTIONS_CARDS_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTIONS_CARDS_FAIL,
  CREATE_FAMILY_MEMBER_COLLECTION_SUCCESS,
  CREATE_FAMILY_MEMBER_COLLECTION_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_DETAIL_FAIL,
  UPDATE_FAMILY_MEMBER_COLLECTION_SUCCESS,
  UPDATE_FAMILY_MEMBER_COLLECTION_FAIL,
  DELETE_FAMILY_MEMBER_COLLECTION_SUCCESS,
  DELETE_FAMILY_MEMBER_COLLECTION_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL,
  UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER,
  CREATE_FAMILY_MEMBER_COLLECTION_MEMBER,
  DELETE_FAMILY_MEMBER_COLLECTION_MEMBER,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_SUCCESS,
  CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_SUCCESS,
  UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS,
  DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_FAIL,
  CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_FAIL,
  UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL,
  DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS,
  GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL,
  UPDATE_FAMILY_MEMBER_FILTER_COLLECTION,
  CREATE_FAMILY_MEMBER_FILTER_COLLECTION,
  DELETE_FAMILY_MEMBER_FILTER_COLLECTION,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_SUCCESS,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_SUCCESS,
  CREATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS,
  GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_SUCCESS,
  UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS,
  DELETE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_FAIL,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_FAIL,
  CREATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL,
  GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_FAIL,
  UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL,
  DELETE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL,
} from "./actiontypes"

const INIT_STATE = {
  familyMemberCollections: [],
  familyMemberCollectionsCards: {},
  familyMemberCollectionDetail: {},
  error: {},
  loading: false,
  memberLoading: false,
  detailLoading: false,
  createdFamilyMemberCollection: {},

  familyMemberCollectionMembers: [],
  familyMemberCollectionMembersCards: {},
  familyMemberCollectionMemberDetail: {},
  createdFamilyMemberCollectionMember: {},

  familyMemberFilterCollections: [],
  familyMemberFilterCollectionsCards: {},
  familyMemberFilterCollectionDetail: {},
  createdFamilyMemberFilterCollection: {},
}

const Collections = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FAMILY_MEMBER_COLLECTIONS:
    case GET_FAMILY_MEMBER_COLLECTIONS_CARDS:
    case GET_FAMILY_MEMBER_COLLECTION_DETAIL:
    case UPDATE_FAMILY_MEMBER_COLLECTION:
    case CREATE_FAMILY_MEMBER_COLLECTION:
    case DELETE_FAMILY_MEMBER_COLLECTION:
    case GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS:
    case GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL:
    case UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER:
    case CREATE_FAMILY_MEMBER_COLLECTION_MEMBER:
    case DELETE_FAMILY_MEMBER_COLLECTION_MEMBER:
    case GET_FAMILY_MEMBER_FILTER_COLLECTIONS:
    case GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS:
    case GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL:
    case UPDATE_FAMILY_MEMBER_FILTER_COLLECTION:
    case CREATE_FAMILY_MEMBER_FILTER_COLLECTION:
    case DELETE_FAMILY_MEMBER_FILTER_COLLECTION:
      return {
        ...state,
        loading: true,
      }
    case GET_FAMILY_MEMBER_COLLECTION_MEMBERS:
      return {
        ...state,
        memberLoading: true,
      }

    // ------------------------------------FAMILY MEMBER COLLECTIONS----------------------------------------

    case GET_FAMILY_MEMBER_COLLECTIONS_SUCCESS:
      return {
        ...state,
        familyMemberCollections: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_COLLECTIONS_CARDS_SUCCESS:
      return {
        ...state,
        familyMemberCollectionsCards: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_COLLECTION_SUCCESS:
      return {
        ...state,
        familyMemberCollections: {
          ...state.familyMemberCollections,
          results: [...state.familyMemberCollections.results, action.payload],
        },
        // familyMemberCollections: action.payload,
        createdFamilyMemberCollection: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_COLLECTION_DETAIL_SUCCESS:
      return {
        ...state,
        familyMemberCollectionDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_FAMILY_MEMBER_COLLECTION_SUCCESS:
      return {
        ...state,
        familyMemberCollectionDetail: action.payload,
        familyMemberCollections: {
          results: state.familyMemberCollections.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case DELETE_FAMILY_MEMBER_COLLECTION_SUCCESS:
      return {
        ...state,
        familyMemberCollections: {
          results: state.familyMemberCollections.results.filter(
            familyMemberCollection =>
              familyMemberCollection.id !== action.payload
          ),
        },
        loading: false,
      }

    // ------------------------------------FAMILY MEMBER COLLECTION MEMBERS----------------------------------------

    case GET_FAMILY_MEMBER_COLLECTION_MEMBERS_SUCCESS:
      return {
        ...state,
        familyMemberCollectionMembers: action.payload,
        memberLoading: false,
      }

    case GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_SUCCESS:
      return {
        ...state,
        familyMemberCollectionMembersCards: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS:
      return {
        ...state,
        familyMemberCollectionMembers: {
          ...state.familyMemberCollectionMembers,
          results: [
            ...state.familyMemberCollectionMembers.results,
            action.payload,
          ],
        },
        createdFamilyMemberCollectionMember: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        familyMemberCollectionMemberDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS:
      return {
        ...state,
        familyMemberCollectionMemberDetail: action.payload,
        loading: false,
      }

    case DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_SUCCESS:
      return {
        ...state,
        familyMemberCollectionMembers: {
          ...state.familyMemberCollectionMembers,
          results: state.familyMemberCollectionMembers.results.filter(
            familyMemberCollectionMember =>
              familyMemberCollectionMember.id !== action.payload
          ),
        },
        loading: false,
      }

    // ------------------------------------FAMILY MEMBER FILTER COLLECTION----------------------------------------

    case GET_FAMILY_MEMBER_FILTER_COLLECTIONS_SUCCESS:
      return {
        ...state,
        familyMemberFilterCollections: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_SUCCESS:
      return {
        ...state,
        familyMemberFilterCollectionsCards: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS:
      return {
        ...state,
        familyMemberFilterCollections: action.payload,
        createdFamilyMemberFilterCollection: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_SUCCESS:
      return {
        ...state,
        familyMemberFilterCollectionDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS:
      return {
        ...state,
        familyMemberFilterCollectionDetail: action.payload,
        loading: false,
      }

    case DELETE_FAMILY_MEMBER_FILTER_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case GET_FAMILY_MEMBER_COLLECTIONS_FAIL:
    case GET_FAMILY_MEMBER_COLLECTIONS_CARDS_FAIL:
    case CREATE_FAMILY_MEMBER_COLLECTION_FAIL:
    case GET_FAMILY_MEMBER_COLLECTION_DETAIL_FAIL:
    case UPDATE_FAMILY_MEMBER_COLLECTION_FAIL:
    case DELETE_FAMILY_MEMBER_COLLECTION_FAIL:
    case GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS_FAIL:
    case CREATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL:
    case GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL_FAIL:
    case UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL:
    case DELETE_FAMILY_MEMBER_COLLECTION_MEMBER_FAIL:
    case GET_FAMILY_MEMBER_FILTER_COLLECTIONS_FAIL:
    case GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS_FAIL:
    case CREATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL:
    case GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL_FAIL:
    case UPDATE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL:
    case DELETE_FAMILY_MEMBER_FILTER_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_FAMILY_MEMBER_COLLECTION_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
        memberLoading: false,
      }

    default:
      return state
  }
}

export default Collections
