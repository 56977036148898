import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ALL_MADRASA_LISTS,
  GET_MADRASA_DETAIL,
  CREATE_MADRASA,
  UPDATE_MADRASA,
  DELETE_MADRASA,
  GET_MADRASA_DROPDOWN,
} from "./actionTypes"
import {
  getAllMadrasaListsSuccess,
  getAllMadrasaListsFail,
  getMadrasaDropdownSuccess,
  getMadrasaDropdownFail,
  getMadrasaDetailSuccess,
  getMadrasaDetailFail,
  createMadrasaSuccess,
  createMadrasaFail,
  updateMadrasaSuccess,
  updateMadrasaFail,
  deleteMadrasaSuccess,
  deleteMadrasaFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getAllMadrasaListsAPi = () => {
  return get(`/api/v1/madrasa/madrasa-detail/`)
}

const getMadrasaDropdownAPi = searchText => {
  return get(
    `/api/v1/madrasa/madrasa-detail/lsg_dropdown?search=${
      searchText && searchText
    }`
  )
}
const getMadrasaDetailsAPi = madrasaId => {
  return get(`/api/v1/madrasa/madrasa-detail/${madrasaId}/`)
}
const createMadrasaApi = ({ madrasa }) => {
  return post("/api/v1/madrasa/madrasa-detail/", madrasa)
}
const updateMadrasaApi = ({ madrasaId, madrasa }) => {
  return ApiPut(`/api/v1/madrasa/madrasa-detail/${madrasaId}/`, madrasa)
}
const deleteMadrasaApi = ({ madrasaId }) => {
  return del(`/api/v1/madrasa/madrasa-detail/${madrasaId}/`)
}

function* fetchMadrasaLists({ payload }) {
  try {
    const response = yield call(getAllMadrasaListsAPi, payload)
    if (response && !response?.error) {
      yield put(getAllMadrasaListsSuccess(response))
    } else {
      yield put(getAllMadrasaListsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAllMadrasaListsFail(error))
  }
}

function* fetchMadrasaDropdown({ payload }) {
  try {
    const response = yield call(getMadrasaDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getMadrasaDropdownSuccess(response))
    } else {
      yield put(getMadrasaDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getMadrasaDropdownFail(error))
  }
}

function* fetchMadrasaDetail({ madrasaId }) {
  try {
    const response = yield call(getMadrasaDetailsAPi, madrasaId)
    if (response && !response?.error) {
      yield put(getMadrasaDetailSuccess(response))
    } else {
      yield put(getMadrasaDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getMadrasaDetailFail(error))
  }
}
function* onCreateMadrasa({ payload }) {
  try {
    const response = yield call(createMadrasaApi, payload)
    if (response && !response?.error) {
      yield put(createMadrasaSuccess(response))
      payload.history.push("/madrasas")
      doneNotification("Created Successfully")
      //get lsgs
      // try {
      //   const response = yield call(getAllMadrasaListsAPi, payload)
      //   yield put(getAllMadrasaListsSuccess(response))
      // } catch (error) {
      //   yield put(getAllMadrasaListsFail(error))
      // }
    } else {
      yield put(createMadrasaFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createMadrasaFail(error))
    errorNotification()
  }
}

function* onUpdateMadrasa({ payload }) {
  try {
    const response = yield call(updateMadrasaApi, payload)
    if (response && !response?.error) {
      yield put(updateMadrasaSuccess(response))
      payload.history.push(`/madrasa/${response?.institute}`)
      doneNotification("Updated Successfully")
    } else {
      yield put(updateMadrasaFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateMadrasaFail(error))
    // console.log('error: ', error);
    errorNotification()
  }
}

function* onDeleteMadrasa({ payload }) {
  try {
    const response = yield call(deleteMadrasaApi, payload)
    if (response && !response?.error) {
      yield put(deleteMadrasaSuccess(response))
      doneNotification("Deleted Successfully")
      //get lsgs
      try {
        const response = yield call(getAllMadrasaListsAPi, payload)
        yield put(getAllMadrasaListsSuccess(response))
      } catch (error) {
        yield put(getAllMadrasaListsFail(error))
      }
    } else {
      yield put(deleteMadrasaFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteMadrasaFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* MadrasaListsSaga() {
  yield takeEvery(GET_ALL_MADRASA_LISTS, fetchMadrasaLists)
  yield takeEvery(GET_MADRASA_DETAIL, fetchMadrasaDetail)
  yield takeEvery(GET_MADRASA_DROPDOWN, fetchMadrasaDropdown)
  yield takeEvery(CREATE_MADRASA, onCreateMadrasa)
  yield takeEvery(UPDATE_MADRASA, onUpdateMadrasa)
  yield takeEvery(DELETE_MADRASA, onDeleteMadrasa)
}

export default MadrasaListsSaga
