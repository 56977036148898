import React, { Fragment, useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Table,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useParams, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import moment from "moment"
//actions institute
//componetns
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import UiModalSubject from "./UiModalSubject"
import UiModalSubjectUpdate from "./UiModalSubjectUpdate"
import UiModalTimetableUpdate from "./UiModalTimetableUpdate"
import UiModalStudentAttendance from "./UiModalAttendance"

import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getDivisionDetail,
  getStudentAttendances,
  getStudents,
  getSubjects,
  getTimetableList,
} from "store/actions"

const DivisionDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    studentLoading,
    attendanceLoading,
    language,
    students,
    divisionDetail,
    subjects,
    timetableList,
    attendances,
    timetableLoading,
    subjectLoading,
  } = useSelector(state => ({
    language: state.Layout.language,
    studentLoading: state.Students.loading,
    students: state.Students.students,
    divisionDetail: state.Divisions.divisionDetail,
    subjects: state.Subjects.subjects,
    timetableList: state.Timetable.timetableList,
    attendances: state.StudentAttendances.studentAttendances,
    attendanceLoading: state.StudentAttendances.loading,
    timetableLoading: state.Timetable.loading,
    subjectLoading: state.Subjects.loading,
  }))

  const [searchTextStudent, setSearchTextStudent] = useState("")
  // const [searchTextAttendance, setSearchTextAttendance] = useState('hai')
  const [studentPage, setStudentPage] = useState(1)
  const [attendancePage, setAttendancePage] = useState(1)
  const [modalSubject, setModalSubject] = useState(false)
  const [updateModalSubject, setUpdateModalSubject] = useState(false)
  const [onUpdateSubject, setOnUpdateSubject] = useState({})
  const [updateModalTimetable, setUpdateModalTimetable] = useState(false)
  const [onUpdateTimetable, setOnUpdateTimetable] = useState({})
  const [modalStudentAttendance, setModalStudentAttendance] = useState(false)

  const [date, setDate] = useState({
    from_date: "",
    to_date: "",
  })

  // console.log(date)

  const handleDate = () => {
    if (date?.from_date && date?.to_date) {
      return date
    } else {
      return {
        from_date: "",
        to_date: "",
      }
    }
  }

  const totalStudentPages = Math.ceil(students?.count / 10)
  const studentPages = range(1, totalStudentPages + 1)

  const studentPageSend = () => {
    if (studentPage >= studentPages.length) {
      return studentPages.length
    }
    if (studentPage < 1) {
      return 1
    } else {
      return studentPage
    }
  }

  const debounceStudentSearch = debounce(
    value => setSearchTextStudent(value),
    600
  )

  const studentColumns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "ക്രമ നം." : "Roll No."}`,
      sort: true,
    },
    {
      dataField: "name",
      text: `${language === "mal" ? "പേര്" : "Name"}`,
      sort: true,
    },
    {
      dataField: "dob",
      text: `${language === "mal" ? "ഡി ഒ ബി" : "DOB"}`,
    },
    {
      dataField: "parent_name",
      text: `${language === "mal" ? "രക്ഷിതാവ്" : "Parent"}`,
    },
    {
      dataField: "parent_phone",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const studentsData = map(students?.results, (item, index) => ({
    ...item,
    key: index,
    no: item?.roll_no,
    dob: moment(item?.dob).format("DD/MM/YYYY"),
    action: (
      <div className="m-0">
        <Link to={`/student/${item?.id}`}>
          <Button type="button" color="success" className="btn w-xs btn-sm">
            View
          </Button>
        </Link>
      </div>
    ),
  }))

  const defaultSortedStudent = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const selectRowStudent = {
    mode: "checkbox",
  }

  const totalAttendancePages = Math.ceil(attendances?.count / 10)
  const attendancePages = range(1, totalAttendancePages + 1)

  const attendancePageSend = () => {
    if (attendancePage >= attendancePages.length) {
      return attendancePages.length
    }
    if (attendancePage < 1) {
      return 1
    } else {
      return attendancePage
    }
  }

  // const debounceAttendanceSearch = debounce(
  //   value => setSearchTextAttendance(value),
  //   600,
  // )

  const attendanceColumns = [
    // {
    //   dataField: 'no',
    //   text: `${language === 'mal' ? 'ക്രമ നം.' : 'Roll No.'}`,
    //   sort: true,
    // },
    {
      dataField: "date",
      text: `${language === "mal" ? "തീയതി" : "Date"}`,
      sort: true,
    },
    {
      dataField: "absents",
      text: `${language === "mal" ? "ഹാജരാകാത്തത്" : "Absents"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const attendancesData = map(attendances?.results, (item, index) => ({
    ...item,
    key: index,
    // no: item?.roll_no,
    date: moment(item?.date).format("DD/MM/YYYY"),
    action: (
      <div className="m-0">
        <Link
          to={`/student/attendance/details/${item?.working_day}?divisionid=${divisionDetail?.id}`}
        >
          <Button type="button" color="success" className="btn w-xs btn-sm">
            View
          </Button>
        </Link>
      </div>
    ),
  }))

  const defaultSortedAttendance = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const selectRowAttendance = {
    mode: "checkbox",
  }

  useEffect(() => {
    dispatch(
      getStudents(divisionDetail?.id, "", searchTextStudent, studentPageSend())
    )
  }, [divisionDetail?.id, searchTextStudent, studentPage])

  useEffect(() => {
    dispatch(
      getStudentAttendances(
        handleDate(),
        divisionDetail?.id,
        attendancePageSend()
      )
    )
  }, [date, divisionDetail?.id, attendancePage])

  useEffect(() => {
    dispatch(getSubjects(divisionDetail?.id))
  }, [dispatch, divisionDetail?.id])

  useEffect(() => {
    dispatch(getTimetableList(divisionDetail?.id))
  }, [dispatch, divisionDetail?.id])

  useEffect(() => {
    dispatch(getDivisionDetail(params.id))
  }, [])

  const updateSubjectModal = item => {
    setOnUpdateSubject(item)
    setUpdateModalSubject(true)
  }

  const updateTimetableModal = item => {
    setOnUpdateTimetable(item)
    setUpdateModalTimetable(true)
  }

  const days = Array.from(new Set(timetableList?.map(item => item?.day)))
  const periods = Array.from(
    new Set(timetableList?.map(item => item?.period_no))
  ).sort((a, b) => a - b)

  return (
    <>
      <UiModalSubject
        show={modalSubject}
        onCloseclick={() => setModalSubject(false)}
      />
      <UiModalStudentAttendance
        show={modalStudentAttendance}
        onCloseclick={() => setModalStudentAttendance(false)}
      />
      <UiModalSubjectUpdate
        show={updateModalSubject}
        onCloseclick={() => setUpdateModalSubject(false)}
        data={onUpdateSubject}
      />
      <UiModalTimetableUpdate
        show={updateModalTimetable}
        onCloseclick={() => setUpdateModalTimetable(false)}
        data={onUpdateTimetable}
      />
      <MetaTags>
        <title>Divisions | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഡിവിഷൻ വിശദാംശങ്ങൾ" : "Division Details"}
          breadcrumbItem={language === "mal" ? "ഡിവിഷൻ" : "Division"}
        />
        <Container fluid>
          <div className="container-fluid">
            <>
              <Row>
                <Col className="col-lg-12 col-md-12">
                  <Row>
                    <Col md="4">
                      <Card className="overflow-hidden">
                        <div className="bg-primary bg-soft">
                          <Row>
                            <div className="text-primary p-4">
                              <div className="px-2">
                                <Row>
                                  <Col md="12">
                                    <div className="d-flex">
                                      <h5 className="text-khaf">Division</h5>
                                      <h4 className="px-2">
                                        {divisionDetail?.division_name}
                                      </h4>
                                    </div>
                                    <p className="m-0 fs-5">
                                      {language === "mal"
                                        ? "ക്ലാസ് ടീച്ചർ"
                                        : "Class Teacher"}
                                      {" : "}
                                      {divisionDetail?.class_teacher_name}
                                    </p>
                                    {/* <p className="text-muted">From {(moment(ledgerDetail?.ledgers?.date_added).format("DD/MM/YYYY"))}</p> */}
                                  </Col>
                                  {/* <div className=" d-flex justify-content-end">
                                    <Button
                                      type="button"
                                      className="btn-sm bg-khaf-blue"
                                    >
                                      <i className="bx bx-edit text-center"></i>
                                      {language === 'mal'
                                        ? ' അപ്ഡേറ്റ്'
                                        : 'Update'}
                                    </Button>
                                  </div> */}
                                </Row>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <h5 className="pb-1">
                  {language === "mal" ? "ഡിവിഷനുകൾ :" : "Students :"}
                </h5>
                <Col lg="12" className="">
                  <React.Fragment>
                    <Row style={{ display: "flex" }}>
                      <Col className="col-lg-12 col-md-12">
                        <Card>
                          <CardBody>
                            <ToolkitProvider
                              keyField="id"
                              columns={studentColumns}
                              data={studentsData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <form
                                            className="app-search d-lg-block"
                                            onChange={e =>
                                              debounceStudentSearch(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <div className="position-relative">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={
                                                  language === "mal"
                                                    ? "തിരയുക..."
                                                    : "Search..."
                                                }
                                                defaultValue={searchTextStudent}
                                              />
                                              <span className="bx bx-search-alt" />
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="8">
                                      <div className="d-flex justify-content-end">
                                        <Link
                                          to={`/student/create?divisionid=${divisionDetail?.id}`}
                                        >
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="btn btn-sm mb-4"
                                          >
                                            {language === "mal"
                                              ? "+ വിദ്യാർത്ഥിയെ ചേർക്കുക"
                                              : "+ Add Student"}
                                          </Button>
                                        </Link>
                                        {/* <Link
                                          to={`/committee/meeting/create?committeeid=${divisionDetail?.id}`}
                                          className="btn-sm btn btn-primary"
                                        >
                                          {language === 'mal'
                                            ? '+ വിദ്യാർത്ഥിയെ ചേർക്കുക'
                                            : '+ Add Student'}
                                        </Link> */}
                                      </div>
                                    </Col>
                                  </Row>
                                  {studentLoading ? (
                                    <Spinner
                                      color="secondary"
                                      className="d-block m-auto"
                                    />
                                  ) : (
                                    <>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField={"id"}
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={
                                                defaultSortedStudent
                                              }
                                              selectRow={selectRowStudent}
                                              classes={
                                                "table align-middle table-nowrap table-hover"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <MyPagination
                                        totalPages={studentPages}
                                        page={studentPage}
                                        setPage={setStudentPage}
                                      />
                                    </>
                                  )}
                                  {students &&
                                    students?.results &&
                                    students?.results?.length <= 0 && (
                                      <p
                                        style={{ fontSize: "15px" }}
                                        className="text-center text-info"
                                      >
                                        {language === "mal"
                                          ? "യോഗങ്ങൾ ഒന്നും ഇല്ല"
                                          : "No Students Yet"}
                                      </p>
                                    )}
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <ToolkitProvider
                              keyField="id"
                              columns={attendanceColumns}
                              data={attendancesData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <AvForm>
                                    <Row className="mb-4">
                                      {/* <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <form
                                              className="app-search d-lg-block"
                                              onChange={e =>
                                                debounceAttendanceSearch(
                                                  e.target.value,
                                                )
                                              }
                                            >
                                              <div className="position-relative">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={
                                                    language === 'mal'
                                                      ? 'തിരയുക...'
                                                      : 'Search...'
                                                  }
                                                  defaultValue={
                                                    searchTextAttendance
                                                  }
                                                />
                                                <span className="bx bx-search-alt" />
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </Col> */}
                                      <Col md="4" className="">
                                        <h5>From :</h5>
                                        <AvField
                                          type="date"
                                          name="from_date"
                                          value={date?.from_date}
                                          onChange={e =>
                                            setDate({
                                              ...date,
                                              from_date: moment(
                                                e.target.value
                                              ).format("yyyy-MM-DD"),
                                            })
                                          }
                                        />
                                        <button
                                          onClick={() =>
                                            setDate({
                                              ...date,
                                              from_date: "",
                                            })
                                          }
                                          type="button"
                                          className="btn btn-white editable-cancel date_close btn-sm"
                                        >
                                          <i className="mdi mdi-close text-danger "></i>
                                        </button>
                                      </Col>
                                      <Col md="4" className="">
                                        <h5>To :</h5>
                                        <AvField
                                          type="date"
                                          name="to_date"
                                          value={date?.to_date}
                                          onChange={e =>
                                            setDate({
                                              ...date,
                                              to_date: moment(
                                                e.target.value
                                              ).format("yyyy-MM-DD"),
                                            })
                                          }
                                        />
                                        <button
                                          onClick={() =>
                                            setDate({
                                              ...date,
                                              to_date: "",
                                            })
                                          }
                                          type="button"
                                          className="btn btn-white editable-cancel date_close btn-sm"
                                        >
                                          <i className="mdi mdi-close text-danger "></i>
                                        </button>
                                      </Col>
                                      <Col md="4">
                                        <div className="d-flex justify-content-end">
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="btn btn-sm mb-4"
                                            onClick={() =>
                                              setModalStudentAttendance(true)
                                            }
                                          >
                                            {language === "mal"
                                              ? "+ ഹാജർ ചേർക്കുക"
                                              : "+ Add Attendance"}
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                  {attendanceLoading ? (
                                    <Spinner
                                      color="secondary"
                                      className="d-block m-auto"
                                    />
                                  ) : (
                                    <>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField={"id"}
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={
                                                defaultSortedAttendance
                                              }
                                              selectRow={selectRowAttendance}
                                              classes={
                                                "table align-middle table-nowrap table-hover"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <MyPagination
                                        totalPages={attendancePages}
                                        page={attendancePage}
                                        setPage={setAttendancePage}
                                      />
                                    </>
                                  )}
                                  {students &&
                                    students?.results &&
                                    students?.results?.length <= 0 && (
                                      <p
                                        style={{ fontSize: "15px" }}
                                        className="text-center text-info"
                                      >
                                        {language === "mal"
                                          ? "യോഗങ്ങൾ ഒന്നും ഇല്ല"
                                          : "No Students Yet"}
                                      </p>
                                    )}
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          </CardBody>
                        </Card>
                        <Row>
                          <Col md="6">
                            <Card className="sm:p-0">
                              <CardBody>
                                <Col
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  className="mb-4 mt-3 sm:mt-0"
                                >
                                  <div className="d-flex justify-content-between align-items-center eduHistory">
                                    <h5 className="font-size-14 font-weight-bold pb-2">
                                      {language === "mal"
                                        ? "ടൈംടേബിൾ"
                                        : "Timetable"}
                                    </h5>
                                  </div>
                                  {timetableLoading ? (
                                    <Spinner
                                      color="secondary"
                                      className="d-block m-auto"
                                    />
                                  ) : (
                                    <div className="table-responsive">
                                      <Table className="table table-bordered mb-0">
                                        <thead>
                                          <tr>
                                            <th>
                                              {language === "mal"
                                                ? "ദിവസങ്ങൾ"
                                                : "Days"}
                                            </th>
                                            {periods?.map((period, key) => (
                                              <th key={key}>{period}</th>
                                            ))}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {days?.map((day, dayIndex) => (
                                            <tr key={dayIndex}>
                                              <td>{day}</td>
                                              {periods?.map(
                                                (period, periodIndex) => {
                                                  const item =
                                                    timetableList?.find(
                                                      entry =>
                                                        entry?.day === day &&
                                                        entry?.period_no ===
                                                          period
                                                    )

                                                  return (
                                                    <td key={periodIndex}>
                                                      <div className="d-flex justify-content-between">
                                                        {item?.subject_name
                                                          ? item?.subject_name
                                                          : `_`}
                                                        <i
                                                          className="bx bx-edit text-primary fs-5"
                                                          onClick={() =>
                                                            updateTimetableModal(
                                                              item
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <p className="m-0">
                                                        {item?.teacher_name}
                                                      </p>
                                                    </td>
                                                  )
                                                }
                                              )}
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </div>
                                  )}
                                </Col>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card className="sm:p-0">
                              <CardBody>
                                <Col
                                  sm="12"
                                  md="12"
                                  lg="12"
                                  className="mb-4 mt-3 sm:mt-0"
                                >
                                  <div className="d-flex justify-content-between align-items-center eduHistory">
                                    <h5 className="font-size-14 font-weight-bold">
                                      {language === "mal"
                                        ? "വിഷയങ്ങൾ :"
                                        : "Subjects:"}
                                    </h5>
                                    <Button
                                      color="primary"
                                      className="m-2 btn-sm p-1"
                                      onClick={() => {
                                        setModalSubject(true)
                                      }}
                                    >
                                      {language === "mal"
                                        ? "+ വിഷയം ചേർക്കുക"
                                        : "+ Add Subject"}
                                    </Button>
                                  </div>
                                  {subjectLoading ? (
                                    <Spinner
                                      color="secondary"
                                      className="d-block m-auto"
                                    />
                                  ) : (
                                    <div className="table-responsive">
                                      <Table className="table table-bordered mb-0">
                                        <thead>
                                          <tr>
                                            <th>
                                              {language === "mal"
                                                ? "നം"
                                                : "NO."}
                                            </th>
                                            <th>
                                              {language === "mal"
                                                ? "വിഷയം"
                                                : "Subject"}
                                            </th>
                                            <th>
                                              {language === "mal"
                                                ? "അധ്യാപകൻ"
                                                : "Teacher"}
                                            </th>
                                            <th>
                                              {language === "mal"
                                                ? "പ്രവർത്തനങ്ങൾ"
                                                : "Actions"}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {subjects?.results?.map(
                                            (item, index) => (
                                              <Fragment key={index}>
                                                <tr>
                                                  <td scope="row">
                                                    {index + 1}
                                                  </td>
                                                  <td>{item?.subject_name}</td>
                                                  <td>{item?.teacher_name}</td>
                                                  <td
                                                    className="m-0 p-0"
                                                    style={{
                                                      maxWidth: "200px",
                                                    }}
                                                  >
                                                    <Button
                                                      type="button"
                                                      color="white"
                                                      className="ms-1 btn"
                                                      onClick={() =>
                                                        updateSubjectModal(item)
                                                      }
                                                    >
                                                      <i
                                                        className="bx bx-pencil me-2"
                                                        style={{
                                                          color: "green",
                                                          fontSize: "14px",
                                                        }}
                                                      />
                                                    </Button>
                                                  </td>
                                                </tr>
                                              </Fragment>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  )}
                                  {subjects &&
                                    subjects?.results &&
                                    subjects?.results?.length <= 0 && (
                                      <p
                                        style={{ fontSize: "15px" }}
                                        className="text-center text-info pt-5"
                                      >
                                        {language === "mal"
                                          ? "വിഷയങ്ങൾ ഒന്നും ഇല്ല"
                                          : "No Subjects Yet"}
                                      </p>
                                    )}
                                </Col>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </React.Fragment>
                </Col>
              </Row>
            </>
          </div>
        </Container>
      </div>
    </>
  )
}

export default DivisionDetails

DivisionDetails.propTypes = {
  history: PropTypes.object,
}
