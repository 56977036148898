import {
  GET_COMMITTEES_SUCCESS,
  GET_COMMITTEES_FAIL,
  GET_COMMITTEE_DROPDOWN_SUCCESS,
  GET_COMMITTEE_DROPDOWN_FAIL,
  GET_COMMITTEE_DETAIL_SUCCESS,
  GET_COMMITTEE_DETAIL_FAIL,
  CREATE_COMMITTEE_SUCCESS,
  CREATE_COMMITTEE_FAIL,
  UPDATE_COMMITTEE_SUCCESS,
  UPDATE_COMMITTEE_FAIL,
  DELETE_COMMITTEE_SUCCESS,
  DELETE_COMMITTEE_FAIL,
  GET_COMMITTEES,
  GET_COMMITTEE_DROPDOWN,
  GET_COMMITTEE_DETAIL,
  UPDATE_COMMITTEE,
  CREATE_COMMITTEE,
  DELETE_COMMITTEE,
} from "./actionTypes"

const INIT_STATE = {
  committees: {
    results: {
      committees: [],
    },
  },
  committeeDetail: {},
  searchCommittees: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdCommittee: {},
}

const Committees = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMITTEES:
    case UPDATE_COMMITTEE:
    case CREATE_COMMITTEE:
    case DELETE_COMMITTEE:
    case GET_COMMITTEE_DROPDOWN:
      return {
        ...state,
        loading: true,
      }
    case GET_COMMITTEE_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_COMMITTEES_SUCCESS:
      return {
        ...state,
        committees: action.payload,
        loading: false,
      }

    case GET_COMMITTEES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_COMMITTEE_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchCommittees: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_COMMITTEE_SUCCESS:
      return {
        ...state,
        // committees: action.payload,
        committees: {
          ...state.committees,
          results: {
            ...state.committees.results,
            committees: [
              ...state.committees.results.committees,
              action.payload,
            ],
          },
        },
        createdCommittee: action.payload,
        loading: false,
      }

    case CREATE_COMMITTEE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_DETAIL_SUCCESS:
      return {
        ...state,
        committeeDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_COMMITTEE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_COMMITTEE_SUCCESS:
      return {
        ...state,
        committeeDetail: action.payload,
        loading: false,
      }

    case UPDATE_COMMITTEE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_COMMITTEE_SUCCESS:
      return {
        ...state,
        // committees: state.committees.filter(
        //   committee => committee.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_COMMITTEE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Committees
