import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import Select from "react-select"
import { Col, Modal, Button, Label } from "reactstrap"
import moment from "moment"

import {
  createStudentAttendance,
  periodsDropdown,
  studentsDropdown,
} from "store/actions"

const UiModalStudentAttendance = ({ show, onCloseclick }) => {
  const dispatch = useDispatch()

  const { loading, language, divisionId, studentsList, periodsList } =
    useSelector(state => ({
      loading: state.Divisions.loading,
      language: state.Layout.language,
      divisionId: state.Divisions.divisionDetail?.id,
      studentsList: state.Students.studentsList,
      periodsList: state.Timetable.periodsList,
    }))

  const today = moment().format("YYYY-MM-DD")
  const day =
    moment(today).format("dddd").charAt(0).toLowerCase() +
    moment(today).format("dddd").slice(1)

  const [students, setStudents] = useState([])
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [period, setPeriod] = useState("Select period...")
  const [periodId, setPeriodId] = useState()

  console.log(students)

  const optionGroupStudent = () => {
    const optionGroup = studentsList?.map(item => {
      return {
        value: item.id,
        label: `${item?.name} (${item?.roll_no})`,
      }
    })
    return optionGroup
  }

  function handlerFinalValueStudent(
    typeData,
    setSelectedMultiStudent,
    setStudents
  ) {
    setSelectedMultiStudent(typeData)
    const arrangedTypes = typeData?.map(a => a.value)
    setStudents(arrangedTypes)
  }

  const studentListFilterItem = (
    selectedMultiStudent,
    setSelectedMultiStudent,
    setStudents
  ) => {
    return (
      studentsList &&
      studentsList?.length > 0 && (
        <Col md={"12"}>
          <Label> {language === "mal" ? "വിദ്യാർത്ഥി" : "Students"}</Label>
          <span className="text-danger">&#42;</span>
          <Select
            value={selectedMultiStudent}
            isMulti={true}
            onChange={e => {
              handlerFinalValueStudent(e, setSelectedMultiStudent, setStudents)
            }}
            options={optionGroupStudent()}
            classNamePrefix="select2-selection"
          />
        </Col>
      )
    )
  }

  const optionGroupPeriod = [
    {
      options: periodsList?.map((result, index) => ({
        key: index,
        label: result?.period_no,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValuePeriod(event) {
    setPeriodId(event.value)
    setPeriod(event.label)
  }

  // const handleEntersPeriod = textEntered => {
  //   setSearchTextPeriod(textEntered)
  // }

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const createStudentAttendanceData = {
      division: divisionId,
      date: values.date,
      period: periodId,
      students: students,
      // description: values.description,
    }
    dispatch(createStudentAttendance(createStudentAttendanceData))
    setPeriod("Select period...")
    setPeriodId("")
    setSelectedStudent(null)
  }

  useEffect(() => {
    dispatch(studentsDropdown("", divisionId))
  }, [dispatch, "", divisionId])

  useEffect(() => {
    dispatch(periodsDropdown(day, divisionId))
  }, [dispatch, day, divisionId])

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Create Attendance</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col md="6">
                  <Label>
                    {language === "mal" ? "തീയതി" : "Date"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="date"
                    type="date"
                    value={today}
                    // validate={{
                    //   required: { value: true },
                    // }}
                  />
                </Col>
                <Col md="6">
                  <Label>
                    {language === "mal" ? "പിരീഡ്" : "Period"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    // onInputChange={handleEntersPeriod}
                    placeholder={period}
                    options={optionGroupPeriod}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValuePeriod}
                    type="text"
                    title="Period"
                    required="required"
                  />
                </Col>
              </div>
              <div className="row mb-4">
                {studentListFilterItem(
                  selectedStudent,
                  setSelectedStudent,
                  setStudents
                )}
                {/* <Col sm="12" md="12">
                  <Label>
                    {language === 'mal' ? 'വിദ്യാർത്ഥി' : 'Students'}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEntersStudent}
                    placeholder={selectedStudent}
                    options={optionGroupStudent()}
                    classNamePrefix="select2-selection"
                    onChange={e => handlerFinalValueStudent(e)}
                    type="text"
                    title="Family Member"
                    required="required"
                  />
                </Col> */}
              </div>
              {/* <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    label={language === 'mal' ? 'വിവരണം' : 'Description'}
                  />
                </Col>
              </div> */}
              <div className="row w-100 justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  disabled={!periodId}
                >
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalStudentAttendance
UiModalStudentAttendance.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
