import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_GROUP_ADMIN,
  GET_GROUP_ADMIN_DETAIL,
  CHANGE_GROUP_ADMIN_STATUS,
  CREATE_GROUP_ADMIN,
  UPDATE_GROUP_ADMIN,
  DELETE_GROUP_ADMIN,
} from "./actionTypes"
import {
  getGroupAdminSuccess,
  getGroupAdminFail,
  getGroupAdminDetailSuccess,
  getGroupAdminDetailFail,
  changeGroupAdminStatusSuccess,
  changeGroupAdminStatusFail,
  createGroupAdminSuccess,
  createGroupAdminFail,
  updateGroupAdminSuccess,
  updateGroupAdminFail,
  deleteGroupAdminSuccess,
  deleteGroupAdminFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getGroupAdminAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/mahall_group_admin/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(`/api/v1/mahall/mahall_group_admin/?page=${page ? page : 1}`)
  }
}
const getGroupAdminDetailsAPi = groupAdminId => {
  return get(`/api/v1/mahall/mahall_group_admin/${groupAdminId}/`)
}
const changeGroupAdminStatusApi = ({ groupAdminId }) => {
  return post(`/api/v1/mahall/mahallgroupadmin/status/${groupAdminId}/`)
}
const createGroupAdminApi = ({ groupAdmin }) => {
  return post("/api/v1/mahall/mahall_group_admin/", groupAdmin)
}
const updateGroupAdminApi = ({ groupAdmin, groupAdminId }) => {
  return ApiPut(
    `/api/v1/mahall/mahall_group_admin/${groupAdminId}/`,
    groupAdmin
  )
}
const deleteGroupAdminApi = ({ groupAdminId }) => {
  return del(`/api/v1/mahall/mahall_group_admin/${groupAdminId}/`)
}

function* fetchGroupAdmin({ payload }) {
  try {
    const response = yield call(getGroupAdminAPi, payload)
    if (response && !response?.error) {
      yield put(getGroupAdminSuccess(response))
    } else {
      yield put(getGroupAdminFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getGroupAdminFail(error))
  }
}

function* fetchGroupAdminDetail({ groupAdminId }) {
  try {
    const response = yield call(getGroupAdminDetailsAPi, groupAdminId)
    if (response && !response?.error) {
      yield put(getGroupAdminDetailSuccess(response))
    } else {
      yield put(getGroupAdminDetailFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getGroupAdminDetailFail(error))
  }
}

function* changeGroupAdminStatus({ payload }) {
  try {
    const response = yield call(changeGroupAdminStatusApi, payload)
    if (
      response &&
      !response?.error &&
      response[0] === "Successfully Updated"
    ) {
      yield put(changeGroupAdminStatusSuccess(payload))
      doneNotification("Status Updated Successfully")
    } else {
      yield put(changeGroupAdminStatusFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(changeGroupAdminStatusFail(error))
  }
}

function* onCreateGroupAdmin({ payload }) {
  try {
    const response = yield call(createGroupAdminApi, payload)
    if (response && !response?.error) {
      yield put(createGroupAdminSuccess(response))
      payload.history.push("/groupadmins")
      doneNotification("Created Successfully")
    } else {
      yield put(createGroupAdminFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(createGroupAdminFail(error))
    errorNotification()
  }
}

function* onUpdateGroupAdmin({ payload }) {
  try {
    const response = yield call(updateGroupAdminApi, payload)
    if (response && !response?.error) {
      yield put(updateGroupAdminSuccess(response))
      payload.history.push("/groupadmins")
      doneNotification("Updated Successfully")
    } else {
      yield put(updateGroupAdminFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(updateGroupAdminFail(error))
    errorNotification()
  }
}

function* onDeleteGroupAdmin({ payload }) {
  try {
    const response = yield call(deleteGroupAdminApi, payload)
    if (response && !response?.error) {
      payload.history.push("/groupadmins")
      yield put(deleteGroupAdminSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteGroupAdminFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteGroupAdminFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* GroupAdminsSaga() {
  yield takeEvery(GET_GROUP_ADMIN, fetchGroupAdmin)
  yield takeEvery(GET_GROUP_ADMIN_DETAIL, fetchGroupAdminDetail)
  yield takeEvery(CHANGE_GROUP_ADMIN_STATUS, changeGroupAdminStatus)
  yield takeEvery(CREATE_GROUP_ADMIN, onCreateGroupAdmin)
  yield takeEvery(UPDATE_GROUP_ADMIN, onUpdateGroupAdmin)
  yield takeEvery(DELETE_GROUP_ADMIN, onDeleteGroupAdmin)
}

export default GroupAdminsSaga
