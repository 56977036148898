import {
  GET_INSTITUTE_WALLET_CONTRA_LIST,
  GET_INSTITUTE_WALLET_CONTRA_LIST_SUCCESS,
  GET_INSTITUTE_WALLET_CONTRA_LIST_FAIL,
  CREATE_INSTITUTE_WALLET_CONTRA,
  CREATE_INSTITUTE_WALLET_CONTRA_SUCCESS,
  CREATE_INSTITUTE_WALLET_CONTRA_FAIL,
} from "./actionTypes"

export const getInstituteWalletContraList = (walletId, searchText, page) => ({
  type: GET_INSTITUTE_WALLET_CONTRA_LIST,
  payload: { walletId, searchText, page },
})

export const getInstituteWalletContraListSuccess = walletContraList => ({
  type: GET_INSTITUTE_WALLET_CONTRA_LIST_SUCCESS,
  payload: walletContraList,
})
export const getInstituteWalletContraListFail = error => ({
  type: GET_INSTITUTE_WALLET_CONTRA_LIST_FAIL,
  payload: error,
})

export const createInstituteWalletContra = instituteWalletContra => ({
  type: CREATE_INSTITUTE_WALLET_CONTRA,
  payload: { instituteWalletContra },
})

export const createInstituteWalletContraSuccess = instituteWalletContra => ({
  type: CREATE_INSTITUTE_WALLET_CONTRA_SUCCESS,
  payload: instituteWalletContra,
})

export const createInstituteWalletContraFail = error => ({
  type: CREATE_INSTITUTE_WALLET_CONTRA_FAIL,
  payload: error,
})
