import React from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"

import { createAsset } from "store/actions"

const UiModalAsset = ({ show, onCloseclick, madrasaId }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const { loading, language } = useSelector(state => ({
    loading: state.Assets.loading,
    language: state.Layout.language,
  }))

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const createAssetData = {
      institute: madrasaId,
      asset_name: values.asset_name,
      remark: values.remark,
    }
    dispatch(createAsset(createAssetData))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "അസറ്റ് ചേർക്കുക" : "Create Asset"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "പേര്" : "Asset Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="asset_name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="remark"
                    type="textarea"
                    label={language === "mal" ? "റിമാർക്" : "Remark"}
                  />
                </Col>
              </div>
              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalAsset
UiModalAsset.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  madrasaId: propTypes.string,
}
