import {
  GET_COMMITTEE_MEETINGS_SUCCESS,
  GET_COMMITTEE_MEETINGS_FAIL,
  GET_COMMITTEE_MEETING_DROPDOWN_SUCCESS,
  GET_COMMITTEE_MEETING_DROPDOWN_FAIL,
  GET_COMMITTEE_MEETING_DETAIL_SUCCESS,
  GET_COMMITTEE_MEETING_DETAIL_FAIL,
  CREATE_COMMITTEE_MEETING_SUCCESS,
  CREATE_COMMITTEE_MEETING_FAIL,
  UPDATE_COMMITTEE_MEETING_SUCCESS,
  UPDATE_COMMITTEE_MEETING_FAIL,
  DELETE_COMMITTEE_MEETING_SUCCESS,
  DELETE_COMMITTEE_MEETING_FAIL,
  GET_COMMITTEE_MEETINGS,
  GET_COMMITTEE_MEETING_DROPDOWN,
  GET_COMMITTEE_MEETING_DETAIL,
  UPDATE_COMMITTEE_MEETING,
  CREATE_COMMITTEE_MEETING,
  DELETE_COMMITTEE_MEETING,
} from "./actionTypes"

const INIT_STATE = {
  committeeMeetings: [],
  committeeMeetingDetail: {},
  searchCommitteeMeetings: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdCommitteeMeeting: {},
}

const CommitteeMeetings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMITTEE_MEETINGS:
    case UPDATE_COMMITTEE_MEETING:
    case CREATE_COMMITTEE_MEETING:
    case DELETE_COMMITTEE_MEETING:
    case GET_COMMITTEE_MEETING_DROPDOWN:
      return {
        ...state,
        loading: true,
      }
    case GET_COMMITTEE_MEETING_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_COMMITTEE_MEETINGS_SUCCESS:
      return {
        ...state,
        committeeMeetings: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_MEETINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_COMMITTEE_MEETING_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchCommitteeMeetings: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_MEETING_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_COMMITTEE_MEETING_SUCCESS:
      return {
        ...state,
        committeeMeetings: action.payload,
        createdCommitteeMeeting: action.payload,
        loading: false,
      }

    case CREATE_COMMITTEE_MEETING_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_MEETING_DETAIL_SUCCESS:
      return {
        ...state,
        committeeMeetingDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_COMMITTEE_MEETING_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_COMMITTEE_MEETING_SUCCESS:
      return {
        ...state,
        committeeMeetingDetail: action.payload,
        loading: false,
      }

    case UPDATE_COMMITTEE_MEETING_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_COMMITTEE_MEETING_SUCCESS:
      return {
        ...state,
        committeeMeetings: action.payload,
        // committeeMeetings: state.committeeMeetings.filter(
        //   committeeMeeting => committeeMeeting.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_COMMITTEE_MEETING_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default CommitteeMeetings
