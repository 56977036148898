import {
  GET_CONSTITUENCIES_SUCCESS,
  GET_CONSTITUENCIES_FAIL,
  GET_CONSTITUENCY_DROPDOWN_SUCCESS,
  GET_CONSTITUENCY_DROPDOWN_FAIL,
  GET_CONSTITUENCY_DETAIL_SUCCESS,
  GET_CONSTITUENCY_DETAIL_FAIL,
  CREATE_CONSTITUENCY_SUCCESS,
  CREATE_CONSTITUENCY_FAIL,
  UPDATE_CONSTITUENCY_SUCCESS,
  UPDATE_CONSTITUENCY_FAIL,
  DELETE_CONSTITUENCY_SUCCESS,
  DELETE_CONSTITUENCY_FAIL,
  GET_CONSTITUENCIES,
  GET_CONSTITUENCY_DROPDOWN,
  GET_CONSTITUENCY_DETAIL,
  UPDATE_CONSTITUENCY,
  CREATE_CONSTITUENCY,
  DELETE_CONSTITUENCY,
} from "./actiontypes"

const INIT_STATE = {
  constituencies: [],
  constituencyDetail: {},
  searchConstituencies: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdConstituency: {},
}

const Constituencies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONSTITUENCIES:
    case UPDATE_CONSTITUENCY:
    case CREATE_CONSTITUENCY:
    case DELETE_CONSTITUENCY:
    case GET_CONSTITUENCY_DROPDOWN:
      return {
        ...state,
        loading: true,
      }
    case GET_CONSTITUENCY_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_CONSTITUENCIES_SUCCESS:
      return {
        ...state,
        constituencies: action.payload,
        loading: false,
      }

    case GET_CONSTITUENCIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_CONSTITUENCY_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchConstituencies: action.payload,
        loading: false,
      }

    case GET_CONSTITUENCY_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_CONSTITUENCY_SUCCESS:
      return {
        ...state,
        constituencies: action.payload,
        createdConstituency: action.payload,
        loading: false,
      }

    case CREATE_CONSTITUENCY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CONSTITUENCY_DETAIL_SUCCESS:
      return {
        ...state,
        constituencyDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_CONSTITUENCY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_CONSTITUENCY_SUCCESS:
      return {
        ...state,
        constituencyDetail: action.payload,
        loading: false,
      }

    case UPDATE_CONSTITUENCY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_CONSTITUENCY_SUCCESS:
      return {
        ...state,
        constituencies: state.constituencies.filter(
          constituency =>
            constituency.id.toString() !== action.payload.id.toString()
        ),
        loading: false,
      }

    case DELETE_CONSTITUENCY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Constituencies
