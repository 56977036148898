import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import moment from "moment"

import UiModalStaff from "./UiModalStaff"
import UiModalStaffUpdate from "./UiModalStaffUpdate"

import {
  getStaffs,
  //  deleteGroup
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"

const Staffs = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const limit = 10

  const { staffs, loading, language } = useSelector(state => ({
    staffs: state.Staffs.staffs,
    loading: state.Staffs.loading,
    language: state.Layout.language,
  }))

  const madrasaId = location?.search?.split("?madrasaid=")[1]

  const [modalStaff, setModalStaff] = useState(false)
  const [modalStaffUpdate, setModalStaffUpdate] = useState(false)
  const [onUpdateStaff, setOnUpdateStaff] = useState()

  const updateStaffModal = item => {
    setOnUpdateStaff(item)
    setModalStaffUpdate(true)
  }

  //pages
  const totalPages = Math.ceil(staffs?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getStaffs(searchText, pageSend(), madrasaId))
  }, [dispatch, page, searchText, madrasaId])

  // const handleDelete = id => {
  //   dispatch(deleteGroup(id, history))
  // }

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
      sort: true,
    },
    {
      dataField: "name",
      text: `${language === "mal" ? "പേര്" : "Name"}`,
      sort: true,
    },
    {
      dataField: "phone",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "dob",
      text: `${language === "mal" ? "ഡി ഒ ബി" : "DOB"}`,
    },
    {
      dataField: "salary",
      text: `${language === "mal" ? "ശമ്പളം" : "Salary"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const StaffsData = map(staffs?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    dob: moment(item?.dob).format("DD/MM/YYYY"),
    action: (
      <div>
        <Button
          type="button"
          color="primary"
          className=" btn w-xs btn-sm"
          onClick={() => updateStaffModal(item)}
        >
          {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
          Edit
        </Button>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMeetingSearch = debounce(value => setSearchText(value), 600)

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <UiModalStaff
        show={modalStaff}
        onCloseclick={() => setModalStaff(false)}
        madrasaId={madrasaId}
      />
      <UiModalStaffUpdate
        show={modalStaffUpdate}
        onCloseclick={() => setModalStaffUpdate(false)}
        data={onUpdateStaff}
      />
      <Row>
        {/* <Row>
          <div className="col-md-6">
            <Link
              to={`/surveyuser/create`}
              type="submit"
              style={{ width: language === "mal" ? "200px" : "150px" }}
              className="w-md btn btn-success d-block"
            >
              {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New SurveyUser"}
            </Link>
          </div>
        </Row> */}
        <Col className="col-12 mt-2">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={StaffsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceMeetingSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Col md="8">
                        <div className="d-flex justify-content-end">
                          <Button
                            type="button"
                            color="primary"
                            className="btn-sm mb-4"
                            onClick={() => setModalStaff(true)}
                          >
                            {language === "mal"
                              ? "+ സ്റ്റാഫിനെ ചേർക്കുക"
                              : "+ Add Staff"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Staffs

// AllStaffs.propTypes = {
//   history: PropTypes.object,
// }
