import {
  GET_SUBJECTS,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAIL,
  GET_SUBJECT_DETAIL,
  GET_SUBJECT_DETAIL_SUCCESS,
  GET_SUBJECT_DETAIL_FAIL,
  CREATE_SUBJECT,
  CREATE_SUBJECT_SUCCESS,
  CREATE_SUBJECT_FAIL,
  UPDATE_SUBJECT,
  UPDATE_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_FAIL,
  DELETE_SUBJECT,
  DELETE_SUBJECT_SUCCESS,
  DELETE_SUBJECT_FAIL,
  SUBJECTS_DROPDOWN,
  SUBJECTS_DROPDOWN_SUCCESS,
  SUBJECTS_DROPDOWN_FAIL,
} from "./actionTypes"

export const getSubjects = (divisionId, searchText, page) => ({
  type: GET_SUBJECTS,
  payload: { divisionId, searchText, page },
})
export const getSubjectsSuccess = subjects => ({
  type: GET_SUBJECTS_SUCCESS,
  payload: subjects,
})
export const getSubjectsFail = error => ({
  type: GET_SUBJECTS_FAIL,
  payload: error,
})

export const subjectsDropdown = (searchText, divisionId) => ({
  type: SUBJECTS_DROPDOWN,
  payload: { searchText, divisionId },
})
export const subjectsDropdownSuccess = subjectList => ({
  type: SUBJECTS_DROPDOWN_SUCCESS,
  payload: subjectList,
})
export const subjectsDropdownFail = error => ({
  type: SUBJECTS_DROPDOWN_FAIL,
  payload: error,
})

export const getSubjectDetail = subjectId => ({
  type: GET_SUBJECT_DETAIL,
  subjectId,
})

export const getSubjectDetailSuccess = subjectDetail => ({
  type: GET_SUBJECT_DETAIL_SUCCESS,
  payload: subjectDetail,
})

export const getSubjectDetailFail = error => ({
  type: GET_SUBJECT_DETAIL_FAIL,
  payload: error,
})

export const createSubject = (newSubject, history) => ({
  type: CREATE_SUBJECT,
  payload: { newSubject, history },
})

export const createSubjectSuccess = newSubject => ({
  type: CREATE_SUBJECT_SUCCESS,
  payload: newSubject,
})

export const createSubjectFail = error => ({
  type: CREATE_SUBJECT_FAIL,
  payload: error,
})

export const updateSubject = (updatedSubject, subjectId, history) => ({
  type: UPDATE_SUBJECT,
  payload: { updatedSubject, subjectId, history },
})

export const updateSubjectSuccess = updatedSubject => ({
  type: UPDATE_SUBJECT_SUCCESS,
  payload: updatedSubject,
})

export const updateSubjectFail = error => ({
  type: UPDATE_SUBJECT_FAIL,
  payload: error,
})

export const deleteSubject = subjectId => ({
  type: DELETE_SUBJECT,
  payload: { subjectId },
})

export const deleteSubjectSuccess = newSubject => ({
  type: DELETE_SUBJECT_SUCCESS,
  payload: newSubject,
})

export const deleteSubjectFail = error => ({
  type: DELETE_SUBJECT_FAIL,
  payload: error,
})
