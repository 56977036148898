import {
  GET_MAHALL_NOTIFICATIONS,
  GET_MAHALL_NOTIFICATIONS_CARDS,
  UPDATE_MAHALL_NOTIFICATION,
  CREATE_MAHALL_NOTIFICATION,
  DELETE_MAHALL_NOTIFICATION,
  GET_MAHALL_NOTIFICATION_DETAIL,
  GET_MAHALL_NOTIFICATIONS_SUCCESS,
  GET_MAHALL_NOTIFICATIONS_FAIL,
  GET_MAHALL_NOTIFICATIONS_CARDS_SUCCESS,
  GET_MAHALL_NOTIFICATIONS_CARDS_FAIL,
  CREATE_MAHALL_NOTIFICATION_SUCCESS,
  CREATE_MAHALL_NOTIFICATION_FAIL,
  GET_MAHALL_NOTIFICATION_DETAIL_SUCCESS,
  GET_MAHALL_NOTIFICATION_DETAIL_FAIL,
  UPDATE_MAHALL_NOTIFICATION_SUCCESS,
  UPDATE_MAHALL_NOTIFICATION_FAIL,
  DELETE_MAHALL_NOTIFICATION_SUCCESS,
  DELETE_MAHALL_NOTIFICATION_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS,
  CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL,
  UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_SUCCESS,
  CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_SUCCESS,
  UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS,
  DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_FAIL,
  CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL,
  GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_FAIL,
  UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL,
  DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS,
  GET_INDIVIDUAL_NOTIFICATIONS,
  GET_INDIVIDUAL_NOTIFICATIONS_CARDS,
  CREATE_INDIVIDUAL_NOTIFICATION,
  GET_INDIVIDUAL_NOTIFICATION_DETAIL,
  UPDATE_INDIVIDUAL_NOTIFICATION,
  DELETE_INDIVIDUAL_NOTIFICATION,
  GET_INDIVIDUAL_NOTIFICATIONS_SUCCESS,
  GET_INDIVIDUAL_NOTIFICATIONS_CARDS_SUCCESS,
  CREATE_INDIVIDUAL_NOTIFICATION_SUCCESS,
  GET_INDIVIDUAL_NOTIFICATION_DETAIL_SUCCESS,
  UPDATE_INDIVIDUAL_NOTIFICATION_SUCCESS,
  DELETE_INDIVIDUAL_NOTIFICATION_SUCCESS,
  GET_INDIVIDUAL_NOTIFICATIONS_FAIL,
  GET_INDIVIDUAL_NOTIFICATIONS_CARDS_FAIL,
  CREATE_INDIVIDUAL_NOTIFICATION_FAIL,
  GET_INDIVIDUAL_NOTIFICATION_DETAIL_FAIL,
  UPDATE_INDIVIDUAL_NOTIFICATION_FAIL,
  DELETE_INDIVIDUAL_NOTIFICATION_FAIL,
} from "./actiontypes"

const INIT_STATE = {
  mahallNotifications: [],
  mahallNotificationsCards: {},
  mahallNotificationDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdMahallNotification: {},

  familyMemberCollectionNotifications: [],
  familyMemberCollectionNotificationsCards: {},
  familyMemberCollectionNotificationDetail: {},
  createdFamilyMemberCollectionNotification: {},

  individualNotifications: [],
  individualNotificationsCards: {},
  individualNotificationDetail: {},
  createdIndividualNotification: {},
}

const Notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAHALL_NOTIFICATIONS:
    case GET_MAHALL_NOTIFICATIONS_CARDS:
    case CREATE_MAHALL_NOTIFICATION:
    case GET_MAHALL_NOTIFICATION_DETAIL:
    case UPDATE_MAHALL_NOTIFICATION:
    case DELETE_MAHALL_NOTIFICATION:
    case GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS:
    case GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS:
    case CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION:
    case GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL:
    case UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION:
    case DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION:
    case GET_INDIVIDUAL_NOTIFICATIONS:
    case GET_INDIVIDUAL_NOTIFICATIONS_CARDS:
    case CREATE_INDIVIDUAL_NOTIFICATION:
    case GET_INDIVIDUAL_NOTIFICATION_DETAIL:
    case UPDATE_INDIVIDUAL_NOTIFICATION:
    case DELETE_INDIVIDUAL_NOTIFICATION:
      return {
        ...state,
        loading: true,
      }

    case GET_MAHALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        mahallNotifications: action.payload,
        loading: false,
      }

    case GET_MAHALL_NOTIFICATIONS_CARDS_SUCCESS:
      return {
        ...state,
        mahallNotificationsCards: action.payload,
        loading: false,
      }

    case CREATE_MAHALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        mahallNotifications: action.payload,
        createdMahallNotification: action.payload,
        loading: false,
      }

    case GET_MAHALL_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        mahallNotificationDetail: action.payload,
        loading: false,
      }

    case UPDATE_MAHALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        mahallNotificationDetail: action.payload,
        loading: false,
      }

    case DELETE_MAHALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    // ------------------------------------FAMILY MEMBER COLLECTION NOTIFICATIONS----------------------------------------

    case GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        familyMemberCollectionNotifications: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_SUCCESS:
      return {
        ...state,
        familyMemberCollectionNotificationsCards: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS:
      return {
        ...state,
        familyMemberCollectionNotifications: action.payload,
        createdFamilyMemberCollectionNotification: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        familyMemberCollectionNotificationDetail: action.payload,
        loading: false,
      }

    case UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS:
      return {
        ...state,
        familyMemberCollectionNotificationDetail: action.payload,
        loading: false,
      }

    case DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    // ------------------------------------INDIVIDUAL NOTIFICATIONS----------------------------------------

    case GET_INDIVIDUAL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        individualNotifications: action.payload,
        loading: false,
      }

    case GET_INDIVIDUAL_NOTIFICATIONS_CARDS_SUCCESS:
      return {
        ...state,
        individualNotificationsCards: action.payload,
        loading: false,
      }

    case CREATE_INDIVIDUAL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        individualNotifications: action.payload,
        createdIndividualNotification: action.payload,
        loading: false,
      }

    case GET_INDIVIDUAL_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        individualNotificationDetail: action.payload,
        loading: false,
      }

    case UPDATE_INDIVIDUAL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        individualNotificationDetail: action.payload,
        loading: false,
      }

    case DELETE_INDIVIDUAL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    //fail

    case GET_MAHALL_NOTIFICATIONS_FAIL:
    case GET_MAHALL_NOTIFICATIONS_CARDS_FAIL:
    case CREATE_MAHALL_NOTIFICATION_FAIL:
    case GET_MAHALL_NOTIFICATION_DETAIL_FAIL:
    case UPDATE_MAHALL_NOTIFICATION_FAIL:
    case DELETE_MAHALL_NOTIFICATION_FAIL:
    case GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_FAIL:
    case GET_FAMILY_MEMBER_COLLECTION_NOTIFICATIONS_CARDS_FAIL:
    case CREATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL:
    case GET_FAMILY_MEMBER_COLLECTION_NOTIFICATION_DETAIL_FAIL:
    case UPDATE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL:
    case DELETE_FAMILY_MEMBER_COLLECTION_NOTIFICATION_FAIL:
    case GET_INDIVIDUAL_NOTIFICATIONS_FAIL:
    case GET_INDIVIDUAL_NOTIFICATIONS_CARDS_FAIL:
    case CREATE_INDIVIDUAL_NOTIFICATION_FAIL:
    case GET_INDIVIDUAL_NOTIFICATION_DETAIL_FAIL:
    case UPDATE_INDIVIDUAL_NOTIFICATION_FAIL:
    case DELETE_INDIVIDUAL_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Notifications
