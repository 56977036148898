import React from "react"
import { Row } from "reactstrap"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import logo_mahallu from "assets/images/logo/logo.png"

const DonationReceiptPdf = ({ pdfRef, transactionDetails }) => {
  return (
    <div
      style={{
        paddingLeft: "50px",
        paddingRight: "50px",
        position: "absolute",
        top: -1400,
        width: "780px",
      }}
      ref={pdfRef}
    >
      {/* <Row>
        <div style={{ border: "2px solid black" }}>
          <div className="d-flex header-div justify-content-center">
            <img
              src={userDetail?.mahall?.logo && userDetail?.mahall?.logo}
              alt=""
              className="img-thumbnail reciept-logo"
              style={{ width: '100px', height: '100px' }}
            />
            <div className="text-black">
              <h2 className="text-black text-center">
                {language === "mal"
                  ? userDetail?.mahall?.mahall_malayalam_name
                    ? userDetail?.mahall?.mahall_malayalam_name
                    : userDetail?.mahall?.mahall_english_name
                  : userDetail?.mahall?.mahall_english_name}
              </h2>
              <p style={{ marginBottom: "10px" }}>
                {language === "mal"
                  ? userDetail?.mahall?.village_malayalam_name
                    ? userDetail?.mahall?.village_malayalam_name
                    : userDetail?.mahall?.village_english_name
                  : userDetail?.mahall?.village_english_name}
                , {userDetail?.mahall?.post_office},{" "}
                {userDetail?.mahall?.pin_code}
              </p>
            </div>
          </div>
          <h2 className="text-black pt-4 text-center">
            {ledgerItemDetail?.transaction_type === "credit"
              ? language === "mal"
                ? "രസീത്"
                : "RECEIPT"
              : language === "mal"
              ? "വൗച്ചർ"
              : "VOUCHER"}
          </h2>
          <div className="middle-div-pdf">
            <div className="sub-middle-div-pdf">
              <div className="d-flex font-p">
                <h5 className="font-h5">
                  {ledgerItemDetail?.transaction_type === "credit"
                    ? language === "mal"
                      ? "രസീത് നം"
                      : "Receipt No."
                    : language === "mal"
                    ? "വൗച്ചർ നം"
                    : "Voucher No."}
                </h5>
                <p>
                  {" "}
                  :{" "}
                  {ledgerItemDetail?.receipt_number
                    ? ledgerItemDetail?.receipt_number
                    : ledgerItemDetail?.voucher_number}
                </p>
              </div>
              <div className="d-flex font-p">
                <h5 className="font-h5">
                  {language === "mal" ? "തീയതി" : "Date"}
                </h5>
                <p>: {moment(ledgerItemDetail?.date).format("DD-MM-yyyy")}</p>
              </div>
            </div>
            <div className="d-flex font-p">
              <h5 className="font-h5">
                {language === "mal" ? "പേര്" : "Name"}
              </h5>
              <p> : {ledgerItemDetail?.member_name}</p>
            </div>
            <div className="d-flex pb-4 font-p">
              <h5 className="font-h5">
                {language === "mal" ? "വിശദാംശങ്ങൾ" : "Particulars"}
              </h5>
              <p> : {ledgerItemDetail?.title}</p>
            </div>
            <div className="d-flex font-p">
              <h5 className="font-h5">
                {language === "mal" ? "തുക" : "Amount"}
              </h5>
              <p> : {ledgerItemDetail?.amount}</p>
            </div>
          </div>
          {ledgerItemDetail?.transaction_type === "debit" ? (
            <div className="d-flex justify-content-around pt-5 pb-3 text-black font-p">
              <p>{language === "mal" ? "സ്വീകർത്താവ്" : "Reciever"}</p>
              <p>
                {language === "mal"
                  ? "സെക്രട്ടറി / പ്രസിഡന്റ്"
                  : "Secretary / President"}
              </p>
            </div>
          ) : (
            <p
              className="d-flex justify-content-end pt-5 pb-3 text-black font-p"
              style={{ paddingRight: "170px" }}
            >
              {language === "mal"
                ? "സെക്രട്ടറി / പ്രസിഡന്റ്"
                : "Secretary / President"}
            </p>
          )}
        </div>
      </Row> */}
      <div style={{ border: "2px solid black" }}>
        <div className="p-3">
          <h5
            className="modal-title h4 flex-center  font-size-22"
            style={{ fontStyle: 600 }}
          >
            Khaf Mahall Empowerment Committee
          </h5>
          <h6 className="modal-sub-title"></h6>

          <div className="modal-body p-3">
            <div className="p-3 d-flex align-items-center justify-content-between">
              <div>
                No:{" "}
                <span className="text-danger">
                  {transactionDetails?.auto_id}
                </span>
              </div>
              <div className="font-size-18">Receipt</div>
              <div>Date : 12/12/2012</div>
            </div>
            <div className="mb-0">
              <div className="text-muted font-size-15">
                Received with thanks from Jb <b>{transactionDetails?.name},</b>{" "}
                a sum of Rupees of <b>{transactionDetails?.amount} .</b>
                DONATION FOR #khaf.in(Khaf Mahall Empowerment)
              </div>
              <p className="d-flex align-items-center justify-content"></p>
            </div>
            <div className="d-flex align-items-center justify-content-between ">
              <div className="">
                <b> Rs : {transactionDetails?.amount}</b>
              </div>
              <div className="">
                <b>Secretary</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DonationReceiptPdf

DonationReceiptPdf.propTypes = {
  pdfRef: PropTypes.any,
  transactionDetails: PropTypes.func,
}
