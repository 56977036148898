import {
  GET_ASSET_ITEM_TRANSACTIONS,
  GET_ASSET_ITEM_TRANSACTIONS_SUCCESS,
  GET_ASSET_ITEM_TRANSACTIONS_FAIL,
  GET_ASSET_ITEM_TRANSACTION_DETAIL,
  GET_ASSET_ITEM_TRANSACTION_DETAIL_SUCCESS,
  GET_ASSET_ITEM_TRANSACTION_DETAIL_FAIL,
  CREATE_ASSET_ITEM_TRANSACTION,
  CREATE_ASSET_ITEM_TRANSACTION_SUCCESS,
  CREATE_ASSET_ITEM_TRANSACTION_FAIL,
  UPDATE_ASSET_ITEM_TRANSACTION,
  UPDATE_ASSET_ITEM_TRANSACTION_SUCCESS,
  UPDATE_ASSET_ITEM_TRANSACTION_FAIL,
  DELETE_ASSET_ITEM_TRANSACTION,
  DELETE_ASSET_ITEM_TRANSACTION_SUCCESS,
  DELETE_ASSET_ITEM_TRANSACTION_FAIL,
} from "./actionTypes"

export const getAssetItemTransactions = (assetItemId, searchText, page) => ({
  type: GET_ASSET_ITEM_TRANSACTIONS,
  payload: { assetItemId, searchText, page },
})
export const getAssetItemTransactionsSuccess = assetItemTransactions => ({
  type: GET_ASSET_ITEM_TRANSACTIONS_SUCCESS,
  payload: assetItemTransactions,
})
export const getAssetItemTransactionsFail = error => ({
  type: GET_ASSET_ITEM_TRANSACTIONS_FAIL,
  payload: error,
})

// export const getVarisangyaCards = () => ({
//   type: GET_VARISANGYA_CARDS,
// })
// export const getVarisangyaCardsSuccess = varisangyaCards => ({
//   type: GET_VARISANGYA_CARDS_SUCCESS,
//   payload: varisangyaCards,
// })
// export const getVarisangyaCardsFail = error => ({
//   type: GET_VARISANGYA_CARDS_FAIL,
//   payload: error,
// })

export const getAssetItemTransactionDetail = assetItemTransactionId => ({
  type: GET_ASSET_ITEM_TRANSACTION_DETAIL,
  assetItemTransactionId,
})

export const getAssetItemTransactionDetailSuccess =
  assetItemTransactionDetail => ({
    type: GET_ASSET_ITEM_TRANSACTION_DETAIL_SUCCESS,
    payload: assetItemTransactionDetail,
  })

export const getAssetItemTransactionDetailFail = error => ({
  type: GET_ASSET_ITEM_TRANSACTION_DETAIL_FAIL,
  payload: error,
})

export const createAssetItemTransaction = (assetItemTransaction, history) => ({
  type: CREATE_ASSET_ITEM_TRANSACTION,
  payload: { assetItemTransaction, history },
})

export const createAssetItemTransactionSuccess = assetItemTransaction => ({
  type: CREATE_ASSET_ITEM_TRANSACTION_SUCCESS,
  payload: assetItemTransaction,
})

export const createAssetItemTransactionFail = error => ({
  type: CREATE_ASSET_ITEM_TRANSACTION_FAIL,
  payload: error,
})

export const updateAssetItemTransaction = (
  assetItemTransaction,
  assetItemTransactionId,
  history
) => ({
  type: UPDATE_ASSET_ITEM_TRANSACTION,
  payload: { assetItemTransaction, assetItemTransactionId, history },
})

export const updateAssetItemTransactionSuccess = assetItemTransaction => ({
  type: UPDATE_ASSET_ITEM_TRANSACTION_SUCCESS,
  payload: assetItemTransaction,
})

export const updateAssetItemTransactionFail = error => ({
  type: UPDATE_ASSET_ITEM_TRANSACTION_FAIL,
  payload: error,
})

export const deleteAssetItemTransaction = (
  assetItemTransactionId,
  history
) => ({
  type: DELETE_ASSET_ITEM_TRANSACTION,
  payload: { assetItemTransactionId, history },
})

export const deleteAssetItemTransactionSuccess = assetItemTransaction => ({
  type: DELETE_ASSET_ITEM_TRANSACTION_SUCCESS,
  payload: assetItemTransaction,
})

export const deleteAssetItemTransactionFail = error => ({
  type: DELETE_ASSET_ITEM_TRANSACTION_FAIL,
  payload: error,
})
