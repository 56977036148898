import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label, Row } from "reactstrap"

import { updateClass, deleteClass } from "store/actions"

const UiModalClassUpdate = ({ show, onCloseclick, data }) => {
  const dispatch = useDispatch()

  const { loading, language } = useSelector(state => ({
    loading: state.Classes.loading,
    language: state.Layout.language,
  }))

  const classDetail = data

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const updateClassData = {
      class_name: values.class_name,
      institute: classDetail?.institute,
      // description: values.description,
    }
    // console.log(updateClassData);
    dispatch(updateClass(updateClassData, classDetail?.id, history))
  }

  const handleDeleteClass = id => {
    // console.log(`delete item ${id}`);
    dispatch(deleteClass(id))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Update Class</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "പേര്" : "Class Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="class_name"
                    type="text"
                    value={classDetail?.class_name}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              {/* <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    value={classDetail?.description}
                    label={language === 'mal' ? 'വിവരണം' : 'Description'}
                  />
                </Col>
              </div> */}
              <Row className="mt-5 d-flex">
                <div className="col-6">
                  <div className="row justify-content-start">
                    <div>
                      <Button
                        type="button"
                        color="danger"
                        className="w-md"
                        onClick={() => {
                          onCloseclick()
                          handleDeleteClass(classDetail?.id)
                        }}
                      >
                        {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md m-auto me-0 d-block"
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക " : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalClassUpdate
UiModalClassUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.object,
}
