import React, { useEffect, useState } from "react"
import { Col, Row, Card, CardBody, Container, Badge } from "reactstrap"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

//action
import { getDashboardCard, getUserDetail } from "store/actions"
import TermsAndConditionModal from "pages/common/Authentication/TermsAndConditionModal"
import masjid from "assets/images/masjid.png"
import { useGetRole } from "hooks/useHandleSessions"
// import EducationChart from 'components/Common/Charts/educationChart'
import HealthChart from "components/Common/Charts/healthChart"
import DiseaseChart from "components/Common/Charts/diseaseChart"
import GenderChart from "components/Common/Charts/genderChart"
// import JobChart from 'components/Common/Charts/jobChart'

function MahallUserDashboard() {
  const dispatch = useDispatch()

  const {
    userDetail,
    language,
    mahallUsersCardsLoading,
    familiesCardsLoading,
    dashboardCard,
  } = useSelector(state => ({
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    familiesCardsLoading: state.Families.loading,
    mahallUsersCardsLoading: state.MahallUsers.loading,
    dashboardCard: state.Dashboard.dashboardCard,
  }))

  const [modalTerms, setModalTerms] = useState(false)

  const { role } = useGetRole()

  useEffect(() => {
    if (!userDetail) dispatch(getUserDetail())
  }, [dispatch, userDetail])

  useEffect(() => {
    dispatch(getDashboardCard())
  }, [dispatch])

  const cardData = [
    {
      title: `${language === "mal" ? "മഹല്ല് യൂസറുകൾ" : "Mahall Users"}`,
      cardValue: dashboardCard?.mahallusers ?? 0,
      iconClass: "user",
      routeLink: "/mahallusers",
      loading: mahallUsersCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "സ്ഥാപന യൂസറുകൾ" : "Institute Users"}`,
      cardValue: dashboardCard?.institute_users ?? 0,
      iconClass: "user",
      routeLink: "/instituteusers",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Active Users"}`,
    },
    {
      title: `${language === "mal" ? "കുടുംബങ്ങൾ" : "Families"}`,
      cardValue: dashboardCard?.families ?? 0,
      iconClass: "home",
      routeLink: "/families",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Active Users"}`,
    },
    {
      title: `${language === "mal" ? "അംഗങ്ങൾ" : "Members"}`,
      cardValue: dashboardCard?.members ?? 0,
      iconClass: "group",
      routeLink: "/familymembers",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Active Users"}`,
    },
    {
      title: `${language === "mal" ? "സ്ഥാപനങ്ങൾ" : "Institutes"}`,
      cardValue: dashboardCard?.institutes ?? 0,
      iconClass: "building-house",
      routeLink: "/institutes",
      loading: mahallUsersCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Institutes"}`,
    },
    {
      title: `${language === "mal" ? "പ്രോഗ്രാമുകൾ" : "Programmes"}`,
      cardValue: dashboardCard?.programs ?? 0,
      iconClass: "grid-alt",
      routeLink: "/programes",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "കമ്മിറ്റികൾ" : "Committees"}`,
      cardValue: dashboardCard?.committees ?? 0,
      iconClass: "chat",
      routeLink: "/mahall/committees",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "യോഗങ്ങൾ" : "Meetings"}`,
      cardValue: dashboardCard?.committee_meetings ?? 0,
      iconClass: "group",
      routeLink: "/committee/meetings",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
  ]

  useEffect(() => {
    if (
      role === "mahall_user" &&
      userDetail?.mahall?.is_terms_agreed === false
    ) {
      setModalTerms(true)
    }
  }, [role])

  return (
    <Row>
      <TermsAndConditionModal
        show={modalTerms}
        onCloseclick={() => setModalTerms(false)}
      />
      <Container fluid>
        <Card className="main-card">
          <Row>
            <Col md="6" style={{ textAlign: "center" }}>
              <img src={masjid} className="img-demo" />
            </Col>
            <Col md="6">
              <div className="d-flex justify-content-end m-2">
                {["premium", "standard"].includes(
                  userDetail?.mahall?.version
                ) ? (
                  <Badge
                    className="me-1 badge-soft bg-warning text-white ms-1 "
                    style={{ fontSize: "13px" }}
                  >
                    {userDetail?.mahall?.version}
                  </Badge>
                ) : (
                  <Badge
                    className="me-1 badge-soft bg-primary text-white ms-1"
                    style={{ fontSize: "13px" }}
                  >
                    {userDetail?.mahall?.version}
                  </Badge>
                )}
              </div>

              <div className="main-div">
                <h3 className="mahall-name">
                  {language === "mal"
                    ? userDetail?.mahall?.mahall_malayalam_name
                      ? userDetail?.mahall?.mahall_malayalam_name
                      : userDetail?.mahall?.mahall_english_name
                    : userDetail?.mahall?.mahall_english_name}
                </h3>
                {userDetail &&
                  userDetail?.mahall &&
                  userDetail?.mahall?.established_date && (
                    <p className="mahall-date text-muted">
                      {language === "mal" ? "" : "Since "}{" "}
                      {moment(userDetail?.mahall?.established_date).format(
                        "DD/MM/yyyy"
                      )}{" "}
                      {language === "mal" ? " മുതൽ" : ""}
                    </p>
                  )}
                <p className="mahall-regNo">
                  <span className="h5 text-black">
                    {" "}
                    {language === "mal" ? "കോഡ് :" : "Code :"}{" "}
                  </span>{" "}
                  <span className="text-khaf h5">
                    {userDetail?.mahall?.referral_code
                      ? userDetail?.mahall?.referral_code
                      : ""}
                  </span>
                </p>
                <div className="address-div">
                  <p className="address-p">
                    {`${
                      userDetail?.mahall?.lsg_english_name
                        ? userDetail?.mahall?.lsg_english_name
                        : ""
                    } ${
                      userDetail?.mahall?.lsg_type_english
                        ? userDetail?.mahall?.lsg_type_english
                        : ""
                    }, `}
                    {userDetail?.mahall?.village_english_name
                      ? userDetail?.mahall?.village_english_name
                      : ""}
                    ,
                    {`${
                      userDetail?.mahall?.post_office
                        ? userDetail?.mahall?.post_office
                        : ""
                    }-${
                      userDetail?.mahall?.pin_code
                        ? userDetail?.mahall?.pin_code
                        : ""
                    }, `}
                    {userDetail?.mahall?.district_english_name
                      ? userDetail?.mahall?.district_english_name
                      : ""}
                    ,
                    {userDetail?.mahall?.state_english_name
                      ? userDetail?.mahall?.state_english_name
                      : ""}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>

      <Row className="mdrow">
        {cardData?.map((item, key) => (
          <Col sm="12" md="3" lg="3" key={key} className="sm:p-0">
            <Card className="blog-stats-wid">
              <CardBody style={{ padding: "18px" }}>
                <Link to={item.routeLink && item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="avatar-sm">
                      <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                    <div className="mx-4 pt-2">
                      <p className="text-muted m-0">{item.title}</p>
                      {item.cardValue >= 0 && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Col lg={6}>
        <HealthChart />
      </Col>
      <Col lg={6}>
        <GenderChart />
      </Col>
      {/* <Col lg={4}>
        <EducationChart />
      </Col> */}
      <Col lg={12}>
        <DiseaseChart />
      </Col>
      {/* <Col lg={6}>
        <JobChart />
      </Col> */}
    </Row>
  )
}

export default MahallUserDashboard
