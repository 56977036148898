import {
  GET_INSTITUTE_USERS_SUCCESS,
  GET_INSTITUTE_USERS_FAIL,
  GET_INSTITUTE_USER_DETAIL_SUCCESS,
  GET_INSTITUTE_USER_DETAIL_FAIL,
  CREATE_INSTITUTE_USER_SUCCESS,
  CREATE_INSTITUTE_USER_FAIL,
  UPDATE_INSTITUTE_USER_SUCCESS,
  UPDATE_INSTITUTE_USER_FAIL,
  DELETE_INSTITUTE_USER_SUCCESS,
  DELETE_INSTITUTE_USER_FAIL,
  GET_INSTITUTE_USERS,
  GET_INSTITUTE_USER_DETAIL,
  UPDATE_INSTITUTE_USER,
  CREATE_INSTITUTE_USER,
  DELETE_INSTITUTE_USER,
} from "./actionTypes"

const INIT_STATE = {
  instituteUsers: [],
  instituteUserDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdInstituteUser: {},
}

const InstituteUsers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INSTITUTE_USERS:
    case UPDATE_INSTITUTE_USER:
    case CREATE_INSTITUTE_USER:
    case DELETE_INSTITUTE_USER:
      return {
        ...state,
        loading: true,
      }
    case GET_INSTITUTE_USER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_INSTITUTE_USERS_SUCCESS:
      return {
        ...state,
        instituteUsers: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_INSTITUTE_USER_SUCCESS:
      return {
        ...state,
        instituteUsers: action.payload,
        createdInstituteUser: action.payload,
        loading: false,
      }

    case CREATE_INSTITUTE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_USER_DETAIL_SUCCESS:
      return {
        ...state,
        instituteUserDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_INSTITUTE_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_INSTITUTE_USER_SUCCESS:
      return {
        ...state,
        instituteUserDetail: action.payload,
        loading: false,
      }

    case UPDATE_INSTITUTE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_INSTITUTE_USER_SUCCESS:
      return {
        ...state,
        // instituteUsers: state.instituteUsers.filter(
        //   instituteUser => instituteUser.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_INSTITUTE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default InstituteUsers
