import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import axios from "axios"
import Select from "react-select"
//actions
import { Image } from "react-bootstrap"

import { API_URL } from "helpers/api_methods"
import {
  createIndividualNotificationSuccess,
  createIndividualNotificationFail,
  getAllFamilyMembersDropdown,
} from "store/actions"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const CreateIndividualNotification = ({ history }) => {
  const dispatch = useDispatch()

  const { loading, language, allFamilyMembers } = useSelector(state => ({
    loading: state.Mahalls.loading,
    error: state.Mahalls.error,
    language: state.Layout.language,
    allFamilyMembers: state.FamilyMembers.allFamilyMembers,
  }))

  const [searchText, setSearchText] = useState("")
  const [familyMember, setFamilyMember] = useState("Select member...")
  const [familyMemberId, setFamilyMemberId] = useState()
  // const [familyMembers, setFamilyMembers] = useState([])
  // const [selectedMultiFamilyMembes, setSelectedMultiFamilyMembers] = useState(null)

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchText))
  }, [dispatch, searchText])

  const optionGroup = [
    {
      options: allFamilyMembers?.map((result, index) => ({
        key: index,
        label: `${result?.full_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        })`,
        // result?.full_name,
        // <>
        //   {result?.full_name}
        //   {result?.mahall_custom_id ? (
        //     <Badge className="bg-khaf-blue mx-2">
        //       {result?.mahall_custom_id}
        //     </Badge>
        //   ) : (
        //     <Badge className="bg-khaf-blue mx-2">
        //       {result?.custom_id}
        //     </Badge>
        //   )}
        // </>,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setFamilyMemberId(event.value)
    setFamilyMember(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  // const optionGroupMember = () => {
  //   const optionGroup = allFamilyMembers?.map(item => {
  //     return {
  //       value: item.id,
  //       label: item?.full_name,
  //     }
  //   })
  //   return optionGroup;
  // }

  // function handleMultiMember(memberData, setSelectedMultiMember, setMembers) {
  //   setSelectedMultiMember(memberData)
  //   const arrangedMembers = memberData.map(a => a.value)
  //   setMembers(arrangedMembers)
  // }

  // const addFamilyMember = (mal, en, selectedMultiMember, setSelectedMultiMember, setMembers) => {
  //   return (
  //     allFamilyMembers &&
  //     allFamilyMembers?.length > 0 && (
  //       <Col md="12" className="mb-3">
  //         <Label>
  //           {
  //             language === "mal" ? mal : en
  //           }
  //         </Label>
  //         <div className="mb-3">
  //           <Select
  //             value={selectedMultiMember}
  //             isMulti={true}
  //             onChange={e => {
  //               handleMultiMember(e, setSelectedMultiMember, setMembers)
  //             }}
  //             options={optionGroupMember()}
  //             classNamePrefix="select2-selection"
  //           />
  //         </div>
  //       </Col>
  //     )
  //   )
  // }

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })

  const [imagePreview, setImagePreview] = useState()

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    // const data = {
    //   family_member: familyMembers
    // }

    // console.log(data);

    setCustomLoad(true)
    createIndividualNotification(
      selectedFile,
      familyMemberId,
      values,
      dispatch,
      history,
      setCustomLoad
    )
    setImagePreview()
  }

  const changeHandler = e => {
    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <>
      <MetaTags>
        <title>Notifications | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "അറിയിപ്പുകൾ" : "Notifications"}
          breadcrumbItem={
            language === "mal" ? "പുതിയത്  ചേർക്കുക" : "Create Notification"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      {/* {addFamilyMember('അംഗങ്ങൾ', 'Members', selectedMultiFamilyMembes, setSelectedMultiFamilyMembers, setFamilyMembers)} */}
                      <div className="row mb-3">
                        <Col sm="12" md="6">
                          <AvField
                            name="sender"
                            type="text"
                            label={language === "mal" ? "നിന്ന്" : "From"}
                          />
                        </Col>
                        <Col className="mb-3" sm="12" md="6">
                          <Label>
                            {language === "mal" ? "ലേക്ക്" : "To"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <Select
                            onInputChange={handleEnters}
                            placeholder={familyMember}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="Family Member"
                            required="required"
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <Label>
                            {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="title"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-3">
                        <Col className="mb-3">
                          <AvField
                            name="sub_title"
                            type="text"
                            label={
                              language === "mal" ? "സബ്‌ടൈറ്റിൽ" : "Subtitle"
                            }
                          />
                        </Col>
                      </div>

                      {/* <div className="row mb-1  ">
                        <Col sm="12" md="6" className="mb-3">
                          <AvField
                            name="datetime"
                            type="date"
                            label={language === "mal" ? "തീയതി" : "Date"}
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <AvField
                            name="sender"
                            type="text"
                            label={language === "mal" ? "അയച്ചയാൾ" : "Sender"}
                          />
                        </Col>
                      </div> */}

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="description"
                            type="textarea"
                            label={
                              language === "mal" ? "വിവരണം" : "Description"
                            }
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <Label>
                            {" "}
                            {language === "mal" ? "ഫോട്ടോ" : "Image"}{" "}
                          </Label>
                          <AvField
                            name="image"
                            type="file"
                            onChange={changeHandler}
                          />
                          {imagePreview && (
                            <Image
                              style={{ width: "150px", height: "150px" }}
                              className="my-2"
                              src={imagePreview.image}
                            />
                          )}
                        </Col>
                      </div>

                      <div className="d-flex justify-content-end">
                        <Button type="submit" color="success" className="w-md">
                          {loading ||
                            (customLoad && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            ))}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateIndividualNotification

CreateIndividualNotification.propTypes = {
  history: PropTypes.object,
}

function createIndividualNotification(
  selectedFile,
  familyMemberId,
  values,
  dispatch,
  history,
  setCustomLoad
) {
  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append("image", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("family_member", familyMemberId)
  formData.append("title", values.title)
  formData.append("sub_title", values.sub_title)
  // formData.append("datetime", values.datetime)
  formData.append("sender", values.sender)
  formData.append("description", values.description)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .post(`${API_URL}/api/v1/notification/individual_notification/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      dispatch(createIndividualNotificationSuccess(res.data))
      history.push("/notifications")
      doneNotification("Created Successfully")
      setCustomLoad(false)
    })
    .catch(err => {
      createIndividualNotificationFail(err)
      errorNotification()
      setCustomLoad(false)
    })
}
