import {
  GET_LEDGER_ITEMS,
  UPDATE_LEDGER_ITEM,
  CREATE_LEDGER_ITEM,
  DELETE_LEDGER_ITEM,
  GET_LEDGER_ITEM_DETAIL,
  GET_LEDGER_ITEMS_SUCCESS,
  GET_LEDGER_ITEMS_FAIL,
  CREATE_LEDGER_ITEM_SUCCESS,
  CREATE_LEDGER_ITEM_FAIL,
  GET_LEDGER_ITEM_DETAIL_SUCCESS,
  GET_LEDGER_ITEM_DETAIL_FAIL,
  UPDATE_LEDGER_ITEM_SUCCESS,
  UPDATE_LEDGER_ITEM_FAIL,
  DELETE_LEDGER_ITEM_SUCCESS,
  DELETE_LEDGER_ITEM_FAIL,
  GET_LEDGER_ITEMS_FOR_DOWNLOAD,
  GET_LEDGER_ITEMS_FOR_DOWNLOAD_SUCCESS,
  GET_LEDGER_ITEMS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  ledgerItems: [],
  ledgerItemsForDownload: [],
  ledgerItemDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdLedgerItem: {},
}

const LedgerItems = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEDGER_ITEMS:
    case GET_LEDGER_ITEMS_FOR_DOWNLOAD:
    case UPDATE_LEDGER_ITEM:
    case CREATE_LEDGER_ITEM:
    case DELETE_LEDGER_ITEM:
      return {
        ...state,
        loading: true,
      }
    case GET_LEDGER_ITEM_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_LEDGER_ITEMS_SUCCESS:
      return {
        ...state,
        ledgerItems: action.payload,
        loading: false,
      }

    case GET_LEDGER_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_LEDGER_ITEMS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        ledgerItemsForDownload: action.payload,
        loading: false,
      }

    case GET_LEDGER_ITEMS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_LEDGER_ITEM_SUCCESS:
      return {
        ...state,
        // ledgerItems: action.payload,
        ledgerItems: {
          ...state.ledgerItems,
          results: {
            ...state.ledgerItems.results,
            items: [...state.ledgerItems.results.items, action.payload],
          },
        },
        createdLedgerItem: action.payload,
        loading: false,
      }

    case CREATE_LEDGER_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_LEDGER_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        ledgerItemDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_LEDGER_ITEM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_LEDGER_ITEM_SUCCESS:
      return {
        ...state,
        // ledgerItemDetail: action.payload,
        ledgerItems: {
          results: {
            ...state.ledgerItems,
            items: state.ledgerItems.results.items?.map(item =>
              item.id.toString() === action.payload.id.toString()
                ? { item, ...action.payload }
                : item
            ),
          },
        },
        loading: false,
      }

    case UPDATE_LEDGER_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_LEDGER_ITEM_SUCCESS:
      return {
        ...state,
        ledgerItems: {
          results: {
            items: state.ledgerItems.results.items.filter(
              ledgerItem => ledgerItem.id !== action.payload
            ),
          },
        },
        loading: false,
      }

    case DELETE_LEDGER_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default LedgerItems
