import {
  GET_COUNTRIES,
  UPDATE_COUNTRY,
  CREATE_COUNTRY,
  DELETE_COUNTRY,
  GET_COUNTRY_DETAIL,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  CREATE_COUNTRY_SUCCESS,
  CREATE_COUNTRY_FAIL,
  GET_COUNTRY_DETAIL_SUCCESS,
  GET_COUNTRY_DETAIL_FAIL,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAIL,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  countries: [],
  countryDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdCountry: {},
}

const Countries = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
    case UPDATE_COUNTRY:
    case CREATE_COUNTRY:
    case DELETE_COUNTRY:
      return {
        ...state,
        loading: true,
      }
    case GET_COUNTRY_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loading: false,
      }

    case GET_COUNTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_COUNTRY_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdCountry: action.payload,
        loading: false,
      }

    case CREATE_COUNTRY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COUNTRY_DETAIL_SUCCESS:
      return {
        ...state,
        countryDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_COUNTRY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDetail: action.payload,
        loading: false,
      }

    case UPDATE_COUNTRY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        countries: {
          results: state.countries.results.filter(
            country => country.id !== action.payload
          ),
        },
        // states: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_COUNTRY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Countries
