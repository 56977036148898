import React, { Fragment, useEffect, useState } from "react"
import { Col, Row, Card, CardBody, Spinner } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
//actions
//componetns
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { debounce, map, range } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getStudentAttendanceDetail } from "store/actions"

const InstituteStudentAttendanceDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()

  const divisionId = location?.search?.split("?divisionid=")[1]

  const { studentAttendanceDetail, language, loading } = useSelector(state => ({
    loading: state.StudentAttendances.loading,
    studentAttendanceDetail: state.StudentAttendances.studentAttendanceDetail,
    language: state.Layout.language,
  }))

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  // const limit = 10

  const totalPages = Math.ceil(studentAttendanceDetail?.absents_count / 10)
  const pages = range(1, totalPages + 1)

  // const pageSend = () => {
  //   if (page >= pages.length) {
  //     return pages.length
  //   }
  //   if (page < 1) {
  //     return 1
  //   } else {
  //     return page
  //   }
  // }

  const columns = [
    // {
    //   dataField: 'no',
    //   text: `${language === 'mal' ? 'നമ്പർ' : 'No.'}`,
    // },
    {
      dataField: "student__roll_no",
      text: `${language === "mal" ? "റോൾ നം." : "Roll No."}`,
    },
    {
      dataField: "student__name",
      text: `${language === "mal" ? "പേര്" : "Student Name"}`,
    },
    {
      dataField: "periods",
      text: `${language === "mal" ? "പിരിയഡുകൾ" : "Periods"}`,
    },
    // {
    //   dataField: 'attendance_percentage',
    //   text: `${language === 'mal' ? 'ഹാജർ %' : 'Attendance %'}`,
    // },
    // {
    //   dataField: 'action',
    //   text: `${language === 'mal' ? 'പ്രവർത്തനങ്ങൾ' : 'Actions'}`,
    // },
  ]

  const CommitteeMeetingsData = map(
    studentAttendanceDetail?.absents,
    (item, index) => ({
      ...item,
      key: index,
      // no: (pageSend() - 1) * limit + index + 1,
      meeting_date:
        item.meeting_date && moment(item.meeting_date).format("DD-MM-yyyy"),
      periods: item?.periods?.map((period, key) => (
        <Fragment key={key}>
          {period?.period__period_no}
          {item?.periods?.length > 1 ? " , " : ""}
        </Fragment>
      )),
      // action: (
      //   <div>
      //     <Link
      //       to={`/committee/meetings/${item?.id}`}
      //       className="btn-success btn-sm"
      //     >
      //       {language === 'mal' ? 'കാണുക' : 'View'}
      //     </Link>
      //   </div>
      // ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMeetingSearch = debounce(value => setSearchText(value), 600)

  useEffect(() => {
    dispatch(getStudentAttendanceDetail(params?.id, divisionId))
  }, [dispatch, params?.id, divisionId])

  return (
    <>
      <MetaTags>
        <title>Attendance List | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഹാജർ വിശദാംശങ്ങൾ" : "Attendance Details"}
          breadcrumbItem={language === "mal" ? "ഹാജർ" : "Attendance"}
        />
        <Row>
          <Col className="col-8 mt-2">
            <Card>
              <CardBody>
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={CommitteeMeetingsData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <form
                                className="app-search d-lg-block"
                                onChange={e =>
                                  debounceMeetingSearch(e.target.value)
                                }
                              >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={
                                      language === "mal"
                                        ? "തിരയുക..."
                                        : "Search..."
                                    }
                                    defaultValue={searchText}
                                  />
                                  <span className="bx bx-search-alt" />
                                </div>
                              </form>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={"table align-middle table-wrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <MyPagination
                            totalPages={pages}
                            page={page}
                            setPage={setPage}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default InstituteStudentAttendanceDetails

InstituteStudentAttendanceDetails.propTypes = {
  history: PropTypes.object,
}
