import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import { MdDelete } from "react-icons/md"
//actions
//componets
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import {
  // debounce,
  map,
  range,
} from "lodash"
import moment from "moment"

import UiModalAddFamilyVarisangyaArrear from "./UiModalAddFamilyVarisangyaArrear"

import MyPagination from "components/Common/MyPagination"
import {
  getFamilyDropdown,
  createFamilyVarisangyaWallet,
  getFamilyDetail,
  getFamilyVarisangyaWalletDetail,
  clearFamilyVarisangyaWalletDetail,
  deleteFamilyVarisangyaWallet,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useWindowSize } from "react-use"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { Html5QrcodeScanner } from "html5-qrcode"

const CreateFamilyVarisangyaTransaction = ({ history }) => {
  const dispatch = useDispatch()
  const searchText = ""
  const sort = "manual"
  const [page, setPage] = useState(1)
  const limit = 10
  const [modalAddArrear, setModalAddArrear] = useState(false)
  const { width, height } = useWindowSize()

  const {
    loading,
    language,
    searchFamilies,
    familyDetail,
    familyVarisangyaWalletDetail,
    tableLoading,
  } = useSelector(state => ({
    loading: state.FamilyVarisangyaWallets.loading,
    language: state.Layout.language,
    searchFamilies: state.Families.searchFamilies,
    familyDetail: state.Families.familyDetail,
    familyVarisangyaWalletDetail:
      state.FamilyVarisangyaWallets.familyVarisangyaWalletDetail,
    tableLoading: state.FamilyVarisangyaWallets.tableLoading,
  }))

  const totalPages = Math.ceil(familyVarisangyaWalletDetail?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const [searchTextFamily, setSearchTextFamily] = useState("")
  const [selectedFamily, setSelectedFamily] = useState("Choose Family")
  const [familyId, setFamilyId] = useState("")

  useEffect(() => {
    dispatch(getFamilyDropdown(searchTextFamily))
  }, [searchTextFamily, dispatch])

  useEffect(() => {
    dispatch(clearFamilyVarisangyaWalletDetail())
  }, [])

  function handlerFinalValueFamily(event) {
    // dispatch(getFamilyDropdown(event.value))
    setFamilyId(event.value)
    setSelectedFamily(event.label && event.family_head_name)
  }

  const optionGroupFamily = [
    {
      options: searchFamilies?.map((result, index) => ({
        key: index,
        label: `${result?.house_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        }) 
                 ${
                   result?.family_head_name
                     ? "- " + result?.family_head_name
                     : ""
                 }`,
        // <div>
        //   {results?.house_name}
        //   {results?.mahall_custom_id ? (
        //     <Badge className="bg-khaf-blue mx-2">
        //       {results?.mahall_custom_id}
        //     </Badge>
        //   ) : results?.family_head_name ? (
        //     <span className="text-khaf">
        //       {" "}({results?.family_head_name})
        //     </span>
        //   ) : (
        //     <Badge className="bg-khaf-blue mx-2">
        //       {results?.custom_id}
        //     </Badge>
        //   )}
        // </div>,
        value: result?.id,
      })),
    },
  ]

  const handleEntersFamily = textEntered => {
    setSearchTextFamily(textEntered)
  }

  useEffect(() => {
    if (familyId) {
      dispatch(getFamilyDetail(familyId))
      dispatch(
        getFamilyVarisangyaWalletDetail(familyId, page, searchText, sort)
      )
    }
  }, [dispatch, familyId, page, searchText])

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const createFamilyVarisangyaWalletData = {
      family: familyId,
      amount: values.amount ? parseInt(values.amount) : 0,
      trn_type: "credit",
      remark: values.remark,
    }
    // console.log(createVarisangyaData);
    if (familyId && parseInt(values.amount) > 0)
      dispatch(
        createFamilyVarisangyaWallet(createFamilyVarisangyaWalletData, history)
      )
  }

  // const handleVarisangyaArrearAdd = (onSubmitProps, values) => {
  //   const createFamilyVarisangyaWalletData = {
  //     family: familyId,
  //     amount: values.amount ? parseInt(values.amount) : 0,
  //     trn_type: 'debit',
  //     remark: values.remark ? values.remark : 'Arrears',
  //   }
  //   // console.log(createVarisangyaData);
  //   dispatch(
  //     createFamilyVarisangyaWallet(createFamilyVarisangyaWalletData, history),
  //   )
  // }

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "date",
      text: language === "mal" ? "തീയതി" : "Date",
    },
    {
      dataField: "amount",
      text: language === "mal" ? "തുക" : "Amount",
    },
    {
      dataField: "balance",
      text: language === "mal" ? "ബാലൻസ്" : "Balance",
    },
    {
      dataField: "remark",
      text: language === "mal" ? "റിമാർക്കുകൾ" : "Remarks",
    },
    {
      dataField: "action",
      text: language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    },
  ]

  const familyVarisangyaTransactionsData = map(
    familyVarisangyaWalletDetail?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,
      amount: (
        <p
          className={
            item?.trn_type === "credit" ? "text-success" : "text-danger"
          }
        >
          {item?.amount}
        </p>
      ),
      balance: <p className="text-primary">{item?.balance}</p>,
      date: item?.date_added && moment(item?.date_added).format("DD/MM/yyyy"),
      action:
        item?.remark === "wrong entry!" ? (
          ""
        ) : (
          <Button
            color="light"
            type="button"
            className="d-flex align-items-center justify-content-center "
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(deleteFamilyVarisangyaWallet(item?.id))}
          >
            <MdDelete color="red" size={"14px"} />
          </Button>
        ),
      // action: (
      //   <div className="m-0">
      //     <Link to={`/varisangya/update/${item?.id}`} className="m-0">
      //       <Button
      //         type="button"
      //         color="primary"
      //         className=" btn w-xs btn-sm"
      //       >
      //         {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
      //         {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
      //       </Button>
      //     </Link>
      //   </div>
      // ),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  // const debouncefamilyVarisangyaWalletSearch = debounce(
  //   value => setSearchText(value),
  //   600,
  // )

  // scanner

  useEffect(() => {
    function onScanSuccess(decodedText, decodedResult) {
      doneNotification("successfully completed")
      console.log(`Scan result: ${decodedText}`, decodedResult)
      console.log(`Scan result:`, decodedText)
      const FinalValue = JSON.parse(decodedText)
      console.log(FinalValue)
      html5QrcodeScanner.clear()

      if (FinalValue) {
        setFamilyMemberId(FinalValue?.id)
      }
    }

    function onScanError(errorMessage) {
      errorNotification("Show the Qr Infrond of camera ")
    }

    const html5QrcodeScanner = new Html5QrcodeScanner("reader", {
      fps: 10,
      qrbox: 250,
    })

    html5QrcodeScanner.render(onScanSuccess, onScanError)

    return () => {
      // Cleanup when the component unmounts
      if (html5QrcodeScanner) {
        html5QrcodeScanner.clear()
      }
    }
  }, [])

  return (
    <>
      <UiModalAddFamilyVarisangyaArrear
        show={modalAddArrear}
        onCloseclick={() => setModalAddArrear(false)}
        data={familyDetail}
      />
      <MetaTags>
        <title>Varisangya | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "വരിസംഖ്യ" : "Varisangya"}
          breadcrumbItem={language === "mal" ? "ചേർക്കുക" : "Entry"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4 mb-4">Fill this form</CardTitle> */}

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="col-md-12">
                        <div className={`col ${familyId ? "mb-2" : "mb-4"}`}>
                          <Label>
                            {language === "mal" ? "കുടുംബം" : "Family"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <Col sm={12}>
                            <Select
                              onInputChange={handleEntersFamily}
                              placeholder={selectedFamily}
                              value={selectedFamily}
                              options={optionGroupFamily}
                              classNamePrefix="select2-selection"
                              onChange={handlerFinalValueFamily}
                              type="text"
                              title="Family"
                              required="required"
                            />
                          </Col>
                        </div>
                      </div>
                      {width >= 420 ? (
                        <div id="reader" style={{ width: "555px" }}>
                          <Button>Click to scan</Button>
                        </div>
                      ) : (
                        <div id="reader" style={{ width: "323px" }}>
                          <Button>Click to scan</Button>
                        </div>
                      )}
                      {familyId && (
                        <Row className="mt-2">
                          <Col md="12">
                            <Card style={{ backgroundColor: "#e6fcee" }}>
                              <CardBody>
                                <h5 className="font-size-13 text-primary font-weight-bold">
                                  {language === "mal"
                                    ? familyDetail?.area?.malayalam
                                    : familyDetail?.area?.english}
                                </h5>

                                <div className="d-flex">
                                  <h5 className="font-size-22 font-weight-bold mt-1">
                                    {familyDetail?.house_name}
                                  </h5>
                                </div>
                                <Badge
                                  className="bg-primary"
                                  style={{ fontSize: "12px" }}
                                >
                                  {language === "mal" ? "അംഗങ്ങൾ" : "Members"} :{" "}
                                  {familyDetail?.no_of_members}
                                </Badge>
                                <Badge
                                  className="bg-primary mx-2 bg-success"
                                  style={{ fontSize: "12px" }}
                                >
                                  {language === "mal"
                                    ? "മഹല്ല് ഐഡി"
                                    : "Mahall ID"}{" "}
                                  : {familyDetail?.mahall_custom_id}
                                </Badge>
                                <div className="text-muted mt-1 d-flex">
                                  <h5 className="font-size-12 text-muted font-weight-bold mt-1">
                                    {familyDetail?.house_number}/
                                    {familyDetail?.ward_number}
                                  </h5>

                                  <h5 className="font-size-12 text-muted font-weight-bold mt-1">
                                    -{" "}
                                    {language === "mal"
                                      ? familyDetail?.lsg_malayalam_name
                                      : familyDetail?.lsg_english_name}
                                  </h5>
                                </div>
                                <p className="mb-1 ">
                                  <i className="bx bx-map-pin text-primary me-1" />{" "}
                                  {familyDetail?.place},{" "}
                                  {familyDetail?.post_office} Post -
                                  {familyDetail?.pin_code},<br />{" "}
                                  <span className="px-1 mx-3">
                                    {language === "mal"
                                      ? familyDetail?.district_malayalam_name
                                      : familyDetail?.district_english_name}
                                    ,{" "}
                                    {language === "mal"
                                      ? familyDetail?.state_malayalam_name
                                      : familyDetail?.state_english_name}
                                  </span>
                                </p>

                                <p className="mb-0">
                                  <i className="bx bx-phone text-primary me-1" />{" "}
                                  {familyDetail?.contact_number || ""}
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <div className="col-md-12 mt-2">
                          <div className="col">
                            <Row>
                              <Col sm={4}>
                                <AvField
                                  id="horizontal-firstname-Input"
                                  name="amount"
                                  type="number"
                                  label={language === "mal" ? "തുക" : "Amount"}
                                />
                              </Col>
                              <Col sm={8}>
                                <AvField
                                  id="horizontal-firstname-Input"
                                  name="remark"
                                  type="text"
                                  label={
                                    language === "mal" ? "വിവരണം" : "Remarks"
                                  }
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Row>

                      <div className="d-flex justify-content-end mt-5">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              {familyId && (
                <Col lg={6}>
                  {familyId && (
                    <>
                      {/* <Button
                    onClick={() => setModalAddArrear(true)}
                    style={{ width: language === 'mal' ? "170px" : "150px" }}
                    className="btn btn-danger m-auto mb-2 me-0 d-block"
                  >
                    <i className={"bx bx-calendar-minus mx-1"}></i>
                    {language === 'mal' ? "കുടിശ്ശിക ചേർക്കൽ" : "Add Arrears"}
                  </Button> */}
                      {/* <Button
                    onClick={() => setModalAddArrear(true)}
                    style={{ width: language === 'mal' ? "170px" : "150px" }}
                    className="btn btn-success m-auto mb-2 me-0 d-block"
                  >
                    <i className={"bx bx-calendar-minus mx-1"}></i>
                    {language === 'mal' ? "കുടിശ്ശിക ചേർക്കൽ" : "Add Transaction"}
                  </Button> */}
                    </>
                  )}
                  <Card>
                    {familyId && (
                      <Col className="pt-3 px-3">
                        <Row className="d-flex align-items-center">
                          <Col>
                            <p className="text-">
                              {language === "mal"
                                ? "വാലറ്റ് ബാലൻസ് : "
                                : "Wallet Balance :"}{" "}
                              <span className="fw-bold ">
                                {familyId && familyDetail?.varisankya_wallet}
                              </span>
                            </p>
                          </Col>
                          <Col>
                            <Button
                              onClick={() => setModalAddArrear(true)}
                              style={{
                                width: language === "mal" ? "170px" : "150px",
                              }}
                              className="btn btn-danger m-auto me-0 d-block"
                            >
                              <i className={"bx bx-calendar-minus mx-1"}></i>
                              {language === "mal"
                                ? "കുടിശ്ശിക ചേർക്കൽ"
                                : "Add Arrears"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {familyId && <hr />}
                    <CardTitle className="mx-3 mt-3">
                      {language === "mal" ? "ഇടപാടുകൾ :" : "Transactions :"}
                    </CardTitle>
                    <CardBody>
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={familyVarisangyaTransactionsData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            {tableLoading ? (
                              <Spinner
                                color="secondary"
                                className="d-block m-auto"
                              />
                            ) : (
                              <>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-wrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                {(!familyVarisangyaWalletDetail?.results ||
                                  familyVarisangyaWalletDetail?.results
                                    ?.length <= 0) && (
                                  <p
                                    style={{ fontSize: "15px" }}
                                    className="text-center text-info"
                                  >
                                    {language === "mal"
                                      ? "ഇടപാടുകൾ ഒന്നും ഇല്ല"
                                      : "No Transactions Yet"}
                                  </p>
                                )}
                                <MyPagination
                                  totalPages={pages}
                                  page={page}
                                  setPage={setPage}
                                />
                              </>
                            )}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateFamilyVarisangyaTransaction

CreateFamilyVarisangyaTransaction.propTypes = {
  history: PropTypes.object,
}
