import React, { useState, useEffect, useRef } from "react"
import { Button, Container, Row, Spinner } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
//actions
import moment from "moment"
import { MetaTags } from "react-meta-tags"

import { getNikahRegistrationDetail } from "store/actions"
import LetterHead from "components/Common/Letterhead/letterHead"
import Breadcrumbs from "components/Common/Breadcrumb"
import NikahCertificateDownloadPdf from "pages/mahallUser/PdfDownloads/nikahCertificatePdf"

const NikahCertificate = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const pdfRef = useRef()

  const nikkahId = location?.search?.split("?nikkahid=")[1]

  const { nikahRegistrationDetail, loading, language, userDetail } =
    useSelector(state => ({
      loading: state.NikahRegistrations.loading,
      detailLoading: state.MahallUsers.detailLoading,
      nikahRegistrationDetail: state.NikahRegistrations.nikahRegistrationDetail,
      language: state.Layout.language,
      userDetail: state.Users.userDetail,
    }))

  const [activeTab, setActiveTab] = useState(1)

  const downloadPdf = () => {
    const input = pdfRef.current
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL(`${userDetail?.mahall?.logo}`)
      const pdf = new jsPDF("p", "mm", "a4", true)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2
      const imgY = 30
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      )
      pdf.save("nikah.pdf")
    })
  }

  const today = moment(new Date()).format("DD-MM-yyyy")

  useEffect(() => {
    dispatch(getNikahRegistrationDetail(nikkahId))
  }, [])

  // function downloadPDF() {
  //   var doc = new jsPDF("p", "pt", "a4")
  //   // const windowWidth = document.documentElement.clientWidth;
  //   // const windowHeight = document.documentElement.clientHeight;
  //   const html = document.querySelector("#pdf-content")

  //   doc.html(html, {
  //     callback: function () {
  //       doc.save("window.pdf")
  //     },
  //     x: 0,
  //     y: 0,
  //   })
  //   // pdfdoc.fromHTML($('#pdf-content').html(), 10, 10, '',
  //   //   function (callback) { pdfdoc.save('First.pdf'); });

  //   // const doc = new jsPDF();
  //   // const windowWidth = document.documentElement.clientWidth;
  //   // const windowHeight = document.documentElement.clientHeight;
  //   // const html = document.querySelector('#pdf-content');

  //   // doc.html(html, {
  //   //   callback: function () {
  //   //     doc.save('window.pdf');
  //   //   },
  //   //   x: 0,
  //   //   y: 0,
  //   // });
  // }

  // function generatePDF() {
  //   const doc = new jsPDF();
  //   doc.text('Hello, World!', 10, 10);
  //   doc.save('my-document.pdf');
  // }

  // const onButtonClick = () => {
  //   // using Java Script method to get PDF file
  //   fetch(pdfFile).then(response => {
  //     response.blob().then(blob => {
  //       console.log(blob);
  //       // Creating new object of PDF file
  //       const fileURL = window.URL.createObjectURL(blob);
  //       console.log("url", fileURL);
  //       // Setting various property values
  //       let alink = document.createElement('a');
  //       alink.href = fileURL;
  //       alink.download = pdfFile;
  //       alink.click();
  //     })
  //   })
  // }

  // const downloadPDF = () => {
  //   const capture = document.querySelector('.actual-receipt');
  //   html2canvas(capture).then((canvas) => {
  //     const imgData = canvas.toDataURL('img/png');
  //     const doc = new jsPDF('p', 'mm', 'a4');
  //     const componentWidth = doc.internal.pageSize.getWidth();
  //     const componentHeight = doc.internal.pageSize.getHeight();
  //     doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
  //     doc.save('receipt.pdf');
  //   })
  // }

  // function generatePDF() {
  //   const html = document.documentElement.innerHTML;
  //   const documentDefinition = { content: html };
  //   const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  //   return pdfDocGenerator;
  // }

  // function downloadPDF() {
  //   const pdfDocGenerator = generatePDF();
  //   pdfDocGenerator.getBlob((blob) => {
  //     saveAs(blob, 'my-pdf-file.pdf');
  //   });
  // }

  return (
    <>
      <MetaTags>
        <title>Nikah Certificate | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <NikahCertificateDownloadPdf pdfRef={pdfRef} activeTab={activeTab} />
        <Breadcrumbs title="Nikkah" breadcrumbItem="Certificate" />
        <Container fluid>
          <div className="container-fluid">
            <div
              className="btn-group btn-group-certificate mt-2 mt-xl-0"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                autoComplete="off"
                defaultChecked
                onChange={() => setActiveTab(1)}
              />
              <label className="btn btn-primary" htmlFor="btnradio1">
                {language === "mal" ? "മോഡൽ 1" : "Model 1"}
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio2"
                autoComplete="off"
                onChange={() => setActiveTab(2)}
              />
              <label className="btn btn-primary" htmlFor="btnradio2">
                {language === "mal" ? "മോഡൽ 2" : "Model 2"}
              </label>
            </div>
            {loading ? (
              <Spinner color="secondary" className="d-block m-auto" />
            ) : (
              <div className="certificate-layout" id="pdf-content">
                <div className="btn-download-certificate">
                  <Button
                    className="btn-sm bg-khaf-blue print-button"
                    onClick={downloadPdf}
                  >
                    <i
                      className="bx bx-download mx-2"
                      style={{ fontSize: "15px", color: "" }}
                    />
                    {language === "mal" ? "ഡൗൺലോഡ്" : "Download"}
                  </Button>
                </div>
                <Row className="mt-2">
                  <LetterHead />
                  <div className="d-flex justify-content-between pt-5">
                    <h5 className="text-black">
                      {language === "mal" ? "റെജി നം : " : "Reg No : "}{" "}
                      {nikahRegistrationDetail?.mahall_custom_id}{" "}
                    </h5>
                    <h5 className="text-black">
                      {language === "mal" ? "തീയതി : " : "Date : "} {today}
                    </h5>
                  </div>
                  <h1 className="text-center text-black pt-5">
                    {language === "mal"
                      ? "വിവാഹ സർട്ടിഫിക്കറ്റ്"
                      : "MARRIAGE CERTIFICATE"}
                  </h1>
                  {activeTab === 1 ? (
                    <>
                      {language === "mal" ? (
                        <p
                          className="pt-4 text-black"
                          style={{ fontSize: "20px", textAlign: "justify" }}
                        >
                          <b>{nikahRegistrationDetail?.groom_name}</b>, S/o{" "}
                          {nikahRegistrationDetail?.groom_father_name},{" "}
                          {nikahRegistrationDetail?.groom_address} യും{" "}
                          <b>{nikahRegistrationDetail?.bride_name}</b>, D/o{" "}
                          {nikahRegistrationDetail?.bride_father_name},{" "}
                          {nikahRegistrationDetail?.bride_address} യും
                          തമ്മിലുള്ള വിവാഹം{" "}
                          <b>
                            {moment(nikahRegistrationDetail?.date).format(
                              "DD-MM-yyyy"
                            )}
                          </b>
                          -ന് {nikahRegistrationDetail?.place} യിൽ വച്ച്
                          നടന്നതായി സാക്ഷ്യപ്പെടുത്തുന്നതാണ് ഇത്. മേൽപ്പറഞ്ഞ
                          വ്യക്തികളെ ഇസ്ലാമിക ശരീഅത്ത് നിയമവും ആചാരങ്ങളും
                          അനുസരിച്ച് അവരുടെ ബന്ധുക്കളുടെ സാന്നിധ്യത്തിൽ നിക്കാഹ്
                          ചെയ്തു.
                        </p>
                      ) : (
                        <p
                          className="pt-4 text-black"
                          style={{ fontSize: "20px", textAlign: "justify" }}
                        >
                          This is to certify that the marriage between{" "}
                          <b>{nikahRegistrationDetail?.groom_name}</b> S/o{" "}
                          {nikahRegistrationDetail?.groom_father_name},{" "}
                          {nikahRegistrationDetail?.groom_address} and{" "}
                          <b>{nikahRegistrationDetail?.bride_name}</b>, D/o{" "}
                          {nikahRegistrationDetail?.bride_father_name},{" "}
                          {nikahRegistrationDetail?.bride_address} held on{" "}
                          <b>
                            {moment(nikahRegistrationDetail?.date).format(
                              "DD-MM-yyyy"
                            )}
                          </b>{" "}
                          at {nikahRegistrationDetail?.place} and the above
                          named persons was solemnized according to Islamic
                          Sharia Law and custom in the presence of their kith
                          and kin.
                        </p>
                      )}
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "170px" }}
                      >
                        <p style={{ fontSize: "16px" }}>
                          {language === "mal" ? "സീൽ" : "Seal"}
                        </p>
                        <p style={{ fontSize: "16px" }}>
                          {language === "mal"
                            ? "പ്രസിഡന്റ് / സെക്രട്ടറി"
                            : "President / Secretary"}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <p
                        className="text-center text-black pt-2 fs-5"
                        style={{ fontFamily: "inherit" }}
                      >
                        {language === "mal"
                          ? `താഴെ പറയുന്ന വിവരങ്ങൾ ${userDetail?.mahall?.mahall_malayalam_name} ന്റെ വിവാഹ രജിസ്റ്ററിന്റെ യഥാർത്ഥ രേഖയിൽ നിന്നും എടുത്തതാണ്`
                          : `The following information are taken from the original record of marriage register of ${userDetail?.mahall?.mahall_english_name}`}
                      </p>
                      <div className="certificate-form-content">
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === "mal"
                              ? "വരന്റെ പേര്‌ "
                              : "Groom Name"}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{":"}</span>{" "}
                            {nikahRegistrationDetail?.groom_name}{" "}
                          </p>
                        </div>
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === "mal"
                              ? "ജനന തീയതി "
                              : "Date of Birth"}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{":"}</span>{" "}
                            {moment(nikahRegistrationDetail?.groom_dob).format(
                              "DD-MM-yyyy"
                            )}{" "}
                          </p>
                        </div>
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === "mal"
                              ? "പിതാവിന്റെ പേര് "
                              : "Father Name "}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{":"}</span>{" "}
                            {nikahRegistrationDetail?.groom_father_name}
                          </p>
                        </div>
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === "mal" ? "വിലാസം " : "Address "}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{":"}</span>{" "}
                            {nikahRegistrationDetail?.groom_address}
                          </p>
                        </div>
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === "mal"
                              ? "വധുവിന്റെ പേര്"
                              : "Bride Name"}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{":"}</span>{" "}
                            {nikahRegistrationDetail?.bride_name}{" "}
                          </p>
                        </div>
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === "mal"
                              ? "ജനന തീയതി "
                              : "Date of Birth"}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{":"}</span>{" "}
                            {moment(nikahRegistrationDetail?.bride_dob).format(
                              "DD-MM-yyyy"
                            )}{" "}
                          </p>
                        </div>
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === "mal"
                              ? "പിതാവിന്റെ പേര് "
                              : "Father Name "}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{":"}</span>{" "}
                            {nikahRegistrationDetail?.bride_father_name}
                          </p>
                        </div>
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === "mal" ? "വിലാസം " : "Address "}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{":"}</span>{" "}
                            {nikahRegistrationDetail?.bride_address}
                          </p>
                        </div>
                        {/* <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === 'mal' ? 'വലിയ്യ് ' : 'Valiyy '}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{':'}</span>{' '}
                            {nikahRegistrationDetail?.valiyy}{' '}
                          </p>
                        </div>
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === 'mal' ? 'കാർമികത്വം ' : 'Headed By'}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{':'}</span>{' '}
                            {nikahRegistrationDetail?.headed_by}
                          </p>
                        </div> */}
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === "mal"
                              ? "നിക്കാഹ് നടന്ന സ്ഥലം "
                              : "Place Of Nikah"}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{":"}</span>{" "}
                            {nikahRegistrationDetail?.place}{" "}
                          </p>
                        </div>
                        <div className="certificate-form-row">
                          <h5 className="certificate-form-h5">
                            {language === "mal"
                              ? "നിക്കാഹ് നടന്ന തീയതി"
                              : "Date Of Nikah"}
                          </h5>
                          <p className="certificate-form-p">
                            <span className="span-column">{":"}</span>{" "}
                            {moment(nikahRegistrationDetail?.date).format(
                              "DD-MM-yyyy"
                            )}
                          </p>
                        </div>
                      </div>
                      <p
                        className="text-black pt-3"
                        style={{ textAlign: "justify", fontSize: "14px" }}
                      >
                        {language === "mal"
                          ? "മേൽപ്പറഞ്ഞ വ്യക്തികൾ തമ്മിലുള്ള വിവാഹം ഇസ്‌ലാമിക ശരീഅത്ത് നിയമവും ആചാരവും അനുസരിച്ചും അവരുടെ ബന്ധുക്കളുടെ സാന്നിധ്യത്തിൽ നടന്നതാണെന്ന് ഞാൻ ഇതിനാൽ സാക്ഷ്യപ്പെടുത്തുന്നു."
                          : " I hereby certified that the marriage between the above named persons was solemnized according to Islamic sharee-ath law and custom in the presence of their kith and kin"}
                      </p>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "70px" }}
                      >
                        <p style={{ fontSize: "16px" }}>
                          {language === "mal" ? "സീൽ" : "Seal"}
                        </p>
                        <p style={{ fontSize: "16px" }}>
                          {language === "mal"
                            ? "പ്രസിഡന്റ് / സെക്രട്ടറി"
                            : "President / Secretary"}
                        </p>
                      </div>
                    </>
                  )}
                </Row>
              </div>
            )}
          </div>
        </Container>
      </div>

      {/* <NikahCertificateDownloadPdf pdfRef={pdfRef} activeTab={activeTab} /> */}
    </>
  )
}

export default NikahCertificate

NikahCertificate.propTypes = {
  history: PropTypes.object,
}
