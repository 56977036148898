import {
  CREATE_SUPPORT_TICKET,
  CREATE_SUPPORT_TICKET_FAIL,
  CREATE_SUPPORT_TICKET_ITEM,
  CREATE_SUPPORT_TICKET_ITEM_FAIL,
  CREATE_SUPPORT_TICKET_ITEM_SUCCESS,
  CREATE_SUPPORT_TICKET_SUCCESS,
  GET_SUPPORT_TICKETS,
  GET_SUPPORT_TICKETS_FAIL,
  GET_SUPPORT_TICKETS_SUCCESS,
  GET_SUPPORT_TICKET_DETAILS,
  GET_SUPPORT_TICKET_DETAILS_FAIL,
  GET_SUPPORT_TICKET_DETAILS_SUCCESS,
  GET_SUPPORT_TICKET_ITEM,
  GET_SUPPORT_TICKET_ITEM_FAIL,
  GET_SUPPORT_TICKET_ITEM_SUCCESS,
  UPDATE_SUPPORT_TICKET,
  UPDATE_SUPPORT_TICKET_FAIL,
  UPDATE_SUPPORT_TICKET_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  allSupportTicket: [],
  supportDetails: {},

  supportTicketItem: {},
  loading: false,
  error: {},
}
const SupportTickets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_SUPPORT_TICKET:
      return {
        ...state,
        loading: true,
      }

    case CREATE_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        allSupportTicket: action.payload,
        loading: false,
      }

    case CREATE_SUPPORT_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_SUPPORT_TICKET:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        allSupportTicket: action?.payload,
      }
    case UPDATE_SUPPORT_TICKET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // admin

    case GET_SUPPORT_TICKETS:
      return {
        ...state,
        loading: true,
      }
    case GET_SUPPORT_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        allSupportTicket: action.payload,
      }

    case GET_SUPPORT_TICKETS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    // deatails

    case GET_SUPPORT_TICKET_DETAILS:
      return {
        ...state,
        loading: true,
      }
    case GET_SUPPORT_TICKET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        supportDetails: action.payload,
      }

    case GET_SUPPORT_TICKET_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    // support ticket item

    case GET_SUPPORT_TICKET_ITEM:
      return {
        ...state,
        loading: true,
      }

    case GET_SUPPORT_TICKET_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        supportTicketItem: action.payload,
      }

    case GET_SUPPORT_TICKET_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    // create

    case CREATE_SUPPORT_TICKET_ITEM:
      return {
        ...state,
        loading: true,
      }

    case CREATE_SUPPORT_TICKET_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        supportTicketItem: action.payload,
      }
    case CREATE_SUPPORT_TICKET_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
  }
  return state
}

export default SupportTickets
