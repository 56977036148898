import {
  GET_SUPERFEEDS,
  GET_SUPERFEEDS_DETAILS,
  GET_SUPERFEEDS_DETAILS_SUCCESS,
  GET_SUPERFEEDS_DETAILS_FAIL,
  CREATE_SUPERFEEDS,
  CREATE_SUPERFEEDS_SUCCESS,
  CREATE_SUPERFEEDS_FAIL,
  UPDATE_SUPERFEEDS,
  UPDATE_SUPERFEEDS_SUCCESS,
  UPDATE_SUPERFEEDS_FAIL,
  DELETE_SUPERFEEDS_SUCCESS,
  DELETE_SUPERFEEDS_FAIL,
  DELETE_SUPERFEEDS,
  GET_SUPERFEEDS_SUCCESS,
  GET_SUPERFEEDS_FAIL,
  REPOST_FEED,
  REPOST_FEEDS,
  REPOST_FEED_SUCCESS,
  REPOST_FEED_FAIL,
} from "./actionTypes"

// SuperFeeds - This line cannot be edited or removed
export const getSuperFeeds = (searchText, page) => ({
  type: GET_SUPERFEEDS,
  payload: { searchText, page },
})

export const getSuperFeedsSuccess = superfeedss => ({
  type: GET_SUPERFEEDS_SUCCESS,
  payload: superfeedss,
})

export const getSuperFeedsFail = error => ({
  type: GET_SUPERFEEDS_FAIL,
  payload: error,
})

export const getSuperFeedsDetails = superfeedsId => ({
  type: GET_SUPERFEEDS_DETAILS,
  payload: superfeedsId,
})

export const getSuperFeedsDetailsSuccess = superfeedsDetails => ({
  type: GET_SUPERFEEDS_DETAILS_SUCCESS,
  payload: superfeedsDetails,
})

export const getSuperFeedsDetailsFail = error => ({
  type: GET_SUPERFEEDS_DETAILS_FAIL,
  payload: error,
})

export const createSuperFeeds = (createData, history) => ({
  type: CREATE_SUPERFEEDS,
  payload: { createData, history },
})

export const createSuperFeedsSuccess = superfeeds => ({
  type: CREATE_SUPERFEEDS_SUCCESS,
  payload: superfeeds,
})

export const createSuperFeedsFail = error => ({
  type: CREATE_SUPERFEEDS_FAIL,
  payload: error,
})

export const getSuperFeedsDetail = superfeedsId => ({
  type: GET_SUPERFEEDS_DETAILS,
  payload: superfeedsId,
})

export const updateSuperFeeds = (superfeedsId, updateData, history) => ({
  type: UPDATE_SUPERFEEDS,
  payload: { superfeedsId, updateData, history },
})

export const updateSuperFeedsSuccess = superfeeds => ({
  type: UPDATE_SUPERFEEDS_SUCCESS,
  payload: superfeeds,
})

export const updateSuperFeedsFail = error => ({
  type: UPDATE_SUPERFEEDS_FAIL,
  payload: error,
})

export const deleteSuperFeeds = (superFeedId, history) => (
  console.log(superFeedId),
  {
    type: DELETE_SUPERFEEDS,
    payload: { superFeedId, history },
  }
)

export const deleteSuperFeedsSuccess = superfeeds => ({
  type: DELETE_SUPERFEEDS_SUCCESS,
  payload: superfeeds,
})

export const deleteSuperFeedsFail = error => ({
  type: DELETE_SUPERFEEDS_FAIL,
  payload: error,
})
//repost

export const repostFeeds = superFeedId => ({
  type: REPOST_FEEDS,
  payload: superFeedId,
})
export const repostFeedSuccess = () => ({
  type: REPOST_FEED_SUCCESS,
  payload: "",
})
export const repostFeedFail = error => ({
  type: REPOST_FEED_FAIL,
  payload: error,
})
