import { PropTypes } from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

function LetterHeadWithValues({ mahall, language }) {
  console.log(mahall)
  // const { userDetail, language } = useSelector(state => ({
  //   userDetail: state.Users.userDetail,
  //   language: state.Layout.language,
  // }))
  return (
    <>
      <div className="d-flex">
        <div className="mt-1" style={{ border: "none" }}>
          <img
            src={mahall?.logo && mahall?.logo}
            alt=""
            className="img-thumbnail logo-certificate"
            style={{ width: "100px", height: "100px" }}
          />
        </div>
        <div className="content-certificate mt-2">
          <h2 className="text-center text-black">
            <b>
              {" "}
              {language === "mal"
                ? mahall?.mahall_malayalam_name
                  ? mahall?.mahall_malayalam_name
                  : mahall?.mahall_english_name
                : mahall?.mahall_english_name}
            </b>
          </h2>
          <p className="text-black text-center">
            {`${
              language === "mal"
                ? mahall?.lsg_malayalam_name
                  ? mahall?.lsg_malayalam_name
                  : mahall?.lsg_english_name
                : mahall?.lsg_english_name
            } 
                        ${
                          language === "mal"
                            ? mahall?.lsg_type_malayalam
                              ? mahall?.lsg_type_malayalam
                              : mahall?.lsg_type_english
                            : mahall?.lsg_type_english
                        },`}{" "}
            {`${mahall?.post_office ? mahall?.post_office : ""}-${
              mahall?.pin_code ? mahall?.pin_code : ""
            }, `}{" "}
            <br />
            {language === "mal"
              ? mahall?.village_malayalam_name
                ? mahall?.village_malayalam_name
                : mahall?.village_english_name
              : mahall?.village_english_name}
            ,{" "}
            {language === "mal"
              ? mahall?.district_malayalam_name
                ? mahall?.district_malayalam_name
                : mahall?.district_english_name
              : mahall?.district_english_name}
            ,{" "}
            {language === "mal"
              ? mahall?.state_malayalam_name
                ? mahall?.state_malayalam_name
                : mahall?.state_english_name
              : mahall?.state_english_name}
            ,{" "}
            {`${language === "mal" ? "ഫോൺ" : "Mob No:"} : ${
              mahall?.phone_number && mahall?.phone_number
            }`}
          </p>
        </div>
      </div>
      <div style={{ borderBottom: "1px solid black" }}></div>
    </>
  )
}
LetterHeadWithValues.propTypes = {
  mahall: PropTypes?.object,
  language: PropTypes.string,
}

export default LetterHeadWithValues
