import {
  GET_GROUP,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAIL,
  GET_GROUP_DETAIL,
  GET_GROUP_DETAIL_SUCCESS,
  GET_GROUP_DETAIL_FAIL,
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  groups: [],
  groupDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdgroup: {},
}

const Groups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GROUP:
    case UPDATE_GROUP:
    case CREATE_GROUP:
    case DELETE_GROUP:
      return {
        ...state,
        loading: true,
      }
    case GET_GROUP_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_GROUP_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        loading: false,
      }

    case GET_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        createdGroup: action.payload,
        loading: false,
      }

    case CREATE_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        groupDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_GROUP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        groupDetail: action.payload,
        loading: false,
      }

    case UPDATE_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_GROUP_SUCCESS:
      return {
        groups: action.payload,
        loading: false,
      }

    case DELETE_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Groups
