import React, { useEffect, useState } from "react"
import {
  // Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Table,
  Button,
  Badge,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
// import ToolkitProvider from 'react-bootstrap-table2-toolkit'
// import BootstrapTable from 'react-bootstrap-table-next'
//actions
//componetns
// import { debounce, map, range } from 'lodash'

// import DeleteModal from 'components/Common/DeleteModal'
import Breadcrumbs from "components/Common/Breadcrumb"
// import MyPagination from 'components/Common/MyPagination'
import {
  deleteStudent,
  getStudentAbsentDetail,
  // deleteCommitteeMeeting,
  getStudentDetail,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"

const StudentDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  // const [searchText, setSearchText] = useState('')
  // const [page, setPage] = useState(1)

  const { studentDetail, loading, language, studentAbsentDetail } = useSelector(
    state => ({
      loading: state.Students.loading,
      studentDetail: state.Students.studentDetail,
      studentAbsentDetail: state.Students.studentAbsentDetail,
      language: state.Layout.language,
    })
  )

  console.log(studentAbsentDetail)

  // const totalPages = Math.ceil(committeeMeetingAttendances?.count / 10)
  // const pages = range(1, totalPages + 1)

  // const pageSend = () => {
  //   if (page >= pages.length) {
  //     return pages.length
  //   }
  //   if (page < 1) {
  //     return 1
  //   } else {
  //     return page
  //   }
  // }

  // const debounceMeetingAttendanceSearch = debounce(
  //   value => setSearchText(value),
  //   600,
  // )

  // const columns = [
  //   {
  //     dataField: 'no',
  //     text: `${language === 'mal' ? 'നം.' : 'No.'}`,
  //     sort: true,
  //   },
  //   {
  //     dataField: 'committee_member_name',
  //     text: `${language === 'mal' ? 'അംഗം' : 'Member'}`,
  //     sort: true,
  //   },
  //   {
  //     dataField: 'committee_member_designation',
  //     text: `${language === 'mal' ? 'പദവി' : 'Designation'}`,
  //   },
  //   {
  //     dataField: 'attendance_time',
  //     text: `${language === 'mal' ? 'സമയം' : 'Time'}`,
  //   },
  //   {
  //     dataField: 'action',
  //     text: `${language === 'mal' ? 'പ്രവർത്തനങ്ങൾ' : 'Actions'}`,
  //   },
  // ]

  // const committeeMeetingAttendancesData = map(
  //   committeeMeetingAttendances?.results,
  //   (item, index) => ({
  //     ...item,
  //     key: index,
  //     no: index + 1,
  //     action: (
  //       <div className="m-0">
  //         <Button type="button" color="primary" className=" btn w-xs btn-sm">
  //           Edit
  //         </Button>
  //       </div>
  //     ),
  //   }),
  // )

  // const defaultSorted = [
  //   {
  //     dataField: 'id',
  //     order: 'asc',
  //   },
  // ]

  // const selectRow = {
  //   mode: 'checkbox',
  // }

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(deleteStudent(studentDetail?.id, history))
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getStudentDetail(params.id))
  }, [])

  useEffect(() => {
    dispatch(getStudentAbsentDetail(params.id))
  }, [])

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <MetaTags>
        <title>Student Details | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal" ? "വിദ്യാർത്ഥി വിശദാംശങ്ങൾ" : "Student Details"
          }
          breadcrumbItem={language === "mal" ? "വിദ്യാർത്ഥികൾ" : "Students"}
        />
        <Container fluid>
          <div className="container-fluid">
            {loading ? (
              <Spinner className="d-block m-auto" />
            ) : (
              <Row>
                <Col md="6" lg="6" sm="12" xs="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <div className="d-flex align-items-center">
                            <h4>{studentDetail?.name}</h4>
                            <Badge className="bg-info rounded-pill mx-2">
                              {`${
                                language === "mal" ? "ക്രമ നം." : "Roll No."
                              }`}{" "}
                              {studentDetail?.roll_no}
                            </Badge>
                          </div>
                          <div className="table-responsive mt-4">
                            <Table className="table mb-0">
                              <tbody>
                                <tr>
                                  <td>
                                    {language === "mal" ? "ക്ലാസ്" : "Class"}
                                  </td>
                                  <td>
                                    {" "}
                                    {": "}
                                    {studentDetail?.class_name}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {language === "mal" ? "ഡിവിഷൻ" : "Division"}
                                  </td>
                                  <td>
                                    {": "}
                                    {studentDetail?.division_name}
                                  </td>
                                </tr>
                                <tr>
                                  {/* <td>
                                      {language === 'mal'
                                        ? 'ക്രമ നം.'
                                        : 'Roll No.'}
                                    </td>
                                    <td>
                                      {': '}
                                      {studentDetail?.roll_no}
                                    </td> */}
                                  <td>
                                    {language === "mal" ? "വയസ്സ്" : "Age"}
                                  </td>
                                  <td>
                                    {": "}
                                    {getAge(studentDetail?.dob)}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {language === "mal"
                                      ? "രക്ഷിതാവ്"
                                      : "Parent"}
                                  </td>
                                  <td>
                                    {": "}
                                    {studentDetail?.parent_name}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {language === "mal"
                                      ? "ഫോൺ നമ്പർ"
                                      : "Phone Number"}
                                  </td>
                                  <td>
                                    {": "}
                                    {studentDetail?.parent_phone}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {language === "mal"
                                      ? "വരിസംഖ്യ തുക"
                                      : "Varisangya Amount"}
                                  </td>
                                  <td>
                                    {": "}
                                    {studentDetail?.varisankya_amount}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {language === "mal"
                                      ? "വാലറ്റ് തുക"
                                      : "Wallet Amount"}
                                  </td>
                                  <td>
                                    {": "}
                                    {studentDetail?.varisankya_wallet}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <div className="d-flex justify-content-between mt-4">
                            <Button
                              type="button"
                              color="danger"
                              className="btn w-sm btn-md"
                              onClick={handleDelete}
                            >
                              Delete
                            </Button>
                            <Link to={`/student/update/${studentDetail?.id}`}>
                              <Button
                                type="button"
                                color="primary"
                                className="btn w-sm btn-md"
                              >
                                Update
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" lg="6" sm="12" xs="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <div>
                            <h5 className="text-danger pb-2">
                              {language === "mal"
                                ? "ഹാജരാകാത്ത തീയതികൾ :"
                                : "Absent Dates :"}
                            </h5>
                            {studentAbsentDetail &&
                            studentAbsentDetail?.results?.length > 0 ? (
                              studentAbsentDetail?.results?.map(
                                (item, key) =>
                                  item?.date && (
                                    <p key={key} className="px-3 m-0">
                                      {moment(item?.date).format("DD-MM-YYYY")}{" "}
                                      {" - "}
                                      {moment(item?.date).format("dddd")}
                                    </p>
                                  )
                              )
                            ) : (
                              <p className="text-center m-0 text-primary">
                                {language === "mal"
                                  ? "ഹാജരാകാത്ത തീയതികൾ ഇല്ല"
                                  : "No Absent Dates Yet"}
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default StudentDetails

StudentDetails.propTypes = {
  history: PropTypes.object,
}
