import {
  GET_FAMILY_MEMBER_VARISANGYA_WALLETS,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET,
  GET_FAMILY_MEMBER_VARISANGYA_WALLETS_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_WALLETS_FAIL,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS,
  CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_FAIL,
  CLEAR_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_FAIL,
  DELETE_FAMILY__MEMBER_VARISANGYA_WALLET,
  DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_SUCCESS,
  DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  familyMemberVarisangyaWallets: [],
  familyMemberVarisangyaWalletDetail: [],
  familyMemberVarisangyaWalletView: {},
  error: {},
  loading: false,
  tableLoading: false,
  pageLoading: false,
  deleteLoading: false,
  createdFamilyMemberVarisangyaWallet: {},
}

const FamilyMemberVarisangyaWallets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL:
      return {
        ...state,
        familyMemberVarisangyaWalletDetail: [],
      }
    case GET_FAMILY_MEMBER_VARISANGYA_WALLETS:
    case GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW:
    case CREATE_FAMILY_MEMBER_VARISANGYA_WALLET:
      return {
        ...state,
        loading: true,
      }
    case GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL:
      return {
        ...state,
        tableLoading: true,
      }

    case GET_FAMILY_MEMBER_VARISANGYA_WALLETS_SUCCESS:
      return {
        ...state,
        familyMemberVarisangyaWallets: action.payload,
        loading: false,
      }
    case GET_FAMILY_MEMBER_VARISANGYA_WALLETS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_SUCCESS:
      return {
        ...state,
        familyMemberVarisangyaWallets: action.payload,
        familyMemberVarisangyaWalletDetail: {
          ...state.familyMemberVarisangyaWalletDetail,
          results: [
            ...state.familyMemberVarisangyaWalletDetail.results,
            action.payload,
          ],
        },
        createdFamilyMemberVarisangyaWallet: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_VARISANGYA_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_SUCCESS:
      return {
        ...state,
        familyMemberVarisangyaWalletDetail: action.payload,
        tableLoading: false,
      }
    case GET_FAMILY_MEMBER_VARISANGYA_WALLET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        tableLoading: false,
      }

    case GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_SUCCESS:
      return {
        ...state,
        familyMemberVarisangyaWalletView: action.payload,
        tableLoading: false,
      }
    case GET_FAMILY_MEMBER_VARISANGYA_WALLET_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
        tableLoading: false,
      }

    case DELETE_FAMILY__MEMBER_VARISANGYA_WALLET:
      return {
        ...state,
        deleteLoading: true,
      }

    case DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
      }
    case DELETE_FAMILY__MEMBER_VARISANGYA_WALLET_FAIL:
      return {
        ...state,
        deleteLoading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default FamilyMemberVarisangyaWallets
