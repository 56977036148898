import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGIN_OTP_USER,
  LOGOUT_USER,
  FORGET_PASSWORD,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  loginOtpSuccess,
  logoutUserSuccess,
  userForgetPasswordSuccess,
  userForgetPasswordError,
} from "./actions"

import { post } from "helpers/api_methods"
import {
  useGetSession,
  useGetToken,
  useRemoveSession,
  useSetSession,
  useSetToken,
} from "hooks/useHandleSessions"

function loginApi({ user }) {
  return post("/api/v1/account/login", user)
}
function loginOtpApi({ otp }) {
  return post("/api/v1/account/login-otp", {
    otp: otp.otp,
    session_key: otp.session_key,
  })
}
function logoutApi(token) {
  return post("/api/v1/account/logout", token)
}
function forgetUserPassApi(email) {
  return post("/api/v1/account/forgot_password", email)
}

function* forgetUserPass({ payload }) {
  try {
    const response = yield call(forgetUserPassApi, payload)
    if (response) {
      yield put(userForgetPasswordSuccess(response?.response))
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* loginUser({ payload }) {
  try {
    const response = yield call(loginApi, payload)
    useSetSession("session_key", response.session_key)
    yield put(loginSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}
function* loginOtpUser({ payload }) {
  try {
    payload["otp"]["session_key"] = useGetSession("session_key")
    const response = yield call(loginOtpApi, payload)
    if (response?.token) {
      useSetToken(response?.token)
    }
    if (response?.error_message) {
      yield put(apiError(response?.error_message))
    } else {
      yield put(loginOtpSuccess(response))
      payload.history.push("/role")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const { token } = useGetToken()
    const response = yield call(logoutApi, token)
    useRemoveSession("token")
    useRemoveSession("current_role")
    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name)
      })
    })
    console.log("Cache cleared!")
    if (response?.error_message) {
      yield put(apiError(response?.error_message))
    } else {
      yield put(logoutUserSuccess(response))
      history.push("/login")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGIN_OTP_USER, loginOtpUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(FORGET_PASSWORD, forgetUserPass)
}

export default authSaga
