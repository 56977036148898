import {
  GET_FAMILY_MEMBER_VARISANGYAS,
  GET_FAMILY_MEMBER_VARISANGYA_CARDS,
  UPDATE_FAMILY_MEMBER_VARISANGYA,
  CREATE_FAMILY_MEMBER_VARISANGYA,
  DELETE_FAMILY_MEMBER_VARISANGYA,
  GET_FAMILY_MEMBER_VARISANGYA_DETAIL,
  GET_FAMILY_MEMBER_VARISANGYAS_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYAS_FAIL,
  GET_FAMILY_MEMBER_VARISANGYA_CARDS_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_CARDS_FAIL,
  CREATE_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  CREATE_FAMILY_MEMBER_VARISANGYA_FAIL,
  GET_FAMILY_MEMBER_VARISANGYA_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_DETAIL_FAIL,
  UPDATE_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  UPDATE_FAMILY_MEMBER_VARISANGYA_FAIL,
  DELETE_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  DELETE_FAMILY_MEMBER_VARISANGYA_FAIL,
  AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS,
  AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_SUCCESS,
  AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_FAIL,
  REFRESH_FAMILY_MEMBER_VARISANGYA,
  REFRESH_FAMILY_MEMBER_VARISANGYA_SUCCESS,
  REFRESH_FAMILY_MEMBER_VARISANGYA_FAIL,
  UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER,
  UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
  UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  familyMemberVarisangyas: [],
  familyMemberVarisangyaCards: {},
  familyMemberVarisangyaDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  // autoGenerateLoading: false,
  refreshLoading: false,
  createdFamilyMemberVarisangya: {},
}

const FamilyMemberVarisangyas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FAMILY_MEMBER_VARISANGYAS:
    case GET_FAMILY_MEMBER_VARISANGYA_CARDS:
    case UPDATE_FAMILY_MEMBER_VARISANGYA:
    case CREATE_FAMILY_MEMBER_VARISANGYA:
    case DELETE_FAMILY_MEMBER_VARISANGYA:
    case UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER:
      return {
        ...state,
        loading: true,
      }
    case GET_FAMILY_MEMBER_VARISANGYA_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS:
      return {
        ...state,
        loading: true,
      }
    case REFRESH_FAMILY_MEMBER_VARISANGYA:
      return {
        ...state,
        refreshLoading: true,
      }

    case GET_FAMILY_MEMBER_VARISANGYAS_SUCCESS:
      return {
        ...state,
        familyMemberVarisangyas: action.payload,
        loading: false,
      }
    case GET_FAMILY_MEMBER_VARISANGYAS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_VARISANGYA_CARDS_SUCCESS:
      return {
        ...state,
        familyMemberVarisangyaCards: action.payload,
        loading: false,
      }
    case GET_FAMILY_MEMBER_VARISANGYA_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_VARISANGYA_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        familyMemberVarisangyas: {
          ...state.familyMemberVarisangyas,
          results: [...state.familyMemberVarisangyas.results, action.payload],
        },
        createdFamilyMemberVarisangya: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_VARISANGYA_DETAIL_SUCCESS:
      return {
        ...state,
        familyMemberVarisangyaDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_FAMILY_MEMBER_VARISANGYA_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_FAMILY_MEMBER_VARISANGYA_SUCCESS:
      return {
        ...state,
        // familyMemberVarisangyaDetail: action.payload,
        familyMemberVarisangyas: {
          results: state.familyMemberVarisangyas.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_FAMILY_MEMBER_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_FAMILY_MEMBER_VARISANGYA_SUCCESS:
      return {
        ...state,
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        familyMemberVarisangyas: {
          results: state.familyMemberVarisangyas.results.filter(
            familyMemberVarisangya =>
              familyMemberVarisangya.id !== action.payload
          ),
        },
        loading: false,
      }

    case DELETE_FAMILY_MEMBER_VARISANGYA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_SUCCESS:
      return {
        ...state,
        // familyMemberVarisangyas: action.payload,
        loading: false,
      }
    case AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS_FAIL:
      return {
        ...state,
        // error: action.payload,
        loading: false,
      }

    case REFRESH_FAMILY_MEMBER_VARISANGYA_SUCCESS:
      return {
        ...state,
        familyMemberVarisangyaItems: action.payload,
        refreshLoading: false,
      }
    case REFRESH_FAMILY_MEMBER_VARISANGYA_FAIL:
      return {
        ...state,
        // error: action.payload,
        refreshLoading: false,
      }

    case UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER_FAIL:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export default FamilyMemberVarisangyas
