import React from "react"
import { Col, Container, Row } from "reactstrap"
// import img1 from "assets/images/khaf/img1.png"

// import { useDispatch } from 'react-redux'

import { useLanguage } from "../Language/SwitchLanguage"

import { useWindowSize } from "hooks/useWindowSize"

function Video() {
  // const dispatch = useDispatch()
  const text = useLanguage()

  const size = useWindowSize()

  return (
    <React.Fragment>
      <section className="section bg-white" id="about">
        <Container>
          <Row className="m-0">
            <div className="">
              <Row className="mt-5 d-flex align-items-center ">
                <Col md="6" className="">
                  <h2 className="text-khaf">{text.introduction_head}</h2>
                  <p className="mt-3" style={{ lineHeight: "1.8" }}>
                    {text.introduction}
                  </p>
                </Col>
                <Col md="6">
                  {size?.width <= 768 ? (
                    <div className="d-flex justify-content-center">
                      <iframe
                        className="rounded"
                        width="640"
                        height="206"
                        src="https://www.youtube.com/embed/Sf7i0ronxtU?controls=0&rel=0&iv_load_policy=0&modestbranding=1&showtitle=0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <iframe
                        className="rounded"
                        width="640"
                        height="300"
                        src="https://www.youtube.com/embed/Sf7i0ronxtU?controls=0&rel=0&iv_load_policy=0&modestbranding=1&showtitle=0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Video
