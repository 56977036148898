/* INSTITUTE_WALLET_CONTRA_LIST */
export const GET_INSTITUTE_WALLET_CONTRA_LIST =
  "GET_INSTITUTE_WALLET_CONTRA_LIST"
export const GET_INSTITUTE_WALLET_CONTRA_LIST_SUCCESS =
  "GET_INSTITUTE_WALLET_CONTRA_LIST_SUCCESS"
export const GET_INSTITUTE_WALLET_CONTRA_LIST_FAIL =
  "GET_INSTITUTE_WALLET_CONTRA_LIST_FAIL"

/**
 * add INSTITUTE_WALLET_CONTRA
 */
export const CREATE_INSTITUTE_WALLET_CONTRA = "CREATE_INSTITUTE_WALLET_CONTRA"
export const CREATE_INSTITUTE_WALLET_CONTRA_SUCCESS =
  "CREATE_INSTITUTE_WALLET_CONTRA_SUCCESS"
export const CREATE_INSTITUTE_WALLET_CONTRA_FAIL =
  "CREATE_INSTITUTE_WALLET_CONTRA_FAIL"
