import {
  GET_BANNERS,
  UPDATE_BANNER,
  CREATE_BANNER,
  DELETE_BANNER,
  GET_BANNER_DETAIL,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAIL,
  CREATE_BANNER_SUCCESS,
  CREATE_BANNER_FAIL,
  GET_BANNER_DETAIL_SUCCESS,
  GET_BANNER_DETAIL_FAIL,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  banners: {
    results: [],
  },
  bannerDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdBanner: {},
}

const Banners = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case GET_VARISANGYA_CARDS:
    case GET_BANNERS:
    case UPDATE_BANNER:
    case CREATE_BANNER:
    case DELETE_BANNER:
      return {
        ...state,
        loading: true,
      }
    case GET_BANNER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        loading: false,
      }
    case GET_BANNERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // case GET_VARISANGYA_CARDS_SUCCESS:
    //   return {
    //     ...state,
    //     varisangyaCards: action.payload,
    //     loading: false,
    //   }
    // case GET_VARISANGYA_CARDS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     loading: false,
    //   }

    case CREATE_BANNER_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdBanner: action.payload,
        loading: false,
      }

    case CREATE_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_BANNER_DETAIL_SUCCESS:
      return {
        ...state,
        bannerDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_BANNER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        bannerDetail: action.payload,
        loading: false,
      }

    case UPDATE_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_BANNER_SUCCESS:
      console.log(action.payload)
      console.log(state)
      return {
        ...state,
        banners: {
          ...state.banners,
          banners: state.banners.results.filter(
            banner => banner.id !== action.payload
          ),
        },
        loading: false,
      }

    case DELETE_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Banners
