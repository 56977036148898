import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ADMINS,
  GET_ADMINS_DETAIL,
  CREATE_ADMINS,
  UPDATE_ADMINS,
  DELETE_ADMINS,
} from "./actiontypes"
import {
  getAdminsSuccess,
  getAdminsFail,
  getAdminsDetailSuccess,
  getAdminsDetailFail,
  createAdminsSuccess,
  createAdminsFail,
  updateAdminsSuccess,
  updateAdminsFail,
  deleteAdminsSuccess,
  deleteAdminsFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getAdminsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/dashboard/admin_list/?search=${searchText && searchText}`
    )
  } else {
    return get(`/api/v1/dashboard/admin_list/?page=${page ? page : 1}`)
  }
}
const getAdminsDetailsAPi = adminsId => {
  return get(`/api/v1/account/admin/${adminsId}/`)
}
const createAdminsApi = ({ admins }) => {
  return post("/api/v1/account/admin/", admins)
}
const updateAdminsApi = ({ adminsId, admins }) => {
  return ApiPut(`/api/v1/account/admin/${adminsId}/`, admins)
}
const deleteAdminsApi = ({ adminsId }) => {
  return del(`/api/v1/account/admin/${adminsId}/`)
}

function* fetchAdmins({ payload }) {
  try {
    const response = yield call(getAdminsAPi, payload)
    yield put(getAdminsSuccess(response))
  } catch (error) {
    yield put(getAdminsFail(error))
  }
}

function* fetchAdminsDetail({ adminsId }) {
  try {
    const response = yield call(getAdminsDetailsAPi, adminsId)
    yield put(getAdminsDetailSuccess(response))
  } catch (error) {
    yield put(getAdminsDetailFail(error))
  }
}
function* onCreateAdmins({ payload }) {
  try {
    const response = yield call(createAdminsApi, payload)
    if (response.email[0] === "admins with this email already exists.") {
      yield put(createAdminsFail(response.email))
    } else {
      yield put(createAdminsSuccess(response))
      payload.history.push("/admins")
      doneNotification()
    }
  } catch (error) {
    yield put(createAdminsFail(error))
    errorNotification()
  }
}

function* onUpdateAdmins({ payload }) {
  try {
    const response = yield call(updateAdminsApi, payload)
    yield put(updateAdminsSuccess(response))
    doneNotification()
  } catch (error) {
    yield put(updateAdminsFail(error))
    errorNotification()
  }
}

function* onDeleteAdmins({ payload }) {
  try {
    const response = yield call(deleteAdminsApi, payload)
    payload.history.push("/admins")
    yield put(deleteAdminsSuccess(response))
    doneNotification()
  } catch (error) {
    errorNotification()
    yield put(deleteAdminsFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification() {
  Notification({
    type: "success",
    message: "Done",
    title: "",
  })
}

function* adminsSaga() {
  yield takeEvery(GET_ADMINS, fetchAdmins)
  yield takeEvery(GET_ADMINS_DETAIL, fetchAdminsDetail)
  yield takeEvery(CREATE_ADMINS, onCreateAdmins)
  yield takeEvery(UPDATE_ADMINS, onUpdateAdmins)
  yield takeEvery(DELETE_ADMINS, onDeleteAdmins)
}

export default adminsSaga
