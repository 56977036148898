import React from "react"
import propTypes from "prop-types"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useDispatch } from "react-redux"
//actions
import { Button, Col, Modal, Label } from "reactstrap"

import { createUser } from "store/actions"

//Import Breadcrumb

const UiModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  //  const { loading, error,Users} = useSelector(state => ({
  //   loading: state.MahallUsers.loading,
  //   error: state.MahallUsers.error,
  //   Users:state.MahallUsers.users
  // }));

  //  const [selectedAccount, setSelectedAccount] = useState(
  //    "Search User Account"
  //  )
  //  const [searchAccountText, setSearchAccountText] = useState("")
  // //  //quotation
  //  function handlerAccountFinalValue(event) {
  //    setSelectedAccount(event.label)
  //  }

  //  const handleAccountEnters = textEntered => {
  //    setSearchAccountText(textEntered)
  //  }
  //  const accountOptions = [
  //    {
  //      options: MahallUsers?.users?.map((result, index) => ({
  //        key: index,
  //        label: result.MahallUsers,
  //        value: result.MahallUsers,
  //        accountId: result.id,
  //      })),
  //    },
  //  ]
  //  const [modal_center, setmodal_center] = useState(false);

  const handleValidSubmit = (onSubmitProps, values) => {
    dispatch(createUser(values, history))
  }
  //  useEffect(() => {
  //    dispatch(getMahallUsers("", ""))
  //  }, [dispatch])

  // const mailError = error[0]

  return (
    <React.Fragment>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Create User</h5>
          <button
            type="button"
            onClick={onCloseClick}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm
            className="form-horizontal "
            onValidSubmit={(onSubmitProps, v) => {
              handleValidSubmit(onSubmitProps, v)
            }}
          >
            {/* <div className="row mb-4">
             <Label
               htmlFor="horizontal-username-Input"
               className="col-sm-3 col-form-label"
             >
             User
             </Label>
             <Col sm={9}>
               <AvField
                 id="horizontal-username-Input"
                 name="account"
                 title="account"
                 type="select"
                >
               <option  onInputChange={handleEnters}
                 value={selectedProduct}
                 placeholder={selectedProduct}
                 onChange={handlerFinalValue}
                 options={optionGroup1}
                 isLoading={true}>{value}</option>
                 </AvField>
             </Col>
           </div> */}
            <div className="row mb-4">
              <Label
                htmlFor="horizontal-firstname-Input"
                className="col-sm-3 col-form-label"
              >
                FullName
              </Label>
              <Col sm={9}>
                <AvField
                  id="horizontal-firstname-Input"
                  title="Fullname"
                  name="full_name"
                  type="text"
                />
              </Col>
            </div>

            <div className="row mb-4">
              <Label
                htmlFor="horizontal-firstname-Input"
                className="col-sm-3 col-form-label"
              >
                User Name
              </Label>
              <Col sm={9}>
                <AvField
                  id="horizontal-firstname-Input"
                  title="Username"
                  name="username"
                  type="text"
                />
              </Col>
            </div>

            <div className="row mb-4">
              <Label
                htmlFor="horizontal-lastname-Input"
                className="col-sm-3 col-form-label"
              >
                Email
              </Label>
              <Col sm={9}>
                <AvField
                  id="horizontal-lastname-Input"
                  name="email"
                  title="email"
                  type="email"
                />
              </Col>
            </div>
            <div className="row mb-4">
              <Label
                htmlFor="horizontal-lastname-Input"
                className="col-sm-3 col-form-label"
              >
                Phone
              </Label>
              <Col sm={9}>
                <AvField
                  id="horizontal-lastname-Input"
                  name="phone"
                  title="phone"
                  type="text"
                />
              </Col>
            </div>

            <div className="row justify-content-end">
              <Col sm={9}>
                <div>
                  {/* {mailError === undefined ? (
                              ""
                            ) : (
                              <Alert color="danger">{error}</Alert>
                            )} */}

                  <Button type="submit" color="success" className="w-md">
                    {/* {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )} */}
                    Create User
                  </Button>
                </div>
              </Col>
            </div>
          </AvForm>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default UiModal
UiModal.propTypes = {
  onCloseClick: propTypes.func,
  show: propTypes.bool,
}
