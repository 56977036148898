import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_DISEASE_TYPE_DATA,
  GET_DASHBOARD_DISEASE_TYPE_DATA_SUCCESS,
  GET_DASHBOARD_DISEASE_TYPE_DATA_FAIL,
  GET_MONTHLY_CHART,
  GET_MONTHLY_CHART_SUCCESS,
  GET_MONTHLY_CHART_FAIL,
  GET_YEARLY_CHART_SUCCESS,
  GET_YEARLY_CHART_FAIL,
  GET_YEARLY_CHART,
  GET_DASHBOARD_CARD,
  GET_DASHBOARD_CARD_SUCCESS,
  GET_DASHBOARD_CARD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  monthlyData: [],
  yearlyData: [],
  error: {},
  loading: false,
  yearLoading: false,
  dashboardData: {},
  dashboardCard: {},
  dashboardDiseaseTypeData: {},
  dashboardLoading: false,
  dashboardDiseaseTypeDataLoading: false,
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MONTHLY_CHART:
      return {
        ...state,
        loading: true,
      }

    case GET_YEARLY_CHART:
      return {
        ...state,
        yearLoading: true,
      }

    case GET_YEARLY_CHART_SUCCESS:
      return {
        ...state,
        yearlyData: action.payload,
        yearLoading: false,
      }

    case GET_YEARLY_CHART_FAIL:
      return {
        ...state,
        error: action.payload,
        yearLoading: false,
      }

    case GET_MONTHLY_CHART_SUCCESS:
      return {
        ...state,
        monthlyData: action.payload,
        loading: false,
      }

    case GET_MONTHLY_CHART_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardLoading: true,
      }

    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        dashboardLoading: false,
      }

    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        dashboardLoading: false,
      }

    case GET_DASHBOARD_CARD:
      return {
        ...state,
        dashboardLoading: true,
      }

    case GET_DASHBOARD_CARD_SUCCESS:
      return {
        ...state,
        dashboardCard: action.payload,
        dashboardLoading: false,
      }

    case GET_DASHBOARD_CARD_FAIL:
      return {
        ...state,
        error: action.payload,
        dashboardLoading: false,
      }

    case GET_DASHBOARD_DISEASE_TYPE_DATA:
      return {
        ...state,
        dashboardDiseaseTypeDataLoading: true,
      }

    case GET_DASHBOARD_DISEASE_TYPE_DATA_SUCCESS:
      return {
        ...state,
        dashboardDiseaseTypeData: action.payload,
        dashboardDiseaseTypeDataLoading: false,
      }

    case GET_DASHBOARD_DISEASE_TYPE_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        dashboardDiseaseTypeDataLoading: false,
      }

    default:
      return state
  }
}

export default Dashboard
