import { useDispatch, useSelector } from "react-redux"
import React, { useEffect } from "react"
import ReactEcharts from "echarts-for-react"
import { Card, CardBody, CardTitle, Spinner } from "reactstrap"

import { getFamilyMembersCards } from "store/actions"

function GenderChart() {
  const dispatch = useDispatch()
  const { familyMembersCards, loading, language } = useSelector(state => ({
    familyMembersCards: state.FamilyMembers.familyMembersCards,
    loading: state.Dashboard.dashboardLoading,
    language: state.Layout.language,
  }))

  useEffect(() => {
    dispatch(getFamilyMembersCards())
  }, [dispatch])

  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "horizontal",
        // x: "center",
        y: "bottom",
        data: [
          `${language === "mal" ? "പുരുഷൻ" : "Male"}`,
          `${language === "mal" ? "സ്ത്രീ" : "Female"}`,
          `${language === "mal" ? "മറ്റുള്ളവ" : "Others"}`,
        ],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#03a96f", "#60e2c0", "#d7d6e1"],
      series: [
        {
          name: "Total count",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            {
              value: familyMembersCards?.total_males ?? 0,
              name: `${language === "mal" ? "പുരുഷൻ" : "Male"}`,
            },
            {
              value: familyMembersCards?.total_females ?? 0,
              name: `${language === "mal" ? "സ്ത്രീ" : "Female"}`,
            },
            {
              value: familyMembersCards?.total_others ?? 0,
              name: `${language === "mal" ? "മറ്റുള്ളവ" : "Others"}`,
            },
          ],
        },
      ],
    }
  }
  return (
    <React.Fragment>
      <Card style={{ height: "400px" }}>
        <CardBody>
          <CardTitle className="">
            {language === "mal" ? "ലിംഗ വിശകലനം" : "Gender Analysis"}{" "}
            <span style={{ float: "right" }}>
              Total :{familyMembersCards?.total_family_members}
            </span>
          </CardTitle>

          {loading ? (
            <>
              <Spinner type="grow" color="info" />
            </>
          ) : (
            <ReactEcharts
              style={{ height: "350px", marginTop: "-35px" }}
              option={getOption()}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default GenderChart
