import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ASSET_ITEM_TRANSACTIONS,
  GET_ASSET_ITEM_TRANSACTION_DETAIL,
  CREATE_ASSET_ITEM_TRANSACTION,
  UPDATE_ASSET_ITEM_TRANSACTION,
  DELETE_ASSET_ITEM_TRANSACTION,
} from "./actionTypes"
import {
  getAssetItemTransactionsSuccess,
  getAssetItemTransactionsFail,
  getAssetItemTransactionDetailSuccess,
  getAssetItemTransactionDetailFail,
  createAssetItemTransactionSuccess,
  createAssetItemTransactionFail,
  updateAssetItemTransactionSuccess,
  updateAssetItemTransactionFail,
  deleteAssetItemTransactionSuccess,
  deleteAssetItemTransactionFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getAssetItemTransactionsAPi = ({ assetItemId, searchText, page }) => {
  if (assetItemId) {
    return get(
      `/api/v1/institute-asset/asset-item-transaction/?institute_asset_item=${
        assetItemId ? assetItemId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/institute-asset/asset-item-transaction/?search=${
        searchText ? searchText : ""
      }&page=${page ? page : 1}`
    )
  }
}
// const getVarisangyasCardsAPi = () => {
//   return get(`/api/v1/varisankya/varisankya/?cards=all`)
// }
const getAssetItemTransactionDetailsAPi = assetItemTransactionId => {
  return get(
    `/api/v1/institute-asset/asset-item-transaction/${assetItemTransactionId}/`
  )
}
const createAssetItemTransactionApi = ({ assetItemTransaction }) => {
  return post(
    "/api/v1/institute-asset/asset-item-transaction/",
    assetItemTransaction
  )
}
const updateAssetItemTransactionApi = ({
  assetItemTransactionId,
  assetItemTransaction,
}) => {
  return ApiPut(
    `/api/v1/institute-asset/asset-item-transaction/${assetItemTransactionId}/`,
    assetItemTransaction
  )
}
const deleteAssetItemTransactionApi = ({ assetItemTransactionId }) => {
  return del(
    `/api/v1/institute-asset/asset-item-transaction/${assetItemTransactionId}/`
  )
}

function* fetchAssetItemTransactions({ payload }) {
  try {
    const response = yield call(getAssetItemTransactionsAPi, payload)
    if (response && !response?.error) {
      yield put(getAssetItemTransactionsSuccess(response))
    } else {
      yield put(getAssetItemTransactionsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAssetItemTransactionsFail(error))
  }
}

// function* fetchVarisangyasCards() {
//   try {
//     const response = yield call(getVarisangyasCardsAPi)
//     if (response && !response?.error) {
//       yield put(getVarisangyaCardsSuccess(response))
//     } else {
//       yield put(getVarisangyaCardsFail(response))
//       errorNotification()
//     }
//   } catch (error) {
//     yield put(getVarisangyaCardsFail(error))
//   }
// }

function* fetchAssetItemTransactionDetail({ assetItemTransactionId }) {
  try {
    const response = yield call(
      getAssetItemTransactionDetailsAPi,
      assetItemTransactionId
    )
    if (response && !response?.error) {
      yield put(getAssetItemTransactionDetailSuccess(response))
    } else {
      yield put(getAssetItemTransactionDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAssetItemTransactionDetailFail(error))
  }
}
function* onCreateAssetItemTransaction({ payload }) {
  try {
    const response = yield call(createAssetItemTransactionApi, payload)
    if (response && !response?.error) {
      yield put(createAssetItemTransactionSuccess(response))
      doneNotification("Created Successfully")
    } else {
      yield put(createAssetItemTransactionFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createAssetItemTransactionFail(error))
    errorNotification()
  }
}

function* onUpdateAssetItemTransaction({ payload }) {
  try {
    const response = yield call(updateAssetItemTransactionApi, payload)
    if (response && !response?.error) {
      yield put(updateAssetItemTransactionSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateAssetItemTransactionFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateAssetItemTransactionFail(error))
    errorNotification()
  }
}

function* onDeleteAssetItemTransaction({ payload }) {
  try {
    const response = yield call(deleteAssetItemTransactionApi, payload)
    if (response && !response?.error) {
      yield put(deleteAssetItemTransactionSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteAssetItemTransactionFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteAssetItemTransactionFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* AssetItemTransactionsSaga() {
  yield takeEvery(GET_ASSET_ITEM_TRANSACTIONS, fetchAssetItemTransactions)
  // yield takeEvery(GET_VARISANGYA_CARDS, fetchVarisangyasCards)
  yield takeEvery(
    GET_ASSET_ITEM_TRANSACTION_DETAIL,
    fetchAssetItemTransactionDetail
  )
  yield takeEvery(CREATE_ASSET_ITEM_TRANSACTION, onCreateAssetItemTransaction)
  yield takeEvery(UPDATE_ASSET_ITEM_TRANSACTION, onUpdateAssetItemTransaction)
  yield takeEvery(DELETE_ASSET_ITEM_TRANSACTION, onDeleteAssetItemTransaction)
}

export default AssetItemTransactionsSaga
