import {
  GET_UNAPPROVED_FAMILIES,
  UPDATE_UNAPPROVED_FAMILY,
  DELETE_UNAPPROVED_FAMILY,
  GET_UNAPPROVED_FAMILY_DETAIL,
  GET_UNAPPROVED_FAMILIES_SUCCESS,
  GET_UNAPPROVED_FAMILIES_FAIL,
  GET_UNAPPROVED_FAMILY_DETAIL_SUCCESS,
  GET_UNAPPROVED_FAMILY_DETAIL_FAIL,
  UPDATE_UNAPPROVED_FAMILY_SUCCESS,
  UPDATE_UNAPPROVED_FAMILY_FAIL,
  DELETE_UNAPPROVED_FAMILY_SUCCESS,
  DELETE_UNAPPROVED_FAMILY_FAIL,
  GET_UNAPPROVED_FAMILIES_CARDS,
  GET_UNAPPROVED_FAMILIES_CARDS_SUCCESS,
  GET_UNAPPROVED_FAMILIES_CARDS_FAIL,
  GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD,
  GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_SUCCESS,
  GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  unapprovedFamilies: [],
  unapprovedFamiliesForDownload: [],
  unapprovedFamilyDetail: {},
  unapprovedFamiliesCards: {},
  error: {},
  loading: false,
}

const UnapprovedFamilies = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UNAPPROVED_FAMILIES:
    case GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD:
    case GET_UNAPPROVED_FAMILIES_CARDS:
    case UPDATE_UNAPPROVED_FAMILY:
    case DELETE_UNAPPROVED_FAMILY:
      return {
        ...state,
        loading: true,
      }
    case GET_UNAPPROVED_FAMILY_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_UNAPPROVED_FAMILIES_SUCCESS:
      return {
        ...state,
        unapprovedFamilies: action.payload,
        loading: false,
      }

    case GET_UNAPPROVED_FAMILIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        unapprovedFamiliesForDownload: action.payload,
        loading: false,
      }

    case GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_UNAPPROVED_FAMILY_DETAIL_SUCCESS:
      return {
        ...state,
        unapprovedFamilyDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_UNAPPROVED_FAMILY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_UNAPPROVED_FAMILIES_CARDS_SUCCESS:
      return {
        ...state,
        unapprovedFamiliesCards: action.payload,
        loading: false,
      }

    case GET_UNAPPROVED_FAMILIES_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_UNAPPROVED_FAMILY_SUCCESS:
      return {
        ...state,
        unapprovedFamilyDetail: action.payload,
        loading: false,
      }

    case UPDATE_UNAPPROVED_FAMILY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_UNAPPROVED_FAMILY_SUCCESS:
      return {
        ...state,
        // families: state.families.filter(
        //   family => family.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_UNAPPROVED_FAMILY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default UnapprovedFamilies
