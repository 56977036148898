import ReactApexChart from "react-apexcharts"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Spinner } from "reactstrap"

import "react-datepicker/dist/react-datepicker.css"

import { getDashboardDiseaseTypeData } from "store/Dashboard/actions"

const DiseaseChart = () => {
  const dispatch = useDispatch()

  const { loading, chartData, language } = useSelector(state => ({
    chartData: state.Dashboard.dashboardDiseaseTypeData,
    loading: state.Dashboard.dashboardLoading,
    language: state.Layout.language,
  }))
  useEffect(() => {
    dispatch(getDashboardDiseaseTypeData())
  }, [dispatch])

  // const data = Object.values(chartData)
  // const total = data?.reduce((a, b) => a + b, 0)
  // console.log(total)

  const series = [
    {
      name: `${language === "mal" ? "എയ്ഡ്സ്" : "AIDS"}`,
      data: [`${chartData?.["AIDS"]}`],
    },
    {
      name: `${language === "mal" ? "ആസ്ത്മ" : "Asthma"}`,
      data: [`${chartData?.["Asthma"]}`],
    },
    {
      name: `${language === "mal" ? "രക്തസമ്മര്ദ്ദം" : "Blood Pressure"}`,
      data: [`${chartData?.["Blood_Pressure"]}`],
    },
    {
      name: `${language === "mal" ? "കാൻസർ" : "Cancer"}`,
      data: [`${chartData?.["Cancer"]}`],
    },
    {
      name: `${language === "mal" ? "തിമിരം" : "Cataracts (Thimiram)"}`,
      data: [`${chartData?.["Cataracts_(Thimiram)"]}`],
    },
    {
      name: `${language === "mal" ? "പ്രമേഹം" : "Diabetes"}`,
      data: [`${chartData?.["Diabetes"]}`],
    },
    {
      name: `${language === "mal" ? "ഹൃദ്രോഗം" : "Heart Disease"}`,
      data: [`${chartData?.["Heart_Disease"]}`],
    },
    {
      name: `${language === "mal" ? "വൃക്കരോഗം" : "Kidney Disease"}`,
      data: [`${chartData?.["Kidney_Disease"]}`],
    },
    {
      name: `${language === "mal" ? "വാതം" : "Rheumatism (Vatham)"}`,
      data: [`${chartData?.["Rheumatism_(Vatham)"]}`],
    },
    {
      name: `${language === "mal" ? "ത്വക്ക് രോഗം" : "Skin Disease"}`,
      data: [`${chartData?.["Skin_Disease"]}`],
    },
    {
      name: `${language === "mal" ? "ഉദരരോഗം" : "Stomach Disease"}`,
      data: [`${chartData?.["Stomach_Disease"]}`],
    },
    {
      name: `${language === "mal" ? "ദന്തരോഗം" : "Teeth Disease"}`,
      data: [`${chartData?.["Teeth_Disease"]}`],
    },
    {
      name: `${language === "mal" ? "ക്ഷയരോഗം" : "Tuberculosis"}`,
      data: [`${chartData?.["Tuberculosis"]}`],
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#42d39f", "#03a96f", "#6ce9c8", "#d0f0de"],
    xaxis: {
      categories: [`${language === "mal" ? "രോഗങ്ങൾ" : "Diseases"}`],
    },
    yaxis: {
      title: {
        text: `${language === "mal" ? "എണ്ണം" : "Count"}`,
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "" + val + " Count"
        },
      },
    },
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle
            className="mb-1 d-flex "
            style={{ justifyContent: "space-between" }}
          >
            {language === "mal" ? "രോഗ ചാർട്ട്" : "Disease Chart"}
          </CardTitle>

          {loading ? (
            <>
              <Spinner type="info" color="info" />
            </>
          ) : (
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
            />
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default DiseaseChart
