import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_USER_DETAIL,
  CHECK_USER_PHONE_NUMBER,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER,
  CHECK_SURVEY_USER_PHONE_NUMBER,
  CHECK_GROUP_ADMIN_PHONE_NUMBER,
  CHECK_INSTITUTE_USER_PHONE_NUMBER,
  CHECK_CUSTOM_ID,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE,
  SET_USER_ROLE,
} from "./actionTypes"
import {
  getUserDetailSuccess,
  getUserDetailFail,
  checkUserPhoneNumberSuccess,
  checkUserPhoneNumberFail,
  checkFamilyMemberPhoneNumberSuccess,
  checkFamilyMemberPhoneNumberFail,
  checkSurveyUserPhoneNumberSuccess,
  checkSurveyUserPhoneNumberFail,
  checkGroupAdminPhoneNumberSuccess,
  checkGroupAdminPhoneNumberFail,
  checkInstituteUserPhoneNumberSuccess,
  checkInstituteUserPhoneNumberFail,
  checkCustomIdSuccess,
  checkCustomIdFail,
  checkFamilyMemberPhoneNumberUpdateSuccess,
  checkFamilyMemberPhoneNumberUpdateFail,
  setUserRoleSuccess,
  setUserRoleFail,
} from "./actions"

import { get, post } from "helpers/api_methods"
import {
  // doneNotification,
  errorNotification,
} from "components/Common/Notification"

const getUserDetailsAPi = () => {
  return get(`/api/v1/account/user`)
}

const checkUserPhoneNumberAPi = ({ checkData }) => {
  return post(`/api/v1/mahall/check_if_mahalluser_exists/`, checkData)
}

const checkFamilyMemberPhoneNumberAPi = ({ checkData }) => {
  return post(`/api/v1/familydetails/check_if_familymember_exists/`, checkData)
}

const checkFamilyMemberPhoneNumberUpdateAPi = ({
  checkData,
  familyMemberId,
}) => {
  return post(
    `/api/v1/familydetails/member_phone_exist_view/${familyMemberId}/`,
    checkData
  )
}

const checkCustomIdAPi = ({ checkData }) => {
  return post(`/api/v1/filter/mahall-custom-id-exist/`, checkData)
}

const checkSurveyUserPhoneNumberAPi = ({ checkData }) => {
  return post(`/api/v1/mahall/check_if_surveyuser_exists/`, checkData)
}

const checkGroupAdminPhoneNumberAPi = ({ checkData }) => {
  return post(`/api/v1/mahall/check_if_mahallgroupadmin_exists/`, checkData)
}

const checkInstituteUserPhoneNumberAPi = ({ checkData }) => {
  return post(`/api/v1/institute/check_if_institute_user_exists/`, checkData)
}

const setUserRoleApi = ({ userRole }) => {
  return post(`/api/v1/account/set-user-role`, userRole)
}

function* fetchUserDetail() {
  try {
    const response = yield call(getUserDetailsAPi)
    if (response) {
      yield put(getUserDetailSuccess(response))
    }
    // doneNotification()
  } catch (error) {
    yield put(getUserDetailFail(error))
    errorNotification()
  }
}

function* fetchCheckUserPhoneNumber({ payload }) {
  try {
    // console.log('payload: ', payload);
    const response = yield call(checkUserPhoneNumberAPi, payload)
    yield put(checkUserPhoneNumberSuccess(response))
    // doneNotification()
  } catch (error) {
    // console.log('error: ', error);
    yield put(checkUserPhoneNumberFail(error))
    errorNotification()
  }
}

function* fetchCheckFamilyMemberPhoneNumber({ payload }) {
  try {
    // console.log('payload: ', payload);
    const response = yield call(checkFamilyMemberPhoneNumberAPi, payload)
    yield put(checkFamilyMemberPhoneNumberSuccess(response))
    // doneNotification()
  } catch (error) {
    // console.log('error: ', error);
    yield put(checkFamilyMemberPhoneNumberFail(error))
    errorNotification()
  }
}

function* fetchCheckFamilyMemberPhoneNumberUpdate({ payload }) {
  try {
    // console.log('payload: ', payload);
    const response = yield call(checkFamilyMemberPhoneNumberUpdateAPi, payload)
    yield put(checkFamilyMemberPhoneNumberUpdateSuccess(response))
    // doneNotification()
  } catch (error) {
    // console.log('error: ', error);
    yield put(checkFamilyMemberPhoneNumberUpdateFail(error))
    errorNotification()
  }
}

function* fetchCheckCutomId({ payload }) {
  try {
    // console.log('payload: ', payload);
    const response = yield call(checkCustomIdAPi, payload)
    yield put(checkCustomIdSuccess(response))
    // doneNotification()
  } catch (error) {
    // console.log('error: ', error);
    yield put(checkCustomIdFail(error))
    errorNotification()
  }
}

function* fetchCheckSurveyUserPhoneNumber({ payload }) {
  try {
    // console.log('payload: ', payload);
    const response = yield call(checkSurveyUserPhoneNumberAPi, payload)
    yield put(checkSurveyUserPhoneNumberSuccess(response))
    // doneNotification()
  } catch (error) {
    // console.log('error: ', error);
    yield put(checkSurveyUserPhoneNumberFail(error))
    errorNotification()
  }
}

function* fetchCheckGroupAdminPhoneNumber({ payload }) {
  try {
    // console.log('payload: ', payload);
    const response = yield call(checkGroupAdminPhoneNumberAPi, payload)
    yield put(checkGroupAdminPhoneNumberSuccess(response))
    // doneNotification()
  } catch (error) {
    // console.log('error: ', error);
    yield put(checkGroupAdminPhoneNumberFail(error))
    errorNotification()
  }
}

function* fetchCheckInstituteUserPhoneNumber({ payload }) {
  try {
    // console.log('payload: ', payload);
    const response = yield call(checkInstituteUserPhoneNumberAPi, payload)
    yield put(checkInstituteUserPhoneNumberSuccess(response))
    // doneNotification()
  } catch (error) {
    // console.log('error: ', error);
    yield put(checkInstituteUserPhoneNumberFail(error))
    errorNotification()
  }
}

function* fetchSetUserRole({ payload }) {
  try {
    const response = yield call(setUserRoleApi, payload)
    yield put(setUserRoleSuccess(response))
    payload.history.push("/dashboard")
    // doneNotification('LoggedIn Successfully')
  } catch (error) {
    yield put(setUserRoleFail(error))
    errorNotification()
  }
}

function* UsersSaga() {
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(CHECK_USER_PHONE_NUMBER, fetchCheckUserPhoneNumber)
  yield takeEvery(
    CHECK_FAMILY_MEMBER_PHONE_NUMBER,
    fetchCheckFamilyMemberPhoneNumber
  )
  yield takeEvery(
    CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE,
    fetchCheckFamilyMemberPhoneNumberUpdate
  )
  yield takeEvery(CHECK_CUSTOM_ID, fetchCheckCutomId)
  yield takeEvery(
    CHECK_SURVEY_USER_PHONE_NUMBER,
    fetchCheckSurveyUserPhoneNumber
  )
  yield takeEvery(
    CHECK_GROUP_ADMIN_PHONE_NUMBER,
    fetchCheckGroupAdminPhoneNumber
  )
  yield takeEvery(
    CHECK_INSTITUTE_USER_PHONE_NUMBER,
    fetchCheckInstituteUserPhoneNumber
  )
  yield takeEvery(SET_USER_ROLE, fetchSetUserRole)
}

export default UsersSaga
