import React, { useEffect, useRef } from "react"
import { Button, Container, Row, Spinner } from "reactstrap"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
//actions
import moment from "moment"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"

import FamilyVarisangyaReceiptPdf from "../PdfDownloads/familyVarisangyaReceiptPdf"

import { getFamilyVarisangyaWalletView } from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Images

const FamilyVarisangyaTransactionPrint = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const pdfRef = useRef()

  console.log(params?.id)

  // const nocId = location?.search?.split("?nocId=")[1]

  const {
    familyVarisangyaWalletView,
    loading,
    // detailLoading,
    language,
    userDetail,
  } = useSelector(state => ({
    loading: state.NocCertificates.loading,
    detailLoading: state.MahallUsers.detailLoading,
    familyVarisangyaWalletView:
      state.FamilyVarisangyaWallets.familyVarisangyaWalletView,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
  }))

  console.log(familyVarisangyaWalletView)

  // const today = moment(new Date()).format('DD-MM-yyyy')

  // const handlePrint = () => {
  //   window.print()
  // }

  useEffect(() => {
    dispatch(getFamilyVarisangyaWalletView(params?.id))
  }, [])

  const downloadPdf = () => {
    const input = pdfRef.current
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL(`${userDetail?.mahall?.logo}`)
      const pdf = new jsPDF("p", "mm", "a4", true)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2
      const imgY = 30
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      )
      pdf.save(
        familyVarisangyaWalletView?.transaction_type === "credit"
          ? "receipt.pdf"
          : "voucher.pdf"
      )
    })
  }
  const printIcon = document.querySelector(".bg-khaf-blue print-button")

  const handlePrint = () => {
    if (printIcon) {
      printIcon.addEventListener("click", () => {
        window.print()
      })
    }
    window.print()
  }

  return (
    <>
      {/* <MetaTags>
        <title>Mahall User | Khaf </title>
      </MetaTags> */}

      <FamilyVarisangyaReceiptPdf pdfRef={pdfRef} />

      <div className="page-content">
        <Breadcrumbs title="" breadcrumbItem="Receipt" />
        <Container fluid>
          <div className="container-fluid">
            {loading ? (
              <Spinner color="secondary" className="d-block m-auto" />
            ) : (
              <div className="certificate-layout">
                <div className="d-flex justify-content-end">
                  <div className="me-1">
                    <Button
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                      className="btn-sm bg-khaf-blue print-button px-3 "
                      onClick={() => handlePrint()}
                    >
                      {language === "mal" ? "അച്ചടിക്കുക" : "Print"}
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="btn-sm bg-khaf-blue print-button"
                      onClick={downloadPdf}
                    >
                      <i
                        className="bx bx-download mx-2"
                        style={{ fontSize: "15px", color: "" }}
                      />
                      {language === "mal" ? "ഡൗൺലോഡ്" : "Download"}
                    </Button>
                  </div>
                </div>
                <Row className="mt-2">
                  <div style={{ border: "2px solid black" }}>
                    <div className="d-flex header-div">
                      <img
                        src={
                          userDetail?.mahall?.logo && userDetail?.mahall?.logo
                        }
                        alt=""
                        className="img-thumbnail reciept-logo"
                        style={{ width: "100px", height: "100px" }}
                      />
                      <div className="text-black text-heading">
                        <h2 className="text-black">
                          {language === "mal"
                            ? userDetail?.mahall?.mahall_malayalam_name
                              ? userDetail?.mahall?.mahall_malayalam_name
                              : userDetail?.mahall?.mahall_english_name
                            : userDetail?.mahall?.mahall_english_name}
                        </h2>
                        <p style={{ marginBottom: "0" }}>
                          {language === "mal"
                            ? userDetail?.mahall?.village_malayalam_name
                              ? userDetail?.mahall?.village_malayalam_name
                              : userDetail?.mahall?.village_english_name
                            : userDetail?.mahall?.village_english_name}
                          , {userDetail?.mahall?.post_office},{" "}
                          {userDetail?.mahall?.pin_code}
                        </p>
                      </div>
                    </div>
                    <h2 className="text-black pt-4 text-center">
                      {familyVarisangyaWalletView?.trn_type === "credit"
                        ? language === "mal"
                          ? "രസീത്"
                          : "RECEIPT"
                        : language === "mal"
                        ? "വൗച്ചർ"
                        : "VOUCHER"}
                    </h2>
                    <div className="middle-div">
                      <div className="sub-middle-div">
                        <div className="d-flex font-p">
                          <h5 className="font-h5">
                            {familyVarisangyaWalletView?.trn_type === "credit"
                              ? language === "mal"
                                ? "രസീത് നം"
                                : "Receipt No."
                              : language === "mal"
                              ? "വൗച്ചർ നം"
                              : "Voucher No."}
                          </h5>
                          <h5
                            style={{
                              fontWeight: 400,
                              color: "#000",
                            }}
                          >
                            {" "}
                            {familyVarisangyaWalletView?.var_no}
                          </h5>
                        </div>
                        <div className="d-flex font-p">
                          <h5 className="font-h5">
                            {language === "mal" ? "തീയതി" : "Date"}
                          </h5>
                          <h5
                            style={{
                              fontWeight: 400,
                              color: "#000",
                            }}
                          >
                            :{" "}
                            {moment(
                              familyVarisangyaWalletView?.date_added
                            ).format("DD-MM-yyyy")}
                          </h5>
                        </div>
                      </div>
                      <div className="d-flex font-p">
                        <h5 className="font-h5">
                          {language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall Id"}
                        </h5>
                        <h5
                          style={{
                            fontWeight: 400,
                            color: "#000",
                          }}
                        >
                          : {familyVarisangyaWalletView?.mahall_custom_id}
                        </h5>
                      </div>
                      <div className="d-flex font-p">
                        <h5 className="font-h5">
                          {language === "mal" ? "പേര്" : "Name"}
                        </h5>
                        {/* <p>
                          {" "}
                          : {familyVarisangyaWalletView?.family_name} (
                          {familyVarisangyaWalletView?.family_head_name})
                        </p> */}
                        <h5
                          style={{
                            fontWeight: 400,
                            color: "#000",
                          }}
                        >
                          {" "}
                          : {familyVarisangyaWalletView?.family_name} (
                          {familyVarisangyaWalletView?.family_head_name})
                        </h5>
                      </div>
                      <div className="d-flex pb-4 font-p">
                        <h5 className="font-h5">
                          {language === "mal" ? "വിശദാംശങ ൾ" : "Particulars"}
                        </h5>
                        <h5
                          style={{
                            fontWeight: 400,
                            color: "#000",
                          }}
                        >
                          {" "}
                          : {familyVarisangyaWalletView?.remark}
                        </h5>
                      </div>
                      <div className="d-flex font-p">
                        <h5 className="font-h5">
                          {language === "mal" ? "തുക" : "Amount"}
                        </h5>
                        <h5
                          style={{
                            fontWeight: 400,
                            color: "#000",
                          }}
                        >
                          {" "}
                          : {familyVarisangyaWalletView?.amount}
                        </h5>
                      </div>
                    </div>
                    {familyVarisangyaWalletView?.trn_type === "debit" ? (
                      <div className="d-flex justify-content-around pt-5 pb-3 text-black font-p">
                        <p>
                          {language === "mal" ? "സ്വീകർത്താവ്" : "Reciever"}
                        </p>
                        <p>
                          {language === "mal"
                            ? "സെക്രട്ടറി / പ്രസിഡന്റ്"
                            : "Secretary / President"}
                        </p>
                      </div>
                    ) : (
                      <p
                        className="d-flex justify-content-end pt-5 pb-3 text-black font-p"
                        style={{ paddingRight: "170px" }}
                      >
                        {language === "mal"
                          ? "സെക്രട്ടറി / പ്രസിഡന്റ്"
                          : "Secretary / President"}
                      </p>
                    )}
                  </div>
                </Row>
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default FamilyVarisangyaTransactionPrint

FamilyVarisangyaTransactionPrint.propTypes = {
  history: PropTypes.object,
}
