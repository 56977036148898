import {
  GET_NIKAH_REGISTRATIONS,
  GET_NIKAH_REGISTRATIONS_SUCCESS,
  GET_NIKAH_REGISTRATIONS_FAIL,
  GET_NIKAH_REGISTRATIONS_CARDS,
  GET_NIKAH_REGISTRATIONS_CARDS_SUCCESS,
  GET_NIKAH_REGISTRATIONS_CARDS_FAIL,
  GET_NIKAH_REGISTRATION_DETAIL,
  GET_NIKAH_REGISTRATION_DETAIL_SUCCESS,
  GET_NIKAH_REGISTRATION_DETAIL_FAIL,
  CREATE_NIKAH_REGISTRATION,
  CREATE_NIKAH_REGISTRATION_SUCCESS,
  CREATE_NIKAH_REGISTRATION_FAIL,
  UPDATE_NIKAH_REGISTRATION,
  UPDATE_NIKAH_REGISTRATION_SUCCESS,
  UPDATE_NIKAH_REGISTRATION_FAIL,
  DELETE_NIKAH_REGISTRATION,
  DELETE_NIKAH_REGISTRATION_SUCCESS,
  DELETE_NIKAH_REGISTRATION_FAIL,
  GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD,
  GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS,
  GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  nikahRegistrations: [],
  nikahRegistrationsCards: {},
  nikahRegistrationsForDownload: [],
  nikahRegistrationDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdNikahRegistrations: {},
}

const NikahRegistrations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NIKAH_REGISTRATIONS:
    case GET_NIKAH_REGISTRATIONS_CARDS:
    case GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD:
    case UPDATE_NIKAH_REGISTRATION:
    case CREATE_NIKAH_REGISTRATION:
    case DELETE_NIKAH_REGISTRATION:
      return {
        ...state,
        loading: true,
      }
    case GET_NIKAH_REGISTRATION_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_NIKAH_REGISTRATIONS_SUCCESS:
      return {
        ...state,
        nikahRegistrations: action.payload,
        loading: false,
      }
    case GET_NIKAH_REGISTRATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_NIKAH_REGISTRATIONS_CARDS_SUCCESS:
      return {
        ...state,
        nikahRegistrationsCards: action.payload,
        loading: false,
      }
    case GET_NIKAH_REGISTRATIONS_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        nikahRegistrationsForDownload: action.payload,
        loading: false,
      }
    case GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_NIKAH_REGISTRATION_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdNikahRegistrations: action.payload,
        loading: false,
      }

    case CREATE_NIKAH_REGISTRATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_NIKAH_REGISTRATION_DETAIL_SUCCESS:
      return {
        ...state,
        nikahRegistrationDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_NIKAH_REGISTRATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_NIKAH_REGISTRATION_SUCCESS:
      return {
        ...state,
        nikahRegistrationDetail: action.payload,
        loading: false,
      }

    case UPDATE_NIKAH_REGISTRATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_NIKAH_REGISTRATION_SUCCESS:
      return {
        ...state,
        // states: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // nikahRegistrations: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // nikahRegistrations: action.payload,
        loading: false,
      }

    case DELETE_NIKAH_REGISTRATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default NikahRegistrations
