import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
//actions
import { debounce } from "lodash"

import {
  createGroupAdmin,
  getGroup,
  checkGroupAdminPhoneNumber,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateGroupAdmin = ({ history }) => {
  const dispatch = useDispatch()

  const {
    loading,
    // error,
    groups,
    language,
    groupAdminPhoneNumberAvailability,
    groupAdminPhoneCheckLoading,
  } = useSelector(state => ({
    loading: state.Mahalls.loading,
    error: state.Mahalls.error,
    groups: state.Groups.groups,
    language: state.Layout.language,
    groupAdminPhoneNumberAvailability:
      state.Users.groupAdminPhoneNumberAvailability,
    groupAdminPhoneCheckLoading: state.Users.groupAdminPhoneCheckLoading,
  }))

  const [group, setGroup] = useState("Search Group")
  const [groupId, setGroupId] = useState("")
  const [searchText, setSearchText] = useState("")

  const [checkValue, setCheckValue] = useState()

  const handleCheckGroupAdminPhoneNumberAvailability = v => {
    if (v.length == 10) {
      const checkGroupAdminPhoneNumberData = {
        phone: v,
      }
      dispatch(checkGroupAdminPhoneNumber(checkGroupAdminPhoneNumberData))
      setCheckValue(v)
    }
    // console.log("phone: ", v)
  }

  useEffect(() => {
    dispatch(getGroup(searchText))
  }, [dispatch, searchText])

  function handlerFinalValue(event) {
    // dispatch(getGroupAdmin(event.value))
    setGroupId(event.value)
    setGroup(event.label)
  }

  const Options = [
    {
      options: groups?.results?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results.mahallgroup_malayalam_name
              ? results.mahallgroup_malayalam_name
              : results.mahallgroup_english_name
            : results.mahallgroup_english_name,
        value: results.id,
      })),
    },
  ]

  const debounceGroupSearch = debounce(value => setSearchText(value), 600)

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    // console.log(values)
    const createGroupAdminData = {
      full_name: values.full_name,
      email: values.email,
      phone: values.phone,
      mahallgroup: groupId,
    }
    if (!groupAdminPhoneNumberAvailability) return
    dispatch(createGroupAdmin(createGroupAdminData, history))
  }

  return (
    <>
      <MetaTags>
        <title>Group Admins | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഗ്രൂപ്പ് അഡ്മിൻമാർ" : "Group Admins"}
          breadcrumbItem={
            language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create Group Admin"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Fill this form</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Full Name"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="full_name"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </Col>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "മൊബൈൽ നമ്പർ"
                                : "Mobile Number"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="phone"
                                type="text"
                                // label={language === "mal" ? "മൊബൈൽ നമ്പർ" : "Mobile Number"}
                                validate={{
                                  required: { value: true },
                                  maxLength: {
                                    value: 10,
                                    errorMessage: `${
                                      language === "mal"
                                        ? "ഫോൺ നമ്പർ 10 അക്കങ്ങൾ ആയിരിക്കണം"
                                        : "phone number must be 10 digits"
                                    }`,
                                  },
                                }}
                                onChange={v => {
                                  handleCheckGroupAdminPhoneNumberAvailability(
                                    v.target.value
                                  )
                                  setCheckValue(v.target.value)
                                }}
                              />
                            </Col>
                            {checkValue &&
                              checkValue?.length == 10 &&
                              (!groupAdminPhoneCheckLoading ? (
                                groupAdminPhoneNumberAvailability ? (
                                  <p className="text-success">
                                    {language === "mal"
                                      ? "ലഭ്യമാണ്"
                                      : "Available"}
                                  </p>
                                ) : (
                                  <p className="text-danger">
                                    {language === "mal"
                                      ? "ലഭ്യമല്ല"
                                      : "Not Available"}
                                  </p>
                                )
                              ) : (
                                <></>
                              ))}
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="email"
                                type="email"
                                label={language === "mal" ? "ഇ_മെയിൽ" : "Email"}
                              />
                            </Col>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "ഗ്രൂപ്പ്" : "Group"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <Select
                                onInputChange={debounceGroupSearch}
                                placeholder={group}
                                value={group}
                                options={Options}
                                classNamePrefix="select2-selection"
                                onChange={handlerFinalValue}
                                type="text"
                                // style={{ borderRadius: "8px", height: "40px" }}
                                title="Group"
                                required="required"
                              />
                            </Col>
                          </div>
                        </div>
                      </Row>
                      <div className="d-flex justify-content-end mt-5">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Submit
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateGroupAdmin

CreateGroupAdmin.propTypes = {
  history: PropTypes.object,
}
