import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"
import PropTypes from "prop-types"

import {
  getDeathRegistrations,
  // deleteDeathRegistration,
  getDeathRegistrationsCards,
  getDeathRegistrationsForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"

const AllMahallDeathRegistrations = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [modalDeathDownload, setModalDeathDownload] = useState(false)
  console.log(setLimit)
  const {
    deathRegistrations,
    loading,
    deathRegistrationCards,
    language,
    deathRegistrationsForDownload,
  } = useSelector(state => ({
    deathRegistrations: state.DeathRegistrations?.deathRegistrations,
    loading: state.DeathRegistrations?.loading,
    deathRegistrationCards:
      state.DeathRegistrations?.deathRegistrationsForDownload?.cards,
    language: state.Layout.language,
    deathRegistrationsForDownload:
      state.DeathRegistrations?.deathRegistrationsForDownload?.results,
  }))

  //pages
  const totalPages = Math.ceil(deathRegistrations?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getDeathRegistrationsForDownload())
  }, [dispatch])

  // useEffect(() => {
  //   if (modalDeathDownload) {
  //     dispatch(getDeathRegistrationsForDownload())
  //   }
  // }, [dispatch, modalDeathDownload])

  useEffect(() => {
    dispatch(getDeathRegistrations(searchText, pageSend()))
  }, [dispatch, page, searchText])

  useEffect(() => {
    dispatch(getDeathRegistrationsCards())
  }, [dispatch])

  // const handleDelete = id => {
  //   dispatch(deleteDeathRegistration(id, history))
  // }

  const cardData = [
    {
      title: language === "mal" ? "ആകെ മരണങ്ങൾ" : "Total Deaths",
      cardValue: deathRegistrationCards?.total_deaths ?? 0,
      iconClass: "error-circle",
      routeLink: "#!",
      loading: loading,
      alt: "No Death Registrations",
    },
    {
      title: language === "mal" ? "ഈ മാസം" : "This Month",
      cardValue: deathRegistrationCards?.total_deaths_this_month ?? 0,
      iconClass: "error-circle",
      routeLink: "#!",
      loading: loading,
      alt: "No Deaths",
    },
    // {
    //   title: "Unpaid",
    //   cardValue: DeathRegistrationCards?.total_fee_not_paid_Deaths,
    //   iconClass: "check-circle",
    //   routeLink: "#!",
    //   loading: loading,
    //   alt: "No unpaid Deaths",
    // },
  ]

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "mahall_custom_id",
      text: language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID",
    },
    {
      dataField: "death_date",
      text: language === "mal" ? "മരണ തീയതി" : "Date",
    },
    {
      dataField: "family_member_name",
      text: language === "mal" ? "പേര്" : "Name",
    },
    {
      dataField: "reason",
      text: language === "mal" ? "മരണ കാരണം" : "Reason",
    },
    // {
    //   dataField: "qabar",
    //   text: language === 'mal' ? "വധു" : "Bride",
    // },
    {
      dataField: "action",
      text: language === "mal" ? "" : "",
    },
  ]

  const deathRegistrationsData = map(
    deathRegistrations?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: (pageSend() - 1) * limit + index + 1,

      date: moment(item?.date_added).format("DD/MM/YYYY"),
      fee: (
        <>
          <Badge
            className={
              "font-size-12 badge-soft-" +
              `${item?.is_paid == true ? "success" : "danger"}`
            }
            color={"primary"}
            pill
          >
            {item?.fee ? item?.fee : "0"}
          </Badge>
        </>
      ),
      action: (
        <div className="m-0">
          {/* <Link to={`/deaths/${item?.id}`} className="btn-sm">
          <i
            className="bx bx-show me-2"
            style={{ color: "green", fontSize: "18px" }}
          />
        </Link> */}
          <Link to={`/death/update/${item?.id}`} className="m-0">
            <Button type="button" color="primary" className=" btn w-xs btn-sm">
              {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
              {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
            </Button>
          </Link>

          {/* <Button
          type="button"
          color="white"
          className=" m-0 btn "
          onClick={() => { handleDelete(item.id) }}
        >
          <i className="bx bx-trash me-2" style={{ color: "red", fontSize: "18px" }} />
        </Button> */}
        </div>
      ),

      statusData: (
        <Badge
          className={
            "font-size-12 badge-soft-" +
            `${
              item?.status == "new"
                ? "success"
                : item?.status == "pending"
                ? "warning"
                : item?.status == "checked"
                ? "primary"
                : item?.status == "confirmed"
                ? "secondary"
                : item?.status == "closed"
                ? "danger"
                : ""
            }`
          }
          color={"primary"}
          pill
        >
          {item?.status}
        </Badge>
      ),
    })
  )

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    {
      label: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
      key: "mahall_custom_id",
    },
    {
      label: `${language === "mal" ? "മരണ തീയതി" : "Death Date"}`,
      key: "death_date",
    },
    {
      label: `${language === "mal" ? "കുടുംബാംഗം" : "Member"}`,
      key: "family_member_name",
    },
    { label: `${language === "mal" ? "മരണ കാരണം" : "Reason"}`, key: "reason" },
    {
      label: `${language === "mal" ? "ഖബർ വിവരങ്ങൾ" : "Qabar Details"}`,
      key: "qabar",
    },
    // { label: "Listed Date", key: "date_added" },
  ]

  const deathRegistrationsExcelData = map(
    deathRegistrationsForDownload,
    (item, index) => ({
      ...item,
      no: (pageSend() - 1) * limit + index + 1,
      mahall_custom_id: item.mahall_custom_id,
      death_date:
        item.death_date && moment(item?.death_date).format("DD/MM/YYYY"),
      family_member_name: item.family_member_name,
      reason: item.reason,
      qabar: item.qabar,
      // date_added: item?.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceDeathRegistrationSearch = debounce(
    value => setSearchText(value),
    600
  )

  const fileName = "khaf-smart-mahall-death-registrations-list.csv"
  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalDeathDownload}
        onCloseclick={() => setModalDeathDownload(false)}
        excelData={deathRegistrationsExcelData}
        headers={headers}
        list={deathRegistrationsForDownload}
        fileName={fileName}
        loading={loading}
      />
      <Row>
        {cardData?.map((item, key) => (
          <Col lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink && item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue >= 0 && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      {/* <p className="m-0 text-info">
                        {item.cardValue >= 0 ? <></> : item.alt}
                      </p> */}
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Col sm="12" md="12" lg="8">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={deathRegistrationsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceDeathRegistrationSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <div className="col-md-8 d-flex justify-content-end">
                        <div>
                          <Button
                            style={{
                              width: language === "mal" ? "150px" : "130px",
                              border: "bold",
                            }}
                            className="btn-md me-2 mt-0  bg-white text-khaf"
                            onClick={() => setModalDeathDownload(true)}
                          >
                            <i
                              className="bx bx-download mx-1"
                              style={{ fontSize: "18px", color: "#009846" }}
                            />
                            {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                          </Button>
                          <Link
                            to="/death/register"
                            style={{
                              width: language === "mal" ? "220px" : "160px",
                            }}
                            className="w-md btn btn-success me-0"
                          >
                            {language === "mal"
                              ? "+ മരണ രജിസ്ട്രേഷൻ "
                              : "+ Death Registration"}
                          </Link>
                        </div>
                      </div>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {deathRegistrations && deathRegistrations?.length <= 0 && (
                      <p
                        style={{ fontSize: "15px" }}
                        className="text-center text-info"
                      >
                        {language === "mal"
                          ? "റെജിസ്ട്രേഷനുകൾ ഒന്നും ഇല്ല"
                          : "No Registrations Yet"}
                      </p>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllMahallDeathRegistrations

AllMahallDeathRegistrations.propTypes = {
  history: PropTypes.object,
}
