import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"

import { getInstituteUsers } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"

const AllMahallInstituteUsers = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { instituteUsers, loading, language } = useSelector(state => ({
    instituteUsers: state.InstituteUsers.instituteUsers,
    loading: state.InstituteUsers.loading,
    language: state.Layout.language,
  }))

  //pages
  const totalPages = Math.ceil(instituteUsers?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getInstituteUsers(searchText, pageSend()))
  }, [dispatch, page, searchText])

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "സീരിയൽ നമ്പർ." : "Serial No.",
      sort: true,
    },
    {
      dataField: "full_name",
      text: language === "mal" ? "പേര്" : "Name",
      sort: true,
    },
    {
      dataField: "institute_type",
      text: language === "mal" ? "തരം" : "Type",
      sort: true,
    },
    {
      dataField: "phone",
      text: language === "mal" ? "ഫോൺ" : "Phone",
    },
    {
      dataField: "email",
      text: language === "mal" ? "ഇ-മെയിൽ" : "Email",
    },
    {
      dataField: "last_login",
      text: language === "mal" ? "അവസാന ലോഗിൻ" : "Last Login",
    },
    {
      dataField: "is_active",
      text: language === "mal" ? "സ്റ്റാറ്റസ്" : "Status",
    },
    {
      dataField: "action",
      text: language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action",
    },
  ]

  // const handleSwitchChange = (e, id) => {
  //   dispatch(changeInsti(id, e.target.checked))
  // }

  const instituteUsersData = map(instituteUsers?.results, (item, index) => ({
    ...item,
    key: index,
    no: index + 1,
    last_login:
      item?.last_login &&
      moment(item?.last_login).format("DD/MM/YYYY - h:mm A"),
    is_active: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            // onChange={() => {
            //   handleSwitchChange(item?._id)
            // }}
            checked={item?.is_active}
            // disabled={userDetail?.mahall?.version === "basic"}
          />
          {/* <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.is_active ? `Active` : `Inactive`}
          </label> */}
        </div>
      </>
    ),
    action: (
      // <div>
      //   <Link to={`/instituteuser/update/${item?.id}`} className="btn-light btn-sm">
      //     View
      //   </Link>
      // </div>
      <Link to={`/instituteuser/update/${item?.id}`} className="m-0">
        <Button type="button" color="primary" className=" btn w-xs btn-sm">
          {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
          {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
        </Button>
      </Link>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  return (
    <React.Fragment>
      <Row>
        <div className="col-md-6 mb-3">
          <Link
            to={`/instituteuser/create`}
            type="submit"
            style={{ width: language === "mal" ? "200px" : "170px" }}
            className="w-md btn btn-success d-block"
          >
            {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New Institute User"}
          </Link>
        </div>
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={instituteUsersData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {instituteUsers && instituteUsers?.results?.length <= 0 && (
                      <p
                        style={{ fontSize: "15px" }}
                        className="text-center text-info"
                      >
                        {language === "mal"
                          ? "യൂസറുകൾ ഒന്നും ഇല്ല"
                          : "No Users Yet"}
                      </p>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllMahallInstituteUsers
