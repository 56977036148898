import React from "react"
import { Image } from "react-bootstrap"
import { Col, Container, Row } from "reactstrap"
// import img1 from "assets/images/khaf/img1.png"
// import { useDispatch } from 'react-redux'

import { useLanguage } from "../Language/SwitchLanguage"

import img2 from "assets/images/khaf/img2.png"
import img3 from "assets/images/khaf/alikutty-usthad-2.png"
// import { useWindowSize } from 'components/Common/useWindowSize'

function AboutUs() {
  // const dispatch = useDispatch()
  const text = useLanguage()

  // const size = useWindowSize()

  return (
    <React.Fragment>
      <section className="section bg-grey" id="about">
        <Container>
          <Row className="m-0">
            <div className="">
              <Row className="text-center mb-5">
                <h2 className="mb-5">{text?.boardMembers}</h2>
              </Row>
              <Row className="mt-4">
                <Col className="" md="6">
                  <Image
                    src={img3}
                    className="rounded-circle mx-auto d-block"
                    alt=""
                  />
                  <h4 className="text-center pt-3 font-size-16">
                    {text?.name1}
                  </h4>
                  <h5 className="text-muted text-center font-size-14">
                    {text?.chief_patron}
                  </h5>
                </Col>
                <Col className="img2" md="6">
                  <Image
                    src={img2}
                    className="rounded-circle mx-auto d-block"
                  />
                  <h4 className="text-center pt-3 font-size-16">
                    {text?.name2}
                  </h4>
                  <h5 className="text-muted text-center font-size-14">
                    {text?.chairman}
                  </h5>
                </Col>
              </Row>
              <Row className="mt-5 ">
                <Col className="mt-2" md="6">
                  {/* <Image src={img1} className="rounded-circle mx-auto d-block" /> */}
                  <h4 className="text-center pt-3 font-size-16">
                    {text?.name3}
                  </h4>
                  <h5 className="text-muted text-center font-size-14">
                    {text?.convenor}
                  </h5>
                </Col>
                <Col className="mt-2" md="6">
                  {/* <Image src={img1} className="rounded-circle mx-auto d-block" /> */}
                  <h4 className="text-center pt-3 font-size-16">
                    {text?.name4}
                  </h4>
                  <h5 className="text-muted text-center font-size-14">
                    {text?.jo_convenor}
                  </h5>
                </Col>
              </Row>
              {/* <Row className="mt-5 d-flex align-items-center ">
                                
                                <Col md='6' className="">
                                    <h1 className="text-khaf">{text.introduction_head}</h1>
                                    <p className="mt-3" style={{lineHeight: "1.8"}}>
                                        {text.introduction}
                                    </p>
                                </Col>
                                <Col md='6'>
                                    {size?.width < 768 ? (
                                        <div className="d-flex justify-content-center">
                                            <iframe className="rounded" width="640" height="360" src="https://www.youtube.com/embed/Sf7i0ronxtU"
                                                title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                        </div>) : (
                                        <div className="d-flex justify-content-center">
                                            <iframe className="rounded" width="640" height="300" src="https://www.youtube.com/embed/Sf7i0ronxtU"
                                                title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                        </div>
                                    )}
                                </Col>
                                
                            </Row> */}
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default AboutUs
