import {
  GET_DEATH_REGISTRATIONS,
  GET_DEATH_REGISTRATIONS_SUCCESS,
  GET_DEATH_REGISTRATIONS_FAIL,
  GET_DEATH_REGISTRATIONS_CARDS,
  GET_DEATH_REGISTRATIONS_CARDS_SUCCESS,
  GET_DEATH_REGISTRATIONS_CARDS_FAIL,
  GET_DEATH_REGISTRATION_DETAIL,
  GET_DEATH_REGISTRATION_DETAIL_SUCCESS,
  GET_DEATH_REGISTRATION_DETAIL_FAIL,
  CREATE_DEATH_REGISTRATION,
  CREATE_DEATH_REGISTRATION_SUCCESS,
  CREATE_DEATH_REGISTRATION_FAIL,
  UPDATE_DEATH_REGISTRATION,
  UPDATE_DEATH_REGISTRATION_SUCCESS,
  UPDATE_DEATH_REGISTRATION_FAIL,
  DELETE_DEATH_REGISTRATION,
  DELETE_DEATH_REGISTRATION_SUCCESS,
  DELETE_DEATH_REGISTRATION_FAIL,
  GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD,
  GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS,
  GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  deathRegistrations: [],
  deathRegistrationsForDownload: [],
  deathRegistrationsCards: {},
  deathRegistrationDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdDeathRegistrations: {},
}

const DeathRegistrations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEATH_REGISTRATIONS:
    case GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD:
    case GET_DEATH_REGISTRATIONS_CARDS:
    case UPDATE_DEATH_REGISTRATION:
    case CREATE_DEATH_REGISTRATION:
    case DELETE_DEATH_REGISTRATION:
      return {
        ...state,
        loading: true,
      }
    case GET_DEATH_REGISTRATION_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_DEATH_REGISTRATIONS_SUCCESS:
      return {
        ...state,
        deathRegistrations: action.payload,
        loading: false,
      }
    case GET_DEATH_REGISTRATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        deathRegistrationsForDownload: action.payload,
        loading: false,
      }
    case GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DEATH_REGISTRATIONS_CARDS_SUCCESS:
      return {
        ...state,
        deathRegistrationsCards: action.payload,
        loading: false,
      }
    case GET_DEATH_REGISTRATIONS_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_DEATH_REGISTRATION_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdDeathRegistrations: action.payload,
        loading: false,
      }

    case CREATE_DEATH_REGISTRATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DEATH_REGISTRATION_DETAIL_SUCCESS:
      return {
        ...state,
        deathRegistrationDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_DEATH_REGISTRATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_DEATH_REGISTRATION_SUCCESS:
      return {
        ...state,
        deathRegistrationDetail: action.payload,
        loading: false,
      }

    case UPDATE_DEATH_REGISTRATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_DEATH_REGISTRATION_SUCCESS:
      return {
        ...state,
        // states: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // nikahRegistrations: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // nikahRegistrations: action.payload,
        loading: false,
      }

    case DELETE_DEATH_REGISTRATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default DeathRegistrations
