import { API_URL, ApiPut, get, patch, post } from "helpers/api_methods"
import {
  createSupportTicketFail,
  createSupportTicketItemFail,
  createSupportTicketItemSuccess,
  createSupportTicketSuccess,
  getSupportTicketDetailsFail,
  getSupportTicketDetailsSuccess,
  getSupportTicketItemFail,
  getSupportTicketItemSuccess,
  getSupportTicketsFail,
  getSupportTicketsSuccess,
  updateSupportTicketFail,
  updateSupportTicketSuccess,
} from "./actions"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  CREATE_SUPPORT_TICKET,
  CREATE_SUPPORT_TICKET_ITEM,
  GET_SUPPORT_TICKETS,
  GET_SUPPORT_TICKET_DETAILS,
  GET_SUPPORT_TICKET_ITEM,
  UPDATE_SUPPORT_TICKET,
} from "./actionTypes"
import axios from "axios"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const createSupportTicketApi = () => {
  return post("/api/v1/support/mahall-support-ticket/")
}

const supportTicketApi = ({ search, page }) => {
  return get(
    `/api/v1/support/mahall-support-ticket/?page=${page ? page : 1}&search=${
      search && search
    }`
  )
}
const supportTicketDetailsAPi = supportTicketId => {
  return get(`/api/v1/support/mahall-support-ticket/${supportTicketId}`)
}

const updateSupportTicketApi = ({ supportTicketId, updateValues }) => {
  console.log(updateValues)
  return patch(
    `/api/v1/support/mahall-support-ticket/${supportTicketId}/`,
    updateValues
  )
}

// support ticket item

const getSupportTicketItemApi = supportTicketId => {
  return get(
    `/api/v1/support/mahall-support-ticket-item/?ticket=${supportTicketId}`
  )
}

// const createSupportTicketItemApi = ({ supportTicketId, createData }) => {
//   return post(
//     `/api/v1/support/mahall-support-ticket-item/?ticket=${supportTicketId}`,
//     createData
//   )
// }

const { token } = useGetToken()
const { role } = useGetRole()

const createSupportTicketItemApi = async datas => {
  console.log(datas)

  const formData = new FormData()

  if (datas?.attachment) {
    if (datas.attachment instanceof Blob) {
      formData.append("attachment", datas.attachment, datas.attachment.name)
    } else {
      const blob = new Blob([datas.attachment], {
        type: "application/octet-stream",
      })
      formData.append("attachment", blob, datas.attachment.name)
    }
  }

  formData.append("message", datas?.message)

  try {
    let response = await axios({
      method: "post",
      url: `${API_URL}/api/v1/support/mahall-support-ticket-item/?ticket=${datas?.id}`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "token " + token,
        Role: role,
      },
      data: formData,
      // finalValue,
    })
    return response.data
  } catch (err) {
    console.log(err)
    return err?.response?.data
  }
}

function* onCreateSupportTicket({ payload }) {
  try {
    const response = yield call(createSupportTicketApi, payload)
    yield put(createSupportTicketSuccess(response))
    doneNotification()
  } catch (error) {
    console.log(error)
    yield put(createSupportTicketFail(error))
    errorNotification()
  }
}
function* fetchSupportTicket({ payload }) {
  try {
    const response = yield call(supportTicketApi, payload)
    yield put(getSupportTicketsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getSupportTicketsFail(error))
  }
}

function* fetchSupportTicketDetails({ payload }) {
  try {
    const response = yield call(supportTicketDetailsAPi, payload)
    yield put(getSupportTicketDetailsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getSupportTicketDetailsFail(error))
  }
}

function* OnUpdateSupportTicket({ payload }) {
  try {
    const response = yield call(updateSupportTicketApi, payload)
    yield put(updateSupportTicketSuccess(response))
    doneNotification("Support Ticket Status Updated Successfully")
  } catch (error) {
    yield put(updateSupportTicketFail(error))
    errorNotification()
  }
}

function* fetchSupportTicketItem({ payload }) {
  try {
    const response = yield call(getSupportTicketItemApi, payload)
    yield put(getSupportTicketItemSuccess(response))
  } catch (error) {
    yield put(getSupportTicketItemFail(error))
    errorNotification()
  }
}

function* onCreateSupportTicketItem({ payload }) {
  try {
    const response = yield call(createSupportTicketItemApi, payload)
    yield put(createSupportTicketItemSuccess(response))
    doneNotification("Mesage Sended Successfully")
    if (payload?.messageSendSuccessWindowRefresh) {
      payload?.messageSendSuccessWindowRefresh()
    }
  } catch (error) {
    console.log(error)
    yield put(createSupportTicketItemFail(error))
    errorNotification()
  }
}

function* SupportTicketSaga() {
  yield takeEvery(CREATE_SUPPORT_TICKET, onCreateSupportTicket)
  yield takeEvery(GET_SUPPORT_TICKETS, fetchSupportTicket)
  yield takeEvery(GET_SUPPORT_TICKET_DETAILS, fetchSupportTicketDetails)
  yield takeEvery(UPDATE_SUPPORT_TICKET, OnUpdateSupportTicket)

  // support item
  yield takeEvery(GET_SUPPORT_TICKET_ITEM, fetchSupportTicketItem)
  yield takeEvery(CREATE_SUPPORT_TICKET_ITEM, onCreateSupportTicketItem)
}

export default SupportTicketSaga
