import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADMIN_ALL_DONATIONS,
  ADMIN_DONATION_DETAILS,
  CREATE_TRANSACTIONS,
  GET_TRANSACTION_DETAILS,
} from "./actionTypes"
import {
  adminAllDonationFail,
  adminAllDonationSuccess,
  adminDonationDetails,
  adminDonationDetailsFail,
  adminDonationDetailsSuccess,
  createTransationsFail,
  createTransationsSuccess,
  getTransactionDetailsFail,
  getTransactionDetailsSuccess,
} from "./actions"
import { get, post } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"

const createTransationsApi = ({ createData }) => {
  return post("/api/v1/payment/payment/", createData)
}
const getTransationDetailsApi = transactionId => {
  return get(`/api/v1/payment/payment/${transactionId}/`)
}

const adminAllDonationsApi = page => {
  return get(`/api/v1/payment/payment/?page=${page ? page : 1}`)
}
const adminDonationDetailsApi = donationId => {
  console.log(donationId)
  // return get(`/api/v1/payment/payment/${donationId}/`)
}

function* onCreateTransations({ payload }) {
  try {
    const response = yield call(createTransationsApi, payload)
    yield put(createTransationsSuccess(response))
    doneNotification()
    console.log(response)
    const transactionId = localStorage?.setItem("transationId", response?.id)
    // payload.history.push('/transation-details')
  } catch (error) {
    console.log(error)
    yield put(createTransationsFail(error))
    errorNotification()
  }
}

function* fetchTransationDetails({ payload }) {
  try {
    const response = yield call(getTransationDetailsApi, payload)
    yield put(getTransactionDetailsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getTransactionDetailsFail(error))
  }
}

function* fetchDonationList({ payload }) {
  try {
    const response = yield call(adminAllDonationsApi, payload)
    yield put(adminAllDonationSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(adminAllDonationFail(error))
  }
}

function* fetchDonationDetails({ payload }) {
  try {
    const response = yield call(adminDonationDetailsApi, payload)
    yield put(adminDonationDetailsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(adminDonationDetailsFail(error))
  }
}

function* TransationsSaga() {
  yield takeEvery(CREATE_TRANSACTIONS, onCreateTransations)
  yield takeEvery(GET_TRANSACTION_DETAILS, fetchTransationDetails)
  yield takeEvery(ADMIN_ALL_DONATIONS, fetchDonationList)
  yield takeEvery(ADMIN_DONATION_DETAILS, fetchDonationDetails)
}

export default TransationsSaga
