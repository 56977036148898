import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ENQUIRIES,
  GET_ENQUIRIES_CARDS,
  GET_ENQUIRY_DETAIL,
  CREATE_ENQUIRY,
  UPDATE_ENQUIRY,
  DELETE_ENQUIRY,
  GET_ENQUIRIES_FOR_DOWNLOAD,
} from "./actionTypes"
import {
  getEnquiriesSuccess,
  getEnquiriesFail,
  getEnquiriesCardsSuccess,
  getEnquiriesCardsFail,
  getEnquiryDetailSuccess,
  getEnquiryDetailFail,
  createEnquirySuccess,
  createEnquiryFail,
  updateEnquirySuccess,
  updateEnquiryFail,
  deleteEnquirySuccess,
  deleteEnquiryFail,
  getEnquiriesForDownloadSuccess,
  getEnquiriesForDownloadFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getEnquiriesAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/enquiry/enquiry/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/enquiry/enquiry/?page=${page ? page : 1}`)
  }
}
const getEnquiriesForDownloadAPi = () => {
  return get(`/api/v1/enquiry/enquiry/`)
}
const getEnquiriesCardsAPi = () => {
  return get(`/api/v1/enquiry/enquiry/?cards=all`)
}
const getEnquiryDetailsAPi = enquiryId => {
  return get(`/api/v1/enquiry/enquiry/${enquiryId}/`)
}
const createEnquiryApi = ({ enquiry }) => {
  return post("/api/v1/enquiry/enquiry/", enquiry)
}
const updateEnquiryApi = ({ enquiryId, enquiry }) => {
  return ApiPut(`/api/v1/enquiry/enquiry/${enquiryId}/`, enquiry)
}
const deleteEnquiryApi = ({ enquiryId }) => {
  return del(`/api/v1/enquiry/enquiry/${enquiryId}/`)
}

function* fetchEnquiries({ payload }) {
  try {
    const response = yield call(getEnquiriesAPi, payload)
    if (response && !response?.error) {
      yield put(getEnquiriesSuccess(response))
    } else {
      yield put(getEnquiriesFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getEnquiriesFail(error))
  }
}

function* fetchEnquiriesForDownload({ payload }) {
  try {
    const response = yield call(getEnquiriesForDownloadAPi, payload)
    if (response && !response?.error) {
      yield put(getEnquiriesForDownloadSuccess(response))
    } else {
      yield put(getEnquiriesForDownloadFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getEnquiriesForDownloadFail(error))
  }
}

function* fetchEnquiriesCards() {
  try {
    const response = yield call(getEnquiriesCardsAPi)
    if (response && !response?.error) {
      yield put(getEnquiriesCardsSuccess(response))
    } else {
      yield put(getEnquiriesCardsFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getEnquiriesCardsFail(error))
  }
}

function* fetchEnquiryDetail({ enquiryId }) {
  try {
    const response = yield call(getEnquiryDetailsAPi, enquiryId)
    if (response && !response?.error) {
      yield put(getEnquiryDetailSuccess(response))
    } else {
      yield put(getEnquiryDetailFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    yield put(getEnquiryDetailFail(error))
  }
}
function* onCreateEnquiry({ payload }) {
  try {
    const response = yield call(createEnquiryApi, payload)
    if (response && !response?.error) {
      yield put(createEnquirySuccess(response))
      if (payload.history) {
        payload.history.push("/enquiries")
      }
      doneNotification()
    } else {
      yield put(createEnquiryFail(response?.error))
      errorNotification()
    }
    // swal({
    //   title: "Enquiry Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createEnquiryFail(error))
    errorNotification()
  }
}

function* onUpdateEnquiry({ payload }) {
  try {
    const response = yield call(updateEnquiryApi, payload)
    if (response && !response?.error) {
      payload.history.push("/enquiries")
      yield put(updateEnquirySuccess(response))
      doneNotification()
    } else {
      yield put(updateEnquiryFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    // console.log("error: ", error)
    yield put(updateEnquiryFail(error))
    errorNotification()
  }
}

function* onDeleteEnquiry({ payload }) {
  try {
    const response = yield call(deleteEnquiryApi, payload)
    if (response && !response?.error) {
      // payload.history.push("/enquiries")
      yield put(deleteEnquirySuccess(response))
      doneNotification()
      // get enquiries
      try {
        const response = yield call(getEnquiriesAPi, { page: 1 })
        yield put(getEnquiriesSuccess(response))
      } catch (error) {
        yield put(getEnquiriesFail(error))
      }
    } else {
      yield put(updateEnquiryFail(response?.error))
      errorNotification()
    }
  } catch (error) {
    console.log("error: ", error)
    errorNotification()
    yield put(deleteEnquiryFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification() {
  Notification({
    type: "success",
    message: "Done",
    title: "",
  })
}

function* EnquiriesSaga() {
  yield takeEvery(GET_ENQUIRIES, fetchEnquiries)
  yield takeEvery(GET_ENQUIRIES_FOR_DOWNLOAD, fetchEnquiriesForDownload)
  yield takeEvery(GET_ENQUIRIES_CARDS, fetchEnquiriesCards)
  yield takeEvery(GET_ENQUIRY_DETAIL, fetchEnquiryDetail)
  yield takeEvery(CREATE_ENQUIRY, onCreateEnquiry)
  yield takeEvery(UPDATE_ENQUIRY, onUpdateEnquiry)
  yield takeEvery(DELETE_ENQUIRY, onDeleteEnquiry)
}

export default EnquiriesSaga
