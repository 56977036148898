import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_DEATH_REGISTRATIONS,
  GET_DEATH_REGISTRATIONS_CARDS,
  GET_DEATH_REGISTRATION_DETAIL,
  CREATE_DEATH_REGISTRATION,
  UPDATE_DEATH_REGISTRATION,
  DELETE_DEATH_REGISTRATION,
  GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD,
} from "./actionTypes"
import {
  getDeathRegistrationsSuccess,
  getDeathRegistrationsFail,
  getDeathRegistrationsCardsSuccess,
  getDeathRegistrationsCardsFail,
  getDeathRegistrationDetailSuccess,
  getDeathRegistrationDetailFail,
  createDeathRegistrationSuccess,
  createDeathRegistrationFail,
  updateDeathRegistrationSuccess,
  updateDeathRegistrationFail,
  deleteDeathRegistrationSuccess,
  deleteDeathRegistrationFail,
  getDeathRegistrationsForDownloadSuccess,
  getDeathRegistrationsForDownloadFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getDeathRegistrationsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/death/death/?search=${searchText && searchText}&page=${
        page && page
      }`
    )
  } else {
    return get(`/api/v1/death/death/?page=${page ? page : 1}`)
  }
}
const getDeathRegistrationsForDownloadAPi = () => {
  return get(`/api/v1/death/death/`)
}
const getDeathRegistrationsCardsAPi = () => {
  return get(`/api/v1/death/death/?cards=all`)
}
const getDeathRegistrationDetailsAPi = deathRegistrationId => {
  return get(`/api/v1/death/death/${deathRegistrationId}/`)
}
const createDeathRegistrationsApi = ({ deathRegistration }) => {
  return post("/api/v1/death/death/", deathRegistration)
}
const updateDeathRegistrationsApi = ({
  deathRegistrationId,
  deathRegistration,
}) => {
  return ApiPut(
    `/api/v1/death/death/${deathRegistrationId}/`,
    deathRegistration
  )
}
const deleteDeathRegistrationApi = ({ deathRegistrationsId }) => {
  return del(`/api/v1/death/death/${deathRegistrationsId}/`)
}

function* fetchDeathRegistrations({ payload }) {
  try {
    const response = yield call(getDeathRegistrationsAPi, payload)
    if (response && !response?.error) {
      yield put(getDeathRegistrationsSuccess(response))
    } else {
      yield put(getDeathRegistrationsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getDeathRegistrationsFail(error))
  }
}

function* fetchDeathRegistrationsForDownload() {
  try {
    const response = yield call(getDeathRegistrationsForDownloadAPi)
    if (response && !response?.error) {
      yield put(getDeathRegistrationsForDownloadSuccess(response))
    } else {
      yield put(getDeathRegistrationsForDownloadFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getDeathRegistrationsForDownloadFail(error))
  }
}

function* fetchDeathRegistrationsCards() {
  try {
    const response = yield call(getDeathRegistrationsCardsAPi)
    if (response && !response?.error) {
      yield put(getDeathRegistrationsCardsSuccess(response))
    } else {
      yield put(getDeathRegistrationsCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getDeathRegistrationsCardsFail(error))
  }
}

function* fetchDeathRegistrationDetail({ deathRegistrationId }) {
  try {
    const response = yield call(
      getDeathRegistrationDetailsAPi,
      deathRegistrationId
    )
    if (response && !response?.error) {
      yield put(getDeathRegistrationDetailSuccess(response))
    } else {
      yield put(getDeathRegistrationDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getDeathRegistrationDetailFail(error))
  }
}
function* onCreateDeathRegistrations({ payload }) {
  try {
    const response = yield call(createDeathRegistrationsApi, payload)
    if (response && !response?.error) {
      yield put(createDeathRegistrationSuccess(response))
      if (payload.history) {
        payload.history.push("/Deaths")
      }
      doneNotification("Registered Successfully")
    } else {
      yield put(createDeathRegistrationFail(response))
      errorNotification(response?.error)
    }
    // swal({
    //   title: "DeathRegistrations Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createDeathRegistrationFail(error))
    errorNotification()
  }
}

function* onUpdateDeathRegistrations({ payload }) {
  try {
    const response = yield call(updateDeathRegistrationsApi, payload)
    if (response && !response?.error) {
      payload.history.push("/Deaths")
      yield put(updateDeathRegistrationSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateDeathRegistrationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    // console.log("error: ", error)
    yield put(updateDeathRegistrationFail(error))
    errorNotification()
  }
}

function* onDeleteDeathRegistrations({ payload }) {
  try {
    const response = yield call(deleteDeathRegistrationApi, payload)
    if (response && !response?.error) {
      // payload.history.push("/Deaths")
      yield put(deleteDeathRegistrationSuccess(response))
      doneNotification("Deleted Successfully")
      // get DeathRegistrations
      try {
        const response = yield call(getDeathRegistrationsAPi, { page: 1 })
        yield put(getDeathRegistrationsSuccess(response))
      } catch (error) {
        yield put(getDeathRegistrationsFail(error))
      }
    } else {
      yield put(deleteDeathRegistrationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    errorNotification()
    yield put(deleteDeathRegistrationFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* DeathRegistrationsSaga() {
  yield takeEvery(GET_DEATH_REGISTRATIONS, fetchDeathRegistrations)
  yield takeEvery(
    GET_DEATH_REGISTRATIONS_FOR_DOWNLOAD,
    fetchDeathRegistrationsForDownload
  )
  yield takeEvery(GET_DEATH_REGISTRATIONS_CARDS, fetchDeathRegistrationsCards)
  yield takeEvery(GET_DEATH_REGISTRATION_DETAIL, fetchDeathRegistrationDetail)
  yield takeEvery(CREATE_DEATH_REGISTRATION, onCreateDeathRegistrations)
  yield takeEvery(UPDATE_DEATH_REGISTRATION, onUpdateDeathRegistrations)
  yield takeEvery(DELETE_DEATH_REGISTRATION, onDeleteDeathRegistrations)
}

export default DeathRegistrationsSaga
