import {
  GET_ASSETS,
  UPDATE_ASSET,
  CREATE_ASSET,
  DELETE_ASSET,
  GET_ASSET_DETAIL,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_FAIL,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_FAIL,
  GET_ASSET_DETAIL_SUCCESS,
  GET_ASSET_DETAIL_FAIL,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAIL,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  assets: [],
  assetDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdAsset: {},
}

const Assets = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case GET_VARISANGYA_CARDS:
    case GET_ASSETS:
    case UPDATE_ASSET:
    case CREATE_ASSET:
    case DELETE_ASSET:
      return {
        ...state,
        loading: true,
      }
    case GET_ASSET_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_ASSETS_SUCCESS:
      return {
        ...state,
        assets: action.payload,
        loading: false,
      }
    case GET_ASSETS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // case GET_VARISANGYA_CARDS_SUCCESS:
    //   return {
    //     ...state,
    //     varisangyaCards: action.payload,
    //     loading: false,
    //   }
    // case GET_VARISANGYA_CARDS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //     loading: false,
    //   }

    case CREATE_ASSET_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        assets: {
          ...state.assets,
          results: [...state.assets.results, action.payload],
        },
        createdAsset: action.payload,
        loading: false,
      }

    case CREATE_ASSET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ASSET_DETAIL_SUCCESS:
      return {
        ...state,
        assetDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_ASSET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_ASSET_SUCCESS:
      return {
        ...state,
        assetDetail: action.payload,
        assets: {
          results: state.assets.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_ASSET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_ASSET_SUCCESS:
      return {
        ...state,
        assets: {
          results: state.assets.results.filter(
            asset => asset.id !== action.payload
          ),
        },
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        loading: false,
      }

    case DELETE_ASSET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Assets
