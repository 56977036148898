import {
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  CHECK_USER_PHONE_NUMBER,
  CHECK_USER_PHONE_NUMBER_SUCCESS,
  CHECK_USER_PHONE_NUMBER_FAIL,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_SUCCESS,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_FAIL,
  CHECK_SURVEY_USER_PHONE_NUMBER,
  CHECK_SURVEY_USER_PHONE_NUMBER_SUCCESS,
  CHECK_SURVEY_USER_PHONE_NUMBER_FAIL,
  CHECK_GROUP_ADMIN_PHONE_NUMBER,
  CHECK_GROUP_ADMIN_PHONE_NUMBER_SUCCESS,
  CHECK_GROUP_ADMIN_PHONE_NUMBER_FAIL,
  CHECK_INSTITUTE_USER_PHONE_NUMBER,
  CHECK_INSTITUTE_USER__PHONE_NUMBER_SUCCESS,
  CHECK_INSTITUTE_USER__PHONE_NUMBER_FAIL,
  CHECK_CUSTOM_ID,
  CHECK_CUSTOM_ID_SUCCESS,
  CHECK_CUSTOM_ID_FAIL,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_SUCCESS,
  CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_FAIL,
  SET_USER_ROLE,
  SET_USER_ROLE_SUCCESS,
  SET_USER_ROLE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  userDetail: {},
  userPhoneNumberAvailability: false,
  familyMemberPhoneNumberAvailability: false,
  familyMemberPhoneNumberUpdateAvailability: false,
  customIdAvailability: false,
  surveyUserPhoneNumberAvailability: false,
  groupAdminPhoneNumberAvailability: false,
  instituteUserPhoneNumberAvailability: false,
  error: {},
  loading: false,
  checkLoading: false,
  memberPhoneCheckLoading: false,
  memberPhoneUpdateCheckLoading: false,
  customIdCheckLoading: false,
  surveyUserPhoneCheckLoading: false,
  groupAdminPhoneCheckLoading: false,
  instituteUserPhoneCheckLoading: false,
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER_ROLE:
      return {
        ...state,
        loading: true,
      }

    case GET_USER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case CHECK_USER_PHONE_NUMBER:
      return {
        ...state,
        checkLoading: true,
      }

    case CHECK_USER_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        userPhoneNumberAvailability: action.payload,
        checkLoading: false,
      }

    case CHECK_USER_PHONE_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        checkLoading: false,
      }

    case CHECK_FAMILY_MEMBER_PHONE_NUMBER:
      return {
        ...state,
        memberPhoneCheckLoading: true,
      }

    case CHECK_FAMILY_MEMBER_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        familyMemberPhoneNumberAvailability: action.payload,
        memberPhoneCheckLoading: false,
      }

    case CHECK_FAMILY_MEMBER_PHONE_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        memberPhoneCheckLoading: false,
      }

    case CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE:
      return {
        ...state,
        memberPhoneUpdateCheckLoading: true,
      }

    case CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_SUCCESS:
      return {
        ...state,
        familyMemberPhoneNumberUpdateAvailability: action.payload,
        memberPhoneUpdateCheckLoading: false,
      }

    case CHECK_FAMILY_MEMBER_PHONE_NUMBER_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        memberPhoneUpdateCheckLoading: false,
      }

    case CHECK_CUSTOM_ID:
      return {
        ...state,
        customIdCheckLoading: true,
      }

    case CHECK_CUSTOM_ID_SUCCESS:
      return {
        ...state,
        customIdAvailability: action.payload,
        customIdCheckLoading: false,
      }

    case CHECK_CUSTOM_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        customIdCheckLoading: false,
      }

    case CHECK_SURVEY_USER_PHONE_NUMBER:
      return {
        ...state,
        surveyUserPhoneCheckLoading: true,
      }

    case CHECK_SURVEY_USER_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        surveyUserPhoneNumberAvailability: action.payload,
        surveyUserPhoneCheckLoading: false,
      }

    case CHECK_SURVEY_USER_PHONE_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        surveyUserPhoneCheckLoading: false,
      }

    case CHECK_GROUP_ADMIN_PHONE_NUMBER:
      return {
        ...state,
        groupAdminPhoneCheckLoading: true,
      }

    case CHECK_GROUP_ADMIN_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        groupAdminPhoneNumberAvailability: action.payload,
        groupAdminPhoneCheckLoading: false,
      }

    case CHECK_GROUP_ADMIN_PHONE_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        groupAdminPhoneCheckLoading: false,
      }

    case CHECK_INSTITUTE_USER_PHONE_NUMBER:
      return {
        ...state,
        instituteUserPhoneCheckLoading: true,
      }

    case CHECK_INSTITUTE_USER__PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        instituteUserPhoneNumberAvailability: action.payload,
        instituteUserPhoneCheckLoading: false,
      }

    case CHECK_INSTITUTE_USER__PHONE_NUMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        instituteUserPhoneCheckLoading: false,
      }

    case SET_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: true,
      }

    case SET_USER_ROLE_FAIL:
      return {
        ...state,
        loading: false,
      }
  }
  return state
}
export default Users
