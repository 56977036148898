import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_FAMILIES,
  GET_FAMILIES_FOR_DOWNLOAD,
  GET_FILTERED_FAMILIES,
  GET_FAMILIES_CARDS,
  GET_FAMILY_DROPDOWN,
  GET_ALL_FAMILY_DROPDOWN,
  GET_FAMILY_DETAIL,
  CREATE_FAMILY,
  UPDATE_FAMILY,
  DELETE_FAMILY,
} from "./actionTypes"
import {
  getFamiliesSuccess,
  getFamiliesFail,
  getFamiliesForDownloadSuccess,
  getFamiliesForDownloadFail,
  getFilteredFamiliesSuccess,
  getFilteredFamiliesFail,
  getFamiliesCardsSuccess,
  getFamiliesCardsFail,
  getFamilyDropdownSuccess,
  getFamilyDropdownFail,
  getAllFamilyDropdownSuccess,
  getAllFamilyDropdownFail,
  getFamilyDetailSuccess,
  getFamilyDetailFail,
  createFamilySuccess,
  createFamilyFail,
  updateFamilySuccess,
  updateFamilyFail,
  deleteFamilySuccess,
  deleteFamilyFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getFamiliesAPi = ({ searchText, page, mahallId, sort, pageSize }) => {
  if (mahallId) {
    return get(
      `/api/v1/familydetails/family/?mahall=${mahallId && mahallId}&search=${
        searchText && searchText
      }&page=${page ? page : 1}&page_size=${pageSize ? pageSize : 10}`
    )
  } else if (sort) {
    return get(
      `/api/v1/familydetails/family/?ordering=${sort && sort}&search=${
        searchText && searchText
      }&page=${page ? page : 1}&page_size=${pageSize ? pageSize : 10}`
    )
  } else {
    return get(
      `/api/v1/familydetails/family/?search=${searchText && searchText}&page=${
        page ? page : 1
      }&page_size=${pageSize ? pageSize : 10}`
    )
  }
}

const getFamiliesForDownloadAPi = ({ filterData }) => {
  return post(`/api/v1/filter/family-filter/`, filterData)
}

const getFilteredFamiliesAPi = ({ page, pageSize, filterData }) => {
  console.log("ameen1", filterData)

  return post(
    `/api/v1/filter/family-filter/?page=${page ? page : 1}&page_size=${
      pageSize ? pageSize : 10
    }`,
    filterData
  )
}

const getFamiliesCardsAPi = () => {
  return get(`/api/v1/familydetails/family/?cards=all`)
}

const getFamilyDropdownAPi = searchText => {
  return get(
    `/api/v1/familydetails/family_dropdown?search=${searchText && searchText}`
  )
}
const getAllFamilyDropdownAPi = ({ searchText, sort }) => {
  if (sort) {
    return get(
      `/api/v1/familydetails/family_dropdown/?zakat_eligible=${
        sort && sort
      }&search=${searchText && searchText}`
    )
  } else {
    return get(
      `/api/v1/familydetails/family_dropdown/?search=${
        searchText && searchText
      }`
    )
  }
}
const getFamilyDetailsAPi = familyId => {
  return get(`/api/v1/familydetails/family/${familyId}/`)
}
const createFamilyApi = ({ family }) => {
  return post("/api/v1/familydetails/family/", family)
}
const updateFamilyApi = ({ familyId, family }) => {
  return ApiPut(`/api/v1/familydetails/family/${familyId}/`, family)
}
const deleteFamilyApi = ({ familyId }) => {
  return del(`/api/v1/familydetails/family/${familyId}/`)
}

function* fetchFamilies({ payload }) {
  try {
    const response = yield call(getFamiliesAPi, payload)
    if (response && !response?.error) {
      yield put(getFamiliesSuccess(response))
    } else {
      yield put(getFamiliesFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamiliesFail(error))
    errorNotification(error)
  }
}

function* fetchFamiliesForDownload({ payload }) {
  try {
    const response = yield call(getFamiliesForDownloadAPi, payload)
    if (response && !response?.error) {
      yield put(getFamiliesForDownloadSuccess(response))
    } else {
      yield put(getFamiliesForDownloadFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamiliesForDownloadFail(error))
  }
}

function* fetchFilteredFamilies({ payload }) {
  try {
    const response = yield call(getFilteredFamiliesAPi, payload)
    if (response && !response?.error) {
      yield put(getFilteredFamiliesSuccess(response))
    } else {
      yield put(getFilteredFamiliesFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFilteredFamiliesFail(error))
  }
}

function* fetchFamiliesCards() {
  try {
    const response = yield call(getFamiliesCardsAPi)
    if (response && !response?.error) {
      yield put(getFamiliesCardsSuccess(response))
    } else {
      yield put(getFamiliesCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamiliesCardsFail(error))
  }
}

function* fetchFamilyDropdown({ payload }) {
  try {
    const response = yield call(getFamilyDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyDropdownSuccess(response))
    } else {
      yield put(getFamilyDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyDropdownFail(error))
  }
}

function* fetchAllFamilyDropdown({ payload }) {
  try {
    const response = yield call(getAllFamilyDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getAllFamilyDropdownSuccess(response))
    } else {
      yield put(getAllFamilyDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAllFamilyDropdownFail(error))
  }
}

function* fetchFamilyDetail({ familyId }) {
  try {
    const response = yield call(getFamilyDetailsAPi, familyId)
    if (response && !response?.error) {
      yield put(getFamilyDetailSuccess(response))
    } else {
      yield put(getFamilyDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyDetailFail(error))
  }
}
function* onCreateFamily({ payload }) {
  try {
    const response = yield call(createFamilyApi, payload)
    if (response && !response?.error) {
      yield put(createFamilySuccess(response))
      // payload.history.push(`/families/${response?.id}`)
      doneNotification("Created Successfully")
    } else {
      yield put(createFamilyFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createFamilyFail(error))
    errorNotification(error)
    // if (error?.response?.data?.error[0]) {
    //   yield put(createFamilyFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function* onUpdateFamily({ payload }) {
  try {
    const response = yield call(updateFamilyApi, payload)
    console.log(response?.error)
    if (response && !response?.error) {
      yield put(updateFamilySuccess(response))
      payload.history.push(`/families/${response?.id}`)
      doneNotification("Updated Successfully")
    } else {
      yield put(updateFamilyFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateFamilyFail(error))
    errorNotification(error)
    // if (error?.response?.data?.error[0]) {
    //   yield put(updateFamilyFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function* onDeleteFamily({ payload }) {
  try {
    const response = yield call(deleteFamilyApi, payload)
    payload.history.push("/families")
    if (response && !response?.error) {
      yield put(deleteFamilySuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteFamilyFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(deleteFamilyFail(error))
    // if (error?.response?.data?.error[0]) {
    //   yield put(deleteFamilyFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* FamiliesSaga() {
  yield takeEvery(GET_FAMILIES, fetchFamilies)
  yield takeEvery(GET_FAMILIES_FOR_DOWNLOAD, fetchFamiliesForDownload)
  yield takeEvery(GET_FILTERED_FAMILIES, fetchFilteredFamilies)
  yield takeEvery(GET_FAMILIES_CARDS, fetchFamiliesCards)
  yield takeEvery(GET_FAMILY_DETAIL, fetchFamilyDetail)
  yield takeEvery(GET_FAMILY_DROPDOWN, fetchFamilyDropdown)
  yield takeEvery(GET_ALL_FAMILY_DROPDOWN, fetchAllFamilyDropdown)
  yield takeEvery(CREATE_FAMILY, onCreateFamily)
  yield takeEvery(UPDATE_FAMILY, onUpdateFamily)
  yield takeEvery(DELETE_FAMILY, onDeleteFamily)
}

export default FamiliesSaga
