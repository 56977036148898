import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"
import PropTypes from "prop-types"

import { getAdmins, getEnquiriesCards } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"

const AllAdmins = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const { enquiries, loading, admins } = useSelector(state => ({
    enquiries: state.Enquiries.enquiries,
    loading: state.Enquiries.loading,
    enquiriesCards: state.Enquiries.enquiriesCards,
    admins: state.Admins.admins,
  }))

  console.log(admins)

  //pages
  const totalPages = Math.ceil(enquiries?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getAdmins(searchText, pageSend()))
  }, [dispatch, page, searchText])

  useEffect(() => {
    dispatch(getEnquiriesCards())
  }, [dispatch])

  // const handleDelete = id => {
  //   dispatch(deleteEnquiry(id, history))
  // }

  const columns = [
    {
      dataField: "no",
      text: "No.",
    },
    {
      dataField: "full_name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "last_login",
      text: "Last Login",
    },
    {
      dataField: "phone",
      text: "Phone Number",
    },
    {
      dataField: "is_active",
      text: "status",
    },
  ]

  const adminData = map(admins?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    last_login: moment(item?.last_login).format("DD/MM/YYYY - h:mm A"),
    is_active: (
      <div className="form-check form-switch form-switch-md mb-3">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizemd"
          checked={item?.is_active}
        />
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceEnquirySearch = debounce(value => setSearchText(value), 600)

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        <Col sm="12" md="12" lg="12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={adminData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceEnquirySearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      {/* <div className="col-md-8">
                        <Link to="/enquiry/create"
                          style={{ width: "120px" }}
                          className="w-md btn btn-success m-auto me-0 d-block"
                        >
                          + New Enquiry
                        </Link>
                      </div> */}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllAdmins

AllAdmins.propTypes = {
  history: PropTypes.object,
}
