import {
  GET_LEDGERS,
  GET_LEDGERS_SUCCESS,
  GET_LEDGERS_FAIL,
  GET_LEDGER_DETAIL,
  GET_LEDGER_DETAIL_SUCCESS,
  GET_LEDGER_DETAIL_FAIL,
  CREATE_LEDGER,
  CREATE_LEDGER_SUCCESS,
  CREATE_LEDGER_FAIL,
  UPDATE_LEDGER_SUCCESS,
  UPDATE_LEDGER_FAIL,
  DELETE_LEDGER,
  DELETE_LEDGER_SUCCESS,
  DELETE_LEDGER_FAIL,
  GET_LEDGER_DROPDOWN,
  GET_LEDGER_DROPDOWN_SUCCESS,
  GET_LEDGER_DROPDOWN_FAIL,
  GET_LEDGERS_FOR_DOWNLOAD,
  GET_LEDGERS_FOR_DOWNLOAD_SUCCESS,
  GET_LEDGERS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  ledgers: {
    count: "",
    results: {
      ledgers: [],
    },
  },
  ledgersForDownload: [],
  searchLedgers: [],
  ledgerDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdLedger: {},
}

const Ledgers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEDGERS:
    case GET_LEDGERS_FOR_DOWNLOAD:
    case CREATE_LEDGER:
    case DELETE_LEDGER:
    case GET_LEDGER_DROPDOWN:
      return {
        ...state,
        loading: true,
      }
    case GET_LEDGER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_LEDGERS_SUCCESS:
      return {
        ...state,
        ledgers: action.payload,
        loading: false,
      }

    case GET_LEDGERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_LEDGERS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        ledgersForDownload: action.payload,
        loading: false,
      }

    case GET_LEDGERS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_LEDGER_SUCCESS:
      return {
        ...state,
        ledgers: {
          ...state.ledgers,
          results: {
            ...state.ledgers.results,
            ledgers: [...state.ledgers.results.ledgers, action.payload],
          },
        },
        createdLedger: action.payload,
        loading: false,
      }

    case CREATE_LEDGER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_LEDGER_DETAIL_SUCCESS:
      return {
        ...state,
        ledgerDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_LEDGER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_LEDGER_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchLedgers: action.payload,
        loading: false,
      }

    case GET_LEDGER_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_LEDGER_SUCCESS:
      return {
        ...state,
        // ledgerDetail: action.payload,
        ledgerDetail: {
          ...state.ledgerDetail,
          ledgers: {
            ...(state.ledgerDetail.ledgers?.id.toString() ===
            action.payload.id.toString()
              ? { ...action.payload }
              : state.ledgerDetail.ledgers),
          },
        },
        loading: false,
      }

    case UPDATE_LEDGER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_LEDGER_SUCCESS:
      return {
        ...state,
        ledgers: action.payload,
        // ledgers: state.ledgers.filter(
        //   ledger => ledger.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_LEDGER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Ledgers
