import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import propTypes from "prop-types"
import { Modal, Button } from "reactstrap"

import { getLedgerItemCategoryDropdown } from "store/actions"
import LetterHead from "components/Common/Letterhead/letterHead"

const UiModalCertificate = ({ show, onCloseclick }) => {
  const dispatch = useDispatch()
  const modalRef = useRef(null)

  const { institute } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    institute: state.Ledgers.ledgerDetail.ledgers?.institute,
  }))

  // console.log(ledgerDetail);

  // const today = moment(new Date()).format("yyyy-MM-DD")

  const [searchText] = useState("")

  useEffect(() => {
    if (institute) {
      dispatch(getLedgerItemCategoryDropdown(institute, searchText))
    }
  }, [institute, searchText, dispatch])

  const handlePrint = () => {
    window.print()
    // const content = modalRef.current.innerHTML;
    // const newWindow = window.open();
    // newWindow.document.write(content);
    // newWindow.print();
    // newWindow.close();
  }

  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={show}
          toggle={onCloseclick}
          centered={true}
          ref={modalRef}
        >
          <div className="modal-header">
            {/* <h5 className="modal-title mt-0">{language === "mal" ? "എൻട്രി ചേർക്കുക" : "Add Entry"}</h5> */}
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <LetterHead />
          </div>
          <Button onClick={handlePrint}>Download</Button>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalCertificate
UiModalCertificate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
