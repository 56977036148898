import {
  GET_DISTRICTS_SUCCESS,
  GET_DISTRICTS_FAIL,
  GET_DISTRICT_DETAIL_SUCCESS,
  GET_DISTRICT_DETAIL_FAIL,
  GET_DISTRICT_DROPDOWN_SUCCESS,
  GET_DISTRICT_DROPDOWN_FAIL,
  CREATE_DISTRICT_SUCCESS,
  CREATE_DISTRICT_FAIL,
  UPDATE_DISTRICT_SUCCESS,
  UPDATE_DISTRICT_FAIL,
  DELETE_DISTRICT_SUCCESS,
  DELETE_DISTRICT_FAIL,
  GET_DISTRICTS,
  GET_DISTRICT_DETAIL,
  GET_DISTRICT_DROPDOWN,
  UPDATE_DISTRICT,
  CREATE_DISTRICT,
  DELETE_DISTRICT,
} from "./actionTypes"

const INIT_STATE = {
  districts: [],
  districtDetail: {},
  searchDistricts: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdDistrict: {},
}

const Districts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DISTRICTS:
    case UPDATE_DISTRICT:
    case CREATE_DISTRICT:
    case DELETE_DISTRICT:
    case GET_DISTRICT_DROPDOWN:
      return {
        ...state,
        loading: true,
      }

    case GET_DISTRICT_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.payload,
        loading: false,
      }

    case GET_DISTRICTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_DISTRICT_SUCCESS:
      return {
        ...state,
        districts: action.payload,
        createdDistrict: action.payload,
        loading: false,
      }

    case CREATE_DISTRICT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DISTRICT_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchDistricts: action.payload,
        loading: false,
      }

    case GET_DISTRICT_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DISTRICT_DETAIL_SUCCESS:
      return {
        ...state,
        districtDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_DISTRICT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_DISTRICT_SUCCESS:
      return {
        ...state,
        districtDetail: action.payload,
        loading: false,
      }

    case UPDATE_DISTRICT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_DISTRICT_SUCCESS:
      return {
        ...state,
        // districts: state?.districts.filter(
        //   district => district.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_DISTRICT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Districts
