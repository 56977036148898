import React, { useState, useEffect } from "react"
import {
  Badge,
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
//actions institute
//componetns
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"
import { AvField, AvForm } from "availity-reactstrap-validation"

import UiModalLedgerUpdate from "./UiModalLedgerUpdate"
import UiModalLedgerItem from "./UiModalLedgerItem"
import UiModalLedgerItemUpdate from "./UiModalLedgerItemUpdate"

import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getLedgerDetail, getLedgerItems } from "store/actions"
import { handleFinancialYear } from "pages/admin/Mahalls/financialYear"

const LedgerMahallDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const {
    // instituteDetail,
    ledgerDetail,
    // institutes,
    loading,
    language,
    ledgerItems,
    userDetail,
  } = useSelector(state => ({
    instituteDetail: state.Institutes.instituteDetail,
    language: state.Layout.language,
    ledgerDetail: state.Ledgers.ledgerDetail,
    institutes: state.Institutes.institutes,
    loading: state.LedgerItems.loading,
    ledgerItems: state.LedgerItems.ledgerItems,
    userDetail: state.Users.userDetail,
  }))

  const { finalToDate, fromDate } = handleFinancialYear(
    userDetail?.mahall?.financial_year_starting_month
  )

  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
  })

  const handleDate = () => {
    if (date?.start_date?.length >= 1 && date?.end_date?.length >= 1) {
      return date
    } else {
      return {
        start_date: "",
        end_date: "",
      }
    }
  }

  // const today = moment(new Date()).format('yyyy-MM-DD')

  // console.log(ledgerItems);

  // const [isOpen, setIsOpen] = useState(false)

  const [modalLedgerItem, setModalLedgerItem] = useState(false)
  const [modalLedgerItemUpdate, setModalLedgerItemUpdate] = useState(false)
  const [onUpdateLedgerItem, setOnUpdateLedgerItem] = useState({})
  const [modalLedgerUpdate, setModalLedgerUpdate] = useState(false)

  const updateLedgerItemModal = item => {
    setOnUpdateLedgerItem(item)
    setModalLedgerItemUpdate(true)
  }

  const totalPages = Math.ceil(ledgerItems?.count / 10)
  const pages = range(1, totalPages + 1)

  // const pageSend = () => {
  //   if (page >= pages.length) {
  //     return pages.length
  //   }
  //   if (page < 1) {
  //     return 1
  //   } else {
  //     return page
  //   }
  // }

  useEffect(() => {
    if (ledgerDetail?.ledgers?.id) {
      dispatch(
        getLedgerItems(
          ledgerDetail?.ledgers?.id,
          searchText,
          page,
          handleDate()
        )
      )
    }
  }, [dispatch, ledgerDetail, page, searchText, date])

  const cardData = [
    {
      title: `${language === "mal" ? "വരുമാനം" : "Income"}`,
      cardValue: `${ledgerDetail?.total_income ?? 0}`,
      className: "text-success",
      iconClass: "downvote",
    },
    {
      title: `${language === "mal" ? "ചെലവ്" : "Expense"}`,
      cardValue: `${ledgerDetail?.total_expense ?? 0}`,
      className: "text-danger",
      iconClass: "upvote",
    },
    {
      title: `${language === "mal" ? "ഫൈനൽ ബാലൻസ്" : "Final Balance"}`,
      cardValue: `${ledgerDetail?.ledgers?.balance ?? 0}`,
      className: "text-info",
      iconClass: "list-check",
    },
    {
      title: `${language === "mal" ? "ഓപ്പണിംഗ് ബാലൻസ്" : "Opening Balance"}`,
      cardValue: `${ledgerDetail?.ledgers?.opening_balance ?? 0}`,
      className: "text-primary",
      iconClass: "list-check",
    },
  ]

  // const handleDelete = () => {
  //   setIsOpen(true)
  // }
  // const handleDeleteEvent = () => {
  //   dispatch(deleteLedger(ledgerDetail?.id, history))
  //   setIsOpen(false)
  // }

  useEffect(() => {
    dispatch(getLedgerDetail(params.id))
  }, [])

  const debounceMahallLedgerItemSearch = debounce(
    value => setSearchText(value),
    600
  )

  const columns = [
    // {
    //   dataField: "no",
    //   text: `${language === "mal" ? "നം." : "No."}`,
    //   sort: true,
    // },
    {
      dataField: "voucher_number",
      text: `${
        ledgerDetail?.ledgers?.ledger_category?.english === "Income"
          ? language === "mal"
            ? "രസീത് നം."
            : "Receipt no."
          : ledgerDetail?.ledgers?.ledger_category?.english === "Expense"
          ? language === "mal"
            ? "വൗച്ചർ നം."
            : "Voucher no."
          : language === "mal"
          ? "ഇടപാട് നം."
          : "Tr no."
      }`,
    },
    {
      dataField: "date",
      text: `${language === "mal" ? "തീയതി" : "Date"}`,
      sort: true,
    },
    {
      dataField: "name_of_member",
      text: `${language === "mal" ? "അംഗം" : "Member"}`,
    },
    {
      dataField: "title",
      text: `${language === "mal" ? "വിശദാംശങ്ങൾ" : "Particulars"}`,
    },
    {
      dataField: "institute_ledger_item_category_name",
      text: `${language === "mal" ? "വിഭാഗം" : "Category"}`,
    },
    {
      dataField: "income",
      text: `${
        ledgerDetail?.ledgers?.ledger_category?.english === "Income" ||
        ledgerDetail?.ledgers?.ledger_category?.english === "Expense"
          ? ""
          : language === "mal"
          ? "വരവ്"
          : "Income"
      }`,
    },
    {
      dataField: "income_expense",
      text: `${
        ledgerDetail?.ledgers?.ledger_category?.english === "Income"
          ? language === "mal"
            ? "വരവ്"
            : "Income"
          : ledgerDetail?.ledgers?.ledger_category?.english === "Expense"
          ? language === "mal"
            ? "ചിലവ്"
            : "Expense"
          : ""
      }`,
    },
    {
      dataField: "expense",
      text: `${
        ledgerDetail?.ledgers?.ledger_category?.english === "Income" ||
        ledgerDetail?.ledgers?.ledger_category?.english === "Expense"
          ? ""
          : language === "mal"
          ? "ചിലവ്"
          : "Expense"
      }`,
    },
    {
      dataField: "action",
      text: `${
        ledgerDetail?.ledgers && ledgerDetail?.ledgers?.is_disable
          ? ""
          : language === "mal"
          ? "പ്രവർത്തനങ്ങൾ"
          : "Actions"
      }`,
    },
    {
      dataField: "print",
      text: "",
    },
  ]

  const ledgerItemsData = map(ledgerItems?.results?.items, (item, index) => ({
    ...item,
    key: index,
    no: index + 1,
    name_of_member: (
      <p className="m-0">
        {item?.member_name ? item?.member_name : item?.other_name}
      </p>
    ),
    date: moment(item?.date).format("DD/MM/YYYY"),
    voucher_number: item?.voucher_number
      ? item?.voucher_number
      : item?.receipt_number,
    income: (
      <p className="text-success">
        {(ledgerDetail?.ledgers?.ledger_category?.english === "Both" ||
          ledgerDetail?.ledgers?.ledger_category === null) &&
          item.transaction_type === "credit" &&
          item.amount}
      </p>
    ),
    expense: (
      <p className="text-danger">
        {(ledgerDetail?.ledgers?.ledger_category?.english === "Both" ||
          ledgerDetail?.ledgers?.ledger_category === null) &&
          item.transaction_type === "debit" &&
          item.amount}
      </p>
    ),
    income_expense: (
      <p
        className={
          "text-" +
          `${
            ledgerDetail?.ledgers?.ledger_category?.english === "Income"
              ? "success"
              : ledgerDetail?.ledgers?.ledger_category?.english === "Expense"
              ? "danger"
              : ""
          }`
        }
      >
        {ledgerDetail?.ledgers?.ledger_category?.english === "Income" ||
        ledgerDetail?.ledgers?.ledger_category?.english === "Expense"
          ? item.amount
          : ""}
      </p>
    ),
    // category: item?.institute_ledger_item_category_name ? item.institute_ledger_item_category_name : '',
    action: (
      <div className="m-0">
        {ledgerDetail?.ledgers && ledgerDetail?.ledgers?.is_disable === true ? (
          <></>
        ) : (
          <Button
            type="button"
            color="primary"
            className=" btn w-xs btn-sm"
            onClick={() => updateLedgerItemModal(item)}
          >
            Edit
          </Button>
        )}
      </div>
    ),
    print: (
      <div>
        <Link to={`/receipt/${item?.id}`} className="btn btn-dark w-xs btn-sm">
          <i className="bx bxs-download me-2" />
          {language === "mal" ? "ഡൗൺലോഡ്" : "Download"}
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  return (
    <>
      {/* <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      /> */}
      <UiModalLedgerItem
        show={modalLedgerItem}
        onCloseclick={() => setModalLedgerItem(false)}
        history={history}
      />
      <UiModalLedgerItemUpdate
        show={modalLedgerItemUpdate}
        onCloseclick={() => setModalLedgerItemUpdate(false)}
        data={onUpdateLedgerItem}
        history={history}
      />
      <UiModalLedgerUpdate
        show={modalLedgerUpdate}
        onCloseclick={() => setModalLedgerUpdate(false)}
        history={history}
      />

      <MetaTags>
        <title>Ledgers | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ലെഡ്ജർ വിവരങ്ങൾ " : "Ledger Details"}
          breadcrumbItem={language === "mal" ? "ലെഡ്ജർ" : "Ledger"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col className="col-lg-12 col-md-12">
                <Row>
                  <Col md="4">
                    <Card
                      className="overflow-hidden bg-primary bg-soft"
                      style={{ minHeight: "224px" }}
                    >
                      <div className="">
                        <Row>
                          <div className="text-primary p-4">
                            <div className="px-2">
                              <Row>
                                <Col md="12">
                                  <div className=" d-flex justify-content-end">
                                    <Button
                                      type="button"
                                      className="btn-sm bg-khaf-blue text-white"
                                      onClick={() => setModalLedgerUpdate(true)}
                                    >
                                      <i className="bx bx-edit text-center"></i>
                                      {language === "mal"
                                        ? " അപ്ഡേറ്റ്"
                                        : "Update"}
                                    </Button>
                                  </div>
                                  <h5 className="text-khaf">Ledger</h5>
                                  <div className="d-flex align-items-center">
                                    <h4 className="mt-2">
                                      {
                                        ledgerDetail?.ledgers
                                          ?.institute_ledger_name
                                      }
                                    </h4>
                                    <Badge
                                      style={{ fontSize: "11px" }}
                                      className={
                                        "mt-2 mx-3 bg-" +
                                        `${
                                          ledgerDetail?.ledgers?.ledger_category
                                            ?.english === "Income"
                                            ? "success"
                                            : ledgerDetail?.ledgers
                                                ?.ledger_category?.english ===
                                              "Expense"
                                            ? "danger"
                                            : "info"
                                        }`
                                      }
                                    >
                                      {language === "mal"
                                        ? ledgerDetail?.ledgers?.ledger_category
                                            ?.malayalam
                                        : ledgerDetail?.ledgers?.ledger_category
                                            ?.english}
                                    </Badge>
                                  </div>
                                  <p className="text-muted">
                                    From{" "}
                                    {moment(
                                      ledgerDetail?.ledgers?.date_added
                                    ).format("DD/MM/YYYY")}
                                  </p>
                                  <p
                                    className="text-muted"
                                    style={{ fontSize: "15px" }}
                                  >
                                    <span>
                                      {language === "mal"
                                        ? "സാമ്പത്തിക വർഷം :"
                                        : "Financial Year :"}
                                    </span>{" "}
                                    <span className="text-khaf-blue">{`${
                                      fromDate ? fromDate : ""
                                    }${" "} to ${" "}${
                                      finalToDate ? finalToDate : ""
                                    }`}</span>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Row>
                      </div>
                      {/* <div className=" d-flex justify-content-end m-3">
                        <Button type="button" className="btn-sm bg-khaf-blue text-white" onClick={() => setModalLedgerUpdate(true)}>
                          <i className="bx bx-edit text-center"></i>{language === "mal" ? " അപ്ഡേറ്റ്" : "Update"}</Button>
                      </div> */}
                    </Card>
                  </Col>
                  <Col md="8">
                    <Row>
                      {cardData?.map((item, key) => (
                        <Col sm="12" md="6" lg="6" key={key} className="sm:p-0">
                          <Card className="blog-stats-wid">
                            <CardBody>
                              <div className="d-flex flex-wrap">
                                <div className="me-3">
                                  <h5 className="text-muted">{item.title}</h5>
                                  <h4 className={`${item.className}`}>
                                    {item.cardValue}
                                  </h4>
                                </div>
                                <div className="avatar-sm ms-auto">
                                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                    <i
                                      className={
                                        "bx bx-" +
                                        `${item.iconClass} ${item.className}`
                                      }
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <AvForm>
                      <Row>
                        <Col md="4" className="">
                          <h5>From :</h5>
                          <AvField
                            type="date"
                            name="start_date"
                            value={date?.start_date}
                            onChange={e =>
                              setDate({
                                ...date,
                                start_date: moment(e.target.value).format(
                                  "yyyy-MM-DD"
                                ),
                              })
                            }
                          />
                          <button
                            onClick={() =>
                              setDate({
                                ...date,
                                start_date: "",
                              })
                            }
                            type="button"
                            className="btn btn-white editable-cancel date_close btn-sm"
                          >
                            <i className="mdi mdi-close text-danger "></i>
                          </button>
                        </Col>
                        <Col md="4" className="">
                          <h5>To :</h5>
                          <AvField
                            type="date"
                            name="end_date"
                            value={date?.end_date}
                            onChange={e =>
                              setDate({
                                ...date,
                                end_date: moment(e.target.value).format(
                                  "yyyy-MM-DD"
                                ),
                              })
                            }
                          />
                          <button
                            onClick={() =>
                              setDate({
                                ...date,
                                end_date: "",
                              })
                            }
                            type="button"
                            className="btn btn-white editable-cancel date_close btn-sm"
                          >
                            <i className="mdi mdi-close text-danger "></i>
                          </button>
                        </Col>
                        {/* <Col>
                    <Button className="btn btn-md w-md bg-khaf-blue btn-date mt-4">View</Button>
                  </Col> */}
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="">
                <React.Fragment>
                  <Row style={{ minHeight: "600px", display: "flex" }}>
                    <Col className="col-lg-12 col-md-12">
                      <Card>
                        <CardBody>
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={ledgerItemsData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <form
                                          className="app-search d-lg-block"
                                          onChange={e =>
                                            debounceMahallLedgerItemSearch(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search..."
                                              defaultValue={searchText}
                                            />
                                            <span className="bx bx-search-alt" />
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="8">
                                    <div className="d-flex justify-content-end">
                                      {ledgerDetail?.ledgers &&
                                      ledgerDetail?.ledgers?.is_disable ===
                                        true ? (
                                        <></>
                                      ) : (
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="btn-sm"
                                          onClick={() =>
                                            setModalLedgerItem(true)
                                          }
                                        >
                                          {language === "mal"
                                            ? "+ എൻട്രി ചേർക്കുക"
                                            : "+ Add Entry"}
                                        </Button>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                {loading ? (
                                  <Spinner
                                    color="secondary"
                                    className="d-block m-auto"
                                  />
                                ) : (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap table-hover"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <MyPagination
                                      totalPages={pages}
                                      page={page}
                                      setPage={setPage}
                                    />
                                  </>
                                )}
                                {ledgerItems &&
                                  ledgerItems?.results &&
                                  ledgerItems?.results?.items?.length <= 0 && (
                                    <p
                                      style={{ fontSize: "15px" }}
                                      className="text-center text-info"
                                    >
                                      {language === "mal"
                                        ? "ലെഡ്ജർ ഐറ്റംസ് ഒന്നും ഇല്ല "
                                        : "No Ledger Items"}
                                    </p>
                                  )}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col className="col-lg-4 col-md-12" style={{minHeight:'600px'}}>
                      <Card className="" style={{minHeight:'500px'}}>
                        <CardBody>

                        </CardBody>
                      </Card>
                    </Col> */}
                  </Row>
                </React.Fragment>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default LedgerMahallDetails

LedgerMahallDetails.propTypes = {
  history: PropTypes.object,
}
