import {
  GET_STUDENT_ATTENDANCES_SUCCESS,
  GET_STUDENT_ATTENDANCES_FAIL,
  GET_STUDENT_ATTENDANCE_DETAIL_SUCCESS,
  GET_STUDENT_ATTENDANCE_DETAIL_FAIL,
  CREATE_STUDENT_ATTENDANCE_SUCCESS,
  CREATE_STUDENT_ATTENDANCE_FAIL,
  UPDATE_STUDENT_ATTENDANCE_SUCCESS,
  UPDATE_STUDENT_ATTENDANCE_FAIL,
  DELETE_STUDENT_ATTENDANCE_SUCCESS,
  DELETE_STUDENT_ATTENDANCE_FAIL,
  GET_STUDENT_ATTENDANCES,
  GET_STUDENT_ATTENDANCE_DETAIL,
  UPDATE_STUDENT_ATTENDANCE,
  CREATE_STUDENT_ATTENDANCE,
  DELETE_STUDENT_ATTENDANCE,
  CREATE_WORKING_DAY,
  CREATE_WORKING_DAY_SUCCESS,
  CREATE_WORKING_DAY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  studentAttendances: [],
  studentAttendanceDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdStudentAttendance: {},
}

const StudentAttendances = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STUDENT_ATTENDANCES:
    case UPDATE_STUDENT_ATTENDANCE:
    case CREATE_STUDENT_ATTENDANCE:
    case DELETE_STUDENT_ATTENDANCE:
    case CREATE_WORKING_DAY:
      return {
        ...state,
        loading: true,
      }
    case GET_STUDENT_ATTENDANCE_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_STUDENT_ATTENDANCES_SUCCESS:
      return {
        ...state,
        studentAttendances: action.payload,
        loading: false,
      }

    case GET_STUDENT_ATTENDANCES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_STUDENT_ATTENDANCE_SUCCESS:
      return {
        ...state,
        studentAttendances: {
          ...state.studentAttendances,
          results: [...state.studentAttendances.results, action.payload],
        },
        // studentAttendances: {
        //   results: state.studentAttendances.results?.map(item =>
        //     item.date.toString() === action.payload.date.toString()
        //       ? { item, ...action.payload }
        //       : item
        //   ),
        // },
        createdStudentAttendance: action.payload,
        loading: false,
      }

    case CREATE_STUDENT_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STUDENT_ATTENDANCE_DETAIL_SUCCESS:
      return {
        ...state,
        studentAttendanceDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_STUDENT_ATTENDANCE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_STUDENT_ATTENDANCE_SUCCESS:
      return {
        ...state,
        studentAttendanceDetail: action.payload,
        studentAttendances: {
          results: state.studentAttendances.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_STUDENT_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_STUDENT_ATTENDANCE_SUCCESS:
      return {
        ...state,
        // studentAttendances: action.payload,
        studentAttendances: {
          results: state.studentAttendances.results.filter(
            studentAttendance => studentAttendance.id !== action.payload
          ),
        },
        loading: false,
      }

    case DELETE_STUDENT_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_WORKING_DAY_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case CREATE_WORKING_DAY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default StudentAttendances
