import { AvField, AvForm } from "availity-reactstrap-validation"
import React from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import { createState } from "store/state/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateState = ({ history }) => {
  const dispatch = useDispatch()

  const { loading } = useSelector(state => ({
    loading: state.States.loading,
    error: state.States.error,
  }))

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    console.log("values: ", values)
    dispatch(createState({ ...values }, history))
  }

  // const mailError = error[0]

  return (
    <>
      <MetaTags>
        <title>States | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="States" breadcrumbItem="Create" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Create State</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            title="State name"
                            name="state_english_name"
                            label="State Name (English)"
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter state name",
                              },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            title="State name"
                            name="state_malayalam_name"
                            label="State Name (മലയാളം)"
                            type="text"
                          />
                        </Col>
                      </div>

                      <div className="d-flex justify-content-end">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            {loading && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            )}
                            Submit
                          </Button>
                        </div>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateState

CreateState.propTypes = {
  history: PropTypes.object,
}
