import React, { Fragment, useEffect, useState } from "react"
import { Col, Row, Card, CardBody, Spinner, Button, Badge } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useParams, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
//actions
//componetns
import { debounce, map, range } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getInstituteWalletContraList,
  getInstituteWalletDetail,
} from "store/actions"
import UiModalCreateContra from "./UiModalContra"

const WalletDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    instituteWalletContraList,
    instituteWalletDetail,
    language,
    loading,
    walletLoading,
  } = useSelector(state => ({
    loading: state.InstituteWalletContra.loading,
    instituteWalletContraList:
      state.InstituteWalletContra.instituteWalletContraList,
    instituteWalletDetail: state.InstituteWallets.instituteWalletDetail,
    language: state.Layout.language,
    walletLoading: state.InstituteWallets.loading,
  }))

  const [modalContra, setModalContra] = useState(false)

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const limit = 10

  const totalPages = Math.ceil(instituteWalletContraList?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    {
      dataField: "title",
      text: `${language === "mal" ? "ടൈറ്റിൽ" : "Title"}`,
    },
    {
      dataField: "to_wallet_name",
      text: `${language === "mal" ? "വാലറ്റ്" : "To Wallet"}`,
    },
    {
      dataField: "date_added",
      text: `${language === "mal" ? "തീയതി" : "Date"}`,
    },
    {
      dataField: "amount",
      text: `${language === "mal" ? "തുക" : "Amount"}`,
    },
    {
      dataField: "from_wallet_balance",
      text: `${language === "mal" ? "ബാലൻസ്" : "Balance"}`,
    },
    {
      dataField: "description",
      text: `${language === "mal" ? "വിവരണം" : "Description"}`,
    },
  ]

  const contraData = map(instituteWalletContraList?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    date_added: item.date_added && moment(item.date_added).format("DD-MM-yyyy"),
    amount: <p className="m-0 text-danger">{item?.amount}</p>,
    from_wallet_balance: (
      <p className="m-0 text-primary">{item?.from_wallet_balance}</p>
    ),
    to_wallet_name: (
      <div>
        <p className="m-0">
          {item?.to_wallet_name} (
          <span className="text-success"> {item?.to_wallet_balance}</span>)
        </p>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceContraSearch = debounce(value => setSearchText(value), 600)

  useEffect(() => {
    dispatch(
      getInstituteWalletContraList(
        instituteWalletDetail?.id,
        searchText,
        pageSend()
      )
    )
  }, [dispatch, instituteWalletDetail, searchText, page])

  useEffect(() => {
    dispatch(getInstituteWalletDetail(params?.id))
  }, [dispatch])

  return (
    <>
      <UiModalCreateContra
        show={modalContra}
        onCloseclick={() => setModalContra(false)}
        currentWallet={instituteWalletDetail}
      />
      <MetaTags>
        <title>Institute Wallet | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "വാലറ്റ് വിശദാംശങ്ങൾ" : "Wallet Details"}
          breadcrumbItem={language === "mal" ? "വാലറ്റ്" : "Institute Wallet"}
        />
        {walletLoading ? (
          <Spinner color="secondary" className="d-block m-auto" />
        ) : (
          <>
            <div className="row">
              <div className="col-md-4">
                <Card className="bg-soft-primary">
                  <CardBody>
                    <div className="d-flex align-items-center justify-content-between">
                      <h4>{instituteWalletDetail?.institute_wallet_name}</h4>
                      <div className="p-2 bg-info rounded">
                        <p className="m-0 text-white fw-bold">
                          {language === "mal" ? "ബാലൻസ്" : "Balance"} :{" "}
                          {instituteWalletDetail?.balance}
                        </p>
                      </div>
                      {/* <Button color="info" className="btn-md">
                    Balance: 10000
                  </Button> */}
                    </div>
                    <Badge
                      className={`${
                        instituteWalletDetail?.is_bank
                          ? "bg-success"
                          : "bg-primary"
                      } rounded-pill`}
                    >
                      {" "}
                      {instituteWalletDetail?.is_bank
                        ? language === "mal"
                          ? "ബാങ്ക്"
                          : "Bank"
                        : language === "mal"
                        ? "കയ്യിൽ കാശ്"
                        : "Cash in hand"}
                    </Badge>
                    <div className="d-flex"></div>
                    <p className="m-0 pt-2 text-muted">
                      {instituteWalletDetail?.description}
                    </p>
                  </CardBody>
                </Card>
              </div>
              {/* <div className="col-md-4">
                <Card className="bg-soft-primary">
                  <CardBody>
                    <div className="">
                      <h4>{language === 'mal' ? '' : 'Balance'}</h4>
                      <h4>{instituteWalletDetail?.balance}</h4>
                    </div>
                  </CardBody>
                </Card>
              </div> */}
            </div>
            <Row>
              <Col className="col-8 mt-1">
                <h5>{language === "mal" ? "ഹിസ്റ്ററി" : "History"} :</h5>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={contraData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e =>
                                      debounceContraSearch(e.target.value)
                                    }
                                  >
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder={
                                          language === "mal"
                                            ? "തിരയുക..."
                                            : "Search..."
                                        }
                                        defaultValue={searchText}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Col>
                            <Col md="8">
                              <div className="d-flex justify-content-end">
                                <Button
                                  className="btn-sm"
                                  color="primary"
                                  onClick={() => setModalContra(true)}
                                >
                                  {language === "mal"
                                    ? "+ തുക മാറ്റം ചെയ്യുക "
                                    : "+ Transfer Amount"}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          {loading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : (
                            <>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table align-middle table-wrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            </>
                          )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <Col className="col-12 mt-1">
                <h5>{language === "mal" ? "ഹിസ്റ്ററി" : "History"} :</h5>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={contraData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e =>
                                      debounceContraSearch(e.target.value)
                                    }
                                  >
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder={
                                          language === "mal"
                                            ? "തിരയുക..."
                                            : "Search..."
                                        }
                                        defaultValue={searchText}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Col>
                            <Col md="8">
                              <div className="d-flex justify-content-end">
                                <Button
                                  className="btn-sm"
                                  color="primary"
                                  onClick={() => setModalContra(true)}
                                >
                                  {language === "mal"
                                    ? "+ തുക മാറ്റം ചെയ്യുക "
                                    : "+ Transfer Amount"}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          {loading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : (
                            <>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table align-middle table-wrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            </>
                          )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
          </>
        )}
      </div>
    </>
  )
}

export default WalletDetails

WalletDetails.propTypes = {
  history: PropTypes.object,
}
