import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
  Label,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import Select from "react-select"

import {
  getInstituteDropdown,
  // getLedgerDropdown,
  getLedgerItemCategoryDropdown,
  getLedgerItems,
  getLedgerItemsForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"

const AllCategoryBasedLedgerItems = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [modalLedgerItemDownload, setModalLedgerItemDownload] = useState(false)

  const {
    loading,
    language,
    ledgerItems,
    ledgerItemsForDownload,
    searchInstitutes,
    // searchLedgers,
    searchLedgerItemCategories,
  } = useSelector(state => ({
    loading: state.LedgerItems.loading,
    language: state.Layout.language,
    ledgerItems: state.LedgerItems.ledgerItems,
    ledgerItemsForDownload:
      state.LedgerItems.ledgerItemsForDownload?.ledger_items,
    searchInstitutes: state.Institutes.searchInstitutes,
    searchLedgers: state.Ledgers.searchLedgers,
    searchLedgerItemCategories:
      state.LedgerItemCategories.searchLedgerItemCategories,
  }))

  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
  })

  const [institute, setInstitute] = useState("choose institute")
  const [instituteId, setInstituteId] = useState("")
  const [searchTextInstitute, setSearchTextInstitute] = useState("")

  // const [ledger, setLedger] = useState('choose ledger')
  // const [ledgerId, setLedgerId] = useState('')
  // const [searchTextLedger, setSearchTextLedger] = useState('')

  const [category, setCategory] = useState("choose category")
  const [categoryId, setCategoryId] = useState("")
  const [searchTextCategory, setSearchTextCategory] = useState("")

  const [type, setType] = useState("")

  // const handleDate = () => {
  //   if (date?.start_date?.length >= 1 && date?.end_date?.length >= 1) {
  //     return date
  //   } else {
  //     return {
  //       start_date: '',
  //       end_date: '',
  //     }
  //   }
  // }

  //pages
  const totalPages = Math.ceil(ledgerItems?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(
      getLedgerItems(
        searchText,
        pageSend(),
        "",
        date,
        true,
        type,
        instituteId,
        categoryId
      )
    )
  }, [dispatch, page, searchText, date, true, type, instituteId, categoryId])

  useEffect(() => {
    if (modalLedgerItemDownload) {
      dispatch(
        getLedgerItemsForDownload(
          date,
          true,
          searchText,
          type,
          "",
          instituteId,
          categoryId
        )
      )
    }
  }, [
    dispatch,
    date,
    true,
    searchText,
    type,
    instituteId,
    categoryId,
    modalLedgerItemDownload,
  ])

  const cardData = [
    {
      title: `${language === "mal" ? "വരുമാനം" : "Income"}`,
      cardValue: `${ledgerItems?.total_income ?? 0}`,
      className: "text-success",
      iconClass: "downvote",
    },
    {
      title: `${language === "mal" ? "ചെലവ്" : "Expense"}`,
      cardValue: `${ledgerItems?.total_expense ?? 0}`,
      className: "text-danger",
      iconClass: "upvote",
    },
    {
      title: `${language === "mal" ? "ബാലൻസ്" : "Balance"}`,
      cardValue: `${ledgerItems?.total_balance ?? 0}`,
      className: "text-info",
      iconClass: "list-check",
    },
  ]

  const columns = [
    // {
    //   dataField: "no",
    //   text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    //   sort: true,
    // },
    {
      dataField: "voucher_number",
      text: `${language === "mal" ? "ഇടപാട് നം." : "Tr no."}`,
    },
    {
      dataField: "date",
      text: `${language === "mal" ? "തീയതി" : "Date"}`,
    },
    {
      dataField: "title",
      text: `${
        language === "mal" ? "ലെഡ്ജർ ഇനത്തിന്റെ  പേര്" : "Ledger Item Name"
      }`,
      sort: true,
    },
    // {
    //   dataField: "transaction_type",
    //   text: `${language === "mal" ? "തരം" : "Type"}`,
    // },
    {
      dataField: "income",
      text: `${language === "mal" ? "വരവ്" : "Income"}`,
    },
    {
      dataField: "expense",
      text: `${language === "mal" ? "ചിലവ്" : "Expense"}`,
    },
    // {
    //   dataField: "date",
    //   text: `${language === "mal" ? "ചിലവ്" : "Expense"}`,
    // },
    // {
    //   dataField: "balance",
    //   text: `${language === "mal" ? "ബാക്കി" : "Balance"}`,
    // },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
    {
      dataField: "print",
      text: "",
    },
  ]

  const ledgersData = map(ledgerItems?.ledger_items, (item, index) => ({
    ...item,
    key: index,
    no: index + 1,
    income: <p className="text-success">{item?.income}</p>,
    expense: <p className="text-danger">{item?.expense}</p>,
    date: item.date && moment(item.date).format("DD-MM-yyyy"),
    voucher_number: item?.voucher_number
      ? item?.voucher_number
      : item?.receipt_number,
    // transaction_type: <p className={`${item?.transaction_type === "credit" ? "text-success" : "text-danger"}`}>{item?.transaction_type}</p>,
    // balance: <p className="text-info">{item?.balance}</p>,
    title: (
      <div>
        {item?.title}
        <Badge className="mx-3 font-size-10 badge-soft-primary" pill>
          {item?.institute_ledger_id__institute_ledger_name}
        </Badge>
      </div>
    ),
    action: (
      <div>
        <Link
          to={`/ledgers/${item?.institute_ledger_id}`}
          className="btn-success btn-sm"
        >
          {language === "mal" ? "കാണുക" : "View"}
        </Link>
      </div>
    ),
    print: (
      <Link to={`/receipt/${item?.id}`} className=" btn-dark w-xs btn-sm">
        <i className="bx bxs-download me-2 mt-2" />
        {language === "mal" ? "ഡൗൺലോഡ്" : "Download"}
      </Link>
    ),
  }))

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    {
      label: `${
        language === "mal" ? "ലെഡ്ജർ ഇനത്തിന്റെ  പേര്" : "Ledger Item Name"
      }`,
      key: "title",
    },
    { label: `${language === "mal" ? "തീയതി" : "Date"}`, key: "date" },
    {
      label: `${language === "mal" ? "ലെഡ്ജറിന്റെ പേര്" : "Ledger Name"}`,
      key: "ledger_name",
    },
    { label: `${language === "mal" ? "തുക" : "Amount"}`, key: "amount" },
    {
      label: `${language === "mal" ? "ഇടപാട് തരം" : "Transaction Type"}`,
      key: "transaction_type",
    },
    {
      label: `${language === "mal" ? "ഇടപാട് നമ്പർ" : "Transaction Number"}`,
      key: "transaction_number",
    },
    {
      label: `${language === "mal" ? "വിവരണം" : "Description"}`,
      key: "description",
    },
  ]

  const ledgerItemsExcelData = map(ledgerItemsForDownload, (item, index) => ({
    ...item,
    no: index + 1,
    title: item.title,
    date: item.date && moment(item?.date).format("DD/MM/YYYY"),
    ledger_name: item.institute_ledger_id__institute_ledger_name,
    amount: item.amount,
    transaction_type: item.transaction_type,
    transaction_number: item.receipt_number
      ? item.receipt_number
      : item.voucher_number,
    description: item.description,
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const fileName = "khaf-smart-ledger-items-list.csv"

  useEffect(() => {
    dispatch(getInstituteDropdown(searchTextInstitute))
  }, [searchTextInstitute, dispatch])

  // useEffect(() => {
  //   dispatch(getLedgerDropdown(searchTextLedger))
  // }, [searchTextLedger, dispatch])

  useEffect(() => {
    dispatch(getLedgerItemCategoryDropdown("", searchTextCategory))
  }, [institute, searchTextCategory, dispatch])

  function handlerFinalValue(event) {
    // dispatch(getInstituteDropdown(event.value))
    setInstituteId(event.value)
    setInstitute(event.label)
  }

  // function handlerFinalValueLedger(event) {
  //   setLedgerId(event.value)
  //   setLedger(event.label)
  // }

  function handlerFinalValueCategory(event) {
    // dispatch(getInstituteDropdown(event.value))
    setCategoryId(event.value)
    setCategory(event.label)
  }

  const optionGroup = [
    {
      options: searchInstitutes?.map((result, index) => ({
        key: index,
        label: `${
          language === "mal"
            ? result.institute_malayalam_name
              ? result.institute_malayalam_name
              : result.institute_english_name
            : result.institute_english_name
        } (${result?.institute_type && result?.institute_type})`,
        value: result.id,
      })),
    },
  ]

  // const optionGroupLedger = [
  //   {
  //     options: searchLedgers?.map((result, index) => ({
  //       key: index,
  //       label: `${result?.institute_ledger_name} (${
  //         language === 'mal'
  //           ? result?.institute_malayalam_name
  //             ? result?.institute_malayalam_name
  //             : result?.institute_english_name
  //           : result?.institute_english_name
  //       }) (${result?.institute_type})`,
  //       value: result.id,
  //     })),
  //   },
  // ]

  const optionGroupCategory = [
    {
      options: searchLedgerItemCategories?.map((results, index) => ({
        key: index,
        label: results.institute_ledger_item_category_name,
        value: results.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchTextInstitute(textEntered)
  }

  // const handleEntersLedger = textEntered => {
  //   setSearchTextLedger(textEntered)
  // }

  const handleEntersCategory = textEntered => {
    setSearchTextCategory(textEntered)
  }

  const instituteTypes = [
    {
      label: "All",
      id: "btnradio1",
      instituteType: "",
    },
    {
      label: "Income",
      id: "btnradio2",
      instituteType: "credit",
    },
    {
      label: "Expense",
      id: "btnradio3",
      instituteType: "debit",
    },
  ]

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalLedgerItemDownload}
        onCloseclick={() => setModalLedgerItemDownload(false)}
        excelData={ledgerItemsExcelData}
        headers={headers}
        list={ledgerItemsForDownload}
        fileName={fileName}
        loading={loading}
      />
      {/* <Row>
        <div className="col-md-6 mb-3">
          <Link
            to={`/institute/create`}
            type="submit"
            style={{ width: language === "mal" ? "200px" : "150px" }}
            className="w-md btn btn-success d-block"
          >
            {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New Institute"}
          </Link>
        </div>
      </Row> */}
      <Row>
        {cardData?.map((item, key) => (
          <Col sm="12" md="6" lg="4" key={key} className="sm:p-0">
            <Card className="blog-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <h5 className="text-muted">{item.title}</h5>
                    <h4 className={`${item.className}`}>{item.cardValue}</h4>
                  </div>
                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i
                        className={
                          "bx bx-" + `${item.iconClass} ${item.className}`
                        }
                      ></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <AvForm>
                <Row>
                  <Col md="3" className="mb-3">
                    <h5>From :</h5>
                    <AvField
                      type="date"
                      name="start_date"
                      value={date?.start_date}
                      onChange={e =>
                        setDate({
                          ...date,
                          start_date: moment(e.target.value).format(
                            "yyyy-MM-DD"
                          ),
                        })
                      }
                    />
                    <button
                      onClick={() =>
                        setDate({
                          ...date,
                          start_date: "",
                        })
                      }
                      type="button"
                      className="btn btn-white editable-cancel date_close btn-sm"
                    >
                      <i className="mdi mdi-close text-danger "></i>
                    </button>
                  </Col>
                  <Col md="3" className="mb-3">
                    <h5>To :</h5>
                    <AvField
                      type="date"
                      name="end_date"
                      value={date?.end_date}
                      onChange={e =>
                        setDate({
                          ...date,
                          end_date: moment(e.target.value).format("yyyy-MM-DD"),
                        })
                      }
                    />
                    <button
                      onClick={() =>
                        setDate({
                          ...date,
                          end_date: "",
                        })
                      }
                      type="button"
                      className="btn btn-white editable-cancel date_close btn-sm"
                    >
                      <i className="mdi mdi-close text-danger "></i>
                    </button>
                  </Col>
                  <Col md="3" className="mb-3">
                    <Label>Institute</Label>
                    <Select
                      onInputChange={handleEnters}
                      placeholder={institute}
                      options={optionGroup}
                      classNamePrefix="select2-selection"
                      onChange={handlerFinalValue}
                      type="text"
                      title="Institute"
                    />
                  </Col>
                  {/* <Col md="4" className="mb-3">
                    <Label>Ledger</Label>
                    <Select
                      onInputChange={handleEntersLedger}
                      placeholder={ledger}
                      options={optionGroupLedger}
                      classNamePrefix="select2-selection"
                      onChange={handlerFinalValueLedger}
                      type="text"
                      title="Ledger"
                    />
                  </Col> */}
                  <Col md="3" className="mb-3">
                    <Label>Category</Label>
                    <Select
                      onInputChange={handleEntersCategory}
                      placeholder={category}
                      options={optionGroupCategory}
                      classNamePrefix="select2-selection"
                      onChange={handlerFinalValueCategory}
                      type="text"
                      title="Category"
                    />
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={ledgersData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div
                          className="btn-group d-flex justify-content-center mt-2 mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          {instituteTypes?.map((item, key) => (
                            <Fragment key={key}>
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id={item?.id}
                                autoComplete="off"
                                defaultChecked={item?.id === "btnradio1"}
                                onChange={() => setType(item?.instituteType)}
                              />
                              <label
                                className="btn btn-primary"
                                htmlFor={item?.id}
                              >
                                {item?.label}
                              </label>
                            </Fragment>
                          ))}
                        </div>
                      </Col>
                      <Col md="4">
                        <Button
                          style={{
                            width: language === "mal" ? "150px" : "130px",
                            border: "bold",
                          }}
                          className="btn-md me-4 mt-1 d-block m-auto bg-white text-khaf"
                          onClick={() => setModalLedgerItemDownload(true)}
                        >
                          <i
                            className="bx bx-download mx-1"
                            style={{ fontSize: "18px", color: "#009846" }}
                          />
                          {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                        </Button>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {ledgerItems &&
                      ledgerItems?.results &&
                      ledgerItems?.results?.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          {language === "mal"
                            ? "ലെഡ്ജർ ഐറ്റംസ് ഒന്നും ഇല്ല "
                            : "No Ledger Items Yet"}
                        </p>
                      )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllCategoryBasedLedgerItems
