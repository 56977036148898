import React, { useState } from "react"
import success from "assets/images/success.jpg"
import { Button } from "reactstrap"
import RecieptModal from "./RecieptModal"

function Success() {
  const [recieptModal, setReceiptModal] = useState(false)

  const modalOpen = () => {
    setReceiptModal(true)
  }
  const modalClose = () => {
    setReceiptModal(false)
  }

  return (
    <div className="py-2">
      <RecieptModal show={recieptModal} onCloseClick={modalClose} />
      <div className="card w-50 p-3 mx-auto">
        <div className="flex-center">
          <img
            src={success}
            alt="payment success"
            height={450}
            width={"100%"}
            style={{ objectFit: "contain" }}
          />
        </div>
        <div>
          <p className="h3 flex-center">Payment Done!</p>
          <p className="my-0 flex-center">
            Thank you for completing your secure online payment.
          </p>
          <p className="flex-center">Have a great day!</p>
          <div className="flex-center">
            <Button
              type="button"
              color="success"
              className="px-3"
              size="sm"
              onClick={modalOpen}
            >
              View Receipt
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success
