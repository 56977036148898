import {
  GET_MAHALLS,
  GET_MAHALLS_SUCCESS,
  GET_MAHALLS_FAIL,
  GET_MAHALLS_CARDS,
  GET_MAHALLS_CARDS_SUCCESS,
  GET_MAHALLS_CARDS_FAIL,
  GET_MAHALL_DETAIL,
  GET_MAHALL_DETAIL_SUCCESS,
  GET_MAHALL_DETAIL_FAIL,
  PATCH_MAHALL_PREMIUM,
  PATCH_MAHALL_PREMIUM_SUCCESS,
  PATCH_MAHALL_PREMIUM_FAIL,
  GET_MAHALL_DROPDOWN,
  GET_MAHALL_DROPDOWN_SUCCESS,
  GET_MAHALL_DROPDOWN_FAIL,
  CREATE_MAHALL,
  CREATE_MAHALL_SUCCESS,
  CREATE_MAHALL_FAIL,
  UPDATE_MAHALL,
  UPDATE_MAHALL_SUCCESS,
  UPDATE_MAHALL_FAIL,
  DELETE_MAHALL,
  DELETE_MAHALL_SUCCESS,
  DELETE_MAHALL_FAIL,
  GET_MAHALLS_FOR_DOWNLOAD,
  GET_MAHALLS_FOR_DOWNLOAD_SUCCESS,
  GET_MAHALLS_FOR_DOWNLOAD_FAIL,
} from "./actiontypes"

const INIT_STATE = {
  mahalls: [],
  mahallsForDownload: [],
  mahallsCards: {},
  mahallDetail: {},
  searchMahalls: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdMahall: {},
  patchMahall: {},
}

const Mahalls = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAHALLS:
    case GET_MAHALLS_FOR_DOWNLOAD:
    case GET_MAHALLS_CARDS:
    case UPDATE_MAHALL:
    case CREATE_MAHALL:
    case PATCH_MAHALL_PREMIUM:
    case DELETE_MAHALL:
    case GET_MAHALL_DROPDOWN:
      return {
        ...state,
        loading: true,
      }
    case GET_MAHALL_DETAIL:
      return {
        ...state,
        detailLoading: true,
      }
    case GET_MAHALLS_SUCCESS:
      return {
        ...state,
        mahalls: action.payload,
        loading: false,
      }

    case GET_MAHALLS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MAHALLS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        mahallsForDownload: action.payload,
        loading: false,
      }

    case GET_MAHALLS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MAHALLS_CARDS_SUCCESS:
      return {
        ...state,
        mahallsCards: action.payload,
        loading: false,
      }

    case GET_MAHALLS_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PATCH_MAHALL_PREMIUM_SUCCESS:
      return {
        ...state,
        patchMahall: action.payload,
        loading: false,
      }

    case PATCH_MAHALL_PREMIUM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_MAHALL_SUCCESS:
      return {
        ...state,
        mahalls: action.payload,
        createdMahall: action.payload,
        loading: false,
      }

    case CREATE_MAHALL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MAHALL_DETAIL_SUCCESS:
      return {
        ...state,
        mahallDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_MAHALL_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_MAHALL_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchMahalls: action.payload,
        loading: false,
      }

    case GET_MAHALL_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_MAHALL_SUCCESS:
      return {
        ...state,
        mahallDetail: action.payload,
        loading: false,
      }

    case UPDATE_MAHALL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_MAHALL_SUCCESS:
      return {
        ...state,
        // mahalls: state.mahalls.filter(
        //   mahall => mahall.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_MAHALL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Mahalls
