import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"

//actions
import {
  getGroupAdminDetail,
  updateGroupAdmin,
  getGroup,
  deleteGroupAdmin,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

const UpdateGroupAdmin = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { groupAdminDetail, groups, language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    groupAdminDetail: state.GroupAdmins.groupAdminDetail,
    groups: state.Groups.groups,
    language: state.Layout.language,
  }))

  const [group, setGroup] = useState("Search Group")
  const [groupId, setGroupId] = useState("")
  const [searchText, setSearchText] = useState("")

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    dispatch(getGroup(searchText))
  }, [dispatch, searchText])

  const handleDelete = () => {
    setIsOpen(true)
  }

  const handleDeleteEvent = () => {
    dispatch(deleteGroupAdmin(groupAdminDetail?.id, history))
    setIsOpen(false)
  }

  function handlerFinalValue(event) {
    // dispatch(getGroupAdmin(event.value))
    setGroupId(event.value)
    setGroup(event.label)
  }

  const Options = [
    {
      options: groups?.results?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results.mahallgroup_malayalam_name
              ? results.mahallgroup_malayalam_name
              : results.mahallgroup_english_name
            : results.mahallgroup_english_name,
        value: results.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  useEffect(() => {
    setGroup(
      language === "mal"
        ? groupAdminDetail?.mahallgroup_malayalam_name
        : groupAdminDetail?.mahallgroup_english_name
    )
    setGroupId(groupAdminDetail?.mahallgroup)
  }, [groupAdminDetail])

  useEffect(() => {
    dispatch(getGroupAdminDetail(params.id))
  }, [dispatch, params.id])

  function handleValidSubmit(values) {
    const updateGroupAdminData = {
      account: groupAdminDetail?.account,
      mahallgroup: groupId,
      is_active: groupAdminDetail?.is_active,
    }
    const updateMahallGroupAdminAccountData = {
      full_name: values.full_name,
      email: values.email,
      phone: values.phone,
    }
    dispatch(
      updateGroupAdmin(
        { updateMahallGroupAdminAccountData, ...updateGroupAdminData },
        groupAdminDetail?.id,
        history
      )
    )
  }

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <MetaTags>
        <title>Group Admins | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഗ്രൂപ്പ് അഡ്മിൻമാർ" : "Group Admins"}
          breadcrumbItem={language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക" : "Update"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">
                      Update Group Admins
                    </CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Full Name"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="full_name"
                                type="text"
                                value={groupAdminDetail?.full_name}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </Col>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="phone"
                                type="text"
                                label={
                                  language === "mal"
                                    ? "മൊബൈൽ നമ്പർ"
                                    : "Mobile Number"
                                }
                                value={groupAdminDetail?.phone}
                                readOnly
                              />
                            </Col>
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Col sm={9}>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="email"
                                type="email"
                                label={language === "mal" ? "ഇമെയിൽ" : "Email"}
                                value={groupAdminDetail?.email}
                              />
                            </Col>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "ഗ്രൂപ്പ്" : "Group"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col sm={9}>
                              <Select
                                onInputChange={handleEnters}
                                placeholder={group}
                                value={group}
                                options={Options}
                                classNamePrefix="select2-selection"
                                onChange={handlerFinalValue}
                                type="text"
                                // style={{ borderRadius: "8px", height: "40px" }}
                                title="Group"
                                required="required"
                              />
                            </Col>
                          </div>
                        </div>
                      </Row>

                      <Row className="mt-5 d-flex">
                        <div className="col-6">
                          <div className="row justify-content-start">
                            <div>
                              <Button
                                type="button"
                                color="danger"
                                className="w-md"
                                onClick={handleDelete}
                              >
                                {language === "mal"
                                  ? "ഡിലീറ്റ് ചെയ്യുക"
                                  : "Delete"}
                              </Button>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="row justify-content-end">
                            <div>
                              <Button
                                type="submit"
                                color="primary"
                                className="w-md m-auto me-0 d-block"
                              >
                                {language === "mal"
                                  ? "അപ്ഡേറ്റ് ചെയ്യുക "
                                  : "Update"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateGroupAdmin

UpdateGroupAdmin.propTypes = {
  history: PropTypes.object,
}
