import React, { useState, useEffect } from "react"
import { Col, Container, Row, Card, CardBody, Spinner } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"

//actions
import { deleteMahallUser, getMahallUserDetail } from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//Import Images

const MahallMahallUserDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { mahallUserDetail, loading, detailLoading } = useSelector(state => ({
    loading: state.MahallUsers.loading,
    detailLoading: state.MahallUsers.detailLoading,
    mahallUserDetail: state.MahallUsers.mahallUserDetail,
  }))

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(deleteMahallUser(mahallUserDetail.id, history))
    setIsOpen(false)
    // history.push("/MahallUsers")
  }

  useEffect(() => {
    dispatch(getMahallUserDetail(params.id))
  }, [])
  // const project = []
  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <MetaTags>
        <title>Mahall User | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="All MahallUsers" breadcrumbItem="MahallUser" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col xl="3"></Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    {detailLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {/* <Media>
                          <img
                            className="d-flex me-3 rounded-circle avatar-sm"
                            src={userProfile}
                            alt="osperb"
                          />

                          <Media className="overflow-hidden" body>
                            <h5 className="text-truncate font-size-15">
                              {mahalluserDetail.account}
                            </h5>
                          </Media>
                        </Media> */}

                        <h5 className="font-size-15 mt-4">
                          mahalluser Details :
                        </h5>

                        <p className="text-muted">{mahallUserDetail.mahall}</p>

                        <div className="text-muted mt-4">
                          <p>
                            <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                            Serial No: {mahallUserDetail.auto_id}
                          </p>
                          <p>
                            <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                            User: {mahallUserDetail.account}
                          </p>
                          <p>
                            <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                            Mahall: {mahallUserDetail.mahall}
                          </p>
                          <p>
                            <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                            permission to view:{" "}
                            {mahallUserDetail.perm_view ? "true" : "false"}
                          </p>
                          <p>
                            <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                            permission to create:{" "}
                            {mahallUserDetail.perm_create ? "true" : "false"}
                          </p>
                          <p>
                            <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                            permission toupdate:{" "}
                            {mahallUserDetail.perm_update ? "true" : "false"}
                          </p>
                          <p>
                            <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                            permission to delete:{" "}
                            {mahallUserDetail.perm_delete ? "true" : "false"}
                          </p>
                        </div>
                      </>
                    )}

                    <Row className="task-dates">
                      <Col sm="6" xs="6">
                        <div className="d-flex">
                          <div className="">
                            <Link
                              to={`/mahalluser/update/${mahallUserDetail?.id}`}
                              className={`btn btn-secondary ${
                                loading && "disabled"
                              } btn-m`}
                            >
                              Edit{" "}
                              <i className="mdi mdi-arrow-right ms-1 bx-fade-right" />
                            </Link>
                          </div>
                          <div style={{ position: "relative", left: "10px" }}>
                            <Link
                              style={{ opacity: 0.8 }}
                              to="#"
                              className={`btn btn-outline-light ${
                                loading && "disabled"
                              }  btn-m`}
                              onClick={handleDelete}
                            >
                              Remove
                              <i className="fas fa-trash text-danger ms-1 bx-tada-hover"></i>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12"></Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default MahallMahallUserDetails

MahallMahallUserDetails.propTypes = {
  history: PropTypes.object,
}
