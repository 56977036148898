import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import PropTypes from "prop-types"

import { getZakats, getZakatCards } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"

const AllZakats = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const { zakats, loading, language } = useSelector(state => ({
    zakats: state.Zakats?.zakats?.results,
    loading: state.Zakats?.loading,
    zakatCards: state.Zakats?.zakats?.results?.cards,
    language: state.Layout.language,
  }))

  //pages
  const totalPages = Math.ceil(zakats?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getZakats(searchText, pageSend()))
  }, [dispatch, page, searchText])

  useEffect(() => {
    dispatch(getZakatCards())
  }, [dispatch])

  // const handleDelete = id => {
  //   dispatch(deleteZakat(id, history))
  // }

  const cardData = [
    // {
    //   title: "Total Zakats",
    //   cardValue: zakatCards?.total_zakats,
    //   iconClass: "error-circle",
    //   routeLink: "#!",
    //   loading: loading,
    //   alt: "No Zakats",
    // },
    // {
    //   title: "This Month",
    //   cardValue: zakatCards?.total_zakats_this_month,
    //   iconClass: "error-circle",
    //   routeLink: "#!",
    //   loading: loading,
    //   alt: "No Zakats",
    // },
    // {
    //   title: "Unpaid",
    //   cardValue: ZakatCards?.total_fee_not_paid_Zakats,
    //   iconClass: "check-circle",
    //   routeLink: "#!",
    //   loading: loading,
    //   alt: "No unpaid Zakats",
    // },
  ]

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "title",
      text: language === "mal" ? "തലക്കെട്ട്" : "Title",
    },
    {
      dataField: "description",
      text: language === "mal" ? "വിവരണം" : "Description",
    },
    {
      dataField: "year",
      text: language === "mal" ? "വർഷം" : "Year",
    },
    {
      dataField: "action",
      text: language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    },
  ]

  const zakatsData = map(zakats, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    action: (
      <div className="m-0">
        {/* <Link to={`/zakats/${item?.id}`} className="btn-sm">
          <i
            className="bx bx-show me-2"
            style={{ color: "green", fontSize: "18px" }}
          />
        </Link> */}
        <Link to={`/zakats/${item?.id}`} className="m-0">
          <Button type="button" color="success" className=" btn w-xs btn-sm">
            {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
            {language === "mal" ? "കാണുക" : "View"}
          </Button>
        </Link>

        {/* <Button
          type="button"
          color="white"
          className=" m-0 btn "
          onClick={() => { handleDelete(item.id) }}
        >
          <i className="bx bx-trash me-2" style={{ color: "red", fontSize: "18px" }} />
        </Button> */}
      </div>
    ),

    statusData: (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          `${
            item?.status == "new"
              ? "success"
              : item?.status == "pending"
              ? "warning"
              : item?.status == "checked"
              ? "primary"
              : item?.status == "confirmed"
              ? "secondary"
              : item?.status == "closed"
              ? "danger"
              : ""
          }`
        }
        color={"primary"}
        pill
      >
        {item?.status}
      </Badge>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceZakatSearch = debounce(value => setSearchText(value), 600)

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        {cardData?.map((item, key) => (
          <Col lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink && item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue > 0 && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p>
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Col sm="12" md="12" lg="8">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={zakatsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceZakatSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <div className="col-md-8">
                        <Link
                          to="/zakat/register"
                          style={{
                            width: language === "mal" ? "120px" : "60px",
                          }}
                          className="w-md btn btn-success m-auto me-0 d-block"
                        >
                          {language === "mal" ? "+ സകാത്ത് " : "+ Zakat"}
                        </Link>
                      </div>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {zakats && zakats?.length <= 0 && (
                      <p
                        style={{ fontSize: "15px" }}
                        className="text-center text-info"
                      >
                        {language === "mal"
                          ? "സകാത്തുകൾ ഒന്നും ഇല്ല"
                          : "No Zakats Yet"}
                      </p>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllZakats

AllZakats.propTypes = {
  history: PropTypes.object,
}
