import React, { Fragment, useEffect } from "react"
import { Col, Container, Row, Card, CardBody, Spinner } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
//actions

import { getActivityLogDetail } from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"

const ActivityLogDetailsAdmin = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, activityLogDetail, language } = useSelector(state => ({
    loading: state.ActivityLogs.loading,
    activityLogDetail: state.ActivityLogs.activityLogDetail,
    language: state.Layout.language,
  }))

  useEffect(() => {
    dispatch(getActivityLogDetail(params.id))
  }, [dispatch])

  const logData = [
    {
      label: `${language === "mal" ? "യൂസർ" : "Username"}`,
      value: activityLogDetail?.username,
    },
    {
      label: `${language === "mal" ? "മഹല്ല്" : "Mahall"}`,
      value: activityLogDetail?.mahall_name,
    },
    {
      label: `${language === "mal" ? "മൊഡ്യൂൾ" : "Module"}`,
      value: activityLogDetail?.content_app_label,
    },
    {
      label: `${language === "mal" ? "സെഗ്മെന്റ്" : "Segment"}`,
      value: activityLogDetail?.content_model,
    },
    {
      label: `${language === "mal" ? "പ്രവർത്തനം" : "Action"}`,
      value: activityLogDetail?.action,
    },
    {
      label: `${language === "mal" ? "ഒബ്ജക്ട് ഐഡി" : "Object ID"}`,
      value: activityLogDetail?.object_id,
    },
    {
      label: `${language === "mal" ? "ഐ പി അഡ്രസ്സ്" : "IP Address"}`,
      value: activityLogDetail?.ip_address,
    },
    {
      label: `${
        language === "mal" ? "പ്രവർത്തന വിശദാംശങ്ങൾ" : "Activity Details"
      }`,
      value: activityLogDetail?.log_data,
    },
  ]

  return (
    <>
      <MetaTags>
        <title>Log Detail | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={`${language === "mal" ? "പ്രവർത്തന വിവരങ്ങൾ " : "Log Detail"}`}
          breadcrumbItem={`${
            language === "mal" ? "പ്രവർത്തന രേഖകൾ" : "Activity Log"
          }`}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md="8" lg="8">
                <Card>
                  <CardBody>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <div className="mb-3">
                        <h4 className="text-center text-black pb-4">
                          {language === "mal"
                            ? "പ്രവർത്തന വിവരങ്ങൾ"
                            : "Log Details"}
                        </h4>
                        {logData?.map((item, key) => (
                          <div key={key} className="d-flex w-100">
                            <h5 className="w-25 text-black">{item?.label}</h5>
                            <span>{":"}</span>
                            <p className="w-75 px-2 fs-5">{item?.value}</p>
                          </div>
                        ))}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default ActivityLogDetailsAdmin

ActivityLogDetailsAdmin.propTypes = {
  history: PropTypes.object,
}
