import React from "react"
import { Container, Row, Col } from "reactstrap"

import { useLanguage } from "../Language/SwitchLanguage"

import ImageCards from "./cards-mini"

const Section = () => {
  const text = useLanguage()

  return (
    <React.Fragment>
      <section
        className="section pt-0 hero-section bg-ico-hero home_wrapper"
        id="home"
      >
        <div className="bg-primary" />
        <Container className="home_cont">
          <Row className="align-items-center">
            <Col lg="12">
              <div className="text-white-50 text-center">
                <p
                  className=" text-white font-size-20 mb-0 text-uppercase"
                  style={{ letterSpacing: "1.2px" }}
                >
                  {/* {text?.mms} */}
                </p>
                <h1
                  className="text-white font-weight-semibold mb-3 font-size-bigger"
                  style={{
                    letterSpacing: "1.2px",
                  }}
                >
                  {text?.home_head}
                </h1>
                <h2 className="text-white font-size-big w-50 m-auto sm:w-80 ">
                  {text?.home_subhead}
                </h2>

                <div
                  className="d-flex flex-wrap gap-2 mt-4 mx-auto "
                  style={{ margin: "auto", width: "fit-content" }}
                >
                  <a href="#contact" className="btn btn-success me-1">
                    {text?.contact}
                  </a>
                </div>
              </div>
              <ImageCards />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Section
