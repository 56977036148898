import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import Select from "react-select"
import { Col, Modal, Button, Label, Row } from "reactstrap"
// import moment from 'moment'

import {
  updateCommitteeMeetingAttendance,
  getCommitteeMemberDropdown,
  deleteCommitteeMeetingAttendance,
} from "store/actions"

const UiModalMeetingAttendanceUpdate = ({
  show,
  onCloseclick,
  history,
  data,
}) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const meetingAttendance = data

  const { loading, language, searchCommitteeMembers, committeeMeetingDetail } =
    useSelector(state => ({
      loading: state.Mahalls.loading,
      language: state.Layout.language,
      searchCommitteeMembers: state.CommitteeMembers.searchCommitteeMembers,
      committeeMeetingDetail: state.CommitteeMeetings.committeeMeetingDetail,
    }))
  console.log(committeeMeetingDetail)
  // const now = moment().format('HH:mm')
  // console.log("time: ", now);

  const [searchText, setSearchText] = useState("")
  const [member, setMember] = useState(meetingAttendance?.committee_member_name)
  const [memberId, setMemberId] = useState("")

  useEffect(() => {
    setMember(meetingAttendance?.committee_member_name)
    setMemberId(meetingAttendance?.committee_member)
  }, [meetingAttendance])

  useEffect(() => {
    if (meetingAttendance?.committee) {
      dispatch(
        getCommitteeMemberDropdown(meetingAttendance?.committee, searchText)
      )
    }
  }, [dispatch, meetingAttendance?.committee, searchText])

  function handlerFinalValue(event) {
    // dispatch(getCommitteeMemberDropdown(event.value))
    setMemberId(event.value)
    setMember(event.label)
  }

  const optionGroup = [
    {
      options: searchCommitteeMembers?.map((results, index) => ({
        key: index,
        label: results.committee_member_name,
        value: results.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const updateMeetingAttendanceData = {
      committee_meeting: meetingAttendance?.committee_meeting,
      committee_member: memberId,
      attendance_time: values.attendance_time,
      description: values.description,
    }
    // console.log(updateMeetingAttendanceData);
    dispatch(
      updateCommitteeMeetingAttendance(
        updateMeetingAttendanceData,
        meetingAttendance?.id,
        history
      )
    )
  }

  const handleDeleteMeetingAttendance = id => {
    // console.log(`delete item ${id}`);
    dispatch(
      deleteCommitteeMeetingAttendance(
        id,
        history,
        meetingAttendance?.committee_meeting
      )
    )
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal"
                ? "ഹാജർ അപ്ഡേറ്റ് ചെയ്യുക"
                : "Update Attendance"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col md="4">
                  <Label>{language === "mal" ? "സമയം" : "Time"}</Label>
                  <AvField
                    name="attendance_time"
                    type="time"
                    value={meetingAttendance?.attendance_time}
                    helpMessage="24 Hr format"
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "അംഗം" : "Member"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={member}
                    options={optionGroup}
                    value={member}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="CommitteeMember"
                    required="required"
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    value={meetingAttendance?.description}
                    label={language === "mal" ? "വിവരണം" : "Note"}
                  />
                </Col>
              </div>
              <Row className="mt-5 d-flex">
                <div className="col-6">
                  <div className="row justify-content-start">
                    <div>
                      <Button
                        type="button"
                        color="danger"
                        className="w-md"
                        onClick={() => {
                          onCloseclick()
                          handleDeleteMeetingAttendance(meetingAttendance?.id)
                        }}
                      >
                        {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md m-auto me-0 d-block"
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക " : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalMeetingAttendanceUpdate
UiModalMeetingAttendanceUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
  data: propTypes.object,
}
