import {
  GET_COMMITTEE_MEETING_ATTENDANCES_SUCCESS,
  GET_COMMITTEE_MEETING_ATTENDANCES_FAIL,
  GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_SUCCESS,
  GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_FAIL,
  CREATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS,
  CREATE_COMMITTEE_MEETING_ATTENDANCE_FAIL,
  UPDATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS,
  UPDATE_COMMITTEE_MEETING_ATTENDANCE_FAIL,
  DELETE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS,
  DELETE_COMMITTEE_MEETING_ATTENDANCE_FAIL,
  GET_COMMITTEE_MEETING_ATTENDANCES,
  GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL,
  UPDATE_COMMITTEE_MEETING_ATTENDANCE,
  CREATE_COMMITTEE_MEETING_ATTENDANCE,
  DELETE_COMMITTEE_MEETING_ATTENDANCE,
} from "./actionTypes"

const INIT_STATE = {
  committeeMeetingAttendances: [],
  committeeMeetingAttendanceDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdCommitteeMeetingAttendance: {},
}

const CommitteeMeetingAttendances = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMITTEE_MEETING_ATTENDANCES:
    case UPDATE_COMMITTEE_MEETING_ATTENDANCE:
    case CREATE_COMMITTEE_MEETING_ATTENDANCE:
    case DELETE_COMMITTEE_MEETING_ATTENDANCE:
      return {
        ...state,
        loading: true,
      }
    case GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_COMMITTEE_MEETING_ATTENDANCES_SUCCESS:
      return {
        ...state,
        committeeMeetingAttendances: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_MEETING_ATTENDANCES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS:
      return {
        ...state,
        // committeeMeetingAttendances: action.payload,
        committeeMeetingAttendances: {
          ...state.committeeMeetingAttendances,
          results: [
            ...state.committeeMeetingAttendances.results,
            action.payload,
          ],
        },
        createdCommitteeMeetingAttendance: action.payload,
        loading: false,
      }

    case CREATE_COMMITTEE_MEETING_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_SUCCESS:
      return {
        ...state,
        committeeMeetingAttendanceDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_COMMITTEE_MEETING_ATTENDANCE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS:
      return {
        ...state,
        committeeMeetingAttendanceDetail: action.payload,
        committeeMeetingAttendances: {
          results: state.committeeMeetingAttendances.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_COMMITTEE_MEETING_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_COMMITTEE_MEETING_ATTENDANCE_SUCCESS:
      return {
        ...state,
        // committeeMeetingAttendances: action.payload,
        committeeMeetingAttendances: {
          results: state.committeeMeetingAttendances.results.filter(
            committeeMeetingAttendance =>
              committeeMeetingAttendance.id !== action.payload
          ),
        },
        loading: false,
      }

    case DELETE_COMMITTEE_MEETING_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default CommitteeMeetingAttendances
