import {
  GET_UNAPPROVED_FAMILY_MEMBERS,
  UPDATE_UNAPPROVED_FAMILY_MEMBER,
  DELETE_UNAPPROVED_FAMILY_MEMBER,
  GET_UNAPPROVED_FAMILY_MEMBER_DETAIL,
  GET_UNAPPROVED_FAMILY_MEMBERS_SUCCESS,
  GET_UNAPPROVED_FAMILY_MEMBERS_FAIL,
  GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_SUCCESS,
  GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_FAIL,
  UPDATE_UNAPPROVED_FAMILY_MEMBER_SUCCESS,
  UPDATE_UNAPPROVED_FAMILY_MEMBER_FAIL,
  DELETE_UNAPPROVED_FAMILY_MEMBER_SUCCESS,
  DELETE_UNAPPROVED_FAMILY_MEMBER_FAIL,
  GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_SUCCESS,
  GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  unapprovedFamilyMembers: [],
  unapprovedFamilyMemberDetail: {},
  searchUnapprovedFamilyMembers: [],
  error: {},
  loading: false,
  detailLoading: false,
}

const UnapprovedFamilyMembers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UNAPPROVED_FAMILY_MEMBERS:
    case GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_SUCCESS:
    case UPDATE_UNAPPROVED_FAMILY_MEMBER:
    case DELETE_UNAPPROVED_FAMILY_MEMBER:
      return {
        ...state,
        loading: true,
      }

    case GET_UNAPPROVED_FAMILY_MEMBER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_UNAPPROVED_FAMILY_MEMBERS_SUCCESS:
      return {
        ...state,
        unapprovedFamilyMembers: action.payload,
        loading: false,
      }
    case GET_UNAPPROVED_FAMILY_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        unapprovedFamilyMemberDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_UNAPPROVED_FAMILY_MEMBER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    // eslint-disable-next-line no-duplicate-case
    case GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchUnapprovedFamilyMembers: action.payload,
        loading: false,
      }
    case GET_UNAPPROVED_FAMILY_MEMBER_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_UNAPPROVED_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        unapprovedFamilyMemberDetail: action.payload,
        loading: false,
      }

    case UPDATE_UNAPPROVED_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_UNAPPROVED_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        // familyMembers: state.familyMembers.filter(
        //   familyMember =>
        //     familyMember.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_UNAPPROVED_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default UnapprovedFamilyMembers
