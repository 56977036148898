import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SURVEY_USER,
  GET_SURVEY_USER_DETAIL,
  CHANGE_SURVEY_USER_STATUS,
  CREATE_SURVEY_USER,
  UPDATE_SURVEY_USER,
  DELETE_SURVEY_USER,
} from "./actionTypes"
import {
  getSurveyUserSuccess,
  getSurveyUserFail,
  getSurveyUserDetailSuccess,
  getSurveyUserDetailFail,
  createSurveyUserSuccess,
  createSurveyUserFail,
  updateSurveyUserSuccess,
  updateSurveyUserFail,
  deleteSurveyUserSuccess,
  deleteSurveyUserFail,
  changeSurveyUserStatusFail,
  changeSurveyUserStatusSuccess,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getSurveyUserAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/surveyuser/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/mahall/surveyuser/?page=${page ? page : 1}`)
  }
}
const getSurveyUserDetailsAPi = surveyUserId => {
  return get(`/api/v1/mahall/surveyuser/${surveyUserId}/`)
}
const changeSurveyUserStatusApi = ({ surveyUserId }) => {
  return post(`/api/v1/mahall/surveyuser/status/${surveyUserId}/`)
}
const createSurveyUserApi = ({ surveyUser }) => {
  return post("/api/v1/mahall/surveyuser/", surveyUser)
}
const updateSurveyUserApi = ({ surveyUser, surveyUserId }) => {
  return ApiPut(`/api/v1/mahall/surveyuser/${surveyUserId}/`, surveyUser)
}
const deleteSurveyUserApi = ({ surveyUserId }) => {
  return del(`/api/v1/mahall/surveyuser/${surveyUserId}/`)
}

function* fetchSurveyUser({ payload }) {
  try {
    const response = yield call(getSurveyUserAPi, payload)
    if (response && !response?.error) {
      yield put(getSurveyUserSuccess(response))
    } else {
      yield put(getSurveyUserFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getSurveyUserFail(error))
  }
}

function* fetchSurveyUserDetail({ surveyUserId }) {
  try {
    const response = yield call(getSurveyUserDetailsAPi, surveyUserId)
    if (response && !response?.error) {
      yield put(getSurveyUserDetailSuccess(response))
    } else {
      yield put(getSurveyUserDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getSurveyUserDetailFail(error))
  }
}

function* changeSurveyUserStatus({ payload }) {
  try {
    const response = yield call(changeSurveyUserStatusApi, payload)
    if (
      response &&
      !response?.error &&
      response[0] === "Successfully Updated"
    ) {
      yield put(changeSurveyUserStatusSuccess(payload))
      doneNotification("Status Changed Successfully")
    } else {
      yield put(changeSurveyUserStatusFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(changeSurveyUserStatusFail(error))
  }
}

function* onCreateSurveyUser({ payload }) {
  try {
    const response = yield call(createSurveyUserApi, payload)
    if (response && !response?.error) {
      yield put(createSurveyUserSuccess(response))
      payload.history.push("/surveyusers")
      doneNotification("Created Successfully")
    } else {
      yield put(createSurveyUserFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createSurveyUserFail(error))
    // if (error?.response?.data?.error[0]) {
    //   yield put(createSurveyUserFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function* onUpdateSurveyUser({ payload }) {
  try {
    const response = yield call(updateSurveyUserApi, payload)
    if (response && !response?.error) {
      yield put(updateSurveyUserSuccess(response))
      payload.history.push("/surveyusers")
      doneNotification("Updated Successfully")
    } else {
      yield put(updateSurveyUserFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateSurveyUserFail(error))
    // if (error?.response?.data?.error[0]) {
    //   yield put(updateSurveyUserFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function* onDeleteSurveyUser({ payload }) {
  try {
    const response = yield call(deleteSurveyUserApi, payload)
    if (response && !response?.error) {
      payload.history.push("/surveyusers")
      yield put(deleteSurveyUserSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteSurveyUserFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(deleteSurveyUserFail(error))
    // if (error?.response?.data?.error[0]) {
    //   yield put(deleteSurveyUserFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* SurveyUsersSaga() {
  yield takeEvery(GET_SURVEY_USER, fetchSurveyUser)
  yield takeEvery(GET_SURVEY_USER_DETAIL, fetchSurveyUserDetail)
  yield takeEvery(CHANGE_SURVEY_USER_STATUS, changeSurveyUserStatus)
  yield takeEvery(CREATE_SURVEY_USER, onCreateSurveyUser)
  yield takeEvery(UPDATE_SURVEY_USER, onUpdateSurveyUser)
  yield takeEvery(DELETE_SURVEY_USER, onDeleteSurveyUser)
}

export default SurveyUsersSaga
