/* STUDENTS */
export const GET_STUDENTS = "GET_STUDENTS"
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS"
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL"

/* STUDENTS_DROPDOWN*/
export const STUDENTS_DROPDOWN = "STUDENTS_DROPDOWN"
export const STUDENTS_DROPDOWN_SUCCESS = "STUDENTS_DROPDOWN_SUCCESS"
export const STUDENTS_DROPDOWN_FAIL = "STUDENTS_DROPDOWN_FAIL"

/* STUDENT  DETAIL*/
export const GET_STUDENT_DETAIL = "GET_STUDENT_DETAIL"
export const GET_STUDENT_DETAIL_SUCCESS = "GET_STUDENT_DETAIL_SUCCESS"
export const GET_STUDENT_DETAIL_FAIL = "GET_STUDENT_DETAIL_FAIL"

/* GET_STUDENT_ABSENT_DETAIL*/
export const GET_STUDENT_ABSENT_DETAIL = "GET_STUDENT_ABSENT_DETAIL"
export const GET_STUDENT_ABSENT_DETAIL_SUCCESS =
  "GET_STUDENT_ABSENT_DETAIL_SUCCESS"
export const GET_STUDENT_ABSENT_DETAIL_FAIL = "GET_STUDENT_ABSENT_DETAIL_FAIL"

/**
 * add STUDENT
 */
export const CREATE_STUDENT = "CREATE_STUDENT"
export const CREATE_STUDENT_SUCCESS = "CREATE_STUDENT_SUCCESS"
export const CREATE_STUDENT_FAIL = "CREATE_STUDENT_FAIL"

/**
 * Edit STUDENT
 */
export const UPDATE_STUDENT = "UPDATE_STUDENT"
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS"
export const UPDATE_STUDENT_FAIL = "UPDATE_STUDENT_FAIL"

/**
 * Delete STUDENT
 */
export const DELETE_STUDENT = "DELETE_STUDENT"
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS"
export const DELETE_STUDENT_FAIL = "DELETE_STUDENT_FAIL"
