import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
//actions
// import moment from 'moment'

import {
  createVarisangyaSplitLedger,
  getLedgerDropdown,
  getVarisangyaSplitLedgers,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const UpdateVarisangyaSplit = ({ history }) => {
  const dispatch = useDispatch()

  const [searchText] = useState("")
  const [ledger] = useState("Select ledger...")
  const [ledgerId] = useState()
  console.log(ledger)
  console.log(ledgerId)
  const {
    loading,
    // error,
    language,
    // userDetail,
    searchLedgers,
    varisangyaSplitLedgers,
  } = useSelector(state => ({
    loading: state.DeathRegistrations.loading,
    error: state.DeathRegistrations.error,
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    searchLedgers: state.Ledgers.searchLedgers,
    varisangyaSplitLedgers:
      state.VarisangyaSplitLedgers?.varisangyaSplitLedgers,
  }))

  // const today = moment(new Date()).format('yyyy-MM-DD')

  const [splitLedgers, setSplitLedgers] = useState(
    varisangyaSplitLedgers?.results || [
      {
        institute_ledger: "",
        default_title: "",
        percentage: parseInt(""),
      },
    ]
  )

  useEffect(() => {
    dispatch(getVarisangyaSplitLedgers("", 1))
  }, [dispatch])

  useEffect(() => {
    setSplitLedgers(varisangyaSplitLedgers?.results)
    console.log(
      "varisangyaSplitLedgers?.results: ",
      varisangyaSplitLedgers?.results
    )
  }, [loading, varisangyaSplitLedgers.results])

  useEffect(() => {
    dispatch(getLedgerDropdown(searchText))
  }, [searchText])

  // useEffect(() => {
  //   console.log('splitLedgers: ', splitLedgers);
  // }, [splitLedgers])

  const [percentage, setPercentage] = useState()

  const total = splitLedgers?.reduce((a, b) => {
    return Number(a) + Number(b.percentage)
  }, 0)

  // handleValidSubmit
  const handleValidSubmit = () => {
    if (total === 100) {
      const splitData = {
        split_ledgers: splitLedgers,
      }
      dispatch(createVarisangyaSplitLedger(splitData, history))
    }
    setPercentage(total)
  }

  // const handleEnters = textEntered => {
  //   setSearchText(textEntered)
  // }
  // const optionGroup = [
  //   {
  //     options: searchLedgers?.map((result, index) => ({
  //       key: index,
  //       label: `${result?.institute_ledger_name} (${
  //         language === 'mal'
  //           ? result?.institute_malayalam_name
  //             ? result?.institute_malayalam_name
  //             : result?.institute_english_name
  //           : result?.institute_english_name
  //       }) (${result?.institute_type})`,
  //       value: result.id,
  //     })),
  //   },
  // ]

  // function handlerFinalValue(event, item) {
  //   setLedgerId(event.value)
  //   setLedger(event.label)
  //   setSplitLedgers(splitLedger =>
  //     splitLedger.map(x =>
  //       x === item ? { ...x, institute_ledger: event.value } : x,
  //     ),
  //   )
  // }

  return (
    <>
      <MetaTags>
        <title>Split Ledger | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ലെഡ്ജറുകൾ വിഭജിക്കുക" : "Split Ledger"}
          breadcrumbItem={language === "mal" ? "വരിസംഖ്യ" : "Varisangya"}
        />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <Row>
                <Col sm="12" md="8" lg="8">
                  <Card>
                    <CardBody>
                      <Col className="col-12 d-flex flex-wrap">
                        <Col className="col-12 ">
                          {/* Tax Categories */}
                          <div className="col-12 mb-4">
                            <Row>
                              <Col md="4">
                                <Label htmlFor="lbltype" className="form-label">
                                  Split Ledgers :{" "}
                                </Label>
                              </Col>
                              <Col md="2">
                                <button
                                  className="mx-2 btn"
                                  onClick={e => {
                                    e.preventDefault()
                                    setSplitLedgers(currentSplitLedger => [
                                      ...currentSplitLedger,
                                      {
                                        institute_ledger: "",
                                        default_title: "",
                                        percentage: "",
                                      },
                                    ])
                                  }}
                                >
                                  <i
                                    className="bx bx-plus"
                                    style={{ fontSize: "20px" }}
                                  ></i>
                                </button>
                              </Col>
                            </Row>

                            <Col md="12" className="mx-3">
                              {splitLedgers?.map((s, index) => {
                                return (
                                  <div key={index}>
                                    <Row>
                                      <Col>
                                        <AvField
                                          type="select"
                                          name="institute_ledger"
                                          value={s.institute_ledger}
                                          onChange={() => {
                                            // const name = e.target.value
                                            setSplitLedgers(
                                              currentSplitLedger =>
                                                currentSplitLedger.map(x =>
                                                  x === s
                                                    ? {
                                                        ...x,
                                                        // institute_ledger,
                                                      }
                                                    : x
                                                )
                                            )
                                          }}

                                          // helpMessage="Select a Shipping Input"
                                        >
                                          <option>Select a Ledger</option>
                                          {searchLedgers?.map((item, key) => (
                                            <option key={key} value={item.id}>
                                              {item.institute_english_name}
                                            </option>
                                          ))}
                                        </AvField>
                                      </Col>

                                      <Col className="db__column">
                                        <AvField
                                          name="default_title"
                                          type="text"
                                          value={s.default_title}
                                          placeholder="Title"
                                          onChange={e => {
                                            const default_title = e.target.value
                                            setSplitLedgers(
                                              currentSplitLedger =>
                                                currentSplitLedger.map(x =>
                                                  x === s
                                                    ? {
                                                        ...x,
                                                        default_title,
                                                      }
                                                    : x
                                                )
                                            )
                                          }}
                                        />
                                      </Col>

                                      <Col className="db__column">
                                        <AvField
                                          name="percentage"
                                          type="text"
                                          value={s.percentage}
                                          placeholder="Percentage"
                                          onChange={e => {
                                            const percentage = e.target.value
                                            setSplitLedgers(
                                              currentSplitLedger =>
                                                currentSplitLedger.map(x =>
                                                  x === s
                                                    ? {
                                                        ...x,
                                                        percentage,
                                                      }
                                                    : x
                                                )
                                            )
                                          }}
                                        />
                                      </Col>

                                      <Col>
                                        <button
                                          className="mx-2 btn"
                                          onClick={e => {
                                            e.preventDefault()
                                            setSplitLedgers(
                                              currentSplitLedger =>
                                                currentSplitLedger.filter(
                                                  x => x !== s
                                                )
                                            )
                                          }}
                                        >
                                          <i
                                            className="bx bx-trash"
                                            style={{
                                              fontSize: "20px",
                                            }}
                                          ></i>
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                )
                              })}
                            </Col>
                          </div>
                        </Col>
                      </Col>
                      <p className="text-danger">
                        {percentage > 100 || percentage < 100
                          ? language === "mal"
                            ? "മൊത്തം ശതമാനം 100 ആയിരിക്കണം"
                            : "Total Percentage must be 100"
                          : ""}
                      </p>
                      <div className="d-flex justify-content-end">
                        <Button color="success" type="submit">
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateVarisangyaSplit

UpdateVarisangyaSplit.propTypes = {
  history: PropTypes.object,
}
