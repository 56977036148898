import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
//actions
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
// import moment from 'moment'
import Select from "react-select"
import moment from "moment"

import {
  createStudent,
  getAllFamilyMembersDropdown,
  getFamilyMemberDetail,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const InstituteCreateStudent = ({ history }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const divisionId = location?.search?.split("?divisionid=")[1]

  const { loading, language, allFamilyMembers, familyMemberDetail } =
    useSelector(state => ({
      loading: state.Mahalls.loading,
      language: state.Layout.language,
      allFamilyMembers: state.FamilyMembers.allFamilyMembers,
      familyMemberDetail: state.FamilyMembers.familyMemberDetail,
    }))

  const today = moment(new Date()).format("yyyy-MM-DD")

  const [searchText, setSearchText] = useState("")
  const [familyMember, setFamilyMember] = useState("Select student...")
  const [familyMemberId, setFamilyMemberId] = useState()

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const createStudentsData = {
      family_member: familyMemberId,
      division: divisionId,
      name: values?.name,
      dob: values?.dob,
      roll_no: parseInt(values?.roll_no),
      parent_name: values?.parent_name,
      parent_phone: values?.parent_phone,
      varisankya_amount: parseInt(values?.varisankya_amount),
      varisankya_wallet: parseInt(values?.varisankya_wallet),
    }
    dispatch(createStudent(createStudentsData, history, dispatch))
  }

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchText))
  }, [dispatch, searchText])

  useEffect(() => {
    if (familyMemberId) {
      dispatch(getFamilyMemberDetail(familyMemberId))
    }
  }, [dispatch, familyMemberId])

  const optionGroup = [
    {
      options: allFamilyMembers?.map((result, index) => ({
        key: index,
        label: `${result?.full_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        })`,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setFamilyMemberId(event.value)
    setFamilyMember(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  return (
    <>
      <MetaTags>
        <title>Students | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "വിദ്യാർത്ഥികൾ" : "Students"}
          breadcrumbItem={language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md={8}>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4 mb-4">Fill this form</CardTitle> */}
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="col-md-6">
                        <div className="col mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-12 col-form-label"
                          >
                            {language === "mal" ? "വിദ്യാർത്ഥി" : "Student"}
                          </Label>
                          <Select
                            onInputChange={handleEnters}
                            placeholder={familyMember}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="Family Member"
                          />
                        </div>
                      </div>
                      <Row>
                        <Col md="6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Name"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="name"
                              value={
                                familyMemberDetail?.full_name
                                  ? familyMemberDetail?.full_name
                                  : ""
                              }
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                },
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="row mb-4">
                            <div className="col-md-6">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-12 col-form-label"
                              >
                                {language === "mal" ? "ക്രമ നം." : "Roll No."}
                              </Label>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="roll_no"
                                type="number"
                                value={"0"}
                              />
                            </div>
                            <div className="col-md-6">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-12 col-form-label"
                              >
                                {language === "mal" ? "ഡി ഒ ബി" : "DOB"}
                              </Label>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="dob"
                                value={
                                  familyMemberDetail?.dob
                                    ? familyMemberDetail?.dob
                                    : today
                                }
                                type="date"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "രക്ഷിതാവിന്റെ പേര്"
                                : "Parent Name"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="parent_name"
                              value={
                                familyMemberDetail?.father_name
                                  ? familyMemberDetail?.father_name
                                  : ""
                              }
                              type="text"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "രക്ഷിതാവിന്റെ ഫോൺ നമ്പർ"
                                : "Parent Phone"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="parent_phone"
                              type="number"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "വരിസംഖ്യ തുക"
                                : "Varisankya Amount"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="varisankya_amount"
                              value="0"
                              type="number"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "വരിസംഖ്യ വാലറ്റ്"
                                : "Varisankya Wallet"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="varisankya_wallet"
                              type="number"
                              value="0"
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                        <div className="col-md-12">
                          <div className="col mb-4">
                            <AvField
                              id="horizontal-firstname-Input"
                              name="description"
                              type="textarea"
                              label={
                                language === 'mal' ? 'വിവരണം' : 'Description'
                              }
                            />
                          </div>
                        </div>
                      </Row> */}

                      <div className="d-flex justify-content-end mt-5">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default InstituteCreateStudent

InstituteCreateStudent.propTypes = {
  history: PropTypes.object,
}
