/* eslint-disable no-fallthrough */
import {
  GET_SURVEY_USER,
  GET_SURVEY_USER_SUCCESS,
  GET_SURVEY_USER_FAIL,
  GET_SURVEY_USER_DETAIL,
  GET_SURVEY_USER_DETAIL_SUCCESS,
  GET_SURVEY_USER_DETAIL_FAIL,
  CHANGE_SURVEY_USER_STATUS_SUCCESS,
  CHANGE_SURVEY_USER_STATUS_FAIL,
  CREATE_SURVEY_USER,
  CREATE_SURVEY_USER_SUCCESS,
  CREATE_SURVEY_USER_FAIL,
  UPDATE_SURVEY_USER,
  UPDATE_SURVEY_USER_SUCCESS,
  UPDATE_SURVEY_USER_FAIL,
  DELETE_SURVEY_USER,
  DELETE_SURVEY_USER_SUCCESS,
  DELETE_SURVEY_USER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  surveyUsers: [],
  surveyUserDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdsurveyUser: {},
  surveyUserStatus: [],
}

const SurveyUsers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SURVEY_USER:
    // case CHANGE_SURVEY_USER_STATUS:
    case UPDATE_SURVEY_USER:
    case CREATE_SURVEY_USER:
    case DELETE_SURVEY_USER:
      return {
        ...state,
        loading: true,
      }
    case GET_SURVEY_USER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_SURVEY_USER_SUCCESS:
      return {
        ...state,
        surveyUsers: action.payload,
        loading: false,
      }

    case GET_SURVEY_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CHANGE_SURVEY_USER_STATUS_SUCCESS:
      return {
        ...state,
        surveyUserStatus: action.payload,
        surveyUsers: {
          results: state.surveyUsers.results?.map(item =>
            item.id === action.payload.surveyUserId
              ? { ...item, is_active: action.payload.checked }
              : item
          ),
        },
        // loading: false,
      }

    case CHANGE_SURVEY_USER_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        // loading: false,
      }

    case CREATE_SURVEY_USER_SUCCESS:
      return {
        ...state,
        surveyUsers: action.payload,
        createdsurveyUser: action.payload,
        loading: false,
      }

    case CREATE_SURVEY_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SURVEY_USER_DETAIL_SUCCESS:
      return {
        ...state,
        surveyUserDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_SURVEY_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_SURVEY_USER_SUCCESS:
      return {
        ...state,
        surveyUserDetail: action.payload,
        loading: false,
      }

    case UPDATE_SURVEY_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_SURVEY_USER_SUCCESS:
      return {
        surveyUsers: action.payload,
        loading: false,
      }

    case DELETE_SURVEY_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default SurveyUsers
