import { AvField, AvForm } from "availity-reactstrap-validation"
import React from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import { createGroup } from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateGroup = ({ history }) => {
  const dispatch = useDispatch()

  const { loading, language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    error: state.Mahalls.error,
    language: state.Layout.language,
  }))

  // const versions = ["basic", "premium"]

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    // console.log(values)
    const createGroupData = {
      mahallgroup_english_name: values.mahallgroup_english_name,
      mahallgroup_malayalam_name: values.mahallgroup_malayalam_name,
    }
    dispatch(createGroup(createGroupData, history))
  }

  return (
    <>
      <MetaTags>
        <title>Groups | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഗ്രൂപ്പുകൾ" : "Groups"}
          breadcrumbItem={
            language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create Group"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Fill this form</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <Label>
                            {language === "mal"
                              ? "ഗ്രൂപ്പ് പേര് (ഇംഗ്ലീഷ്)"
                              : "Group Name (English)"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="mahallgroup_english_name"
                            type="text"
                            // label="Name (English)"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col className="mb-3">
                          <AvField
                            name="mahallgroup_malayalam_name"
                            type="text"
                            label={
                              language === "mal"
                                ? "ഗ്രൂപ്പ് പേര് (മലയാളം)"
                                : "Group Name (Malayalam)"
                            }
                          />
                        </Col>
                      </div>

                      <div className="d-flex justify-content-end">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Submit
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateGroup

CreateGroup.propTypes = {
  history: PropTypes.object,
}
