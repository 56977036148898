import React from "react"
import { Row, Col, Card, Badge } from "reactstrap"
import { useSelector } from "react-redux"
import moment from "moment"

const WelcomeComp = () => {
  const { userDetail, language, instituteMahallDetail } = useSelector(
    state => ({
      userDetail: state.Users.userDetail,
      language: state.Layout.language,
      instituteMahallDetail: state.Institutes.instituteMahallDetail,
    })
  )

  return (
    <>
      <Card className="main-card">
        <Row>
          <Col md="6" style={{ display: "flex", alignItems: "center" }}>
            <div className="institute-name-div">
              <Badge className="badge-institute-type">
                {instituteMahallDetail?.institute_type}
              </Badge>
              <h2 className="text-khaf institute-name">
                <b>
                  {language === "mal"
                    ? instituteMahallDetail?.institute_malayalam_name
                      ? instituteMahallDetail?.institute_malayalam_name
                      : instituteMahallDetail?.institute_english_name
                    : instituteMahallDetail?.institute_english_name}
                </b>
              </h2>
            </div>
          </Col>
          <Col md="6" className="institute-detail-div">
            <div className="p-3">
              <p className="m-0 text-dark pb-2" style={{ fontWeight: "500" }}>
                {language === "mal" ? "" : "Since "}{" "}
                {moment(instituteMahallDetail?.established_date).format(
                  "DD/MM/yyyy"
                )}{" "}
                {language === "mal" ? " മുതൽ" : ""}
              </p>
              <p
                className="m-0 text-khaf-blue pb-2"
                style={{ fontSize: "15px", fontWeight: "500" }}
              >
                {instituteMahallDetail?.institute_place}
              </p>
              <p
                className="text-dark m-0"
                style={{ fontSize: "14px", fontWeight: "500" }}
              >
                <span>
                  {language === "mal" ? "വരിസംഖ്യ :" : "Varisangya Amount :"}
                </span>{" "}
                <span className="text-khaf-blue" style={{ fontWeight: "bold" }}>
                  {userDetail?.mahall?.default_varisankya_amount}
                </span>
              </p>
              {/* <div className="d-flex justify-content-end">
                <div
                  className="m-2"
                  style={{
                    backgroundColor: 'white',
                    color: '#009846',
                    fontWeight: '700',
                    borderRadius: '6px',
                  }}
                >
                  <p className="address-p">
                    {instituteMahallDetail?.institute_type}
                  </p>
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  )
}
export default WelcomeComp
