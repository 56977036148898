import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"

//actions
import {
  createLsg,
  getDistrictDropdown,
  getDefaultTypeLists,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateLsg = ({ history }) => {
  const dispatch = useDispatch()

  const {
    loading,
    // error,
    // Districts,
    searchDistricts,
    // defaultTypeLists,
    language,
  } = useSelector(state => ({
    loading: state.Lsgs.loading,
    error: state.Lsgs.error,
    Districts: state.Districts.Districts,
    searchDistricts: state.Districts.searchDistricts,
    defaultTypeLists: state.TypeLists.defaultTypeLists,
    language: state.Layout.language,
  }))

  const [district, setDistrict] = useState("Search District")
  const [districtId, setDistrictId] = useState("")
  const [searchText, setSearchText] = useState("")

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const createLsgData = {
      lsg_english_name: values.lsg_english_name,
      lsg_malayalam_name: values.lsg_malayalam_name,
      lsg_type_english: lsgTypes[values.lsg_type]?.english,
      lsg_type_malayalam: lsgTypes[values.lsg_type]?.malayalam,
      district: districtId,
    }
    console.log("createLsgData: ", createLsgData)
    dispatch(createLsg(createLsgData, history))
  }

  useEffect(() => {
    dispatch(getDistrictDropdown(searchText))
  }, [searchText, dispatch])

  useEffect(() => {
    dispatch(getDefaultTypeLists())
  }, [dispatch])

  function handlerFinalValue(event) {
    dispatch(getDistrictDropdown(event.value))
    setDistrictId(event.value)
    setDistrict(event.label)
  }

  const optionGroup = [
    {
      options: searchDistricts?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results.district_malayalam_name
              ? results.district_malayalam_name
              : results.district_english_name
            : results.district_english_name,
        value: results.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const lsgTypes = [
    { english: "Grama Panchayat", malayalam: "ഗ്രാമ പഞ്ചായത്ത്" },
    { english: "Municipality", malayalam: "മുനിസിപ്പാലിറ്റി" },
    { english: "Corporation", malayalam: "കോർപ്പറേഷൻ" },
    { english: "Others", malayalam: "മറ്റുള്ളവ" },
  ]

  // const mailError = error[0]

  return (
    <>
      <MetaTags>
        <title>LSGs | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="LSGs" breadcrumbItem="Create" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Fill this form</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            name="lsg_english_name"
                            type="text"
                            title="lsg english name"
                            label="LSG Name (English)"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            name="lsg_malayalam_name"
                            type="text"
                            title="lsg malayalam name"
                            label="LSG Name (മലയാളം)"
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <div className="row">
                            <AvField
                              type="select"
                              name="lsg_type"
                              label="LSGType"
                            >
                              <option>Choose a LSG Type ...</option>
                              {lsgTypes.map((item, key) => (
                                <option key={key} value={key}>
                                  {item.english}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>
                        <Col>
                          <Label> District </Label>
                          <Select
                            onInputChange={handleEnters}
                            placeholder={district}
                            value={district}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="District"
                            required="required"
                          />
                        </Col>
                      </div>

                      <div className="d-flex justify-content-end">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            )}
                            Submit
                          </Button>
                        </div>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateLsg

CreateLsg.propTypes = {
  history: PropTypes.object,
}
