import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_SUBJECTS,
  GET_SUBJECT_DETAIL,
  CREATE_SUBJECT,
  UPDATE_SUBJECT,
  DELETE_SUBJECT,
  SUBJECTS_DROPDOWN,
} from "./actionTypes"
import {
  getSubjectsSuccess,
  getSubjectsFail,
  getSubjectDetailSuccess,
  getSubjectDetailFail,
  createSubjectSuccess,
  createSubjectFail,
  updateSubjectSuccess,
  updateSubjectFail,
  deleteSubjectSuccess,
  deleteSubjectFail,
  subjectsDropdownSuccess,
  subjectsDropdownFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getSubjectsAPi = ({ divisionId, searchText, page }) => {
  if (divisionId) {
    return get(
      `/api/v1/madrasa/madrasa-subject/?division=${
        divisionId ? divisionId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/madrasa/madrasa-subject/?search=${
        searchText ? searchText : ""
      }&page=${page ? page : 1}`
    )
  }
}
const subjectsDropdownAPi = ({ searchText, divisionId }) => {
  return get(
    `/api/v1/madrasa/madrasa-subject-dropdown/?division=${
      divisionId ? divisionId : ""
    }&search=${searchText ? searchText : ""}`
  )
}
const getSubjectDetailsAPi = subjectId => {
  return get(`/api/v1/madrasa/madrasa-subject/${subjectId}/`)
}
const createSubjectApi = ({ newSubject }) => {
  return post("/api/v1/madrasa/madrasa-subject/", newSubject)
}
const updateSubjectApi = ({ subjectId, updatedSubject }) => {
  return ApiPut(`/api/v1/madrasa/madrasa-subject/${subjectId}/`, updatedSubject)
}
const deleteSubjectApi = ({ subjectId }) => {
  return del(`/api/v1/madrasa/madrasa-subject/${subjectId}/`)
}

function* fetchSubjects({ payload }) {
  try {
    const response = yield call(getSubjectsAPi, payload)
    if (response && !response?.error) {
      yield put(getSubjectsSuccess(response))
    } else {
      yield put(getSubjectsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getSubjectsFail(error))
  }
}

function* fetchSubjectsDropdown({ payload }) {
  try {
    const response = yield call(subjectsDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(subjectsDropdownSuccess(response))
    } else {
      yield put(subjectsDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(subjectsDropdownFail(error))
  }
}

function* fetchSubjectDetail({ subjectId }) {
  try {
    const response = yield call(getSubjectDetailsAPi, subjectId)
    if (response && !response?.error) {
      yield put(getSubjectDetailSuccess(response))
    } else {
      yield put(getSubjectDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getSubjectDetailFail(error))
  }
}
function* onCreateSubject({ payload }) {
  try {
    const response = yield call(createSubjectApi, payload)
    if (response && !response?.error) {
      yield put(createSubjectSuccess(response))
      if (payload.history) {
        payload.history.push(`/division/${response?.division}`)
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createSubjectFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createSubjectFail(error))
    errorNotification()
  }
}

function* onUpdateSubject({ payload }) {
  try {
    const response = yield call(updateSubjectApi, payload)
    if (response && !response?.error) {
      yield put(updateSubjectSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateSubjectFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateSubjectFail(error))
    errorNotification()
  }
}

function* onDeleteSubject({ payload }) {
  try {
    const response = yield call(deleteSubjectApi, payload)
    if (response && !response?.error) {
      yield put(deleteSubjectSuccess(payload?.subjectId))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteSubjectFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteSubjectFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* SubjectsSaga() {
  yield takeEvery(GET_SUBJECTS, fetchSubjects)
  yield takeEvery(SUBJECTS_DROPDOWN, fetchSubjectsDropdown)
  yield takeEvery(GET_SUBJECT_DETAIL, fetchSubjectDetail)
  yield takeEvery(CREATE_SUBJECT, onCreateSubject)
  yield takeEvery(UPDATE_SUBJECT, onUpdateSubject)
  yield takeEvery(DELETE_SUBJECT, onDeleteSubject)
}

export default SubjectsSaga
