import {
  GET_FEEDS,
  UPDATE_FEED,
  CREATE_FEED,
  DELETE_FEED,
  GET_FEED_DETAIL,
  GET_FEEDS_SUCCESS,
  GET_FEEDS_FAIL,
  CREATE_FEED_SUCCESS,
  CREATE_FEED_FAIL,
  GET_FEED_DETAIL_SUCCESS,
  GET_FEED_DETAIL_FAIL,
  UPDATE_FEED_SUCCESS,
  UPDATE_FEED_FAIL,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  feeds: [],
  feedDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdFeed: {},
}

const Feeds = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEEDS:
    case UPDATE_FEED:
    case CREATE_FEED:
    case DELETE_FEED:
      return {
        ...state,
        loading: true,
      }
    case GET_FEED_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_FEEDS_SUCCESS:
      return {
        ...state,
        feeds: action.payload,
        loading: false,
      }
    case GET_FEEDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_FEED_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        createdFeed: action.payload,
        loading: false,
      }

    case CREATE_FEED_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FEED_DETAIL_SUCCESS:
      return {
        ...state,
        feedDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_FEED_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_FEED_SUCCESS:
      return {
        ...state,
        feedDetail: action.payload,
        loading: false,
      }

    case UPDATE_FEED_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_FEED_SUCCESS:
      return {
        ...state,
        // states: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // enquiries: state.enquiries.filter(
        //   feed => feed.id.tostring() !== action.payload.id.tostring()
        // ),
        // enquiries: action.payload,
        loading: false,
      }

    case DELETE_FEED_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Feeds
