import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom"

// Import Routes all
import {
  publicRoutes,
  commonRoute,
  mahallUserPremiumRoutes,
  mahallUserBasicRoutes,
  surveyUserRoutes,
  groupAdminRoutes,
  instituteUserRoutes,
  adminRoutes,
} from "./routes"
// Import all middleware
import Authmiddleware from "./routes/route"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

import { getUserDetail } from "store/actions"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"
// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/custom.scss"

const App = () => {
  const dispatch = useDispatch()

  const { userDetail, loading, language } = useSelector(state => ({
    userDetail: state.Users.userDetail,
    loading: state.Users.loading,
    language: state.Layout.language,
  }))

  const env = "dev" //'prod'
  const { token } = useGetToken()
  const { role } = useGetRole()

  useEffect(() => {
    if (token) {
      dispatch(getUserDetail())
    }
  }, [dispatch, token])

  function MyRoute() {
    let Routes = commonRoute
    switch (role) {
      case "admin":
        Routes = adminRoutes
        break
      case "mahall_user":
        Routes =
          env === "dev"
            ? mahallUserPremiumRoutes
            : userDetail?.mahall?.version === "basic"
            ? mahallUserBasicRoutes
            : mahallUserPremiumRoutes
        break
      case "group_admin":
        Routes = groupAdminRoutes
        break
      case "survey_user":
        Routes = surveyUserRoutes
        break
      case "institute_user":
        Routes = instituteUserRoutes
        break
      default:
        Routes = commonRoute
        break
    }
    return Routes
  }

  const Layout = VerticalLayout
  return (
    <div id="base app-component" className={language}>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}
          {token && !userDetail?.roles && loading ? (
            <Route path="*" component={LoadingComponent} />
          ) : (
            MyRoute()?.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))
          )}
          {!token && (
            <Route render={() => <Redirect to={{ pathname: "/login" }} />} />
          )}
          <Route render={() => <Redirect to={{ pathname: "/404" }} />} />,
        </Switch>
      </Router>
    </div>
  )
}

const LoadingComponent = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <i className="bx bx-loader bx-spin font-size-30 align-middle text-info me-2"></i>
    </div>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
