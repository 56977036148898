import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_INSTITUTE_USERS,
  GET_INSTITUTE_USER_DETAIL,
  CREATE_INSTITUTE_USER,
  UPDATE_INSTITUTE_USER,
  DELETE_INSTITUTE_USER,
} from "./actionTypes"
import {
  getInstituteUsersSuccess,
  getInstituteUsersFail,
  getInstituteUserDetailSuccess,
  getInstituteUserDetailFail,
  createInstituteUserSuccess,
  createInstituteUserFail,
  updateInstituteUserSuccess,
  updateInstituteUserFail,
  deleteInstituteUserSuccess,
  deleteInstituteUserFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getInstituteUsersAPi = ({ searchText, page, is_madrasa }) => {
  if (is_madrasa) {
    return get(
      `/api/v1/institute/institute_user/?search=${
        searchText && searchText
      }&page=${page ? page : 1}&institute_type=${is_madrasa ? is_madrasa : ""}`
    )
  } else {
    return get(
      `/api/v1/institute/institute_user/?page=${page ? page : 1}&search=${
        searchText && searchText
      }`
    )
  }
}
const getInstituteUserDetailsAPi = instituteUserId => {
  return get(`/api/v1/institute/institute_user/${instituteUserId}/`)
}
const createInstituteUserApi = instituteUser => {
  return post("/api/v1/institute/institute_user/", instituteUser)
}
const updateInstituteUserApi = ({ instituteUserId, instituteUser }) => {
  return ApiPut(
    `/api/v1/institute/institute_user/${instituteUserId}/`,
    instituteUser
  )
}
const deleteInstituteUserApi = ({ instituteUserId }) => {
  return del(`/api/v1/institute/institute_user/${instituteUserId}/`)
}

function* fetchInstituteUsers({ payload }) {
  try {
    const response = yield call(getInstituteUsersAPi, payload)
    if (response && !response?.error) {
      yield put(getInstituteUsersSuccess(response))
    } else {
      yield put(getInstituteUsersFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstituteUsersFail(error))
    errorNotification(error)
  }
}

function* fetchInstituteUserDetail({ instituteUserId }) {
  try {
    const response = yield call(getInstituteUserDetailsAPi, instituteUserId)
    if (response && !response?.error) {
      yield put(getInstituteUserDetailSuccess(response))
    } else {
      yield put(getInstituteUserDetailFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstituteUserDetailFail(error))
  }
}
function* onCreateInstituteUser({ payload: { instituteUser, history } }) {
  try {
    const response = yield call(createInstituteUserApi, instituteUser)
    if (response && !response?.error) {
      yield put(createInstituteUserSuccess(response))
      history.push("/instituteusers")
      doneNotification("Created Successfully")
    } else {
      yield put(createInstituteUserFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createInstituteUserFail(error))
    errorNotification()
  }
}

function* onUpdateInstituteUser({ payload }) {
  try {
    const response = yield call(updateInstituteUserApi, payload)
    if (response && !response?.error) {
      yield put(updateInstituteUserSuccess(response))
      payload.history.push("/instituteusers")
      doneNotification("Updated Successfully")
    } else {
      yield put(updateInstituteUserFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateInstituteUserFail(error))
    errorNotification()
  }
}

function* onDeleteInstituteUser({ payload }) {
  try {
    const response = yield call(deleteInstituteUserApi, payload)
    if (response && !response?.error) {
      payload.history.push("/instituteusers")
      yield put(deleteInstituteUserSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteInstituteUserFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteInstituteUserFail(error))
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* InstituteUsersSaga() {
  yield takeEvery(GET_INSTITUTE_USERS, fetchInstituteUsers)
  yield takeEvery(GET_INSTITUTE_USER_DETAIL, fetchInstituteUserDetail)
  yield takeEvery(CREATE_INSTITUTE_USER, onCreateInstituteUser)
  // yield takeEvery(CREATE_USER, onCreateUser)
  yield takeEvery(UPDATE_INSTITUTE_USER, onUpdateInstituteUser)
  yield takeEvery(DELETE_INSTITUTE_USER, onDeleteInstituteUser)
}

export default InstituteUsersSaga
