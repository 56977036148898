import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import { getEnquiryDetail, updateEnquiry } from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"

const UpdateEnquiry = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { enquiryDetail } = useSelector(state => ({
    enquiryDetail: state.Enquiries.enquiryDetail,
  }))

  function handleValidSubmit(values) {
    const updateEnquiryData = {
      email: values.email,
      full_name: values.full_name,
      phone: values.phone,
      mahall_name: values.mahall_name,
      mahall_place: values.mahall_place,
      mahall_register_number: values.mahall_register_number,
      note: values.note,
      status: values.status_value,
    }
    dispatch(updateEnquiry(updateEnquiryData, enquiryDetail.id, history))
    window.scroll(0, 0)
  }

  useEffect(() => {
    dispatch(getEnquiryDetail(params.id))
  }, [dispatch, params.id])

  const statusTypes = ["new", "pending", "checked", "confirmed", "closed"]

  return (
    <>
      <MetaTags>
        <title>Enquiry | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Enquiries" breadcrumbItem="Update" />
        <Container fluid>
          <div className="container-fluid mt-5">
            <Row>
              <Col sm="12" md="12" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Update Enquiry</CardTitle>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="full_name"
                            label="Full Name"
                            type="text"
                            value={enquiryDetail?.full_name}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter full name",
                              },
                            }}
                          />
                        </Col>
                        <Col>
                          <Label>
                            Phone Number
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="phone"
                            // label="Phone Number"
                            type="number"
                            value={enquiryDetail?.phone}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="email"
                            label="Email"
                            type="email"
                            value={enquiryDetail?.email}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="mahall_name"
                            label="Mahall Name"
                            type="text"
                            value={enquiryDetail?.mahall_name}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="mahall_place"
                            label="Place"
                            type="text"
                            value={enquiryDetail?.mahall_place}
                          />
                        </Col>
                        <Col>
                          <AvField
                            name="mahall_register_number"
                            label="Register No."
                            type="text"
                            value={enquiryDetail?.mahall_register_number}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="note"
                            label="Note"
                            type="textarea"
                            value={enquiryDetail?.note}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="status_value"
                            label="Status"
                            type="select"
                            value={enquiryDetail?.status}
                          >
                            <option>Choose a status ...</option>
                            {statusTypes.map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        </Col>
                      </div>

                      <div className="row justify-content-end">
                        <Col>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Update
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateEnquiry

UpdateEnquiry.propTypes = {
  history: PropTypes.object,
}
