import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"

import { getCommittees } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"

const AllCommittees = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { committees, loading, language } = useSelector(state => ({
    committees: state.Committees.committees,
    loading: state.Committees.loading,
    language: state.Layout.language,
  }))

  //pages
  const totalPages = Math.ceil(committees?.count / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getCommittees("", page, searchText))
  }, [dispatch, page, searchText])

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "സീരിയൽ നമ്പർ" : "Serial No."}`,
      sort: true,
    },
    {
      dataField: "last_meeting_date",
      text: `${language === "mal" ? "അവസാന യോഗം" : "Last Meeting"}`,
    },
    {
      dataField: "title",
      text: `${language === "mal" ? "കമ്മിറ്റിയുടെ പേര്" : "Committee Name"}`,
      sort: true,
    },
    {
      dataField: "members_count",
      text: `${language === "mal" ? "അംഗങ്ങൾ" : "Members"}`,
    },
    {
      dataField: "meetings_count",
      text: `${language === "mal" ? "യോഗങ്ങൾ" : "Meetings"}`,
    },
    // {
    //   dataField: "constituency_name",
    //   text: "Constituency",
    // },
    // {
    //   dataField: "district_english_name",
    //   text: "District",
    // },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const committeesData = map(committees?.results, (item, index) => ({
    ...item,
    key: index,
    no: index + 1,
    last_meeting_date:
      item?.last_meeting_date &&
      moment(item?.last_meeting_date).format("DD/MM/YYYY"),
    action: (
      <div>
        <Link to={`/committees/${item?.id}`} className="btn-success btn-sm">
          {language === "mal" ? "കാണുക" : "View"}
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  return (
    <React.Fragment>
      {/* <Row>
        <div className="col-md-6 mb-3">
          <Link
            to={`/institute/create`}
            type="submit"
            style={{ width: language === "mal" ? "200px" : "150px" }}
            className="w-md btn btn-success d-block"
          >
            {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New Institute"}
          </Link>
        </div>
      </Row> */}
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={committeesData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllCommittees
