import React from "react"
import { MetaTags } from "react-meta-tags"

import Details from "./Details"

function SettingsIndex() {
  return (
    <>
      <MetaTags>
        <title>Preferences | Khaf </title>
      </MetaTags>
      <Details />
    </>
  )
}

export default SettingsIndex
