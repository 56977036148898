import React, { useEffect, useState, Fragment } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Badge,
  Button,
  Label,
  Input,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
import Select from "react-select"
// import { AvField, AvForm } from 'availity-reactstrap-validation'
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"

import UiModalCollectionMember from "./UiModalCollectionMember"
import UiModalMembersDownload from "./UiModalMembersDownload"

//actions
import {
  getFamilyMembersCards,
  getMahallTypeLists,
  getFilteredFamilyMembers,
  getFamilyMembersForDownload,
  getCountries,
  getMahallWardNumbers,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import { useGetSession, useSetSession } from "hooks/useHandleSessions"
import { IoCloseOutline } from "react-icons/io5"
import useLocalStorage from "hooks/useLocalStorage"

const AllMahallFamilyMembers = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  // const [limit, setLimit] = useState(10)
  const limit = 10
  const [isFilterListOpen, setIsFilterListOpen] = useState(false)
  const [modalDownload, setModalDownload] = useState(false)
  const {
    familyMembers,
    loading,
    familyMembersCards,
    language,
    userDetail,
    mahallTypeLists,
    // mahallId,
    familyMembersforDownload,
    familyMembersforDownloadLoading,
    allCountries,
    mahallWardNumbers,
  } = useSelector(state => ({
    familyMembers: state.FamilyMembers.familyMembers,
    loading: state.FamilyMembers.loading,
    familyMembersforDownload: state.FamilyMembers.familyMembersforDownload,
    familyMembersforDownloadLoading:
      state.FamilyMembers.familyMembersforDownloadLoading,
    familyMembersCards: state.FamilyMembers.familyMembersCards,
    language: state.Layout.language,
    userDetail: state.Users.userDetail,
    mahallTypeLists: state.TypeLists.mahallTypeLists,
    mahallId: state.Users.userDetail?.mahall?.id,
    allCountries: state.Countries.countries?.results,
    mahallWardNumbers: state.TypeLists.mahallWardNumbers.ward_numbers,
  }))

  const pageSize_value = useGetSession("pageSize_value")

  const [pageSize, setPageSize] = useState(pageSize_value || 10)
  const [filterListArea, setFilterListArea] = useState([])

  const [dataArray, setDataArray] = useLocalStorage("familyMemberFilter", [])

  // const [searchTextCountries, setSearchTextCountries] = useState('')
  // console.log(familyMembersforDownload);

  //pages
  const totalPages = Math.ceil(familyMembers?.count / pageSize)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const handlePremiumLink = () => {
    if (["premium", "standard"].includes(userDetail?.mahall?.version)) {
      return "!#"
    } else {
      return "/premium"
    }
  }

  const sort_value_member = useGetSession("sort_value_member")

  const [sort, setSort] = useState(
    sort_value_member ? sort_value_member : "-custom_id"
  )

  const sortData = [
    {
      heading: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
      id: "mahall_custom_id",
    },
    {
      heading: `${language === "mal" ? "തിയതി" : "Date"}`,
      id: "-custom_id",
    },
  ]

  const filterData = {
    filter: filterListArea,
    sort: [sort],
    search: searchText,
  }

  useEffect(() => {
    if (
      userDetail &&
      ["standard", "premium"].includes(userDetail?.mahall?.version) &&
      modalDownload
    ) {
      dispatch(getFamilyMembersForDownload(filterData))
    }
  }, [dispatch, sort, searchText, filterListArea, modalDownload])

  useEffect(() => {
    dispatch(getFilteredFamilyMembers(pageSend(), pageSize, filterData))
  }, [dispatch, page, sort, pageSize, searchText, filterListArea])

  useEffect(() => {
    dispatch(getFamilyMembersCards())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCountries())
  }, [dispatch])

  useEffect(() => {
    if (userDetail?.mahall?.id) {
      dispatch(getMahallTypeLists(userDetail?.mahall?.id))
    }
  }, [dispatch, userDetail])

  useEffect(() => {
    if (userDetail?.mahall?.id) {
      if (!mahallWardNumbers || mahallWardNumbers.length < 0) {
        dispatch(getMahallWardNumbers(userDetail?.mahall?.id))
      }
    }
  }, [dispatch, userDetail])

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("diff:", diff / (365 * 24 * 60 * 60 * 1000))
      // console.log("age: ", age)
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  function getDateFromAge(age) {
    var today = new Date()
    var birthDate = moment(today)
    birthDate = birthDate.subtract(age, "years")
    birthDate = birthDate.format("YYYY-MM-DD")
    return birthDate
  }

  const cardData = [
    {
      title: `${
        language === "mal" ? "ആകെ കുടുംബാംഗങ്ങൾ" : "Total Family Members"
      }`,
      cardValue: familyMembersCards?.total_family_members ?? 0,
      iconClass: "bx bxs-user",
      routeLink: "/",
      loading: loading,
      alt: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ ഇല്ല" : "No Family Members"}`,
    },
    {
      title: `${language === "mal" ? "ആകെ പുരുഷന്മാർ" : "Total Males"}`,
      cardValue: familyMembersCards?.total_males ?? 0,
      iconClass: "bx bx-male",
      routeLink: "/",
      loading: loading,
      alt: `${language === "mal" ? "പുരുഷന്മാർ ഇല്ല" : "No Males"}`,
    },
    {
      title: `${language === "mal" ? "ആകെ സ്ത്രീകൾ" : "Total Females"}`,
      cardValue: familyMembersCards?.total_females ?? 0,
      iconClass: "bx bx-female",
      routeLink: "/",
      loading: loading,
      alt: `${language === "mal" ? "സ്ത്രീകളില്ല" : "No Females"}`,
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
    },
    // {
    //   dataField: "custom_id",
    //   text: `${language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID"}`,
    // },
    {
      dataField: "mahall_custom_id",
      text: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
    },
    {
      dataField: "name",
      text: `${language === "mal" ? "പേര്" : "Name"}`,
      sort: true,
    },
    {
      dataField: "family_name",
      text: `${language === "mal" ? "വീട്ടുപേര്" : "Family Name"}`,
    },
    {
      dataField: "ageGender",
      text: `${language === "mal" ? "പ്രായം / ലിംഗം" : "Age / Gender"}`,
    },
    {
      dataField: "blood_group.english",
      text: `${language === "mal" ? "രക്ത ഗ്രൂപ്പ്" : "Blood Group"}`,
    },
    {
      dataField: "healthStatus",
      text: `${language === "mal" ? "ആരോഗ്യ സ്ഥിതി" : "Health Status"}`,
    },
    {
      dataField: "phone",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]

  const familyMembersData = map(familyMembers?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * pageSize + index + 1,
    name: (
      <>
        {/* <p>{`${item?.full_name} (${
          item?.is_alive ? 'Alive' : 'Not Alive'
        })`}</p> */}
        <p>
          {item?.full_name}
          <span className="text-primary px-1">
            {item?.is_family_head && "(H)"}
          </span>
          <span className="text-danger">{` ${
            !item?.is_alive
              ? language === "mal"
                ? "(നിര്യാതനായി)"
                : "(Late)"
              : ""
          }`}</span>
        </p>
      </>
    ),
    family_name: (
      <p>
        {" "}
        {item?.family_name}{" "}
        {item?.family_head_name && (
          <span className="text-primary px-1">({item?.family_head_name})</span>
        )}
      </p>
    ),
    ageGender: `${getAge(item?.dob)} / ${
      item?.gender && item?.gender != null ? item?.gender : ""
    }`,
    healthStatus: (
      <>
        {item?.health_condition?.map((status, key) => {
          return (
            <Fragment key={key}>
              <Badge
                className={
                  "mx-1 font-size-12 badge-soft-" +
                  `${
                    status.english == "Patient"
                      ? "danger"
                      : status.english == "Satisfied"
                      ? "success"
                      : status.english == "Disabled"
                      ? "warning"
                      : ""
                  }`
                }
                color="black"
                pill
              >
                {language === "mal"
                  ? status.malayalam
                    ? status.malayalam
                    : status.english
                  : status.english}
              </Badge>
            </Fragment>
          )
        })}
      </>
    ),

    action: (
      <Link
        target="_blank"
        to={`/familymembers/${item?.id}`}
        className=" btn-success w-xs btn-sm"
      >
        {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
        {language === "mal" ? "കാണുക" : "View"}
      </Link>
    ),
  }))

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    {
      label: `${language === "mal" ? "സിസ്റ്റം ഐഡി" : "System ID"}`,
      key: "custom_id",
    },
    {
      label: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
      key: "mahall_custom_id",
    },
    { label: `${language === "mal" ? "പേര്" : "Name"}`, key: "full_name" },
    {
      label: `${language === "mal" ? "വീട്ടുപേര്" : "Family Name"}`,
      key: "family_name",
    },
    {
      label: `${language === "mal" ? "ഏരിയ" : "Area"}`,
      key: "family__area_choice_id",
    },
    {
      label: `${language === "mal" ? "വീട് നമ്പർ" : "House No."}`,
      key: "family__house_number",
    },
    {
      label: `${language === "mal" ? "വാർഡ് നമ്പർ" : "Ward No."}`,
      key: "family__ward_number",
    },
    {
      label: `${language === "mal" ? "വീട്" : "House Type"}`,
      key: "family__house_type",
    },
    {
      label: `${language === "mal" ? "വീടിൻ്റെ സ്ഥിതി" : "Build Type"}`,
      key: "family__house_build_type",
    },
    { label: `${language === "mal" ? "ലിംഗം" : "Gender"}`, key: "gender" },
    { label: `${language === "mal" ? "പ്രായം" : "Age"}`, key: "age" },
    { label: `${language === "mal" ? "ഫോൺ" : "Phone"}`, key: "phone" },
    { label: `${language === "mal" ? "ഇ-മെയിൽ " : "Email"}`, key: "email" },
    {
      label: `${language === "mal" ? "ഐഡി കാർഡ് " : "Id Card"}`,
      key: "id_card",
    },
    {
      label: `${language === "mal" ? "ഐഡി നമ്പർ " : "ID Number"}`,
      key: "id_card_number",
    },
    {
      label: language === "mal" ? "വരിസംഗ്യ ഗ്രേഡ്" : "Varisangya Grade",
      key: "family_member_varisankya_grade",
    },
    {
      label: language === "mal" ? "രാജ്യ കോഡ്" : "Alt Country Code",
      key: "alt_country_code",
    },
    {
      label: language === "mal" ? "ഇതര ഫോൺ നമ്പർ" : "Alt Phone Number",
      key: "alt_phone_number",
    },
    {
      label:
        language === "mal" ? "Whatsapp രാജ്യ കോഡ്" : "Whatsapp Country Code",
      key: "whatsapp_country_code",
    },
    {
      label: language === "mal" ? "Whatsapp നമ്പർ" : "Whatsapp Number",
      key: "whatsapp_number",
    },
    {
      label: `${language === "mal" ? "പിതാവ് " : "Father"}`,
      key: "father_name",
    },
    {
      label: `${language === "mal" ? "മാതാവ്" : "Mother"}`,
      key: "mother_name",
    },
    {
      label: `${language === "mal" ? "വൈവാഹിക നില " : "Marital Status"}`,
      key: "marital_status",
    },
    {
      label: `${language === "mal" ? "ഇണയുടെ പേര് " : "Spouse"}`,
      key: "spouse_name",
    },
    {
      label: `${language === "mal" ? "അവിവാഹിത/ൻ കാരണം " : "Unmarried Reason"}`,
      key: "unmarried_reason",
    },
    {
      label: `${language === "mal" ? "വിദേശത്താണോ?" : "Is Abroad?"}`,
      key: "is_abroad",
    },
    {
      label: `${language === "mal" ? "രാജ്യം" : "Country"}`,
      key: "present_country",
    },
    {
      label: `${language === "mal" ? "അഡ്രസ്" : "Address"}`,
      key: "present_address",
    },
    {
      label: `${language === "mal" ? "രക്ത ഗ്രൂപ്പ്" : "Blood Group"}`,
      key: "blood_group",
    },
    {
      label: `${
        language === "mal" ? "രക്തദാനം ചെയ്യാൻ തയ്യാറാണോ?" : "Ready To Donate?"
      }`,
      key: "ready_to_donate",
    },
    {
      label: `${language === "mal" ? "ആരോഗ്യ സ്ഥിതി" : "Health Status"}`,
      key: "healthStatus",
    },
    {
      label: `${language === "mal" ? "രോഗത്തിന്റെ തരം" : "Disease Type"}`,
      key: "disease_type",
    },
    {
      label: `${language === "mal" ? "മറ്റ് രോഗ തരം" : "Other Disease Type"}`,
      key: "other_disease_type",
    },
    {
      label: `${language === "mal" ? "രോഗ നില" : "Disease Level"}`,
      key: "disease_level",
    },
    {
      label: `${language === "mal" ? "വൈകല്യ തരം" : "Disability Type"}`,
      key: "disability_type",
    },
    {
      label: `${
        language === "mal" ? "മറ്റ് വൈകല്യ തരം" : "Other Disability Type"
      }`,
      key: "other_disability_type",
    },
    {
      label: `${
        language === "mal" ? "സാമൂഹിക പ്രവർത്തനങ്ങൾ" : "Social Activities"
      }`,
      key: "social_activity",
    },
    {
      label: `${
        language === "mal" ? "പ്രവർത്തന വിശദാംശങ്ങൾ" : "Activity Details"
      }`,
      key: "social_activity_details",
    },
    {
      label: `${language === "mal" ? "മോശം ശീലങ്ങൾ " : "Bad Habits"}`,
      key: "bad_habits",
    },
    {
      label: `${
        language === "mal" ? "ഭൗതിക വിദ്യാഭ്യാസം" : "Formal Education"
      }`,
      key: "educational_qualification_formal",
    },
    {
      label: `${language === "mal" ? "വിശദാംശങ്ങൾ" : "Details"}`,
      key: "educational_qualification_formal_details",
    },
    {
      label: `${language === "mal" ? "മത വിദ്യാഭ്യാസം" : "Islamic Education"}`,
      key: "educational_qualification_islamic",
    },
    {
      label: `${language === "mal" ? "വിശദാംശങ്ങൾ" : "Details"}`,
      key: "educational_qualification_islamic_details",
    },
    {
      label: `${language === "mal" ? "വിദ്യാർത്ഥിയാണോ?" : "Is Student?"}`,
      key: "is_student",
    },
    {
      label: `${
        language === "mal" ? "പഠിക്കാത്തതിന്റെ കാരണം" : "Not Studying Reason"
      }`,
      key: "not_studying_reason",
    },
    {
      label: `${language === "mal" ? "ജോലി ചെയ്യുന്നുണ്ടോ?" : "Is Working?"}`,
      key: "is_working",
    },
    {
      label: `${
        language === "mal" ? "ജോലി ചെയ്യാത്തതിന്റെ കാരണം" : "Not Working Reason"
      }`,
      key: "not_working_reason",
    },
    {
      label: `${language === "mal" ? "അനാഥനാണോ?" : "Is Orphan?"}`,
      key: "is_orphan",
    },
    { label: "Listed Date", key: "date_added" },
  ]

  const familyMembersExcelData = map(
    familyMembersforDownload?.results,
    (item, index) => ({
      ...item,
      no: (pageSend() - 1) * limit + index + 1,
      custom_id: item.custom_id,
      mahall_custom_id: item.mahall_custom_id,
      full_name: item.full_name,
      family_name: item.family_name,
      family__area_choice_id: item.family__area_choice_id,
      family__house_number: item.family__house_number,
      family__ward_number: item.family__ward_number,
      family__house_type: item?.family__house_type,
      family__house_build_type: item?.family__house_build_type,
      blood_group: item.blood_group?.english,
      family_member_varisankya_grade: item.family_member_varisankya_grade,
      alt_country_code: item.alt_country_code,
      alt_phone_number: item.alt_phone_number,
      whatsapp_country_code: item.whatsapp_country_code,
      whatsapp_number: item.whatsapp_number,
      gender:
        language === "mal"
          ? item.gender === "male"
            ? "പുരുഷൻ"
            : item.gender === "female"
            ? "സ്ത്രീ"
            : "മറ്റുള്ളവ"
          : item.gender === "male"
          ? "Male"
          : item.gender === "female"
          ? "Female"
          : "Others",
      age: `${getAge(item?.dob)}`,
      healthStatus: `${item?.health_condition?.map(status => {
        return `${
          language === "mal"
            ? status.malayalam
              ? status.malayalam
              : status.english
            : status.english
        } `
      })}`,
      phone: item.phone,
      email: item.email,
      father_name: item.father_name,
      mother_name: item.mother_name,
      marital_status:
        language === "mal"
          ? item?.marital_status?.malayalam
            ? item?.marital_status?.malayalam
            : item?.marital_status?.english
          : item?.marital_status?.english,
      spouse_name: item.spouse_name,
      unmarried_reason:
        language === "mal"
          ? item?.unmarried_reason?.malayalam
            ? item?.unmarried_reason?.malayalam
            : item?.unmarried_reason?.english
          : item?.unmarried_reason?.english,
      ready_to_donate:
        item?.ready_to_donate === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      id_card:
        language === "mal"
          ? item?.id_card?.malayalam
            ? item?.id_card?.malayalam
            : item?.id_card?.english
          : item?.id_card?.english,
      id_card_number: item.id_card_number,
      is_abroad:
        item?.is_abroad === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      present_country:
        language === "mal"
          ? item?.present_country_malayalam
          : item?.present_country_english,
      present_address: item.present_address,
      disease_type: `${item?.disease_type?.map(status => {
        return `${
          language === "mal"
            ? status.malayalam
              ? status.malayalam
              : status.english
            : status.english
        } `
      })}`,
      other_disease_type: item.other_disease_type,
      disease_level:
        language === "mal"
          ? item?.disease_level?.malayalam
            ? item?.disease_level?.malayalam
            : item?.disease_level?.english
          : item?.disease_level?.english,
      disability_type: `${item?.disability_type?.map(status => {
        return `${
          language === "mal"
            ? status.malayalam
              ? status.malayalam
              : status.english
            : status.english
        } `
      })}`,
      social_activity: `${item?.social_activity?.map(status => {
        return `${
          language === "mal"
            ? status.malayalam
              ? status.malayalam
              : status.english
            : status.english
        } `
      })}`,
      other_disability_type: item.other_disability_type,
      social_activity_details: item.social_activity_details,
      bad_habits: `${item?.bad_habits?.map(status => {
        return `${
          language === "mal"
            ? status.malayalam
              ? status.malayalam
              : status.english
            : status.english
        } `
      })}`,
      educational_qualification_formal:
        language === "mal"
          ? item?.educational_qualification_formal?.malayalam
            ? item?.educational_qualification_formal?.malayalam
            : item?.educational_qualification_formal?.english
          : item?.educational_qualification_formal?.english,
      educational_qualification_formal_details:
        item.educational_qualification_formal_details,
      educational_qualification_islamic:
        language === "mal"
          ? item?.educational_qualification_islamic?.malayalam
            ? item?.educational_qualification_islamic?.malayalam
            : item?.educational_qualification_islamic?.english
          : item?.educational_qualification_islamic?.english,
      educational_qualification_islamic_details:
        item.educational_qualification_islamic_details,
      is_student:
        item?.is_student === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      not_studying_reason:
        language === "mal"
          ? item?.not_studying_reason?.malayalam
            ? item?.not_studying_reason?.malayalam
            : item?.not_studying_reason?.english
          : item?.not_studying_reason?.english,
      is_working:
        item?.is_working === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      not_working_reason:
        language === "mal"
          ? item?.not_working_reason?.malayalam
            ? item?.not_working_reason?.malayalam
            : item?.not_working_reason?.english
          : item?.not_working_reason?.english,
      is_orphan:
        item?.is_orphan === true
          ? language === "mal"
            ? "ഉണ്ട്"
            : "Yes"
          : language === "mal"
          ? "ഇല്ല"
          : "No",
      date_added:
        item?.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMahallFamilyMemberSearch = debounce(
    value => setSearchText(value),
    600
  )

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  //multi-list
  // const [genders, setGenders] = useState([])
  // const [selectedMultiGender, setSelectedMultiGender] = useState(null)
  const [relations, setRelations] = useState([])
  const [selectedMultiRelation, setSelectedMultiRelation] = useState(null)
  const [idCardTypes, setIdCardTypes] = useState([])
  const [selectedMultiIdCardType, setSelectedMultiIdCardType] = useState(null)
  const [countries, setCountries] = useState([])
  const [selectedMultiCountry, setSelectedMultiCountry] = useState(null)
  const [maritalStatuses, setMaritalStatuses] = useState([])
  const [selectedMultiMaritalStatus, setSelectedMultiMaritalStatus] =
    useState(null)
  const [unmarriedReasons, setUnmarriedReasons] = useState([])
  const [selectedMultiUnmarriedReason, setSelectedMultiUnmarriedReason] =
    useState(null)
  // const [divorceReasons, setDivorceReasons] = useState([])
  // const [selectedMultiDivorceReason, setSelectedMultiDivorceReason] =
  //   useState(null)
  const [bloodGroups, setBloodGroups] = useState([])
  const [selectedMultiBloodGroup, setSelectedMultiBloodGroup] = useState(null)
  const [healthConditions, setHealthConditions] = useState([])
  const [selectedMultiHealthCondition, setSelectedMultiHealthCondition] =
    useState(null)
  const [diseaseTypes, setDiseaseTypes] = useState([])
  const [selectedMultiDiseaseType, setSelectedMultiDiseaseType] = useState(null)
  const [diseaseLevels, setDiseaseLevels] = useState([])
  const [selectedMultiDiseaseLevel, setSelectedMultiDiseaseLevel] =
    useState(null)
  const [disabilityTypes, setDisabilityTypes] = useState([])
  const [selectedMultiDisabilityType, setSelectedMultiDisabilityType] =
    useState(null)
  const [socialActivities, setSocialActivities] = useState([])
  const [selectedMultiSocialActivity, setSelectedMultiSocialActivity] =
    useState(null)
  const [badHabits, setBadHabits] = useState([])
  const [selectedMultiBadHabit, setSelectedMultiBadHabit] = useState(null)
  const [islamicEducations, setIslamicEducations] = useState([])
  const [selectedMultiIslamicEducation, setSelectedMultiIslamicEducation] =
    useState(null)
  const [formalEducations, setFormalEducations] = useState([])
  const [selectedMultiFormalEducation, setSelectedMultiFormalEducation] =
    useState(null)
  const [notStudyingReasons, setNotStudyingReasons] = useState([])
  const [selectedMultiNotStudyingReason, setSelectedMultiNotStudyingReason] =
    useState(null)
  const [notWorkingReasons, setNotWorkingReasons] = useState([])
  const [selectedMultiNotWorkingReason, setSelectedMultiNotWorkingReason] =
    useState(null)
  const [varisangyaGrades, setVarisangyaGrades] = useState([])
  const [selectedMultiVarisangyaGrade, setSelectedMultiVarisangyaGrade] =
    useState(null)
  const [areas, setAreas] = useState([])
  const [selectedMultiArea, setSelectedMultiArea] = useState(null)
  const [wardNumbers, setWardNumbers] = useState([])
  const [selectedMultiWardNumber, setSelectedMultiWardNumber] = useState(null)
  const [houseTypes, setHouseTypes] = useState([])
  const [selectedMultiHouseType, setSelectedMultiHouseType] = useState(null)
  const [houseBuildTypes, setHouseBuildTypes] = useState([])
  const [selectedMultiHouseBuildType, setSelectedMultiHouseBuildType] =
    useState(null)

  // const [ages] = useState([])
  // const [selectedMultiAge] = useState(null)

  // console.log(ages)
  // console.log(selectedMultiAge)
  //ward_number
  const optionGroupWardNumber = () => {
    const optionGroup = mahallWardNumbers?.map(item => {
      return {
        value: item,
        label: item,
      }
    })
    return optionGroup
  }

  function handleMultiWardNumber(e) {
    setSelectedMultiWardNumber(e)
    const arrangedWardNumbers = e.map(a => a.value)
    setWardNumbers(arrangedWardNumbers)
  }

  const optionGroupType = type => {
    const optionGroup = mahallTypeLists?.[`${type}`]?.map(item => {
      return {
        value: item.id,
        label: `${language === "mal" ? item?.malayalam : item?.english}`,
      }
    })
    return optionGroup
  }

  function handleMultiType(typeData, setSelectedMultiType, setType) {
    setSelectedMultiType(typeData)
    const arrangedTypes = typeData.map(a => a.value)
    setType(arrangedTypes)
  }

  const mahallTypeListFilterItem = (
    type,
    mal,
    en,
    selectedMultiType,
    setSelectedMultiType,
    setTypes,
    colSize
  ) => {
    return (
      mahallTypeLists?.[`${type}`] &&
      mahallTypeLists?.[`${type}`]?.length > 0 && (
        <Col md={colSize ? colSize : "3"} className="mb-3">
          <Label>{language === "mal" ? mal : en}</Label>
          <div className="mb-3">
            <Select
              value={selectedMultiType}
              isMulti={true}
              onChange={e => {
                handleMultiType(e, setSelectedMultiType, setTypes)
              }}
              options={optionGroupType(type)}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
      )
    )
  }

  const optionGroupCountry = () => {
    const optionGroup = allCountries?.map(item => {
      return {
        value: item.id,
        label: `${
          language === "mal"
            ? item?.country_malayalam_name
            : item?.country_english_name
        }`,
      }
    })
    return optionGroup
  }

  function handleMultiTypeCountry(
    typeData,
    setSelectedMultiCountry,
    setCountry
  ) {
    setSelectedMultiCountry(typeData)
    const arrangedTypes = typeData.map(a => a.value)
    setCountry(arrangedTypes)
  }

  const countryListFilterItem = (
    mal,
    en,
    selectedMultiCountry,
    setSelectedMultiCountry,
    setCountries,
    colSize
  ) => {
    return (
      allCountries &&
      allCountries?.length > 0 && (
        <Col md={colSize ? colSize : "3"} className="mb-3">
          <Label>{language === "mal" ? mal : en}</Label>
          <div className="mb-3">
            <Select
              value={selectedMultiCountry}
              isMulti={true}
              onChange={e => {
                handleMultiTypeCountry(e, setSelectedMultiCountry, setCountries)
              }}
              options={optionGroupCountry()}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
      )
    )
  }

  const setOfNumbers = count => {
    return Array.from({ length: count }, (v, i) => i)
  }

  // const optionGroupAge = () => {
  //   const optionGroup = setOfNumbers(200).map(item => {
  //     return {
  //       value: item,
  //       label: item,
  //     }
  //   })
  //   return optionGroup
  // }

  // function handleMultiAge(e) {
  //   setSelectedMultiAge(e)
  //   const arrangedAges = e.map(a => a.value)
  //   setAges(arrangedAges)
  // }

  const [modalCollection, setModalCollection] = useState(false)
  const [modalCollection1, setModalCollection1] = useState([])

  const handleFilterFind = values => {
    const filterList = []
    const filterItems = [
      {
        field: "relation_choice_id",
        value: relations,
      },
      {
        field: "id_card_choice_id",
        value: idCardTypes,
      },
      {
        field: "blood_group_choice_id",
        value: bloodGroups,
      },
      {
        field: "marital_status_choice_id",
        value: maritalStatuses,
      },
      {
        field: "unmarried_reason_choice_id",
        value: unmarriedReasons,
      },
      {
        field: "health_condition_choice_id_array",
        value: healthConditions,
      },
      {
        field: "disease_type_choice_id_array",
        value: diseaseTypes,
      },
      {
        field: "disease_level_choice_id",
        value: diseaseLevels,
      },
      {
        field: "disability_type_choice_id_array",
        value: disabilityTypes,
      },
      {
        field: "social_activity_type_id_array",
        value: socialActivities,
      },
      {
        field: "bad_habits_choice_id_array",
        value: badHabits,
      },
      {
        field: "educational_qualification_islamic_choice_id",
        value: islamicEducations,
      },
      {
        field: "educational_qualification_formal_choice_id",
        value: formalEducations,
      },
      {
        field: "not_studying_reason_choice_id ",
        value: notStudyingReasons,
      },
      {
        field: "not_working_reason_choice_id",
        value: notWorkingReasons,
      },
      {
        field: "present_country",
        value: countries,
      },
      {
        field: "family_member_varisankya_grade_choice_id",
        value: varisangyaGrades,
      },
      {
        field: "family__area_choice_id",
        value: areas,
      },
      {
        field: "family__ward_number",
        value: wardNumbers,
      },
      {
        field: "family__house_type_choice_id",
        value: houseTypes,
      },
      {
        field: "family__house_build_type_choice_id",
        value: houseBuildTypes,
      },
    ]

    for (const filterItem of filterItems) {
      if (filterItem.value.length > 0) {
        filterList.push(filterItem)
      }
    }

    if (values.gender && values.gender !== "none")
      filterList.push({ field: "gender", value: [values.gender] })
    if (
      values.is_abroad &&
      values.is_abroad !== "none" &&
      values.is_abroad !== null
    )
      filterList.push({
        field: "is_abroad",
        value: [
          values.is_abroad === "true"
            ? true
            : values.is_abroad === "false"
            ? false
            : null,
        ],
      })
    if (
      values.ready_to_donate &&
      values.ready_to_donate !== "none" &&
      values.ready_to_donate !== null
    )
      filterList.push({
        field: "ready_to_donate",
        value: [
          values.ready_to_donate === "true"
            ? true
            : values.ready_to_donate === "false"
            ? false
            : null,
        ],
      })
    if (
      values.is_student &&
      values.is_student !== "none" &&
      values.is_student !== null
    )
      filterList.push({
        field: "is_student",
        value: [
          values.is_student === "true"
            ? true
            : values.is_student === "false"
            ? false
            : null,
        ],
      })
    if (
      values.is_working &&
      values.is_working !== "none" &&
      values.is_working !== null
    )
      filterList.push({
        field: "is_working",
        value: [
          values.is_working === "true"
            ? true
            : values.is_working === "false"
            ? false
            : null,
        ],
      })
    if (
      values.is_orphan &&
      values.is_orphan !== "none" &&
      values.is_orphan !== null
    )
      filterList.push({
        field: "is_orphan",
        value: [
          values.is_orphan === "true"
            ? true
            : values.is_orphan === "false"
            ? false
            : null,
        ],
      })

    if (
      values.is_family_head &&
      values.is_family_head !== "none" &&
      values.is_family_head !== null
    )
      filterList.push({
        field: "is_family_head",
        value: [
          values.is_family_head === "true"
            ? true
            : values.is_family_head === "false"
            ? false
            : null,
        ],
      })

    if (
      values.is_alive &&
      values.is_alive !== "none" &&
      values.is_alive !== null
    )
      filterList.push({
        field: "is_alive",
        value: [
          values.is_alive === "true"
            ? true
            : values.is_alive === "false"
            ? false
            : null,
        ],
      })

    if (
      values.age_from &&
      values.age_to &&
      values.age_from !== "none" &&
      values.age_from !== null &&
      values.age_to !== "none" &&
      values.age_to !== null
    ) {
      filterList.push({
        field: "dob",
        value: {
          from: getDateFromAge(parseInt(values.age_to) + 1),
          to: getDateFromAge(parseInt(values.age_from)),
        },
      })
    }

    if (
      values.family__house_number &&
      values.family__house_number !== "none" &&
      values.family__house_number !== null
    )
      filterList.push({
        field: "family__house_number",
        value: [values.family__house_number || null],
      })

    setFilterListArea(filterList)
    setModalCollection1(filterList)
    setDataArray(filterList)
  }

  const handleFilterLabelText = labelText => {
    if (labelText?.includes("choice_id")) {
      const cutTheChoiceId = labelText?.split("choice_id")[0]
      const stringWithSpaces = cutTheChoiceId.replace(/_/g, " ")
      const capitalizedString = stringWithSpaces.replace(/\b\w/g, match =>
        match.toUpperCase()
      )
      return capitalizedString
    } else {
      return labelText
    }
  }

  const removeFilterItems = item => {
    console.log("data :", item)
    const modifiedFilter = filterListArea?.filter(i => i?.field !== item?.field)

    setFilterListArea(modifiedFilter)
    setDataArray(modifiedFilter)
  }

  return (
    <React.Fragment>
      <UiModalCollectionMember
        show={modalCollection}
        onCloseclick={() => setModalCollection(false)}
        history={history}
        data={modalCollection1}
      />
      <UiModalMembersDownload
        show={modalDownload}
        onCloseclick={() => setModalDownload(false)}
        excelData={familyMembersExcelData}
        headers={headers}
        list={familyMembersforDownload}
        loading={familyMembersforDownloadLoading}
      />
      <Row>
        {!isFilterListOpen &&
          cardData?.map((item, key) => (
            <Col lg={4} md={6} key={key} className="sm:p-0">
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to={item.routeLink}>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted  mb-2">{item.title}</p>
                        {/* {item.cardValue && ( */}
                        <h5 className="mb-0">
                          {item.cardValue && item.cardValue}
                        </h5>
                        {/* )} */}
                        {/* <p className="m-0 text-info">
                        {item.cardValue >= 0 ? <></> : item.alt}
                      </p> */}
                      </div>

                      <div className="avatar-sm ms-auto">
                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                          <i className={`${item.iconClass}`}></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          ))}
        {/* <Row>
          <div className="col-md-6">
            <Link
              to={`/familymember/create`}
              type="submit"
              style={{ width: language === 'mal' ? "200px" : "150px" }}
              className="w-md btn btn-success d-block"
            >
              {language === 'mal' ? '+ പുതിയത് ചേർക്കുക' : '+ New Member'}
            </Link>
          </div>
        </Row> */}
        <Col className="col-12 mt-2 sm:p-0">
          <ToolkitProvider
            keyField="id"
            columns={columns}
            data={familyMembersData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col md="2">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceMahallFamilyMemberSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="d-flex mt-2 mb-2"
                          style={{ alignItems: "center" }}
                        >
                          <p>{language === "mal" ? "ക്രമം" : "Sort by"}</p>
                          <div className="mx-2 mb-3">
                            <select
                              value={sort}
                              onChange={e => {
                                setSort(e.target.value)
                                useSetSession(
                                  "sort_value_member",
                                  e.target.value
                                )
                              }}
                              style={{
                                height: "30px",
                                padding: "0 5px",
                                borderColor: "#d0d6db",
                                borderRadius: "3px",
                              }}
                            >
                              {/* <option>
                                {language === "mal"
                                  ? "തിരഞ്ഞെടുക്കുക ..."
                                  : "Choose..."}{" "}
                              </option> */}
                              {sortData.map((item, key) => (
                                <option key={key} value={item.id}>
                                  {item.heading}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="d-flex mt-2 mb-2"
                          style={{ alignItems: "center", width: "100px" }}
                        >
                          <p>{language === "mal" ? "ക്രമം" : "Show"}</p>
                          <div className="mx-2">
                            <div className="mb-3">
                              <Input
                                value={pageSize}
                                onChange={e => {
                                  if (
                                    e.target.value <= 0 ||
                                    e.target.value > 100
                                  ) {
                                    // setPageSize(10)
                                  } else {
                                    setPageSize(e.target.value)
                                    useSetSession(
                                      "pageSize_value",
                                      e.target.value
                                    )
                                  }
                                }}
                                style={{
                                  height: "30px",
                                  padding: "0 5px",
                                  borderColor: "#d0d6db",
                                  borderRadius: "3px",
                                }}
                              ></Input>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        md="4"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div>
                          {userDetail?.mahall?.version === "basic" ? (
                            <Link
                              style={{
                                width: language === "mal" ? "135px" : "50px",
                              }}
                              className="w-md btn btn-primary m-auto me-0 mt-0 d-block"
                              to={handlePremiumLink()}
                            >
                              <i className="bx bx-list-ol font-size-16 align-middle"></i>{" "}
                              {language === "mal" ? "ഫിൽറ്റർ" : "Filter"}
                              <i
                                className="bx bx-crown mx-1"
                                style={{ fontSize: "12px", color: "gold" }}
                              />
                            </Link>
                          ) : (
                            <>
                              <Button
                                style={{
                                  width: language === "mal" ? "135px" : "50px",
                                }}
                                className="w-md btn btn-info m-auto me-0 mt-0 d-block bg-khaf-blue"
                                onClick={() =>
                                  setIsFilterListOpen(!isFilterListOpen)
                                }
                              >
                                {!isFilterListOpen ? (
                                  <>
                                    <i className="bx bx-list-ol font-size-16 align-middle"></i>
                                    {language === "mal" ? "ഫിൽറ്റർ" : "Filter"}
                                  </>
                                ) : (
                                  `${
                                    language === "mal" ? "അടയ്ക്കുക" : "Close"
                                  }`
                                )}
                              </Button>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>

                    {isFilterListOpen ? (
                      <>
                        {/* https://api.dev.khaf.in/api/v1/filter/family-member-filter/?page_size=25 */}
                        <AvForm
                          onValidSubmit={(e, v) => {
                            handleFilterFind(v)
                          }}
                        >
                          <Row>
                            <hr />
                            {/* gender  */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal" ? "ലിംഗം" : "Gender"}
                                </Label>
                                <AvField type="select" name="gender">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      english: "Male",
                                      malayalam: "പുരുഷൻ",
                                      id: "male",
                                    },
                                    {
                                      english: "Female",
                                      malayalam: "സ്ത്രീ",
                                      id: "female",
                                    },
                                    {
                                      english: "Others",
                                      malayalam: "മറ്റുള്ളവ",
                                      id: "others",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                            {/* Age */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal" ? "വയസ്സ്" : "Age"}
                                </Label>
                                <Col className=" d-flex justify-content-space-between">
                                  <div className="col-md-6">
                                    <AvField
                                      type="select"
                                      name="age_from"
                                      helpMessage={
                                        language === "mal" ? "മുതൽ" : "From"
                                      }
                                    >
                                      <option>{"none"}</option>
                                      {setOfNumbers(100)?.map((item, key) => (
                                        <option key={key} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </AvField>
                                  </div>

                                  <div className="col-md-6 mx-1">
                                    <AvField
                                      type="select"
                                      name="age_to"
                                      helpMessage={
                                        language === "mal" ? "വരെ" : "To"
                                      }
                                    >
                                      <option>{"none"}</option>
                                      {setOfNumbers(100)?.map((item, key) => (
                                        <option key={key} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </AvField>
                                  </div>
                                </Col>
                              </Col>
                            }
                            {/* is_family_head  */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "കുടുംബനാഥനാണോ?"
                                    : "Is Family Head?"}
                                </Label>
                                <AvField type="select" name="is_family_head">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "അതെ",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "അല്ല ",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }

                            {mahallTypeListFilterItem(
                              "relation",
                              "കുടുംബനാഥനുമായുള്ള ബന്ധം",
                              "Relation",
                              selectedMultiRelation,
                              setSelectedMultiRelation,
                              setRelations,
                              2
                            )}
                            {mahallTypeListFilterItem(
                              "id_card",
                              "ഐഡി കാർഡ് തരം",
                              "Id Card Type",
                              selectedMultiIdCardType,
                              setSelectedMultiIdCardType,
                              setIdCardTypes,
                              2
                            )}

                            {/* is_abroad */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "വിദേശത്താണോ?"
                                    : "Is Abroad?"}
                                </Label>
                                <AvField type="select" name="is_abroad">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "അതെ",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "അല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }

                            {mahallTypeListFilterItem(
                              "blood_group",
                              "രക്ത ഗ്രൂപ്പ്",
                              "Blood Group",
                              selectedMultiBloodGroup,
                              setSelectedMultiBloodGroup,
                              setBloodGroups
                            )}
                            {/* ready_to_donate */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "രക്തദാനത്തിന് തയ്യാറാണോ?"
                                    : "Ready To Donate?"}
                                </Label>
                                <AvField type="select" name="ready_to_donate">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "അതെ",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "അല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                            {mahallTypeListFilterItem(
                              "marital_status",
                              "വൈവാഹിക നില",
                              "Marital Status",
                              selectedMultiMaritalStatus,
                              setSelectedMultiMaritalStatus,
                              setMaritalStatuses
                            )}
                            {mahallTypeListFilterItem(
                              "unmarried_reason",
                              "അവിവാഹിത/ൻ കാരണം",
                              "Unmarried Reason",
                              selectedMultiUnmarriedReason,
                              setSelectedMultiUnmarriedReason,
                              setUnmarriedReasons
                            )}

                            {mahallTypeListFilterItem(
                              "health_condition",
                              "ആരോഗ്യസ്ഥിതി",
                              "Health Condition",
                              selectedMultiHealthCondition,
                              setSelectedMultiHealthCondition,
                              setHealthConditions,
                              2
                            )}
                            {mahallTypeListFilterItem(
                              "disease_type",
                              "രോഗം",
                              "Disease",
                              selectedMultiDiseaseType,
                              setSelectedMultiDiseaseType,
                              setDiseaseTypes
                            )}
                            {mahallTypeListFilterItem(
                              "disease_level",
                              "രോഗ നില",
                              "Disease Level",
                              selectedMultiDiseaseLevel,
                              setSelectedMultiDiseaseLevel,
                              setDiseaseLevels,
                              2
                            )}
                            {mahallTypeListFilterItem(
                              "disability_type",
                              "വൈകല്യ തരം",
                              "Disability Type",
                              selectedMultiDisabilityType,
                              setSelectedMultiDisabilityType,
                              setDisabilityTypes
                            )}
                            {mahallTypeListFilterItem(
                              "social_activity_type_id",
                              "സാമൂഹിക പ്രവർത്തനങ്ങൾ",
                              "Social Activities",
                              selectedMultiSocialActivity,
                              setSelectedMultiSocialActivity,
                              setSocialActivities
                            )}
                            {mahallTypeListFilterItem(
                              "bad_habits",
                              "മോശം ശീലങ്ങൾ",
                              "Bad Habits",
                              selectedMultiBadHabit,
                              setSelectedMultiBadHabit,
                              setBadHabits,
                              2
                            )}

                            {mahallTypeListFilterItem(
                              "educational_qualification_islamic",
                              "മത വിദ്യാഭ്യാസം",
                              "Islamic Education",
                              selectedMultiIslamicEducation,
                              setSelectedMultiIslamicEducation,
                              setIslamicEducations
                            )}
                            {mahallTypeListFilterItem(
                              "educational_qualification_formal",
                              "ഭൗതിക വിദ്യാഭ്യാസം",
                              "Formal Education",
                              selectedMultiFormalEducation,
                              setSelectedMultiFormalEducation,
                              setFormalEducations
                            )}

                            {/* is_student */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "വിദ്യാർത്ഥിയാണോ?"
                                    : "Is Student?"}
                                </Label>
                                <AvField type="select" name="is_student">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "അതെ",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "അല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                            {mahallTypeListFilterItem(
                              "not_studying_reason",
                              "പഠിക്കാത്തതിന്റെ കാരണം",
                              "Not Studying Reason",
                              selectedMultiNotStudyingReason,
                              setSelectedMultiNotStudyingReason,
                              setNotStudyingReasons
                            )}

                            {/* is_working */}
                            {
                              <Col md="2" className="mb-3">
                                <Label>
                                  {language === "mal"
                                    ? "ജോലി ചെയ്യുന്നുണ്ടോ?"
                                    : "Is Working?"}
                                </Label>
                                <AvField type="select" name="is_working">
                                  <option>{"none"}</option>
                                  {[
                                    {
                                      id: true,
                                      malayalam: "അതെ",
                                      english: "Yes",
                                    },
                                    {
                                      id: false,
                                      malayalam: "അല്ല",
                                      english: "No",
                                    },
                                  ]?.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {language === "mal"
                                        ? item?.malayalam
                                        : item?.english}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            }
                            {mahallTypeListFilterItem(
                              "not_working_reason",
                              "ജോലി ചെയ്യാത്തതിന്റെ കാരണം",
                              "Not Working Reason",
                              selectedMultiNotWorkingReason,
                              setSelectedMultiNotWorkingReason,
                              setNotWorkingReasons
                            )}

                            <Col md="2" className="mb-3">
                              <Label>
                                {language === "mal"
                                  ? "അനാഥനാണോ?"
                                  : "Is Orphan?"}
                              </Label>
                              <AvField type="select" name="is_orphan">
                                <option>{"none"}</option>
                                {[
                                  {
                                    id: true,
                                    malayalam: "അതെ",
                                    english: "Yes",
                                  },
                                  {
                                    id: false,
                                    malayalam: "അല്ല",
                                    english: "No",
                                  },
                                ]?.map((item, key) => (
                                  <option key={key} value={item.id}>
                                    {language === "mal"
                                      ? item?.malayalam
                                      : item?.english}
                                  </option>
                                ))}
                              </AvField>
                            </Col>

                            {/* {countryListFilterItem('not_working_reason', "ജോലി ചെയ്യാത്തതിന്റെ കാരണം", "Not Working Reason", selectedMultiNotWorkingReason, setSelectedMultiNotWorkingReason, setNotWorkingReasons)} */}

                            {countryListFilterItem(
                              "രാജ്യം",
                              "Country",
                              selectedMultiCountry,
                              setSelectedMultiCountry,
                              setCountries,
                              3
                            )}

                            <Col md="2" className="mb-3">
                              <Label>
                                {language === "mal"
                                  ? "ജീവിച്ചിരിപ്പുണ്ടോ?"
                                  : "Is Alive?"}
                              </Label>
                              <AvField type="select" name="is_alive">
                                <option>{"none"}</option>
                                {[
                                  {
                                    id: true,
                                    malayalam: "അതെ",
                                    english: "Yes",
                                  },
                                  {
                                    id: false,
                                    malayalam: "അല്ല",
                                    english: "No",
                                  },
                                ]?.map((item, key) => (
                                  <option key={key} value={item.id}>
                                    {language === "mal"
                                      ? item?.malayalam
                                      : item?.english}
                                  </option>
                                ))}
                              </AvField>
                            </Col>

                            {mahallTypeListFilterItem(
                              "varisankya_grade",
                              "വരിസംഖ്യ ഗ്രേഡ്",
                              "Varisangya Grade",
                              selectedMultiVarisangyaGrade,
                              setSelectedMultiVarisangyaGrade,
                              setVarisangyaGrades,
                              2
                            )}
                            {mahallTypeListFilterItem(
                              "area",
                              "ഏരിയ",
                              "Area",
                              selectedMultiArea,
                              setSelectedMultiArea,
                              setAreas,
                              2
                            )}
                            <Col md="2">
                              <AvField
                                name="family__house_number"
                                type="string"
                                label="House Number"
                              />
                            </Col>
                            {mahallWardNumbers &&
                              mahallWardNumbers.length > 0 && (
                                <Col md="2" className="mb-3">
                                  <Label>
                                    {language === "mal"
                                      ? "വാർഡ് നമ്പർ"
                                      : "Ward No."}
                                  </Label>
                                  <div className="mb-3">
                                    <Select
                                      value={selectedMultiWardNumber}
                                      isMulti={true}
                                      onChange={e => {
                                        handleMultiWardNumber(e)
                                      }}
                                      options={optionGroupWardNumber()}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                              )}
                            {mahallTypeListFilterItem(
                              "house_type",
                              "വീട്",
                              "House Type",
                              selectedMultiHouseType,
                              setSelectedMultiHouseType,
                              setHouseTypes,
                              2
                            )}
                            {mahallTypeListFilterItem(
                              "house_build_type",
                              "വീടിൻ്റെ സ്ഥിതി",
                              "Build Type",
                              selectedMultiHouseBuildType,
                              setSelectedMultiHouseBuildType,
                              setHouseBuildTypes,
                              2
                            )}
                          </Row>
                          <Row>
                            {filterListArea?.length >= 1 && (
                              <div className="mb-3">
                                <p>Filters</p>
                                {filterListArea?.map((label, key) => (
                                  <Badge
                                    key={key}
                                    className={`bg-light rounded-pill p-2 px-3 me-2 mb-2`}
                                  >
                                    {handleFilterLabelText(label?.field)}
                                    <IoCloseOutline
                                      onClick={() => removeFilterItems(label)}
                                      className="font-size-14 text-danger cursor-pointer ms-2"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Badge>
                                ))}
                              </div>
                            )}
                            <Col className="mb-3 d-flex align-items-end justify-content-end">
                              <Button
                                onClick={() => setModalCollection(true)}
                                type="submit"
                                color="info"
                                className="me-3"
                              >
                                {language === "mal"
                                  ? "കളക്ഷനിലേക്ക്  ചേർക്കുക"
                                  : "Add To Collection"}
                              </Button>
                              <Button
                                type="submit"
                                className="w-xl btn btn-success"
                              >
                                {language === "mal" ? "ഫിൽറ്റർ" : "Find"}
                              </Button>
                            </Col>
                          </Row>
                        </AvForm>
                      </>
                    ) : (
                      <></>
                    )}
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          {/* <Col md="2">
                            <AvForm>
                              <div className="d-flex" style={{ width: "120px", border: "1px solid #333" }}>
                                <AvField
                                  type="select"
                                  name="sort_member"
                                  className="sort"
                                  value={sort}
                                  onChange={(e) => {
                                    setSort(e.target.value)
                                  }}
                                >
                                  <option>
                                    {language === "mal"
                                      ? "ഏരിയ തിരഞ്ഞെടുക്കുക ..."
                                      : "Sort by"}{" "}
                                  </option>
                                  {sortData.map((item, key) => (
                                    <option key={key} value={item.id}>
                                      {item.heading}
                                    </option>
                                  ))}
                                </AvField>
                                <i className="bx bxs-sort-alt" style={{ fontSize: "15px", display: "flex", alignItems: "center" }} />
                              </div>
                            </AvForm>
                          </Col> */}
                          {/* {familyMembersforDownloadLoading ? <Spinner color="secondary" className="d-block m-auto" /> :
                            userDetail?.mahall.version === 'basic' ? <></> :
                              <Button data-tip data-for="downloadTip"
                                style={{ width: "200px", border: 'none' }}
                                className="w-md btn btn-info m-auto me-0 mt-0 mb-2 d-block bg-white"
                              >
                                {familyMembersforDownload && familyMembersforDownload.length > 0 && <CSVLink data={familyMembersExcelData} headers={headers} filename={"khaf-smart-mahall-family-members-list.csv"}>
                                  <i className="bx bx-download mx-1" style={{ fontSize: '18px', color: "blue" }} />
                                  {language === 'mal' ? `ഡൗൺലോഡ് (${familyMembers?.count})` : `Download (${familyMembers?.count})`}
                                </CSVLink>}
                              </Button>
                          } */}
                          {userDetail?.mahall?.version === "basic" ? (
                            <></>
                          ) : (
                            <Button
                              style={{
                                width: language === "mal" ? "150px" : "130px",
                                border: "bold",
                              }}
                              className="btn-md m-auto me-4 mt-0 mb-2 d-block bg-white text-khaf"
                              onClick={() => setModalDownload(true)}
                            >
                              <i
                                className="bx bx-download mx-1"
                                style={{ fontSize: "18px", color: "#009846" }}
                              />
                              {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                            </Button>
                          )}
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {familyMembers &&
                      familyMembers?.results &&
                      familyMembers?.results?.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          {language === "mal"
                            ? "അംഗങ്ങൾ ഒന്നും ഇല്ല"
                            : "No Members Yet"}
                        </p>
                      )}
                  </CardBody>
                </Card>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllMahallFamilyMembers
