import React from "react"
// import { Link } from 'react-router-dom'
import { useSelector } from "react-redux"
import moment from "moment"
import { Row, Col, Card, Badge } from "reactstrap"
import { Link } from "react-router-dom"

const WelcomeComp = () => {
  const { language, instituteDetail } = useSelector(state => ({
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    instituteDetail: state.Institutes.instituteDetail,
  }))

  return (
    <>
      <Card className="" style={{ backgroundColor: "#cafbca" }}>
        <Row>
          <Col md="6" style={{ display: "flex", alignItems: "center" }}>
            <div className="institute-name-div">
              <Badge className="badge-institute-type">
                {instituteDetail?.institute_type}
              </Badge>
              <h2 className="text-khaf institute-name">
                <b>
                  {language === "mal"
                    ? instituteDetail?.institute_malayalam_name
                      ? instituteDetail?.institute_malayalam_name
                      : instituteDetail?.institute_english_name
                    : instituteDetail?.institute_english_name}
                </b>
              </h2>
            </div>
          </Col>
          <Col md="6" className="institute-detail-div">
            <div className="pt-3 px-3">
              <p className="pb-2 m-0 text-dark" style={{ fontWeight: "500" }}>
                {language === "mal" ? "" : "Since "}{" "}
                {moment(instituteDetail?.established_date).format("DD/MM/yyyy")}{" "}
                {language === "mal" ? " മുതൽ" : ""}
              </p>
              <p
                className="m-0 pb-2 text-khaf-blue"
                style={{ fontSize: "15px", fontWeight: "500" }}
              >
                {instituteDetail?.institute_place}
              </p>
              <p className="text-muted m-0 pb-2">
                {instituteDetail?.description
                  ? instituteDetail?.description
                  : ""}
              </p>
              {/* <div className="d-flex justify-content-end">
                <Link
                  to={`/institute/update/${instituteDetail?.id}`}
                  className="btn btn-primary btn-sm"
                >
                  {language === 'mal' ? ' അപ്ഡേറ്റ്' : 'Update'}
                </Link>
              </div> */}

              {/* <div className="d-flex justify-content-end m-2"> */}
              {/* <div
                className="me-2"
                style={{
                  backgroundColor: 'white',
                  color: '#009846',
                  fontWeight: '700',
                  borderRadius: '6px',
                }}
              >
                <p className="address-p"> {instituteDetail?.institute_type}</p>
              </div> */}
            </div>
          </Col>
          <div className="d-flex justify-content-end">
            <Link
              to={`/institute/update/${instituteDetail?.id}`}
              className="btn btn-primary btn-sm mx-2 mb-2"
              style={{ width: "125px" }}
            >
              {language === "mal" ? " അപ്ഡേറ്റ്" : "Update"}
            </Link>
          </div>
        </Row>
      </Card>
    </>
  )
}
export default WelcomeComp
