import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { useSelector } from "react-redux"

import user1 from "assets/images/logo/mahalluLogo.png"

const WelcomeComp = () => {
  const { userDetail, language } = useSelector(state => ({
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
  }))

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="8">
              <div className="text-primary p-4">
                <h5 className="text-primary">
                  {language === "mal"
                    ? userDetail?.mahall_group?.mahallgroup_malayalam_name
                      ? userDetail?.mahall_group?.mahallgroup_malayalam_name
                      : userDetail?.mahall_group?.mahallgroup_english_name
                    : userDetail?.mahall_group?.mahallgroup_english_name}
                </h5>
                <p>
                  {language === "mal"
                    ? userDetail?.mahall?.mahall_malayalam_name
                      ? userDetail?.mahall?.mahall_malayalam_name
                      : userDetail?.mahall?.mahall_english_name
                    : userDetail?.mahall?.mahall_english_name}
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={
                    userDetail?.mahall?.logo ? userDetail?.mahall?.logo : user1
                  }
                  alt=""
                  className="img-thumbnail rounded-circle"
                  style={{
                    width: "100px",
                    height: "70px",
                    objectFit: "contain",
                  }}
                />
              </div>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col>
                    <p className="text-muted mb-0">
                      {`${userDetail?.mahall?.lsg_english_name} ${userDetail?.mahall?.lsg_type_english}, `}
                      {userDetail?.mahall?.village_english_name},
                      {`${userDetail?.mahall?.post_office}-${userDetail?.mahall?.pin_code}, `}
                      {userDetail?.mahall?.district_english_name},
                      {userDetail?.mahall?.state_english_name}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
