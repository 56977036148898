import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Modal, Button, Label, Row } from "reactstrap"
import axios from "axios"
import { Image } from "react-bootstrap"

import {
  updateGallerySuccess,
  updateGalleryFail,
  deleteGallery,
} from "store/actions"
import { API_URL } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { useFileSizes } from "hooks/useFileSize"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UiModalGalleryUpdate = ({ show, onCloseclick, data, history }) => {
  const dispatch = useDispatch()
  // const params = useParams()
  const handleFileSizes = useFileSizes

  const { loading, language, instituteDetail } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    galleryDetail: state.Galleries.galleryDetail,
    instituteDetail: state.Institutes.instituteDetail,
  }))

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })
  const [imagePreview, setImagePreview] = useState()

  const [filesSize, setFilesSize] = useState()

  // useEffect(() => {
  //   setImagePreview()
  // }, [data, data?.image])

  // useEffect(() => {
  //   dispatch(getGalleryDetail(params?.id))
  // }, [dispatch, params?.id])

  // console.log(instituteDetail);

  const handleValidSubmit = (onSubmitProps, values) => {
    setCustomLoad(true)
    // if (handleFileSizes(filesSize)?.status === true) {
    updateGallery(
      data.id,
      selectedFile,
      values,
      instituteDetail,
      dispatch,
      history,
      setCustomLoad
    )
    setImagePreview()
    // }
  }

  const changeHandler = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleDeleteGallery = id => {
    dispatch(deleteGallery(id, history, instituteDetail?.id))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal"
                ? "ഗാലറി ഡാറ്റ അപ്ഡേറ്റ് ചെയ്യുക"
                : "Update Gallery Data"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-3">
                <AvField
                  name="title"
                  type="text"
                  label={language === "mal" ? "പേര്" : "Title"}
                  value={data?.title}
                />
              </div>
              {/* <div className="row mb-3">
                <AvField
                  name="label"
                  type="text"
                  label={language === "mal" ? "ലേബൽ" : "Label"}
                  value={data?.label}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="link"
                  type="text"
                  label={language === "mal" ? "ലിങ്ക്" : "Link"}
                  value={data?.link}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="description"
                  type="textarea"
                  label={language === "mal" ? "വിവരണം" : "Description"}
                  value={data?.description}
                />
              </div> */}
              <div className="row mb-3">
                <Label>{language === "mal" ? "ഫോട്ടോ" : "Photo"}</Label>
                {imagePreview ? (
                  <Image
                    style={{ width: "300px", height: "200px" }}
                    className="my-2"
                    src={imagePreview.image}
                  />
                ) : (
                  <Image
                    style={{ width: "300px", height: "200px" }}
                    className="my-2"
                    src={data?.image}
                  />
                )}
                {handleFileSizes(filesSize)?.text && (
                  <p className="text-danger mt-1">
                    {handleFileSizes(filesSize)?.text}
                  </p>
                )}
                <AvField name="image" type="file" onChange={changeHandler} />
              </div>
              <Row className="mt-5 d-flex">
                <div className="col-6">
                  <div className="row justify-content-start">
                    <div>
                      <Button
                        type="button"
                        color="danger"
                        className="w-md"
                        onClick={() => {
                          onCloseclick()
                          handleDeleteGallery(data?.id)
                        }}
                      >
                        {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md m-auto me-0 d-block"
                        onClick={onCloseclick}
                      >
                        {loading ||
                          (customLoad && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          ))}
                        {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക " : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalGalleryUpdate
UiModalGalleryUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
  data: propTypes.object,
}

function updateGallery(
  id,
  selectedFile,
  values,
  instituteDetail,
  dispatch,
  history,
  setCustomLoad
) {
  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append("image", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("title", values.title)
  formData.append("label", values.label)
  formData.append("link", values.link)
  formData.append("description", values.description)
  formData.append("institute", instituteDetail?.id)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .patch(`${API_URL}/api/v1/institute/institute_image/${id}/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        doneNotification("Updated Successfully")
        setCustomLoad(false)
        dispatch(updateGallerySuccess(res?.data))
        // dispatch(getInstituteDetail(res?.data?.institute))
      } else {
        // updateGalleryFail(err)
        errorNotification()
      }
    })
    .catch(err => {
      updateGalleryFail(err)
      errorNotification()
      setCustomLoad(false)
    })
}
