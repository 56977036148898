import PropTypes from "prop-types"
import classnames from "classnames"
import { useHistory } from "react-router"
import React, { useEffect, useState, Fragment } from "react"
import {
  Col,
  Container,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
//actions
//redux
import { useSelector, useDispatch } from "react-redux"

import UiModalMahallTypelistsUpdate from "./UiModalMahallTypelistsUpdate"
import UiModalDefaultTypelistsUpdate from "./UiModalDefaultTypelistsUpdate"

import {
  getMahallDropdown,
  createMahallTypeListItem,
  createDefaultTypeListItem,
  getDefaultTypeLists,
  getMahallTypeLists,
  deleteDefaultTypeListItem,
  deleteMahallTypeListItem,
} from "store/actions"

const Details = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { defaultTypeLists, mahallTypeLists, loading, language } = useSelector(
    state => ({
      defaultTypeLists: state.TypeLists.defaultTypeLists,
      mahallTypeLists: state.TypeLists.mahallTypeLists,
      loading: state.TypeLists.loading,
      language: state.Layout.language,
    })
  )

  const [modalMahallTypeList, setModalMahallTypeList] = useState(false)
  const [onUpdateMahallTypeList, setOnUpdateMahallTypeList] = useState({})

  const [modalDefaultTypeList, setModalDefaultTypeList] = useState(false)
  const [onUpdateDefaultTypeList, setOnUpdateDefaultTypeList] = useState({})

  const updateMahallTypeListModal = item => {
    setOnUpdateMahallTypeList(item)
    setModalMahallTypeList(true)
  }

  const updateDefaultTypeListModal = item => {
    setOnUpdateDefaultTypeList(item)
    setModalDefaultTypeList(true)
  }
  // console.log(defaultTypeLists)

  const { searchMahalls } = useSelector(state => ({
    searchMahalls: state.Mahalls.searchMahalls,
  }))

  // const [activeTab] = useState('1')
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1")
  const [searchText, setSearchText] = useState("")
  const [selectedMahall, setSelectedMahall] = useState("Search Mahall")
  const [mahallId, setMahallId] = useState("")

  const typeHeads = [
    { name: "Mahar Type", value: "mahar_type" },
    { name: "Area", value: "area" },
    { name: "LSG Type", value: "lsg_type" },
    { name: "Ration Card Category", value: "ration_card" },
    { name: "House Type", value: "house_type" },
    { name: "House Build Type", value: "house_build_type" },
    { name: "Water Facility", value: "water_facility" },
    { name: "Electric Facility", value: "electric_facility" },
    { name: "Financial Status", value: "financial_status" },
    { name: "Bank Loan", value: "bank_loan" },
    { name: "Annual Income", value: "annual_income" },
    { name: "Home Business", value: "home_business" },

    { name: "Gender", value: "gender" },
    { name: "Relation", value: "relation" },
    { name: "ID Card", value: "id_card" },
    { name: "Marital Status", value: "marital_status" },
    { name: "Unmarried Reason", value: "unmarried_reason" },
    { name: "Blood Group", value: "blood_group" },
    { name: "Health Condition", value: "health_condition" },
    { name: "Disease Type", value: "disease_type" },
    { name: "Disease Level", value: "disease_level" },
    { name: "Disability Type", value: "disability_type" },
    { name: "Social Activity Type", value: "social_activity_type" },
    { name: "Bad Habits", value: "bad_habits" },
    {
      name: "Educational Qualification Islamic",
      value: "educational_qualification_islamic",
    },
    {
      name: "Educational Qualification Formal",
      value: "educational_qualification_formal",
    },
    { name: "Not Studying Reason", value: "not_studying_reason" },
    { name: "Not Working Reason", value: "not_working_reason" },
    { name: "Education Status", value: "education_status" },
    { name: "Job Sector", value: "job_sector" },
    { name: "Job Type", value: "job_type" },
    { name: "Ledger Category", value: "ledger_category" },
    { name: "Varisangya Grade", value: "varisankya_grade" },
  ]

  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }

  useEffect(() => {
    dispatch(getDefaultTypeLists())
  }, [dispatch])

  useEffect(() => {
    if (mahallId && mahallId != "") {
      dispatch(getMahallTypeLists(mahallId))
    }
  }, [dispatch, mahallId])

  useEffect(() => {
    dispatch(getMahallDropdown(searchText))
  }, [dispatch])

  const handleDefaultTypeListItemDelete = id => {
    dispatch(deleteDefaultTypeListItem(id, history))
  }

  const handleMahallTypeListItemDelete = id => {
    dispatch(deleteMahallTypeListItem(id, history, mahallId))
  }

  // const toggleTab = tab => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab)
  //   }
  // }

  const Options = [
    {
      options: searchMahalls?.map((results, index) => ({
        key: index,
        label:
          language === "mal"
            ? results?.mahall_malayalam_name
              ? results?.mahall_malayalam_name
              : results?.mahall_english_name
            : results?.mahall_english_name,
        value: results?.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  function handlerFinalValue(event) {
    dispatch(getMahallDropdown(event.value))
    setMahallId(event.value)
    setSelectedMahall(event.label)
  }

  function handleDefaultCreateTypeListItem(values) {
    const defaultCreateTypeListItem = {
      ...values,
      is_default: true,
    }
    // console.log("defaultCreateType: ", defaultCreateTypeListItem)
    dispatch(createDefaultTypeListItem(defaultCreateTypeListItem, history))
    values = ""
  }

  function handleMahallCreateTypeListItem(values) {
    const mahallCreateTypeListItem = {
      ...values,
      mahall: mahallId,
      is_default: false,
    }
    // console.log("mahallCreateType: ", mahallCreateTypeListItem)
    dispatch(createMahallTypeListItem(mahallCreateTypeListItem, history))
  }

  return (
    <React.Fragment>
      <UiModalMahallTypelistsUpdate
        show={modalMahallTypeList}
        onCloseclick={() => setModalMahallTypeList(false)}
        data={onUpdateMahallTypeList}
        history={history}
      />
      <UiModalDefaultTypelistsUpdate
        show={modalDefaultTypeList}
        onCloseclick={() => setModalDefaultTypeList(false)}
        data={onUpdateDefaultTypeList}
        history={history}
      />
      <div>
        <Container fluid>
          {
            <>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4">
                        Admin Preferences
                      </CardTitle>
                      {/* <p className="card-title-desc">
                          Create & update the data accordingly.
                        </p> */}

                      <Nav className="icon-tab nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customIconActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleIconCustom("1")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-address-card"></i> Default
                              Type List
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-address-card"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customIconActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleIconCustom("2")
                            }}
                          >
                            <span className="d-none d-sm-block">
                              <i className="fas fa-cash-register"></i> Mahall
                              Type List
                            </span>
                            <span className="d-block d-sm-none">
                              <i className="fas fa-cash-register"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={customIconActiveTab}
                        className="mt-4 p-3 text-muted"
                        // style={{backgroundColor:'#F9F9F9'}}
                      >
                        {/* Default Type List */}
                        <TabPane tabId="1">
                          <AvForm
                            onValidSubmit={(e, v) => {
                              handleDefaultCreateTypeListItem(v)
                            }}
                          >
                            <Row className="mt-0">
                              <Col sm="12">
                                <CardText className="mb-0"></CardText>
                                <Row>
                                  <Col className="col-12 mt-3 d-flex flex-wrap">
                                    <div className="  row mb-4">
                                      <AvField
                                        type="select"
                                        name="type"
                                        label="Type"
                                      >
                                        <option>Choose a type ...</option>
                                        {typeHeads?.map((item, key1) => (
                                          <option key={key1} value={item.value}>
                                            {item.name}
                                          </option>
                                        ))}
                                      </AvField>
                                    </div>
                                    <div className=" mx-3">
                                      <AvField
                                        name="english"
                                        label="English"
                                        type="text"
                                      />
                                    </div>
                                    <div className=" mx-3">
                                      <AvField
                                        name="malayalam"
                                        label="Malayalam"
                                      />
                                    </div>
                                    <div
                                      className=" d-flex"
                                      style={{ alignItems: "center" }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-success save-customer"
                                      >
                                        {loading && (
                                          <>
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                          </>
                                        )}
                                        Add
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                          <Row>
                            {typeHeads.map((type, key) => (
                              <Fragment key={key}>
                                {defaultTypeLists?.[`${type.value}`] &&
                                  defaultTypeLists?.[`${type.value}`]?.length >
                                    0 && (
                                    <Col
                                      sm="12"
                                      md="12"
                                      lg="6"
                                      key={key}
                                      className="mb-4"
                                    >
                                      <>
                                        <label>{type.name}</label> <br />
                                        <div className="table-responsive">
                                          <Table className="table table-bordered mb-0">
                                            <thead>
                                              <tr>
                                                <th>#</th>
                                                <th>English</th>
                                                <th>മലയാളം</th>
                                                <th>Actions</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {defaultTypeLists?.[
                                                `${type.value}`
                                              ]?.map((item, key) => (
                                                <Fragment key={key}>
                                                  <tr>
                                                    <th scope="row">
                                                      {key + 1}
                                                    </th>
                                                    <td>{item.english}</td>
                                                    <td>{item.malayalam}</td>
                                                    <td>
                                                      <Button
                                                        type="button"
                                                        color="white"
                                                        className="ms-1 btn"
                                                        onClick={() =>
                                                          updateDefaultTypeListModal(
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className="bx bx-pencil me-2"
                                                          style={{
                                                            color: "green",
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </Button>

                                                      <Button
                                                        type="button"
                                                        color="white"
                                                        className="ms-1 btn "
                                                        onClick={() => {
                                                          handleDefaultTypeListItemDelete(
                                                            item.id
                                                          )
                                                        }}
                                                      >
                                                        <i
                                                          className="bx bx-trash me-2"
                                                          style={{
                                                            color: "red",
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </Button>
                                                    </td>
                                                  </tr>
                                                </Fragment>
                                              ))}
                                            </tbody>
                                          </Table>
                                        </div>
                                      </>
                                    </Col>
                                  )}
                              </Fragment>
                            ))}
                          </Row>
                        </TabPane>
                      </TabContent>

                      <TabContent
                        activeTab={customIconActiveTab}
                        className=" p-3 text-muted"
                        // style={{backgroundColor:'#F9F9F9'}}
                      >
                        {/* Mahallu Type List */}
                        <TabPane tabId="2">
                          <AvForm
                            onValidSubmit={(e, v) => {
                              handleMahallCreateTypeListItem(v)
                            }}
                          >
                            <Row className="mt-0 mb-4">
                              <Col sm="12">
                                <CardText className="mb-0"></CardText>
                                <Row>
                                  <div className="col-12 row mb-4">
                                    <Col>
                                      <Select
                                        onInputChange={handleEnters}
                                        placeholder={selectedMahall}
                                        value={selectedMahall}
                                        options={Options}
                                        classNamePrefix="select2-selection"
                                        onChange={handlerFinalValue}
                                        type="text"
                                        title="Mahall"
                                        required="required"
                                      />
                                    </Col>
                                  </div>
                                </Row>
                                <Row>
                                  <Col className="col-12 mt-3 d-flex flex-wrap">
                                    <div className="  row mb-4">
                                      <AvField
                                        type="select"
                                        name="type"
                                        label="Type"
                                      >
                                        <option>Choose a type ...</option>
                                        {typeHeads?.map((item, key2) => (
                                          <option key={key2} value={item.value}>
                                            {item.name}
                                          </option>
                                        ))}
                                      </AvField>
                                    </div>
                                    <div className=" mx-3">
                                      <AvField
                                        name="english"
                                        label="English"
                                        type="text"
                                      />
                                    </div>
                                    <div className=" mx-3">
                                      <AvField
                                        name="malayalam"
                                        label="Malayalam"
                                      />
                                    </div>
                                    <div
                                      className=" d-flex"
                                      style={{ alignItems: "center" }}
                                    >
                                      <button
                                        type="submit"
                                        className="btn btn-success save-customer"
                                      >
                                        {loading && (
                                          <>
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                          </>
                                        )}
                                        Add
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                          <Row>
                            {typeHeads.map((type, key) => (
                              <Fragment key={key}>
                                {mahallTypeLists?.[`${type.value}`] &&
                                  mahallTypeLists?.[`${type.value}`]?.length >
                                    0 && (
                                    <Col
                                      sm="12"
                                      md="12"
                                      lg="6"
                                      key={key}
                                      className="mb-4"
                                    >
                                      <>
                                        <label>{type.name}</label> <br />
                                        <div className="table-responsive">
                                          <Table className="table table-bordered mb-0">
                                            <thead>
                                              <tr>
                                                <th>#</th>
                                                <th>English</th>
                                                <th>മലയാളം</th>
                                                <th>Actions</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {mahallTypeLists?.[
                                                `${type.value}`
                                              ]?.map((item, key) => (
                                                <Fragment key={key}>
                                                  <tr>
                                                    <th scope="row">
                                                      {key + 1}
                                                    </th>
                                                    <td>{item.english}</td>
                                                    <td>{item.malayalam}</td>
                                                    <td>
                                                      <Button
                                                        type="button"
                                                        color="white"
                                                        className="ms-1 btn"
                                                        onClick={() =>
                                                          updateMahallTypeListModal(
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          className="bx bx-pencil me-2"
                                                          style={{
                                                            color: "green",
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </Button>

                                                      <Button
                                                        type="button"
                                                        color="white"
                                                        className="ms-1 btn "
                                                        onClick={() => {
                                                          handleMahallTypeListItemDelete(
                                                            item.id
                                                          )
                                                        }}
                                                      >
                                                        <i
                                                          className="bx bx-trash me-2"
                                                          style={{
                                                            color: "red",
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </Button>
                                                    </td>
                                                  </tr>
                                                </Fragment>
                                              ))}
                                            </tbody>
                                          </Table>
                                        </div>
                                      </>
                                    </Col>
                                  )}
                              </Fragment>
                            ))}
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col className="col-12">
                    <Card>
                      <CardBody>
                        <Row className="col-12">
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Save
                            </button>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col> */}
                {/* </AvForm> */}
              </Row>
            </>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

Details.propTypes = {
  settings: PropTypes.object,
  match: PropTypes.any,
  getSettings: PropTypes.func,
}

export default Details
