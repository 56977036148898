import React, { Fragment, useState, useEffect } from "react"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Badge,
  Collapse,
  Button,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { debounce, map, range } from "lodash"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import moment from "moment"

//Import Images
import men from "assets/images/icons/men.png"
import women from "assets/images/icons/woman.png"
import family from "assets/images/icons/family.png"
import facility from "assets/images/icons/facility.png"
import land from "assets/images/icons/land.png"
import car from "assets/images/icons/car.png"
import other from "assets/images/icons/other.png"
import seniorCitizen from "assets/images/icons/seniorcitizen.jpeg"
import patient from "assets/images/icons/patient.jpeg"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import MyPagination from "components/Common/MyPagination"
import { deleteFamily, getFamilyDetail, getFamilyMembers } from "store/actions"
import UiModalQrcode from "pages/common/UiModalQrcode"

const SurveyUserFamilyDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const [modalQrcode, setModalQrcode] = useState(false)
  const [familyIdModal, setFamilyIdModal] = useState({})

  const { familyDetail, loading, detailLoading, familyMembers, language } =
    useSelector(state => ({
      loading: state.Families.loading,
      detailLoading: state.Families.detailLoading,
      language: state.Layout.language,
      familyDetail: state.Families.familyDetail,
      mahallDetail: state.Mahalls.mahallDetail,
      familyMembers: state.FamilyMembers.familyMembers,
    }))

  const totalPages = Math.ceil(familyMembers?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const [isOpen, setIsOpen] = useState(false)

  const handleDeleteEvent = () => {
    dispatch(deleteFamily(familyDetail.id, history))
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getFamilyDetail(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    if (familyDetail?.id) {
      dispatch(getFamilyMembers(searchText, page, familyDetail?.id))
    }
  }, [searchText, page, familyDetail])

  const debounceMahallFamilyFamilyMemberSearch = debounce(
    value => setSearchText(value),
    600
  )

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
    },
    {
      dataField: "mahall_custom_id",
      text: `${language === "mal" ? "ഐഡി" : "Mahall ID"}`,
    },
    {
      dataField: "full_name",
      text: `${language === "mal" ? "പേര്" : "Name"}`,
    },
    {
      dataField: "ageGender",
      text: `${language === "mal" ? "പ്രായം / ലിംഗം" : "Age / Gender"}`,
      sort: true,
    },
    {
      dataField: "blood_group.english",
      text: `${language === "mal" ? "രക്ത ഗ്രൂപ്പ്" : "Blood Group"}`,
      sort: true,
    },
    {
      dataField: "healthStatus",
      text: `${language === "mal" ? "ആരോഗ്യ സ്ഥിതി" : "Health Status"}`,
      sort: true,
    },
    {
      dataField: "phone",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]

  const familyMembersData = map(familyMembers?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    ageGender: `${getAge(item?.dob && item?.dob != null ? item?.dob : 0)} / ${
      item?.gender && item?.gender != null ? item?.gender : ""
    }`,
    healthStatus: (
      <>
        {item?.health_condition?.map((status, key) => {
          return (
            <Fragment key={key}>
              <Badge
                className={
                  "mx-1 font-size-12 badge-soft-" +
                  `${
                    status.english == "Patient"
                      ? "danger"
                      : status.english == "Satisfied"
                      ? "success"
                      : status.english == "Disabled"
                      ? "warning"
                      : ""
                  }`
                }
                color="black"
                pill
              >
                {status.english}
              </Badge>
            </Fragment>
          )
        })}
      </>
    ),
    action: (
      <div>
        <Link
          to={`/familymembers/${item?.id}`}
          className=" btn-success w-xs btn-sm"
        >
          {language === "mal" ? "കാണുക" : "View"}
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    if (dateString) {
      const startDate = moment(today)
      const timeEnd = moment(birthDate)
      const diff = startDate.diff(timeEnd)
      var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
      if (age < 0) age = 0
      return age
    } else {
      return 0
    }
  }

  const changeYearsTillDate = year => {
    const thisYear = new Date().getFullYear()
    const difference = thisYear - year
    return difference
  }

  const [col1, setCol1] = useState(true)
  const [col2, setCol2] = useState(true)
  const [col3, setCol3] = useState(true)
  const [col4, setCol4] = useState(true)

  const familyIdQr = id => {
    setFamilyIdModal(id)
    setModalQrcode(true)
  }

  return (
    <>
      <UiModalQrcode
        show={modalQrcode}
        onCloseclick={() => setModalQrcode(false)}
        data={familyIdModal}
      />
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <MetaTags>
        <title>Family | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഖാഫ്" : "Khaf"}
          breadcrumbItem={
            language === "mal" ? "കുടുംബ വിശദാംശങ്ങൾ" : "Family Details"
          }
        />
        <Container fluid>
          <div className="container-fluid ">
            <Row>
              <Col md={12}>
                {detailLoading ? (
                  <Spinner />
                ) : (
                  <Row>
                    <Card className="sm:p-0 px-0 ">
                      <div
                        className="card-header bg-khaf"
                        style={{
                          borderRadius: "10px 10px 0 0",
                        }}
                      >
                        <div className="d-flex justify-content-between mb-2">
                          {familyDetail?.mahall_custom_id && (
                            <Badge
                              className="rounded-pill bg-white"
                              style={{ fontSize: "12px", color: "black" }}
                            >
                              {familyDetail?.mahall_custom_id}
                            </Badge>
                          )}
                          <Badge
                            className="rounded-pill bg-dark"
                            style={{ fontSize: "12px" }}
                          >
                            {familyDetail?.custom_id}
                          </Badge>
                        </div>
                        <div className=" d-flex align-items-center justify-content-between align-items-center familycard">
                          <h5 className="align-self-start text-white mb-0  pt-1 housename">
                            {familyDetail?.house_name}{" "}
                            {familyDetail &&
                              familyDetail?.area &&
                              familyDetail?.area?.english && (
                                <span className="me-2">
                                  ({" "}
                                  {language === "mal"
                                    ? familyDetail?.area?.malayalam
                                    : familyDetail?.area?.english}
                                  )
                                </span>
                              )}
                            <span className="font-size-12 text-white fw-normal">
                              {familyDetail?.house_number
                                ? familyDetail?.house_number
                                : 0}
                              /
                              {familyDetail?.ward_number
                                ? familyDetail?.ward_number
                                : 0}
                            </span>
                            <span className="font-size-12 text-white fw-normal">
                              -{" "}
                              {language === "mal"
                                ? familyDetail?.lsg_malayalam_name
                                : familyDetail?.lsg_english_name}
                            </span>
                          </h5>
                          {familyDetail?.year_of_residence && (
                            <h5 className="text-white m-0 housename">
                              {changeYearsTillDate(
                                familyDetail?.year_of_residence
                              )}{" "}
                              yrs
                            </h5>
                          )}
                        </div>
                      </div>
                      <CardBody
                        style={{
                          backgroundColor: "#f4fff4",
                          borderRadius: "0 0 10px 10px",
                        }}
                      >
                        <Row>
                          <Col md={6}>
                            {familyDetail?.family_head && (
                              <h5 className="fs-5 text-black ">
                                {language === "mal"
                                  ? "കുടുംബനാഥൻ : "
                                  : "Family Head : "}{" "}
                                <span className="text-khaf">
                                  {familyDetail?.family_head ? (
                                    <Link
                                      className="text-khaf"
                                      to={`/familymembers/${familyDetail?.family_head}`}
                                    >
                                      {familyDetail?.family_head_name}
                                    </Link>
                                  ) : (
                                    familyDetail?.family_head_name
                                  )}
                                </span>
                              </h5>
                            )}

                            <div
                              className={`d-flex ${
                                familyDetail?.family_head_name ? "mt-4" : ""
                              }`}
                              style={{ paddingRight: "8rem" }}
                            >
                              <div
                                className="d-flex"
                                style={{ width: "160px" }}
                              >
                                <img
                                  src={men}
                                  alt={men}
                                  className="me-2"
                                  style={{ height: "17px", width: "17px" }}
                                />
                                <p className="fs-5 text-black">
                                  {language === "mal" ? "ആൺ" : "Male"}
                                </p>
                              </div>

                              <div>
                                <span className="fs-5 text-black">
                                  : {familyDetail?.no_of_males || 0}
                                </span>
                              </div>
                            </div>

                            <div
                              className="d-flex mt-1"
                              style={{ paddingRight: "8rem" }}
                            >
                              <div
                                className="d-flex"
                                style={{ width: "160px" }}
                              >
                                <img
                                  src={women}
                                  alt={women}
                                  className="me-2"
                                  style={{ height: "17px", width: "17px" }}
                                />
                                <p className="fs-5 text-black">
                                  {" "}
                                  {language === "mal" ? "സ്ത്രീ" : "Female"}
                                </p>
                              </div>

                              <div>
                                <span className="fs-5 text-black">
                                  : {familyDetail?.no_of_females || 0}
                                </span>
                              </div>
                            </div>

                            <div
                              className="d-flex mt-1"
                              style={{ paddingRight: "8rem" }}
                            >
                              <div
                                className="d-flex"
                                style={{ width: "160px" }}
                              >
                                <img
                                  src={family}
                                  alt={family}
                                  className="me-2"
                                  style={{ height: "17px", width: "17px" }}
                                />
                                <p className="fs-5 text-black">
                                  {" "}
                                  {language === "mal" ? "അംഗങ്ങൾ" : "Members"}
                                </p>
                              </div>

                              <div>
                                <span className="fs-5 text-black">
                                  : {familyDetail?.no_of_members || 0}
                                </span>
                              </div>
                            </div>

                            <div
                              className="d-flex mt-1"
                              style={{ paddingRight: "8rem" }}
                            >
                              <div
                                className="d-flex"
                                style={{ width: "160px" }}
                              >
                                <img
                                  src={seniorCitizen}
                                  alt={seniorCitizen}
                                  className="me-2"
                                  style={{ height: "17px", width: "17px" }}
                                />
                                <p className="fs-5 text-black">
                                  {language === "mal"
                                    ? "മുതിർന്ന പൗരൻ"
                                    : "Senior Citizen"}
                                </p>
                              </div>

                              <div>
                                <span className="fs-5 text-black">
                                  : {familyDetail?.no_of_senior_citizens || 0}
                                </span>
                              </div>
                            </div>

                            <div
                              className="d-flex mt-1"
                              style={{ paddingRight: "8rem" }}
                            >
                              <div
                                className="d-flex"
                                style={{ width: "160px" }}
                              >
                                <img
                                  src={patient}
                                  alt={patient}
                                  className="me-2"
                                  style={{ height: "17px", width: "17px" }}
                                />
                                <p className="fs-5 text-black">
                                  {language === "mal" ? "രോഗികൾ" : "Patients"}
                                </p>
                              </div>

                              <div>
                                <span className="fs-5 text-black">
                                  : {familyDetail?.no_of_patients || 0}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            {familyDetail?.house_image ? (
                              <img
                                style={{
                                  width: "100%",
                                  height: "270px",
                                  objectFit: "contain",
                                }}
                                src={familyDetail?.house_image}
                                alt="house-image"
                              />
                            ) : (
                              `${
                                language === "mal"
                                  ? "ഫോട്ടോ ചേർത്തിട്ടില്ല"
                                  : "No image added"
                              }`
                            )}
                          </Col>
                        </Row>
                        <p
                          style={{
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          className="d-flex justify-content-end text-primary pt-1"
                          onClick={() => familyIdQr(familyDetail?.id)}
                        >
                          {" "}
                          {language === "mal"
                            ? "<> QR കോഡ് കാണുക"
                            : "<> View QR Code"}
                        </p>
                        <Card
                          className="sm:p-0 mt-4"
                          style={{ position: "relative" }}
                        >
                          <Row>
                            <Col md={6}>
                              <div
                                className="d-flex flex-column mt-3 justify-content-between"
                                style={{ paddingLeft: "16px" }}
                              >
                                <div className="mb-4">
                                  <h5 className="text-khaf">
                                    {" "}
                                    {language === "mal" ? "വിലാസം" : "Address"}
                                  </h5>
                                  <p className="text-black m-0 fs-5">
                                    {familyDetail?.place},{" "}
                                    {familyDetail?.post_office} Post -
                                    {familyDetail?.pin_code},{" "}
                                    {language === "mal"
                                      ? familyDetail?.district_malayalam_name
                                      : familyDetail?.district_english_name}
                                    ,{" "}
                                    {language === "mal"
                                      ? familyDetail?.state_malayalam_name
                                      : familyDetail?.state_english_name}
                                  </p>
                                </div>
                                <div className="mb-4">
                                  <h5 className="text-khaf">
                                    {language === "mal"
                                      ? "മൊബൈൽ നമ്പർ"
                                      : "Mobile Number"}
                                  </h5>
                                  <p className="text-black m-0 fs-5">
                                    {familyDetail?.contact_number || ""}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            {familyDetail?.varisankya_amount &&
                              familyDetail?.varisankya_amount !== "0.00" && (
                                <Col md={6}>
                                  <span
                                    className="spandemmy"
                                    style={{
                                      height: "120px",
                                      width: "2px",
                                      backgroundColor: "#f2f1f1",
                                      display: "inline-block",
                                      position: "absolute",
                                      borderRadius: "10px",
                                      top: "15px",
                                    }}
                                  ></span>
                                  <div
                                    className="d-flex flex-column justify-content-between varisnkya"
                                    style={{ paddingLeft: "10rem" }}
                                  >
                                    {familyDetail?.varisankya_amount && (
                                      <div
                                        className="d-flex"
                                        style={{ paddingLeft: "15px" }}
                                      >
                                        <h5 className="text-black mt-1 varisangyadiv">
                                          {language === "mal"
                                            ? "വരിസംഖ്യ തുക :"
                                            : "Varisangya Amount "}
                                        </h5>
                                        <p className="fs-5">
                                          <span className="mx-2">:</span>
                                          <span className="text-khaf fw-bolder">
                                            {familyDetail?.varisankya_amount}
                                          </span>{" "}
                                        </p>
                                      </div>
                                    )}
                                    {familyDetail?.varisankya_wallet && (
                                      <div
                                        className="d-flex"
                                        style={{ paddingLeft: "15px" }}
                                      >
                                        <h5 className="text-black varisangyadiv">
                                          {language === "mal"
                                            ? "വാലറ്റ് ബാലൻസ് : "
                                            : "Wallet Balance  "}
                                        </h5>
                                        <p className="fs-5">
                                          <span className="mx-2">:</span>
                                          <span className="text-danger fw-bolder">
                                            {familyDetail?.varisankya_wallet}
                                          </span>{" "}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              )}
                          </Row>
                        </Card>
                        <Row>
                          <Col lg={6}>
                            <Card className="sm:p-0">
                              <div className="accordion" id="accordion">
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingOne"
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-between py-1"
                                      style={{
                                        borderBottom: `${
                                          col2 ? "1px solid green" : "none"
                                        }`,
                                      }}
                                    >
                                      <div
                                        className="d-flex"
                                        style={{ marginLeft: "12px" }}
                                      >
                                        <img
                                          src={land}
                                          alt={land}
                                          className="me-2"
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                        />
                                        <h5
                                          className=" mb-0 text-black "
                                          style={{ marginLeft: "2px" }}
                                        >
                                          {language === "mal"
                                            ? "ആസ്തി വിവരം (സെന്റിൽ) :"
                                            : "Land Area (In Cents)"}
                                        </h5>
                                      </div>
                                      <button
                                        className="border-0 bg-white text-khaf "
                                        type="button"
                                        onClick={() => setCol2(!col2)}
                                        style={{
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                          boxShadow: "none",
                                        }}
                                      >
                                        {" "}
                                        <i
                                          className={`${
                                            col2 ? "bx bx-minus" : "bx bx-plus"
                                          }`}
                                        />
                                      </button>
                                    </div>
                                  </h2>

                                  <Collapse
                                    isOpen={col2}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <div className="d-flex flex-column justify-content-between ">
                                        <div className="d-flex">
                                          <h5 className="text-black mt-1 cardleft">
                                            {language === "mal"
                                              ? "വീട്"
                                              : "Home"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black ">
                                              {familyDetail?.land_area_home ||
                                                0}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "കൃഷി"
                                              : "Farm"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {familyDetail?.land_area_farm ||
                                                0}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "വാടക"
                                              : "Rent"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {familyDetail?.land_area_rent ||
                                                0}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "മറ്റുള്ളവ"
                                              : "Others"}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {familyDetail?.land_area_others ||
                                                0}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="text-black fw-bold cardleft">
                                            {language === "mal"
                                              ? "ആകെ ആസ്തി"
                                              : "Total Area"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black fw-bold">
                                              {familyDetail?.total_land_area ||
                                                0}
                                            </span>{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                            </Card>
                          </Col>
                          <Col lg={6}>
                            <Card className="sm:p-0">
                              <div className="accordion" id="accordion">
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingOne"
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-between py-1"
                                      style={{
                                        borderBottom: `${
                                          col3 ? "1px solid green" : "none"
                                        }`,
                                      }}
                                    >
                                      <div
                                        className="d-flex"
                                        style={{ marginLeft: "12px" }}
                                      >
                                        <img
                                          src={car}
                                          alt={car}
                                          className="me-2"
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                        />
                                        <h5
                                          className=" mb-0 text-black "
                                          style={{ marginLeft: "2px" }}
                                        >
                                          {language === "mal"
                                            ? "വാഹനങ്ങൾ :"
                                            : "Vehicles "}
                                        </h5>
                                      </div>
                                      <button
                                        className="border-0 bg-white text-khaf "
                                        type="button"
                                        onClick={() => setCol3(!col3)}
                                        style={{
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                          boxShadow: "none",
                                        }}
                                      >
                                        {" "}
                                        <i
                                          className={`${
                                            col3 ? "bx bx-minus" : "bx bx-plus"
                                          }`}
                                        />
                                      </button>
                                    </div>
                                  </h2>

                                  <Collapse
                                    isOpen={col3}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <div className="d-flex flex-column justify-content-between ">
                                        <div className="d-flex ">
                                          <h5 className="text-black mt-1 cardleft">
                                            {language === "mal"
                                              ? "2 വീലറുകൾ"
                                              : "2 Wheelers"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black ">
                                              {familyDetail?.two_wheeler_vehicles ||
                                                0}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex ">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "3 വീലറുകൾ"
                                              : "3 Wheelers"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {familyDetail?.three_wheeler_vehicles ||
                                                0}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex ">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "4 വീലറുകൾ"
                                              : "4 Wheelers"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {familyDetail?.four_wheeler_vehicles ||
                                                0}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex ">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "ടാക്സികൾ"
                                              : "Taxi Vehicles"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {familyDetail?.taxi_vehicles || 0}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="text-black fw-bold cardleft">
                                            {language === "mal"
                                              ? "ആകെ വാഹനങ്ങൾ"
                                              : "Total Vehicles"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black fw-bold">
                                              {familyDetail?.total_vehicles ||
                                                0}
                                            </span>{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <Card className="sm:p-0">
                              <div className="accordion" id="accordion">
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingOne"
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-between py-1"
                                      style={{
                                        borderBottom: `${
                                          col1 ? "1px solid green" : "none"
                                        }`,
                                      }}
                                    >
                                      <div
                                        className="d-flex"
                                        style={{ marginLeft: "12px" }}
                                      >
                                        <img
                                          src={facility}
                                          alt={facility}
                                          className="me-2"
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                        />
                                        <h5
                                          className=" mb-0 text-black"
                                          style={{ marginLeft: "2px" }}
                                        >
                                          {language === "mal"
                                            ? "സൗകര്യങ്ങൾ :"
                                            : "Facilities "}
                                        </h5>
                                      </div>
                                      <button
                                        className="border-0 bg-white text-khaf "
                                        type="button"
                                        onClick={() => setCol1(!col1)}
                                        style={{
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                          boxShadow: "none",
                                        }}
                                      >
                                        {" "}
                                        <i
                                          className={`${
                                            col1 ? "bx bx-minus" : "bx bx-plus"
                                          }`}
                                        />
                                      </button>
                                    </div>
                                  </h2>

                                  <Collapse
                                    isOpen={col1}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <div className="d-flex flex-column justify-content-between ">
                                        <div className="d-flex">
                                          <h5 className="text-black mt-1 cardleft">
                                            {language === "mal"
                                              ? "വെള്ളം"
                                              : "Water"}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black ">
                                              {" "}
                                              {language === "mal"
                                                ? familyDetail?.water_facility
                                                    ?.malayalam
                                                : familyDetail?.water_facility
                                                    ?.english || ""}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "വൈദ്യുതി"
                                              : "Electricity"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {" "}
                                              {language === "mal"
                                                ? familyDetail
                                                    ?.electric_facility
                                                    ?.malayalam
                                                : familyDetail
                                                    ?.electric_facility
                                                    ?.english || ""}
                                            </span>{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                            </Card>
                          </Col>
                          <Col lg={6}>
                            <Card className="sm:p-0">
                              <div className="accordion" id="accordion">
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingOne"
                                  >
                                    <div
                                      className="d-flex align-items-center justify-content-between py-1"
                                      style={{
                                        borderBottom: `${
                                          col4 ? "1px solid green" : "none"
                                        }`,
                                      }}
                                    >
                                      <div
                                        className="d-flex"
                                        style={{ marginLeft: "12px" }}
                                      >
                                        <img
                                          src={other}
                                          alt={other}
                                          className="me-2"
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                        />
                                        <h5
                                          className=" mb-0 text-black "
                                          style={{ marginLeft: "2px" }}
                                        >
                                          {language === "mal"
                                            ? "മറ്റ് വിശദാംശങ്ങൾ :"
                                            : "Other Details "}
                                        </h5>
                                      </div>
                                      <button
                                        className="border-0 bg-white text-khaf "
                                        type="button"
                                        onClick={() => setCol4(!col4)}
                                        style={{
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                          boxShadow: "none",
                                        }}
                                      >
                                        {" "}
                                        <i
                                          className={`${
                                            col4 ? "bx bx-minus" : "bx bx-plus"
                                          }`}
                                        />
                                      </button>
                                    </div>
                                  </h2>

                                  <Collapse
                                    isOpen={col4}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <div className="d-flex flex-column justify-content-between ">
                                        <div className="d-flex">
                                          <h5 className="text-black mt-1 cardleft">
                                            {language === "mal"
                                              ? "വീട്"
                                              : "House Type"}{" "}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black ">
                                              {language === "mal"
                                                ? familyDetail?.house_type
                                                    ?.malayalam
                                                : familyDetail?.house_type
                                                    ?.english}{" "}
                                              /{" "}
                                              {language === "mal"
                                                ? familyDetail?.house_build_type
                                                    ?.malayalam
                                                : familyDetail?.house_build_type
                                                    ?.english}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "റേഷൻ കാർഡ്"
                                              : "Ration Card"}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {language === "mal"
                                                ? familyDetail?.ration_card
                                                    ?.malayalam
                                                : familyDetail?.ration_card
                                                    ?.english}{" "}
                                              /{" "}
                                              {familyDetail?.ration_card_number}
                                            </span>{" "}
                                          </p>
                                        </div>

                                        <div className="d-flex">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "സാമ്പത്തിക സ്ഥിതി"
                                              : "Financial Status"}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {language === "mal"
                                                ? familyDetail?.financial_status
                                                    ?.malayalam
                                                : familyDetail?.financial_status
                                                    ?.english}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "വാർഷിക വരുമാനം"
                                              : "Annual Income"}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {
                                                familyDetail?.annual_income
                                                  ?.english
                                              }
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "ബാങ്ക് വായ്പ"
                                              : "Bank Loan"}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {familyDetail?.bank_loan?.map(
                                                (item, key) => {
                                                  return (
                                                    <Fragment key={key}>{`${
                                                      key != 0 ? "," : ""
                                                    } ${
                                                      language === "mal"
                                                        ? item.malayalam
                                                        : item.english
                                                    } `}</Fragment>
                                                  )
                                                }
                                              )}
                                              {familyDetail?.bank_loan_details
                                                ? `(${familyDetail?.bank_loan_details})`
                                                : ""}
                                            </span>{" "}
                                          </p>
                                        </div>
                                        <div className="d-flex">
                                          <h5 className="text-black cardleft">
                                            {language === "mal"
                                              ? "ഹോം ബിസിനസ്സ്"
                                              : "Home Business"}
                                          </h5>
                                          <p className="fs-5">
                                            <span className="me-2">:</span>
                                            <span className="text-black">
                                              {familyDetail?.home_business?.map(
                                                (item, key) => {
                                                  return (
                                                    <Fragment key={key}>{`${
                                                      key != 0 ? "," : ""
                                                    } ${
                                                      language === "mal"
                                                        ? item.malayalam
                                                        : item.english
                                                    } `}</Fragment>
                                                  )
                                                }
                                              )}
                                              {familyDetail?.home_business_details
                                                ? `(${familyDetail?.home_business_details})`
                                                : ""}
                                            </span>{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Row className="d-flex align-items-center">
                            <Col md="4">
                              <div className="d-flex flex-wrap ">
                                <div className="d-flex">
                                  <p className="text-khaf fw-bold fs-5">
                                    {language === "mal"
                                      ? "മഹല്ലിൽ നിന്നുള്ള സഹായം "
                                      : "Help From Mahall "}
                                  </p>
                                </div>

                                <div className="mx-2">
                                  <span className="text-black fw-bold fs-5">
                                    :{" "}
                                    {familyDetail?.help_receiving_from_mahall
                                      ? language === "mal"
                                        ? "ഉണ്ട്"
                                        : "Yes"
                                      : language === "mal"
                                      ? "ഇല്ല"
                                      : "No"}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            {familyDetail &&
                              familyDetail?.help_receiving_from_mahall &&
                              familyDetail?.help_receiving_from_mahall_details !==
                                null && (
                                <Col md={8}>
                                  <Card className="mt-1">
                                    <h5 className="text-muted px-3 py-3 m-0">
                                      {familyDetail?.help_receiving_from_mahall
                                        ? `${
                                            language === "mal"
                                              ? "വിശദാംശങ്ങൾ :"
                                              : "Details :"
                                          } ${
                                            familyDetail?.help_receiving_from_mahall_details
                                          }`
                                        : ""}
                                    </h5>
                                  </Card>
                                </Col>
                              )}
                          </Row>
                          <Row className="d-flex align-items-center">
                            <Col md="4">
                              <div className="d-flex flex-wrap ">
                                <div className="d-flex">
                                  <p className="text-khaf fw-bold fs-5">
                                    {language === "mal"
                                      ? "മറ്റുള്ളവരിൽ നിന്നുള്ള സഹായം "
                                      : "Help From Others "}
                                  </p>
                                </div>

                                <div className="mx-2">
                                  <span className="text-black fw-bold fs-5">
                                    :{" "}
                                    {familyDetail?.help_receiving_from_others
                                      ? language === "mal"
                                        ? "ഉണ്ട്"
                                        : "Yes"
                                      : language === "mal"
                                      ? "ഇല്ല"
                                      : "No"}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            {familyDetail &&
                              familyDetail?.help_receiving_from_others &&
                              familyDetail?.help_receiving_from_others_details !==
                                null && (
                                <Col md={8}>
                                  <Card className="mt-1">
                                    <h5 className="text-muted px-3 py-3 m-0">
                                      {familyDetail?.help_receiving_from_others
                                        ? `${
                                            language === "mal"
                                              ? "വിശദാംശങ്ങൾ :"
                                              : "Details :"
                                          } ${
                                            familyDetail?.help_receiving_from_others_details
                                          }`
                                        : ""}
                                    </h5>
                                  </Card>
                                </Col>
                              )}
                          </Row>
                          <Row className="d-flex align-items-center">
                            <Col md={4}>
                              <div className="d-flex flex-wrap">
                                <div className="d-flex zakat-div">
                                  <p className="text-khaf fw-bold fs-5">
                                    {language === "mal"
                                      ? "സകാത്തിനുള്ള അർഹത :"
                                      : "Zakat Eligible "}
                                  </p>
                                </div>

                                <div className="mx-2">
                                  <span className="text-black fw-bold fs-5">
                                    :{" "}
                                    {familyDetail?.is_zakat_eligible
                                      ? language === "mal"
                                        ? "ഉണ്ട്"
                                        : "Yes"
                                      : language === "mal"
                                      ? "ഇല്ല"
                                      : "No"}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            {familyDetail &&
                              familyDetail?.is_zakat_eligible &&
                              familyDetail?.zakat_eligibility_reason !==
                                null && (
                                <Col md={8}>
                                  <Card className="mt-1">
                                    <h5 className="text-muted  px-3 py-3 m-0">
                                      {familyDetail?.is_zakat_eligible
                                        ? `${
                                            language === "mal"
                                              ? "കാരണം :"
                                              : "Reason :"
                                          } ${
                                            familyDetail?.zakat_eligibility_reason ===
                                            null
                                              ? ""
                                              : familyDetail?.zakat_eligibility_reason
                                          }`
                                        : ""}
                                    </h5>
                                  </Card>
                                </Col>
                              )}
                          </Row>
                        </Row>
                        <Row className="mt-3">
                          <Col md="6">
                            <div className="d-flex align-items-end mb-3">
                              <p className="text-black fs-6 m-0">
                                Listed By :{" "}
                                <span className="text-black px-1">
                                  {familyDetail?.creator_name}
                                </span>
                              </p>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="d-flex justify-content-end">
                              <Link to={`/family/update/${familyDetail?.id}`}>
                                <Button className="me-2 bg-khaf border-0">
                                  {language === "mal" ? "പുതുക്കുക" : "Edit"}
                                </Button>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                )}
              </Col>
            </Row>

            <Row>
              <div className="col-md-6 sm:p-0">
                <Link
                  to={`/familymember/create`}
                  type="submit"
                  style={{ width: "200px" }}
                  className="w-md btn btn-success d-block"
                >
                  {language === "mal"
                    ? "+ പുതിയ കുടുംബാംഗം"
                    : "+ New Family Member"}
                </Link>
              </div>
            </Row>
            <Row>
              <Col className="col-12 mt-2 sm:p-0">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={familyMembersData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e =>
                                      debounceMahallFamilyFamilyMemberSearch(
                                        e.target.value
                                      )
                                    }
                                  >
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        defaultValue={searchText}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {loading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : (
                            <>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table align-middle table-wrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                              />
                            </>
                          )}
                          {!loading &&
                            familyMembers &&
                            familyMembers?.results &&
                            familyMembers?.results?.length <= 0 && (
                              <p
                                style={{ fontSize: "15px" }}
                                className="text-center text-info"
                              >
                                {language === "mal"
                                  ? "അംഗങ്ങൾ ഒന്നും ഇല്ല"
                                  : "No Members Yet"}
                              </p>
                            )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default SurveyUserFamilyDetails

SurveyUserFamilyDetails.propTypes = {
  history: PropTypes.object,
}
