import React from "react"
import { Image } from "react-bootstrap"
import { CardBody, Col, Container, Media, Row } from "reactstrap"

import img from "assets/images/khaf/khaf-screens.png"

const ImageCards = () => {
  return (
    <React.Fragment>
      <section className="section position-absolut p-0 modal_sectio mt-5 sm:mt-0">
        <Container>
          <div className="currency-price">
            <Row className="sm:flex-col">
              <Col lg="12" sm="12" className="firstDiv">
                <CardBody id="firstImage" className="mt-2 p-0">
                  <Media>
                    <Media body>
                      <Image
                        src={img}
                        alt=""
                        className="w-100 h-100"
                        style={{ objectFit: "contain" }}
                      />
                    </Media>
                  </Media>
                </CardBody>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default ImageCards
