import React from "react"
import { Card, CardBody, Container, Row } from "reactstrap"

function AppDownloadSection() {
  return (
    <div>
      {/* <Container
        style={{ filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))" }}
      > */}
      <div className=" card">
        <Row className=" w-100 m-0">
          <div className="py-5">
            <div className="">
              {" "}
              <p className="d-flex align-items-center justify-content-center h2">
                Explore More with our App
              </p>
            </div>
          </div>
        </Row>
      </div>

      {/* </Container> */}
    </div>
  )
}

export default AppDownloadSection
