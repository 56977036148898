import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  createSupportTicketItem,
  getSupportTicketDetails,
  getSupportTicketItem,
  updateSupportTicket,
} from "store/actions"
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min"
import Select from "react-select"
import { map } from "lodash"
import moment from "moment"
import { errorNotification } from "components/Common/Notification"

function SupportDetails() {
  const dispatch = useDispatch()
  const params = useParams()

  const { language, supportDetails, supportTicketItems, loading } = useSelector(
    state => ({
      language: state.Layout.language,
      loading: state.SupportTickets.loading,
      supportDetails: state?.SupportTickets?.supportDetails,
      supportTicketItems: state?.SupportTickets?.supportTicketItem,
    })
  )

  const [supportStatus, setSupportStatus] = useState("")
  const [customMessage, setCustomMessage] = useState()

  useEffect(() => {
    dispatch(getSupportTicketDetails(params?.id))
  }, [dispatch, params?.id])

  console.log(supportDetails)

  function handlerFinalValue(event) {
    setSupportStatus(event.label)
    const finalValue = {
      status: event?.value,
    }
    dispatch(updateSupportTicket(params?.id, finalValue))
  }

  useEffect(() => {
    setSupportStatus(supportDetails?.status)
  }, [supportDetails])

  useEffect(() => {
    dispatch(getSupportTicketItem(params?.id))
  }, [dispatch, params?.id])

  const addMessage = () => {
    console.log(customMessage)
    const message = {
      attachment: imageUpload ? imageUpload : null,
      message: customMessage,
      id: params?.id,
      messageSendSuccessWindowRefresh,
    }
    dispatch(createSupportTicketItem(message))
  }

  const messageSendSuccessWindowRefresh = () => {
    window.location.reload()
  }

  console.log(customMessage)

  const moment = require("moment")
  const dateToFormat = new Date(supportDetails?.date_added)
  const isToday = moment(dateToFormat).isSame(moment(), "day")
  const formattedDate = isToday
    ? "Today"
    : moment(dateToFormat).format("MMMM D, YYYY")

  const statusWidth = () => {
    if (window.screen.width >= 414) {
      return "20%"
    } else {
      return "100%"
    }
  }

  const chatHeight = () => {
    if (window.screen.width >= 414) {
      return 500
    } else {
      return 300
    }
  }

  const [imageUpload, setImageUpload] = useState("")
  const [imagePreview, setImagePreview] = useState()

  console.log(imageUpload)

  const handleImageChange = e => {
    const fileName = Array.from(e.target.files)
    const name =
      fileName[0]?.name.length <= 100
        ? fileName[0]?.name
        : errorNotification("Ensure this filename has at most 100 characters ")
    setImageUpload({
      name: name,
      size: fileName[0]?.size,
      image: e.target.files[0],
    })
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <div>
      <div className="page-content">
        <Breadcrumbs title="Support Ticket Details" breadcrumbItem=" Details" />
        <Container fluid>
          <div className="container-fluid p-2 ">
            <div className=" chat-heading d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                &nbsp; #{supportDetails?.auto_id} &nbsp;
                <span className="font-size-22">
                  &nbsp;{supportDetails?.subject}{" "}
                </span>
              </div>
              <div className={` px-3`} style={{ width: statusWidth() }}>
                <Select
                  placeholder={supportStatus}
                  value={supportStatus}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                  onChange={handlerFinalValue}
                  type="text"
                  title="Status"
                />
              </div>
            </div>

            <div>
              <div
                className="chat-conversation p-3 "
                style={{
                  height: chatHeight(),
                  overflow: "overlay",
                }}
              >
                <ul className="list-unstyled">
                  <li>
                    <div className="chat-day-title">
                      <span className="title">{formattedDate}</span>
                    </div>
                  </li>
                  {supportTicketItems &&
                    map(supportTicketItems, message => (
                      <li className={message?.is_admin === true ? "right" : ""}>
                        <div className="conversation-list">
                          <div className="ctext-wrap">
                            <div className="conversation-name">
                              {message?.creator_name}
                              <p>
                                {message?.attachment ? (
                                  <img
                                    src={message?.attachment}
                                    width={250}
                                    height={100}
                                    style={{ objectFit: "cover" }}
                                  />
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            <p className="font-size-15">{message?.message}</p>
                            <p className="chat-time mb-0">
                              <i className="bx bx-time-five align-middle me-1" />
                              {moment(message?.date_added).format(
                                "DD-MM-YY hh:mm"
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              {imagePreview ? (
                <img
                  src={imagePreview?.image}
                  style={{
                    height: 200,
                    maxWidth: 400,
                    objectFit: "cover",
                    border: "none",
                    borderRadius: 5,
                  }}
                />
              ) : (
                ""
              )}

              <div className="p-3 chat-input-section">
                <Row>
                  <Col>
                    <div className="position-relative">
                      <input
                        type="text"
                        value={customMessage}
                        // onKeyPress={onKeyPress}
                        onChange={e => setCustomMessage(e.target.value)}
                        className="form-control chat-input"
                        placeholder="Enter Message..."
                      />
                      <div className="chat-input-links">
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <div onChange={e => handleImageChange(e)}>
                              <i
                                style={{
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                                className="mdi mdi-file-document-outline font-size-18"
                                id="Filetooltip"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="Filetooltip"
                              >
                                Add Files
                              </UncontrolledTooltip>
                              <input
                                className=""
                                style={{
                                  opacity: "0.0",
                                  position: "absolute",
                                  left: -70,
                                  cursor: "pointer",
                                }}
                                type="file"
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>

                  <Col className="col-auto">
                    <Button
                      type="button"
                      color="primary"
                      onClick={e => addMessage()}
                      className="btn btn-primary btn-rounded chat-send w-md "
                    >
                      <span className="d-none d-sm-inline-block me-2">
                        {loading && (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        )}{" "}
                        Send
                      </span>{" "}
                      <i className="mdi mdi-send" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default SupportDetails

const optionGroup = [
  { label: "Pending", value: "pending" },
  { label: "Proccessing", value: "proccessing" },
  { label: "Closed", value: "closed" },
]
