import React from "react"
import { Button, Col, Row } from "reactstrap"
import donation from "assets/images/donation.png"
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useLanguage } from "../Language/SwitchLanguage"

function Donation() {
  const location = useLocation()

  console.log(location)

  return (
    <div>
      <div className="p-2 " style={{ overflow: "hidden" }}>
        <Row>
          <Col md={6} lg={6} sm={12} className="">
            <div>
              <img
                src={donation}
                height={""}
                width={"100%"}
                style={{ borderRadius: 5 }}
              />
            </div>
          </Col>
          <Col
            md={6}
            lg={6}
            sm={12}
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              {location?.pathname === "/home/en" ? (
                <h1 className="font-weight-600">
                  Donate to Mahal <br />
                  Empowerment Committee
                </h1>
              ) : (
                <h1 className="font-weight-600">
                  മഹല്ല് ശാക്തീകരണ <br />
                  സമിതിയിലേക്ക് സംഭാവന ചെയ്യുക.
                </h1>
              )}
              <Link to={"/transactions"}>
                <Button
                  size="md"
                  color="success"
                  className="px-3"
                  style={{ borderRadius: 15 }}
                >
                  {location?.pathname === "/home/en"
                    ? "DONATE NOW"
                    : " സംഭാവനചെയ്യുക"}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Donation
