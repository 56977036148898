import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_STATES,
  GET_STATE_DETAIL,
  CREATE_STATE,
  UPDATE_STATE,
  DELETE_STATE,
} from "./actionTypes"
import {
  getStatesSuccess,
  getStatesFail,
  getStateDetailSuccess,
  getStateDetailFail,
  createStateSuccess,
  createStateFail,
  updateStateSuccess,
  updateStateFail,
  deleteStateSuccess,
  deleteStateFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getStatesAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/state/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/mahall/state/?page=${page ? page : 1}`)
  }
}
const getStateDetailsAPi = stateId => {
  return get(`/api/v1/mahall/state/${stateId}/`)
}
const createStateApi = ({ state }) => {
  return post("/api/v1/mahall/state/", state)
}
const updateStateApi = ({ stateId, state }) => {
  return ApiPut(`/api/v1/mahall/state/${stateId}/`, state)
}
const deleteStateApi = ({ stateId }) => {
  return del(`/api/v1/mahall/state/${stateId}/`)
}

function* fetchStates({ payload }) {
  try {
    const response = yield call(getStatesAPi, payload)
    if (response && !response?.error) {
      yield put(getStatesSuccess(response))
    } else {
      yield put(getStatesFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStatesFail(error))
  }
}

function* fetchStateDetail({ stateId }) {
  try {
    const response = yield call(getStateDetailsAPi, stateId)
    if (response && !response?.error) {
      yield put(getStateDetailSuccess(response))
    } else {
      yield put(getStateDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStateDetailFail(error))
  }
}
function* onCreateState({ payload }) {
  try {
    const response = yield call(createStateApi, payload)
    if (response && !response?.error) {
      yield put(createStateSuccess(response))
      payload.history.push("/states")
      doneNotification("Created Successfully")
    } else {
      yield put(createStateFail(response))
      errorNotification()
    }
    //get states
    // try {
    //   const response = yield call(getStatesAPi, payload)
    //   yield put(getStatesSuccess(response))
    // } catch (error) {
    //   yield put(getStatesFail(error))
    // }
  } catch (error) {
    // console.log('error: ', error);
    yield put(createStateFail(error))
    errorNotification()
  }
}

function* onUpdateState({ payload }) {
  try {
    const response = yield call(updateStateApi, payload)
    if (response && !response?.error) {
      payload.history.push("/states")
      yield put(updateStateSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateStateFail(response))
      errorNotification()
    }
  } catch (error) {
    // console.log('error: ', error);
    yield put(updateStateFail(error))
    errorNotification()
  }
}

function* onDeleteState({ payload }) {
  try {
    const response = yield call(deleteStateApi, payload)
    if (response && !response?.error) {
      // payload.history.push("/states")
      yield put(deleteStateSuccess(response))
      doneNotification("Deleted Successfully")
      //get states
      try {
        const response = yield call(getStatesAPi, payload)
        yield put(getStatesSuccess(response))
      } catch (error) {
        yield put(getStatesFail(error))
      }
    } else {
      yield put(deleteStateFail(response))
      errorNotification()
    }
  } catch (error) {
    // console.log('error: ', error);
    errorNotification()
    yield put(deleteStateFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* StatesSaga() {
  yield takeEvery(GET_STATES, fetchStates)
  yield takeEvery(GET_STATE_DETAIL, fetchStateDetail)
  yield takeEvery(CREATE_STATE, onCreateState)
  yield takeEvery(UPDATE_STATE, onUpdateState)
  yield takeEvery(DELETE_STATE, onDeleteState)
}

export default StatesSaga
