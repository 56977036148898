import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_DIVISIONS,
  GET_DIVISION_DETAIL,
  CREATE_DIVISION,
  UPDATE_DIVISION,
  DELETE_DIVISION,
  DIVISION_DROPDOWN,
} from "./actionTypes"
import {
  getDivisionsSuccess,
  getDivisionsFail,
  getDivisionDetailSuccess,
  getDivisionDetailFail,
  createDivisionSuccess,
  createDivisionFail,
  updateDivisionSuccess,
  updateDivisionFail,
  deleteDivisionSuccess,
  deleteDivisionFail,
  divisionDropdownSuccess,
  divisionDropdownFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getDivisionsAPi = ({ classId, searchText, page }) => {
  if (classId) {
    return get(
      `/api/v1/madrasa/madrasa-division/?madrasa_class=${
        classId ? classId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/madrasa/madrasa-division/?search=${
        searchText ? searchText : ""
      }&page=${page ? page : 1}`
    )
  }
}
const divisionDropdownAPi = ({ searchText, classId }) => {
  return get(
    `/api/v1/madrasa/madrasa-division-dropdown/?madrasa_class=${
      classId ? classId : ""
    }&search=${searchText ? searchText : ""}`
  )
}
const getDivisionDetailsAPi = divisionId => {
  return get(`/api/v1/madrasa/madrasa-division/${divisionId}/`)
}
const createDivisionApi = ({ newDivision }) => {
  return post("/api/v1/madrasa/madrasa-division/", newDivision)
}
const updateDivisionApi = ({ divisionId, updatedDivision }) => {
  return ApiPut(
    `/api/v1/madrasa/madrasa-division/${divisionId}/`,
    updatedDivision
  )
}
const deleteDivisionApi = ({ divisionId }) => {
  return del(`/api/v1/madrasa/madrasa-division/${divisionId}/`)
}

function* fetchDivisions({ payload }) {
  try {
    const response = yield call(getDivisionsAPi, payload)
    if (response && !response?.error) {
      yield put(getDivisionsSuccess(response))
    } else {
      yield put(getDivisionsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getDivisionsFail(error))
  }
}

function* fetchDivisionDropdown({ payload }) {
  try {
    const response = yield call(divisionDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(divisionDropdownSuccess(response))
    } else {
      yield put(divisionDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(divisionDropdownFail(error))
  }
}

function* fetchDivisionDetail({ divisionId }) {
  try {
    const response = yield call(getDivisionDetailsAPi, divisionId)
    if (response && !response?.error) {
      yield put(getDivisionDetailSuccess(response))
    } else {
      yield put(getDivisionDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getDivisionDetailFail(error))
  }
}
function* onCreateDivision({ payload }) {
  try {
    const response = yield call(createDivisionApi, payload)
    if (response && !response?.error) {
      yield put(createDivisionSuccess(response))
      doneNotification("Created Successfully")
    } else {
      yield put(createDivisionFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createDivisionFail(error))
    errorNotification()
  }
}

function* onUpdateDivision({ payload }) {
  try {
    const response = yield call(updateDivisionApi, payload)
    if (response && !response?.error) {
      yield put(updateDivisionSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateDivisionFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateDivisionFail(error))
    errorNotification()
  }
}

function* onDeleteDivision({ payload }) {
  try {
    const response = yield call(deleteDivisionApi, payload)
    if (response && !response?.error) {
      yield put(deleteDivisionSuccess(payload?.divisionId))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteDivisionFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteDivisionFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* DivisionsSaga() {
  yield takeEvery(GET_DIVISIONS, fetchDivisions)
  yield takeEvery(DIVISION_DROPDOWN, fetchDivisionDropdown)
  yield takeEvery(GET_DIVISION_DETAIL, fetchDivisionDetail)
  yield takeEvery(CREATE_DIVISION, onCreateDivision)
  yield takeEvery(UPDATE_DIVISION, onUpdateDivision)
  yield takeEvery(DELETE_DIVISION, onDeleteDivision)
}

export default DivisionsSaga
