import React from "react"
import { MetaTags } from "react-meta-tags"
import { Container } from "reactstrap"
import AdminDashboardContainer from "./AdminDashboard"

function AdminDashboard() {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Home | Khaf</title>
        </MetaTags>

        <Container fluid></Container>
        <AdminDashboardContainer />
      </div>
    </React.Fragment>
  )
}

export default AdminDashboard
