import {
  GET_STUDENT_VARISANGYAS,
  GET_STUDENT_VARISANGYAS_SUCCESS,
  GET_STUDENT_VARISANGYAS_FAIL,
  GET_STUDENT_VARISANGYA_CARDS,
  GET_STUDENT_VARISANGYA_CARDS_SUCCESS,
  GET_STUDENT_VARISANGYA_CARDS_FAIL,
  GET_STUDENT_VARISANGYA_DETAIL,
  GET_STUDENT_VARISANGYA_DETAIL_SUCCESS,
  GET_STUDENT_VARISANGYA_DETAIL_FAIL,
  CREATE_STUDENT_VARISANGYA,
  CREATE_STUDENT_VARISANGYA_SUCCESS,
  CREATE_STUDENT_VARISANGYA_FAIL,
  UPDATE_STUDENT_VARISANGYA,
  UPDATE_STUDENT_VARISANGYA_SUCCESS,
  UPDATE_STUDENT_VARISANGYA_FAIL,
  DELETE_STUDENT_VARISANGYA,
  DELETE_STUDENT_VARISANGYA_SUCCESS,
  DELETE_STUDENT_VARISANGYA_FAIL,
  GET_STUDENT_VARISANGYA_ITEMS,
  GET_STUDENT_VARISANGYA_ITEMS_SUCCESS,
  GET_STUDENT_VARISANGYA_ITEMS_FAIL,
  AUTO_GENERATE_STUDENT_VARISANGYAS,
  AUTO_GENERATE_STUDENT_VARISANGYAS_SUCCESS,
  AUTO_GENERATE_STUDENT_VARISANGYAS_FAIL,
  REFRESH_STUDENT_VARISANGYA,
  REFRESH_STUDENT_VARISANGYA_SUCCESS,
  REFRESH_STUDENT_VARISANGYA_FAIL,
  UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER,
  UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
  UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
} from "./actionTypes"

export const getStudentVarisangyas = (
  searchText,
  page,
  limit,
  instituteId
) => ({
  type: GET_STUDENT_VARISANGYAS,
  payload: { searchText, page, limit, instituteId },
})
export const getStudentVarisangyasSuccess = studentVarisangya => ({
  type: GET_STUDENT_VARISANGYAS_SUCCESS,
  payload: studentVarisangya,
})
export const getStudentVarisangyasFail = error => ({
  type: GET_STUDENT_VARISANGYAS_FAIL,
  payload: error,
})

export const getStudentVarisangyaCards = () => ({
  type: GET_STUDENT_VARISANGYA_CARDS,
})
export const getStudentVarisangyaCardsSuccess = studentVarisangyaCards => ({
  type: GET_STUDENT_VARISANGYA_CARDS_SUCCESS,
  payload: studentVarisangyaCards,
})
export const getStudentVarisangyaCardsFail = error => ({
  type: GET_STUDENT_VARISANGYA_CARDS_FAIL,
  payload: error,
})

export const getStudentVarisangyaDetail = studentVarisangyaId => ({
  type: GET_STUDENT_VARISANGYA_DETAIL,
  studentVarisangyaId,
})

export const getStudentVarisangyaDetailSuccess = studentVarisangyaDetail => ({
  type: GET_STUDENT_VARISANGYA_DETAIL_SUCCESS,
  payload: studentVarisangyaDetail,
})

export const getStudentVarisangyaDetailFail = error => ({
  type: GET_STUDENT_VARISANGYA_DETAIL_FAIL,
  payload: error,
})

export const getStudentVarisangyaItems = studentId => ({
  type: GET_STUDENT_VARISANGYA_ITEMS,
  payload: { studentId },
})
export const getStudentVarisangyaItemsSuccess = studentVarisangyaItem => ({
  type: GET_STUDENT_VARISANGYA_ITEMS_SUCCESS,
  payload: studentVarisangyaItem,
})
export const getStudentVarisangyaItemsFail = error => ({
  type: GET_STUDENT_VARISANGYA_ITEMS_FAIL,
  payload: error,
})

export const createStudentVarisangya = (studentVarisangya, history) => ({
  type: CREATE_STUDENT_VARISANGYA,
  payload: { studentVarisangya, history },
})

export const createStudentVarisangyaSuccess = studentVarisangya => ({
  type: CREATE_STUDENT_VARISANGYA_SUCCESS,
  payload: studentVarisangya,
})

export const createStudentVarisangyaFail = error => ({
  type: CREATE_STUDENT_VARISANGYA_FAIL,
  payload: error,
})

export const updateStudentVarisangya = (
  studentVarisangya,
  studentVarisangyaId,
  history
) => ({
  type: UPDATE_STUDENT_VARISANGYA,
  payload: { studentVarisangya, studentVarisangyaId, history },
})

export const updateStudentVarisangyaSuccess = studentVarisangya => ({
  type: UPDATE_STUDENT_VARISANGYA_SUCCESS,
  payload: studentVarisangya,
})

export const updateStudentVarisangyaFail = error => ({
  type: UPDATE_STUDENT_VARISANGYA_FAIL,
  payload: error,
})

export const deleteStudentVarisangya = (studentVarisangyaId, history) => ({
  type: DELETE_STUDENT_VARISANGYA,
  payload: { studentVarisangyaId, history },
})

export const deleteStudentVarisangyaSuccess = studentVarisangya => ({
  type: DELETE_STUDENT_VARISANGYA_SUCCESS,
  payload: studentVarisangya,
})

export const deleteStudentVarisangyaFail = error => ({
  type: DELETE_STUDENT_VARISANGYA_FAIL,
  payload: error,
})

export const autoGenerateStudentVarisangyas = instituteId => ({
  type: AUTO_GENERATE_STUDENT_VARISANGYAS,
  payload: { instituteId },
})

export const autoGenerateStudentVarisangyasSuccess = studentVarisangya => ({
  type: AUTO_GENERATE_STUDENT_VARISANGYAS_SUCCESS,
  payload: studentVarisangya,
})

export const autoGenerateStudentVarisangyasFail = error => ({
  type: AUTO_GENERATE_STUDENT_VARISANGYAS_FAIL,
  payload: error,
})

export const refreshStudentVarisangya = studentVarisangyaId => ({
  type: REFRESH_STUDENT_VARISANGYA,
  payload: { studentVarisangyaId },
})

export const refreshStudentVarisangyaSuccess = studentVarisangya => ({
  type: REFRESH_STUDENT_VARISANGYA_SUCCESS,
  payload: studentVarisangya,
})

export const refreshStudentVarisangyaFail = error => ({
  type: REFRESH_STUDENT_VARISANGYA_FAIL,
  payload: error,
})

export const updateStudentVarisangyaAmountByFilter = (
  studentVarisangyaAmuontByFilter,
  history
) => ({
  type: UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER,
  payload: { studentVarisangyaAmuontByFilter, history },
})

export const updateStudentVarisangyaAmountByFilterSuccess =
  studentVarisangyaAmuontByFilter => ({
    type: UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_SUCCESS,
    payload: studentVarisangyaAmuontByFilter,
  })

export const updateStudentVarisangyaAmountByFilterFail = error => ({
  type: UPDATE_STUDENT_VARISANGYA_AMOUNT_BY_FILTER_FAIL,
  payload: error,
})
