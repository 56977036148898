import React from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label, Row } from "reactstrap"

import {
  updateFamilyMemberCollection,
  deleteFamilyMemberCollection,
} from "store/actions"

const UiModalCollectionUpdate = ({ show, onCloseclick, data, history }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  // console.log(data);

  const { loading, language, familyMemberCollectionDetail } = useSelector(
    state => ({
      loading: state.Mahalls.loading,
      language: state.Layout.language,
      familyMemberCollectionDetail:
        state.Collections.familyMemberCollectionDetail,
    })
  )
  console.log(familyMemberCollectionDetail)

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const updateCollectionData = {
      mahall: data?.mahall,
      collection_name: values.collection_name,
    }
    // console.log(updateCollectionData);
    dispatch(
      updateFamilyMemberCollection(updateCollectionData, data?.id, history)
    )
  }

  const handleDeleteCollection = id => {
    dispatch(deleteFamilyMemberCollection(id))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {" "}
              {language === "mal"
                ? "കളക്ഷൻ അപ്ഡേറ്റ് ചെയ്യുക"
                : "Update Collection"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "പേര്" : "Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="collection_name"
                    type="text"
                    value={data?.collection_name}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <Row className="mt-5 d-flex">
                <div className="col-6">
                  <div className="row justify-content-start">
                    <div>
                      <Button
                        type="button"
                        color="danger"
                        className="w-md"
                        onClick={() => {
                          onCloseclick()
                          handleDeleteCollection(data?.id)
                        }}
                      >
                        {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md m-auto me-0 d-block"
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക " : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalCollectionUpdate
UiModalCollectionUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.object,
  history: propTypes.object,
}
