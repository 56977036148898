import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_LSGS,
  GET_LSG_DETAIL,
  CREATE_LSG,
  UPDATE_LSG,
  DELETE_LSG,
  GET_LSG_DROPDOWN,
} from "./actionTypes"
import {
  getLsgsSuccess,
  getLsgsFail,
  getLsgDropdownSuccess,
  getLsgDropdownFail,
  getLsgDetailSuccess,
  getLsgDetailFail,
  createLsgSuccess,
  createLsgFail,
  updateLsgSuccess,
  updateLsgFail,
  deleteLsgSuccess,
  deleteLsgFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getLsgsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/lsg/?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/mahall/lsg/?page=${page ? page : 1}`)
  }
}

const getLsgDropdownAPi = searchText => {
  return get(`/api/v1/mahall/lsg_dropdown?search=${searchText && searchText}`)
}
const getLsgDetailsAPi = lsgId => {
  return get(`/api/v1/mahall/lsg/${lsgId}/`)
}
const createLsgApi = ({ lsg }) => {
  return post("/api/v1/mahall/lsg/", lsg)
}
const updateLsgApi = ({ lsgId, lsg }) => {
  return ApiPut(`/api/v1/mahall/lsg/${lsgId}/`, lsg)
}
const deleteLsgApi = ({ lsgId }) => {
  return del(`/api/v1/mahall/lsg/${lsgId}/`)
}

function* fetchLsgs({ payload }) {
  try {
    const response = yield call(getLsgsAPi, payload)
    if (response && !response?.error) {
      yield put(getLsgsSuccess(response))
    } else {
      yield put(getLsgsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getLsgsFail(error))
  }
}

function* fetchLsgDropdown({ payload }) {
  try {
    const response = yield call(getLsgDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getLsgDropdownSuccess(response))
    } else {
      yield put(getLsgDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getLsgDropdownFail(error))
  }
}

function* fetchLsgDetail({ lsgId }) {
  try {
    const response = yield call(getLsgDetailsAPi, lsgId)
    if (response && !response?.error) {
      yield put(getLsgDetailSuccess(response))
    } else {
      yield put(getLsgDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getLsgDetailFail(error))
  }
}
function* onCreateLsg({ payload }) {
  try {
    const response = yield call(createLsgApi, payload)
    if (response && !response?.error) {
      yield put(createLsgSuccess(response))
      payload.history.push("/lsgs")
      doneNotification("Created Successfully")
      //get lsgs
      try {
        const response = yield call(getLsgsAPi, payload)
        yield put(getLsgsSuccess(response))
      } catch (error) {
        yield put(getLsgsFail(error))
      }
    } else {
      yield put(createLsgFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createLsgFail(error))
    errorNotification()
  }
}

function* onUpdateLsg({ payload }) {
  try {
    const response = yield call(updateLsgApi, payload)
    if (response && !response?.error) {
      yield put(updateLsgSuccess(response))
      payload.history.push("/lsgs")
      doneNotification("Updated Successfully")
    } else {
      yield put(updateLsgFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateLsgFail(error))
    // console.log('error: ', error);
    errorNotification()
  }
}

function* onDeleteLsg({ payload }) {
  try {
    const response = yield call(deleteLsgApi, payload)
    if (response && !response?.error) {
      yield put(deleteLsgSuccess(response))
      doneNotification("Deleted Successfully")
      //get lsgs
      try {
        const response = yield call(getLsgsAPi, payload)
        yield put(getLsgsSuccess(response))
      } catch (error) {
        yield put(getLsgsFail(error))
      }
    } else {
      yield put(deleteLsgFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteLsgFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* LsgsSaga() {
  yield takeEvery(GET_LSGS, fetchLsgs)
  yield takeEvery(GET_LSG_DETAIL, fetchLsgDetail)
  yield takeEvery(GET_LSG_DROPDOWN, fetchLsgDropdown)
  yield takeEvery(CREATE_LSG, onCreateLsg)
  yield takeEvery(UPDATE_LSG, onUpdateLsg)
  yield takeEvery(DELETE_LSG, onDeleteLsg)
}

export default LsgsSaga
