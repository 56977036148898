import {
  GET_JOB_FAMILY_MEMBER,
  GET_JOB_FAMILY_MEMBER_SUCCESS,
  GET_JOB_FAMILY_MEMBER_FAIL,
  GET_JOB_FAMILY_MEMBER_DETAIL,
  GET_JOB_FAMILY_MEMBER_DETAIL_SUCCESS,
  GET_JOB_FAMILY_MEMBER_DETAIL_FAIL,
  CREATE_JOB_FAMILY_MEMBER,
  CREATE_JOB_FAMILY_MEMBER_SUCCESS,
  CREATE_JOB_FAMILY_MEMBER_FAIL,
  UPDATE_JOB_FAMILY_MEMBER,
  UPDATE_JOB_FAMILY_MEMBER_SUCCESS,
  UPDATE_JOB_FAMILY_MEMBER_FAIL,
  DELETE_JOB_FAMILY_MEMBER,
  DELETE_JOB_FAMILY_MEMBER_SUCCESS,
  DELETE_JOB_FAMILY_MEMBER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  jobs: [],
  jobDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdJob: {},
}

const JobFamilyMember = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOB_FAMILY_MEMBER:
    case UPDATE_JOB_FAMILY_MEMBER:
    case CREATE_JOB_FAMILY_MEMBER:
    case DELETE_JOB_FAMILY_MEMBER:
      return {
        ...state,
        loading: true,
      }
    case GET_JOB_FAMILY_MEMBER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_JOB_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
        loading: false,
      }

    case GET_JOB_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_JOB_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
        createdJob: action.payload,
        loading: false,
      }

    case CREATE_JOB_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_JOB_FAMILY_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        jobDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_JOB_FAMILY_MEMBER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_JOB_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        jobDetail: action.payload,
        loading: false,
      }

    case UPDATE_JOB_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_JOB_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        jobs: state.jobs.filter(
          job => job.id.toString() !== action.payload.id.toString()
        ),
        loading: false,
      }

    case DELETE_JOB_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default JobFamilyMember
