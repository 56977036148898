import React from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Modal, Button } from "reactstrap"

import { updateMahallTypeListItem } from "store/actions"

// const [modal_center, setmodal_center] = useState(false);

const UiModalMahallTypelistsUpdate = ({
  show,
  onCloseclick,
  data,
  history,
}) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const mahallTypeList = data

  const handleValidSubmit = (onSubmitProps, values) => {
    const mahallUpdateTypeListItem = {
      ...values,
      is_default: false,
    }

    dispatch(
      updateMahallTypeListItem(
        mahallUpdateTypeListItem,
        mahallTypeList?.id,
        history
      )
    )
  }

  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  const typeHeads = [
    { name: "Mahar Type", value: "mahar_type" },
    { name: "Area", value: "area" },
    { name: "LSG Type", value: "lsg_type" },
    { name: "Ration Card Category", value: "ration_card" },
    { name: "House Type", value: "house_type" },
    { name: "House Build Type", value: "house_build_type" },
    { name: "Water Facility", value: "water_facility" },
    { name: "Electric Facility", value: "electric_facility" },
    { name: "Financial Status", value: "financial_status" },
    { name: "Bank Loan", value: "bank_loan" },
    { name: "Annual Income", value: "annual_income" },
    { name: "Home Business", value: "home_business" },

    { name: "Gender", value: "gender" },
    { name: "Relation", value: "relation" },
    { name: "ID Card", value: "id_card" },
    { name: "Marital Status", value: "marital_status" },
    { name: "Unmarried Reason", value: "unmarried_reason" },
    { name: "Blood Group", value: "blood_group" },
    { name: "Health Condition", value: "health_condition" },
    { name: "Disease Type", value: "disease_type" },
    { name: "Disease Level", value: "disease_level" },
    { name: "Disability Type", value: "disability_type" },
    { name: "Social Activity Type", value: "social_activity_type" },
    { name: "Bad Habits", value: "bad_habits" },
    {
      name: "Educational Qualification Islamic",
      value: "educational_qualification_islamic",
    },
    {
      name: "Educational Qualification Formal",
      value: "educational_qualification_formal",
    },
    { name: "Not Studying Reason", value: "not_studying_reason" },
    { name: "Not Working Reason", value: "not_working_reason" },
    { name: "Education Status", value: "education_status" },
    { name: "Job Sector", value: "job_sector" },
    { name: "Job Type", value: "job_type" },
  ]

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Fill The Form</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-3">
                <AvField
                  type="select"
                  name="type"
                  label="Type"
                  value={mahallTypeList?.type ? mahallTypeList?.type : ""}
                >
                  <option>Choose a type ...</option>
                  {typeHeads?.map((item, key2) => (
                    <option key={key2} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </AvField>
              </div>
              <div className="row mb-3">
                <AvField
                  name="english"
                  label="English"
                  type="text"
                  value={mahallTypeList?.english}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="malayalam"
                  label="Malayalam"
                  value={mahallTypeList?.malayalam}
                />
              </div>
              <div className="row w-100 justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  onClick={onCloseclick}
                >
                  {" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Update"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalMahallTypelistsUpdate
UiModalMahallTypelistsUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.any,
  history: propTypes.object,
}
