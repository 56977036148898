import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
//actions
import { Link } from "react-router-dom/cjs/react-router-dom.min"

import {
  getAllFamilyMembersDropdown,
  updateNocCertificate,
  deleteNocCertificate,
  getNocCertificateDetail,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import FormEditors from "components/Common/FormEditor"

const UpdateNocCertificate = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { nocCertificateDetail, language, allFamilyMembers } = useSelector(
    state => ({
      loading: state.CommitteeMeetings.loading,
      error: state.Mahalls.error,
      language: state.Layout.language,
      nocCertificateDetail: state.NocCertificates.nocCertificateDetail,
      allFamilyMembers: state.FamilyMembers.allFamilyMembers,
    })
  )

  const [searchText] = useState("")
  const [familyMember, setFamilyMember] = useState("Select member...")
  const [familyMemberId, setFamilyMemberId] = useState()

  console.log(familyMember)
  console.log(familyMemberId)

  useEffect(() => {
    setFamilyMember(nocMemberName?.full_name)
    setFamilyMemberId(nocCertificateDetail?.member)
  }, [nocCertificateDetail])

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchText))
  }, [dispatch, searchText])

  // const optionGroup = [
  //   {
  //     options: allFamilyMembers?.map((result, index) => ({
  //       key: index,
  //       label: `${result?.full_name} (${
  //         result?.mahall_custom_id
  //           ? result?.mahall_custom_id
  //           : result?.custom_id
  //       })`,
  //       value: result.id,
  //     })),
  //   },
  // ]

  // function handlerFinalValue(event) {
  //   setFamilyMemberId(event.value)
  //   setFamilyMember(event.label)
  // }

  // const handleEnters = textEntered => {
  //   setSearchText(textEntered)
  // }

  const [content, setContent] = useState("")

  const contentData = nocCertificateDetail?.content
    ? nocCertificateDetail?.content
    : ""

  useEffect(() => {
    setContent(contentData)
  }, [nocCertificateDetail])

  useEffect(() => {
    dispatch(getNocCertificateDetail(params?.id))
  }, [dispatch, params.id])

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    // console.log(values)
    const updateNocCertificateData = {
      title: values.title,
      subject: values.subject,
      mahall_custom_id: values.mahall_custom_id,
      mahall: nocCertificateDetail?.mahall,
      member: nocCertificateDetail?.member,
      content: content,
      date: values.date,
    }
    // console.log(updateNocData);
    dispatch(
      updateNocCertificate(
        updateNocCertificateData,
        nocCertificateDetail?.id,
        history
      )
    )
  }

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }

  const handleDeleteEvent = () => {
    dispatch(deleteNocCertificate(nocCertificateDetail?.id, history))
    setIsOpen(false)
  }

  const nocMemberName = allFamilyMembers?.find(
    item => item?.id === nocCertificateDetail?.member
  )

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <MetaTags>
        <title>NOC Certificate | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal" ? "എൻ ഒ സി സർട്ടിഫിക്കറ്റ്" : "NOC Certificates"
          }
          breadcrumbItem={
            language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക" : "Update NOC Certificate"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-end">
                      <Link
                        to={`/noc/certificate?nocId=${nocCertificateDetail?.id}`}
                        type="button"
                        className="btn btn-outline-success"
                      >
                        {language === "mal"
                          ? "സർട്ടിഫിക്കറ്റ് കാണുക"
                          : "View Certificate"}
                      </Link>
                    </div>
                    {/* <CardTitle className="h4 mb-4">Fill this form</CardTitle> */}
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Title"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="title"
                              type="text"
                              value={nocCertificateDetail?.title}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "കുടുംബാംഗം" : "Member"}
                            </Label>
                            {/* <Select
                              onInputChange={handleEnters}
                              placeholder={familyMember}
                              options={optionGroup}
                              classNamePrefix="select2-selection"
                              onChange={handlerFinalValue}
                              type="text"
                              title="Family Member"
                              required="required"
                            /> */}
                            <AvField
                              id="horizontal-firstname-Input"
                              name="member"
                              type="text"
                              readOnly
                              value={nocMemberName?.full_name}
                            />
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "വിഷയം" : "Subject"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="subject"
                              type="text"
                              value={nocCertificateDetail?.subject}
                              // label={language === "mal" ? "വിഷയം" : "Subject"}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "തീയതി" : "Custom Id"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="mahall_custom_id"
                              type="text"
                              readOnly
                              value={nocCertificateDetail?.mahall_custom_id}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "തീയതി" : "Date"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="date"
                              type="date"
                              value={nocCertificateDetail?.date}
                            />
                          </div>
                        </div>
                      </Row>

                      <Row></Row>
                      <Row>
                        <Col md="12">
                          <div className="col-12 mb-3">
                            <Label htmlFor="lbltype" className="form-label">
                              {language === "mal" ? "ഉള്ളടക്കം" : "Content"}
                            </Label>
                            <Col sm="12" className="mt-0">
                              {content?.length >= 1 && (
                                <FormEditors
                                  content={content}
                                  setContent={setContent}
                                />
                              )}

                              {content?.length <= 0 && (
                                <FormEditors
                                  content={content}
                                  setContent={setContent}
                                />
                              )}
                            </Col>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-5 d-flex">
                        <div className="col-6">
                          <div className="row justify-content-start">
                            <div>
                              <Button
                                type="button"
                                color="danger"
                                className="w-md"
                                onClick={handleDelete}
                              >
                                {language === "mal"
                                  ? "ഡിലീറ്റ് ചെയ്യുക"
                                  : "Delete"}
                              </Button>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="row justify-content-end">
                            <div>
                              <Button
                                type="submit"
                                color="primary"
                                className="w-md m-auto me-0 d-block"
                              >
                                {language === "mal"
                                  ? "അപ്ഡേറ്റ് ചെയ്യുക "
                                  : "Update"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateNocCertificate

UpdateNocCertificate.propTypes = {
  history: PropTypes.object,
}
