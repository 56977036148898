/* SUBJECTS */
export const GET_SUBJECTS = "GET_SUBJECTS"
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS"
export const GET_SUBJECTS_FAIL = "GET_SUBJECTS_FAIL"

/* SUBJECTS_DROPDOWN*/
export const SUBJECTS_DROPDOWN = "SUBJECTS_DROPDOWN"
export const SUBJECTS_DROPDOWN_SUCCESS = "SUBJECTS_DROPDOWN_SUCCESS"
export const SUBJECTS_DROPDOWN_FAIL = "SUBJECTS_DROPDOWN_FAIL"

/* SUBJECT  DETAIL*/
export const GET_SUBJECT_DETAIL = "GET_SUBJECT_DETAIL"
export const GET_SUBJECT_DETAIL_SUCCESS = "GET_SUBJECT_DETAIL_SUCCESS"
export const GET_SUBJECT_DETAIL_FAIL = "GET_SUBJECT_DETAIL_FAIL"

/**
 * add SUBJECT
 */
export const CREATE_SUBJECT = "CREATE_SUBJECT"
export const CREATE_SUBJECT_SUCCESS = "CREATE_SUBJECT_SUCCESS"
export const CREATE_SUBJECT_FAIL = "CREATE_SUBJECT_FAIL"

/**
 * Edit SUBJECT
 */
export const UPDATE_SUBJECT = "UPDATE_SUBJECT"
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS"
export const UPDATE_SUBJECT_FAIL = "UPDATE_SUBJECT_FAIL"

/**
 * Delete SUBJECT
 */
export const DELETE_SUBJECT = "DELETE_SUBJECT"
export const DELETE_SUBJECT_SUCCESS = "DELETE_SUBJECT_SUCCESS"
export const DELETE_SUBJECT_FAIL = "DELETE_SUBJECT_FAIL"
