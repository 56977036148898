import {
  GET_DIVISIONS,
  UPDATE_DIVISION,
  CREATE_DIVISION,
  DELETE_DIVISION,
  GET_DIVISION_DETAIL,
  GET_DIVISIONS_SUCCESS,
  GET_DIVISIONS_FAIL,
  CREATE_DIVISION_SUCCESS,
  CREATE_DIVISION_FAIL,
  GET_DIVISION_DETAIL_SUCCESS,
  GET_DIVISION_DETAIL_FAIL,
  UPDATE_DIVISION_SUCCESS,
  UPDATE_DIVISION_FAIL,
  DELETE_DIVISION_SUCCESS,
  DELETE_DIVISION_FAIL,
  DIVISION_DROPDOWN,
  DIVISION_DROPDOWN_SUCCESS,
  DIVISION_DROPDOWN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  divisions: [],
  allDivisions: [],
  divisionDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdDivision: {},
}

const Divisions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DIVISION_DROPDOWN:
    case GET_DIVISIONS:
    case UPDATE_DIVISION:
    case CREATE_DIVISION:
    case DELETE_DIVISION:
      return {
        ...state,
        loading: true,
      }
    case GET_DIVISION_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_DIVISIONS_SUCCESS:
      return {
        ...state,
        divisions: action.payload,
        loading: false,
      }
    case GET_DIVISIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DIVISION_DROPDOWN_SUCCESS:
      return {
        ...state,
        allDivisions: action.payload,
        loading: false,
      }
    case DIVISION_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_DIVISION_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        divisions: {
          ...state.divisions,
          results: [...state.divisions.results, action.payload],
        },
        createdDivision: action.payload,
        loading: false,
      }

    case CREATE_DIVISION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_DIVISION_DETAIL_SUCCESS:
      return {
        ...state,
        divisionDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_DIVISION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_DIVISION_SUCCESS:
      return {
        ...state,
        // divisionDetail: action.payload,
        divisions: {
          results: state.divisions.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_DIVISION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_DIVISION_SUCCESS:
      return {
        ...state,
        divisions: {
          results: state.divisions.results.filter(
            division => division.id !== action.payload
          ),
        },
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        loading: false,
      }

    case DELETE_DIVISION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Divisions
