import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"
import Select from "react-select"

import {
  createInstituteWalletContra,
  getInstituteWalletDropdown,
} from "store/actions"
import { debounce } from "lodash"

const UiModalCreateContra = ({ show, onCloseclick, currentWallet }) => {
  const dispatch = useDispatch()

  const { loading, language, searchWallets } = useSelector(state => ({
    loading: state.Assets.loading,
    language: state.Layout.language,
    searchWallets: state.InstituteWallets.searchWallets,
  }))

  const currentWalletId = currentWallet?.id

  const sortedWalletList = searchWallets?.filter(
    item => item?.id !== currentWalletId
  )

  const [amount, setAmount] = useState("0")
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const handleAmountChange = e => {
    const enteredAmount = parseFloat(e.target.value)
    if (!isNaN(enteredAmount)) {
      if (enteredAmount > parseFloat(currentWallet?.balance)) {
        setErrorMessage(
          `${
            language === "mal"
              ? "തുക വാലറ്റ് ബാലൻസിനേക്കാൾ കൂടുതലാണ്"
              : "Amount is greater than your wallet balance"
          }`
        )
        setError(true)
      } else {
        setErrorMessage("")
        setError(false)
      }
      setAmount(e.target.value)
    } else {
      setErrorMessage(
        `${
          language === "mal"
            ? "ദയവായി ഒരു സാധുവായ നമ്പർ നൽകുക"
            : "Please enter a valid number"
        }`
      )
      setError(true)
    }
  }

  const [searchTextWallet, setSearchTextWallet] = useState("")
  const [walletName, setWalletName] = useState("Select wallet...")
  const [walletId, setWalletId] = useState(false)

  const optionGroup = [
    {
      options: sortedWalletList?.map((result, index) => ({
        key: index,
        label: result?.institute_wallet_name,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setWalletId(event.value)
    setWalletName(event.label)
  }

  const debounceWalletSearch = debounce(
    value => setSearchTextWallet(value),
    600
  )

  // const handleEnters = textEntered => {
  //   setSearchTextWallet(textEntered)
  // }

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const createInstituteWalletContraData = {
      from_wallet: currentWalletId,
      to_wallet: walletId,
      amount: parseInt(values.amount),
      title: values.title,
      description: values.description,
    }
    dispatch(createInstituteWalletContra(createInstituteWalletContraData))
  }

  useEffect(() => {
    dispatch(
      getInstituteWalletDropdown(currentWallet?.institute, searchTextWallet)
    )
  }, [dispatch, currentWallet, searchTextWallet])

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "ട്രാൻസ്ഫർ" : "Transfer"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>
                    {language === "mal" ? "വാലറ്റ്" : "Wallet"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={debounceWalletSearch}
                    placeholder={walletName}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Institute Wallet"
                    required="required"
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="title"
                    type="text"
                    label={language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="amount"
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    label={language === "mal" ? "തുക" : "Amount"}
                  />
                  {errorMessage && (
                    <p className="text-danger m-0">{errorMessage}</p>
                  )}
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    label={language === "mal" ? "വിവരണം" : "Description"}
                  />
                </Col>
              </div>
              <div className="row w-100 justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  disabled={error}
                >
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalCreateContra
UiModalCreateContra.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  currentWallet: propTypes.object,
}
