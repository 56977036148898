import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COMMITTEE_MEMBERS,
  GET_COMMITTEE_MEMBER_DETAIL,
  CREATE_COMMITTEE_MEMBER,
  UPDATE_COMMITTEE_MEMBER,
  DELETE_COMMITTEE_MEMBER,
  GET_COMMITTEE_MEMBER_DROPDOWN,
} from "./actionTypes"
import {
  getCommitteeMembersSuccess,
  getCommitteeMembersFail,
  getCommitteeMemberDetailSuccess,
  getCommitteeMemberDetailFail,
  createCommitteeMemberSuccess,
  createCommitteeMemberFail,
  updateCommitteeMemberSuccess,
  updateCommitteeMemberFail,
  deleteCommitteeMemberSuccess,
  deleteCommitteeMemberFail,
  getCommitteeMemberDropdownSuccess,
  getCommitteeMemberDropdownFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"
import {
  getCommitteeDetailSuccess,
  getCommitteeDetailFail,
} from "store/actions"

const getCommitteeMembersAPi = ({ committeeId, searchText, page }) => {
  if ((committeeId, searchText, page)) {
    return get(
      `/api/v1/committee/committee_member/?committee=${
        committeeId && committeeId
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  }
}
const getCommitteeMemberDetailsAPi = committeeMemberId => {
  return get(`/api/v1/committee/committee_member/${committeeMemberId}/`)
}
const getCommitteeMemberDropdownAPi = ({ committeeId, searchText }) => {
  return get(
    `/api/v1/committee/committee_member_dropdown?committee=${
      committeeId && committeeId
    }&search=${searchText && searchText}`
  )
}
const getCommitteeDetailsAPi = committeeId => {
  return get(`/api/v1/committee/committee/${committeeId}/`)
}
const createCommitteeMemberApi = ({ committeeMember }) => {
  return post("/api/v1/committee/committee_member/", committeeMember)
}
const updateCommitteeMemberApi = ({ committeeMemberId, committeeMember }) => {
  return ApiPut(
    `/api/v1/committee/committee_member/${committeeMemberId}/`,
    committeeMember
  )
}
const deleteCommitteeMemberApi = ({ committeeMemberId }) => {
  return del(`/api/v1/committee/committee_member/${committeeMemberId}/`)
}

function* fetchCommitteeMembers({ payload }) {
  try {
    const response = yield call(getCommitteeMembersAPi, payload)
    if (response && !response?.error) {
      yield put(getCommitteeMembersSuccess(response))
    } else {
      yield put(getCommitteeMembersFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error", error)
    yield put(getCommitteeMembersFail(error))
  }
}

function* fetchCommitteeMemberDetail({ committeeMemberId }) {
  try {
    const response = yield call(getCommitteeMemberDetailsAPi, committeeMemberId)
    if (response && !response?.error) {
      yield put(getCommitteeMemberDetailSuccess(response))
    } else {
      yield put(getCommitteeMemberDetailFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getCommitteeMemberDetailFail(error))
  }
}

function* fetchCommitteeMemberDropdown({ payload }) {
  try {
    const response = yield call(getCommitteeMemberDropdownAPi, payload)
    console.log(response)
    if (response && !response?.error) {
      yield put(getCommitteeMemberDropdownSuccess(response))
    } else {
      yield put(getCommitteeMemberDropdownFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getCommitteeMemberDropdownFail(error))
  }
}
function* onCreateCommitteeMember({ payload }) {
  try {
    const response = yield call(createCommitteeMemberApi, payload)
    if (response && !response?.error) {
      yield put(createCommitteeMemberSuccess(response))
      doneNotification("Added Successfully")
    } else {
      yield put(createCommitteeMemberFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createCommitteeMemberFail(error))
    errorNotification()
  }
}

function* onUpdateCommitteeMember({ payload }) {
  try {
    const response = yield call(updateCommitteeMemberApi, payload)
    if (response && !response?.error) {
      yield put(updateCommitteeMemberSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateCommitteeMemberFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error", error)
    yield put(updateCommitteeMemberFail(error))
    errorNotification()
  }
}

function* onDeleteCommitteeMember({ payload }) {
  try {
    const response = yield call(deleteCommitteeMemberApi, payload)
    if (response && !response?.error) {
      yield put(deleteCommitteeMemberSuccess(payload.committeeMemberId))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteCommitteeMemberFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteCommitteeMemberFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* CommitteeMembersSaga() {
  yield takeEvery(GET_COMMITTEE_MEMBERS, fetchCommitteeMembers)
  yield takeEvery(GET_COMMITTEE_MEMBER_DETAIL, fetchCommitteeMemberDetail)
  yield takeEvery(GET_COMMITTEE_MEMBER_DROPDOWN, fetchCommitteeMemberDropdown)
  yield takeEvery(CREATE_COMMITTEE_MEMBER, onCreateCommitteeMember)
  yield takeEvery(UPDATE_COMMITTEE_MEMBER, onUpdateCommitteeMember)
  yield takeEvery(DELETE_COMMITTEE_MEMBER, onDeleteCommitteeMember)
}

export default CommitteeMembersSaga
