import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ZAKAT_ITEMS,
  GET_ZAKAT_ITEM_CARDS,
  GET_ZAKAT_ITEM_DETAIL,
  CREATE_ZAKAT_ITEM,
  UPDATE_ZAKAT_ITEM,
  DELETE_ZAKAT_ITEM,
} from "./actionTypes"
import {
  getZakatItemsSuccess,
  getZakatItemsFail,
  getZakatItemCardsSuccess,
  getZakatItemCardsFail,
  getZakatItemDetailSuccess,
  getZakatItemDetailFail,
  createZakatItemSuccess,
  createZakatItemFail,
  updateZakatItemSuccess,
  updateZakatItemFail,
  deleteZakatItemSuccess,
  deleteZakatItemFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"
import { getZakatDetailFail, getZakatDetailSuccess } from "store/actions"

const getZakatItemsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/zakat/zakat_item/?search=${searchText && searchText}&page=${
        page && page
      }`
    )
  } else {
    return get(`/api/v1/zakat/zakat_item/?page=${page ? page : 1}`)
  }
}
const getZakatItemsCardsAPi = () => {
  return get(`/api/v1/zakat/zakat_item/?cards=all`)
}
const getZakatItemDetailsAPi = zakatItemId => {
  return get(`/api/v1/zakat/zakat_item/${zakatItemId}/`)
}
const getZakatDetailsAPi = zakatId => {
  return get(`/api/v1/zakat/zakat/${zakatId}/`)
}
const createZakatItemApi = ({ zakatItem }) => {
  return post("/api/v1/zakat/zakat_item/", zakatItem)
}
const updateZakatItemApi = ({ zakatItemId, zakatItem }) => {
  return ApiPut(`/api/v1/zakat/zakat_item/${zakatItemId}/`, zakatItem)
}
const deleteZakatItemApi = ({ zakatItemId }) => {
  return del(`/api/v1/zakat/zakat_item/${zakatItemId}/`)
}

function* fetchZakatItems({ payload }) {
  try {
    const response = yield call(getZakatItemsAPi, payload)
    if (response && !response?.error) {
      yield put(getZakatItemsSuccess(response))
    } else {
      yield put(getZakatItemsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getZakatItemsFail(error))
  }
}

function* fetchZakatItemsCards() {
  try {
    const response = yield call(getZakatItemsCardsAPi)
    if (response && !response?.error) {
      yield put(getZakatItemCardsSuccess(response))
    } else {
      yield put(getZakatItemCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getZakatItemCardsFail(error))
  }
}

function* fetchZakatItemDetail({ zakatId }) {
  try {
    const response = yield call(getZakatItemDetailsAPi, zakatId)
    if (response && !response?.error) {
      yield put(getZakatItemDetailSuccess(response))
    } else {
      yield put(getZakatItemDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getZakatItemDetailFail(error))
  }
}
function* onCreateZakatItem({ payload }) {
  try {
    const response = yield call(createZakatItemApi, payload)
    if (response && !response?.error) {
      yield put(createZakatItemSuccess(response))
      doneNotification("Created Successfully")
      try {
        const response2 = yield call(getZakatDetailsAPi, response?.zakat)
        yield put(getZakatDetailSuccess(response2))
      } catch (error) {
        yield put(getZakatDetailFail(error))
      }
    } else {
      yield put(createZakatItemFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createZakatItemFail(error))
    errorNotification()
  }
}

function* onUpdateZakatItem({ payload }) {
  try {
    const response = yield call(updateZakatItemApi, payload)
    if (response && !response?.error) {
      console.log("zakatItem Update response: ", response)
      yield put(updateZakatItemSuccess(response))
      doneNotification("Updated Successfully")
      try {
        const response2 = yield call(getZakatDetailsAPi, response?.zakat)
        yield put(getZakatDetailSuccess(response2))
      } catch (error) {
        yield put(getZakatDetailFail(error))
      }
    } else {
      yield put(updateZakatItemFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateZakatItemFail(error))
    errorNotification()
  }
}

function* onDeleteZakatItem({ payload }) {
  try {
    const response = yield call(deleteZakatItemApi, payload)
    if (response && !response?.error) {
      yield put(deleteZakatItemSuccess(response))
      doneNotification("Deleted Successfully")
      try {
        const response = yield call(getZakatItemsAPi, { page: 1 })
        yield put(getZakatItemsSuccess(response))
      } catch (error) {
        yield put(getZakatItemsFail(error))
      }
    } else {
      yield put(deleteZakatItemFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    errorNotification()
    yield put(deleteZakatItemFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* ZakatItemsSaga() {
  yield takeEvery(GET_ZAKAT_ITEMS, fetchZakatItems)
  yield takeEvery(GET_ZAKAT_ITEM_CARDS, fetchZakatItemsCards)
  yield takeEvery(GET_ZAKAT_ITEM_DETAIL, fetchZakatItemDetail)
  yield takeEvery(CREATE_ZAKAT_ITEM, onCreateZakatItem)
  yield takeEvery(UPDATE_ZAKAT_ITEM, onUpdateZakatItem)
  yield takeEvery(DELETE_ZAKAT_ITEM, onDeleteZakatItem)
}

export default ZakatItemsSaga
