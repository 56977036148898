import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button, Label } from "reactstrap"
// import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
import Select from "react-select"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
// import { AvField, AvForm } from 'availity-reactstrap-validation'
// import moment from 'moment'

import {
  getInstituteDropdown,
  getLedgerItemCategories,
  getLedgerItemCategoriesForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"

const AllInstituteCategories = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [
    modalLedgerItemCategoriesDownload,
    setModalLedgerItemCategoriesDownload,
  ] = useState(false)

  const {
    loading,
    language,
    ledgerItemCategories,
    ledgerItemCategoriesForDownload,
    searchInstitutes,
  } = useSelector(state => ({
    loading: state.LedgerItemCategories.loading,
    language: state.Layout.language,
    ledgerItemCategories: state.LedgerItemCategories.ledgerItemCategories,
    ledgerItemCategoriesForDownload:
      state.LedgerItemCategories.ledgerItemCategoriesForDownload?.results,
    searchInstitutes: state.Institutes.searchInstitutes,
  }))

  const [institute, setInstitute] = useState("Select institute")
  const [instituteId, setInstituteId] = useState("")
  const [searchTextInstitute, setSearchTextInstitute] = useState("")

  //pages
  const totalPages = Math.ceil(ledgerItemCategories?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getLedgerItemCategories(instituteId, searchText, pageSend()))
  }, [dispatch, instituteId, page, searchText])

  useEffect(() => {
    if (modalLedgerItemCategoriesDownload) {
      dispatch(getLedgerItemCategoriesForDownload(instituteId, searchText))
    }
  }, [dispatch, instituteId, searchText, modalLedgerItemCategoriesDownload])

  // const cardData = [
  //   {
  //     title: `${language === 'mal' ? 'വരുമാനം' : 'Income'}`,
  //     cardValue: `${ledgerItemCategories?.total_income ?? 0}`,
  //     className: 'text-success',
  //     iconClass: 'downvote',
  //   },
  //   {
  //     title: `${language === 'mal' ? 'ചെലവ്' : 'Expense'}`,
  //     cardValue: `${ledgerItemCategories?.total_expense ?? 0}`,
  //     className: 'text-danger',
  //     iconClass: 'upvote',
  //   },
  //   {
  //     title: `${language === 'mal' ? 'ബാലൻസ്' : 'Balance'}`,
  //     cardValue: `${ledgerItemCategories?.total_balance ?? 0}`,
  //     className: 'text-info',
  //     iconClass: 'list-check',
  //   },
  // ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
      sort: true,
    },
    {
      dataField: "institute_ledger_item_category_name",
      text: `${
        language === "mal" ? "ലെഡ്ജർ ഇനത്തിന്റെ  പേര്" : "Category Name"
      }`,
      sort: true,
    },
    {
      dataField: "balance",
      text: `${language === "mal" ? "ബാലൻസ്" : "Balance"}`,
    },
    {
      dataField: "institute_name",
      text: `${language === "mal" ? "വരവ്" : "Institute Name"}`,
    },
  ]

  const ledgerItemCategoriesData = map(
    ledgerItemCategories?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: index + 1,
    })
  )

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    {
      label: `${language === "mal" ? "വിഭാഗത്തിന്റെ പേര്" : "Category Name"}`,
      key: "institute_ledger_item_category_name",
    },
    {
      label: `${language === "mal" ? "ബാലൻസ്" : "Balance"}`,
      key: "balance",
    },
    {
      label: `${language === "mal" ? "സ്ഥാപനത്തിന്റെ പേര്" : "Institute Name"}`,
      key: "institute_name",
    },
  ]

  const ledgerItemCategoriessExcelData = map(
    ledgerItemCategoriesForDownload,
    (item, index) => ({
      ...item,
      no: index + 1,
      institute_ledger_item_category_name:
        item.institute_ledger_item_category_name,
      institute_name: item.institute_name,
      balance: item.balance,
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const fileName = "khaf-smart-ledger-items-list.csv"

  useEffect(() => {
    dispatch(getInstituteDropdown(searchTextInstitute))
  }, [searchTextInstitute, dispatch])

  function handlerFinalValue(event) {
    // dispatch(getInstituteDropdown(event.value))
    setInstituteId(event.value)
    setInstitute(event.label)
  }

  const optionGroup = [
    {
      label: "All Institutes",
      value: "",
    },
    {
      options: searchInstitutes?.map((result, index) => ({
        key: index,
        label: `${
          language === "mal"
            ? result.institute_malayalam_name
              ? result.institute_malayalam_name
              : result.institute_english_name
            : result.institute_english_name
        } (${result?.institute_type && result?.institute_type})`,
        value: result.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchTextInstitute(textEntered)
  }

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalLedgerItemCategoriesDownload}
        onCloseclick={() => setModalLedgerItemCategoriesDownload(false)}
        excelData={ledgerItemCategoriessExcelData}
        headers={headers}
        list={ledgerItemCategoriesForDownload}
        fileName={fileName}
        loading={loading}
      />
      {/* <Row>
        <Col md="12">
          <Card>
            <CardBody className="d-flex justify-content-center">
              <Col md="4">
                <Label>Institute</Label>
                <Select
                  onInputChange={handleEnters}
                  placeholder={institute}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                  onChange={handlerFinalValue}
                  type="text"
                  title="Institute"
                />
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={ledgerItemCategoriesData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mt-1">
                          <Select
                            onInputChange={handleEnters}
                            placeholder={institute}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="Institute"
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <Button
                          style={{
                            width: language === "mal" ? "150px" : "130px",
                            border: "bold",
                          }}
                          className="btn-md me-4 mt-0 d-block m-auto bg-white text-khaf"
                          onClick={() =>
                            setModalLedgerItemCategoriesDownload(true)
                          }
                        >
                          <i
                            className="bx bx-download mx-1"
                            style={{ fontSize: "18px", color: "#009846" }}
                          />
                          {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
                        </Button>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {ledgerItemCategories &&
                      ledgerItemCategories?.results &&
                      ledgerItemCategories?.results?.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          {language === "mal"
                            ? "ലെഡ്ജർ ഐറ്റംസ് ഒന്നും ഇല്ല "
                            : "No Ledger Items Yet"}
                        </p>
                      )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllInstituteCategories
