import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import axios from "axios"
//actions
import { Image } from "react-bootstrap"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

import { API_URL } from "helpers/api_methods"
import {
  updateBannerSuccess,
  updateBannerFail,
  getBannerDetail,
  deleteBanner,
} from "store/actions"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import { useFileSizes } from "hooks/useFileSize"
import DeleteModal from "components/Common/DeleteModal"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UpdateBanner = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const handleFileSizes = useFileSizes

  const { loading, language, bannerDetail, mahallId } = useSelector(state => ({
    loading: state.Banners.loading,
    error: state.Mahalls.error,
    language: state.Layout.language,
    bannerDetail: state.Banners.bannerDetail,
    mahallId: state.Banners.bannerDetail?.mahall,
  }))

  // const [customLoadAppImage, setCustomLoadAppImage] = useState(false)
  const [selectedFileAppImage, setSelectedFileAppImage] = useState({
    image: null,
  })
  const [imagePreviewAppImage, setImagePreviewAppImage] = useState()
  const [filesSizeAppImage, setFilesSizeAppImage] = useState()

  useEffect(() => {
    setSelectedFileAppImage(bannerDetail?.image_app)
  }, [bannerDetail])

  const changeHandlerAppImage = e => {
    const filesizeAppImage = e.target.files[0].size
    setFilesSizeAppImage(filesizeAppImage)

    setSelectedFileAppImage({
      ...selectedFileAppImage,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreviewAppImage({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  // const [customLoadWebImage, setCustomLoadWebImage] = useState(false)

  // const [filesSizeWebImage, setFilesSizeWebImage] = useState()

  // const changeHandlerWebImage = e => {
  //   const filesizeWebImage = e.target.files[0].size
  //   setFilesSizeWebImage(filesizeWebImage)

  //   setSelectedFileWebImage({
  //     ...selectedFileWebImage,
  //     image: e.target.files[0],
  //   })

  //   if (e.target.files && e.target.files[0]) {
  //     let reader = new FileReader()
  //     reader.onload = e => {
  //       setImagePreviewWebImage({ image: e.target.result })
  //     }
  //     reader.readAsDataURL(e.target.files[0])
  //   }
  // }

  useEffect(() => {
    dispatch(getBannerDetail(params.id))
  }, [])

  function handleValidSubmit(onSubmitProps, values) {
    updateNewBanner(
      selectedFileAppImage,
      values,
      mahallId,
      dispatch,
      history,
      bannerDetail
    )
    // setImagePreviewAppImage()
    // setImagePreviewWebImage()
  }

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }

  const handleDeleteBanner = () => {
    dispatch(deleteBanner(bannerDetail?.id, history))
    setIsOpen(false)
  }

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteBanner}
      />
      <MetaTags>
        <title>Banners | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ബാനറുകൾ" : "Banners"}
          breadcrumbItem={
            language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക" : "Update Banner"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="row mb-3">
                        <Col md="6">
                          <Label>
                            {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                          </Label>
                          <AvField
                            name="title"
                            type="text"
                            value={bannerDetail?.title}
                          />
                        </Col>
                        <Col md="6" className="banner-field">
                          <Label>
                            {language === "mal" ? "മുൻഗണന" : "Priority"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="priority"
                            type="number"
                            value={bannerDetail?.priority}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col md="12" className="mb-3">
                          <AvField
                            name="url"
                            type="text"
                            value={bannerDetail?.url}
                            label={language === "mal" ? "യു ആർ എൽ" : "URL"}
                          />
                        </Col>
                      </div>

                      {/* <div className="row mb-4"> */}
                      <Col md="12">
                        <Label>
                          {language === "mal"
                            ? "മൊബൈൽ ആപ്പിലേക്കുള്ള ഫോട്ടോ"
                            : "App Image"}
                          <span className="text-danger">&#42;</span>
                        </Label>
                        <AvField
                          name="image_app"
                          type="file"
                          onChange={changeHandlerAppImage}
                        />
                        {handleFileSizes(filesSizeAppImage)?.text && (
                          <p className="text-danger mt-1">
                            {handleFileSizes(filesSizeAppImage)?.text}
                          </p>
                        )}
                        {imagePreviewAppImage ? (
                          <Image
                            style={{ width: "50%", height: "50%" }}
                            className="my-2"
                            src={imagePreviewAppImage.image}
                          />
                        ) : (
                          <Image
                            style={{ width: "50%", height: "50%" }}
                            className="my-2"
                            src={bannerDetail?.image_app}
                          />
                        )}
                      </Col>

                      {/* <Col md="12" className="banner-field mt-2">
                        <Label> {language === "mal" ? "വെബ്ബിലേക്കുള്ള ഫോട്ടോ" : "Web Image"} </Label>
                        <AvField
                          name="image_web"
                          type="file"
                          onChange={changeHandlerWebImage}
                        />
                        {handleFileSizes(filesSizeWebImage)?.text && (
                          <p className="text-danger mt-1">
                            {handleFileSizes(filesSizeWebImage)?.text}
                          </p>
                        )}
                        {
                          imagePreviewWebImage ? <Image style={{ width: "50%", height: "50%" }} className="my-2" src={imagePreviewWebImage.image} /> : <Image style={{ width: "50%", height: "50%" }} className="my-2" src={bannerDetail?.image_web} />
                        }
                      </Col> */}

                      {/* </div> */}

                      <Row className="mt-5 d-flex">
                        <div className="col-6">
                          <div className="row justify-content-start">
                            <div>
                              <Button
                                type="button"
                                color="danger"
                                className="w-md"
                                onClick={handleDelete}
                              >
                                {language === "mal"
                                  ? "ഡിലീറ്റ് ചെയ്യുക"
                                  : "Delete"}
                              </Button>
                            </div>
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="row justify-content-end">
                            <div>
                              <Button
                                type="submit"
                                color="primary"
                                className="w-md m-auto me-0 d-block"
                              >
                                {loading && (
                                  <>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                  </>
                                )}
                                {language === "mal"
                                  ? "അപ്ഡേറ്റ് ചെയ്യുക"
                                  : "Update"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Row>

                      {/* <div className="d-flex justify-content-end">
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Update
                        </Button>
                      </div> */}
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateBanner

UpdateBanner.propTypes = {
  history: PropTypes.object,
}

function updateNewBanner(
  selectedFileAppImage,
  values,
  mahallId,
  dispatch,
  history,
  bannerDetail
) {
  console.log(selectedFileAppImage)
  const formData = new FormData()
  {
    selectedFileAppImage &&
      selectedFileAppImage?.image &&
      formData.append(
        "image_app",
        selectedFileAppImage?.image,
        selectedFileAppImage?.image?.name
      )
  }
  // {
  //   selectedFileWebImage &&
  //     selectedFileWebImage?.image &&
  //     formData.append("image_web", selectedFileWebImage?.image, selectedFileWebImage?.image?.name)
  // }
  formData.append("mahall", mahallId)
  formData.append("title", values.title)
  formData.append("url", values.url)
  formData.append("priority", values.priority)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .put(`${API_URL}/api/v1/banner/banner/${bannerDetail?.id}/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        dispatch(updateBannerSuccess(res.data))
        history.push("/banners")
        doneNotification("Updated Successfully")
      } else {
        updateBannerFail(res?.error)
        console.log(res?.error)
        errorNotification()
      }
    })
    .catch(err => {
      console.log(err)
      updateBannerFail(err)
      errorNotification()
      // if (err?.response?.data?.error[0]) {
      //   console.log(err?.response?.data?.error[0]);
      //   createMahallFail(err)
      //   errorNotification()
      //   setCustomLoadAppImage(false)
      // }
    })
}
