import React, { useState, useEffect } from "react"
import { Col, Container, Row, Card, CardBody, Spinner } from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"

//actions state
import { deleteState, getStateDetail } from "store/state/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//Import Images

const EnquiryDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { stateDetail, loading, detailLoading } = useSelector(state => ({
    loading: state.States.loading,
    detailLoading: state.States.detailLoading,
    stateDetail: state.States.stateDetail,
  }))

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(deleteState(stateDetail.id, history))
    setIsOpen(false)
    history.push("/states")
  }

  useEffect(() => {
    dispatch(getStateDetail(params.id))
  }, [])
  // const project = []
  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <MetaTags>
        <title>State | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="All States" breadcrumbItem="State" />
        <Container fluid>
          <div className="container-fluid mt-4">
            <Row>
              <Col xl="3"></Col>
              <Col lg="6">
                <Card>
                  <CardBody>
                    {detailLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {/* <Media>

                          <Media className="overflow-hidden" body>
                            <h3 className="text-truncate font-size-15">
                              {stateDetail.name}
                            </h3>
                            <p className="text-muted">{stateDetail.address}</p>
                          </Media>
                        </Media> */}

                        <h2 className="font-size-21 mt-3 font-weight-bold">
                          {stateDetail.state_english_name}
                        </h2>

                        <div className="text-muted mt-3">
                          <p>
                            <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                            Serial No: {stateDetail.auto_id}
                          </p>
                        </div>
                      </>
                    )}

                    <Row className="task-dates">
                      <Col>
                        <div className="d-flex ">
                          <div>
                            <Link
                              to={`/state/update/${stateDetail?.id}`}
                              className={`btn btn-secondary ${
                                loading && "disabled"
                              } btn-m`}
                            >
                              Edit{" "}
                              <i className="mdi mdi-arrow-right ms-1 pl-4 pr-4 bx-fade-right" />
                            </Link>
                          </div>
                          <div style={{ position: "relative", left: "10px" }}>
                            <Link
                              style={{ opacity: 0.8 }}
                              to="#"
                              className={`btn btn-outline-light ${
                                loading && "disabled"
                              }  btn-m`}
                              onClick={handleDelete}
                            >
                              Remove
                              <i className="fas fa-trash text-danger ms-1 pl-4 pr-4 bx-tada-hover"></i>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12"></Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default EnquiryDetails

EnquiryDetails.propTypes = {
  history: PropTypes.object,
}
