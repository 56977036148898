import {
  GET_STAFFS,
  UPDATE_STAFF,
  CREATE_STAFF,
  DELETE_STAFF,
  GET_STAFF_DETAIL,
  GET_STAFFS_SUCCESS,
  GET_STAFFS_FAIL,
  CREATE_STAFF_SUCCESS,
  CREATE_STAFF_FAIL,
  GET_STAFF_DETAIL_SUCCESS,
  GET_STAFF_DETAIL_FAIL,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_FAIL,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL,
  GET_STAFF_DROPDOWN,
  GET_STAFF_DROPDOWN_SUCCESS,
  GET_STAFF_DROPDOWN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  staffs: [],
  staffList: [],
  staffDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdStaff: {},
}

const Staffs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STAFF_DROPDOWN:
    case GET_STAFFS:
    case UPDATE_STAFF:
    case CREATE_STAFF:
    case DELETE_STAFF:
      return {
        ...state,
        loading: true,
      }
    case GET_STAFF_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_STAFFS_SUCCESS:
      return {
        ...state,
        staffs: action.payload,
        loading: false,
      }
    case GET_STAFFS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STAFF_DROPDOWN_SUCCESS:
      return {
        ...state,
        staffList: action.payload,
        loading: false,
      }
    case GET_STAFF_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_STAFF_SUCCESS:
      return {
        ...state,
        staffs: {
          ...state.staffs,
          results: [...state.staffs.results, action.payload],
        },
        createdStaff: action.payload,
        loading: false,
      }

    case CREATE_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_STAFF_DETAIL_SUCCESS:
      return {
        ...state,
        staffDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_STAFF_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        // staffDetail: action.payload,
        staffs: {
          results: state.staffs.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        staffs: {
          results: state.staffs.results.filter(
            staff => staff.id !== action.payload
          ),
        },
        loading: false,
      }

    case DELETE_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Staffs
