import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
// import { useDispatch,useSelector} from 'react-redux'
// import { useParams, useHistory } from 'react-router'
import { Link } from "react-router-dom"
import classnames from "classnames"
import AuthCode from "react-auth-code-input"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Form,
  Input,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
// import { otpVerification } from 'store/actions'
import Breadcrumbs from "components/Common/Breadcrumb"

const UpdatePhone = () => {
  // const dispatch = useDispatch()
  // const params = useParams()

  // const history = useHistory()

  const [activeTab, setactiveTab] = useState(1)
  // const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  // const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  // function handleValidSubmit(e, v) {
  // console.log(v)
  // dispatch(otpVerification(v))
  // }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  // function toggleTabVertical(tab) {
  //   if (activeTabVartical !== tab) {
  //     var modifiedSteps = [...passedStepsVertical, tab]

  //     if (tab >= 1 && tab <= 2) {
  //       setoggleTabVertical(tab)
  //       setPassedStepsVertical(modifiedSteps)
  //     }
  //   }
  // }

  // const { mahallUserDetail } = useSelector(state => ({
  //   mahallUserDetail: state.MahallUsers.mahallUserDetail,
  // }))

  // const handleNext = () => {
  //   setactiveTab(2)
  // }

  return (
    <>
      <MetaTags>
        <title>Mahall User | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="MahallUsers" breadcrumbItem="Update Phone" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span> Update Phone
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number ms-2">02</span> OTP
                              Verification
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix mt-4">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={1}>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                      Old Phone Number
                                    </Label>
                                    <Input
                                      type="mobile"
                                      name="old_phone_number"
                                      className="form-control"
                                      id="basicpill-firstname-input1"
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2">
                                      New Phone Number
                                    </Label>
                                    <Input
                                      type="mobile"
                                      name="new_phone_number"
                                      className="form-control"
                                      id="basicpill-lastname-input2"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </TabPane>
                          <TabPane tabId={2}>
                            <div>
                              <Form>
                                <Row>
                                  <Col md="12">
                                    <div className="mb-3">
                                      <Label
                                        for="basicpill-pancard-input5"
                                        style={{
                                          marginLeft: "100px",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Enter OTP
                                      </Label>
                                      <div style={{ marginLeft: "50px" }}>
                                        <AuthCode
                                          characters={6}
                                          className="form-control form-control-lg text-center"
                                          inputStyle={{
                                            marginTop: "15px",
                                            width: "50px",
                                            height: "calc(1.5em + 1rem + 2px)",
                                            padding: ".5rem 1rem",
                                            borderRadius: "8px",
                                            fontSize: "1.01562rem",
                                            textAlign: "center",
                                            marginRight: "15px",
                                            border: "1px solid #ced4da",
                                            textTransform: "uppercase",
                                            // borderRadius: '.4rem',
                                          }}
                                          onChange={() => null}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          {activeTab !== 1 && (
                            <li
                              className={
                                activeTab === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                          )}
                          {activeTab !== 2 && (
                            <li
                              className={
                                activeTab === 2 ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                Send OTP
                              </Link>
                            </li>
                          )}
                          {activeTab === 2 && (
                            <li>
                              <Button color="success" className="btn-md">
                                Verify OTP
                              </Button>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdatePhone

UpdatePhone.propTypes = {
  history: PropTypes.object,
}
