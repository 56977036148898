import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_COUNTRIES,
  GET_COUNTRY_DETAIL,
  CREATE_COUNTRY,
  UPDATE_COUNTRY,
  DELETE_COUNTRY,
} from "./actionTypes"
import {
  getCountriesSuccess,
  getCountriesFail,
  getCountryDetailSuccess,
  getCountryDetailFail,
  createCountrySuccess,
  createCountryFail,
  updateCountrySuccess,
  updateCountryFail,
  deleteCountrySuccess,
  deleteCountryFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getCountriesAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/mahall/country?search=${searchText && searchText}&page=${
        page ? page : 1
      }`
    )
  } else {
    return get(`/api/v1/mahall/country?page=${page ? page : 1}`)
  }
}
const getCountryDetailsAPi = countryId => {
  return get(`/api/v1/mahall/country/${countryId}/`)
}
const createCountryApi = ({ country }) => {
  return post("/api/v1/mahall/country/", country)
}
const updateCountryApi = ({ countryId, country }) => {
  return ApiPut(`/api/v1/mahall/country/${countryId}/`, country)
}
const deleteCountryApi = ({ countryId }) => {
  return del(`/api/v1/mahall/country/${countryId}/`)
}

function* fetchCountries({ payload }) {
  try {
    const response = yield call(getCountriesAPi, payload)
    if (response && !response?.error) {
      yield put(getCountriesSuccess(response))
    } else {
      yield put(getCountriesFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getCountriesFail(error))
  }
}

function* fetchCountryDetail({ countryId }) {
  try {
    const response = yield call(getCountryDetailsAPi, countryId)
    if (response && !response?.error) {
      yield put(getCountryDetailSuccess(response))
    } else {
      yield put(getCountryDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getCountryDetailFail(error))
  }
}
function* onCreateCountry({ payload }) {
  try {
    const response = yield call(createCountryApi, payload)
    if (response && !response?.error) {
      yield put(createCountrySuccess(response))
      payload.history.push("/countries")
      doneNotification("Created Successfully")
    } else {
      yield put(createCountryFail(response))
      errorNotification()
    }
    //get countrys
    // try {
    //   const response = yield call(getCountrysAPi, payload)
    //   yield put(getCountrysSuccess(response))
    // } catch (error) {
    //   yield put(getCountrysFail(error))
    // }
  } catch (error) {
    // console.log('error: ', error);
    yield put(createCountryFail(error))
    errorNotification()
  }
}

function* onUpdateCountry({ payload }) {
  try {
    const response = yield call(updateCountryApi, payload)
    if (response && !response?.error) {
      payload.history.push("/countries")
      yield put(updateCountrySuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateCountryFail(response))
      errorNotification()
    }
  } catch (error) {
    // console.log('error: ', error);
    yield put(updateCountryFail(error))
    errorNotification()
  }
}

function* onDeleteCountry({ payload }) {
  try {
    const response = yield call(deleteCountryApi, payload)
    if (response && !response?.error) {
      console.log("res: ", response?.error)
      // payload.history.push("/countries")
      yield put(deleteCountrySuccess(payload?.countryId))
      doneNotification("Deleted Successfully")
      //get countrys
      // try {
      //   const response = yield call(getCountriesAPi, { page: 1 })
      //   yield put(getCountriesSuccess(response))
      // } catch (error) {
      //   yield put(getCountriesFail(error))
      // }
    } else {
      yield put(deleteCountryFail(response))
      errorNotification()
    }
  } catch (error) {
    console.log("error: ", error)
    errorNotification()
    yield put(deleteCountryFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* CountriesSaga() {
  yield takeEvery(GET_COUNTRIES, fetchCountries)
  yield takeEvery(GET_COUNTRY_DETAIL, fetchCountryDetail)
  yield takeEvery(CREATE_COUNTRY, onCreateCountry)
  yield takeEvery(UPDATE_COUNTRY, onUpdateCountry)
  yield takeEvery(DELETE_COUNTRY, onDeleteCountry)
}

export default CountriesSaga
