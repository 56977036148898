import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"

//actions
import {
  getDeathRegistrationDetail,
  updateDeathRegistration,
  getAllFamilyMembersDropdown,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const UpdateMahallDeathRegistration = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [searchText, setSearchText] = useState("")
  const [familyMember, setFamilyMember] = useState()
  const [familyMemberId, setFamilyMemberId] = useState()
  console.log(familyMember)
  const { deathRegistrationDetail, language, allFamilyMembers, loading } =
    useSelector(state => ({
      deathRegistrationDetail: state.DeathRegistrations.deathRegistrationDetail,
      loading: state.DeathRegistrations.loading,
      language: state.Layout.language,
      allFamilyMembers: state.FamilyMembers.allFamilyMembers,
    }))
  console.log(setSearchText)
  console.log(allFamilyMembers)
  function handleValidSubmit(onSubmitProps, values) {
    const updateDeathRegistrationData = {
      mahall_custom_id: values.mahall_custom_id,
      family_member: familyMemberId,
      death_date: values.death_date,
      death_place: values.death_place,
      reason: values.reason,
      qabar: values.qabar,
    }
    // console.log('updateDeathRegistrationData: ',updateDeathRegistrationData);
    dispatch(
      updateDeathRegistration(
        updateDeathRegistrationData,
        deathRegistrationDetail.id,
        history
      )
    )

    window.scroll(0, 0)
  }

  useEffect(() => {
    dispatch(getDeathRegistrationDetail(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    setFamilyMemberId(deathRegistrationDetail?.family_member)
    setFamilyMember(deathRegistrationDetail?.family_member_name)
  }, [deathRegistrationDetail])

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchText))
  }, [dispatch, searchText])

  // const handleEnters = textEntered => {
  //   setSearchText(textEntered)
  // }
  // const optionGroup = [
  //   {
  //     options: allFamilyMembers?.map((results, index) => ({
  //       key: index,
  //       label: results.full_name,
  //       value: results.id,
  //     })),
  //   },
  // ]

  // function handlerFinalValue(event) {
  //   setFamilyMemberId(event.value)
  //   setFamilyMember(event.label)
  // }

  return (
    <>
      <MetaTags>
        <title>Death | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "മരണം" : "Death"}
          breadcrumbItem={language === "mal" ? "അപ്ഡേറ്റ്" : "Update"}
        />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <Row>
                <Col sm="12" md="8" lg="6">
                  <Card>
                    <CardBody>
                      <Row>
                        <div className="row mb-4">
                          <Col>
                            <AvField
                              name="mahall_custom_id"
                              label={
                                language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"
                              }
                              type="text"
                              value={deathRegistrationDetail?.mahall_custom_id}
                              readOnly
                            />
                          </Col>
                          <Col>
                            <Label>
                              {language === "mal" ? "തീയതി " : "Date "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              name="death_date"
                              type="date"
                              value={deathRegistrationDetail?.death_date}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: `${
                                    language === "mal"
                                      ? "നിർബന്ധമാണ്!"
                                      : "mandatory!"
                                  }`,
                                },
                              }}
                            />
                          </Col>
                        </div>
                        <div className="mb-3 row sm:mt-0">
                          <Label>
                            {language === "mal" ? "കുടുംബാംഗം" : "Member"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="family_member_name"
                            type="text"
                            value={deathRegistrationDetail?.family_member_name}
                            readOnly
                          />
                        </div>

                        <div className="row mb-4">
                          <Col>
                            <Label>
                              {language === "mal" ? "മരണ സ്ഥലം" : "Death Place"}
                            </Label>
                            <AvField
                              name="death_place"
                              type="text"
                              value={deathRegistrationDetail?.death_place}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col>
                            <Label>
                              {language === "mal" ? "മരണ കാരണം " : "Reason"}
                            </Label>
                            <AvField
                              name="reason"
                              type="text"
                              value={deathRegistrationDetail?.reason}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col>
                            <Label>
                              {language === "mal"
                                ? "ഖബർ വിവരങ്ങൾ"
                                : "Qabr Details"}
                            </Label>
                            <AvField
                              name="qabar"
                              type="textarea"
                              value={deathRegistrationDetail?.qabar}
                            />
                          </Col>
                        </div>
                        <div style={{ textDecoration: "underline blue" }}>
                          <Link to={`/death/certificate/${params?.id}`}>
                            <p>View Certificate</p>
                          </Link>
                        </div>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="col-12 btn btn-success save-customer"
                          >
                            {loading && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            )}
                            {language === "mal" ? "അപ്ഡേറ്റ്" : "Update"}
                          </button>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateMahallDeathRegistration

UpdateMahallDeathRegistration.propTypes = {
  history: PropTypes.object,
}
