import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label, Badge } from "reactstrap"
import Select from "react-select"

// import { useFileSizes } from 'components/Common/useFileSize'
import { createZakatItem, getAllFamilyDropdown } from "store/actions"

const UiModalZakatItemCreate = ({ show, onCloseclick, history }) => {
  const dispatch = useDispatch()
  // const params = useParams()
  // const handleFileSizes = useFileSizes

  const { loading, language, zakatId, allFamilies } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    zakatId: state.Zakats.zakatDetail?.id,
    allFamilies: state.Families.allFamilies,
  }))

  console.log(zakatId)

  // const today = moment(new Date()).format("yyyy-MM-DD")

  const [family, setFamily] = useState("Select Family...")
  const [familyId, setFamilyId] = useState("")
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    dispatch(getAllFamilyDropdown(searchText, true))
  }, [searchText, true, dispatch])

  function handlerFinalValue(event) {
    setFamilyId(event.value)
    setFamily(event.label)
  }

  const optionGroup = [
    {
      options: allFamilies?.map((results, index) => ({
        key: index,
        label: (
          <div>
            {results?.house_name}
            {results?.mahall_custom_id ? (
              <Badge className="bg-khaf-blue mx-2">
                {results?.mahall_custom_id}
              </Badge>
            ) : results?.family_head_name ? (
              <span className="text-khaf"> ({results?.family_head_name})</span>
            ) : (
              <Badge className="bg-khaf-blue mx-2">{results?.custom_id}</Badge>
            )}
          </div>
        ),
        value: results.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const [customLoad, setCustomLoad] = useState(false)
  console.log(customLoad)
  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    setCustomLoad(true)
    const createZakatItemData = {
      family: familyId,
      quantity: values.quantity,
      description: values.description,
      zakat: zakatId,
    }
    // console.log('createZakatItemData: ', createZakatItemData);
    dispatch(createZakatItem(createZakatItemData, history))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "എൻട്രി ചേർക്കുക" : "Add Entry"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>
                    {language === "mal" ? "കുടുംബം" : "Family"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={family}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Family"
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col>
                  <Label>{language === "mal" ? "വിവരണം" : "Details"}</Label>
                  <AvField name="description" type="textarea" />
                </Col>
              </div>

              <div className="row mb-4">
                <Col md="4">
                  <Label>
                    {language === "mal" ? "സകാത്ത് അളവ്" : "Zakat Quantity"}
                  </Label>
                  <AvField name="quantity" type="number" />
                </Col>
              </div>

              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalZakatItemCreate
UiModalZakatItemCreate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
