import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"

//actions
import { getDistrictDetail, updateDistrict, getStates } from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"

const UpdateDistrict = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [state, setState] = useState("Search State")
  const [stateId, setStateId] = useState("")
  const [searchText, setSearchText] = useState("")

  const { districtDetail, statesData } = useSelector(state => ({
    districtDetail: state.Districts.districtDetail,
    statesData: state.States.states,
  }))

  function handleValidSubmit(values) {
    dispatch(
      updateDistrict({ ...values, state: stateId }, districtDetail.id, history)
    )
    window.scroll(0, 0)
  }

  useEffect(() => {
    dispatch(getDistrictDetail(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    setState(districtDetail.state_english_name)
    setStateId(districtDetail.state)
  }, [dispatch, districtDetail])

  useEffect(() => {
    dispatch(getStates(searchText))
  }, [dispatch, searchText])

  function handlerFinalValue(event) {
    dispatch(getStates(event.value))
    setStateId(event.value)
    setState(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const optionGroup = [
    {
      options: statesData.results?.map((results, index) => ({
        key: index,
        label: results.state_english_name,
        value: results.id,
      })),
    },
  ]

  return (
    <>
      <MetaTags>
        <title>District | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="District" breadcrumbItem="Update" />
        <Container fluid>
          <div className="container-fluid mt-5">
            <Row>
              <Col sm="12" md="12" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Update District</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            className="filePathClass"
                            name="district_english_name"
                            label="Name (English)"
                            type="text"
                            value={districtDetail?.district_english_name || ""}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            className="filePathClass"
                            name="district_malayalam_name"
                            label="Name (മലയാളം)"
                            type="text"
                            value={
                              districtDetail?.district_malayalam_name || ""
                            }
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col>
                          <Label> State </Label>
                          <Select
                            onInputChange={handleEnters}
                            placeholder={state}
                            value={state}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="State"
                            required="required"
                          />
                        </Col>
                      </div>

                      <div className="d-flex justify-content-end">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateDistrict

UpdateDistrict.propTypes = {
  history: PropTypes.object,
}
