import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Label, Modal, Button } from "reactstrap"
import { map } from "lodash"
import { useParams } from "react-router-dom"

import { createJobFamilyMember, getMahallTypeLists } from "store/actions"
import { handleSelectOrDeselect } from "hooks/useSelectOrDeselect"

const UiModalJob = ({ show, onCloseclick, history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    mahallTypeLists,
    //  userDetail,
    mahallId,
    language,
  } = useSelector(state => ({
    mahallTypeLists: state.TypeLists.mahallTypeLists,

    userDetail: state.Users.userDetail,
    mahallId: state.Users.userDetail?.mahall?.id,
    language: state.Layout.language,
  }))

  const handleValidSubmit = (onSubmitProps, values) => {
    const createJobFamilyMemberData = {
      family_member: params?.id,
      job_office: values.job_office,
      job_place: values.job_place,
      job_sector_choice_id: jobSector,
      job_type_choice_id: jobType,
      job_position: values.job_position,
      monthly_income: values.monthly_income,
    }

    dispatch(createJobFamilyMember(createJobFamilyMemberData, history))
  }

  const [jobSector, setJobSector] = useState()
  const [jobType, setJobType] = useState("")

  useEffect(() => {
    if (mahallId) {
      dispatch(getMahallTypeLists(mahallId))
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Fill The Form</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-3">
                <AvField
                  name="job_office"
                  type="text"
                  label={language === "mal" ? "ഓഫീസ് പേര്" : "Office Name"}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="job_place"
                  type="text"
                  label={language === "mal" ? "ഓഫീസ് സ്ഥലം" : "Office Place :"}
                />
              </div>
              <div className="row mb-3">
                <Label for="basicpill-servicetax-input8">
                  {language === "mal" ? "തൊഴിൽ മേഖല" : "Job Sector"}
                </Label>
                <div className="d-flex flex-wrap">
                  {map(mahallTypeLists?.job_sector, (item, key) => (
                    <div key={key} className="mx-1">
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio5"
                        id={item.id}
                        autoComplete="off"
                        value={item.id}
                        checked={jobSector === item.id}
                        onClick={e =>
                          handleSelectOrDeselect(
                            jobSector,
                            setJobSector,
                            e.target.value
                          )
                        }
                        // onChange={e => setJobSector(e.target.value)}
                      />
                      <label className="btn btn-outline-info" htmlFor={item.id}>
                        {language === "mal" ? item?.malayalam : item?.english}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row mb-3">
                <Label for="basicpill-servicetax-input8">
                  {language === "mal" ? "ജോലി രീതി" : "Job Type"}
                </Label>
                <div className="d-flex flex-wrap">
                  {map(mahallTypeLists?.job_type, (item, key) => (
                    <div key={key} className="mx-1">
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio6"
                        id={item.id}
                        autoComplete="off"
                        value={item.id}
                        checked={jobType === item.id}
                        onClick={e =>
                          handleSelectOrDeselect(
                            jobType,
                            setJobType,
                            e.target.value
                          )
                        }
                        // onChange={e => setJobType(e.target.value)}
                      />
                      <label className="btn btn-outline-info" htmlFor={item.id}>
                        {language === "mal" ? item?.malayalam : item?.english}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row mb-3">
                <AvField
                  name="job_position"
                  type="text"
                  label={language === "mal" ? "ജോലി സ്ഥാനം" : "Job Position"}
                />
              </div>
              <div className="row mb-3">
                <AvField
                  name="monthly_income"
                  type="number"
                  label={
                    language === "mal" ? "പ്രതിമാസ വരുമാനം" : "Monthly Income"
                  }
                />
              </div>
              <div className="row w-100 justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  onClick={onCloseclick}
                >
                  {" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalJob
UiModalJob.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
