import React from "react"
import { useSelector } from "react-redux"
import propTypes from "prop-types"
import { CSVLink } from "react-csv"
// import img from "assets/images/khaf/tick.gif"
import { Modal, Button, Spinner } from "reactstrap"

import img from "assets/images/khaf/tick.jpg"

const UiModalExcelDownload = ({
  show,
  onCloseclick,
  excelData,
  headers,
  list,
  fileName,
  loading,
}) => {
  // const dispatch = useDispatch()

  const { language } = useSelector(state => ({
    language: state.Layout.language,
  }))

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            {/* <h5 className="modal-title mt-0">{language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create Collection"}</h5> */}
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {loading ? (
              <Spinner color="secondary" className="d-block m-auto" />
            ) : (
              <div className="d-flex justify-content-center mb-4">
                <img width="250px" src={img} alt="" />
              </div>
            )}
            <div className="row w-100 justify-content-end">
              {loading ? (
                <></>
              ) : (
                <Button
                  style={{ width: "200px", borderColor: "blue" }}
                  className="btn btn-white"
                  color="black"
                >
                  {loading && !list && !list?.length > 0 ? (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      <p>
                        {language === "mal"
                          ? "ഡൗൺലോഡ് ചെയ്യുന്നു..."
                          : "Downloading..."}
                      </p>
                    </>
                  ) : (
                    <CSVLink
                      data={excelData}
                      headers={headers}
                      filename={fileName}
                    >
                      <i
                        className="bx bx-download mx-1"
                        style={{ fontSize: "18px" }}
                      />
                      {language === "mal"
                        ? `ഡൗൺലോഡ് (${list?.length})`
                        : `Download (${list?.length})`}
                    </CSVLink>
                  )}
                </Button>
              )}
              {/* <Button
                type="submit"
                color="success"
                className="w-25"
              >{loading && (
                <>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                </>
              )}
                {" "}
                {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
              </Button> */}
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalExcelDownload
UiModalExcelDownload.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  excelData: propTypes.any,
  headers: propTypes.any,
  list: propTypes.any,
  fileName: propTypes.string,
  loading: propTypes.bool,
}
