import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_STUDENT_ATTENDANCES,
  GET_STUDENT_ATTENDANCE_DETAIL,
  CREATE_STUDENT_ATTENDANCE,
  UPDATE_STUDENT_ATTENDANCE,
  DELETE_STUDENT_ATTENDANCE,
  CREATE_WORKING_DAY,
} from "./actionTypes"
import {
  getStudentAttendancesSuccess,
  getStudentAttendancesFail,
  getStudentAttendanceDetailSuccess,
  getStudentAttendanceDetailFail,
  createStudentAttendanceSuccess,
  createStudentAttendanceFail,
  updateStudentAttendanceSuccess,
  updateStudentAttendanceFail,
  deleteStudentAttendanceSuccess,
  deleteStudentAttendanceFail,
  createWorkingDaySuccess,
  createWorkingDayFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"
import { getStudentDetailSuccess, getStudentDetailFail } from "store/actions"

const getStudentAttendancesAPi = ({ date, divisionId, page }) => {
  return post(
    `/api/v1/madrasa/madrasa-working-days/?from_date=${
      date?.from_date ? date?.from_date : ""
    }&to_date=${date?.to_date ? date?.to_date : ""}&division=${
      divisionId ? divisionId : ""
    }&page=${page ? page : 1}`
  )
}
const getStudentAttendanceDetailsAPi = ({ workingDayId, divisionId }) => {
  return post(
    `/api/v1/madrasa/madrasa-daily-attandance/?working_day=${
      workingDayId ? workingDayId : ""
    }&division=${divisionId ? divisionId : ""}`
  )
}
const getStudentDetailsAPi = studentId => {
  return get(`/madrasa/madrasa-period-attandance/${studentId}/`)
}
const createStudentAttendanceApi = ({ studentAttendance }) => {
  return post(
    "/api/v1/madrasa/madrasa-period-bulk-attandance/",
    studentAttendance
  )
}
const updateStudentAttendanceApi = ({
  studentAttendanceId,
  studentAttendance,
}) => {
  return ApiPut(
    `/api/v1/madrasa/madrasa-period-attandance/${studentAttendanceId}/`,
    studentAttendance
  )
}
const deleteStudentAttendanceApi = ({ studentAttendanceId }) => {
  return del(
    `/api/v1/madrasa/madrasa-period-attandance/${studentAttendanceId}/`
  )
}
const createWorkingDayApi = ({ instituteId, workingDate }) => {
  return post(
    `/api/v1/madrasa/calculate-daily-attandance/${instituteId}/?date=${workingDate}`
  )
}

function* fetchStudentAttendances({ payload }) {
  try {
    const response = yield call(getStudentAttendancesAPi, payload)
    if (response && !response?.error) {
      yield put(getStudentAttendancesSuccess(response))
    } else {
      yield put(getStudentAttendancesFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getStudentAttendancesFail(error))
  }
}

function* fetchStudentAttendanceDetail({ payload }) {
  try {
    const response = yield call(getStudentAttendanceDetailsAPi, payload)
    if (response && !response?.error) {
      yield put(getStudentAttendanceDetailSuccess(response))
    } else {
      yield put(getStudentAttendanceDetailFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getStudentAttendanceDetailFail(error))
  }
}
function* onCreateStudentAttendance({ payload }) {
  try {
    const response = yield call(createStudentAttendanceApi, payload)
    if (response && !response?.error) {
      yield put(createStudentAttendanceSuccess(response))
      doneNotification("Created Successfully")
    } else {
      yield put(createStudentAttendanceFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createStudentAttendanceFail(error))
    errorNotification()
  }
}

function* onUpdateStudentAttendance({ payload }) {
  try {
    const response = yield call(updateStudentAttendanceApi, payload)
    if (response && !response?.error) {
      yield put(updateStudentAttendanceSuccess(response))
      doneNotification("Updated Successfully")
      try {
        const response2 = yield call(
          getStudentDetailsAPi,
          response?.committee_meeting
        )
        yield put(getStudentDetailSuccess(response2))
      } catch (error) {
        yield put(getStudentDetailFail(error))
      }
    } else {
      yield put(updateStudentAttendanceFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateStudentAttendanceFail(error))
    errorNotification()
  }
}

function* onDeleteStudentAttendance({ payload }) {
  try {
    const response = yield call(deleteStudentAttendanceApi, payload)
    if (response && !response?.error) {
      yield put(deleteStudentAttendanceSuccess(payload.studentAttendanceId))
      doneNotification("Deleted Successfully")
      // try {
      //   const response2 = yield call(getStudentAttendancesAPi, payload)
      //   yield put(getStudentAttendancesSuccess(response2))
      // } catch (error) {
      //   yield put(getStudentAttendancesFail(error))
      // }
    } else {
      yield put(deleteStudentAttendanceFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteStudentAttendanceFail(error))
  }
}

function* onCreateWorkingDay({ payload }) {
  try {
    const response = yield call(createWorkingDayApi, payload)
    if (response && !response?.error) {
      yield put(createWorkingDaySuccess(response))
      doneNotification("Workingday created Successfully")
    } else {
      yield put(createWorkingDayFail(response?.error))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createWorkingDayFail(error))
    errorNotification()
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* StudentAttendancesSaga() {
  yield takeEvery(GET_STUDENT_ATTENDANCES, fetchStudentAttendances)
  yield takeEvery(GET_STUDENT_ATTENDANCE_DETAIL, fetchStudentAttendanceDetail)
  yield takeEvery(CREATE_STUDENT_ATTENDANCE, onCreateStudentAttendance)
  yield takeEvery(UPDATE_STUDENT_ATTENDANCE, onUpdateStudentAttendance)
  yield takeEvery(DELETE_STUDENT_ATTENDANCE, onDeleteStudentAttendance)
  yield takeEvery(CREATE_WORKING_DAY, onCreateWorkingDay)
}

export default StudentAttendancesSaga
