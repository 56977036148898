import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_UNAPPROVED_FAMILIES,
  GET_UNAPPROVED_FAMILY_DETAIL,
  UPDATE_UNAPPROVED_FAMILY,
  DELETE_UNAPPROVED_FAMILY,
  GET_UNAPPROVED_FAMILIES_CARDS,
  GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD,
} from "./actionTypes"
import {
  getUnapprovedFamiliesSuccess,
  getUnapprovedFamiliesFail,
  getUnapprovedFamilyDetailSuccess,
  getUnapprovedFamilyDetailFail,
  updateUnapprovedFamilySuccess,
  updateUnapprovedFamilyFail,
  deleteUnapprovedFamilySuccess,
  deleteUnapprovedFamilyFail,
  getUnapprovedFamiliesCardsSuccess,
  getUnapprovedFamiliesCardsFail,
  getUnapprovedFamiliesForDownloadSuccess,
  getUnapprovedFamiliesForDownloadFail,
} from "./actions"

import { get, del, patch } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getUnapprovedFamiliesAPi = ({ searchText, page, mahallId }) => {
  if (mahallId) {
    return get(
      `/api/v1/surveyapp/guest_family/?mahall=${mahallId && mahallId}&search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else if (searchText) {
    return get(
      `/api/v1/surveyapp/guest_family/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(`/api/v1/surveyapp/guest_family/?page=${page ? page : 1}`)
  }
}

const getUnapprovedFamiliesForDownloadAPi = ({
  searchText,
  page,
  mahallId,
}) => {
  if (mahallId) {
    return get(
      `/api/v1/surveyapp/guest_family/?mahall=${mahallId && mahallId}&search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else if (searchText) {
    return get(
      `/api/v1/surveyapp/guest_family/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(`/api/v1/surveyapp/guest_family/?page=${page ? page : 1}`)
  }
}

const getUnapprovedFamilyDetailsAPi = unapprovedFamilyId => {
  return get(`/api/v1/surveyapp/guest_family/${unapprovedFamilyId}/`)
}

const getUnapprovedFamiliesCardsAPi = () => {
  return get(`/api/v1/surveyapp/guest_family/?cards=all`)
}

const updateUnapprovedFamilyApi = ({
  unapprovedFamilyId,
  unapprovedFamily,
}) => {
  return patch(
    `/api/v1/surveyapp/guest_family/${unapprovedFamilyId}/`,
    unapprovedFamily
  )
}
const deleteUnapprovedFamilyApi = ({ unapprovedFamilyId }) => {
  return del(`/api/v1/surveyapp/guest_family/${unapprovedFamilyId}/`)
}

function* fetchUnapprovedFamilies({ payload }) {
  try {
    const response = yield call(getUnapprovedFamiliesAPi, payload)
    if (response && !response?.error) {
      yield put(getUnapprovedFamiliesSuccess(response))
    } else {
      yield put(getUnapprovedFamiliesFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getUnapprovedFamiliesFail(error))
  }
}

function* fetchUnapprovedFamiliesForDownload({ payload }) {
  try {
    const response = yield call(getUnapprovedFamiliesForDownloadAPi, payload)
    if (response && !response?.error) {
      yield put(getUnapprovedFamiliesForDownloadSuccess(response))
    } else {
      yield put(getUnapprovedFamiliesForDownloadFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getUnapprovedFamiliesForDownloadFail(error))
  }
}

function* fetchUnapprovedFamilyDetail({ unapprovedFamilyId }) {
  try {
    const response = yield call(
      getUnapprovedFamilyDetailsAPi,
      unapprovedFamilyId
    )
    if (response && !response?.error) {
      yield put(getUnapprovedFamilyDetailSuccess(response))
    } else {
      yield put(getUnapprovedFamilyDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getUnapprovedFamilyDetailFail(error))
  }
}

function* fetchUnapprovedFamiliesCards() {
  try {
    const response = yield call(getUnapprovedFamiliesCardsAPi)
    if (response && !response?.error) {
      yield put(getUnapprovedFamiliesCardsSuccess(response))
    } else {
      yield put(getUnapprovedFamiliesCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getUnapprovedFamiliesCardsFail(error))
  }
}

function* onUpdateUnapprovedFamily({ payload }) {
  try {
    const response = yield call(updateUnapprovedFamilyApi, payload)
    if (response && !response?.error) {
      yield put(updateUnapprovedFamilySuccess(response))
      // {
      //   response?.is_approved
      //     ? payload.history.push(`/families/${response?.id}`)
      //     : payload.history.push(`/unapprovedfamilies`)
      // }
      doneNotification("Updated Successfully")
      try {
        const response2 = yield call(getUnapprovedFamiliesAPi, { page: 1 })
        yield put(getUnapprovedFamiliesSuccess(response2))
      } catch (error) {
        yield put(getUnapprovedFamiliesFail(error))
      }
    } else {
      yield put(updateUnapprovedFamilyFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateUnapprovedFamilyFail(error))
    // if (error?.response?.data?.error[0]) {
    //   yield put(updateFamilyFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function* onDeleteUnapprovedFamily({ payload }) {
  try {
    const response = yield call(deleteUnapprovedFamilyApi, payload)
    payload.history.push("/families")
    if (response && !response?.error) {
      yield put(deleteUnapprovedFamilySuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteUnapprovedFamilyFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(deleteUnapprovedFamilyFail(error))
    // if (error?.response?.data?.error[0]) {
    //   yield put(deleteFamilyFail(error))
    //   errorNotification(error?.response?.data?.error[0])
    // }
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* UnapprovedFamiliesSaga() {
  yield takeEvery(GET_UNAPPROVED_FAMILIES, fetchUnapprovedFamilies)
  yield takeEvery(
    GET_UNAPPROVED_FAMILIES_FOR_DOWNLOAD,
    fetchUnapprovedFamiliesForDownload
  )
  yield takeEvery(GET_UNAPPROVED_FAMILY_DETAIL, fetchUnapprovedFamilyDetail)
  yield takeEvery(GET_UNAPPROVED_FAMILIES_CARDS, fetchUnapprovedFamiliesCards)
  yield takeEvery(UPDATE_UNAPPROVED_FAMILY, onUpdateUnapprovedFamily)
  yield takeEvery(DELETE_UNAPPROVED_FAMILY, onDeleteUnapprovedFamily)
}

export default UnapprovedFamiliesSaga
