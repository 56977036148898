import {
  GET_SUBJECTS,
  UPDATE_SUBJECT,
  CREATE_SUBJECT,
  DELETE_SUBJECT,
  GET_SUBJECT_DETAIL,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAIL,
  CREATE_SUBJECT_SUCCESS,
  CREATE_SUBJECT_FAIL,
  GET_SUBJECT_DETAIL_SUCCESS,
  GET_SUBJECT_DETAIL_FAIL,
  UPDATE_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_FAIL,
  DELETE_SUBJECT_SUCCESS,
  DELETE_SUBJECT_FAIL,
  SUBJECTS_DROPDOWN,
  SUBJECTS_DROPDOWN_SUCCESS,
  SUBJECTS_DROPDOWN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  subjects: [],
  subjectList: [],
  subjectDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdSubject: {},
}

const Subjects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBJECTS_DROPDOWN:
    case GET_SUBJECTS:
    case UPDATE_SUBJECT:
    case CREATE_SUBJECT:
    case DELETE_SUBJECT:
      return {
        ...state,
        loading: true,
      }
    case GET_SUBJECT_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: action.payload,
        loading: false,
      }
    case GET_SUBJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case SUBJECTS_DROPDOWN_SUCCESS:
      return {
        ...state,
        subjectList: action.payload,
        loading: false,
      }
    case SUBJECTS_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        subjects: {
          ...state.subjects,
          results: [...state.subjects.results, action.payload],
        },
        subjectList: [...state.subjectList, action.payload],
        createdSubject: action.payload,
        loading: false,
      }

    case CREATE_SUBJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SUBJECT_DETAIL_SUCCESS:
      return {
        ...state,
        subjectDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_SUBJECT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        subjects: {
          results: state.subjects.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_SUBJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        subjects: {
          results: state.subjects.results.filter(
            subject => subject.id !== action.payload
          ),
        },
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        loading: false,
      }

    case DELETE_SUBJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Subjects
