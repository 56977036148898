import React, { useEffect } from "react"
import { Col, Row, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import CardContact from "../card-contact"

import group3Image from "assets/images/home/group3.png"
import mapImage from "assets/images/home/map.png"
import settingsImage from "assets/images/home/settings.png"
import enquiriesImage from "assets/images/home/enquiry.png"
import usersImage from "assets/images/home/familymembers.png"
import { getDashboardCard } from "store/actions"

function AdminDashboardContainer() {
  const dispatch = useDispatch()

  const {
    // userDetail,
    language,
    mahallUsersCardsLoading,
    familiesCardsLoading,
    dashboardCard,
  } = useSelector(state => ({
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    familiesCardsLoading: state.Families.loading,
    mahallUsersCardsLoading: state.MahallUsers.loading,
    dashboardCard: state.Dashboard.dashboardCard,
  }))

  useEffect(() => {
    dispatch(getDashboardCard())
  }, [dispatch])

  const cardData = [
    {
      title: `${language === "mal" ? "യൂസറുകൾ" : "Enquiries"}`,
      cardValue: dashboardCard?.enquiries ?? 0,
      iconClass: "phone",
      routeLink: "/enquiries",
      loading: mahallUsersCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "ഗ്രൂപ്പുകൾ" : "Groups"}`,
      cardValue: dashboardCard?.groups ?? 0,
      iconClass: "globe",
      routeLink: "/groups",
      loading: mahallUsersCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "ഗ്രൂപ്പ് അഡ്മിൻസ്" : "Group Admins"}`,
      cardValue: dashboardCard?.groups_admins ?? 0,
      iconClass: "globe",
      routeLink: "/groupadmins",
      loading: mahallUsersCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "മഹല്ലുകൾ" : "Mahalls"}`,
      cardValue: dashboardCard?.mahalls ?? 0,
      iconClass: "map",
      routeLink: "/mahalls",
      loading: mahallUsersCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "പ്രീമിയം മഹല്ലുകൾ" : "Premium Mahalls"}`,
      cardValue: dashboardCard?.premium_mahalls_count ?? 0,
      iconClass: "map",
      routeLink: "/mahalls",
      loading: mahallUsersCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Inactive Users"}`,
    },
    {
      title: `${language === "mal" ? "ബേസിക് മഹല്ലുകൾ" : "Basic Mahalls"}`,
      cardValue: dashboardCard?.basic_mahalls_count ?? 0,
      iconClass: "map",
      routeLink: "/mahalls",
      loading: mahallUsersCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Inactive Users"}`,
    },
    {
      title: `${language === "mal" ? "മഹല്ല് യൂസറുകൾ" : "Mahall Users"}`,
      cardValue: dashboardCard?.mahall_users ?? 0,
      iconClass: "user",
      routeLink: "/mahallusers",
      loading: mahallUsersCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "കുടുംബങ്ങൾ" : "Families"}`,
      cardValue: dashboardCard?.families ?? 0,
      iconClass: "home",
      routeLink: "/families",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Active Users"}`,
    },
    {
      title: `${language === "mal" ? "കുടുംബാംഗങ്ങൾ" : "Members"}`,
      cardValue: dashboardCard?.members ?? 0,
      iconClass: "group",
      routeLink: "/familymembers",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Inactive Users"}`,
    },
    {
      title: `${language === "mal" ? "സർവേ യൂസറുകൾ" : "Survey Users"}`,
      cardValue: dashboardCard?.survey_users ?? 0,
      iconClass: "user",
      routeLink: "/surveyusers",
      loading: familiesCardsLoading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Inactive Users"}`,
    },
  ]
  return (
    <Row>
      <Col lg="12" className="">
        <Row style={{ minHeight: "52vh", height: "auto" }}>
          {/* Mahall User Dashboard */}
          {/* <Col lg="4">
            <AdminWelcomeComp />
          </Col> */}
          <Col lg="12">
            <Row>
              {cardData?.map((item, key) => (
                <Col sm="12" md="6" lg="3" key={key} className="sm:p-0">
                  <Card className="blog-stats-wid">
                    <CardBody>
                      <Link to={item.routeLink && item.routeLink}>
                        <div className="d-flex flex-wrap">
                          <div className="me-3">
                            <p className="text-muted mb-3">{item.title}</p>
                            {item.cardValue >= 0 && (
                              <h5 className="mb-0">{item.cardValue}</h5>
                            )}
                            {/* <p className="m-0 text-info">
                              {item.cardValue !== "" ? <></> : item.alt}
                            </p> */}
                          </div>

                          <div className="avatar-sm ms-auto">
                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                              <i className={"bx bx-" + `${item.iconClass}`}></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          {/* <Col lg={3}>
            <HealthChart />
          </Col>
          <Col lg={6}>
            <DiseaseChart />
          </Col>
          <Col lg={3}>
            <PieChart />
          </Col> */}
        </Row>
        <Row className="m-2 mt-0">
          {/* <CardContact image={profileImage} title='Profile' link='/' /> */}
          <CardContact
            image={enquiriesImage}
            title="Enquiries"
            link="/enquiries"
          />
          <CardContact image={mapImage} title="Mahalls" link="/mahalls" />
          <CardContact image={group3Image} title="Groups" link="/groups" />
          <CardContact image={usersImage} title="Users" link="/mahallusers" />
          {/* <CardContact
            image={notificationImage}
            title="Notifications"
            link="/notifications"
          /> */}
          <CardContact
            image={settingsImage}
            title="Settings"
            link="/settings"
          />
        </Row>
      </Col>
    </Row>
  )
}

export default AdminDashboardContainer
