import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Media } from "reactstrap"

const CardBox = props => {
  return (
    <React.Fragment>
      {props.coins.map((coin, key) => (
        <Card key={key} style={{ maxWidth: "330px" }} className="p-1 card-poup">
          <CardBody>
            <Media>
              <div className="avatar-xs me-3">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft bg-" +
                    coin.color +
                    " text-" +
                    coin.color +
                    " font-size-18"
                  }
                >
                  <i className={coin.icon} />
                </span>
              </div>
              <Media body>
                <h6 className="">
                  <strong>{coin.title}</strong>
                </h6>
                <p className="text-muted font-size-12  mb-0">
                  {coin?.description}
                </p>
              </Media>
            </Media>
          </CardBody>
        </Card>
      ))}
    </React.Fragment>
  )
}

CardBox.propTypes = {
  coins: PropTypes.array,
}

export default CardBox
