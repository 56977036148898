import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_INSTITUTE_WALLET_CONTRA_LIST,
  CREATE_INSTITUTE_WALLET_CONTRA,
} from "./actionTypes"
import {
  getInstituteWalletContraListSuccess,
  getInstituteWalletContraListFail,
  createInstituteWalletContraSuccess,
  createInstituteWalletContraFail,
} from "./actions"

import { get, post } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getInstituteWalletContraListAPi = ({ walletId, searchText, page }) => {
  if (walletId) {
    return get(
      `/api/v1/institute/institute_wallet_contra/?institute_wallet=${
        walletId ? walletId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/institute/institute_wallet_contra/?search=${
        searchText ? searchText : ""
      }&page=${page ? page : 1}`
    )
  }
}
const createInstituteWalletContraApi = ({ instituteWalletContra }) => {
  return post(
    "/api/v1/institute/institute_wallet_contra/",
    instituteWalletContra
  )
}

function* fetchInstituteWalletContraList({ payload }) {
  try {
    const response = yield call(getInstituteWalletContraListAPi, payload)
    if (response && !response?.error) {
      yield put(getInstituteWalletContraListSuccess(response))
    } else {
      yield put(getInstituteWalletContraListFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getInstituteWalletContraListFail(error))
    errorNotification()
  }
}

function* onCreateInstituteWalletContra({ payload }) {
  try {
    const response = yield call(createInstituteWalletContraApi, payload)
    if (response && !response?.error) {
      yield put(createInstituteWalletContraSuccess(response))
      doneNotification("Added Successfully")
    } else {
      yield put(createInstituteWalletContraFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createInstituteWalletContraFail(error))
    errorNotification()
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* InstituteWalletContraSaga() {
  yield takeEvery(
    GET_INSTITUTE_WALLET_CONTRA_LIST,
    fetchInstituteWalletContraList
  )
  yield takeEvery(CREATE_INSTITUTE_WALLET_CONTRA, onCreateInstituteWalletContra)
}

export default InstituteWalletContraSaga
