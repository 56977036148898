import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_INSTITUTE_ACCOUNTS,
  GET_INSTITUTE_ACCOUNT_DETAIL,
  CREATE_INSTITUTE_ACCOUNT,
  UPDATE_INSTITUTE_ACCOUNT,
  DELETE_INSTITUTE_ACCOUNT,
  GET_ACCOUNT_ITEM_DETAIL,
  CREATE_ACCOUNT_ITEM,
  UPDATE_ACCOUNT_ITEM,
  DELETE_ACCOUNT_ITEM,
} from "./actionTypes"
import {
  getInstituteAccountsSuccess,
  getInstituteAccountsFail,
  getInstituteAccountDetailSuccess,
  getInstituteAccountDetailFail,
  createInstituteAccountSuccess,
  createInstituteAccountFail,
  updateInstituteAccountSuccess,
  updateInstituteAccountFail,
  deleteInstituteAccountSuccess,
  deleteInstituteAccountFail,
  getAccountItemDetailSuccess,
  getAccountItemDetailFail,
  createAccountItemSuccess,
  createAccountItemFail,
  updateAccountItemSuccess,
  updateAccountItemFail,
  deleteAccountItemSuccess,
  deleteAccountItemFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getInstituteAccountsAPi = ({ searchText }) => {
  if (searchText) {
    return get(
      `/api/v1/institute/institute_account/?search=${searchText && searchText}`
    )
  } else {
    return get(`/api/v1/institute/institute_account/`)
  }
}
const getInstituteAccountDetailsAPi = instituteAccountId => {
  return get(`/api/v1/institute/institute_account/${instituteAccountId}/`)
}
const createInstituteAccountApi = ({ instituteAccount }) => {
  return post("/api/v1/institute/institute_account/", instituteAccount)
}
const updateInstituteAccountApi = ({
  instituteAccountId,
  instituteAccount,
}) => {
  return ApiPut(
    `/api/v1/institute/institute_account/${instituteAccountId}/`,
    instituteAccount
  )
}
const deleteInstituteAccountApi = ({ instituteAccountId }) => {
  return del(`/api/v1/institute/institute_account/${instituteAccountId}/`)
}

// const getAccountItemDetailsAPi = accountItemId => {
//   return get(`/api/v1/institute/institute_account_item${accountItemId}/`)
// }
const createAccountItemApi = ({ accountItem }) => {
  return post("/api/v1/institute/institute_account_item", accountItem)
}
const updateAccountItemApi = ({ accountItemId, accountItem }) => {
  return ApiPut(
    `/api/v1/institute/institute_account_item${accountItemId}/`,
    accountItem
  )
}
// const deleteAccountItemApi = ({ accountItemId }) => {
//   return del(`/api/v1/institute/institute_account_item${accountItemId}/`)
// }

function* fetchInstituteAccounts({ payload }) {
  try {
    const response = yield call(getInstituteAccountsAPi, payload)
    yield put(getInstituteAccountsSuccess(response))
  } catch (error) {
    yield put(getInstituteAccountsFail(error))
  }
}

function* fetchInstituteAccountDetail({ instituteAccountId }) {
  try {
    const response = yield call(
      getInstituteAccountDetailsAPi,
      instituteAccountId
    )
    yield put(getInstituteAccountDetailSuccess(response))
  } catch (error) {
    yield put(getInstituteAccountDetailFail(error))
  }
}
function* onCreateInstituteAccount({ payload }) {
  try {
    const response = yield call(createInstituteAccountApi, payload)
    if (
      response.email[0] === "Institute Account with this email already exists."
    ) {
      yield put(createInstituteAccountFail(response.email))
    } else {
      yield put(createInstituteAccountSuccess(response))
      payload.history.push("/instituteaccounts")
      doneNotification()
    }
  } catch (error) {
    yield put(createInstituteAccountFail(error))
    errorNotification()
  }
}

function* onUpdateInstituteAccount({ payload }) {
  try {
    const response = yield call(updateInstituteAccountApi, payload)
    yield put(updateInstituteAccountSuccess(response))
    doneNotification()
  } catch (error) {
    yield put(updateInstituteAccountFail(error))
    errorNotification()
  }
}

function* onDeleteInstituteAccount({ payload }) {
  try {
    const response = yield call(deleteInstituteAccountApi, payload)
    payload.history.push("/instituteaccounts")
    yield put(deleteInstituteAccountSuccess(response))
    doneNotification()
  } catch (error) {
    errorNotification()
    yield put(deleteInstituteAccountFail(error))
  }
}

function* fetchAccountItemDetail({
  instituteAccountId,
  getAccountitemDetailsAPi,
}) {
  try {
    const response = yield call(getAccountitemDetailsAPi, instituteAccountId)
    yield put(getAccountItemDetailSuccess(response))
  } catch (error) {
    yield put(getAccountItemDetailFail(error))
  }
}
function* onCreateAccountItem({ payload }) {
  try {
    const response = yield call(createAccountItemApi, payload)
    if (response.email[0] === "Account Item with this email already exists.") {
      yield put(createAccountItemFail(response.email))
    } else {
      yield put(createAccountItemSuccess(response))
      payload.history.push("/instituteaccounts")
      doneNotification()
    }
  } catch (error) {
    yield put(createAccountItemFail(error))
    errorNotification()
  }
}

function* onUpdateAccountItem({ payload }) {
  try {
    const response = yield call(updateAccountItemApi, payload)
    yield put(updateAccountItemSuccess(response))
    doneNotification()
  } catch (error) {
    yield put(updateAccountItemFail(error))
    errorNotification()
  }
}

function* onDeleteAccountItem({ payload }) {
  try {
    const response = yield call(deleteInstituteAccountApi, payload)
    payload.history.push("/instituteaccounts")
    yield put(deleteAccountItemSuccess(response))
    doneNotification()
  } catch (error) {
    errorNotification()
    yield put(deleteAccountItemFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification() {
  Notification({
    type: "success",
    message: "Done",
    title: "",
  })
}

function* InstituteAccountsSaga() {
  yield takeEvery(GET_INSTITUTE_ACCOUNTS, fetchInstituteAccounts)
  yield takeEvery(GET_INSTITUTE_ACCOUNT_DETAIL, fetchInstituteAccountDetail)
  yield takeEvery(CREATE_INSTITUTE_ACCOUNT, onCreateInstituteAccount)
  yield takeEvery(UPDATE_INSTITUTE_ACCOUNT, onUpdateInstituteAccount)
  yield takeEvery(DELETE_INSTITUTE_ACCOUNT, onDeleteInstituteAccount)
  yield takeEvery(GET_ACCOUNT_ITEM_DETAIL, fetchAccountItemDetail)
  yield takeEvery(CREATE_ACCOUNT_ITEM, onCreateAccountItem)
  yield takeEvery(UPDATE_ACCOUNT_ITEM, onUpdateAccountItem)
  yield takeEvery(DELETE_ACCOUNT_ITEM, onDeleteAccountItem)
}

export default InstituteAccountsSaga
