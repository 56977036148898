import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
//actions
import axios from "axios"
import { Image } from "react-bootstrap"
import moment from "moment"

import {
  createInstituteSuccess,
  createInstituteFail,
  createMadrasa,
  clearCreatedInstitute,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import { API_URL } from "helpers/api_methods"
import {
  // doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { useFileSizes } from "hooks/useFileSize"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const CreateMahallMadrasa = ({ history }) => {
  const dispatch = useDispatch()
  const handleFileSizes = useFileSizes

  const { loading, mahallId, language, createdInstitute } = useSelector(
    state => ({
      loading: state.Institutes.loading,
      error: state.Institutes.error,
      mahallId: state.Users.userDetail?.mahall?.id,
      language: state.Layout.language,
      createdInstitute: state.Institutes.createdInstitute,
    })
  )

  const today = moment().format("YYYY-MM-DD")

  const instituteType = "madrasa"

  const [sunday, setSunday] = useState(false)
  const [monday, setMonday] = useState(false)
  const [tuesday, setTuesday] = useState(false)
  const [wednsday, setWednsday] = useState(false)
  const [thursday, setThursday] = useState(false)
  const [friday, setFriday] = useState(false)
  const [saturday, setSaturday] = useState(false)

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })

  const [imagePreview, setImagePreview] = useState()

  const [filesSize, setFilesSize] = useState()

  const handleChange = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    onSubmitProps.preventDefault()

    setCustomLoad(true)
    createNewInstitute(
      filesSize,
      selectedFile,
      values,
      mahallId,
      instituteType,
      dispatch,
      // history,
      setCustomLoad
    )
    setImagePreview()
  }

  const handleValidSubmitMadrasaDetails = (onSubmitProps, v) => {
    onSubmitProps.preventDefault()
    const details = {
      institute: createdInstitute?.id,
      periods_per_day: v?.periods_per_day,
      sadhar_muallim: v?.sadhar_muallim,
      varisankya_period: v?.varisankya_period,
      sunday: sunday,
      monday: monday,
      tuesday: tuesday,
      wednesday: wednsday,
      thursday: thursday,
      friday: friday,
      saturday: saturday,
    }
    dispatch(createMadrasa(details, history))
    dispatch(clearCreatedInstitute())
  }

  return (
    <>
      <MetaTags>
        <title>Madrasa | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "മദ്രസ" : "Madrasa"}
          breadcrumbItem={language === "mal" ? "പുതിയത് ചേർക്കുക" : "Create"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">
                      {language === "mal"
                        ? "ഈ ഫോം പൂരിപ്പിക്കുക"
                        : "Fill this form"}
                    </CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                      // contentType="multipart/form-data"
                    >
                      <div className="row mb-4">
                        <Col>
                          <Label>
                            {language === "mal"
                              ? "പേര് (ഇംഗ്ലീഷ്)"
                              : "Name (English)"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="institute_english_name"
                            type="text"
                            // label="Name (English)"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col className="mb-3">
                          <AvField
                            name="institute_malayalam_name"
                            type="text"
                            label={
                              language === "mal"
                                ? "പേര് (മലയാളം)"
                                : "Name (മലയാളം)"
                            }
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Col sm="12" md="6">
                          <AvField
                            name="institute_place"
                            type="text"
                            label={
                              language === "mal" ? "സ്ഥലം" : "Madrasa Place"
                            }
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <AvField
                            name="established_date"
                            value={today}
                            type="date"
                            label={
                              language === "mal"
                                ? "സ്ഥാപിച്ച തീയതി"
                                : "Established Date"
                            }
                          />
                        </Col>
                      </div>

                      <div className="row mb-4 mt-2">
                        <Col md="12">
                          <AvField
                            name="description"
                            type="textarea"
                            label={
                              language === "mal" ? "വിവരണം" : "Description"
                            }
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <Label>{language === "mal" ? "ലോഗോ" : "Logo"} </Label>
                          <AvField
                            name="logo"
                            type="file"
                            onChange={handleChange}
                          />
                          {handleFileSizes(filesSize)?.text && (
                            <p className="text-danger mt-1">
                              {handleFileSizes(filesSize)?.text}
                            </p>
                          )}
                          {imagePreview && (
                            <Image
                              style={{ width: "150px", height: "150px" }}
                              className="my-2"
                              src={imagePreview?.image}
                            />
                          )}
                        </Col>
                      </div>
                      {!createdInstitute?.id && (
                        <div className="justify-content-end d-flex mb-4">
                          <Button
                            type="submit"
                            color={
                              instituteType === "madrasa"
                                ? "primary"
                                : "success"
                            }
                            className="w-md"
                          >
                            {loading ||
                              (customLoad && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              ))}
                            {language === "mal" ? "സൂക്ഷിക്കുക" : "Save"}
                          </Button>
                        </div>
                      )}
                    </AvForm>
                    {createdInstitute?.id && (
                      <AvForm
                        className="form-horizontal "
                        onValidSubmit={(onSubmitProps, v) => {
                          handleValidSubmitMadrasaDetails(onSubmitProps, v)
                        }}
                      >
                        <Row className="mb-3">
                          <Col md="6">
                            <AvField
                              name="periods_per_day"
                              type="number"
                              label={
                                language === "mal"
                                  ? "പ്രതിദിന പിരീഡുകൾ"
                                  : "Periods Per Day"
                              }
                            />
                          </Col>
                          <Col md="6">
                            <AvField
                              name="sadhar_muallim"
                              type="text"
                              label={
                                language === "mal"
                                  ? "സദർ മുഅല്ലിം"
                                  : "Sadar Muallim"
                              }
                            />
                          </Col>
                        </Row>
                        <Col sm="12" md="6">
                          <Label>
                            {language === "mal"
                              ? "വരിസംഖ്യ പീരീഡ്"
                              : "Varisangya Period"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="varisankya_period"
                            type="number"
                            value="1"
                            validate={{
                              required: { value: true },
                              max: {
                                value: 12,
                                errorMessage: `${
                                  language === "mal"
                                    ? "തുക 13-ൽ കുറവായിരിക്കണം"
                                    : "Amount must be less than 13"
                                }`,
                              },
                            }}
                          />
                        </Col>
                        <Row className="mt-5">
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Sunday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={sunday}
                                onChange={() => {}}
                                onClick={() => setSunday(!sunday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Monday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={monday}
                                onChange={() => {}}
                                onClick={() => setMonday(!monday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Tuesday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={tuesday}
                                onChange={() => {}}
                                onClick={() => setTuesday(!tuesday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Wednsday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={wednsday}
                                onChange={() => {}}
                                onClick={() => setWednsday(!wednsday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Thursday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={thursday}
                                onChange={() => {}}
                                onClick={() => setThursday(!thursday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Friday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={friday}
                                onChange={() => {}}
                                onClick={() => setFriday(!friday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Saturday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={saturday}
                                onChange={() => {}}
                                onClick={() => setSaturday(!saturday)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="d-flex mb-4 justify-content-end mt-2">
                          <Button
                            type="submit"
                            color="success"
                            className="w-md"
                          >
                            {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                          </Button>
                        </div>
                      </AvForm>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateMahallMadrasa

CreateMahallMadrasa.propTypes = {
  history: PropTypes.object,
}

function createNewInstitute(
  filesSize,
  selectedFile,
  values,
  mahallId,
  instituteType,
  dispatch
  // history,
  // setCustomLoad,
) {
  const formData = new FormData()
  const handleFileSizes = useFileSizes
  {
    handleFileSizes(filesSize)?.status === true &&
      selectedFile &&
      selectedFile?.image &&
      formData.append("logo", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("mahall", mahallId)
  formData.append("institute_english_name", values.institute_english_name)
  formData.append("institute_malayalam_name", values.institute_malayalam_name)
  formData.append("established_date", values.established_date)
  formData.append("institute_type", instituteType)
  formData.append("institute_place", values.institute_place)
  formData.append("description", values.description)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .post(`${API_URL}/api/v1/institute/institute/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        dispatch(createInstituteSuccess(res.data))
        // history.push('/madrasas')
        // doneNotification('Created Successfully')
        // setCustomLoad(false)
      } else {
        // createInstituteFail(err)
        errorNotification(res?.error)
      }
    })
    .catch(err => {
      createInstituteFail(err)
      errorNotification()
      // setCustomLoad(false)
    })
}
