import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
//actions
// import moment from 'moment'
import Select from "react-select"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

import {
  updateStudent,
  // getAllFamilyMembersDropdown,
  // getFamilyMemberDetail,
  getStudentDetail,
  deleteStudent,
  divisionDropdown,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

const InstituteUpdateStudent = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, language, studentDetail, allDivisions } = useSelector(
    state => ({
      loading: state.Mahalls.loading,
      language: state.Layout.language,
      studentDetail: state.Students.studentDetail,
      allDivisions: state.Divisions.allDivisions,
    })
  )

  const [searchTextDivision, setSearchTextDivision] = useState("")
  const [division, setDivision] = useState("Select division...")
  const [divisionId, setDivisionId] = useState()
  // const [classId, setClassId] = useState()

  // console.log(classId)

  // useEffect(() => {
  //   setClassId(divisionDetail?.madrasa_class)
  // }, [divisionDetail])

  useEffect(() => {
    setDivision(studentDetail?.division_name)
    setDivisionId(studentDetail?.division)
  }, [studentDetail])

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const updateStudentsData = {
      family_member: studentDetail?.family_member,
      division: studentDetail?.division ? studentDetail?.division : divisionId,
      name: values?.name,
      dob: values?.dob,
      roll_no: parseInt(values?.roll_no),
      parent_name: values?.parent_name,
      parent_phone: values?.parent_phone,
      varisankya_amount: parseInt(values?.varisankya_amount),
      varisankya_wallet: parseInt(values?.varisankya_wallet),
    }
    dispatch(updateStudent(updateStudentsData, studentDetail?.id, history))
  }

  useEffect(() => {
    dispatch(divisionDropdown(searchTextDivision))
  }, [dispatch, searchTextDivision])

  const optionGroup = [
    {
      options: allDivisions?.map((result, index) => ({
        key: index,
        label: result.division_name,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setDivisionId(event.value)
    setDivision(event.label)
  }

  const handleEnters = textEntered => {
    setSearchTextDivision(textEntered)
  }

  useEffect(() => {
    dispatch(getStudentDetail(params?.id))
  }, [dispatch])

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(deleteStudent(studentDetail?.id, history))
    setIsOpen(false)
  }

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <MetaTags>
        <title>Students | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "വിദ്യാർത്ഥികൾ" : "Students"}
          breadcrumbItem={language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക" : "Edit"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md={8}>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4 mb-4">Fill this form</CardTitle> */}
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="col-md-6">
                        <div className="col mb-4">
                          <Label
                            htmlFor="horizontal-firstname-Input"
                            className="col-sm-12 col-form-label"
                          >
                            {language === "mal" ? "ഡിവിഷൻ" : "Division"}
                          </Label>
                          <Select
                            onInputChange={handleEnters}
                            placeholder={division}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="Family Member"
                          />
                        </div>
                      </div>
                      <Row>
                        <Col md="6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal" ? "പേര്" : "Name"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="name"
                              value={studentDetail?.name}
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                },
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="row mb-4">
                            <div className="col-md-6">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-12 col-form-label"
                              >
                                {language === "mal" ? "ക്രമ നം." : "Roll No."}
                              </Label>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="roll_no"
                                type="text"
                                value={studentDetail?.roll_no}
                              />
                            </div>
                            <div className="col-md-6">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-12 col-form-label"
                              >
                                {language === "mal" ? "ഡി ഒ ബി" : "DOB"}
                              </Label>
                              <AvField
                                id="horizontal-firstname-Input"
                                name="dob"
                                value={studentDetail?.dob}
                                type="date"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "രക്ഷിതാവിന്റെ പേര്"
                                : "Parent Name"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="parent_name"
                              value={studentDetail?.parent_name}
                              type="text"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "രക്ഷിതാവിന്റെ ഫോൺ നമ്പർ"
                                : "Parent Phone"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="parent_phone"
                              type="number"
                              value={studentDetail?.parent_phone}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "വരിസംഖ്യ തുക"
                                : "Varisankya Amount"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="varisankya_amount"
                              type="number"
                              value={studentDetail?.varisankya_amount}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="col mb-4">
                            <Label
                              htmlFor="horizontal-firstname-Input"
                              className="col-sm-12 col-form-label"
                            >
                              {language === "mal"
                                ? "വരിസംഖ്യ വാലറ്റ്"
                                : "Varisankya Wallet"}
                            </Label>
                            <AvField
                              id="horizontal-firstname-Input"
                              name="varisankya_wallet"
                              type="number"
                              value={studentDetail?.varisankya_wallet}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                        <div className="col-md-12">
                          <div className="col mb-4">
                            <AvField
                              id="horizontal-firstname-Input"
                              name="description"
                              type="textarea"
                              value={studentDetail?.description}
                              label={
                                language === 'mal' ? 'വിവരണം' : 'Description'
                              }
                            />
                          </div>
                        </div>
                      </Row> */}

                      <div className="d-flex justify-content-end mt-5">
                        <Button
                          type="button"
                          color="danger"
                          className="w-md mx-3"
                          onClick={handleDelete}
                        >
                          {language === "mal" ? "ഇല്ലാതാക്കുക" : "Delete"}
                        </Button>
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക" : "Update"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default InstituteUpdateStudent

InstituteUpdateStudent.propTypes = {
  history: PropTypes.object,
}
