/* STUDENT_VARISANGYA_WALLET */
export const GET_STUDENT_VARISANGYA_WALLETS = "GET_STUDENT_VARISANGYA_WALLETS"
export const GET_STUDENT_VARISANGYA_WALLETS_SUCCESS =
  "GET_STUDENT_VARISANGYA_WALLETS_SUCCESS"
export const GET_STUDENT_VARISANGYA_WALLETS_FAIL =
  "GET_STUDENT_VARISANGYA_WALLETS_FAIL"

/**
 * add STUDENT_VARISANGYA_WALLET
 */
export const CREATE_STUDENT_VARISANGYA_WALLET =
  "CREATE_STUDENT_VARISANGYA_WALLET"
export const CREATE_STUDENT_VARISANGYA_WALLET_SUCCESS =
  "CREATE_STUDENT_VARISANGYA_WALLET_SUCCESS"
export const CREATE_STUDENT_VARISANGYA_WALLET_FAIL =
  "CREATE_STUDENT_VARISANGYA_WALLET_FAIL"

/**
 * STUDENT_VARISANGYA_WALLET_DETAIL
 */
export const GET_STUDENT_VARISANGYA_WALLET_DETAIL =
  "GET_STUDENT_VARISANGYA_WALLET_DETAIL"
export const GET_STUDENT_VARISANGYA_WALLET_DETAIL_SUCCESS =
  "GET_STUDENT_VARISANGYA_WALLET_DETAIL_SUCCESS"
export const GET_STUDENT_VARISANGYA_WALLET_DETAIL_FAIL =
  "GET_STUDENT_VARISANGYA_WALLET_DETAIL_FAIL"

/**
 * STUDENT_VARISANGYA_WALLET_VIEW
 */
export const GET_STUDENT_VARISANGYA_WALLET_VIEW =
  "GET_STUDENT_VARISANGYA_WALLET_VIEW"
export const GET_STUDENT_VARISANGYA_WALLET_VIEW_SUCCESS =
  "GET_STUDENT_VARISANGYA_WALLET_VIEW_SUCCESS"
export const GET_STUDENT_VARISANGYA_WALLET_VIEW_FAIL =
  "GET_STUDENT_VARISANGYA_WALLET_VIEW_FAIL"

/**
 * add CLEAR_STUDENT_VARISANGYA_WALLET_DETAIL
 */
export const CLEAR_STUDENT_VARISANGYA_WALLET_DETAIL =
  "CLEAR_STUDENT_VARISANGYA_WALLET_DETAIL"
