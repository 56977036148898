import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_NIKAH_REGISTRATIONS,
  GET_NIKAH_REGISTRATIONS_CARDS,
  GET_NIKAH_REGISTRATION_DETAIL,
  CREATE_NIKAH_REGISTRATION,
  UPDATE_NIKAH_REGISTRATION,
  DELETE_NIKAH_REGISTRATION,
  GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD,
} from "./actionTypes"
import {
  getNikahRegistrationsSuccess,
  getNikahRegistrationsFail,
  getNikahRegistrationsCardsSuccess,
  getNikahRegistrationsCardsFail,
  getNikahRegistrationDetailSuccess,
  getNikahRegistrationDetailFail,
  createNikahRegistrationSuccess,
  createNikahRegistrationFail,
  updateNikahRegistrationSuccess,
  updateNikahRegistrationFail,
  deleteNikahRegistrationSuccess,
  deleteNikahRegistrationFail,
  getNikahRegistrationsForDownloadSuccess,
  getNikahRegistrationsForDownloadFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getNikahRegistrationsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/nikah/nikah/?search=${searchText && searchText}&page=${
        page && page
      }`
    )
  } else {
    return get(`/api/v1/nikah/nikah/?page=${page ? page : 1}`)
  }
}
const getNikahRegistrationsCardsAPi = () => {
  return get(`/api/v1/nikah/nikah/?cards=all`)
}
const getNikahRegistrationsForDownloadApi = () => {
  return get("/api/v1/nikah/nikah/")
}
const getNikahRegistrationDetailsAPi = nikahRegistrationId => {
  return get(`/api/v1/nikah/nikah/${nikahRegistrationId}/`)
}
const createNikahRegistrationsApi = ({ nikahRegistration }) => {
  return post("/api/v1/nikah/nikah/", nikahRegistration)
}
const updateNikahRegistrationsApi = ({
  nikahRegistrationId,
  nikahRegistration,
}) => {
  return ApiPut(
    `/api/v1/nikah/nikah/${nikahRegistrationId}/`,
    nikahRegistration
  )
}
const deleteNikahRegistrationApi = ({ nikahRegistrationsId }) => {
  return del(`/api/v1/nikah/nikah/${nikahRegistrationsId}/`)
}

function* fetchNikahRegistrations({ payload }) {
  try {
    const response = yield call(getNikahRegistrationsAPi, payload)
    if (response && !response?.error) {
      yield put(getNikahRegistrationsSuccess(response))
    } else {
      yield put(getNikahRegistrationsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getNikahRegistrationsFail(error))
  }
}

function* fetchNikahRegistrationsCards() {
  try {
    const response = yield call(getNikahRegistrationsCardsAPi)
    if (response && !response?.error) {
      yield put(getNikahRegistrationsCardsSuccess(response))
    } else {
      yield put(getNikahRegistrationsCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getNikahRegistrationsCardsFail(error))
  }
}

function* fetchNikahRegistrationsForDownload() {
  try {
    const response = yield call(getNikahRegistrationsForDownloadApi)
    if (response && !response?.error) {
      yield put(getNikahRegistrationsForDownloadSuccess(response))
    } else {
      yield put(getNikahRegistrationsForDownloadFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getNikahRegistrationsForDownloadFail(error))
  }
}

function* fetchNikahRegistrationDetail({ nikahRegistrationId }) {
  try {
    const response = yield call(
      getNikahRegistrationDetailsAPi,
      nikahRegistrationId
    )
    if (response && !response?.error) {
      yield put(getNikahRegistrationDetailSuccess(response))
    } else {
      yield put(getNikahRegistrationDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getNikahRegistrationDetailFail(error))
  }
}
function* onCreateNikahRegistrations({ payload }) {
  try {
    const response = yield call(createNikahRegistrationsApi, payload)
    if (response && !response?.error) {
      yield put(createNikahRegistrationSuccess(response))
      if (payload.history) {
        payload.history.push("/nikahs")
      }
      doneNotification("Registered Successfully")
    } else {
      yield put(createNikahRegistrationFail(response))
      errorNotification(response?.error)
    }
    // swal({
    //   title: "NikahRegistrations Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createNikahRegistrationFail(error))
    errorNotification()
  }
}

function* onUpdateNikahRegistrations({ payload }) {
  try {
    const response = yield call(updateNikahRegistrationsApi, payload)
    if (response && !response?.error) {
      payload.history.push("/nikahs")
      yield put(updateNikahRegistrationSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateNikahRegistrationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    // console.log("error: ", error)
    yield put(updateNikahRegistrationFail(error))
    errorNotification()
  }
}

function* onDeleteNikahRegistrations({ payload }) {
  try {
    const response = yield call(deleteNikahRegistrationApi, payload)
    if (response && !response?.error) {
      // payload.history.push("/nikahs")
      yield put(deleteNikahRegistrationSuccess(response))
      doneNotification("Deleted Successfully")
      // get NikahRegistrations
      try {
        const response = yield call(getNikahRegistrationsAPi, { page: 1 })
        yield put(getNikahRegistrationsSuccess(response))
      } catch (error) {
        yield put(getNikahRegistrationsFail(error))
      }
    } else {
      yield put(deleteNikahRegistrationFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    errorNotification()
    yield put(deleteNikahRegistrationFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* NikahRegistrationsSaga() {
  yield takeEvery(GET_NIKAH_REGISTRATIONS, fetchNikahRegistrations)
  yield takeEvery(GET_NIKAH_REGISTRATIONS_CARDS, fetchNikahRegistrationsCards)
  yield takeEvery(
    GET_NIKAH_REGISTRATIONS_FOR_DOWNLOAD,
    fetchNikahRegistrationsForDownload
  )
  yield takeEvery(GET_NIKAH_REGISTRATION_DETAIL, fetchNikahRegistrationDetail)
  yield takeEvery(CREATE_NIKAH_REGISTRATION, onCreateNikahRegistrations)
  yield takeEvery(UPDATE_NIKAH_REGISTRATION, onUpdateNikahRegistrations)
  yield takeEvery(DELETE_NIKAH_REGISTRATION, onDeleteNikahRegistrations)
}

export default NikahRegistrationsSaga
