import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"

import {
  getNocCertificates,
  // deleteGroup,
  getNocCertificateForDownload,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import UiModalExcelDownload from "pages/common/UiModalExcelDownload"

const AllNikahNocCertificates = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [modalNocDownload, setModalNocDownload] = useState(false)

  const { nocCertificates, loading, language, nocCertificateForDownload } =
    useSelector(state => ({
      nocCertificates: state.NocCertificates.nocCertificates,
      loading: state.NocCertificates.loading,
      language: state.Layout.language,
      nocCertificateForDownload:
        state.NocCertificates.nocCertificateForDownload,
    }))

  //pages
  const totalPages = Math.ceil(nocCertificates?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    if (modalNocDownload) {
      dispatch(getNocCertificateForDownload())
    }
  }, [dispatch, modalNocDownload])

  useEffect(() => {
    dispatch(getNocCertificates(searchText, pageSend()))
  }, [dispatch, page, searchText])

  // const handleDelete = id => {
  //   dispatch(deleteGroup(id, history))
  // }

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    {
      dataField: "title",
      text: `${language === "mal" ? "ടൈറ്റിൽ" : "Title"}`,
    },
    // {
    //   dataField: "member_name",
    //   text: `${language === "mal" ? "അംഗം" : "Member"}`,
    // },
    {
      dataField: "date",
      text: `${language === "mal" ? "തീയതി" : "Date"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const nocCertificatesData = map(nocCertificates?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    date: item.date && moment(item.date).format("DD-MM-yyyy"),
    action: (
      <div>
        <Link to={`/noc/update/${item?.id}`} className="btn-primary btn-sm">
          {language === "mal" ? "കാണുക" : "Edit"}
        </Link>
      </div>
    ),
  }))

  const headers = [
    { label: `${language === "mal" ? "നം." : "No."}`, key: "no" },
    {
      label: `${language === "mal" ? "മഹല്ല് ഐഡി" : "Mahall ID"}`,
      key: "mahall_custom_id",
    },
    { label: `${language === "mal" ? "തീയതി" : "Date"}`, key: "date" },
    { label: `${language === "mal" ? "ടൈറ്റിൽ" : "Title"}`, key: "title" },
  ]

  const NocCertificateExcelData = map(
    nocCertificateForDownload,
    (item, index) => ({
      ...item,
      no: (pageSend() - 1) * limit + index + 1,
      mahall_custom_id: item.mahall_custom_id,
      date: item.date && moment(item?.date).format("DD/MM/YYYY"),
      title: item.title,
    })
  )

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceNocSearch = debounce(value => setSearchText(value), 600)

  const fileName = "khaf-smart-mahall-noc-certificates-list.csv"

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <UiModalExcelDownload
        show={modalNocDownload}
        onCloseclick={() => setModalNocDownload(false)}
        excelData={NocCertificateExcelData}
        headers={headers}
        list={nocCertificateForDownload}
        fileName={fileName}
        loading={loading}
      />
      <Row>
        <Row>
          <div className="d-flex justify-content-end">
            <Button
              style={{
                width: language === "mal" ? "150px" : "130px",
                border: "bold",
              }}
              className="btn-md me-2 mt-0  bg-white text-khaf"
              onClick={() => setModalNocDownload(true)}
            >
              <i
                className="bx bx-download mx-1"
                style={{ fontSize: "18px", color: "#009846" }}
              />
              {language === "mal" ? `ഡൗൺലോഡ് ` : `Download`}
            </Button>
            <Link
              to={`/noc/create`}
              type="button"
              style={{ width: language === "mal" ? "170px" : "100px" }}
              className="w-md btn btn-success d-block"
            >
              {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New NOC"}
            </Link>
          </div>
        </Row>
        <Col className="col-12 mt-2">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={nocCertificatesData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => debounceNocSearch(e.target.value)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllNikahNocCertificates

// AllCommitteeMeetings.propTypes = {
//   history: PropTypes.object,
// }
