import {
  GET_FAMILY_MEMBERS,
  GET_FAMILY_MEMBERS_SUCCESS,
  GET_FAMILY_MEMBERS_FAIL,
  GET_FAMILY_MEMBERS_FOR_DOWNLOAD,
  GET_FAMILY_MEMBERS_FOR_DOWNLOAD_SUCCESS,
  GET_FAMILY_MEMBERS_FOR_DOWNLOAD_FAIL,
  GET_FILTERED_FAMILY_MEMBERS,
  GET_FILTERED_FAMILY_MEMBERS_SUCCESS,
  GET_FILTERED_FAMILY_MEMBERS_FAIL,
  GET_FAMILY_MEMBERS_CARDS,
  GET_FAMILY_MEMBERS_CARDS_SUCCESS,
  GET_FAMILY_MEMBERS_CARDS_FAIL,
  GET_FAMILY_MEMBER_DROPDOWN,
  GET_FAMILY_MEMBER_DROPDOWN_SUCCESS,
  GET_FAMILY_MEMBER_DROPDOWN_FAIL,
  GET_ALL_FAMILY_MEMBERS_DROPDOWN,
  GET_ALL_FAMILY_MEMBERS_DROPDOWN_SUCCESS,
  GET_ALL_FAMILY_MEMBERS_DROPDOWN_FAIL,
  GET_FAMILY_MEMBER_DETAIL_SUCCESS,
  GET_FAMILY_MEMBER_DETAIL_FAIL,
  UPDATE_PHONE_FAMILY_MEMBER,
  UPDATE_PHONE_FAMILY_MEMBER_SUCCESS,
  UPDATE_PHONE_FAMILY_MEMBER_FAIL,
  CREATE_FAMILY_MEMBER,
  CREATE_FAMILY_MEMBER_SUCCESS,
  CREATE_FAMILY_MEMBER_FAIL,
  UPDATE_FAMILY_MEMBER,
  UPDATE_FAMILY_MEMBER_SUCCESS,
  UPDATE_FAMILY_MEMBER_FAIL,
  DELETE_FAMILY_MEMBER,
  DELETE_FAMILY_MEMBER_SUCCESS,
  DELETE_FAMILY_MEMBER_FAIL,
  GET_FAMILY_MEMBER_DETAIL,
  CLEAR_MEMBER_DETAILS,
} from "./actionTypes"

const INIT_STATE = {
  familyMembers: [],
  familyMembersForDownload: [],
  otp: {},
  familyMembersCards: {},
  familyMemberDetail: {},
  searchFamilyMembers: [],
  error: {},
  loading: false,
  familyMembersForDownloadLoading: false,
  detailLoading: false,
  createdFamilyMember: {},
  allFamilyMembers: [],
}

const FamilyMembers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FAMILY_MEMBERS:
    case GET_FILTERED_FAMILY_MEMBERS:
    case GET_FAMILY_MEMBERS_CARDS:
    case GET_FAMILY_MEMBER_DROPDOWN:
    case GET_ALL_FAMILY_MEMBERS_DROPDOWN:
    case UPDATE_FAMILY_MEMBER:
    case CREATE_FAMILY_MEMBER:
    case DELETE_FAMILY_MEMBER:
      return {
        ...state,
        loading: true,
      }

    case GET_FAMILY_MEMBERS_FOR_DOWNLOAD:
      return {
        ...state,
        familyMembersforDownloadLoading: true,
      }

    case GET_FAMILY_MEMBER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_FAMILY_MEMBERS_SUCCESS:
      return {
        ...state,
        familyMembers: action.payload,
        loading: false,
      }
    case GET_FAMILY_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBERS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        familyMembersforDownload: action.payload,
        familyMembersforDownloadLoading: false,
      }

    case GET_FAMILY_MEMBERS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        familyMembersforDownloadLoading: false,
      }

    case GET_FILTERED_FAMILY_MEMBERS_SUCCESS:
      return {
        ...state,
        familyMembers: action.payload,
        loading: false,
      }
    case GET_FILTERED_FAMILY_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBERS_CARDS_SUCCESS:
      return {
        ...state,
        familyMembersCards: action.payload,
        loading: false,
      }
    case GET_FAMILY_MEMBERS_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchFamilyMembers: action.payload,
        loading: false,
      }
    case GET_FAMILY_MEMBER_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ALL_FAMILY_MEMBERS_DROPDOWN_SUCCESS:
      return {
        ...state,
        allFamilyMembers: action.payload,
        loading: false,
      }
    case GET_ALL_FAMILY_MEMBERS_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        familyMembers: action.payload,
        createdFamilyMember: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FAMILY_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        familyMemberDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_FAMILY_MEMBER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_PHONE_FAMILY_MEMBER:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_PHONE_FAMILY_MEMBER_SUCCESS:
      state = {
        ...state,
        loading: false,
        otp: action.payload,
      }
      break

    case UPDATE_PHONE_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        familyMemberDetail: action.payload,
        loading: false,
      }

    case UPDATE_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        // familyMembers: state.familyMembers.filter(
        //   familyMember =>
        //     familyMember.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_FAMILY_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CLEAR_MEMBER_DETAILS:
      return {
        ...state,
        familyMemberDetail: {},
      }

    default:
      return state
  }
}

export default FamilyMembers
