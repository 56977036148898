import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import axios from "axios"
//actions
import { Image } from "react-bootstrap"

import { API_URL } from "helpers/api_methods"
import {
  createFeedSuccess,
  createFeedFail,
  getSuperFeeds,
  createSuperFeeds,
  createSuperBanners,
  updateSuperBanners,
  getSuperBannerDetails,
  deleteBanner,
} from "store/actions"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import { useFileSizes } from "hooks/useFileSize"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const UpdateSuperBanner = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, language, mahallId, bannerDetails } = useSelector(state => ({
    loading: state.Feeds.loading,
    error: state.Mahalls.error,
    language: state.Layout.language,
    mahallId: state.Users.userDetail?.mahall?.id,
    bannerDetails: state.SuperBanners.superBannerDetails,
  }))

  const [customLoad, setCustomLoad] = useState(false)
  console.log(customLoad)

  const [imageUploadWeb, setImageUploadWeb] = useState({ image_web: null })
  const [imagePreviewWeb, setImagePreviewWeb] = useState()

  const [imageUploadApp, setImageUploadApp] = useState({
    image_web: null,
  })
  const [imagePreviewApp, setImagePreviewApp] = useState()

  const handleImageChangeWeb = e => {
    const fileName = Array.from(e.target.files)
    const name =
      fileName[0]?.name.length <= 100
        ? fileName[0]?.name
        : errorNotification("Ensure this filename has at most 100 characters ")
    setImageUploadWeb({
      name: name,
      size: fileName[0]?.size,
      image: e.target.files[0],
    })
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreviewWeb({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleImageChangeApp = e => {
    const fileName = Array.from(e.target.files)
    const name =
      fileName[0]?.name.length <= 100
        ? fileName[0]?.name
        : errorNotification("Ensure this filename has at most 100 characters ")
    setImageUploadApp({
      name: name,
      size: fileName[0]?.size,
      image: e.target.files[0],
    })
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreviewApp({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  useEffect(() => {
    dispatch(getSuperBannerDetails(params?.id))
  }, [params?.id, dispatch])

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    console.log(values, onSubmitProps)

    const updateData = {
      ...values,

      image_app: imageUploadApp?.image && imageUploadApp,
      image_web: imageUploadWeb?.image && imageUploadWeb,
    }
    dispatch(updateSuperBanners(params?.id, updateData, history))
  }
  // useEffect(() => {
  //   setImagePreviewApp(bannerDetails?.image_app && bannerDetails?.image_app)
  //   setImagePreviewWeb(bannerDetails?.image_web && bannerDetails?.image_web)
  // }, [bannerDetails])

  const handleDeleteBanner = id => {
    dispatch(deleteBanner(id, history))
  }

  return (
    <>
      <MetaTags>
        <title> Super Banners | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "മുന്തിയ ബാനറുകൾ" : "Super Banners"}
          breadcrumbItem={
            language === "mal" ? "അപ്ഡേറ്റ് " : "Update Super Banners"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <Row className="row mb-4">
                        <Col md="6" sm="12" lg="6" className="mb-3">
                          <Label>
                            {language === "mal" ? "മുൻഗണന" : "Priority"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="priority"
                            type="number"
                            value={bannerDetails?.priority}
                          />
                        </Col>
                        <Col md="6" sm="12" lg="6" className="mb-3">
                          <Label>
                            {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="title"
                            type="text"
                            value={bannerDetails?.title}
                          />
                        </Col>
                      </Row>

                      <div className="row mb-4">
                        <Col md="12" className="mb-3">
                          <AvField
                            name="url"
                            type="text"
                            label={language === "mal" ? "യു ആർ എൽ" : "URL"}
                            value={bannerDetails?.url}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label>
                          {language === "mal"
                            ? "വെബ്സൈറ്റ് ഫോട്ടോ"
                            : "Web Image"}
                        </Label>
                        <AvField
                          name="image"
                          type="file"
                          onChange={handleImageChangeWeb}
                        />

                        {imagePreviewWeb ? (
                          <Image
                            style={{
                              width: "40%",
                              height: "50%",
                              objectFit: "contain",
                            }}
                            className="my-2"
                            src={imagePreviewWeb?.image}
                          />
                        ) : (
                          <Image
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                            }}
                            className="my-2"
                            src={bannerDetails?.image_web}
                          />
                        )}
                        <Label>
                          {language === "mal"
                            ? "അപ്ലിക്കേഷൻ ഫോട്ടോ"
                            : " App Image"}
                        </Label>
                        <AvField
                          name="image_app"
                          type="file"
                          onChange={handleImageChangeApp}
                        />

                        {imagePreviewApp ? (
                          <Image
                            style={{
                              width: "40%",
                              height: "50%",
                              objectFit: "contain",
                            }}
                            className="my-2"
                            src={imagePreviewApp?.image}
                          />
                        ) : (
                          <Image
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                            }}
                            className="my-2"
                            src={bannerDetails?.image_app}
                          />
                        )}
                      </div>

                      <div className="d-flex justify-content-end">
                        <Button
                          type="button"
                          color="white"
                          className=" m-0 btn "
                          onClick={() => {
                            handleDeleteBanner(bannerDetails?.id)
                          }}
                        >
                          <i
                            className="bx bx-trash me-2"
                            style={{ color: "red", fontSize: "18px" }}
                          />
                        </Button>
                        <Button type="submit" color="primary" className="w-md">
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateSuperBanner

UpdateSuperBanner.propTypes = {
  history: PropTypes.object,
}
