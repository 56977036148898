import React, { useEffect } from "react"
import {
  Badge,
  // Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  // Spinner,
  Table,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams, Link } from "react-router-dom"
import PropTypes from "prop-types"
//actions institute
//components
// import ToolkitProvider from "react-bootstrap-table2-toolkit"
// import BootstrapTable from "react-bootstrap-table-next"
// import { debounce, map, range } from "lodash"

// import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getAssetItemTransactionDetail,
  // getAssetItemTransactions,
} from "store/actions"
import moment from "moment"

const MahallInstituteAssetItemTransactionDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()

  // const [searchText, setSearchText] = useState("")
  // const [page, setPage] = useState(1)
  // const limit = 10

  const { assetItemTransactionDetail, loading, language } = useSelector(
    state => ({
      assetItemTransactionDetail:
        state.AssetItemTransactions.assetItemTransactionDetail,
      language: state.Layout.language,
      loading: state.AssetItemTransactions.loading,
    })
  )

  // const totalPages = Math.ceil(assetItemTransactions?.count / 10)
  // const pages = range(1, totalPages + 1)

  // const pageSend = () => {
  //   if (page >= pages.length) {
  //     return pages.length
  //   }
  //   if (page < 1) {
  //     return 1
  //   } else {
  //     return page
  //   }
  // }

  // useEffect(() => {
  //   dispatch(
  //     getAssetItemTransactions(assetItemTransactionDetail?.id, searchText, page)
  //   )
  // }, [assetItemTransactionDetail, searchText, page])

  const cardData = [
    {
      title: `${language === "mal" ? "സ്റ്റോക്കുകൾ" : "Stocks"}`,
      cardValue: assetItemTransactionDetail?.quantity,
      iconClass: "error-circle",
      routeLink: "#!",
      loading: loading,
      alt: `${language === "mal" ? "സകാത് ഇനങ്ങൾ ഇല്ല" : "No Zakat Items"}`,
    },
    {
      title: `${language === "mal" ? "മൂല്യം" : "Value"}`,
      cardValue: assetItemTransactionDetail?.value,
      iconClass: "error-circle",
      routeLink: "#!",
      loading: loading,
      alt: `${language === "mal" ? "നൽകിയ സകാതുകൾ ഇല്ല" : "No Assets Given"}`,
    },
  ]

  useEffect(() => {
    dispatch(getAssetItemTransactionDetail(params.id))
  }, [])

  // const debounceAssetItemTransactionSearch = debounce(
  //   value => setSearchText(value),
  //   600
  // )

  // const columns = [
  //   {
  //     dataField: "no",
  //     text: `${language === "mal" ? "നം." : "No."}`,
  //     sort: true,
  //   },
  //   {
  //     dataField: "title",
  //     text: `${language === "mal" ? "വിശദാംശങ്ങൾ" : "Particulars"}`,
  //   },
  //   {
  //     dataField: "date",
  //     text: `${language === "mal" ? "തീയതി" : "Date"}`,
  //     sort: true,
  //   },
  //   {
  //     dataField: "quantity",
  //     text: `${language === "mal" ? "അളവ്" : "Quantity"}`,
  //   },
  //   {
  //     dataField: "value",
  //     text: `${language === "mal" ? "മൂല്യം" : "Value"}`,
  //   },
  //   {
  //     dataField: "action",
  //     text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
  //   },
  //   // {
  //   //   dataField: "print",
  //   //   text: "",
  //   // },
  // ]

  // const assetItemTransactionsData = map(
  //   assetItemTransactions?.results,
  //   (item, index) => ({
  //     ...item,
  //     key: index,
  //     no: (pageSend() - 1) * limit + index + 1,
  //     value: (
  //       <p
  //         className={`${
  //           item?.transaction_type === "debit" ? "text-danger" : "text-success"
  //         } m-0`}
  //       >
  //         {item?.value}
  //       </p>
  //     ),
  //     action: (
  //       <div className="m-0">
  //         <Link
  //           to={`/zakatItem/update/${item?.id}`}
  //           className="btn btn-success btn-sm"
  //         >
  //           {language === "mal" ? "കാണുക" : "View"}
  //         </Link>
  //       </div>
  //     ),
  //   })
  // )

  // const defaultSorted = [
  //   {
  //     dataField: "id",
  //     order: "asc",
  //   },
  // ]

  // const selectRow = {
  //   mode: "checkbox",
  // }

  return (
    <>
      <MetaTags>
        <title>Asset Item | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal"
              ? "ആസ്തി ഇന ഇടപാട് വിവരങ്ങൾ"
              : "Asset Item Transaction Details"
          }
          breadcrumbItem={
            language === "mal" ? "ആസ്തി ഇന ഇടപാടുകൾ" : "Asset Item Transaction"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col className="col-lg-12 col-md-12">
                <Row>
                  {/* <Col md="4">
                    <Card
                      className="overflow-hidden bg-white bg-soft"
                      style={{ minHeight: "99px" }}
                    >
                      <div className="">
                        <Row>
                          <div className="text-primary p-4">
                            <div className="px-2">
                              <Row>
                                <Col md="12">
                                  <h5 className="text-khaf">
                                    {assetItemTransactionDetail?.title}
                                    <Badge
                                      className={`rounded-pill mx-2 ${
                                        assetItemTransactionDetail?.transaction_type ===
                                        "debit"
                                          ? "badge-soft-danger"
                                          : "badge-soft-success"
                                      }`}
                                    >
                                      {
                                        assetItemTransactionDetail?.transaction_type
                                      }
                                    </Badge>
                                  </h5>
                                  <p className="m-0 text-muted">
                                    {assetItemTransactionDetail?.remark}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Card>
                  </Col> */}
                  <Col md="8">
                    <Row>
                      {cardData?.map((item, key) => (
                        <Col sm="12" md="6" lg="6" key={key} className="">
                          <Card>
                            <CardBody>
                              <div className="d-flex flex-wrap">
                                <div className="me-3">
                                  <h5 className="text-muted">{item.title}</h5>
                                  <h4 className={`${item.className}`}>
                                    {item.cardValue}
                                  </h4>
                                </div>
                                <div className="avatar-sm ms-auto">
                                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                    <i
                                      className={
                                        "bx bx-" +
                                        `${item.iconClass} ${item.className}`
                                      }
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <div className=" d-flex justify-content-end">
                    <Badge
                      className="rounded-pill bg-warning"
                      style={{ fontSize: "11px" }}
                    >
                      {assetItemTransactionDetail?.transaction_type === "debit"
                        ? language === "mal"
                          ? "വൗച്ചർ നം."
                          : "Voucher No."
                        : language === "mal"
                        ? "രസീത് നം."
                        : "Receipt No."}
                      {" : "}
                      {assetItemTransactionDetail?.ledger_item?.voucher_number
                        ? assetItemTransactionDetail?.ledger_item
                            ?.voucher_number
                        : assetItemTransactionDetail?.ledger_item
                            ?.receipt_number}
                    </Badge>
                  </div>
                  <h4 className="pb-2">
                    {language === "mal"
                      ? "ലെഡ്ജർ വിശദാംശങ്ങൾ"
                      : "Ledger Details"}
                  </h4>
                  <Badge
                    className={`${
                      assetItemTransactionDetail?.transaction_type === "credit"
                        ? "bg-success"
                        : "bg-danger"
                    }`}
                    style={{ fontSize: "13px" }}
                  >
                    {language === "mal" ? "തുക" : "Amount"} {" : "}
                    {assetItemTransactionDetail?.ledger_item?.amount}
                  </Badge>
                  <Badge className="bg-info mx-2" style={{ fontSize: "13px" }}>
                    {language === "mal" ? "ബാലൻസ്" : "Balance"} {" : "}
                    {assetItemTransactionDetail?.ledger_item?.balance}
                  </Badge>
                  <Row>
                    <Col md="6">
                      <div className="table-responsive mt-3">
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <td>
                                {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                              </td>
                              <td>
                                {" "}
                                {": "}
                                {assetItemTransactionDetail?.title}
                              </td>
                            </tr>
                            <tr>
                              <td>{language === "mal" ? "പേര്" : "Member"}</td>
                              <td>
                                {": "}
                                {assetItemTransactionDetail?.ledger_item
                                  ?.member_name
                                  ? assetItemTransactionDetail?.ledger_item
                                      ?.member_name
                                  : assetItemTransactionDetail?.ledger_item
                                      ?.other_name}
                              </td>
                            </tr>
                            <tr>
                              <td>{language === "mal" ? "തീയതി" : "Date"}</td>
                              <td>
                                {": "}
                                {moment(
                                  assetItemTransactionDetail?.ledger_item?.date
                                ).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {language === "mal" ? "ലെഡ്ജർ" : "Ledger"}
                              </td>
                              <td>
                                {": "}
                                {
                                  assetItemTransactionDetail?.ledger_item
                                    ?.ledger_name
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {language === "mal" ? "വിഭാഗം" : "Category"}
                              </td>
                              <td>
                                {": "}
                                {
                                  assetItemTransactionDetail?.ledger_item
                                    ?.category_name
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {language === "mal" ? "വിവരണം" : "Description"}
                              </td>
                              <td>
                                {": "}
                                {assetItemTransactionDetail?.remark}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mx-5">
                        <h5 className="text-center">
                          {language === "mal" ? "ബിൽ ഫോട്ടോ" : "Bill Image"}
                        </h5>
                        {assetItemTransactionDetail?.ledger_item?.bill_image ? (
                          <img
                            src={
                              assetItemTransactionDetail?.ledger_item
                                ?.bill_image
                            }
                            alt=""
                            width={"75%"}
                            height={"250px"}
                          />
                        ) : (
                          <h5>NO IMAGE</h5>
                        )}
                      </div>
                    </Col>
                    <div className="d-flex justify-content-end mt-3">
                      <Link
                        className="btn btn-dark btn-md"
                        to={`/receipt/${assetItemTransactionDetail?.ledger_item?.id}`}
                      >
                        {" "}
                        <i className="bx bxs-download me-2" />
                        {language === "mal" ? "പ്രിന്റ്" : "Print"}
                      </Link>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Row>
            {/* <Row>
              <Col lg="12" className="">
                <React.Fragment>
                  <Row style={{ minHeight: "600px", display: "flex" }}>
                    <Col className="col-lg-12 col-md-12">
                      <Card>
                        <CardBody>
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={assetItemTransactionsData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <form
                                          className="app-search d-lg-block"
                                          onChange={e =>
                                            debounceAssetItemTransactionSearch(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search..."
                                              defaultValue={searchText}
                                            />
                                            <span className="bx bx-search-alt" />
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="8">
                                    <div className="d-flex justify-content-end">
                                      <Link
                                        to={{
                                          pathname:
                                            "/mahall/institute/assetItem/add/entry",
                                          state: assetItemTransactionDetail,
                                        }}
                                        className="btn btn-primary btn-sm"
                                      >
                                        {language === "mal"
                                          ? "+ എൻട്രി ചേർക്കുക"
                                          : "+ Add Entry"}
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                                {loading ? (
                                  <Spinner
                                    color="secondary"
                                    className="d-block m-auto"
                                  />
                                ) : (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap table-hover"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <MyPagination
                                      totalPages={pages}
                                      page={page}
                                      setPage={setPage}
                                    />
                                  </>
                                )}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </React.Fragment>
              </Col>
            </Row> */}
          </div>
        </Container>
      </div>
    </>
  )
}

export default MahallInstituteAssetItemTransactionDetails

MahallInstituteAssetItemTransactionDetails.propTypes = {
  history: PropTypes.object,
}
