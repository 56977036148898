import {
  GET_ZAKATS,
  GET_ZAKATS_SUCCESS,
  GET_ZAKATS_FAIL,
  GET_ZAKAT_CARDS,
  GET_ZAKAT_CARDS_SUCCESS,
  GET_ZAKAT_CARDS_FAIL,
  GET_ZAKAT_DETAIL,
  GET_ZAKAT_DETAIL_SUCCESS,
  GET_ZAKAT_DETAIL_FAIL,
  CREATE_ZAKAT,
  CREATE_ZAKAT_SUCCESS,
  CREATE_ZAKAT_FAIL,
  UPDATE_ZAKAT,
  UPDATE_ZAKAT_SUCCESS,
  UPDATE_ZAKAT_FAIL,
  DELETE_ZAKAT,
  DELETE_ZAKAT_SUCCESS,
  DELETE_ZAKAT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  zakats: [],
  zakatCards: {},
  zakatDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdZakat: {},
}

const Zakats = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ZAKATS:
    case GET_ZAKAT_CARDS:
    case UPDATE_ZAKAT:
    case CREATE_ZAKAT:
    case DELETE_ZAKAT:
      return {
        ...state,
        loading: true,
      }
    case GET_ZAKAT_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_ZAKATS_SUCCESS:
      return {
        ...state,
        zakats: action.payload,
        loading: false,
      }
    case GET_ZAKATS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ZAKAT_CARDS_SUCCESS:
      return {
        ...state,
        zakatCards: action.payload,
        loading: false,
      }
    case GET_ZAKAT_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_ZAKAT_SUCCESS:
      return {
        ...state,
        createdZakat: action.payload,
        loading: false,
      }

    case CREATE_ZAKAT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ZAKAT_DETAIL_SUCCESS:
      return {
        ...state,
        zakatDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_ZAKAT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_ZAKAT_SUCCESS:
      return {
        ...state,
        zakatDetail: action.payload,
        loading: false,
      }

    case UPDATE_ZAKAT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_ZAKAT_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case DELETE_ZAKAT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Zakats
