import React, { Fragment, useState, useEffect } from "react"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Label,
  Badge,
  Button,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { debounce, map, range } from "lodash"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import moment from "moment"

//actions family
import {
  deleteDeathRegistration,
  getDeathRegistrationDetail,
  // getDeathRegistrationDocuments,
} from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import MyPagination from "components/Common/MyPagination"

//Import Images

const MahallDeathRegistrationDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  console.log(setLimit)
  const { deathRegistrationDetail, loading, detailLoading, language } =
    useSelector(state => ({
      loading: state.DeathRegistrations.loading,
      detailLoading: state.DeathRegistrations.detailLoading,
      language: state.Layout.language,
      deathRegistrationDetail: state.DeathRegistrations.deathRegistrationDetail,
    }))

  const familyMembers = []

  const totalPages = Math.ceil(familyMembers?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const [isOpen, setIsOpen] = useState(false)

  // const handleDelete = () => {
  //   setIsOpen(true)
  // }
  const handleDeleteEvent = () => {
    dispatch(deleteDeathRegistration(deathRegistrationDetail.id, history))
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getDeathRegistrationDetail(params.id))
  }, [dispatch, params.id])

  // useEffect(() => {
  //   if (familyDetail?.id) {
  //     dispatch(getFamilyMembers(searchText, page, familyDetail?.id))
  //   }
  // }, [searchText, page, familyDetail])

  const debounceMahallFamilyFamilyMemberSearch = debounce(
    value => setSearchText(value),
    600
  )

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
    },
    {
      dataField: "custom_id",
      text: `${language === "mal" ? "ഐഡി" : "ID"}`,
    },
    {
      dataField: "full_name",
      text: `${language === "mal" ? "പേര്" : "Name"}`,
    },
    {
      dataField: "ageGender",
      text: `${language === "mal" ? "പ്രായം / ലിംഗം" : "Age / Gender"}`,
      sort: true,
    },
    {
      dataField: "blood_group.english",
      text: `${language === "mal" ? "രക്ത ഗ്രൂപ്പ്" : "Blood Group"}`,
      sort: true,
    },
    {
      dataField: "healthStatus",
      text: `${language === "mal" ? "ആരോഗ്യ സ്ഥിതി" : "Health Status"}`,
      sort: true,
    },
    {
      dataField: "phone",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]

  const familyMembersData = map(familyMembers?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    ageGender: `${getAge(item?.dob)} / ${item?.gender}`,
    healthStatus: (
      <>
        {item?.health_condition?.map((status, key) => {
          return (
            <Fragment key={key}>
              <Badge
                className={
                  "mx-1 font-size-12 badge-soft-" +
                  `${
                    status.english == "Patient"
                      ? "danger"
                      : status.english == "Satisfied"
                      ? "success"
                      : status.english == "Disabled"
                      ? "warning"
                      : ""
                  }`
                }
                color="black"
                pill
              >
                {status.english}
              </Badge>
            </Fragment>
          )
        })}
      </>
    ),
    action: (
      <div>
        <Link to={`/familymembers/${item?.id}`} className="btn-sm">
          <i
            className="bx bx-show me-2"
            style={{ color: "green", fontSize: "18px" }}
          />
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    const startDate = moment(today)
    const timeEnd = moment(birthDate)
    const diff = startDate.diff(timeEnd)
    var age = Math.floor(diff / (365 * 24 * 60 * 60 * 1000))
    if (age < 0) age = 0
    return age
  }

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <MetaTags>
        <title>Death | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ഖാഫ്" : "Khaf"}
          breadcrumbItem={
            language === "mal" ? "കുടുംബ വിശദാംശങ്ങൾ" : "Family Details"
          }
        />
        <Container fluid>
          <div className="container-fluid ">
            <Row>
              <Col md={12}>
                {detailLoading ? (
                  <Spinner />
                ) : (
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      <Card>
                        <CardBody>
                          <Row>
                            <Col sm="12" md="6">
                              <Label>
                                {language === "mal"
                                  ? "അപേക്ഷക/ൻ "
                                  : "Applicant "}
                              </Label>
                              <div className="row mb-3 d-flex">
                                {deathRegistrationDetail?.mahall_of}
                              </div>

                              <div className="row mb-4">
                                <Col>
                                  {deathRegistrationDetail?.mahall_custom_id}
                                </Col>
                                <Col>
                                  <Label>
                                    {language === "mal" ? "തീയതി " : "Date "}
                                    <span className="text-danger">&#42;</span>
                                  </Label>
                                  {deathRegistrationDetail?.date}
                                </Col>
                              </div>
                              <div className="row mb-4">
                                <Col>
                                  <Label>
                                    {language === "mal" ? "സ്ഥലം " : "Place "}
                                    <span className="text-danger">&#42;</span>
                                  </Label>
                                  {deathRegistrationDetail?.place}
                                </Col>
                              </div>

                              <div className="row mb-4 ">
                                <Col>
                                  <Label>
                                    {language === "mal"
                                      ? "കാർമികത്വം "
                                      : "Headed By "}
                                  </Label>
                                  {deathRegistrationDetail?.headed_by}
                                </Col>
                              </div>
                            </Col>
                            <Col sm="12" md="6">
                              <div className="row mb-4 ">
                                <Col>
                                  <Label>
                                    {language === "mal"
                                      ? "വലിയ്യ് "
                                      : "Valiyy "}
                                  </Label>
                                  {deathRegistrationDetail?.valiyy}
                                </Col>
                              </div>
                              <div className="row mb-4 ">
                                <Col>
                                  <Label>
                                    {language === "mal"
                                      ? "വധുവുമായുള്ള ബന്ധം "
                                      : "Relation with Bride "}
                                  </Label>
                                  {
                                    deathRegistrationDetail?.relation_bw_bride_valiyy
                                  }
                                </Col>
                              </div>

                              <div className="row mb-4">
                                <Col>
                                  <Label>
                                    {language === "mal"
                                      ? "മഹർ തരം "
                                      : "Mahar Type "}
                                  </Label>
                                  {
                                    deathRegistrationDetail?.mahar_type_choice_id
                                  }
                                </Col>
                                <Col>
                                  <Label>
                                    {language === "mal" ? "അളവ് " : "Unit "}
                                  </Label>
                                  {deathRegistrationDetail?.mahar_unit}
                                </Col>
                              </div>

                              <div className="row mb-4">
                                <Col>
                                  <Label>
                                    {language === "mal" ? "ഫീസ്" : "Fee"}
                                  </Label>
                                  {deathRegistrationDetail?.fee}
                                </Col>
                                <Col>
                                  <Label for="basicpill-email-input4">
                                    {language === "mal"
                                      ? "ഫീസ് അടച്ചിട്ടുണ്ടോ?"
                                      : "Is Fee Paid?"}
                                  </Label>
                                  <div className="form-check form-switch form-switch-lg">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd"
                                      // checked={isPaidSwitch ? true : false}
                                      defaultChecked={
                                        deathRegistrationDetail?.is_paid
                                      }
                                    />
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col sm="12" md="12" lg="6">
                      <Card>
                        <CardBody>
                          <div
                            className="mb-3 text-center bg-khaf d-flex align-items-center justify-content-center text-white"
                            style={{ height: "30px" }}
                          >
                            <div>
                              <strong>
                                {language === "mal" ? "വധു" : "Bride"}
                              </strong>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <Label>
                              {language === "mal" ? "പേര് " : "Name "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col>{deathRegistrationDetail?.bride_name}</Col>
                          </div>
                          <div className="row mb-4">
                            <Label>
                              {language === "mal" ? "മഹല്ല് " : "Mahall "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col>{deathRegistrationDetail?.bride_mahall}</Col>
                          </div>
                          <div className="row mb-4">
                            <Label>
                              {language === "mal" ? "വിലാസം " : "Address "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col>{deathRegistrationDetail?.bride_address}</Col>
                          </div>
                          <div className="row mb-4">
                            <Label>
                              {language === "mal"
                                ? "ആധാർ നമ്പർ"
                                : "Aadhaar No."}
                            </Label>
                            <Col>{deathRegistrationDetail?.bride_aadhaar}</Col>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm="12" md="12" lg="6">
                      <Card>
                        <CardBody>
                          {/* <CardTitle className="h4 mb-4">{language === 'mal' ? "വരൻ" : "Groom"}</CardTitle> */}
                          <div
                            className="mb-3 text-center bg-khaf-blue d-flex align-items-center justify-content-center text-white"
                            style={{ height: "30px" }}
                          >
                            <div>
                              <strong>
                                {language === "mal" ? "വരൻ" : "Groom"}
                              </strong>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <Label>
                              {language === "mal" ? "പേര് " : "Name "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col>{deathRegistrationDetail?.groom_name}</Col>
                          </div>
                          <div className="row mb-4">
                            <Label>
                              {language === "mal" ? "മഹല്ല് " : "Mahall "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col>{deathRegistrationDetail?.groom_mahall}</Col>
                          </div>
                          <div className="row mb-4">
                            <Label>
                              {language === "mal" ? "വിലാസം " : "Address "}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <Col>{deathRegistrationDetail?.groom_address}</Col>
                          </div>
                          <div className="row mb-4">
                            <Label>
                              {language === "mal"
                                ? "ആധാർ നമ്പർ"
                                : "Aadhaar No."}
                            </Label>
                            <Col>{deathRegistrationDetail?.groom_aadhaar}</Col>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col sm="12" md="12" lg="12">
                      <Card>
                        <CardBody>
                          <Row>
                            <Col sm="12" md="6">
                              <div className="row mt-4 mb-4">
                                <Col>
                                  <label>
                                    {language === "mal"
                                      ? "വധുവിന്റെ ഒപ്പ് (ഫോട്ടോ)"
                                      : "Bride's Signature (Photo)"}
                                  </label>
                                </Col>
                              </div>

                              <div className="row mb-4 ">
                                <Col className="mt-4 ">
                                  <Label>
                                    {language === "mal"
                                      ? "സാക്ഷി (1) പേര് "
                                      : "Witness (1) Name "}
                                    <span className="text-danger">&#42;</span>
                                  </Label>
                                  {deathRegistrationDetail?.witness_1_name}
                                </Col>
                              </div>
                              <div className="row mt-4 mb-4">
                                <Col>
                                  <label>
                                    {language === "mal"
                                      ? "സാക്ഷിയുടെ (1) ഒപ്പ് (ഫോട്ടോ)"
                                      : "Witness's (1) Signature (Photo)"}
                                  </label>
                                </Col>
                              </div>
                            </Col>

                            <Col sm="12" md="6">
                              <div className="row mt-4 mb-4">
                                <Col>
                                  <label>
                                    {language === "mal"
                                      ? "വരന്റെ ഒപ്പ് (ഫോട്ടോ)"
                                      : "Groom's Signature (Photo)"}
                                  </label>
                                </Col>
                              </div>

                              <div className="row mb-4 ">
                                <Col className="mt-4 ">
                                  <Label>
                                    {language === "mal"
                                      ? "സാക്ഷി (2) പേര് "
                                      : "Witness (2) Name "}
                                    <span className="text-danger">&#42;</span>
                                  </Label>
                                  {deathRegistrationDetail?.witness_2_name}
                                </Col>
                              </div>
                              <div className="row mt-4 mb-4">
                                <Col>
                                  <label>
                                    {language === "mal"
                                      ? "സാക്ഷിയുടെ (2) ഒപ്പ് (ഫോട്ടോ)"
                                      : "Witness's (2) Signature (Photo)"}
                                  </label>
                                </Col>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="col-12 btn btn-success save-customer"
                        >
                          {loading && (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          )}
                          {language === "mal" ? "അപ്ഡേറ്റ്" : "Update"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row>
              <div className="col-md-6 sm:p-0">
                <Link
                  to={`/familymember/create`}
                  type="submit"
                  style={{ width: "200px" }}
                  className="w-md btn btn-success d-block"
                >
                  {language === "mal"
                    ? "+ പുതിയ കുടുംബാംഗം"
                    : "+ New Family Member"}
                </Link>
              </div>
            </Row>
            <Row>
              <Col className="col-12 mt-2 sm:p-0">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={familyMembersData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e =>
                                      debounceMahallFamilyFamilyMemberSearch(
                                        e.target.value
                                      )
                                    }
                                  >
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        defaultValue={searchText}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {loading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : (
                            <>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table align-middle table-wrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <MyPagination
                                pages={pages}
                                clcickedPage={page}
                                onNunClick={item => setPage(item)}
                                onNextClick={() => setPage(page + 1)}
                                onPrevClick={() => setPage(page - 1)}
                                onFastNextClick={() => setPage(pages.length)}
                                onFastPrevClick={() => setPage(1)}
                                apiPage={pageSend}
                              />
                            </>
                          )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default MahallDeathRegistrationDetails

MahallDeathRegistrationDetails.propTypes = {
  history: PropTypes.object,
}
