import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_VARISANGYAS,
  GET_VARISANGYA_CARDS,
  GET_VARISANGYA_DETAIL,
  CREATE_VARISANGYA,
  UPDATE_VARISANGYA,
  DELETE_VARISANGYA,
} from "./actionTypes"
import {
  getVarisangyasSuccess,
  getVarisangyasFail,
  getVarisangyaCardsSuccess,
  getVarisangyaCardsFail,
  getVarisangyaDetailSuccess,
  getVarisangyaDetailFail,
  createVarisangyaSuccess,
  createVarisangyaFail,
  updateVarisangyaSuccess,
  updateVarisangyaFail,
  deleteVarisangyaSuccess,
  deleteVarisangyaFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getVarisangyasAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/varisankya/varisankya/?search=${searchText && searchText}&page=${
        page && page
      }`
    )
  } else {
    return get(`/api/v1/varisankya/varisankya/?page=${page ? page : 1}`)
  }
}
const getVarisangyasCardsAPi = () => {
  return get(`/api/v1/varisankya/varisankya/?cards=all`)
}
const getVarisangyaDetailsAPi = varisangyaId => {
  return get(`/api/v1/varisankya/varisankya/${varisangyaId}/`)
}
const createVarisangyaApi = ({ varisangya }) => {
  return post("/api/v1/varisankya/varisankya/", varisangya)
}
const updateVarisangyaApi = ({ varisangyaId, varisangya }) => {
  return ApiPut(`/api/v1/varisankya/varisankya/${varisangyaId}/`, varisangya)
}
const deleteVarisangyaApi = ({ varisangyaId }) => {
  return del(`/api/v1/varisankya/varisankya/${varisangyaId}/`)
}

function* fetchVarisangyas({ payload }) {
  try {
    const response = yield call(getVarisangyasAPi, payload)
    if (response && !response?.error) {
      yield put(getVarisangyasSuccess(response))
    } else {
      yield put(getVarisangyasFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getVarisangyasFail(error))
  }
}

function* fetchVarisangyasCards() {
  try {
    const response = yield call(getVarisangyasCardsAPi)
    if (response && !response?.error) {
      yield put(getVarisangyaCardsSuccess(response))
    } else {
      yield put(getVarisangyaCardsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getVarisangyaCardsFail(error))
  }
}

function* fetchVarisangyaDetail({ varisangyaId }) {
  try {
    const response = yield call(getVarisangyaDetailsAPi, varisangyaId)
    if (response && !response?.error) {
      yield put(getVarisangyaDetailSuccess(response))
    } else {
      yield put(getVarisangyaDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getVarisangyaDetailFail(error))
  }
}
function* onCreateVarisangya({ payload }) {
  try {
    const response = yield call(createVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(createVarisangyaSuccess(response))
      if (payload.history) {
        payload.history.push("/varisangyas")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createVarisangyaFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createVarisangyaFail(error))
    errorNotification()
  }
}

function* onUpdateVarisangya({ payload }) {
  try {
    const response = yield call(updateVarisangyaApi, payload)
    if (response && !response?.error) {
      payload.history.push("/varisangyas")
      yield put(updateVarisangyaSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateVarisangyaFail(response))
      errorNotification()
    }
  } catch (error) {
    // console.log("error: ", error)
    yield put(updateVarisangyaFail(error))
    errorNotification()
  }
}

function* onDeleteVarisangya({ payload }) {
  try {
    const response = yield call(deleteVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(deleteVarisangyaSuccess(response))
      doneNotification("Deleted Successfully")
      payload.history.push("/varisangyas")
    } else {
      yield put(deleteVarisangyaFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteVarisangyaFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* VarisangyasSaga() {
  yield takeEvery(GET_VARISANGYAS, fetchVarisangyas)
  yield takeEvery(GET_VARISANGYA_CARDS, fetchVarisangyasCards)
  yield takeEvery(GET_VARISANGYA_DETAIL, fetchVarisangyaDetail)
  yield takeEvery(CREATE_VARISANGYA, onCreateVarisangya)
  yield takeEvery(UPDATE_VARISANGYA, onUpdateVarisangya)
  yield takeEvery(DELETE_VARISANGYA, onDeleteVarisangya)
}

export default VarisangyasSaga
