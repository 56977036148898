import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  // CardTitle,
  Col,
  Container,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import PropTypes from "prop-types"
import axios from "axios"
import { Image } from "react-bootstrap"

import { API_URL } from "helpers/api_methods"
//actions
import {
  getInstituteDetail,
  updateInstituteSuccess,
  updateInstituteFail,
  deleteInstitute,
  getMadrasaDetail,
  updateMadrasa,
  getAllMadrasaLists,
} from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import DeleteModal from "components/Common/DeleteModal"
import { useFileSizes } from "hooks/useFileSize"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UpdateMahallMadrasa = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const handleFileSizes = useFileSizes

  const {
    instituteDetail,
    mahallId,
    loading,
    language,
    madrasas,
    madrasaDetail,
  } = useSelector(state => ({
    instituteDetail: state.Institutes.instituteDetail,
    mahallId: state.Users.userDetail?.mahall?.id,
    loading: state.Institutes.loading,
    language: state.Layout.language,
    madrasas: state.MadrasaLists.madrasaLists,
    madrasaDetail: state.MadrasaLists.madrasaDetail,
  }))

  const instituteType = instituteDetail?.institute_type

  const [sunday, setSunday] = useState(false)
  const [monday, setMonday] = useState(false)
  const [tuesday, setTuesday] = useState(false)
  const [wednsday, setWednsday] = useState(false)
  const [thursday, setThursday] = useState(false)
  const [friday, setFriday] = useState(false)
  const [saturday, setSaturday] = useState(false)

  useEffect(() => {
    setSunday(madrasaDetail?.sunday)
    setMonday(madrasaDetail?.monday)
    setTuesday(madrasaDetail?.tuesday)
    setWednsday(madrasaDetail?.wednesday)
    setThursday(madrasaDetail?.thursday)
    setFriday(madrasaDetail?.friday)
    setSaturday(madrasaDetail?.saturday)
  }, [madrasaDetail])

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })

  const [imagePreview, setImagePreview] = useState()

  const [filesSize, setFilesSize] = useState()

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }

  const handleDeleteEvent = () => {
    dispatch(deleteInstitute(instituteDetail?.id, history, instituteType))
    setIsOpen(false)
  }

  function handleValidSubmit(values) {
    setCustomLoad(true)
    updateNewInstitute(
      selectedFile,
      values,
      mahallId,
      instituteType,
      instituteDetail,
      dispatch,
      setCustomLoad
    )
    setImagePreview()
  }

  useEffect(() => {
    dispatch(getInstituteDetail(params.id))
  }, [dispatch, params.id])

  const handleChange = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleValidSubmitMadrasaDetails = (onSubmitProps, v) => {
    onSubmitProps.preventDefault()
    const details = {
      institute: madrasaDetail?.institute,
      periods_per_day: v?.periods_per_day,
      sadhar_muallim: v?.sadhar_muallim,
      sunday: sunday,
      monday: monday,
      tuesday: tuesday,
      wednesday: wednsday,
      thursday: thursday,
      friday: friday,
      saturday: saturday,
    }
    dispatch(updateMadrasa(details, madrasaDetail?.id, history))
  }

  useEffect(() => {
    dispatch(getAllMadrasaLists())
  }, [dispatch])

  const filteredDetails = madrasas?.find(
    item => item?.institute === instituteDetail?.id
  )

  useEffect(() => {
    dispatch(getMadrasaDetail(filteredDetails?.id))
  }, [dispatch, filteredDetails])

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <MetaTags>
        <title>Madrasa | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "മദ്രസ" : "Madrasa"}
          breadcrumbItem={language === "mal" ? "പുതുക്കുക" : "Update"}
        />
        <Container fluid>
          {loading ? (
            <Spinner color="secondary" className="d-block m-auto" />
          ) : (
            <div className="container-fluid">
              <Row>
                <Col sm="12" md="12" lg="8">
                  <Card>
                    <CardBody>
                      {/* <CardTitle className="h4 mb-4">Update Profile</CardTitle> */}

                      <AvForm
                        className="form-horizontal "
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(v)
                        }}
                      >
                        <div className="row mb-4">
                          <Col>
                            <Label>
                              {language === "mal"
                                ? "പേര് (ഇംഗ്ലീഷ്)"
                                : "Name (English)"}
                              <span className="text-danger">&#42;</span>
                            </Label>
                            <AvField
                              name="institute_english_name"
                              type="text"
                              value={instituteDetail?.institute_english_name}
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col className="mb-3">
                            <AvField
                              name="institute_malayalam_name"
                              type="text"
                              label={
                                language === "mal"
                                  ? "പേര് (മലയാളം)"
                                  : "Name (മലയാളം)"
                              }
                              value={instituteDetail?.institute_malayalam_name}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col sm="12" md="6">
                            <AvField
                              name="institute_place"
                              type="text"
                              label={
                                language === "mal" ? "സ്ഥലം" : "Madrasa Place"
                              }
                              value={instituteDetail?.institute_place}
                            />
                          </Col>
                          <Col sm="12" md="6">
                            <AvField
                              name="established_date"
                              type="date"
                              label={
                                language === "mal"
                                  ? "സ്ഥാപിച്ച തീയതി"
                                  : "Established Date"
                              }
                              value={instituteDetail?.established_date}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4 mt-2">
                          <Col md="12">
                            <AvField
                              name="description"
                              type="textarea"
                              label={
                                language === "mal" ? "വിവരണം" : "Description"
                              }
                              value={instituteDetail?.description}
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Col>
                            <Label>
                              {" "}
                              {language === "mal" ? "ലോഗോ" : "Logo"}{" "}
                            </Label>
                            <AvField
                              name="logo"
                              type="file"
                              onChange={handleChange}
                            />
                            {handleFileSizes(filesSize)?.text && (
                              <p className="text-danger mt-1">
                                {handleFileSizes(filesSize)?.text}
                              </p>
                            )}
                            {imagePreview ? (
                              <Image
                                style={{ width: "150px", height: "150px" }}
                                className="my-2"
                                src={imagePreview.image}
                              />
                            ) : (
                              <Image
                                style={{ width: "150px", height: "150px" }}
                                className="my-2"
                                src={instituteDetail?.logo}
                              />
                            )}
                          </Col>
                        </div>
                        {/* <Row className="mb-5"> */}
                        <div className="d-flex justify-content-end mb-3">
                          <Button
                            type="submit"
                            color="success"
                            className="w-md"
                          >
                            {loading ||
                              (customLoad && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16"></i>
                                </>
                              ))}
                            {language === "mal" ? "സേവ് ചെയ്യുക " : "Save"}
                          </Button>
                        </div>
                        {/* </Row> */}
                      </AvForm>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <AvForm
                        className="form-horizontal "
                        onValidSubmit={(onSubmitProps, v) => {
                          handleValidSubmitMadrasaDetails(onSubmitProps, v)
                        }}
                      >
                        <Row>
                          <Col md="6">
                            <AvField
                              name="periods_per_day"
                              type="number"
                              value={madrasaDetail?.periods_per_day}
                              label={
                                language === "mal"
                                  ? "പ്രതിദിന പിരീഡുകൾ"
                                  : "Periods Per Day"
                              }
                            />
                          </Col>
                          <Col md="6">
                            <AvField
                              name="sadhar_muallim"
                              type="text"
                              value={madrasaDetail?.sadhar_muallim}
                              label={
                                language === "mal"
                                  ? "സദർ മുഅല്ലിം"
                                  : "Sadar Muallim"
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mt-5">
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Sunday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={sunday}
                                onChange={() => {}}
                                onClick={() => setSunday(!sunday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Monday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={monday}
                                onChange={() => {}}
                                onClick={() => setMonday(!monday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Tuesday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={tuesday}
                                onChange={() => {}}
                                onClick={() => setTuesday(!tuesday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Wednsday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={wednsday}
                                onChange={() => {}}
                                onClick={() => setWednsday(!wednsday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Thursday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={thursday}
                                onChange={() => {}}
                                onClick={() => setThursday(!thursday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Friday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={friday}
                                onChange={() => {}}
                                onClick={() => setFriday(!friday)}
                              />
                            </div>
                          </Col>
                          <Col sm={2} className="d-flex mb-4">
                            <Label className="px-2">
                              {language === "mal"
                                ? "എല്ലാ ഡാറ്റയും കാണുക"
                                : "Saturday"}
                            </Label>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={saturday}
                                onChange={() => {}}
                                onClick={() => setSaturday(!saturday)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="d-flex mb-4 justify-content-between mt-2">
                          <Button
                            type="button"
                            color="danger"
                            className="w-md"
                            onClick={handleDelete}
                          >
                            {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            {language === "mal"
                              ? "അപ്ഡേറ്റ് ചെയ്യുക"
                              : "Update"}
                          </Button>
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </>
  )
}

export default UpdateMahallMadrasa

UpdateMahallMadrasa.propTypes = {
  history: PropTypes.object,
}

function updateNewInstitute(
  selectedFile,
  values,
  mahallId,
  instituteType,
  instituteDetail,
  dispatch,
  setCustomLoad
) {
  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append("logo", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("mahall", mahallId)
  formData.append("institute_english_name", values.institute_english_name)
  formData.append("institute_malayalam_name", values.institute_malayalam_name)
  formData.append("established_date", values.established_date)
  formData.append("institute_type", instituteType)
  formData.append("institute_place", values.institute_place)
  formData.append("description", values.description)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .patch(
      `${API_URL}/api/v1/institute/institute/${instituteDetail.id}/`,
      formData,
      {
        headers: {
          Authorization: "token " + token,
          Role: role,
        },
      }
    )
    .then(res => {
      if (res && !res?.error) {
        dispatch(updateInstituteSuccess(res.data))
        doneNotification("Saved Successfully")
        setCustomLoad(false)
      } else {
        // updateInstituteFail(err)
        errorNotification()
      }
    })
    .catch(err => {
      updateInstituteFail(err)
      errorNotification()
      setCustomLoad(false)
    })
}
