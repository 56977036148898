import moment from "moment"

export const months = [
  {
    label: "Jan",
    id: 1,
  },
  {
    label: "Feb",
    id: 2,
  },
  {
    label: "Mar",
    id: 3,
  },
  {
    label: "Apr",
    id: 4,
  },
  {
    label: "May",
    id: 5,
  },
  {
    label: "Jun",
    id: 6,
  },
  {
    label: "Jul",
    id: 7,
  },
  {
    label: "Aug",
    id: 8,
  },
  {
    label: "Sep",
    id: 9,
  },
  {
    label: "Oct",
    id: 10,
  },
  {
    label: "Nov",
    id: 11,
  },
  {
    label: "Dec",
    id: 12,
  },
]

export const handleFinancialYear = monthIndex => {
  const today = new Date()
  const month = months[monthIndex - 1]?.label
  const monthToDate = moment()
    .month(month ? month : "Jan")
    .startOf("month")
  const getDaysDiffBetweenDates = (today - monthToDate) / (1000 * 3600 * 24)
  const fromDate =
    Math.sign(getDaysDiffBetweenDates) === 1 ||
    Math.sign(getDaysDiffBetweenDates) === 0
      ? moment()
          .month(month ? month : "Jan")
          .startOf("month")
      : moment()
          .month(month ? month : "Jan")
          .startOf("month")
          .subtract(1, "y")
  const toDate = moment(fromDate).add(12, "M")
  const finalToDate = moment(toDate).subtract(1, "d")

  return {
    fromDate: moment(fromDate).format("DD-MMM-YYYY"),
    finalToDate: moment(finalToDate).format("DD-MMM-YYYY"),
  }
}
