import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"
import PropTypes from "prop-types"

import {
  getSurveyUser,
  // deleteGroup,
  changeSurveyUserStatus,
} from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"

const AllSurveyUsers = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const { surveyUsers, loading, language } = useSelector(state => ({
    surveyUsers: state.SurveyUsers.surveyUsers,
    loading: state.SurveyUsers.loading,
    language: state.Layout.language,
  }))

  //pages
  const totalPages = Math.ceil(surveyUsers?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getSurveyUser(searchText, pageSend()))
  }, [dispatch, page, searchText])

  // const handleDelete = id => {
  //   dispatch(deleteGroup(id, history))
  // }

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    {
      dataField: "full_name",
      text: `${language === "mal" ? "പേര്" : "Name"}`,
    },
    {
      dataField: "phone",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "email",
      text: `${language === "mal" ? "ഇമെയിൽ" : "Email"}`,
    },
    {
      dataField: "date_added",
      text: `${language === "mal" ? "ചേർന്ന തീയതി" : "Join Date"}`,
    },
    {
      dataField: "last_login",
      text: `${language === "mal" ? "അവസാന ലോഗിൻ" : "Last Login"}`,
    },
    {
      dataField: "is_active",
      text: `${language === "mal" ? "സ്റ്റാറ്റസ്" : "Status"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]

  const handleSwitchChange = (e, id) => {
    dispatch(changeSurveyUserStatus(id, e.target.checked))
    // window.location.reload()
  }

  const SurveyUsersData = map(surveyUsers?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    date_added: moment(item?.date_added).format("DD/MM/YYYY"),
    last_login: moment(item?.last_login).format("DD/MM/YYYY - h:mm A"),
    mahall_english_name:
      language === "mal"
        ? item?.mahall_malayalam_name
          ? item?.mahall_malayalam_name
          : item?.mahall_english_name
        : item?.mahall_english_name,
    is_active: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={e => {
              handleSwitchChange(e, item?.id)
            }}
            checked={item?.is_active}
          />
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={`/surveyuser/update/${item?.id}`} className="m-0">
          <Button type="button" color="primary" className=" btn w-xs btn-sm">
            {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
            {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
          </Button>
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMahallSearch = debounce(value => setSearchText(value), 600)

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        <Row>
          <div className="col-md-6">
            <Link
              to={`/surveyuser/create`}
              type="submit"
              style={{ width: language === "mal" ? "200px" : "150px" }}
              className="w-md btn btn-success d-block"
            >
              {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New SurveyUser"}
            </Link>
          </div>
        </Row>
        <Col className="col-12 mt-2">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={SurveyUsersData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceMahallSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {surveyUsers && surveyUsers?.results?.length <= 0 && (
                      <p
                        style={{ fontSize: "15px" }}
                        className="text-center text-info"
                      >
                        {language === "mal"
                          ? "യൂസറുകൾ ഒന്നും ഇല്ല"
                          : "No Users Yet"}
                      </p>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllSurveyUsers

AllSurveyUsers.propTypes = {
  history: PropTypes.object,
}
