import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_STAFFS,
  GET_STAFF_DETAIL,
  CREATE_STAFF,
  UPDATE_STAFF,
  DELETE_STAFF,
  GET_STAFF_DROPDOWN,
} from "./actionTypes"
import {
  getStaffsSuccess,
  getStaffsFail,
  getStaffDetailSuccess,
  getStaffDetailFail,
  createStaffSuccess,
  createStaffFail,
  updateStaffSuccess,
  updateStaffFail,
  deleteStaffSuccess,
  deleteStaffFail,
  getStaffDropdownSuccess,
  getStaffDropdownFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getStaffsAPi = ({ searchText, page, instituteType }) => {
  if (instituteType) {
    return get(
      `/api/v1/madrasa/madrasa-staff/?institute=${
        instituteType ? instituteType : ""
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/madrasa/madrasa-staff?page=${page ? page : 1}&search=${
        searchText && searchText
      }`
    )
  }
}
const getStaffDropdownAPi = ({ searchText, instituteId }) => {
  return get(
    `/api/v1/madrasa/madrasa-staff-dropdown/?institute=${
      instituteId ? instituteId : ""
    }&search=${searchText ? searchText : ""}`
  )
}
const getStaffDetailsAPi = staffId => {
  return get(`/api/v1/madrasa/madrasa-staff/${staffId}/`)
}
const createStaffApi = ({ newStaff }) => {
  return post("/api/v1/madrasa/madrasa-staff/", newStaff)
}
const updateStaffApi = ({ staffId, updatedStaff }) => {
  return ApiPut(`/api/v1/madrasa/madrasa-staff/${staffId}/`, updatedStaff)
}
const deleteStaffApi = ({ staffId }) => {
  return del(`/api/v1/madrasa/madrasa-staff/${staffId}/`)
}

function* fetchStaffs({ payload }) {
  try {
    const response = yield call(getStaffsAPi, payload)
    if (response && !response?.error) {
      yield put(getStaffsSuccess(response))
    } else {
      yield put(getStaffsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStaffsFail(error))
  }
}

function* fetchStaffDropdown({ payload }) {
  try {
    const response = yield call(getStaffDropdownAPi, payload)
    if (response && !response?.error) {
      yield put(getStaffDropdownSuccess(response))
    } else {
      yield put(getStaffDropdownFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStaffDropdownFail(error))
  }
}

function* fetchStaffDetail({ staffId }) {
  try {
    const response = yield call(getStaffDetailsAPi, staffId)
    if (response && !response?.error) {
      yield put(getStaffDetailSuccess(response))
    } else {
      yield put(getStaffDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getStaffDetailFail(error))
  }
}
function* onCreateStaff({ payload }) {
  try {
    const response = yield call(createStaffApi, payload)
    if (response && !response?.error) {
      yield put(createStaffSuccess(response))
      doneNotification("Created Successfully")
    } else {
      yield put(createStaffFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createStaffFail(error))
    errorNotification()
  }
}

function* onUpdateStaff({ payload }) {
  try {
    const response = yield call(updateStaffApi, payload)
    if (response && !response?.error) {
      yield put(updateStaffSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateStaffFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateStaffFail(error))
    errorNotification()
  }
}

function* onDeleteStaff({ payload }) {
  try {
    const response = yield call(deleteStaffApi, payload)
    if (response && !response?.error) {
      yield put(deleteStaffSuccess(payload?.staffId))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteStaffFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteStaffFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* StaffsSaga() {
  yield takeEvery(GET_STAFFS, fetchStaffs)
  yield takeEvery(GET_STAFF_DROPDOWN, fetchStaffDropdown)
  yield takeEvery(GET_STAFF_DETAIL, fetchStaffDetail)
  yield takeEvery(CREATE_STAFF, onCreateStaff)
  yield takeEvery(UPDATE_STAFF, onUpdateStaff)
  yield takeEvery(DELETE_STAFF, onDeleteStaff)
}

export default StaffsSaga
