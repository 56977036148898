import {
  GET_SUPER_BANNERS,
  GET_SUPER_BANNERS_DETAILS,
  GET_SUPER_BANNER_DETAILS_SUCCESS,
  GET_SUPER_BANNER_DETAILS_FAIL,
  GET_SUPER_BANNER_SUCCESS,
  GET_SUPER_BANNER_FAIL,
  CREATE_SUPER_BANNERS,
  CREATE_SUPER_BANNER_SUCCESS,
  CREATE_SUPER_BANNER_FAIL,
  UPDATE_SUPER_BANNERS,
  UPDATE_SUPER_BANNER_SUCCESS,
  UPDATE_SUPER_BANNER_FAIL,
  DELETE_SUPER_BANNERS,
  DELETE_SUPER_BANNER_SUCCESS,
  DELETE_SUPER_BANNER_FAIL,
} from "./actionTypes"

// SuperFeeds - This line cannot be edited or removed
export const getSuperBanners = (searchText, page) => ({
  type: GET_SUPER_BANNERS,
  payload: { searchText, page },
})

export const getSuperBannerSuccess = superBanners => ({
  type: GET_SUPER_BANNER_SUCCESS,
  payload: superBanners,
})

export const getSuperBannerFail = error => ({
  type: GET_SUPER_BANNER_FAIL,
  payload: error,
})

export const getSuperBannerDetails = superBannerId => ({
  type: GET_SUPER_BANNERS_DETAILS,
  payload: superBannerId,
})

export const getSuperBannerDetailsSuccess = superBannerDetails => ({
  type: GET_SUPER_BANNER_DETAILS_SUCCESS,
  payload: superBannerDetails,
})

export const getSuperBannerDetailsFail = error => ({
  type: GET_SUPER_BANNER_DETAILS_FAIL,
  payload: error,
})

export const createSuperBanners = (createData, history) => ({
  type: CREATE_SUPER_BANNERS,
  payload: { createData, history },
})

export const createSuperBannerSuccess = superBanners => ({
  type: CREATE_SUPER_BANNER_SUCCESS,
  payload: superBanners,
})

export const createSuperBannerFail = error => ({
  type: CREATE_SUPER_BANNER_FAIL,
  payload: error,
})

export const updateSuperBanners = (superBannerId, updateData, history) => ({
  type: UPDATE_SUPER_BANNERS,
  payload: { superBannerId, updateData, history },
})

export const updateSuperBannerSuccess = superBanners => ({
  type: UPDATE_SUPER_BANNER_SUCCESS,
  payload: superBanners,
})

export const updateSuperBannerFail = error => ({
  type: UPDATE_SUPER_BANNER_FAIL,
  payload: error,
})

export const deleteSuperBanners = (superBannerId, history) => ({
  type: DELETE_SUPER_BANNERS,
  payload: { superBannerId, history },
})

export const deleteSuperBannerSuccess = superBanners => ({
  type: DELETE_SUPER_BANNER_SUCCESS,
  payload: superBanners,
})

export const deleteSuperBannerFail = error => ({
  type: DELETE_SUPER_BANNER_FAIL,
  payload: error,
})
