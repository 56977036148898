import React, { useState, useEffect } from "react"
import {
  // Badge,
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams, Link } from "react-router-dom"
import PropTypes from "prop-types"
//actions institute
//components
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import UiModalAssetUpdate from "./UiModalAssetUpdate"

import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getAssetDetail, getAssetItems } from "store/actions"

const InstituteAssetDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const limit = 10

  const { assetDetail, loading, language, assetItems } = useSelector(state => ({
    assetDetail: state.Assets.assetDetail,
    language: state.Layout.language,
    loading: state.AssetItems.loading,
    assetItems: state.AssetItems.assetItems,
  }))

  const [modalAssetUpdate, setModalAssetUpdate] = useState(false)

  const totalPages = Math.ceil(assetItems?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getAssetItems(assetDetail?.id, searchText, page))
  }, [assetDetail, searchText, page])

  const cardData = [
    {
      title: `${language === "mal" ? "ആകെ സ്റ്റോക്കുകൾ" : "Total Stocks"}`,
      cardValue: assetDetail?.total_quantity ? assetDetail?.total_quantity : 0,
      iconClass: "error-circle",
      routeLink: "#!",
      loading: loading,
    },
    {
      title: `${language === "mal" ? "ആകെ മൂല്യം" : "Total Value"}`,
      cardValue: assetDetail?.total_value ? assetDetail?.total_value : 0,
      iconClass: "error-circle",
      routeLink: "#!",
      loading: loading,
    },
    // {
    //   title: "Unpaid",
    //   cardValue: ZakatItemCards?.total_fee_not_paid_Assets,
    //   iconClass: "check-circle",
    //   routeLink: "#!",
    //   loading: loading,
    //   alt: "No unpaid Assets",
    // },
  ]

  // const handleDelete = () => {
  //   setIsOpen(true)
  // }
  // const handleDeleteEvent = () => {
  //   dispatch(deleteLedger(ledgerDetail?.id, history))
  //   setIsOpen(false)
  // }

  useEffect(() => {
    dispatch(getAssetDetail(params.id))
  }, [])

  const debounceAssetItemSearch = debounce(value => setSearchText(value), 600)

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "item_name",
      text: language === "mal" ? "ഇനത്തിന്റെ പേര്" : "Item Name",
    },
    {
      dataField: "quantity",
      text: language === "mal" ? "സ്റ്റോക്കുകൾ" : "Stocks",
    },
    {
      dataField: "value",
      text: language === "mal" ? "മൂല്യം" : "Value",
    },
    {
      dataField: "remark",
      text: language === "mal" ? "റിമാർക്" : "Remark",
    },
    {
      dataField: "action",
      text: language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions",
    },
  ]

  const assetItemsData = map(assetItems?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    action: (
      <div className="m-0">
        <Link
          to={`/mahall/institute/assetItem/${item?.id}`}
          className="btn btn-success btn-sm"
        >
          {language === "mal" ? "കാണുക" : "View"}
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  return (
    <>
      <UiModalAssetUpdate
        show={modalAssetUpdate}
        onCloseclick={() => setModalAssetUpdate(false)}
        history={history}
      />

      <MetaTags>
        <title>Asset | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ആസ്തി വിവരങ്ങൾ" : "Asset Detail"}
          breadcrumbItem={language === "mal" ? "ആസ്തി" : "Asset"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col className="col-lg-12 col-md-12">
                <Row>
                  <Col md="4">
                    <Card className="overflow-hidden bg-primary bg-soft">
                      <div className="">
                        <Row>
                          <div className="text-primary p-4">
                            <div className="px-2">
                              <Row>
                                <Col md="12">
                                  <h5 className="text-khaf">Asset</h5>
                                  <h4 className="mt-4">
                                    {assetDetail?.asset_name}
                                  </h4>
                                  <p className="m-0">{assetDetail?.remark}</p>
                                  {/* <div className=" d-flex justify-content-end m-3">
                                    <Button type="button" className="btn-sm bg-khaf-blue text-white" onClick={() => setModalAssetUpdate(true)}>
                                      <i className="bx bx-edit text-center"></i>{language === "mal" ? " അപ്ഡേറ്റ്" : "Update"}</Button>
                                  </div> */}
                                  {/* <p className="text-muted">From {(moment(ledgerDetail?.ledgers?.date_added).format("DD/MM/YYYY"))}</p> */}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Row>
                      </div>
                      <div className=" d-flex justify-content-end mx-3 mb-2">
                        <Button
                          type="button"
                          className="btn-sm bg-khaf-blue text-white"
                          onClick={() => setModalAssetUpdate(true)}
                        >
                          <i className="bx bx-edit text-center"></i>
                          {language === "mal" ? " അപ്ഡേറ്റ്" : "Update"}
                        </Button>
                      </div>
                    </Card>
                  </Col>
                  <Col md="8">
                    <Row>
                      {cardData?.map((item, key) => (
                        <Col sm="12" md="6" lg="6" key={key} className="sm:p-0">
                          <Card className="blog-stats-wid">
                            <CardBody>
                              <div className="d-flex flex-wrap">
                                <div className="me-3">
                                  <h5 className="text-muted">{item.title}</h5>
                                  <h4 className={`${item.className}`}>
                                    {item.cardValue}
                                  </h4>
                                </div>
                                <div className="avatar-sm ms-auto">
                                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                    <i
                                      className={
                                        "bx bx-" +
                                        `${item.iconClass} ${item.className}`
                                      }
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="">
                <React.Fragment>
                  <Row style={{ minHeight: "600px", display: "flex" }}>
                    <Col className="col-lg-12 col-md-12">
                      <Card>
                        <CardBody>
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={assetItemsData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <form
                                          className="app-search d-lg-block"
                                          onChange={e =>
                                            debounceAssetItemSearch(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <div className="position-relative">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search..."
                                              defaultValue={searchText}
                                            />
                                            <span className="bx bx-search-alt" />
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="8">
                                    <div className="d-flex justify-content-end">
                                      <Link
                                        to={{
                                          pathname:
                                            "/mahall/institute/assetItem/create",
                                          state: assetDetail,
                                        }}
                                        className="btn btn-primary btn-sm"
                                      >
                                        {language === "mal"
                                          ? "+ എൻട്രി ചേർക്കുക"
                                          : "+ Add Asset Item"}
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                                {loading ? (
                                  <Spinner
                                    color="secondary"
                                    className="d-block m-auto"
                                  />
                                ) : (
                                  <>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"id"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap table-hover"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <MyPagination
                                      totalPages={pages}
                                      page={page}
                                      setPage={setPage}
                                    />
                                  </>
                                )}
                                {assetItems &&
                                  assetItems?.results &&
                                  assetItems?.results?.length <= 0 && (
                                    <p
                                      style={{ fontSize: "15px" }}
                                      className="text-center text-info"
                                    >
                                      {language === "mal"
                                        ? "ഇനങ്ങൾ ഒന്നും ഇല്ല"
                                        : "No Items Yet"}
                                    </p>
                                  )}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col className="col-lg-4 col-md-12" style={{minHeight:'600px'}}>
                      <Card className="" style={{minHeight:'500px'}}>
                        <CardBody>

                        </CardBody>
                      </Card>
                    </Col> */}
                  </Row>
                </React.Fragment>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default InstituteAssetDetails

InstituteAssetDetails.propTypes = {
  history: PropTypes.object,
}
