import React, { Fragment, useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
//actions institute
//componetns
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import UiModalCommitteeUpdate from "./UiModalCommitteeUpdate"
import UiModalCommitteeMember from "./UiModalCommitteeMember"
import UiModalCommitteeMemberUpdate from "./UiModalCommitteeMemberUpdate"

import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getCommitteeDetail,
  // getCommitteeMembers,
  getCommitteeMeetings,
  getCommitteeMembers,
} from "store/actions"
import CardContact from "components/Common/card-contact"

const InstituteCommitteeDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    loading,
    memberLoading,
    language,
    committeeMembers,
    committeeDetail,
    committeeMeetings,
    committeeDetailCards,
  } = useSelector(state => ({
    language: state.Layout.language,
    loading: state.Committees.loading,
    memberLoading: state.CommitteeMembers.loading,
    committeeMembers: state.CommitteeMembers.committeeMembers,
    committeeDetail: state.Committees.committeeDetail?.committee,
    committeeDetailCards: state.Committees.committeeDetail?.cards,
    committeeMeetings: state.CommitteeMeetings.committeeMeetings,
  }))

  const cardData = [
    {
      title: `${language === "mal" ? "അംഗങ്ങൾ" : "Members"}`,
      cardValue: `${committeeDetailCards?.committee_members ?? 0}`,
      iconClass: "group",
      routeLink: "/#!",
      loading: loading,
      alt: `${language === "mal" ? "അംഗങ്ങൾ ഇല്ല" : "No Members"}`,
    },
    {
      title: `${language === "mal" ? "യോഗങ്ങൾ" : "Meetings"}`,
      cardValue: `${committeeDetailCards?.committee_meetings ?? 0}`,
      iconClass: "chat",
      routeLink: "/#!",
      loading: loading,
      alt: `${language === "mal" ? "യോഗങ്ങൾ ഇല്ല" : "No Meetings"}`,
    },
    {
      title: `${
        language === "mal"
          ? `യോഗങ്ങൾ (${moment().format("MMM")})`
          : `Meetings (${moment().format("MMM")})`
      }`,
      cardValue: `${committeeDetailCards?.meetings_this_month ?? 0}`,
      iconClass: "chat",
      routeLink: "/#!",
      loading: loading,
      alt: `${language === "mal" ? "യോഗങ്ങൾ ഇല്ല" : "No Meetings"}`,
    },
  ]

  const [modalCommitteeUpdate, setModalCommitteeUpdate] = useState(false)
  const [modalCommitteeMember, setModalCommitteeMember] = useState(false)
  const [modalCommitteeMemberUpdate, setModalCommitteeMemberUpdate] =
    useState(false)
  const [onUpdateCommitteeMember, setOnUpdateCommitteeMember] = useState({})

  const updateCommitteeMemberModal = item => {
    setOnUpdateCommitteeMember(item)
    setModalCommitteeMemberUpdate(true)
  }

  const [searchTextMeeting, setSearchTextMeeting] = useState("")
  const [meetingPage, setMeetingPage] = useState(1)
  const [memberPage, setMemberPage] = useState(1)

  const totalMeetingPages = Math.ceil(committeeMeetings?.count / 10)
  const meetingPages = range(1, totalMeetingPages + 1)

  const meetingPageSend = () => {
    if (meetingPage >= meetingPages.length) {
      return meetingPages.length
    }
    if (meetingPage < 1) {
      return 1
    } else {
      return meetingPage
    }
  }

  const totalMemberPages = Math.ceil(committeeMembers?.count / 8)
  const memberPages = range(1, totalMemberPages + 1)

  const memberPageSend = () => {
    if (memberPage >= memberPages?.length) {
      return memberPages.length
    }
    if (memberPage < 1) {
      return 1
    } else {
      return memberPage
    }
  }

  const debounceCommitteeMeetingSearch = debounce(
    value => setSearchTextMeeting(value),
    600
  )

  const committeeMeetingColumns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
      sort: true,
    },
    {
      dataField: "title",
      text: `${language === "mal" ? "ടൈറ്റിൽ" : "Title"}`,
      sort: true,
    },
    {
      dataField: "meeting_date",
      text: `${language === "mal" ? "മീറ്റിംഗ് തീയതി" : "Meeting Date"}`,
    },
    {
      dataField: "attandance_percentage",
      text: `${language === "mal" ? "ഹാജർ %" : "Attendance %"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const committeeMeetingsData = map(
    committeeMeetings?.results,
    (item, index) => ({
      ...item,
      key: index,
      no: index + 1,
      meeting_date: moment(item?.meeting_date).format("DD/MM/YYYY"),
      action: (
        <div className="m-0">
          <Link to={`/committee/meetings/${item.id}`}>
            <Button type="button" color="success" className=" btn w-xs btn-sm">
              {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
              View
            </Button>
          </Link>
          {/* <Link to={`/institutes/${item?.id}`} className="btn-light btn-sm">
          View
        </Link> */}
        </div>
      ),
    })
  )

  const defaultSortedMeeting = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRowMeeting = {
    mode: "checkbox",
  }

  useEffect(() => {
    if (committeeDetail?.id) {
      dispatch(getCommitteeMembers(committeeDetail?.id, "", memberPageSend()))
    }
  }, [committeeDetail, memberPage])

  useEffect(() => {
    if (committeeDetail?.id) {
      dispatch(
        getCommitteeMeetings(
          committeeDetail?.id,
          searchTextMeeting,
          meetingPageSend()
        )
      )
    }
  }, [committeeDetail, searchTextMeeting, meetingPage])

  useEffect(() => {
    dispatch(getCommitteeDetail(params.id))
  }, [])

  return (
    <>
      <UiModalCommitteeUpdate
        show={modalCommitteeUpdate}
        onCloseclick={() => setModalCommitteeUpdate(false)}
        history={history}
      />
      <UiModalCommitteeMember
        show={modalCommitteeMember}
        onCloseclick={() => setModalCommitteeMember(false)}
        history={history}
      />
      page
      <UiModalCommitteeMemberUpdate
        show={modalCommitteeMemberUpdate}
        onCloseclick={() => setModalCommitteeMemberUpdate(false)}
        data={onUpdateCommitteeMember ? onUpdateCommitteeMember : ""}
        history={history}
      />
      <MetaTags>
        <title>Committee | Khaf </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal" ? "കമ്മിറ്റി വിശദാംശങ്ങൾ" : "Committee Details"
          }
          breadcrumbItem={language === "mal" ? "കമ്മിറ്റി" : "Committee"}
        />
        <Container fluid>
          <div className="container-fluid">
            {/* {loading ? (
              <Spinner color="secondary" className="d-block m-auto" />
            ) : ( */}
            <>
              <Row>
                <Col className="col-lg-12 col-md-12">
                  <Row>
                    <Col md="4">
                      <Card className="overflow-hidden">
                        <div className="bg-primary bg-soft">
                          <Row>
                            <div className="text-primary p-4">
                              <div className="px-2">
                                <Row>
                                  <Col md="12">
                                    <h5 className="text-khaf">Committee</h5>
                                    <h4 className="mt-4">
                                      {committeeDetail?.title}
                                    </h4>
                                    {/* <p className="text-muted">From {(moment(ledgerDetail?.ledgers?.date_added).format("DD/MM/YYYY"))}</p> */}
                                  </Col>
                                  <div className=" d-flex justify-content-end">
                                    <Button
                                      type="button"
                                      className="btn-sm bg-khaf-blue"
                                      onClick={() =>
                                        setModalCommitteeUpdate(true)
                                      }
                                    >
                                      <i className="bx bx-edit text-center"></i>
                                      {language === "mal"
                                        ? " അപ്ഡേറ്റ്"
                                        : "Update"}
                                    </Button>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                    <Col md="8">
                      <Row>
                        {cardData?.map((item, key) => (
                          <Col
                            sm="12"
                            md="6"
                            lg="4"
                            key={key}
                            className="sm:p-0"
                          >
                            <Card className="blog-stats-wid">
                              <CardBody>
                                <Link to={item.routeLink && item.routeLink}>
                                  <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                      <p className="text-muted mb-2">
                                        {item.title}
                                      </p>
                                      {item.cardValue && (
                                        <h5 className="mb-0">
                                          {item.cardValue}
                                        </h5>
                                      )}
                                      <p className="m-0 text-info">
                                        {item.cardValue ? <></> : item.alt}
                                      </p>
                                    </div>

                                    <div className="avatar-sm ms-auto">
                                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                        <i
                                          className={
                                            "bx bxs-" + `${item.iconClass}`
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <h5 className="pb-1">
                  {language === "mal" ? "അംഗങ്ങൾ :" : "Members :"}
                </h5>
                <div className="d-flex justify-content-end">
                  <Button
                    type="button"
                    color="primary"
                    className="btn-sm mb-4"
                    onClick={() => setModalCommitteeMember(true)}
                  >
                    {language === "mal" ? "+ അംഗത്തെ ചേർക്കുക" : "+ Add Member"}
                  </Button>
                </div>
                {memberLoading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <Row>
                    {map(committeeMembers?.results, (user, key) => (
                      <CardContact
                        user={user}
                        updateCommitteeMemberModal={updateCommitteeMemberModal}
                        key={"_user_" + key}
                      />
                    ))}
                    <MyPagination
                      totalPages={memberPages}
                      page={memberPage}
                      setPage={setMemberPage}
                    />
                  </Row>
                )}
              </Row>
              <Row>
                <h5 className="pb-1">
                  {language === "mal" ? "യോഗങ്ങൾ :" : "Meetings :"}
                </h5>
                <Col lg="12" className="">
                  <React.Fragment>
                    <Row style={{ display: "flex" }}>
                      <Col className="col-lg-12 col-md-12">
                        <Card>
                          <CardBody>
                            <ToolkitProvider
                              keyField="id"
                              columns={committeeMeetingColumns}
                              data={committeeMeetingsData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <form
                                            className="app-search d-lg-block"
                                            onChange={e =>
                                              debounceCommitteeMeetingSearch(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <div className="position-relative">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={
                                                  language === "mal"
                                                    ? "തിരയുക..."
                                                    : "Search..."
                                                }
                                                defaultValue={searchTextMeeting}
                                              />
                                              <span className="bx bx-search-alt" />
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="8">
                                      <div className="d-flex justify-content-end">
                                        <Link
                                          to={`/committee/meeting/create?committeeid=${committeeDetail?.id}`}
                                          className="btn-sm btn btn-primary"
                                        >
                                          {language === "mal"
                                            ? "+ യോഗം ചേർക്കുക"
                                            : "+ Add Meeting"}
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                  {loading ? (
                                    <Spinner
                                      color="secondary"
                                      className="d-block m-auto"
                                    />
                                  ) : (
                                    <>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField={"id"}
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={
                                                defaultSortedMeeting
                                              }
                                              selectRow={selectRowMeeting}
                                              classes={
                                                "table align-middle table-nowrap table-hover"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <MyPagination
                                        totalPages={meetingPages}
                                        page={meetingPage}
                                        setPage={setMeetingPage}
                                      />
                                    </>
                                  )}
                                  {committeeMeetings &&
                                    committeeMeetings?.results &&
                                    committeeMeetings?.results?.length <= 0 && (
                                      <p
                                        style={{ fontSize: "15px" }}
                                        className="text-center text-info"
                                      >
                                        No Meetings Yet
                                      </p>
                                    )}
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </React.Fragment>
                </Col>
              </Row>
            </>
            {/* )} */}
          </div>
        </Container>
      </div>
    </>
  )
}

export default InstituteCommitteeDetails

InstituteCommitteeDetails.propTypes = {
  history: PropTypes.object,
}
