import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import axios from "axios"
//actions
import { Image } from "react-bootstrap"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"

import { API_URL } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import {
  updateIndividualNotificationSuccess,
  updateIndividualNotificationFail,
  getIndividualNotificationDetail,
  deleteIndividualNotification,
} from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UpdateIndividualNotification = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const { language, individualNotificationDetail, memberId } = useSelector(
    state => ({
      loading: state.Mahalls.loading,
      error: state.Mahalls.error,
      groupId: state.Users.userDetail?.mahall_group?.id,
      language: state.Layout.language,
      individualNotificationDetail:
        state.Notifications.individualNotificationDetail,
      memberId: state.Notifications.individualNotificationDetail?.family_member,
    })
  )

  // const date = individualNotificationDetail?.datetime
  // const dateTime = date?.split("T")[0]

  const notificationId = individualNotificationDetail?.id

  // eslint-disable-next-line no-unused-vars
  const [customLoad, setCustomLoad] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })

  const [imagePreview, setImagePreview] = useState()

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    setCustomLoad(true)
    updateIndividualNotification(
      selectedFile,
      memberId,
      values,
      notificationId,
      dispatch,
      history,
      setCustomLoad
    )
    setImagePreview()
  }

  // const changeHandler = e => {
  //   setSelectedFile({
  //     ...selectedFile,
  //     image: e.target.files[0],
  //   })

  //   if (e.target.files && e.target.files[0]) {
  //     let reader = new FileReader()
  //     reader.onload = e => {
  //       setImagePreview({ image: e.target.result })
  //     }
  //     reader.readAsDataURL(e.target.files[0])
  //   }
  // }

  useEffect(() => {
    dispatch(getIndividualNotificationDetail(params.id))
  }, [])

  const [isOpen, setIsOpen] = useState(false)

  const handleDeleteEvent = () => {
    dispatch(
      deleteIndividualNotification(individualNotificationDetail?.id, history)
    )
    setIsOpen(false)
  }

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <MetaTags>
        <title>Notifications | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "അറിയിപ്പുകൾ" : "Notifications"}
          breadcrumbItem={language === "mal" ? "അപ്ഡേറ്റ്  ചെയ്യുക" : "Update"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="8">
                <Card>
                  <CardBody>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="row mb-3">
                        <Col sm="12" md="6">
                          <AvField
                            name="sender"
                            type="text"
                            readOnly
                            value={individualNotificationDetail?.sender}
                            label={language === "mal" ? "നിന്ന്" : "From"}
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <Label>
                            {language === "mal" ? "ലേക്ക്" : "To"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="title"
                            type="text"
                            value={
                              individualNotificationDetail?.family_member_name
                            }
                            readOnly
                          />
                        </Col>
                      </div>

                      <div className="row mb-3">
                        <Col className="mb-3">
                          <Label>
                            {language === "mal" ? "ടൈറ്റിൽ" : "Title"}
                            <span className="text-danger">&#42;</span>
                          </Label>
                          <AvField
                            name="title"
                            type="text"
                            readOnly
                            value={individualNotificationDetail?.title}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-3">
                        <Col className="mb-3">
                          <AvField
                            name="sub_title"
                            type="text"
                            readOnly
                            value={individualNotificationDetail?.sub_title}
                            label={
                              language === "mal" ? "സബ്‌ടൈറ്റിൽ" : "Subtitle"
                            }
                          />
                        </Col>
                      </div>

                      {/* <div className="row mb-1  ">
                        <Col sm="12" md="6" className="mb-3">
                          <AvField
                            name="datetime"
                            type="date"
                            value={dateTime}
                            label={language === "mal" ? "തീയതി" : "Date"}
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <AvField
                            name="sender"
                            type="text"
                            value={individualNotificationDetail?.sender}
                            label={language === "mal" ? "അയച്ചയാൾ" : "Sender"}
                          />
                        </Col>
                      </div> */}

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            name="description"
                            type="textarea"
                            readOnly
                            value={individualNotificationDetail?.description}
                            label={
                              language === "mal" ? "വിവരണം" : "Description"
                            }
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          {/* <Label> {language === "mal" ? "ഫോട്ടോ" : "Image"} </Label>
                          <AvField
                            name="image"
                            type="file"
                            onChange={changeHandler}
                          /> */}
                          {imagePreview ? (
                            <Image
                              style={{ width: "150px", height: "150px" }}
                              className="my-2"
                              src={imagePreview.image}
                            />
                          ) : (
                            <Image
                              style={{ width: "150px", height: "150px" }}
                              className="my-2"
                              src={individualNotificationDetail?.image}
                            />
                          )}
                        </Col>
                      </div>

                      {/* <Row className="task-dates">
                        <Col sm="12" xs="12">
                          <div className="d-flex justify-content-end">
                            <div className="">
                              <Button
                                type="submit"
                                color="primary"
                                className="btn btn-primary"
                              >
                                Update{" "}
                              </Button>
                            </div>
                            <div style={{ position: "relative", left: "10px" }}>
                              <Button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleDelete}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row> */}
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateIndividualNotification

UpdateIndividualNotification.propTypes = {
  history: PropTypes.object,
}

function updateIndividualNotification(
  selectedFile,
  memberId,
  values,
  notificationId,
  dispatch,
  history,
  setCustomLoad
) {
  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append("image", selectedFile?.image, selectedFile?.image?.name)
  }
  formData.append("family_member", memberId)
  formData.append("title", values.title)
  formData.append("sub_title", values.sub_title)
  // formData.append("datetime", values.datetime)
  formData.append("sender", values.sender)
  formData.append("description", values.description)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .put(
      `${API_URL}/api/v1/notification/individual_notification/${notificationId}/`,
      formData,
      {
        headers: {
          Authorization: "token " + token,
          Role: role,
        },
      }
    )
    .then(res => {
      dispatch(updateIndividualNotificationSuccess(res.data))
      history.push("/notifications")
      doneNotification("Updated Successfully")
      setCustomLoad(false)
    })
    .catch(err => {
      updateIndividualNotificationFail(err)
      errorNotification()
      setCustomLoad(false)
    })
}
