import React from "react"
import { useSelector } from "react-redux"

import MadrasaDashboard from "./madrasaDashboard"
import InstituteDashboard from "./instituteDashboard"

function InstituteUserDashboard() {
  const { userDetail } = useSelector(state => ({
    userDetail: state.Users.userDetail,
  }))
  return (
    <div>
      {userDetail?.institute?.institute_type === "madrasa" ? (
        <MadrasaDashboard />
      ) : (
        <InstituteDashboard />
      )}
    </div>
  )
}

export default InstituteUserDashboard
