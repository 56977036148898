import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FAMILY_VARISANGYAS,
  GET_FAMILY_VARISANGYA_CARDS,
  GET_FAMILY_VARISANGYA_DETAIL,
  CREATE_FAMILY_VARISANGYA,
  UPDATE_FAMILY_VARISANGYA,
  DELETE_FAMILY_VARISANGYA,
  AUTO_GENERATE_FAMILY_VARISANGYAS,
  REFRESH_FAMILY_VARISANGYA,
  UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
} from "./actionTypes"
import {
  getFamilyVarisangyasSuccess,
  getFamilyVarisangyasFail,
  getFamilyVarisangyaCardsSuccess,
  getFamilyVarisangyaCardsFail,
  getFamilyVarisangyaDetailSuccess,
  getFamilyVarisangyaDetailFail,
  createFamilyVarisangyaSuccess,
  createFamilyVarisangyaFail,
  updateFamilyVarisangyaSuccess,
  updateFamilyVarisangyaFail,
  deleteFamilyVarisangyaSuccess,
  deleteFamilyVarisangyaFail,
  autoGenerateFamilyVarisangyasSuccess,
  autoGenerateFamilyVarisangyasFail,
  refreshFamilyVarisangyaSuccess,
  refreshFamilyVarisangyaFail,
  updateFamilyVarisangyaAmountByFilterSuccess,
  updateFamilyVarisangyaAmountByFilterFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getFamilyVarisangyasAPi = ({ searchText, page, limit }) => {
  if (searchText) {
    return get(
      `/api/v1/varisankya/family_varisankya/?search=${
        searchText && searchText
      }&page=${page && page}&page_size=${limit ? limit : 10}`
    )
  } else {
    return get(
      `/api/v1/varisankya/family_varisankya/?page=${
        page ? page : 1
      }&page_size=${limit ? limit : 10}`
    )
  }
}

const autoGenerateFamilyVarisangyasAPi = () => {
  return post(`/api/v1/varisankya/family_varisankya_auto_generate/`)
}

const refreshFamilyVarisangyaAPi = ({ familyVarisangyaId }) => {
  console.log("familyVarisangyaId: ", familyVarisangyaId)
  return post(
    `/api/v1/varisankya/family_varisankya_refresh/${familyVarisangyaId}/`
  )
}

const updateFamilyVarisangyaAmountByFilterApi = ({
  familyVarisangyaAmuontByFilter,
}) => {
  return post(
    "/api/v1/varisankya/family_varisankya_amount_update_in_filter/",
    familyVarisangyaAmuontByFilter,
    { "Content-type": "" }
  )
}

const getFamilyVarisangyasCardsAPi = () => {
  return get(`/api/v1/varisankya/family_varisankya/?cards=all`)
}
const getFamilyVarisangyaDetailsAPi = familyVarisangyaId => {
  return get(`/api/v1/varisankya/family_varisankya/${familyVarisangyaId}/`)
}
const createFamilyVarisangyaApi = ({ familyVarisangya }) => {
  return post("/api/v1/varisankya/family_varisankya/", familyVarisangya)
}
const updateFamilyVarisangyaApi = ({
  familyVarisangyaId,
  familyVarisangya,
}) => {
  return ApiPut(
    `/api/v1/varisankya/family_varisankya/${familyVarisangyaId}/`,
    familyVarisangya
  )
}
const deleteFamilyVarisangyaApi = ({ familyVarisangyaId }) => {
  return del(`/api/v1/varisankya/family_varisankya/${familyVarisangyaId}/`)
}

function* fetchFamilyVarisangyas({ payload }) {
  try {
    const response = yield call(getFamilyVarisangyasAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyVarisangyasSuccess(response))
    } else {
      yield put(getFamilyVarisangyasFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyasFail(error))
  }
}

function* autoGenerateFamilyVarisangyas() {
  try {
    const response = yield call(autoGenerateFamilyVarisangyasAPi)
    if (response && !response?.error) {
      yield put(autoGenerateFamilyVarisangyasSuccess(response))
      try {
        const response2 = yield call(getFamilyVarisangyasAPi, { page: 1 })
        yield put(getFamilyVarisangyasSuccess(response2))
      } catch (error) {
        yield put(getFamilyVarisangyasFail(error))
      }
    } else {
      yield put(autoGenerateFamilyVarisangyasFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyasFail(error))
  }
}

function* refreshFamilyVarisangya({ payload }) {
  console.log(payload)
  try {
    const response = yield call(refreshFamilyVarisangyaAPi, payload)
    if (response && !response?.error) {
      yield put(refreshFamilyVarisangyaSuccess(response))
      doneNotification(response?.response)
      try {
        const response2 = yield call(
          getFamilyVarisangyaDetailsAPi,
          payload?.familyVarisangyaId
        )
        yield put(getFamilyVarisangyaDetailSuccess(response2))
      } catch (error) {
        yield put(getFamilyVarisangyaDetailFail(error))
      }
    } else {
      yield put(refreshFamilyVarisangyaFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    yield put(refreshFamilyVarisangyaFail(error))
  }
}

function* updateFamilyVarisangyaAmountByFilter({ payload }) {
  console.log("data :", payload)
  try {
    const response = yield call(
      updateFamilyVarisangyaAmountByFilterApi,
      payload
    )
    if (response && !response?.error) {
      console.log("response:", response?.error)
      yield put(updateFamilyVarisangyaAmountByFilterSuccess(response))
      payload.history.push("/family/wallet/details")
      doneNotification("Created Successfully")
    } else {
      yield put(updateFamilyVarisangyaAmountByFilterFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error :", error)
    yield put(updateFamilyVarisangyaAmountByFilterFail(error))
    errorNotification()
  }
}

function* fetchFamilyVarisangyasCards() {
  try {
    const response = yield call(getFamilyVarisangyasCardsAPi)
    if (response && !response?.error) {
      yield put(getFamilyVarisangyaCardsSuccess(response))
    } else {
      yield put(getFamilyVarisangyaCardsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyaCardsFail(error))
  }
}

function* fetchFamilyVarisangyaDetail({ familyVarisangyaId }) {
  try {
    const response = yield call(
      getFamilyVarisangyaDetailsAPi,
      familyVarisangyaId
    )
    if (response && !response?.error) {
      yield put(getFamilyVarisangyaDetailSuccess(response))
    } else {
      yield put(getFamilyVarisangyaDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyVarisangyaDetailFail(error))
  }
}
function* onCreateFamilyVarisangya({ payload }) {
  try {
    const response = yield call(createFamilyVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(createFamilyVarisangyaSuccess(response))
      if (payload.history) {
        payload.history.push("/varisangyas")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createFamilyVarisangyaFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createFamilyVarisangyaFail(error))
    errorNotification()
  }
}

function* onUpdateFamilyVarisangya({ payload }) {
  try {
    const response = yield call(updateFamilyVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(updateFamilyVarisangyaSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateFamilyVarisangyaFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateFamilyVarisangyaFail(error))
    errorNotification()
  }
}

function* onDeleteFamilyVarisangya({ payload }) {
  try {
    const response = yield call(deleteFamilyVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(deleteFamilyVarisangyaSuccess(payload.familyVarisangyaId))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteFamilyVarisangyaFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteFamilyVarisangyaFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* FamilyVarisangyasSaga() {
  yield takeEvery(GET_FAMILY_VARISANGYAS, fetchFamilyVarisangyas)
  yield takeEvery(GET_FAMILY_VARISANGYA_CARDS, fetchFamilyVarisangyasCards)
  yield takeEvery(GET_FAMILY_VARISANGYA_DETAIL, fetchFamilyVarisangyaDetail)
  yield takeEvery(CREATE_FAMILY_VARISANGYA, onCreateFamilyVarisangya)
  yield takeEvery(UPDATE_FAMILY_VARISANGYA, onUpdateFamilyVarisangya)
  yield takeEvery(DELETE_FAMILY_VARISANGYA, onDeleteFamilyVarisangya)
  yield takeEvery(
    AUTO_GENERATE_FAMILY_VARISANGYAS,
    autoGenerateFamilyVarisangyas
  )
  yield takeEvery(REFRESH_FAMILY_VARISANGYA, refreshFamilyVarisangya)
  yield takeEvery(
    UPDATE_FAMILY_VARISANGYA_AMOUNT_BY_FILTER,
    updateFamilyVarisangyaAmountByFilter
  )
}

export default FamilyVarisangyasSaga
