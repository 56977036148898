import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_FAMILY_MEMBER_COLLECTIONS,
  GET_FAMILY_MEMBER_COLLECTIONS_CARDS,
  GET_FAMILY_MEMBER_COLLECTION_DETAIL,
  CREATE_FAMILY_MEMBER_COLLECTION,
  UPDATE_FAMILY_MEMBER_COLLECTION,
  DELETE_FAMILY_MEMBER_COLLECTION,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS,
  GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL,
  CREATE_FAMILY_MEMBER_COLLECTION_MEMBER,
  UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER,
  DELETE_FAMILY_MEMBER_COLLECTION_MEMBER,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS,
  GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS,
  GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL,
  CREATE_FAMILY_MEMBER_FILTER_COLLECTION,
  UPDATE_FAMILY_MEMBER_FILTER_COLLECTION,
  DELETE_FAMILY_MEMBER_FILTER_COLLECTION,
} from "./actiontypes"
import {
  getFamilyMemberCollectionsSuccess,
  getFamilyMemberCollectionsFail,
  getFamilyMemberCollectionsCardsSuccess,
  getFamilyMemberCollectionsCardsFail,
  getFamilyMemberCollectionDetailSuccess,
  getFamilyMemberCollectionDetailFail,
  createFamilyMemberCollectionSuccess,
  createFamilyMemberCollectionFail,
  updateFamilyMemberCollectionSuccess,
  updateFamilyMemberCollectionFail,
  deleteFamilyMemberCollectionSuccess,
  deleteFamilyMemberCollectionFail,
  getFamilyMemberCollectionMembersSuccess,
  getFamilyMemberCollectionMembersFail,
  getFamilyMemberCollectionMembersCardsSuccess,
  getFamilyMemberCollectionMembersCardsFail,
  getFamilyMemberCollectionMemberDetailSuccess,
  getFamilyMemberCollectionMemberDetailFail,
  createFamilyMemberCollectionMemberSuccess,
  createFamilyMemberCollectionMemberFail,
  updateFamilyMemberCollectionMemberSuccess,
  updateFamilyMemberCollectionMemberFail,
  deleteFamilyMemberCollectionMemberSuccess,
  deleteFamilyMemberCollectionMemberFail,
  getFamilyMemberFilterCollectionsSuccess,
  getFamilyMemberFilterCollectionsFail,
  getFamilyMemberFilterCollectionsCardsSuccess,
  getFamilyMemberFilterCollectionsCardsFail,
  getFamilyMemberFilterCollectionDetailSuccess,
  getFamilyMemberFilterCollectionDetailFail,
  createFamilyMemberFilterCollectionSuccess,
  createFamilyMemberFilterCollectionFail,
  updateFamilyMemberFilterCollectionSuccess,
  updateFamilyMemberFilterCollectionFail,
  deleteFamilyMemberFilterCollectionSuccess,
  deleteFamilyMemberFilterCollectionFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

// ------------------------------------FAMILY MEMBER COLLECTIONS----------------------------------------

const getFamilyMemberCollectionsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/notification/familymember_collection/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/notification/familymember_collection/?page=${page ? page : 1}`
    )
  }
}
const getFamilyMemberCollectionsCardsAPi = () => {
  return get(`/api/v1/notification/familymember_collection/?cards=all`)
}
const getFamilyMemberCollectionDetailsAPi = familyMemberCollectionId => {
  return get(
    `/api/v1/notification/familymember_collection/${familyMemberCollectionId}/`
  )
}
const createFamilyMemberCollectionApi = ({ familyMemberCollection }) => {
  return post(
    "/api/v1/notification/familymember_collection/",
    familyMemberCollection,
    { "Content-type": "" }
  )
}
const updateFamilyMemberCollectionApi = ({
  familyMemberCollectionId,
  familyMemberCollection,
}) => {
  return ApiPut(
    `/api/v1/notification/familymember_collection/${familyMemberCollectionId}/`,
    familyMemberCollection
  )
}
const deleteFamilyMemberCollectionApi = ({ familyMemberCollectionId }) => {
  return del(
    `/api/v1/notification/familymember_collection/${familyMemberCollectionId}/`
  )
}

function* fetchFamilyMemberCollections({ payload }) {
  try {
    const response = yield call(getFamilyMemberCollectionsAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyMemberCollectionsSuccess(response))
    } else {
      yield put(getFamilyMemberCollectionsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberCollectionsFail(error))
  }
}

function* fetchFamilyMemberCollectionsCards({ payload }) {
  try {
    const response = yield call(getFamilyMemberCollectionsCardsAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyMemberCollectionsCardsSuccess(response))
    } else {
      yield put(getFamilyMemberCollectionsCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberCollectionsCardsFail(error))
  }
}

function* fetchFamilyMemberCollectionDetail({ familyMemberCollectionId }) {
  try {
    const response = yield call(
      getFamilyMemberCollectionDetailsAPi,
      familyMemberCollectionId
    )
    if (response && !response?.error) {
      yield put(getFamilyMemberCollectionDetailSuccess(response))
    } else {
      yield put(getFamilyMemberCollectionDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberCollectionDetailFail(error))
  }
}

function* onCreateFamilyMemberCollection({ payload }) {
  // console.log("data :", payload)
  try {
    const response = yield call(createFamilyMemberCollectionApi, payload)
    if (response && !response?.error) {
      yield put(createFamilyMemberCollectionSuccess(response))
      // payload.history.push("/mahalls")
      doneNotification("Created Successfully")
    } else {
      yield put(createFamilyMemberCollectionFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createFamilyMemberCollectionFail(error))
    errorNotification()
  }
}

function* onUpdateFamilyMemberCollection({ payload }) {
  try {
    const response = yield call(updateFamilyMemberCollectionApi, payload)
    if (response && !response?.error) {
      yield put(updateFamilyMemberCollectionSuccess(response))
      doneNotification("Updated Successfully")
      // try {
      //   const response = yield call(getFamilyMemberCollectionsAPi, { page: 1 })
      //   yield put(getFamilyMemberCollectionsSuccess(response))
      // } catch (error) {
      //   yield put(getFamilyMemberCollectionsFail(error))
      // }
    } else {
      yield put(updateFamilyMemberCollectionFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateFamilyMemberCollectionFail(error))
    errorNotification()
  }
}

function* onDeleteFamilyMemberCollection({ payload }) {
  try {
    const response = yield call(deleteFamilyMemberCollectionApi, payload)
    if (response && !response?.error) {
      yield put(
        deleteFamilyMemberCollectionSuccess(payload.familyMemberCollectionId)
      )
      doneNotification("Deleted Successfully")
      // try {
      //   const response = yield call(getFamilyMemberCollectionsAPi, { page: 1 })
      //   yield put(getFamilyMemberCollectionsSuccess(response))
      // } catch (error) {
      //   yield put(getFamilyMemberCollectionsFail(error))
      // }
    } else {
      yield put(deleteFamilyMemberCollectionFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteFamilyMemberCollectionFail(error))
  }
}

// ------------------------------------FAMILY MEMBER COLLECTION MEMBERS----------------------------------------

const getFamilyMemberCollectionMembersAPi = ({
  searchText,
  page,
  familyMemberCollectionId,
}) => {
  if (familyMemberCollectionId) {
    return get(
      `/api/v1/notification/familymember_collection_member/?collection=${
        familyMemberCollectionId && familyMemberCollectionId
      }&search=${searchText && searchText}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/notification/familymember_collection_member/?page=${
        page ? page : 1
      }`
    )
  }
}
const getFamilyMemberCollectionMembersCardsAPi = () => {
  return get(`/api/v1/notification/familymember_collection_member/?cards=all`)
}
const getFamilyMemberCollectionMemberDetailsAPi =
  familyMemberCollectionMemberId => {
    return get(
      `/api/v1/notification/familymember_collection_member/${familyMemberCollectionMemberId}/`
    )
  }
const createFamilyMemberCollectionMemberApi = ({
  familyMemberCollectionMember,
}) => {
  return post(
    "/api/v1/notification/familymember_collection_member/",
    familyMemberCollectionMember,
    { "Content-type": "" }
  )
}
const updateFamilyMemberCollectionMemberApi = ({
  familyMemberCollectionMemberId,
  familyMemberCollectionMember,
}) => {
  return ApiPut(
    `/api/v1/notification/familymember_collection_member/${familyMemberCollectionMemberId}/`,
    familyMemberCollectionMember
  )
}
const deleteFamilyMemberCollectionMemberApi = ({
  familyMemberCollectionMemberId,
}) => {
  return del(
    `/api/v1/notification/familymember_collection_member/${familyMemberCollectionMemberId}/`
  )
}

function* fetchFamilyMemberCollectionMembers({ payload }) {
  try {
    const response = yield call(getFamilyMemberCollectionMembersAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyMemberCollectionMembersSuccess(response))
    } else {
      yield put(getFamilyMemberCollectionMembersFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberCollectionMembersFail(error))
  }
}

function* fetchFamilyMemberCollectionMembersCards({ payload }) {
  try {
    const response = yield call(
      getFamilyMemberCollectionMembersCardsAPi,
      payload
    )
    if (response && !response?.error) {
      yield put(getFamilyMemberCollectionMembersCardsSuccess(response))
    } else {
      yield put(getFamilyMemberCollectionMembersCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberCollectionMembersCardsFail(error))
  }
}

function* fetchFamilyMemberCollectionMemberDetail({
  familyMemberCollectionMemberId,
}) {
  try {
    const response = yield call(
      getFamilyMemberCollectionMemberDetailsAPi,
      familyMemberCollectionMemberId
    )
    if (response && !response?.error) {
      yield put(getFamilyMemberCollectionMemberDetailSuccess(response))
    } else {
      yield put(getFamilyMemberCollectionMemberDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberCollectionMemberDetailFail(error))
  }
}

function* onCreateFamilyMemberCollectionMember({ payload }) {
  // console.log("data :", payload)
  try {
    const response = yield call(createFamilyMemberCollectionMemberApi, payload)
    if (response && !response?.error) {
      yield put(createFamilyMemberCollectionMemberSuccess(response))
      // payload.history.push("/mahalls")
      doneNotification("Added Successfully")
    } else {
      yield put(createFamilyMemberCollectionMemberFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(createFamilyMemberCollectionMemberFail(error))
    errorNotification()
  }
}

function* onUpdateFamilyMemberCollectionMember({ payload }) {
  try {
    const response = yield call(updateFamilyMemberCollectionMemberApi, payload)
    if (response && !response?.error) {
      yield put(updateFamilyMemberCollectionMemberSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateFamilyMemberCollectionMemberFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateFamilyMemberCollectionMemberFail(error))
    errorNotification()
  }
}

function* onDeleteFamilyMemberCollectionMember({ payload }) {
  try {
    const response = yield call(deleteFamilyMemberCollectionMemberApi, payload)
    if (response && !response?.error) {
      // payload.history.push("/mahalls")
      yield put(
        deleteFamilyMemberCollectionMemberSuccess(
          payload?.familyMemberCollectionMemberId
        )
      )
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteFamilyMemberCollectionMemberFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteFamilyMemberCollectionMemberFail(error))
  }
}

// ------------------------------------FAMILY MEMBER FILTER COLLECTION----------------------------------------

const getFamilyMemberFilterCollectionsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `/api/v1/notification/familymember_collection_create_in_filter/?search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/notification/familymember_collection_create_in_filter/?page=${
        page ? page : 1
      }`
    )
  }
}
const getFamilyMemberFilterCollectionsCardsAPi = () => {
  return get(
    `/api/v1/notification/familymember_collection_create_in_filter/?cards=all`
  )
}
const getFamilyMemberFilterCollectionDetailsAPi =
  familyMemberFilterCollectionId => {
    return get(
      `/api/v1/notification/familymember_collection_create_in_filter/${familyMemberFilterCollectionId}/`
    )
  }
const createFamilyMemberFilterCollectionApi = ({
  familyMemberFilterCollection,
}) => {
  return post(
    "/api/v1/notification/familymember_collection_create_in_filter/",
    familyMemberFilterCollection,
    { "Content-type": "" }
  )
}
const updateFamilyMemberFilterCollectionApi = ({
  familyMemberFilterCollectionId,
  familyMemberFilterCollection,
}) => {
  return ApiPut(
    `/api/v1/notification/familymember_collection_create_in_filter/${familyMemberFilterCollectionId}/`,
    familyMemberFilterCollection
  )
}
const deleteFamilyMemberFilterCollectionApi = ({
  familyMemberFilterCollectionId,
}) => {
  return del(
    `/api/v1/notification/familymember_collection_create_in_filter/${familyMemberFilterCollectionId}/`
  )
}

function* fetchFamilyMemberFilterCollections({ payload }) {
  try {
    const response = yield call(getFamilyMemberFilterCollectionsAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyMemberFilterCollectionsSuccess(response))
    } else {
      yield put(getFamilyMemberFilterCollectionsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberFilterCollectionsFail(error))
  }
}

function* fetchFamilyMemberFilterCollectionsCards({ payload }) {
  try {
    const response = yield call(
      getFamilyMemberFilterCollectionsCardsAPi,
      payload
    )
    if (response && !response?.error) {
      yield put(getFamilyMemberFilterCollectionsCardsSuccess(response))
    } else {
      yield put(getFamilyMemberFilterCollectionsCardsFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberFilterCollectionsCardsFail(error))
  }
}

function* fetchFamilyMemberFilterCollectionDetail({
  familyMemberFilterCollectionId,
}) {
  try {
    const response = yield call(
      getFamilyMemberFilterCollectionDetailsAPi,
      familyMemberFilterCollectionId
    )
    if (response && !response?.error) {
      yield put(getFamilyMemberFilterCollectionDetailSuccess(response))
    } else {
      yield put(getFamilyMemberFilterCollectionDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getFamilyMemberFilterCollectionDetailFail(error))
  }
}

function* onCreateFamilyMemberFilterCollection({ payload }) {
  console.log("data :", payload)
  try {
    const response = yield call(createFamilyMemberFilterCollectionApi, payload)
    if (response && !response?.error) {
      console.log("response:", response?.error)
      yield put(createFamilyMemberFilterCollectionSuccess(response))
      payload.history.push("/collections")
      doneNotification("Created Successfully")
    } else {
      yield put(createFamilyMemberFilterCollectionFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error :", error)
    yield put(createFamilyMemberFilterCollectionFail(error))
    errorNotification()
  }
}

function* onUpdateFamilyMemberFilterCollection({ payload }) {
  try {
    const response = yield call(updateFamilyMemberFilterCollectionApi, payload)
    if (response && !response?.error) {
      yield put(updateFamilyMemberFilterCollectionSuccess(response))
      doneNotification()
    } else {
      yield put(updateFamilyMemberFilterCollectionFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(updateFamilyMemberFilterCollectionFail(error))
    errorNotification()
  }
}

function* onDeleteFamilyMemberFilterCollection({ payload }) {
  try {
    const response = yield call(deleteFamilyMemberFilterCollectionApi, payload)
    if (response && !response?.error) {
      // payload.history.push("/mahalls")
      yield put(deleteFamilyMemberFilterCollectionSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteFamilyMemberFilterCollectionFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteFamilyMemberFilterCollectionFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* CollectionsSaga() {
  yield takeEvery(GET_FAMILY_MEMBER_COLLECTIONS, fetchFamilyMemberCollections)
  yield takeEvery(
    GET_FAMILY_MEMBER_COLLECTIONS_CARDS,
    fetchFamilyMemberCollectionsCards
  )
  yield takeEvery(
    GET_FAMILY_MEMBER_COLLECTION_DETAIL,
    fetchFamilyMemberCollectionDetail
  )
  yield takeEvery(
    CREATE_FAMILY_MEMBER_COLLECTION,
    onCreateFamilyMemberCollection
  )
  yield takeEvery(
    UPDATE_FAMILY_MEMBER_COLLECTION,
    onUpdateFamilyMemberCollection
  )
  yield takeEvery(
    DELETE_FAMILY_MEMBER_COLLECTION,
    onDeleteFamilyMemberCollection
  )

  yield takeEvery(
    GET_FAMILY_MEMBER_COLLECTION_MEMBERS,
    fetchFamilyMemberCollectionMembers
  )
  yield takeEvery(
    GET_FAMILY_MEMBER_COLLECTION_MEMBERS_CARDS,
    fetchFamilyMemberCollectionMembersCards
  )
  yield takeEvery(
    GET_FAMILY_MEMBER_COLLECTION_MEMBER_DETAIL,
    fetchFamilyMemberCollectionMemberDetail
  )
  yield takeEvery(
    CREATE_FAMILY_MEMBER_COLLECTION_MEMBER,
    onCreateFamilyMemberCollectionMember
  )
  yield takeEvery(
    UPDATE_FAMILY_MEMBER_COLLECTION_MEMBER,
    onUpdateFamilyMemberCollectionMember
  )
  yield takeEvery(
    DELETE_FAMILY_MEMBER_COLLECTION_MEMBER,
    onDeleteFamilyMemberCollectionMember
  )

  yield takeEvery(
    GET_FAMILY_MEMBER_FILTER_COLLECTIONS,
    fetchFamilyMemberFilterCollections
  )
  yield takeEvery(
    GET_FAMILY_MEMBER_FILTER_COLLECTIONS_CARDS,
    fetchFamilyMemberFilterCollectionsCards
  )
  yield takeEvery(
    GET_FAMILY_MEMBER_FILTER_COLLECTION_DETAIL,
    fetchFamilyMemberFilterCollectionDetail
  )
  yield takeEvery(
    CREATE_FAMILY_MEMBER_FILTER_COLLECTION,
    onCreateFamilyMemberFilterCollection
  )
  yield takeEvery(
    UPDATE_FAMILY_MEMBER_FILTER_COLLECTION,
    onUpdateFamilyMemberFilterCollection
  )
  yield takeEvery(
    DELETE_FAMILY_MEMBER_FILTER_COLLECTION,
    onDeleteFamilyMemberFilterCollection
  )
}

export default CollectionsSaga
