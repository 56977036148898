import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ASSETS,
  GET_ASSET_DETAIL,
  CREATE_ASSET,
  UPDATE_ASSET,
  DELETE_ASSET,
} from "./actionTypes"
import {
  getAssetsSuccess,
  getAssetsFail,
  getAssetDetailSuccess,
  getAssetDetailFail,
  createAssetSuccess,
  createAssetFail,
  updateAssetSuccess,
  updateAssetFail,
  deleteAssetSuccess,
  deleteAssetFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getAssetsAPi = ({ instituteId, searchText, page }) => {
  if (instituteId) {
    return get(
      `/api/v1/institute-asset/asset/?institute=${
        instituteId ? instituteId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/institute-asset/asset/?search=${
        searchText ? searchText : ""
      }&page=${page ? page : 1}`
    )
  }
}
// const getVarisangyasCardsAPi = () => {
//   return get(`/api/v1/varisankya/varisankya/?cards=all`)
// }
const getAssetDetailsAPi = assetId => {
  return get(`/api/v1/institute-asset/asset/${assetId}/`)
}
const createAssetApi = ({ asset }) => {
  return post("/api/v1/institute-asset/asset/", asset)
}
const updateAssetApi = ({ assetId, asset }) => {
  return ApiPut(`/api/v1/institute-asset/asset/${assetId}/`, asset)
}
const deleteAssetApi = ({ assetId }) => {
  return del(`/api/v1/institute-asset/asset/${assetId}/`)
}

function* fetchAssets({ payload }) {
  try {
    const response = yield call(getAssetsAPi, payload)
    if (response && !response?.error) {
      yield put(getAssetsSuccess(response))
    } else {
      yield put(getAssetsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAssetsFail(error))
  }
}

// function* fetchVarisangyasCards() {
//   try {
//     const response = yield call(getVarisangyasCardsAPi)
//     if (response && !response?.error) {
//       yield put(getVarisangyaCardsSuccess(response))
//     } else {
//       yield put(getVarisangyaCardsFail(response))
//       errorNotification()
//     }
//   } catch (error) {
//     yield put(getVarisangyaCardsFail(error))
//   }
// }

function* fetchAssetDetail({ assetId }) {
  try {
    const response = yield call(getAssetDetailsAPi, assetId)
    if (response && !response?.error) {
      yield put(getAssetDetailSuccess(response))
    } else {
      yield put(getAssetDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getAssetDetailFail(error))
  }
}
function* onCreateAsset({ payload }) {
  try {
    const response = yield call(createAssetApi, payload)
    if (response && !response?.error) {
      yield put(createAssetSuccess(response))
      if (payload.history) {
        payload.history.push("/varisangyas")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createAssetFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(createAssetFail(error))
    errorNotification()
  }
}

function* onUpdateAsset({ payload }) {
  try {
    const response = yield call(updateAssetApi, payload)
    if (response && !response?.error) {
      yield put(updateAssetSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateAssetFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateAssetFail(error))
    errorNotification()
  }
}

function* onDeleteAsset({ payload }) {
  try {
    const response = yield call(deleteAssetApi, payload)
    if (response && !response?.error) {
      yield put(deleteAssetSuccess(response))
      doneNotification("Deleted Successfully")
      payload.history.goBack()
    } else {
      yield put(deleteAssetFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteAssetFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* AssetsSaga() {
  yield takeEvery(GET_ASSETS, fetchAssets)
  // yield takeEvery(GET_VARISANGYA_CARDS, fetchVarisangyasCards)
  yield takeEvery(GET_ASSET_DETAIL, fetchAssetDetail)
  yield takeEvery(CREATE_ASSET, onCreateAsset)
  yield takeEvery(UPDATE_ASSET, onUpdateAsset)
  yield takeEvery(DELETE_ASSET, onDeleteAsset)
}

export default AssetsSaga
