import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label } from "reactstrap"
import { map } from "lodash"

import { createLedger, getMahallTypeLists } from "store/actions"
import { handleSelectOrDeselect } from "hooks/useSelectOrDeselect"

const UiModalMahallLedger = ({ show, onCloseclick }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const { loading, language, instituteId, mahallTypeLists, mahallId } =
    useSelector(state => ({
      loading: state.Mahalls.loading,
      language: state.Layout.language,
      instituteId: state.Institutes.instituteMahallDetail?.id,
      mahallTypeLists: state.TypeLists.mahallTypeLists,
      mahallId: state.Users.userDetail?.mahall?.id,
    }))

  useEffect(() => {
    if (mahallId) {
      dispatch(getMahallTypeLists(mahallId))
    }
  }, [dispatch])

  const [ledgerCategory, setLedgerCategory] = useState()

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const createLedgerData = {
      institute_ledger_name: values.institute_ledger_name,
      institute: instituteId,
      ledger_category_choice_id: ledgerCategory,
      opening_balance: values.opening_balance
        ? parseInt(values.opening_balance)
        : 0,
      balance: values.opening_balance ? parseInt(values.opening_balance) : 0,
      description: values.description,
    }
    // console.log(createLedgerData);
    dispatch(createLedger(createLedgerData))
    setLedgerCategory("")
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Create Ledger</h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "ലെഡ്ജറിന്റെ പേര്" : "Ledger Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="institute_ledger_name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="opening_balance"
                    type="number"
                    value="0"
                    label={
                      language === "mal"
                        ? "ഓപ്പണിംഗ് ബാലൻസ്"
                        : "Opening Balance"
                    }
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label for="basicpill-servicetax-input8">
                    {language === "mal" ? "ലെഡ്ജർ വിഭാഗം" : "Ledger Category"}
                  </Label>
                  <div className="d-flex flex-wrap">
                    {map(mahallTypeLists?.ledger_category, (item, key) => (
                      <div key={key} className="mx-1 mt-1">
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio5"
                          id={item.id}
                          autoComplete="off"
                          value={item.id}
                          checked={ledgerCategory === item.id}
                          onClick={e =>
                            handleSelectOrDeselect(
                              ledgerCategory,
                              setLedgerCategory,
                              e.target.value
                            )
                          }
                          // onChange={e => setLedgerCategory(e.target.value)}
                        />
                        <label
                          className="btn btn-outline-info"
                          htmlFor={item.id}
                        >
                          {language === "mal" ? item?.malayalam : item?.english}
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    label={language === "mal" ? "വിവരണം" : "Description"}
                  />
                </Col>
              </div>
              <div className="row w-100 justify-content-end">
                <Button type="submit" color="success" className="w-25">
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalMahallLedger
UiModalMahallLedger.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}
