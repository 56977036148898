import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"

import { getFeeds, getSuperFeeds } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"
import { useGetRole } from "hooks/useHandleSessions"

const AllSuperFeeds = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const role = useGetRole()

  const { superFeedsList, loading, language } = useSelector(state => ({
    superFeedsList: state.SuperFeeds.superfeeds,
    loading: state.Mahalls.loading,
    language: state.Layout.language,
  }))

  //pages
  const totalPages = Math.ceil(superFeedsList?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getSuperFeeds(searchText, pageSend()))
  }, [dispatch, page, searchText])

  // useEffect(() => {
  //   dispatch(getMahallsCards())
  // }, [dispatch])

  // const cardData = [
  //   {
  //     title: `${language === 'mal' ? 'ആകെ മഹല്ലുകൾ' : 'Total Mahalls'}`,
  //     cardValue: mahallsCards?.total_mahalls,
  //     iconClass: 'map-alt',
  //     routeLink: '/mahalls',
  //     loading: loading,
  //     // alt: `${language === 'mal' ? "മഹല്ലുകൾ ഇല്ല" : "No Mahalls"}`,
  //   },
  //   {
  //     title: `${language === 'mal' ? 'ആകെ കുടുംബങ്ങൾ' : 'Total Families'}`,
  //     cardValue: mahallsCards?.total_families,
  //     iconClass: 'home',
  //     routeLink: '/families',
  //     loading: loading,
  //     // alt: `${language === 'mal' ? "കുടുംബങ്ങൾ ഇല്ല" : "No Families"}`,
  //   },
  //   {
  //     title: `${language === 'mal' ? 'ആകെ കുടുംബാംഗങ്ങൾ' : 'Total Members'}`,
  //     cardValue: mahallsCards?.total_family_members,
  //     iconClass: 'user',
  //     routeLink: '/familymembers',
  //     loading: loading,
  //     // alt: `${language === 'mal' ? "കുടുംബാംഗങ്ങൾ ഇല്ല" : "No Family Members"}`,
  //   },
  // ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    {
      dataField: "title",
      text: `${language === "mal" ? "ടൈറ്റിൽ" : "Title"}`,
    },
    {
      dataField: "date_added",
      text: `${language === "mal" ? "തീയതി" : "Date"}`,
    },
    {
      dataField: "url",
      text: `${language === "mal" ? "യു ആർ എൽ" : "URL"}`,
    },
    // {
    //   dataField: "image",
    //   text: `${language === "mal" ? "ആപ്പിലെ ഫോട്ടോ" : "Image"}`,
    // },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]

  const FeedsData = map(superFeedsList?.results, (item, index) => ({
    ...item,
    key: index,
    no: index + 1,
    date_added:
      item?.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
    // image: (
    //   <>
    //     {item.image && (
    //       <img width="100px" height="100px" src={item?.image && item?.image} alt="" />
    //     )}
    //   </>
    // ),
    action: (
      <div>
        <Link to={`/super-feed/update/${item?.id}`} className="m-0">
          {role?.role === "mahall_user" ? (
            <Button type="button" color="primary" className=" btn w-xs btn-sm">
              {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
              {language === "mal" ? "കാണുക" : "view"}
            </Button>
          ) : (
            <Button type="button" color="primary" className=" btn w-xs btn-sm">
              {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
              {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
            </Button>
          )}
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceFeedSearch = debounce(value => setSearchText(value), 600)

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        {/* {cardData?.map((item, key) => (
          <Col lg={4} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p>
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))} */}
        <Row>
          <div className="col-md-12 d-flex justify-content-end">
            <Link
              to={`/super-feed/create`}
              type="submit"
              style={{ width: language === "mal" ? "200px" : "150px" }}
              className="w-md btn btn-success d-block"
            >
              {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New Super Feed"}
            </Link>
          </div>
        </Row>
        <Col className="col-12 mt-2">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={FeedsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => debounceFeedSearch(e.target.value)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {superFeedsList &&
                      superFeedsList?.results &&
                      superFeedsList?.results.length <= 0 && (
                        <p
                          style={{ fontSize: "15px" }}
                          className="text-center text-info"
                        >
                          {language === "mal"
                            ? "ഫീഡുകൾ ഒന്നും ഇല്ല"
                            : "No Feeds Yet"}
                        </p>
                      )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllSuperFeeds
