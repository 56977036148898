import React, { useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
//actions institute
//componetns

import UiModalInstituteCategory from "./UiModalInstituteCategory"
import UiModalInstituteCategoryUpdate from "./UiModalInstituteCategoryUpdate"
import UiModalLedger from "./UiModalLedger"

import Breadcrumbs from "components/Common/Breadcrumb"
import { getLedgers, getLedgerItemCategories } from "store/actions"
import "swiper/swiper.scss"
import { handleFinancialYear } from "pages/admin/Mahalls/financialYear"

const MadrasaAccountDetails = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const {
    loading,
    userDetail,
    language,
    ledgers,
    total_income,
    total_expense,
    total_balance,
    ledgerItemCategories,
  } = useSelector(state => ({
    loading: state.Ledgers.loading,
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
    ledgers: state.Ledgers?.ledgers?.results?.ledgers,
    total_income: state.Ledgers?.ledgers?.results?.total_income,
    total_expense: state.Ledgers?.ledgers?.results?.total_expense,
    total_balance: state.Ledgers?.ledgers?.results?.total_balance,
    ledgerItemCategories: state.LedgerItemCategories.ledgerItemCategories,
  }))

  const { finalToDate, fromDate } = handleFinancialYear(
    userDetail?.mahall?.financial_year_starting_month
  )

  const madrasaId = location?.search?.split("?madrasaid=")[1]

  const [modalLedger, setModalLedger] = useState(false)
  const [modalInstituteCategory, setModalInstituteCategory] = useState(false)
  const [modalInstituteCategoryUpdate, setModalInstituteCategoryUpdate] =
    useState(false)
  const [onUpdateInstituteCategory, setOnUpdateInstituteCategory] = useState({})

  useEffect(() => {
    if (madrasaId) {
      dispatch(getLedgers(madrasaId))
    }
  }, [madrasaId])

  useEffect(() => {
    if (madrasaId) {
      dispatch(getLedgerItemCategories(madrasaId))
    }
  }, [madrasaId])

  const updateInstituteCategoryModal = item => {
    setOnUpdateInstituteCategory(item)
    setModalInstituteCategoryUpdate(true)
  }

  return (
    <>
      <UiModalInstituteCategoryUpdate
        show={modalInstituteCategoryUpdate}
        onCloseclick={() => setModalInstituteCategoryUpdate(false)}
        data={onUpdateInstituteCategory}
      />
      <UiModalInstituteCategory
        show={modalInstituteCategory}
        onCloseclick={() => setModalInstituteCategory(false)}
        madrasaId={madrasaId}
      />
      <UiModalLedger
        show={modalLedger}
        onCloseclick={() => setModalLedger(false)}
        madrasaId={madrasaId}
      />

      <MetaTags>
        <title>Madrasa Account | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={
            language === "mal"
              ? "മദ്രസ അക്കൗണ്ട് വിവരങ്ങൾ"
              : "Madrasa Account Details"
          }
          breadcrumbItem={
            language === "mal" ? "മദ്രസ അക്കൗണ്ടുകൾ" : "Madrasa Account"
          }
        />
        <Container fluid>
          <div className="container-fluid">
            {loading ? (
              <Spinner color="secondary" className="d-block m-auto" />
            ) : (
              <Row>
                <Col lg="12" className="">
                  <Row className="mt-2">
                    <Col md="12" lg="8">
                      {/* <h5>{language === 'mal' ? 'അക്കൗണ്ട്' : 'Account'}</h5> */}
                      <Card>
                        <CardBody>
                          <h6 className="text-black ">
                            {language === "mal" ? "സമ്മറി :" : "Summary :"}
                          </h6>
                          <p
                            className="text-center pb-2"
                            style={{
                              fontSize: "15px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              {language === "mal"
                                ? "സാമ്പത്തിക വർഷം :"
                                : "Financial Year :"}
                            </span>{" "}
                            <span className="text-primary">{`${
                              fromDate ? fromDate : ""
                            }${" "} to ${" "}${
                              finalToDate ? finalToDate : ""
                            }`}</span>
                          </p>
                          <Container fluid>
                            <div>
                              <Row className="m-3">
                                <Col md="4">
                                  <Card style={{ backgroundColor: "#e8fdfe" }}>
                                    <p
                                      className="p-3 m-0 justify-content-center"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "700",
                                        color: "#15a252",
                                      }}
                                    >
                                      {language === "mal" ? "വരവ്" : "Income"} :{" "}
                                      {total_income}
                                    </p>
                                  </Card>
                                </Col>
                                <Col md="4">
                                  <Card style={{ backgroundColor: "#fef4f0" }}>
                                    <p
                                      className="p-3 m-0 justify-content-center"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "700",
                                        color: "#ff4d45",
                                      }}
                                    >
                                      {language === "mal" ? "ചിലവ്" : "Expense"}{" "}
                                      :{total_expense}
                                    </p>
                                  </Card>
                                </Col>
                                <Col md="4">
                                  <Card style={{ backgroundColor: "#eef9f4" }}>
                                    <p
                                      className="p-3 m-0 justify-content-center"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "700",
                                        color: "blue",
                                      }}
                                    >
                                      {language === "mal"
                                        ? "ബാക്കി"
                                        : "Balance"}{" "}
                                      : {total_balance}
                                    </p>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          </Container>
                          <h6 className="text-black mt-4">
                            {language === "mal"
                              ? "എല്ലാ ലെഡ്ജറുകളും :"
                              : "All Ledgers :"}
                          </h6>
                          <Container fluid>
                            <Row>
                              {ledgers &&
                                ledgers.length > 0 &&
                                ledgers?.map((item, key) => (
                                  <Col
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    key={key}
                                    className="sm:p-0 mt-1"
                                  >
                                    <Card
                                      className="blog-stats-wid"
                                      style={{
                                        backgroundColor: "#dce0e4",
                                        borderRadius: "10px",
                                        minHeight: "130px",
                                      }}
                                    >
                                      <Link to={`/ledgers/${item?.id}`}>
                                        <h5 className="px-3 pt-3 text-black">
                                          {item.institute_ledger_name}
                                        </h5>
                                        <Row>
                                          <Col md="4">
                                            <div className="mx-3">
                                              <p className="m-0 text-muted pb-1">
                                                {" "}
                                                {language === "mal"
                                                  ? "വരവ്"
                                                  : "Income"}{" "}
                                              </p>

                                              <div
                                                className=" p-2 mb-3"
                                                style={{
                                                  borderRadius: "6px",
                                                  backgroundColor: "#e8fdfe",
                                                }}
                                              >
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "#15a252",
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  {item.credit_amount
                                                    ? item.credit_amount
                                                    : 0}
                                                </p>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mx-3">
                                              <p className="m-0 text-muted pb-1">
                                                {" "}
                                                {language === "mal"
                                                  ? "ചിലവ്"
                                                  : "Expense"}{" "}
                                              </p>

                                              <div
                                                className=" p-2"
                                                style={{
                                                  borderRadius: "6px",
                                                  backgroundColor: "#fef4f0",
                                                }}
                                              >
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "#ff4d45",
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  {item.debit_amount
                                                    ? item.debit_amount
                                                    : 0}
                                                </p>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="mx-3">
                                              <p className="m-0 text-muted pb-1">
                                                {" "}
                                                {language === "mal"
                                                  ? "ബാക്കി"
                                                  : "Balance"}{" "}
                                              </p>

                                              <div
                                                className=" p-2"
                                                style={{
                                                  borderRadius: "6px",
                                                  backgroundColor: "#eef9f4",
                                                }}
                                              >
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "blue",
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  {item.balance
                                                    ? item.balance
                                                    : 0}
                                                </p>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Link>
                                    </Card>
                                  </Col>
                                ))}
                            </Row>
                            <Button
                              onClick={() => setModalLedger(true)}
                              className="bg-khaf w-100"
                            >
                              {language === "mal"
                                ? "+ പുതിയത് ചേർക്കുക"
                                : "+ Add New Ledger"}
                            </Button>
                          </Container>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card>
                        <CardBody>
                          <h6 className="text-black">
                            {language === "mal"
                              ? "മദ്രസ ക്യാറ്റഗറികൾ :"
                              : "Madrasa Categories:"}
                          </h6>
                          <div className=" d-flex justify-content-end">
                            <Button
                              type="button"
                              color="success"
                              className="btn-sm mb-3"
                              onClick={() => setModalInstituteCategory(true)}
                            >
                              {language === "mal"
                                ? "+ പുതിയത് ചേർക്കുക"
                                : "+ Add Category"}
                            </Button>
                          </div>
                          <Table className="mx-1 mt-1">
                            <thead>
                              <tr>
                                <th>
                                  {language === "mal"
                                    ? "ക്യാറ്റഗറിയുടെ പേര്"
                                    : "Category Name"}
                                </th>
                                <th>
                                  {language === "mal" ? "ബാലൻസ്" : "Balanace"}
                                </th>
                                <th>
                                  {language === "mal"
                                    ? "പ്രവർത്തനങ്ങൾ"
                                    : "Actions"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {ledgerItemCategories?.results &&
                                ledgerItemCategories?.results.length > 0 &&
                                ledgerItemCategories?.results.map(
                                  (item, key) => (
                                    <tr key={key}>
                                      <td>
                                        {
                                          item.institute_ledger_item_category_name
                                        }
                                      </td>
                                      <td>{item.balance}</td>
                                      <td>
                                        <Button
                                          onClick={() =>
                                            updateInstituteCategoryModal(item)
                                          }
                                          color="primary"
                                          className="btn-primary btn-sm"
                                        >
                                          {language === "mal"
                                            ? "തിരുത്തുക"
                                            : "Edit"}
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                          {ledgerItemCategories?.results &&
                            ledgerItemCategories?.results.length <= 0 && (
                              <p className="text-center text-primary">
                                {language === "mal"
                                  ? "ക്യാറ്റഗറികൾ ഒന്നും ഇല്ല"
                                  : "No Categories Yet"}
                              </p>
                            )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default MadrasaAccountDetails

MadrasaAccountDetails.propTypes = {
  history: PropTypes.object,
}
