import React from "react"
import { useSelector } from "react-redux"

function LetterHead() {
  const { userDetail, language } = useSelector(state => ({
    userDetail: state.Users.userDetail,
    language: state.Layout.language,
  }))
  return (
    <>
      <div className="d-flex">
        <div className="mt-1" style={{ border: "none" }}>
          <img
            src={userDetail?.mahall?.logo && userDetail?.mahall?.logo}
            alt=""
            className="img-thumbnail logo-certificate"
            style={{ width: "100px", height: "100px" }}
          />
        </div>
        <div className="content-certificate mt-2">
          <h2 className="text-center text-black">
            <b>
              {" "}
              {language === "mal"
                ? userDetail?.mahall?.mahall_malayalam_name
                  ? userDetail?.mahall?.mahall_malayalam_name
                  : userDetail?.mahall?.mahall_english_name
                : userDetail?.mahall?.mahall_english_name}
            </b>
          </h2>
          <p className="text-black text-center">
            {`${
              language === "mal"
                ? userDetail?.mahall?.lsg_malayalam_name
                  ? userDetail?.mahall?.lsg_malayalam_name
                  : userDetail?.mahall?.lsg_english_name
                : userDetail?.mahall?.lsg_english_name
            } 
                        ${
                          language === "mal"
                            ? userDetail?.mahall?.lsg_type_malayalam
                              ? userDetail?.mahall?.lsg_type_malayalam
                              : userDetail?.mahall?.lsg_type_english
                            : userDetail?.mahall?.lsg_type_english
                        },`}{" "}
            {`${
              userDetail?.mahall?.post_office
                ? userDetail?.mahall?.post_office
                : ""
            }-${
              userDetail?.mahall?.pin_code ? userDetail?.mahall?.pin_code : ""
            }, `}{" "}
            <br />
            {language === "mal"
              ? userDetail?.mahall?.village_malayalam_name
                ? userDetail?.mahall?.village_malayalam_name
                : userDetail?.mahall?.village_english_name
              : userDetail?.mahall?.village_english_name}
            ,{" "}
            {language === "mal"
              ? userDetail?.mahall?.district_malayalam_name
                ? userDetail?.mahall?.district_malayalam_name
                : userDetail?.mahall?.district_english_name
              : userDetail?.mahall?.district_english_name}
            ,{" "}
            {language === "mal"
              ? userDetail?.mahall?.state_malayalam_name
                ? userDetail?.mahall?.state_malayalam_name
                : userDetail?.mahall?.state_english_name
              : userDetail?.mahall?.state_english_name}
            ,{" "}
            {`${language === "mal" ? "ഫോൺ" : "Mob No:"} : ${
              userDetail?.phone && userDetail?.phone
            }`}
          </p>
        </div>
      </div>
      <div style={{ borderBottom: "1px solid black" }}></div>
    </>
  )
}

export default LetterHead
