import {
  GET_CLASSES,
  UPDATE_CLASS,
  CREATE_CLASS,
  DELETE_CLASS,
  GET_CLASS_DETAIL,
  GET_CLASSES_SUCCESS,
  GET_CLASSES_FAIL,
  CREATE_CLASS_SUCCESS,
  CREATE_CLASS_FAIL,
  GET_CLASS_DETAIL_SUCCESS,
  GET_CLASS_DETAIL_FAIL,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_FAIL,
  DELETE_CLASS_SUCCESS,
  DELETE_CLASS_FAIL,
  GET_CLASS_DROPDOWN,
  GET_CLASS_DROPDOWN_SUCCESS,
  GET_CLASS_DROPDOWN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  classes: [],
  classList: [],
  classDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdClass: {},
}

const Classes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLASS_DROPDOWN:
    case GET_CLASSES:
    case UPDATE_CLASS:
    case CREATE_CLASS:
    case DELETE_CLASS:
      return {
        ...state,
        loading: true,
      }
    case GET_CLASS_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }

    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
        loading: false,
      }
    case GET_CLASSES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CLASS_DROPDOWN_SUCCESS:
      return {
        ...state,
        classList: action.payload,
        loading: false,
      }
    case GET_CLASS_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_CLASS_SUCCESS:
      return {
        ...state,
        // states: action.payload,
        classes: {
          ...state.classes,
          results: [...state.classes.results, action.payload],
        },
        createdClass: action.payload,
        loading: false,
      }

    case CREATE_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CLASS_DETAIL_SUCCESS:
      return {
        ...state,
        classDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_CLASS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_CLASS_SUCCESS:
      return {
        ...state,
        classes: {
          results: state.classes.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_CLASS_SUCCESS:
      return {
        ...state,
        classes: {
          results: state.classes.results.filter(
            division => division.id !== action.payload
          ),
        },
        // zakat: state.states.filter(
        //   state => state.id.toString() !== action.payload.id.toString()
        // ),
        // zakat: state.enquiries.filter(
        //   enquiry => enquiry.id.tostring() !== action.payload.id.tostring()
        // ),
        // zakat: action.payload,
        loading: false,
      }

    case DELETE_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Classes
