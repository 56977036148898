import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"
import PropTypes from "prop-types"

import { getGroup, deleteGroup } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"

//css
import "assets/scss/datatables.scss"

const AllGroups = ({ history }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const { groups, loading, language } = useSelector(state => ({
    groups: state.Groups.groups,
    loading: state.Mahalls.loading,
    language: state.Layout.language,
  }))

  //pages
  const totalPages = Math.ceil(groups?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getGroup(searchText, pageSend()))
  }, [dispatch, page, searchText])

  const handleDelete = id => {
    dispatch(deleteGroup(id, history))
  }

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    {
      dataField: "mahallgroup_english_name",
      text: `${language === "mal" ? "ഗ്രൂപ്പ് പേര്" : "Group Name"}`,
    },
    {
      dataField: "date_added",
      text: `${language === "mal" ? "ചേർന്ന തീയതി" : "Join Date"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]

  const GroupsData = map(groups?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    date_added: moment(item?.date_added).format("DD/MM/YYYY"),
    mahallgroup_english_name:
      language === "mal"
        ? item?.mahallgroup_malayalam_name
          ? item?.mahallgroup_malayalam_name
          : item?.mahallgroup_english_name
        : item?.mahallgroup_english_name,
    action: (
      <div>
        <Link to={`/group/update/${item?.id}`} className="m-0">
          <Button type="button" color="white" className=" btn m-0 p-0">
            <i
              className="bx bx-pencil me-2"
              style={{ color: "green", fontSize: "18px" }}
            />
          </Button>
        </Link>

        <Button
          type="button"
          color="white"
          className=" m-0 btn "
          onClick={() => {
            handleDelete(item.id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "18px" }}
          />
        </Button>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMahallSearch = debounce(value => setSearchText(value), 600)

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        <Row>
          <div className="col-md-6">
            <Link
              to={`/group/create`}
              type="submit"
              style={{ width: language === "mal" ? "200px" : "150px" }}
              className="w-md btn btn-success d-block"
            >
              {language === "mal" ? "+ പുതിയത് ചേർക്കുക" : "+ New Group"}
            </Link>
          </div>
        </Row>
        <Col className="col-12 mt-2">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={GroupsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceMahallSearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    language === "mal"
                                      ? "തിരയുക..."
                                      : "Search..."
                                  }
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllGroups

AllGroups.propTypes = {
  history: PropTypes.object,
}
