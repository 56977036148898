/* DIVISIONS */
export const GET_DIVISIONS = "GET_DIVISIONS"
export const GET_DIVISIONS_SUCCESS = "GET_DIVISIONS_SUCCESS"
export const GET_DIVISIONS_FAIL = "GET_DIVISIONS_FAIL"

/* DIVISION_DROPDOWN*/
export const DIVISION_DROPDOWN = "DIVISION_DROPDOWN"
export const DIVISION_DROPDOWN_SUCCESS = "DIVISION_DROPDOWN_SUCCESS"
export const DIVISION_DROPDOWN_FAIL = "DIVISION_DROPDOWN_FAIL"

/* DIVISION  DETAIL*/
export const GET_DIVISION_DETAIL = "GET_DIVISION_DETAIL"
export const GET_DIVISION_DETAIL_SUCCESS = "GET_DIVISION_DETAIL_SUCCESS"
export const GET_DIVISION_DETAIL_FAIL = "GET_DIVISION_DETAIL_FAIL"

/**
 * add DIVISION
 */
export const CREATE_DIVISION = "CREATE_DIVISION"
export const CREATE_DIVISION_SUCCESS = "CREATE_DIVISION_SUCCESS"
export const CREATE_DIVISION_FAIL = "CREATE_DIVISION_FAIL"

/**
 * Edit DIVISION
 */
export const UPDATE_DIVISION = "UPDATE_DIVISION"
export const UPDATE_DIVISION_SUCCESS = "UPDATE_DIVISION_SUCCESS"
export const UPDATE_DIVISION_FAIL = "UPDATE_DIVISION_FAIL"

/**
 * Delete DIVISION
 */
export const DELETE_DIVISION = "DELETE_DIVISION"
export const DELETE_DIVISION_SUCCESS = "DELETE_DIVISION_SUCCESS"
export const DELETE_DIVISION_FAIL = "DELETE_DIVISION_FAIL"
