import {
  GET_MASTER_ACCOUNT_ITEMS,
  GET_MASTER_ACCOUNT_ITEMS_SUCCESS,
  GET_MASTER_ACCOUNT_ITEMS_FAIL,
  GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD,
  GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_SUCCESS,
  GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  masterAccountItems: [],
  masterAccountItemsForDownload: [],
  error: {},
  loading: false,
  dateLoading: false,
}

const MasterAccountItems = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MASTER_ACCOUNT_ITEMS:
      return {
        ...state,
        loading: true,
      }
    case GET_MASTER_ACCOUNT_ITEMS_SUCCESS:
      return {
        ...state,
        masterAccountItems: action.payload,
        loading: false,
      }

    case GET_MASTER_ACCOUNT_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD:
      return {
        ...state,
        loading: true,
      }
    case GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        masterAccountItemsForDownload: action.payload,
        loading: false,
      }

    case GET_MASTER_ACCOUNT_ITEMS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default MasterAccountItems
