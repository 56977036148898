import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FAMILY_MEMBER_VARISANGYAS,
  GET_FAMILY_MEMBER_VARISANGYA_CARDS,
  GET_FAMILY_MEMBER_VARISANGYA_DETAIL,
  CREATE_FAMILY_MEMBER_VARISANGYA,
  UPDATE_FAMILY_MEMBER_VARISANGYA,
  DELETE_FAMILY_MEMBER_VARISANGYA,
  AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS,
  REFRESH_FAMILY_MEMBER_VARISANGYA,
  UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER,
} from "./actionTypes"
import {
  getFamilyMemberVarisangyasSuccess,
  getFamilyMemberVarisangyasFail,
  getFamilyMemberVarisangyaCardsSuccess,
  getFamilyMemberVarisangyaCardsFail,
  getFamilyMemberVarisangyaDetailSuccess,
  getFamilyMemberVarisangyaDetailFail,
  createFamilyMemberVarisangyaSuccess,
  createFamilyMemberVarisangyaFail,
  updateFamilyMemberVarisangyaSuccess,
  updateFamilyMemberVarisangyaFail,
  deleteFamilyMemberVarisangyaSuccess,
  deleteFamilyMemberVarisangyaFail,
  autoGenerateFamilyMemberVarisangyasSuccess,
  autoGenerateFamilyMemberVarisangyasFail,
  refreshFamilyMemberVarisangyaSuccess,
  refreshFamilyMemberVarisangyaFail,
  updateFamilyMemberVarisangyaAmountByFilterSuccess,
  updateFamilyMemberVarisangyaAmountByFilterFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getFamilyMemberVarisangyasAPi = ({ searchText, page, limit }) => {
  if (searchText) {
    return get(
      `/api/v1/varisankya/family_member_varisankya/?search=${
        searchText && searchText
      }&page=${page && page}&page_size=${limit ? limit : 10}`
    )
  } else {
    return get(
      `/api/v1/varisankya/family_member_varisankya/?page=${
        page ? page : 1
      }&page_size=${limit ? limit : 10}`
    )
  }
}

const autoGenerateFamilyMemberVarisangyasAPi = () => {
  return post(`/api/v1/varisankya/family_member_varisankya_auto_generate/`)
}

const refreshFamilyMemberVarisangyaAPi = ({ familyMemberVarisangyaId }) => {
  console.log("familyMemberVarisangyaId: ", familyMemberVarisangyaId)
  return post(
    `/api/v1/varisankya/family_member_varisankya_refresh/${familyMemberVarisangyaId}/`
  )
}

const updateFamilyMemberVarisangyaAmountByFilterApi = ({
  familyMemberVarisangyaAmuontByFilter,
}) => {
  return post(
    "/api/v1/varisankya/family_member_varisankya_amount_update_in_filter/",
    familyMemberVarisangyaAmuontByFilter,
    { "Content-type": "" }
  )
}

const getFamilyMemberVarisangyasCardsAPi = () => {
  return get(`/api/v1/varisankya/family_member_varisankya/?cards=all`)
}
const getFamilyMemberVarisangyaDetailsAPi = familyMemberVarisangyaId => {
  return get(
    `/api/v1/varisankya/family_member_varisankya/${familyMemberVarisangyaId}/`
  )
}
const createFamilyMemberVarisangyaApi = ({ familyMemberVarisangya }) => {
  return post(
    "/api/v1/varisankya/family_member_varisankya/",
    familyMemberVarisangya
  )
}
const updateFamilyMemberVarisangyaApi = ({
  familyMemberVarisangyaId,
  familyMemberVarisangya,
}) => {
  return ApiPut(
    `/api/v1/varisankya/family_member_varisankya/${familyMemberVarisangyaId}/`,
    familyMemberVarisangya
  )
}
const deleteFamilyMemberVarisangyaApi = ({ familyMemberVarisangyaId }) => {
  return del(
    `/api/v1/varisankya/family_member_varisankya/${familyMemberVarisangyaId}/`
  )
}

function* fetchFamilyMemberVarisangyas({ payload }) {
  try {
    const response = yield call(getFamilyMemberVarisangyasAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyMemberVarisangyasSuccess(response))
    } else {
      yield put(getFamilyMemberVarisangyasFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyMemberVarisangyasFail(error))
  }
}

function* autoGenerateFamilyMemberVarisangyas() {
  try {
    const response = yield call(autoGenerateFamilyMemberVarisangyasAPi)
    if (response && !response?.error) {
      yield put(autoGenerateFamilyMemberVarisangyasSuccess(response))
      try {
        const response2 = yield call(getFamilyMemberVarisangyasAPi, { page: 1 })
        yield put(getFamilyMemberVarisangyasSuccess(response2))
      } catch (error) {
        yield put(getFamilyMemberVarisangyasFail(error))
      }
    } else {
      yield put(autoGenerateFamilyMemberVarisangyasFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyMemberVarisangyasFail(error))
  }
}

function* refreshFamilyMemberVarisangya({ payload }) {
  try {
    const response = yield call(refreshFamilyMemberVarisangyaAPi, payload)
    if (response && !response?.error) {
      yield put(refreshFamilyMemberVarisangyaSuccess(response))
      doneNotification(response?.response)
      try {
        const response2 = yield call(
          getFamilyMemberVarisangyaDetailsAPi,
          payload?.familyMemberVarisangyaId
        )
        yield put(getFamilyMemberVarisangyaDetailSuccess(response2))
      } catch (error) {
        yield put(getFamilyMemberVarisangyaDetailFail(error))
      }
    } else {
      yield put(refreshFamilyMemberVarisangyaFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    yield put(refreshFamilyMemberVarisangyaFail(error))
  }
}

function* updateFamilyMemberVarisangyaAmountByFilter({ payload }) {
  console.log("data :", payload)
  try {
    const response = yield call(
      updateFamilyMemberVarisangyaAmountByFilterApi,
      payload
    )
    if (response && !response?.error) {
      console.log("response:", response?.error)
      yield put(updateFamilyMemberVarisangyaAmountByFilterSuccess(response))
      payload.history.push("/family/member/wallet/details")
      doneNotification("Updated Successfully")
    } else {
      yield put(updateFamilyMemberVarisangyaAmountByFilterFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error :", error)
    yield put(updateFamilyMemberVarisangyaAmountByFilterFail(error))
    errorNotification()
  }
}

function* fetchFamilyMemberVarisangyasCards() {
  try {
    const response = yield call(getFamilyMemberVarisangyasCardsAPi)
    if (response && !response?.error) {
      yield put(getFamilyMemberVarisangyaCardsSuccess(response))
    } else {
      yield put(getFamilyMemberVarisangyaCardsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyMemberVarisangyaCardsFail(error))
  }
}

function* fetchFamilyMemberVarisangyaDetail({ familyMemberVarisangyaId }) {
  try {
    const response = yield call(
      getFamilyMemberVarisangyaDetailsAPi,
      familyMemberVarisangyaId
    )
    if (response && !response?.error) {
      yield put(getFamilyMemberVarisangyaDetailSuccess(response))
    } else {
      yield put(getFamilyMemberVarisangyaDetailFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyMemberVarisangyaDetailFail(error))
  }
}
function* onCreateFamilyMemberVarisangya({ payload }) {
  try {
    const response = yield call(createFamilyMemberVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(createFamilyMemberVarisangyaSuccess(response))
      if (payload.history) {
        payload.history.push("/varisangyas")
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createFamilyMemberVarisangyaFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createFamilyMemberVarisangyaFail(error))
    errorNotification()
  }
}

function* onUpdateFamilyMemberVarisangya({ payload }) {
  try {
    const response = yield call(updateFamilyMemberVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(updateFamilyMemberVarisangyaSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateFamilyMemberVarisangyaFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(updateFamilyMemberVarisangyaFail(error))
    errorNotification()
  }
}

function* onDeleteFamilyMemberVarisangya({ payload }) {
  try {
    const response = yield call(deleteFamilyMemberVarisangyaApi, payload)
    if (response && !response?.error) {
      yield put(
        deleteFamilyMemberVarisangyaSuccess(payload.familyMemberVarisangyaId)
      )
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteFamilyMemberVarisangyaFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteFamilyMemberVarisangyaFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* FamilyMemberVarisangyasSaga() {
  yield takeEvery(GET_FAMILY_MEMBER_VARISANGYAS, fetchFamilyMemberVarisangyas)
  yield takeEvery(
    GET_FAMILY_MEMBER_VARISANGYA_CARDS,
    fetchFamilyMemberVarisangyasCards
  )
  yield takeEvery(
    GET_FAMILY_MEMBER_VARISANGYA_DETAIL,
    fetchFamilyMemberVarisangyaDetail
  )
  yield takeEvery(
    CREATE_FAMILY_MEMBER_VARISANGYA,
    onCreateFamilyMemberVarisangya
  )
  yield takeEvery(
    UPDATE_FAMILY_MEMBER_VARISANGYA,
    onUpdateFamilyMemberVarisangya
  )
  yield takeEvery(
    DELETE_FAMILY_MEMBER_VARISANGYA,
    onDeleteFamilyMemberVarisangya
  )
  yield takeEvery(
    AUTO_GENERATE_FAMILY_MEMBER_VARISANGYAS,
    autoGenerateFamilyMemberVarisangyas
  )
  yield takeEvery(
    REFRESH_FAMILY_MEMBER_VARISANGYA,
    refreshFamilyMemberVarisangya
  )
  yield takeEvery(
    UPDATE_FAMILY_MEMBER_VARISANGYA_AMOUNT_BY_FILTER,
    updateFamilyMemberVarisangyaAmountByFilter
  )
}

export default FamilyMemberVarisangyasSaga
