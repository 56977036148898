import axios from "axios"

import {
  updateFamilyMemberSuccess,
  updateFamilyMemberFail,
} from "store/actions"
import { API_URL } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

export function uploadImage(
  setCustomLoad,
  selectedFile,
  id,
  dispatch,
  history,
  isUpdate
) {
  setCustomLoad(true)

  const { role } = useGetRole()

  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.member_image &&
      formData.append(
        "member_image",
        selectedFile?.member_image,
        selectedFile?.member_image?.name
      )
  }
  const { token } = useGetToken()

  axios
    .patch(
      `${API_URL}/api/v1/familydetails/familymember/${id?.id}/`,
      formData,
      {
        headers: {
          Authorization: "token " + token,
          Role: role,
        },
      }
    )
    .then(res => {
      if (res && !res?.error) {
        dispatch(updateFamilyMemberSuccess(res?.data))
        if (isUpdate !== "true") {
          // console.log("image upload data: ", res?.data)
          history.push(`/families/${res?.data?.family}`)
        }
        doneNotification("Uploaded Successfully")
        setCustomLoad(false)
      } else {
        // updateFamilyMemberFail(err)
        errorNotification(res?.error)
      }
    })
    .catch(err => {
      updateFamilyMemberFail(err)
      errorNotification()
      // if (err?.response?.data?.error) {
      //   updateFamilyMemberFail(err)
      //   errorNotification()
      //   setCustomLoad(false)
      // }
    })
}
