import React from "react"
import { useSelector } from "react-redux"
// import { useParams } from 'react-router'
import propTypes from "prop-types"
import { Modal } from "reactstrap"
import QRCodeReact from "qrcode.react"
// import axios from 'axios'

// import { createGallerySuccess, createGalleryFail } from 'store/actions'
// import { API_URL } from 'helpers/api_methods'
// import {
//   doneNotification,
//   errorNotification,
// } from 'components/Common/Notification'
// import { useFileSizes } from 'components/Common/useFileSize'

const UiModalQrcode = ({ show, onCloseclick, data }) => {
  const { language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
  }))

  console.log(data)

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "ഗാലറി ഡാറ്റ ചേർക്കുക" : "QR Code"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ">
            <div className="d-flex justify-content-center m-4">
              <QRCodeReact value={data} />
            </div>{" "}
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalQrcode
UiModalQrcode.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data: propTypes.object,
}
