import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FAMILY_MEMBER_VARISANGYA_ITEMS,
  CREATE_FAMILY_MEMBER_VARISANGYA_ITEM,
  DELETE_FAMILY_MEMBER_VARISANGYA_ITEM,
} from "./actionTypes"
import {
  getFamilyMemberVarisangyaItemsSuccess,
  getFamilyMemberVarisangyaItemsFail,
  createFamilyMemberVarisangyaItemSuccess,
  createFamilyMemberVarisangyaItemFail,
  deleteFamilyMemberVarisangyaItemSuccess,
  deleteFamilyMemberVarisangyaItemFail,
} from "./actions"

import { get, post, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getFamilyMemberVarisangyaItemsAPi = ({
  varisangyaId,
  searchText,
  page,
}) => {
  if (varisangyaId) {
    return get(
      `/api/v1/varisankya/family_member_varisankya_item/?family_member_varisankya=${
        varisangyaId ? varisangyaId : ""
      }&search=${searchText ? searchText : ""}&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/varisankya/family_member_varisankya_item/?search=${
        searchText ? searchText : ""
      }&page=${page ? page : 1}`
    )
  }
}
const createFamilyMemberVarisangyaItemApi = ({
  familyMemberVarisangyaItem,
}) => {
  return post(
    "/api/v1/varisankya/family_member_varisankya_single_entry_amount_update_in_filter/",
    familyMemberVarisangyaItem
  )
}
const deleteFamilyMemberVarisangyaItemApi = ({
  familyMemberVarisangyaItemId,
}) => {
  return del(
    `/api/v1/varisankya/family_member_varisankya_item/${familyMemberVarisangyaItemId}/`
  )
}

function* fetchFamilyMemberVarisangyaItems({ payload }) {
  try {
    const response = yield call(getFamilyMemberVarisangyaItemsAPi, payload)
    if (response && !response?.error) {
      yield put(getFamilyMemberVarisangyaItemsSuccess(response))
    } else {
      yield put(getFamilyMemberVarisangyaItemsFail(response))
      errorNotification()
    }
  } catch (error) {
    yield put(getFamilyMemberVarisangyaItemsFail(error))
  }
}
function* onCreateFamilyMemberVarisangyaItem({ payload }) {
  try {
    const response = yield call(createFamilyMemberVarisangyaItemApi, payload)
    if (response && !response?.error) {
      yield put(createFamilyMemberVarisangyaItemSuccess(response))
      if (payload.history) {
        payload.history.push(
          `/family/member/varisangyas/${response?.family_member_varisankya}`
        )
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createFamilyMemberVarisangyaItemFail(response))
      errorNotification()
    }
    // swal({
    //   title: "Zakats Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createFamilyMemberVarisangyaItemFail(error))
    errorNotification()
  }
}

function* onDeleteFamilyMemberVarisangyaItem({ payload }) {
  try {
    const response = yield call(deleteFamilyMemberVarisangyaItemApi, payload)
    if (response && !response?.error) {
      yield put(
        deleteFamilyMemberVarisangyaItemSuccess(
          payload.familyMemberVarisangyaItemId
        )
      )
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteFamilyMemberVarisangyaItemFail(response))
      errorNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(deleteFamilyMemberVarisangyaItemFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* FamilyMemberVarisangyaItemsSaga() {
  yield takeEvery(
    GET_FAMILY_MEMBER_VARISANGYA_ITEMS,
    fetchFamilyMemberVarisangyaItems
  )
  yield takeEvery(
    CREATE_FAMILY_MEMBER_VARISANGYA_ITEM,
    onCreateFamilyMemberVarisangyaItem
  )
  yield takeEvery(
    DELETE_FAMILY_MEMBER_VARISANGYA_ITEM,
    onDeleteFamilyMemberVarisangyaItem
  )
}

export default FamilyMemberVarisangyaItemsSaga
