import {
  GET_SUPERFEEDS,
  GET_SUPERFEEDSS_SUCCESS,
  GET_SUPERFEEDSS_FAIL,
  GET_SUPERFEEDS_DETAILS,
  GET_SUPERFEEDS_DETAILS_SUCCESS,
  GET_SUPERFEEDS_DETAILS_FAIL,
  CREATE_SUPERFEEDS,
  CREATE_SUPERFEEDS_SUCCESS,
  CREATE_SUPERFEEDS_FAIL,
  UPDATE_SUPERFEEDS,
  UPDATE_SUPERFEEDS_SUCCESS,
  UPDATE_SUPERFEEDS_FAIL,
  DELETE_SUPERFEEDS,
  DELETE_SUPERFEEDS_SUCCESS,
  DELETE_SUPERFEEDS_FAIL,
  GET_SUPERFEEDS_FAIL,
  GET_SUPERFEEDS_SUCCESS,
  REPOST_FEEDS,
  REPOST_FEED_FAIL,
  REPOST_FEED_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  superfeeds: {
    superfeeds: [],
    total: "",
    page: "",
  },
  superfeedsDetails: {},
  error: {},
  loading: false,
}

const SuperFeeds = (state = INIT_STATE, action) => {
  switch (action.type) {
    // SuperFeeds - This line cannot be edited or removed
    case GET_SUPERFEEDS:
    case GET_SUPERFEEDS_DETAILS:
    case CREATE_SUPERFEEDS:
    case UPDATE_SUPERFEEDS:
    case DELETE_SUPERFEEDS:
    case REPOST_FEEDS:
      return {
        ...state,
        loading: true,
      }

    case DELETE_SUPERFEEDS_FAIL:
    case GET_SUPERFEEDS_FAIL:
    case GET_SUPERFEEDS_DETAILS_FAIL:
    case UPDATE_SUPERFEEDS_FAIL:
    case CREATE_SUPERFEEDS_FAIL:
    case REPOST_FEED_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SUPERFEEDS_SUCCESS:
      return {
        ...state,
        superfeeds: action.payload,
        error: {},
        loading: false,
      }

    case GET_SUPERFEEDS_DETAILS_SUCCESS:
      return {
        ...state,
        superfeedsDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_SUPERFEEDS_SUCCESS:
      return {
        ...state,
        // superfeeds: {
        //   ...state.superfeeds,
        //   superfeedss: [...state?.superfeeds?.superfeeds, action.payload],
        //   total: state.superfeeds.total + 1,
        // },
        // superfeedsDetails: action.payload,
        superfeeds: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_SUPERFEEDS_SUCCESS:
      return {
        ...state,
        superfeedss: {
          ...state.superfeeds,
          superfeeds: state.superfeeds.superfeeds?.map(superfeeds =>
            superfeeds._id === action.payload._id
              ? { ...superfeeds, ...action.payload }
              : superfeeds
          ),
        },
        superfeedsDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_SUPERFEEDS_SUCCESS:
      return {
        ...state,
        superfeeds: action?.payload,
        // {
        //   ...state.superfeeds,
        //   superfeeds: state.superfeeds.superfeeds?.filter(
        //     superfeeds => superfeeds._id !== action.payload._id
        //   ),
        // },
        error: {},
        loading: false,
      }
    case REPOST_FEED_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default SuperFeeds
