import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label, Row } from "reactstrap"
import { Image } from "react-bootstrap"
import axios from "axios"
import { map } from "lodash"
import Select from "react-select"
import moment from "moment"

import { API_URL } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { useFileSizes } from "hooks/useFileSize"
import {
  createLedgerItemFail,
  createLedgerItemSuccess,
  getAllFamilyMembersDropdown,
  getLedgerItemCategoryDropdown,
} from "store/actions"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UiModalLedgerItem = ({ show, onCloseclick, history }) => {
  const dispatch = useDispatch()
  // const params = useParams()
  const handleFileSizes = useFileSizes

  const {
    loading,
    language,
    ledgerDetail,
    searchLedgerItemCategories,
    institute,
    allFamilyMembers,
  } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
    ledgerDetail: state.Ledgers.ledgerDetail.ledgers,
    searchLedgerItemCategories:
      state.LedgerItemCategories.searchLedgerItemCategories,
    institute: state.Ledgers.ledgerDetail.ledgers?.institute,
    allFamilyMembers: state.FamilyMembers.allFamilyMembers,
  }))

  const today = moment(new Date()).format("yyyy-MM-DD")

  const [category, setCategory] = useState("select category")
  const [categoryId, setCategoryId] = useState("")
  const [searchText, setSearchText] = useState("")
  const [searchTextMember, setSearchTextMember] = useState("")
  const [familyMember, setFamilyMember] = useState("Select member...")
  const [familyMemberId, setFamilyMemberId] = useState()
  console.log(setSearchTextMember)
  const [otherName, setOtherName] = useState("")

  useEffect(() => {
    if (institute) {
      dispatch(getLedgerItemCategoryDropdown(institute, searchText))
    }
  }, [institute, searchText, dispatch])

  useEffect(() => {
    dispatch(getAllFamilyMembersDropdown(searchTextMember))
  }, [dispatch, searchTextMember])

  function handlerFinalValue(event) {
    // dispatch(getLedgerItemCategoryDropdown(event.value))
    setCategoryId(event.value)
    setCategory(event.label)
  }

  const optionGroup = [
    {
      options: searchLedgerItemCategories?.map((results, index) => ({
        key: index,
        label: results.institute_ledger_item_category_name,
        value: results.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  useEffect(() => {
    setCategory("select category")
    setCategoryId("")
    setFamilyMember("select member")
    setFamilyMemberId("")
  }, [])

  const optionGroupMembers = [
    {
      label: "All Members",
      value: "",
    },
    {
      options: allFamilyMembers?.map((result, index) => ({
        key: index,
        label: `${result?.full_name} (${
          result?.mahall_custom_id
            ? result?.mahall_custom_id
            : result?.custom_id
        })`,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValueMembers(event) {
    setFamilyMemberId(event.value)
    setFamilyMember(event.label)
  }

  const handleEntersMembers = textEntered => {
    setSearchText(textEntered)
  }

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })
  console.log(customLoad)
  const [imagePreview, setImagePreview] = useState()

  const [filesSize, setFilesSize] = useState()

  const changeHandler = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const transactionTypes = [
    {
      name: `${language === "mal" ? "വരുമാനം" : "Income"}`,
      value: "credit",
    },
    {
      name: `${language === "mal" ? "ചെലവ്" : "Expense"}`,
      value: "debit",
    },
  ]

  const [transactionType, setTransactionType] = useState("credit")

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    setCustomLoad(true)
    // if (handleFileSizes(filesSize)?.status === true) {
    createLedgerItem(
      selectedFile,
      values,
      ledgerDetail,
      familyMemberId,
      categoryId,
      transactionType,
      dispatch,
      history,
      setCustomLoad
    )
    setImagePreview()
    // }
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "എൻട്രി ചേർക്കുക" : "Add Entry"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Row>
                    {ledgerDetail?.ledger_category?.english === "Income" ? (
                      <div className="d-flex mt-1">
                        <Button type="button" color="success" className="w-100">
                          {language === "mal" ? "വരവ്" : "Income"}
                        </Button>
                      </div>
                    ) : ledgerDetail?.ledger_category?.english === "Expense" ? (
                      <div className="d-flex mt-1">
                        <Button type="button" color="danger" className="w-100">
                          {language === "mal" ? "ചിലവ്" : "Expense"}
                        </Button>
                      </div>
                    ) : (
                      <div className="d-flex mt-1">
                        {map(transactionTypes, (item, key) => (
                          <Col md="6">
                            <div key={key} className="me-3">
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio6"
                                id={item.name}
                                autoComplete="off"
                                value={item.value}
                                checked={
                                  transactionType === item.value ? true : false
                                }
                                onChange={e =>
                                  setTransactionType(e.target.value)
                                }
                              />
                              {item.value === "credit" ? (
                                <label
                                  className="btn btn-outline-success w-100"
                                  htmlFor={item.name}
                                >
                                  {item.name}
                                </label>
                              ) : (
                                <label
                                  className="btn btn-outline-danger w-100"
                                  htmlFor={item.name}
                                >
                                  {item.name}
                                </label>
                              )}
                            </div>
                          </Col>
                        ))}
                      </div>
                    )}
                  </Row>
                </Col>
              </div>
              {otherName?.length === 0 && (
                <div className="row mb-4">
                  <Col>
                    <Label>
                      {language === "mal" ? "കുടുംബാംഗം" : "Member"}
                      <span className="text-danger">&#42;</span>
                    </Label>
                    <Select
                      onInputChange={handleEntersMembers}
                      placeholder={familyMember}
                      options={optionGroupMembers}
                      classNamePrefix="select2-selection"
                      onChange={handlerFinalValueMembers}
                      type="text"
                      title="Family Member"
                      required="required"
                    />
                  </Col>
                </div>
              )}
              {!familyMemberId && (
                <div className="row mb-4">
                  <Col>
                    <AvField
                      name="other_name"
                      type="text"
                      onChange={e => setOtherName(e.target.value)}
                      label={language === "mal" ? "വേറെ പേര്" : "Other Name"}
                    />
                  </Col>
                </div>
              )}
              <div className="row mb-4">
                <Col>
                  <AvField
                    name="title"
                    type="text"
                    label={language === "mal" ? "വിശദാംശങ്ങൾ" : "Particulars"}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="6">
                  <Label>
                    {language === "mal" ? "തീയതി" : "Date"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="date"
                    type="date"
                    value={today}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
                <Col sm="12" md="6">
                  <Label>
                    {language === "mal" ? "തുക" : "Amount"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="amount"
                    type="number"
                    value="0"
                    // label={language === "mal" ? "തുക" : "Amount"}
                    validate={{
                      required: { value: true },
                      min: {
                        value: 0,
                        errorMessage: `${
                          language === "mal"
                            ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                            : "Amount must be greater than 0"
                        }`,
                      },
                    }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>{language === "mal" ? "വിഭാഗം" : "Category"}</Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={category}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Institute Category"
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    label={language === "mal" ? "വിവരണം" : "Details"}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>
                    {language === "mal" ? "ബില്ലിന്റെ ഫോട്ടോ" : "Bill Image"}
                  </Label>
                  <AvField
                    name="bill_image"
                    type="file"
                    onChange={changeHandler}
                  />
                  {imagePreview && (
                    <Image
                      style={{ width: "150px", height: "150px" }}
                      className="my-2"
                      src={imagePreview.image}
                    />
                  )}
                  {handleFileSizes(filesSize)?.text && (
                    <p className="text-danger mt-1">
                      {handleFileSizes(filesSize)?.text}
                    </p>
                  )}
                </Col>
              </div>
              <div className="row w-100 justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  // onClick={onCloseclick}
                >
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}{" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalLedgerItem
UiModalLedgerItem.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
}

function createLedgerItem(
  selectedFile,
  values,
  ledgerDetail,
  familyMemberId,
  categoryId,
  transactionType,
  dispatch,
  history,
  setCustomLoad
) {
  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append(
        "bill_image",
        selectedFile?.image,
        selectedFile?.image?.name
      )
  }
  formData.append("institute_ledger", ledgerDetail?.id)
  formData.append("member", familyMemberId ? familyMemberId : "")
  formData.append("other_name", values.other_name ? values.other_name : "")
  formData.append(
    "institute_ledger_item_category",
    categoryId ? categoryId : ""
  )
  formData.append(
    "transaction_type",
    ledgerDetail?.ledger_category?.english === "Income"
      ? "credit"
      : ledgerDetail?.ledger_category?.english === "Expense"
      ? "debit"
      : transactionType
  )
  formData.append("title", values.title ? values.title : null)
  formData.append("date", values.date)
  formData.append("amount", values.amount ? parseInt(values.amount) : 0)
  formData.append("description", values.description ? values.description : null)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .post(`${API_URL}/api/v1/institute/institute_ledger_item/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        doneNotification("Created Successfully")
        setCustomLoad(false)
        dispatch(createLedgerItemSuccess(res?.data))
        // dispatch(getLedgerDetail(res?.data?.institute_ledger))
      } else {
        // createLedgerItemFail(err)
        errorNotification()
      }
    })
    .catch(err => {
      createLedgerItemFail(err)
      errorNotification()
      setCustomLoad(false)
    })
}
