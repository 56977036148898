import React, { Fragment, useState, useEffect } from "react"
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useParams, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
//actions institute
//componetns
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import UiModalDivision from "./UiModalDivision"
import UiModalDivisionUpdate from "./UiModalDivisionUpdate"
import UiModalClassUpdate from "./UiModalClassUpdate"

import MyPagination from "components/Common/MyPagination"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getClassDetail, getDivisions } from "store/actions"

const ClassDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, language, divisions, classDetail } = useSelector(state => ({
    language: state.Layout.language,
    loading: state.Divisions.loading,
    divisions: state.Divisions.divisions,
    classDetail: state.Classes.classDetail,
    // classCards: state.Classes.classDetail?.cards,
  }))

  // const cardData = [
  //   {
  //     title: `${language === 'mal' ? 'അംഗങ്ങൾ' : 'Members'}`,
  //     cardValue: `${classCards?.committee_members ?? 0}`,
  //     iconClass: 'group',
  //     routeLink: '/#!',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'അംഗങ്ങൾ ഇല്ല' : 'No Members'}`,
  //   },
  //   {
  //     title: `${language === 'mal' ? 'യോഗങ്ങൾ' : 'Students'}`,
  //     cardValue: `${classCards?.committee_meetings ?? 0}`,
  //     iconClass: 'chat',
  //     routeLink: '/#!',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'യോഗങ്ങൾ ഇല്ല' : 'No Students'}`,
  //   },
  //   {
  //     title: `${
  //       language === 'mal'
  //         ? `യോഗങ്ങൾ (${moment().format('MMM')})`
  //         : `Students (${moment().format('MMM')})`
  //     }`,
  //     cardValue: `${classCards?.meetings_this_month ?? 0}`,
  //     iconClass: 'chat',
  //     routeLink: '/#!',
  //     loading: loading,
  //     alt: `${language === 'mal' ? 'യോഗങ്ങൾ ഇല്ല' : 'No Students'}`,
  //   },
  // ]

  const [modalClassUpdate, setModalClassUpdate] = useState(false)
  const [modalDivision, setModalDivision] = useState(false)
  const [modalDivisionUpdate, setModalDivisionUpdate] = useState(false)
  const [onUpdateDivision, setOnUpdateDivision] = useState()

  const updateDivisionModal = item => {
    setOnUpdateDivision(item)
    setModalDivisionUpdate(true)
  }

  const [searchTextDivision, setSearchTextDivision] = useState("")
  const [divisionPage, setDivisionPage] = useState(1)

  const totalDivisionPages = Math.ceil(divisions?.count / 10)
  const divisionPages = range(1, totalDivisionPages + 1)

  const divisionPageSend = () => {
    if (divisionPage >= divisionPages.length) {
      return divisionPages.length
    }
    if (divisionPage < 1) {
      return 1
    } else {
      return divisionPage
    }
  }

  const debounceDivisionSearch = debounce(
    value => setSearchTextDivision(value),
    600
  )

  const divisionColumns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നം." : "No."}`,
      sort: true,
    },
    {
      dataField: "division_name",
      text: `${language === "mal" ? "ഡിവിഷൻ" : "Division"}`,
      sort: true,
    },
    {
      dataField: "class_teacher_name",
      text: `${language === "mal" ? "അധ്യാപകൻ" : "Class Teacher"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Actions"}`,
    },
  ]

  const divisionsData = map(divisions?.results, (item, index) => ({
    ...item,
    key: index,
    no: index + 1,
    meeting_date: moment(item?.meeting_date).format("DD/MM/YYYY"),
    action: (
      <div className="m-0">
        <Button
          type="button"
          color="primary"
          className="w-xs btn-sm"
          onClick={() => updateDivisionModal(item)}
        >
          {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
          Edit
        </Button>
        <Link to={`/division/${item?.id}`}>
          <Button
            type="button"
            className="btn btn-success w-xs btn-sm mx-2"
            // onClick={() => updateDivisionModal(item)}
          >
            {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
            View
          </Button>
        </Link>
        {/* <Link to={`/institutes/${item?.id}`} className="btn-light btn-sm">
          View
        </Link> */}
      </div>
    ),
  }))

  const defaultSortedDivision = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRowDivision = {
    mode: "checkbox",
  }

  useEffect(() => {
    dispatch(
      getDivisions(classDetail?.id, searchTextDivision, divisionPageSend())
    )
  }, [classDetail?.id, searchTextDivision, divisionPage])

  // useEffect(() => {
  //   if (classDetail?.id) {
  //     dispatch(
  //       getStudents(classDetail?.id, searchTextDivision, divisionPageSend()),
  //     )
  //   }
  // }, [classDetail, searchTextDivision, divisionPage])

  useEffect(() => {
    dispatch(getClassDetail(params.id))
  }, [])

  return (
    <>
      <UiModalClassUpdate
        show={modalClassUpdate}
        onCloseclick={() => setModalClassUpdate(false)}
        history={history}
      />
      <UiModalDivision
        show={modalDivision}
        onCloseclick={() => setModalDivision(false)}
        history={history}
      />
      <UiModalDivisionUpdate
        show={modalDivisionUpdate}
        onCloseclick={() => setModalDivisionUpdate(false)}
        data={onUpdateDivision}
        history={history}
      />

      <MetaTags>
        <title>Classes | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title={language === "mal" ? "ക്ലാസ് വിശദാംശങ്ങൾ" : "Class Details"}
          breadcrumbItem={language === "mal" ? "ക്ലാസ്" : "Class"}
        />
        <Container fluid>
          <div className="container-fluid">
            <>
              <Row>
                <Col className="col-lg-12 col-md-12">
                  <Row>
                    <Col md="4">
                      <Card className="overflow-hidden">
                        <div className="bg-primary bg-soft">
                          <Row>
                            <div className="text-primary p-4">
                              <div className="px-2">
                                <Row>
                                  <Col md="12">
                                    <h5 className="text-khaf">Class</h5>
                                    <h4 className="mt-4">
                                      {classDetail?.class_name}
                                    </h4>
                                    {/* <p className="text-muted">From {(moment(ledgerDetail?.ledgers?.date_added).format("DD/MM/YYYY"))}</p> */}
                                  </Col>
                                  {/* <div className=" d-flex justify-content-end">
                                    <Button
                                      type="button"
                                      className="btn-sm bg-khaf-blue"
                                      onClick={() => setModalClassUpdate(true)}
                                    >
                                      <i className="bx bx-edit text-center"></i>
                                      {language === 'mal'
                                        ? ' അപ്ഡേറ്റ്'
                                        : 'Update'}
                                    </Button>
                                  </div> */}
                                </Row>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                    {/* <Col md="8">
                      <Row>
                        {cardData?.map((item, key) => (
                          <Col
                            sm="12"
                            md="6"
                            lg="4"
                            key={key}
                            className="sm:p-0"
                          >
                            <Card className="blog-stats-wid">
                              <CardBody>
                                <Link to={item.routeLink && item.routeLink}>
                                  <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                      <p className="text-muted mb-2">
                                        {item.title}
                                      </p>
                                      {item.cardValue && (
                                        <h5 className="mb-0">
                                          {item.cardValue}
                                        </h5>
                                      )}
                                      <p className="m-0 text-info">
                                        {item.cardValue ? <></> : item.alt}
                                      </p>
                                    </div>

                                    <div className="avatar-sm ms-auto">
                                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                        <i
                                          className={
                                            'bx bxs-' + `${item.iconClass}`
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
              <Row>
                <h5 className="pb-1">
                  {language === "mal" ? "ഡിവിഷനുകൾ :" : "Divisions :"}
                </h5>
                <Col lg="12" className="">
                  <React.Fragment>
                    <Row style={{ display: "flex" }}>
                      <Col className="col-lg-12 col-md-12">
                        <Card>
                          <CardBody>
                            <ToolkitProvider
                              keyField="id"
                              columns={divisionColumns}
                              data={divisionsData}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <form
                                            className="app-search d-lg-block"
                                            onChange={e =>
                                              debounceDivisionSearch(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <div className="position-relative">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={
                                                  language === "mal"
                                                    ? "തിരയുക..."
                                                    : "Search..."
                                                }
                                                defaultValue={
                                                  searchTextDivision
                                                }
                                              />
                                              <span className="bx bx-search-alt" />
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="8">
                                      <div className="d-flex justify-content-end">
                                        <Button
                                          type="button"
                                          color="primary"
                                          className="btn-sm mb-4"
                                          onClick={() => setModalDivision(true)}
                                        >
                                          {language === "mal"
                                            ? "+ ഡിവിഷൻ ചേർക്കുക"
                                            : "+ Add Division"}
                                        </Button>
                                        {/* <Link
                                          to={`/committee/meeting/create?committeeid=${classDetail?.id}`}
                                          className="btn-sm btn btn-primary"
                                        >
                                          {language === 'mal'
                                            ? '+ വിദ്യാർത്ഥിയെ ചേർക്കുക'
                                            : '+ Add Student'}
                                        </Link> */}
                                      </div>
                                    </Col>
                                  </Row>
                                  {loading ? (
                                    <Spinner
                                      color="secondary"
                                      className="d-block m-auto"
                                    />
                                  ) : (
                                    <>
                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField={"id"}
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={
                                                defaultSortedDivision
                                              }
                                              selectRow={selectRowDivision}
                                              classes={
                                                "table align-middle table-nowrap table-hover"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <MyPagination
                                        totalPages={divisionPages}
                                        page={divisionPage}
                                        setPage={setDivisionPage}
                                      />
                                    </>
                                  )}
                                  {divisions &&
                                    divisions?.results &&
                                    divisions?.results?.length <= 0 && (
                                      <p
                                        style={{ fontSize: "15px" }}
                                        className="text-center text-info"
                                      >
                                        {language === "mal"
                                          ? "യോഗങ്ങൾ ഒന്നും ഇല്ല"
                                          : "No Divisions Yet"}
                                      </p>
                                    )}
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </React.Fragment>
                </Col>
              </Row>
            </>
          </div>
        </Container>
      </div>
    </>
  )
}

export default ClassDetails

ClassDetails.propTypes = {
  history: PropTypes.object,
}
