/* GET_STUDENT_ATTENDANCES */
export const GET_STUDENT_ATTENDANCES = "GET_STUDENT_ATTENDANCES"
export const GET_STUDENT_ATTENDANCES_SUCCESS = "GET_STUDENT_ATTENDANCES_SUCCESS"
export const GET_STUDENT_ATTENDANCES_FAIL = "GET_STUDENT_ATTENDANCES_FAIL"

/* GET_STUDENT_ATTENDANCE_DETAIL DETAIL*/
export const GET_STUDENT_ATTENDANCE_DETAIL = "GET_STUDENT_ATTENDANCE_DETAIL"
export const GET_STUDENT_ATTENDANCE_DETAIL_SUCCESS =
  "GET_STUDENT_ATTENDANCE_DETAIL_SUCCESS"
export const GET_STUDENT_ATTENDANCE_DETAIL_FAIL =
  "GET_STUDENT_ATTENDANCE_DETAIL_FAIL"

/**
 * add CREATE_STUDENT_ATTENDANCE
 */
export const CREATE_STUDENT_ATTENDANCE = "CREATE_STUDENT_ATTENDANCE"
export const CREATE_STUDENT_ATTENDANCE_SUCCESS =
  "CREATE_STUDENT_ATTENDANCE_SUCCESS"
export const CREATE_STUDENT_ATTENDANCE_FAIL = "CREATE_STUDENT_ATTENDANCE_FAIL"

/**
 * Edit UPDATE_STUDENT_ATTENDANCE
 */
export const UPDATE_STUDENT_ATTENDANCE = "UPDATE_STUDENT_ATTENDANCE"
export const UPDATE_STUDENT_ATTENDANCE_SUCCESS =
  "UPDATE_STUDENT_ATTENDANCE_SUCCESS"
export const UPDATE_STUDENT_ATTENDANCE_FAIL = "UPDATE_STUDENT_ATTENDANCE_FAIL"

/**
 * Delete DELETE_STUDENT_ATTENDANCE
 */
export const DELETE_STUDENT_ATTENDANCE = "DELETE_STUDENT_ATTENDANCE"
export const DELETE_STUDENT_ATTENDANCE_SUCCESS =
  "DELETE_STUDENT_ATTENDANCE_SUCCESS"
export const DELETE_STUDENT_ATTENDANCE_FAIL = "DELETE_STUDENT_ATTENDANCE_FAIL"

/**
 * add CREATE_WORKING_DAY
 */
export const CREATE_WORKING_DAY = "CREATE_WORKING_DAY"
export const CREATE_WORKING_DAY_SUCCESS = "CREATE_WORKING_DAY_SUCCESS"
export const CREATE_WORKING_DAY_FAIL = "CREATE_WORKING_DAY_FAIL"
