import React from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label, Row } from "reactstrap"

import {
  updateLedgerItemCategory,
  deleteLedgerItemCategory,
} from "store/actions"

const UiModalInstituteCategoryUpdate = ({ show, onCloseclick, data }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const categoryDetail = data

  const { loading, language } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
  }))

  const handleValidSubmit = (onSubmitProps, values) => {
    const updateLedgerItemCategoryData = {
      institute_ledger_item_category_name:
        values.institute_ledger_item_category_name,
      institute: categoryDetail?.institute,
    }
    // console.log(updateLedgerItemCategoryData);
    dispatch(
      updateLedgerItemCategory(updateLedgerItemCategoryData, categoryDetail?.id)
    )
  }

  const handleDeleteCategory = id => {
    dispatch(deleteLedgerItemCategory(id, categoryDetail?.institute))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal"
                ? "കാറ്റഗറി അപ്ഡേറ്റ് ചെയ്യുക"
                : "Update Category"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "പേര്" : "Category Name"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="institute_ledger_item_category_name"
                    type="text"
                    value={categoryDetail?.institute_ledger_item_category_name}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
              <Row className="mt-5 d-flex">
                <div className="col-6">
                  <div className="row justify-content-start">
                    <div>
                      <Button
                        type="button"
                        color="danger"
                        className="w-md"
                        onClick={() => {
                          onCloseclick()
                          handleDeleteCategory(categoryDetail?.id)
                        }}
                      >
                        {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md m-auto me-0 d-block"
                        onClick={onCloseclick}
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക " : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
              {/* <div className="row w-100 justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  onClick={onCloseclick}
                >{loading && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </>
                )}
                  {" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div> */}
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalInstituteCategoryUpdate
UiModalInstituteCategoryUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
  data: propTypes.object,
}
