/* SuperBanners - This line cannot be edited or removed */
export const GET_SUPER_BANNERS = "GET_SUPER_BANNERS"
export const GET_SUPER_BANNER_SUCCESS = "GET_SUPER_BANNER_SUCCESS"
export const GET_SUPER_BANNER_FAIL = "GET_SUPER_BANNER_FAIL"

/* SuperFeeds_DETAILS */
export const GET_SUPER_BANNERS_DETAILS = "GET_SUPER_BANNERS_DETAILS"
export const GET_SUPER_BANNER_DETAILS_SUCCESS =
  "GET_SUPER_BANNER_DETAILS_SUCCESS"
export const GET_SUPER_BANNER_DETAILS_FAIL = "GET_SUPER_BANNER_DETAILS_FAIL"

/* CREATE SuperBanners */
export const CREATE_SUPER_BANNERS = "CREATE_SUPER_BANNERS"
export const CREATE_SUPER_BANNER_SUCCESS = "CREATE_SUPER_BANNER_SUCCESS"
export const CREATE_SUPER_BANNER_FAIL = "CREATE_SUPER_BANNER_FAIL"

/* Edit SuperBanners */
export const UPDATE_SUPER_BANNERS = "UPDATE_SUPER_BANNERS"
export const UPDATE_SUPER_BANNER_SUCCESS = "UPDATE_SUPER_BANNER_SUCCESS"
export const UPDATE_SUPER_BANNER_FAIL = "UPDATE_SUPER_BANNER_FAIL"

/* Delete SuperBanners */
export const DELETE_SUPER_BANNERS = "DELETE_SUPER_BANNERS"
export const DELETE_SUPER_BANNER_SUCCESS = "DELETE_SUPER_BANNER_SUCCESS"
export const DELETE_SUPER_BANNER_FAIL = "DELETE_SUPER_BANNER_FAIL"
