import {
  GET_COMMITTEE_MEETING_IMAGES,
  UPDATE_COMMITTEE_MEETING_IMAGE,
  CREATE_COMMITTEE_MEETING_IMAGE,
  DELETE_COMMITTEE_MEETING_IMAGE,
  GET_COMMITTEE_MEETING_IMAGE_DETAIL,
  GET_COMMITTEE_MEETING_IMAGES_SUCCESS,
  GET_COMMITTEE_MEETING_IMAGES_FAIL,
  CREATE_COMMITTEE_MEETING_IMAGE_SUCCESS,
  CREATE_COMMITTEE_MEETING_IMAGE_FAIL,
  GET_COMMITTEE_MEETING_IMAGE_DETAIL_SUCCESS,
  GET_COMMITTEE_MEETING_IMAGE_DETAIL_FAIL,
  UPDATE_COMMITTEE_MEETING_IMAGE_SUCCESS,
  UPDATE_COMMITTEE_MEETING_IMAGE_FAIL,
  DELETE_COMMITTEE_MEETING_IMAGE_SUCCESS,
  DELETE_COMMITTEE_MEETING_IMAGE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  committeeMeetingImages: [],
  committeeMeetingImageDetail: {},
  searchCommitteeMeetingImages: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdCommitteeMeetingImage: {},
}

const CommitteeMeetingImages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMITTEE_MEETING_IMAGES:
    case UPDATE_COMMITTEE_MEETING_IMAGE:
    case CREATE_COMMITTEE_MEETING_IMAGE:
    case DELETE_COMMITTEE_MEETING_IMAGE:
      return {
        ...state,
        loading: true,
      }
    case GET_COMMITTEE_MEETING_IMAGE_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_COMMITTEE_MEETING_IMAGES_SUCCESS:
      return {
        ...state,
        committeeMeetingImages: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_MEETING_IMAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_COMMITTEE_MEETING_IMAGE_SUCCESS:
      return {
        ...state,
        // committeeMeetingImages: action.payload,
        committeeMeetingImages: {
          ...state.committeeMeetingImages,
          results: [...state.committeeMeetingImages.results, action.payload],
        },
        createdCommitteeMeetingImage: action.payload,
        loading: false,
      }

    case CREATE_COMMITTEE_MEETING_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COMMITTEE_MEETING_IMAGE_DETAIL_SUCCESS:
      return {
        ...state,
        committeeMeetingImageDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_COMMITTEE_MEETING_IMAGE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_COMMITTEE_MEETING_IMAGE_SUCCESS:
      return {
        ...state,
        committeeMeetingImageDetail: action.payload,
        committeeMeetingImages: {
          results: state.committeeMeetingImages.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        loading: false,
      }

    case UPDATE_COMMITTEE_MEETING_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_COMMITTEE_MEETING_IMAGE_SUCCESS:
      return {
        ...state,
        // committeeMeetingImages: state.committeeMeetingImages.filter(
        //   committeeMeetingImage => committeeMeetingImage.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_COMMITTEE_MEETING_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default CommitteeMeetingImages
