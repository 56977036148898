import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, CardFooter, Col } from "reactstrap"
// import images from "assets/images"

const CardContact = props => {
  const { image, title, link } = props

  return (
    <Col xs="6" sm="6" md="3" lg="2">
      <Link to={link}>
        <Card
          className=" text-center"
          style={{ height: "130px", width: "130px" }}
        >
          <CardBody className="d-flex flex-wrap align-items-center justify-content-center">
            {/* {icon &&
                  <i className= {`${icon} me-2`} style={{ fontSize: "30px" }} />
                } */}

            {image && (
              <img src={image} height="50px" width="50px" alt="card-image" />
            )}

            {/* <h5 className="font-size-15 mb-1">
                  <Link to="#" className="text-dark">
                    {title}
                  </Link>
                </h5> */}
            {/* <p className="text-muted">{title}</p> */}
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            {title}
            {/* <div className="contact-links d-flex font-size-20">
                  <div className="flex-fill">
                    <Link to="#" id={"message" + icon?.id}>
                      <i className="bx bx-message-square-dots" />
                    </Link>
                  </div>
                  <div className="flex-fill">
                    <Link to="#" id={"project" + icon?.id}>
                      <i className="bx bx-pie-chart-alt" />
                      <UncontrolledTooltip
                        placement="top"
                        target={"project" + icon?.id}
                      >
                        Projects
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                  <div className="flex-fill">
                    <Link to="#" id={"profile" + icon?.id}>
                      <i className="bx bx-icon-circle" />
                      <UncontrolledTooltip
                        placement="top"
                        target={"profile" + icon?.id}
                      >
                        Profile
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </div> */}
          </CardFooter>
        </Card>
      </Link>
    </Col>
  )
}

CardContact.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
}

export default CardContact
