import {
  GET_FAMILY_MEMBER_VARISANGYA_ITEMS,
  GET_FAMILY_MEMBER_VARISANGYA_ITEMS_SUCCESS,
  GET_FAMILY_MEMBER_VARISANGYA_ITEMS_FAIL,
  DELETE_FAMILY_MEMBER_VARISANGYA_ITEM,
  CREATE_FAMILY_MEMBER_VARISANGYA_ITEM,
  CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS,
  CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL,
  DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS,
  DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  familyMemberVarisangyaItems: [],
  error: {},
  loading: false,
}

const FamilyMemberVarisangyaItems = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FAMILY_MEMBER_VARISANGYA_ITEMS:
    case CREATE_FAMILY_MEMBER_VARISANGYA_ITEM:
    case DELETE_FAMILY_MEMBER_VARISANGYA_ITEM:
      return {
        ...state,
        loading: true,
      }

    case GET_FAMILY_MEMBER_VARISANGYA_ITEMS_SUCCESS:
      return {
        ...state,
        familyMemberVarisangyaItems: action.payload,
        loading: false,
      }
    case GET_FAMILY_MEMBER_VARISANGYA_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS:
      return {
        ...state,
        // familyMemberVarisangyaItems: {
        //   ...state.familyMemberVarisangyaItems,
        //   results: [...state.familyMemberVarisangyaItems.results, action.payload],
        // },
        loading: false,
      }

    case CREATE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_SUCCESS:
      return {
        ...state,
        familyMemberVarisangyaItems: {
          ...state.familyMemberVarisangyaItems,
          results: state.familyMemberVarisangyaItems.results.filter(
            familyMemberVarisangyaItem =>
              familyMemberVarisangyaItem.id !==
              action.payload.familyMemberVarisangyaItemId
          ),
        },
        loading: false,
      }

    case DELETE_FAMILY_MEMBER_VARISANGYA_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default FamilyMemberVarisangyaItems
