import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
//actions
import moment from "moment"

import { getMahallUsers, getMahallUsersCards } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"

const AllMahallMahallUsers = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const { mahallUsers, loading, mahallUsersCards, language, userDetail } =
    useSelector(state => ({
      mahallUsers: state.MahallUsers.mahallUsers,
      loading: state.MahallUsers.loading,
      mahallUsersCards: state.MahallUsers.mahallUsersCards,
      language: state.Layout.language,
      userDetail: state.Users.userDetail,
    }))

  //pages
  const totalPages = Math.ceil(mahallUsers?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getMahallUsers(searchText, pageSend()))
  }, [dispatch, page, searchText])

  useEffect(() => {
    dispatch(getMahallUsersCards())
  }, [dispatch])

  const cardData = [
    {
      title: `${language === "mal" ? "ആകെ യൂസറുകൾ" : "Total Users"}`,
      cardValue: mahallUsersCards?.total_users,
      iconClass: "user",
      routeLink: "/",
      loading: loading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Users"}`,
    },
    {
      title: `${language === "mal" ? "സജീവമായ യൂസറുകൾ" : "Active Users"}`,
      cardValue: mahallUsersCards?.active_users,
      iconClass: "smile",
      routeLink: "/",
      loading: loading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Active Users"}`,
    },
    {
      title: `${language === "mal" ? "നിഷ്ക്രിയ യൂസറുകൾ" : "Inactive Users"}`,
      cardValue: mahallUsersCards?.inactive_users,
      iconClass: "upside-down",
      routeLink: "/",
      loading: loading,
      alt: `${language === "mal" ? "ഇല്ല" : "No Inactive Users"}`,
    },
  ]

  const columns = [
    {
      dataField: "no",
      text: `${language === "mal" ? "നമ്പർ" : "No."}`,
    },
    {
      dataField: "full_name",
      text: `${language === "mal" ? "പേര് " : "Name"}`,
    },
    {
      dataField: "phone",
      text: `${language === "mal" ? "ഫോൺ" : "Phone"}`,
    },
    {
      dataField: "email",
      text: `${language === "mal" ? "ഇമെയിൽ" : "Email"}`,
    },
    {
      dataField: "date_added",
      text: `${language === "mal" ? "ചേർന്ന തീയതി" : "Joining Date"}`,
    },
    {
      dataField: "last_login",
      text: `${language === "mal" ? "അവസാന ലോഗിൻ" : "Last Login"}`,
    },
    {
      dataField: "activeData",
      text: `${language === "mal" ? "സ്റ്റാറ്റസ്" : "Status"}`,
    },
    {
      dataField: "action",
      text: `${language === "mal" ? "പ്രവർത്തനങ്ങൾ" : "Action"}`,
    },
  ]

  const handleSwitchChange = () => {
    // dispatch(changeMahallUserStatus(id, history))
  }

  const MahallUsersData = map(mahallUsers?.results, (item, index) => ({
    ...item,
    key: index,
    no: (pageSend() - 1) * limit + index + 1,
    date_added:
      item?.date_added && moment(item?.date_added).format("DD/MM/YYYY"),
    last_login:
      item?.last_login &&
      moment(item?.last_login).format("DD/MM/YYYY - h:mm A"),
    activeData: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.is_active}
            disabled={userDetail?.mahall?.version === "basic"}
          />
          {/* <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.is_active ? `Active` : `Inactive`}
          </label> */}
        </div>
      </>
    ),

    action: (
      <Link to={`/mahalluser/update/${item?.id}`} className="m-0">
        <Button type="button" color="primary" className=" btn w-xs btn-sm">
          {/* <i className="bx bx-pencil me-2" style={{ color: "green", fontSize: "18px" }} /> */}
          {language === "mal" ? "എഡിറ്റ് ചെയ്യുക" : "Edit"}
        </Button>
      </Link>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceMahallUserSearch = debounce(value => setSearchText(value), 600)

  return (
    <React.Fragment>
      <Row>
        {cardData?.map((item, key) => (
          <Col sm="12" md="6" lg="4" key={key} className="sm:p-0">
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink && item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {/* {item.cardValue > 0 && (
                        )} */}
                      <h5 className="mb-0">
                        {item.cardValue && item.cardValue}
                      </h5>
                      {/* <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p> */}
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Col className="col-12 sm:p-0">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField={"key"}
                columns={columns}
                data={MahallUsersData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <div className="col-md-6">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <form
                                className="app-search d-lg-block"
                                onChange={e =>
                                  debounceMahallUserSearch(e.target.value)
                                }
                              >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={
                                      language === "mal"
                                        ? "തിരയുക..."
                                        : "Search..."
                                    }
                                    defaultValue={searchText}
                                  />
                                  <span className="bx bx-search-alt" />
                                </div>
                              </form>
                            </div>
                          </div>
                        </Col>
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/mahalluser/create"
                          style={{
                            width: language === "mal" ? "200px" : "50px",
                          }}
                          className="w-md btn btn-success m-auto me-0 d-block"
                        >
                          {language === "mal"
                            ? "+ പുതിയത് ചേർക്കുക"
                            : "+ New User"}
                        </Link>
                      </div>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"key"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                    {mahallUsers && mahallUsers?.results?.length <= 0 && (
                      <p
                        style={{ fontSize: "15px" }}
                        className="text-center text-info"
                      >
                        {language === "mal"
                          ? "യൂസറുകൾ ഒന്നും ഇല്ല"
                          : "No Users Yet"}
                      </p>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllMahallMahallUsers
