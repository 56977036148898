import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_NOC_CERTIFICATES,
  GET_NOC_CERTIFICATE_DETAIL,
  CREATE_NOC_CERTIFICATE,
  UPDATE_NOC_CERTIFICATE,
  DELETE_NOC_CERTIFICATE,
  GET_NOC_CERTIFICATES_FOR_DOWNLOAD,
} from "./actionTypes"
import {
  getNocCertificateSuccess,
  getNocCertificateFail,
  getNocCertificateDetailSuccess,
  getNocCertificateDetailFail,
  createNocCertificateSuccess,
  createNocCertificateFail,
  updateNocCertificateSuccess,
  updateNocCertificateFail,
  deleteNocCertificateSuccess,
  deleteNocCertificateFail,
  getNocCertificateForDownloadSuccess,
  getNocCertificateForDownloadFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getNocCertificateAPi = ({ searchText, nocType, page }) => {
  if (searchText) {
    return get(
      `/api/v1/noc/noc/?noc_type=${nocType && nocType}&search=${
        searchText && searchText
      }&page=${page ? page : 1}`
    )
  } else {
    return get(
      `/api/v1/noc/noc/?noc_type=${nocType && nocType}&page=${page ? page : 1}`
    )
  }
}
const getNocCertificateForDownloadAPi = nocType => {
  return get(`/api/v1/noc/noc/?noc_type=${nocType && nocType}`)
}
const getNocCertificateDetailAPi = nocCertificateId => {
  return get(`/api/v1/noc/noc/${nocCertificateId}/`)
}
const createNocCertificateApi = ({ nocCertificate }) => {
  return post("/api/v1/noc/noc/", nocCertificate)
}
const updateNocCertificateApi = ({ nocCertificateId, nocCertificate }) => {
  return ApiPut(`/api/v1/noc/noc/${nocCertificateId}/`, nocCertificate)
}
const deleteNocCertificateApi = ({ nocCertificateId }) => {
  return del(`/api/v1/noc/noc/${nocCertificateId}/`)
}

function* fetchNocCertificate({ payload }) {
  try {
    const response = yield call(getNocCertificateAPi, payload)
    if (response && !response?.error) {
      yield put(getNocCertificateSuccess(response))
    } else {
      yield put(getNocCertificateFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    yield put(getNocCertificateFail(error))
  }
}

function* fetchNocCertificateForDownload() {
  try {
    const response = yield call(getNocCertificateForDownloadAPi)
    if (response && !response?.error) {
      yield put(getNocCertificateForDownloadSuccess(response))
    } else {
      yield put(getNocCertificateForDownloadFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    yield put(getNocCertificateForDownloadFail(error))
  }
}

function* fetchNocCertificateDetail({ nocCertificateId }) {
  try {
    const response = yield call(getNocCertificateDetailAPi, nocCertificateId)
    if (response && !response?.error) {
      yield put(getNocCertificateDetailSuccess(response))
    } else {
      yield put(getNocCertificateDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    console.log("error: ", error)
    yield put(getNocCertificateDetailFail(error))
  }
}
function* onCreateNocCertificate({ payload }) {
  try {
    const response = yield call(createNocCertificateApi, payload)
    if (response && !response?.error) {
      yield put(createNocCertificateSuccess(response))
      if (payload.history) {
        payload.history.push(`/noc?noc_type=`)
      }
      doneNotification("Created Successfully")
    } else {
      yield put(createNocCertificateFail(response))
      errorNotification(response?.error)
    }
    // swal({
    //   title: "Enquiry Submited",
    //   icon: "success",
    //   button: "ok",
    // })
  } catch (error) {
    // console.log("error: ", error)
    yield put(createNocCertificateFail(error))
    errorNotification()
  }
}

function* onUpdateNocCertificate({ payload }) {
  try {
    const response = yield call(updateNocCertificateApi, payload)
    if (response && !response?.error) {
      payload.history.push("/noc")
      yield put(updateNocCertificateSuccess(response))
      doneNotification("Updated Successfully")
    } else {
      yield put(updateNocCertificateFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    // console.log("error: ", error)
    yield put(updateNocCertificateFail(error))
    errorNotification()
  }
}

function* onDeleteNocCertificate({ payload }) {
  try {
    const response = yield call(deleteNocCertificateApi, payload)
    if (response && !response?.error) {
      payload.history.push("/noc")
      yield put(deleteNocCertificateSuccess(response))
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteNocCertificateFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteNocCertificateFail(error))
  }
}

function errorNotification(msg) {
  Notification({
    type: "error",
    message: msg ? msg : "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* NocCertificateSaga() {
  yield takeEvery(GET_NOC_CERTIFICATES, fetchNocCertificate)
  yield takeEvery(
    GET_NOC_CERTIFICATES_FOR_DOWNLOAD,
    fetchNocCertificateForDownload
  )
  yield takeEvery(GET_NOC_CERTIFICATE_DETAIL, fetchNocCertificateDetail)
  yield takeEvery(CREATE_NOC_CERTIFICATE, onCreateNocCertificate)
  yield takeEvery(UPDATE_NOC_CERTIFICATE, onUpdateNocCertificate)
  yield takeEvery(DELETE_NOC_CERTIFICATE, onDeleteNocCertificate)
}

export default NocCertificateSaga
