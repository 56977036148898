import React, { Fragment } from "react"
import MetaTags from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
//redux
import { useDispatch, useSelector } from "react-redux"

// availity-reactstrap-validation

// actions

import { useSetRole } from "hooks/useHandleSessions"
// import images
import logo_mahallu from "assets/images/logo/logo.png"
import login_img_mahall from "assets/images/bg_login.jpg"
import iconAdmin from "assets/images/khaf/icon1.png"
import iconMahalluser from "assets/images/khaf/icon2.png"
import iconGroupadmin from "assets/images/khaf/icon3.png"
import iconSurveyor from "assets/images/khaf/icon5.png"
import iconInstituteUser from "assets/images/khaf/icon6.png"
//css
import "toastr/build/toastr.min.css"
import { setUserRole } from "store/actions"

const Role = ({ history }) => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => ({
    user: state.Login.user,
    loading: state.Users.loading,
  }))

  console.log("user", user)

  const handleSetUserRole = userRole => {
    useSetRole(userRole)
    const roleData = {
      role: userRole,
    }
    dispatch(setUserRole(roleData, history))
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Login | Khaf </title>
        </MetaTags>
        <Container fluid className="p-0 bg-white" style={{ height: "100vh" }}>
          <Row className="g-0 h-100">
            <Col md={12} lg={12} xl={12} className="bg-light sm:d-none">
              <div className="">
                <img
                  src={login_img_mahall}
                  alt="logo"
                  className="login-bg-image"
                />
              </div>
            </Col>

            <div className="d-flex position-absolute main-prant-login">
              <div className="w-50 display-md-none"></div>
              <div className="custom-login-card w-50">
                <div className="bg-light-soft p-5 custom-login-child ">
                  <div className="w-100 loginCard ">
                    <div className="d-flex flex-column h-100 login-form-wrap">
                      <div className="m-auto">
                        <div className="d-block auth-logo">
                          <h4>
                            <img src={logo_mahallu} alt="logo" height="50" />
                          </h4>
                        </div>
                      </div>

                      <div className="w-100 sm:w-100 m-auto">
                        <div className="">
                          <p
                            style={{ fontSize: "18px" }}
                            className="text-khaf-blue text-center"
                          >
                            Choose Your Profile
                          </p>
                        </div>
                        <div className="mb-3">
                          {(user?.roles || [])?.map((role, key) => (
                            <div
                              className={
                                role != "user"
                                  ? "d-flex align-items-center justify-content-between bg-light p-2 mb-2 rounded"
                                  : ""
                              }
                              key={key}
                            >
                              <div
                                className={
                                  role != "user"
                                    ? "d-flex align-items-center"
                                    : ""
                                }
                              >
                                {role === "admin" ? (
                                  <>
                                    <img
                                      width="35"
                                      className="rounded"
                                      src={iconAdmin}
                                      alt=""
                                    />
                                    <h4 className="ms-3 mb-0">Admin</h4>
                                  </>
                                ) : role === "group_admin" ? (
                                  <>
                                    <img
                                      width="35"
                                      className="rounded"
                                      src={iconGroupadmin}
                                      alt=""
                                    />
                                    <h4 className="ms-3 mb-0">Group Admin</h4>
                                  </>
                                ) : role === "mahall_user" ? (
                                  <>
                                    <img
                                      width="35"
                                      className="rounded"
                                      src={iconMahalluser}
                                      alt=""
                                    />
                                    <h4 className="ms-3 mb-0">Mahall User</h4>
                                  </>
                                ) : role === "survey_user" ? (
                                  <>
                                    <img
                                      width="35"
                                      className="rounded"
                                      src={iconSurveyor}
                                      alt=""
                                    />
                                    <h4 className="ms-3 mb-0">Survey User</h4>
                                  </>
                                ) : role === "institute_user" ? (
                                  <>
                                    <img
                                      width="35"
                                      className="rounded"
                                      src={iconInstituteUser}
                                      alt=""
                                    />
                                    <h4 className="ms-3 mb-0">
                                      Institute User
                                    </h4>
                                  </>
                                ) : role === "user" ? (
                                  <></>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {role != "user" ? (
                                <input
                                  type="radio"
                                  id="customRadio1"
                                  name="customRadio"
                                  className="form-check-input"
                                  style={{ width: "20px", height: "20px" }}
                                  onClick={() => {
                                    handleSetUserRole(role)
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="w-100 text-center">
                        <p style={{ fontSize: "12px" }}>
                          from&nbsp;&nbsp;
                          <a
                            style={{
                              color: "#1781BB",
                              fontWeight: "600",
                              fontSize: "16px",
                            }}
                            className="text-size-16"
                            href="https://osperb.com/"
                          >
                            osperb
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Role

Role.propTypes = {
  history: PropTypes.object,
}
