import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_DEFAULT_TYPE_LISTS,
  GET_MAHALL_TYPE_LISTS,
  CREATE_DEFAULT_TYPE_LIST_ITEM,
  CREATE_MAHALL_TYPE_LIST_ITEM,
  UPDATE_DEFAULT_TYPE_LIST_ITEM,
  UPDATE_MAHALL_TYPE_LIST_ITEM,
  DELETE_DEFAULT_TYPE_LIST_ITEM,
  DELETE_MAHALL_TYPE_LIST_ITEM,
  GET_MAHALL_WARD_NUMBERS,
} from "./actionTypes"
import {
  getDefaultTypeListsSuccess,
  getDefaultTypeListsFail,
  getMahallTypeListsSuccess,
  getMahallTypeListsFail,
  createDefaultTypeListItemSuccess,
  createDefaultTypeListItemFail,
  createMahallTypeListItemSuccess,
  createMahallTypeListItemFail,
  updateDefaultTypeListItemSuccess,
  updateDefaultTypeListItemFail,
  updateMahallTypeListItemSuccess,
  updateMahallTypeListItemFail,
  deleteDefaultTypeListItemSuccess,
  deleteDefaultTypeListItemFail,
  deleteMahallTypeListItemSuccess,
  deleteMahallTypeListItemFail,
  getMahallWardNumbersSuccess,
  getMahallWardNumbersFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getDefaultTypeListsAPi = () => {
  return get(`/api/v1/mahall/choices_list/?mahall=default&type=all`)
}

const getMahallTypeListsAPi = ({ mahall }) => {
  console.log(mahall)
  return get(`/api/v1/mahall/choices_list/?mahall=${mahall}&type=all`)
}

const getMahallWardNumbersAPi = ({ mahall }) => {
  console.log(mahall)
  return get(`/api/v1/filter/ward_numbers-unique_list/?mahall=${mahall}`)
}

const createDefaultTypeListItemApi = ({ defaultTypeListItem }) => {
  return post("/api/v1/mahall/choices_list/", defaultTypeListItem)
}

const createMahallTypeListItemApi = ({ mahallTypeListItem }) => {
  return post("/api/v1/mahall/choices_list/", mahallTypeListItem)
}

const updateDefaultTypeListItemApi = ({
  defaultTypeListItemId,
  defaultTypeListItem,
}) => {
  return ApiPut(
    `/api/v1/mahall/choices_list/${defaultTypeListItemId}/`,
    defaultTypeListItem
  )
}
const updateMahallTypeListItemApi = ({
  mahallTypeListItemId,
  mahallTypeListItem,
}) => {
  return ApiPut(
    `/api/v1/mahall/choices_list/${mahallTypeListItemId}/`,
    mahallTypeListItem
  )
}

const deleteDefaultTypeListItemApi = ({ defaultTypeListItemId }) => {
  return del(`/api/v1/mahall/choices_list/${defaultTypeListItemId}/`)
}

const deleteMahallTypeListItemApi = ({ mahallTypeListItemId }) => {
  return del(`/api/v1/mahall/choices_list/${mahallTypeListItemId}/`)
}

function* fetchDefaultTypeLists({ payload }) {
  try {
    const response = yield call(getDefaultTypeListsAPi, payload)
    yield put(getDefaultTypeListsSuccess(response))
  } catch (error) {
    yield put(getDefaultTypeListsFail(error))
  }
}

function* fetchMahallTypeLists({ payload }) {
  try {
    const response = yield call(getMahallTypeListsAPi, payload)
    yield put(getMahallTypeListsSuccess(response))
  } catch (error) {
    yield put(getMahallTypeListsFail(error))
  }
}

function* fetchMahallWardNumbers({ payload }) {
  try {
    const response = yield call(getMahallWardNumbersAPi, payload)
    yield put(getMahallWardNumbersSuccess(response))
  } catch (error) {
    yield put(getMahallWardNumbersFail(error))
  }
}

function* onCreateDefaultTypeListItem({ payload }) {
  try {
    const response = yield call(createDefaultTypeListItemApi, payload)
    yield put(createDefaultTypeListItemSuccess(response))
    // payload.history.push("/DefaultTypeListItems")
    doneNotification()
  } catch (error) {
    yield put(createDefaultTypeListItemFail(error))
    // console.log('error: ',error);
    errorNotification()
  }
}

function* onCreateMahallTypeListItem({ payload }) {
  try {
    const response = yield call(createMahallTypeListItemApi, payload)
    yield put(createMahallTypeListItemSuccess(response))
    // payload.history.push("/settings")
    doneNotification()
  } catch (error) {
    yield put(createMahallTypeListItemFail(error))
    errorNotification()
  }
}

function* onUpdateDefaultTypeListItem({ payload }) {
  try {
    const response = yield call(updateDefaultTypeListItemApi, payload)
    yield put(updateDefaultTypeListItemSuccess(response))
    doneNotification()
    try {
      const response = yield call(getDefaultTypeListsAPi, payload)
      yield put(getDefaultTypeListsSuccess(response))
    } catch (error) {
      yield put(getDefaultTypeListsFail(error))
    }
  } catch (error) {
    yield put(updateDefaultTypeListItemFail(error))
    errorNotification()
  }
}

function* onUpdateMahallTypeListItem({ payload }) {
  try {
    const response = yield call(updateMahallTypeListItemApi, payload)
    yield put(updateMahallTypeListItemSuccess(response))
    doneNotification()
    try {
      const res = yield call(getMahallTypeListsAPi, response)
      yield put(getMahallTypeListsSuccess(res))
    } catch (error) {
      yield put(getMahallTypeListsFail(error))
    }
  } catch (error) {
    yield put(updateMahallTypeListItemFail(error))
    errorNotification()
  }
}

function* onDeleteDefaultTypeListItem({ payload }) {
  try {
    const response = yield call(deleteDefaultTypeListItemApi, payload)
    // payload.history.push("/settings")
    yield put(deleteDefaultTypeListItemSuccess(response))
    doneNotification()
    try {
      const response = yield call(getDefaultTypeListsAPi, payload)
      yield put(getDefaultTypeListsSuccess(response))
    } catch (error) {
      yield put(getDefaultTypeListsFail(error))
    }
  } catch (error) {
    errorNotification()
    // console.log('error: ',error);
    yield put(deleteDefaultTypeListItemFail(error))
  }
}

function* onDeleteMahallTypeListItem({ payload }) {
  try {
    const response = yield call(deleteMahallTypeListItemApi, payload)
    // payload.history.push("/MahallTypeListItems")
    yield put(deleteMahallTypeListItemSuccess(response))
    doneNotification()
    try {
      const response = yield call(getMahallTypeListsAPi, payload)
      yield put(getMahallTypeListsSuccess(response))
    } catch (error) {
      yield put(getMahallTypeListsFail(error))
    }
  } catch (error) {
    errorNotification()
    yield put(deleteMahallTypeListItemFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification() {
  Notification({
    type: "success",
    message: "Done",
    title: "",
  })
}

function* TypeListSaga() {
  yield takeEvery(GET_DEFAULT_TYPE_LISTS, fetchDefaultTypeLists)
  yield takeEvery(GET_MAHALL_TYPE_LISTS, fetchMahallTypeLists)
  yield takeEvery(GET_MAHALL_WARD_NUMBERS, fetchMahallWardNumbers)
  yield takeEvery(CREATE_DEFAULT_TYPE_LIST_ITEM, onCreateDefaultTypeListItem)
  yield takeEvery(CREATE_MAHALL_TYPE_LIST_ITEM, onCreateMahallTypeListItem)
  yield takeEvery(UPDATE_DEFAULT_TYPE_LIST_ITEM, onUpdateDefaultTypeListItem)
  yield takeEvery(UPDATE_MAHALL_TYPE_LIST_ITEM, onUpdateMahallTypeListItem)
  yield takeEvery(DELETE_DEFAULT_TYPE_LIST_ITEM, onDeleteDefaultTypeListItem)
  yield takeEvery(DELETE_MAHALL_TYPE_LIST_ITEM, onDeleteMahallTypeListItem)
}

export default TypeListSaga
