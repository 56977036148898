import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import Select from "react-select"
import { Col, Modal, Button, Label, Row } from "reactstrap"

import { updateDivision, deleteDivision, getStaffDropdown } from "store/actions"

const UiModalDivisionUpdate = ({ show, onCloseclick, history, data }) => {
  const dispatch = useDispatch()
  // const params = useParams()

  const { loading, language, staffList } = useSelector(state => ({
    loading: state.Divisions.loading,
    language: state.Layout.language,
    staffList: state.Staffs.staffList,
  }))

  const division = data

  const [searchText, setSearchText] = useState("")
  const [staff, setStaff] = useState("Select staff...")
  const [staffId, setStaffId] = useState()

  useEffect(() => {
    setStaff(division?.class_teacher_name)
    setStaffId(division?.class_teacher)
  }, [division])

  const optionGroup = [
    {
      options: staffList?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result.id,
      })),
    },
  ]

  function handlerFinalValue(event) {
    setStaffId(event.value)
    setStaff(event.label)
  }

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    const updateDivisionData = {
      madrasa_class: division?.class,
      division_name: values.division_name,
      class_teacher: staffId,
      // description: values.description,
    }
    dispatch(updateDivision(updateDivisionData, division?.id, history))
  }

  const handleDeleteDivision = id => {
    dispatch(deleteDivision(id, history, division?.class))
  }

  useEffect(() => {
    dispatch(getStaffDropdown(searchText))
  }, [dispatch, searchText])

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal"
                ? "ഡിവിഷൻ അപ്ഡേറ്റ് ചെയ്യുക"
                : "Update Division"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "ഡിവിഷന്റെ പേര്" : "Division Name"}
                    {/* <span className="text-danger">&#42;</span> */}
                  </Label>
                  <AvField
                    name="division_name"
                    type="text"
                    value={division?.division_name}
                    // validate={{
                    //   required: { value: true },
                    // }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>
                    {language === "mal" ? "അധ്യാപകൻ" : "Class Teacher"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={staff}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Family Member"
                    required="required"
                  />
                </Col>
              </div>
              {/* <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    value={division?.description}
                    label={language === 'mal' ? 'വിവരണം' : 'Description'}
                  />
                </Col>
              </div> */}
              <Row className="mt-5 d-flex">
                <div className="col-6">
                  <div className="row justify-content-start">
                    <div>
                      <Button
                        type="button"
                        color="danger"
                        className="w-md"
                        onClick={() => {
                          onCloseclick()
                          handleDeleteDivision(division?.id)
                        }}
                      >
                        {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md m-auto me-0 d-block"
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക " : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalDivisionUpdate
UiModalDivisionUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
  data: propTypes.object,
}
