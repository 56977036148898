import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCountryDetail, updateCountry } from "store/actions"

const UpdateCountry = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { countryDetail } = useSelector(state => ({
    countryDetail: state.Countries.countryDetail,
  }))

  function handleValidSubmit(values) {
    dispatch(updateCountry(values, countryDetail?.id, history))
    window.scroll(0, 0)
  }

  useEffect(() => {
    dispatch(getCountryDetail(params.id))
  }, [])

  return (
    <>
      <MetaTags>
        <title>Country | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Countries" breadcrumbItem="Update" />
        <Container fluid>
          <div className="container-fluid mt-5">
            <Row>
              <Col sm="12" md="12" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Update Country</CardTitle>

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="country_english_name"
                            type="text"
                            label="Country Name (English)"
                            value={countryDetail?.country_english_name || ""}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter state name",
                              },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="country_malayalam_name"
                            type="text"
                            label="Country Name (മലയാളം)"
                            value={countryDetail?.country_malayalam_name || ""}
                          />
                        </Col>
                      </div>

                      <div className="row justify-content-end">
                        <Col>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Update
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateCountry

UpdateCountry.propTypes = {
  history: PropTypes.object,
}
