import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { useParams } from 'react-router'
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label, Row } from "reactstrap"
import { Image } from "react-bootstrap"
import axios from "axios"
import Select from "react-select"

import {
  updateLedgerItemFail,
  deleteLedgerItem,
  getLedgerItemCategoryDropdown,
  updateLedgerItemSuccess,
} from "store/actions"
import { API_URL } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"
import { useFileSizes } from "hooks/useFileSize"
import { useGetRole, useGetToken } from "hooks/useHandleSessions"

const UiModalLedgerItemUpdate = ({ show, onCloseclick, history, data }) => {
  const dispatch = useDispatch()
  // const params = useParams()
  const handleFileSizes = useFileSizes

  const { loading, language, ledgerId, searchLedgerItemCategories, institute } =
    useSelector(state => ({
      loading: state.Mahalls.loading,
      language: state.Layout.language,
      ledgerId: state.Ledgers.ledgerDetail?.ledgers?.id,
      searchLedgerItemCategories:
        state.LedgerItemCategories.searchLedgerItemCategories,
      institute: state.Ledgers.ledgerDetail.ledgers?.institute,
    }))

  const ledgerItem = data

  const [category, setCategory] = useState("select category")
  const [categoryId, setCategoryId] = useState("")
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    setCategory(ledgerItem?.institute_ledger_item_category_name)
    setCategoryId(ledgerItem?.institute_ledger_item_category)
  }, [ledgerItem])

  useEffect(() => {
    if (institute) {
      dispatch(getLedgerItemCategoryDropdown(institute, searchText))
    }
  }, [institute, searchText, dispatch])

  function handlerFinalValue(event) {
    // dispatch(getLedgerItemCategoryDropdown(event.value))
    setCategoryId(event.value)
    setCategory(event.label)
  }

  const optionGroup = [
    {
      options: searchLedgerItemCategories?.map((results, index) => ({
        key: index,
        label: results.institute_ledger_item_category_name,
        value: results.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  const [customLoad, setCustomLoad] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    image: null,
  })
  console.log(customLoad)
  const [imagePreview, setImagePreview] = useState()

  const [filesSize, setFilesSize] = useState()

  const changeHandler = e => {
    const filesize = e.target.files[0].size
    setFilesSize(filesize)

    setSelectedFile({
      ...selectedFile,
      image: e.target.files[0],
    })

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  // const transactionTypes = [
  //   {
  //     name: `${language === 'mal' ? 'വരുമാനം' : 'Income'}`,
  //     value: 'credit',
  //   },
  //   {
  //     name: `${language === 'mal' ? 'ചെലവ്' : 'Expense'}`,
  //     value: 'debit',
  //   },
  // ]

  const [transactionType, setTransactionType] = useState(
    ledgerItem?.transaction_type
  )

  useEffect(() => {
    setTransactionType(ledgerItem?.transaction_type)
  }, [ledgerItem])

  const handleValidSubmit = (onSubmitProps, values) => {
    onCloseclick()
    setCustomLoad(true)
    // if (handleFileSizes(filesSize)?.status === true) {
    updateLedgerItem(
      selectedFile,
      ledgerItem?.id,
      values,
      ledgerId,
      ledgerItem?.member,
      categoryId,
      transactionType,
      dispatch,
      history,
      setCustomLoad
    )
    setImagePreview()
    // }
  }

  const handleDeleteLedgerItem = id => {
    dispatch(deleteLedgerItem(id, ledgerId))
  }

  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {language === "mal" ? "എൻട്രി അപ്ഡേറ്റ് ചെയ്യുക" : "Update Entry"}
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Row>
                    <div className="d-flex mt-1">
                      <Button
                        disabled
                        type="button"
                        color={
                          ledgerItem?.transaction_type === "credit"
                            ? "success"
                            : "danger"
                        }
                        className="w-100"
                      >
                        {ledgerItem?.transaction_type === "credit"
                          ? "Income"
                          : "Expense"}
                      </Button>
                      {/* {map(
                        transactionTypes,
                        (item, key) => (
                          <Col md="6">
                            <div key={key} className="me-3 mt-1">
                              <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id={item.name}
                                autoComplete="off"
                                value={item.value}
                                // checked
                                checked={item.value === transactionType}
                                onChange={e =>
                                  setTransactionType(
                                    e.target.value
                                  )
                                }
                              />
                              {item.value === "credit" ? (
                                <label
                                  className="btn btn-outline-success w-100"
                                  htmlFor={item.name}
                                >
                                  {item.name}
                                </label>) : (
                                <label
                                  className="btn btn-outline-danger w-100"
                                  htmlFor={item.name}
                                >
                                  {item.name}
                                </label>
                              )}
                            </div>
                          </Col>
                        )
                      )} */}
                    </div>
                  </Row>
                </Col>
              </div>
              <div className="row mb-4">
                <Col>
                  <Label>
                    {language === "mal" ? "കുടുംബാംഗം" : "Member"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="member"
                    type="text"
                    value={
                      ledgerItem?.member_name
                        ? ledgerItem?.member_name
                        : ledgerItem?.other_name
                    }
                    readOnly
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col>
                  <AvField
                    name="title"
                    type="text"
                    value={ledgerItem?.title}
                    label={language === "mal" ? "വിശദാംശങ്ങൾ" : "Particulars"}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="6">
                  <Label>
                    {language === "mal" ? "തീയതി" : "Date"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="date"
                    type="date"
                    value={ledgerItem?.date}
                    // label={language === "mal" ? "തീയതി" : "Date"}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
                <Col sm="12" md="6">
                  <Label>
                    {language === "mal" ? "തുക" : "Amount"}
                    <span className="text-danger">&#42;</span>
                  </Label>
                  <AvField
                    name="amount"
                    type="number"
                    value={ledgerItem?.amount ? ledgerItem?.amount : 0}
                    // label={language === "mal" ? "തുക" : "Amount"}
                    validate={{
                      required: { value: true },
                      min: {
                        value: 0,
                        errorMessage: `${
                          language === "mal"
                            ? "തുക 0-ൽ കൂടുതലായിരിക്കണം"
                            : "Amount must be greater than 0"
                        }`,
                      },
                    }}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>{language === "mal" ? "വിഭാഗം" : "Category"}</Label>
                  <Select
                    onInputChange={handleEnters}
                    placeholder={category}
                    value={category}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                    onChange={handlerFinalValue}
                    type="text"
                    title="Institute Category"
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <AvField
                    name="description"
                    type="textarea"
                    value={ledgerItem?.description}
                    label={language === "mal" ? "വിവരണം" : "Details"}
                  />
                </Col>
              </div>
              <div className="row mb-4">
                <Col sm="12" md="12">
                  <Label>
                    {language === "mal" ? "ബില്ലിന്റെ ഫോട്ടോ" : "Bill Image"}
                  </Label>
                  <AvField
                    name="bill_image"
                    type="file"
                    onChange={changeHandler}
                  />
                  {imagePreview ? (
                    <Image
                      style={{ width: "150px", height: "150px" }}
                      className="my-2"
                      src={imagePreview.image}
                    />
                  ) : (
                    <Image
                      style={{ width: "150px", height: "150px" }}
                      className="my-2"
                      src={ledgerItem?.bill_image}
                    />
                  )}
                  {handleFileSizes(filesSize)?.text && (
                    <p className="text-danger mt-1">
                      {handleFileSizes(filesSize)?.text}
                    </p>
                  )}
                </Col>
              </div>
              <Row className="mt-5 d-flex">
                <div className="col-6">
                  <div className="row justify-content-start">
                    <div>
                      <Button
                        type="button"
                        color="danger"
                        className="w-md"
                        onClick={() => {
                          onCloseclick()
                          handleDeleteLedgerItem(ledgerItem?.id)
                        }}
                      >
                        {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md m-auto me-0 d-block"
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        {language === "mal" ? "അപ്ഡേറ്റ് ചെയ്യുക " : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
              {/* <div className="row w-100 justify-content-end">
                <Button
                  type="submit"
                  color="success"
                  className="w-25"
                  onClick={onCloseclick}
                >{loading && (
                  <>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </>
                )}
                  {" "}
                  {language === "mal" ? "സമർപ്പിക്കുക" : "Submit"}
                </Button>
              </div> */}
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalLedgerItemUpdate
UiModalLedgerItemUpdate.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  history: propTypes.object,
  data: propTypes.object,
}

function updateLedgerItem(
  selectedFile,
  id,
  values,
  ledgerId,
  ledgerItem,
  categoryId,
  transactionType,
  dispatch,
  history,
  setCustomLoad
) {
  const formData = new FormData()
  {
    selectedFile &&
      selectedFile?.image &&
      formData.append(
        "bill_image",
        selectedFile?.image,
        selectedFile?.image?.name
      )
  }
  formData.append("institute_ledger", ledgerId)
  formData.append("member", ledgerItem?.member)
  formData.append("other_name", ledgerItem?.other_name)
  formData.append(
    "institute_ledger_item_category",
    categoryId ? categoryId : ""
  )
  formData.append("transaction_type", transactionType)
  formData.append("title", values.title)
  formData.append("date", values.date)
  formData.append("amount", values.amount ? parseInt(values.amount) : 0)
  formData.append("description", values.description)

  const { token } = useGetToken()
  const { role } = useGetRole()

  axios
    .put(`${API_URL}/api/v1/institute/institute_ledger_item/${id}/`, formData, {
      headers: {
        Authorization: "token " + token,
        Role: role,
      },
    })
    .then(res => {
      if (res && !res?.error) {
        doneNotification("Updated Successfully")
        setCustomLoad(false)
        dispatch(updateLedgerItemSuccess(res?.data))
        // dispatch(getLedgerDetail(res?.data?.institute_ledger))
      } else {
        // updateLedgerItemFail(err)
        errorNotification()
      }
    })
    .catch(err => {
      updateLedgerItemFail(err)
      errorNotification()
      setCustomLoad(false)
    })
}
