import {
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAIL,
  GET_ADMINS_DETAIL_SUCCESS,
  GET_ADMINS_DETAIL_FAIL,
  CREATE_ADMINS_SUCCESS,
  CREATE_ADMINS_FAIL,
  UPDATE_ADMINS_SUCCESS,
  UPDATE_ADMINS_FAIL,
  DELETE_ADMINS_SUCCESS,
  DELETE_ADMINS_FAIL,
  GET_ADMINS,
  GET_ADMINS_DETAIL,
  UPDATE_ADMINS,
  CREATE_ADMINS,
  DELETE_ADMINS,
} from "./actiontypes"

const INIT_STATE = {
  admins: [],
  adminsDetail: {},
  error: {},
  loading: false,
  detailLoading: false,
  createdAdmins: {},
}

const Admins = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADMINS:
    case UPDATE_ADMINS:
    case CREATE_ADMINS:
    case DELETE_ADMINS:
      return {
        ...state,
        loading: true,
      }
    case GET_ADMINS_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.payload,
        loading: false,
      }

    case GET_ADMINS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.payload,
        createdAdmins: action.payload,
        loading: false,
      }

    case CREATE_ADMINS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ADMINS_DETAIL_SUCCESS:
      return {
        ...state,
        ADMINSDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_ADMINS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_ADMINS_SUCCESS:
      return {
        ...state,
        adminsDetail: action.payload,
        loading: false,
      }

    case UPDATE_ADMINS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_ADMINS_SUCCESS:
      return {
        ...state,
        admins: state.admins.filter(
          admin => admin.id.toString() !== action.payload.id.toString()
        ),
        loading: false,
      }

    case DELETE_ADMINS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Admins
