import { call, put, takeEvery } from "redux-saga/effects"
// Crypto Redux States
import moment from "moment"

import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DISEASE_TYPE_DATA,
  GET_MONTHLY_CHART,
  GET_YEARLY_CHART,
  GET_DASHBOARD_CARD,
} from "./actionTypes"
import {
  getDashboardDataSuccess,
  getDashboardDataFail,
  getDashboardDiseaseTypeDataSuccess,
  getDashboardDiseaseTypeDataFail,
  getMonthlyChartSuccess,
  getMonthlyChartFail,
  getYearlyChartSuccess,
  getYearlyChartFail,
  getDashboardCardSuccess,
  getDashboardCardFail,
} from "./actions"

import { get } from "helpers/api_methods"
import { errorNotification } from "components/Common/Notification"

const getMonthlyChartAPi = ({ date }) => {
  return get(
    `/api/v1/dashboard/yearly-products-graph/?date=${
      date ? date : moment(Date.now()).format("YYYY")
    }`
  )
}
const getYearlyChartAPi = ({ date }) => {
  return get(
    `/api/v1/dashboard/yearly-revenue-graph/?date=${
      date ? date : moment(Date.now()).format("YYYY")
    }`
  )
}

function* fetchYearlyChart({ payload }) {
  try {
    const response = yield call(getYearlyChartAPi, payload)
    if (response && !response?.error) {
      yield put(getYearlyChartSuccess(response))
    } else {
      yield put(getYearlyChartFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getYearlyChartFail(error))
    // errorNotification(error)
  }
}

function* fetchMonthlyChart({ payload }) {
  try {
    const response = yield call(getMonthlyChartAPi, payload)
    if (response && !response?.error) {
      yield put(getMonthlyChartSuccess(response))
    } else {
      yield put(getMonthlyChartFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getMonthlyChartFail(error))
    // errorNotification(error)
  }
}

const getDashboardDataAPi = () => {
  return get("/api/v1/dashboard/health_condition_chart/")
}

const getDashboardDiseaseTypeDataAPi = () => {
  return get("/api/v1/dashboard/disease_type_chart/")
}

function* fetchDashboardData() {
  try {
    const response = yield call(getDashboardDataAPi)
    if (response && !response?.error) {
      yield put(getDashboardDataSuccess(response))
    } else {
      yield put(getDashboardDataFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getDashboardDataFail(error))
    // errorNotification(error)
  }
}

function* fetchDashboardDiseaseTypeData() {
  try {
    const response = yield call(getDashboardDiseaseTypeDataAPi)
    if (response && !response?.error) {
      yield put(getDashboardDiseaseTypeDataSuccess(response))
    } else {
      yield put(getDashboardDiseaseTypeDataFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getDashboardDiseaseTypeDataFail(error))
    // errorNotification(error)
  }
}

const getDashboardCardAPi = () => {
  return get("/api/v1/dashboard/dashboard_cards/")
}

function* fetchDashboardCard() {
  try {
    const response = yield call(getDashboardCardAPi)
    if (response && !response?.error) {
      yield put(getDashboardCardSuccess(response))
    } else {
      yield put(getDashboardCardFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getDashboardCardFail(error))
    // errorNotification(error)
  }
}

function* DashBoardDataSaga() {
  yield takeEvery(GET_DASHBOARD_DATA, fetchDashboardData)
  yield takeEvery(
    GET_DASHBOARD_DISEASE_TYPE_DATA,
    fetchDashboardDiseaseTypeData
  )
  yield takeEvery(GET_MONTHLY_CHART, fetchMonthlyChart)
  yield takeEvery(GET_YEARLY_CHART, fetchYearlyChart)
  yield takeEvery(GET_DASHBOARD_CARD, fetchDashboardCard)
}

export default DashBoardDataSaga
