import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"

//actions
import { createDistrict, getStates } from "store/actions"
//componets
import Breadcrumbs from "components/Common/Breadcrumb"

const CreateDistrict = ({ history }) => {
  const dispatch = useDispatch()

  const { loading, states } = useSelector(state => ({
    loading: state.Districts.loading,
    error: state.Districts.error,
    districtDetail: state.Districts.districtDetail,
    states: state.States.states,
  }))

  const [state, setState] = useState("Search State")
  const [stateId, setStateId] = useState("")
  const [searchText, setSearchText] = useState("")

  // handleValidSubmit
  const handleValidSubmit = values => {
    console.log(values)
    dispatch(createDistrict({ ...values, state: stateId }, history))
  }

  useEffect(() => {
    dispatch(getStates(searchText))
  }, [searchText, dispatch])

  function handlerFinalValue(event) {
    dispatch(getStates(event.value))
    setStateId(event.value)
    setState(event.label)
  }

  const optionGroup = [
    {
      options: states?.results?.map((results, index) => ({
        key: index,
        label: results.state_english_name,
        value: results.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  // const mailError = error[0]

  return (
    <>
      <MetaTags>
        <title>Districts | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Districts" breadcrumbItem="Create" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col sm="12" md="12" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Fill this form</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <div className="row mb-4">
                        <Col>
                          <AvField
                            label="District Name (English)"
                            // name="district_english_name"
                            name="district_english_name"
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter district name",
                              },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <AvField
                            label="District Name (മലയാളം)"
                            name="district_malayalam_name"
                            type="text"
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Col>
                          <Label> State </Label>
                          <Select
                            onInputChange={handleEnters}
                            placeholder={state}
                            value={state}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            onChange={handlerFinalValue}
                            type="text"
                            title="State"
                            required="required"
                          />
                        </Col>
                      </div>

                      <div className="d-flex justify-content-end">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            {loading && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            )}
                            Submit
                          </Button>
                        </div>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateDistrict

CreateDistrict.propTypes = {
  history: PropTypes.object,
}
