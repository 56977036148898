function sidebardata() {
  return [
    // {
    //   heading: "States",
    //   badgeClass: "danger",
    //   badgeValue: "",
    //   iconClass: "bx bx-book-add",
    //   subTitles: [
    //     { title: "All States", routeLink: "/states" },
    //     {
    //       title: "Create States",
    //       routeLink: "/state/create",
    //     },
    //   ],
    // },
    // {
    //   heading: "Districts",
    //   badgeClass: "danger",
    //   badgeValue: "",
    //   iconClass: "bx bx-grid-alt",
    //   subTitles: [
    //     { title: "All Districts", routeLink: "/districts" },
    //     {
    //       title: "Create Districts",
    //       routeLink: "/district/create",
    //     },
    //   ],
    // },
    // {
    //   heading: "Constituencies",
    //   badgeClass: "danger",
    //   badgeValue: "",
    //   iconClass: "dripicons-store",
    //   subTitles: [
    //     { title: "All Constituencies", routeLink: "/constituencies" },
    //     {
    //       title: "Create Constituencies",
    //       routeLink: "/constituency/create",
    //     },
    //   ],
    // },
    // {
    //   heading: "Villages",
    //   badgeClass: "danger",
    //   badgeValue: "",
    //   iconClass: "bx bx-abacus",
    //   subTitles: [
    //     { title: "All Villages", routeLink: "/villages" },
    //     {
    //       title: "Create Village",
    //       routeLink: "/village/create",
    //     },
    //   ],
    // },
    {
      heading: "",
      headingSaparetor: "Users",
      badgeClass: "",
      badgeValue: "",
      iconClass: "",
      subTitles: [{ title: "", routeLink: "" }],
    },
    {
      heading: "Mahall Users",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bx-user-pin",
      subTitles: [
        { title: "All Mahall Users", routeLink: "/mahallusers" },
        {
          title: "Create Mahall User",
          routeLink: "/mahalluser/create",
        },
      ],
    },
    {
      heading: "Institute Users",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bx-user-pin",
      subTitles: [
        { title: "All Institute Users", routeLink: "/instituteusers" },
        {
          title: "Create Institute User",
          routeLink: "/instituteuser/create",
        },
      ],
    },

    {
      heading: "",
      headingSaparetor: "Mahalls",
      badgeClass: "",
      badgeValue: "",
      iconClass: "",
      subTitles: [{ title: "", routeLink: "" }],
    },
    {
      heading: "Mahalls",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "dripicons-home",
      subTitles: [
        { title: "All Mahalls", routeLink: "/mahalls" },
        {
          title: "Create Mahall",
          routeLink: "/mahall/create",
        },
      ],
    },
    {
      heading: "",
      headingSaparetor: "Families",
      badgeClass: "",
      badgeValue: "",
      iconClass: "",
      subTitles: [{ title: "", routeLink: "" }],
    },

    {
      heading: "Families",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bx-home",
      subTitles: [
        { title: "All Families", routeLink: "/families" },
        {
          title: "Create Family",
          routeLink: "/family/create",
        },
      ],
    },
    {
      heading: "Family Members",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "dripicons-user-group",
      subTitles: [
        { title: "All Members", routeLink: "/familymembers" },
        {
          title: "Create Family Member",
          routeLink: "/familymember/create",
        },
      ],
    },
    {
      heading: "Jobs",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bx-chevron-left-square",
      subTitles: [
        { title: "All Jobs", routeLink: "/jobs" },
        {
          title: "Create Job",
          routeLink: "/job/create",
        },
      ],
    },
    {
      heading: "Educations",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bxs-graduation",
      subTitles: [
        { title: "All Educations", routeLink: "/educations" },
        {
          title: "Create Education",
          routeLink: "/education/create",
        },
      ],
    },

    {
      heading: "",
      headingSaparetor: "Institutes",
      badgeClass: "",
      badgeValue: "",
      iconClass: "bx bxs-crown",
      subTitles: [{ title: "", routeLink: "" }],
    },

    {
      heading: "Institutes",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bxs-institution",
      subTitles: [
        { title: "All Institutes", routeLink: "/institutes" },
        {
          title: "Create Institute",
          routeLink: "/institute/create",
        },
      ],
    },

    {
      heading: "Accounts",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bx-id-card",
      subTitles: [
        { title: "All Accounts", routeLink: "/instituteaccounts" },
        {
          title: "Create Account",
          routeLink: "/instituteaccount/create",
        },
      ],
    },

    {
      heading: "",
      headingSaparetor: "Committees",
      badgeClass: "",
      badgeValue: "",
      iconClass: "",
      subTitles: [{ title: "", routeLink: "" }],
    },

    {
      heading: "Committees",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bx-bar-chart-alt-2",
      subTitles: [
        { title: "All Committees", routeLink: "/committees" },
        {
          title: "Create Committee",
          routeLink: "/committee/create",
        },
      ],
    },

    {
      heading: "Members",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bx-user-plus",
      subTitles: [
        { title: "All Members", routeLink: "/committeemembers" },
        {
          title: "Create Member",
          routeLink: "/committeemember/create",
        },
      ],
    },

    {
      heading: "Meetings",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bx-target-lock",
      subTitles: [
        { title: "All Meetings", routeLink: "/committeemeetings" },
        {
          title: "Create Meeting",
          routeLink: "/committeemeeting/create",
        },
      ],
    },
    {
      heading: "Meeting Attendances",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bxl-unsplash",
      subTitles: [
        {
          title: "All Meeting Attendances",
          routeLink: "/committeemeetingattendances",
        },
        {
          title: "Create Meeting Attendance",
          routeLink: "/committeemeetingattendance/create",
        },
      ],
    },
  ]
}

export const mySideBar = sidebardata()

//Prod
