import {
  GET_INSTITUTE_WALLETS_SUCCESS,
  GET_INSTITUTE_WALLETS_FAIL,
  GET_INSTITUTE_WALLET_DROPDOWN_SUCCESS,
  GET_INSTITUTE_WALLET_DROPDOWN_FAIL,
  GET_INSTITUTE_WALLET_DETAIL_SUCCESS,
  GET_INSTITUTE_WALLET_DETAIL_FAIL,
  CREATE_INSTITUTE_WALLET_SUCCESS,
  CREATE_INSTITUTE_WALLET_FAIL,
  UPDATE_INSTITUTE_WALLET_SUCCESS,
  UPDATE_INSTITUTE_WALLET_FAIL,
  DELETE_INSTITUTE_WALLET_SUCCESS,
  DELETE_INSTITUTE_WALLET_FAIL,
  GET_INSTITUTE_WALLETS,
  GET_INSTITUTE_WALLET_DROPDOWN,
  GET_INSTITUTE_WALLET_DETAIL,
  UPDATE_INSTITUTE_WALLET,
  CREATE_INSTITUTE_WALLET,
  DELETE_INSTITUTE_WALLET,
  GET_INSTITUTE_WALLETS_DOWNLOAD,
  GET_INSTITUTE_WALLETS_DOWNLOAD_SUCCESS,
  GET_INSTITUTE_WALLETS_DOWNLOAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  instituteWallets: [],
  instituteWalletsDownload: [],
  instituteWalletDetail: {},
  searchWallets: [],
  error: {},
  loading: false,
  detailLoading: false,
}

const InstituteWallets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INSTITUTE_WALLETS:
    case GET_INSTITUTE_WALLETS_DOWNLOAD:
    case UPDATE_INSTITUTE_WALLET:
    case CREATE_INSTITUTE_WALLET:
    case DELETE_INSTITUTE_WALLET:
    case GET_INSTITUTE_WALLET_DROPDOWN:
      return {
        ...state,
        loading: true,
      }
    case GET_INSTITUTE_WALLET_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_INSTITUTE_WALLETS_SUCCESS:
      return {
        ...state,
        instituteWallets: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_WALLETS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_INSTITUTE_WALLETS_DOWNLOAD_SUCCESS:
      return {
        ...state,
        instituteWalletsDownload: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_WALLETS_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_INSTITUTE_WALLET_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchWallets: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_WALLET_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_INSTITUTE_WALLET_SUCCESS:
      return {
        ...state,
        instituteWallets: {
          ...state.instituteWallets,
          results: [...state.instituteWallets.results, action.payload],
        },
        loading: false,
      }

    case CREATE_INSTITUTE_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_WALLET_DETAIL_SUCCESS:
      return {
        ...state,
        instituteWalletDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_INSTITUTE_WALLET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_INSTITUTE_WALLET_SUCCESS:
      return {
        ...state,
        instituteWallets: {
          results: state.instituteWallets.results?.map(item =>
            item.id.toString() === action.payload.id.toString()
              ? { item, ...action.payload }
              : item
          ),
        },
        instituteWalletDetail: action.payload,
        loading: false,
      }

    case UPDATE_INSTITUTE_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_INSTITUTE_WALLET_SUCCESS:
      return {
        ...state,
        instituteWallets: action.payload,
        instituteWallets: state.instituteWallets.filter(
          instituteWallet => instituteWallet.id !== action.payload.id
        ),
        loading: false,
      }

    case DELETE_INSTITUTE_WALLET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default InstituteWallets
