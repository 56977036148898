/* TIMETABLE_LIST */
export const GET_TIMETABLE_LIST = "GET_TIMETABLE_LIST"
export const GET_TIMETABLE_LIST_SUCCESS = "GET_TIMETABLE_LIST_SUCCESS"
export const GET_TIMETABLE_LIST_FAIL = "GET_TIMETABLE_LIST_FAIL"

/**
 * Edit TIMETABLE
 */
export const UPDATE_TIMETABLE = "UPDATE_TIMETABLE"
export const UPDATE_TIMETABLE_SUCCESS = "UPDATE_TIMETABLE_SUCCESS"
export const UPDATE_TIMETABLE_FAIL = "UPDATE_TIMETABLE_FAIL"

/**
 * PERIODS_DROPDOWN
 */
export const PERIODS_DROPDOWN = "PERIODS_DROPDOWN"
export const PERIODS_DROPDOWN_SUCCESS = "PERIODS_DROPDOWN_SUCCESS"
export const PERIODS_DROPDOWN_FAIL = "PERIODS_DROPDOWN_FAIL"
